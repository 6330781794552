import React from "react";
import { connect } from "react-redux";
import { Pagination } from "../../shared";
import { loadMedia, loadLessMedia, loadMediaPage } from "../store/actions";
import { setItems } from "../store/carousel/actions";

class MediaPaginationAction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      paginationInputValue: undefined
    };
  }

  /**
   * Load next page in media list
   */
  loadNextPage = () => {
    this.props.loadMedia();
    this.props.setItems([]);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load previous page in media list
   */
  loadPrevPage = () => {
    this.props.loadLessMedia();
    this.props.setItems([]);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load first page in media list
   */
  loadFirstPage = () => {
    this.props.loadMediaPage(1);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load last page in media list
   */
  loadLastPage = () => {
    const { totalMedia, maxRows } = this.props;
    this.props.loadMediaPage(Math.ceil(totalMedia / maxRows));
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Called when input field in pagination changes
   * @param {number} value the page number
   */
  onPagingationInputChange = value => {
    this.setState({
      paginationInputValue: value === "" ? undefined : value
    });
  };

  /**
   * Called when input field in pagination shoul update the current page
   * @param {object} value for some reason, an object containing the page number as property "text"
   */
  onPagingationInputEnter = value => {
    const nextPage = Number(value.text);
    const { totalMedia, maxRows, page: currentPage } = this.props;
    const totalPages = Math.ceil(totalMedia / maxRows);

    if (
      value.text === "" ||
      isNaN(nextPage) ||
      nextPage <= 0 ||
      nextPage > totalPages
    ) {
      this.setState({ paginationInputValue: currentPage });
    } else {
      this.props.loadMediaPage(nextPage);
      this.setState({ paginationInputValue: undefined });
    }
  };

  render() {
    const { page, maxRows, loading, totalMedia } = this.props;
    const { paginationInputValue } = this.state;

    return (
      <Pagination
        page={page}
        maxRows={maxRows}
        inputvalue={
          paginationInputValue === undefined ? page : paginationInputValue
        }
        total={totalMedia}
        disabled={loading}
        more={this.loadNextPage}
        less={this.loadPrevPage}
        tofirst={this.loadFirstPage}
        tolast={this.loadLastPage}
        onChange={this.onPagingationInputChange}
        onEnter={this.onPagingationInputEnter}
      />
    );
  }
}

const mapStateToProps = state => ({
  totalMedia: state.Media.get("total"),
  meta: state.Media.get("meta"),
  maxRows: state.Media.get("fetchLimit"),
  page: state.Media.get("page"),
  loading: state.Media.get("fetching")
});

const mapDispatchToProps = dispatch => ({
  loadMedia: () => dispatch(loadMedia()),
  loadLessMedia: () => dispatch(loadLessMedia()),
  setItems: items => setItems(items)(dispatch),
  loadMediaPage: page => dispatch(loadMediaPage(page))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaPaginationAction);
