import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITY_DETAILS_ERROR,
  FETCH_ENTITY_DETAILS_SUCCESS,
  FETCH_ENTITY_COMMENTS,
  FETCH_ENTITY_COMMENTS_ERROR,
  FETCH_ENTITY_COMMENTS_SUCCESS,
  GOTO_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  START_FETCH,
  FETCH_FILTER,
  FETCH_FILTER_SUCCESS,
  FETCH_FILTER_ERROR,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  RESET_POST_BUFFER,
  RESET_POST_BUFFER_INDEX,
  MULTISVG_UPDATE_TITLE,
  SET_POST_TMPDATA,
  SET_POST_DESCRIPTION,
  CLEAR_POST_TMPDATA,
  SET_MEDIA_DRAFT
} from "../../api";
import { actionCreator } from "../../shared";

export * from "./imageEditorActions";

export const fetchEntities = (
  entityName,
  apiAction,
  args = {},
  searchParameters = {},
  batchIndex
) => ({
  type: actionCreator(entityName, FETCH_ENTITIES),
  entityName,
  apiAction,
  args,
  searchParameters,
  batchIndex
});

export const fetchEntitiesSuccess = (
  entityName,
  entities,
  metadata,
  searchParameters = {},
  batchIndex
) => ({
  type: actionCreator(entityName, FETCH_ENTITIES_SUCCESS),
  entityName,
  entities,
  metadata,
  searchParameters,
  batchIndex
});

export const fetchEntitiesError = (entityName, error, batchIndex) => ({
  type: actionCreator(entityName, FETCH_ENTITIES_ERROR),
  entityName,
  error,
  batchIndex
});

export const fetchEntityDetails = (entityName, apiAction, args = {}) => ({
  type: actionCreator(entityName, FETCH_ENTITY_DETAILS),
  entityName,
  apiAction,
  args
});

export const fetchEntityDetailsSuccess = (entityName, entity, args) => ({
  type: actionCreator(entityName, FETCH_ENTITY_DETAILS_SUCCESS),
  entityName,
  entity,
  args
});

export const fetchEntityDetailsError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_ENTITY_DETAILS_ERROR),
  entityName,
  error
});

export const fetchComments = (entityName, apiAction, args = {}) => ({
  type: actionCreator(entityName, FETCH_ENTITY_COMMENTS),
  entityName,
  apiAction,
  args
});

export const fetchCommentsSuccess = (entityName, entity, args) => ({
  type: actionCreator(entityName, FETCH_ENTITY_COMMENTS_SUCCESS),
  entityName,
  entity,
  args
});

export const fetchCommentsError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_ENTITY_COMMENTS_ERROR),
  entityName,
  error
});

export const fetchFilter = (entityName, apiAction, args, filters) => ({
  type: actionCreator(entityName, FETCH_FILTER),
  entityName,
  apiAction,
  args,
  filters
});

export const fetchFilterSuccess = (entityName, filter, labels) => ({
  type: actionCreator(entityName, FETCH_FILTER_SUCCESS),
  entityName,
  filter,
  labels
});

export const fetchFilterError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_FILTER_ERROR),
  entityName,
  error
});

export const displayError = msg => ({
  type: "DISPLAY_ERROR",
  msg
});

export const gotoPage = (entityName, page) => ({
  type: actionCreator(entityName, GOTO_PAGE),
  page
});

export const nextPage = entityName => ({
  type: actionCreator(entityName, NEXT_PAGE)
});

export const setDraftState = (entityName, draftSate) => ({
  type: actionCreator(entityName, SET_MEDIA_DRAFT),
  draftSate
});

export const previousPage = entityName => ({
  type: actionCreator(entityName, PREVIOUS_PAGE)
});

export const startFetch = entityName => ({
  type: actionCreator(entityName, START_FETCH)
});

export const endFetch = entityName => ({
  type: actionCreator(entityName, END_FETCH)
});

export const resetBeforeFilterSortChange = entityName => ({
  type: actionCreator(entityName, RESET_BEFORE_FILTER_SORT_CHANGE)
});

export const resetPostBuffer = entityName => ({
  type: actionCreator(entityName, RESET_POST_BUFFER)
});

export const resetPostBufferPage = (entityName, page) => ({
  type: actionCreator(entityName, RESET_POST_BUFFER_INDEX),
  batchIndex: page
});

export const updateTitle = (entityName, title) => ({
  type: actionCreator(entityName, MULTISVG_UPDATE_TITLE),
  title
});

export const setTmpDataForNewPost = (entityName, data) => ({
  type: actionCreator(entityName, SET_POST_TMPDATA),
  entityName,
  data
});

export const setDescription = (entityName, description) => ({
  type: actionCreator(entityName, SET_POST_DESCRIPTION),
  entityName,
  description
});

export const clearTmpDataForNewPost = entityName => ({
  type: actionCreator(entityName, CLEAR_POST_TMPDATA),
  entityName
});
