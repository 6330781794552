import React from "react";

const HelpResource = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-help-resource"
  >
    <g>
      <path d="M4.7 50h31.2c2.6 0 4.7-2.1 4.7-4.7v-4.7h4.7c2.6 0 4.7-2 4.7-4.7V4.7C50 2 47.9 0 45.3 0H14.1a4.7 4.7 0 0 0-4.7 4.7v4.7H4.7A4.7 4.7 0 0 0 0 14v31.2C0 48 2.1 50 4.7 50zm36-12.5V14.1c0-2.6-2.2-4.7-4.8-4.7H12.5V4.7c0-.9.7-1.6 1.6-1.6h31.2c.9 0 1.6.7 1.6 1.6v31.2c0 .9-.7 1.6-1.6 1.6h-4.7zM3 47V13h34v34H3z" />
      <path d="M20 15a15 15 0 1 1 0 30 15 15 0 0 1 0-30zm0 3a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm1.5 18v3h-3v-3h3zM20 21a6 6 0 0 0-6 6h3a3 3 0 0 1 3-3 3 3 0 0 1 3 3c0 3-4.5 2.6-4.5 7.5h3c0-3.4 4.5-3.8 4.5-7.5a6 6 0 0 0-6-6z" />
    </g>
  </svg>
);

export default HelpResource;
