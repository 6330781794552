import { client } from "../../api";
import * as querystring from "querystring";
import { FILE_STATUS } from "./constants";

export const getListProducts = (type, searchParameters) => {
  const params = Object.assign(
    {
      type
    },
    searchParameters,
    {
      sort_orders: searchParameters.sort
        ? Object.keys(searchParameters.sort).map(
            key => `${key}:${searchParameters.sort[key]}`
          )
        : []
    },
    searchParameters.status &&
      (Array.isArray(searchParameters.status)
        ? {
            status: searchParameters.status.map(index => FILE_STATUS[index].id)
          }
        : { status: FILE_STATUS[searchParameters.status].id })
  );

  delete params.sort;
  // modify this if necessary when BE is done (subtask SKL-8939)
  return client.get(`entities?${querystring.stringify(params)}`);
};

export const exportProductList = async params => {
  try {
    const response = await client.get(
      `entities/export?type=product&${querystring.stringify(params)}`,
      { responseType: "blob" }
    );
    // RETRIEVE THE BLOB AND CREATE LOCAL URL
    const csvFile = document.createElement("a");
    const url = window.URL.createObjectURL(response.data);

    // Set name to file, including timestamp
    const timestamp = new Date().toLocaleString();
    csvFile.download = "Produktlista " + timestamp + ".csv";
    csvFile.href = url;

    // Open file
    csvFile.click();
    return;
  } catch (e) {
    console.error("fel", e);
  }
};
