import {
  END_FETCH,
  FETCH_ENTITY_DETAILS_ERROR,
  FETCH_ENTITY_DETAILS_SUCCESS,
  START_FETCH,
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITIES,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_ERROR
} from "../../api";

export const ACTION_FETCH_SYSTEMS = `SYSTEMS/${FETCH_ENTITIES}`;
export const ACTION_FETCH_SYSTEMS_START = `SYSTEMS/${START_FETCH}`;
export const ACTION_FETCH_SYSTEMS_SUCCESS = `SYSTEMS/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_SYSTEMS_FAILED = `SYSTEMS/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_SYSTEMS_END = `SYSTEMS/${END_FETCH}`;

export const ACTION_FETCH_SYSTEMS_DETAILS = `SYSTEMS/${FETCH_ENTITY_DETAILS}`;
export const ACTION_FETCH_SYSTEMS_DETAILS_START = `SYSTEMS/${START_FETCH}`;
export const ACTION_FETCH_SYSTEMS_DETAILS_SUCCESS = `SYSTEMS/${FETCH_ENTITY_DETAILS_SUCCESS}`;
export const ACTION_FETCH_SYSTEMS_DETAILS_FAILED = `SYSTEMS/${FETCH_ENTITY_DETAILS_ERROR}`;
export const ACTION_FETCH_SYSTEMS_DETAILS_END = `SYSTEMS/${END_FETCH}`;

export const ACTION_ACTIVATE_AIDAHELP = "ACTIVATE_AIDAHELP";

export const activateAida = aida => ({
  type: ACTION_ACTIVATE_AIDAHELP,
  aida
});

export const fetchSupportSystemDetails = payload => ({
  type: ACTION_FETCH_SYSTEMS_DETAILS,
  payload
});

export const fetchSupportSystemDetailsStart = () => ({
  type: ACTION_FETCH_SYSTEMS_DETAILS_START
});

export const fetchSupportSystemsDetailsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_SYSTEMS_DETAILS_SUCCESS,
  data,
  metadata
});

export const fetchSupportSystemsDetailsFailed = error => ({
  type: ACTION_FETCH_SYSTEMS_DETAILS_FAILED,
  error
});

export const fetchSupportSystemsDetailsEnd = () => ({
  type: ACTION_FETCH_SYSTEMS_DETAILS_END
});

export const fetchSupportSystems = (data) => ({
  type: ACTION_FETCH_SYSTEMS,
  data
});

export const fetchSupportSystemsStart = () => ({
  type: ACTION_FETCH_SYSTEMS_START
});

export const fetchSupportSystemsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_SYSTEMS_SUCCESS,
  data,
  metadata
});

export const fetchSupportSystemsFailed = error => ({
  type: ACTION_FETCH_SYSTEMS_FAILED,
  error
});

export const fetchSupportSystemsEnd = () => ({
  type: ACTION_FETCH_SYSTEMS_END
});
