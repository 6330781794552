import { Column } from "react-virtualized";
import KeyWordsRenderer from "../Renderer/KeyWords";

const KeyWordsColumn = () => null;

KeyWordsColumn.propTypes = Object.assign({}, Column.propTypes);
KeyWordsColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: KeyWordsRenderer,
  draggable: true
});

export default KeyWordsColumn;