import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { PrimaryButton } from "../../../shared/components/Button";
import Link from "../../../shared/components/Link";
import { selectCurrentPath } from "../../../store/selectors";
import HasPermission from "../../../shared/Permissions";
import {
  CREATE,
  OPMESSAGE,
  PRODUCTNEWS,
  MARKETMESSAGE
} from "../../../constants";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0;
  margin-top: 0.5rem;
`;

const AddMessage = ({ type }) => {
  const path = useSelector(selectCurrentPath);

  const getTitleByType = type => {
    switch (type) {
      case OPMESSAGE:
        return "Nytt driftmeddelande";
      case PRODUCTNEWS:
        return "Ny produktnyhet";
      case MARKETMESSAGE:
        return "Nytt marknadsmeddelande";
      default:
        return "Ny okänd";
    }
  };

  return (
    <HasPermission
      component={
        <Link to={`${path}/new`}>
          <StyledPrimaryButton studlicon="Plus">
            {getTitleByType(type)}
          </StyledPrimaryButton>
        </Link>
      }
      section={type}
      permission={CREATE}
    />
  );
};

export default AddMessage;
