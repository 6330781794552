import React from "react";
import { connect } from "react-redux";
import {
  StyledActionButton,
  StyledActionLink,
  StyledActionPubButton,
  StyledActionWrapper,
  StyledLockedBox
} from "./StyledAction";
import { addFlash } from "../../../../../shared";
import { publishPosts, deletePosts } from "../../../../../posts/store/actions";
import HasPermission from "../../../../Permissions";
import { DELETE, sectionCoverter, EXERCISES, AIDA } from "../../../../../constants";
import Tooltip from "react-tooltip-lite";
import AidaPreview from "../../../../../posts/containers/AidaPreview";

const ActionRenderer = ({
  cellData = {},
  rowData,
  publishPosts,
  deletePosts,
  workflowMap,
  infoFlash
}) => {
  const showPreviewButton = rowData.type === "exercise" || rowData.type === "aida";
  const showPublishButton = false; //rowData.type === 'exercise';

  const { publishPost, deletePost, editLink, previewPost, lock } = cellData;
  const { selected } = rowData;

  /**
   * On publish click
   * @private
   */
  const _onPublish = () => publishPosts([publishPost.payload.id]);

  /**
   * On trash click
   * @private
   */
  const _onTrash = () => {
    if (rowData.usage_count > 0) {
      infoFlash(
        "Denna resurs är kopplad till en eller flera övningar.\nVar god tag bort kopplingen innan du tar bort resursen."
      );
      return;
    }
    let deletePostConfirm = window.confirm(
      "Vill du ta bort post " + deletePost.id
    );
    if (deletePostConfirm) {
      deletePosts(rowData.type, [deletePost.id]);
    }
  };

  /**
   * Returns section name for row
   */
  const getSection = () =>
    rowData && rowData.type && sectionCoverter[rowData.type.toLowerCase()];

  /**
   * Renders edit button
   * @returns {*}
   */
  const renderEdit = () => (
    <Tooltip content="Redigera" useDefaultStyles>
      {editLink ? (
        <StyledActionLink
          selected={selected}
          to={editLink}
          studlicon="Pencil"
        />
      ) : (
        <StyledActionButton selected={selected} studlicon="Pencil" disabled />
      )}
    </Tooltip>
  );

  /**
   * Renders preview button
   * @returns {null}
   */
  const renderPreviewButton = () =>
    showPreviewButton &&
    previewPost && (
      <Tooltip content="Preview" useDefaultStyles>
        <a target="_blank" href={previewPost.path} rel="noopener noreferrer">
          <StyledActionPubButton selected={selected} studlicon="Preview" />
        </a>
      </Tooltip>
    );

  /**
   * Renders aida preview button
   * @returns {null}
   */
  const renderAidaPreviewButton = () =>
    showPreviewButton &&
    previewPost && (
      <Tooltip content="Preview" useDefaultStyles>
        <AidaPreview id={previewPost.id} selected={selected} btnType="action" />
      </Tooltip>
     );

  /**
   * Render delete button
   * @returns {*}
   */
  const renderDeletePost = () => (
    <Tooltip content="Ta bort" useDefaultStyles>
      {deletePost ? (
        <StyledActionPubButton
          selected={selected}
          studlicon="Trash"
          onClick={_onTrash}
        />
      ) : (
        <StyledActionButton selected={selected} studlicon="Trash" disabled />
      )}
    </Tooltip>
  );

  /**
   * Renders publish button
   * @returns {null}
   */
  const renderPublishButton = () =>
    showPublishButton && publishPost ? (
      <StyledActionPubButton
        selected={selected}
        onClick={_onPublish}
        studlicon={rowData.status ? "ArrowCircleUp" : "Circle"}
      />
    ) : null;

  /**
   * Renders actions
   * @returns {*}
   */
  const renderActions = () => {
    const section = getSection();
    const actions =
      workflowMap &&
      workflowMap.exercises &&
      workflowMap.exercises[rowData.status];
    const hasDelete = actions
      ? actions.includes(DELETE)
      : section === EXERCISES
      ? false
      : true;

    return (
      <React.Fragment>
        {renderEdit()}
        {section === AIDA ? renderAidaPreviewButton() : renderPreviewButton()}
        {hasDelete && (
          <HasPermission
            component={renderDeletePost()}
            section={getSection()}
            permission={DELETE}
          />
        )}
        {renderPublishButton()}
      </React.Fragment>
    );
  };

  /**
   * Renders lock state
   * @returns {*}
   */
  const renderLockedState = () => {
    return (
      <StyledLockedBox>
        <span>Redigeras av:</span>

        <span>{lock.lockedBy.name}</span>
      </StyledLockedBox>
    );
  };

  /**
   * Checks if is locked
   * @returns {*}
   */
  const isLocked = () => lock && lock.isLocked;

  /**
   * If a post is locked, renders box for locked state, otherwise render edit actions
   * @returns {*}
   */
  const renderByIsLockedCondition = () =>
    isLocked() ? renderLockedState() : renderActions();

  return (
    <StyledActionWrapper isSelected={selected} isLocked={isLocked()}>
      {renderByIsLockedCondition()}
    </StyledActionWrapper>
  );
};

const mapStateToProps = state => ({
  workflowMap: state.Product.get("workflowMap")
});

const mapDispatchToProps = dispatch => ({
  infoFlash: msg => dispatch(addFlash(msg)),
  publishPosts: postIds => dispatch(publishPosts(postIds)),
  deletePosts: (postType, postIds) => dispatch(deletePosts(postType, postIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionRenderer);
