import styled from "styled-components";
import { PrimaryButton } from "../../components/Button";

export const StyledFilterContainer = styled.div`
  width: 240px;
  overflow-y: scroll;
  position: sticky;
  top: 0;
`;

export const StyledModalContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  display: flex;
  border: 0;
`;

export const StyledModalActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledFilterButton = styled(PrimaryButton)`
  width: 100%;
  border-radius: 5px;
`;

export const StyledFilterButtonWrapper = styled.div`
  width: 100%;
  padding: 15px 10px;
`;
