import React from "react";

import SearchBox from "../SearchBox";
import ChoiceGroup from "../ChoiceGroup";
import SearchWithDataList from "../SearchDataList";

import {
  SectionContent,
  StyledSection,
  Title,
  SectionHeader,
  ChooseLabel
} from "./StyledFilterSection";
import { isNumeric } from "../../helpers";

/**
 * Filter section component (stateless)
 * @param {Object} content describing the section {component, title, data, key}
 * @param {String} searchValue containg the current search value if component is a 'searchBox'
 * @param {Object} query representing the current querystring
 * @param {Function} onFilterChange when any filter has changed
 * @param {Function} onSearchChange when typing in a searchBox
 */
const FilterSection = ({
  content,
  searchValue,
  onFilterChange,
  onSearchChange = () => {},
  query
}) => {
  /**
   * Callback from searchbox.
   * @params event Event from input
   */
  const _onChange = event => {
    onFilterChange(event.target.value, content.title, content.filterkey);
  };

  /**
   * Callback from choicegroups.
   * @params event Event from input
   */
  const _onChoiceGroupSelect = newValue => {
    const val =
      content.key === "type"
        ? newValue.map(nv => content.data[nv].type)
        : newValue;
    onFilterChange(val, content.title, content.filterkey);
  };

  /**
   * Callback from SelectGroup
   * @param {*} _ item (ingnored)
   * @param {*} newValue The selected value
   */
  const _onTagSelect = (_, newValue) =>
    onFilterChange(newValue, content.title, content.filterkey);

  /**
   * Callback from SearchBox
   * @param {Object} event Event from input
   */
  const _onSearchChange = event =>
    onSearchChange(event.target.value, content.title, content.filterkey);

  /**
   * Callback from SearchBox
   * @param {Object} event Event from input
   */
  const _onSearchKeyUp = ({ keyCode, target }) => {
    if (keyCode === 13) {
      target.blur();
    }
    return null;
  };

  /**
   * Render a SearchBox section
   */
  const renderSearchBox = () => {
    return (
      <SectionContent title={content.title} open={true}>
        <SearchBox
          id={"search_post"}
          onChange={_onSearchChange}
          onBlur={_onChange}
          onKeyUp={_onSearchKeyUp}
          value={searchValue || ""}
        />
      </SectionContent>
    );
  };

  /**
   * Adds value to taglist when pressing enter
   * @param {*} event - keyCode, target, target.value
   */
  const _onSearchDataListKeyUp = ({ keyCode, target, target: { value } }) =>
    keyCode === 13 ? addValueToTagList(value, target) : null;

  /**
   * Add value to taglist
   * @param {*} value
   * @param {*} target
   */
  const addValueToTagList = (value, target) => {
    const tag = content.data[0].tags.filter(t => t.title === value);
    const querytags = getSelectedIndices("tags");
    if (tag.length > 0) {
      onFilterChange(
        [tag[0].id, ...querytags],
        content.title,
        content.filterkey
      );
      if (target) {
        target.value = "";
      }
    }
  };

  /**
   * Remove a tag from datalist
   * @param {*} value
   * @param {*} target
   */
  const _onSearchDataListDeleteTag = (value, target) => {
    const tag = content.data[0].tags.filter(t => t.title === value);
    const querytags = getSelectedIndices("tags");
    const newTags = querytags.filter(qt => qt !== tag[0].id);
    if (tag !== []) {
      onFilterChange(newTags, content.title, content.filterkey);
      if (target) {
        target.value = "";
      }
    }
  };

  /**
   * add value to taglist
   * @param {*} event
   */
  const _onDataListChange = event => {
    if (!event.nativeEvent.inputType) {
      addValueToTagList(event.target.value, event.target);
    }
  };

  /**
   * Helper function that returns the selected items from the query
   * @param {String} key the property in the query
   */
  const getSelectedIndices = key => {
    const selected = (query && query[key]) || [];
    const arr = Array.isArray(selected) ? selected : [selected];

    return key === "type"
      ? arr.map(v => getTypeIndex(v))
      : arr.map(v => (isNumeric(v) ? +v : v));
  };

  /**
   * get index of type
   * @param {*} v
   */
  const getTypeIndex = v => {
    const t = content.data.filter(d => d.type === v);
    return t ? t[0].id : null;
  };

  /**
   * Helper function that extracts the data from content for different content-types
   */
  const getDataFromContent = () => {
    if (["difficulty", "status", "type", "component", "nosection"].indexOf(content.key) >= 0) {
      return content.data;
    }
    if (content.key === "chips") {
      return content.data[0] ? content.data[0].chips : []
    }

    return content.data[0] ? content.data[0].tags: []
  };

  /**
   * Render a Choice Group section
   */
  const renderChoiceGroup = () => {
    return (
      <SectionContent title={content.title} open={true}>
        <ChoiceGroup
          choices={getDataFromContent()}
          title={content.title}
          multi={true}
          collapsible={true}
          onChange={_onChoiceGroupSelect}
          selected={getSelectedIndices(content.key)}
        />
      </SectionContent>
    );
  };

  /**
   * Render a Select Group section
   */
  const renderSelectGroup = () => {
    return (
      <div>
        <SectionHeader>
          <Title>{content.title}</Title>
        </SectionHeader>
        <ChooseLabel
          title={"Välj labels:"}
          fullWidth={true}
          icon={"chevron"}
          tags={getDataFromContent()}
          selected={getSelectedIndices("tags")}
          eventCallback={_onTagSelect}
        />
      </div>
    );
  };

  const renderSearchDataList = () => {
    const dl = content.data[0] ? content.data[0].tags : [];
    return (
      <SectionContent title={content.title} open={true}>
        <SearchWithDataList
          id={"search_tags"}
          dataList={dl}
          selectedTags={getSelectedIndices("tags")}
          onChange={_onDataListChange}
          onKeyUp={_onSearchDataListKeyUp}
          deleteCallback={_onSearchDataListDeleteTag}
        />
      </SectionContent>
    );
  };

  return (
    <StyledSection>
      {content.component === "SearchBox" ? renderSearchBox() : null}

      {content.component === "ChoiceGroup" ? renderChoiceGroup() : null}

      {content.component === "SelectGroup" ? renderSelectGroup() : null}

      {content.component === "SearchDataList" ? renderSearchDataList() : null}
    </StyledSection>
  );
};

export default FilterSection;
