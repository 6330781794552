import React from "react";
import {
  SquareMinusSolid,
  CheckboxSolidChecked,
  CheckboxEmpty
} from "../../../shared/components/StudliIcons";

import {
  StyledLabels,
  StyledLabelItem,
  StyledButton,
  StyledInputHandle,
  StyledInputWrapper,
  StyledCheckboxLabel
} from "./TagPostLabelStyles";

const TagPostLabels = ({ labelList, scrollable, singleselect, onChange }) => {
  let currentTagId = [];

  /**
   * status of item
   * @param {*} item
   */
  const itemStatus = item => {
    if (item.status !== "empty") {
      currentTagId = [...currentTagId, item.id];
    }
    return item.status;
  };

  /**
   * Change the status of the button. If singleselect only one can be nonempty at a time.
   * @param {*} name
   * @param {*} status
   */
  const _btnChange = (name, status) => {
    if (currentTagId.indexOf(name) >= 0) {
      currentTagId.forEach(a => {
        if (a === name) {
          onChange(name, status);
        } else {
          onChange(a, "empty");
        }
      });
    } else {
      currentTagId.forEach(a => {
        onChange(a, "empty");
      });
      onChange(name, "checked");
    }
  };

  return (
    <StyledLabels className={scrollable ? "scroll" : ""}>
      {labelList.map((item, i) => (
        <StyledLabelItem key={item.id} color={item.color}>
          <CheckboxLabelItem
            label={item.title}
            checkedStatus={itemStatus(item)}
            name={item.id}
            onChange={singleselect ? _btnChange : onChange}
          />
        </StyledLabelItem>
      ))}
    </StyledLabels>
  );
};

const CheckboxLabelItem = ({
  name,
  checkedStatus,
  isDisabled,
  onChange,
  label
}) => {
  /**
   * Toggle status. If previous status is neutral or empty next status will be 'checked'.
   * @param {*} status
   */
  const toggleStatus = status => {
    return status === "checked" ? "empty" : "checked";
  };

  /**
   * changes the status of the checkbox depending on previous status.
   */
  const _onButtonClick = () => {
    onChange(name, toggleStatus(checkedStatus));
  };

  /**
   * Render of the icon in the checkbox depending of status.
   */
  const renderIcon = {
    neutral: <SquareMinusSolid size={20} />,
    checked: <CheckboxSolidChecked size={20} />,
    empty: <CheckboxEmpty size={20} />
  };

  return (
    <StyledButton onClick={_onButtonClick}>
      <StyledInputWrapper>
        <StyledInputHandle>{renderIcon[checkedStatus]}</StyledInputHandle>
      </StyledInputWrapper>
      {label ? <StyledCheckboxLabel>{label}</StyledCheckboxLabel> : null}
    </StyledButton>
  );
};

export default TagPostLabels;
