import MultipleChoice from "./components/MultipleChoice/MultipleChoice";
import { MULTIPLE_CHOICE } from "../constants";
import {
  defaultMultipleChoiceData,
  defaultMultipleChoiceSettings
} from "./components/MultipleChoice/MultipleChoiceHelpers";

const getUsedResources = ({ data }) => {
  if (!data.options) {
    return undefined;
  }
  return data.options.reduce((acc, option) => {
    if (option.mediaresource && option.mediaresource.data) {
      return acc.concat(option.mediaresource.data.id);
    }
    return acc;
  }, []);
};

const types = [MULTIPLE_CHOICE];

export const createMultipleChoicePlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      const { options } = entity.data;
      const { settings = {} } = entity;

      return {
        component: MultipleChoice,
        style: { padding: 0 },
        props: {
          options: options,
          useCorrectionButton: settings.useCorrectionButton,
          buttonLineUp: settings.buttonLineUp,
          buttonSize: settings.buttonSize,
          formtype: settings.formtype,
          data: { ...defaultMultipleChoiceData(), ...entity.data },
          settings: { ...defaultMultipleChoiceSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types,
  getUsedResources
});

export default createMultipleChoicePlugin;

export const emptyBlocks = {
  [MULTIPLE_CHOICE]: {
    type: MULTIPLE_CHOICE,
    data: defaultMultipleChoiceData(),
    settings: defaultMultipleChoiceSettings()
  }
};
