import { LINE_UP } from "../constants";
import Lineup from "./components/Lineup/Lineup";
import {
  defaultLineupData,
  defaultLineupSettings
} from "./components/Lineup/LineupHelpers";

const types = [LINE_UP];

export const createLineupPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: Lineup,
        style: { padding: 0 },
        props: {
          data: { ...defaultLineupData(), ...entity.data },
          settings: { ...defaultLineupSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types
});

export default createLineupPlugin;

export const emptyBlocks = {
  [LINE_UP]: {
    type: LINE_UP,
    data: defaultLineupData(),
    settings: defaultLineupSettings()
  }
};
