import React from "react";
import {
  ADVENTURES,
  ADVENTURES_CARD,
  ADVENTURES_GROUP,
  MARKERS
} from "../../constants";
import AdventuresEditor from "./AdventuresEditor";
import {
  AdventuresGroup,
  Adventures,
  AdventuresCard
} from "../views";

const AdventuresTabPanel = (type) => () => {
  return(
    <AdventuresEditor components={key[type].component} index={key[type].index} />
  )
}

const key = {
  [ADVENTURES_GROUP]: {component: AdventuresGroup, index: 0},
  [ADVENTURES]: {component: Adventures, index: 0},
  [ADVENTURES_CARD]: {component: AdventuresCard, index: 0},
  [MARKERS]: {component: AdventuresGroup, index: 1}
}

export default AdventuresTabPanel;
