import React from "react";
import styled from "styled-components";
import FileToolbar from "../../components/FileToolbar";
import { StyledColumn, StyledQuestionsWrapper } from "./StyledFormativeTests";

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
`;
const FormativeTests = ({
  tests,
  downloadContent,
  uploadContent,
  deleteContent,
  disabled
}) => {
  return (
    <StyledColumn>
      <FileToolbar
        title="Test"
        onDownloadContent={downloadContent}
        onUploadContent={uploadContent}
        onDeleteContent={deleteContent}
        disabled={disabled}
      />
      <StyledQuestionsWrapper>
        {tests && tests.map((t, index) => <Text key={index}>{t.title}</Text>)}
      </StyledQuestionsWrapper>
    </StyledColumn>
  );
};

export default FormativeTests;
