import React from "react";
import { connect } from "react-redux";
import { selectMarkerGroups } from "../../sagas/selectors"
import MarkerGroup from "./MarkerGroup";
import {
  StyledAccordionPanelHeader,
  StyledAccordionPanelLabel,
  StyledHeadGroupInternalWrapper,
  StyledCollapseButton,
} from "./StyledMarkerTree";
import { expandTreeElement } from "../api/actions";
import { translation } from "../../../constants";


const MarkerHeadGroup = ({ data, type, markerGroups, openCollapseHeadGroup }) => {

  const renderPanelCollapseButton = () => {
    return data.isOpen
      ? <StyledCollapseButton studlicon="Minus" onClick={() => openCollapseHeadGroup(type, data.id, "headGroup", false)} />
      : <StyledCollapseButton studlicon="Plus" onClick={() => openCollapseHeadGroup(type, data.id, "headGroup", true)} />
  }

  return (
    <div key={data.id}>
      <StyledAccordionPanelHeader>
        <StyledAccordionPanelLabel>
          <b>HUVUDMÄRKESGRUPP:</b> {data.label}, <b>TYP:</b> <i>{translation[data.group_type]}</i>
        </StyledAccordionPanelLabel>
        {renderPanelCollapseButton()}
      </StyledAccordionPanelHeader>
      <StyledHeadGroupInternalWrapper open={data.isOpen}>
        {
          markerGroups.map((markerGroup) => (
            <MarkerGroup
              key={markerGroup.id}
              markerGroup={markerGroup}
              typeOfGroup={type} />
          ))
        }
      </StyledHeadGroupInternalWrapper>
    </div>
  )
}
const mapStateToProps = (state, { data }) => {
  const { id } = data;
  return {
    markerGroups: selectMarkerGroups(state, id),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openCollapseHeadGroup: (type, id, group, param) => dispatch(expandTreeElement(type, id, group, param))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerHeadGroup)