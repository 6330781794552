import * as T from "io-ts";

const rxSpecialChars = /^[a-zA-Z0-9åäöÅÄÖ !&)(+–,:?!'_-]+$/;
const rxTitle = rxSpecialChars;
const rxColour = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
const Title = T.brand(T.string, s => rxTitle.test(s), "title");
const Colour = T.brand(T.string, s => rxColour.test(s), "colour");

export const Classroomgroup = T.interface({
  title: Title
});

export const ClassroomgroupDetails = T.interface({
  title: Title,
  colour: Colour
});

export const classroomgroupErrors = {
  title: () => "Namnet innehåller otillåtet tecken.",
  colour: () => "Ogiltig färg"
};

export const classroomgroupDetailsErrors = {
  title: () => "Namnet i kombination med upplaga måste vara unikt.",
  colour: () => "Ogiltig färg"
};

export const checkIfGroupExists = (classroomgroups, title, edition) =>
  !!classroomgroups.find(
    g => g.title.toLowerCase() === title.toLowerCase() && g.edition === edition
  );
