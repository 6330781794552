import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  publishProduct,
  saveProduct,
  uploadCoverImage,
  setProductCommercial,
  setProductDiscontinued
} from "../api/actions";

import Form from "../components/ProductForm";
import { getCurrentProductType, getProductInfo } from "../store/selectors";

const ProductForm = () => {
  const [productInfo, subjects, stages] = useSelector(getProductInfo);
  const productType = useSelector(getCurrentProductType);
  const disabled = useSelector(state => state.Product.get("saving"));
  const dispatch = useDispatch();

  const onImageChosen = file => {
    dispatch(uploadCoverImage(file));
  };

  return (
    <>
      {productInfo && (
        <Form
          defaultValues={productInfo}
          subjects={subjects}
          stages={stages}
          onSubmit={vals => dispatch(saveProduct(vals))}
          onSetCommercial={() => dispatch(setProductCommercial(productInfo.id))}
          onSetDiscontinued={() => dispatch(setProductDiscontinued(productInfo.id))}
          onPublish={() => dispatch(publishProduct(productInfo.id))}
          disabled={disabled}
          onImageChosen={onImageChosen}
          productType={productType}
        />
      )}
    </>
  );
};

export default ProductForm;
