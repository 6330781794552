import * as React from "react";
import { DATA, WORDS } from "../../../constants";
import MathWordItem from "./MathWordItem";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";

import {
  StyledMathWord,
  StyledAddedWords,
  StyledMathWordContent
} from "./StyledMathWord";

import PluginHoc from "../../../shared/PluginHoc";

const MAX_WORDS = 1;

export class MathWord extends React.Component {
  static defaultProps = {
    settings: {
      multiLine: true
    }
  };

  constructor(props) {
    super(props);
    this.state = this.getStateFromData(props);
  }

  /**
   * Extracts state from data
   * @param data
   * @returns {{value: string}}
   */
  getStateFromData = data => {
    return {
      title: "",
      mediaresources: {}
    };
  };

  /**
   * Removes media from a mathword
   * @param {number} indx
   * @param {object} prop
   */
  removeMedia = (indx, prop) => {
    this.props.storeDraft(this.props.draftTarget);
    const { words } = this.props;
    words[indx][prop] = null;
    this.props.updateData(WORDS, words, DATA);
  };

  /**
   * Opens media library
   * @param {number} indx
   */
  openMediaLibrary = indx => {
    const { openMediaModal = () => {} } = this.props.actions || {};
    const addMedia = this.onChange;

    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === "image") {
        this.props.storeDraft(this.props.draftTarget);
        addMedia(indx, { mediasrc: selected[0].url, mediaresource: null });
      }
      if (selected[0].type === "audio") {
        this.props.storeDraft(this.props.draftTarget);
        addMedia(indx, { soundsrc: selected[0].url });
      }
    });
  };

  /**
   * Opens media resource list
   * @param {number} indx
   */
  openMediaResourceList = indx => {
    const { openMediaresourceModal = () => {} } = this.props.actions || {};
    const addMediaResource = this.onChange;
    openMediaresourceModal(res => {
      if (res.length > 0) {
        const mediaresource = {
          data: {
            id: res[0].id,
            src: res[0].data
          },
          type: "mediaresource"
        };

        this.props.storeDraft(this.props.draftTarget);

        addMediaResource(indx, {
          mediaresource: mediaresource,
          mediasrc: null
        });
        this.setState({
          mediaresources: {
            ...this.state.mediaresources,
            [res[0].id]: res[0].data
          }
        });
      }
    });
  };

  /**
   * Capture changes made in each word and updates data
   * @param {number} indx
   * @param {object} data
   */
  onChange = (indx, data) => {
    const { words } = this.props;
    const w = { ...words[indx], ...data };
    words[indx] = w;
    this.props.updateData(WORDS, words, DATA);
  };

  /**
   * Add a new empty word when pressing add new word button.
   */
  addWord = () => {
    const { words } = this.props;

    if (words.length < MAX_WORDS) {
      const word = {
        title: "",
        mediasrc: null,
        mediaresource: null,
        soundsrc: null,
        text: ""
      };
      const newWords = [...words, word];
      this.props.updateData(WORDS, newWords, DATA);
    } else {
      console.log("No more words are allowed");
    }
  };

  render() {
    return (
      <StyledMathWord>
        <StyledMathWordContent>
          <StyledAddedWords>{this.renderEditMathWords()}</StyledAddedWords>
        </StyledMathWordContent>

        {this.renderMathWordSidebar()}
      </StyledMathWord>
    );
  }

  /**
   * Render the mathwords. Maximum count is three.
   * @returns {*}
   */
  renderEditMathWords = () => {
    const { words } = this.props;
    return words
      ? words.map((word, index) => this.renderEditWord(word, index))
      : null;
  };

  /**
   * Render a word with title, text and mediaresources
   * @param {object} word
   * @param {number} index
   * @returns {*}
   */
  renderEditWord = (word, index) => {
    const { resources: propsMediaResources } = this.props;
    const { mediaresources: stateMediaResources } = this.state;
    const resources = { ...propsMediaResources, ...stateMediaResources };

    return (
      <div key={index}>
        <MathWordItem
          data={word}
          index={index}
          onChangeHandler={this.onChange}
          openMediaLibrary={this.openMediaLibrary}
          openMediaResourceList={this.openMediaResourceList}
          resources={resources}
          removeMedia={this.removeMedia}
          blockKey={this.props.blockKey}
          onChange={this.props.onChange}
          settings={this.props.settings}
          storeDraft={this.props.storeDraft}
          draftTarget={this.props.draftTarget}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  };

  /**
   * Render Mathword sidebar
   * @returns {*}
   */
  renderMathWordSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#35877A"}>
          Övning:
        </StyledSidebarHeading>
        {/* SIDEBAR CONTENT */}
      </StyledSidebarSection>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#35877A"}>
          Text och bild:
        </StyledSidebarHeading>
        {/* SIDEBAR CONTENT */}
      </StyledSidebarSection>
    </StyledBlockSidebar>
  );
}

export default PluginHoc({
  Component: MathWord
});
