import styled from "styled-components";

import {
  BorderBottom,
  BorderRight,
  Toggle
} from "../../../shared/components/StudliIcons";
import { Trash } from "react-feather";

export const toggle = active => (
  <Toggle size={40} color={active ? "white" : "black"} />
);

export const borderBottom = active => (
  <BorderBottom size={24} color={active ? "white" : "black"} />
);

export const borderRight = active => (
  <BorderRight size={24} color={active ? "white" : "black"} />
);

export const trash = <Trash width="16px" height="16px" />;

export const StyledInteractiveWrapper = styled.div`
  display: flex;
  margin: 7px 5px;
  align-items: center;
`;
