import React from "react";
import styled from "styled-components";
import { StandardButton, Button } from "../../../shared";
import TagsInPost from "./TagsInPost";

export const StyledTagPostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

export const StyledTagPostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  left: ${props => (props.useDetailViewPosition ? "-320px" : "auto")};
  top: ${props => (props.useDetailViewPosition ? "-30vh" : "auto")};
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: visible;
  will-change: transform, opacity;
  z-index: 4;
  height: auto;
  padding-bottom: 10px;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 220px;
`;

export const StyledHeaderTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
`;

export const StyledTop = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
export const StyledTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;

export const StyledMiddle = styled.div`
  max-height: 63vh;
  overflow-y: scroll;
`;

export const StyledMiddleContent = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

export const StyledBottom = styled.div`
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.neutralLight};
`;

export const StyledUpdateButton = styled(props => (
  <StandardButton {...props} />
))`
  width: 280px;
  margin: 10px;
  border-radius: 5px;
`;

export const StyledInlineSectionButton = styled(props => <Button {...props} />)`
  background-color: transparent;
  border: 0;
  padding: 0 0 10px 0;
  min-width: 25px;

  &:hover,
  &:active {
    background-color: transparent;
  }
`;
export const StyledInlineTagDisplay = styled(TagsInPost)`
  postition: absolute;
  left: 0;
`;
