import React from "react";

const Checkbox = ({ target, value, onChange }) => {
  /**
   * callback when input changes
   * @param checked
   * @returns {*}
   * @private
   */
  const _onChange = ({ target: { checked } }) => onChange(checked, target);

  return <input type="checkbox" onChange={_onChange} defaultChecked={value} />;
};

export default Checkbox;
