import React from "react";

const Ellipsis = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-ellipsis"
  >
    <path d="M25,19.7368421 C27.9111842,19.7368421 30.2631579,22.0888158 30.2631579,25 C30.2631579,27.9111842 27.9111842,30.2631579 25,30.2631579 C22.0888158,30.2631579 19.7368421,27.9111842 19.7368421,25 C19.7368421,22.0888158 22.0888158,19.7368421 25,19.7368421 Z M19.7368421,5.26315789 C19.7368421,8.17434211 22.0888158,10.5263158 25,10.5263158 C27.9111842,10.5263158 30.2631579,8.17434211 30.2631579,5.26315789 C30.2631579,2.35197368 27.9111842,0 25,0 C22.0888158,0 19.7368421,2.35197368 19.7368421,5.26315789 Z M19.7368421,44.7368421 C19.7368421,47.6480263 22.0888158,50 25,50 C27.9111842,50 30.2631579,47.6480263 30.2631579,44.7368421 C30.2631579,41.8256579 27.9111842,39.4736842 25,39.4736842 C22.0888158,39.4736842 19.7368421,41.8256579 19.7368421,44.7368421 Z" />
  </svg>
);

export default Ellipsis;
