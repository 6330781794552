import React from "react";
import {
  StyledInputWrapper,
  StyledGroup,
  SectionTitle,
  StyledField,
  StyledMediaWrapper,
  StyledMediaButtons,
  StyledButton
} from "./StyledMessageEditor";
import {
  getMedia,
  renderMedia
} from "../../../shared/MediaHandler/MediaHandler";


const MarketMessageSettings = ({
  meta,
  onMetaDataChange,
  openMedia,
  removeMedia
}) => {

  return (
    <StyledInputWrapper>
      <StyledGroup>
        <SectionTitle>Knapp: </SectionTitle>
        <StyledField
          value={meta.actionCaption}
          onChange={onMetaDataChange}
          disabled={false}
          label="Text"
          name="actionCaption"
        />
        <StyledField
          value={meta.actionURL}
          onChange={onMetaDataChange}
          disabled={false}
          label="URL"
          name="actionURL"
        />
      </StyledGroup>
      <StyledGroup>
        <SectionTitle>Media: </SectionTitle>
        <StyledMediaWrapper>
          {meta.image &&
            renderMedia(
              getMedia({
                image: {
                  src: meta.image.url
                }
              })
            )}
          <StyledMediaButtons>
            <StyledButton
              onClick={openMedia("image")}
              type="button"
              skin="DM"
              outline
            >
              Välj bild
            </StyledButton>
            <StyledButton
              disabled={!(meta.image && meta.image.url)}
              onClick={() => removeMedia()}
              type="button"
              skin="DM"
              outline
            >
              Ta bort bild
            </StyledButton>
          </StyledMediaButtons>
        </StyledMediaWrapper>
      </StyledGroup>
    </StyledInputWrapper>
  );
};

export default MarketMessageSettings;
