import React from "react";
import styled from "styled-components";

const Text = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const TextRenderer = ({ cellData, rowData }) => (
  <Text isSelected={rowData.selected}>{cellData}</Text>
);

export default TextRenderer;
