import React from "react";

const Toggle = ({ size, color }) => (
  <svg
    xmlns=" https://www.w3.org/2000/svg "
    viewBox="-5 -5 30 30"
    width={`${size}px`}
    fill={color}
    className="studlicon-toggle"
  >
    <path d="M12.5,17.5 L14.1666667,17.5 L14.1666667,15.8333333 L12.5,15.8333333 L12.5,17.5 Z M15.8333333,7.5 L17.5,7.5 L17.5,5.83333333 L15.8333333,5.83333333 L15.8333333,7.5 Z M2.5,4.16666667 L2.5,15.8333333 C2.5,16.75 3.25,17.5 4.16666667,17.5 L7.5,17.5 L7.5,15.8333333 L4.16666667,15.8333333 L4.16666667,4.16666667 L7.5,4.16666667 L7.5,2.5 L4.16666667,2.5 C3.25,2.5 2.5,3.25 2.5,4.16666667 Z M15.8333333,2.5 L15.8333333,4.16666667 L17.5,4.16666667 C17.5,3.25 16.75,2.5 15.8333333,2.5 Z M9.16666667,19.1666667 L10.8333333,19.1666667 L10.8333333,0.833333333 L9.16666667,0.833333333 L9.16666667,19.1666667 Z M15.8333333,14.1666667 L17.5,14.1666667 L17.5,12.5 L15.8333333,12.5 L15.8333333,14.1666667 Z M12.5,4.16666667 L14.1666667,4.16666667 L14.1666667,2.5 L12.5,2.5 L12.5,4.16666667 Z M15.8333333,10.8333333 L17.5,10.8333333 L17.5,9.16666667 L15.8333333,9.16666667 L15.8333333,10.8333333 Z M15.8333333,17.5 C16.75,17.5 17.5,16.75 17.5,15.8333333 L15.8333333,15.8333333 L15.8333333,17.5 Z" id="path-3"></path>
  </svg>
);

export default Toggle;
