import uuidv5 from "uuid";
import { LINES, NORMAL } from "../../../constants";

/**
 * returns a default jump object
 */
export const defaultJump = () => ({
  image: null,
  turnAsset: false,
  jumpLength: 1,
  auto: false,
  fixed: {
    isActive: false,
    start: null,
    end: null,
    length: null
  }
});

/**
 * Returns a default show arrow object
 * @returns {{left: boolean, right: boolean}}
 */
export const defaultShowArrow = () => ({
  left: false,
  right: false
});

/**
 * Generate steps objects
 * @returns {{id: *, text: string, isMarked: boolean, isCorrect: boolean, isStartingPoint: boolean}}
 */
export const generateStepObject = text => ({
  id: uuidv5(),
  text,
  isMarked: false,
  isCorrect: false,
  isStartingPoint: false,
  isJumpStart: false,
  isJumpEnd: false,
  showLabel: false,
  hasCustomValue: false,
  stepImage: null
});

/**
 * Generate a default settings object
 * @returns {{options: Array, steps: *[], lineType, interval: number, showArrow: {left: boolean, right: boolean}, errorMargin: number, marking}}
 */
export const defaultNumberlineSettings = () => ({
  lineType: NORMAL,
  startValue: 0,
  interval: 1,
  errorMargin: 0,
  marking: LINES,
  operative: true,
  showArrow: defaultShowArrow(),
  jump: defaultJump(),
  smooth: false
});

export const defaultStepsGenerator = () => ({
  steps: [generateStepObject("0"), generateStepObject("1")]
});
