import styled from "styled-components";
import {
  black,
  neutralTertiary,
  themeDarkAlt,
  white
} from "../../../../styles/shared/colors";

export const StyledTickMarkerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 44px 35px 44px;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
  justify-items: center;
  align-items: center;
  z-index: 2;
  min-height: 90px;
  width: 100%;
`;

export const StyledTickMarker = styled.div`
  cursor: pointer;
  width: 15px;
  height: 20px;
  ${({ selected, active }) => getTickMarkerColors(selected, active)};
`;

export const StyledTickLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25px;
  height: 100%;
  flex-direction: column;
  grid-area: bottom;
`;

export const StyledTickMarkBg = styled.div`
  background: rgba(117, 141, 175, 0.5);
  border-radius: ${({ isFirst, isLast }) => getBorderRadius(isFirst, isLast)};
  grid-area: middle;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTickImageContainer = styled.div`
  position: relative;
  grid-area: top;
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 30%
  display: flex;
  justify-content: center;
  align-items: center;
  `;

export const StyledTickImage = styled.img`
  width: 50%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
`;

export const StyledRemoveImgButton = styled.button`
  position: absolute;
  top: -14px;
  right: 0;
  background: none;
  padding: 0;
  display: flex;
  border: none;
  cursor: pointer;
`;

export const StyledSliderLabel = styled.div`
  width: 25px;
  height: 25px;
  cursor: initial;
  border-radius: 4px;
  padding: 6px;
  background: #dadada;
  color: #333333;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSliderLabelText = styled.span`
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 25px;
  max-width: 50px;
`;

const tickStandardBorder = `1px solid ${neutralTertiary}`;

const getTickMarkerColors = (isSelected, isActive) =>
  isSelected || isActive
    ? `
    border: ${isSelected ? `3px solid ${black}` : tickStandardBorder};
    background-color: ${themeDarkAlt};
  `
    : `
    border: ${tickStandardBorder};
    background-color: ${white};
  `;

const getBorderRadius = (isFirst, isLast) => {
  if (isFirst) return "4px 0 0 4px";
  else if (isLast) return "0 4px 4px 0";
  else return "0";
};
