import { useState, useRef, useEffect } from "react";

const ARROW_UP = 40;
const ARROW_DOWN = 38;
const ENTER = 13;

function useKeyNavigation(ref, itemList, onEnter, setIsVisible) {
  const [currentNodeIndex, setCurrentNodeIndex] = useState(-1);
  const itemsRef = useRef([]);

  const handleArrowUp = e => {
    if (currentNodeIndex < itemList.length - 1) {
      setCurrentNodeIndex(currentNodeIndex + 1);
    }

    if (setIsVisible) {
      setIsVisible(true)
    }

    itemsRef.current[currentNodeIndex + 1] &&
      itemsRef.current[currentNodeIndex + 1].scrollIntoView(false);

    e.preventDefault();
  };

  const handleArrowDown = e => {
    if (currentNodeIndex >= 0) {
      setCurrentNodeIndex(currentNodeIndex - 1);
    }

    if (setIsVisible) {
      setIsVisible(true)
    }

    itemsRef.current[currentNodeIndex - 1] &&
      itemsRef.current[currentNodeIndex - 1].scrollIntoView(false);

    e.preventDefault();
  };

  const handleEnter = e => {
    if (currentNodeIndex >= 0) {
      onEnter(itemList[currentNodeIndex]);
    }
    e.preventDefault();
  };

  useEffect(() => {
    const handleArrowKey = e => {
      switch (e.keyCode) {
        case ARROW_UP:
          handleArrowUp(e);
          break;
        case ARROW_DOWN:
          handleArrowDown(e);
          break;
        case ENTER:
          handleEnter(e);
          break;
        default:
          break;
      }
    };

    const divRef = ref.current;

    divRef.addEventListener("keydown", handleArrowKey, true);
    return () => {
      divRef.removeEventListener("keydown", handleArrowKey, true);
    };
  });

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, itemList.length);
    setCurrentNodeIndex(-1);
  }, [itemList]);

  return { itemsRef, currentNodeIndex };
}

export default useKeyNavigation;
