import { DO_LOGIN, DO_LOGOUT, USER_LOGIN_FAILURE } from "../api/actions";
import { takeEvery } from "redux-saga/effects";
import {
  makeAuthInit,
  makeDoLogin,
  makeDoLogout,
  makeHandleLoginFailure
} from "./makers";
import { AUTH_INIT } from "../../shared";

/**
 * Watches auth init
 */
export function* watchAuthInit() {
  yield takeEvery(AUTH_INIT, makeAuthInit);
}

/**
 * Watches login actions
 */
export function* watchDoLogin() {
  yield takeEvery(DO_LOGIN, makeDoLogin);
}

/**
 * Watches logout actions
 */
export function* watchDoLogout() {
  yield takeEvery(DO_LOGOUT, makeDoLogout);
}

/**
 * Watches login failure
 */
export function* watchLoginFailure() {
  yield takeEvery(USER_LOGIN_FAILURE, makeHandleLoginFailure);
}
