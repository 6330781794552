import React from "react";

const CaretDown = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-caretdown"
  >
    <path d="M32.9979821,19 L17.0023538,19 C15.225791,19 14.3294158,21.1555004 15.588166,22.4141882 L23.5857927,30.4141899 C24.3667928,31.19519 25.6331681,31.1952525 26.4142308,30.4141899 L34.4122324,22.4141882 C35.6684202,21.1580004 34.7781075,19 32.9979821,19 Z M24.9999805,29.0000021 L16.9999788,21.0000004 L32.9999821,21.0000004 L24.9999805,29.0000021 Z" />
  </svg>
);

export default CaretDown;
