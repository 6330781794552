import {
  DATA,
  MEDIA_RESOURCE,
  RESOURCES,
  IMAGE,
  AUDIO,
  VIDEO
} from "../../constants";
import { getMediaObject } from "../../plugins/dataModels";
import * as React from "react";

/**
 * This component allows for modal use in the plugins.
 * @param type - {string} - Defines what type of media object the modal should filter on. Also used as key when data
 *                          is updated if no other key is specified
 * @param openMediaLibrary - {func}
 * @param updateData - {func} - callback for updating the data
 * @param data - {object} - the data object
 * @param openOnMount - {boolean} - if true, the modal will open when the component using this class is mounted in the
 *                                  view. The default value is false
 * @param overrideKey - {string} - if a custom key is needed, other than that of type a custom key can be supplied.
 *                                 See description for "type" for a more detailed explanation.
 * @returns {{openModal: function()}}
 * @constructor
 */
export const ModalHandler = ({
  type,
  openMediaLibrary,
  updateData,
  data,
  settings: { openOnMount, key: overrideKey } = {},
  updateDraftList
}) => {
  /**
   * Decides if component should use a custom key eg. "BackgroundImage" instead of one of the default ones matching type,
   * that is "audio", "video" or "image".
   * @returns {*}
   */
  const getKey = () => (overrideKey ? overrideKey : type);

  /**
   * TYPE: MEDIA_RESOURCE has a different data structure. 'id' is the key that hold the value
   * In all other types 'src' holds the value.
   */
  const mediaSourceData = () =>
    type === MEDIA_RESOURCE
      ? (data[getKey()] || {}).data.id
      : (data[getKey()] || {}).src;

  /**
   *  Select media callback
   */
  const selectMediaCallback = selected =>
    getKey() === MEDIA_RESOURCE
      ? mediaResourceCallback(selected)
      : mediaCallback(selected);

  /**
   * Callback for mediaresources
   * Updates both resources & mediaobject
   *
   * @param {*} selected
   */
  const mediaResourceCallback = selected => {
    if (selected && selected[0]) {
      const { id, title, data } = selected[0];
      const mediaObject = getMediaObject(type, id, title, data);

      updateDraftList();

      updateData(id, { [id]: data }, RESOURCES);
      updateData(null, prepDataObject(mediaObject), DATA);
    }
  };

  /**
   * Callback for media e.g AUDIO, VIDEO, IMAGE
   *
   * @param {*} selected
   */
  const mediaCallback = selected => {
    if (selected && selected.length) {
      const { type, url } = selected[0];
      const mediaObject = getMediaObject(type, url, getFileType(url));

      updateDraftList();

      updateData(null, prepDataObject(mediaObject), DATA);
    }
  };

  /**
   * Prepares the data object that is used to update the current selection of media.
   * There should NEVER be 1 mediaresource and 1 image selected at the same time.
   * We should always override the old selected opposite with blank data.
   *
   * Audio is not afflicted by this, audio should not be aware of other resources.
   *
   * @param {*} mediaObjectq
   */
  const prepDataObject = mediaObject => {
    const dataObject = { ...data };

    switch (type) {
      case IMAGE:
        dataObject[IMAGE] = mediaObject;
        if (dataObject[MEDIA_RESOURCE]) {
          dataObject[MEDIA_RESOURCE] = getMediaObject(MEDIA_RESOURCE);
        }
        break;
      case AUDIO:
        dataObject[AUDIO] = mediaObject;
        break;
      case VIDEO:
        dataObject[VIDEO] = mediaObject;
        break;
      case MEDIA_RESOURCE:
        dataObject[MEDIA_RESOURCE] = mediaObject;
        if (dataObject[IMAGE]) {
          dataObject[IMAGE] = getMediaObject(IMAGE);
        }
        break;
      default:
        return dataObject;
    }
    return dataObject;
  };

  /**
   * TODO: This is a desperate hack!! Nothing else! This should be supplied by the modals in the `selectMediaCallback`
   * TODO: as soon as possible! PLEASE CHANGE ASAP
   */
  const getFileType = src => src.split(".")[src.split(".").length - 1];

  /**
   *  Remove selected media
   * @returns {*}
   */
  const removeMedia = () => {
    updateDraftList();
    updateData(getKey(), getMediaObject(type), DATA);
  };

  /**
   * Opens stuff upp and stuff
   * @returns {function(): *}
   */
  const openModal = () => {
    return mediaSourceData()
      ? removeMedia()
      : openMediaLibrary(selectMediaCallback, generateConditionsObject())();
  };

  /**
   * Generates a condition object to filter the filters
   * @returns {{filters: {selected: *, hide: string[]}}}
   */
  const generateConditionsObject = () => ({
    filters: {
      selected: type,
      hide: ["type"]
    }
  });

  React.useEffect(() => {
    if (openOnMount) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openModal };
};
