import { ENTITIES, MEDIA_RESOURCE } from "../../constants";

export * from "./imageEditorSelectors";

const MEDIARESOURCETYPE = ENTITIES[MEDIA_RESOURCE];

/**
 * select list of posts of type 'type'
 * @param {*} state
 * @param {*} type
 */
export const selectList = (state, type = MEDIARESOURCETYPE) => {
  if (!state[type]) {
    return [];
  }
  return state[type].get("list");
};

/**
 * get a selector for current selectedItem (detailpage). If in listview this is empty.
 * @param {*} type
 */
export const getCurrentPostSelector = (type = MEDIARESOURCETYPE) => state =>
  state[type].get("selectedItem");

/**
 * select current selectedItem (detailpage). If in listview this is empty.
 * @param {*} state
 * @param {*} type
 */
export const selectCurrentPost = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("selectedItem");

/**
 * get List of difficulty values
 * @param {*} state
 * @param {*} type
 */
export const selectDifficulty = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("difficulty");

/**
 * get List of comments
 * @param {*} state
 * @param {*} type
 */
export const selectComments = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("comments");

/**
 * get Description of mediaresource
 * @param {*} state
 * @param {*} type
 */
export const selectDescription = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("description");

/**
 * get List of labels
 * @param {*} state
 * @param {*} type
 */
export const selectLabels = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("labels");

/**
 * get List of tags
 * @param {*} state
 * @param {*} type
 */
export const selectTags = (state, type = MEDIARESOURCETYPE) =>
  state[type].get("tags") || [];

/**
 * Select what type of post we are currently showing
 * @param {*} state
 */
export const selectCurrentPostType = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[MEDIA_RESOURCE];
  }
  return postType;
};

/**
 * Returns a list of selected posts.
 * @param {*} state
 * @param {*} type
 * @param {*} useCurrentPost true if on a detailview and want to use currentPost.
 */
export const selectSelectedPostList = (
  state,
  useCurrentPost,
  type = MEDIARESOURCETYPE
) => {
  let selectedPostsList = [];

  if (!!useCurrentPost) {
    const currentPost = selectCurrentPost(state, type);
    selectedPostsList = currentPost ? [currentPost.first()] : [];
  } else {
    const postlist = selectList(state, type);
    const selectedPostsIds = state.Carousel.items.map(p => p.id);
    selectedPostsList = postlist
      .filter(post => selectedPostsIds.indexOf(post.id) > -1)
      .toJS();
  }
  return selectedPostsList;
};

/**
 * Returns an object with postIds as key and an array of tagids as values
 * postIds is taken from selectedPostList
 * @param {*} selectedPostList list of selected posts
 * @return {object} ex {8: [1,2,3,4]}
 */
export const getTagsPerPost = selectedPostList =>
  selectedPostList.reduce((a, post) => {
    a[post.id] = post.tags ? post.tags : [];
    return a;
  }, {});

/**
 * Returns an object with tagIds as key and an array of postIds who has that tag as value.
 * postIds is taken from selectedPostList
 * @param {*} selectedPostList
 * @return {object} ex {1: [8,6], 2: [8]}
 */
export const getPostsPerTag = selectedPostList =>
  selectedPostList.reduce((a, post) => {
    const postTags = post.tags ? post.tags : [];
    postTags.map(
      tag =>
        (a[tag] =
          a[tag] !== undefined
            ? a[tag].indexOf(post.id) < 0
              ? [...a[tag], post.id]
              : a[tag]
            : [post.id])
    );
    return a;
  }, {});

/**
 * Returns if filter should be visible for current location
 * @param {*} state
 */
export const selectFilterVisibility = state =>
  state.location.routesMap[state.location.type].hideFilters;
