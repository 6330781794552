import styled from "styled-components";
import Avatar from "../Avatar/Avatar";

export const StyledUserNavHandlerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 10px;
`;

export const StyledUserName = styled.span`
  color: #fff;
  font-weight: 900;
`;

export const LogoutBtn = styled.div`
  display: flex;
  padding: 0;
  width: 32px;
  height: 32px;
  background: ${props => props.theme.blue};
  user-select: none;
  color: ${props => props.theme.white};
  font-size: 12px;
  font-weight: 900;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(180deg);
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const StyledProfilePic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
