import React from "react";
import { connect } from "react-redux";

import routerService from "../../services/router";

const Router = ({ route, isAuthorized }) => {
  /**
   * Checks if a user is logged in
   * @returns {boolean|*}
   */
  const authRequirementsPass = () => {
    const routeObject = routerService.getRoute(route);
    const routeRequireAuth = routeObject.requireAuth;

    if (!routeObject) {
      throw new Error(
        "Problem with route. It probably doens't exist. Please make sure route is correctly registered"
      );
    }

    return !routeRequireAuth || isAuthorized;
  };

  if (authRequirementsPass()) {
    const Route = routerService.getView(route);
    return <Route />;
  }

  return null;
};

const mapStateToProps = state => ({
  route: state.location.type,
  isAuthorized: state.viewer.get("author") !== null
});

export default connect(mapStateToProps)(Router);
