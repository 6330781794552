import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Trash2 as Trash } from "react-feather";
import Label from "./Label";
import {
  getProductInfo,
  selectProductsForRelatedProductsSelector,
  getRelatedProducts
} from "../store/selectors";
import { createProductsRelation, deleteProductsRelation } from "../api/actions";
import IconButton from "./IconButton";
import { Search } from "./TwinHandler";
import Confirm from "../../shared/components/Confirm";

const RelatedProductWrapper = styled.div`
  display: flex;
  margin-top: 0.4rem;
`;

const RelatedProductText = styled.p`
  margin: 0;
`;

const StyledLabel = styled(Label)`
  margin-top: 1.25rem;
`;

const RelatedProductsHandler = () => {
  const dispatch = useDispatch();
  const relatedProducts = useSelector(getRelatedProducts);
  const [entity] = useSelector(getProductInfo);
  const [showConfirmRemoveRelatedProduct, setRemoveRelatedProduct] = useState(undefined);

  const removeRelatedProduct = productId =>
    dispatch(deleteProductsRelation(entity.id, productId));

  const addRelatedProduct = relatedProductId =>
    dispatch(createProductsRelation(entity.id, relatedProductId));

  return (
    <>
      {relatedProducts.length !== 0 &&
        relatedProducts.map(product => (
          <RelatedProductWrapper key={product.id}>
            <IconButton
              aria-label="Ta bort relaterade produkten"
              onClick={() => setRemoveRelatedProduct(product.id)}
            >
              <Trash width="16px" height="16px" />
            </IconButton>
            <RelatedProductText>{product.title}</RelatedProductText>
          </RelatedProductWrapper>
        ))}
      <StyledLabel>
        Lägg till produkt
        <Search
          placeholder="Sök"
          onSelect={p => addRelatedProduct(p.id)}
          selector={selectProductsForRelatedProductsSelector}
        />
      </StyledLabel>
      {showConfirmRemoveRelatedProduct && (
        <Confirm
          confirmBtnText="Koppla från"
          onClose={go => {
            if (go) {
              removeRelatedProduct(showConfirmRemoveRelatedProduct);
            }
            setRemoveRelatedProduct(false);
          }}
        >
          <p>
            Är du säker på att du vill koppla bort denna produkt från den här
            kursen?
          </p>
        </Confirm>
      )}
    </>
  );
};

export default RelatedProductsHandler;
