import { createReducer } from "../../../store/utils";
import { ADD_AUTHOR } from "../../api/actions";
import { fromJS } from "immutable";

const authorsReducer = initialState => {
  const state = fromJS(initialState);

  return createReducer(state, {
    [ADD_AUTHOR](state, { author }) {
      return state.set(author.id, author);
    }
  });
};

export default authorsReducer({});
