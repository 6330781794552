import Video from "./components/Video/Video";
import { VIDEO } from "../constants";
import { getMediaObject } from "./dataModels";

export const createVideoPlugin = () => ({
  entityRendererFn: entity => {
    const types = [VIDEO];

    if (types.includes(entity.type)) {
      const data = entity.data;
      const { settings = {} } = entity;
      return {
        component: Video,
        props: {
          data,
          settings
        }
      };
    }
    return null;
  }
});

export default createVideoPlugin;

export const emptyBlocks = {
  video: {
    type: VIDEO,
    data: {
      [VIDEO]: getMediaObject(VIDEO),
      altText: "",
      id: null,
      cc: []
    },
    settings: {
      operative: false,
      useClosedCaption: false
    }
  }
};
