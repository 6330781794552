import { put, select } from "redux-saga/effects";
import { ROUTE_PRODUCT_NEWS } from "../routes";
import { selectEntityId } from "../../store/selectors";
import { fetchSupportSystemDetails } from "../../systems/api/actions";
import { generalPostSaga, generalFlashMessage } from "../../posts/api/saga";

export function* onMessagesRoute(action) {
  yield put(fetchSupportSystemDetails(action.payload));
  const entityId = yield select(selectEntityId);
  yield put({ type: ROUTE_PRODUCT_NEWS, payload: { entityId: entityId } });
}

/**
 * Publish a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const publishMessagePostsSaga = (entityName, apiAction) => {
  return function*(action) {
    // If validation ok then publish - if not show error
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result && result.data !== "") {
      const {
        data: { invalidPosts, numOfPostsToValidate, numOfPostsValidated }
      } = result.data;

      if (numOfPostsToValidate === numOfPostsValidated) {
        let message =
          numOfPostsValidated > 1
            ? `${numOfPostsValidated} poster är nu publicerade.`
            : `Posten är nu publicerad.`;

        yield generalFlashMessage(message, "success");
      } else {
        for (let post of invalidPosts) {
          let startMessage = `Post ${post.postId}, ${post.exerciseType} har följande fel: `;
          const uniqueErrors = [...new Set(post.errors)].map(err => " " + err);
          const message = startMessage + uniqueErrors;

          yield generalFlashMessage(message, "info", false);
        }
      }
    }
  };
};
