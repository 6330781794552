import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch} from "react-redux";
import IconButton from "../../../products/components/IconButton";
import { selectClassroomgroupEntityId } from "../../../store/selectors";
import { Trash2 as Trash } from "react-feather";
import { deleteStickerModal } from "./DeleteStickerModal";
import { deleteStickers, addStickers } from "../../api/actions";
import { openMediaModal } from "../../../shared/components/Modal/MediaModal";
import { MEDIA } from "../../../constants";
import Button from "../../../shared/components/Button";

const ActionGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: -4rem;
  position: relative;
  z-index: 2;
`;
const ActionsRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
const StyledIconButton = styled(IconButton)`
  height: 27px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  width: 30px;
  border-radius: 50%;
  padding-top: 4px;
  margin-left: 5px;
`;
const StyledButton = styled(Button)`
  margin: 0;
  width: 145px;
  fill: ${props => props.theme.themePrimary};
  margin-bottom: 2rem;
`;

const StickerActions = ({ selected, setSelected }) => {
  const dispatch = useDispatch();
  const classroomGroupEntityId = useSelector(selectClassroomgroupEntityId);

  const onDeleteStickers = () => {
    const stickerIds = selected.map(s => s.id);
    dispatch(deleteStickers(classroomGroupEntityId, stickerIds));
    setSelected([]);
  };

  const deleteButtonClick = () => {
    deleteStickerModal(dispatch)({
      onClickOK: onDeleteStickers,
      selected: selected
    });
  };

  const openMedia = key => () => {
    openMediaModal(dispatch)(
      {
        onOK: images => dispatch(addStickers(classroomGroupEntityId, images)),
        onCancel: () => {
          // do nothing
        }
      },
      {
        filters: {
          selected: key,
          hide: ["type"]
        }
      },
      MEDIA,
      "Lägg till en eller flera bilder",
      true,
      true
    );
  };

  return (
    <>
      <ActionGroup>
        <StyledButton
          onClick={openMedia("image")}
          type="button"
          skin="DM"
          outline
        >
          Välj stickers
        </StyledButton>
        {selected.length > 0 && (
          <ActionsRight>
            <span>{`${selected.length} ${
              selected.length === 1 ? "markerad" : "markerade"
            }`}</span>
            <StyledIconButton
              aria-label="Ta bort produkt från grupp"
              onClick={() => deleteButtonClick()}
            >
              <Trash width="24px" height="24px" />
            </StyledIconButton>
          </ActionsRight>
        )}
      </ActionGroup>
    </>
  );
};

export default StickerActions;
