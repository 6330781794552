import React from "react";
import PageHeader from "../../shared/components/PageHeader";

import MediaShell from "./MediaShell";
import { MEDIA } from "../../constants";

const Media = () => (
  <React.Fragment>
    <PageHeader studlicon="Media" title="Media" />
    <MediaShell section={MEDIA} />
  </React.Fragment>
);

export default Media;
