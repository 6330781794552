import styled from "styled-components";
import {
  PrimaryButton,
  StandardButton
} from "../../../shared/components/Button/index";

export const StyledPrimaryButton = styled(PrimaryButton)`
  height: 42px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSortMachine = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledSortMachineContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledItemContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 735px;
  width: 100%;
`;

export const StyledItem = styled.li`
  border: 1px solid #999;
  z-index: 5;
  background: white;
  padding: 10px;
  display: flex;
  margin: 0;
  min-height: 100px;
`;

export const StyledButton = styled(StandardButton)`
  background: white;
  min-width: 40px;
  &:hover {
    background: white;
    fill: #999;
  }
`;

export const StyledButtonRowWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

export const StyledDragHandleWrapper = styled.div`
  margin-top: 12px;
`;
export const StyledColumnWrapper = styled.div`
  flex: 0 0 5%;
`;
export const StyledCheckboxWrapper = styled.div`
  flex: 0 0 5%;
  margin: 5px;
`;
export const StyledValueContentWrapper = styled.div`
  flex: 10;
  margin-left: 10px;
  min-width: 35%;
`;
export const StyledQuestionContentWrapper = styled.div`
  flex: 1;
  margin: 0 4px 0 15px;
  min-width: 30%;
`;
export const StyledTextButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const StyledItemEditText = styled.div`
  flex: 1;
  display: flex;
  ${props => (props.hasMedia ? "flex-direction: column;" : "")};
`;

export const StyledAnswerBox = styled.div`
  flex: 1;
  margin: 0 10px;
  max-width: 20%;
  min-width: 252px;
  align-self: flex-end;
`;

export const StyledMediaResourceWrapper = styled.div`
  border: thin solid #999;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export const StyledBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  border: thin solid #999;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: #f3f3f2;
  height: 100px;
  flex-direction: column;
  position: relative;
`;

export const StyledWarning = styled.div`
  position: absolute;
  color: ${props => props.theme.red};
  line-height: normal;
  top: 0;
  left: 0;
  transform: translateY(-120%);
`;

export const StyledBoxContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 0px 10px 0px 10px;
`;

export const StyledBoxValue = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  grid-column: span 4;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const StyledAddBoxLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  grid-column: span 4;
  height: 40px;
  display: flex;
  flex: 2;
  align-self: center;
`;

export const StyledBoxLabel = styled.label`
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  grid-column: span 4;
  display: flex;
  flex: 5;
`;

export const StyledItemBoxLabel = styled.label`
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  align-self: center;
  margin-right: 5px;
`;

export const StyledClearButton = styled(StandardButton)`
  min-width: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledClearButtonWrapper = styled.div`
  display: flex;
  width: 40px;
  flex: 1;
  justify-content: center;
`;

export const StyledBoxAddButton = styled(StandardButton)`
  min-width: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledBoxAddButtonWrapper = styled.div`
  align-self: center;
  display: flex;
  width: 40px;
  flex: 1;
`;

export const StyledAddBox = styled.div`
  display: flex;
  flex: 1;
  max-width: 8%;
  box-sizing: border-box;
  border: thin solid #999;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 121px;
  background-color: #f3f3f2;
  height: 100px;
  min-width: 150px;
  flex-direction: row;
  align-self: flex-end;
`;

export const StyledUtilityButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledDefaultRadioButton = styled.div`
  margin-right: auto;
  align-self: center;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  border: 3px solid ${props => (props.isActive ? "#2196F3" : "#848484")};
  ${props => (props.isActive ? "" : "min-height: 25px")};
  ${props => (props.isActive ? "" : "min-width: 25px")};

  &:hover {
    border: 3px solid #2196f3;
  }

  span {
    min-width: 20px;
    min-height: 20px;
    position: relative;
    border-radius: 50%;
    border: 4px solid #fff;
    background: #2196f3;
    display: ${props => (props.isActive ? "block" : "none")};
  }
`;

export const StyledEditInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: thin solid #999;
  font-size: 16px;
  input {
    margin: 0 10px;
    border: 0;
    outline: none;
    width: 255px;
  }
  height: 42px;
`;

export const StyledValueEditInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 70px;
`;

export const StyledInputContainer = styled.div`
  border: thin solid #999;
  min-height: 40px;
  margin-right: 4px;
  display: flex;
  min-width: 0;
`;

export const StyledQuestionEditInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 16px;
  padding: 5px 10px;
  input {
    border: 0;
    outline: none;
    width: 100%;
  }
  height: 42px;

  .TextEditor, .TextEditor--Edit {
    position: relative;
    padding: 4px;
    box-shadow: none;
    margin: 0;
    width: 100%;
  }
  .public-DraftEditor-content {
    min-height: 0;
  }

  .draftJsToolbar__toolbar__dNtBH {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: max-content;
  }
`;

export const StyledAddNewItemRow = styled.div`
  display: inline-flex;
`;

export const StyledAddNewItemWrapper = styled.div`
  margin-top: 15px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;

export const StyledAnswerBoxesContainer = styled.div`
  margin: 100px 0px 10px 0px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const StyledDefaultChips = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ccc8c8;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  display: flex;
`;
export const StyledWideChips = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ccc8c8;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  padding: 9px 35px;
  display: flex;
`;
export const StyledTallChips = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ccc8c8;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  padding: 17px 10px;
  display: flex;
`;
export const StyledSquareChips = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ccc8c8;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  padding: 14px;
  display: flex;
`;

export const StyledExtraLargeChips = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ccc8c8;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  padding: 18px;
  display: flex;
`;

export const StyledBoxEditInputWrapper = styled.div`
  padding: 5px 10px 5px 0;
  font-size: 16px;
  width: 100%;
  .TextEditor, .TextEditor--Edit {
    position: relative;
    padding: 4px;
    box-shadow: none;
    margin: 0;
    width: 100%;
  }
  .public-DraftEditor-content {
    min-height: 0;
  }

  .draftJsToolbar__toolbar__dNtBH {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: max-content;
  }
`

export const StyledAnswerBoxEditMediaWrapper = styled.div`
  height: 111px;
  div {
    display: block;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: start;
      & > div {
        order: 2;
      }
      & > span {
        order: 1;
      }
    }
  }
`