import styled from "styled-components";

const IconButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  outline: 0;
  box-sizing: content-box;
  height: 16px;
`;

export default IconButton;
