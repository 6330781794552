import { CALCULATE_TYPE, PLUS, OPERATIVE, ANSWER_TYPE, EQUAL_SIGN, EQUAL, DECIMAL } from "../../../constants";

/**
 * Generates default Lineup data object
 * @returns {{items: Array}}
 */
export const defaultLineupData = () => ({
  items: [{ value: "" }, { value: "" }],
  result: null,
  grid: null
});

/**
 * Generates default Lineup settings object
 */
export const defaultLineupSettings = () => ({
  [CALCULATE_TYPE]: PLUS,
  [ANSWER_TYPE]: DECIMAL,
  [EQUAL_SIGN]: EQUAL,
  [OPERATIVE]: true
});
