import { fromJS, List } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  ACTION_FETCH_TAXONOMY_LEVELS_START,
  ACTION_FETCH_TAXONOMY_LEVELS_SUCCESS,
  ACTION_FETCH_TAXONOMY_LEVELS_END,
  ACTION_FETCH_TAXONOMY_SUBJECTS_START,
  ACTION_FETCH_TAXONOMY_SUBJECTS_SUCCESS,
  ACTION_FETCH_TAXONOMY_SUBJECTS_END,
} from "./actions";

export const requiredFields = {
  fetching: false,
  levels: [],
  subjects: [],
};

const reducer = (initialState) => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {
    [ACTION_FETCH_TAXONOMY_LEVELS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_TAXONOMY_LEVELS_SUCCESS](state, { payload }) {
      return state.set("levels", new List(payload));
    },
    [ACTION_FETCH_TAXONOMY_LEVELS_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_FETCH_TAXONOMY_SUBJECTS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_TAXONOMY_SUBJECTS_SUCCESS](state, { payload }) {
      return state.set("subjects", new List(payload));
    },
    [ACTION_FETCH_TAXONOMY_SUBJECTS_END](state) {
      return state.set("fetching", false);
    },
  });
};

export default reducer;
