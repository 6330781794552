import React from "react";

const CheckboxSolidChecked = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-checkboxsolidchecked"
  >
    <path d="M44.6428571,50 L5.35714286,50 C2.3984375,50 0,47.6015625 0,44.6428571 L0,5.35714286 C0,2.3984375 2.3984375,0 5.35714286,0 L44.6428571,0 C47.6015625,0 50,2.3984375 50,5.35714286 L50,44.6428571 C50,47.6015625 47.6015625,50 44.6428571,50 Z M21.7984375,39.0559152 L42.3341518,18.5202009 C43.0314732,17.8228795 43.0314732,16.6921875 42.3341518,15.9948661 L39.808817,13.4695313 C39.1114955,12.7722098 37.9808036,12.7720982 37.2833705,13.4695313 L20.5357143,30.2170759 L12.7166295,22.3979911 C12.019308,21.7006696 10.8886161,21.7006696 10.191183,22.3979911 L7.66584821,24.9233259 C6.96852679,25.6206473 6.96852679,26.7513393 7.66584821,27.4486607 L19.2729911,39.0558036 C19.9704241,39.7533482 21.1010045,39.7533482 21.7984375,39.0559152 Z" />
  </svg>
);

export default CheckboxSolidChecked;
