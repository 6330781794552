import styled from "styled-components";
import IconButton from "../../../products/components/IconButton";
import { Trash2 as Trash } from "react-feather";

const Text = styled.p`
  margin: 0;
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
`;

const ProductItem = ({product, handleClick}) => {
  const onClickIcon = () => handleClick(product.id);

  return (
    <ProductWrapper key={product.id}>
      <IconButton
        aria-label="Ta bort produkt från grupp"
        onClick={onClickIcon}
      >
        <Trash width="16px" height="16px" />
      </IconButton>
      <Text>{product.sesam.name}</Text>
    </ProductWrapper>
  )
}

export default ProductItem;