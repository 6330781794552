import React from "react";
import {
  PLUS,
  MINUS,
  CALCULATE_TYPE,
  translation,
  GOAL,
  DEFAULT,
  EQUAL,
  FRACTION,
  MIXED,
  ANSWER_TYPE,
  WIDE,
  VERY_WIDE,
  INTEGER,
  MULTIPLE,
  SINGLE,
  MULTIPLE_ANSWERS,
  DECIMAL,
  PERCENT,
  SQUARE,
  FRACTION_METHOD,
  CONVERT_METHOD,
  METHOD_TYPE,
  DROPDOWN,
  DECIMAL_COUNT,
  IMAGE_METHOD,
  NUMERATOR,
  DENOMINATOR,
  RESULT,
  EXTEND,
  SHORT,
  SIMPLIFY,
  SIMPLIFY_HIDDEN,
  SIMPLIFY_MULTIPLE,
  INPUT
} from "../../../constants";
import {
  Addition,
  Subtraction
} from "../../../shared/components/StudliIcons";
import { isNumeric } from "../../../shared/helpers";
import { clone } from "ramda";

export const rowSettingStyles = {
  [CALCULATE_TYPE]: DEFAULT,
  [ANSWER_TYPE]: WIDE,
  [MULTIPLE_ANSWERS]: WIDE,
  [METHOD_TYPE]: WIDE,
  [SIMPLIFY]: WIDE,
  [SIMPLIFY_HIDDEN]: VERY_WIDE
};

export const calculate_type = [
  { id: 0, label: <Addition size={22} color="white"/>, value: PLUS },
  { id: 1, label: <Subtraction size={22} color="white" />, value: MINUS },
];

export const answer_type = [
  { id: 0, label: 'Bråkform', value: FRACTION },
  { id: 1, label: 'Blandat', value: MIXED },
  { id: 2, label: 'Procent', value: PERCENT },
  { id: 3, label: 'Decimal', value: DECIMAL },
];

export const multiple_answers = [
  { id: 0, label: 'Ett', value:  SINGLE },
  { id: 1, label: 'Flera', value: MULTIPLE },
];

export const method_type = [
  { id: 0, label: 'Bråk', value:  FRACTION_METHOD },
  { id: 1, label: 'Omvandla', value: CONVERT_METHOD },
  { id: 2, label: 'Bild', value: IMAGE_METHOD }
];

export const decimals_type = [
  { id: 0, label: '0', value: 0 },
  { id: 1, label: '1', value: 1 },
  { id: 2, label: '2', value: 2 },
  { id: 3, label: '3', value: 3 },
];

export const extensions_type = [
  { id: 0, label: 'Förläng', value:  EXTEND },
  { id: 1, label: 'Förkorta', value: SHORT },
];

export const extensions_hidden = [
  { id: 0, label: 'Visa mellansteget', value: true },
];

export const caluculateNumbers = {
  [PLUS]: (numbers) => numbers.reduce((a, b) => a + b),
  [MINUS]: (numbers) => numbers.reduce((a, b) => a - b),
  [EXTEND]: (a, b) =>  a * b,
  [SHORT]: (a, b) => a / b
}

const calculateTypeData = {
  title: `${translation[CALCULATE_TYPE]}:`,
  data: calculate_type,
  type: CALCULATE_TYPE
}

const answerTypeData = {
  title: `${translation[ANSWER_TYPE]}:`,
  data: answer_type,
  type: ANSWER_TYPE,
  style: SQUARE
}

const multipleAnswersData = {
  title: `${translation[MULTIPLE_ANSWERS]}:`,
  data: multiple_answers,
  type: MULTIPLE_ANSWERS
}

const methodTypeData = {
  title: `${translation[METHOD_TYPE]}:`,
  data: method_type,
  type: METHOD_TYPE,
  style: SQUARE
}

const decimalsTypeData = {
  title: `${translation[DECIMAL_COUNT]}:`,
  data: decimals_type,
  type: DECIMAL_COUNT,
  component: DROPDOWN
}

const extensionsTypeData = {
  title: `${translation[SIMPLIFY]}:`,
  data: extensions_type,
  type: SIMPLIFY
}

const extensionsHiddenData = {
  title: "",
  data: extensions_hidden,
  type: SIMPLIFY_HIDDEN
}

const extensionsInputTypeData = {
  title: "",
  type: SIMPLIFY_MULTIPLE,
  component: INPUT
}

export const settingsData = (settings) => {
  const { methodType } = settings
  let rows = []

  if(methodType === IMAGE_METHOD || methodType === CONVERT_METHOD) {
    rows = [
      {...answerTypeData},
      {...multipleAnswersData},
      {...methodTypeData},
      {...decimalsTypeData},
      {...extensionsTypeData},
      {...extensionsInputTypeData}
    ]
  } else {
    rows = [
      {...calculateTypeData},
      {...answerTypeData},
      {...multipleAnswersData},
      {...methodTypeData},
      {...decimalsTypeData},
      {...extensionsTypeData},
      {...extensionsHiddenData},
      {...extensionsInputTypeData}
    ]
  }

  return([{ title: translation[GOAL], rows }]);
}

export const warningText = {
  notWithinBorders: "Max antal tecken uppnådd",
  notNumeric: "Inmatat värde måste vara ett tal",
  valueIsNegative: "Talet får inte vara mindre än noll",
  hasDecimal: "Inga decimaler är tillåtna",
};

/**
 * Checks if the params has any type of errors
 * @param  {...any} params
 */
export const isErrorFree = (type, expressionPart, ...params) => {
  for(let i = 0; i < params.length; i++) {
    const value = params[i].value
    const key = params[i].key
    const notNumeric = !isNumeric(value)
    const valueIsNegative = value < 0
    const hasDecimal = value.toString().indexOf(".") !== -1
    const empty = value === ""
    const integerEmpty = !value && key === INTEGER
    const typeWithDecimals = (hasDecimal && !valueIsNegative) && (type === PERCENT || type === DECIMAL) && key === INTEGER
    const notWithinBorders = !valuesIswithinBorders(value, key) && expressionPart !== RESULT

    if(typeWithDecimals) continue
    if(integerEmpty) continue
    if(empty) return { condition: false, error: null }

    if(notWithinBorders) return { condition: false, error: warningText[Object.keys({ notWithinBorders })[0]] }
    if(notNumeric) return { condition: false, error: warningText[Object.keys({ notNumeric })[0]] }
    if(valueIsNegative) return { condition: false, error: warningText[Object.keys({ valueIsNegative })[0]] }
    if(hasDecimal) return { condition: false, error: warningText[Object.keys({ hasDecimal })[0]] }
  }
  return { condition: true, error: null }
}

/**
 * Add terms to grid
 * @param {*} termsDigits
 * @param {*} grid
 * @param {*} width
 */
const addTermsToGrid = (termsDigits, grid, width) => {
  if(termsDigits.length > 1) {
    const t = termsInfo(termsDigits)
    const diff = t.longLength - t.shortLength

    termsDigits.forEach((digits, row) => {
      const padding = (t.shortIndex === row && !t.equalLengths) ? width + diff : width
      grid[row + 1] = mergeArray(grid[row + 1], digits, padding)
    });
  } else {
    grid[2] = mergeArray(grid[2], termsDigits[0], width)
  }
  
  return grid;
};

/**
 * Grid height & width
 * @param {*} pad
 */
const getGridDimensions = (pad, type) => ({ height: 4, width: pad-1 + (type === PERCENT ? 1 : 0)})

/**
 * Empty cell
 */
const emptyCell = {
  id: "",
  hidden: false,
  value: "",
  interactive: false
}

/**
 * Returns an array with key/value pairs
 * @param {*} integer
 * @param {*} numerator
 * @param {*} denominator
 */
export const keyValueFractionObject = (integer, numerator, denominator) => ([
  {key: INTEGER, value: integer},
  {key: NUMERATOR, value: numerator}, 
  {key: DENOMINATOR, value: denominator}
])

/**
 * Return an item with values
 * @param {*} v1
 * @param {*} i1
 * @param {*} v2
 * @param {*} i2
 */
export const fractionObject = ({v1 = "", v2 = "", v3 = "", i1 = false, i2 = false, i3 = false}) => ({
  integer: {
    value: v1,
    interactive: i1
  },
  numerator: {
    value: v2,
    interactive: i2
  },
  denominator: {
    value: v3,
    interactive: i3
  }
})

/**
 * Creates empty grid
 * @param {*} w
 * @param {*} h
 */
const getEmptyGrid = (w, h) => Array(h).fill("").map(_ => Array(w).fill(emptyCell));

/**
 * Merges arrays.
 * @param {*} arr1
 * @param {*} arr2
 * @param {*} pad
 */
const mergeArray = (arr1, arr2, pad) => {
  const l = Math.max(arr1.length, arr2.length + pad);
  const r = [];
  
  for (let i = 0; i < l; i++) {
    if (i >= pad && arr2[i - pad] !== undefined) {
      r[i] = { ...arr1[i], value: arr2[i - pad] }
    } else {
      r[i] = arr1[i];
    }
  }

  return r;
};

/**
 * Returns false if the value exceed the limits for current type
 * @param {*} value
 * @param {*} type
 */
export const valuesIswithinBorders = (value, type) => {
  if(value === "") {
    return true
  }

  switch(type) {
    case INTEGER:
      return value.toString().length < 3
    case NUMERATOR:
    case DENOMINATOR:
      return value.toString().length < 5
    default:
      break;
  }
}

/**
 * Add operation symbol to grid
 * @param {*} grid
 * @param {*} pos1
 * @param {*} pos2
 * @param {*} symbol
 */
const addOperationSymbol = (grid, pos1, pos2, symbol) => {
  grid[pos1][pos2] = { ...grid[pos1][pos2], isVertical: true, value: symbol };
  return grid;
};

/**
 * Returns the operationsymbol for the selected type
 * @param {*} type
 */
const getOperationSymbol = (type) => {
  switch (type) {
    case PLUS:
      return "+";
    case MINUS:
      return "-";
    case EQUAL:
      return "=";
    case PERCENT:
      return "%"
    default:
      break;
  }
};

/**
 * Generate ids for grid.
 * @param items
 */
export const generateIDsForEachPosition = grid =>
  grid.map((column, index1) =>
    column.map((row, index2) => ({ ...row, id: `[${index1}, ${index2}]` }))
);

/**
 * Convert all values to string
 * @param {*} grid
 */
const convertValuesToString = grid =>
  grid.map(column =>
    column.map(row => ({ ...row, value: row.value.toString() }))
  );

/**
 * Split all terms into individual digits
 * @param {*} terms
 */
const getTermsDigits = (terms) => terms.map(t => ("" + t).split("").map(d => d === "." ? d : +d));

/**
 * Return information about the terms. e.g Which of the two terms is longenst/shortest
 * and on which index they belong to.
 * @param {*} termsDigits
 */
const termsInfo = (termsDigits) => {
  const equalLengths = termsDigits[0].length === termsDigits[1].length

  const term1 = Number(termsDigits[0].join(''))
  const term2 = Number(termsDigits[1].join(''))
  let long = {}
  let short = {}

  if(equalLengths) {
    long = term1 > term2
      ? {longLength: termsDigits[0].length, longIndex: 0}
      : {longLength: termsDigits[1].length, longIndex: 1}
    short = term1 < term2
      ? {shortLength: termsDigits[0].length, shortIndex: 0}
      : {shortLength: termsDigits[1].length, shortIndex: 1}
  } else {
    long = termsDigits[0].length > termsDigits[1].length
      ? {longLength: termsDigits[0].length, longIndex: 0}
      : {longLength: termsDigits[1].length, longIndex: 1}
    short = termsDigits[0].length < termsDigits[1].length
      ? {shortLength: termsDigits[0].length, shortIndex: 0}
      : {shortLength: termsDigits[1].length, shortIndex: 1}
  }

  return {...long, ...short, equalLengths }
}

/**
 * Updates the resultterms depending on the answertype
 * @param {*} calculatedIntegers
 * @param {*} calculatedNumerators
 * @param {*} commonMultiple
 * @param {*} answerType
 * @param {*} decimalCount
 */
export const updateResultTerms = (integers, numerators, commonMultiple, answerType, decimalCount, calculateType) => {

  const calculatedNumerators = caluculateNumbers[calculateType](numerators)
  const calculatedIntegers = caluculateNumbers[calculateType](integers) * commonMultiple

  let resultInteger;
  let resultNumerator;

  if (answerType === FRACTION) {
    resultInteger = 0
    resultNumerator = calculatedNumerators + calculatedIntegers
  }

  if (answerType === MIXED) {
    resultInteger = Math.floor((calculatedNumerators + calculatedIntegers)/commonMultiple)
    resultNumerator = (calculatedNumerators + calculatedIntegers) % commonMultiple
  }

  if (answerType === PERCENT) {
    resultInteger = +((caluculateNumbers[calculateType](integers) + (calculatedNumerators/commonMultiple)) * 100).toFixed(decimalCount)
    resultNumerator = 0
  }

  if (answerType === DECIMAL) {
    resultInteger = +(caluculateNumbers[calculateType](integers) + (calculatedNumerators/commonMultiple)).toFixed(decimalCount)
    resultNumerator = 0
  }

  return {resultInteger, resultNumerator}
}

/**
 * Builds and returns the lineup array depeding on settings
 * @param {*} settings
 * @param {*} resultPart
 * @param {*} simplifiedRequirement
 * @param {*} originalFactors
 * @param {*} calculatedFactors
 * @param {*} equalDenomitors
 */
const createLineupArray = (settings, resultPart, simplifiedRequirement, originalFactors, calculatedFactors, equalDenomitors, resultObj) => {
  const { methodType, simplify, simplifyMultiple, simplifyHidden } = settings

  const simplifiedResult = simplifyResult(resultPart, simplify, simplifyMultiple, resultObj)
  const originalResult = simplifiedRequirement ? {...resultPart, simplified: true} : resultPart

  const resultHidden = [
    ...calculatedFactors,
    simplifiedResult
  ]

  const result = [
    ...calculatedFactors,
    originalResult,
    simplifiedResult
  ]

  const standardResult = [
    ...calculatedFactors,
    originalResult
  ]

  const req = (!simplifyHidden || methodType === CONVERT_METHOD)

  if(equalDenomitors) {
    if(simplifiedRequirement) {
      return req ? resultHidden : result
    } return standardResult
  } else {
    if(simplifiedRequirement) {
      return req ? [...originalFactors, ...resultHidden] : [...originalFactors, ...result]
    } return [...originalFactors, ...standardResult]
  }
}

/**
 * Returns the calculated value
 * @param {*} num1
 * @param {*} num2
 * @param {*} type
 */
export const calculateResult = (items, resultObj, settings) => {
  const { calculateType, decimalCount, answerType, methodType } = settings
  const integers = items.map(item => +item.integer.value)
  const denominators = items.map(item => +item.denominator.value)
  const commonMultiple =  [...denominators].reduce(lcm)
  const equalDenomitors = denominators.every((val, i, arr) => arr[0] === val)

  const originalFactors = items.map(item =>
    fractionObject({v1: +item.integer.value, v2: +item.numerator.value, v3: +item.denominator.value})
  )

  const calculatedFactors = items.map(item => {
    const v2 = +item.numerator.value * (commonMultiple / item.denominator.value)
    return {
      ...fractionObject({v1: +item.integer.value, v2, v3: commonMultiple}), ghost: !equalDenomitors
    }
  })

  const numeratorValues = calculatedFactors.map(item => +item.numerator.value)
  const { resultInteger, resultNumerator } = updateResultTerms(integers, numeratorValues, commonMultiple, answerType, decimalCount, calculateType)
  const simplifiedRequirement = (answerType === FRACTION || answerType === MIXED) && (methodType !== IMAGE_METHOD)

  const resultPart = {
    ...fractionObject({
      v1: resultInteger,
      v2: resultNumerator,
      v3: commonMultiple,
      i1: simplifiedRequirement ? false : resultObj.integer.interactive,
      i2: simplifiedRequirement ? false : resultObj.numerator.interactive,
      i3: simplifiedRequirement ? false : resultObj.denominator.interactive
    })
  }

  return createLineupArray(settings, resultPart, simplifiedRequirement, originalFactors, calculatedFactors, equalDenomitors, resultObj)
}

/**
 * Simplifies the result by either extending it or shortens it
 * @param {*} result
 * @param {*} method
 * @param {*} number
 */
const simplifyResult = (result, method, number, resultObj) =>({
  denominator: { ...resultObj.denominator, value: caluculateNumbers[method](result.denominator.value, number)},
  integer: { ...resultObj.integer, value: caluculateNumbers[method](result.integer.value, number)},
  numerator: { ...resultObj.numerator, value: caluculateNumbers[method](result.numerator.value, number)}
})

/**
 * Greatest common divisor
 * @param {*} a
 * @param {*} b
 */
const gcd = (a, b) => a ? gcd(b % a, a) : b;

/**
 * Lowest common multiple
 * @param {*} a
 * @param {*} b
 */
const lcm = (a, b) => a * b / gcd(a, b);

/**
 * Add additional properties to the grid
 * @param {*} grid
 * @param {*} position
 * @param {*} termsDigits
 */
const addPropsToGrid = (grid, position, termsDigits, keys, answerType, finalPosition) => {
  if(keys.length > 1) {
    const t = termsInfo(termsDigits)
    const diff = t.longLength - t.shortLength

    // add division borders
    for(let i = 0; i < t.longLength; i++) {
      grid[1][position + i] = {...grid[1][position + i], borderBottom: true}
    }

    // center terms 
    if(!t.equalLengths) {
      for(let i = 0; i < t.shortLength; i++) {
        grid[1 + t.shortIndex][position + diff + i] = {...grid[1 + t.shortIndex][position + diff + i], isHorizontal: diff}
      }
    }
  } else {
    for(let i = 0; i < termsDigits[0].length; i++) {
      if(finalPosition) {
        // add decimal or percentage property
        const isPercent = { isPercent: answerType === PERCENT }
        const isDecimal = { isDecimal: answerType === DECIMAL }
        grid[2][position + i] = {...grid[2][position + i], isInteger: true, ...isPercent, ...isDecimal}
      } else {
        grid[2][position + i] = {...grid[2][position + i], isInteger: true}
      }
    }
  }

  return grid
}

/**
 * Add interactive props to the grid
 * @param {*} grid
 * @param {*} position
 * @param {*} term
 * @param {*} termsDigits
 */
const addInteractiveToGrid = (grid, position, term, termsDigits, keys) => {
  if(keys.length > 1) {
    const t = termsInfo(termsDigits)
    const diff = t.longLength - t.shortLength
    const numeratorLength = term.numerator.value.toString().length
    const denominatorLength = term.denominator.value.toString().length

    for(let i = 0; i < numeratorLength; i++) {
      const row = position + i + (t.shortIndex === 0 ? diff : 0)
      grid[1][row] = {...grid[1][row], interactive: term.numerator.interactive}
    }

    for(let i = 0; i < denominatorLength; i++) {
      const row = position + i  + (t.shortIndex === 1 ? diff : 0)
      grid[2][row] = {...grid[2][row], interactive: term.denominator.interactive}
    }
  } else {
    for(let i = 0; i < termsDigits[0].length; i++) {
      grid[2][position + i] = {...grid[2][position + i], interactive: term.integer.interactive}
    }    
  }

  return grid
}

/**
 * Returns the grid paddings and positions where data will be displayed.
 * @param {*} terms
 */
const calculatePadding = (terms) => {
  let pad = 0
  const positions = []
  for(let i = 0; i < terms.length; i++) {
    let keys = Object.keys(terms[i])
    let termsDigits = getTermsDigits(Object.values(terms[i]));
    if(keys.length > 1) {
      const t = termsInfo(termsDigits)
      positions.push(pad)
      pad += t.longLength + 1
    } else {
      positions.push(pad)
      pad += termsDigits[0].length + (i === terms.length-1 ? 1 : 0)
    }
  }
  return { positions, padding: pad}
}

/**
 * Separates integer property from object
 * @param {*} strippedTerms
 */
const separateIntegerFromTerms = (strippedTerms, answerType) => {
  let arr = []
  for(let i = 0; i < strippedTerms.length; i++) {
    const integer = strippedTerms[i].integer
    const lastTerm = i === strippedTerms.length - 1

    if(lastTerm) {
      if(typeof integer === 'object' && integer.value !== "") {
        if(answerType !== FRACTION) {
          arr.push({integer})
        }
      }
      if(typeof integer !== 'object' && integer !== "") {
        if(answerType !== FRACTION) {
          arr.push({integer})
        }
      } 
    } else {
      if(typeof integer === 'object' && integer.value) {
        arr.push({integer})
      }
      if(typeof integer !== 'object' && integer) {
        arr.push({integer})
      } 
    }
    delete strippedTerms[i].integer
    arr.push(strippedTerms[i])
  }

  return arr
}

/**
 * Removes the fraction part from object
 * @param {*} strippedTerms
 */
const stripFractionPartFromResult = (terms, answerType) => {
  if(answerType !== FRACTION) {
    const numerator = terms[terms.length-1].numerator
    const value = typeof numerator === 'object' ? numerator.value : numerator
    if(value === 0) terms.pop()
  }

  return terms
}

/**
 * Find on which positions the operation symbols should be placed.
 * @param {*} positions
 */
const findMissingPositions = (grid) => {
  let missingPositions = []

  for(let i = 0; i < grid[1].length; i++) {
    if(!isNumeric(grid[1][i].value) && !isNumeric(grid[2][i].value) && grid[2][i].value !== ".") {
      missingPositions.push(i)
    }
  }
  return missingPositions
}

/**
 * Generates a multidimensional array ( grid ) with complete data for diffrent calculation types.
 * @param  {...any} terms
 * @param type
 */
export const generateGridWithTerms = (type, terms, items, answerType, methodType, simplifyHidden) => {
  const strippedTerms = clone(terms).map(term => ({integer: term.integer.value, numerator: term.numerator.value, denominator: term.denominator.value}))
  const separatedStrippedTerms = stripFractionPartFromResult(separateIntegerFromTerms(clone(strippedTerms), answerType), answerType)
  const separatedTerms = stripFractionPartFromResult(separateIntegerFromTerms(clone(terms), answerType), answerType)

  const { positions, padding } = calculatePadding(separatedStrippedTerms)
  const { height, width } = getGridDimensions(padding, answerType)
  const lastIndex = terms.length - 2

  let grid = getEmptyGrid(width, height);

  for(let i = 0; i < positions.length; i++) {
    const keys = Object.keys(separatedStrippedTerms[i])
    let termsDigits = getTermsDigits(Object.values(separatedStrippedTerms[i]));
    const finalPosition = i === positions.length - 1

    grid = addTermsToGrid(termsDigits, grid, positions[i])
    grid = addPropsToGrid(grid, positions[i], termsDigits, keys, answerType, finalPosition)
    grid = addInteractiveToGrid(grid, positions[i], separatedTerms[i], termsDigits, keys)
  }

  const operationPositions = findMissingPositions(grid)

  const simplifiedRequirement = (answerType === FRACTION || answerType === MIXED) && (methodType !== IMAGE_METHOD) && simplifyHidden
  const diff = lastIndex - (simplifiedRequirement ? items.length + 1 : items.length)

  for(let i = 0; i < operationPositions.length; i++) {
    if(i === diff || i === lastIndex || (simplifiedRequirement && i === lastIndex - 1)) {
      grid = addOperationSymbol(grid, grid.length - 2, operationPositions[i], getOperationSymbol(EQUAL));
    } else if(i === operationPositions.length - 1) {
      grid = addOperationSymbol(grid, grid.length - 2, operationPositions[i], getOperationSymbol(answerType));
    } else {
      grid = addOperationSymbol(grid, grid.length - 2, operationPositions[i], getOperationSymbol(type));
    }
  }

  grid = convertValuesToString(grid);
  grid = generateIDsForEachPosition(grid);

  return grid;
};