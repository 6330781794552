import React, { PureComponent } from "react";
import connect from "react-redux/es/connect/connect";
import { NodeCancelButton } from "./NodeCancelBtn";
import { fromJS } from "immutable";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import NodeEdit from "./NodeEdit";
import { addNodeUnderParent, removeNodeAtPath } from "react-sortable-tree";
import { CancelButton, PrimaryButton } from "../../../shared";
import {
  redoTreeChangeAction,
  removeNodeAction,
  resetTreeDraft,
  saveStructure,
  publishStructure,
  setActiveNode,
  undoTreeChangeAction,
  updateCategory,
  updateTreeDraftAction
} from "../../api/actions";
import {
  PanelSection,
  PanelWrapper,
  StyledButtonsSpacer,
  StyledButtonsSpacerLine,
  StyledIsGoalIconWrapper,
  StyledIsPublishedIconWrapper,
  StyledOverlay,
  StyledPanelButtonsWrapper,
  StyledPanelWrapper,
  StyledSpacer,
  StyledTreeContainer,
  StyledTreeModBar,
  StyledTreeModBarCol,
  StyledTreeSearchBox,
  Tree,
  StyledNodeStandardBtn
} from "./StyledStructureTree";
import {
  RotateCcw,
  RotateCw,
  Minimize,
  Maximize,
  ArrowLeft,
  ArrowRight
} from "react-feather";
import { NodeStandardBtn } from "./NodeStandardBtn";
import { TreeExpansionOptBtn } from "./TreeExpansionOptBtn";
import Input from "../../../plugins/components/Input";
import {
  StyledReplicationButtons,
  StyledUndoButton
} from "../../components/ReplicationButtons/StyledReplicationButtons";
import * as StudliIcons from "../../../shared/components/StudliIcons";
import RemoveModal from "./RemoveModal";
import HasPermission from "../../../shared/Permissions";
import { PUBLISH, STRUCTURE, EDIT, SAVE } from "../../../constants";

class StructureTree extends PureComponent {
  /**
   * Searches a node based on given key for a match with a fraze, returns true if match occurs
   * @param key
   * @param searchQuery
   * @param node
   * @param path
   * @param treeIndex
   * @returns {*}
   */
  static stringSearch(key, searchQuery, node, path, treeIndex) {
    if (searchQuery.length < 1) {
      return false;
    }

    if (typeof node[key] === "function") {
      return (
        String(node[key]({ node, path, treeIndex }))
          .toLowerCase()
          .indexOf(searchQuery.toLowerCase()) > -1
      );
    }

    return (
      node[key] &&
      String(node[key])
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      editNode: false,
      showNodeDetails: false,
      searchFocusIndex: 0,
      searchFoundCount: null,
      removeModalActive: false,
      removeData: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fetching) {
      this.props.isFetchingData(false);
    }
  }

  /**
   * Convert the tree from ImmutableJS list to javascript array
   * @returns {any | * | Object}
   */
  convertTreeToJS = () => this.props.treeDraft.get("tree").toJS();

  /**
   * Converts the tree from javascript array to ImmutableJS list
   * @returns {any}
   */
  convertTreeFromJS = () => fromJS(this.props.treeDraft.get("tree"));
  /**
   * Activates the edit mode for given node
   * @param node
   * @param path
   */

  activateEditNode = nodeData => {
    this.props.setActiveNodeEdit(nodeData);
    this.setState({ showNodeDetails: true, editNode: this.state.editable });
  };

  /**
   * Returns true if at least one of the keys in the arrays matches
   * @param path
   * @param treeIndex
   * @param node
   * @param searchQuery
   * @returns {number}
   */
  searchMethod = ({ path, treeIndex, node, searchQuery }) =>
    ["title", "subtitle", "id"].filter(key =>
      StructureTree.stringSearch(key, searchQuery, node, path, treeIndex)
    ).length;

  /**
   * This might seem super stupid to whom ever comes after me. However there is a reason for this. The tree requires
   * an on change function to work. But since no indication is given on which event that triggered the event, this
   * onChange callback leaves itself useless. Since the collapsing of the trees should not be part of the undo/redo
   * history we need that information however. So we instead use the event specific callbacks. Which makes the onChange
   * a weird thing to then have as required..
   */
  dummyFunc = () => {};

  /**
   * Passes updated treeData to redux
   * @param treeData
   * @returns {*}
   */
  onMoveNode = ({ treeData }) => this.props.updateTreeDraft(treeData);

  /**
   * passes updated treeData to redux and bypass undo/redo history
   * @param treeData
   * @returns {*}
   */
  onVisibilityToggle = ({ treeData }) =>
    this.props.updateTreeDraft(treeData, true);

  /**
   * Updates search result data
   * @param matches
   */
  searchFinishCallback = matches => {
    const { searchFocusIndex } = this.state;

    this.setState({
      searchFoundCount: matches.length,
      searchFocusIndex:
        matches.length > 0 ? searchFocusIndex % matches.length : 0
    });
  };

  /**
   * Selects previous match in search result
   */
  selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    });
  };

  /**
   * Select next match in search result
   */
  selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    });
  };

  /**
   * Reset the changes
   */
  resetToInitialState = () => {
    this.props.resetTreeDraft();
    this.setState({ editable: false });
  };

  /**
   * User saves node
   * @param data
   * @param path
   * @returns {*}
   * @private
   */
  _onNodeSave = (data, path) => {
    const { id: permId, tempId, ...rest } = data;

    const id = permId ? permId : tempId;

    this.setState({ showNodeDetails: false });
    this.props.onUpdateCategory(id, rest, path);
  };

  /**
   * Users cancels edit
   * @private
   */
  _onCancel = () => this.setState({ showNodeDetails: false });

  /**
   * returns node key
   * @param node
   * @param tempId
   * @param id
   * @returns {null}
   */
  getNodeKey = ({ node, node: { tempId, id } }) =>
    node !== null ? (tempId ? tempId : id) : null;

  /**
   * Returns array of buttons
   * @param node
   * @param path
   * @returns {*[]}
   */
  nodePropsButtons = (node, path) => [
    this.renderAddNodeBtn(node, path),
    this.renderRemoveNodeBtn(node, path),
    this.renderEditNodeBtn(node, path),
    this.renderToggleIsGoalIndicator(node, path),
    this.renderToggleIsTableIndicator(node, path),
    this.renderisPublishedIndicator(node, path)
  ];

  /**
   * Add an id to list for removals
   * @param id
   * @param path
   * @returns {*}
   */
  removeNodeFromTree = ({ id, path }) => {
    this.props.addIdForRemoval(id, this.removeNodeFromTreeData(path));
    this.toggleRemoveCategoryModal();
  };

  /**
   * creates a new tree without node at given path
   * @param path
   * @returns {*|Object[]}
   */
  removeNodeFromTreeData = path =>
    removeNodeAtPath({
      treeData: this.props.treeDraft,
      path,
      getNodeKey: this.getNodeKey
    });

  /**
   * Publish tree
   */
  publishTree = () => {
    this.props.onPublish();
  };

  /**
   * Saves the tree changes
   */
  saveTree = () => {
    this.toggleEditable();
    this.props.isFetchingData(true);
    this.props.onSave(this.props.treeDraft);
  };

  /**
   * Flips the editable state
   */
  toggleEditable = () => this.setState({ editable: !this.state.editable });

  /**
   * collapse or expand entire tree based on boolean
   * @param expanded
   */
  collapseTree = expanded => {
    const { treeDraft, updateTreeDraft } = this.props;
    const recursiveTreeCollapse = ({ children: c, ...rest }) => ({
      children: c ? c.map(recursiveTreeCollapse) : [],
      ...rest,
      expanded
    });

    updateTreeDraft(treeDraft.map(recursiveTreeCollapse), true);
  };

  /**
   * Generate node properties
   * @param node
   * @param path
   * @returns {{className: string, buttons: *[], onClick: function()}}
   */
  generateNodeProps = ({ node, path }) => ({
    className: "category-node",
    buttons: this.nodePropsButtons(node, path)
  });

  /**
   * Fetches parent node key
   * @param parentPath
   * @returns {null}
   */
  getParentNodeKey = parentPath =>
    parentPath === null ? null : parentPath[parentPath.length - 1];

  /**
   * Adds a node to given path
   * @param parentNode
   * @param parentPath
   * @returns {function()}
   */
  addNodeToPath = (parentNode, parentPath) => () => {
    const newTitle = window.prompt("Lägg till en ny kategori.\nTitel:");

    if (newTitle) {
      let parentKey = this.getParentNodeKey(parentPath);
      this.props.updateTreeDraft(
        addNodeUnderParent({
          treeData: this.props.treeDraft,
          parentKey: parentKey,
          expandParent: true,
          getNodeKey: this.getNodeKey,
          newNode: {
            parent_id: parentKey ? parentKey : "0",
            tempId: "new-" + Date.now(), // Give the new node a temporary unique id
            title: newTitle
          }
        }).treeData
      );
    }
  };

  /**
   * If we are fetching data, we show that to the user.
   * @returns {*}
   */
  showFetchingData = () => <span>Fetching...</span>;

  /**
   * Opens and closes remove category modal
   * @param data
   */
  toggleRemoveCategoryModal = (data = false) =>
    this.setState({
      removeModalActive: !this.state.removeModalActive,
      removeData: data ? data : {}
    });

  render() {
    return this.props.fetching ? (
      this.showFetchingData()
    ) : (
      <PanelWrapper>
        {this.renderRemoveCategoryModal()}

        <StyledPanelButtonsWrapper>
          {this.renderButtons()}
          {this.renderTreeModBar()}
        </StyledPanelButtonsWrapper>

        <StyledPanelWrapper>
          <PanelSection side={"left"}>
            <StyledTreeContainer>
              {this.renderTree()}
              {this.renderTreeOverlay()}
            </StyledTreeContainer>
          </PanelSection>

          <PanelSection
            side={"right"}
            display={this.state.showNodeDetails ? 1 : 0}
          >
            {this.renderNodeEdit()}
          </PanelSection>
        </StyledPanelWrapper>
      </PanelWrapper>
    );
  }

  /**
   * Renders category modal
   * @returns {*}
   */
  renderRemoveCategoryModal = () => (
    <RemoveModal
      data={this.state.removeData}
      isActive={this.state.removeModalActive}
      acceptCallback={this.removeNodeFromTree}
      cancelCallback={this.toggleRemoveCategoryModal}
    />
  );

  /**
   * Render add node btn
   * @param node
   * @param path
   * @returns {null}
   */
  renderAddNodeBtn = (node, path) =>
    this.state.editable ? (
      <NodeStandardBtn
        studlicon={"SquarePlus"}
        transparent={true}
        callback={this.addNodeToPath(node, path)}
      />
    ) : null;

  /**
   * Render remove node button
   * @param node
   * @param path
   * @returns {null}
   */
  renderRemoveNodeBtn = (node, path) =>
    this.state.editable ? (
      <NodeCancelButton
        studlicon={"Trash"}
        nodeData={{ id: node.id, path, title: node.title }}
        callback={this.toggleRemoveCategoryModal}
      />
    ) : null;

  /**
   * Render remove node button
   * @param node
   * @param path
   * @returns {null}
   */
  renderEditNodeBtn = (node, path) =>
    this.state.editable ? (
      <NodeCancelButton
        studlicon="Pencil"
        transparent={true}
        nodeData={{ node, path }}
        callback={this.activateEditNode}
      />
    ) : null;

  jumpToGoalList = (node, path) => this.props.setIdForGoalList(node.node);

  /**
   * Renders a non-clickable goal indicator icon. Rendered when state.editable is true.
   * @returns {*}
   */
  renderStaticTableIndicatorIcon = () => {
    const Icon = StudliIcons["Table"];
    return <Icon size={20} />;
  };

  /**
   * Renders a non-clickable goal indicator icon. Rendered when state.editable is true.
   * @returns {*}
   */
  renderStaticGoalIndicatorIcon = () => {
    const Icon = StudliIcons["BullseyeArrow"];
    return <Icon size={20} />;
  };

  /**
   * Render a clickable goal incator icon. Rendered when state.editable is false.
   *
   * onClick: Jumps to goal list.
   *
   * @param node
   * @param path
   * @returns {*}
   */
  renderClickableGoalIndicatorIcon = (node, path) => (
    <NodeCancelButton
      studlicon="BullseyeArrow"
      transparent={true}
      nodeData={{ node, path }}
      callback={this.jumpToGoalList}
    />
  );

  /**
   * Toggles between a clickable and a non-clickable goal indicator icon.
   *
   * @param node
   * @param path
   * @returns {null}
   */
  renderToggleIsTableIndicator = (node, path) =>
    node.is_table ? (
      <StyledIsGoalIconWrapper>
        <StyledSpacer />
        {this.renderStaticTableIndicatorIcon()}
      </StyledIsGoalIconWrapper>
    ) : null;

  /**
   * Toggles between a clickable and a non-clickable goal indicator icon.
   *
   * @param node
   * @param path
   * @returns {null}
   */
  renderToggleIsGoalIndicator = (node, path) =>
    node.is_goal ? (
      <StyledIsGoalIconWrapper>
        <StyledSpacer />
        {this.state.editable
          ? this.renderStaticGoalIndicatorIcon()
          : this.renderClickableGoalIndicatorIcon(node, path)}
      </StyledIsGoalIconWrapper>
    ) : null;

  /**
   * Renders indicator for is_published
   *
   * @param node
   * @param path
   * @returns {null}
   */

  renderisPublishedIndicator = (node, path) =>
    node.is_published ? (
      <StyledIsPublishedIconWrapper>
        <StyledSpacer />
        <NodeCancelButton
          studlicon="Cloud"
          transparent={true}
          nodeData={{ node, path }}
          disabled={true}
        />
      </StyledIsPublishedIconWrapper>
    ) : null;

  /**
   * Render tree
   * @returns {*}
   */
  renderTree = () => (
    <Tree
      treeData={this.props.treeDraft}
      getNodeKey={this.getNodeKey}
      canDrag={this.state.editable}
      onChange={this.dummyFunc}
      onMoveNode={this.onMoveNode}
      generateNodeProps={this.generateNodeProps}
      searchQuery={this.props.searchString}
      searchMethod={this.searchMethod}
      searchFocusOffset={this.state.searchFocusIndex}
      searchFinishCallback={this.searchFinishCallback}
      onVisibilityToggle={this.onVisibilityToggle}
    />
  );

  /**
   * Render search box for searching in tree as well as buttons to sort through result
   * @returns {*}
   */
  renderTreeSearchBox = () => (
    <StyledTreeSearchBox>
      <StyledNodeStandardBtn transparent onClick={this.selectPrevMatch}>
        <ArrowLeft size={14} />
      </StyledNodeStandardBtn>
      <StyledNodeStandardBtn transparent onClick={this.selectNextMatch}>
        <ArrowRight size={14} />
      </StyledNodeStandardBtn>

      <Input
        placeholder="Sök efter nod.."
        onChange={this.props.setSearchString}
        onEnter={this.selectNextMatch}
      />
    </StyledTreeSearchBox>
  );

  /**
   * Render tree modification bar
   * @returns {*}
   */
  renderTreeModBar = () => (
    <StyledTreeModBar>
      <StyledTreeModBarCol>{this.renderTreeSearchBox()}</StyledTreeModBarCol>

      <StyledTreeModBarCol>{this.renderDimensionButtons()}</StyledTreeModBarCol>
    </StyledTreeModBar>
  );

  /**
   * Render buttons for maximizing or minimizing trees
   * @returns {*}
   */
  renderDimensionButtons = () => (
    <div>
      <TreeExpansionOptBtn
        callback={this.collapseTree}
        expandOpt={false}
        transparent
        icon={<Minimize size={14} />}
      />
      <TreeExpansionOptBtn
        callback={this.collapseTree}
        expandOpt={true}
        transparent
        icon={<Maximize size={14} />}
      />
    </div>
  );

  /**
   * Renders publish button
   */
  renderPublishButton = () => (
    <PrimaryButton
      visible={this.state.editable ? 0 : 1}
      onClick={this.publishTree}
      disabled={this.props.publishing}
    >
      Publicera
    </PrimaryButton>
  );

  /**
   * Renders edit button
   */
  renderEditButton = () => (
    <PrimaryButton
      visible={this.state.editable ? 0 : 1}
      onClick={this.toggleEditable}
    >
      Redigera
    </PrimaryButton>
  );

  /**
   * Renders save button
   */
  renderSaveButton = () => (
    <PrimaryButton
      visible={this.state.editable ? 1 : 0}
      onClick={this.saveTree}
    >
      Spara
    </PrimaryButton>
  );

  /**
   * Render buttons
   * @returns {*}
   */
  renderButtons = () => (
    <ButtonGroup>
      <HasPermission
        component={this.renderEditButton()}
        section={STRUCTURE}
        permission={EDIT}
      />
      <HasPermission
        component={this.renderSaveButton()}
        section={STRUCTURE}
        permission={SAVE}
      />
      <HasPermission
        component={this.renderPublishButton()}
        section={STRUCTURE}
        permission={PUBLISH}
      />

      <CancelButton
        visible={this.state.editable ? 1 : 0}
        onClick={this.resetToInitialState}
      >
        Avbryt
      </CancelButton>

      {this.renderButtonsSpacer()}
      {this.renderNewRootNodeBtn()}

      {this.renderButtonsSpacer()}
      {this.renderUndoRedoButtons()}
    </ButtonGroup>
  );

  /**
   * Render Button for creating new root node
   * @returns {null}
   */
  renderNewRootNodeBtn = () =>
    this.state.editable ? (
      <NodeStandardBtn
        studlicon={"SquarePlus"}
        transparent={true}
        callback={this.addNodeToPath(null, null)}
      />
    ) : null;

  /**
   * Renders a buttons spacer line for give a bit of air between buttons
   * @returns {*}
   */
  renderButtonsSpacer = () =>
    this.state.editable ? (
      <StyledButtonsSpacer>
        <StyledButtonsSpacerLine />
      </StyledButtonsSpacer>
    ) : null;

  /**
   * Renders undo and redo buttons
   * @returns {*}
   */
  renderUndoRedoButtons = () => {
    const {
      undoTreeAction,
      redoTreeAction,
      replicationStatus: { hasUndo, hasRedo }
    } = this.props;
    const { editable } = this.state;

    return editable ? (
      <StyledReplicationButtons>
        <StyledUndoButton onClick={undoTreeAction} disabled={!hasUndo}>
          <RotateCcw size={14} />
        </StyledUndoButton>

        <StyledUndoButton onClick={redoTreeAction} disabled={!hasRedo}>
          <RotateCw size={14} />
        </StyledUndoButton>
      </StyledReplicationButtons>
    ) : null;
  };

  /**
   * Render overlay over tree when it is being edited
   * @returns {*}
   */
  renderTreeOverlay = () => (
    <StyledOverlay
      onClick={this._onCancel}
      isActive={this.state.editable && this.state.showNodeDetails}
    />
  );

  /**
   * Render NodeEdit
   * @returns {*}
   */
  renderNodeEdit = () =>
    this.state.showNodeDetails ? (
      <NodeEdit
        onSave={this._onNodeSave}
        onCancel={this._onCancel}
        disabled={!this.state.editable}
      />
    ) : null;
}

const mapStateToProps = state => {
  return {
    categories: state.Structure.get("list"),
    treeData: state.Structure.get("list").toJS(),
    fetching: state.Structure.get("fetching"),
    entity: state.Product.get("selectedItem"),
    treeDraft: state.Structure.getIn(["treeDraft", "tree"]).toJS(),
    replicationStatus: {
      hasUndo: state.Structure.getIn(["replication", "undo"]).size > 0,
      hasRedo: state.Structure.getIn(["replication", "redo"]).size > 0
    },
    publishing: state.Structure.get("publishing")
  };
};

const mapDispatchToProps = dispatch => ({
  onSave: categories => dispatch(saveStructure({ categories })),
  onPublish: () => dispatch(publishStructure()),
  onUpdateCategory: (id, data, path) =>
    dispatch(updateCategory(id, data, path)),
  setActiveNodeEdit: data => dispatch(setActiveNode(data)),
  resetTreeDraft: () => dispatch(resetTreeDraft()),
  addIdForRemoval: (id, tree) => dispatch(removeNodeAction(id, tree)),
  updateTreeDraft: (tree, bypassReplication) =>
    dispatch(updateTreeDraftAction(tree, bypassReplication)),
  undoTreeAction: () => dispatch(undoTreeChangeAction()),
  redoTreeAction: () => dispatch(redoTreeChangeAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(StructureTree);
