import styled from "styled-components"

export const StyledInputWrapper = styled.div`
  width: 23rem;
  input {
    font-size: 14px;
    width: 100%;
  }
`

export const StyledSearchResult = styled.ul`
  border: 1px solid;
  border-radius: 5px;
  position: absolute;
  width: 23rem;
  z-index: 5;
  background-color: white;
  border-style: outset;
  max-height: 25rem;
  overflow-y: scroll;
  padding-left: 0;
  margin-top: 3px;
`

export const StyledSearchResultItem = styled.li`
  cursor: pointer;
  padding: 0.3rem 0.7rem;
  list-style-type: none;
  font-weight: 500;
  margin: 0;
  &:hover, &.active {
    background-color: ${props => props.theme.neutralQuaternary}
  }
`