import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_EXERCISES, ROUTE_DETAILED_EXERCISE } from "../routes";
import {
  fetchEntitiesSaga,
  fetchEntityDetailsSaga,
  fetchFiltersSaga,
  setCategoryToPostsSaga,
  publishPostsSaga,
  unpublishPostsSaga,
  addRemoveTagsToPostSaga,
  deletePostsSaga,
  savePostsSaga,
  fetchCommentsSaga,
  addCommentToPost,
  duplicatePostSaga,
  requestReviewSaga,
  reviewPostSaga,
  rejectPostSaga,
  fetchKeyboardsSaga,
  fetchAllChipsForEntitySaga,
  addRemoveChipsToPostSaga
} from "../api/saga";

import {
  POST_SET_CATEGORY,
  PUBLISH_POSTS,
  UNPUBLISH_POSTS,
  DELETE_POSTS,
  DUPLICATE_POST,
  SAVE_ENTITY_DETAILS,
  CREATE_ENTITY_DETAILS,
  SAVE_POST,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  RELOAD_POST,
  RELOAD_POSTS,
  SORT_POSTS,
  FILTER_POSTS,
  ADD_COMMENT,
  FETCH_FILTER,
  POST_ADD_REMOVE_TAGS,
  REQUEST_REVIEW,
  REVIEW_POST,
  REJECT_POST,
  FETCH_CHIPS,
  POST_ADD_REMOVE_CHIPS
} from "../store/actions";
import { onFetchStructure } from "../../structure/sagas/sagas";
import { onMediaFilterRoute } from "../../media/sagas/sagas";
import {
  onListRoute,
  onDetailedRoute,
  sortList,
  onFilterRoute,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onLoadComments,
  onLoadKeyboards,
  onFetchChips
} from "./sagas";
import {
  FETCH_ENTITIES,
  FETCH_ENTITY_COMMENTS,
  FETCH_ENTITY_DETAILS,
  FETCH_KEYBOARD_LIST
} from "../../api";
import { actionCreator } from "../../shared";
import {
  ENTITIES,
  EXERCISE,
  MEDIA_RESOURCE,
  RELOAD_DETAILED_EXERCISE
} from "../../constants";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(EXERCISE, FETCH_ENTITIES),
    fetchEntitiesSaga(ENTITIES[EXERCISE], "listExercises")
  );
  yield takeLatest(
    actionCreator(EXERCISE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[EXERCISE], "fetchExercise")
  );
  yield takeEvery(
    actionCreator(EXERCISE, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[EXERCISE], "saveExercise")
  );
  yield takeEvery(
    actionCreator(EXERCISE, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[EXERCISE], "createExercise")
  );

  yield takeEvery(
    actionCreator(EXERCISE, SAVE_POST),
    savePostsSaga(ENTITIES[EXERCISE], "savePost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeEvery(
    actionCreator(EXERCISE, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeEvery(
    actionCreator(EXERCISE, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeEvery(
    actionCreator(EXERCISE, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeEvery(
    actionCreator(EXERCISE, RELOAD_POST),
    onDetailedRoute,
    ENTITIES[EXERCISE],
    "fetchExercise"
  );
  yield takeEvery(
    actionCreator(EXERCISE, SORT_POSTS),
    sortList,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeLatest(
    actionCreator(EXERCISE, FILTER_POSTS),
    filterPosts,
    ENTITIES[EXERCISE],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(EXERCISE, ADD_COMMENT),
    addCommentToPost(ENTITIES[EXERCISE], "addCommentToPost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, FETCH_FILTER),
    fetchFiltersSaga(ENTITIES[EXERCISE], "loadFilters")
  );
  yield takeEvery(
    actionCreator(EXERCISE, FETCH_ENTITY_COMMENTS),
    fetchCommentsSaga(ENTITIES[EXERCISE], "fetchCommentsForPost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, FETCH_KEYBOARD_LIST),
    fetchKeyboardsSaga(ENTITIES[EXERCISE], "fetchKeyboardListForPost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, POST_ADD_REMOVE_TAGS),
    addRemoveTagsToPostSaga(ENTITIES[EXERCISE], "addRemoveTagsToPost")
  );
  yield takeEvery(
    POST_SET_CATEGORY,
    setCategoryToPostsSaga(ENTITIES[EXERCISE], "setCategoryToPosts")
  );
  yield takeEvery(
    actionCreator(EXERCISE, PUBLISH_POSTS),
    publishPostsSaga(ENTITIES[EXERCISE], "publishPosts")
  );
  yield takeEvery(
    actionCreator(EXERCISE, REQUEST_REVIEW),
    requestReviewSaga(ENTITIES[EXERCISE], "requestReview")
  );
  yield takeEvery(
    actionCreator(EXERCISE, REVIEW_POST),
    reviewPostSaga(ENTITIES[EXERCISE], "reviewPost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, REJECT_POST),
    rejectPostSaga(ENTITIES[EXERCISE], "rejectPost")
  );
  yield takeEvery(
    actionCreator(EXERCISE, UNPUBLISH_POSTS),
    unpublishPostsSaga(ENTITIES[EXERCISE], "unpublishPosts")
  );
  yield takeEvery(
    actionCreator(EXERCISE, DELETE_POSTS),
    deletePostsSaga(ENTITIES[EXERCISE], "deletePosts")
  );
  yield takeEvery(DUPLICATE_POST, duplicatePostSaga("duplicatePost"));

  yield takeEvery(
    actionCreator(EXERCISE, FETCH_CHIPS),
    fetchAllChipsForEntitySaga(ENTITIES[EXERCISE], "fetchAllChipsForEntity")
  );
  yield takeEvery(
    actionCreator(EXERCISE, POST_ADD_REMOVE_CHIPS),
    addRemoveChipsToPostSaga(ENTITIES[EXERCISE], "addRemoveChipsToPost")
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_EXERCISES,
    onListRoute,
    ENTITIES[EXERCISE],
    "listExercises"
  );
  yield takeEvery(
    ROUTE_EXERCISES,
    onFilterRoute,
    ENTITIES[EXERCISE],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_EXERCISES,
    onFetchStructure,
    "Structure",
    "fetchStructure"
  );
  yield takeEvery(
    ROUTE_EXERCISES,
    onFetchChips,
    ENTITIES[EXERCISE],
    "fetchAllChips"
  );
  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onDetailedRoute,
    ENTITIES[EXERCISE],
    "fetchExercise"
  );
  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onLoadComments,
    ENTITIES[EXERCISE],
    "loadComments"
  );
  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onLoadKeyboards,
    ENTITIES[EXERCISE],
    "loadKeyboards"
  );

  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );

  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );

  yield takeEvery(
    RELOAD_DETAILED_EXERCISE,
    onFilterRoute,
    ENTITIES[EXERCISE],
    "loadFilters"
  );
  yield takeEvery(
    RELOAD_DETAILED_EXERCISE,
    onFetchStructure,
    "Structure",
    "fetchStructure"
  );
  yield takeEvery(
    RELOAD_DETAILED_EXERCISE,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(
    RELOAD_DETAILED_EXERCISE,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_DETAILED_EXERCISE,
    onFetchChips,
    ENTITIES[EXERCISE],
    "fetchAllChips"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
