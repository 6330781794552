import { default as Text } from "./components/Text";
import { AUDIO, TEXT } from "../constants";
import { getMediaObject } from "./dataModels";

const data = {
  text: "",
  [AUDIO]: getMediaObject(AUDIO)
};

const settings = {
  readOnly: false,
  multiLine: true,
  minimizeSpace: false
};

export const createTextPlugin = () => ({
  entityRendererFn: entity => {
    const types = [TEXT];
    if (types.includes(entity.type)) {
      return {
        component: Text,
        style: { padding: 0 },
        props: {
          data: entity.data,
          settings: { ...settings, ...entity.settings }
        }
      };
    }

    return null;
  }
});

export default createTextPlugin;

export const emptyBlocks = {
  [TEXT]: {
    type: TEXT,
    data,
    settings
  }
};
