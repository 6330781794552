import React from "react";
import styled from "styled-components";
import MessageEditor from "../../containers/MessageEditor";
import { ENTITIES, OPMESSAGE } from "../../../constants";

const StyledMessageWrapper = styled.div`
  padding: 1.5rem;
`;

const OpMessageDetails = () => {
  return (
    <StyledMessageWrapper>
      <MessageEditor type={ENTITIES[OPMESSAGE]} />
    </StyledMessageWrapper>
  );
};

export default OpMessageDetails;
