import React from "react";
import styled from "styled-components";
import { Image } from "react-feather";

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 200px;
  width: 150px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  border-radius: 4px;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ error }) => error && "border-color: #B00020;"}
`;

const NoImage = () => (
  <Wrapper>
    <Image width="200px" height="200px" />
  </Wrapper>
);

export default NoImage;
