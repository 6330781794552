import React from "react";
import styled from "styled-components";
import Checkbox from "../Checkbox";
// import {RadioStateless} from "../Radio";
// import Tag from "../Tag";
// import Link from "../Link";

const ChoiceGroup = styled.fieldset`
  border: 0;
  padding: 0 5px;
`;

const ChoiceGroupChoices = styled.ul`
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  line-height: 24px;
`;

const ChoiceItem = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
`;

const StyledLink = styled.a`
  margin-top: 10px;
  cursor: pointer;
  ${props => props.theme.d100()};
  color: ${props => props.theme.blue} !important;
`;

const ChoiceGroupStateless = ({
  multi,
  collapsible,
  expanded,
  title,
  choices,
  onToggle,
  onSelect,
  selected,
  range
}) => {
  const collapsibleToggleMarkup = collapsible && choices.length > 5? (
    <StyledLink onClick={onToggle}>
      {expanded ? "Visa mindre" : "Visa mer"}
    </StyledLink>
  ) : null;

  const isChecked = value => {
    return selected.indexOf(value) > -1;
  };
  const checkChoicesLength = choices =>{
    if(choices.length <= 5) return choices
    if(choices.length > 5) return choices.slice(0, range);
  }
  if (collapsible && !expanded) {
    choices = checkChoicesLength(choices);    
  }

  return (
    <ChoiceGroup>
      <ChoiceGroupChoices>
        {choices.map((choice, index) => (
          <ChoiceGroupItem
            key={`${choice.id}_${index}`}
            choice={choice}
            checked={isChecked(choice.id)}
            onClick={onSelect}
            multi={multi}
          />
        ))}
      </ChoiceGroupChoices>
      {collapsibleToggleMarkup}
    </ChoiceGroup>
  );
};

ChoiceGroupStateless.defaultProps = {
  onToggle: () => {},
  onSelect: () => {},
  choices: [],
  isExpanded: false,
  selected: [],
  range: 4
};

const ChoiceGroupItem = ({ choice, onClick, checked, disabled, multi }) => {
  const _onClick = () => onClick(choice.id);

  const input = multi ? (
    <Checkbox
      id={choice.id}
      name={`${choice.id}`}
      label={choice.title}
      value={choice.id}
      isChecked={checked}
      isDisabled={disabled}
    />
  ) : (
    <Checkbox
      id={choice.id}
      name={`${choice.id}`}
      label={choice.title}
      value={choice.value}
      isChecked={checked}
      isDisabled={disabled}
    />
  );

  return <ChoiceItem onClick={_onClick}>{input}</ChoiceItem>;
};

export default ChoiceGroupStateless;
