import {
  CASE_SENSITIVE,
  FONT_SIZE,
  INPUT_TYPE,
  M,
  OPERATIVE,
  PUNCTUATION_MARKS_SENSITIVE,
  SPACE_SENSITIVE,
  NUMERIC,
  AUDIO
} from "../../../constants";
import { getMediaObject } from "../../dataModels";

/**
 * Generates default blanks data object
 * @returns {{text: string, inputs: Array, [AUDIO]}}
 */
export const defaultBlanksData = () => ({
  text: "",
  inputs: [],
  [AUDIO]: getMediaObject(AUDIO)
});

/**
 * Generates default blanks settings object
 * @returns {{operative: boolean, inputType}}
 */
export const defaultBlanksSettings = () => ({
  [OPERATIVE]: true,
  [INPUT_TYPE]: NUMERIC,
  [FONT_SIZE]: M,
  [PUNCTUATION_MARKS_SENSITIVE]: false,
  [SPACE_SENSITIVE]: false,
  [CASE_SENSITIVE]: false
});
