import React from "react";

const Trash = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-trash"
  >
    <path fill="currentColor" d="M35.8125,6.25 L32.53125,1.875 C31.6425781,0.693359375 30.2558594,0 28.78125,0 L20.96875,0 C19.4941406,0 18.1074219,0.693359375 17.21875,1.875 L13.9375,6.25 L5.34375,6.25 C4.04492188,6.25 3,7.29492188 3,8.59375 L3,8.7890625 C3,9.11132812 3.26367188,9.375 3.5859375,9.375 L6.125,9.375 L6.125,45.3125 C6.125,47.9003906 8.22460938,50 10.8125,50 L38.9375,50 C41.5253906,50 43.625,47.9003906 43.625,45.3125 L43.625,9.375 L46.1640625,9.375 C46.4863281,9.375 46.75,9.11132812 46.75,8.7890625 L46.75,8.59375 C46.75,7.29492188 45.7050781,6.25 44.40625,6.25 L35.8125,6.25 Z M20.96875,3.125 L28.78125,3.125 C29.2695312,3.125 29.7382812,3.359375 30.03125,3.75 L31.90625,6.25 L17.84375,6.25 L19.71875,3.75 C20.0117188,3.359375 20.4804688,3.125 20.96875,3.125 Z M40.5,45.3125 C40.5,46.171875 39.796875,46.875 38.9375,46.875 L10.8125,46.875 C9.953125,46.875 9.25,46.171875 9.25,45.3125 L9.25,9.375 L40.5,9.375 L40.5,45.3125 Z M23.3125,41.015625 L23.3125,15.234375 C23.3125,14.5898438 23.8398438,14.0625 24.484375,14.0625 L25.265625,14.0625 C25.9101562,14.0625 26.4375,14.5898438 26.4375,15.234375 L26.4375,41.015625 C26.4375,41.6601562 25.9101562,42.1875 25.265625,42.1875 L24.484375,42.1875 C23.8398438,42.1875 23.3125,41.6601562 23.3125,41.015625 Z M15.5,41.015625 L15.5,15.234375 C15.5,14.5898438 16.0273438,14.0625 16.671875,14.0625 L17.453125,14.0625 C18.0976562,14.0625 18.625,14.5898438 18.625,15.234375 L18.625,41.015625 C18.625,41.6601562 18.0976562,42.1875 17.453125,42.1875 L16.671875,42.1875 C16.0273438,42.1875 15.5,41.6601562 15.5,41.015625 Z M31.125,41.015625 L31.125,15.234375 C31.125,14.5898438 31.6523437,14.0625 32.296875,14.0625 L33.078125,14.0625 C33.7226562,14.0625 34.25,14.5898438 34.25,15.234375 L34.25,41.015625 C34.25,41.6601562 33.7226562,42.1875 33.078125,42.1875 L32.296875,42.1875 C31.6523437,42.1875 31.125,41.6601562 31.125,41.015625 Z" />
  </svg>
);

export default Trash;
