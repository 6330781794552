import React from "react";
import MessagePostList from "../../containers/MessagePostList";
import { ENTITIES, OPMESSAGE } from "../../../constants";
import styled from "styled-components";
import AddMessage from "../../components/AddMessage";

const StyledMessageHeader = styled.div`
  padding: 20px 15px;
  margin-bottom: 3rem;
`;

const OpMessages = () => {
  return (
    <React.Fragment>
      <StyledMessageHeader>
        <AddMessage type={OPMESSAGE} />
      </StyledMessageHeader>
      <MessagePostList postType={ENTITIES[OPMESSAGE]} />
    </React.Fragment>
  );
};

export default OpMessages;
