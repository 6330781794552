import React from "react";

const Resources = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-resources"
  >
    <path d="M46.875,28.125 L31.25,28.125 C29.5244141,28.125 28.125,29.5244141 28.125,31.25 L28.125,46.875 C28.125,48.6005859 29.5244141,50 31.25,50 L46.875,50 C48.6005859,50 50,48.6005859 50,46.875 L50,31.25 C50,29.5244141 48.6005859,28.125 46.875,28.125 Z M46.875,46.875 L31.25,46.875 L31.25,31.25 L46.875,31.25 L46.875,46.875 Z M12.5,25 C5.59667969,25 0,30.5966797 0,37.5 C0,44.4033203 5.59667969,50 12.5,50 C19.4033203,50 25,44.4033203 25,37.5 C25,30.5966797 19.4033203,25 12.5,25 Z M12.5,46.875 C7.33105469,46.875 3.125,42.6689453 3.125,37.5 C3.125,32.3300781 7.33105469,28.125 12.5,28.125 C17.6699219,28.125 21.875,32.3300781 21.875,37.5 C21.875,42.6689453 17.6699219,46.875 12.5,46.875 Z M49.5097656,19.6425781 L39.0693359,1.78613281 C38.3740234,0.595703125 37.1552734,0 35.9375,0 C34.7197266,0 33.5009766,0.595703125 32.8056641,1.78613281 L22.3652344,19.6425781 C20.9726563,22.0234375 22.7128906,25 25.4970703,25 L46.3779297,25 C49.1621094,25 50.9023438,22.0234375 49.5097656,19.6425781 Z M46.8144531,21.6337891 C46.7226562,21.7939453 46.5751953,21.875 46.3779297,21.875 L25.4970703,21.875 C25.2998047,21.875 25.1523438,21.7939453 25.0605469,21.6337891 C24.9423828,21.4267578 25.0263672,21.2822266 25.0625,21.2207031 L35.5029297,3.36328125 C35.5957031,3.20507813 35.7412109,3.125 35.9375,3.125 C36.1337891,3.125 36.2792969,3.20507812 36.3720703,3.36328125 L46.8125,21.2207031 C46.8486328,21.2822266 46.9335938,21.4267578 46.8144531,21.6337891 Z" />
  </svg>
);

export default Resources;
