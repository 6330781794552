import styled from 'styled-components';

const deviceSize = {
  desktopL: 1200,
  desktop: 992,
  tablet: 768,
  tabletS: 680,
  phone: 520,
  phoneS: 320,
}

const multiplier = 35;

export const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: ${multiplier}vh;
  background-image: ${(props) => props.backgroundImage
    ? `url("${props.backgroundImage}")`
    : 'none'
  };
  background-size: auto ${multiplier}vh;
  background-repeat: no-repeat;
  background-color: #333333;
  z-index: 1;
  margin-bottom: 38px;
`;

export const StyledIntroductionViewLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 100%;
  position: relative;
`;

export const StyledPlaceablesArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledIntroductionViewRightColumn = styled.div`
  flex-grow: 1;
  max-width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: relative;
  z-index: 21;
  flex-direction: column;
  
  @media (min-width: ${deviceSize.desktopL}px ) {
    max-width: 50%;
  }
`;

export const StyledTextBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  max-width: 600px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${deviceSize.desktopL}px ) {
    max-width: 900px;
  }
`;
export const StyledAudioPlayer = styled.audio`
  width: 100%;
  height: 20px;
  position: absolute;
  margin-top: 2px;
  border: 1px solid black;
  border-radius: 8px; 
`
export const PlaceableImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform-origin: top left;
  z-index: ${(props) => props.zIndex};
  width: calc(${(props) => props.scale}*420*${multiplier}vh/1080);
  transform: translateX(calc(${(props) => props.offsetX}*${multiplier}vh/1080)) translateY(calc(${(props) => props.offsetY}*${multiplier}vh/1080));
`;

export const PlaceableImage = styled.img`
  width: 100%;
  height: auto;
  user-select: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none ; 
  ${(props) => props.isMirrored ? 'transform:  scaleX(-1)' : ''};
`;

export const StyledHeading = styled.h1`
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #277575;
`;

export const StyledSectionName = styled.h1`
  text-transform: capitalize;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #277575;
  margin-bottom: 10px;
`;

export const StyledFeedbackBox = styled.div`
  border:1px solid #91bfbf;
  line-height: normal;
  color: #277575;
  padding:3px;
  margin:3px 0px;
`;

export const StyledFeedbackHeading = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledFeedbackDetails = styled.div`
  font-size: 14px;
`;

export const StyledId = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #277575;
`;
