import styled from "styled-components";
import { StandardButton } from "../../../../../shared";
import {
  getHeight,
  getWidth,
  getBorderThickness,
  getAlignment
} from "../../ChartHelpers";

export const StyledCellButton = styled(({ title, ...props }) => (
  <StandardButton {...props} />
))`
  border: thin solid #999;
  border-width: ${({ border }) => getBorderThickness(border)};
  border-radius: 0px;
  ${props => props.title && "font-weight: bold; font-size:larger;"}
  background-color: ${props => props.color};
  height: ${props => getHeight(props.column)};
  width: ${props => getWidth(props.row)};
  min-width: ${props => getWidth(props.row)};
  ${props => props.selected && "border: 3px solid black;"}
  justify-content: ${({ type, index }) => getAlignment(type, index)};

`;
