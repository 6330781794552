import { takeEvery, all, fork } from "redux-saga/effects";
import {
  onFetchStructure,
  onListRoute,
  onSaveStructure,
  onCreateCategory,
  onStructureSaved,
  onUpdateCategory,
  undoTreeChange,
  updateTreeDraft,
  redoTreeChange,
  makeDeleteNode,
  makeUpdateGoalOrder,
  makeSaveGoalsOrderChanges,
  undoGoalChange,
  redoGoalChange,
  onPublishStructure,
  onFetchGoals,
  makeSaveStageChanges
} from "./sagas";
import {
  ACTION_FETCH_STRUCTURE,
  ACTION_SAVE_STRUCTURE,
  ACTION_CREATE_CATEGORY,
  ACTION_SAVE_STRUCTURE_SUCCESS,
  ACTION_UPDATE_CATEGORY,
  ACTION_UNDO_TREE,
  ACTION_UPDATE_TREE_DRAFT,
  ACTION_REDO_TREE,
  ACTION_REMOVE_NODES,
  ACTION_UPDATE_GOAL_ORDER,
  ACTION_SAVE_GOALS_ORDER_CHANGES,
  ACTION_UNDO_GOALS,
  ACTION_REDO_GOALS,
  ACTION_PUBLISH_STRUCTURE,
  ACTION_FETCH_GOALS,
  ACTION_UPDATE_STAGE_CHANGES
} from "../api/actions";
import { ROUTE_STRUCTURE } from "../routes";

/**
 * Tree saga watchers
 */
function* watchStructureRoutes() {
  yield takeEvery(ROUTE_STRUCTURE, onListRoute);
}

function* watchStructureRequests() {
  yield takeEvery(ACTION_FETCH_STRUCTURE, onFetchStructure);
}

function* watchGoalsRequests() {
  yield takeEvery(ACTION_FETCH_GOALS, onFetchGoals);
}

function* watchSaveStructure() {
  yield takeEvery(ACTION_SAVE_STRUCTURE, onSaveStructure);
}

function* watchPublishStructure() {
  yield takeEvery(ACTION_PUBLISH_STRUCTURE, onPublishStructure);
}

function* watchSaveStructureSuccess() {
  yield takeEvery(ACTION_SAVE_STRUCTURE_SUCCESS, onStructureSaved);
}

function* watchCreateCategory() {
  yield takeEvery(ACTION_CREATE_CATEGORY, onCreateCategory);
}

function* watchUpdateCategory() {
  yield takeEvery(ACTION_UPDATE_CATEGORY, onUpdateCategory);
}

function* watchTreeDraftUpdate() {
  yield takeEvery(ACTION_UPDATE_TREE_DRAFT, updateTreeDraft);
}

function* watchTreeUndo() {
  yield takeEvery(ACTION_UNDO_TREE, undoTreeChange);
}

function* watchTreeRedo() {
  yield takeEvery(ACTION_REDO_TREE, redoTreeChange);
}

export function* watchDeleteNode() {
  yield takeEvery(ACTION_REMOVE_NODES, makeDeleteNode);
}

/**
 * Goal saga watchers
 */
function* watchUpdateGoalOrder() {
  yield takeEvery(ACTION_UPDATE_GOAL_ORDER, makeUpdateGoalOrder);
}

function* watchUpdateStageChanges() {
  yield takeEvery(ACTION_UPDATE_STAGE_CHANGES, makeSaveStageChanges);
}

function* watchSaveGoalsOrderChanges() {
  yield takeEvery(ACTION_SAVE_GOALS_ORDER_CHANGES, makeSaveGoalsOrderChanges);
}

function* watchGoalsUndo() {
  yield takeEvery(ACTION_UNDO_GOALS, undoGoalChange);
}

function* watchGoalsRedo() {
  yield takeEvery(ACTION_REDO_GOALS, redoGoalChange);
}

export default function* rootSaga() {
  yield all([
    fork(watchStructureRoutes),
    fork(watchStructureRequests),
    fork(watchGoalsRequests),
    fork(watchSaveStructure),
    fork(watchPublishStructure),
    fork(watchCreateCategory),
    fork(watchSaveStructureSuccess),
    fork(watchUpdateCategory),
    fork(watchTreeDraftUpdate),
    fork(watchTreeUndo),
    fork(watchTreeRedo),
    fork(watchDeleteNode),

    fork(watchUpdateGoalOrder),
    fork(watchUpdateStageChanges),
    fork(watchSaveGoalsOrderChanges),
    fork(watchGoalsUndo),
    fork(watchGoalsRedo)
  ]);
}
