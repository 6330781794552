import { registerReducer, registerSaga, RouterService } from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import {
  productNews as productNewsReducer,
  opMessage as opMessageReducer,
  marketMessage as marketMessageReducer
} from "./store/reducer";
import { ENTITIES, PRODUCTNEWS, OPMESSAGE, MARKETMESSAGE } from "../constants";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer(ENTITIES[PRODUCTNEWS], productNewsReducer);
  registerReducer(ENTITIES[OPMESSAGE], opMessageReducer);
  registerReducer(ENTITIES[MARKETMESSAGE], marketMessageReducer);
};
