import React from "react";
import styled from "styled-components";
import { BiafMediaListItem } from "./BiafMediaListItem";
import { SortableContainer, arrayMove } from "react-sortable-hoc";

const Stack = styled("ul")`
	display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 0 1rem 0;
  padding: 0;
`;

const BiafMediaList = ({ list, removeItem, previewId, updateOrder }) => {
  if (!list) return null;

  const filteredList = list.filter(item => item.id !== previewId);

  const onDelete = (id) => removeItem(id);

  const sort = ({ oldIndex, newIndex }) => {
    const updatedFileList = arrayMove(filteredList, oldIndex, newIndex);
    const idList = updatedFileList.map(item => item.id);
    if (previewId) idList.push(previewId);
    updateOrder(idList);
  }

  return (
    <Sortable useDragHandle onSortEnd={sort}>
      {filteredList.map((item, index) =>
        <BiafMediaListItem
          index={index}
          key={item.id}
          title={item.title}
          onDelete={() => onDelete(item.id)}
        />)}
    </Sortable>
  );
}

export const Sortable = SortableContainer(({ children }) => {
  return <Stack>{children}</Stack>;
});

export default BiafMediaList;
