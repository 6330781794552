import React from "react";
import { connect } from "react-redux";
import MediaToolbar from "../../containers/MediaToolbar/MediaToolbar";
import MediaListContainer from "../../containers/MediaList/MediaList";
import MediaModalContent from "./MediaModalContent";
import { modalClose } from "../../../shared/store/modal/actions";
import { setItems } from "../../store/carousel/actions";
import { MediaDetailRight } from "./styledMediaModalContent";
import {
  fetchMediaByID,
  reloadMedia,
  deleteMediaFiles,
  updateMediaFile
} from "../../store/actions";
import { modalOpen, StandardButton } from "../../../shared";
import {
  StyledContainer,
  StyledNavigationAndContent,
  StyledContent,
  StyledTopBarContainer
} from "./StyledMediaShell";
import { MEDIA_RESOURCE } from "../../../constants";
import { reloadPosts } from "../../../posts/store/actions";
import { selectEntityId } from "../../../store/selectors";
import { selectCurrentMediaLib } from "../../api/selectors";
import { camelCaseToPascalCase } from "../../../shared/helpers";

class MediaShell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDropZone: false,
      hasChanged: false,
      showModal: false,
      mediaObj: null
    };
  }

  componentDidUpdate() {
    if (this.state.showModal) {
      this.props.active && this.props.active.id && this.onshowSelectedModal();
    }
  }

  handleSetMediaObj = media => {
    this.setState({ mediaObj: media });
  };

  handleHasChanged = change => {
    this.setState({ hasChanged: change });
  };

  /**
   * show upload media dropzone
   */
  onShowUploadMedia = () => {
    this.setState(prevState => ({ showDropZone: !prevState.showDropZone }));
  };

  /**
   * Clear all selections in medialist
   */
  clearAll = () => this.props.setSelectedMedia([]);

  /**
   * Select all media on current page
   */
  onSelectAllMediaList = () =>
    this.props.setSelectedMedia(this.props.mediaItems);

  /**
   * Callback for uploading media.
   */
  onUploadMedia = media => {
    const {
      section,
      updateMediaList,
      updateMediaresourceList
    } = this.props;
    this.props.setSelectedMedia(media);
    section === MEDIA_RESOURCE ? updateMediaresourceList() : updateMediaList();
    this.setState(_ => ({ showDropZone: false, showModal: true }));
  };

  onDeleteFiles = () => {
    const { selectedMediaItems, deleteFiles } = this.props;
    const selectedMediaIds = selectedMediaItems.map(item => item.id);
    deleteFiles(selectedMediaIds);
  };

  /**
   * Close modal and update list
   */
  _onCloseModal = () => {
    const {
      section,
      updateMediaList,
      updateMediaresourceList,
      _modalClose
    } = this.props;
    if (!this.state.hasChanged) {
      _modalClose();
      return;
    }
    section === MEDIA_RESOURCE ? updateMediaresourceList() : updateMediaList();
    _modalClose();
  };

  handleSaveAndCloseModal = async () => {
    let data = {
      user_id: this.state.mediaObj.user_id,
      description: this.state.mediaObj.description,
      title: this.state.mediaObj.title
    };
    await this.props.updateFile(this.state.mediaObj.media_id, data);
    this._onCloseModal();
  };

  renderActions = state => (
    <MediaDetailRight>
      <StandardButton onClick={this._onCloseModal}>Avbryt</StandardButton>
      {state && (
        <StandardButton onClick={this.handleSaveAndCloseModal}>
          Spara
        </StandardButton>
      )}
    </MediaDetailRight>
  );

  /**
   * Show modal with info of the selected media
   */
  onshowSelectedModal = () => {
    const header = <span></span>;
    const children = (
      <MediaModalContent
        handleSetMediaObj={this.handleSetMediaObj}
        handleHasChanged={this.handleHasChanged}
        onModalClose={this._onCloseModal}
        renderActions={this.renderActions}
      />
    );
    const actions = this.renderActions();
    this.props.setActiveMedia(this.props.active.id);
    this.props._modalOpen({
      header,
      children,
      actions,
      closeModal: this._onCloseModal
    });
    this.setState({ showModal: false });
  };

  render() {
    return (
      <StyledContainer>
        <StyledTopBarContainer>
          {this.renderMediaToolbar()}
        </StyledTopBarContainer>
        <StyledNavigationAndContent>
          {this.renderMediaListContent()}
        </StyledNavigationAndContent>
      </StyledContainer>
    );
  }

  /**
   * Render the toolbar
   */
  renderMediaToolbar = () => (
    <MediaToolbar
      active={this.props.active}
      newPostLink={this.props.newPostLink}
      section={this.props.section}
      onDeleteFiles={this.onDeleteFiles}
      onUploadMedia={this.onShowUploadMedia}
      showDropZone={this.state.showDropZone}
      hasSelectedItems={this.props.hasItems}
      onshowSelectedModal={this.onshowSelectedModal}
      selectedMediaItems={this.props.selectedMediaItems}
      onClearMediaList={this.clearAll}
      onSelectAll={this.onSelectAllMediaList}
      mediaType={this.props.mediaType}
    />
  );

  /**
   * Render the medialist
   */
  renderMediaListContent = () => {
    const { showDropZone } = this.state;
    const children = this.props;

    return (
      <StyledContent>
        {" "}
        <MediaListContainer
          section={this.props.section}
          useCarousel={true}
          showDropZone={showDropZone}
          onUploadMedia={this.onUploadMedia}
          onShowUploadMedia={this.onShowUploadMedia}
          onshowSelectedModal={this.onshowSelectedModal}
        >
          {" "}
          {children}{" "}
        </MediaListContainer>
      </StyledContent>
    );
  };
}

const mapStateToProps = (state, props) => {
  const prodID = selectEntityId(state);
  let mediaList = null;

  if (props.section === MEDIA_RESOURCE) {
    mediaList = state.Mediaresource.get("list").toJS();
  } else {
    mediaList = state.Media.get("list").toJS();
  }

  const lib = selectCurrentMediaLib(state);

  return {
    modal: state.modal,
    mediaItems: mediaList,
    selectedMediaItems: state.MediaCarousel.items,
    hasItems: state.MediaCarousel.items.length > 0,
    active: state.MediaCarousel.active,
    newPostLink: state.location.routesMap["ROUTE_MEDIARESOURCE_EDIT"].path
      .replace(":entityId", prodID)
      .replace(":id", "new"),
    mediaType: camelCaseToPascalCase(lib)
  };
};

const mapDispatchToProps = dispatch => ({
  _modalOpen: modalOpen(dispatch),
  _modalClose: () => modalClose(dispatch),
  setSelectedMedia: items => setItems(items)(dispatch),
  setActiveMedia: id => dispatch(fetchMediaByID(id)),
  updateMediaList: () => dispatch(reloadMedia()),
  updateMediaresourceList: () => dispatch(reloadPosts(MEDIA_RESOURCE)),
  deleteFiles: ids => dispatch(deleteMediaFiles(ids)),
  updateFile: (id, data) => dispatch(updateMediaFile(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaShell);
