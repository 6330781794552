import styled from "styled-components";

const MessageTitle = styled.div`
  padding: 5px;
  background-color: #eaeaea;
`;

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "link":
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    case "title":
      return (
        <MessageTitle>
          <h4 {...attributes}>{children}</h4>
        </MessageTitle>
      );
    case "subtitle":
      return <h5 {...attributes}>{children}</h5>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
