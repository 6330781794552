import React from "react";

const Bullet = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-bullet"
  >
    <path d="M25,12.0967742 C17.8739919,12.0967742 12.0967742,17.8739919 12.0967742,25 C12.0967742,32.1260081 17.8739919,37.9032258 25,37.9032258 C32.1260081,37.9032258 37.9032258,32.1260081 37.9032258,25 C37.9032258,17.8739919 32.1260081,12.0967742 25,12.0967742 Z M25,31.4516129 C21.4425403,31.4516129 18.5483871,28.5574597 18.5483871,25 C18.5483871,21.4425403 21.4425403,18.5483871 25,18.5483871 C28.5574597,18.5483871 31.4516129,21.4425403 31.4516129,25 C31.4516129,28.5574597 28.5574597,31.4516129 25,31.4516129 Z" />
  </svg>
);

export default Bullet;
