export const DO_LOGIN = "DO_LOGIN";
export const USER_LOGIN_SUCCESSFUL = "USER_LOGIN_SUCCESSFUL";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const DO_LOGOUT = "DO_LOGOUT";
export const ADD_AUTHOR = "ADD_AUTHOR";

/**
 * Action for requesting authorization
 * @param {Object} obj
 * @param {Object} obj.authData
 * @returns {Object}
 */
export const doLogin = ({ authData }) => ({
  type: DO_LOGIN,
  authData
});

/**
 * Action for successfull user login
 * @param {number} authorId
 * @param {string} clientToken
 * @returns {Object}
 */
export const userLoginSuccessful = (authorId, clientToken) => ({
  type: USER_LOGIN_SUCCESSFUL,
  authorId,
  clientToken
});

/**
 * Action for unsuccessful login
 * @param {number} status
 * @returns {Object}
 */
export const userLoginFailure = status => ({
  type: USER_LOGIN_FAILURE,
  status
});

/**
 * Action for user logout
 * @returns {Object}
 */
export const doLogout = () => ({
  type: DO_LOGOUT
});

/**
 * Action to add an author to the list of authors
 * @param {Object} author
 * @returns {Object}
 */
export const addAuthor = author => ({
  type: ADD_AUTHOR,
  author
});
