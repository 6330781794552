import React from "react";
import styled from "styled-components";
import { MinusSquare, PlusSquare, Info } from "react-feather";

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  display: inline-block;
  padding: 1.75rem;
  transition: all 0.28s linear;
  transition-property: height;
  ${({ show }) => (!show ? `height: calc(48px + 3.5rem);` : `height: auto;`)}
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  ${({ show, noPadding }) => show && !noPadding && `padding-bottom: 1.75rem;`}
  position: relative;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-right: 0.6rem;
  line-height: normal;
`;

const Content = styled.div`
  opacity: 1;
  ${({ show }) => (show ? `opacity: 1;` : `opacity: 0;`)}
`;

const Toggle = styled.button`
  margin-left: auto;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;

  & > svg {
    color: rgba(0, 0, 0, 0.54);
  }

  &:hover > svg,
  &:focus > svg {
    color: rgba(0, 0, 0, 0.87);
  }
`;

const Card = ({ children, title, noPadding }) => {
  const [show, toggleShow] = React.useState(true);

  return (
    <Wrapper show={show}>
      <Header show={show} noPadding={noPadding}>
        <Title>{title}</Title>
        <Info width="20px" />
        <Toggle
          onClick={() => toggleShow(!show)}
          aria-label={show ? "Stäng kort" : "Öppna kort"}
        >
          {show && <MinusSquare width="20px" />}
          {!show && <PlusSquare width="20px" />}
        </Toggle>
      </Header>
      <Content show={show}>{children}</Content>
    </Wrapper>
  );
};

export default Card;
