import * as React from "react";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import PluginHoc from "../../../../shared/PluginHoc";
import {
  StyledHelpWrapper,
  StyledHelpContainer,
  StyledTextArea,
  StyledTopSection
} from "../StyledHelp";
import Text from "../../../../shared/components/Text/Text";

const About = ({
  data,
  settings,
  updateData,
  pluginHeader,
  storeDraft,
  draftTarget
}) => {
  /**
   *  Render text area
   */
  const renderTextEditor = () => (
    <StyledTextArea>
      <Text
        data={data}
        settings={settings}
        storeDraft={storeDraft}
        draftTarget={draftTarget}
        updateData={updateData}
      />
    </StyledTextArea>
  );

  /**
   * Render sidebar
   * @returns {*}
   */
  const renderSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#72A5AF"}>
          Inställningar
        </StyledSidebarHeading>
      </StyledSidebarSection>
    </StyledBlockSidebar>
  );

  /**
   * Render read only
   * @returns {*}
   */
  const renderReadOnly = () => (
    <StyledHelpWrapper>
      <StyledHelpContainer>
        <StyledTopSection>{data.title}</StyledTopSection>
        <div>{data.text}</div>
      </StyledHelpContainer>
    </StyledHelpWrapper>
  );

  /**
   * Render edit mode.
   * @returns {*}
   */
  const renderEditMode = () => (
    <StyledHelpWrapper>
      <StyledHelpContainer>
        {pluginHeader}

        {renderTextEditor()}
      </StyledHelpContainer>
      {renderSidebar()}
    </StyledHelpWrapper>
  );

  return settings.readOnly ? renderReadOnly() : renderEditMode();
};

export default PluginHoc({
  Component: About
});
