import React from "react";
import styled from "styled-components";

import Card from "./Card";
import TeachersGuide from "./TeachersGuide";
import Toc from "./toc";
import TwinHandler from "./TwinHandler";
import Classroomgroups from "./ClassroomGroups";

const Container = styled.div`
  display: flex;
`;

const Part = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 1.75rem;
  }

  &:last-child {
    margin-left: 1.75rem;
  }
`;

const ClassroomCard = () => {
  return (
    <Card title="Klassrum">
      <Container>
        <Part>
          <Classroomgroups />
          <TwinHandler />
        </Part>
        <Part>
          <Toc />
          <TeachersGuide />
        </Part>
      </Container>
    </Card>
  );
};

export default ClassroomCard;
