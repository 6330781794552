const seenKeys = {};
const MULTIPLIER = Math.pow(2, 24);

function getRandomNumber() {
  const cryptoObj = window.crypto || window.msCrypto;

  const array = new Uint32Array(1);
  return cryptoObj.getRandomValues(array);
}

function generateRandomKey() {
  let key;
  while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
    key = Math.floor(getRandomNumber() * MULTIPLIER).toString(32);
  }
  seenKeys[key] = true;
  return key;
}

export const generateImageBlock = item => {
  return {
    blocks: [
      {
        key: generateRandomKey(),
        text: " ",
        type: "atomic",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [
          {
            key: 0,
            offset: 0,
            length: 1
          }
        ]
      }
    ],
    entityMap: {
      0: {
        data: {
          src: item.data.image.src
        },
        type: "IMAGE",
        mutability: "IMMMUTABLE"
      }
    }
  };
};

export const generateSliderBlock = item => {
  return {
    blocks: [
      {
        key: generateRandomKey(),
        text: " ",
        type: "atomic",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [
          {
            key: 0,
            offset: 0,
            length: 1
          }
        ]
      }
    ],
    entityMap: {
      0: {
        data: {
          slides: item.data.slides.map(s => ({
            src: s.image.src,
            text: s.text
          }))
        },
        type: "IMAGESLIDER",
        mutability: "IMMMUTABLE"
      }
    }
  };
};

export const generateAudioBlock = item => {
  return {
    blocks: [
      {
        key: generateRandomKey(),
        text: " ",
        type: "atomic",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [
          {
            key: 0,
            offset: 0,
            length: 1
          }
        ]
      }
    ],
    entityMap: {
      0: {
        data: {
          src: item.data.audio.src,
          attributes: item.data.audio.attributes,
          filetype: item.data.audio.filetype
        },
        type: "AUDIO",
        mutability: "IMMMUTABLE"
      }
    }
  };
};

export const generateVideoBlock = item => {
  return {
    blocks: [
      {
        key: generateRandomKey(),
        text: " ",
        type: "atomic",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [
          {
            key: 0,
            offset: 0,
            length: 1
          }
        ]
      }
    ],
    entityMap: {
      0: {
        data: {
          src: item.data.video.src
        },
        type: "draft-js-video-plugin-video",
        mutability: "IMMMUTABLE"
      }
    }
  };
};
