import React from "react";

const DotComma = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    viewBox="0 0 50 50"
  >
    <path
      fill={"currentColor"}
      id="path-1"
      d="M17.31,0A4.72,4.72,0,0,1,22,4.72V28.33A15.74,15.74,0,0,1,6.3,44.07H5.51A2.36,2.36,0,0,1,3.15,41.7V37a2.36,2.36,0,0,1,2.36-2.36H6.3a6.29,6.29,0,0,0,6.29-6.29V22H4.72A4.72,4.72,0,0,1,0,17.31V4.72A4.72,4.72,0,0,1,4.72,0ZM37,22A11,11,0,1,1,48,11,11,11,0,0,1,37,22Z"
    />
  </svg>
);

export default DotComma;
