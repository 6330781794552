import React from "react";
import { connect } from "react-redux";
import Card from "../../components/Card";
import {
  StyledProductViewWrapper,
  StyledSecondaryProductViewColumn,
  StyledSectionContainer,
  StyledDescriptionContainer,
  StyledLink,
  StyledSection,
  StyledSectionsTitle,
  StyledSectionsWrapper,
  StyledSectionTitle,
  StyledDescription,
  StyledTitle
} from "./StyledProductView";
import { ROUTE_EXERCISES } from "../../../posts/routes";
import {
  EXERCISES,
  translation,
  SET_REVIEWED,
  PUBLISH,
  DRAFT_READY_FOR_REVIEW,
  DRAFT_REVIEWED,
  REVISION_READY_FOR_REVIEW,
  REVISION_REVIEWED,
  READY_FOR_REVIEW,
  REVIEWED
} from "../../../constants";
import HasPermission from "../../../shared/Permissions";

const messages = {
  [READY_FOR_REVIEW]: "Du har ogranskade övningar i produkten",
  [REVIEWED]: "Du har opublicerade övningar i produkten"
};

const filters = {
  [DRAFT_READY_FOR_REVIEW]: 1,
  [DRAFT_REVIEWED]: 4,
  [REVISION_READY_FOR_REVIEW]: 2,
  [REVISION_REVIEWED]: 5
};

const ProductView = props => {
  const getStatusArray = status =>
    Object.entries(status)
      .map(([key, value]) => ({ key, value }))
      .filter(obj => filters[obj.key]);

  const getSections = status => {
    if (status) {
      const statusArray = getStatusArray(status);
      const componentArr = [];
      let readyForReviewCount = 0;
      let reviewedCount = 0;

      statusArray.forEach(obj => {
        if (obj.key.indexOf(READY_FOR_REVIEW) !== -1) {
          readyForReviewCount = readyForReviewCount + obj.value;
        }
        if (obj.key.indexOf(REVIEWED) !== -1) {
          reviewedCount = reviewedCount + obj.value;
        }
      });

      if (readyForReviewCount) {
        const entityId = props.entity && props.entity.id;
        const filteredStatus = [
          filters[DRAFT_READY_FOR_REVIEW],
          filters[DRAFT_REVIEWED]
        ];
        const component = wrapComponentWithPermission(
          SET_REVIEWED,
          filteredStatus,
          readyForReviewCount,
          entityId,
          messages[READY_FOR_REVIEW]
        );
        componentArr.push(component);
      }
      if (reviewedCount) {
        const entityId = props.entity && props.entity.id;
        const filteredStatus = [
          filters[REVISION_READY_FOR_REVIEW],
          filters[REVISION_REVIEWED]
        ];
        const component = wrapComponentWithPermission(
          PUBLISH,
          filteredStatus,
          reviewedCount,
          entityId,
          messages[REVIEWED]
        );
        componentArr.push(component);
      }

      return componentArr;
    }
  };

  const wrapComponentWithPermission = (
    permission,
    status,
    workFlow,
    entityId,
    message
  ) => (
    <HasPermission
      key={permission}
      component={renderSection(
        translation[EXERCISES],
        status,
        workFlow,
        entityId,
        message
      )}
      section={EXERCISES}
      permission={permission}
    />
  );

  const renderSection = (section, status, count, entityId, message) => (
    <StyledSectionContainer key={status}>
      <StyledSection>
        <StyledDescriptionContainer>
          <StyledSectionTitle>{section}</StyledSectionTitle>
          <StyledDescription>{message}</StyledDescription>
          <StyledLink
            onClick={props.goToPosts && props.goToPosts(status, entityId)}
          >
            {`Gå till ${count} övningar`}
          </StyledLink>
        </StyledDescriptionContainer>
      </StyledSection>
    </StyledSectionContainer>
  );

  const renderSectionTitle = status => {
    if (status) {
      const titles = [];
      const statusArray = getStatusArray(status);

      const modifiedArray = statusArray.map(item => {
        if (item.key.includes(READY_FOR_REVIEW)) {
          return READY_FOR_REVIEW;
        }
        if (item.key.includes(REVIEWED)) {
          return REVIEWED;
        }
        return null;
      });

      const uniqueStatusArray = [...new Set(modifiedArray)];
      uniqueStatusArray.forEach(item => {
        if (item === READY_FOR_REVIEW) {
          const value = <StyledTitle>Granska</StyledTitle>;
          titles.push({ key: SET_REVIEWED, value: value });
        }
        if (item === REVIEWED) {
          const value = <StyledTitle>Publicera</StyledTitle>;
          titles.push({ key: PUBLISH, value: value });
        }
      });

      return titles.map(obj => (
        <HasPermission
          key={obj.key}
          component={obj.value}
          section={EXERCISES}
          permission={obj.key}
        />
      ));
    }
  };

  return (
    <Card
      title="Dina ärenden">
      <StyledProductViewWrapper>
        <StyledSecondaryProductViewColumn>
          <StyledSectionsWrapper>
            <StyledSectionsTitle>
              {renderSectionTitle(props.statusSummary)}
            </StyledSectionsTitle>
            {getSections(props.statusSummary)}
          </StyledSectionsWrapper>
        </StyledSecondaryProductViewColumn>
      </StyledProductViewWrapper>
    </Card>
  );
};

const mapStateToProps = state => ({
  entity: state.Product.get("selectedItem"),
  statusSummary: state.Product.get("statusSummary")
});

const mapDispatchToProps = dispatch => ({
  goToPosts: (status, entityId) => () =>
    dispatch({
      type: ROUTE_EXERCISES,
      payload: { entityId: entityId },
      query: { status }
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
