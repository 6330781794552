import React from "react";

const Media = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-media"
  >
    <path
      fill="currentColor"
      d="M45.3125,6 L4.6875,6 C2.09863281,6 0,8.09863281 0,10.6875 L0,38.8125 C0,41.4013672 2.09863281,43.5 4.6875,43.5 L45.3125,43.5 C47.9013672,43.5 50,41.4013672 50,38.8125 L50,10.6875 C50,8.09863281 47.9013672,6 45.3125,6 Z M46.875,38.8125 C46.875,39.6740234 46.1740234,40.375 45.3125,40.375 L4.6875,40.375 C3.82597656,40.375 3.125,39.6740234 3.125,38.8125 L3.125,10.6875 C3.125,9.82597656 3.82597656,9.125 4.6875,9.125 L45.3125,9.125 C46.1740234,9.125 46.875,9.82597656 46.875,10.6875 L46.875,38.8125 Z M10.9375,22.40625 C13.9578125,22.40625 16.40625,19.9578125 16.40625,16.9375 C16.40625,13.9171875 13.9578125,11.46875 10.9375,11.46875 C7.9171875,11.46875 5.46875,13.9171875 5.46875,16.9375 C5.46875,19.9578125 7.9171875,22.40625 10.9375,22.40625 Z M10.9375,14.59375 C12.2298828,14.59375 13.28125,15.6451172 13.28125,16.9375 C13.28125,18.2298828 12.2298828,19.28125 10.9375,19.28125 C9.64511719,19.28125 8.59375,18.2298828 8.59375,16.9375 C8.59375,15.6451172 9.64511719,14.59375 10.9375,14.59375 Z M31.1551758,16.8426758 L21.875,26.1229492 L18.8448242,23.0927734 C17.9294922,22.1774414 16.4455078,22.1774414 15.5302734,23.0927734 L6.93652344,31.6865234 C6.49696017,32.1260272 6.25000769,32.7221557 6.25,33.34375 L6.25,36.078125 C6.25,36.725293 6.77470703,37.25 7.421875,37.25 L42.578125,37.25 C43.225293,37.25 43.75,36.725293 43.75,36.078125 L43.75,27.09375 C43.75,26.472168 43.5030273,25.8759766 43.0635742,25.4364258 L34.4698242,16.8426758 C33.5544922,15.9274414 32.0705078,15.9274414 31.1551758,16.8426758 Z M40.625,34.125 L9.375,34.125 L9.375,33.6673828 L17.1875,25.8548828 L21.875,30.5423828 L32.8125,19.6048828 L40.625,27.4173828 L40.625,34.125 Z"
    ></path>
    <path
      fill="currentColor"
      d="M45.3125,6 L4.6875,6 C2.09863281,6 0,8.09863281 0,10.6875 L0,38.8125 C0,41.4013672 2.09863281,43.5 4.6875,43.5 L45.3125,43.5 C47.9013672,43.5 50,41.4013672 50,38.8125 L50,10.6875 C50,8.09863281 47.9013672,6 45.3125,6 Z M46.875,38.8125 C46.875,39.6740234 46.1740234,40.375 45.3125,40.375 L4.6875,40.375 C3.82597656,40.375 3.125,39.6740234 3.125,38.8125 L3.125,10.6875 C3.125,9.82597656 3.82597656,9.125 4.6875,9.125 L45.3125,9.125 C46.1740234,9.125 46.875,9.82597656 46.875,10.6875 L46.875,38.8125 Z M10.9375,22.40625 C13.9578125,22.40625 16.40625,19.9578125 16.40625,16.9375 C16.40625,13.9171875 13.9578125,11.46875 10.9375,11.46875 C7.9171875,11.46875 5.46875,13.9171875 5.46875,16.9375 C5.46875,19.9578125 7.9171875,22.40625 10.9375,22.40625 Z M10.9375,14.59375 C12.2298828,14.59375 13.28125,15.6451172 13.28125,16.9375 C13.28125,18.2298828 12.2298828,19.28125 10.9375,19.28125 C9.64511719,19.28125 8.59375,18.2298828 8.59375,16.9375 C8.59375,15.6451172 9.64511719,14.59375 10.9375,14.59375 Z M31.1551758,16.8426758 L21.875,26.1229492 L18.8448242,23.0927734 C17.9294922,22.1774414 16.4455078,22.1774414 15.5302734,23.0927734 L6.93652344,31.6865234 C6.49696017,32.1260272 6.25000769,32.7221557 6.25,33.34375 L6.25,36.078125 C6.25,36.725293 6.77470703,37.25 7.421875,37.25 L42.578125,37.25 C43.225293,37.25 43.75,36.725293 43.75,36.078125 L43.75,27.09375 C43.75,26.472168 43.5030273,25.8759766 43.0635742,25.4364258 L34.4698242,16.8426758 C33.5544922,15.9274414 32.0705078,15.9274414 31.1551758,16.8426758 Z M40.625,34.125 L9.375,34.125 L9.375,33.6673828 L17.1875,25.8548828 L21.875,30.5423828 L32.8125,19.6048828 L40.625,27.4173828 L40.625,34.125 Z"
    ></path>
  </svg>
);

export default Media;
