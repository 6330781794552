import styled from "styled-components";

export const SideBarContainer = styled.div`
  position: absolute;
  z-index: 3;
  border-right: 1px solid lightgrey;
  height: 100%;
  background-color: white;
`;

export const SideBarWrapper = styled.div`
  position: fixed;
  height: 100%;
  z-index: 3;
  top: 73px;
`;

export const SideBarMargin = styled.div`
  width: 0;
  margin-right: 36px;
  transition: margin-right 200ms;
  ${props => {
    if (!props.byMouseOver) {
      return props.collapsed ? "margin-right: 36px" : "margin-right: 200px";
    }
  }}
`;

export const SideBarButton = styled.button`
  width: 24px;
  height: 24px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  top: 12px;
  transition: left 200ms;
  ${props => (props.collapsed ? `left: 24px;` : `left: 188px`)};
  &:hover {
    background-color: lightgray;
  }
  cursor: pointer;
`;

export const StyledSideBarWrapper = styled.div`
  ${props => (props.hidden ? "display: none" : null)}
`;
