import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../shared/components/Modal/Modal";
import TreeSelect from "../../../shared/components/TreeSelect";
import styled from "styled-components";
import {
  selectChips,
  selectPostListChipAndIds
} from "../../api/selectors";
import { PrimaryButton, StandardButton, Button } from "../../../shared";
import { addRemoveChipsToPost } from "../../store/actions";
import { makeAddRemoveObject } from "../../sagas/PostDraft/helpers";
import Tooltip from "react-tooltip-lite";
import ChipsViewer from "../../../shared/components/ChipsViewer";

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  height: 45rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
`;

const StyledModalFooter = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  align-self: end;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeaderTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
`;

export const StyledInlineSectionButton = styled(props => <Button {...props} />)`
  background-color: transparent;
  border: 0;
  padding: 0 0 10px 0;
  min-width: 25px;

  &:hover,
  &:active {
    background-color: transparent;
  }
`;

const ChipPost = ({
  isDetailView,
  useCurrentPost = false,
  postType,
  title
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const chips = useSelector(state => selectChips(state, postType));
  const { postChips, post_ids } = useSelector(state =>
    selectPostListChipAndIds(state, useCurrentPost, postType)
  );
  const [chipsSelected, setChipsSelected] = useState([]);

  useEffect(() => {
    setChipsSelected(postChips)
  }, [postChips])

  const dispatch = useDispatch();

  const closeModal = () => {
    setModalOpen(false);
  };

  const addChips = () => {
    setModalOpen(false);
    const o = makeAddRemoveObject(chipsSelected, postChips, post_ids);
    dispatch(addRemoveChipsToPost(postType, o.add, o.remove, !!useCurrentPost));
  };

  const deleteChips = t => {
    const deleted = chipsSelected.filter(c => c !== t);
    const o = makeAddRemoveObject(deleted, postChips, post_ids);
    setChipsSelected(deleted);
    dispatch(addRemoveChipsToPost(postType, null, o.remove, !!useCurrentPost));
  };

  return (
    <>
      {!!isDetailView ? (
        <StyledHeader>
          <StyledHeaderTitle>{title}</StyledHeaderTitle>
          <StyledInlineSectionButton
            studlicon="Cog"
            onClick={() => setModalOpen(true)}
          ></StyledInlineSectionButton>
        </StyledHeader>
      ) : (
        <Tooltip content="Koppla" useDefaultStyles >
          <StandardButton
            studlicon="Compass"
            onClick={() => setModalOpen(true)}
          />
        </Tooltip>
      )}
      {!!isDetailView && (
        <ChipsViewer
          selectedChips={chipsSelected}
          chipsTree={chips}
          deleteChipsCallback={deleteChips}
        />
      )}
      <Modal isModalOpen={modalOpen} modalClose={closeModal}>
        <StyledModalContent>
          <div>
            <TreeSelect
              items={chips}
              postChips={chipsSelected}
              onTreeChange={nodes => setChipsSelected(nodes)}
            />
          </div>
          <StyledModalFooter>
            <PrimaryButton onClick={addChips}>Uppdatera</PrimaryButton>
          </StyledModalFooter>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default ChipPost;
