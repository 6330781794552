import React from "react";
import Tag from "../../../shared/components/Tag";

import {
  TagsPostWrapper,
  TagWrapper,
  NoTagsSelected
} from "./TagsInMediaStyles";

const TagsInMedia = ({ tagList, mediaTags }) => {
  const flattenTagList = () =>
    tagList ? tagList.reduce((acc, g) => [...acc, ...g.tags], []) : [];

  const tList = flattenTagList();
  const basicColor = "#00b294";

  const getTagById = id => {
    return tList.find(item => {
      return item.id === id;
    });
  };

  const tagRenderer = (tag, fill) => (
    <Tag
      label={tag.title}
      variant={"info"}
      fill={fill}
      labelColor={tag.color || basicColor}
    />
  );

  const renderTags = tags =>
    tags.map(tag => (
      <TagWrapper key={tag.id + "selectedTagsRenderer"}>
        {tagRenderer(tag, true)}
      </TagWrapper>
    ));

  const mediaTagsRenderer = () => {
    const mTags = mediaTags 
      ? mediaTags
        .map(item => getTagById(item))
        .filter(t => t !== undefined ) 
      : [];

    return mTags.length > 0 ? (
      renderTags(mTags)
    ) : (
      <NoTagsSelected>Ingen tagg vald</NoTagsSelected>
    );
  };

  return <TagsPostWrapper>{mediaTagsRenderer()}</TagsPostWrapper>;
};

export default TagsInMedia;
