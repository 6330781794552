// Primary
export const themeDarker = "#004578";
export const themeDark = "#005a9e";
export const themeDarkAlt = "#106ebe";
export const themePrimary = "#0078d7";
export const themeSecondary = "#2b88d8";
export const themeTertiary = "#71afe5";
export const themeLight = "#c7e0f4";
export const themeLighter = "#deecf9";
export const themeLighterAlt = "#eff6fc";

// Neutral
export const black = "#000000";
export const neutralDark = "#212121";
export const neutralPrimary = "#333333";
export const neutralPrimaryAlt = "#3c3c3c";
export const neutralSecondary = "#666666";
export const neutralSecondaryAlt = "#767676";
export const neutralTertiary = "#a6a6a6";
export const neutralTertiaryAlt = "#c8c8c8";
export const neutralQuaternary = "#d0d0d0";
export const neutralQuaternaryAlt = "#dadada";
export const neutralLight = "#eaeaea";
export const neutralLighter = "#f4f4f4";
export const neutralLighterAlt = "#f8f8f8";
export const white = "#ffffff";

// Accent
export const yellow = "#ffb900";
export const yellowLight = "#fff100";
export const orange = "#d83b01";
export const orangeLight = "#ea4300";
export const orangeLighter = "#ff8c00";
export const redDark = "#a80000";
export const red = "#e81123";
export const magentaDark = "#5c005c";
export const magenta = "#b4009e";
export const magentaLight = "#e3008c";
export const purpleDark = "#32145a";
export const purple = "#5c2d91";
export const purpleLight = "#b4a0ff";
export const blueDark = "#002050";
export const blueMid = "#00188f";
export const blue = "#0078d7";
export const blueLight = "#00bcf2";
export const tealDark = "#004b50";
export const teal = "#008272";
export const tealLight = "#00b294";
export const greenDark = "#004b1c";
export const green = "#107c10";
export const greenLight = "#bad80a";
export const darkOrange = "darkorange";
export const darkGray = "darkgray";
export const darkBlue = "darkblue";
export const lightGray = "lightgray";

// Informative
export const infoColor = neutralSecondaryAlt;
export const infoBackground = neutralLighter;
export const successColor = green;
export const successBackground = "#dff6dd";
export const warningColor = neutralSecondaryAlt;
export const warningBackground = "#fff4ce";
export const errorColor = redDark;
export const errorBackground = "#fde7e9";

// Tomoyo
export const tomoyoPrimary = "#277575";
