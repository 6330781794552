let currentKey = 0;

/**
 * get a new unique key
 * @returns {number} key
 */
const getUniqueKey = () => {
  if (currentKey === Number.MAX_SAFE_INTEGER) {
    currentKey = 0;
  }
  return ++currentKey;
};

/**
 * get the last generated key
 * @returns {number} key
 */
export const getLastBlockKey = () => {
  return currentKey;
};

/**
 * add unique blockkeys to a block and its children
 * @param {object} block
 * @returns {object} block with blockKeys
 */
export const generateBlockKeys = block => {
  if (!block) {
    return block;
  }
  const { data, type, ...rest } = block;
  if (isBlockArray(block)) {
    return {
      type,
      ...rest,
      blockKey: getUniqueKey(),
      data: data.map(generateBlockKeys)
    };
  }
  return { type, ...rest, blockKey: getUniqueKey(), data };
};

/**
 * Removes all blockKeys from the block and its children
 * @param {object} block
 * @returns {object} block without blockKeys
 */
const removeBlockKeys = block => {
  const { data, type, ...rest } = block;
  if (isBlockArray(block)) {
    return { type, ...rest, data: data.map(removeBlockKeys) };
  }
  return { type, ...rest, data };
};

const isBlockArray = block => {
  return ((block || {}).type || "").toLowerCase() === "blockarray";
};

/**
 * Transforms the old exercise format to a blockarray
 * @param {object} raw
 * @returns {object} block of type blockarray
 */
const convertFromOldFormat = raw => {
  const transformEntity = ({ type, data, settings }) => ({
    type,
    data,
    settings
  });
  const convertOldBlock = (entities, block) => {
    const key = block.entityMap[0].key;
    return transformEntity(entities[key]);
  };

  return {
    type: "blockarray",
    data: raw.blocks.map(convertOldBlock.bind(null, raw.entityMap))
  };
};

/**
 * replaces a block with a new block
 * @param {number} searchKey the blockkey of the block to replace
 * @param {object} newBlock the new block that should be added
 * @param {object} block block-structure to be searched
 * @returns {object} a new block-structure where the replacement has been done
 */
export const replaceBlock = (searchKey, newBlock, block) => {
  const { data, blockKey, type, ...rest } = block;
  if (blockKey === searchKey) {
    return generateBlockKeys(newBlock);
  } else if (type.toLowerCase() === "blockarray") {
    return {
      blockKey,
      type,
      ...rest,
      data: data.map(replaceBlock.bind(null, searchKey, newBlock))
    };
  }
  return block;
};

/**
 * @param {number} targetKey the blockKey of the block that should be updated
 * @param {object} targetData the data to update the block
 * @param {object} block the block-structure where the update should be done
 * @returns {object} a new block-structure where the update has been done
 */
export const updateBlock = (targetKey, targetData, block) => {
  const { blockKey, type, ...rest } = block;

  if (blockKey === targetKey) {
    return { blockKey, type, ...rest, ...targetData };
  } else if (type.toLowerCase() === "blockarray") {
    return {
      blockKey,
      type,
      ...rest,
      data: block.data.map(updateBlock.bind(null, targetKey, targetData))
    };
  }

  return block;
};

const _findBlock = (targetKey, block, parent = null, index = -1) => {
  const { blockKey, type, data } = block;
  if (blockKey === targetKey) {
    return { block, parent, index };
  } else if (type.toLowerCase() === "blockarray") {
    return data.reduce((acc, child, index) => {
      return acc || _findBlock(targetKey, child, block, index);
    }, null);
  }
  return null;
};

/**
 * Search for a specific block in a block-structure
 * @param {number} targetKey the key to find
 * @param {object} block block-structure to search
 * @returns {{block: object, parent: object, index: number}|null} The block, parent and the blocks index in the parent, or null if not found
 */
export const findBlock = (targetKey, block) => {
  return _findBlock(targetKey, block) || {};
};

/**
 * Flattens an array of blocks, i.e. removes blockarrays and replaces them with their blocks
 * @param {array} blocks
 * @returns {array} the resulting array of blocks
 */
export const flattenBlockArrayData = blocks => {
  if (!Array.isArray(blocks)) {
    return blocks;
  }
  return blocks.reduce((acc, block) => {
    const { data } = block;
    if (isBlockArray(block)) {
      return acc.concat(flattenBlockArrayData(data));
    }
    return acc.concat(block);
  }, []);
};

/**
 * Removes empty blocks inside a block structure
 * @param {object} block the block-structure to be cleaned
 * @returns {object} new block-structure
 */
export const cleanupBlockArray = block => {
  const { type, data, ...rest } = block;
  if (isBlockArray(block)) {
    return { type, ...rest, data: data.filter(d => d).map(cleanupBlockArray) };
  }
  return block;
};

/**
 * Removes a block from a block-structure
 * @param {number} searchKey the key of the block to remove
 * @param {object} block the block-structure to remove the block from
 * @returns {object} the new block-structure
 */
export const removeBlock = (searchKey, block) => {
  return cleanupBlockArray(replaceBlock(searchKey, null, block));
};

/**
 * Insert a new block before another block
 * @param {number} searchKey the block to search for
 * @param {object} newBlock block to be inserted before the found block
 * @param {object} block the block-structure to work on
 * @returns {object} the new block-structure
 */
export const insertBlockBefore = (searchKey, newBlock, block) => {
  const { parent, index } = findBlock(searchKey, block);
  if (parent) {
    const targetKey = parent.blockKey;
    const targetData = [].concat(parent.data);
    targetData.splice(index, 0, generateBlockKeys(newBlock));
    return updateBlock(targetKey, { data: targetData }, block);
  }
  return block;
};

/**
 * Insert a new block after another block
 * @param {number} searchKey the block to search for
 * @param {object} newBlock block to be inserted after the found block
 * @param {object} block the block-structure to work on
 * @returns {object} the new block-structure
 */
export const insertBlockAfter = (searchKey, newBlock, block) => {
  const { parent, index } = findBlock(searchKey, block);
  if (parent) {
    const targetKey = parent.blockKey;
    const targetData = [].concat(parent.data);
    targetData.splice(index + 1, 0, generateBlockKeys(newBlock));
    return updateBlock(targetKey, { data: targetData }, block);
  }
  return block;
};

/**
 * Insert a new block last in block-structure
 * @param {object} newBlock block to be inserted
 * @param {object} block the block-structure to work on
 * @returns {object} the new block-structure
 */
export const insertBlockLast = (newBlock, block) => {
  const { blockKey, data } = block;

  return isBlockArray(block)
    ? updateBlock(
        blockKey,
        { data: [...data, generateBlockKeys(newBlock)] },
        block
      )
    : block;
};

/**
 * Insert a new block first in block-structure
 * @param {object} newBlock block to be inserted
 * @param {object} block the block-structure to work on
 * @returns {object} the new block-structure
 */
export const insertBlockFirst = (newBlock, block) => {
  const { blockKey, data } = block;
  if (isBlockArray(block)) {
    const newData = [].concat(generateBlockKeys(newBlock), data);
    return updateBlock(blockKey, { data: newData }, block);
  }
  return block;
};

/**
 * Convert editable data-structure to raw data-structure (for saving)
 * @param {object} data the editable-structure
 * @returns {object} the raw-structure
 */
export const convertToRaw = data => {
  return removeBlockKeys(data);
};

/**
 * Convert raw data-structure to editable data-structure
 * @param {object} data the raw-structure
 * @returns {object} the editable-structure
 */
export const convertFromRaw = raw => {
  return generateBlockKeys(
    raw && raw.entityMap ? convertFromOldFormat(raw) : raw
  );
};

/**
 * Extract all used resource id:s from the post
 * @param {array} plugins
 * @param {object} postData
 * @returns {array} an array of resource ids
 */
export const getUsedResources = (plugins, postData) => {
  const noop = () => undefined;

  const { type, data } = postData;

  let r = [];
  if (["resource", "mediaresource", "helpresource"].indexOf(type) >= 0) {
    r = [data.id];
  } else if (isBlockArray(postData)) {
    r = data.reduce(
      (acc, block) => acc.concat(getUsedResources(plugins, block)),
      []
    );
  } else {
    r = plugins.reduce((acc, plugin) => {
      return (plugin.types || []).indexOf(type) >= 0
        ? acc.concat((plugin.getUsedResources || noop)({ type, data }))
        : acc;
    }, []);
  }
  return r
    .filter(id => id !== undefined) // only keep id:s that are not undefined
    .filter((x, i, a) => a.indexOf(x) === i); // unique id:s
};

/**
 * Extract all components that a post uses
 * @param {object} postData
 * @param {array} resources array of resources that the post uses
 * @returns {array} an array of strings that indicates the component types
 */
export const getUsedComponents = (postData, resources) => {
  const getComponents = data => {
    const { type } = data;

    if (isBlockArray(data)) {
      return flattenBlockArrayData(data.data).map(block => block.type);
    }
    return type;
  };

  const postComp = getComponents(postData);
  const resourceComp = resources
    ? Object.keys(resources).map(key => getComponents(resources[key]))
    : [];

  return []
    .concat(postComp, resourceComp)
    .reduce((acc, val) => acc.concat(val), [])
    .filter((x, i, a) => a.indexOf(x) === i);
};
