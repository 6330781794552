import React from "react";

const Preview = ({ size, currentColor }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-preview"
  >
    <path
      fill={currentColor}
      d="M49.4231314,22.8568576 C44.5286001,14.5510417 35.4000411,9 25.0000411,9 C14.5955272,9 5.4691383,14.5550347 0.576950801,22.8567708 C-0.192316934,24.1621388 -0.192316934,25.7822188 0.576950801,27.0875868 C5.47156886,35.3934896 14.6000411,40.9444444 25.0000411,40.9444444 C35.404555,40.9444444 44.5309439,35.3894097 49.4231314,27.0875868 C50.1923092,25.7822254 50.1923092,24.162219 49.4231314,22.8568576 Z M36.1111522,22.5416667 C36.1111522,28.4863715 31.1364994,33.3055556 25.0000411,33.3055556 C18.8635827,33.3055556 13.88893,28.4863715 13.88893,22.5416667 C13.88893,16.5969618 18.8635827,11.7777778 25.0000411,11.7777778 C31.1364994,11.7777778 36.1111522,16.5969618 36.1111522,22.5416667 Z M47.0299022,25.6773437 C42.520614,33.3296007 34.1897112,38.1666667 25.0000411,38.1666667 C15.7733918,38.1666667 7.46002372,33.296441 2.97017997,25.6774306 C2.71385242,25.2422613 2.71385242,24.70227 2.97017997,24.2671007 C5.49379108,19.9847222 9.33658622,16.4168403 14.0517772,14.2074653 C12.2261696,16.4818576 11.1111522,19.3791667 11.1111522,22.5416667 C11.1111522,29.9911458 17.2935307,36.0833333 25.0000411,36.0833333 C32.7057702,36.0833333 38.88893,29.9914931 38.88893,22.5416667 C38.88893,19.3809896 37.7749543,16.4831597 35.9483918,14.2074653 C40.6150584,16.3940104 44.4791209,19.9384549 47.029989,24.2671007 C47.2862557,24.702264 47.2862224,25.242212 47.0299022,25.6773437 Z M19.4444855,22.1944444 C19.4444855,21.2485243 19.6812043,20.3579861 20.0981314,19.5783854 L20.0981314,19.5801215 C20.0981314,20.8434896 21.1223501,21.8677083 22.3857182,21.8677083 C23.6490862,21.8677083 24.673305,20.8434896 24.673305,19.5801215 C24.673305,18.3167535 23.6490862,17.2925347 22.3857182,17.2925347 L22.3839821,17.2925347 C23.1635827,16.8756076 24.0541209,16.6388889 25.0000411,16.6388889 C28.0682702,16.6388889 30.5555966,19.1262153 30.5555966,22.1944444 C30.5555966,25.2626736 28.0682702,27.75 25.0000411,27.75 C21.9318119,27.75 19.4444855,25.2626736 19.4444855,22.1944444 Z"
    />
  </svg>
);

export default Preview;
