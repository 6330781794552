import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import { ChevronDown, Settings, Filter } from "react-feather";

const DropDownBaseComp = styled.div`
  position: relative;
  overflow: visible;
  z-index: 5;
`;

const DropDownBaseContent = styled.div`
  display: none;
  position: absolute;
  box-sizing: border-box;
  min-width: 160px;
  margin: 0;
  padding: 0;
  transform: scale(1);
  left: 0px;
  top: 32px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border: solid 1px ${props => props.theme.neutralLight};
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &:focus {
    outline: none;
  }

  &.isfullwidth {
    width: 100%;
    border-radius: 0;
    top: 40px;
  }

  &.is_active {
    display: inline-block;
    transform: scale(1);
    opacity: 1;
  }
`;

const DropDownBaseButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 32px;
  padding: 0 8px;
  padding-right: 4px;
  margin: 0;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  outline: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  color: ${props => props.theme.neutralPrimary};
  background-color: ${props => props.theme.neutralLighterAlt};
  border: solid 1px ${props => props.theme.neutralQuaternary};

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.neutralPrimary};
  }

  &.isfullwidth {
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    height: 40px;
    justify-content: space-between;
  }

  &.is_active {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.themeDarkAlt};
    border: solid 1px ${props => props.theme.themeDark};

    &:hover,
    &:active,
    &:focus {
      color: ${props => props.theme.white};
    }
  }
`;
const DropDownBaseButtonTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 22px;
  min-width: 1px;
  min-height: 1px;
`;

const DropDownBaseButtonCaret = styled.div`
  color: ${props => props.theme.neutralPrimaryAlt};
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: -2px;
  margin-left: 0;
  vertical-align: top;
  font-size: 20;

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &.is_active {
    color: ${props => props.theme.themeLighterAlt};
  }
`;

class DropDownBase extends React.PureComponent {
  static defaultProps = {
    onToggle: () => {},
    isActive: false,
    fullWidth: false,
    icon: "chevron"
  };

  constructor(props) {
    super(props);

    this.ICONS = {
      CHEVRON: "chevron",
      SETTINGS: "settings",
      FILTER: "filter"
    };
  }

  getIcon = () => {
    const desiredIcon = this.props.icon.toLowerCase();

    if (desiredIcon === this.ICONS.CHEVRON)
      return <ChevronDown color={"currentColor"} size={16} />;
    if (desiredIcon === this.ICONS.SETTINGS)
      return <Settings color={"currentColor"} size={16} />;
    if (desiredIcon === this.ICONS.FILTER)
      return <Filter color={"currentColor"} size={16} />;
  };

  render() {
    const { isActive, titleComponent, onToggle, fullWidth, items } = this.props;

    const DropDownBaseClasses = classNames({
      isfullwidth: fullWidth,
      is_active: isActive
    });

    return (
      <DropDownBaseComp>
        <DropDownBaseButton className={DropDownBaseClasses} onClick={onToggle}>
          <DropDownBaseButtonTitle>{titleComponent}</DropDownBaseButtonTitle>

          <DropDownBaseButtonCaret className={DropDownBaseClasses}>
            <span className="DropDownBase__button__caret">
              {this.getIcon()}
            </span>
          </DropDownBaseButtonCaret>
        </DropDownBaseButton>

        <DropDownBaseContent className={DropDownBaseClasses}>
          {items}
        </DropDownBaseContent>
      </DropDownBaseComp>
    );
  }
}

export default DropDownBase;
