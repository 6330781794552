// This could be included in settings maybe

const filterDataObject = (type, title, key, component, open, filterKey) => ({
  [type]: {
    title: title,
    key: key,
    component: component,
    open: open,
    filterkey: filterKey
  }
});

export const filterdata = {
  ...filterDataObject(
    "SEARCH",
    "Fritextsök",
    "search",
    "SearchBox",
    true,
    "search"
  ),
  ...filterDataObject(
    "LABELS",
    "Labels",
    "labels",
    "SelectGroup",
    true,
    "tags"
  ),
  ...filterDataObject(
    "TEMPLATES",
    "Mallar",
    "templates",
    "ChoiceGroup",
    true,
    "tmpls"
  ),
  ...filterDataObject(
    "DIFFICULTY",
    "Svårighetsgrad",
    "difficulty",
    "ChoiceGroup",
    true,
    "difficulty"
  ),
  ...filterDataObject(
    "COMPONENT",
    "Komponenter",
    "component",
    "ChoiceGroup",
    true,
    "component"
  ),
  ...filterDataObject(
    "STATUS",
    "Status",
    "status",
    "ChoiceGroup",
    true,
    "status"
  ),
  ...filterDataObject(
    "NOTAGS",
    "Saknar tagg",
    "notags",
    "ChoiceGroup",
    true,
    "notags"
  ),
  ...filterDataObject("TAGS", "Tags", "tags", "ChoiceGroup", true, "tags"),
  ...filterDataObject(
    "CHIPS",
    "Koppling",
    "chips",
    "ChoiceGroup",
    true,
    "chips"
  ),
  ...filterDataObject(
    "NOSECTION",
    "Saknar koppling",
    "nosection",
    "ChoiceGroup",
    true,
    "nosection"
  )
};

export const mediaFilter = {
  ...filterDataObject(
    "MEDIATAGS",
    "Mediakategorier",
    "tags",
    "ChoiceGroup",
    true,
    "tags"
  ),
  ...filterDataObject(
    "MEDIALABELS",
    "Labels",
    "labels",
    "SearchDataList",
    true,
    "tags"
  ),
  ...filterDataObject(
    "MEDIATYPES",
    "Mediatyp",
    "type",
    "ChoiceGroup",
    true,
    "type"
  ),
  ...filterDataObject(
    "MEDIASEARCH",
    "Fritextsök",
    "search",
    "SearchBox",
    true,
    "search"
  )
};

/**
 * Order of the Filters including tags for different postTypes.
 */
const filterOrderByTitle = {
  Exercise: [
    "Fritextsök",
    "Status",
    "Labels",
    "Kategorier",
    "Adapt",
    "Komponenter",
    "Svårighetsgrad",
    "Blooms",
    "Bruner"
  ],
  Resource: ["Fritextsök", "Labels", "Komponenter"],
  Template: ["Fritextsök", "Labels", "Komponenter"],
  Media: [
    "Fritextsök",
    "Mediatyp",
    "Labels",
    "Typ",
    "Geometri",
    "Matematik",
    "Småbilder",
    "Algebra",
    "Statistik"
  ],
  Aida: ["Fritextsök", "Status", "Saknar koppling", "System", "Nyckelord"]
};

/**
 * Action for filters
 * @param {*} type route type for post type (exercise, resource, media, mediaresource)
 * @param {*} entityId
 * @param {*} params
 */
export const filterQueryAction = (type, entityId, params) => ({
  type,
  payload: {
    entityId: entityId,
    query: params
  }
});

/**
 * If filter key is a tag return data f as a filter object
 * @param {*} f
 */
export const filterTransformer = f => {
  if (f.key === "tags") {
    return f.data.map(item => ({
      title: item.title,
      key: "tags",
      component: f.component,
      open: f.open,
      data: [item],
      filterkey: f.filterkey
    }));
  }
  if (f.key === "chips") {
    return f.data.map(item => ({
      title: item.title,
      key: "chips",
      component: f.component,
      open: f.open,
      data: [item],
      filterkey: f.filterkey
    }));
  }
  return f;
};

/**
 * Order filters in a specific order according to filterOrderByTitle
 * @param {*} filterArr
 * @param {*} postType
 */
export const orderFilter = (filterArr, postType = "Exercise") => {
  const titleOrder = filterOrderByTitle[postType] || [];
  return filterArr
    .map(a => {
      const prio = titleOrder.indexOf(a.title);
      return { prio: prio >= 0 ? prio : Number.MAX_SAFE_INTEGER, item: a };
    })
    .sort((a, b) => (a.prio < b.prio ? -1 : a.prio > b.prio ? 1 : 0))
    .map(a => a.item);
};

/**
 * Get filter data for postType
 * @param {*} state
 * @param {*} postType
 */
const selectFilterList = (state, postType = "Exercise") =>
  state[postType].get("filters");

/**
 * Get filters, transform it and order them in the order it will be presented.
 * filterList contains filters for all Edda and the current entityId.
 * @param {*} state
 * @param {*} postType
 */
export const selectAndOrderFilterList = (
  state,
  postType
) => {
  const filters = selectFilterList(state, postType);
  const listWithModifiedTagData = flattenList(
    filters.map(filterTransformer)
  );
  return orderFilter(listWithModifiedTagData, postType);
};

/**
 * Flatten arr
 * @param {*} arr
 */
const flattenList = arr => {
  const a = arr.reduce((res, item) => res.concat(item), []);
  return a;
};

export const hasFilters = (query, searchValue) => {
  const hasSearch = query && query.search ? true : false;
  const hasTags = query && query.tags && query.tags.length > 0 ? true : false;
  const hasStatus =
    query && query.status && query.status.length > 0 ? true : false;
  const hasDifficulty =
    query && query.difficulty && query.difficulty.length > 0 ? true : false;
  const hasType = query && query.type && query.type.length > 0 ? true : false;
  const hasComponent =
    query && query.component && query.component.length > 0 ? true : false;
  const hasNoTags =
    query && query.notags && query.notags.length > 0 ? true : false;
  const hasChips = query && query.chips && query.chips.length > 0 ? true : false; 
  const hasNoChips =
    query && query.nosection && query.nosection.length > 0 ? true : false;

  return (
    hasSearch ||
    hasTags ||
    hasStatus ||
    hasDifficulty ||
    hasType ||
    hasComponent ||
    hasNoTags ||
    hasChips || 
    hasNoChips || 
    searchValue
  );
};
