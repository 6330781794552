import { call, put } from "redux-saga/effects";
import * as api from "../api/requests";
import { addFlash } from "../../shared";
import * as actions from "../api/actions";
import * as shared from "../../shared";

export function* onFetchErrors(action) {
  const err = `Error fetching taxonomy${
    action.payload ? `: ${action.payload.toString()}` : ""
  }`;
  yield put(shared.addFlash(err, "error"));
}

export function* onFetchTaxonomyLeves() {
  yield put(actions.fetchTaxonomyLevelsStart());
  try {
    const response = yield call(api.getTaxonomyLevels);
    yield put(actions.fetchTaxonomyLevelsSuccess(response.data.data));
  } catch (err) {
    addFlash("Kunde inte hämta nivåer", "error");
  } finally {
    yield put(actions.fetchTaxonomyLevelsEnd());
  }
}

export function* onFetchTaxonomySubjects(action) {
  yield put(actions.fetchTaxonomySubjectsStart());
  try {
    const response = yield call(api.getTaxonomySubjects(action.payload));
    yield put(actions.fetchTaxonomySubjectsSuccess(response.data.data));
  } catch (err) {
    addFlash("Kunde inte hämta områden", "error");
  } finally {
    yield put(actions.fetchTaxonomySubjectsEnd());
  }
}
