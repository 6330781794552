import { client, longTimeoutClient } from "../../api";
import * as querystring from "querystring";
import { productPostData } from "../containers/FormativeTestsCard/tempdata/productPostData_ex";
import { editQuestionData as questionData } from "../containers/FormativeQuestionsForm/editQuestions_ex";

export const listClassroomGroups = (type, searchParameters) => {
  const params = Object.assign(
    {
      type
    },
    searchParameters,
    {
      sort_orders: searchParameters.sort
        ? Object.keys(searchParameters.sort).map(
            key => `${key}:${searchParameters.sort[key]}`
          )
        : []
    }
  );

  delete params.sort;
  return client.get(`entities?${querystring.stringify(params)}`);
};

export const createClassroomgroup = data =>
  client.post(`entity?type=classroomgroup`, data.data);

export const classroomGroupDetails = classroomGroupEntityId => {
  return client.get(`entity/${classroomGroupEntityId}?type=classroomgroup`);
};

export const updateClassroomgroupDetails = (classroomGroupEntityId, data) => {
  return client.put(`entity/${classroomGroupEntityId}`, data);
};

export const addProductToClassroomGroup = (
  productEntityId,
  classroomGroupEntityId
) =>
  client.put(
    `entity/${classroomGroupEntityId}/classroomgroup/${productEntityId}`
  );

export const deleteProductFromClassroomGroup = (
  productEntityId,
  classroomgroupEntityId
) =>
  client.delete(
    `entity/${classroomgroupEntityId}/classroomgroup/${productEntityId}`
  );

export const publishClassroomGroup = classroomgroupEntityId =>
  client.get(`/entity/${classroomgroupEntityId}/publish`);

/**
 * Fetches the content of post with postId
 * @param {*} classroomGroupEntityId
 * @param {*} postId
 * @returns
 */
export const fetchPreparedAssignments = (classroomGroupEntityId, postId) => {
  return client.get(`/entity/${classroomGroupEntityId}/post/${postId}?type=preparedassignments`);
};

/**
 * Saves data to post with postId
 * @param {Str} data
 * @param {Number} classroomGroupEntityId
 * @param {Number} postId
 * @returns
 */
export const uploadPreparedAssignmentFile = (
  data,
  classroomGroupEntityId,
  postId
) => client.put(`/entity/${classroomGroupEntityId}/post/${postId}`, data);

/**
 * Creates new post with data
 * @param {Str} data
 * @param {Number} classroomGroupEntityId
 * @returns
 */
 export const createPreparedAssignmentFile = (
  data,
  classroomGroupEntityId,
  productId
) => {
  const defaultdata = {
    title: "Untitled",
    description: "prepared asignment",
    type: "preparedassignments",
    metadata: "{}"
  };
  return client.post(`/entity/${classroomGroupEntityId}/post`, {...defaultdata, ...data, secondary_entity_id: productId});
}

/**
 * Downloads content of postId to a file
 * @param {Number} classroomGroupEntityId
 * @param {Number} postId
 * @returns
 */
export const downloadPreparedAssignmentFile = (data) => {
  // RETRIEVE THE BLOB AND CREATE LOCAL URL
  const jsonBlob = new Blob([data.data],{type:'application/json'})
  const jsonFile = document.createElement("a");
  const url = window.URL.createObjectURL(jsonBlob);
  
  // Set name to file, including timestamp
  const timestamp = new Date().toLocaleString();
  jsonFile.download = "prepared assignments " + timestamp + ".json";
  jsonFile.href = url;
  
  // Open file
  jsonFile.click();
};

/**
 * Deletes content in post with postId
 * @param {Number} classroomGroupEntityId
 * @param {Number} postId
 * @returns
 */
export const deletePreparedAssignments = (classroomGroupEntityId, postId, data) => {
  data.data = "[]";
  return client.put(`/entity/${classroomGroupEntityId}/post/${postId}`, data);
};

/**
 * Publishes the posts with postIds
 * @param {Number} classroomGroupEntityId
 * @param {Array} postIds
 * @returns
 */
export const publishPreparedAssignments = (classroomGroupEntityId, postIds) => {
  let data = {
    posts: postIds,
  }
  return client.post(`/entity/${classroomGroupEntityId}/post/publish`, data)
};

// FORMATIVE TESTS

export const fetchFormativeTests = (classroomgroupEntityId, postId) => {
  // change when BE is done
  console.log("request tests", classroomgroupEntityId, postId);
  return productPostData;
};

export const publishFormativeTests = (classroomGroupEntityId, postIds) => {
  //TODO: change this when BE for formative tests is done (SKL-8060).
  return console.log("Publish posts", postIds, classroomGroupEntityId);
};

export const uploadFormativeTestsFile = (
  classroomGroupEntityId,
  postId,
  data
) => {
  // change when BE is done
  return console.log(`Upload formative test to post ${postId}`, data, classroomGroupEntityId);
};

export const downloadFormativeTests = (classroomGroupEntityId, postId) => {
  //TODO: change this when BE for formative tests, SKL-8059 is done.
  return console.log("BE to be implemented for download", postId, classroomGroupEntityId);
};

export const deleteFormativeTests = (classroomGroupEntityId, postId) => {
  //TODO: change this when BE for formative tests, SKL-8059 is done.
  return console.log("delete tests in post ", postId, classroomGroupEntityId);
};

export const deleteFormativeQuestion = (
  classroomGroupEntityId,
  questionId,
  postId
) => {
  //TODO: change this when BE for formative tests, SKL-8061 is done.
  return console.log(`Delete post ${questionId} in post ${postId}`, classroomGroupEntityId);
};

export const createFormativeQuestion = (
  classroomGroupEntityId,
  postId,
  data
) => {
  console.log("Change when BE is done", classroomGroupEntityId, postId, data);
  return { postId: 4 };
};

export const fetchFormativeQuestionData = postId => {
  console.log("Hämta data för formative questions post", postId);
  return { data: questionData }
};

export const updateFormativeQuestionData = (postId, data) => {
  // postId is questionId and data is the new data
  // TODO: add this when BE for formative questions, SKL-8061 is done.
  return console.log("Uppdatera post ", postId, data);
};

export const deleteFormativeQuestionsMediaItem = (postId, mediaId) => {
  // TODO change this when BE for formative tests, SKL-8061 is done.
  return console.log(`Ta bort media ${mediaId} för post ${postId}`);
};

export const deleteFormativeQuestionsMediaSection = (postId, mediaType) => {
  // TODO change this when BE for formative tests, SKL-8061 is done.
  // mediaType is IMAGE or AUDIO
  return console.log(`Ta bort media sektion ${mediaType} i post ${postId}`);
};

export const uploadFormativeQuestionMediaFile = (
  classroomGroupEntityId,
  postId,
  file
) => {
  let formData = new FormData();
  formData.append("file", file);
  console.log(`upload media to post ${postId}`, classroomGroupEntityId);
  return longTimeoutClient
    .post()
    .then(response => response)
    .catch(error => error);
};

export const saveClassroomGroupData = (args, data) => {
  const { classroomGroupEntityId } = args;
  return client.put(`entity/${classroomGroupEntityId}`, args, data);
};

export const fetchClassroomGroupPermissions = () =>
  client.get(`entity-type/classroomgroup/permissions`);

// STICKERS

export const fetchClassroomStickers = classroomGroupEntityId => {
  return client.get(`/entity/${classroomGroupEntityId}/classroomgroup/stickers`);
};

export const deleteStickers = (classroomGroupEntityId, stickerIds) =>
  client.delete(`/entity/${classroomGroupEntityId}/classroomgroup/stickers`, {data: stickerIds});

export const addStickersToClassroomgroup = (classroomGroupEntityId, stickerIds) =>
  client.post(`/entity/${classroomGroupEntityId}/classroomgroup/stickers`, stickerIds);

export const publishStickers = classroomGroupEntityId => {
  return client.get(`/entity/${classroomGroupEntityId}/classroomgroup/stickers/publish`);
};
