import styled from "styled-components"
import Button from "../../Button";


export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 34rem;
    background-color: #fff;
    padding: 20px;
    border-radius: 7px;
`;

export const StyledTitle = styled.p`
    font-size: 1rem;
    margin: 0;
    font-weight: bold;
`

export const StyledButtonWrapper = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: end;
`;

export const StyledText = styled.div`
    margin: 1rem 0;
`

export const StyledButton = styled(Button)`
    color: #0078d7;

    &:hover {
        color: #0078d7;
    }
`