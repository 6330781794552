import * as React from "react";
import htmlToDraft from "html-to-draftjs";
import { BlanksEditor } from "../BlanksEditor";
import { StyledBlockMainContent } from "../../../posts/components/Editor/BlockMainContent/StyledBlockMainContent";
import { TextParser } from "../../../shared/TextTransformerService";
import { ContentState, convertToRaw } from "draft-js";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import {
  DATA,
  FONT_SIZE,
  INPUT_TYPE,
  SETTINGS,
  SIZES,
  SPACE_SENSITIVE,
  PUNCTUATION_MARKS_SENSITIVE,
  CASE_SENSITIVE,
  translation,
  ANSWER_TYPE,
  KEYBOARD_CHARACTER_SET
} from "../../../constants";
import SidebarButton from "../NumberLine/SidebarButton/SidebarButton";
import {
  StyledGridColumn,
  StyledInputTypeInputsContainer,
  StyledInputTypeLabel,
  StyledRowContainer
} from "./StyledBlanks";
import PluginHoc from "../../../shared/PluginHoc";
import ButtonsToggle from "../ButtonsToggle/ButtonsToggle";
import {
  caseSensitiveData,
  dotsSensitiveData,
  SidebarButtonStyles,
  spaceSensitiveData,
  answerTypes,
  tags
} from "./BlanksData";
import DropDown, { Item } from "../../../shared/components/DropDown";

export class Blanks extends React.PureComponent {
  static defaultProps = {
    staticInputWidth: true,
    inputHeight: "small",
    updateData: () => {}
  };

  textHandler;

  constructor(props) {
    super(props);

    this.textHandler = new TextParser(tags);
    this.state = this.getStateFromData(props.data);
  }

  getStateFromData = data => {
    const htmlData = this.transformDataToHTML(data);
    const blocksFromHtml = htmlToDraft(htmlData);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = this.textHandler.getEditorStateFromContentState(
      contentState
    );
    const editorRawdata = convertToRaw(editorState.getCurrentContent());

    return {
      editorState: editorState,
      editorRawdata: editorRawdata
    };
  };

  transformDataToHTML = data => {
    const { text = "", inputs = [] } = data;
    const myReg = /(\{\d+\})/g;

    // handle LaTeX, split on $ and only transform the even parts, i.e. the parts outside LaTeX
    //replace {} with <code>answerString</code> to show where the inputfield are in editview
    let newText = text
      .split("$")
      .map((splitText, index) =>
        index % 2
          ? splitText
          : splitText.replace(myReg, match => {
              let indx = Number(match.slice(1, match.length - 1));
              let answersArr = inputs[indx].correctAnswers;
              let answerStr = answersArr.reduce(
                (str, answer) => str.concat(answer.text + ";"),
                ""
              );
              return (
                "<code>" + answerStr.substr(0, answerStr.length - 1) + "</code>"
              );
            })
      )
      .join("$");

    if (newText.indexOf("<br>") >= 0) {
      newText = "<p>" + newText.split("<br>").join("</p><p>") + "</p>";

      newText = newText.split("<p><h1>").join("<h1>").split("</h1></p>").join("</h1>");
      newText = newText.split("<p><h2>").join("<h2>").split("</h2></p>").join("</h2>");
      newText = newText.split("<p><h3>").join("<h3>").split("</h3></p>").join("</h3>");
    }
    
    return newText;
  };

  /**
   * Parses raw to preview
   * @param raw
   * @returns {{text, inputs}}
   */
  parseRawToData = raw =>
    this.transformTextarrayToData(this.textHandler.parseRawToData(raw));

  parseRawToPreview = raw => ({
    __html:
      "<p>" + this.textHandler.parseRawToData(raw).join("</p><p>") + "</p>"
  });

  transformTextarrayToData = textArray => {
    const myReg = /\{(.*?)\}/gi;
    let questionStr = textArray.join("<br>");
    let indx = 0,
      inputs = [];
    // handle LaTeX, split on $ and only transform the even parts, i.e. the parts outside LaTeX
    let newStr = questionStr
      .split("$")
      .map((text, index) =>
        index % 2
          ? text
          : text.replace(myReg, function(match) {
              let sub = match.substr(1, match.length - 2);
              // remove possible html tags in answer string
              let no = sub.replace(/<(.*?)>/g, "");

              inputs.push({
                correctAnswers: no.split(";").map(item => ({ text: item }))
              });

              return "{" + indx++ + "}";
            })
      )
      .join("$");

    return {
      text: newStr,
      inputs: inputs,
      audio: this.props.data.audio
    };
  };

  transformInputIndicesToText = ({text, inputs}) => {
    const myReg = /\{(\d+)\}/gi;
    return text.split("$").map((text, index) =>
      index % 2
        ? text // inside LaTeX expression, leave this part alone
        : text.replace(myReg, function(match) {
          // replace all input indices with the correct answer, see SKL-9515
          const indx = +(match.substr(1, match.length - 2));
          const correctAnswers = inputs[indx]?.correctAnswers;
          return "{" + correctAnswers.map(item => item?.text).join(";") + "}";
        })
    ).join("$");
  };

  onChange = editorState => {
    const textEditorState = this.textHandler.getStateFromData(
      this.transformInputIndicesToText(this.props.data)
    );
    const { didChange, text } = this.textHandler.onTextChange(
      editorState,
      textEditorState
    );

    const rawdata = convertToRaw(editorState.getCurrentContent());
    const data = this.parseRawToData(rawdata);

    let currentText = this.textHandler.getRaw(editorState);

    const prevEditorState = this.state.editorState;
    const prevrawdata = convertToRaw(prevEditorState.getCurrentContent());
    const prevdata = this.parseRawToData(prevrawdata);

    let change = true;

    if (text) {
      change = text === currentText ? true : false;
    }

    if (
      didChange &&
      change &&
      JSON.stringify(prevdata) !== JSON.stringify(data)
    ) {
      this.props.storeDraft(this.props.draftTarget);
      this.props.updateData(null, data, DATA);
      this.setState(this.getStateFromData(data));
    }
  };

  renderEditMode() {
    const { editorState } = this.state;
    const { pluginHeader, data } = this.props;

    return (
      <div style={{ display: "flex" }} className="studli-blanks">
        <StyledBlockMainContent className="studli-blanks__editor">
          {pluginHeader}
          <BlanksEditor
            editorState={editorState}
            onChange={this.onChange}
            data={data}
          />
          <span>
            <p className="instruct">
              Markera text och klicka på &lt;&nbsp;&gt; knappen för att skapa
              eller ta bort en lucka. Rätt svar blir den markerade texten. Fler
              alternativ separeras med ;-tecken
            </p>
          </span>
        </StyledBlockMainContent>
        {this.renderBlanksSidebar()}
      </div>
    );
  }

  renderReadOnlyMode() {
    // TODO: rewrite for a nicer looking preview
    const { editorRawdata } = this.state;
    const previewData = this.parseRawToPreview(editorRawdata);
    return (
      <div
        className="studli-blanks"
        dangerouslySetInnerHTML={previewData}
      ></div>
    );
  }

  /**
   * Checks if a setting is active
   * @param key
   * @param value
   * @returns {boolean}
   */
  isActiveSetting = (key, value) =>
    this.props.settings && this.props.settings[key] === value;

  /**
   * Sets input type setting
   * @param inputType
   * @returns {function(): void}
   */
  setInputType = inputType => () => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData("inputType", inputType, SETTINGS);
  };

  /**
   * Set font size setting
   * @param fontSize
   */
  setFontSize = fontSize => () => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData("fontSize", fontSize, SETTINGS);
  };
  
  /**
   * Set keyboard character set
   * @param value
   */
  setKeyboard = (keyboardId) => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(KEYBOARD_CHARACTER_SET, keyboardId, SETTINGS);
  }

  /**
   * Handles on change event with predefined keys
   * @param key
   * @returns {function(*=)}
   */
  changeSetting = key => value => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(key, value, SETTINGS);
  };

  /**
   * Checks if a settings is active
   * @param key
   * @returns {*}
   */
  isActive = key => this.props.settings && this.props.settings[key];

  /**
   * Renders a settings row
   * @param title
   * @param data
   * @param setting
   * @returns {*}
   */
  renderSettingRow = (title, data, setting) => (
    <React.Fragment>
      <StyledGridColumn colStart={1}>
        <StyledInputTypeLabel>{title}</StyledInputTypeLabel>
      </StyledGridColumn>
      <StyledGridColumn colStart={2}>
        <ButtonsToggle
          buttonData={data}
          onChange={this.changeSetting(setting)}
          activeIndex={this.props.settings[setting] ? 0 : 1}
        />
      </StyledGridColumn>
    </React.Fragment>
  );

  /**
   * Renders font size inputs
   */
  renderFontSizesButtons = () =>
    SIZES.map(size => (
      <SidebarButton
        key={size}
        callback={this.setFontSize(size)}
        isActive={this.isActiveSetting(FONT_SIZE, size)}
        content={size}
        overrideStyles={SidebarButtonStyles}
      />
    ));

  /**
   * Renders exercise format options
   */
  renderFormatButtons = () =>
    answerTypes.map(({ id, label, value }) => (
      <SidebarButton
        key={id}
        callback={this.setInputType(value)}
        isActive={this.isActiveSetting(INPUT_TYPE, value)}
        content={label}
        overrideStyles={SidebarButtonStyles}
      />
    ));

  /**
   * Renders settings for what the exercise should be sensitive towards
   * @returns {*}
   */
  renderSensitivitySettings = () => (
    <StyledSidebarSection>
      <StyledSidebarHeading backgroundColor={"#35877A"}>
        Känslighet:
      </StyledSidebarHeading>
      <StyledRowContainer>
        {this.renderSettingRow("Skriftläge", caseSensitiveData, CASE_SENSITIVE)}
        {this.renderSettingRow(
          "Mellanrum",
          spaceSensitiveData,
          SPACE_SENSITIVE
        )}
        {this.renderSettingRow(
          "Punkter",
          dotsSensitiveData,
          PUNCTUATION_MARKS_SENSITIVE
        )}
      </StyledRowContainer>
    </StyledSidebarSection>
  );

  /**
   * Renders a settings block
   * @param title
   * @param subTitle
   * @param renderer
   * @returns {*}
   */
  renderSettingsBlock = (title, subTitle, renderer) => (
    <StyledSidebarSection>
      <StyledSidebarHeading backgroundColor={"#35877A"}>
        {title}:
      </StyledSidebarHeading>

      <StyledInputTypeInputsContainer>
        <React.Fragment>
          <StyledInputTypeLabel>{subTitle}</StyledInputTypeLabel>
          {renderer()}
        </React.Fragment>
      </StyledInputTypeInputsContainer>
    </StyledSidebarSection>
  );

  /**
   * Renders dropdown items
   * @param dropDownData
   */
  renderDropDownItems = (dropDownData) => (
    dropDownData.map(({ keyboard_id, keyboard_label }) => (
      <Item key={`item_${keyboard_label}`} value={keyboard_id}>
        {keyboard_label}
      </Item>
  )));

  /**
   * Renders keyboard selection dropdown
   */
  renderKeyboardDropDown = () => (
    <StyledInputTypeInputsContainer>
      <StyledInputTypeLabel>{"Alternativ inmatning"}</StyledInputTypeLabel>
      <DropDown
        defaultValue={this.props.settings.keyboardCharacterSet}
        onChange={this.setKeyboard}
        inSidebar={true}
        overrideStyles={{marginBottom: '6px'}}
        >
      {this.renderDropDownItems(this.props.keyboards)}
      </DropDown>
    </StyledInputTypeInputsContainer>
  );

  /**
   * Render Blanks sidebar
   * @returns {*}
   */
  renderBlanksSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      {this.renderSensitivitySettings()}

      {this.renderSettingsBlock(
        "Format",
        translation[ANSWER_TYPE],
        this.renderFormatButtons
      )}
      {this.renderKeyboardDropDown()}
      {this.renderSettingsBlock(
        "Text och bild",
        "Textstorlek",
        this.renderFontSizesButtons
      )}
    </StyledBlockSidebar>
  );

  render() {
    const { readOnly } = this.props;
    return readOnly ? this.renderReadOnlyMode() : this.renderEditMode();
  }
}

export default PluginHoc({
  Component: Blanks
});
