import React from "react";
import MultiSvgImage from "./MultiSvgImage";
import { isJsonString } from "../../shared/helpers";

/**
 * Render multi svg
 * @param data
 * @param id
 * @param resources
 * @param height
 * @param width
 *
 */
export const renderMultiSVG = (id, resources, height, width) => {
  const mediaresource = resources[id];

  if (mediaresource && mediaresource.data) {
    const res = mediaresource.data.icons
      ? mediaresource.data
      : parseData(mediaresource.data);

    return renderSvgImage(res.icons, res.format, height, width);
  }
  return null;
};

/**
 * Render multisvg image
 * @param {*} icons
 * @param {*} format
 * @param {*} height
 * @param {*} width
 */
export const renderSvgImage = (icons, format, width, height) => (
  <MultiSvgImage icons={icons} format={format} width={width} height={height} />
);

/**
 * Parses the data if needed before returning it
 * @param data
 */
const parseData = data => {
  if (isJsonString(data)) {
    return JSON.parse(data).data;
  } else {
    return data.data;
  }
};
