import { generalErrorHandler } from "../../api";
import {
  ACTION_FETCH_PRODUCT_DETAILS_FAILED,
  fetchProductDetails,
  fetchProductDetailsEnd,
  fetchProductDetailsFailed,
  fetchProductDetailsStart,
  fetchProductDetailsSuccess,
  fetchProducts,
  fetchProductsEnd,
  fetchProductsFailed,
  fetchProductsStart,
  fetchProductsSuccess,
  fetchProductStatusSummary,
  fetchProductStatusSummaryStart,
  fetchProductStatusSummarySuccess,
  fetchProductStatusSummaryFailed,
  fetchProductStatusSummaryEnd,
  fetchProductSettingsStart,
  fetchProductSettingsSuccess,
  fetchProductSettingsFailed,
  fetchProductSettingsEnd,
  saveProductStart,
  saveProductFailed,
  saveProductEnd,
  addToTeachersGuideSuccess,
  removeFromTeachersGuideSuccess,
  activateProduct,
  fetchProductPublishFilesStatus,
  fetchProductPublishFilesStatusSuccess,
  setPublishingProdStatus,
  setPublishingPreviewStatus,
  fetchProductPublishHistorySuccess,
  stopProductBuildStatusWatcher,
  fetchAvailableSesamgroupsSuccess,
  fetchProductFilesStart,
  fetchProductFilesSuccess,
  fetchProductFilesEnd,
  fetchProductFiles
} from "../api/actions";
import { onFetchSupportSystemDetails } from "../../systems/sagas/sagas";
import { call, put, race, select, take, delay } from "redux-saga/effects";
import * as api from "../api/requests";
import * as sharedModule from "../../shared";
import { ROUTE_PRODUCT } from "../routes";
import {
  getAllProductsListLength,
  selectProductFilesStatus,
  getProductTaxonomyLevels,
} from "../store/selectors";
import {
  adaptNavigation,
  mainNavigation,
  setSections,
  addFlash,
} from "../../shared";
import { setSelectedProductId } from "../../shared/store/navigation/actions";
import {
  selectEntityId,
  selectIsInSystem,
  selectPrePath,
} from "../../store/selectors";
import {
  fetchTaxonomyLevels,
  fetchTaxonomySubjects,
} from "../../taxonomy/api/actions";

export function* onFetchError(action) {
  yield put(
    sharedModule.addFlash(
      `Error when fetching products${
        action.error ? ": " + action.error.toString() : ""
      }`,
      "error"
    )
  );
}

export function* onProductActivated(action) {
  yield put(sharedModule.setTitle(action.entity.title));
  yield put(setSelectedProductId(action.entity.id));
  yield put({ type: ROUTE_PRODUCT, payload: { entityId: action.entity.id } });
}

export function* onFetchProducts() {
  yield put(fetchProductsStart());
  try {
    const result = yield call(api.listProducts);
    yield put(fetchProductsSuccess(result.data.data, result.data.metadata));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchProductsFailed(error));
  } finally {
    yield put(fetchProductsEnd());
  }
}

export function* onFetchProductDetails(action) {
  yield put(fetchProductDetailsStart());

  try {
    const result = yield call(api.getProductDetails, action.entityId);
    yield put(
      fetchProductDetailsSuccess(result.data.data, result.data.metadata)
    );
    const types = yield select((state) => state.Product.get("types"));
    const productType = types.find(
      (t) => t.get("id") === result.data.data.entity.typeId
    );
    yield put(sharedModule.setTitle(result.data.data.entity.sesam_name));

    if (!productType) {
      yield call(setNavigation, "adapt");
    } else {
      yield call(setNavigation, productType.get("type"));
    }
    yield put(fetchTaxonomyLevels());
    const levels = yield select(getProductTaxonomyLevels);
    yield put(fetchTaxonomySubjects(levels.map((l) => l.id)));
    return true;
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchProductDetailsFailed(error.response));
  } finally {
    yield put(fetchProductDetailsEnd());
  }
}

export function* onFetchProductStatusSummary(action) {
  yield put(fetchProductStatusSummaryStart());

  try {
    const result = yield call(api.getProductStatusSummary(action.entityId));
    yield put(
      fetchProductStatusSummarySuccess(result.data.data, result.data.metadata)
    );
    return true;
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchProductStatusSummaryFailed(error.response));
  } finally {
    yield put(fetchProductStatusSummaryEnd());
  }
}

/**
 * Always fetches status summary & fetches product details only when routing.
 * @param {*} action
 */
export function* onProductRoute(action) {
  yield put(fetchProductStatusSummary(action.payload.entityId));

  if (action.meta && action.meta.location.kind !== "load") {
    yield put(fetchProductDetails(action.payload.entityId));
  }

  yield put(fetchProductFiles(action.payload.entityId));

  yield put(fetchProductPublishFilesStatus(action.payload.entityId));

  const productsLength = yield select(getAllProductsListLength);
  if (productsLength === 0) {
    yield put(fetchProducts());
  }
}

export function* onProductListRoute() {
  yield call(setNavigation, "adapt");
}

export function* applicationEnsureProduct() {
  const prePath = yield select(selectPrePath);
  if (!(prePath === "products" || prePath === "system")) {
    return;
  }
  const entityId = yield select(selectEntityId);
  const isInASystem = yield select(selectIsInSystem);

  if (isInASystem && entityId) {
    yield call(onFetchSupportSystemDetails, { payload: { entityId } });
    return;
  }
  if (entityId === undefined) {
    yield put(setSections(mainNavigation));
    return;
  }
  const { success, failed } = yield race({
    success: call(onFetchProductDetails, { entityId }),
    failed: take(ACTION_FETCH_PRODUCT_DETAILS_FAILED),
  });
  if (success) {
    const product = yield select((s) => s.Product.get("selectedItem"));
    yield put(sharedModule.setTitle(product.sesam_name));
    yield put(sharedModule.setSelectedProductId(entityId));
  }
  if (failed) {
    console.log(failed);
  }
}

export function* onFetchProductSettings() {
  yield put(fetchProductSettingsStart());

  try {
    const result = yield call(api.getProductSettings);
    yield put(fetchProductSettingsSuccess(result.data.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchProductSettingsFailed(error));
  } finally {
    yield put(fetchProductSettingsEnd());
  }
}

export function* onFetchProductFiles({ entityId }) {
  yield put(fetchProductFilesStart());

  try {
    const result = yield call(api.getProductFiles, entityId);
    yield put(fetchProductFilesSuccess(result.data.data));
  } catch (error) {
    yield generalErrorHandler(error);
  } finally {
    yield put(fetchProductFilesEnd());
  }
}

function* setNavigation(productType) {
  if (productType === "adapt") {
    yield put(setSections(adaptNavigation));
    return;
  }

  yield put(setSections(mainNavigation));
  return;
}

export function* onSaveProduct(action, msg, cover = false) {
  yield put(saveProductStart());

  const prod = {
    ...action.data,
    subjectId: action.data.subjectId || null,
    topicId: action.data.topicId || null,
    stageId: action.data.stageId || null,
  };

  if (!cover) {
    const product = yield select((state) => state.Product.get("selectedItem"));
    prod.coverUrl = product.coverUrl;
  }

  try {
    yield call(api.saveProduct, prod);
    yield put(
      addFlash(msg || `${action.data.title} har blivit sparad.`, "success")
    );
    yield put(fetchProductDetails(prod.id));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(saveProductFailed(error));
    yield put(
      addFlash(`Något gick fel när ${action.data.title} sparades.`, "error")
    );
  } finally {
    yield put(saveProductEnd(prod));
  }
}

export function* onSaveCoverImg({ file }) {
  try {
    const { data } = yield call(api.uploadCoverImg, file);

    const product = yield select((state) => state.Product.get("selectedItem"));

    yield call(
      onSaveProduct,
      {
        data: {
          ...product,
          coverUrl: data.data.coverUrl,
        },
      },
      "Omslagsbilden har sparats.",
      true
    );
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när omslagsbild för produkten skulle sparades.`,
        "error"
      )
    );
  }
}

export function* onDisconnectTwin({ payload: { entityId, twinId } }) {
  try {
    yield call(api.deleteTwins, entityId, twinId);

    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(
      addFlash(`Något gick fel när tvilling kopplingen togs bort.`, "error")
    );
  }
}

export function* onConnectTwin({ payload: { entityId, twinId } }) {
  try {
    yield call(api.setTwins, entityId, twinId);

    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(
      addFlash(`Något gick fel när tvilling kopplingen skulle skapas.`, "error")
    );
  }
}

export function* onSetProductSettings({ payload: { entityId, settings } }) {
  try {
    yield call(api.setProductSettings, entityId, settings);

    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när inställningar till en produkt skulle skapas.`,
        "error"
      )
    );
  }
}

export function* onSetProductFiles({ payload: { entityId, files } }) {
  try {
    yield call(api.setProductFiles, entityId, files);
    yield call(onFetchProductFiles, { entityId });
    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(addFlash(`Något gick fel när media skulle länkas.`, "error"));
  }
}

export function* onCreateProductsRelation({
  payload: { entityId, relatedProductId }
}) {
  try {
    yield call(api.addProductsRelation, entityId, relatedProductId);

    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när relation mellan produkterna skapades.`,
        "error"
      )
    );
  }
}

export function* onRemoveProductsRelation({
  payload: { entityId, relatedProductId },
}) {
  try {
    yield call(api.removeProductsRelation, entityId, relatedProductId);

    yield call(onFetchProductDetails, { entityId });
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när relation mellan produkterna togs bort.`,
        "error"
      )
    );
  }
}
export function* onRemoveProductFromTeachersGuide({
  payload: { teachersGuideId, entityId },
}) {
  try {
    yield call(api.removeProductFromTeachersGuide, teachersGuideId, entityId);

    yield put(removeFromTeachersGuideSuccess(teachersGuideId, entityId));
  } catch (err) {
    console.log(err);
    yield put(
      addFlash(
        `Något gick fel när produkten skulle läggas till i lärarhandledningen.`,
        "error"
      )
    );
  }
}

export function* onAddProductToTeachersGuide({
  payload: { teachersGuideId, entityId },
}) {
  try {
    yield call(api.addProductToTeachersGuide, teachersGuideId, entityId);

    yield put(addToTeachersGuideSuccess(teachersGuideId, entityId));
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när produkten skulle tas bort från lärarhandledningen.`,
        "error"
      )
    );
  }
}

export function* onCreateProduct(data) {
  try {
    const result = yield call(api.createProduct, data);
    const product = yield call(api.getProductDetails, result.data.data);

    yield put(activateProduct(product.data.data.entity));
    yield call(onFetchProducts);
    yield put(addFlash(`Produkten har skapats`, "success"));
  } catch (error) {
    yield put(addFlash(`Något gick fel när produkten skulle skapas.`, "error"));
  }
}

export function* onSetProductCommercial({ payload: { entityId } }) {
  try {
    yield call(api.setProductCommercial, entityId);
    yield put(addFlash(`Produkten är nu satt som säljbar!`, "success"));
    yield put(fetchProductDetails(entityId));
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när produkten skulle sättas som säljbar.`,
        "error"
      )
    );
  }
}

export function* onSetProductDiscontinued({ payload: { entityId } }) {
  try {
    yield call(api.setProductDiscontinued, entityId);
    yield put(addFlash(`Produkten är nu nedlagd!`, "success"));
    yield put(fetchProductDetails(entityId));
  } catch (err) {
    yield put(
      addFlash(`Något gick fel när produkten skulle läggas ner.`, "error")
    );
  }
}

export function* onPublishProduct({ payload: { entityId } }) {
  try {
    yield call(api.publishProduct, entityId);
    yield put(addFlash(`Produkten är nu publicerad!`, "success"));
    yield put(fetchProductDetails(entityId));
  } catch (err) {
    yield put(
      addFlash(`Något gick fel när produkten skulle publiceras.`, "error")
    );
  }
}

export function* onPublishProductFiles({ payload: { entityId, env } }) {
  const fileStatus = yield select(selectProductFilesStatus);
  if (env === "preview" && fileStatus["preview"].status === "active") {
    yield put(addFlash(`Publicering pågår fortfarande sen tidigare`, "info"));
    return;
  }
  if (env === "prod" && fileStatus["preview"].pubstate !== "unpublished") {
    yield put(addFlash(`Finns inget att publicera`, "info"));
    return;
  }
  try {
    yield call(api.publishProductFiles, entityId, env);
    if (env === "prod") {
      yield put(setPublishingProdStatus(true));
    } else {
      yield put(setPublishingPreviewStatus(true));
    }
    yield put(addFlash(`Publicering startad!`, "success"));
  } catch (err) {
    yield put(
      addFlash(`Något gick fel när produkten skulle publiceras.`, "error")
    );
  }
}

export function* onImportProduct({ payload: { sesamName } }) {
  try {
    const result = yield call(api.importProduct, sesamName);
    yield put(activateProduct(result.data.data.product));
    yield call(onFetchProducts);
    yield put(addFlash(`Produkten har importerats`, "success"));
  } catch (error) {
    if (error.response.data === "Product with sesam name already exists") {
      yield put(
        addFlash(`En produkt med sesamnamnet ${sesamName} finns redan.`, "info")
      );
    } else {
      yield put(
        addFlash(
          `Produkten kunde inte importeras. Kontrollera att sesamgruppen är giltig.`,
          "info"
        )
      );
    }
  }
}

export function* onFetchProductPublishFilesHistory(action) {
  try {
    const pubHistory = yield call(
      api.fetchPublishHistory(action.payload.entityId)
    );
    yield put(fetchProductPublishHistorySuccess(pubHistory.data));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* pollBuildStatusTask() {
  while (true) {
    try {
      const entityId = yield select(selectEntityId);
      if (!entityId) {
        return;
      }
      // Fetching publish status at regular interval.
      const result = yield call(api.fetchPublishFileStatus(entityId));
      yield put(fetchProductPublishFilesStatusSuccess(result.data));
      yield delay(10000);
    } catch (error) {
      yield generalErrorHandler(error);
      // Once the polling has encountered an error,
      // it should be stopped immediately
      yield put(stopProductBuildStatusWatcher(error));
    }
  }
}

export function* onFetchAvailableSesamgroups() {
  try {
    const result = yield call(api.getAvailbleSesamgroups);
    yield put(fetchAvailableSesamgroupsSuccess(result.data));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onSetProductTaxonomyLevels(action) {
  const { entityId, levelIds } = action.payload;
  try {
    yield call(api.setProductTaxonomyLevels(entityId, levelIds));
  } catch (error) {
    yield put(addFlash(`Något gick fel när nivåerna skulle sparas.`, "error"));
  } finally {
    yield put(fetchProductDetails(entityId));
  }
}

export function* onSetProductTaxonomySubjects(action) {
  const { entityId, subjectIds } = action.payload;
  try {
    yield call(api.setProductTaxonomySubjects(entityId, subjectIds));
  } catch (error) {
    yield put(addFlash(`Något gick fel när områden skulle sparas.`, "error"));
  } finally {
    yield put(fetchProductDetails(entityId));
  }
}
