import * as React from "react";
import {
  StyledRadioButton,
  StyledRadioButtonContainer,
  StyledLabel
} from "./StyledRadioButton";

export const RadioButton = ({
  callback,
  isActive,
  overrideStyles,
  buttonStyle,
  content,
  label = false,
  title
}) => {
  /**
   * Handles click callback
   * @returns {null}
   */
  const handleClick = () => (callback ? callback() : null);

  /**
   * Renders button
   * @returns {*}
   */
  const renderButton = () => (
    <StyledRadioButton
      title={title ? title : null}
      style={buttonStyle}
      isActive={isActive}
      onClick={handleClick}
      overrideStyles={overrideStyles}
    >
      {content}
    </StyledRadioButton>
  );

  /**
   * Renders label
   * @returns {null}
   */
  const renderLabel = () => (label ? <StyledLabel>{label}</StyledLabel> : null);

  return (
    <StyledRadioButtonContainer overrideStyles={overrideStyles}>
      {renderButton()}
      {renderLabel()}
    </StyledRadioButtonContainer>
  );
};
