import * as React from "react";
import PluginHoc from "../../../shared/PluginHoc";
import {
  StyledSortMachine,
  StyledSortMachineContent,
  StyledPrimaryButton,
  StyledEditInputWrapper,
  StyledAddNewItemRow,
  StyledAnswerBoxesContainer,
  StyledAnswerBox,
  StyledAddNewItemWrapper,
  StyledBox,
  StyledBoxLabel,
  StyledBoxValue,
  StyledClearButtonWrapper,
  StyledClearButton,
  StyledBoxContentWrapper,
  StyledAddBox,
  StyledAddBoxLabel,
  StyledBoxAddButtonWrapper,
  StyledBoxAddButton,
  StyledMediaResourceWrapper,
  StyledAnswerBoxEditMediaWrapper
} from "./StyledSortMachine";
import {
  DATA,
  BOXES,
  ITEMS,
  IMAGE,
  LABEL,
  UNSORTED_BOX
} from "../../../constants";
import { ItemContainer, SortMachineItem } from "./SortMachineItem";
import { Input } from "../Input";
import SortMachineBoxLabel from "./SortMachineBoxLabel";
import {
  getMedia,
  renderEditMedia,
  renderMedia,
  mediaresourceStructure
} from "../../../shared/MediaHandler/MediaHandler";
import {
  boxesData,
  totalItemsInBox,
  rowSettingStyles,
  settingsData
} from "./SortMachineData";
import { guid } from "../../../shared/helpers";
import SideBar from "../Sidebar/Sidebar";

const SortMachine = props => {
  const [label, setQuestionLabel] = React.useState("");
  const [updatedItem, setUpdatedItem] = React.useState(false);
  const [mediaresources, setMediaResources] = React.useState({});
  const { settings, data, updateData, storeDraft, draftTarget } = props;
  const chipsRef = React.useRef(settings.chips);
  /**
   * This Effect listens to changes done to the chips setting.
   */
  React.useEffect(() => {
    if (settings.chips !== chipsRef.current) {
      chipsRef.current = settings.chips;
      resetAllItems(data.boxes, false);
    }
    // eslint-disable-next-line
  }, [settings.chips]);

  /**
   * Save question label to state
   * @param questionLabel
   */
  const onQuestionLabelChange = questionLabel =>
    setQuestionLabel(questionLabel);

  /**
   * Sets ID's for items
   * @param items
   */
  const setIdsForItems = items =>
    items.map((item, index) => ({ ...item, id: "id_" + index }));

  /**
   * Sets initial index for items
   * @param {*} items
   */
  const setInitalIndexForItems = items =>
    items.map((item, index) => ({ ...item, initialIndex: index }));

  /**
   * Adds the next available box & returns a sorted array
   */
  const findNextBox = () => {
    const newBoxes = [...props.data.boxes];
    for (let i = 0; i < boxesData.length; i++) {
      if (!newBoxes.some(box => box.id === boxesData[i].id)) {
        return [...newBoxes, { ...boxesData[i] }].sort((a, b) =>
          a.value.localeCompare(b.value)
        );
      }
    }
    return newBoxes;
  };

  /**
   * When adding/removing a box or if the chips settings change,
   * then we remove all box associations and restore to default.
   * @param {*} boxesListq
   */
  const resetAllItems = (boxesList, doStoreDraft) => {
    const maxItemsInBox = totalItemsInBox[settings.chips][boxesList.length];
    const boxes = boxesList.map(box => ({ ...box, totalCorrectItems: 0 }));
    const items = data.items.map(item => ({
      ...item,
      initialBox: UNSORTED_BOX,
      value: UNSORTED_BOX
    }));

    if (doStoreDraft) {
      storeDraft(draftTarget);
    }
    updateData(
      null,
      { boxes: boxes, items: items, maxItemsInBox: maxItemsInBox },
      DATA
    );
  };

  /**
   * Checks if there is more room for boxes
   */
  const canAddMoreBoxes = () =>
    props.data.boxes.length < boxesData.length ? true : false;

  /**
   * Add box to boxes array
   */
  const addBox = () =>
    canAddMoreBoxes() ? resetAllItems(findNextBox(), true) : null;

  /**
   * Remove a box or clears its data if it's the last one.
   * @param {*} index
   */
  const removeBox = index => () => {
    const newBoxes = [...props.data.boxes];
    if (newBoxes.length > 1) {
      newBoxes.splice(index, 1);
    }

    renameBoxes(newBoxes);
    resetAllItems(newBoxes, true);
  };

  /**
   * Rename boxes and change id based on their current position
   * @param {*} boxes
   */
  const renameBoxes = boxes => {
    boxes.forEach((box, index) => {
      box.id = boxesData[index].id;
      box.value = boxesData[index].value;
    });
  };

  /**
   * Displays alert when box is full
   * @param {*} name
   */
  const showAlert = name =>
    props.infoFlash(
      `Box: ${name} är full, lägg till en ny låda eller ändra boxens innehåll.`
    );

  /**
   * Opens media resource list
   */
  const openMediaResourceList = () => {
    const { openMediaresourceModal = () => {} } = props.actions || {};
    openMediaresourceModal(res => {
      addNewItem({ multiSVG: mediaresourceStructure(res[0]) });
      setMediaResources({ ...mediaresources, [res[0].id]: res[0].data });
    });
  };

  /**
   * Opens media library
   */
  const openMediaLibrary = () => {
    const { openMediaModal = () => {} } = props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === IMAGE) {
        addNewItem({ image: { src: selected[0].url } });
      }
    });
  };

  /**
   * open media library and replace image or mediaresource with a new media
   * @param index index of item to replace image in
   */
  const onChangeMedia = (index, type) => {
    const { openMediaModal = () => {} } = props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === IMAGE) {
        type === BOXES
          ? onBoxImageChange(index, selected[0])
          : onItemImageChange(index, selected[0]);
      }
    });
  };

  /**
   * Open mediaresource library and replaces image or mediaresource with a new mediaresource
   * @param index index of item to replace mediaresource in
   */
  const onChangeMediaResource = (index, type) => {
    const { openMediaresourceModal = () => {} } = props.actions || {};
    openMediaresourceModal(res => {
      if (res.length > 0) {
        type === BOXES
          ? onBoxImageChange(index, mediaresourceStructure(res[0]))
          : onItemImageChange(index, mediaresourceStructure(res[0]));
        setMediaResources({ ...mediaresources, [res[0].id]: res[0].data });
      }
    });
  };

  /**
   * Adds new item
   * @param content
   */
  const addNewItem = content => {
    if (label || content.image || content.multiSVG) {
      const { items } = props.data;
      let newItem = {
        initialBox: UNSORTED_BOX,
        value: UNSORTED_BOX
      };

      if (label && !(content.image || content.multiSVG)) {
        newItem[LABEL] = label;
      }
      if (content.image) {
        newItem[IMAGE] = content.image;
      }
      if (content.multiSVG) {
        newItem = { ...newItem, ...content.multiSVG };
      }

      setQuestionLabel("");
      storeDraft(draftTarget);
      props.updateData(
        ITEMS,
        setInitalIndexForItems(items.concat({ ...newItem, id: guid() })),
        DATA
      );
    }
  };

  /**
   * Return Box index by value
   * @param {*} id
   * @returns index or -1 if not found
   */
  const findBoxIndexByValue = (value, boxes) =>
    boxes.findIndex(box => box.value === value);

  /**
   * Updates the param totalCorrectItems for a specific box by index, from content or item value
   * @param {*} content holds incoming value
   * @param {*} item holds previous value
   */
  const modifyTotalCorrectItemsForBox = (content, item) => {
    const { boxes } = props.data;
    const newAssociateBoxIndex = findBoxIndexByValue(
      content && content.value,
      boxes
    );
    const prevAssociateBoxIndex = findBoxIndexByValue(
      item && item.value,
      boxes
    );

    if (newAssociateBoxIndex !== -1) {
      var newAssociateModifiedBox = boxes.splice(newAssociateBoxIndex, 1);
      newAssociateModifiedBox[0].totalCorrectItems =
        newAssociateModifiedBox[0].totalCorrectItems + 1;
      boxes.splice(newAssociateBoxIndex, 0, newAssociateModifiedBox[0]);
    }

    if (prevAssociateBoxIndex !== -1) {
      var prevAssociateModifiedBox = boxes.splice(prevAssociateBoxIndex, 1);
      prevAssociateModifiedBox[0].totalCorrectItems =
        prevAssociateModifiedBox[0].totalCorrectItems - 1;
      boxes.splice(prevAssociateBoxIndex, 0, prevAssociateModifiedBox[0]);
    }

    if (newAssociateBoxIndex !== 1 && prevAssociateBoxIndex !== -1) {
      storeDraft(draftTarget);
      props.updateData(BOXES, boxes, DATA);
    }
  };

  /**
   * Handles edit item content
   * @param index
   * @param content
   */
  const editItemContent = (index, content) => {
    const { items, boxes } = props.data;
    let item = { ...items[index] };
    const totalItems =
      totalItemsInBox[props.settings.chips][props.data.boxes.length];

    if (content.value !== undefined) {
      const boxIndex = findBoxIndexByValue(content && content.value, boxes);
      const totalCorrectItems =
        props.data.boxes[boxIndex] &&
        props.data.boxes[boxIndex].totalCorrectItems;

      if (totalCorrectItems < totalItems || !totalCorrectItems) {
        modifyTotalCorrectItemsForBox(content, item);
        item.value = content.value;
      } else {
        showAlert(content.value);
        return;
      }
    }
    if (content.label !== undefined) {
      item.label = content.label;
    }
    if (content.initialBox !== undefined) {
      const values = items.filter(
        elem => elem.initialBox === content.initialBox
      );
      if (values.length < totalItems || content.initialBox === UNSORTED_BOX) {
        item.initialBox = content.initialBox;
      } else {
        showAlert(content.initialBox);
        return;
      }
    }

    storeDraft(draftTarget);
    props.updateData(
      ITEMS,
      items.map((element, i) => (i === index ? item : element)),
      DATA
    );
  };

  /**
   * Handles edit box content
   * @param index
   * @param content
   */
  const editBoxContent = (index, content) => {
    const { boxes } = props.data;
    let newBox = { ...boxes[index] };

    if (content.value !== undefined) {
      newBox.value = content.value;
    }
    if (content.label !== undefined) {
      newBox.label = content.label;
    }

    storeDraft(draftTarget);
    props.updateData(
      BOXES,
      boxes.map((item, i) => (i === index ? newBox : item)),
      DATA
    );
  };

  /**
   * Handles image item change
   * @param index
   * @param media
   */
  const onItemImageChange = (index, media) => {
    const { items } = props.data;
    let newItem = { ...items[index] };

    delete newItem.label;

    if (media.mediaresource) {
      newItem = { ...newItem, ...media };
      if (newItem.image) {
        delete newItem.image;
      }
      storeDraft(draftTarget);
      props.updateData(
        ITEMS,
        setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
        DATA
      );
    }
    if (media.url) {
      newItem.image = {
        src: media.url
      };
      if (newItem.mediaresource) {
        delete newItem.mediaresource;
      }

      storeDraft(draftTarget);
      props.updateData(
        ITEMS,
        setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
        DATA
      );
    }
  };

  /**
   * Handles image box change
   * @param index
   * @param media
   */
  const onBoxImageChange = (index, media) => {
    const { boxes } = props.data;

    var modifiedBox = boxes.splice(index, 1);
    modifiedBox[0].label = "";

    if (media.mediaresource) {
      modifiedBox[0].mediaresource = media.mediaresource;
      if (modifiedBox[0].image) {
        delete modifiedBox[0].image;
      }
      boxes.splice(index, 0, modifiedBox[0]);
      storeDraft(draftTarget);
      props.updateData(BOXES, boxes, DATA);
    }
    if (media.url) {
      modifiedBox[0].image = { src: media.url };
      if (modifiedBox[0].mediaresource) {
        delete modifiedBox[0].mediaresource;
      }
      boxes.splice(index, 0, modifiedBox[0]);
      storeDraft(draftTarget);
      props.updateData(BOXES, boxes, DATA);
    }
  };

  /**
   * Duplicates item with index
   * @param index
   */
  const onDuplicateItem = index => {
    const items = [...props.data.items];
    const content = { ...items[index] };
    const { boxes } = props.data;

    const boxIndex = findBoxIndexByValue(content && content.value, boxes);
    const totalCorrectItems =
      props.data.boxes[boxIndex] &&
      props.data.boxes[boxIndex].totalCorrectItems;
    const totalItems =
      totalItemsInBox[props.settings.chips][props.data.boxes.length];
    const values = items.filter(elem => elem.initialBox === content.initialBox);

    const checkInitialBox =
      values.length < totalItems || content.initialBox === UNSORTED_BOX;
    const checkTotalItems =
      totalCorrectItems < totalItems || !totalCorrectItems;

    if (checkInitialBox && checkTotalItems) {
      modifyTotalCorrectItemsForBox(content, null);

      items.splice(index, 0, { ...props.data.items[index], id: guid() });
      storeDraft(draftTarget);
      props.updateData(ITEMS, setInitalIndexForItems(items), DATA);
    } else {
      showAlert(checkTotalItems ? content.initialBox : content.value);
    }
  };

  /**
   * Function that runs after items has moved.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...props.data.items];
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);
    setUpdatedItem(true);

    storeDraft(draftTarget);
    props.updateData(ITEMS, setInitalIndexForItems(items), DATA);
  };

  /**
   * Removes item
   * @param index
   */
  const removeItem = index => {
    const { items } = props.data;
    const item = { ...items[index] };

    setUpdatedItem(true);
    modifyTotalCorrectItemsForBox(null, item);

    storeDraft(draftTarget);
    props.updateData(
      ITEMS,
      setIdsForItems(items.filter((_, i) => i !== index)),
      DATA
    );
  };

  const callbackUpdatedItem = () => setUpdatedItem(false);

  /**
   * Render items
   */
  const renderItems = () => {
    const {
      actions,
      resources: propsMediaResources,
      data: { items, boxes }
    } = props;
    const resources = { ...propsMediaResources, ...mediaresources };

    return items
      ? items.map((item, i) => (
          <SortMachineItem
            key={"alt" + i}
            indx={i}
            index={i}
            data={item}
            boxes={boxes}
            actions={actions}
            onChangeFn={editItemContent}
            onRemoveFn={removeItem}
            resources={resources}
            onImageChange={onItemImageChange}
            editBtnFn={editItemContent}
            onMediaChange={onChangeMedia}
            onMediaResourceChange={onChangeMediaResource}
            onDuplicateItem={onDuplicateItem}
            onRemoveImage={removeItemImage}
            storeDraft={props.storeDraft}
            draftTarget={props.draftTarget}
            callbackUpdatedItem={callbackUpdatedItem}
            updatedItem={updatedItem}
          />
        ))
      : null;
  };

  /**
   * Removes image / mediaresource from box
   */
  const removeBoxImage = index => () => {
    const { boxes } = props.data;

    var modifiedBox = boxes.splice(index, 1);
    delete modifiedBox[0].image;
    delete modifiedBox[0].mediaresource;

    boxes.splice(index, 0, modifiedBox[0]);
    storeDraft(draftTarget);
    props.updateData(BOXES, boxes, DATA);
  };

  /**
   * Removes image / mediaresource from item
   */
  const removeItemImage = index => () => {
    const { items } = props.data;
    let newItem = { ...items[index], label: "" };

    delete newItem.image;
    delete newItem.mediaresource;

    storeDraft(draftTarget);
    props.updateData(
      ITEMS,
      setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
      DATA
    );
  };

  /**
   * render the value box & media part if media exists.
   * @param media
   */
  const renderValueBox = () =>
    props.data.boxes.map((element, index) => {
      const { resources: propsMediaResources } = props;
      const resources = { ...propsMediaResources, ...mediaresources };
      const media = getMedia(element, resources);
      const { id, value, label } = element;

      return (
        <StyledAnswerBox key={id}>
          {media.mediaComp ? renderMediaResource(media, index) : null}
          {renderBox(value, index)}
          {media.mediaComp ? (
            <StyledAnswerBoxEditMediaWrapper>
              {renderEditMedia(
                media,
                index,
                false,
                BOXES,
                onChangeMedia,
                onChangeMediaResource
              )}{" "}
            </StyledAnswerBoxEditMediaWrapper>
          ) : null}
          {media.mediaComp ? null : renderBoxLabel(index, label)}
        </StyledAnswerBox>
      );
    });

  /**
   * Render mediaresource file
   * @param {*} media
   * @param {*} index
   */
  const renderMediaResource = (media, index) => (
    <StyledMediaResourceWrapper>
      {renderMedia(media, removeBoxImage(index))}
    </StyledMediaResourceWrapper>
  );

  const renderBox = (value, index) => (
    <StyledBox>
      <StyledBoxContentWrapper>
        <StyledBoxLabel>{"Låda " + value}</StyledBoxLabel>
        <StyledClearButtonWrapper>
          <StyledClearButton
            onClick={removeBox(index)}
            studlicon={"Trash"}
          ></StyledClearButton>
        </StyledClearButtonWrapper>
      </StyledBoxContentWrapper>
      <StyledBoxContentWrapper>
        <StyledBoxLabel>{"Alternativ"}</StyledBoxLabel>
        <StyledBoxValue>
          {props.data.boxes[index].totalCorrectItems +
            "/" +
            totalItemsInBox[props.settings.chips][props.data.boxes.length]}
        </StyledBoxValue>
      </StyledBoxContentWrapper>
    </StyledBox>
  );

  const renderAddBox = () => (
    <StyledAddBox>
      <StyledBoxAddButtonWrapper>
        <StyledBoxAddButton
          onClick={addBox}
          studlicon={"Plus"}
        ></StyledBoxAddButton>
      </StyledBoxAddButtonWrapper>
      <StyledAddBoxLabel>{"Lägg till låda"}</StyledAddBoxLabel>
    </StyledAddBox>
  );

  /**
   * Render Box label
   *
   * @param {*} index
   * @param {*} label
   */
  const renderBoxLabel = (index, label) => (
    <SortMachineBoxLabel
      index={index}
      label={label}
      onChangeMedia={onChangeMedia}
      onChangeMediaResource={onChangeMediaResource}
      storeDraft={storeDraft}
      draftTarget={draftTarget}
      editBtnFn={editBoxContent}
      callbackUpdatedItem={callbackUpdatedItem}
      updatedItem={updatedItem}
    />
  );

  /**
   * Renders add media button
   * @returns {*}
   */
  const renderAddMediaButton = () => (
    <StyledPrimaryButton studlicon="Media" onClick={openMediaLibrary}>
      Lägg till Enskild bild
    </StyledPrimaryButton>
  );

  /**
   * Renders add media resource button
   * @returns {*}
   */
  const renderAddMediaResourceButton = () => (
    <StyledPrimaryButton
      studlicon="ImageResourceIcon"
      outline
      onClick={openMediaResourceList}
    >
      Lägg till Sammansatt bild
    </StyledPrimaryButton>
  );

  /**
   * Renders input for new items
   * @returns {*}
   */
  const renderNewItemInput = () => (
    <StyledEditInputWrapper>
      <Input
        placeholder="Skriv in fråga eller välj bild alt. resurs"
        value={label}
        onChange={onQuestionLabelChange}
        onEnter={addNewItem}
      />
    </StyledEditInputWrapper>
  );

  /**
   * Renders add item if is not read only state
   * @returns {null}
   */
  const renderAddItem = () => {
    if (!!props.readOnly) {
      return null;
    }
    return (
      <StyledAddNewItemWrapper>
        <StyledAddNewItemRow>
          {renderNewItemInput()}
          {renderAddMediaButton()}
          {renderAddMediaResourceButton()}
        </StyledAddNewItemRow>
      </StyledAddNewItemWrapper>
    );
  };

  /**
   * Renders items for Sort machine
   */
  const renderSortMachineItems = () => (
    <ItemContainer useDragHandle onSortEnd={onSortEnd}>
      {renderItems()}
    </ItemContainer>
  );

  /**
   * Renders answer boxes
   */
  const renderAnswerBoxes = () => (
    <StyledAnswerBoxesContainer>
      {renderValueBox()}
      {canAddMoreBoxes() ? renderAddBox() : null}
    </StyledAnswerBoxesContainer>
  );

  /**
   * Render Sortmachine sidebar
   * @returns {*}
   */
  const renderSortMachineSidebar = () => (
    <SideBar
      updateData={props.updateData}
      storeDraft={props.storeDraft}
      draftTarget={props.draftTarget}
      settings={props.settings}
      data={settingsData}
      rowSettingStyles={rowSettingStyles}
    />
  );

  return (
    <StyledSortMachine>
      <StyledSortMachineContent>
        {renderSortMachineItems()}
        {renderAddItem()}
        {renderAnswerBoxes()}
      </StyledSortMachineContent>
      {renderSortMachineSidebar()}
    </StyledSortMachine>
  );
};

export default PluginHoc({
  Component: SortMachine
});
