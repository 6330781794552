import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StandardButton } from "../../../shared";
import {
  publishPosts,
  unpublishPosts,
  reviewPost,
  requestReview,
  rejectPost
} from "../../store/actions";
import HasPermission from "../../../shared/Permissions";
import {
  PUBLISH,
  EXERCISES,
  DELETE,
  UNPUBLISH,
  REQUEST_REVIEW,
  SET_REVIEWED,
  REJECT
} from "../../../constants";
import Tooltip from "react-tooltip-lite";
import { selectCurrentPostType } from "../../api/selectors";

const mapStateToProps = state => ({
  postType: selectCurrentPostType(state),
  selectedPosts: state.Carousel.items,
  workflowMap: state.Product.get("workflowMap")
});

const mapDispatchToProps = dispatch => ({
  publishPosts: (postType, postIds, mode) => dispatch(publishPosts(postType, postIds, mode)),
  unpublishPosts: (postType, postIds, mode) => dispatch(unpublishPosts(postType, postIds, mode)),
  _requestReview: (postType, postIds, mode) => dispatch(requestReview(postType, postIds, mode)),
  _reviewPost: (postType, postIds, mode) => dispatch(reviewPost(postType, postIds, mode)),
  _rejectPost: (postType, postIds, mode) => dispatch(rejectPost(postType, postIds, mode))
});

const PublishPostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

class PublishPost extends React.PureComponent {
  onPublish = () => {
    const { selectedPosts, postType } = this.props;
    const postIds = selectedPosts.map(post => post.id);
    this.props.publishPosts(postType, postIds, EXERCISES);
  };

  onUnpublish = () => {
    const { selectedPosts, postType} = this.props;
    const postIds = selectedPosts.map(post => post.id);
    this.props.unpublishPosts(postType, postIds, EXERCISES);
  };

  onRequestReview = () => {
    const { selectedPosts, _requestReview, postType } = this.props;
    const postIds = selectedPosts.map(post => post.id);
    _requestReview(postType, postIds, EXERCISES);
  };

  onReviewPost = () => {
    const { selectedPosts, _reviewPost, postType } = this.props;
    const postIds = selectedPosts.map(post => post.id);
    _reviewPost(postType, postIds, EXERCISES);
  };

  onRejectPost = () => {
    const { selectedPosts, _rejectPost, postType } = this.props;
    const postIds = selectedPosts.map(post => post.id);
    _rejectPost(postType, postIds, EXERCISES);
  };

  /**
   * Render publish button
   */
  renderPublishPost = () => (
    <PublishPostWrapper>
      <Tooltip content={"Publicera"} useDefaultStyles={true}>
        <StandardButton studlicon="Cloud" onClick={this.onPublish} />
      </Tooltip>
    </PublishPostWrapper>
  );

  /**
   * Render unpublish button
   */
  renderUnPublishPost = () => (
    <PublishPostWrapper>
      <Tooltip content={"Avpublicera"} useDefaultStyles={true}>
        <StandardButton studlicon="CloudOffline" onClick={this.onUnpublish} />
      </Tooltip>
    </PublishPostWrapper>
  );

  /**
   * Render ready for review button
   */
  renderReadyForReview = () => (
    <PublishPostWrapper>
      <Tooltip content={"Skicka till granskning"} useDefaultStyles={true}>
        <StandardButton
          studlicon="ReadyCheckMark"
          onClick={this.onRequestReview}
        />
      </Tooltip>
    </PublishPostWrapper>
  );

  /**
   * Render ready for publish button
   */
  renderReadyForPublish = () => (
    <PublishPostWrapper>
      <Tooltip content={"Godkänn granskning"} useDefaultStyles={true}>
        <StandardButton
          studlicon="CloudCheckMark"
          onClick={this.onReviewPost}
        />
      </Tooltip>
    </PublishPostWrapper>
  );

  /**
   * Render reject button
   */
  renderRejectPost = () => (
    <PublishPostWrapper>
      <Tooltip content="Avböj" useDefaultStyles={true}>
        <StandardButton studlicon="Close" onClick={this.onRejectPost} />
      </Tooltip>
    </PublishPostWrapper>
  );

  /**
   * Render action component depending on which action should be rendered.
   * @param action
   * @returns { JSX.component }
   */
  renderAction = action => {
    switch (action) {
      case UNPUBLISH:
        return this.renderUnPublishPost();
      case PUBLISH:
        return this.renderPublishPost();
      case REQUEST_REVIEW:
        return this.renderReadyForReview();
      case SET_REVIEWED:
        return this.renderReadyForPublish();
      case REJECT:
        return this.renderRejectPost();
      default:
        return null;
    }
  };

  /**
   * 1: Get available actions for each post status.
   * 2: Filter out all 'delete' actions.
   * 3: Check if selected posts has equal actions.
   * 4: render common actions for all posts if permission is granted.
   */
  renderAvailableAction = () => {
    const {
      selectedPosts,
      workflowMap: { exercises }
    } = this.props;
    const actionList = selectedPosts.map(post =>
      Object.values({ ...exercises[post.status] }).filter(
        action => action !== DELETE
      )
    );
    const result = actionList
      .shift()
      .filter(v => actionList.every(a => a.indexOf(v) !== -1));

    return result.map(element => (
      <HasPermission
        key={element}
        component={this.renderAction(element)}
        section={EXERCISES}
        permission={element}
      />
    ));
  };

  render() {
    return this.renderAvailableAction();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishPost);
