import {
  all,
  fork,
  put,
  takeEvery,
  takeLatest,
  call,
  race,
  take
} from "redux-saga/effects";

import {
  onFetchProducts,
  onProductRoute,
  onFetchProductDetails,
  onProductActivated,
  onProductListRoute,
  onFetchError,
  onFetchProductStatusSummary,
  onFetchProductSettings,
  onSaveProduct,
  onSaveCoverImg,
  onDisconnectTwin,
  onConnectTwin,
  onRemoveProductFromTeachersGuide,
  onSetProductSettings,
  onCreateProductsRelation,
  onRemoveProductsRelation,
  onAddProductToTeachersGuide,
  onCreateProduct,
  onPublishProduct,
  onImportProduct,
  onPublishProductFiles,
  onSetProductCommercial,
  onSetProductDiscontinued,
  onFetchProductPublishFilesHistory,
  pollBuildStatusTask,
  onFetchAvailableSesamgroups,
  onSetProductTaxonomyLevels,
  onSetProductTaxonomySubjects,
  onSetProductFiles,
  onFetchProductFiles
} from "./sagas";
import {
  ACTION_ACTIVATE_PRODUCT,
  ACTION_FETCH_PRODUCT_DETAILS,
  ACTION_FETCH_PRODUCT_DETAILS_FAILED,
  ACTION_FETCH_PRODUCTS,
  ACTION_FETCH_PRODUCTS_FAILED,
  ACTION_FETCH_PRODUCT_STATUS_SUMMARY,
  ACTION_FETCH_PRODUCT_STATUS_SUMMARY_FAILED,
  ACTION_FETCH_PRODUCT_SETTINGS_FAILED,
  ACTION_FETCH_PRODUCT_SETTINGS,
  fetchProductSettings,
  fetchProducts,
  ACTION_UPDATE_PRODUCT,
  ACTION_UPLOAD_COVER_IMAGE,
  ACTION_DISCONNECT_TWIN,
  ACTION_CONNECT_TWIN,
  ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE,
  ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE,
  ACTION_CREATE_PRODUCT,
  ACTION_PUBLISH_PRODUCT,
  ACTION_IMPORT_PRODUCT,
  ACTION_PUBLISH_PRODUCT_FILES,
  ACTION_FETCH_PRODUCT_PUBLISH_HISTORY,
  START_BUILD_STATUS_WATCHER_TASK,
  STOP_BUILD_STATUS_WATCHER_TASK,
  ACTION_CREATE_PRODUCTS_RELATION,
  ACTION_DELETE_PRODUCTS_RELATION,
  ACTION_SET_PRODUCT_SETTINGS,
  ACTION_SET_PRODUCT_COMMERCIAL,
  ACTION_SET_PRODUCT_DISCONTINUED,
  ACTION_FETCH_AVAILABLE_SESAMGROUPS,
  fetchAvailableSesamgroups,
  ACTION_PRODUCT_SET_TAXONOMY_LEVELS,
  ACTION_PRODUCT_SET_TAXONOMY_SUBJECTS,
  ACTION_SET_PRODUCT_FILES,
  ACTION_FETCH_PRODUCT_FILES
} from "../api/actions";
import { ROUTE_PRODUCT, ROUTE_PRODUCTS } from "../routes";
import { fetchSupportSystems } from "../../systems/api/actions";
import {
  fetchSeries,
  fetchSeriesPermissions,
  fetchSeriesWorkFlow
} from "../../series/api/actions";
import { fetchClassroomGroupPermissions } from "../../classroom/api/actions";
import { onMediaFilterRoute } from "../../media/sagas/sagas";

function* watchProductActivated() {
  yield takeEvery(ACTION_ACTIVATE_PRODUCT, onProductActivated);
}

function* watchProductsRequests() {
  yield takeEvery(ACTION_FETCH_PRODUCTS, onFetchProducts);
}

function* watchProductDetailsRequests() {
  yield takeEvery(ACTION_FETCH_PRODUCT_DETAILS, onFetchProductDetails);
}

function* watchProductStatusSummary() {
  yield takeEvery(
    ACTION_FETCH_PRODUCT_STATUS_SUMMARY,
    onFetchProductStatusSummary
  );
}

function* watchProductRoute() {
  yield takeEvery(ROUTE_PRODUCT, onProductRoute);
  yield takeEvery(
    ROUTE_PRODUCT,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
}

function* watchProductListRoute() {
  yield takeEvery(ROUTE_PRODUCTS, onProductListRoute);
}

function* watchFetchErrors() {
  yield takeEvery(ACTION_FETCH_PRODUCTS_FAILED, onFetchError);
  yield takeEvery(ACTION_FETCH_PRODUCT_DETAILS_FAILED, onFetchError);
  yield takeEvery(ACTION_FETCH_PRODUCT_STATUS_SUMMARY_FAILED, onFetchError);
  yield takeEvery(ACTION_FETCH_PRODUCT_SETTINGS_FAILED, onFetchError);
}

function* watchFetchProductSettings() {
  yield takeLatest(ACTION_FETCH_PRODUCT_SETTINGS, onFetchProductSettings);
}

function* watchFetchProductFiles() {
  yield takeLatest(ACTION_FETCH_PRODUCT_FILES, onFetchProductFiles);
}

function* watchSaveProduct() {
  yield takeLatest(ACTION_UPDATE_PRODUCT, onSaveProduct);
}

function* watchUploadCoverImage() {
  yield takeLatest(ACTION_UPLOAD_COVER_IMAGE, onSaveCoverImg);
}

function* watchDisconnectTwin() {
  yield takeLatest(ACTION_DISCONNECT_TWIN, onDisconnectTwin);
}

function* watchConnectTwin() {
  yield takeLatest(ACTION_CONNECT_TWIN, onConnectTwin);
}

function* watchCreateProductsRelation() {
  yield takeLatest(ACTION_CREATE_PRODUCTS_RELATION, onCreateProductsRelation);
}

function* watchRemoveProductsRelation() {
  yield takeLatest(ACTION_DELETE_PRODUCTS_RELATION, onRemoveProductsRelation);
}

function* watchRemoveFromTeachersGuide() {
  yield takeLatest(
    ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE,
    onRemoveProductFromTeachersGuide
  );
}

function* watchAddToTeachersGuide() {
  yield takeLatest(
    ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE,
    onAddProductToTeachersGuide
  );
}

function* watchSetProductSettings() {
  yield takeLatest(ACTION_SET_PRODUCT_SETTINGS, onSetProductSettings);
}

function* watchSetProductFiles() {
  yield takeLatest(ACTION_SET_PRODUCT_FILES, onSetProductFiles);
}

function* watchCreateProduct() {
  yield takeLatest(ACTION_CREATE_PRODUCT, onCreateProduct);
}

function* watchSetProductCommercial() {
  yield takeLatest(ACTION_SET_PRODUCT_COMMERCIAL, onSetProductCommercial);
}

function* watchSetProductDiscontinued() {
  yield takeLatest(ACTION_SET_PRODUCT_DISCONTINUED, onSetProductDiscontinued);
}

function* watchPublishProduct() {
  yield takeLatest(ACTION_PUBLISH_PRODUCT, onPublishProduct);
}

function* watchImportProduct() {
  yield takeLatest(ACTION_IMPORT_PRODUCT, onImportProduct);
}

function* watchPublishProductFiles() {
  yield takeLatest(ACTION_PUBLISH_PRODUCT_FILES, onPublishProductFiles);
}

function* watchFetchPublishHistory() {
  yield takeLatest(
    ACTION_FETCH_PRODUCT_PUBLISH_HISTORY,
    onFetchProductPublishFilesHistory
  );
}

function* watchPollBuildStatusTask() {
  while (true) {
    yield take(START_BUILD_STATUS_WATCHER_TASK);
    yield race([
      call(pollBuildStatusTask),
      take(STOP_BUILD_STATUS_WATCHER_TASK),
    ]);
  }
}

function* watchFetchAvailableSesamgroupRequests() {
  yield takeEvery(
    ACTION_FETCH_AVAILABLE_SESAMGROUPS,
    onFetchAvailableSesamgroups
  );
}

function* watchSetTaxonomyLevels() {
  yield takeLatest(
    ACTION_PRODUCT_SET_TAXONOMY_LEVELS,
    onSetProductTaxonomyLevels
  );
}

function* watchSetTaxonomySubjects() {
  yield takeLatest(
    ACTION_PRODUCT_SET_TAXONOMY_SUBJECTS,
    onSetProductTaxonomySubjects
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchProductsRequests),
    fork(watchProductDetailsRequests),
    fork(watchProductStatusSummary),
    fork(watchProductRoute),
    fork(watchProductActivated),
    fork(watchProductListRoute),
    fork(watchFetchErrors),
    fork(watchFetchProductSettings),
    fork(watchFetchProductFiles),
    fork(watchSaveProduct),
    fork(watchUploadCoverImage),
    fork(watchDisconnectTwin),
    fork(watchConnectTwin),
    fork(watchCreateProductsRelation),
    fork(watchRemoveProductsRelation),
    fork(watchRemoveFromTeachersGuide),
    fork(watchAddToTeachersGuide),
    fork(watchCreateProduct),
    fork(watchPublishProduct),
    fork(watchPublishProductFiles),
    fork(watchImportProduct),
    fork(watchFetchPublishHistory),
    fork(watchPollBuildStatusTask),
    fork(watchSetProductSettings),
    fork(watchSetProductFiles),
    fork(watchSetProductCommercial),
    fork(watchSetProductDiscontinued),
    fork(watchFetchAvailableSesamgroupRequests),
    fork(watchSetTaxonomyLevels),
    fork(watchSetTaxonomySubjects),
    put(fetchProductSettings()), // we always wants this data as soon as the app starts up
    put(fetchProducts()),
    put(fetchSeries()),
    put(fetchSeriesWorkFlow()),
    put(fetchSeriesPermissions()),
    put(fetchSupportSystems()),
    put(fetchClassroomGroupPermissions()),
    put(fetchAvailableSesamgroups()),
  ]);
}
