import styled from "styled-components";
import { white } from "../../../../styles/shared/colors";

export const StyledBlockSidebar = styled.aside`
  flex: 1 1 auto;
  max-width: ${({ maxWidth }) => maxWidth || "none"};
  background-color: #4a4a4a;
`;

export const StyledSidebarSection = styled.div`
  padding-bottom: 10px;
`;

export const StyledSidebarHeading = styled.div`
  width: 100%;
  height: 30px;
  color: ${white};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 5px 10px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
`;

export const StyledSidebarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSidebarContent = styled.div`
  padding: 0 10px;
`;
