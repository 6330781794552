import React from "react";
import styled from "styled-components";

const Text = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  cursor: pointer;
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const ProductLinkRenderer = ({ cellData, rowData }) => {
  const { type, onClickProduct } = rowData;

  return type ? (
    <Text onClick={() => onClickProduct(rowData.data)}>{cellData}</Text>
  ) : null;
};

export default ProductLinkRenderer;
