import React from "react";

const Lock = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-lock"
  >
    <path d="M24.8323587,41.0156414 C23.7602339,41.0156414 22.8830409,40.1367368 22.8830409,39.06252 L22.8830409,32.8125314 C22.8830409,31.7383146 23.7602339,30.85941 24.8323587,30.85941 C25.9044834,30.85941 26.7816764,31.7383146 26.7816764,32.8125314 L26.7816764,39.06252 C26.7816764,40.1367368 25.9044834,41.0156414 24.8323587,41.0156414 Z M46.6647173,26.5625428 L46.6647173,45.3125086 C46.6647173,47.9003945 44.5692008,50 41.9863548,50 L7.67836257,50 C5.09551657,50 3,47.9003945 3,45.3125086 L3,26.5625428 C3,23.9746569 5.09551657,21.8750514 7.67836257,21.8750514 L9.23781676,21.8750514 L9.23781676,15.6250628 C9.23781676,6.99226607 16.2553606,-0.0292054737 24.8810916,9.13477948e-05 C33.497076,0.0293881693 40.4269006,7.13875018 40.4269006,15.7715469 L40.4269006,21.8750514 L41.9863548,21.8750514 C44.5692008,21.8750514 46.6647173,23.9746569 46.6647173,26.5625428 Z M12.3567251,21.8750514 L37.3079922,21.8750514 L37.3079922,15.6250628 C37.3079922,8.73054415 31.7134503,3.12508564 24.8323587,3.12508564 C17.9512671,3.12508564 12.3567251,8.73054415 12.3567251,15.6250628 L12.3567251,21.8750514 Z M43.545809,45.3125086 L43.545809,26.5625428 C43.545809,25.7031694 42.8440546,25.0000457 41.9863548,25.0000457 L7.67836257,25.0000457 C6.82066277,25.0000457 6.11890838,25.7031694 6.11890838,26.5625428 L6.11890838,45.3125086 C6.11890838,46.171882 6.82066277,46.8750057 7.67836257,46.8750057 L41.9863548,46.8750057 C42.8440546,46.8750057 43.545809,46.171882 43.545809,45.3125086 Z" />
  </svg>
);

export default Lock;
