import {
  MULTISVG_SET_DATA,
  MULTISVG_SET_FORMAT,
  MULTISVG_UPDATE_DRAFT,
  MULTISVG_EDITOR_UPDATE_SETTINGS,
  FETCH_ENTITY_DETAILS
} from "../../api";
import { actionCreator } from "../../shared";

export const setMultiSVGData = (entityName, data) => ({
  type: actionCreator(entityName, MULTISVG_SET_DATA),
  entityName,
  data
});

export const setMultiSVGFormat = (entityName, format) => ({
  type: actionCreator(entityName, MULTISVG_SET_FORMAT),
  entityName,
  format
});

export const updateDraftMultiSVG = (entityName, newdraft) => ({
  type: actionCreator(entityName, MULTISVG_UPDATE_DRAFT),
  entityName,
  newdraft
});

export const updateEditorSettings = (entityName, settings) => ({
  type: actionCreator(entityName, MULTISVG_EDITOR_UPDATE_SETTINGS),
  entityName,
  settings
});

export const fetchMediaResource = (entityName, apiAction, args) => ({
  type: actionCreator("MEDIARESOURCE", FETCH_ENTITY_DETAILS),
  entityName,
  apiAction,
  args
});
