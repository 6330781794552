import React from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  width: 16rem;
`;
const StyledOption = styled.option``;

const ProductSelect = ({ productList, onChange, v }) => {
  const _onChange = e => onChange(e.target.value);
  
  return (
    <>
      <StyledSelect
        onChange={_onChange}
        name="products"
        id="product-select"
        aria-label="Välj produkt"
        value={v || ""}
      >
        <StyledOption value="">Välj produkt</StyledOption>
        {productList &&
          productList.map(item => (
            <StyledOption key={item.id} value={item.id}>
              {item.sesam.name}
            </StyledOption>
          ))}
      </StyledSelect>
    </>
  );
};

export default ProductSelect;
