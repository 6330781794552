import { Column } from "react-virtualized";
import ProductLinkRenderer from "../Renderer/ProductLinkRenderer";

const ProductLinkColumn = () => null;

ProductLinkColumn.propTypes = { ...Column.propTypes };
ProductLinkColumn.defaultProps = {
  ...Column.defaultProps,
  ...{
    cellRenderer: ProductLinkRenderer,
    draggable: true
  }
};

export default ProductLinkColumn;
