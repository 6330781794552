import React from "react";
import Tag from "../../Tag";

const LabelRenderer = ({ cellData }) => {
  return (
    cellData &&
    cellData.map((tag, index) => {
      if (tag.color === undefined) return null;
      if (tag.id === undefined) return null;
      return (
        <Tag
          key={index}
          label={tag.title}
          variant={"info"}
          fill={true}
          labelColor={tag.color}
        />
      );
    })
  );
};

export default LabelRenderer;
