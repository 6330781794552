import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../products/components/Card";
import {
  addProductToClassroomGroup,
  deleteProductFromClassroomGroup,
  updateClassroomgroupDetails,
  publishClassroomGroup
} from "../../api/actions";
import { selectClassroomgroupEntityId } from "../../../store/selectors";
import {
  selectClassroomGroup,
  getClassroomgroupList
} from "../../store/selectors";

import ClassroomGroupForm from "../../components/ClassroomGroupForm";

const ClassroomGroupCard = () => {
  const classroomgroup = useSelector(selectClassroomGroup);
  const classroomGroups = useSelector(getClassroomgroupList);
  const classroomGroupEntityId = useSelector(selectClassroomgroupEntityId);
  const disabled = false;
  const dispatch = useDispatch();

  return (
    <Card title="Egenskaper">
      <ClassroomGroupForm
        values={classroomgroup}
        entityId={classroomGroupEntityId}
        updateDetails={val =>
          dispatch(updateClassroomgroupDetails(classroomGroupEntityId, val))
        }
        addProduct={id =>
          dispatch(addProductToClassroomGroup(id, classroomGroupEntityId))
        }
        removeProduct={id =>
          dispatch(deleteProductFromClassroomGroup(id, classroomGroupEntityId))
        }
        publishClassroomGroup={() =>
          dispatch(publishClassroomGroup(classroomGroupEntityId))
        }
        classroomGroups={classroomGroups}
        disabled={disabled}
      />
    </Card>
  );
};

export default ClassroomGroupCard;
