import styled from "styled-components";

export const StyledDropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;
  position: absolute;
  max-width: 1200px;
  background: rgba(249, 249, 249, 0.9);
  height: 100%;

  z-index: 100000;
  border: 1px solid lightgrey;
`;

export const StyledDropzoneActions = styled.div`
  display: flex;
  height: 50px;
`;

export const StyledDropZoneButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  top: 200px;
  right: 450px;
  margin: auto;
  position: absolute;
`;

export const StyledUploadContainer = styled.div`
  background: rgba(240, 240, 240);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  align-self: center;
  border: 1px solid;
  padding: 20px 0px 40px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledHeaderTitle = styled.div`
  margin: 20px auto;
  font-weight: bold;
`;

export const StyledUploadDescriptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  margin: auto;
  width: 80%;
`;

export const StyledMetaData = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px 10px 10px 0px;
`;

export const StyledLabels = styled.span`
  margin: 0px 10px 10px 0px;
`;
export const StyledInvalidLabel = styled.span`
  font-size: 11px;
  flex: 1;
`;

export const StyledProgressBarContainer = styled.span`
  margin: 10px 10px 10px 0px;
`;

export const StyledInvalidFilesContainer = styled.span`
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0px;
  padding: 10px;
`;

export const StyledTitle = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  flex: 1;
`;
export const StyledInvalidRowWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;
export const StyledTitlesWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;
