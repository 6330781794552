import React from "react";
import { connect } from "react-redux";
import { StandardButton } from "../../../shared";
import { Collapsible } from "../../../shared";
import Tooltip from "react-tooltip-lite";
import TagPostLabels from "./TagPostLabels";
import { savePost } from "../../store/actions";

import {
  StyledTagPostWrapper,
  StyledTagPostContent,
  StyledHeader,
  StyledHeaderTitle,
  StyledTop,
  StyledTitle,
  StyledMiddle,
  StyledMiddleContent,
  StyledBottom,
  StyledUpdateButton,
  StyledInlineSectionButton,
  StyledInlineTagDisplay
} from "./DifficultyPostStyles";

import {
  selectCurrentPost,
  selectList,
  selectDifficulty,
  selectCurrentPostType,
  selectSelectedPostList
} from "../../api/selectors";

class DifficultyPost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open,
      clickedItems: {},
      currentDiff: [],
      diffList: []
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    this.setState({
      currentDiff: this.getCurrentDifficulties(),
      diffList: this.getDifficultyList()
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  onToggle = () => {
    let newDiffList = this.getDifficultyList();
    let currentDiffList = this.state.diffList;

    this.setState({
      isOpen: !this.state.isOpen,
      diffList:
        JSON.stringify(currentDiffList) !== JSON.stringify(newDiffList)
          ? newDiffList
          : currentDiffList
    });
  };

  /**
   * Update the difficulty value(s) for the selected post(s)
   */
  onUpdate = () => {
    const { clickedItems } = this.state;
    const { selectedPostsList } = this.props;
    let newDiff = null;

    Object.keys(clickedItems).forEach(diff => {
      if (clickedItems[diff].length > 0) {
        newDiff = Number(diff);
      }
    });

    const useInlineSectionSkin = !!this.props.useCurrentPost;

    if (newDiff !== null) {
      selectedPostsList.forEach((post, i) => {
        this.props.savePost(this.props.postType, {
          postId: post.id,
          difficulty: newDiff,
          entityId: post.entity_id,
          detailedPage: useInlineSectionSkin,
          finalRequest: i === selectedPostsList.length - 1 ? true : false
        });
      });
    }

    this.setState({
      isOpen: false,
      clickedItems: {},
      currentDiff: [newDiff]
    });
  };

  /**
   * returns a list of difficulties of the selected posts. Duplicates are removed.
   */
  getCurrentDifficulties = () => {
    const { selectedPostsList } = this.props;
    const diffs = selectedPostsList.map(post => post.difficulty);
    return diffs.filter((v, i) => diffs.indexOf(v) === i);
  };

  /**
   * Returns an array with the difficulties and their status for the selectedPosts
   */
  getDifficultyList = () => {
    const { difficulty } = this.props;
    const cDiff = this.getCurrentDifficulties();
    const diffArr = difficulty.toJS().map((d, i) => {
      const status =
        cDiff.indexOf(i) >= 0
          ? cDiff.length > 1
            ? "neutral"
            : "checked"
          : "empty";
      return { id: i, title: d, status: status };
    });

    return [
      {
        title: "Svårighetsgrad",
        tags: diffArr
      }
    ];
  };

  /**
   * Click in checkbox
   * @param {number} tagId
   * @param {string} status
   */
  tagClick = (tagId, status) => {
    const { selectedPostsList } = this.props;
    const postIds = selectedPostsList.map(post => {
      return post.id;
    });

    const { diffList } = this.state;
    let { clickedItems } = this.state;

    clickedItems[tagId] = status === "checked" ? postIds : [];

    let temp = diffList.map(g => {
      g.tags = g.tags.map(t => {
        if (t.id === tagId) {
          t.status = status;
        }
        return t;
      });

      return g;
    });

    this.setState({
      diffList: temp,
      clickedItems
    });
  };

  render() {
    const { currentDiff, diffList } = this.state;
    const { useCurrentPost, content, selectedPostsList } = this.props;

    const useInlineSectionSkin = !!useCurrentPost;
    const title = content[0];
    const tagList = diffList[0] && diffList[0].tags ? diffList[0].tags : [];

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <div ref={this.clickRef}>
        <StyledTagPostWrapper>
          {this.renderButtonOrHeader(useInlineSectionSkin, title)}
          <StyledTagPostContent className={classNames}>
            <StyledTop>
              <StyledTitle>Välj svårighetsgrad</StyledTitle>
            </StyledTop>
            <StyledMiddle>
              {diffList ? this.renderDiffList(diffList) : null}
            </StyledMiddle>
            <StyledBottom>
              <StyledUpdateButton onClick={this.onUpdate}>
                {" "}
                Uppdatera {selectedPostsList.length} poster{" "}
              </StyledUpdateButton>
            </StyledBottom>
          </StyledTagPostContent>
        </StyledTagPostWrapper>
        {useInlineSectionSkin ? (
          <StyledInlineTagDisplay
            tagList={tagList}
            postTags={currentDiff}
            content={content}
          />
        ) : null}
      </div>
    );
  }

  /**
   * Renders the button or header of the difficulty selection window depending if it is on a detailview or listview
   * @param {bool} useInlineSectionSkin
   * @param {string} title
   */
  renderButtonOrHeader = (useInlineSectionSkin, title) =>
    useInlineSectionSkin ? (
      <StyledHeader>
        <StyledHeaderTitle>{title}</StyledHeaderTitle>
        <StyledInlineSectionButton
          studlicon="Cog"
          onClick={this.onToggle}
        ></StyledInlineSectionButton>
      </StyledHeader>
    ) : (
      <Tooltip content={"Svårighetsgrad"} useDefaultStyles={true}>
        <StandardButton
          studlicon="FormatListBullet"
          onClick={this.onToggle}
        ></StandardButton>
      </Tooltip>
    );

  /**
   * Renders the difficulty list with current status
   * @param {bool} useInlineSectionSkin
   * @param {array} diffList
   */
  renderDiffList = diffList =>
    diffList.map(g => (
      <StyledMiddleContent key={g.title}>
        <Collapsible title={g.title} open={true}>
          <TagPostLabels
            labelList={g.tags}
            onChange={this.tagClick}
            scrollable={false}
            singleselect={g.title !== "Labels"}
          />
        </Collapsible>
      </StyledMiddleContent>
    ));
}

const mapStateToProps = (state, props) => {
  let postType = selectCurrentPostType(state);
  return {
    postType: postType,
    selectedPostsList: selectSelectedPostList(
      state,
      props.useCurrentPost,
      postType
    ),
    postlist: selectList(state, postType).toJS(),
    currentPost: selectCurrentPost(state, postType),
    difficulty: selectDifficulty(state, postType)
  };
};

const mapDispatchToProps = dispatch => ({
  savePost: (postType, args) => dispatch(savePost(postType, args))
});

export default connect(mapStateToProps, mapDispatchToProps)(DifficultyPost);
