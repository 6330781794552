import React from "react";
import { Film } from "react-feather";
import {
  StyledAddMediaButton,
  StyledButtonContainer
} from "./StyledAddMediaButtons";
import {
  Media,
  Sound,
  Plus,
  Minus,
  ImageResourceIcon
} from "../../../shared/components/StudliIcons";
import { AUDIO, IMAGE, VIDEO, MEDIA, MEDIA_RESOURCE } from "../../../constants";
import { ModalHandler } from "../../effects/ModalHandler";

/**
 * Returns media modals
 * @param openMediaLibrary
 * @param updateData
 * @param data
 * @returns {{audio: openModal, video: openModal, image: openModal, mediaresource: openModal}}
 */
const getModals = (openMediaLibrary, updateData, data, updateDraftList) => {
  const { openModal: audio } = ModalHandler({
    type: AUDIO,
    openMediaLibrary: openMediaLibrary(MEDIA),
    updateData,
    data,
    updateDraftList
  });
  const { openModal: video } = ModalHandler({
    type: VIDEO,
    openMediaLibrary: openMediaLibrary(MEDIA),
    updateData,
    data,
    updateDraftList
  });
  const { openModal: image } = ModalHandler({
    type: IMAGE,
    openMediaLibrary: openMediaLibrary(MEDIA),
    updateData,
    data,
    updateDraftList
  });
  const { openModal: mediaresource } = ModalHandler({
    type: MEDIA_RESOURCE,
    openMediaLibrary: openMediaLibrary(MEDIA_RESOURCE),
    updateData,
    data,
    updateDraftList
  });

  return { audio, video, image, mediaresource };
};

const MediaButtons = ({
  openMediaLibrary,
  updateData,
  data = {},
  settings,
  updateDraftList
}) => {
  const { audio, video, image, mediaresource } = data;

  const modals = getModals(openMediaLibrary, updateData, data, updateDraftList);
  const iconSize = 20;

  /**
   * Sound icon
   */
  const soundIcon = <Sound size={iconSize} />;

  /**
   * Media icon
   */
  const mediaIcon = <Media size={iconSize} />;

  /**
   * Mediaresource icon
   */
  const mediaResourceIcon = (
    <ImageResourceIcon color={"white"} size={iconSize} />
  );

  /**
   * Media icon
   */
  const copy = <Film size={iconSize} />;

  /**
   * Render icon
   * @param condition
   * @returns {*}
   */
  const renderIcon = condition =>
    condition ? <Minus size={"16"} /> : <Plus size={"16"} />;

  /**
   * Render add button
   * @param target
   * @param media
   * @param icon
   * @param bgColor
   * @returns {*}
   */
  const renderAddButton = (target, media, icon, bgColor = "") => {
    if (settings && settings.readOnly) {
      return null;
    }

    return target in data ? (
      <StyledAddMediaButton callback={modals[target]} bgColor={bgColor}>
        {renderIcon(media && (media.src || (media.data && media.data.id)))}
        {icon}
      </StyledAddMediaButton>
    ) : null;
  };

  return (
    <StyledButtonContainer>
      {renderAddButton(AUDIO, audio, soundIcon)}
      {renderAddButton(IMAGE, image, mediaIcon, "#377AD3")}
      {renderAddButton(VIDEO, video, copy, "#384E70")}
      {renderAddButton(
        MEDIA_RESOURCE,
        mediaresource,
        mediaResourceIcon,
        "#384E70"
      )}
    </StyledButtonContainer>
  );
};

export default MediaButtons;
