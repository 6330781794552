import React from "react";
import { RadioButton } from "../../../shared/components/RadioButton/RadioButton";
import { Toggle } from "../../../shared/components/StudliIcons";

const customStyles = {
  containerWidth: "25px",
  cursor: "pointer"
};

const buttonStyles = {
  borderRadius: "5px",
  padding: 0,
  width: "25px",
  height: "25px"
};

const ToggleButton = ({
  isActive,
  callback,
  content = <Toggle size={40} color={isActive ? "white" : "black"} />,
  title
}) => (
  <RadioButton
    isActive={isActive}
    callback={callback}
    content={content}
    overrideStyles={customStyles}
    buttonStyle={buttonStyles}
    title={title ? title : null}
  />
);

export default ToggleButton;
