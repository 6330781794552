import React from "react";

const PostAdd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-postadd"
  >
    <path d="M49.21875,9.375 L40.625,9.375 L40.625,0.78125 C40.625,0.349609375 40.2753906,0 39.84375,0 L38.28125,0 C37.8496094,0 37.5,0.349609375 37.5,0.78125 L37.5,9.375 L28.90625,9.375 C28.4746094,9.375 28.125,9.72460938 28.125,10.15625 L28.125,11.71875 C28.125,12.1503906 28.4746094,12.5 28.90625,12.5 L37.5,12.5 L37.5,21.09375 C37.5,21.5253906 37.8496094,21.875 38.28125,21.875 L39.84375,21.875 C40.2753906,21.875 40.625,21.5253906 40.625,21.09375 L40.625,12.5 L49.21875,12.5 C49.6503906,12.5 50,12.1503906 50,11.71875 L50,10.15625 C50,9.72460938 49.6503906,9.375 49.21875,9.375 Z M48.5576172,35.8115234 L38.8193359,31.625 L48.5576172,27.4384766 C49.3730469,27.0869141 49.7480469,26.1464844 49.3955078,25.3369141 C49.0390625,24.5273438 48.0947266,24.1523438 47.2724609,24.5087891 L25.2685547,33.9677734 C25.0917969,34.0429688 24.9033203,34.0429688 24.7333984,33.9677734 L4.41699219,25.234375 L23.3085937,17.1132812 C24.1240234,16.7626953 24.4990234,15.8212891 24.1464844,15.0117188 C23.7900391,14.203125 22.8515625,13.8271484 22.0234375,14.1835938 L1.44628906,23.0283203 C0.567382812,23.4042969 0,24.2695313 0,25.234375 C0,26.1982422 0.567382812,27.0644531 1.44238281,27.4394531 L11.1816406,31.6259766 L1.4453125,35.8105469 C0.567382812,36.1865234 0,37.0527344 0,38.0185547 C0,38.9824219 0.567382812,39.8486328 1.44238281,40.2236328 L23.4462891,49.6806641 C23.9404297,49.8925781 24.4697266,50.0009766 24.9990234,50.0009766 C25.5283203,50.0009766 26.0576172,49.8945313 26.5517578,49.6806641 L48.5527344,40.2246094 C49.4316406,39.8486328 49.9990234,38.9824219 49.9990234,38.0185547 C50,37.0527344 49.4326172,36.1865234 48.5576172,35.8115234 Z M25.2675781,46.75 C25.0908203,46.8251953 24.9023437,46.8251953 24.7324219,46.75 L4.41699219,38.0166016 L15.234375,33.3671875 L23.4472656,36.8974609 C23.9414062,37.109375 24.4707031,37.2177734 25,37.2177734 C25.5292969,37.2177734 26.0585937,37.1113281 26.5527344,36.8974609 L34.7675781,33.3662109 L45.5859375,38.0166016 L25.2675781,46.75 Z" />
  </svg>
);

export default PostAdd;
