import styled from "styled-components";
import { themeTertiary } from "../../../styles/shared/colors";
import { getTextColorByContrast } from "../../helpers";

export const DeletableChip = styled.div`
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: relative;
  margin-left: 7px;
  border-radius: 50%;
  background-color: darkgrey;
  color: white;
`;

export const DeletableChipIcon = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const ChipContent = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1px;
  min-height: 1px;
`;

export const PlainChip = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ isDeletable }) =>
    isDeletable ? `space-between` : `center`};
  box-sizing: border-box;
  min-width: ${({ isDeletable }) => (isDeletable ? `74px` : `64px`)};
  height: 28px;
  line-height: 24px;
  padding: ${({ isDeletable }) => (isDeletable ? `0 5px 0 12px` : `0 12px`)};
  margin: 2px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  vertical-align: middle;
  font-size: 14px;
  background-color: ${({ labelColor }) =>
    labelColor ? labelColor : themeTertiary};
  color: ${({ labelColor }) => getTextColorByContrast(labelColor)};
  border-radius: 15px;
  border: 1px solid darkgrey;

  &.tag-small {
    min-width: 18px;
    height: 18px;
    line-height: 18px;
    padding: 0 4px;
  }
`;
