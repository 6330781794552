import Structure from "./views/Structure";

export const ROUTE_STRUCTURE = "ROUTE_STRUCTURE";

export const routes = {
  [ROUTE_STRUCTURE]: {
    path: "/products/:entityId/structure",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Struktur",
    icon: "Structure"
  }
};

export const views = {
  [ROUTE_STRUCTURE]: Structure
};
