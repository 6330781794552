import { client } from "../../api";
import * as querystring from "querystring";

export const listProducts = () => client.get("entities?type=product");

export const getProductDetails = (entityId) =>
  client.get(`entity/${entityId}?type=product`);

export const getProductStatusSummary = (entityId) => () =>
  client.get(`entity/${entityId}/post/status-summary`);

export const getProductSettings = () => client.get(`entities/settings`);

export const setProductSettings = (entityId, settings) => {
  return client.post(`entity/${entityId}/settings`, settings);
};

export const getProductFiles = entityId =>
  client.get(`entity/media/files/entity_id/${entityId}`);

export const setProductFiles = (entityId, files) =>
  client.post(`entity/media/files/entity_id/${entityId}`, files);

export const saveProduct = (data) => client.put(`entity/${data.id}`, data);

export const uploadCoverImg = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return client.post(`entity/cover`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const setTwins = (entityId, twinId) =>
  client.put(`entity/${entityId}/twin/${twinId}`);

export const deleteTwins = (entityId, twinId) =>
  client.delete(`entity/${entityId}/twin/${twinId}`);

export const addProductsRelation = (entityId, relatedProductId) => {
  return client.post(`entity/relations`, {
    entityParentId: entityId,
    entityChildId: relatedProductId,
  });
};

export const removeProductsRelation = (entityId, relatedProductId) =>
  client.delete(`entity/${entityId}/relation/${relatedProductId}`);

export const addProductToTeachersGuide = (teachersGuideId, entityId) =>
  client.put(`/entity/${entityId}/teachersguide/${teachersGuideId}`);

export const removeProductFromTeachersGuide = (teachersGuideId, entityId) =>
  client.delete(`/entity/${entityId}/teachersguide/${teachersGuideId}`);

export const createProduct = (data) =>
  client.post(`entity?type=product`, data.data);

export const publishProduct = (entityId) =>
  client.get(`/entity/${entityId}/publish?ptype=meta`);

export const setProductCommercial = (entityId) =>
  client.put(`/entity/${entityId}/commercialize`);

export const setProductDiscontinued = (entityId) =>
  client.put(`/entity/${entityId}/discontinue`);

export const publishProductFiles = (entityId, env) => {
  const params = { ptype: "data", env: env };
  return client.get(
    `/entity/${entityId}/publish?${querystring.stringify(params)}`
  );
};

export const importProduct = (sesamName) =>
  client.get(`/entity/import/${sesamName}`);

export const fetchPublishFileStatus = (entityId) => () =>
  client.get(`/build/${entityId}/status`);

export const fetchPublishHistory = (entityId) => () =>
  client.get(`/build/${entityId}/history`);

export const getAvailbleSesamgroups = () =>
  client.get(`entities/sesamgroups/available`);

export const setProductTaxonomyLevels = (entityId, levelIds) => () =>
  client.post(`entity/${entityId}/taxonomy/levels`, { ids: levelIds });

export const setProductTaxonomySubjects = (entityId, subjectIds) => () =>
  client.post(`entity/${entityId}/taxonomy/subjects`, { ids: subjectIds });
