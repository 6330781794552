import { actionCreator } from "../../shared";
import {
  ENTITIES,
  HELP_RESOURCE,
  MEDIA,
  MEDIA_RESOURCE
} from "../../constants";
import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_HELP_RESOURCES, ROUTE_DETAILED_HELP_RESOURCE } from "../routes";
import {
  fetchEntitiesSaga,
  fetchEntityDetailsSaga,
  fetchFiltersSaga,
  addRemoveTagsToPostSaga,
  deletePostsSaga,
  savePostsSaga,
  addCommentToPost,
  fetchCommentsSaga
} from "../api/saga";
import {
  onListRoute,
  onDetailedRoute,
  sortList,
  onFilterRoute,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onLoadComments
} from "./sagas";

import {
  FETCH_ENTITIES,
  FETCH_ENTITY_DETAILS,
  FILTER_POSTS,
  FETCH_ENTITY_COMMENTS
} from "../../api";

import {
  CREATE_ENTITY_DETAILS,
  DELETE_POSTS,
  FETCH_FILTER,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  POST_ADD_REMOVE_TAGS,
  RELOAD_POSTS,
  SAVE_ENTITY_DETAILS,
  SAVE_POST,
  SORT_POSTS,
  ADD_COMMENT
} from "../store/actions";
import { onMediaFilterRoute } from "../../media/sagas/sagas";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(HELP_RESOURCE, FETCH_ENTITIES),
    fetchEntitiesSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "listHelpResources"
    )
  );
  yield takeLatest(
    actionCreator(HELP_RESOURCE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "fetchHelpResource"
    )
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "saveHelpResource"
    )
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "createHelpResource"
    )
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, SAVE_POST),
    savePostsSaga(ENTITIES[HELP_RESOURCE.toLowerCase()], "savePost")
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listResources"
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, POST_ADD_REMOVE_TAGS),
    addRemoveTagsToPostSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "addRemoveTagsToPost"
    )
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, DELETE_POSTS),
    deletePostsSaga(ENTITIES[HELP_RESOURCE.toLowerCase()], "deletePosts")
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listHelpResources"
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listHelpResources"
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listHelpResources"
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, SORT_POSTS),
    sortList,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listHelpResources"
  );
  yield takeLatest(
    actionCreator(HELP_RESOURCE, FILTER_POSTS),
    filterPosts,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, FETCH_FILTER),
    fetchFiltersSaga(ENTITIES[HELP_RESOURCE.toLowerCase()], "loadFilters")
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, ADD_COMMENT),
    addCommentToPost(ENTITIES[HELP_RESOURCE.toLowerCase()], "addCommentToPost")
  );
  yield takeEvery(
    actionCreator(HELP_RESOURCE, FETCH_ENTITY_COMMENTS),
    fetchCommentsSaga(
      ENTITIES[HELP_RESOURCE.toLowerCase()],
      "fetchCommentsForPost"
    )
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_HELP_RESOURCES,
    onListRoute,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "listHelpResources"
  );
  yield takeEvery(
    ROUTE_HELP_RESOURCES,
    onFilterRoute,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "loadFilters"
  );

  yield takeEvery(
    ROUTE_DETAILED_HELP_RESOURCE,
    onLoadComments,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "loadComments"
  );

  yield takeEvery(
    ROUTE_DETAILED_HELP_RESOURCE,
    onFilterRoute,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_DETAILED_HELP_RESOURCE,
    onDetailedRoute,
    ENTITIES[HELP_RESOURCE.toLowerCase()],
    "fetchHelpResource"
  );
  yield takeEvery(
    ROUTE_DETAILED_HELP_RESOURCE,
    onMediaFilterRoute,
    ENTITIES[MEDIA],
    "loadMediaFilters"
  );
  yield takeEvery(
    ROUTE_DETAILED_HELP_RESOURCE,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
