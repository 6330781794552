import NumberLine from "./components/NumberLine/NumberLine";
import { NUMBER_LINE } from "../constants";
import {
  defaultStepsGenerator,
  defaultNumberlineSettings
} from "./components/NumberLine/NumberLineHelpers";

export const createNumberLinePlugin = () => ({
  entityRendererFn: entity => {
    const types = [NUMBER_LINE];

    if (types.includes(entity.type)) {
      return {
        component: NumberLine,
        style: { padding: "0px" },
        props: {
          data: { ...defaultStepsGenerator(), ...entity.data },
          settings: { ...defaultNumberlineSettings(), ...entity.settings }
        }
      };
    }

    return null;
  }
});

export default createNumberLinePlugin;

export const emptyBlocks = {
  [NUMBER_LINE]: {
    type: NUMBER_LINE,
    data: defaultStepsGenerator(),
    settings: defaultNumberlineSettings()
  }
};
