import * as React from "react";

export const Input = ({ value, placeholder, onChange, onEnter }) => {
  const _onChange = e => onChange(e.target.value);
  const _onKeyDown = e => {
    if (e.keyCode === 13 && onEnter !== undefined) {
      onEnter({ text: e.target.value });
    }
  };

  return (
    <input
      value={value}
      placeholder={placeholder}
      onChange={_onChange}
      onKeyDown={_onKeyDown}
    />
  );
};

export default Input;
