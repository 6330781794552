import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectMessageListData } from "../../api/selector";
import {
  DataTable,
  withActions,
  StatusColumn,
  DateColumn
} from "../../../shared";
import { Pagination } from "../../../posts/containers/ToolBar/PostActions";
import LinkColumn from "../../../shared/components/DataTable/Columns/Link";
import MenuActionColumn from "../../../shared/components/DataTable/Columns/MenuActionColumn";
import { sortPosts } from "../../../posts/store/actions";
import CountWithInfoColumn from "../../../shared/components/DataTable/Columns/CountWithInfo";
import { MARKETMESSAGE } from "../../../constants";

const ActionDataTable = withActions(DataTable);

const StyledDataTable = styled(ActionDataTable)`
  height: calc(100% - 112px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const MessagePostList = ({ postType }) => {
  const poster = useSelector(state => selectMessageListData(state, postType));
  const dispatch = useDispatch();
  const _onSort = sort => {
    dispatch(sortPosts(postType, sort));
  };

  return (
    <StyledDataTable list={poster} actionsRight={Pagination} onSort={_onSort}>
      <LinkColumn dataKey="title" label="Titel" width={0.3} abc={123} />
      <CountWithInfoColumn dataKey="products" label="Antal produkter" width={0.15} disableSort/>
      {postType.toLowerCase() === MARKETMESSAGE && <CountWithInfoColumn dataKey="series" label="Antal serier" width={0.15} disableSort/>}
      <CountWithInfoColumn dataKey="chips" label="Användartyper" width={0.15} disableSort/>
      <DateColumn
        dataKey="startTime"
        label="Starttid"
        width={0.2}
        disableSort
      />
      <DateColumn dataKey="endTime" label="Sluttid" width={0.2} disableSort />
      <StatusColumn
        dataKey="status"
        label=""
        width={62}
        fixedWidth
        disableSort
      />
      <DateColumn dataKey="updated_at" label="Senast uppdaterad" width={0.2} />
      <MenuActionColumn
        dataKey="actions"
        label=""
        fixedWidth
        width={40}
        disableSort
      />
    </StyledDataTable>
  );
};

export default MessagePostList;
