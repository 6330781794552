import { getBorderKey } from "../../ChartHelpers";
import { StyledCellButton } from "./StyledButtonCell";
import AutoLaTeX from "react-autolatex";

const ButtonCell = ({
  type,
  cell,
  callback,
  cellIndex,
  rowIndex,
  selected
}) => {
  /**
   * If the string has "$" we assume it's LaTex.
   * @param {*} value
   * @returns
   */
  const isLatex = value => value && value.indexOf("$") !== -1;

  return (
    <StyledCellButton
      id="chart-button"
      selected={selected}
      data-rowindex={rowIndex}
      data-cellindex={cellIndex}
      type={type}
      index={cellIndex}
      title={cell.title}
      border={getBorderKey(cell)}
      color={cell.color}
      column={cell.column}
      onClick={callback && callback(rowIndex, cellIndex)}
      row={cell.row}
      withInnerHTML={!isLatex(cell.value)}
    >
      {isLatex(cell.value) ? <AutoLaTeX>{cell.value}</AutoLaTeX> : cell.value}
    </StyledCellButton>
  );
};

export default ButtonCell;
