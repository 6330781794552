import About from "./components/Help/About/About";
import { getMediaObject } from "./dataModels";
import { ABOUT, AUDIO } from "../constants";

const getUsedResources = ({ data }) => {
  if (!data.mediaresource) {
    return undefined;
  }
  return [data.mediaresource.id];
};

const types = [ABOUT];

const settings = {
  readOnly: false,
  multiLine: true
};

export const createAboutPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: About,
        style: { padding: "0px" },
        props: {
          data: entity.data,
          settings: { ...settings, ...entity.settings },
          help: entity.help
        }
      };
    }
    return null;
  },
  types,
  getUsedResources
});

export default createAboutPlugin;

export const emptyBlocks = {
  [ABOUT]: {
    type: ABOUT,
    data: {
      title: null,
      text: null,
      [AUDIO]: getMediaObject(AUDIO)
    },
    settings: {}
  }
};
