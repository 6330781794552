import React from "react";
import {
  StyledTextBox,
  StyledTextBoxContentOptional,
  StyledTextBoxContentText,
  StyledTextBoxContent,
  ContentWrapper
} from './StyledTextBox';
import { StyledAudioPlayer } from "./StyledJSONPreview"
import { filterMediaListItems, getAudioUrl } from "../AdventuresCardEditor/helpers";

const SliderBox = (props) => {
  const url = getAudioUrl(props.audio, filterMediaListItems("audio", props.mediaList))

  return <StyledTextBox>
    <ContentWrapper>
      {url && (<StyledAudioPlayer src={url} controls />)}
      <StyledTextBoxContent >
        <StyledTextBoxContentText dangerouslySetInnerHTML={{ __html: props.body }} />
      </StyledTextBoxContent>
    </ContentWrapper>
    {props.children &&
      <StyledTextBoxContentOptional>
        {props.children}
      </StyledTextBoxContentOptional>}
  </StyledTextBox>
};

export default SliderBox;

