import * as React from "react";
import {
  StyledRadioBtn,
  StyledRadioBtnRow,
  StyledRadioBtnRowContainer,
  StyledRadioBtnRowLabel
} from "./StyledRadioButtonRow";

export const RadioButtonRow = props => {
  /**
   * Compares given variable to current activeIndex
   * @param index
   * @returns {boolean}
   */
  const isActiveIndex = index => props.activeIndex === index;

  /**
   * Render the styled radio buttons
   */
  const renderStyledRadioBtn = () => {
    return props.options.map((item, index) => {
      return (
        <StyledRadioBtn
          isActive={isActiveIndex(index)}
          onClick={() => props.callback(index)}
          key={index}
        >
          {item}
        </StyledRadioBtn>
      );
    });
  };

  return (
    <StyledRadioBtnRowContainer>
      <StyledRadioBtnRow>{renderStyledRadioBtn()}</StyledRadioBtnRow>

      <StyledRadioBtnRowLabel>{props.label}</StyledRadioBtnRowLabel>
    </StyledRadioBtnRowContainer>
  );
};
