import styled from "styled-components";
import { getTextColorByContrast } from "../../../shared/helpers";

export const StyledLabels = styled.ul`
  list-style-type: none;
  padding: 0 0 5px 0;
  &.scroll {
    height: 170px;
  }
`;

export const StyledLabelItem = styled.li`
  height: 30px;
  border-radius: 5px;
  display: flex;
  padding: 2px 5px;
  justify-content: start;
  background-color: ${props => props.color};
  svg {
    margin-right: 5px;
  }
`;
export const StyledButton = styled.div.attrs({
  role: "button"
})`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
`;

export const StyledInputHandle = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  pointer-events: none;
  fill: ${({ contrastingColor }) => getTextColorByContrast(contrastingColor)};
`;

export const StyledInputWrapper = styled.div`
  display: inline-block;
  position: relative;
  flex: 0 0 26px;
  box-sizing: content-box;
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
`;

export const StyledCheckboxLabel = styled.label`
  color: ${({ contrastingColor }) => getTextColorByContrast(contrastingColor)};
  user-select: none;
  pointer-events: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 8px;
`;
