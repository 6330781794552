import { connect } from "react-redux";
import React from "react";
import { sectionCoverter } from "../constants";

/**
 * Helper function that modifies the permissons data received from backend.
 * Receives an array with permission strings, splits the permission strings e.g exercises.publish --> exercises: ['publish']
 * @param {*} array
 * @returns { object }
 */
export const modifyPermissionsArray = array => {
  var object = {};

  array.forEach(permission => {
    const stringArr = permission.split(".");

    if (!object[stringArr[0]]) {
      object[stringArr[0]] = [];
    }

    if (stringArr.length > 1) {
      object[stringArr[0]].push(stringArr[1]);
    }
  });
  return object;
};

export const hasPermissionTo = (section, permissions) =>
  permissions && permissions[section] && permissions[section].length > 0;

/**
 * Returns true if permission is granted.
 * @param {*} section
 * @param {*} permission
 * @param {*} permissions
 */
export const hasPermissionBool = (section, permission, permissions) =>
  checkPermissions({
    component: <p>{"dummy"}</p>,
    section,
    permission,
    permissions
  })
    ? true
    : false;

/**
 * Returns section name
 */
export const getSection = type => type && sectionCoverter[type.toLowerCase()];

/**
 * Check for permission to return the component.
 * Use this component to wrap the component which should be returned or not depending on the list of
 * @param permissions
 *
 * @param component wrapped component to be returned if allowed
 * @param defaultComponent function can accept a second component for conditional rendering. null by default.
 * @param section if not passed on to function, the component will NOT be returned
 * @param permission if not passed on to function, the component WILL be returned
 * @param permissions, list of permissions - map state to props
 * @param multiplePermissions list of permissions to find out if there is at least one permission available
 *
 * @returns { component | defaultComponent }
 */
const checkPermissions = ({
  component,
  defaultComponent = null,
  section,
  permission,
  permissions,
  multiplePermissions
}) => {
  const array = permissions && permissions[section];

  if (multiplePermissions && array) {
    return checkMultiplePermissions(
      component,
      multiplePermissions,
      array,
      defaultComponent
    );
  }

  return array
    ? componentShouldReturn(array, permission, component, defaultComponent)
    : defaultComponent;
};

/**
 * If the user has at least one permission granted in the multiplePermissions prop,
 * then the function will allow the component to return.
 *
 * @param {*} component
 * @param {*} multiplePermissions
 * @param {*} array
 * @param {*} defaultComponent
 */
const checkMultiplePermissions = (
  component,
  multiplePermissions,
  array,
  defaultComponent
) => {
  const atLeastOnePermission = multiplePermissions.some(permission =>
    array.includes(permission)
  );
  return atLeastOnePermission ? component : defaultComponent;
};

/**
 * If permission !== null, we check if the permission exists in the permissions array
 * and component | defaultComponent is returned depending if the permission was found or not.
 *
 * if permission === null, we return the component. The permission MUST have a value.
 * Otherwise we assume the component to be a whole section.
 *
 * @param {*} array
 * @param {*} permission
 * @param {*} component
 *
 * @returns { component | null }
 */
const componentShouldReturn = (
  array,
  permission,
  component,
  defaultComponent
) =>
  permission
    ? array.includes(permission)
      ? component
      : defaultComponent
    : component;

/**
 * Gives us access to state, where restrictions list will be availiable.
 * @param {*} state
 */
const mapStateToProps = (state, { type = "Product" }) => {
  const permissions = state[type] && state[type].get("permissions");
  return ({
    permissions: permissions || state["Product"].get("permissions")
  })
};

const HasPermission = connect(mapStateToProps)(checkPermissions);

export default HasPermission;
