import styled from "styled-components";
import Button from "../../../shared/components/Button";

export const StyledTextEditorContainer = styled.div`
  max-width: 1010px;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const StyledAidaTextEditorContainer = styled.div`
  max-width: 1010px;
  flex: 1;
  h2 {
    margin-block-start: 0.5em;
    margin-block-end: 0.1em;
    font-weight: 500;
    font-size: 1.6em;
  }

  width: 100%;
  height: 100%;
`

export const StyledTextBlock = styled.div`
  display: flex;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 6px;
`;

export const StyledLinkButtonWrapper = styled.div`
  display: inline-flex;
  vertical-align: bottom;
`;

export const StyledLinkButton = styled(Button).attrs({
  type: "button",
  icon: "Link"
})`
  background-color: transparent;
  min-width: 36px;
  hight: 34px;
  &:hover,
  &:focus {
    background-color: #efefef;
  }
`;
