import * as React from "react";
import { Input } from "../Input";
import { RadioButtonRow } from "../../../shared/components/RadioButtonRow/index";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import {
  StyledVideo,
  StyledVideoContent,
  StyledEditInputWrapper,
  StyledAltText,
  StyledSettingsRow,
  StyledHeaderText
} from "./StyledVideo";
import { DATA, ALT_TEXT, SETTINGS, VIDEO } from "../../../constants";
import PluginHoc from "../../../shared/PluginHoc";

const Video = ({
  pluginHeader,
  updateData,
  useModal,
  data,
  settings,
  storeDraft,
  draftTarget
}) => {
  /**
   * Save input
   * @param value
   */
  const onChange = value => {
    storeDraft(draftTarget);
    updateData(ALT_TEXT, value, DATA);
  };

  /**
   * Use modal
   */
  useModal(VIDEO);

  /**
   * sets useClosedCaption setting on or off.
   * If you don't want to use the cc:s although it exists
   * @param {number} indexClicked
   */
  const setClosedCaption = indexClicked => {
    storeDraft(draftTarget);
    updateData("useClosedCaption", indexClicked === 1, SETTINGS);
  };

  /**
   * Update alt text for video
   */
  const addAltText = () => {
    storeDraft(draftTarget);
    updateData(ALT_TEXT, data.altText, DATA);
  };

  /**
   * Render Video sidebar
   * @returns {*}
   */
  const renderVideoSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#35877A"}>
          Video:
        </StyledSidebarHeading>
        {/* SIDEBAR CONTENT */}
        <StyledSettingsRow>
          {renderVTTHeaderText()}
          {renderUseCCSetting()}
        </StyledSettingsRow>
      </StyledSidebarSection>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#35877A"}>
          Text och bild:
        </StyledSidebarHeading>
        {/* SIDEBAR CONTENT */}
      </StyledSidebarSection>
    </StyledBlockSidebar>
  );

  /**
   * Header text for closed caption setting
   */
  const renderVTTHeaderText = () => {
    const { cc } = data;

    return (
      <StyledHeaderText>
        {cc && cc.length > 0
          ? "Det finns vtt filer för denna video. Vill du använda dem?"
          : "Inga vtt filer finns för denna video"}
      </StyledHeaderText>
    );
  };

  /**
   * Render the alt text for video
   */
  const renderEditAltText = () => (
    <StyledAltText>
      <label htmlFor="altTextVideo">Lägg till en alt text:</label>
      <StyledEditInputWrapper>
        <Input
          name="altTextVideo"
          placeholder="Alt text..."
          value={data.altText || ""}
          onChange={onChange}
          onEnter={addAltText}
        />
      </StyledEditInputWrapper>
    </StyledAltText>
  );

  /**
   * Setting to deside if you want to use vtt files or not in the video
   * NOTE: This setting can be changed.
   */
  const renderUseCCSetting = () => {
    const Opt = [<div>Av</div>, <div>På</div>];
    const { useClosedCaption } = settings;
    const { cc } = data;

    return cc && cc.length > 0 ? (
      <RadioButtonRow
        options={Opt}
        activeIndex={useClosedCaption ? 1 : 0}
        callback={setClosedCaption}
        label={"Closed Caption"}
      />
    ) : null;
  };

  return (
    <StyledVideo>
      <StyledVideoContent>
        {pluginHeader}
        {renderEditAltText()}
      </StyledVideoContent>
      {renderVideoSidebar()}
    </StyledVideo>
  );
};

export default PluginHoc({
  Component: Video,
  defaultSettings: { operative: true }
});
