import React from "react";
import { connect } from "react-redux";
import { Collapsible } from "../../../shared";
import TagMediaLabels from "./TagMediaLabels";
import { addRemoveTagsToMedia } from "../../store/actions";

import {
  StyledTagPostWrapper,
  StyledTagPostContent,
  StyledHeader,
  StyledHeaderTitle,
  StyledTop,
  StyledTitle,
  StyledMiddle,
  StyledMiddleContent,
  StyledBottom,
  StyledUpdateButton,
  StyledInlineSectionButton,
  StyledInlineTagDisplay,
  StyledTagMedia,
  MediaButton
} from "./TagMediaStyles";

import {
  selectCurrentPostType,
  selectSelectedMediaList,
  selectLabels,
  selectTags,
  getTagsPerMedia,
  getMediaPerTag,
  selectTagsForCurrentEntity
} from "../../api/selectors";
import { MEDIA_RESOURCE, ENTITIES } from "../../../constants";
import { addRemoveTagsToPost } from "../../../mediaresource/store/actions";
import { selectEntityId, selectIsInSystem } from "../../../store/selectors";

class TagMedia extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open,
      tagList: [],
      clickedItems: {},
      clickedTags: [],
      tagsPerPostState: [],
      postsPerTagState: []
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  /**
   * Function to handle click inside and outside of this component.
   */
  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  /**
   * Close window if click outside this component.
   */
  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  /**
   * Toggla if tagWindow is open or not. Set tagList.
   */
  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      tagList: !this.state.isOpen ? this.getTagList() : []
    });
  };

  /**
   * Add status property for each tag (checked, empty, neutral)
   */
  getTagsWithStatus = () => {
    const { tags, selectedPostsList, postsPerTag } = this.props;
    return tags.map(group => ({
      title: group.title,
      open: false,
      tags: group.data[0].tags.map(t =>
        postsPerTag[t.id]
          ? {
              ...t,
              status:
                postsPerTag[t.id].length === selectedPostsList.length
                  ? "checked"
                  : "neutral"
            }
          : { ...t, status: "empty" }
      )
    }));
  };

  /**
   * Add status property to each label (checked, empty, neutral)
   */
  getLabelsWithStatus = () => {
    const { labels, postsPerTag, selectedPostsList } = this.props;

    return [
      {
        title: "Labels",
        open: true,
        tags: labels.map(l =>
          postsPerTag[l.id]
            ? {
                ...l,
                status:
                  postsPerTag[l.id].length === selectedPostsList.length
                    ? "checked"
                    : "neutral"
              }
            : { ...l, status: "empty" }
        )
      }
    ];
  };

  /**
   * remove tag duplicates from taglist
   * @param taglist
   * @return {object}
   */
  removeDuplicates = taglist => {
    const isMediaresource = this.props.postType === ENTITIES[MEDIA_RESOURCE];
    return Object.keys(taglist).reduce(
      (o, p) => {
        isMediaresource
          ? o.post_ids.push(Number(p))
          : o.mediafile_ids.push(Number(p));
        taglist[p].forEach(t => {
          if (o.tags.indexOf(t) < 0) {
            o.tags = [...o.tags, ...[t]];
          }
        });
        return o;
      },
      isMediaresource
        ? { post_ids: [], tags: [] }
        : { mediafile_ids: [], tags: [] }
    );
  };

  /**
   * save the changes. Divide clickedItems into an add and a remove list.
   */
  onUpdate = () => {
    const { clickedItems } = this.state;
    const { tagsPerPost } = this.props;
    let addTags = {};
    let removeTags = {};
    let add_res = null;
    let remove_res = null;

    Object.keys(clickedItems).forEach(tagId => {
      tagId = Number(tagId);
      if (clickedItems[tagId].length > 0) {
        const posts = clickedItems[tagId];
        posts.forEach(postId => {
          if (!tagsPerPost[postId] || tagsPerPost[postId].indexOf(tagId) < 0) {
            if (addTags[postId]) {
              addTags[postId].push(tagId);
            } else {
              addTags[postId] = [tagId];
            }
          }
        });
      } else {
        Object.keys(tagsPerPost).forEach(postId => {
          if (tagsPerPost[postId].indexOf(tagId) >= 0) {
            if (removeTags[postId]) {
              removeTags[postId].push(tagId);
            } else {
              removeTags[postId] = [tagId];
            }
          }
        });
      }
    });

    if (Object.keys(addTags).length > 0) {
      add_res = this.removeDuplicates(addTags);
    }

    if (Object.keys(removeTags).length > 0) {
      remove_res = this.removeDuplicates(removeTags);
    }

    if (add_res !== null || remove_res !== null) {
      this.props.postType === ENTITIES[MEDIA_RESOURCE]
        ? this.props.addRemoveTagPost(
            this.props.postType,
            add_res,
            remove_res,
            !!this.props.useCurrentPost
          )
        : this.props.addRemoveTagMedia(
            this.props.entityId,
            add_res,
            remove_res,
            !!this.props.useCurrentPost
          );
    }

    this.setState({
      isOpen: false,
      clickedItems: {}
    });
  };

  /**
   * click on a tag (checkbox) in tagwindow
   */
  tagClick = (tagId, status) => {
    const { selectedPostsList } = this.props;
    const { clickedTags } = this.state;
    let { clickedItems } = this.state;
    let tag = {};
    const tagList = this.getTagList();
    const postIds = selectedPostsList.map(post =>
      post.id !== null ? post.id : 0
    );

    clickedItems[tagId] = status === "checked" ? postIds : [];

    const temp = tagList.map(g => {
      g.tags = g.tags.map(t => {
        if (t.id === tagId) {
          t.status = status;
          tag = t;
        }
        return t;
      });
      return g;
    });
    clickedTags[tagId] = tag;

    this.setState({
      tagList: temp,
      clickedItems,
      clickedTags
    });
  };

  render() {
    const { useCurrentPost, selectedPostsList } = this.props;
    const useInlineSectionSkin = !!useCurrentPost;

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <div ref={this.clickRef}>
        <StyledTagMedia>
          <StyledTagPostWrapper>
            {this.renderButtonOrHeader(useInlineSectionSkin)}
            <StyledTagPostContent
              className={classNames}
              detailview={useInlineSectionSkin}
            >
              <StyledTop>
                <StyledTitle>Välj labels:</StyledTitle>
              </StyledTop>
              <StyledMiddle>{this.renderTagList()}</StyledMiddle>
              <StyledBottom detailview={useInlineSectionSkin}>
                <StyledUpdateButton onClick={this.onUpdate}>
                  {" "}
                  Uppdatera {selectedPostsList.length} poster{" "}
                </StyledUpdateButton>
              </StyledBottom>
            </StyledTagPostContent>
          </StyledTagPostWrapper>
          {useInlineSectionSkin ? this.renderCurrentPostTags() : null}
        </StyledTagMedia>
      </div>
    );
  }

  /**
   * returns a list of labels and tags. If there is no tagList in state - use tagList from props.
   */
  getTagList = () => {
    return this.state.tagList.length > 0
      ? this.state.tagList
      : [...this.getLabelsWithStatus(), ...this.getTagsWithStatus()];
  };

  /**
   * Render the tags for the current mediafile.
   */
  renderCurrentPostTags = () => {
    const { selectedPostsList, tagsPerPost } = this.props;
    const { tagsPerPostState } = this.state;
    const tagList = this.getTagList();
    const postId = selectedPostsList[0]
      ? selectedPostsList[0].id !== null
        ? selectedPostsList[0].id
        : 0
      : 0;
    const tags = tagsPerPostState.length > 0 ? tagsPerPostState : tagsPerPost;

    const tagIds = tags[postId] ? tags[postId] : [];
    return <StyledInlineTagDisplay mediaTags={tagIds} tagList={tagList} />;
  };

  /**
   * Renders the button or header of the tag selection window depending if it is on a detailview or listview
   * @param {bool} useInlineSectionSkin
   * @param {string} title
   */
  renderButtonOrHeader = useInlineSectionSkin =>
    useInlineSectionSkin ? (
      <StyledHeader>
        <StyledInlineSectionButton studlicon="Cog" onClick={this.onToggle}>
          <StyledHeaderTitle>Välj labels</StyledHeaderTitle>
        </StyledInlineSectionButton>
      </StyledHeader>
    ) : (
      <MediaButton
        outline
        disabled={this.props.disabled}
        studlicon="Label"
        onClick={this.onToggle}
      >
        Välj labels
      </MediaButton>
    );

  /**
   * Renders the tag list with current status
   * @param {array} tagList
   */
  renderTagList = () => {
    const tagList = this.getTagList();
    return tagList.map(g => g.tags.length > 0 && (
      <StyledMiddleContent key={g.title}>
        <Collapsible title={g.title} open={true}>
          <TagMediaLabels
            labelList={g.tags}
            onChange={this.tagClick}
            scrollable={false}
            singleselect={false}
          />
        </Collapsible>
      </StyledMiddleContent>
    ));
  };
}

const mapStateToProps = (state, props) => {
  const postType = selectCurrentPostType(state);
  const entityId = selectEntityId(state);
  const selMediaList = selectSelectedMediaList(
    state,
    props.useCurrentPost,
    postType
  );
  const isSystem = selectIsInSystem(state);
  const tags = isSystem
    ? selectTagsForCurrentEntity(state, postType) 
    : selectTags(state, postType);

  return {
    postType: postType,
    entityId: entityId,
    selectedPostsList: selMediaList,
    labels: selectLabels(state, postType).toJS(),
    tags: tags,
    tagsPerPost: getTagsPerMedia(selMediaList),
    postsPerTag: getMediaPerTag(selMediaList)
  };
};

const mapDispatchToProps = dispatch => ({
  addRemoveTagMedia: (entityId, add, remove, detailpage) =>
    dispatch(addRemoveTagsToMedia(entityId, add, remove, detailpage)),
  addRemoveTagPost: (postType, add, remove, detailpage) =>
    dispatch(addRemoveTagsToPost(postType, add, remove, detailpage))
});

export default connect(mapStateToProps, mapDispatchToProps)(TagMedia);
