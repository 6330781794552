import styled from "styled-components";

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 0.75rem;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > input,
  & > select {
    margin-top: 0.25rem;
  }

  ${({ disabled }) => 
    disabled &&
    ` opacity: 0.7;`
  }

  ${({ error }) =>
    error &&
    `
    color: #B00020;
  `}
`;

export default Label;
