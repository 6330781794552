import React from "react";

const Aida = ({ size, color }) => (
  <svg
    viewBox="0 0 22 22"
    width={`${size}px`}
    fill={color}
    className="studlicon-aida"
  >
  <path d="M5,13.18 L5,17.18 L12,21 L19,17.18 L19,13.18 L12,17 L5,13.18 Z M12,3 L1,9 L12,15 L21,10.09 L21,17 L23,17 L23,9 L12,3 Z" id="path-1"></path>
  
</svg>
);

export default Aida;
