export const LOAD_MORE_MEDIA = "LOAD_MORE_MEDIA";
export const LOAD_LESS_MEDIA = "LOAD_LESS_MEDIA";
export const RELOAD_MEDIA = "RELOAD_MEDIA";
export const FILTER_MEDIA = "FILTER_MEDIA";
export const UPDATE_MEDIAFILE = "UPDATE_MEDIAFILE";
export const LOAD_MEDIA_PAGE = "LOAD_MEDIA_PAGE";
export const LOAD_MEDIA_DETAILS = "LOAD_MEDIA_DETAILS";
export const MEDIA_ADD_REMOVE_TAGS = "MEDIA_ADD_REMOVE_TAGS";
export const DELETE_MEDIAFILES = "DELETE_MEDIAFILES";

export const loadMedia = () => ({
  type: LOAD_MORE_MEDIA
});

export const loadLessMedia = () => ({
  type: LOAD_LESS_MEDIA
});

export const reloadMedia = () => ({
  type: RELOAD_MEDIA
});

export const filterMedia = filter => ({
  type: FILTER_MEDIA,
  filter
});

export const loadMediaPage = page => ({
  type: LOAD_MEDIA_PAGE,
  page
});

export const fetchMediaByID = id => ({
  type: LOAD_MEDIA_DETAILS,
  id
});

export const updateMediaFile = (id, data) => ({
  type: UPDATE_MEDIAFILE,
  id,
  data
});

export const deleteMediaFiles = ids => ({
  type: DELETE_MEDIAFILES,
  ids
});

export const addRemoveTagsToMedia = (entityId, add, remove, detailpage) => ({
  type: MEDIA_ADD_REMOVE_TAGS,
  entityId,
  add,
  remove,
  detailpage
});
