import {
  START_FETCH,
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITY_DETAILS_SUCCESS,
  FETCH_ENTITY_DETAILS_ERROR
} from "../../api";

export const ACTION_FETCH_CLASSROOMGROUPS = `CLASSROOM/${FETCH_ENTITIES}`;
export const ACTION_FETCH_CLASSROOMGROUPS_START = `CLASSROOM/${START_FETCH}`;
export const ACTION_FETCH_CLASSROOMGROUPS_SUCCESS = `CLASSROOM/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_CLASSROOMGROUPS_FAILED = `CLASSROOM/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_CLASSROOMGROUPS_END = `CLASSROOM/${END_FETCH}`;

export const ACTION_FETCH_CLASSROOMGROUP_DETAILS = `CLASSROOM/${FETCH_ENTITY_DETAILS}`;
export const ACTION_FETCH_CLASSROOMGROUP_DETAILS_START = `CLASSROOM/${START_FETCH}`;
export const ACTION_FETCH_CLASSROOMGROUP_DETAILS_SUCCESS = `CLASSROOM/${FETCH_ENTITY_DETAILS_SUCCESS}`;
export const ACTION_FETCH_CLASSROOMGROUP_DETAILS_FAILED = `CLASSROOM/${FETCH_ENTITY_DETAILS_ERROR}`;
export const ACTION_FETCH_CLASSROOMGROUP_DETAILS_END = `CLASSROOM/${END_FETCH}`;

export const ACTION_FILTER_CLASSROOMGROUPS = `ACTION_FILTER_CLASSROOMGROUPS`;
export const ACTION_FETCH_CLASSROOMGROUP_PERMISSIONS = `ACTION_FETCH_CLASSROOMGROUP_PERMISSIONS`;
export const ACTION_FETCH_CLASSROOMGROUP_PERMISSION_SUCCESS = `ACTION_FETCH_CLASSROOMGROUP_PERMISSION_SUCCESS`;

export const SORT_CLASSROOMGROUPS = `SORT_CLASSROOMGROUPS`;
export const RESET_BEFORE_FILTER_SORT_CHANGE = `RESET_BEFORE_FILTER_SORT_CHANGE`;

export const ACTION_UPDATE_CLASSROOMGROUP_DETAILS = `ACTION_UPDATE_CLASSROOMGROUP_DETAILS`;
export const ACTION_CREATE_CLASSROOMGROUP = `CLASSROOM/CREATE_CLASSROOMGROUP`;
export const ACTION_ADD_PRODUCT_TO_CLASSROOMGROUP = `CLASSROOM/ADD_PRODUCT_TO_CLASSROOMGROUP`;
export const ACTION_DELETE_PRODUCT_FROM_CLASSROOMGROUP = `CLASSROOM/DELETE_PRODUCT_FROM_CLASSROOMGROUP`;

export const ACTION_PUBLISH_CLASSROOMGROUP = `ACTION_PUBLISH_CLASSROOMGROUP`;

export const ACTION_FETCH_PREPARED_ASSIGNMENTS = `ACTION_FETCH_PREPARED_ASSIGNMENTS`;
export const ACTION_FETCH_PREPARED_ASSIGNMENTS_SUCCESS = `ACTION_FETCH_PREPARED_ASSIGNMENTS_SUCCESS`;
export const ACTION_DELETE_PREPARED_ASSIGNMENTS_FROM_PRODUCT = `ACTION_DELETE_PREPARED_ASSIGNMENTS_FROM_PRODUCT`;
export const ACTION_CLEAR_PREPARED_ASSIGNMENTS_SUCCESS = `ACTION_CLEAR_PREPARED_ASSIGNMENTS_SUCCESS`;

export const ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE = `ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE`;
export const ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE_SUCCESS = `ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE_SUCCESS`;
export const ACTION_DOWNLOAD_PREPARED_ASSIGNMENTS = `ACTION_DOWNLOAD_PREPARED_ASSIGNMENTS`;

export const ACTION_PUBLISH_PREPARED_ASSIGNMENTS = `ACTION_PUBLISH_PREPARED_ASSIGNMENTS`;
export const ACTION_FETCH_FORMATIVE_TESTS = `ACTION_FETCH_FORMATIVE_TESTS`;
export const ACTION_FETCH_FORMATIVE_TESTS_SUCCESS = `ACTION_FETCH_FORMATIVE_TESTS_SUCCESS`;
export const ACTION_PUBLISH_FORMATIVE_TESTS = `ACTION_PUBLISH_FORMATIVE_TESTS`;

export const ACTION_UPLOAD_FORMATIVE_TESTS = `ACTION_UPLOAD_FORMATIVE_TESTS`;
export const ACTION_UPLOAD_FORMATIVE_TESTS_SUCCESS = `ACTION_UPLOAD_FORMATIVE_TESTS_SUCCESS`;
export const ACTION_DOWNLOAD_FORMATIVE_TESTS = `ACTION_DOWNLOAD_FORMATIVE_TESTS`;

export const ACTION_DELETE_FORMATIVE_TESTS_FROM_PRODUCT = `ACTION_DELETE_FORMATIVE_TESTS_FROM_PRODUCT`;
export const ACTION_DELETE_FORMATIVE_TESTS_SUCCESS = `ACTION_DELETE_FORMATIVE_TESTS_SUCCESS`;
export const ACTION_DELETE_FORMATIVE_QUESTION = `ACTION_DELETE_FORMATIVE_QUESTION`;
export const ACTION_DELETE_FORMATIVE_QUESTION_SUCCESS = `ACTION_DELETE_FORMATIVE_QUESTION_SUCCESS`;

export const ACTION_CREATE_FORMATIVE_QUESTION = `ACTION_CREATE_FORMATIVE_QUESTION`;
export const ACTION_CREATE_FORMATIVE_QUESTION_SUCCESS = `ACTION_CREATE_FORMATIVE_QUESTION_SUCCESS`;
export const ACTION_FETCH_FORMATIVE_QUESTION_DATA = `ACTION_FETCH_FORMATIVE_QUESTION_DATA`;
export const ACTION_FETCH_FORMATIVE_QUESTION_DATA_SUCCESS = `ACTION_FETCH_FORMATIVE_QUESTION_DATA_SUCCESS`;
export const ACTION_UPDATE_FORMATIVE_QUESTION_DATA = `ACTION_UPDATE_FORMATIVE_QUESTION_DATA`;

export const ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM = `ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM`;
export const ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM_SUCCESS = `ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM_SUCCESS`;
export const ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION = `ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION`;
export const ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION_SUCCESS = `ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION_SUCCESS`;

export const ACTION_SET_FORMATIVE_QUESTION_SELECTED_PRODUCT = `ACTION_SET_FORMATIVE_QUESTION_SELECTED_PRODUCT`;

export const ACTION_FETCH_CLASSROOMGROUP_STICKERS = `ACTION_FETCH_CLASSROOMGROUP_STICKERS`;
export const ACTION_FETCH_CLASSROOMGROUP_STICKERS_SUCCESS = `ACTION_FETCH_CLASSROOMGROUP_STICKERS_SUCCESS`;
export const ACTION_DELETE_STICKERS = `ACTION_DELETE_STICKERS`;
export const ACTION_ADD_STICKERS_TO_CLASSROOMGROUP = `ACTION_ADD_STICKERS_TO_CLASSROOMGROUP`;
export const ACTION_PUBLISH_STICKERS = `ACTION_PUBLISH_STICKERS`;

export const fetchClassroomGroups = (args, searchParameters = {}) => ({
  type: ACTION_FETCH_CLASSROOMGROUPS,
  args,
  searchParameters
});

export const fetchClassroomGroupsStart = () => ({
  type: ACTION_FETCH_CLASSROOMGROUPS_START
});

export const fetchClassroomGroupsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_CLASSROOMGROUPS_SUCCESS,
  data,
  metadata
});

export const fetchClassroomGroupsFailed = error => ({
  type: ACTION_FETCH_CLASSROOMGROUPS_FAILED,
  error
});

export const fetchClassroomGroupsEnd = () => ({
  type: ACTION_FETCH_CLASSROOMGROUPS_END
});

export const filterClassroomGroups = filter => ({
  type: ACTION_FILTER_CLASSROOMGROUPS,
  filter
});

export const sortClassroomGroups = sort => ({
  type: SORT_CLASSROOMGROUPS,
  sort
});

export const resetBeforeFilterSortChange = () => ({
  type: RESET_BEFORE_FILTER_SORT_CHANGE
});

export const createClassroomGroup = data => ({
  type: ACTION_CREATE_CLASSROOMGROUP,
  data
});

export const fetchClassroomGroupDetails = payload => ({
  type: ACTION_FETCH_CLASSROOMGROUP_DETAILS,
  payload
});

export const fetchClassroomGroupDetailsStart = () => ({
  type: ACTION_FETCH_CLASSROOMGROUP_DETAILS_START
});

export const fetchClassroomGroupDetailsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_CLASSROOMGROUP_DETAILS_SUCCESS,
  data,
  metadata
});

export const fetchClassroomGroupDetailsFailed = error => ({
  type: ACTION_FETCH_CLASSROOMGROUP_DETAILS_FAILED,
  error
});

export const fetchClassroomGroupDetailsEnd = () => ({
  type: ACTION_FETCH_CLASSROOMGROUP_DETAILS_END
});

export const updateClassroomgroupDetails = (classroomGroupEntityId, data) => ({
  type: ACTION_UPDATE_CLASSROOMGROUP_DETAILS,
  classroomGroupEntityId,
  data
});

export const addProductToClassroomGroup = (
  productEntityId,
  classroomGroupEntityId
) => ({
  type: ACTION_ADD_PRODUCT_TO_CLASSROOMGROUP,
  productEntityId,
  classroomGroupEntityId
});

export const deleteProductFromClassroomGroup = (
  productEntityId,
  classroomGroupEntityId
) => ({
  type: ACTION_DELETE_PRODUCT_FROM_CLASSROOMGROUP,
  productEntityId,
  classroomGroupEntityId
});

export const publishClassroomGroup = classroomGroupEntityId => ({
  type: ACTION_PUBLISH_CLASSROOMGROUP,
  classroomGroupEntityId
});

export const fetchPreparedAssignmentsForProduct = (
  classroomGroupEntityId,
  postId
) => ({
  type: ACTION_FETCH_PREPARED_ASSIGNMENTS,
  classroomGroupEntityId,
  postId
});

export const fetchPreparedAssignmentsForProductSuccess = data => ({
  type: ACTION_FETCH_PREPARED_ASSIGNMENTS_SUCCESS,
  data
});

export const uploadPreparedAssignmentsFile = (
  data,
  classroomGroupEntityId,
  postId,
  productId
) => ({
  type: ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE,
  data,
  classroomGroupEntityId,
  postId,
  productId
});

export const uploadPreparedAssignmentsFileSuccess = () => ({
  type: ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE_SUCCESS
});

export const deletePreparedAssignmentsforProduct = (
  classroomGroupEntityId,
  postId
) => ({
  type: ACTION_DELETE_PREPARED_ASSIGNMENTS_FROM_PRODUCT,
  classroomGroupEntityId,
  postId
});

export const clearPreparedAssignmentsSuccess = () => ({
  type: ACTION_CLEAR_PREPARED_ASSIGNMENTS_SUCCESS
});

export const publishPreparedAssignmentsToLive = (
  classroomGroupEntityId,
  postIds
) => ({
  type: ACTION_PUBLISH_PREPARED_ASSIGNMENTS,
  classroomGroupEntityId,
  postIds
});

export const downloadPreparedAssignments = (
  classroomGroupEntityId,
  postId
) => ({
  type: ACTION_DOWNLOAD_PREPARED_ASSIGNMENTS,
  classroomGroupEntityId,
  postId
});

export const fetchFormativeTestsForProduct = (
  classroomGroupEntityId,
  postId
) => ({
  type: ACTION_FETCH_FORMATIVE_TESTS,
  classroomGroupEntityId,
  postId
});

export const fetchFormativeTestsForProductSuccess = data => ({
  type: ACTION_FETCH_FORMATIVE_TESTS_SUCCESS,
  data
});

export const publishFormativeTestsToLive = (
  classroomGroupEntityId,
  postIds
) => ({
  type: ACTION_PUBLISH_FORMATIVE_TESTS,
  classroomGroupEntityId,
  postIds
});

export const deleteFormativeTestsforProduct = (
  classroomGroupEntityId,
  postId
) => ({
  type: ACTION_DELETE_FORMATIVE_TESTS_FROM_PRODUCT,
  classroomGroupEntityId,
  postId
});

export const deleteFormativeTestsSuccess = () => ({
  type: ACTION_DELETE_FORMATIVE_TESTS_SUCCESS
});

export const downloadFormativeTests = (classroomGroupEntityId, postId) => ({
  type: ACTION_DOWNLOAD_FORMATIVE_TESTS,
  classroomGroupEntityId,
  postId
});

export const uploadFormativeTestsFile = (
  classroomGroupEntityId,
  postId,
  data
) => ({
  type: ACTION_UPLOAD_FORMATIVE_TESTS,
  classroomGroupEntityId,
  postId,
  data
});

export const deleteFormativeQuestion = (
  classroomGroupEntityId,
  questionId,
  postId
) => ({
  type: ACTION_DELETE_FORMATIVE_QUESTION,
  classroomGroupEntityId,
  questionId,
  postId
});

export const createFormativeQuestion = (classroomGroupEntityId, postId) => ({
  type: ACTION_CREATE_FORMATIVE_QUESTION,
  classroomGroupEntityId,
  postId
});

export const createFormativeQuestionSuccess = data => ({
  type: ACTION_CREATE_FORMATIVE_QUESTION_SUCCESS,
  data
});

export const fetchFormativeQuestionData = postId => ({
  type: ACTION_FETCH_FORMATIVE_QUESTION_DATA,
  postId
});

export const fetchFormativeQuestionDataSuccess = data => ({
  type: ACTION_FETCH_FORMATIVE_QUESTION_DATA_SUCCESS,
  data
});

export const updateFormativeQuestionData = (postId, data) => ({
  type: ACTION_UPDATE_FORMATIVE_QUESTION_DATA,
  postId,
  data
});

export const deleteFormativeQuestionsMediaItem = mediaId => ({
  type: ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM,
  mediaId
});

export const deleteFormativeQuestionsMediaItemSuccess = data => ({
  type: ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM_SUCCESS,
  data
});

export const deleteFormativeQuestionsMediaSection = mediaType => ({
  type: ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION,
  mediaType
});

export const deleteFormativeQuestionsMediaSectionSuccess = data => ({
  type: ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION_SUCCESS,
  data
});

export const setFormativeQuestionSelectedProduct = id => ({
  type: ACTION_SET_FORMATIVE_QUESTION_SELECTED_PRODUCT,
  id
});

export const fetchClassroomGroupPermissions = () => ({
  type: ACTION_FETCH_CLASSROOMGROUP_PERMISSIONS
});

export const fetchClassroomGroupPermissionSuccess = data => ({
  type: ACTION_FETCH_CLASSROOMGROUP_PERMISSION_SUCCESS,
  data
});

export const fetchClassroomStickers = classroomGroupEntityId => ({
  type: ACTION_FETCH_CLASSROOMGROUP_STICKERS,
  classroomGroupEntityId
});

export const fetchClassroomStickersSuccess = data => ({
  type: ACTION_FETCH_CLASSROOMGROUP_STICKERS_SUCCESS,
  data
});

export const deleteStickers = (
  classroomGroupEntityId, 
  stickerIds
) => ({
  type: ACTION_DELETE_STICKERS,
  classroomGroupEntityId,
  stickerIds
});

export const addStickers = (
  classroomGroupEntityId,
  stickers
) => ({
  type: ACTION_ADD_STICKERS_TO_CLASSROOMGROUP,
  classroomGroupEntityId,
  stickers
});

export const publishStickers = classroomGroupEntityId => ({
  type: ACTION_PUBLISH_STICKERS,
  classroomGroupEntityId
});
