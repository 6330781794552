import React from "react";
import { PageHeader } from "../../../shared";
import PostEditor from "../../containers/PostEditor";
import styled from "styled-components";

import { ENTITIES, RESOURCE } from "../../../constants";

const ResourceDetailView = styled.div`
  background-color: ${props => props.theme.neutralLight};
  padding: 7px 10px;
`;

const ResourceDetails = () => (
  <React.Fragment>
    <ResourceDetailView>
      <PageHeader headerIcon="Layers" title="Resurs" />
    </ResourceDetailView>
    <PostEditor type={ENTITIES[RESOURCE]} />
  </React.Fragment>
);

export default ResourceDetails;
