import React from "react";
import { SelectField } from "../Input";
import { MEDIA_TYPES } from "../../../media/api/constants";

const availableTypes = MEDIA_TYPES.filter(item => item.type !== "image");
availableTypes.push({ title: "Sammanfattning", type: "summary" });

const SelectMediaType = ({ selectedMediaType, onChange, disabled }) => (
  <SelectField
    disabled={disabled}
    showValue
    value={selectedMediaType?.value.toLowerCase() || ""}
    onChange={onChange}
    label="Medietyp"
    name="mediaType"
    options={[
      !selectedMediaType && { title: "Välj", type: null },
      ...availableTypes
    ]
      .filter(Boolean)
      .map(v => ({ key: v.title, val: v.type }))}
  />
);

export default SelectMediaType;
