import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  ACTION_FETCH_STRUCTURE_START,
  ACTION_FETCH_STRUCTURE_SUCCESS,
  ACTION_FETCH_STRUCTURE_END,
  ACTION_SAVE_STRUCTURE_START,
  ACTION_SAVE_STRUCTURE_END,
  ACTION_SET_STRUCTURE_DRAFT,
  ACTION_UPDATE_STRUCTURE_DRAFT,
  ACTION_CREATE_STRUCTURE_DRAFT,
  ACTION_RESET_TREE_DRAFT,
  ACTION_POP_REDO_RECORD,
  ACTION_ADD_REDO_RECORD,
  ACTION_POP_UNDO_RECORD,
  ACTION_ADD_UNDO_RECORD,
  ACTION_TREE_DRAFT_UPDATE,
  ACTION_UPDATE_GOAL_ORDER_REDUCER,
  FETCH_GOAL_SUCCESS,
  ACTION_CREATE_GOAL_DRAFT,
  ACTION_RESET_GOAL_DRAFT,
  ACTION_ADD_GOALS_UNDO_RECORD,
  ACTION_POP_GOALS_UNDO_RECORD,
  ACTION_ADD_GOALS_REDO_RECORD,
  ACTION_POP_GOALS_REDO_RECORD,
  ACTION_CLEAR_UNDO_REDO_ARRAYS,
  ACTION_UPDATE_STAGE_CHANGES_REDUCER,
  ACTION_PUBLISH_STRUCTURE_SUCCESS,
  ACTION_PUBLISH_STRUCTURE,
  ACTION_PUBLISH_STRUCTURE_FAILED
} from "./actions";

export const requiredFields = {
  active: {},
  list: [],
  fetching: false,
  nodeDraft: Map({ node: Map(), path: new List() }),
  treeDraft: Map({ tree: new List() }),
  goalsOrder: [],
  goalsDraft: [],
  replication: Map({ undo: new List(), redo: new List() }),
  goalsReplication: Map({ undo: new List(), redo: new List() }),
  publishing: false
};

const reducer = initialState => {
  let mergedState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(mergedState, {
    [ACTION_FETCH_STRUCTURE_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_STRUCTURE_SUCCESS](state, action) {
      return state.update("list", () => new List(action.data));
    },
    [ACTION_FETCH_STRUCTURE_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_SAVE_STRUCTURE_START](state) {
      return state;
    },
    [ACTION_SAVE_STRUCTURE_END](state) {
      return state.set("list", state.getIn(["treeDraft", "tree"]));
    },
    [ACTION_UPDATE_STRUCTURE_DRAFT](state, action) {
      return state.set("nodeDraft", fromJS(action.data));
    },
    [ACTION_SET_STRUCTURE_DRAFT](state, action) {
      return state.set("nodeDraft", fromJS(action.data));
    },
    [ACTION_CREATE_STRUCTURE_DRAFT](state, action) {
      return state.updateIn(
        ["treeDraft", "tree"],
        () => new List(fromJS(action.data))
      );
    },
    [ACTION_RESET_TREE_DRAFT](state) {
      return state.set("treeDraft", Map({ tree: state.get("list") }));
    },
    [ACTION_TREE_DRAFT_UPDATE](state, { tree }) {
      return state.setIn(["treeDraft", "tree"], new List(tree));
    },
    [ACTION_ADD_UNDO_RECORD](state, action) {
      return state.updateIn(["replication", "undo"], list =>
        list.push(action.data)
      );
    },
    [ACTION_POP_UNDO_RECORD](state) {
      return state.updateIn(["replication", "undo"], list => list.pop());
    },
    [ACTION_ADD_REDO_RECORD](state, action) {
      return state.updateIn(["replication", "redo"], list =>
        list.push(action.prevTree)
      );
    },
    [ACTION_POP_REDO_RECORD](state) {
      return state.updateIn(["replication", "redo"], list => list.pop());
    },
    [ACTION_CLEAR_UNDO_REDO_ARRAYS](state) {
      return state.set(
        "replication",
        Map({ undo: new List(), redo: new List() })
      );
    },

    [FETCH_GOAL_SUCCESS](state, action) {
      return state.update("goalsOrder", () => new List(action.data));
    },
    [ACTION_CREATE_GOAL_DRAFT](state, action) {
      return state.set("goalsDraft", new List(action.data));
    },
    [ACTION_UPDATE_GOAL_ORDER_REDUCER](state, action) {
      return state.update("goalsDraft", () => new List(action.data));
    },
    [ACTION_RESET_GOAL_DRAFT](state) {
      return state.set("goalsDraft", new List(state.get("goalsOrder")));
    },
    [ACTION_ADD_GOALS_UNDO_RECORD](state, { data }) {
      return state.updateIn(["goalsReplication", "undo"], list =>
        list.push(data)
      );
    },
    [ACTION_POP_GOALS_UNDO_RECORD](state) {
      return state.updateIn(["goalsReplication", "undo"], list => list.pop());
    },
    [ACTION_ADD_GOALS_REDO_RECORD](state, { data }) {
      return state.updateIn(["goalsReplication", "redo"], list =>
        list.push(data)
      );
    },
    [ACTION_POP_GOALS_REDO_RECORD](state) {
      return state.updateIn(["goalsReplication", "redo"], list => list.pop());
    },
    [ACTION_UPDATE_STAGE_CHANGES_REDUCER](state, { data }) {
      const checkedList = state.get("goalsDraft").map(goal => {
        if (goal.stage === data.stage) {
          return { ...goal, stage: 0 };
        }
        return goal;
      });
      const newList = checkedList.map(goal => {
        return goal.category_ref === data.category_ref ? data : goal;
      });
      return state.set("goalsDraft", newList);
    },
    [ACTION_PUBLISH_STRUCTURE](state) {
      return state.set("publishing", true);
    },
    [ACTION_PUBLISH_STRUCTURE_SUCCESS](state) {
      return state.set("publishing", false);
    },
    [ACTION_PUBLISH_STRUCTURE_FAILED](state) {
      return state.set("publishing", false);
    }
  });
};

export default reducer;
