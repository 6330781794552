import styled from "styled-components";
import {
  blue,
  white,
  neutralQuaternaryAlt,
  neutralPrimary
} from "../../../styles/shared/colors";

const getBackgroundColor = overrideStyles =>
  overrideStyles && overrideStyles.backgroundColor
    ? overrideStyles.backgroundColor
    : neutralQuaternaryAlt;

const getTextColor = overrideStyles =>
  overrideStyles && overrideStyles.color
    ? overrideStyles.color
    : neutralPrimary;

export const StyledRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-area: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.gridArea : "auto"};
  margin: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.margin : "0"};
  width: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.containerWidth : "auto"};
`;

export const StyledRadioButton = styled.div`
  width: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.width : "30px"};
  height: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.height : "30px"};
  cursor: ${({ overrideStyles }) =>
    overrideStyles ? overrideStyles.cursor : "pointer"};
  border-radius: 4px;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.isActive ? blue : getBackgroundColor(props.overrideStyles)};
  color: ${props =>
    props.isActive ? white : getTextColor(props.overrideStyles)};
  line-height: normal;
`;

export const StyledLabel = styled.span`
  margin-top: 6px;
  color: ${neutralPrimary};
  font-size: 12px;
  font-weight: 600;
  user-select: none;
`;
