import * as baseline from "./baseline";
import * as colors from "./colors";
import * as font from "./font";
import * as headings from "./headings";
import * as animations from "./animations";
import * as buttons from "./buttons";

const styles = {
  ...baseline,
  ...colors,
  ...font,
  ...headings,
  ...animations,
  buttons
};

export default styles;
