import React, { useState } from "react";
import styled from "styled-components";
import PublishButton from "./PublishButton";
import Card from "./Card";
import ImageUpload from "./ImageUpload";
import { getSesamAvailable, getProductTypes } from "../store/selectors";
import { Field, SelectField } from "./Input";
import AutoComplete from "./AutoComplete";
import Label from "./Label";
import {
  createValidation,
  ErrorFormatter,
  productErrors,
  WeakProduct
} from "../validation/product";
import Modal from "../../shared/components/Modal/Modal";
import PrimaryButton from "../../shared/components/Button/Primary";
import CommercialButton from "./CommercialButton";
import DiscontinueButton from "./DiscontinueButton";
import Tag from "../../shared/components/Tag";
import { useSelector } from "react-redux";

const Search = styled(AutoComplete)`
  width: 100%;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ProductTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: space-between;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;
const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const NOOP = () => {};

const weakValidation = createValidation(
  WeakProduct,
  ErrorFormatter(productErrors)
);

const ProductForm = ({
  defaultValues,
  onSubmit,
  onPublish,
  onSetCommercial,
  onSetDiscontinued,
  disabled,
  onImageChosen,
  subjects = [],
  stages = [],
  productType
}) => {
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [hasChanges, setHasChanges] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [newTypeId, setNewTypeId] = useState(null);
  const types = useSelector(getProductTypes).toJS();

  const productTypeOmniaCourse = "omnia_course";
  const productTypeOmniaBook = "omnia_book";
  const productTypeOmniaBiaf = "omnia_biaf";
  const productTypesDillXpub = ["dill", "xpub", "dill_cloud", "xpub_cloud"];
  const productTypeAdapt = "adapt";

  const isDillXpub = productTypesDillXpub.includes(productType.toLowerCase());
  const isOmniaBook = productType === productTypeOmniaBook;
  const isOmniaBiaf = productType === productTypeOmniaBiaf;
  const isOmniaProduct =
    productType === productTypeOmniaCourse || isOmniaBook || isOmniaBiaf;
  const isAdapt = productType === productTypeAdapt;

  const activeTypes =
    isDillXpub &&
    types.filter(
      t =>
        t.type.toLowerCase() === "dill_cloud" ||
        t.type.toLowerCase() === "xpub_cloud"
    );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    const v = {
      ...defaultValues,
      sesam_name: defaultValues.sesam.validated ? defaultValues.sesam.name : "",
      tempfolder: !defaultValues.sesam.validated ? defaultValues.sesam.name : ""
    };
    setValues(v);
    setHasChanges([]);
    setErrors({});
  }, [defaultValues]);

  const _onChange = (val, name) => {
    if (errors[name]) {
      const { err } = weakValidation({
        ...values,
        [name]: val
      });
      setErrors(err || {});
    }

    setValues({
      ...values,
      [name]: val
    });

    if (defaultValues[name] === val) {
      setHasChanges(hasChanges.filter(n => n !== name));
      return;
    }

    setHasChanges([...hasChanges, name]);
  };

  const _onSelectChange = (val, name) => {
    if (isDillXpub && defaultValues.typeId !== val) {
      setNewTypeId({ name: name, value: val });
      setIsModalOpen(true);
      return;
    }
    const data = {
      ...values,
      [name]: val
    };

    setValues(data);
    setHasChanges([]);
    setErrors({});

    delete data.sesam_name;
    delete data.tempfolder;

    onSubmit(data);
  };

  const _onSesamSelectChange = val => {
    if (val === "") {
      return;
    }
    setShowDropDown(false);
    const data = {
      ...values,
      tempfolder: "",
      sesam_name: val
    };

    setValues(data);

    if (defaultValues.sesam.validated && defaultValues.sesam.name !== val) {
      setIsModalOpen(true);
    } else {
      setValues(data);
      setHasChanges([]);
      setErrors({});
      const submitData = {
        ...values,
        sesam: {
          name: val,
          validated: true
        }
      };
      delete submitData.sesam_name;
      delete submitData.tempfolder;

      onSubmit(submitData);
    }
  };

  const submit = () => {
    if (hasChanges.length === 0) {
      return;
    }
    let { err, data } = weakValidation(values);
    setShowDropDown(false);

    if (err) {
      setErrors(err);
      return;
    }

    if (
      defaultValues.sesam.validated &&
      defaultValues.sesam.name !== values.sesam_name
    ) {
      setIsModalOpen(true);
    } else {
      setHasChanges([]);
      setErrors({});
      const submitData = {
        ...data,
        sesam: {
          name:
            data.tempfolder.length === 0 ? data.sesam_name : data.tempfolder,
          validated: data.tempfolder.length === 0
        }
      };
      delete submitData.sesam_name;
      delete submitData.tempfolder;
      onSubmit(submitData);
    }
  };

  const publish = () => {
    if (hasChanges.length > 0) {
      const errs = hasChanges.reduce(
        (res, key) => ({
          ...res,
          [key]: "Ändringar måste sparas innan det går att publicera."
        }),
        {}
      );

      setErrors(errs);

      return;
    }

    onPublish();
  };

  const setCommercial = () => {
    if (defaultValues.publishedProdTime) {
      onSetCommercial();
    }
  };

  const setDiscontinued = () => {
    if (defaultValues.publishedProdTime) {
      onSetDiscontinued();
    }
  };

  const submitAfterCheck = () => {
    setIsModalOpen(false);
    setHasChanges([]);
    setErrors({});
    const submitData = {
      ...values,
      sesam: {
        name: values.sesam_name,
        validated: true
      }
    };
    delete submitData.sesam_name;
    delete submitData.tempfolder;

    onSubmit(submitData);
  };

  const submitTypeAfterCheck = () => {
    const data = {
      ...values,
      [newTypeId.name]: newTypeId.value
    };

    setValues(data);
    setHasChanges([]);
    setErrors({});
    setShowDropDown(false);
    setIsModalOpen(false);

    delete data.sesam_name;
    delete data.tempfolder;

    onSubmit(data);
  };

  return (
    <Card
      title="Egenskaper"
      info="Information om hur användare redigerar Egenskaper."
    >
      {!isDillXpub && (
        <>
          <Label style={{ marginBottom: "0.25rem" }}>Produkttyp</Label>
          <Tag
            label={productType}
            url=""
            changeCallback={NOOP}
            deleteCallback={NOOP}
            labelColor="#287574"
          />
        </>
      )}
      <Form>
        {!isOmniaProduct && !isAdapt && (
          <>
            {showDropDown ? (
              <SelectField
                error={errors["typeId"]}
                value={values.typeId || 0}
                onChange={_onSelectChange}
                onBlur={!newTypeId ? () => setShowDropDown(false) : NOOP}
                label="Produkttyp"
                name="typeId"
                options={activeTypes.map(v => ({ key: v.type, val: v.id }))}
                disabled={disabled}
              />
            ) : (
              <Label>
                Produkttyp
                <ProductTypeWrapper>
                  <Tag
                    label={productType}
                    url=""
                    deleteCallback={NOOP}
                    editCallback={() => setShowDropDown(true)}
                    labelColor="#287574"
                    isEditable
                  />
                </ProductTypeWrapper>
              </Label>
            )}
          </>
        )}
        {isDillXpub && (
          <>
            {values && !values.sesam_name ? (
              <Label>
                Sesamgrupp
                <Search
                  placeholder="Sök sesamgrupp"
                  onSelect={_onSesamSelectChange}
                  selector={getSesamAvailable}
                  name="sesam_name"
                  value={values.sesam_name}
                  error={errors["sesam_name"]}
                />
              </Label>
            ) : (
              <Field
                error={errors["sesam_name"]}
                value={values.sesam_name}
                onChange={_onChange}
                onBlur={submit}
                disabled={disabled || defaultValues.publishedProdTime}
                label="Sesamgrupp"
                name="sesam_name"
              />
            )}
          </>
        )}
        <Field
          error={errors["tempfolder"]}
          value={values.tempfolder}
          onChange={_onChange}
          onBlur={submit}
          disabled={
            (values.sesam && values.sesam.validated) ||
            defaultValues.publishedProdTime
          }
          label="Tillfälligt mappnamn"
          name="tempfolder"
        />
        <Field
          error={errors["title"]}
          value={values.title}
          onChange={_onChange}
          onBlur={submit}
          disabled={disabled}
          label="Titel"
          name="title"
        />
        {!isOmniaBook && !isOmniaBiaf && (
          <Field
            error={errors["niceName"]}
            value={values.niceName}
            onChange={_onChange}
            onBlur={submit}
            disabled={disabled}
            label="Kort titel"
            name="niceName"
          />
        )}
        <Field
          error={errors["artnr"]}
          value={values.artnr}
          onChange={_onChange}
          onBlur={submit}
          disabled={disabled}
          label="Artikelnummer"
          name="artnr"
        />
        {!isOmniaProduct && !isDillXpub && (
          <Field
            error={errors["isbn"]}
            value={values.isbn}
            onChange={_onChange}
            onBlur={submit}
            disabled={disabled}
            label="ISBN"
            name="isbn"
          />
        )}
        {!isOmniaProduct && !isDillXpub && (
          <Field
            error={errors["authors"]}
            value={values.authors}
            onChange={_onChange}
            onBlur={submit}
            disabled={disabled}
            label="Författare"
            name="authors"
          />
        )}
        {isOmniaProduct && (
          <Field
            error={errors["publisher"]}
            value={values.publisher}
            onChange={_onChange}
            onBlur={submit}
            disabled={disabled}
            label="Förlag"
            name="publisher"
          />
        )}
        {!isOmniaProduct && !isDillXpub && (
          <SelectField
            error={errors["stageId"]}
            value={values.stageId || 0}
            onChange={_onSelectChange}
            disabled={disabled}
            label="Nivå"
            name="stageId"
            options={[
              !values.stageId ? { stage: "Nivå", id: 0 } : null,
              ...stages
            ]
              .filter(Boolean)
              .map(v => ({
                key: v.stage,
                val: v.id
              }))}
          />
        )}
        {!isOmniaProduct && !isDillXpub && (
          <SelectField
            error={errors["subjectId"]}
            value={values.subjectId || 0}
            onChange={_onSelectChange}
            disabled={disabled}
            label="Område"
            name="subjectId"
            options={[
              !values.subjectId ? { subject: "Område", id: 0 } : null,
              ...subjects
            ]
              .filter(Boolean)
              .map(v => ({
                key: v.subject,
                val: v.id
              }))}
          />
        )}
        {isDillXpub && (
          <Field
            error={errors["commonfolders"]}
            value={values.commonfolders}
            onChange={_onChange}
            onBlur={submit}
            disabled={disabled}
            label="Gemensamma mappar"
            name="commonfolders"
          />
        )}
      </Form>
      {!isOmniaProduct && (
        <Form>
          <ImageUpload
            name="coverUrl"
            img={values.coverUrl}
            onChange={onImageChosen}
            error={errors["coverUrl"]}
          />
        </Form>
      )}
      {!isOmniaProduct && (
        <CommercialButton
          commercialTime={defaultValues.commercializationDate}
          disabled={
            !defaultValues.publishedProdTime ||
            defaultValues.commercializationDate
          }
          onClick={setCommercial}
        />
      )}
      {isOmniaProduct && (
        <DiscontinueButton
          discontinueDate={defaultValues.discontinueDate}
          disabled={
            !defaultValues.publishedProdTime || defaultValues.isDiscontinued
          }
          onClick={setDiscontinued}
        />
      )}
      <ButtonWrapper>
        <PublishButton
          toLive={false}
          disabled
          publishtime={defaultValues.publishedPreviewTime}
          isLatest
        />
        <PublishButton
          toLive
          onClick={publish}
          disabled={Object.keys(errors).length}
          publishtime={defaultValues.publishedProdTime}
          isLatest={defaultValues.publishedProdTime >= defaultValues.updatedAt}
        />
      </ButtonWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        {newTypeId ? (
          <StyledModalContent>
            {`Är du säker på att du vill ändra produkttyp till ${
              activeTypes.find(t => t.id === newTypeId.value).type
            }?`}
            <StyledButton onClick={submitTypeAfterCheck}>Ändra</StyledButton>
          </StyledModalContent>
        ) : (
          <StyledModalContent>
            Om du ändrar sesamgrupp, måste du publicera om produktens innehåll.
            Vill du ändra sesamgrupp?
            <StyledButton onClick={submitAfterCheck}>Ändra</StyledButton>
          </StyledModalContent>
        )}
      </Modal>
    </Card>
  );
};

export default ProductForm;
