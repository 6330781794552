import React from "react";

const MoveBack = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-moveback"
  >
    <rect
      transform="translate(25.000000, 20.000000) rotate(-270.000000) translate(-25.000000, -20.000000) translate(20.000000, -0.000000)"
      fill="#377AD3"
      id="path-1"
      x="0"
      y="0"
      width="10"
      height="40"
    ></rect>
    <path
      transform="translate(25.000000, 35.000000) rotate(-270.000000) translate(-25.000000, -35.000000) translate(15.000000, 15.000000)"
      fill="#384E70"
      d="M10,10.1005051 L19.8994949,20 L10,29.8994949 L10,40 L0,40 L0,0 L10,0 L10,10.1005051 Z"
      id="path-3"
    ></path>
  </svg>
);

export default MoveBack;
