import React from "react";
import { useSelector, useDispatch } from "react-redux";
import NavStateless from "../../components/Nav";
import { buildNavigation, hasSelectedProductId } from "../../store/navigation/selectors";
import {
  setSelectedProductId,
  setIfProductSelectedMessageVisible,
} from "../../../shared/store/navigation/actions";
import { selectPermissions } from "../../../products/store/selectors";
import { setTitle } from "../../../shared/"

const Nav = () => {
  const { sections, collapsed, title, hideNavigation } = useSelector(
    buildNavigation
  );
  const permissions = useSelector(selectPermissions);
  const hasSelectedId = useSelector(hasSelectedProductId);
  const dispatch = useDispatch();

  if (hideNavigation) {
    return null;
  }

  const menuClickHandler = (text, to) => {
    if (text === "Produkt" && !hasSelectedId) {
      dispatch(setIfProductSelectedMessageVisible(true));
    }
    if (to.payload.entityId === null) {
      dispatch({type: "ROUTE_PRODUCTS", payload: {}});
    }
    if (to.payload.entityType !== "product") {
      dispatch(setSelectedProductId(null));
      dispatch(setIfProductSelectedMessageVisible(false));
    }
    if (!to.payload.entityType) {
      dispatch(setTitle(text));
    }
  };

  return (
    <NavStateless
      sections={sections}
      title={title}
      collapsed={collapsed}
      clickHandler={menuClickHandler}
      permissions={permissions}
    />
  );
};

export default Nav;
