import styled from "styled-components";
import TagSelector from "../TagSelector";
import Collapsible from "../Collapsible";

export const SectionContent = styled(Collapsible)`
  padding-top: 10px;
`;

export const StyledSection = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid lightgrey;
`;

export const Title = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
`;

export const SectionHeader = styled.div`
  height: 20px;
  margin-bottom: 10px;
`;

export const ChooseLabel = styled(TagSelector)`
  padding-top: 25px;
`;
