import React, { useState, useCallback, useEffect } from "react";
import TreeSection from "./TreeSection";
import styled from "styled-components";
import useGetChipsBelowNodes from "./useGetChipsBelowNodes";

const StyledTreeWrapper = styled.ul`
  list-style: none;
  padding-left: 5px;
  margin-top: 0;
  height: 38rem;
  overflow-y: scroll;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0.2rem;
  margin-top: 0.5rem;
  color: ${props => props.theme.neutralDark};
`;

const TreeSelect = ({ items, postChips, onTreeChange }) => {
  const [chipSelected, setChipSelected] = useState(postChips || []);
  const [tree, setTree] = useState([]);
  const { nodeObj } = useGetChipsBelowNodes(tree);

  useEffect(() => {
    if (items.length > 0) {
      setTree(items);
    }
  }, [items]);

  const memChipChange = useCallback(
    val => {
      if (val.add) {
        const addedChips = [...chipSelected, ...val.add];
        setChipSelected(addedChips);
        onTreeChange(addedChips);
      }
      if (val.remove) {
        const newList = chipSelected.filter(c => val.remove.indexOf(c) < 0);
        setChipSelected(newList);
        onTreeChange(newList);
      }
    },
    [chipSelected, onTreeChange]
  );
  
  return (
    <>
      {items.map(i => (
        <div key={i.id}>
          <StyledTitle>{i.title}</StyledTitle>
          <StyledTreeWrapper>
            {i.sub_sections.map(s => (
              <TreeSection
                key={s.id}
                item={s}
                onChipChange={memChipChange}
                chipsSel={chipSelected}
                chipsBelowNode={nodeObj}
              />
            ))}
          </StyledTreeWrapper>
        </div>
      ))}
    </>
  );
};

export default TreeSelect;
