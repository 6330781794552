import React from "react";
import styled from "styled-components";

import BarSectionHeader from "./Header";

const BarContent = styled.div`
  padding: 2px 10px;
`;

const BarSection = ({ className, children, title, primaryAction }) => (
  <section className={className}>
    <BarSectionHeader title={title} primaryAction={primaryAction} />
    <BarContent>{children}</BarContent>
  </section>
);

export default styled(BarSection)`
  color: #333;
  font-size: 10px;
  margin: 0;
  list-style-type: none;
  width: 100%;
  padding: 3px 10px 23px 6px;
  border-bottom: 1px solid #ccc;
`;
