import { map, reduce, findFirst } from "fp-ts/lib/Array";
import { fold } from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import { fold as oFold } from "fp-ts/lib/Option";
import * as T from "io-ts";

const rxHttp = /(http(s?)):\/\//i;
const rxSpecialChars = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂æÞþÐð0-9 !@#^&*)(+=–.,:?!'_-]+$/;

const ID = T.brand(T.number, n => n > 0, "id");

const rxSesam = /^[a-zA-ZäöåÄÖÅ0-9\-_:]+$/;
const SesamName = T.brand(T.string, s => rxSesam.test(s), "sesam_name");

const rxTitle = rxSpecialChars;
const Title = T.brand(T.string, s => rxTitle.test(s), "title");

const NiceName = T.brand(T.string, s => rxTitle.test(s), "niceName");

const rxArtNr = /^[a-zA-ZäöåÄÖÅ0-9-]+$/;
const ArticleNumber = T.brand(T.string, s => rxArtNr.test(s), "artnr");

const rxISBN = /^\d+$/;
const ISBN = T.brand(T.string, s => rxISBN.test(s), "isbn");

const rxAuthors = rxSpecialChars;
const Authors = T.brand(T.string, s => rxAuthors.test(s), "authors");

const Stage = T.brand(T.number, n => n > 0, "stageId");

const Subject = T.brand(T.number, n => n > 0, "subjectId");

const CoverUrl = T.brand(T.string, s => rxHttp.test(s), "coverUrl");

const ProductType = T.brand(T.number, n => n > 0, "typeId");

export const Product = T.type({
  id: ID,
  sesam_name: SesamName,
  title: Title,
  niceName: NiceName,
  artnr: ArticleNumber,
  isbn: ISBN,
  authors: Authors,
  stageId: Stage,
  subjectId: Subject,
  coverUrl: CoverUrl,
  typeId: ProductType
});

const optional = t => T.union([t, T.null, T.undefined, T.literal("")]);

export const WeakProduct = T.interface({
  id: optional(ID),
  sesam_name: optional(SesamName),
  tempfolder: optional(SesamName),
  title: Title,
  typeId: ProductType,
  niceName: optional(NiceName),
  artnr: optional(ArticleNumber),
  isbn: optional(ISBN),
  authors: optional(Authors),
  stageId: optional(Stage),
  subjectId: optional(Subject),
  coverUrl: optional(CoverUrl)
});

export const CreateProduct = T.interface({
  sesam_name: optional(SesamName),
  tempfolder: optional(SesamName),
  title: Title,
  typeId: ProductType,
})

export const ImportAProduct = T.interface({
  sesam_name: SesamName
});
export const ErrorFormatter = (format = {}) => (key, value) =>
  format[key] && format[key](value);

export const productErrors = {
  title: () =>
    "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: - – ( ) + , ' & : ? ! .",
  niceName: () =>
    "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: - – ( ) + , ' & : ? ! .",
  sesam_name: () => "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: - _ :",
  tempfolder: () => "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: - _ :",
  artnr: () =>
    "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: -",
  isbn: () =>
    `ISBN nr ska anges med siffror utan mellanslag, exempelvis "9789144007533"`,
  authors: () =>
    "Får enbart innehålla bokstäver och siffror, samt följande specialtecken: - , . ( ) &",
  typeId: () => "Produkten måste vara kopplad till en produkttyp."
};

const getValidationKey = flow(
  e => e.context,
  findFirst(c => c.key.length > 0),
  oFold(
    () => "",
    c => c.key
  )
);

export const fromValidationErrors = formatter =>
  flow(
    map(e => [getValidationKey(e), e.value]),
    reduce({}, (fieldErrors, [key, value]) => ({
      ...fieldErrors,
      [key]: formatter ? formatter(key, value) : `Invalid: ${key}`
    }))
  );

export const createValidation = (codec, formatter) => data =>
  pipe(
    data,
    codec.decode,
    fold(
      flow(fromValidationErrors(formatter), err => ({ err, data: undefined })),
      data => ({ err: undefined, data })
    )
  );
