import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  START_FETCH
} from "../../api";

export const ACTION_FETCH_STRUCTURE = `STRUCTURE/${FETCH_ENTITIES}`;
export const ACTION_FETCH_STRUCTURE_START = `STRUCTURE/${START_FETCH}`;
export const ACTION_FETCH_STRUCTURE_SUCCESS = `STRUCTURE/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_STRUCTURE_FAILED = `STRUCTURE/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_STRUCTURE_END = `STRUCTURE/${END_FETCH}`;

export const ACTION_FETCH_GOALS = `STRUCTURE/FETCH_GOALS`;
export const ACTION_FETCH_GOALS_START = `STRUCTURE/GOALS_START`;
export const ACTION_FETCH_GOALS_FAILED = `STRUCTURE/GOALS_FAILED`;
export const ACTION_FETCH_GOALS_END = `STRUCTURE/GOALS_END`;

export const ACTION_PUBLISH_STRUCTURE = `STRUCTURE/PUBLISH`;
export const ACTION_PUBLISH_STRUCTURE_FAILED = `STRUCTURE/PUBLISH_FAILED`;
export const ACTION_PUBLISH_STRUCTURE_SUCCESS = `STRUCTURE/PUBLISH_SUCCESS`;

export const ACTION_SAVE_STRUCTURE = `STRUCTURE/SAVE`;
export const ACTION_SAVE_STRUCTURE_START = `STRUCTURE/SAVE_START`;
export const ACTION_SAVE_STRUCTURE_SUCCESS = `STRUCTURE/SAVE_SUCCESS`;
export const ACTION_SAVE_STRUCTURE_FAILED = `STRUCTURE/SAVE_FAILED`;
export const ACTION_SAVE_STRUCTURE_END = `STRUCTURE/SAVE_END`;

export const ACTION_CREATE_CATEGORY = `STRUCTURE/CREATE_CATEGORY`;
export const ACTION_CREATE_CATEGORY_START = `STRUCTURE/CREATE_CATEGORY_START`;
export const ACTION_CREATE_CATEGORY_SUCCESS = `STRUCTURE/CREATE_CATEGORY_SUCCESS`;
export const ACTION_CREATE_CATEGORY_FAILED = `STRUCTURE/CREATE_CATEGORY_FAILED`;
export const ACTION_CREATE_CATEGORY_END = `STRUCTURE/CREATE_CATEGORY_END`;

export const ACTION_UPDATE_CATEGORY = `STRUCTURE/UPDATE_CATEGORY`;
export const ACTION_UPDATE_CATEGORY_FAILED = `ACTION_UPDATE_CATEGORY_FAILED`;

export const ACTION_SET_STRUCTURE_DRAFT = `ACTION_SET_STRUCTURE_DRAFT`;
export const ACTION_UPDATE_STRUCTURE_DRAFT = `ACTION_UPDATE_STRUCTURE_DRAFT`;
export const ACTION_CREATE_STRUCTURE_DRAFT = `ACTION_CREATE_STRUCTURE_DRAFT`;
export const ACTION_RESET_TREE_DRAFT = `ACTION_RESET_TREE_DRAFT`;
export const ACTION_ADD_NODE_ID_FOR_REMOVAL = `ACTION_ADD_NODE_ID_FOR_REMOVAL`;
export const ACTION_UPDATE_TREE_DRAFT = `ACTION_UPDATE_TREE_DRAFT`;
export const ACTION_RESET_DELETED = `ACTION_RESET_DELETED`;
export const ACTION_REMOVE_NODES = `ACTION_REMOVE_NODES`;

export const ACTION_UNDO_TREE = `ACTION_UNDO_TREE`;
export const ACTION_TREE_DRAFT_UPDATE = `ACTION_TREE_DRAFT_UPDATE`;
export const ACTION_REDO_TREE = `ACTION_REDO_TREE`;
export const ACTION_CLEAR_UNDO_REDO_ARRAYS = `ACTION_CLEAR_UNDO_REDO_ARRAYS`;
export const ACTION_ADD_UNDO_RECORD = `ACTION_ADD_UNDO_RECORD`;
export const ACTION_POP_UNDO_RECORD = `ACTION_POP_UNDO_RECORD`;
export const ACTION_ADD_REDO_RECORD = `ACTION_ADD_REDO_RECORD`;
export const ACTION_POP_REDO_RECORD = `ACTION_POP_REDO_RECORD`;

export const FETCH_GOAL_SUCCESS = `FETCH_GOAL_SUCCESS`;
export const ACTION_UPDATE_STAGE_CHANGES = `ACTION_UPDATE_STAGE_CHANGES`;
export const ACTION_UPDATE_STAGE_CHANGES_REDUCER = `ACTION_UPDATE_STAGE_CHANGES_REDUCER`;
export const ACTION_UPDATE_GOAL_ORDER = `ACTION_UPDATE_GOAL_ORDER`;
export const ACTION_UPDATE_GOAL_ORDER_REDUCER = `ACTION_UPDATE_GOAL_ORDER_REDUCER`;
export const ACTION_CREATE_GOAL_DRAFT = `ACTION_CREATE_GOAL_DRAFT`;
export const ACTION_RESET_GOAL_DRAFT = `RESET_GOAL_DRAFT`;
export const ACTION_SAVE_GOALS_ORDER_CHANGES = `ACTION_SAVE_GOALS_ORDER_CHANGES`;
export const ACTION_UNDO_GOALS = `ACTION_UNDO_GOALS`;
export const ACTION_REDO_GOALS = `ACTION_REDO_GOALS`;
export const ACTION_ADD_GOALS_UNDO_RECORD = `ACTION_ADD_GOAL_UNDO_RECORD`;
export const ACTION_POP_GOALS_UNDO_RECORD = `ACTION_POP_GOAL_UNDO_RECORD`;
export const ACTION_ADD_GOALS_REDO_RECORD = `ACTION_ADD_GOAL_REDO_RECORD`;
export const ACTION_POP_GOALS_REDO_RECORD = `ACTION_POP_GOAL_REDO_RECORD`;

export const removeNodeAction = (id, tree) => ({
  type: ACTION_REMOVE_NODES,
  id,
  tree
});

export const pushRedoAction = prevTree => ({
  type: ACTION_ADD_REDO_RECORD,
  prevTree
});

export const popUndoAction = () => ({
  type: ACTION_POP_UNDO_RECORD
});

export const popRedoAction = () => ({
  type: ACTION_POP_REDO_RECORD
});

export const onTreeDraftUpdateAction = tree => ({
  type: ACTION_TREE_DRAFT_UPDATE,
  tree
});

export const onTreeUpdateAction = data => ({
  type: ACTION_ADD_UNDO_RECORD,
  data
});

export const undoTreeChangeAction = () => ({
  type: ACTION_UNDO_TREE
});

export const redoTreeChangeAction = () => ({
  type: ACTION_REDO_TREE
});

export const clearUndoRedoArraysAction = () => ({
  type: ACTION_CLEAR_UNDO_REDO_ARRAYS
});

export const fetchStructure = entityId => ({
  type: ACTION_FETCH_STRUCTURE,
  entityId
});

export const fetchStructureStart = () => ({
  type: ACTION_FETCH_STRUCTURE_START
});

export const fetchStructureSuccess = (data, metadata) => ({
  type: ACTION_FETCH_STRUCTURE_SUCCESS,
  data,
  metadata
});

export const fetchStructureFailed = error => ({
  type: ACTION_FETCH_STRUCTURE_FAILED,
  error
});

export const fetchStructureEnd = () => ({
  type: ACTION_FETCH_STRUCTURE_END
});

export const fetchGoals = () => ({
  type: ACTION_FETCH_GOALS
});

export const fetchGoalsStart = () => ({
  type: ACTION_FETCH_GOALS_START
});

export const fetchGoalsFailed = error => ({
  type: ACTION_FETCH_GOALS_FAILED,
  error
});

export const fetchGoalsEnd = () => ({
  type: ACTION_FETCH_GOALS_END
});

export const saveStructure = data => ({
  type: ACTION_SAVE_STRUCTURE,
  data
});

export const saveStructureStart = () => ({
  type: ACTION_SAVE_STRUCTURE_START
});

export const saveStructureSuccess = entityId => ({
  type: ACTION_SAVE_STRUCTURE_SUCCESS,
  data: {
    entityId
  }
});

export const saveStructureFailed = error => ({
  type: ACTION_SAVE_STRUCTURE_FAILED,
  data: error
});

export const saveStructureEnd = entityId => ({
  type: ACTION_SAVE_STRUCTURE_END,
  data: {
    entityId
  }
});

export const publishStructure = () => ({
  type: ACTION_PUBLISH_STRUCTURE
});

export const publishStructureFailed = error => ({
  type: ACTION_PUBLISH_STRUCTURE_FAILED,
  data: error
});

export const publishStructureSuccess = data => ({
  type: ACTION_PUBLISH_STRUCTURE_SUCCESS,
  data
});

export const createCategory = title => ({
  type: ACTION_CREATE_CATEGORY,
  data: {
    title
  }
});

export const createCategoryStart = () => ({
  type: ACTION_CREATE_CATEGORY_START
});

export const createCategorySuccess = data => ({
  type: ACTION_CREATE_CATEGORY_SUCCESS,
  data
});

export const createCategoryFailed = () => ({
  type: ACTION_CREATE_CATEGORY_FAILED
});

export const createCategoryEnd = () => ({
  type: ACTION_CREATE_CATEGORY_END
});

export const updateCategory = (id, data, path) => ({
  type: ACTION_UPDATE_CATEGORY,
  id,
  data,
  path
});

export const updateCategoryFailed = () => ({
  type: ACTION_UPDATE_CATEGORY_FAILED
});

export const setActiveNode = data => ({
  type: ACTION_SET_STRUCTURE_DRAFT,
  data
});

export const updateActiveNode = data => ({
  type: ACTION_UPDATE_STRUCTURE_DRAFT,
  data
});

export const createStructureDraft = (data, metadata) => ({
  type: ACTION_CREATE_STRUCTURE_DRAFT,
  data,
  metadata
});

export const resetTreeDraft = () => ({
  type: ACTION_RESET_TREE_DRAFT
});

export const addIdForRemoval = (id, tree) => ({
  type: ACTION_ADD_NODE_ID_FOR_REMOVAL,
  id,
  tree
});

export const updateTreeDraftAction = (tree, bypassReplication = false) => ({
  type: ACTION_UPDATE_TREE_DRAFT,
  tree,
  bypassReplication
});

export const resetDeletedList = () => ({
  type: ACTION_RESET_DELETED
});

export const updateStageChanges = data => ({
  type: ACTION_UPDATE_STAGE_CHANGES,
  data
});

export const updateStageChangesReducer = data => ({
  type: ACTION_UPDATE_STAGE_CHANGES_REDUCER,
  data
});

export const updateGoalOrder = (data, bypassReplication) => ({
  type: ACTION_UPDATE_GOAL_ORDER,
  data,
  bypassReplication
});

export const updateGoalOrderReducer = data => ({
  type: ACTION_UPDATE_GOAL_ORDER_REDUCER,
  data
});

export const fetchGoalSuccess = data => ({
  type: FETCH_GOAL_SUCCESS,
  data
});

export const createGoalDraft = data => ({
  type: ACTION_CREATE_GOAL_DRAFT,
  data
});

export const resetGoalDraft = () => ({
  type: ACTION_RESET_GOAL_DRAFT
});

export const saveGoalsOrderChanges = () => ({
  type: ACTION_SAVE_GOALS_ORDER_CHANGES
});

export const undoGoalsChange = () => ({
  type: ACTION_UNDO_GOALS
});

export const redoGoalsChange = () => ({
  type: ACTION_REDO_GOALS
});

export const addGoalsUndoRecord = data => ({
  type: ACTION_ADD_GOALS_UNDO_RECORD,
  data
});

export const popGoalsUndoRecord = () => ({
  type: ACTION_POP_GOALS_UNDO_RECORD
});

export const addGoalsRedoRecord = data => ({
  type: ACTION_ADD_GOALS_REDO_RECORD,
  data
});

export const popGoalsRedoRecord = () => ({
  type: ACTION_POP_GOALS_REDO_RECORD
});
