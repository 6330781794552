import React from "react";
import {
  PlaceableImage,
  PlaceableImageWrapper,
  StyledIntroductionViewLayout,
  StyledTextBoxWrapper,
  StyledWrapper,
  StyledIntroductionViewRightColumn,
  StyledPlaceablesArea,
  StyledHeading,
  StyledId,
  StyledSectionName,
  StyledFeedbackBox,
  StyledFeedbackHeading,
  StyledFeedbackDetails
} from './StyledJSONPreview.js';
import SliderBox from "./SliderBox";


const JSONPreview = (props) => {
  
  const placeablesRenderer = (images) => {
    return (
      images.map((image) => (
        <PlaceableImageWrapper
          key={`${image.id}_${image.url}`}
          scale={image.scale}
          offsetX={image.offset_x}
          offsetY={image.offset_y}
          zIndex={image.z_index}>
          <PlaceableImage
            src={getImgUrl(image.url)}
            key={image.id}
            isMirrored={image.is_mirrored}
            alt="placeable" />
        </PlaceableImageWrapper>
      ))
    );
  };

  /**
   * The JSON data only has the image file name so
   * we need to build the full url from the media file list.
   */
  const getImgUrl = (fileName) => props.mediaList[fileName] && props.mediaList[fileName].url;

  const renderFeedbackBox = (feedback) => {
    return (
      <StyledFeedbackBox>
        <StyledFeedbackHeading>Feedback:</StyledFeedbackHeading>
        <StyledFeedbackDetails>title: {feedback.title}</StyledFeedbackDetails>
        <StyledFeedbackDetails>text: {feedback.text}</StyledFeedbackDetails>
        <StyledFeedbackDetails>audio: {feedback.audio}</StyledFeedbackDetails>
      </StyledFeedbackBox>
    )
  };

  const renderItemCard = (section, card, y) => {
    return (
      <div key={y}>
        <StyledHeading>({y}) Item title: {card.title}</StyledHeading>
        {card.feedback && renderFeedbackBox(card.feedback)}
        <StyledHeading>Item slides:</StyledHeading>
        {Array.isArray(card.posts) && card.posts.map((post, index) => {
          return renderCard(section + "/" + card.title, post, index);
        })}
      </div>
    )
  };

  const renderCard = (section, card, y) => {
    return (
      <div key={y}>
        <StyledId>{section}/id{y}</StyledId>
        <StyledWrapper backgroundImage={getImgUrl(card.background_image)}>
          <StyledIntroductionViewLayout>
            <StyledIntroductionViewRightColumn>
              <StyledTextBoxWrapper>
                <SliderBox body={card.body} audio={card.audio} mediaList={props.mediaList} />
              </StyledTextBoxWrapper>
            </StyledIntroductionViewRightColumn>
            <StyledPlaceablesArea>
              {card.images && placeablesRenderer(card.images)}
            </StyledPlaceablesArea>
          </StyledIntroductionViewLayout>
        </StyledWrapper>
      </div>
    )
  };

  const renderSection = (section, i) => {
    return (
      <div key={i}>
        <StyledSectionName>{section}</StyledSectionName>
        {Array.isArray(props.data[section]) && props.data[section].map((item, y) => {
          return section === "items" ?
            renderItemCard(section, item, y) : renderCard(section, item, y);
        })}
      </div>
    )
  }

  return (
    props.data ? Object.keys(props.data).map((section, i) => (
      renderSection(section, i)
    ))
      : null
  )
}

export default JSONPreview
