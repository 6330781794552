import React, { useState } from "react";
import { PrimaryButton } from "../../../shared/components/Button";
import styled from "styled-components";
import Modal from "../../../shared/components/Modal/Modal";
import CreateNewProductForm from "./CreateNewProductForm";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0;
  margin-top: 0.5rem;
`
const StyledModalContent = styled.div`
  width: 530px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 1.7rem;
  border-radius: 7px;
  position: relative;
`

const AddProduct = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const renderModal = () => (
    <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
      <StyledModalContent>
        <CreateNewProductForm close={closeModal}/>
      </StyledModalContent>
    </Modal>
  );

  return (
    <>
    {isModalOpen ? renderModal() : null}
    <StyledPrimaryButton onClick={() => setIsModalOpen(true)} studlicon="Plus">Ny produkt</StyledPrimaryButton>
    </>
  )
}

export default AddProduct;