export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const UPDATE_SHADOW_CLOSE = "UPDATE_SHADOW_CLOSE";
export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";

export const modalClose = dispatch =>
  dispatch({
    type: CLOSE_MODAL
  });

export const modalOpen = dispatch => (params = {}) =>
  dispatch({
    type: OPEN_MODAL,
    ...params
  });

export const modalUpdate = dispatch => (params = {}) =>
  dispatch({
    type: UPDATE_MODAL,
    ...params
  });

export const updateDisableShadowClose = (dispatch, payload) =>
  dispatch({type: UPDATE_SHADOW_CLOSE, payload: payload});

export const openConfirmModal = dispatch => 
  dispatch({ type: OPEN_CONFIRM_MODAL });

export const closeConfirmModal = dispatch => 
  dispatch({ type: CLOSE_CONFIRM_MODAL }); 
