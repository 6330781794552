import React, { useState } from "react";
import styled from "styled-components";
import Label from "../../../products/components/Label";
import ProductSearch from "../../../products/components/ProductSearch";
import IconButton from "../../../products/components/IconButton";
import PrimaryButton from "../../../shared/components/Button/Primary";
import { Trash2 as Trash } from "react-feather";
import Modal from "../../../shared/components/Modal/Modal";

const Search = styled(ProductSearch)`
  width: 100%;
  margin-top: 0;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const Text = styled.p`
  margin: 0;
`;

const StyledErrorMessage = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

const Wrapper = styled.div``;

const StyledProductsWrapper = styled.div`
  padding: 4px 0;
  ${props => (props.showScroll ? "overflow-y: scroll" : null)};
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const SeriesProducts = ({
  serie,
  productsList,
  errors,
  setErrors,
  setSerie
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const trashOnClick = id => {
    setIsModalOpen(true);
    setSelectedId(id);
  };

  const removeProductButtonClick = () => {
    setSerie({
      ...serie,
      products: serie.products.filter(id => id !== selectedId)
    });
    setIsModalOpen(false);
  };

  const addNewProductToGroup = id => {
    if (serie.products && isIdInList(id)) {
      setErrors({
        products: "Produkten finns redan med i gruppen."
      });
      return;
    }

    setErrors({});
    setSerie({
      ...serie,
      products: serie.products ? [...serie.products, id] : [id]
    });
  };

  const productsFromIds = productsList.filter(
    p => serie.products && serie.products.includes(p.id)
  );

  const isIdInList = id =>
    serie.products.filter(currentId => currentId === id).length > 0;

  return (
    <Wrapper>
      <Label>
        <Search
          placeholder="Sök produkt"
          onSelect={p => addNewProductToGroup(p.id)}
        />
        {Object.keys(errors).length !== 0 && (
          <StyledErrorMessage>{errors.products}</StyledErrorMessage>
        )}
      </Label>
      <StyledProductsWrapper
        showScroll={serie.products && serie.products.length > 6}
      >
        {productsFromIds &&
          productsFromIds.map(p => (
            <ProductWrapper key={p.id}>
              <Text>{p.sesam.name}</Text>
              <IconButton
                aria-label="Ta bort produkt från grupp"
                onClick={() => trashOnClick(p.id)}
              >
                <Trash width="16px" height="16px" />
              </IconButton>
            </ProductWrapper>
          ))}
      </StyledProductsWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={() => setIsModalOpen(false)}>
        <StyledModalContent>
          Vill du ta bort produkten från serien?
          <StyledButton onClick={removeProductButtonClick}>
            Ta bort
          </StyledButton>
        </StyledModalContent>
      </Modal>
    </Wrapper>
  );
};

export default SeriesProducts;
