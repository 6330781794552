import { RouterService } from "../shared";
import { registerReducer, registerSaga } from "../shared";
import { routes, views } from "./routes";

import rootSaga from "./sagas";
import { media as mediaReducer } from "../media/store/reducer";

import { mediaCarouselReducer } from "./store/carousel/reducer";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Media", mediaReducer);
  registerReducer("MediaCarousel", mediaCarouselReducer);
};
