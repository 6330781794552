import React from "react";
import Input from "../../../../../shared/components/Input";
import Text from "../../../../../shared/components/Text/Text";
import { isHTML } from "../../../../../shared/helpers";
import {
  StyledInputContainer,
  StyledTextEditorContainer
} from "./StyledInputCell";

const InputCell = ({
  cell,
  onChange,
  cellIndex,
  rowIndex,
  storeDraft,
  draftTarget,
  selectedCells
}) => {
  const onEditChange = (keyType, text, target) =>
    onChange(rowIndex, cellIndex)(text);

  React.useEffect(() => {
    if (cell.interactive && isHTML(cell.value)) {
      const stripedHtml = cell.value.replace(/<[^>]+>/g, "");
      onChange(rowIndex, cellIndex)(stripedHtml);
    }
  }, [cell.interactive, cell.value, cellIndex, onChange, rowIndex]);

  return (
    <StyledInputContainer
      color={cell.color}
      column={cell.column}
      row={cell.row}
    >
      <StyledTextEditorContainer color={cell.color}>
        {cell.interactive ? (
          <Input value={cell.value} onChange={onChange(rowIndex, cellIndex)} />
        ) : (
          <Text
            data={{ text: cell.value }}
            settings={{
              useInlineToolbar: true,
              oneLine: true,
              readOnly: false,
              useToolbar: selectedCells.length === 1
            }}
            storeDraft={storeDraft}
            draftTarget={draftTarget}
            updateData={onEditChange}
          />
        )}
      </StyledTextEditorContainer>
    </StyledInputContainer>
  );
};

export default InputCell;
