import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { File } from "react-feather";

import {
  StandardButton,
  PrimaryButton,
  updateDisableShadowClose
} from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import Link from "../../../shared/components/Link";
import { nextItem, prevItem } from "../../store/carousel/actions";
import TextArea from "../../../shared/components/TextArea";
import { updateMediaFile } from "../../store/actions";
import { Sound } from "../../../shared/components/StudliIcons";
import { format } from "../../../date";
import TagMedia from "../../components/TagMedia";
import { getPostUsageFromMediaData } from "../../containers/MediaList/helpers";
import { modalUpdate } from "../../../shared/store/modal/actions";
import { selectCurrentMediaLib, selectCurrentMedia } from "../../api/selectors";
import {
  MediaDetailWrapper,
  MediaItemContent,
  MediaWrapper,
  MediaDetailRow,
  DetailRow,
  MediaDetailContent,
  MediaDetailLeft,
  MediaDetailRight,
  Label,
  FileTypeWrapper,
  VersionWrapper,
  MediaDetailPaginationMarkup,
  StyledUsedByLabel,
  TextInputWrapper,
  TitleInput,
  MediaContainer,
  IconWrapper
} from "./styledMediaModalContent";
import { selectEntityId } from "../../../store/selectors";

const IMAGE_TYPE = "image";
const AUDIO_TYPE = "audio";
const VIDEO_TYPE = "video";
const DOCUMENT_TYPE = "document";
const NO_TYPE = null;

/**
 * Format the dateString
 * @param {*} dateString
 */
const formatDate = dateString => {
  const date = new Date(dateString);
  return format(date);
};

class MediaModalContent extends PureComponent {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      url: "",
      labels: [],
      changed: false,
      initialDesc: ""
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.changed !== this.state.changed) {
      this.props.handleHasChanged(this.state.changed);
    }
  }

  static getDerivedStateFromProps(nextprops, prevState) {
    const { activeMedia } = nextprops;

    if (activeMedia === null) return null;

    if (activeMedia.url !== prevState.url) {
      return {
        title: activeMedia.title,
        description: activeMedia.description,
        initialDesc: activeMedia.description,
        url: activeMedia.url
      };
    }
    return null;
  }

  /**
   * save changes to title in state
   */
  _onTitleChange = newTitle => {
    this.props.modalUpdate({
      actions: this.props.renderActions(true)
    });
    const { activeMedia } = this.props;
    this.props.updateShadowClose(true);
    this.setState({
      title: newTitle,
      changed: true
    });

    let data = {
      media_id: activeMedia.id,
      user_id: activeMedia.user_id,
      description: this.state.description
    };
    if (newTitle !== activeMedia.title) {
      data.title = newTitle;
    }
    this.props.handleSetMediaObj(data);
  };

  /**
   * save changes to description in state
   */
  _onDescChange = desc => {
    this.props.modalUpdate({
      actions: this.props.renderActions(true)
    });
    const { activeMedia } = this.props;
    this.props.updateShadowClose(true);
    this.setState({
      description: desc,
      changed: true
    });

    let data = {
      media_id: activeMedia.id,
      user_id: activeMedia.user_id,
      title: this.state.title
    };
    if (desc !== activeMedia.description) {
      data.description = desc;
    }
    this.props.handleSetMediaObj(data);
  };

  /**
   * Change media item
   */
  _changeMediaItem = () => {
    console.log("Ej implementerat. Change");
  };

  /**
   * Add a new file
   */
  _addNewFile = () => {
    console.log("Ej implementerat. Visa uppladdningsläge");
  };

  /**
   * Returns correct mediaType for file
   */
  getMediaType = media => {
    if (media.content_type.match(/^image\//)) return IMAGE_TYPE;
    if (media.content_type.match(/^audio\//)) return AUDIO_TYPE;
    if (media.content_type.match(/^video\//)) return VIDEO_TYPE;
    if (media.content_type.match(/^application\//)) return DOCUMENT_TYPE;

    return NO_TYPE;
  }

  /**
   * Returns filetype of current mediafile
   */
  getFileType = () => this.props.activeMedia.url.split(".").pop();

  render() {
    if (this.props.activeMedia === null) {
      return false;
    }

    return (
      <MediaDetailWrapper>
        {this.renderDetailHeader()}
        {this.renderMedia()}
        {this.renderMediaDetailContent()}
      </MediaDetailWrapper>
    );
  }

  /**
   * render header info in detailmodal. Pagination and image name
   */
  renderDetailHeader = () => {
    const { activeMedia } = this.props;

    return (
      <MediaDetailRow>
        <MediaDetailContent>
          <MediaDetailRight> {this.renderPagination()} </MediaDetailRight>
          <MediaDetailLeft>
            <Label>Filnamn:</Label> {activeMedia.original_filename}
          </MediaDetailLeft>
        </MediaDetailContent>
      </MediaDetailRow>
    );
  };

  /**
   * Render media view
   */
  renderMedia = () => {
    const mediaType = this.getMediaType(this.props.activeMedia);
    return (
      <MediaWrapper>
        {mediaType === IMAGE_TYPE && this.renderImage()}
        {mediaType === AUDIO_TYPE && this.renderAudio()}
        {mediaType === VIDEO_TYPE && this.renderVideo()}
        {mediaType === DOCUMENT_TYPE && this.renderDocument()}
      </MediaWrapper>
    );
  };

  /**
   * render image
   */
  renderImage = () => (
    <MediaContainer>
      <img src={this.props.activeMedia.url} alt="" />
    </MediaContainer>
  );

  /**
   * render audio
   */
  renderAudio = () => (
    <MediaContainer>
      <IconWrapper>
        <Sound size="180" viewBox="10 10 50 50" />
      </IconWrapper>
      <audio controls src={this.props.activeMedia.url} />
    </MediaContainer>
  );

  /**
   * render video
   */
  renderVideo = () => (
    <MediaContainer>
      <video controls src={this.props.activeMedia.url} />
    </MediaContainer>
  );

  /**
   * render document icon
   */
  renderDocument = () => (
    <MediaContainer>
      <IconWrapper>
        <File size="180" strokeWidth="1.5" />
      </IconWrapper>
    </MediaContainer>
  );

  /**
   * Render the detail content for current media file
   */
  renderMediaDetailContent = () => <div>{this.renderChildrenContent()}</div>;

  /**
   * Render children content
   */
  renderChildrenContent = () => {
    return (
      <MediaItemContent>
        <MediaDetailRow>
          {this.renderFileAndUserInfo()}
          {this.renderFileLabels()}
        </MediaDetailRow>

        <MediaDetailRow>
          {this.renderFileTypeAndConnectionInfo()}
          {this.renderTextInput()}
        </MediaDetailRow>

        <MediaDetailRow>{this.renderChangeFileMarkup()}</MediaDetailRow>
      </MediaItemContent>
    );
  };

  checkMediaConnectionInfo = () => {
    switch (this.props.medialib) {
      case "aida":
        return this.renderSystemMediaConnectionInfo();
      case "series":
        return this.renderSeriesMediaConnectionInfo();
      case "classroomgroup":
        return this.renderClassroomgroupMediaConnectionInfo();
      case "messages":
        return this.renderMessageserviceMediaConnectionInfo();
      default:
        return this.renderMediaConnectionInfo();
    }
  };

  /**
   * render when a file was updated and who it was uploaded by
   */
  renderFileAndUserInfo = () => (
    <div>
      <DetailRow>
        <Label>Uppdaterad: </Label>
        {formatDate(this.props.activeMedia.updated_at)}
      </DetailRow>
      <DetailRow>
        <Label>Uppladdad av: </Label> {this.props.activeMedia.user_id} (ska
        ersättas med namn)
      </DetailRow>
    </div>
  );

  /**
   * render the labels for current media file
   */
  renderFileLabels = () => (
    <MediaDetailRight>
      <TagMedia useCurrentPost={true} />
    </MediaDetailRight>
  );

  /**
   * render filetype, and how many exercises and resources this file is used by
   */
  renderFileTypeAndConnectionInfo = () => (
    <div>
      <DetailRow>
        <Label>Filtyp: </Label>
        <FileTypeWrapper>{this.getFileType().toUpperCase()}</FileTypeWrapper>
      </DetailRow>
      {this.checkMediaConnectionInfo()}
    </div>
  );

  /**
   * Render how many times this file is used in different places
   */
  renderMediaConnectionInfo = () => {
    const { activeMedia, currentEntityId, onModalClose } = this.props;
    const postUsage = getPostUsageFromMediaData(activeMedia);
    return (
      <>
        <DetailRow>
          <Label>Övningar: </Label>
          <StyledUsedByLabel tagtype="exercise">
            <Link
              to={{
                type: "ROUTE_EXERCISES",
                payload: { entityId: currentEntityId },
                query: { media: activeMedia.id }
              }}
              studlicon="Posts"
              onClick={onModalClose}
            >
              {postUsage.exercise || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
        <DetailRow>
          <Label>Resurser: </Label>
          <StyledUsedByLabel tagtype="resource">
            <Link
              to={{
                type: "ROUTE_RESOURCES",
                payload: { entityId: currentEntityId },
                query: { media: activeMedia.id }
              }}
              studlicon="Resources"
              onClick={onModalClose}
            >
              {postUsage.resource || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
        <DetailRow>
          <Label>Sammansatta bilder: </Label>
          <StyledUsedByLabel tagtype="mediaresource">
            <Link
              to={{
                type: "ROUTE_MEDIARESOURCES",
                payload: { entityId: currentEntityId },
                query: { media: activeMedia.id }
              }}
              studlicon="ImageResourceIcon"
              onClick={onModalClose}
            >
              {postUsage.mediaresource || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
        <DetailRow>
          <Label>Hjälpresurser: </Label>
          <StyledUsedByLabel tagtype="helpresource">
            <Link
              to={{
                type: "ROUTE_HELP_RESOURCES",
                payload: { entityId: currentEntityId },
                query: { media: activeMedia.id }
              }}
              studlicon="HelpResource"
              onClick={onModalClose}
            >
              {postUsage.helpresource || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
      </>
    );
  };

  /**
   * Render how many times this file is used in aida
   */
  renderSystemMediaConnectionInfo = () => {
    const { activeMedia, currentEntityId, onModalClose } = this.props;
    const postUsage = getPostUsageFromMediaData(activeMedia);

    return (
      <>
        <DetailRow>
          <Label>Hjälppost: </Label>
          <StyledUsedByLabel tagtype="aida">
            <Link
              to={`/system/${currentEntityId}/posts?media=${activeMedia.id}`}
              studlicon="AidaHelp"
              onClick={onModalClose}
            >
              {postUsage.aida || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
      </>
    );
  };

  /**
   * Render how many times this file is used in aida
   */
  renderSeriesMediaConnectionInfo = () => {
    const { activeMedia, onModalClose } = this.props;
    const seriesUsage = getPostUsageFromMediaData(activeMedia, "entityUsage");

    return (
      <>
        <DetailRow>
          <Label>Serier: </Label>
          <StyledUsedByLabel tagtype="series">
            <Link
              to={`/series?media=${activeMedia.id}`}
              studlicon="AidaHelp"
              onClick={onModalClose}
            >
              {seriesUsage.series || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
      </>
    );
  };

  /**
   * Render how many times this file is used in classroomgroups
   */
  renderClassroomgroupMediaConnectionInfo = () => {
    const { activeMedia, onModalClose } = this.props;
    const classroomgroupUsage = getPostUsageFromMediaData(
      activeMedia,
      "entityUsage"
    );

    return (
      <>
        <DetailRow>
          <Label>Klassrumsgrupp: </Label>
          <StyledUsedByLabel tagtype="classroomgroup">
            <Link
              to={`/klassrum/grupper?media=${activeMedia.id}`}
              studlicon="AidaHelp"
              onClick={onModalClose}
            >
              {classroomgroupUsage.classroomgroup || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
      </>
    );
  };

  /**
   * Render how many times this file is used in messages
   */
  renderMessageserviceMediaConnectionInfo = () => {
    const { activeMedia, currentEntityId, onModalClose } = this.props;
    const postUsage = getPostUsageFromMediaData(activeMedia);
    return (
      <>
        <DetailRow>
          <Label>Marknadsmeddelande: </Label>
          <StyledUsedByLabel tagtype="marketmessage">
            <Link
              to={{
                type: "ROUTE_MARKETMESSAGES",
                payload: { entityId: currentEntityId },
                query: { media: activeMedia.id }
              }}
              studlicon="AidaHelp"
              onClick={onModalClose}
            >
              {postUsage.marketmessage || 0}
            </Link>
          </StyledUsedByLabel>
        </DetailRow>
      </>
    );
  };

  /**
   * Render the title and description area
   */
  renderTextInput = () => (
    <MediaDetailRight>
      <TextInputWrapper>
        <Label>Titel: </Label>
          <TitleInput
            value={this.state.title}
            onChange={this._onTitleChange}
            name="title"
            autoComplete="off"
            />
          <Label>Beskrivning: </Label>
          <TextArea
            value={this.state.description}
            onChange={this._onDescChange}
          />
      </TextInputWrapper>
    </MediaDetailRight>
  );

  /**
   * Render the change file part
   */
  renderChangeFileMarkup = () => (
    <MediaDetailLeft>
      <Label>Tidigare versioner:</Label> (0)
      <VersionWrapper>Ej implementerat än</VersionWrapper>
      <ButtonGroup>
        <StandardButton disabled onClick={this._changeMediaItem}>
          Byt ut
        </StandardButton>
        <PrimaryButton onClick={this._addNewFile}>Ny fil</PrimaryButton>
      </ButtonGroup>
    </MediaDetailLeft>
  );

  /**
   * Render the pagination if selectedItems is more then one.
   */
  renderPagination = () =>
    this.props.selectedItems.length > 1 ? (
      <MediaDetailPaginationMarkup>
        <ButtonGroup>
          {this.renderPostCountMarkUp()}
          {this.renderPrevMarkup()}
          {this.renderNextMarkUp()}
        </ButtonGroup>
      </MediaDetailPaginationMarkup>
    ) : null;

  /**
   * Markup for number of posts
   */
  renderPostCountMarkUp = () => {
    const currentIndex =
      this.props.selectedItems.findIndex(
        item => item.id === this.props.activeMedia.id
      ) + 1;
    return (
      <div>
        {currentIndex} av {this.props.selectedItems.length}
      </div>
    );
  };

  /**
   * Render prev button
   */
  renderPrevMarkup = () => (
    <StandardButton
      studlicon="ChevronLeft"
      onClick={this.props.prevMediaItem}
    />
  );

  /**
   * render next button
   */
  renderNextMarkUp = () => (
    <StandardButton
      studlicon="ChevronRight"
      onClick={this.props.nextMediaItem}
    />
  );
}

const mapStateToProps = (state, props) => {
  const selectedActive = selectCurrentMedia(state, "Media");
  return {
    activeMedia: selectedActive ? selectedActive.toJS() : null,
    selectedItems: state.MediaCarousel.items,
    currentEntityId: selectEntityId(state),
    medialib: selectCurrentMediaLib(state)
  };
};

const mapDispatchToProps = dispatch => ({
  modalUpdate: modalUpdate(dispatch),
  nextMediaItem: () => nextItem()(dispatch),
  prevMediaItem: () => prevItem()(dispatch),
  updateFile: (id, data) => dispatch(updateMediaFile(id, data)),
  updateShadowClose: state =>
    dispatch(updateDisableShadowClose(dispatch, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaModalContent);
