import React, { Component } from "react";
import { PageHeader } from "../../../shared";
import StructureTree from "../../containers/StructureTree";
import { Tab, TabList, TabPanel } from "react-tabs";
import {
  StyledStructureWrapper,
  StyledTabs,
  TabWrapper
} from "./StyledStructure";

import "react-tabs/style/react-tabs.css";
import GoalList from "../../containers/GoalList/GoalList";

class Structure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      searchString: "",
      goalId: -1,
      fetchingData: false
    };
  }

  /**
   * The component should not re-render if the goalId is reset.
   *
   * @param nextProps
   * @param nextState
   * @param nextContext
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const searchStringDidChange =
      this.state.searchString !== nextState.searchString;
    const indexDidChange = this.state.tabIndex !== nextState.tabIndex;

    return searchStringDidChange || indexDidChange;
  }

  /**
   * Takes in a condition that checks if there are data getting fetched from server.
   * @param condition
   */
  isFetchingData = condition => this.setState({ fetchingData: condition });

  /**
   * Changes tab index manually, starts at 0
   * If data is being fetching we block tab switching.
   * @param index
   */
  changeTabIndex = index =>
    this.state.fetchingData ? null : this.setState({ tabIndex: index });

  /**
   * Sets the search string for structure and switches to it's tab
   * @param structureTitle
   */
  setSearchStringForStructure = structureTitle =>
    this.setState({ searchString: structureTitle, tabIndex: 0 });

  /**
   * Set the goalId for goalList and swithes to it's tab
   * @param id
   */
  setIdForGoalList = ({ id }) => this.setState({ goalId: id, tabIndex: 1 });

  /**
   * Resets the id from state after the goal has been highlighted and scrolled to in
   * goallist
   */
  resetId = () => this.setState({ goalId: -1 });

  /**
   * Sets the searchstring for structure.
   * @param searchString
   */
  setSearchString = searchString =>
    this.setState({ searchString: searchString });

  render() {
    return (
      <StyledStructureWrapper>
        <PageHeader studlicon="Structure" title="Struktur" />

        <TabWrapper>
          <StyledTabs
            selectedIndex={this.state.tabIndex}
            onSelect={this.changeTabIndex}
          >
            <TabList>
              <Tab>Struktur</Tab>
              <Tab>Adaptivitet</Tab>
            </TabList>

            <TabPanel>
              <StructureTree
                setIdForGoalList={this.setIdForGoalList}
                searchString={this.state.searchString}
                setSearchString={this.setSearchString}
                isFetchingData={this.isFetchingData}
              />
            </TabPanel>

            <TabPanel>
              <GoalList
                setSearchStringForStructure={this.setSearchStringForStructure}
                setSearchString={this.setSearchString}
                goalId={this.state.goalId}
                resetId={this.resetId}
              />
            </TabPanel>
          </StyledTabs>
        </TabWrapper>
      </StyledStructureWrapper>
    );
  }
}

export default Structure;
