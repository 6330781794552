import React from "react";
import JSONPreview from "./JSONPreview";

const JSONPreviewContainer = ({json, mediaList}) => {
  const parseData = () => {
    try {
      const parsed = JSON.parse(json);
      return parsed
    }
    catch(error) {
      return undefined;
    }
  }
  return (
      <JSONPreview mediaList={mediaList} data={parseData()} />
  );
}
export default JSONPreviewContainer

