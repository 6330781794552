import React from "react";
import { 
  StyledMediaItemContainer, 
  StyledMediaItemImage,
  StyledMediaItemName,
  StyledMediaItemImageContainer,
  StyledMediaDeleteButton,
  StyledMediaItemImageWrapper,
  StyledAudioContainer,
  StyledAudioWrapper
} from "./StyledAdventureMediaItem";
import { Trash } from "../../../../../shared/components/StudliIcons";

const AdventureMediaItem = (props) => {
  
  const handleDelete = () => {
    props.callbackToHandleDeleteItem(props.id);
  };

  const renderAudioPlayer = () => <audio src={props.url} controls />;

  const renderDeleteButton = () => (
    <StyledMediaDeleteButton onClick={handleDelete}>
      <Trash size={22} />
    </StyledMediaDeleteButton>
  );

  const renderImage = () => (
    <>
      <StyledMediaItemImageContainer>
        <StyledMediaItemImageWrapper>
          <StyledMediaItemImage src={props.url} />
        </StyledMediaItemImageWrapper>
        {renderDeleteButton()}
      </StyledMediaItemImageContainer>
      <StyledMediaItemName>{props.name}</StyledMediaItemName>
    </>
  );

  const renderAudio = () => (
    <StyledAudioWrapper>
      <StyledAudioContainer>
        <StyledMediaItemName>{props.name}</StyledMediaItemName>
        {renderDeleteButton()}
      </StyledAudioContainer>
        {renderAudioPlayer()}
    </StyledAudioWrapper>
  );

  return (
    <StyledMediaItemContainer>
      {props.type === 'image' ? renderImage() : renderAudio()}
    </StyledMediaItemContainer>
  )
}

export default AdventureMediaItem