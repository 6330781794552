import React from "react";

const CaretUpDown = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-caretupdown"
  >
    <path d="M32.9979821,27 L17.0023538,27 C15.225791,27 14.3294158,29.1555004 15.588166,30.4141882 L23.5857927,38.4141899 C24.3667928,39.19519 25.6331681,39.1952525 26.4142308,38.4141899 L34.4122324,30.4141882 C35.6684202,29.1580004 34.7781075,27 32.9979821,27 Z M24.9999805,37.0000021 L16.9999788,29.0000004 L32.9999821,29.0000004 L24.9999805,37.0000021 Z M17.0019967,22.9999638 L32.997625,22.9999638 C34.7741879,22.9999638 35.670563,20.8444634 34.4118128,19.5857756 L26.4141861,11.585774 C25.633186,10.8047738 24.3668107,10.8047113 23.5857481,11.585774 L15.5877464,19.5857756 C14.3315587,20.8419634 15.2218713,22.9999638 17.0019967,22.9999638 Z M25.0000016,13 L33.0000033,21.0000016 L17,21.0000016 L25.0000016,13 Z" />
  </svg>
);

export default CaretUpDown;
