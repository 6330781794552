import {
  DRAFT,
  PUBLISHED,
  DRAFT_READY_FOR_REVIEW,
  REVISION_READY_FOR_REVIEW,
  DRAFT_REVIEWED,
  REVISION_REVIEWED,
  UNPUBLISHED,
  MODIFIED,
  FILTER_AIDASYSTEM,
  FILTER_AIDATAG,
  DRAFT_PREVIEW_PUBLISHED,
  REVISION_PREVIEW_PUBLISHED,
} from "../../constants";

export const AIDA_KEYWORD_COLOR = "#e2e2e2";
export const CHIP_COLOR = "#e2e2e2";
export const FETCH_LIMIT = 50;
export const PREVIEW_URL =
  process.env.REACT_APP_PREVIEW_URL ||
  "http://mr.test.studentlitteratur.se/?preview=";
export const DIFFICULTY = ["Ingen", "Lätt", "Medel", "Svår"];
export const STATUS = [
  { id: DRAFT, title: "Utkast" },
  { id: DRAFT_READY_FOR_REVIEW, title: "Utkast redo att granskas" },
  { id: DRAFT_REVIEWED, title: "Utkast redo att publiceras" },
  { id: MODIFIED, title: "Ändrad" },
  { id: REVISION_READY_FOR_REVIEW, title: "Ändrad redo att granskas" },
  { id: DRAFT_PREVIEW_PUBLISHED, title: "Förhandsgranskning publicerad"},
  { id: REVISION_PREVIEW_PUBLISHED, title: "Förhandsgranskning publicerad"},
  { id: REVISION_REVIEWED, title: "Ändrad redo att publiceras" },
  { id: PUBLISHED, title: "Publicerad" },
  { id: UNPUBLISHED, title: "Avpublicerad" }
];
export const NOTAGS = [
  { id: FILTER_AIDASYSTEM, title: "Saknar system" },
  { id: FILTER_AIDATAG, title: "Saknar nyckelord" }
];
