import styled from "styled-components";
import { StandardButton } from "../../../../shared";

export const StyledRemovePrompt = styled.div`
  width: 400px;
  min-height: 250px;
  background-color: #ffffff;
  padding: 18px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  svg {
    color: ${props => props.theme.red};
    margin-right: 10px;
  }
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRemoveHeading = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
`;

export const StyledRemoveText = styled.p`
  font-size: 16px;
`;

export const StyledButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;

export const StyledModalButton = styled(props => <StandardButton {...props} />)`
  background-color: ${props => (props.$danger ? "red" : "#cbc9c9")};
  color: ${props => (props.$danger ? "white" : "black")};
  margin-right: 8px;
  font-weight: bold;

  &:hover {
    background-color: ${props => (props.$danger ? "red" : "#cbc9c9")};
    color: ${props => (props.$danger ? "white" : "black")};
    cursor: pointer;
    opacity: 0.5;
  }
`;
