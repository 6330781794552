import { takeEvery, all, fork } from "redux-saga/effects";
import { FETCH_ENTITIES, FETCH_MEDIA_LIST } from "../../api";
import {
  ADVENTURES_GROUP,
  ADVENTURES,
  ADVENTURES_CARD,
  ADVENTURES_CARD_EDITOR,
  MARKERS
} from "../../constants";
import { actionCreator } from "../../shared";
import {
  fetchEntitiesSaga,
  onDeleteEntity,
  onSaveEntity,
  onUpdatingOrder,
  onSaveJson,
  fetchMediaListSaga,
  onDeleteMediaItem,
  onDeleteMediaSection,
  onPublishEntity,
  onUnPublishEntity,
  onReviewEntity,
  onRequestReviewEntity,
  onRejectEntity,
  getReadyForPublishList,
  updateMediaFileSaga,
  updateMarkerItemSaga,
  updateMarkerColorThemeSaga,
  onPublishMarkers,
  onUploadCollectionMarker
} from "../api/saga";
import {
  PUBLISH_MARKERS,
  UPDATE_MARKER_COLOR_THEME,
  UPDATE_MARKER_ITEM,
  UPLOAD_MEDIA_FILE
} from "../markers/api/actions";
import {
  ROUTE_ADVENTURES_GROUP,
  ROUTE_ADVENTURES,
  ROUTE_ADVENTURES_CARD,
  ROUTE_ADVENTURES_CARD_EDITOR,
  ROUTE_MARKERS
} from "../routes";
import {
  ACTION_SAVE_ENTITY,
  ACTION_DELETE_ENTITY,
  ACTION_PUBLISH_ENTITY,
  ACTION_UPDATE_ORDER,
  ACTION_UPLOADING_DONE,
  ACTION_DELETE_MEDIA_ITEM,
  ACTION_DELETE_MEDIA_SECTION,
  ACTION_UNPUBLISH_ENTITY,
  ACTION_REVIEW_ENTITY,
  ACTION_REQUEST_REVIEW_ENTITY,
  ACTION_REJECT_ENTITY,
  ACTION_RELOAD_ADVENTURES,
  ACTION_GET_PUBLISH_LIST,
  ACTION_UPDATE_COLLECTION_MARKER
} from "./actions";
import { onRoute, onGetMediaList } from "./sagas";

function* watchRequests() {
  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, FETCH_MEDIA_LIST),
    fetchMediaListSaga(ADVENTURES_CARD_EDITOR, "getAdventureMediaList")
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, FETCH_ENTITIES),
    fetchEntitiesSaga(ADVENTURES_GROUP, "listAdventuresGroups")
  );
  yield takeEvery(
    actionCreator(ADVENTURES, FETCH_ENTITIES),
    fetchEntitiesSaga(ADVENTURES, "listAdventures")
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, FETCH_ENTITIES),
    fetchEntitiesSaga(ADVENTURES_CARD, "listAdventuresCards")
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, FETCH_ENTITIES),
    fetchEntitiesSaga(ADVENTURES_CARD_EDITOR, "listAdventuresCardEditor")
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_SAVE_ENTITY),
    onSaveEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_DELETE_ENTITY),
    onDeleteEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_UPDATE_ORDER),
    onUpdatingOrder,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_SAVE_ENTITY),
    onSaveEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_DELETE_ENTITY),
    onDeleteEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_UPDATE_ORDER),
    onUpdatingOrder,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_SAVE_ENTITY),
    onSaveEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_DELETE_ENTITY),
    onDeleteEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_PUBLISH_ENTITY),
    onPublishEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_PUBLISH_ENTITY),
    onPublishEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_PUBLISH_ENTITY),
    onPublishEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_GET_PUBLISH_LIST),
    getReadyForPublishList,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_GET_PUBLISH_LIST),
    getReadyForPublishList,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_GET_PUBLISH_LIST),
    getReadyForPublishList,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_UNPUBLISH_ENTITY),
    onUnPublishEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_UNPUBLISH_ENTITY),
    onUnPublishEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_UNPUBLISH_ENTITY),
    onUnPublishEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_REVIEW_ENTITY),
    onReviewEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_REVIEW_ENTITY),
    onReviewEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_REVIEW_ENTITY),
    onReviewEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_REQUEST_REVIEW_ENTITY),
    onRequestReviewEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_REQUEST_REVIEW_ENTITY),
    onRequestReviewEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_REQUEST_REVIEW_ENTITY),
    onRequestReviewEntity,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_REJECT_ENTITY),
    onRejectEntity,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    actionCreator(ADVENTURES, ACTION_REJECT_ENTITY),
    onRejectEntity,
    ADVENTURES
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_REJECT_ENTITY),
    onRejectEntity,
    ADVENTURES_CARD
  );

  yield takeEvery(
    actionCreator(ADVENTURES_CARD, ACTION_UPDATE_ORDER),
    onUpdatingOrder,
    ADVENTURES_CARD
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, ACTION_SAVE_ENTITY),
    onSaveJson,
    ADVENTURES_CARD_EDITOR
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, ACTION_UPLOADING_DONE),
    onGetMediaList,
    ADVENTURES_CARD_EDITOR,
    "getAdventureMediaList"
  );
  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, ACTION_DELETE_MEDIA_ITEM),
    onDeleteMediaItem,
    ADVENTURES_CARD_EDITOR
  );

  yield takeEvery(
    actionCreator(ADVENTURES_CARD_EDITOR, ACTION_DELETE_MEDIA_SECTION),
    onDeleteMediaSection,
    ADVENTURES_CARD_EDITOR
  );
  yield takeEvery(
    actionCreator(ADVENTURES_GROUP, ACTION_UPDATE_COLLECTION_MARKER),
    onUploadCollectionMarker,
    ADVENTURES_GROUP
  );


  yield takeEvery(
    actionCreator(MARKERS, FETCH_ENTITIES),
    fetchEntitiesSaga(MARKERS, "getMarkers")
  );

  yield takeEvery(
   actionCreator(MARKERS, UPLOAD_MEDIA_FILE),
    updateMediaFileSaga(MARKERS,"updateMediaFile")
  );

  yield takeEvery(
   actionCreator(MARKERS, UPDATE_MARKER_ITEM),
    updateMarkerItemSaga(MARKERS,"updateMarkerItem")
  );

  yield takeEvery(
   actionCreator(MARKERS, UPDATE_MARKER_COLOR_THEME),
    updateMarkerColorThemeSaga(MARKERS,"updateMarkerColorTheme")
  );

  yield takeEvery(
    actionCreator(MARKERS, PUBLISH_MARKERS),
    onPublishMarkers(MARKERS, "publishMarkers")
   );
}

function* watchRoutes() {
  yield takeEvery(
    ACTION_RELOAD_ADVENTURES,
    onRoute
  );
  yield takeEvery(
    ROUTE_ADVENTURES_GROUP,
    onRoute,
    ADVENTURES_GROUP
  );
  yield takeEvery(
    ROUTE_ADVENTURES, 
    onRoute, 
    ADVENTURES
  );
  yield takeEvery(
    ROUTE_ADVENTURES_CARD,
    onRoute,
    ADVENTURES_CARD
  );
  yield takeEvery(
    ROUTE_ADVENTURES_CARD_EDITOR,
    onRoute,
    ADVENTURES_CARD_EDITOR
  );
  yield takeEvery(
    ROUTE_ADVENTURES_CARD_EDITOR,
    onGetMediaList,
    ADVENTURES_CARD_EDITOR,
    "getAdventureMediaList"
  );

  yield takeEvery(
    ROUTE_MARKERS,
    onRoute,
    MARKERS,
    "getMarkers"
  );
}

export default function* rootSaga() {
  yield all([fork(watchRoutes), fork(watchRequests)]);
}
