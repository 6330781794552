import * as React from "react";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import createToolbarPlugin, {
  Separator
} from "@draft-js-plugins/static-toolbar";
import Editor from "@draft-js-plugins/editor";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  CodeButton,
  SubButton,
  SupButton
} from "@draft-js-plugins/buttons";

import { TextParser } from "../../../shared/TextTransformerService";
import toolbarStyles from "./toolbarStyles.module.css";
import buttonStyles from "./buttonStyles.module.css";

const classNames = require("classnames");

const customStyleMap = {
  SUBSCRIPT: { fontSize: "0.8em", verticalAlign: "sub" },
  SUPERSCRIPT: { fontSize: "0.8em", verticalAlign: "super" }
};

export class TextEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    const { editorState } = props;
    this.state = {
      editorState
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this._keyDown);

    if (this.props.useInlineToolbar) {
      this._focus();
    }
  }

  componentDidUpdate() {
    const textHandler = new TextParser();
    const { editorState } = this.state;
    const { data } = this.props;

    if (
      this.props.updatedItem &&
      textHandler.getRaw(editorState) !== data.text.trim()
    ) {
      let newState = textHandler.createNewEditorState(editorState, data.text);

      this.setState({
        editorState: newState
      });

      this.props.callbackUpdatedItem();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this._keyDown);
  }

  _keyDown = event => {
    let undo = event.ctrlKey && event.keyCode === 90 ? true : false;

    if (undo) {
      const textHandler = new TextParser();
      const { editorState } = this.state;
      const { data } = this.props;

      let newText = data.text ? data.text : "";
      newText = newText.split("<ins>").join("<u>");
      newText = newText.split("</ins>").join("</u>");

      let newState = textHandler.createNewEditorState(editorState, newText);

      this.setState({
        editorState: newState
      });
    }
  };

  toolbarPlugin = this.props.useInlineToolbar
    ? createToolbarPlugin({ theme: { toolbarStyles, buttonStyles } })
    : createToolbarPlugin();

  _onChange = editorState => {
    const { onChange } = this.props;
    onChange(editorState);

    this.setState({
      editorState
    });
  };

  _ref = editor => (this.editor = editor);

  _focus = () => this.editor.focus();

  _handled = () => "handled";

  shortToolbarButtons = [BoldButton, ItalicButton, UnderlineButton, SupButton];
  inlineToolbarButtons = [
    BoldButton,
    ItalicButton,
    UnderlineButton,
    SupButton,
    SubButton
  ];

  renderInlineToolbar = () => {
    const { Toolbar } = this.toolbarPlugin;
    const { readOnly, oneLine, useToolbar } = this.props;

    if (readOnly || (oneLine && !useToolbar)) {
      return null;
    }

    return (
      <Toolbar>
        {externalProps => (
          <div>
            {this.inlineToolbarButtons.map((Button, i) => (
              <Button key={i} {...externalProps} />
            ))}
          </div>
        )}
      </Toolbar>
    );
  };

  renderToolbar = () => {
    const { readOnly, oneLine, useToolbar, useShortToolbar } = this.props;

    if (readOnly || (oneLine && !useToolbar)) {
      return null;
    }
    const { Toolbar } = this.toolbarPlugin;

    return (
      <Toolbar>
        {externalProps => (
          <div>
            {useShortToolbar ? (
              this.shortToolbarButtons.map((Button, i) => (
                <Button key={i} {...externalProps} />
              ))
            ) : (
              <>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <CodeButton {...externalProps} />
                <Separator {...externalProps} />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <Separator {...externalProps} />
                <SubButton {...externalProps} />
                <SupButton {...externalProps} />
                <Separator {...externalProps} />
              </>
            )}
          </div>
        )}
      </Toolbar>
    );
  };

  render() {
    const { editorState } = this.state;
    const { readOnly, oneLine, useInlineToolbar } = this.props;

    const className = classNames("TextEditor", {
      "TextEditor--Edit": !readOnly,
      "TextEditor--OneLine": oneLine,
      "TextEditor--ReadOnly": readOnly
    });

    let editor = (
      <Editor
        plugins={[this.toolbarPlugin]}
        customStyleMap={customStyleMap}
        readOnly={readOnly}
        editorState={editorState}
        onChange={this._onChange}
        ref={this._ref}
        onBlur={this._blur}
      />
    );

    if (oneLine) {
      editor = (
        <Editor
          plugins={[this.toolbarPlugin]}
          customStyleMap={customStyleMap}
          readOnly={readOnly}
          editorState={editorState}
          onChange={this._onChange}
          handleReturn={this._handled}
          ref={this._ref}
        />
      );
    }

    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <div className={className} onClick={this._focus}>
        {editor}
        {useInlineToolbar ? this.renderInlineToolbar() : this.renderToolbar()}
      </div>
    );
  }
}

export default TextEditor;
