import styled from "styled-components";
import {
  PrimaryButton,
  StandardButton
} from "../../../shared/components/Button/index";

export const StyledMultipleChoiceWrapper = styled.div`
  display: flex;
  padding: 0;
`;

export const StyledSettingsRow = styled.div`
  width: 100%;
  margin: 15px 10px;
  color: white;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMediaResursButton = styled(StandardButton)`
  min-width: 40px;
  height: 40px;
  fill: white;
  border-radius: 4px 0 0 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMediaButton = styled(PrimaryButton)`
  min-width: 40px;
  height: 40px;
  fill: white;
  border-radius: 4px 0 0 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMultipleChoice = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledMultipleChoiceContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledOptionContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 735px;
  width: 100%;
`;

export const StyledOption = styled.li`
  border: 1px solid #999;
  z-index: 5;
  background: white;
  padding: 10px;
  display: flex;
  margin: 0;
  min-height: 100px;
`;

export const StyledButton = styled(StandardButton)`
  background: white;
  min-width: 40px;
  &:hover {
    background: white;
    fill: #999;
  }
`;

export const StyledTextToolbarButton = styled(StandardButton)`
  background: ${props =>
    props.activestate === "active" ? "#e0e0e0" : "white"};
  min-width: 40px;
  &:hover {
    background-color: #e0e0e0;
  }
  &:focus {
    background-color: #e0e0e0;
  }
`;

export const StyledDragHandleWrapper = styled.div`
  flex: 0 0 12%;
`;
export const StyledColumnWrapper = styled.div`
  flex: 0 0 5%;
`;
export const StyledCheckboxWrapper = styled.div`
  flex: 0 0 5%;
`;
export const StyledOptionContentWrapper = styled.div`
  flex: 1;
  margin: 0 15px;
  min-width: 40%;
`;
export const StyledOptionImage = styled.div`
  border: thin solid #999;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const StyledMediaResourceWrapper = styled.div`
  border: thin solid #999;
  margin-bottom: 10px;
`;

export const StyledOptionEditText = styled.div``;

export const StyledEditInputWrapper = styled.div`
  border: thin solid #999;
  font-size: 16px;
  padding: 5px 10px;
  input {
    border: 0;
    outline: none;
    width: 100%;
  }
  height: 40px;
`;

export const StyledAddInputWrapper = styled(StyledEditInputWrapper)`
  padding: 0;
  border: 0;

  .TextEditor,
  .TextEditor--Edit {
    position: relative;
    padding: 4px;
    box-shadow: none;
  }
  .public-DraftEditor-content {
    min-height: 0;
  }

  .draftJsToolbar__toolbar__dNtBH {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: fit-content;
  }
`;

export const StyledEditSoundWrapper = styled.div`
  border: thin solid #999;
  font-size: 12px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  a,
  p {
    padding: 5px 10px;
  }
`;
export const StyledOptionMediaEdit = styled.div`
  border: thin solid #999;
  font-size: 12px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  a,
  p {
    padding: 5px 10px;
  }
`;
export const StyledOptionMedia = styled.div`
  display: flex;
  img {
    height: 85px;
  }
`;

export const StyledEditMedia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledAddNewOptionRow = styled.div`
  margin-top: 15px;
  display: inline-flex;
  width: 100%;
`;

export const StyledInputTypeInputsContainer = styled.div`
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
`;

export const StyledInputTypeLabel = styled.label`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  grid-column: span 4;
`;
