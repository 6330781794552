import React from "react";
import styled from "styled-components";
import PreparedAssignmentsNode from "./PreparedAssignmentsNode";

const StyledAssignmentList = styled.div`
  margin: 0.3rem;
  height: 18rem;
  overflow-y: scroll;
  border-top: 1px solid;
  padding-top: 1rem;
`;

const PreparedAssignmentsList = ({ list }) => {
  return (
    <StyledAssignmentList>
      {list &&
        list.map((l, index) => (
          <PreparedAssignmentsNode key={index} node={l} />
        ))}
    </StyledAssignmentList>
  );
};

export default PreparedAssignmentsList;
