import { createSelector } from "reselect";
import { translation } from "../../../constants";

const getSystemData = (systems, systemName) => {
  if (!systems) {
    return [];
  }
  const sysdata = systems.filter(sys => sys.title.toLowerCase() === systemName);
  return sysdata.toJS();
};

const getEntityIdFromProductOrSystem = (
  systemName,
  systemdata,
  selectedProductId
) => {
  if (systemName === "product") {
    return selectedProductId;
  }
  if (systemdata.length > 0) {
    return systemdata[0].id;
  }
  return undefined;
};

const isTypeAChildRoute = (type, route) =>
  route.childRoutes && route.childRoutes.indexOf(type) >= 0;

export const buildNavigation = createSelector(
  state => state.navigation,
  state => state.location,
  state => state.Systems.get("systems"),
  (navigation, location, systems) => {
    const selectedProductId = navigation.get("selectedProductEntityId");
    return {
      sections: navigation
        .get("sections")
        .map((section, key) => {
          return {
            header: key,
            links: section
              .get("routes")
              .map(routeKey => {
                const route = location.routesMap[routeKey];
                const subs = section.getIn(["subs", `${routeKey}`]);
                const systemName = Object.keys(translation).find(
                  syskey => translation[syskey] === route.text
                );
                const systemdata = getSystemData(systems, systemName);
                const entityId = getEntityIdFromProductOrSystem(
                  systemName,
                  systemdata,
                  selectedProductId
                );
                const payload = { entityId: entityId, entityType: route.type };
                return {
                  to: {
                    type: routeKey,
                    payload: payload,
                    query: route.query || {}
                  },
                  isActive: (_, loc) =>
                    loc.type === routeKey || isTypeAChildRoute(loc.type, route),
                  text: route.text,
                  subs: subs
                    ? subs
                        .map(subRouteKey => {
                          const subroute = location.routesMap[subRouteKey];
                          return {
                            subto: {
                              type: subRouteKey,
                              payload: payload,
                              query: route.query || {}
                            },
                            isActive: (_, loc) =>
                              loc.type === subRouteKey ||
                              isTypeAChildRoute(loc.type, subroute),
                            subtext: subroute.text
                          };
                        })
                        .toList()
                        .toJS()
                    : null,
                  isOpen:
                    location.type === routeKey ||
                    isTypeAChildRoute(location.type, route) ||
                    (subs &&
                      subs.some(sub => {
                        const subroute = location.routesMap[sub];
                        return (
                          sub === location.type ||
                          isTypeAChildRoute(location.type, subroute)
                        );
                      }))
                };
              })
              .toList()
              .toJS()
          };
        })
        .toList()
        .toJS(),
      collapsed: navigation.get("collapsed"),
      title: navigation.get("title"),
      hideNavigation: location.routesMap[location.type].hideNavigation
    };
  }
);

export const selectNavMenuState = state => {
  const collapsed = state.navigation.get("collapsed");
  const byMouseOver = state.navigation.get("byMouseOver");
  return {
    collapsed: collapsed,
    byMouseOver: byMouseOver
  };
};

export const selectIsOnStartPage = state =>
  state.location.type === "ROUTE_PRODUCTS";

export const selectHeaderTitle = state => state.navigation.get("title");

export const selectCurrentRouteAndTitle = state => ({
  type: state.location.type,
  title: state.location.routesMap[state.location.type].text
});

export const hasSelectedProductId = state => {
  return state.navigation.get("selectedProductEntityId") !== null;
};

export const isProductSelectedMessageVisible = state =>
  state.navigation.get("isProductSelectedMessageVisible");
