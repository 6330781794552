import { actionCreator } from "../../../shared";

export const SET_ENTITY_DRAFT = "SET_ENTITY_DRAFT";
export const UPDATE_ENTITY_DRAFT = "UPDATE_ENTITY_DRAFT";
export const RESET_ENTITY_DRAFT = "RESET_ENTITY_DRAFT";
export const SAVE_ENTITY_DRAFT = "SAVE_ENTITY_DRAFT";
export const STORE_ENTITY_DRAFT = "STORE_ENTITY_DRAFT";
export const REVERT_ENTITY_DRAFT = "REVERT_ENTITY_DRAFT";
export const SET_REPLACING_BLOCK = "SET_REPLACE_BLOCK";

/**
 * Actions for settings a new post draft
 * @param entityType
 * @param post
 * @returns {{type: string, post: *}}
 */
export const setEntityDraft = (entityType, post) => ({
  type: actionCreator(entityType, SET_ENTITY_DRAFT),
  post
});

/**
 * Action for updating post draft
 * @param entityType
 * @param key
 * @param value
 * @returns {{type: string, post: *}}
 */
export const updateEntityDraft = (entityType, key, value) => ({
  type: actionCreator(entityType, UPDATE_ENTITY_DRAFT),
  key,
  value
});

/**
 * Action for resetting post draft
 * @param entityType
 * @returns {{type: string}}
 */
export const resetEntityDraft = entityType => ({
  type: actionCreator(entityType, RESET_ENTITY_DRAFT),
  entityType
});

/**
 * Action for storing previous postdraft
 * @param {} entityType
 */
export const storeEntityDraft = (entityType, key) => ({
  type: actionCreator(entityType, STORE_ENTITY_DRAFT),
  key
});

/**
 * Action for reverting the postdraft one step
 * @param {} entityType
 */
export const revertEntityDraft = entityType => ({
  type: actionCreator(entityType, REVERT_ENTITY_DRAFT),
  entityType
});

/**
 * Action for request saving of a post
 * @param entityType
 * @returns {{type: string}}
 */
export const saveEntityDraft = entityType => ({
  type: actionCreator(entityType, SAVE_ENTITY_DRAFT),
  entityType
});

/**
 * Action for setting status of a block replacement procedure
 * @param entityType
 * @param value: boolean
 */
export const setReplaceBlockOngoing = (entityType, value) => ({
  type: actionCreator(entityType, SET_REPLACING_BLOCK),
  entityType,
  value
});
