import React from "react";
import { StyledNodeStandardBtn } from "./StyledStructureTree";

export const NodeStandardBtn = ({ callback, ...rest }) => {
  const onClick = e => {
    e.stopPropagation();
    callback();
  };

  return (
    <StyledNodeStandardBtn
      studlicon={"Trash"}
      transparent={true}
      onClick={onClick}
      {...rest}
    />
  );
};
