import React from "react";
import styled from "styled-components";
import IconButton from "../../../products/components/IconButton";
import { Trash2 as Trash } from "react-feather";

const StyledMediaItemWrapper = styled.div`
  margin-bottom: 0.7rem;
  width: 100%;
  display: flex;
  padding-top: 1rem;
`;
const StyledMediaContainer = styled.div`
  img {
    width: 170px;
  }
  audio {
    width: 220px;
    height: 30px;
    margin-top: 3px;
  }
  video {
    width: 220px;
  }
  padding-right: 0.4rem;
`;
const StyledMediaFileName = styled.div`
  font-size: 12px;
`;

const MediaListItem = ({ item, removeItem, type }) => {
  return (
    <StyledMediaItemWrapper>
      <StyledMediaContainer>
        {type === "image" && (
          <img alt={item.alt || item.filename} src={item.src} />
        )}
        {type === "audio" && <audio src={item.src} controls />}
        {type === "video" && <video controls src={item.url} />}
        <StyledMediaFileName>{item.filename}</StyledMediaFileName>
      </StyledMediaContainer>
      <IconButton
        aria-label="Ta bort media"
        onClick={() => removeItem(item.id)}
      >
        <Trash width="18px" height="18px" />
      </IconButton>
    </StyledMediaItemWrapper>
  );
};

export default MediaListItem;
