import styled from "styled-components";
import {
  PrimaryButton,
  StandardButton
} from "../../../shared/components/Button/index";

export const StyledPrimaryButton = styled(PrimaryButton)`
  height: 42px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledOrder = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledOrderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledItemContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 735px;
  width: 100%;
`;

export const StyledCheckBoxWrapper = styled.div`
  margin: auto 0;
`;

export const StyledOrderTextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledItem = styled.li`
  border: 1px solid #999;
  z-index: 5;
  background: white;
  padding: 10px;
  display: flex;
  margin: 0;
  min-height: 100px;
  align-items: flex-start;

  .TextEditor, .TextEditor--Edit {
    position: relative;
    padding: 4px;
    box-shadow: none;
    margin: 0;
    width: 100%;
  }
  .public-DraftEditor-content {
    min-height: 0;
  }

  .draftJsToolbar__toolbar__dNtBH {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: max-content;
  }
`;

export const StyledButton = styled(StandardButton)`
  background: white;
  min-width: 40px;
  &:hover {
    background: white;
    fill: #999;
  }
`;

export const StyledButtonRowWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

export const StyledDragHandleWrapper = styled.div`
  margin-top: 12px;
`;
export const StyledColumnWrapper = styled.div`
  flex: 0 0 5%;
`;
export const StyledCheckboxWrapper = styled.div`
  flex: 0 0 5%;
  margin: 5px;
`;
export const StyledValueContentWrapper = styled.div`
  min-width: 35%;
  width: 100%;
`;
export const StyledQuestionContentWrapper = styled.div`
  flex: 1;
  margin: 0 4px 0 15px;
  min-width: 30%;
`;

export const StyledItemEditText = styled.div`
  ${props => (props.hasMedia ? 
  "display: flex; flex-direction: column; flex: 1;" 
  : "")};
`;

export const StyledWarning = styled.div`
  position: absolute;
  color: ${props => props.theme.red};
  line-height: normal;
  top: 0;
  left: 0;
  transform: translateY(-120%);
`;

export const StyledItemBoxLabel = styled.label`
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  align-self: center;
  margin-right: 5px;
`;

export const StyledRowOptionsWrapper = styled.label`
  display: flex;
  flex: 1;
`;

export const StyledRowOption = styled.label`
  display: flex;
  margin: 0px 10px;
`;

export const StyledUtilityButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledDefaultRadioButton = styled.div`
  margin-right: auto;
  align-self: center;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  border: 3px solid ${props => (props.isActive ? "#2196F3" : "#848484")};
  ${props => (props.isActive ? "" : "min-height: 25px")};
  ${props => (props.isActive ? "" : "min-width: 25px")};

  &:hover {
    border: 3px solid #2196f3;
  }

  span {
    min-width: 20px;
    min-height: 20px;
    position: relative;
    border-radius: 50%;
    border: 4px solid #fff;
    background: #2196f3;
    display: ${props => (props.isActive ? "block" : "none")};
  }
`;

export const StyledEditInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: thin solid #999;
  font-size: 16px;
  input {
    margin: 0 10px;
    border: 0;
    outline: none;
    width: 255px;
  }
  height: 42px;
`;

export const StyledQuestionEditInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  font-size: 16px;
  padding: 0;
  width: 100%;

  input {
    border: 0;
    outline: none;
    width: 100%;
  }
  height: 42px;
`;

export const StyledAddNewItemRow = styled.div`
  display: inline-flex;
`;

export const StyledAddNewItemWrapper = styled.div`
  margin-top: 15px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;
