import styled from "styled-components";
import {
  DataTable,
  DateColumn,
  StatusColumn,
  TextColumn,
  withActions
} from "../../../shared";
import { useSelector, useDispatch } from "react-redux";
import LinkColumn from "./Columns/LinkColumn";
import CheckboxRenderer, {
  CheckboxHeaderRenderer
} from "../../../shared/components/DataTable/Renderer/Checkbox";
import { useEffect, useState } from "react";
import { Column } from "react-virtualized";
import { getSeriesAvailable } from "../../store/selectors";
import { sortSeries } from "../../api/actions";
import { Pagination } from "../../../posts/containers/ToolBar/PostActions";
import MenuActionColumn from "../../../shared/components/DataTable/Columns/MenuActionColumn";
import { SERIES } from "../../../constants";

const StyledDataTable = styled(withActions(DataTable))`
  height: calc(100% - 112px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const SeriesList = ({ setSelected }) => {
  const dispatch = useDispatch();
  const listdata = useSelector(state => getSeriesAvailable(state, SERIES));

  const [list, setList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setList(listdata);
  }, [listdata]);

  useEffect(() => {
    const selectedSeries = list.filter(s => s.selected);
    setSelected(selectedSeries);
  }, [list, setSelected]);

  const onSort = sortBy => dispatch(sortSeries(sortBy));

  const toggleSelectAll = () => {
    const updatedList = list.map(item => ({ ...item, selected: !allSelected }));

    setList(updatedList);
    setAllSelected(!allSelected);
  };

  const toggleListItem = id => {
    const itemIndex = list.findIndex(row => row.id === id);
    const updatedList = list.map((item, i) =>
      i === itemIndex ? { ...item, selected: !item.selected } : item
    );

    setList(updatedList);
  };

  return (
    <StyledDataTable actionsRight={Pagination} list={list} onSort={onSort}>
      <Column
        disableSort
        dataKey="selected"
        label={
          <CheckboxHeaderRenderer
            selected={allSelected}
            disabled={list.length === 0}
            onChange={toggleSelectAll}
          />
        }
        cellRenderer={({ cellData, rowData }) => (
          <CheckboxRenderer
            onChange={toggleListItem}
            cellData={cellData}
            id={rowData.id}
          />
        )}
        width={0.1}
      />
      <LinkColumn dataKey="title" label="Titel" width={0.2} />
      <TextColumn dataKey="path" label="Sökväg" width={0.2} />
      <TextColumn dataKey="numberOfSeries" label="Serier" width={0.2} />
      <TextColumn dataKey="numberOfProducts" label="Produkter" width={0.2} />
      <StatusColumn dataKey="status" label="" width={0.1} />
      <DateColumn dataKey="updatedAt" label="Senast ändrad" width={0.2} />
      <MenuActionColumn
        dataKey="actions"
        label=""
        fixedWidth
        width={40}
        disableSort
      />
    </StyledDataTable>
  );
};

export default SeriesList;
