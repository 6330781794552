import React from "react";

const ArrowCircleUp = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-arrowcircleup"
  >
    <path
      fill="currentColor"
      d="M0,25 C0,11.1895161 11.1895161,0 25,0 C38.8104839,0 50,11.1895161 50,25 C50,38.8104839 38.8104839,50 25,50 C11.1895161,50 0,38.8104839 0,25 Z M29.4354839,36.6935484 L29.4354839,25 L36.5826613,25 C37.6612903,25 38.2056452,23.6895161 37.4395161,22.9334677 L25.8568548,11.4112903 C25.3830645,10.9375 24.6270161,10.9375 24.1532258,11.4112903 L12.5604839,22.9334677 C11.7943548,23.6995968 12.3387097,25 13.4173387,25 L20.5645161,25 L20.5645161,36.6935484 C20.5645161,37.358871 21.108871,37.9032258 21.7741935,37.9032258 L28.2258065,37.9032258 C28.891129,37.9032258 29.4354839,37.358871 29.4354839,36.6935484 Z"
    />
  </svg>
);

export default ArrowCircleUp;
