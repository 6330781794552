import React from "react";
import Tag from "../../../shared/components/Tag";

import {
  TagsPostWrapper,
  TagWrapper,
  NoTagsSelected,
  ColorLessTag
} from "./TagsInPostStyles";

const TagsInPost = ({ tagList, postTags, content }) => {
  const getTagList = () => {
    const list_tags = tagList
      ? tagList.filter(g => content.indexOf(g.title) > -1)
      : [];
    return list_tags && list_tags[0] ? list_tags[0].tags : [];
  };

  const tList = content.indexOf("Difficulty") > -1 ? tagList : getTagList();

  const getTagById = id => {
    return tList.find(item => {
      return item.id === id;
    });
  };

  const tagRenderer = (tag, fill) => {
    if (tag.color) {
      return (
        <Tag
          label={tag.title}
          variant={"info"}
          fill={fill}
          labelColor={tag.color}
        />
      );
    } else {
      return <ColorLessTag>{tag.title}</ColorLessTag>;
    }
  };

  const renderTags = tags =>
    tags.map(tag => (
      <TagWrapper key={tag.id + "selectedTagsRenderer"}>
        {tagRenderer(tag, true)}
      </TagWrapper>
    ));

  const postTagsRenderer = () => {
    const pTags = postTags
      ? postTags.reduce((acc, item) => {
          const v = getTagById(item);
          return v ? [].concat(acc, v) : acc;
        }, [])
      : [];

    return pTags.length > 0 ? (
      renderTags(pTags)
    ) : (
      <NoTagsSelected>Ingen tagg vald</NoTagsSelected>
    );
  };

  return <TagsPostWrapper>{postTagsRenderer()}</TagsPostWrapper>;
};

export default TagsInPost;
