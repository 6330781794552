import React from "react";
import styled from "styled-components";
import { Column } from "react-virtualized";
import ActionRenderer from "./Renderer/Action/Action";

const ActionBar = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 48px;
  @media screen and (max-width: 1200px) {
    height: auto;
  }
  border-bottom: 1px solid ${props => props.theme.neutralLight};
  padding: 0 10px;
`;

const ActionTop = styled.div`
  display: flex;
  padding: 0 10px;
  flex-direction: row;
  height: 48px;
  @media screen and (max-width: 1200px) {
    height: auto;
    > div {
      flex-direction: column;
    }
  }
  flex: 1;
`;

const ActionSection = styled(({ start, end, ...rest }) => (
  <section {...rest} />
))`
  display: flex;
  flex: 1;
  align-items: center;
  flex-grow: 1;
  margin: 10px 0px;
  ${props =>
    props.end
      ? `
      justify-self: flex-end;
      flex-grow:12;
    `
      : null};
  ${props =>
    props.start
      ? `
      justify-self: flex-start;
      flex-grow:1;
    `
      : null};
  height: 100%;
`;

function withActions(DataTable) {
  return class ActionDataTable extends React.PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        list: nextProps.list
      };
    }

    constructor(props) {
      super(props);
      this.state = {
        showRowActions: false,
        list: props.list
      };
    }

    render() {
      const {
        actionsTop,
        actionsLeft,
        actionsRight,
        actionsMid,
        children,
        rowActions,
        ...rest
      } = this.props;
      const { list } = this.state;
      const actions = (
        <div>
          <ActionTop>
            {" "}
            {actionsTop ? React.createElement(actionsTop) : null}{" "}
          </ActionTop>
          <ActionBar>
            <ActionSection start>
              {actionsLeft ? React.createElement(actionsLeft) : null}
            </ActionSection>
            <ActionSection>
              {actionsMid ? React.createElement(actionsMid) : null}
            </ActionSection>
            <ActionSection end>
              {actionsRight ? React.createElement(actionsRight) : null}
            </ActionSection>
          </ActionBar>
        </div>
      );

      const childrenArr = React.Children.toArray(children);

      if (rowActions) {
        const rowActionRenderer = ({ cellData, rowData }) => (
          <ActionRenderer
            cellData={cellData}
            rowData={rowData}
            id={rowData.id}
          />
        );

        childrenArr.push(
          <Column
            key="actions"
            dataKey="actions"
            label=""
            className="action_column"
            width={20}
            cellRenderer={rowActionRenderer}
            fixedWidth
            disableSort
          />
        );
      }

      return (
        <DataTable
          {...rest}
          actions={actions}
          children={childrenArr}
          list={list}
        />
      );
    }
  };
}

export default withActions;
