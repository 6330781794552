import { AUDIO, IMAGE, VIDEO, MEDIA_RESOURCE } from "../constants";

export const getMediaObject = (type, src = "", prop = "", data = "") =>
  type === MEDIA_RESOURCE
    ? {
        data: {
          id: src,
          text: prop,
          src: data
        },
        type
      }
    : { src, fileType: prop, attributes: attributesObject[type] };

const attributesObject = {
  [IMAGE]: {},
  [VIDEO]: {
    controls: true,
    controlsList: "nodownload"
  },
  [AUDIO]: {
    preload: "metadata",
    controls: true,
    controlsList: "nodownload"
  }
};
