import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { saveProduct } from "../api/actions";
import { getProductInfo } from "../store/selectors";
import Checkbox from "./Checkbox";

const StyledCheckbox = styled(Checkbox)`
  padding-bottom: 1.625rem;
`;

const Toc = () => {
  const [entity] = useSelector(getProductInfo);
  const dispatch = useDispatch();

  return (
    <StyledCheckbox
      label="Läs in tematoc istället för xtoc"
      checked={entity.useTemaToc}
      onChange={checked =>
        dispatch(
          saveProduct({
            ...entity,
            useTemaToc: checked
          })
        )
      }
    />
  );
};

export default Toc;
