import React from "react";
import { connect } from "react-redux";
import { StandardButton } from "../../../shared";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import { setCategoryToPosts } from "../../store/actions";

import {
  CatActionWrapper,
  CatActionContent,
  Top,
  Title,
  Tree,
  TreeWrapper,
  Middle,
  MiddleContent,
  Bottom,
  UpdateButton,
  InlineSectionButton,
  Header,
  HeaderTitle,
  InlineBranchDisplay
} from "./CategoryStyles";

const NODE_ELEMENT_TYPE = "SPAN";

const mapStateToProps = state => {
  return {
    selectedPosts: state.Carousel.items,
    currentPost: state.Exercise.get("selectedItem"),
    categories: state.Structure.get("list")
  };
};

const mapDispatchToProps = dispatch => ({
  setCategory: (postIds, categoryId, useCurrentPost) =>
    dispatch(setCategoryToPosts(postIds, categoryId, useCurrentPost))
});

class CategoryAction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open,
      detailViewId: -1,
      selectedItemId: -1
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  /**
   * Listen to mouse clicks
   * @param {object} e the mouse event
   */
  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  /**
   * If mouse is clicked outside of this component, close the category tree.
   */
  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  /**
   * Toggle the catgegory tree edit / view mode
   */
  onToggle = () => {
    let selId = this.state.detailViewId;
    if (this.props.useCurrentPost && this.state.detailViewId === -1) {
      const currentPost = this.props.currentPost.first();
      selId = currentPost.category_id !== null ? currentPost.category_id : -1;
    }

    this.setState({
      isOpen: !this.state.isOpen,
      selectedItemId: this.state.isOpen ? -1 : selId
    });
  };

  /**
   * Called when the user wants to update the category
   */
  onUpdate = () => {
    const selPost = this.props.useCurrentPost
      ? this.props.currentPost.toArray()
      : this.props.selectedPosts;
    const postIds = selPost.map(post => post.id);
    this.props.setCategory(
      postIds,
      this.state.selectedItemId,
      this.props.useCurrentPost
    );

    this.setState({
      isOpen: false,
      detailViewId: this.state.selectedItemId,
      selectedItemId: -1
    });
  };

  /**
   * Returns a key for a node in the tree
   * @param {object} node
   * @returns {any} key
   */
  getNodeKey = ({ node }) => {
    if (node == null) return null;
    if (node.tempId) {
      return node.tempId;
    }
    return node.id;
  };

  /**
   * handler for tree changes
   * @param {array} treeData  array of tree nodes
   */
  onTreeChange = treeData => this.setState({ treeData });

  /**
   * handles for tree-node-clicks
   * @param {string} nodeId the id of the selected node
   * @param {object} e the click-event
   */
  onNodeClick = (nodeId, e) => {
    if (e.target.tagName === NODE_ELEMENT_TYPE) {
      let { selectedItemId } = this.state;
      selectedItemId = selectedItemId !== nodeId ? nodeId : -1;
      this.setState({
        selectedItemId
      });
    }
  };

  /**
   * Generates props for a node, used by Tree for rendering the tree
   * @param {object} node the node to be rendered
   */
  generateNodeProps = ({ node }) => ({
    className:
      this.state.selectedItemId === node.id
        ? "category-node selected"
        : "category-node",
    onClick: this.onNodeClick.bind(this, node.id)
  });

  /**
   * Get the current category id, based on state and/or the currently loaded post.
   * @return {string} the current category id
   */
  getCategoryId = () => {
    const { detailViewId } = this.state;
    if (detailViewId !== -1) {
      return detailViewId;
    }
    const { currentPost: currentPostMap } = this.props;
    const currentPost = currentPostMap && currentPostMap.first();
    return currentPost ? currentPost.category_id : null;
  };

  /**
   * Sub render function for the tree
   * @param {array} treeData  the treeData that should be rendered
   */
  renderTree = treeData => {
    return (
      <TreeWrapper>
        <Tree
          treeData={treeData}
          getNodeKey={this.getNodeKey}
          theme={FileExplorerTheme}
          canDrag={false}
          onChange={this.onTreeChange}
          generateNodeProps={this.generateNodeProps}
        />
      </TreeWrapper>
    );
  };

  /**
   * Sub render function for the branch that contains a specific node
   * @param {array} treeData the entire tree where the branch is
   * @param {string} selectedItemId a node id that is used to select whitch branch to render
   */
  renderBranch = ({ treeData, selectedItemId }) => (
    <InlineBranchDisplay treeData={treeData} selectedItemId={selectedItemId} />
  );

  render() {
    const { selectedPosts, useCurrentPost, categories } = this.props;

    const useInlineSectionSkin = !!useCurrentPost;
    const title = "Struktur";

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    const buttonText = useInlineSectionSkin
      ? "Flytta 1 post"
      : "Flytta " + selectedPosts.length + " poster";

    const treeData = categories.toArray().map(convertCategory);
    const selectedItemId = this.getCategoryId();

    return (
      <div ref={this.clickRef}>
        <CatActionWrapper>
          {useInlineSectionSkin ? (
            <Header>
              <HeaderTitle>{title}</HeaderTitle>
              <InlineSectionButton
                studlicon="Cog"
                onClick={this.onToggle}
              ></InlineSectionButton>
            </Header>
          ) : (
            <StandardButton
              studlicon="Move"
              onClick={this.onToggle}
            ></StandardButton>
          )}

          <CatActionContent className={classNames}>
            <Top>
              <Title>Kategorisera:</Title>
            </Top>

            <Middle>
              <MiddleContent>{this.renderTree(treeData)}</MiddleContent>
            </Middle>

            <Bottom>
              <UpdateButton onClick={this.onUpdate}>
                {" "}
                {buttonText}{" "}
              </UpdateButton>
            </Bottom>
          </CatActionContent>
        </CatActionWrapper>
        {useInlineSectionSkin
          ? this.renderBranch({ treeData, selectedItemId })
          : null}
      </div>
    );
  }
}

/**
 * Convert categories from backend data to something rendereable
 * @param {object} category category tree
 */
const convertCategory = category => ({
  id: category.id,
  title: category.title,
  children: category.children ? category.children.map(convertCategory) : [],
  expanded: true
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAction);
