import * as React from "react";
import styled from "styled-components";

const Icon = styled.image``;

const defaultFormat = "16:9";

const getViewBox = format => {
  const viewBoxes = {
    "16:9": "0 0 700 390",
    "3:1": "0 0 900 300",
    "4:1": "0 0 1024 256",
    "2:1": "0 0 312 136",
    "1:1": "0 0 256 256"
  };
  return viewBoxes[format] || viewBoxes[defaultFormat];
};

const getIconSize = format => {
  const iconSizes = {
    "16:9": 280,
    "3:1": 280,
    "4:1": 256,
    "2:1": 136,
    "1:1": 256
  };
  return iconSizes[format] || iconSizes[defaultFormat];
};

class MultiSvgImage extends React.PureComponent {
  getSize = (size, format) => {
    const scale =
      size === "extrasmall"
        ? 0.125
        : size === "small"
        ? 0.25
        : size === "medium"
        ? 0.5
        : size === "large"
        ? 1
        : 0.5;
    return scale * getIconSize(format);
  };

  renderImg = (x, y, size, imageObj, index) => {
    return (
      <Icon
        x={x}
        y={y}
        width={size + "px"}
        height={size + "px"}
        xlinkHref={imageObj.src}
        key={"k" + index}
      />
    );
  };

  render() {
    const { format, icons } = this.props;
    const viewboxSize = getViewBox(format);

    return (
      <svg viewBox={viewboxSize} height={"200px"} width={"200px"}>
        {icons.map((img, i) =>
          this.renderImg(
            img.x,
            img.y,
            this.getSize(img.size, format),
            img.image,
            i
          )
        )}
      </svg>
    );
  }
}

export default MultiSvgImage;
