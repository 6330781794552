import { put, select, take, call } from "redux-saga/effects";
import {
  fetchMediaFilter,
  fetchMediaEntities,
  previousMediaPage,
  nextMediaPage,
  resetMediaBufferPage,
  resetBeforeFilterSortChange,
  fetchMediaEntityDetails,
  gotoMediaPage
} from "../api/actions";

import { FETCH_MEDIA_ENTITIES_SUCCESS } from "../../api";
import { actionCreator } from "../../shared";
import {
  selectEntityId,
  selectEntityType
} from "../../store/selectors";

import { getMediaRoute } from "../../shared/helpers";
import { getSystemsList } from "../../systems/store/selector";

export function* onMediaFilterRoute(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const entityType = yield select(selectEntityType);
  const systems = yield select(getSystemsList);
  const mediaRoute = getMediaRoute(entityId, entityType, systems)

  const filters = yield select(st => st.location.routesMap[mediaRoute].filters);
  const tagsByTypeOnly = yield select(st => st.location.routesMap[mediaRoute].tagsByTypeOnly);
  yield put(
    fetchMediaFilter(entityName, apiAction, { entityId, entityType, tagsByTypeOnly }, filters)
  );
}

export function* filterMedia(entityName, apiAction, filterParams) {
  const entityId = yield select(selectEntityId);
  const entityType = yield select(selectEntityType);
  const params = filterParams.filter;

  yield put(resetBeforeFilterSortChange(entityName));

  const page = yield select(st => st[entityName].get("page"));

  yield call(
    fetchMediaPage,
    entityName,
    apiAction,
    { entityId, entityType },
    params,
    page
  );
  yield take(actionCreator(entityName, FETCH_MEDIA_ENTITIES_SUCCESS));
}

function* fetchMediaPage(entityName, apiAction, args, params, page) {
  const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));
  yield put(
    fetchMediaEntities(entityName, apiAction, args, {
      // we need to subtract by one to get desired page. Page 1 = 50 * 0 on
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

export function* onMediaListRoute(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const entityType = yield select(selectEntityType);
  const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));

  const params = (yield select(st => st.location.query)) || {};

  let page = 1;

  if (params.page) {
    page = params.page;
  }

  yield put(resetBeforeFilterSortChange(entityName));
  yield put(
    fetchMediaEntities(
      entityName,
      apiAction,
      { entityId, entityType },
      { offset: fetchLimit * (page - 1), num_result: fetchLimit, ...params }
    )
  );

  yield take(actionCreator(entityName, FETCH_MEDIA_ENTITIES_SUCCESS));
}

export function* previousMediaPageSaga(entityName, apiAction) {
  const batchIndex = yield select(st => st[entityName].get("page") - 1);
  const buffer = yield select(st =>
    st[entityName].get("buffer").get(batchIndex)
  );
  yield put(previousMediaPage(entityName));
  if (!buffer) {
    const entityId = yield select(selectEntityId);
    const entityType = yield select(selectEntityType);
    const searchParams = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));
    yield put(
      fetchMediaEntities(
        entityName,
        apiAction,
        { entityId, entityType },
        {
          ...searchParams,
          offset: (batchIndex - 1) * fetchLimit,
          num_result: fetchLimit
        }
      )
    );
  }
}

export function* nextMediaPageSaga(entityName, apiAction) {
  const batchIndex = yield select(st => st[entityName].get("page") + 1);
  const buffer = yield select(st =>
    st[entityName].get("buffer").get(batchIndex)
  );
  yield put(nextMediaPage(entityName));
  if (!buffer || buffer.size === 0) {
    const entityId = yield select(selectEntityId);
    const entityType = yield select(selectEntityType);
    const searchParams = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));
    yield put(
      fetchMediaEntities(
        entityName,
        apiAction,
        { entityId, entityType },
        {
          ...searchParams,
          offset: (batchIndex - 1) * fetchLimit,
          num_result: fetchLimit
        }
      )
    );
  }
}

export function* loadMediaPageSaga(entityName, apiAction, { page }) {
  yield put(gotoMediaPage(entityName, page));
  const batchIndex = yield select(st => st[entityName].get("page"));
  const buffer = yield select(st =>
    st[entityName].get("buffer").get(batchIndex)
  );
  if (!buffer) {
    const entityId = yield select(selectEntityId);
    const entityType = yield select(selectEntityType);
    const searchParams = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));
    yield put(
      fetchMediaEntities(
        entityName,
        apiAction,
        { entityId, entityType },
        {
          ...searchParams,
          offset: (batchIndex - 1) * fetchLimit,
          num_result: fetchLimit
        }
      )
    );
  }
}

export function* reloadMediaPageSaga(entityName, apiAction) {
  const page = yield select(st => st[entityName].get("page"));
  yield put(resetMediaBufferPage(entityName, page));
  const entityId = yield select(selectEntityId);
  const entityType = yield select(selectEntityType);
  const searchParams = yield select(st =>
    st[entityName].get("searchParameters").toJS()
  );
  const fetchLimit = yield select(st => st[entityName].get("fetchLimit"));
  yield put(
    fetchMediaEntities(
      entityName,
      apiAction,
      { entityId, entityType },
      {
        ...searchParams,
        offset: (page - 1) * fetchLimit,
        num_result: fetchLimit
      }
    )
  );
}

export function* mediacarousel(entityName, apiAction) {
  const active = yield select(st => st.MediaCarousel.active);
  const entityId = yield select(selectEntityId);
  yield put(
    fetchMediaEntityDetails(entityName, apiAction, {
      entityId,
      fileID: active.id
    })
  );
}
