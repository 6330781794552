import * as React from "react";
import { Plus } from "react-feather";
import { COLUMN, EQUATION, ROW, STANDARD } from "../../../constants";
import { Toggle } from "../../../shared/components/StudliIcons";
import ButtonCell from "./ChartCells/ButtonCell/ButtonCell";
import InputCell from "./ChartCells/InputCell/InputCell";
import MediaCell from "./ChartCells/MediaCell/MediaCell";
import {
  StyledChartGrid,
  StyledMarkerContainer,
  StyledMarkerButton,
  StyledInteractiveSign,
  StyledCellContainer,
  StyledAddButton,
  StyledButtonsContainer
} from "./StyledChartGrid";

const ChartGrid = ({
  grid,
  storeDraft,
  draftTarget,
  settings: { showBorders, chartType },
  updateCellCallback,
  onSelectedCell,
  onAddSection,
  multipleSelections,
  resources,
  selectedCells,
  gridDimensions,
  gridDimensionsCallback
}) => {
  const gridRef = React.useRef();
  const [multiSelection, setMultipleSelection] = React.useState(false);

  React.useEffect(() => {
    let current = gridRef.current;

    if (current) {
      const onClick = e => {
        setMultipleSelection(false);

        const element = e.path.find(
          el =>
            el.id === "chart-button" ||
            el.id === "media-container" ||
            el.id === "section-button"
        );

        const rowIndex = element && element.attributes["data-rowindex"];
        const cellIndex = element && element.attributes["data-cellindex"];
        const direction = element && element.attributes.direction;

        if (e.shiftKey) {
          setMultipleSelection(true);

          rowIndex &&
            cellIndex &&
            multipleSelections(
              rowIndex.nodeValue,
              cellIndex.nodeValue,
              direction && direction.nodeValue
            );
          e.stopPropagation();
        }
      };

      current.addEventListener("mousedown", onClick);
      return () => {
        current.removeEventListener("mousedown", onClick);
      };
    }
  }, [multiSelection, multipleSelections, selectedCells]);

  React.useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.clientHeight;
      const width = gridRef.current.clientWidth;

      if (gridDimensions.width !== width || gridDimensions.height !== height) {
        gridDimensionsCallback(width, height);
      }
    }
  }, [gridRef, gridDimensions, gridDimensionsCallback]);

  const cellCallback = multiSelection ? undefined : onSelectedCell;

  const cells = (cell, rowIndex, cellIndex) => {
    const isSelected = selectedCells.some(
      selectedCell => selectedCell.id === cell.id
    );

    if (cell.image || cell.mediaresource) {
      return (
        <MediaCell
          selected={isSelected}
          key={`media-[${rowIndex}/${cellIndex}]`}
          cell={cell}
          callback={cellCallback}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          resources={resources}
        />
      );
    }

    return isSelected && !multiSelection ? (
      <InputCell
        key={`input-[${rowIndex}/${cellIndex}]`}
        cell={cell}
        onChange={updateCellCallback}
        rowIndex={rowIndex}
        cellIndex={cellIndex}
        storeDraft={storeDraft}
        draftTarget={draftTarget}
        selectedCells={selectedCells}
      />
    ) : (
      <ButtonCell
        selected={isSelected}
        type={chartType}
        key={`button-[${rowIndex}/${cellIndex}]`}
        cell={cell}
        callback={cellCallback}
        rowIndex={rowIndex}
        cellIndex={cellIndex}
      />
    );
  };

  return (
    <StyledChartGrid
      showBorders={showBorders}
      ref={gridRef}
      rowLength={grid[0].length}
      id="wrapper"
    >
      {grid.map((row, rowIndex) =>
        row.map((cell, cellIndex) => {
          if (rowIndex === 0 || cellIndex === 0) {
            const sectionDirection = rowIndex === 0 ? COLUMN : ROW;
            const sectionIndex = rowIndex === 0 ? cellIndex : rowIndex;

            return (
              <StyledMarkerContainer
                column={cell.column}
                row={cell.row}
                direction={sectionDirection}
                key={`marker-[${rowIndex}/${cellIndex}]`}
              >
                <StyledButtonsContainer>
                  <StyledMarkerButton
                    id="section-button"
                    data-rowindex={rowIndex}
                    data-cellindex={cellIndex}
                    direction={sectionDirection}
                    onClick={
                      multiSelection
                        ? undefined
                        : onSelectedCell(rowIndex, cellIndex, {
                            direction: sectionDirection,
                            index: sectionIndex
                          })
                    }
                  />
                  {chartType === EQUATION && rowIndex === 0 ? null : (
                    <StyledAddButton
                      direction={sectionDirection}
                      onClick={onAddSection(sectionDirection, sectionIndex + 1)}
                    >
                      <Plus width="14px" height="14px" />
                    </StyledAddButton>
                  )}
                </StyledButtonsContainer>

                {rowIndex === 0 && cellIndex === 0 && (
                  <StyledButtonsContainer>
                    <StyledMarkerButton
                      id="section-button"
                      data-rowindex={rowIndex}
                      data-cellindex={cellIndex}
                      direction={ROW}
                      onClick={
                        multiSelection
                          ? undefined
                          : onSelectedCell(rowIndex, cellIndex, {
                              direction: ROW,
                              index: cellIndex
                            })
                      }
                    />
                    {chartType === STANDARD && (
                      <StyledAddButton
                        position="zero"
                        direction={COLUMN}
                        onClick={onAddSection(COLUMN, rowIndex)}
                      >
                        <Plus width="14px" height="14px" />
                      </StyledAddButton>
                    )}
                    <StyledAddButton
                      position="zero"
                      direction={ROW}
                      onClick={onAddSection(ROW, cellIndex)}
                    >
                      <Plus width="14px" height="14px" />
                    </StyledAddButton>
                    <StyledAddButton
                      direction={ROW}
                      onClick={onAddSection(ROW, cellIndex + 1)}
                    >
                      <Plus width="14px" height="14px" />
                    </StyledAddButton>
                  </StyledButtonsContainer>
                )}
                <StyledCellContainer key={cell.id}>
                  {cells(cell, rowIndex, cellIndex)}
                  {cell.interactive && (
                    <StyledInteractiveSign>
                      <Toggle size={20} color="black" />
                    </StyledInteractiveSign>
                  )}
                </StyledCellContainer>
              </StyledMarkerContainer>
            );
          }

          return (
            <StyledCellContainer key={cell.id}>
              {cells(cell, rowIndex, cellIndex)}
              {cell.interactive && (
                <StyledInteractiveSign>
                  <Toggle size={20} color="black" />
                </StyledInteractiveSign>
              )}
            </StyledCellContainer>
          );
        })
      )}
    </StyledChartGrid>
  );
};

export default ChartGrid;
