import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../../shared/components/Button";
import { createProduct, importProduct } from "../../api/actions";
import { getProductTypes, getSesamAvailable } from "../../store/selectors";
import { Field, SelectField } from "../Input";
import Switch from "../Switch";
import Label from "../Label";
import {
  createValidation,
  ErrorFormatter,
  productErrors,
  CreateProduct,
  ImportAProduct
} from "../../validation/product";
import AutoComplete from "../AutoComplete";

const createProductValidation = createValidation(
  CreateProduct,
  ErrorFormatter(productErrors)
);

const importValidation = createValidation(
  ImportAProduct,
  ErrorFormatter(productErrors)
);

export const Search = styled(AutoComplete)`
  width: 100%;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const Title = styled.span`
  ${props => props.theme.d400}
  margin-top: 0;
  color: #287574;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
`;

export const Input = styled(Field)`
  width: 100%;
  height: 45px;
  &:disabled {
    background: white;
    opacity: 0.7;
  }
`;

export const StyledSelect = styled(SelectField)`
  width: 224px;
  font-weight: 500;
  color: #287574;
  &:disabled {
    opacity: 0.7;
  }
`;

const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 6.5rem;
`;
const FormButtonWrapper = styled.div`
  position: absolute;
  bottom: 27px;
  right: 27px;
`;

const CreateNewProductForm = ({ close }) => {
  const initValues = {
    sesam_name: "",
    tempfolder: "",
    title: "",
    typeId: ""
  };

  const dispatch = useDispatch();
  const types = useSelector(getProductTypes).toJS();
  const [formValues, setFormValues] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [isImportMode, setIsImportMode] = useState(false);

  const activeTypes = types.filter(t => !t.disabled);
  const productTypes = [{ type: "Välj produkttyp", id: 0 }, ...activeTypes];

  const addNewProduct = data => dispatch(createProduct(data));
  const importProductToEdda = sesamName => dispatch(importProduct(sesamName));

  const onSubmit = () => {
    let { err } = isImportMode
      ? importValidation(formValues)
      : createProductValidation(formValues);
    if (formValues.sesam_name === '' && formValues.tempfolder === '') {
      err = {...err, sesam_name: "Välj en sesamgrupp"}
    }
   
    if (err) {
      setErrors(err);
      return;
    }
    if (isImportMode) {
      importProductToEdda(formValues.sesam_name);
    } else {
      const data = {
        title: formValues.title,
        typeId: formValues.typeId,
        sesam: {
          name: formValues.sesam_name !== '' ? formValues.sesam_name : formValues.tempfolder,
          validated: formValues.sesam_name !== '' 
        },
      }
      addNewProduct(data);
      close();
    }
  };

  const _onChange = (value, name) => {
    
    if (errors[name]) {
      const { err } = createProductValidation({
        ...formValues,
        [name]: value
      });
      setErrors(err || {});
    }

    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const _onSelectChange = (value) => {
    if (errors["sesam_name"]) {
      const { err } = createProductValidation({
        ...formValues,
        sesam_name: value
      });
      setErrors(err || {});
    }

    setFormValues({
      ...formValues,
      tempfolder: "",
      sesam_name: value
    });
  }

  const _onImportChange = (value, name) => {
    if (errors[name]) {
      const { err } = importValidation({
        ...formValues,
        [name]: value
      });
      setErrors(err || {});
    }

    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const _onSwitchChange = bool => {
    setIsImportMode(bool);
    setErrors({});
    setFormValues(initValues);
  };

  return (
    <>
      <>
        <Title>{isImportMode ? "Importera produkt" : "Skapa ny produkt"}</Title>
        <FormWrapper>
          <FormElementWrapper>
            {isImportMode && (
              <Input
                error={errors["sesam_name"]}
                value={formValues.sesam_name}
                onChange={_onImportChange}
                label="Sesamgrupp *"
                name="sesam_name"
                autoComplete="off"
              />
            )}
            { !isImportMode && (
              <Label>
                Sesamgrupp
                <Search
                  placeholder="Sök sesamgrupp"
                  onSelect={_onSelectChange}
                  selector={getSesamAvailable}
                  name="sesam_name"
                  error={errors["sesam_name"]}
                />
              </Label>
            )}
          </FormElementWrapper>
          { !isImportMode && (
            <FormElementWrapper>
              <Input
                error={errors["tempfolder"]}
                value={formValues.tempfolder}
                onChange={_onChange}
                label="Mappnamn (om inte sesamgrupp finns eller annat än xpub/dill)"
                name="tempfolder"
                autoComplete="off"
                disabled={formValues.sesam_name !== ""}
              />
            </FormElementWrapper>
          )}
          <FormElementWrapper>
            <Input
              error={errors["title"]}
              value={formValues.title}
              onChange={_onChange}
              label="Titel *"
              name="title"
              autoComplete="off"
              disabled={isImportMode}
            />
          </FormElementWrapper>
          <FormElementWrapper>
            <StyledSelect
              error={errors["typeId"]}
              value={formValues.typeId || 0}
              onChange={_onChange}
              label="Produkttyp"
              name="typeId"
              options={productTypes.map(v => ({ key: v.type, val: v.id }))}
              disabled={isImportMode}
            />
          </FormElementWrapper>
          <FormElementWrapper>
            <Switch label="Importera befintlig" onChange={_onSwitchChange} />
          </FormElementWrapper>
        </FormWrapper>
      </>
      <FormButtonWrapper>
        <PrimaryButton onClick={onSubmit}>
          {isImportMode ? "Importera" : "Skapa"}
        </PrimaryButton>
      </FormButtonWrapper>
    </>
  );
};

export default CreateNewProductForm;
