export const productPostData = {
  questions: [
    {
      postId: 1,
      title: "Kapitel 1"
    },
    {
      postId: 2,
      title: "Kapitel 2"
    },
    {
      postId: 3,
      title: "Kapitel 3"
    }
  ],
  tests: [
    {
      title: "Kapitel 1"
    },
    {
      title: "Kapitel 2"
    },
    {
      title: "Kapitel 3"
    }
  ]
}

