import React from "react";
import { connect } from "react-redux";
import { StandardButton } from "../../../shared";
import { Collapsible } from "../../../shared";
import TagPostLabels from "./TagPostLabels";
import { addRemoveTagsToPost } from "../../store/actions";

import {
  StyledTagPostWrapper,
  StyledTagPostContent,
  StyledHeader,
  StyledHeaderTitle,
  StyledTop,
  StyledTitle,
  StyledMiddle,
  StyledMiddleContent,
  StyledBottom,
  StyledUpdateButton,
  StyledInlineSectionButton,
  StyledInlineTagDisplay
} from "./TagPostStyles";

import {
  selectCurrentPostType,
  selectSelectedPostList,
  selectLabels,
  selectTags,
  getTagsPerPost,
  getPostsPerTag
} from "../../api/selectors";

class TagPost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open,
      tagList: [],
      clickedItems: {},
      clickedTags: [],
      tagsPerPostState: [],
      postsPerTagState: []
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  /**
   * Function to handle click inside and outside of this component.
   */
  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  /**
   * Close window if click outside this component.
   */
  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  /**
   * Toggla if tagWindow is open or not. Set tagList.
   */
  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      tagList: !this.state.isOpen ? this.getTagList() : []
    });
  };

  /**
   * Add status property for each tag (checked, empty, neutral)
   */
  getTagsWithStatus = () => {
    const { tags, selectedPostsList, postsPerTag } = this.props;
    return tags.map(group => ({
      title: group.title,
      open: false,
      tags: group.data[0].tags.map(t =>
        postsPerTag[t.id]
          ? {
              ...t,
              status:
                postsPerTag[t.id].length === selectedPostsList.length
                  ? "checked"
                  : "neutral"
            }
          : { ...t, status: "empty" }
      )
    }));
  };

  /**
   * Add status property to each label (checked, empty, neutral)
   */
  getLabelsWithStatus = () => {
    const { labels, postsPerTag, selectedPostsList } = this.props;

    return [
      {
        title: "Labels",
        open: true,
        tags: labels.map(l =>
          postsPerTag[l.id]
            ? {
                ...l,
                status:
                  postsPerTag[l.id].length === selectedPostsList.length
                    ? "checked"
                    : "neutral"
              }
            : { ...l, status: "empty" }
        )
      }
    ];
  };

  /**
   * remove tag duplicates from taglist
   * @param taglist
   * @return {object}
   */
  removeDuplicates = taglist =>
    Object.keys(taglist).reduce(
      (o, p) => {
        o.post_ids.push(Number(p));
        taglist[p].forEach(t => {
          if (o.tags.indexOf(t) < 0) {
            o.tags = [...o.tags, ...[t]];
          }
        });
        return o;
      },
      { post_ids: [], tags: [] }
    );

  /**
   * save the changes. Divide clickedItems into an add and a remove list.
   */
  onUpdate = () => {
    const { clickedItems } = this.state;
    const { tagsPerPost } = this.props;
    let addTags = {};
    let removeTags = {};
    let add_res = null;
    let remove_res = null;

    Object.keys(clickedItems).forEach(tagId => {
      tagId = Number(tagId);
      if (clickedItems[tagId].length > 0) {
        const posts = clickedItems[tagId];
        posts.forEach(postId => {
          if (!tagsPerPost[postId] || tagsPerPost[postId].indexOf(tagId) < 0) {
            if (addTags[postId]) {
              addTags[postId].push(tagId);
            } else {
              addTags[postId] = [tagId];
            }
          }
        });
      } else {
        Object.keys(tagsPerPost).forEach(postId => {
          if (tagsPerPost[postId].indexOf(tagId) >= 0) {
            if (removeTags[postId]) {
              removeTags[postId].push(tagId);
            } else {
              removeTags[postId] = [tagId];
            }
          }
        });
      }
    });

    if (Object.keys(addTags).length > 0) {
      add_res = this.removeDuplicates(addTags);
    }

    if (Object.keys(removeTags).length > 0) {
      remove_res = this.removeDuplicates(removeTags);
    }

    if (add_res !== null || remove_res !== null) {
      this.props.addRemoveTagPost(
        this.props.postType,
        add_res,
        remove_res,
        !!this.props.useCurrentPost
      );
    }

    this.setState({
      isOpen: false,
      clickedItems: {}
    });
  };

  /**
   * click on a tag (checkbox) in tagwindow
   */
  tagClick = (tagId, status) => {
    const { selectedPostsList } = this.props;
    const { clickedTags } = this.state;
    let { clickedItems } = this.state;
    let tag = {};
    const tagList = this.getTagList();

    const postIds = selectedPostsList.map(post => {
      return post.id;
    });

    clickedItems[tagId] = status === "checked" ? postIds : [];

    const temp = tagList.map(g => {
      g.tags = g.tags.map(t => {
        if (t.id === tagId) {
          t.status = status;
          tag = t;
        }
        return t;
      });
      return g;
    });
    clickedTags[tagId] = tag;

    this.setState({
      tagList: temp,
      clickedItems,
      clickedTags
    });
  };

  render() {
    const {
      useCurrentPost,
      content,
      selectedPostsList,
      isDetailView
    } = this.props;
    const useInlineSectionSkin = !!useCurrentPost;
    const title = content[0];
    const useDetailViewPosition = isDetailView;

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <div ref={this.clickRef}>
        <StyledTagPostWrapper>
          {this.renderButtonOrHeader(useInlineSectionSkin, title)}
          <StyledTagPostContent
            className={classNames}
            useDetailViewPosition={useDetailViewPosition}
          >
            <StyledTop>
              <StyledTitle>Välj labels:</StyledTitle>
            </StyledTop>
            <StyledMiddle>
              {this.renderTagList(useInlineSectionSkin)}
            </StyledMiddle>
            <StyledBottom>
              <StyledUpdateButton onClick={this.onUpdate}>
                {" "}
                Uppdatera {selectedPostsList.length} poster{" "}
              </StyledUpdateButton>
            </StyledBottom>
          </StyledTagPostContent>
        </StyledTagPostWrapper>
        {useInlineSectionSkin ? this.renderCurrentPostTags() : null}
      </div>
    );
  }

  /**
   * returns a list of labels and tags. If there is no tagList in state - use tagList from props.
   */
  getTagList = () => {
    return this.state.tagList.length > 0
      ? this.state.tagList
      : [...this.getLabelsWithStatus(), ...this.getTagsWithStatus()];
  };

  /**
   * Render the tags for the current post. Show only tags depending on content.
   */
  renderCurrentPostTags = () => {
    const { selectedPostsList, content, tagsPerPost } = this.props;
    const { tagsPerPostState } = this.state;
    const tagList = this.getTagList();

    const postId = selectedPostsList[0] ? selectedPostsList[0].id : 0;
    const tags = tagsPerPostState.length > 0 ? tagsPerPostState : tagsPerPost;

    const tagIds = tags[postId] ? tags[postId] : [];
    return (
      <StyledInlineTagDisplay
        postTags={tagIds}
        tagList={tagList}
        content={content}
      />
    );
  };

  /**
   * Renders the button or header of the tag selection window depending if it is on a detailview or listview
   * @param {bool} useInlineSectionSkin
   * @param {string} title
   */
  renderButtonOrHeader = (useInlineSectionSkin, title) =>
    useInlineSectionSkin ? (
      <StyledHeader>
        <StyledHeaderTitle>{title}</StyledHeaderTitle>
        <StyledInlineSectionButton
          studlicon="Cog"
          onClick={this.onToggle}
        ></StyledInlineSectionButton>
      </StyledHeader>
    ) : (
      <StandardButton
        studlicon="Label"
        onClick={this.onToggle}
      ></StandardButton>
    );

  /**
   * Renders the tag list with current status
   * @param {bool} useInlineSectionSkin
   * @param {array} tagList
   */
  renderTagList = useInlineSectionSkin => {
    const tagList = this.getTagList();

    return tagList.map(g =>
      this.props.content.indexOf(g.title) > -1 ? (
        <StyledMiddleContent key={g.title}>
          <Collapsible
            title={g.title}
            open={useInlineSectionSkin ? true : g.open}
          >
            <TagPostLabels
              labelList={g.tags}
              onChange={this.tagClick}
              scrollable={false}
              singleselect={g.title !== "Labels"}
            />
          </Collapsible>
        </StyledMiddleContent>
      ) : null
    );
  };
}

const mapStateToProps = (state, props) => {
  const postType = selectCurrentPostType(state);
  const selPostList = selectSelectedPostList(
    state,
    props.useCurrentPost,
    postType
  );

  return {
    postType: postType,
    selectedPostsList: selPostList,
    labels: selectLabels(state, postType).toJS(),
    tags: selectTags(state, postType),
    tagsPerPost: getTagsPerPost(selPostList),
    postsPerTag: getPostsPerTag(selPostList)
  };
};

const mapDispatchToProps = dispatch => ({
  addRemoveTagPost: (postType, add, remove, detailpage) =>
    dispatch(addRemoveTagsToPost(postType, add, remove, detailpage))
});

export default connect(mapStateToProps, mapDispatchToProps)(TagPost);
