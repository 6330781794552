import React from "react";
import { Trash2 as Trash } from "react-feather";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { GripHandle as DragIcon } from "../../../shared/components/StudliIcons";
import { Grip, ListItem, PreviewContainer, StyledDeleteButton, Title, TitleContainer } from "./StyledMediaListItem";

const DeleteButton = ({ onDelete }) => (
  <StyledDeleteButton
    aria-label="Ta bort media"
    onClick={onDelete}
  >
    <Trash width="24px" height="24px" />
  </StyledDeleteButton>
);

export const BiafMediaListItem = SortableElement(({ title, onDelete }) => (
  <ListItem>
    <TitleContainer>
      <DragHandle />
      <Title>{title}</Title>
    </TitleContainer>
    <DeleteButton onDelete={onDelete} />
  </ListItem>
));

const GripHandle = () => (
  <Grip>
    <DragIcon size={18} />
  </Grip>
);

const DragHandle = SortableHandle(() => <GripHandle />);

export const PreviewItem = ({ fileList, selected, onDelete }) => {
  if (!fileList || !selected) return null;

  const selectedItem = fileList.find(file => file.id === parseInt(selected.value));

  if (!selectedItem) return null;

  return (
    <PreviewContainer>
      <Title>{selectedItem.title}</Title>
      <DeleteButton onDelete={() => onDelete(selectedItem.id)} />
    </PreviewContainer>
  )
}