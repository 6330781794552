import React from "react";
import Link from "../Link";
import { CircleX } from "../StudliIcons";

import {
  DeletableChip,
  DeletableChipIcon,
  PlainChip,
  ChipContent
} from "./StyledChip";

const Chip = ({
  label,
  id,
  url,
  isDeletable = false,
  labelColor,
  deleteCallback
}) => {
  /**
   * Render deletable
   * @returns {null}
   */
  const renderDeletable = () =>
    isDeletable ? (
      <DeletableChip onClick={_deleteCallback}>
        <DeletableChipIcon>
          <CircleX size="24" />
        </DeletableChipIcon>
      </DeletableChip>
    ) : null;

  /**
   * Delete callback
   * @returns {null}
   * @private
   */
  const _deleteCallback = () =>
    deleteCallback ? deleteCallback(id || label) : null;

  /**
   * Render label
   * @returns {*}
   */
  const renderLabel = () => (
    <PlainChip labelColor={labelColor} isDeletable={isDeletable}>
      <ChipContent>{label}</ChipContent>
      {renderDeletable()}
    </PlainChip>
  );

  /**
   * Render link
   * @returns {*}
   */
  const renderLink = () => <Link href={url}>{renderLabel()}</Link>;

  return url ? renderLink() : renderLabel();
};

export default Chip;
