import { ROUTE_AIDA_LIST } from "../posts";
import { ROUTE_AIDA_MEDIA, ROUTE_OMNIA_MEDIA } from "../media";
import Aida from "./views/Aida";
import Empty from "./views/Empty";
import { ENTITIES, AIDA, OMNIA } from "../constants";

export const ROUTE_AIDA = "ROUTE_AIDA";
export const ROUTE_OMNIA = "ROUTE_OMNIA";

export const routes = {
  [ROUTE_AIDA]: {
    name: ENTITIES[AIDA],
    path: "/system/:entityId/aida",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Hjälpverktyg",
    type: "system",
    routes: [ROUTE_AIDA_LIST, ROUTE_AIDA_MEDIA]
  },
  [ROUTE_OMNIA]: {
    name: ENTITIES[OMNIA],
    path: "/system/:entityId/omnia",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Omnia",
    type: "system",
    routes: [ROUTE_OMNIA_MEDIA]
  }
};

export const views = {
  [ROUTE_AIDA]: Aida,
  [ROUTE_OMNIA]: Empty
};
