import styled from "styled-components";

export const EditorWrapper = styled.div`
  background-color: #fff;
  padding: 20px;
`;

export const LockBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4a4a4a;
  color: white;
  padding: 20px;
`;

export const InvalidDataBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a80000;
  color: white;
  padding: 20px;
`;

export const EditorBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 60px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
`;

export const BlockContent = styled.div`
  padding: 8px 16px;
`;

export const PostInfoMarkup = styled.div`
  line-height: 24px;
  font-size: 14px;
  input {
    margin-left: 15px;
    box-shadow: none;
  }
`;

export const StyledLayoutWrapper = styled.div`
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
