import React from "react";
import {
  DEFAULT,
  CHIPS,
  EXTRALARGE,
  SQUARE,
  ALTERNATIVE,
  translation,
  WIDE
} from "../../../constants";
import {
  StyledDefaultChips,
  StyledSquareChips,
  StyledExtraLargeChips,
  StyledWideChips
} from "../SortMachine/StyledSortMachine";

export const rowSettingStyles = {
  [CHIPS]: WIDE
};

export const totalItemsInBox = {
  [DEFAULT]: 7,
  [SQUARE]: 7,
  [EXTRALARGE]: 4,
  [WIDE]: 3
};

export const maxTotalItems = 12;

export const chipsData = [
  { id: 1, label: <StyledDefaultChips />, value: DEFAULT },
  { id: 2, label: <StyledSquareChips />, value: SQUARE },
  { id: 3, label: <StyledExtraLargeChips />, value: EXTRALARGE },
  { id: 4, label: <StyledWideChips />, value: WIDE },
];

export const settingsData = [
  {
    title: translation[ALTERNATIVE],
    rows: [
      {
        title: "",
        data: chipsData,
        type: CHIPS,
        style: SQUARE
      }
    ]
  }
];
