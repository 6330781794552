import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DataTable,
  selectable,
  TextColumn,
  DateColumn,
  LabelColumn,
  ChipColumn,
  IDColumn,
  CountColumn,
  StatusColumn,
  LockColumn,
  ErrorColumn,
  withActions,
  addFlash
} from "../../../shared";
import { ActionTop } from "../ToolBar/PostActions";
import { Pagination } from "../ToolBar/PostActions";
import { sortPosts } from "../../store/actions";
import { setItems } from "../../store/carousel/actions";
import { hasItems, getActive } from "../../store/carousel/selectors";
import {
  getCurrentPostPage,
  isCurrentPostPageLoading,
  getSearchParameters
} from "../../store/selectors";
import LinkColumn from "../../../shared/components/DataTable/Columns/Link";

import {
  ENTITIES,
  RESOURCE,
  HELP_RESOURCE,
  CREATE,
  translation
} from "../../../constants";
import { hasPermissionBool, getSection } from "../../../shared/Permissions";

const ActionDataTable = withActions(DataTable);

const SelectableDataTable = styled(selectable(ActionDataTable))`
  height: calc(100% - 112px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const mapStateToProps = (state, props) => {
  return {
    posts: getCurrentPostPage(state, props.type),
    loading: isCurrentPostPageLoading(state, props.type),
    hasItems: hasItems(state),
    activeItem: getActive(state),
    selected: state.Carousel ? state.Carousel.items : null,
    pathname: state.location.pathname,
    permissions: state.Product.get("permissions"),
    sortDirections: getSearchParameters(state, props.type).toJS().sort
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  infoFlash: msg => dispatch(addFlash(msg)),
  sort: (postType, sort) => dispatch(sortPosts(postType, sort)),
  onSelect: items => setItems(items)(dispatch)
});

class PostList extends React.PureComponent {
  static defaultProps = {
    posts: []
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.onKeyDown);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.onKeyDown);
  };

  /**
   * Handles key events.
   * @param e
   */
  onKeyDown = e => {
    if (e.ctrlKey && e.altKey) {
      //Represents ctrl + n ( CREATE NEW )
      if (e.keyCode === 78) {
        const section = getSection(this.props.type);
        hasPermissionBool(section, CREATE, this.props.permissions)
          ? window.open(this.props.pathname + "/new", "_top", false)
          : this.props.infoFlash(
              `Du har inga rättigheter för att skapa en ny ${translation[
                this.props.type.toLowerCase()
              ].toLowerCase()}.`
            );
        e.preventDefault();
      }
    }
  };

  render() {
    const {
      posts,
      loading,
      sort,
      onSelect,
      selected,
      type,
      sortDirections
    } = this.props;

    return (
      <SelectableDataTable
        list={posts}
        selectedPosts={selected}
        onSelect={onSelect}
        onSort={sort.bind(this, type)}
        actionsTop={ActionTop}
        actionsRight={Pagination}
        loading={loading}
        sortDirections={sortDirections}
        rowActions
      >
        <IDColumn dataKey="id" label="ID" width={0.1} />
        {type === ENTITIES[RESOURCE] || type === ENTITIES[HELP_RESOURCE] ? (
          <CountColumn dataKey="usage_count" label="Anv." width={0.1} />
        ) : null}
        <LinkColumn dataKey="title" label="Titel" width={0.2} abc={123} />
        {type === "Aida" ? (
          <ChipColumn
            dataKey="chips"
            label="Nyckelord"
            width={0.7}
            disableSort
          />
        ) : (
          <LabelColumn dataKey="tags" label="Labels" width={0.7} disableSort />
        )}
        {type === "Exercise" ? (
          <TextColumn dataKey="category" label="Kategori" width={0.4} />
        ) : null}
        {type === "Exercise" ? (
          <LockColumn
            dataKey="lock"
            label=""
            width={50}
            fixedWidth
            disableSort
          />
        ) : null}
        {type === "Exercise" ? (
          <ErrorColumn
            dataKey="error"
            label=""
            width={50}
            fixedWidth
            disableSort
          />
        ) : null}
        {type === "Exercise" || type === "Aida" ? (
          <StatusColumn
            dataKey="status"
            label=""
            width={62}
            fixedWidth
            disableSort
          />
        ) : null}
        <DateColumn
          dataKey="updated_at"
          label="Senast uppdaterad"
          width={0.3}
        />
      </SelectableDataTable>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostList);
