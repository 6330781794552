import { registerReducer, registerSaga, RouterService } from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import { structure as structureReducer } from "../structure/store/reducer";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Structure", structureReducer);
};
