import React from "react";
import { InputField } from "./InputStyles";

const Input = ({
  value,
  placeholder,
  onChange,
  onEnter,
  onBlur,
  className,
  overrideStyles = false,
  type = "text",
  disabled,
  ...rest
}) => {
  /**
   * Handles on change events
   * @param e
   * @returns {null}
   * @private
   */
  const _onChange = e =>
    onChange ? onChange(e.target.value, e.target.name) : null;

  /**
   * Handles on enter events
   * @param e
   * @returns {null}
   * @private
   */
  const _onKeyDown = e =>
    e.keyCode === 13 && onEnter
      ? onEnter({ text: e.target.value, target: e.target })
      : null;

  const _onBlur = e => (onBlur ? onBlur(e.target.value, e.target.name) : null);

  return (
    <InputField
      className={className}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={_onChange}
      onKeyDown={_onKeyDown}
      onBlur={_onBlur}
      overrideStyles={overrideStyles}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Input;
