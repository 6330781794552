import styled from "styled-components";
import IconButton from "./IconButton";
import { Publish } from "../../shared/components/StudliIcons";

const StyledPreviewButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  margin: 0 10px;
  padding: 0;
`;

const PreviewButton = ({ onClickPreview }) => {
  const _onClick = e => onClickPreview();

  return (
    <StyledPreviewButton aria-label="Förhandsgranska" onClick={_onClick}>
      <Publish size="33" />
    </StyledPreviewButton>
  );
};

export default PreviewButton;
