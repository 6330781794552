import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { DataTable, DateColumn } from "../../../shared";
import { sortClassroomGroups } from "../../api/actions";

import StatusColumn from "./Columns/StatusColumn";
import LinkColumn from "./Columns/LinkColumn";
import TypeColumn from "./Columns/TypeColumn";
import { getClassroomgroupList } from "../../store/selectors";
import EditionColumn from "./Columns/EditionColumn";

const StyledDataTable = styled(DataTable)`
  height: calc(100% - 112px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const ClassroomList = () => {
  const listdata = useSelector(getClassroomgroupList);
  const dispatch = useDispatch();

  const _onSort = sortBy => {
    dispatch(sortClassroomGroups(sortBy));
  };

  return (
    <StyledDataTable list={listdata} onSort={_onSort}>
      <LinkColumn dataKey="title" label="Namn" width={0.6} />
      <EditionColumn dataKey="edition" label="Upplaga" width={0.2} />
      <TypeColumn dataKey="typeId" label="Tillval" width={0.4} />
      <DateColumn dataKey="updatedAt" label="Senast ändrad" width={0.2} />
      <StatusColumn dataKey="status" label="Status" width={0.2} />
    </StyledDataTable>
  );
};

export default ClassroomList;
