import styled from "styled-components";

export const Grid = styled.div`
  height: 100%;
  background-color: #eeeeee;
  padding: 1rem;
  display: flex;
`;

export const Section = styled.section`
  min-width: 550px;
  margin: 0px 8px 20px;
`;
