import {
  WS_SOCKET_OPENED,
  WS_SOCKET_ERROR,
  WS_SOCKET_CLOSED,
  WS_INITIATE, NETWORK_CONNECTED, NETWORK_DISCONNECTED, NETWORK_DISCONNECTED_DELAYED, NETWORK_CHECK_CONNECTION
} from "../../../constants";

/** 
 * Action for initiating websocket 
 */
export const initiateWebSocket = () => ({
  type: WS_INITIATE,
});

/** 
 * Action for websocket opened 
 */
export const socketOpened = () => ({
  type: WS_SOCKET_OPENED,
});

/**
 * Action for websocket error
 */
export const socketError = () => ({
  type: WS_SOCKET_ERROR,
});

/**
 * Action for websocket closed
 */
export const socketClosed = () => ({
  type: WS_SOCKET_CLOSED,
});

export const networkConnected = () => ({
  type: NETWORK_CONNECTED,
});

export const networkDisonnected = (error) => ({
  type: NETWORK_DISCONNECTED,
  error
});

export const delayedNetworkDisonnected = (error) => ({
  type: NETWORK_DISCONNECTED_DELAYED,
  error
});

export const checkNetworkConnection = () => ({
  type: NETWORK_CHECK_CONNECTION,
})
