import createStoryReducer from "../api/reducer";

const adventuresReducer = createStoryReducer("Adventures");
const adventuresGroupReducer = createStoryReducer("AdventuresGroup");
const adventuresCardReducer = createStoryReducer("AdventuresCard");
const adventuresCardEditorReducer = createStoryReducer("AdventuresCardEditor");

export const adventures = adventuresReducer({
  args: {}
});

export const adventuresGroup = adventuresGroupReducer({
  args: {}
});

export const adventuresStoryCard = adventuresCardReducer({
  args: {}
});

export const adventuresStoryCardEditor = adventuresCardEditorReducer({
  args: {}
});
