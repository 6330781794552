import React from "react";
import { ChevronDown } from "react-feather";
import { StandardButton } from "../Button";

export const DropDownButton = ({
  children,
  hasChevron,
  inSidebar,
  ...rest
}) => {
  /**
   * Renders chevron
   * @returns {null}
   */
  const renderChevron = () =>
    hasChevron ? <ChevronDown width="16px" height="16px" /> : null;

  return (
    <StandardButton outline {...rest}>
      {children}
      {renderChevron()}
    </StandardButton>
  );
};
