import React from "react";
import { connect } from "react-redux";
import {
  requestReview,
  reviewPost,
  rejectPost,
  publishPosts,
  unpublishPosts
} from "../../store/actions";

import {
  selectCurrentPostType,
  selectSelectedPostList
} from "../../api/selectors";
import {
  StyledHeader,
  StyledHeaderTitle,
  StyledInlineSectionButton,
  StyledMiddleContent,
  StyledPublishPostContent,
  StyledPublishPostWrapper,
  StyledTitle,
  StyledTop,
  StyledUpdateButton
} from "./StatusPostStyles";
import {
  EDIT,
  REQUEST_REVIEW,
  SET_REVIEWED,
  DELETE,
  EXERCISES,
  UNPUBLISH,
  REJECT,
  PUBLISH
} from "../../../constants";
import HasPermission from "../../../shared/Permissions";

class StatusPost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  renderSettingsButton = disabled => (
    <StyledInlineSectionButton
      studlicon="Cog"
      onClick={this.onToggle}
      disabled={disabled}
    />
  );

  /**
   * Renders the settings button
   * @param {bool} useInlineSectionSkin
   * @param {string} title
   */
  renderSettingsSection = (title, settingsButton) => (
    <StyledHeader>
      <StyledHeaderTitle>{title}</StyledHeaderTitle>
      {settingsButton}
    </StyledHeader>
  );

  renderUpdateStatus = () => {
    const { content, postDraftState, status, workflowMap } = this.props;

    const actions =
      workflowMap &&
      workflowMap.exercises &&
      workflowMap.exercises[status].filter(element => element !== DELETE);
    const title = content[0];

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    const actionButtons = actions.map(action => (
      <HasPermission
        key={action}
        component={this.renderAction(action)}
        section={EXERCISES}
        permission={action}
      />
    ));
    const disabled = postDraftState === "dirty";

    const settingsButton = (
      <HasPermission
        component={this.renderSettingsButton(disabled)}
        section={EXERCISES}
        multiplePermissions={actions}
      />
    );

    return (
      <StyledPublishPostWrapper>
        {this.renderSettingsSection(title, settingsButton)}
        <StyledPublishPostContent className={classNames}>
          <StyledTop>
            <StyledTitle>Uppdatera status:</StyledTitle>
          </StyledTop>

          <div>
            <StyledMiddleContent>{actionButtons}</StyledMiddleContent>
          </div>
        </StyledPublishPostContent>
      </StyledPublishPostWrapper>
    );
  };

  updateStatus = key => () => {
    const {
      selectedPostsList,
      _requestReview,
      _reviewPost,
      _rejectPost,
      _publishPost,
      _unpublishPost,
      postType
    } = this.props;
    const { id } = selectedPostsList[0];

    switch (key) {
      case REQUEST_REVIEW:
        _requestReview(postType, [id], EDIT);
        break;
      case SET_REVIEWED:
        _reviewPost(postType, [id], EDIT);
        break;
      case REJECT:
        _rejectPost(postType, [id], EDIT);
        break;
      case PUBLISH:
        _publishPost(postType, [id], EDIT);
        break;
      case UNPUBLISH:
        _unpublishPost(postType, [id], EDIT);
        break;
      default:
      // not needed
    }
  };

  renderDropDownButton = (callback, title) => (
    <StyledUpdateButton onClick={callback}>{title}</StyledUpdateButton>
  );

  /**
   * Render action component depending on which action should be rendered.
   * @param action
   * @returns { JSX.component }
   */
  renderAction = action => {
    switch (action) {
      case REQUEST_REVIEW:
        return this.renderDropDownButton(
          this.updateStatus(REQUEST_REVIEW),
          "Skicka till granskning"
        );
      case SET_REVIEWED:
        return this.renderDropDownButton(
          this.updateStatus(SET_REVIEWED),
          "Godkänn granskning"
        );
      case REJECT:
        return this.renderDropDownButton(
          this.updateStatus(REJECT),
          "Avböj granskning"
        );
      case PUBLISH:
        return this.renderDropDownButton(
          this.updateStatus(PUBLISH),
          "Publicera övning"
        );
      case UNPUBLISH:
        return this.renderDropDownButton(
          this.updateStatus(UNPUBLISH),
          "Avpublicera övning"
        );
      default:
        return null;
    }
  };

  render() {
    return <div ref={this.clickRef}>{this.renderUpdateStatus()}</div>;
  }
}

const mapStateToProps = (state, props) => {
  let postType = selectCurrentPostType(state);
  return {
    postType: postType,
    selectedPostsList: selectSelectedPostList(
      state,
      props.useCurrentPost,
      postType
    ),
    postDraftState: state.Exercise.getIn(["postDraft", "postDraftState"]),
    workflowMap: state.Product.get("workflowMap")
  };
};

const mapDispatchToProps = dispatch => ({
  _requestReview: (postType, postIds, mode) => dispatch(requestReview(postType, postIds, mode)),
  _reviewPost: (postType, postIds, mode) => dispatch(reviewPost(postType, postIds, mode)),
  _rejectPost: (postType, postIds, mode) => dispatch(rejectPost(postType, postIds, mode)),
  _publishPost: (postType, postIds, mode) => dispatch(publishPosts(postType, postIds, mode)),
  _unpublishPost: (postType, postIds, mode) => dispatch(unpublishPosts(postType, postIds, mode))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusPost);
