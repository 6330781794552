import { prepareEntityDraft } from "../../sagas/PostDraft/helpers";
import { selectAidaPreviewData } from "../../api/selectors";
import { useSelector } from "react-redux";
import {
  generateImageBlock,
  generateAudioBlock,
  generateVideoBlock,
  generateSliderBlock
} from "./helpers";

function useGetPreviewData() {
  let { post, chiplist } = useSelector(selectAidaPreviewData);
  post = post ? post : null;
  const postdataChips = (post && post.data && post.data.chips) || [];
  const currentAidaChips = chiplist.reduce((acc, tag) => {
    if (postdataChips.some(t => tag.id === t)) {
      acc.push(tag.title);
    }
    return acc;
  }, []);

  const postData = post && prepareEntityDraft(post).toJS();

  const transformPostDataToPreviewData = arr => {
    const newArr = arr.map(item => {
      switch (item.type) {
        case "image":
          return generateImageBlock(item);
        case "audio":
          return generateAudioBlock(item);
        case "video":
          return generateVideoBlock(item);
        case "aidatext":
          return item.data.text === ""
            ? { blocks: [], entityMap: {} }
            : item.data.text;
        case "imageslider":
          return generateSliderBlock(item);
        default:
          return { blocks: [], entityMap: {} };
      }
    });

    let m = 0;
    return newArr.reduce(
      (acc, o) => {
        const newMap = Object.values(o.entityMap).reduce((e, map) => {
          return { ...e, [m++]: map };
        }, acc.entityMap);

        let key = 0;
        const newBlocks = [...acc.blocks, ...o.blocks].map(block => {
          const e = block.entityRanges.map(r => {
            r.key = key++;
            return r;
          });
          block.entityRanges = e;
          return block;
        });

        return { blocks: newBlocks, entityMap: newMap };
      },
      { blocks: [], entityMap: {} }
    );
  };

  return {
    data: {
      content: postData
        ? JSON.stringify(transformPostDataToPreviewData(postData.post.data))
        : "",
      tags: currentAidaChips,
      title: post && post.data.title
    },
    chips: currentAidaChips
  };
}

export default useGetPreviewData;
