import styled from "styled-components";
import PrimaryButton from "../../../shared/components/Button/Primary";

export const StyledQuestionsWrapper = styled.div`
  overflow-y: scroll;
  margin-top: 1rem;
`;
export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 1rem;
`;
export const Divider = styled.div`
  width: 1.5rem;
  border-left: 1px solid;
`;
export const Container = styled.div`
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -3px;

  button {
    margin: 0 3px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 100%;
`;

export const StyledFormativeWrapper = styled.div`
  height: 22rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const StyledModalContent = styled.div`
  width: 350px;
  height: 150px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 1.7rem;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 1rem;
  align-self: end;
  width: 100px;
`;
