import React from "react";
import { ChevronRight } from "../../../shared/components/StudliIcons";
import { ENTITIES, OPMESSAGE } from "../../../constants";
import {
  SectionTitle,
  StyledTimeAndDateWrapper,
  StyledDateWrapper,
  StyledTimeCover,
  StyledIcon,
  StyledErrorContent,
  ErrorMsg
} from "./StyledMessageEditor";
import Checkbox from "../../../products/components/Checkbox";
import TextField from "@material-ui/core/TextField";
import { formatISOZeroTime } from "../../../date";

const toLocalTimeString = gmtDateString =>
  new Date(gmtDateString).toLocaleString("sv-se");

const MessageTimeDateSettings = ({
  type,
  meta,
  isChecked,
  onCheckBoxChange,
  onMetaDataChange,
  errors
}) => {
  const zeroTime = formatISOZeroTime();

  return (
    <>
      <SectionTitle>Visningsperiod: </SectionTitle>
      {type === ENTITIES[OPMESSAGE] && (
        <Checkbox
          label="Akut driftsmeddelande"
          checked={isChecked}
          onChange={onCheckBoxChange}
        />
      )}
      <StyledTimeAndDateWrapper>
        <StyledDateWrapper>
          {meta.startTime === zeroTime && (
            <StyledTimeCover>Publicering</StyledTimeCover>
          )}
          <TextField
            id="datetime-local"
            label="Från"
            type="datetime-local"
            variant="outlined"
            size="small"
            value={
              meta.startTime === zeroTime
                ? ""
                : toLocalTimeString(meta.startTime)
            }
            onChange={e => onMetaDataChange(e.target.value, "startTime")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </StyledDateWrapper>
        <StyledIcon>
          <ChevronRight size="18" />
        </StyledIcon>
        <StyledDateWrapper>
          {meta.endTime === zeroTime && (
            <StyledTimeCover>Ingen sluttid vald</StyledTimeCover>
          )}
          <TextField
            id="datetime-local"
            label="Till"
            type="datetime-local"
            variant="outlined"
            size="small"
            value={
              meta.endTime === zeroTime ? "" : toLocalTimeString(meta.endTime)
            }
            onChange={e => onMetaDataChange(e.target.value, "endTime")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </StyledDateWrapper>
      </StyledTimeAndDateWrapper>
      <StyledErrorContent>
        {errors["endTime"] && <ErrorMsg>{errors["endTime"]}</ErrorMsg>}
        {errors["startTime"] && <ErrorMsg>{errors["startTime"]}</ErrorMsg>}
      </StyledErrorContent>
    </>
  );
};

export default MessageTimeDateSettings;
