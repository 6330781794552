import React from "react";

const CanvasAdd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-canvasadd"
  >
    <path
      d="M5.078125,3 C5.72529297,3 6.25,3.51894102 6.25,4.15899725 L6.25,4.15899725 L6.25,6.09065934 L25,6.09 L25,12.615 L10.8173077,12.6153846 C10.1950308,12.6153846 9.68314538,13.069458 9.62159047,13.6512633 L9.61538462,13.7692308 L9.61538462,26.8461538 C9.61538462,27.4833654 10.1535457,28 10.8173077,28 L10.8173077,28 L27.6442308,28 C28.3079928,28 28.8461538,27.4833654 28.8461538,26.8461538 L28.8461538,26.8461538 L28.846,25 L31.249,25 L31.25,28.1923077 C31.25,29.413722 30.311015,30.4134832 29.1227009,30.4946762 L28.9663462,30.5 L22.1153846,30.5 L22.1153846,35.5 C22.1153846,36.1372115 22.6266376,36.6538462 23.2572115,36.6538462 L23.2572115,36.6538462 L39.2427885,36.6538462 C39.8733624,36.6538462 40.3846154,36.1372115 40.3846154,35.5 L40.3846154,35.5 L40.384,25 L46.875,25 L46.875,40.0879121 L48.828125,40.0879121 C49.475293,40.0879121 50,40.6068531 50,41.2469093 L50,41.2469093 L50,45.1102335 C50,45.7502897 49.475293,46.2692308 48.828125,46.2692308 L48.828125,46.2692308 L44.921875,46.2692308 C44.274707,46.2692308 43.75,45.7502897 43.75,45.1102335 L43.75,45.1102335 L43.75,43.1785714 L6.25,43.1785714 L6.25,45.1102335 C6.25,45.7502897 5.72529297,46.2692308 5.078125,46.2692308 L5.078125,46.2692308 L1.171875,46.2692308 C0.524707031,46.2692308 0,45.7502897 0,45.1102335 L0,45.1102335 L0,41.2469093 C0,40.6068531 0.524707031,40.0879121 1.171875,40.0879121 L1.171875,40.0879121 L3.125,40.0879121 L3.125,9.18131868 L1.171875,9.18131868 C0.524707031,9.18131868 0,8.66237766 0,8.02232143 L0,8.02232143 L0,4.15899725 C0,3.51894102 0.524707031,3 1.171875,3 L1.171875,3 Z"
      id="path-1"
    ></path>
    <path
      d="M49.09375,9.375 L40.5,9.375 L40.5,0.78125 C40.5,0.349609375 40.1503906,0 39.71875,0 L38.15625,0 C37.7246094,0 37.375,0.349609375 37.375,0.78125 L37.375,9.375 L28.78125,9.375 C28.3496094,9.375 28,9.72460938 28,10.15625 L28,11.71875 C28,12.1503906 28.3496094,12.5 28.78125,12.5 L37.375,12.5 L37.375,21.09375 C37.375,21.5253906 37.7246094,21.875 38.15625,21.875 L39.71875,21.875 C40.1503906,21.875 40.5,21.5253906 40.5,21.09375 L40.5,12.5 L49.09375,12.5 C49.5253906,12.5 49.875,12.1503906 49.875,11.71875 L49.875,10.15625 C49.875,9.72460938 49.5253906,9.375 49.09375,9.375 Z"
      id="path-3"
    ></path>
  </svg>
);

export default CanvasAdd;
