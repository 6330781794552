import React, { useState } from "react";
import { PrimaryButton } from "../../../shared/components/Button";
import styled from "styled-components";
import Modal from "../../../shared/components/Modal/Modal";
import CreateNewClassroomGroupForm from "./CreateNewClassroomgroupForm";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0;
  margin-top: 0.5rem;
`;

const StyledModalContent = styled.div`
  width: 530px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 1.7rem;
  border-radius: 7px;
  position: relative;
`;

const AddClassroomGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const _onButtonClick = () => {
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <StyledPrimaryButton onClick={_onButtonClick} studlicon="Plus">
        Ny klassrumsgrupp
      </StyledPrimaryButton>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          <CreateNewClassroomGroupForm close={closeModal} />
        </StyledModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default AddClassroomGroup;
