import React from "react";

const Sesam = ({ size, color }) => (
  <svg
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-sesam"
  >
  <path d="M13,9.5 L18,9.5 L18,7.5 L13,7.5 L13,9.5 Z M13,16.5 L18,16.5 L18,14.5 L13,14.5 L13,16.5 Z M19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 L19,3 C20.1,3 21,3.9 21,5 L21,19 C21,20.1 20.1,21 19,21 Z M6,11 L11,11 L11,6 L6,6 L6,11 Z M7,7 L10,7 L10,10 L7,10 L7,7 Z M6,18 L11,18 L11,13 L6,13 L6,18 Z M7,14 L10,14 L10,17 L7,17 L7,14 Z" id="path-1"></path>
</svg>
);

export default Sesam;
