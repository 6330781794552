import React from "react";

const Adapt = ({ size, color }) => (
  <svg
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-adapt"
  >
  <path d="M17,16 L13,12 L13,8.82 C14.16,8.4 15,7.3 15,6 C15,4.34 13.66,3 12,3 C10.34,3 9,4.34 9,6 C9,7.3 9.84,8.4 11,8.82 L11,12 L7,16 L3,16 L3,21 L8,21 L8,17.95 L12,13.75 L16,17.95 L16,21 L21,21 L21,16 L17,16 Z" id="path-1"></path>
</svg>
);

export default Adapt;
