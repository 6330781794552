import styled from "styled-components";
import Button from "../../shared/components/Button";
import { format } from "../../date";
import { RotatingArrows } from "../../shared/components/StudliIcons";

const StyledButton = styled(Button)`
  margin: 0;
  width: 145px;
  fill: ${props =>
    props.skin === "primary" ? props.theme.white : props.theme.themePrimary};
`;

const StyledButtonTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${props => (props.extraMargin ? "10px" : "0px")};
`;
const StyledDateWrapper = styled.div`
  margin-left: 4px;
  font-size: 12px;
  margin-bottom: 4px;
  text-align: center;
  &.published-not-latest {
    color: #b00020;
  }
  color: #333333;
`;

const formatDate = dateString => {
  const date = new Date(dateString);
  return format(date);
};

const PublishButton = ({
  toLive = false,
  loading = false,
  onClick,
  disabled,
  publishtime,
  isLatest = true,
  ...rest
}) => {
  const _onClick = e => onClick(e);

  const btnSkin = toLive ? "primary" : "DM";
  const btnText = toLive ? "Publicera Live" : "Publicera till Test";
  const classname = isLatest ? "published-islatest" : "published-not-latest";

  return (
    <StyledButtonTimeWrapper extraMargin={disabled}>
      {!toLive && disabled && <span>Senast publicerad till test: </span>}
      {!toLive && disabled && !publishtime && (
        <StyledDateWrapper>Inget publicerat ännu</StyledDateWrapper>
      )}
      {publishtime && (
        <StyledDateWrapper className={classname}>
          {formatDate(publishtime)}
        </StyledDateWrapper>
      )}
      {!disabled && (
        <StyledButton
          onClick={_onClick}
          type="button"
          skin={btnSkin}
          {...rest}
          outline={!toLive}
        >
          {!loading && btnText}
          {loading && <RotatingArrows size="24" />}
        </StyledButton>
      )}
    </StyledButtonTimeWrapper>
  );
};

export default PublishButton;
