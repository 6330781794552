export const SET_MEDIA_CAROUSEL = "SET_MEDIA_CAROUSEL";
export const NEXT_IN_MEDIA_CAROUSEL = "NEXT_IN_MEDIA_CAROUSEL";
export const PREV_IN_MEDIA_CAROUSEL = "PREV_IN_MEDIA_CAROUSEL";

export const nextItem = () => dispatch =>
  dispatch({
    type: NEXT_IN_MEDIA_CAROUSEL
  });

export const prevItem = () => dispatch =>
  dispatch({
    type: PREV_IN_MEDIA_CAROUSEL
  });

export const setItems = items => dispatch =>
  dispatch({
    type: SET_MEDIA_CAROUSEL,
    items,
    active: items[0] || null
  });
