import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GroupSettings from "../../components/GroupSettings";
import GroupProducts from "../../components/GroupProducts";
import PublishButton from "../../../products/components/PublishButton";
import PreviewButton from "../../../products/components/PreviewButton";

import {
  createValidation,
  ErrorFormatter
} from "../../../products/validation/product";
import {
  ClassroomgroupDetails,
  classroomgroupDetailsErrors,
  checkIfGroupExists,
  classroomgroupErrors
} from "../../components/AddClassroomGroup/validation";

const Container = styled.div`
  display: flex;
`;

const Part = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 1.75rem;
  }

  &:last-child {
    margin-left: 1.75rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 100%;
`;

export const ErrorMsg = styled.span`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px;

  button {
    margin: 0 3px;
  }
`;

const classroomgroupDetailsValidation = createValidation(
  ClassroomgroupDetails,
  ErrorFormatter(classroomgroupErrors)
);

const initValues = {
  title: "",
  colour: "#ffffff",
  lightMode: 0,
  typeId: 1,
  edition: 1
};

const ClassroomGroupForm = ({
  values,
  entityId,
  updateDetails,
  addProduct,
  removeProduct,
  publishClassroomGroup,
  classroomGroups,
  disabled
}) => {
  const [formValues, setFormValues] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [hasChanges, setHasChanges] = useState([]);

  useEffect(() => {
    if (values && values.title) {
      const { title, colour, lightMode, typeId, edition } = values;
      setFormValues({
        title: title,
        colour: colour,
        lightMode: lightMode,
        typeId: typeId,
        edition
      });
    }
  }, [values]);

  const _onChange = (val, name) => {
    const { err } = classroomgroupDetailsValidation({
      ...formValues,
      [name]: val
    });
    setErrors(err || {});

    if (name === "title") {
      const found = checkIfGroupExists(
        classroomGroups,
        val,
        formValues.edition
      );

      if (found && val !== values[name]) {
        setErrors({ title: classroomgroupDetailsErrors.title() });
      }

      if (!val) {
        setErrors({
          title: "Måste innehålla minst ett tillåtet tecken."
        });
      }
    }
    if (name === "edition") {
      const found = checkIfGroupExists(classroomGroups, formValues.title, val);

      if (found) {
        setErrors({
          edition: `${formValues.title} har redan en upplaga ${val}`
        });
      }
    }

    setFormValues({
      ...formValues,
      [name]: val
    });

    if (values[name] === val) {
      setHasChanges(hasChanges.filter(n => n !== name));
      return;
    }

    setHasChanges([...hasChanges, name]);
  };

  const onSelectChange = (val, name) => {
    setFormValues({
      ...formValues,
      [name]: val
    });
    updateDetails({ ...values, [name]: val });
  };

  const save = () => {
    if (Object.keys(errors).length > 0 && !errors["save"]) {
      return;
    }
    updateDetails({ ...values, ...formValues });
    setHasChanges([]);
    setErrors({});
  };

  const publish = () => {
    if (hasChanges.length > 0) {
      setErrors({
        save: "Ändringar måste sparas innan det går att publicera."
      });
      return;
    }
    publishClassroomGroup(entityId);
  };

  const onBlur = () => {
    if (hasChanges.length > 0) {
      save();
    }
  };

  const gotoPreview = () => {
    window.open(values.previewURL);
  };

  return (
    <>
      <Container>
        <Part>
          <GroupSettings
            onChange={_onChange}
            values={formValues}
            errors={errors}
            onBlur={onBlur}
            onSelectChange={onSelectChange}
            setErrors={setErrors}
          />
        </Part>
        <Part>
          <GroupProducts
            classroomGroup={values}
            addProduct={addProduct}
            removeProduct={removeProduct}
          />
        </Part>
      </Container>

      <Container>
        <ButtonWrapper>
          <ButtonGroup>
            <PublishButton
              toLive={false}
              disabled
              publishtime={values.publishedPreviewTime}
              isLatest
            />
            <PreviewButton onClickPreview={gotoPreview} />
          </ButtonGroup>
          <ButtonGroup>
            <PublishButton
              toLive={true}
              onClick={publish}
              disabled={disabled}
              publishtime={values.publishedProdTime}
              isLatest={values.publishedProdTime >= values.publishedPreviewTime}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Container>
    </>
  );
};

export default ClassroomGroupForm;
