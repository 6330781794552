import { Column } from "react-virtualized";
import EditionRenderer from "../Renderer/EditionRenderer";

const EditionColumn = () => null;

EditionColumn.propTypes = Object.assign({}, Column.propTypes);
EditionColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: EditionRenderer,
  draggable: true
});

export default EditionColumn;
