import React from "react";
import Chip from "../Chip";
import styled from "styled-components";
import useFindPathsForChips from "./useFindPathsForChips";

const StyledWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0.5rem;
`;

const StyledTagGroup = styled.li`
  padding: 2px 0;
`;
const StyledTitle = styled.div`
  padding: 0.3rem 0;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralDark}
`;

const ChipsViewer = ({ selectedChips, chipsTree, deleteChipsCallback }) => {
  const { groups } = useFindPathsForChips(chipsTree, selectedChips);
  return (
    <StyledWrapper>
      {groups &&
        groups.map((g, index) => {
          return (
            <StyledTagGroup key={index}>
              <StyledTitle>{g.path.join(" / ")}</StyledTitle>
              <div>
                {g.tags.map(t => (
                  <Chip
                    key={t.id}
                    id={t.id}
                    label={t.title}
                    isDeletable
                    deleteCallback={deleteChipsCallback}
                    labelColor="white"
                  />
                ))}
              </div>
            </StyledTagGroup>
          );
        })}
      {(!groups || groups.length === 0) && <span>Inget valt</span>}
    </StyledWrapper>
  );
};

export default ChipsViewer;
