import { isBefore, startOfYesterday } from "date-fns";
import { createSelector } from "reselect";
import {
  ROUTE_DETAILED_EXERCISE,
  ROUTE_DETAILED_RESOURCE,
  ROUTE_DETAILED_HELP_RESOURCE,
  ROUTE_DETAILED_AIDA
} from "../routes";

import { PUBLISH_POSTS } from "./actions";
import { PREVIEW_URL } from "../api/constants";
import { RESOURCE, EXERCISE, HELP_RESOURCE, AIDA } from "../../constants";
import { selectEntityId } from "../../store/selectors";
import { distanceInWordsToNow, format } from "../../date";
// import {ENTITIES} from "../../constants";

const formatDate = dateString => {
  const date = new Date(dateString);

  if (isBefore(date, startOfYesterday())) {
    return format(date);
  }
  return distanceInWordsToNow(date);
};

const populateTags = (posttags, labels = [], tags = []) => {
  if (labels.length === 0 && tags.length === 0) return posttags;
  if (!posttags) return posttags;

  let taglist = (tags || []).reduce((res, group) => res.concat(group.tags), []);
  taglist = [].concat(taglist, labels);

  const popTags = posttags.reduce((acc, posttag) => {
    let tagdata = {};
    for (let tag of taglist) {
      if (tag.id === posttag) {
        tagdata = tag;
        break;
      }
    }

    return tagdata.id ? [...acc, tagdata] : [...acc];
  }, []);

  return popTags;
};

export const getCurrentPostPage = (state, type = "Exercise") => {
  if (!state[type]) {
    debugger;
  }
  const list = state[type].get("list");
  const labels = state[type].get("labels").toArray();
  const tags = state[type].get("tags");
  const entityId = selectEntityId(state);
  const chipsList = selectFlatChipList(state, type);

  const detailedRoute = {
    [EXERCISE.toLowerCase()]: ROUTE_DETAILED_EXERCISE,
    [RESOURCE.toLowerCase()]: ROUTE_DETAILED_RESOURCE,
    [HELP_RESOURCE.toLowerCase()]: ROUTE_DETAILED_HELP_RESOURCE,
    [AIDA.toLowerCase()]: ROUTE_DETAILED_AIDA
  }[type.toLowerCase()];
  return state[type].get("fetchingFilters") &&
    state[type].get("fetching") &&
    list.size === 0
    ? new Array(50).fill(true).map((_, index) => ({ id: index }))
    : list
        .map(row => ({
          id: row.id,
          title: row.title,
          type: row.type,
          entity_id: state.Product.get("selectedItem")
            ? state.Product.get("selectedItem").id
            : null,
          lock: row.lock,
          tags: populateTags(row.tags, labels, tags),
          chips:
            chipsList && row.chips
              ? row.chips.map(c => chipsList.find(i => c === i.id))
              : [],
          status: row.status !== undefined ? row.status : 0,
          category: row.category_title,
          updated_at: formatDate(row.updated_at),
          usage_count: row.usage_count || 0,
          invalid_data: row.invalid_data,
          actions: {
            editLink: {
              type: detailedRoute,
              postType: type,
              payload: {
                entityId: entityId,
                id: row.id
              }
            },
            lock: row.lock,
            previewPost: {
              path: PREVIEW_URL + row.id,
              id: row.id
            },
            deletePost: {
              entityId: entityId,
              id: row.id
            },
            publishPost: {
              type: PUBLISH_POSTS,
              payload: {
                entityId: entityId,
                id: row.id
              }
            }
          }
        }))
        .toArray();
};

export const isCurrentPostPageLoading = (state, type = "Exercise") => {
  return state[type].get("fetching") || state[type].get("fetchingFilters");
};

export const getLabelData = (state, type = "Exercise") => {
  return state[type].get("labels");
};

export const getSearchParameters = (state, type = "Exercise") => {
  return state[type].get("searchParameters");
};

export const selectFlatChipList = createSelector(
  (state, type) => state[type].get("chips") || [],
  chips => findChipsInTree(chips)
);

const findChipsInTree = tree => {
  return tree.reduce(
    (arr, node) => [
      ...arr,
      ...node.chips,
      ...findChipsInTree(node.sub_sections)
    ],
    []
  );
};
