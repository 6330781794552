/**
 * Selects Post draft from the state
 * @param state
 * @param entityType
 */
export const selectEntityDraft = (state, entityType) =>
  state[entityType].get("postDraft");

export const selectInitialDraft = (state, entityType) =>
  state[entityType].get("initialDraft");
