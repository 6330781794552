import { all, fork, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ACTION_FETCH_PRODUCTLIST,
  SORT_PRODUCTLIST,
  EXPORT_PRODUCTLIST,
  ACTION_SET_FETCH_LIMIT
} from "../api/actions";
import {
  previousPageSaga,
  nextPageSaga,
  loadPageSaga,
  onFetchProductList,
  exportProductListSaga,
  sortProductListSaga,
  onProductListRoute,
  onSetFetchLimit
} from "./sagas";
import { ENTITIES, PRODUCTLIST } from "../../constants";
import { actionCreator } from "../../shared";
import {
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE
} from "../../posts/store/actions";
import { ROUTE_PRODUCTS } from "../../products/routes";

function* watchFetchProductList() {
  yield takeLatest(ACTION_FETCH_PRODUCTLIST, onFetchProductList);
}

function* watchLoadLessProducts() {
  yield takeEvery(
    actionCreator(PRODUCTLIST, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[PRODUCTLIST],
    "listProducts"
  );
}

function* watchLoadMoreProducts() {
  yield takeEvery(
    actionCreator(PRODUCTLIST, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[PRODUCTLIST],
    "listProducts"
  );
}
function* watchLoadPageProducts() {
  yield takeEvery(
    actionCreator(PRODUCTLIST, LOAD_POST_PAGE),
    loadPageSaga,
    PRODUCTLIST,
    "listProducts"
  );
}
function* watchSortProductList() {
  yield takeEvery(SORT_PRODUCTLIST, sortProductListSaga);
}
function* watchExportProductList() {
  yield takeEvery(EXPORT_PRODUCTLIST, exportProductListSaga);
}

function* watchProductListRoute() {
  yield takeLatest(ROUTE_PRODUCTS, onProductListRoute);
}

function* watchSetFetchLimit() {
  yield takeLatest(ACTION_SET_FETCH_LIMIT, onSetFetchLimit);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadLessProducts),
    fork(watchLoadMoreProducts),
    fork(watchLoadPageProducts),
    fork(watchFetchProductList),
    fork(watchSortProductList),
    fork(watchExportProductList),
    fork(watchProductListRoute),
    fork(watchSetFetchLimit)
  ]);
}
