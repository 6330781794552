import React from "react";

const CommentAdd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-commentadd"
  >
    <path d="M43.75,0 L6.25,0 C2.80273438,0 0,2.80273438 0,6.25 L0,34.375 C0,37.8222656 2.80273438,40.625 6.25,40.625 L15.625,40.625 L15.625,48.828125 C15.625,49.5214844 16.1914062,50 16.796875,50 C17.03125,50 17.2753906,49.9316406 17.4902344,49.765625 L29.6875,40.625 L43.75,40.625 C47.1972656,40.625 50,37.8222656 50,34.375 L50,6.25 C50,2.80273438 47.1972656,0 43.75,0 Z M46.875,34.375 C46.875,36.09375 45.46875,37.5 43.75,37.5 L28.6425781,37.5 L27.8125,38.125 L18.75,44.921875 L18.75,37.5 L6.25,37.5 C4.53125,37.5 3.125,36.09375 3.125,34.375 L3.125,6.25 C3.125,4.53125 4.53125,3.125 6.25,3.125 L43.75,3.125 C45.46875,3.125 46.875,4.53125 46.875,6.25 L46.875,34.375 Z M33.59375,18.75 L26.5625,18.75 L26.5625,11.71875 C26.5625,11.2890625 26.2109375,10.9375 25.78125,10.9375 L24.21875,10.9375 C23.7890625,10.9375 23.4375,11.2890625 23.4375,11.71875 L23.4375,18.75 L16.40625,18.75 C15.9765625,18.75 15.625,19.1015625 15.625,19.53125 L15.625,21.09375 C15.625,21.5234375 15.9765625,21.875 16.40625,21.875 L23.4375,21.875 L23.4375,28.90625 C23.4375,29.3359375 23.7890625,29.6875 24.21875,29.6875 L25.78125,29.6875 C26.2109375,29.6875 26.5625,29.3359375 26.5625,28.90625 L26.5625,21.875 L33.59375,21.875 C34.0234375,21.875 34.375,21.5234375 34.375,21.09375 L34.375,19.53125 C34.375,19.1015625 34.0234375,18.75 33.59375,18.75 Z" />
  </svg>
);

export default CommentAdd;
