import { default as AidaText } from "./components/AidaText";
import { AIDATEXT } from "../constants";

const data = {
  text: ""
};

const settings = {
  readOnly: false,
  multiLine: true,
  minimizeSpace: false,
  useToolbar: true
};

export const createAidaTextPlugin = () => ({
  entityRendererFn: entity => {
    const types = [AIDATEXT];
    if (types.includes(entity.type)) {
      return {
        component: AidaText,
        style: { padding: 0 },
        props: {
          data: entity.data,
          settings: { ...settings, ...entity.settings }
        }
      };
    }

    return null;
  }
});

export default createAidaTextPlugin;

export const emptyBlocks = {
  aidatext: {
    type: AIDATEXT,
    data,
    settings
  }
};
