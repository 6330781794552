import {
  green,
  lightGray,
  darkBlue,
  darkGray,
  darkOrange,
  black,
  orange,
  blue
} from "../../styles/shared/colors"

import {
  PUBLISHED,
  MODIFIED,
  DRAFT,
  DRAFT_READY_FOR_REVIEW,
  REVISION_READY_FOR_REVIEW,
  DRAFT_REVIEWED,
  REVISION_REVIEWED,
  UNPUBLISHED,
  DRAFT_PREVIEW_PUBLISHED,
  REVISION_PREVIEW_PUBLISHED,
} from "../../constants";

export const statusColor = {
  [PUBLISHED]: green,
  [MODIFIED]: darkGray,
  [DRAFT]: lightGray,
  [DRAFT_READY_FOR_REVIEW]: orange,
  [REVISION_READY_FOR_REVIEW]: darkOrange,
  [DRAFT_PREVIEW_PUBLISHED]: darkOrange,
  [REVISION_PREVIEW_PUBLISHED]: darkOrange,
  [DRAFT_REVIEWED]: blue,
  [REVISION_REVIEWED]: darkBlue,
  [UNPUBLISHED]: black
};
