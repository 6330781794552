import styled from "styled-components";
import { PrimaryButton } from "../../../shared";

export const maxDimensions = {
  width: 500,
  height: 385
};

export const StyledChart = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledChartContent = styled.div`
  margin: 100px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledChartContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`;

export const StyledGridContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`;

export const StyledChartSettings = styled.div`
  align-self: center;
  margin-top: 50px;
`;

export const StyledSettingsRow = styled.div`
  justify-content: center;
  display: flex;
`;

export const StyledText = styled.div`
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  input {
    border: ${props =>
      props.hightlight ? "2px solid red" : "thin solid #999"};
    outline: none;
    flex: 1;
    margin: 0 10px;
    box-shadow: none;
    width: 40px;
  }
  height: 42px;
`;

export const StyledIdContainer = styled.div`
  display: flex;
  align-self: center;
  border: thin solid #999;
`;

export const StyledIdLabel = styled.div`
  font-size: 12px;
  width: 100%;
  align-self: center;
  margin: 0 5px;
`;

export const StyledRemoveButton = styled(props => <PrimaryButton {...props} />)`
  min-width: fit-content;
`;
