import React from "react";
import styled from "styled-components";
import Card from "../Card";
import BiafMediaHandler from "./BiafMediaHandler";

const Part = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex: 1;
	flex-direction: column;
`;

const BiafContentCard = () => {
	return (
		<Card title="Innehåll">
			<Part>
				<BiafMediaHandler />
			</Part>
		</Card>
	);
};

export default BiafContentCard;
