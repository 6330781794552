import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { PrimaryButton, StandardButton } from "../../../shared";

import { uploadMedia } from "../../api/requests";
import { MediaButton } from "../../containers/MediaToolbar/StyledMediaToolbar";
import ProgressBar from "../../../shared/components/ProgressBar/ProgressBar";
import {
  StyledDropZoneButtonsWrapper,
  StyledUploadContainer,
  StyledHeaderTitle,
  StyledUploadDescriptions,
  StyledLabels,
  StyledMetaData,
  StyledDropzoneActions,
  StyledDropZoneWrapper,
  StyledProgressBarContainer,
  StyledInvalidFilesContainer,
  StyledTitle,
  StyledButtonContainer,
  StyledInvalidLabel,
  StyledInvalidRowWrapper,
  StyledTitlesWrapper
} from "./StyledMediaDropZone";
import { translationErrorMessage } from "../../../constants";
import { isJsonString } from "../../../shared/helpers";

class MediaDropZone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: {},
      failed: [],
      uploaded: [],
      fileTypeCount: {},
      jsonName: "",
      isUploading: false,
      progress: 0
    };
  }

  /**
   * Callback for selecting/dropping files from file browser.
   */
  onDrop = files => {
    if (files.length > 1) {
      const jsonFilesArray = files.filter(
        file => file.type === "application/json"
      );

      if (jsonFilesArray.length > 1) {
        this.props.showFlash(
          "Ett oväntat fel har uppstått: Mer än 1 JSON fil förekommer.",
          "error"
        );
        return;
      }

      if (jsonFilesArray.length < 1) {
        this.props.showFlash(
          "Ett oväntat fel har uppstått: JSON fil saknas",
          "error"
        );
        return;
      }

      this.verifyJSONFile(jsonFilesArray[0], files);
    } else {
      this.uploadSingleMedia(files);
    }
  };

  /**
   * Upload single media,
   * onSuccess: openns Media overview
   * onError: Shows flash with error message
   */
  uploadSingleMedia = file => {
    uploadMedia({
      files: file,
      entityId: this.props.entityId,
      entityType: this.props.entityType,
      basePath: this.props.basePath
    }).then(res => {
      if (this.props.onUploadMedia && res && res.data) {
        this.props.onUploadMedia(res.data.data);
      } else {
        const { response } = res;
        this.props.showFlash(
          "Ett oväntat fel har uppstått: " +
            (translationErrorMessage[response.data.message]
              ? translationErrorMessage[response.data.message]
              : response.data.message),
          "error"
        );
      }
    });
  };

  /**
   * Returns mediafiles with uploaded json data
   */
  mapMediaFiles = (mediaFiles, json) =>
    mediaFiles.map(file => ({
      mediaFile: file,
      json: json.files.find(item => item.filename === file.name)
    }));

  /**
   * Verifies the uploaded JSON file.
   * For validation:
   * 1: Uploaded files length must match files length from JSON file.
   * 2: Uploaded files must match files from JSON file.
   *
   * onError: show flash with error message.
   */
  verifyJSONFile = (jsonFile, files) => {
    const mediaFiles = files.filter(file => file.type !== "application/json");
    this.readUploadedFileAsText(jsonFile)
      .then(result => {
        const matchingCount = result.files.length === mediaFiles.length;

        if (matchingCount) {
          const matchingFiles = mediaFiles.every(file =>
            result.files.some(el => el.filename === file.name)
          );

          matchingFiles
            ? this.setState({
                // Triggers uploading
                files: this.mapMediaFiles(mediaFiles, result),
                isUploading: true,
                jsonName: jsonFile.name,
                fileTypeCount: this.extractFiletypeCount(mediaFiles)
              })
            : this.props.showFlash(
                "Ett oväntat fel har uppstått: Filer i JSON filen matchar inte uppladdade filer.",
                "error"
              );
        } else {
          this.props.showFlash(
            "Ett oväntat fel har uppstått: Antalet filer i JSON filen matchar inte antalet uppladdade filer.",
            "error"
          );
        }
      })
      .catch(e => {
        this.props.showFlash(e, "error");
      });
  };

  /**
   * Returns the count for each file type.
   */
  extractFiletypeCount = files => {
    const fileTypes = files.map(file => file.type);
    let object = { image: 0, audio: 0, video: 0, other: 0 };

    fileTypes.forEach(file => {
      if (file.indexOf("image") !== -1) {
        object = { ...object, image: object.image + 1 };
      } else if (file.indexOf("audio") !== -1) {
        object = { ...object, audio: object.audio + 1 };
      } else if (file.indexOf("video") !== -1) {
        object = { ...object, video: object.video + 1 };
      } else {
        object = { ...object, other: object.other + 1 };
      }
    });
    return {
      ...object,
      total_files: object.image + object.audio + object.video + object.other
    };
  };

  /**
   * Reads and parses the JSON file.
   */
  readUploadedFileAsText = jsonFile => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = event => {
        isJsonString(event.target.result)
          ? resolve(JSON.parse(event.target.result))
          : reject(
              "Ett oväntat fel har uppstått: Det gick inte att läsa JSON filen, kontrollera filen."
            );
      };
      reader.readAsText(jsonFile);
    });
  };

  /**
   * Renders if drag is active.
   */
  renderIsDragActive = isDragActive =>
    isDragActive ? (
      <p>Drop files here...</p>
    ) : (
      <div style={{ display: "flex" }}>
        <h5 style={{ margin: "0px 10px", color: "grey" }}>
          Släpp filerna här eller{" "}
        </h5>
        <PrimaryButton> Bläddra </PrimaryButton>
      </div>
    );

  /**
   * Renders Dropzone actions
   */
  renderDropZoneActions = (
    isGridView,
    isDragActive,
    getRootProps,
    getInputProps
  ) => (
    <StyledDropzoneActions
      isGridView={isGridView}
      {...getRootProps()}
      className={classNames("dropzone", { "dropzone--isActive": isDragActive })}
    >
      <input {...getInputProps()} />
      {this.renderIsDragActive(isDragActive)}
    </StyledDropzoneActions>
  );

  /**
   * Renders Dropzone buttons
   */
  renderDropzoneButtons = isGridView => (
    <StyledDropZoneButtonsWrapper>
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) =>
          this.renderDropZoneActions(
            isGridView,
            isDragActive,
            getRootProps,
            getInputProps
          )
        }
      </Dropzone>
      <MediaButton outline onClick={this.props.onShowUploadMedia.bind()}>
        {" "}
        {"Avbryt"}{" "}
      </MediaButton>
    </StyledDropZoneButtonsWrapper>
  );

  /**
   * Renders progress bar for uploading media.
   */
  renderProgressBar = percentage => (
    <StyledProgressBarContainer>
      <StyledTitle>
        {percentage === 100 ? "Klart" : "Laddar upp filer..."}
      </StyledTitle>
      <ProgressBar percentage={percentage} />
    </StyledProgressBarContainer>
  );

  /**
   * Renders meta data for uploading media.
   */
  renderMetaData = fileTypeCount => (
    <StyledMetaData>
      <StyledLabels>{`Bilder: ${fileTypeCount.image}`}</StyledLabels>
      <StyledLabels>{`Filmer: ${fileTypeCount.video}`}</StyledLabels>
      <StyledLabels>{`Ljud: ${fileTypeCount.audio}`}</StyledLabels>
    </StyledMetaData>
  );

  /**
   * If some files are invalid, a compilation of all invalid files with error message, is rendered.
   */
  renderInvalidFiles = failed => (
    <StyledInvalidFilesContainer>
      <StyledTitlesWrapper>
        <StyledTitle>Undantagna filer:</StyledTitle>
        <StyledTitle>Orsak:</StyledTitle>
      </StyledTitlesWrapper>
      {failed.map(file =>
        this.renderInvalidFileRow(file.filename, file.error, file.key)
      )}
    </StyledInvalidFilesContainer>
  );

  /**
   * Renders a single file with error message.
   */
  renderInvalidFileRow = (filename, error, key) => (
    <StyledInvalidRowWrapper key={key}>
      <StyledInvalidLabel>{filename}</StyledInvalidLabel>
      <StyledInvalidLabel>
        {translationErrorMessage[error]
          ? translationErrorMessage[error]
          : error}
      </StyledInvalidLabel>
    </StyledInvalidRowWrapper>
  );

  /**
   * If state variable isUploading === true, Uploading starts.
   * Each file in the files list gets uploaded one by one.
   * For each callback, the information received gets stored and displayed for the user.
   */
  renderUploading = () => {
    const { files, fileTypeCount } = this.state;
    let count = 0;
    let failed = [];
    let uploaded = [];
    Array.isArray(files) &&
      files.forEach(file => {
        uploadMedia({
          files: [file.mediaFile],
          json: file.json,
          entityId: this.props.entityId,
          entityType: this.props.entityType,
          basePath: this.props.basePath
        }).then(res => {
          count = count + 1;
          const progress = (count / files.length) * 100;

          if (res && res.data) {
            uploaded.push(res.data.data[0]);
          } else {
            const { response } = res;
            failed.push({
              filename: file.mediaFile.name,
              error: response.data.message,
              key: count
            });
          }
          this.setState({
            progress: progress,
            files: {},
            failed: failed,
            uploaded: uploaded
          });
        });
      });

    return (
      <StyledUploadContainer>
        <StyledHeaderTitle>
          {"Uppladdning av filer med fältdatafil"}
        </StyledHeaderTitle>
        <StyledUploadDescriptions>
          <StyledLabels>Fältdata fil: {this.state.jsonName}</StyledLabels>
          <StyledLabels>{`Antal filer att ladda upp: ${fileTypeCount.total_files}`}</StyledLabels>
          {this.renderMetaData(fileTypeCount)}
          {this.renderProgressBar(this.state.progress)}
          {this.state.progress === 100 &&
            this.state.failed.length !== 0 &&
            this.renderInvalidFiles(this.state.failed)}
          {this.state.progress === 100 && this.renderButtons()}
        </StyledUploadDescriptions>
      </StyledUploadContainer>
    );
  };

  /**
   * An Array of information about the invalid files is compiled and
   * added to a dummy textarea. The content in the dummy gets copied and then removed.
   */
  copyToClipboard = array => () => {
    let output = "";

    array.forEach(row => {
      output = output + `\n${row.key} : ${row.filename} - ${row.error}`;
    });

    const el = document.createElement("textarea");
    el.value = output;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  /**
   * Closes current view and setting uploaded media to media carousel.
   */
  closeUploadingMedia = () => {
    if (this.props.onUploadMedia) {
      this.props.onUploadMedia(this.state.uploaded);
    }
  };

  /**
   * Renders copy & show uploaded media buttons.
   */
  renderButtons = () => (
    <StyledButtonContainer>
      {this.state.failed.length !== 0 && this.renderCopyListButton()}
      {this.renderShowUploadButton()}
    </StyledButtonContainer>
  );

  /**
   * Callback for copy to clipboard invalid files.
   */
  renderCopyListButton = () => (
    <StandardButton onClick={this.copyToClipboard(this.state.failed)}>
      Kopiera lista
    </StandardButton>
  );

  /**
   * Callback closing current view.
   */
  renderShowUploadButton = () => (
    <PrimaryButton onClick={this.closeUploadingMedia}>
      Visa uppladdade
    </PrimaryButton>
  );

  render() {
    const { isGridView } = this.props;
    return (
      <StyledDropZoneWrapper isGridView={isGridView}>
        {this.state.isUploading
          ? this.renderUploading()
          : this.renderDropzoneButtons(isGridView)}
      </StyledDropZoneWrapper>
    );
  }
}

export default MediaDropZone;
