import React from "react";

const SquarePlus = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-squareplus"
  >
    <path d="M44.6428571,3.57142857 C45.625,3.57142857 46.4285714,4.375 46.4285714,5.35714286 L46.4285714,44.6428571 C46.4285714,45.625 45.625,46.4285714 44.6428571,46.4285714 L5.35714286,46.4285714 C4.375,46.4285714 3.57142857,45.625 3.57142857,44.6428571 L3.57142857,5.35714286 C3.57142857,4.375 4.375,3.57142857 5.35714286,3.57142857 L44.6428571,3.57142857 Z M44.6428571,0 L5.35714286,0 C2.39955357,0 0,2.39955357 0,5.35714286 L0,44.6428571 C0,47.6004464 2.39955357,50 5.35714286,50 L44.6428571,50 C47.6004464,50 50,47.6004464 50,44.6428571 L50,5.35714286 C50,2.39955357 47.6004464,0 44.6428571,0 Z M37.9464286,27.0089286 C38.6830357,27.0089286 39.2857143,26.40625 39.2857143,25.6696429 L39.2857143,24.3303571 C39.2857143,23.59375 38.6830357,22.9910714 37.9464286,22.9910714 L12.0535714,22.9910714 C11.3169643,22.9910714 10.7142857,23.59375 10.7142857,24.3303571 L10.7142857,25.6696429 C10.7142857,26.40625 11.3169643,27.0089286 12.0535714,27.0089286 L37.9464286,27.0089286 Z M22.9910714,37.9464286 C22.9910714,38.6830357 23.59375,39.2857143 24.3303571,39.2857143 L25.6696429,39.2857143 C26.40625,39.2857143 27.0089286,38.6830357 27.0089286,37.9464286 L27.0089286,12.0535714 C27.0089286,11.3169643 26.40625,10.7142857 25.6696429,10.7142857 L24.3303571,10.7142857 C23.59375,10.7142857 22.9910714,11.3169643 22.9910714,12.0535714 L22.9910714,37.9464286 Z" />
  </svg>
);

export default SquarePlus;
