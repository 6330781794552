import React from "react";

const MediaAdd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-mediaadd"
  >
    <path
      d="M25,6 L25,9.125 L4.6875,9.125 C3.87383898,9.125 3.2033818,9.75025379 3.13139786,10.545492 L3.125,10.6875 L3.125,38.8125 C3.125,39.6740234 3.82597656,40.375 4.6875,40.375 L4.6875,40.375 L45.3125,40.375 C46.1740234,40.375 46.875,39.6740234 46.875,38.8125 L46.875,38.8125 L46.875,25 L50,25 L50,38.8125 C50,41.4013672 47.9013672,43.5 45.3125,43.5 L45.3125,43.5 L4.6875,43.5 C2.09863281,43.5 0,41.4013672 0,38.8125 L0,38.8125 L0,10.6875 C0,8.09863281 2.09863281,6 4.6875,6 L4.6875,6 L25,6 Z M15.5302734,23.0927734 C16.4455078,22.1774414 17.9294922,22.1774414 18.8448242,23.0927734 L18.8448242,23.0927734 L21.875,26.1229492 L25,22.997 L25,25 L27.416,25 L21.875,30.5423828 L17.1875,25.8548828 L9.375,33.6673828 L9.375,34.125 L40.625,34.125 L40.625,27.4173828 L38.208,25 L42.627,25 L43.0635742,25.4364258 C43.459082,25.8320215 43.6986807,26.3544961 43.7426453,26.9081684 L43.75,27.09375 L43.75,36.078125 C43.75,36.725293 43.225293,37.25 42.578125,37.25 L42.578125,37.25 L7.421875,37.25 C6.77470703,37.25 6.25,36.725293 6.25,36.078125 L6.25,36.078125 L6.25,33.34375 C6.25,32.7221557 6.49696017,32.1260272 6.93652344,31.6865234 L6.93652344,31.6865234 Z M10.9375,11.46875 C13.9578125,11.46875 16.40625,13.9171875 16.40625,16.9375 C16.40625,19.9578125 13.9578125,22.40625 10.9375,22.40625 C7.9171875,22.40625 5.46875,19.9578125 5.46875,16.9375 C5.46875,13.9171875 7.9171875,11.46875 10.9375,11.46875 Z M10.9375,14.59375 C9.64511719,14.59375 8.59375,15.6451172 8.59375,16.9375 C8.59375,18.2298828 9.64511719,19.28125 10.9375,19.28125 C12.2298828,19.28125 13.28125,18.2298828 13.28125,16.9375 C13.28125,15.6451172 12.2298828,14.59375 10.9375,14.59375 Z"
      id="path-1"
    ></path>
    <path
      d="M49.09375,9.375 L40.5,9.375 L40.5,0.78125 C40.5,0.349609375 40.1503906,0 39.71875,0 L38.15625,0 C37.7246094,0 37.375,0.349609375 37.375,0.78125 L37.375,9.375 L28.78125,9.375 C28.3496094,9.375 28,9.72460938 28,10.15625 L28,11.71875 C28,12.1503906 28.3496094,12.5 28.78125,12.5 L37.375,12.5 L37.375,21.09375 C37.375,21.5253906 37.7246094,21.875 38.15625,21.875 L39.71875,21.875 C40.1503906,21.875 40.5,21.5253906 40.5,21.09375 L40.5,12.5 L49.09375,12.5 C49.5253906,12.5 49.875,12.1503906 49.875,11.71875 L49.875,10.15625 C49.875,9.72460938 49.5253906,9.375 49.09375,9.375 Z"
      id="path-3"
    ></path>
  </svg>
);

export default MediaAdd;
