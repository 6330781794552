import createMessageReducer from "../api/reducer";

export const productNewsReducer = createMessageReducer("ProductNews");
export const opMessageReducer = createMessageReducer("OpMessage");
export const marketMessageReducer = createMessageReducer("MarketMessage");

export const productNews = productNewsReducer({
  args: {}
});

export const opMessage = opMessageReducer({
  args: {}
});

export const marketMessage = marketMessageReducer({
  args: {}
});
