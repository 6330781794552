import styled from "styled-components";

export const StyledRadioBtnRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledRadioBtnRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const StyledRadioBtn = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  background: ${props => (props.isActive ? "#0078d7" : "#cecece")};
  color: ${props => (props.isActive ? "#fff" : "#333")};
`;

export const StyledRadioBtnRowLabel = styled.span`
  text-transform: capitalize;
  font-size: 0.9em;
`;
