import React from "react";
import styled, { css } from "styled-components";
import Link from "../Link";

const MenuButtonStyles = css`
  color: #4a4a4a;
  fill: #4a4a4a;
  align-self: center;
  flex: 1;
  display: flex;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  padding: ${props => props.theme.baselineNumber}px
    ${props => props.theme.baselineNumber * 2}px;
  height: 38px;

  & > span {
    transition: width 200ms;
  }

  & > span > svg {
    height: 18px;
  }

  ${props =>
    props.collapsed
      ? `
      & > span {
        width: 32px;
        margin: 0;
      }

      & > span > svg {
        margin: auto;
      }
    `
      : null}
  
  &.${props => props.activeClassName} {
    color: #000;
    fill: #000;
    background: rgba(80, 153, 252, 0.2);
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;
const StyledNavButton = styled.div`
  ${MenuButtonStyles}
  width: 100%;
  cursor: pointer;
  background-color: #ffffff;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
`;

const StyledNavLink = styled(({ collapsed, clickHandler, ...rest }) => (
  <Link {...rest} />
)).attrs({
  collapsed: props => props.collapsed,
  activeClassName: "nav-item-active"
})`
  ${MenuButtonStyles}

  &:active,
  &:focus,
  &:hover {
    color: #000;
    fill: #000;
    text-decoration: none;
  }
`;

const NavLink = ({
  collapsed,
  to,
  text,
  clickHandler,
  hasPermission,
  ...rest
}) => {
  const _onClick = () => {
    clickHandler(text, to);
  };

  if (collapsed) {
    return null;
  }
  
  return (!to.payload.entityId && to.payload.entityType)  ? 
    (
      <StyledNavButton
        id={to.type}
        nav
        key={`${to}-${text}`}
        to={to}
        {...rest}
        onClick={_onClick}
        collapsed={collapsed}
      >
        {text}
      </StyledNavButton>
    ) : (
      <StyledNavLink
        id={to.type}
        nav
        key={`${to}-${text}`}
        to={to}
        {...rest}
        onClick={_onClick}
        collapsed={collapsed}
      >
        {text}
      </StyledNavLink>
    );
};

export default NavLink;
