import React from "react";
import styled from "styled-components";
import { StandardButton, Button } from "../../../shared";

export const StyledPublishPostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;
export const StyledPublishPostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0,0,0,0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  left:${props => (props.isDetailView ? "auto" : "-320px")}
  top:${props => (props.isDetailView ? "auto" : "30vh")}
  right: 0;

  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
export const StyledTop = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
export const StyledTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
export const StyledMiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

export const StyledUpdateButton = styled(props => (
  <StandardButton {...props} />
))`
  width: 280px;
  border-radius: 5px;
  display: block;
  margin: 5px 0 0 0 !important;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 220px;
`;
export const StyledHeaderTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
`;

export const StyledInlineSectionButton = styled(props => <Button {...props} />)`
  background-color: transparent;
  border: 0;
  padding: 0 0 10px 0;
  min-width: 25px;
  &:hover,
  &:active {
    background-color: transparent;
  }
`;
