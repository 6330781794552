import { actionCreator } from "../../shared";
import { onFetchStructure } from "../../structure/sagas/sagas";
import { onMediaFilterRoute } from "../../media/sagas/sagas";
import {
  ENTITIES,
  RESOURCE,
  MEDIA_RESOURCE,
  RELOAD_DETAILED_RESOURCE
} from "../../constants";
import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_RESOURCES, ROUTE_DETAILED_RESOURCE } from "../routes";
import {
  fetchEntitiesSaga,
  fetchEntityDetailsSaga,
  fetchFiltersSaga,
  addRemoveTagsToPostSaga,
  deletePostsSaga,
  savePostsSaga,
  addCommentToPost,
  fetchCommentsSaga,
  fetchKeyboardsSaga
} from "../api/saga";
import {
  onListRoute,
  onDetailedRoute,
  sortList,
  onFilterRoute,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onLoadComments,
  onLoadKeyboards
} from "./sagas";

import {
  FETCH_ENTITIES,
  FETCH_ENTITY_COMMENTS,
  FETCH_ENTITY_DETAILS,
  FILTER_POSTS,
  FETCH_KEYBOARD_LIST
} from "../../api";
import {
  ADD_COMMENT,
  CREATE_ENTITY_DETAILS,
  DELETE_POSTS,
  FETCH_FILTER,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  POST_ADD_REMOVE_TAGS,
  RELOAD_POSTS,
  SAVE_ENTITY_DETAILS,
  SAVE_POST,
  SORT_POSTS
} from "../store/actions";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(RESOURCE, FETCH_ENTITIES),
    fetchEntitiesSaga(ENTITIES[RESOURCE], "listResources")
  );
  yield takeLatest(
    actionCreator(RESOURCE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[RESOURCE], "fetchResource")
  );
  yield takeEvery(
    actionCreator(RESOURCE, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[RESOURCE], "saveResource")
  );
  yield takeEvery(
    actionCreator(RESOURCE, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[RESOURCE], "createResource")
  );
  yield takeEvery(
    actionCreator(RESOURCE, SAVE_POST),
    savePostsSaga(ENTITIES[RESOURCE], "savePost")
  );
  yield takeEvery(
    actionCreator(RESOURCE, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeEvery(
    actionCreator(RESOURCE, POST_ADD_REMOVE_TAGS),
    addRemoveTagsToPostSaga(ENTITIES[RESOURCE], "addRemoveTagsToPost")
  );
  yield takeEvery(
    actionCreator(RESOURCE, DELETE_POSTS),
    deletePostsSaga(ENTITIES[RESOURCE], "deletePosts")
  );
  yield takeEvery(
    actionCreator(RESOURCE, ADD_COMMENT),
    addCommentToPost(ENTITIES[RESOURCE], "addCommentToPost")
  );
  yield takeEvery(
    actionCreator(RESOURCE, FETCH_ENTITY_COMMENTS),
    fetchCommentsSaga(ENTITIES[RESOURCE], "fetchCommentsForPost")
  );
  yield takeEvery(
    actionCreator(RESOURCE, FETCH_KEYBOARD_LIST),
    fetchKeyboardsSaga(ENTITIES[RESOURCE], "fetchKeyboardListForPost")
  );
  yield takeEvery(
    actionCreator(RESOURCE, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeEvery(
    actionCreator(RESOURCE, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeEvery(
    actionCreator(RESOURCE, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeEvery(
    actionCreator(RESOURCE, SORT_POSTS),
    sortList,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeLatest(
    actionCreator(RESOURCE, FILTER_POSTS),
    filterPosts,
    ENTITIES[RESOURCE],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(RESOURCE, FETCH_FILTER),
    fetchFiltersSaga(ENTITIES[RESOURCE], "loadFilters")
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_RESOURCES,
    onListRoute,
    ENTITIES[RESOURCE],
    "listResources"
  );
  yield takeEvery(
    ROUTE_RESOURCES,
    onFilterRoute,
    ENTITIES[RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_RESOURCES,
    onFetchStructure,
    "Structure",
    "fetchStructure"
  );

  yield takeEvery(
    ROUTE_DETAILED_RESOURCE,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );

  yield takeEvery(
    ROUTE_DETAILED_RESOURCE,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );

  yield takeEvery(
    ROUTE_DETAILED_RESOURCE,
    onDetailedRoute,
    ENTITIES[RESOURCE],
    "fetchResource"
  );
  yield takeEvery(
    ROUTE_DETAILED_RESOURCE,
    onLoadComments,
    ENTITIES[RESOURCE],
    "loadComments"
  );

  yield takeEvery(
    ROUTE_DETAILED_RESOURCE,
    onLoadKeyboards,
    ENTITIES[RESOURCE],
    "loadKeyboards"
  );

  yield takeEvery(
    RELOAD_DETAILED_RESOURCE,
    onFilterRoute,
    ENTITIES[RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    RELOAD_DETAILED_RESOURCE,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    RELOAD_DETAILED_RESOURCE,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(
    RELOAD_DETAILED_RESOURCE,
    onFetchStructure,
    "Structure",
    "fetchStructure"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
