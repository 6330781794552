import { call, put, select, all, delay } from "redux-saga/effects";
import * as api from "./requests";
import { generalErrorHandler } from "../../api";
import {
  startFetch,
  endFetch,
  fetchMediaFilterSuccess,
  fetchMediaFilterError,
  fetchMediaEntitiesSuccess,
  fetchMediaEntitiesError,
  fetchMediaEntities,
  fetchMediaEntityDetailsSuccess,
  fetchMediaEntityDetailsError
} from "./actions";

import { MEDIA_TYPES, OMNIA_SYSTEM_REFID } from "./constants";

import * as sharedModule from "../../shared";
import { SET_MEDIA_CAROUSEL } from "../store/carousel/actions";
import { selectEntityId, selectEntityType } from "../../store/selectors";
import { addFlash } from "../../shared";
import { translationErrorMessage } from "../../constants";
import { getCurrentProductType } from "../../products/store/selectors";
import { getSystemsList } from "../../systems/store/selector";

/**
 * Fetches media entities
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchMediaEntitiesSaga = (entityName, apiAction) => {
  return function*(action) {
    let batchIndex = action.batchIndex;
    if (batchIndex === undefined) {
      batchIndex = yield select(st => st[entityName].get("page"));
    }
    const searchParameters = action.searchParameters;
    let args = action.args;
    if (!args) {
      args = yield select(st => st[entityName].get("args").toJS());
    }

    const productType = yield select(getCurrentProductType);

    if (productType === "omnia_biaf") {
      const systems = yield select(getSystemsList);
      const omnia = systems.find(s => s.refId === OMNIA_SYSTEM_REFID);
      args = { ...args, overrideEntityId: omnia.id };
    }

    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], args, searchParameters);
      yield put(
        fetchMediaEntitiesSuccess(
          action.entityName,
          result.data.data,
          result.data.metadata,
          searchParameters,
          batchIndex
        )
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchMediaEntitiesError(action.entityName, error.response, batchIndex)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * Fetches entitity data from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchMediaEntityDetailsSaga = (entityName, apiAction) => {
  return function*(action) {
    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], action.args);
      yield put(
        fetchMediaEntityDetailsSuccess(entityName, result.data, action.args)
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchMediaEntityDetailsError(entityName, error.response, action.args)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

export const fetchMediaFiltersSaga = (entityName, apiAction) => {
  return function*(action) {
    yield put(startFetch(entityName));
    const entityId = yield select(selectEntityId);
    const { tagsByTypeOnly } = action.args;
    let fdata = (action.filters || []).map(type =>
      sharedModule.mediaFilter[type] ? sharedModule.mediaFilter[type] : null
    );
    try {
      const result = yield call(api["fetchMediaTagFilter"], { entityId });
      let labels = [];

      fdata = fdata.map(obj => {
        if (obj === null) return obj;

        const { title, key, component, open, filterkey } = obj;
        let data = null;

        if (key === "type") {
          data = MEDIA_TYPES.map((d, index) => ({
            id: index,
            title: d.title,
            type: d.type
          }));
        }

        if (key === "labels") {
          data = result ? result.data.data : [];
          data = data.filter(d => d.type === "label");
          labels =
            data[0] && data[0].tags
              ? data[0].tags.map(tag => {
                  tag.color = tag.color || "#AAAAAA";
                  return tag;
                })
              : [];
        }

        if (key === "tags") {
          data = result ? result.data.data : [];
          data = data.filter(d => d.type !== "label");
          if (tagsByTypeOnly && tagsByTypeOnly.length > 0) {
            data = data.filter(d => d.type && tagsByTypeOnly.some(t => t === d.type))
          } else {
            data = data.filter(d => !d.type)
          }
        }

        return {
          title,
          key,
          component,
          open,
          data,
          filterkey
        };
      });

      yield put(
        fetchMediaFilterSuccess(action.entityName, fdata, labels, result)
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchMediaFilterError(action.entityName, error.response, { entityId })
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * Update media file. Like description.
 * @param {*} entityName
 * @param {*} apiAction
 */
export const updateMediaFile = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    action.data.entity_id = entityId;
    yield call(api[apiAction], { mediaId: action.id, data: action.data });
  };
};

export const deleteMediaSaga = (entityName, apiAction) => {
  return function* (action) {
    const entityId = yield select(selectEntityId);
    const entityType = yield select(selectEntityType);
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    try {
      yield call(api[apiAction], { data: { media_ids: action.ids } });
      // Delay is needed to wait for the updated imagelist
      yield delay(500);
      yield put(
        fetchMediaEntities(
          entityName,
          apiAction,
          { entityId, entityType },
          searchParameters
        )
      );
      yield put({ type: SET_MEDIA_CAROUSEL, items: [] });
    } catch (error) {
      yield put(
        addFlash(
          translationErrorMessage[error.response.data.message]
            ? translationErrorMessage[error.response.data.message]
            : error.response.data.message,
          "error"
        )
      );
    }
  };
};

/**
 * Add or remove tags
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addRemoveTagsToMediaSaga = (entityName, apiAction) => {
  return function*(action) {
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const entityType = yield select(selectEntityType);

    const args = {
      entityId: action.entityId,
      entityType: entityType
    };

    let apicalls = {};

    if (action.add !== null) {
      apicalls["add"] = call(api["addTagsToMedia"], action.add);
    }
    if (action.remove !== null) {
      apicalls["remove"] = call(api["removeTagsFromMedia"], action.remove);
    }

    try {
      yield all(apicalls);

      if (!action.detailpage) {
        yield put(
          fetchMediaEntities(entityName, apiAction, args, searchParameters)
        );
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};
