import {
  START_FETCH,
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_DETAILS,
  FETCH_ENTITY_DETAILS_ERROR
} from "../../api";

export const ACTION_FETCH_SERIES = `SERIES/${FETCH_ENTITIES}`;
export const ACTION_FETCH_SERIES_START = `SERIES/${START_FETCH}`;
export const ACTION_FETCH_SERIES_SUCCESS = `SERIES/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_SERIES_FAILED = `SERIES/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_SERIES_END = `SERIES/${END_FETCH}`;

export const ACTION_FETCH_SERIES_DETAILS = `SERIES/${FETCH_ENTITY_DETAILS}`;
export const ACTION_FETCH_SERIES_DETAILS_START = `SERIES/${START_FETCH}`;
export const ACTION_FETCH_SERIES_DETAILS_SUCCESS = `SERIES/FETCH_SERIES_DETAILS_SUCCESS`;
export const ACTION_FETCH_SERIES_DETAILS_FAILED = `SERIES/${FETCH_ENTITY_DETAILS_ERROR}`;
export const ACTION_FETCH_SERIES_DETAILS_END = `SERIES/${END_FETCH}`;

export const ACTION_FILTER_SERIES = `ACTION_FILTER_SERIES`;

export const SORT_SERIES = `SORT_SERIES`;
export const RESET_BEFORE_FILTER_SORT_CHANGE = `RESET_BEFORE_FILTER_SORT_CHANGE`;

export const FETCH_SERIES_PERMISSIONS = `FETCH_SERIES_PERMISSIONS`;
export const FETCH_SERIES_WORKFLOW = `FETCH_SERIES_WORKFLOW`;
export const FETCH_SERIES_PERMISSIONS_SUCCESS = `FETCH_SERIES_PERMISSIONS_SUCCESS`;
export const FETCH_SERIES_WORKFLOW_SUCCESS = `FETCH_SERIES_WORKFLOW_SUCCESS`;

export const PUBLISH_SERIES = "PUBLISH_SERIES";
export const PUBLISH_PREVIEW_SERIES = "PUBLISH_PREVIEW_SERIES";
export const UNPUBLISH_SERIES = "UNPUBLISH_SERIES";

export const ACTION_DELETE_SERIES = `ACTION_DELETE_SERIES`;
export const ACTION_UPDATE_SERIES = `ACTION_UPDATE_SERIES`;
export const ACTION_CREATE_SERIES = `SERIES/CREATE_SERIES`;
export const ACTION_ADD_PRODUCT_TO_SERIES = `SERIES/ADD_PRODUCT_TO_SERIES`;
export const ACTION_DELETE_PRODUCT_FROM_SERIES = `SERIES/DELETE_PRODUCT_FROM_SERIES`;
export const ACTION_ADD_SUB_SERIES_TO_SERIES = `SERIES/ADD_SUB_SERIES_TO_SERIES`;
export const ACTION_DELETE_SUB_SERIES_FROM_SERIES = `SERIES/DELETE_SUB_SERIES_FROM_SERIES`;
export const ACTION_EDITING_CREATED_SERIES = `SERIES/CURRENT_SERIE_IS_EDITED`;

export const fetchSeries = (args, searchParameters = {}) => ({
  type: ACTION_FETCH_SERIES,
  args,
  searchParameters
});

export const fetchSeriesStart = () => ({
  type: ACTION_FETCH_SERIES_START
});

export const fetchSeriesSuccess = (data, metadata) => ({
  type: ACTION_FETCH_SERIES_SUCCESS,
  data,
  metadata
});

export const fetchSeriesFailed = error => ({
  type: ACTION_FETCH_SERIES_FAILED,
  error
});

export const fetchSeriesEnd = () => ({
  type: ACTION_FETCH_SERIES_END
});

export const filterSeries = filter => ({
  type: ACTION_FILTER_SERIES,
  filter
});

export const sortSeries = sort => ({
  type: SORT_SERIES,
  sort
});

export const resetBeforeFilterSortChange = () => ({
  type: RESET_BEFORE_FILTER_SORT_CHANGE
});

export const createSeries = data => ({
  type: ACTION_CREATE_SERIES,
  data
});

export const fetchSeriesDetails = payload => ({
  type: ACTION_FETCH_SERIES_DETAILS,
  payload
});

export const fetchSeriesDetailsStart = () => ({
  type: ACTION_FETCH_SERIES_DETAILS_START
});

export const fetchSeriesDetailsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_SERIES_DETAILS_SUCCESS,
  data,
  metadata
});

export const fetchSeriesDetailsFailed = error => ({
  type: ACTION_FETCH_SERIES_DETAILS_FAILED,
  error
});

export const fetchSeriesDetailsEnd = () => ({
  type: ACTION_FETCH_SERIES_DETAILS_END
});

export const updateSeriesDetails = (seriesEntityId, data) => ({
  type: ACTION_UPDATE_SERIES,
  seriesEntityId,
  data
});

export const deleteSeries = postIds => ({
  type: ACTION_DELETE_SERIES,
  postIds
});

export const addProductToSeries = (productEntityId, seriesEntityId) => ({
  type: ACTION_ADD_PRODUCT_TO_SERIES,
  productEntityId,
  seriesEntityId
});

export const deleteProductFromSeries = (productEntityId, seriesEntityId) => ({
  type: ACTION_DELETE_PRODUCT_FROM_SERIES,
  productEntityId,
  seriesEntityId
});

export const fetchSeriesWorkFlow = () => ({
  type: FETCH_SERIES_WORKFLOW
});

export const fetchSeriesPermissions = () => ({
  type: FETCH_SERIES_PERMISSIONS
});

export const fetchSeriesWorkFlowSuccess = data => ({
  type: FETCH_SERIES_WORKFLOW_SUCCESS,
  data
});

export const fetchSeriesPermissionsSuccess = data => ({
  type: FETCH_SERIES_PERMISSIONS_SUCCESS,
  data
});

export const publishSeries = postIds => ({
  type: PUBLISH_SERIES,
  postIds
});

export const publishPreviewSeries = postIds => ({
  type: PUBLISH_PREVIEW_SERIES,
  postIds
})

export const unpublishSeries = postIds => ({
  type: UNPUBLISH_SERIES,
  postIds
})

export const createdSerieIsEdited = data => ({
  type: ACTION_EDITING_CREATED_SERIES,
  data
})
