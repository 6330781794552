import {
  getMedia,
  renderMedia
} from "../../../../../shared/MediaHandler/MediaHandler";
import { getBorderKey, getHeight } from "../../ChartHelpers";
import { StyledMediaContainer } from "./StyledMediaCell";

const MediaCell = ({
  cell,
  callback,
  cellIndex,
  rowIndex,
  resources,
  selected
}) => (
  <StyledMediaContainer
    id="media-container"
    data-rowindex={rowIndex}
    data-cellindex={cellIndex}
    border={getBorderKey(cell)}
    column={cell.column}
    row={cell.row}
    onClick={callback && callback(rowIndex, cellIndex)}
    selected={selected}
  >
    {renderMedia(getMedia(cell, resources), null, getHeight(cell.column))}
  </StyledMediaContainer>
);

export default MediaCell;
