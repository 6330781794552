import { generalErrorHandler } from "../../api";
import {
  fetchProductList,
  fetchProductListEnd,
  fetchProductListFailed,
  fetchProductListStart,
  fetchProductListSuccess,
  resetBeforeFilterSortChange,
  setFetchLimitSuccess
} from "../api/actions";

import { call, put, select } from "redux-saga/effects";
import * as api from "../api/requests";

import {
  selectFetchLimit,
  selectSearchParams,
  selectPage
} from "../store/selectors";
import { selectQuery } from "../../posts/sagas/selectors";
import { gotoPage, nextPage, previousPage } from "../../posts/api/actions";

export function* onFetchProductList(action) {
  let batchIndex = action.batchIndex;
  if (batchIndex === undefined) {
    batchIndex = yield select(selectPage);
  }
  const searchParameters = yield select(selectSearchParams);

  yield put(fetchProductListStart());
  try {
    const result = yield call(api.getListProducts, "product", searchParameters);

    yield put(
      fetchProductListSuccess(
        result.data.data.entities,
        result.data.metadata,
        searchParameters,
        batchIndex
      )
    );
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchProductListFailed(error.response, batchIndex));
  } finally {
    yield put(fetchProductListEnd());
  }
}

export function* onProductListRoute() {
  let page = yield select(selectPage);
  const params = yield select(selectQuery);

  if (params.page) {
    page = params.page;
  }

  const args = yield select(st => st.Productlist.get("args").toJS()) || {};
  const fetchLimit = yield select(selectFetchLimit);

  yield put(
    fetchProductList(args, {
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

function* fetchPage(args, params) {
  const fetchLimit = yield select(selectFetchLimit);
  const currentPage = yield select(selectPage);

  yield put(
    fetchProductList(args, {
      ...params,
      offset: fetchLimit * (currentPage - 1),
      num_result: fetchLimit
    })
  );
}

export function* previousPageSaga(entityName) {
  const searchParams = yield select(selectSearchParams);
  yield put(previousPage(entityName));
  yield call(fetchPage, {}, searchParams);
}

export function* nextPageSaga(entityName) {
  const searchParams = yield select(selectSearchParams);
  yield put(nextPage(entityName));
  yield call(fetchPage, {}, searchParams);
}

export function* loadPageSaga(entityName, _, { page }) {
  const searchParams = yield select(selectSearchParams);
  yield put(gotoPage(entityName, page));
  yield call(fetchPage, {}, searchParams);
}

export function* exportProductListSaga() {
  const searchParams = yield select(st => st.location.query);
  yield call(api.exportProductList, searchParams);
}

export function* sortProductListSaga({ sort }) {
  yield put(resetBeforeFilterSortChange());

  const searchParams = yield select(selectSearchParams);
  
  if (!sort.sesam_name) {
    sort = {...sort, sesam_name: 'ASC' }
  }
  const params = { ...searchParams, sort };

  yield call(fetchPage, {}, params);
}

export function* onSetFetchLimit({ limit }) {
  yield put(resetBeforeFilterSortChange());
  yield put(setFetchLimitSuccess(limit));
  const searchParams = yield select(selectSearchParams);
  yield call(fetchPage, {}, searchParams);
}
