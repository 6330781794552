
import React from "react";

const TrashFilled = ({ size }) => (
  <svg 
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 24 24" 
    width={`${size}px`}
    className="studlicon-pencil-filled"
    fill='white'
  >
  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
</svg>
);

export default TrashFilled;


