import styled from "styled-components";

const Section = styled.section`
display: inline-flex;
flex: 1;
align-items: flex-start;
justify-content: center;
min-width: 0;
height: 100%;
z-index: 1;
${props =>
  props.start
    ? `
    justify-content: flex-start;
    order: -1;
  `
    : null}

${props =>
  props.end
    ? `
    justify-content: flex-end;
    order: 1;
  `
    : null}

${props =>
  props.shrink
    ? `
    flex: none;
  `
    : null}
`;

export default Section;
