import React from "react";
import Link from "../../../shared/components/Link";
import { StandardButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import {
  ToolBarContent,
  ToolBarisSavedMarkup,
  ToolBarLeft,
  ToolBarMid,
  ToolBarPaginationMarkup,
  ToolBarRight,
  ToolbarRow,
  ToolbarWrapper
} from "./StyledToolBar";

export const ToolBar = ({
  paginationClick,
  postIDsInCarousel,
  showCarousel,
  activePostID,
  breadcrumb,
  isSaved,
  postTitle,
  children,
  onBackClick
}) => {
  const currentIndex = Array.isArray(postIDsInCarousel) ? postIDsInCarousel.findIndex(id => +id === +activePostID) : 0;
  const numberOfPosts = Array.isArray(postIDsInCarousel) ? postIDsInCarousel.length : 1;

  /**
   * Runs callback for pagination click
   * @param direction
   * @returns {*}
   */
  const buttonCallback = direction => () => {
    return direction === "Left"
      ? paginationClick("prev")
      : paginationClick("next");
  };

  /**
   * Renders button
   * @param direction
   * @returns {*}
   */
  const renderButton = direction => (
    <StandardButton
      studlicon={`Chevron${direction}`}
      onClick={buttonCallback(direction)}
    />
  );

  /**
   * Renders post count
   * @returns {*}
   */
  const renderPostCount = () => (
    <div>
      Post {currentIndex + 1} / {numberOfPosts}
    </div>
  );

  /**
   * Renders carousel
   * @returns {null}
   */
  const renderCarousel = () =>
    postIDsInCarousel.length && showCarousel ? (
      <ToolBarPaginationMarkup>
        <ButtonGroup>
          {renderPostCount()}
          {renderButton("Left")}
          {renderButton("Right")}
        </ButtonGroup>
      </ToolBarPaginationMarkup>
    ) : null;

  /**
   * Renders bread crumbs
   * @returns {null}
   */
  const renderBreadCrumbs = () =>
    breadcrumb ? (
      <nav>
        <Link onClick={onBackClick} to={breadcrumb.href} icon={"ChevronLeft"}>
          {breadcrumb.content}
        </Link>
      </nav>
    ) : null;

  /**
   * Renders saved
   * @returns {null}
   */
  const renderSaved = () => (isSaved ? <ToolBarisSavedMarkup /> : null);

  return (
    <ToolbarWrapper>
      <ToolbarRow>
        <ToolBarContent>
          <ToolBarLeft>{renderBreadCrumbs()}</ToolBarLeft>

          <ToolBarRight>{renderCarousel()}</ToolBarRight>
        </ToolBarContent>
      </ToolbarRow>
      <ToolbarRow>
        <ToolBarContent>
          <ToolBarLeft>{postTitle}</ToolBarLeft>

          <ToolBarMid>{renderSaved()}</ToolBarMid>

          <ToolBarRight>
            <div>{children}</div>
          </ToolBarRight>
        </ToolBarContent>
      </ToolbarRow>
    </ToolbarWrapper>
  );
};

export default ToolBar;
