import {
  intervalToDuration,
  formatDuration,
  format as dfnsFormat
} from "date-fns";
import { sv } from "date-fns/locale";

export const distanceInWordsToNow = to => {
  const now = new Date();
  const durations = intervalToDuration({ start: now, end: to });
  return formatDuration(durations, { locale: sv });
};

export const format = (date, f = "yyyy-MM-dd HH:mm") =>
  dfnsFormat(date, f, { locale: sv });

export const formatISOZeroTime = () =>
  new Date('0001-01-01').toISOString();

export const formatLocalISOTime = (date) => {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000;
  return new Date(new Date(date) - tzoffset).toISOString();
}
