import styled from "styled-components";

export const StyledPluginHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
`;

export const StyledMediaRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
