import { connect } from "react-redux";

import TopBar from "../../components/TopBar";
import { selectHeaderTitle } from "../../store/navigation/selectors";

const mapStateToProps = state => ({
  logoNav: () => ({
    type: Object.keys(state.location.routesMap).find(
      key => state.location.routesMap[key].navLogoLink
    )
  }),
  collapsed: state.navigation.get("collapsed"),
  hideNavigation: state.location.routesMap[state.location.type].hideNavigation,
  isOnStartPage: state.location.type === "ROUTE_PRODUCTS",
  title: selectHeaderTitle(state)
});

export default connect(mapStateToProps)(TopBar);
