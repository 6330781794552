import React from "react";
import { Transforms, Editor, Element as SlateElement } from "slate";
import { useSlate } from "slate-react";
import IconButton from "../../../products/components/IconButton";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  background-color: ${props => (props.active ? "#ccc" : "transparent")};
  font-size: 18px;
  height: 23px;
  padding: 0 4px 4px;
`;

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const BlockEditorButton = ({ format }) => {
  const editor = useSlate();

  const isBlockActive = (ed, form) => {
    const { selection } = ed;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(ed, {
        at: Editor.unhangRange(ed, selection),
        match: n =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === form
      })
    );

    return !!match;
  };

  const toggleBlock = (ed, form) => {
    const isActive = isBlockActive(ed, form);
    const isList = LIST_TYPES.includes(form);

    Transforms.unwrapNodes(ed, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type),
      split: true
    });
    const newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : form
    };
    Transforms.setNodes(ed, newProperties);

    if (!isActive && isList) {
      const block = { type: form, children: [] };
      Transforms.wrapNodes(ed, block);
    }
  };

  return (
    <StyledIconButton
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {format === "heading-one" && <div>H1</div>}
      {format === "heading-two" && <div>H2</div>}
      {format === "subtitle" && <div>H</div>}
    </StyledIconButton>
  );
};

export default BlockEditorButton;
