import { AUDIO } from "../../constants";
import PluginHoc from "../../shared/PluginHoc";

const Audio = ({ pluginHeader, useModal }) => {
  useModal(AUDIO);

  return pluginHeader;
};

export default PluginHoc({
  Component: Audio
});
