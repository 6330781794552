import React from "react";

const Key = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-key"
  >
    <path d="M32.8125,3.125 C40.5790039,3.125 46.875,9.42099609 46.875,17.1875 C46.875,24.9540039 40.5790039,31.25 32.8125,31.25 C30.9601562,31.25 29.1917969,30.8911133 27.572168,30.240332 L23.4375,34.375 L18.75,34.375 L18.75,40.625 L12.5,40.625 L12.5,46.875 L3.125,46.875 L3.125,39.0625 L19.759668,22.427832 C19.1088867,20.8082031 18.75,19.0398437 18.75,17.1875 C18.75,9.42099609 25.0459961,3.125 32.8125,3.125 Z M32.8125,0 C23.321875,0 15.625,7.69228516 15.625,17.1875 C15.625,18.6823242 15.8149414,20.1515625 16.1912109,21.5768555 L0.686425781,37.0816406 C0.246943797,37.5211908 3.54269695e-05,38.1172976 0,38.7388672 L0,47.65625 C0,48.9506836 1.04931641,50 2.34375,50 L13.28125,50 C14.5756836,50 15.625,48.9506836 15.625,47.65625 L15.625,43.75 L19.53125,43.75 C20.8256836,43.75 21.875,42.7006836 21.875,41.40625 L21.875,37.5 L23.7611328,37.5 C24.3827148,37.5 24.9789062,37.2530273 25.418457,36.8135742 L28.4232422,33.8087891 C29.8484375,34.1850586 31.3176758,34.375 32.8125,34.375 C42.303125,34.375 50,26.6827148 50,17.1875 C50,7.696875 42.3077148,0 32.8125,0 Z M37.5,10.546875 C38.5769531,10.546875 39.453125,11.4230469 39.453125,12.5 C39.453125,13.5769531 38.5769531,14.453125 37.5,14.453125 C36.4230469,14.453125 35.546875,13.5769531 35.546875,12.5 C35.546875,11.4230469 36.4230469,10.546875 37.5,10.546875 Z M37.5,7.8125 C34.9111328,7.8125 32.8125,9.91113281 32.8125,12.5 C32.8125,15.0888672 34.9111328,17.1875 37.5,17.1875 C40.0888672,17.1875 42.1875,15.0888672 42.1875,12.5 C42.1875,9.91113281 40.0888672,7.8125 37.5,7.8125 Z" />
  </svg>
);

export default Key;
