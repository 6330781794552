import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
`;

export const NavigationAndContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 73px;
`;

export const Content = styled.main`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
`;

export const TopBarContainer = styled.header`
  position: fixed;
  width: 100%;
  min-height: 72px;
  z-index: 3;
`;

export const AsideContainerStyle = `
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const PrimaryAsideContainer = styled.div`
  ${AsideContainerStyle}
  display: flex;
  height: calc(100vh - 49px);
  left: 0;
  border-right: 1px solid lightgrey;
`;

export const SecondaryAsideContainer = styled.div`
  ${AsideContainerStyle} left: 0;
`;

export const SideBarContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const SideBarMargin = styled.div`
  width: 20px;
`;
