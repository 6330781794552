export const LOAD_MORE_POSTS = "LOAD_MORE_POSTS";
export const LOAD_LESS_POSTS = "LOAD_LESS_POSTS";
export const LOAD_POST_PAGE = "LOAD_POST_PAGE";
export const RELOAD_POSTS = "RELOAD_POSTS";
export const RELOAD_POST = "RELOAD_POST";
export const SORT_POSTS = "SORT_POSTS";
export const FETCH_FILTER = "FETCH_FILTER";
export const FILTER_POSTS = "FILTER_POSTS";
export const POST_ADD_REMOVE_TAGS = "POST_ADD_REMOVE_TAGS";
export const POST_SET_CATEGORY = "POST_SET_CATEGORY";
export const PUBLISH_POSTS = "PUBLISH_POSTS";
export const PUBLISH_PREVIEW_POSTS = "PUBLISH_PREVIEW_POSTS";
export const REQUEST_REVIEW = "REQUEST_REVIEW";
export const REVIEW_POST = "REVIEW_POST";
export const REJECT_POST = "REJECT_POST";
export const UNPUBLISH_POSTS = "UNPUBLISH_POSTS";
export const DELETE_POSTS = "DELETE_POSTS";
export const SAVE_POST = "SAVE_POST";
export const DUPLICATE_POST = "DUPLICATE_POST";
export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_DESCRIPTION = "ADD_DESCRIPTION";
export const SAVE_ENTITY_DETAILS = "SAVE_ENTITY_DETAILS";
export const CREATE_ENTITY_DETAILS = "CREATE_ENTITY_DETAILS";
export const ROUTE_DETAILED_EXERCISE = "ROUTE_DETAILED_EXERCISE";
export const ROUTE_DETAILED_RESOURCE = "ROUTE_DETAILED_RESOURCE";
export const ROUTE_DETAILED_HELP_RESOURCE = "ROUTE_DETAILED_HELP_RESOURCE";
export const ROUTE_MEDIARESOURCE_EDIT = "ROUTE_MEDIARESOURCE_EDIT";
export const REGISTER_VALIDATOR = "REGISTER_VALIDATOR";
export const UNREGISTER_VALIDATOR = "UNREGISTER_VALIDATOR";
export const SET_SAVE_POST_STATUS = "SET_SAVE_POST_STATUS";
export const ROUTE_DETAILED_AIDA = "ROUTE_DETAILED_AIDA";
export const POST_ADD_REMOVE_CHIPS = "POST_ADD_REMOVE_CHIPS";
export const FETCH_CHIPS = "FETCH_CHIPS";
export const FETCH_CHIPS_SUCCESS = "FETCH_CHIPS_SUCCESS";

/**
 * Action for saving post
 * @param data
 * @param postId
 * @param lowerCaseType
 * @returns {{type, args}}
 */
export const actionSavePost = (data, postId, lowerCaseType) =>
  savePostObject(
    `${lowerCaseType}/${SAVE_ENTITY_DETAILS}`.toUpperCase(),
    data,
    postId
  );

/**
 * Action for saving post before leaving
 * @param data
 * @param postId
 * @param lowerCaseType
 * @returns {{type, args}}
 */
export const actionSavePostBeforeLeave = (data, postId, lowerCaseType) =>
  savePostObject(`${lowerCaseType}/${SAVE_POST}`.toUpperCase(), data, postId);

/**
 * Action for creating posts
 * @param data
 * @param lowerCaseType
 * @returns {{type, args}}
 */
export const actionCreatePost = (data, lowerCaseType) =>
  savePostObject(
    `${lowerCaseType}/${CREATE_ENTITY_DETAILS}`.toUpperCase(),
    data
  );

/**
 * Return a save object
 * @param type
 * @param data
 * @param postId
 * @returns {{type: *, args: {postId: *}}}
 */
const savePostObject = (type, data, postId = null) => {
  const args = postId ? { ...data, postId } : data;

  return {
    type,
    args
  };
};

/**
 * Action for editing a post
 * @returns {{type: string, payload: {entityId: *, id: *}, location: {pathname: string}}}
 */
export const actionEditEntity = (entityId, id, entityType, query) => {
  const routes = {
    exercise: ROUTE_DETAILED_EXERCISE,
    resource: ROUTE_DETAILED_RESOURCE,
    helpresource: ROUTE_DETAILED_HELP_RESOURCE,
    mediaresource: ROUTE_MEDIARESOURCE_EDIT,
    aida: ROUTE_DETAILED_AIDA
  };
  const entitytype = entityType.toLowerCase();
  return {
    type: routes[entitytype],
    payload: {
      entityId: entityId,
      id: id,
      query: query
    },
    location: {
      pathname: `/products/${entityId}/${entitytype}/${id}`
    }
  };
};

export const loadPosts = postType => ({
  type: `${postType.toUpperCase()}/${LOAD_MORE_POSTS}`
});

export const loadLessPosts = postType => ({
  type: `${postType.toUpperCase()}/${LOAD_LESS_POSTS}`
});

export const loadPage = (postType, page) => ({
  type: `${postType.toUpperCase()}/${LOAD_POST_PAGE}`,
  page
});

export const reloadPosts = postType => ({
  type: `${postType.toUpperCase()}/${RELOAD_POSTS}`
});

export const reloadPost = (postType, postId) => ({
  type: `${postType.toUpperCase()}/${RELOAD_POST}`,
  postId
});

export const sortPosts = (postType, sort) => ({
  type: `${postType.toUpperCase()}/${SORT_POSTS}`,
  sort
});

export const filterPosts = (postType, filter) => ({
  type: `${postType.toUpperCase()}/${FILTER_POSTS}`,
  filter
});

export const fetchFilters = (postType, filters) => ({
  type: `${postType.toUpperCase()}/${FETCH_FILTER}`,
  filters,
  entityName: postType
});

export const addRemoveTagsToPost = (postType, add, remove, detailpage) => ({
  type: `${postType.toUpperCase()}/${POST_ADD_REMOVE_TAGS}`,
  add,
  remove,
  detailpage
});

export const setCategoryToPosts = (postIds, catId, useCurrentPost) => ({
  type: POST_SET_CATEGORY,
  postIds,
  catId,
  useCurrentPost
});

export const publishPreviewPosts = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${PUBLISH_PREVIEW_POSTS}`,
  postIds,
  mode
})

export const publishPosts = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${PUBLISH_POSTS}`,
  postIds,
  mode
});

export const requestReview = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${REQUEST_REVIEW}`,
  postIds,
  mode
});

export const reviewPost = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${REVIEW_POST}`,
  postIds,
  mode
});

export const rejectPost = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${REJECT_POST}`,
  postIds,
  mode
});

export const unpublishPosts = (postType, postIds, mode) => ({
  type: `${postType.toUpperCase()}/${UNPUBLISH_POSTS}`,
  postIds,
  mode
});

export const deletePosts = (postType, postIds) => ({
  type: `${postType.toUpperCase()}/${DELETE_POSTS}`,
  postIds,
  entityName: postType
});

export const duplicatePost = (postId, postType) => ({
  type: DUPLICATE_POST,
  postType: postType,
  postId
});

export const savePost = (postType, args) => ({
  type: `${postType.toUpperCase()}/${SAVE_POST}`,
  args
});

export const savePostSuccess = (postType, args) => ({
  type: `${postType.toUpperCase()}/${SAVE_POST_SUCCESS}`,
  args
});

export const setSavePostStatus = (postType, args) => ({
  type: `${postType.toUpperCase()}/${SET_SAVE_POST_STATUS}`,
  args
});

export const addComment = (postType, comment) => ({
  type: `${postType.toUpperCase()}/${ADD_COMMENT}`,
  comment
});

export const addDescription = (postType, description) => ({
  type: `${postType.toUpperCase()}/${ADD_DESCRIPTION}`,
  description
});

export const fetchAllChips = (postType, args = {}) => ({
  type: `${postType.toUpperCase()}/${FETCH_CHIPS}`,
  args
});

export const fetchAllChipsForEntitySuccess = (postType, data) => ({
  type: `${postType.toUpperCase()}/${FETCH_CHIPS_SUCCESS}`,
  data
});

export const addRemoveChipsToPost = (postType, add, remove, detailpage) => ({
  type: `${postType.toUpperCase()}/${POST_ADD_REMOVE_CHIPS}`,
  add,
  remove,
  detailpage
});
