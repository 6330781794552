import React from "react";

const TextTool = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 20 20"
    width={`${size}px`}
    className="studlicon-texttool"
  >
    <polygon fill="#565656" points="0 0 0 3 5 3 5 15.01 7.98 15.01 7.98 3.01 12.97 3.01 12.97 0 0 0" />
    <polygon fill="#565656" points="10 5 10 8.01 13 8.01 13 15.01 16.01 15.01 16.01 8.01 18.98 8.01 18.98 5.01 10 5" />
  </svg>
);

export default TextTool;