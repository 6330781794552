import React from "react";
import styled from "styled-components";
import { hasPermissionTo } from "../../Permissions";
import { translation } from "../../../constants";
import NavLink from "./NavLink";

const NavSub = styled.div`
  padding-left: 14px;
  background-color: white;
  overflow: hidden;

  &.open {
    max-height: 350px;
  }
  &.closed {
    max-height: 0;
  }
  transition: all 0.5s;
`;

const NavSubLine = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 165px;
  margin-top: 15px;
  height: 10px;
`;

/**
 * Renders navigation section
 * @param {*} collapsed
 * @param {*} to
 * @param {*} text
 * @param {*} rest
 */

/**
 * Returns navigation section name from section title.
 * @param {*} object
 * @param {*} value
 */
const getTranslationKey = (object, value) =>
  Object.keys(object).find(key => object[key] === value);

/**
 * Renders all navigation sections.
 * @param {*} param0
 */
const NavSection = ({
  links = [],
  className,
  collapsed,
  clickHandler,
  permissions
}) => (
  <section className={className}>
    <nav>
      {links.map(({ text, to, subs, isOpen, ...rest }) => {
        const section = getTranslationKey(translation, text);
        const hasPermission = hasPermissionTo(section, permissions);
        // Note: Permissions will be finished later when details has been decided.
        return (
          <React.Fragment key={text}>
            <NavLink
              collapsed={collapsed}
              to={to}
              text={text}
              clickHandler={clickHandler}
              hasPermission={hasPermission}
              {...rest}
            />
            <NavSub className={isOpen ? "open" : "closed"}>
              {subs &&
                subs.map(({ subtext, subto, ...subrest }) => (
                  <NavLink
                    key={subtext}
                    collapsed={collapsed}
                    to={subto}
                    text={subtext}
                    clickHandler={clickHandler}
                    hasPermission={hasPermission}
                    {...subrest}
                  />
                ))}
              {isOpen && subs && <NavSubLine />}
            </NavSub>
          </React.Fragment>
        );
      })}
    </nav>
  </section>
);

export default styled(NavSection)`
  color: #333;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
