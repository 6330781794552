import React from "react";
import { AutoSizer } from "react-virtualized";
import styled from "styled-components";
import Draggable from "react-draggable";
import { ChevronDown, ChevronUp } from "react-feather";

import Table from "./Table";
import NoRows from "./Renderer/NoRows";

const SortIcon = styled.span`
  & > svg {
    position: relative;
    top: 2px;
  }
`;

class DataTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sizes: React.Children.toArray(props.children).reduce(
        (sizes, child) =>
          Object.assign({}, sizes, {
            [child.props.dataKey]: child.props.width
          }),
        {}
      ),
      sortDirections: React.Children.toArray(props.children).reduce(
        (directions, child) =>
          child.props.disableSort
            ? directions
            : Object.assign({}, directions, {
                [child.props.dataKey]:
                  props.sortDirections &&
                  Object.keys(props.sortDirections).includes(
                    child.props.dataKey
                  )
                    ? props.sortDirections[child.props.dataKey]
                    : undefined
              }),
        {}
      )
    };
  }

  getRow = ({ index }) => this.props.list[index];

  headerRenderer = (width, isDraggable) => ({
    dataKey,
    disableSort,
    label
  }) => {
    let draggable;
    let sortable;
    let onLabelClick;

    if (isDraggable) {
      draggable = (
        <Draggable
          axis="x"
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
          onDrag={(event, { deltaX }) =>
            this.resizeRow({
              dataKey,
              deltaX,
              width
            })
          }
          position={{ x: 0 }}
          zIndex={999}
        >
          <span className="DragHandleIcon">⋮</span>
        </Draggable>
      );
    }

    if (!disableSort) {
      const { sortDirections } = this.state;
      let sortChevron;
      const sortDirection = sortDirections[dataKey];

      if (sortDirection) {
        sortChevron =
          sortDirection === "ASC" ? (
            <ChevronDown width={14} height={14} />
          ) : (
            <ChevronUp width={14} height={14} />
          );
      }

      sortable = <SortIcon>{sortChevron}</SortIcon>;

      onLabelClick = () => this.onSort({ sortBy: dataKey });
    }

    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <React.Fragment key={dataKey}>
        <div
          className={`DataTable_label ${isDraggable ? "draggable" : ""}`}
          onClick={onLabelClick}
        >
          {label} {sortable}
        </div>
        {draggable}
      </React.Fragment>
    );
  };

  resizeRow = ({ dataKey, deltaX, width }) =>
    this.setState(prevState => {
      const children = React.Children.toArray(this.props.children);
      const indexOfChild = children.findIndex(
        child => child.props.dataKey === dataKey
      );

      let nextDataKey = children[indexOfChild + 1].props.dataKey;

      const prevSizes = prevState.sizes;
      const percentDelta = deltaX / width;

      if (children[indexOfChild + 1].props.fixedWidth) {
        return {
          ...prevSizes
        };
      }

      if ((prevSizes[dataKey] + percentDelta) * width < 34) {
        return {
          ...prevSizes
        };
      }

      if ((prevSizes[nextDataKey] - percentDelta) * width < 34) {
        return {
          ...prevSizes
        };
      }

      return {
        sizes: {
          ...prevSizes,
          [dataKey]: prevSizes[dataKey] + percentDelta,
          [nextDataKey]: prevSizes[nextDataKey] - percentDelta
        }
      };
    });

  isDraggable = (index, draggable) =>
    draggable &&
    index + 1 < React.Children.toArray(this.props.children).length &&
    !React.Children.toArray(this.props.children)[index + 1].props.fixedWidth;

  onSort = ({ sortBy }) => {
    const { onSort } = this.props;
    let { sortDirections } = this.state;

    let sortDirection = "ASC";
    if (sortDirections[sortBy] === sortDirection) {
      sortDirection = "DESC";
    } else if (sortDirections[sortBy] === "DESC") {
      sortDirection = undefined;
    }

    if (!sortDirection) {
      delete sortDirections[sortBy];
      sortDirections = Object.assign({}, sortDirections);
    } else {
      sortDirections = Object.assign({}, sortDirections, {
        [sortBy]: sortDirection
      });
    }

    this.setState({
      sortDirections
    });

    sortDirections = Object.keys(sortDirections).reduce(function(p, c) {
      if (sortDirections[c] !== undefined) p[c] = sortDirections[c];
      return p;
    }, {});

    onSort(sortDirections);
  };

  calcWidth = (width, children) => {
    const widthToSubtract = children.reduce((total, child) => {
      if (child.props.fixedWidth) {
        return total + child.props.width;
      }
      return total;
    }, 0);

    return width - widthToSubtract;
  };

  rowStyleFormat(row) {
    if (row.index < 0) return;

    const cell = this.getRow(row);

    if (cell.selected) {
      return {
        backgroundColor: "#b0d0ed",
        borderBottom: "none",
        boxShadow: "darkgrey 0px 1px 3px",
        zIndex: "1000"
      };
    }
  }

  render() {
    const {
      className,
      children,
      list,
      onSort,
      actions,
      onRowMouseOver
    } = this.props;
    const { sizes } = this.state;

    return (
      <div className={className}>
        <AutoSizer>
          {({ height, width }) => {
            const columnWidth = this.calcWidth(
              width,
              React.Children.toArray(children)
            );
            return (
              <React.Fragment>
                {actions
                  ? React.cloneElement(actions, {
                      style: { width: `${width}px` }
                    })
                  : null}
                <Table
                  rowCount={list.length}
                  rowGetter={this.getRow}
                  width={width}
                  height={actions ? height - 98 : height}
                  headerHeight={48}
                  rowHeight={48}
                  noRowsRenderer={NoRows}
                  onRowMouseOver={onRowMouseOver}
                  rowStyle={this.rowStyleFormat.bind(this)}
                >
                  {React.Children.map(children, (child, index) =>
                    React.cloneElement(child, {
                      width: child.props.fixedWidth
                        ? child.props.width
                        : columnWidth * sizes[child.props.dataKey],
                      headerRenderer: this.headerRenderer(
                        width,
                        this.isDraggable(index, child.props.draggable)
                      ),
                      disableSort: child.props.disableSort || !onSort
                    })
                  )}
                </Table>
              </React.Fragment>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

export default styled(DataTable)`
  display: flex;
  flex: 1;
  height: 100%;
`;
