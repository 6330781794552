import React from "react";
import Tag from "../../../shared/components/Tag";
import { AIDA_KEYWORD_COLOR } from "../../api/constants";
import {
  TagsPostWrapper,
  TagWrapper,
  NoTagsSelected,
  ColorLessTag
} from "./TagsInPostStyles";

const TagsInPost = ({ tagList, postTags, content, postType }) => {
  const getTagList = () => {
    const list_tags = tagList ? tagList.map(g => g.tags) : [];
    return [].concat(...list_tags);
  };

  const tList = content.indexOf("Difficulty") > -1 ? tagList : getTagList();

  const getTagById = id => {
    return tList.find(item => {
      return item.id === id;
    });
  };

  const tagRenderer = (tag, fill) => {
    if (tag.color || postType === "Aida") {
      return (
        <Tag
          label={tag.title}
          variant="info"
          fill={fill}
          labelColor={postType === "Aida" ? AIDA_KEYWORD_COLOR : tag.color}
        />
      );
    } else {
      return <ColorLessTag>{tag.title}</ColorLessTag>;
    }
  };

  const renderTags = tags =>
    tags.map(tag => (
      <TagWrapper key={tag.id + "selectedTagsRenderer"}>
        {tagRenderer(tag, true)}
      </TagWrapper>
    ));

  const postTagsRenderer = () => {
    const pTags = postTags
      ? postTags.reduce((acc, item) => {
          const v = getTagById(item);
          return v ? [].concat(acc, v) : acc;
        }, [])
      : [];

    return pTags.length > 0 ? (
      renderTags(pTags)
    ) : (
      <NoTagsSelected>Ingen tagg vald</NoTagsSelected>
    );
  };

  return <TagsPostWrapper>{postTagsRenderer()}</TagsPostWrapper>;
};

export default TagsInPost;
