import React from "react";
import styled from "styled-components";

const Shadow = ({ className, onClick }) => (
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  <div className={className} onClick={onClick} />
);

export default styled(Shadow)`
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.25s ease 0s;

  ${props =>
    props.backdrop
      ? `
    opacity: .4;
  `
      : null};
`;
