/**
 * Cookie helpers borrowed from https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
 */

/**
 * Get cookie by name
 * @param {string} name name of the cookie
 */
export const getCookie = name => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

/**
 * Set a cookie
 * @param {string} name name of the cookie
 * @param {string} value value of the cookie
 * @param {number} days cookie expiration in days
 */
export const setCookie = (name, value, days) => {
  let d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
};

/**
 * Delete a cookie
 * @param {string} name name of the cookie
 */
export const deleteCookie = name => setCookie(name, "", -1);
