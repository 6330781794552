import {
  OPERATIVE,
  ORDER,
  BOXES_COUNT,
  SHUFFLED,
  DEFAULT,
  CHIPS,
  BOX_TYPE,
  FREE,
  TEXT_SIZE,
  L,
  MAX_ITEMS_IN_BOX
} from "../../../constants";

/**
 * Generates default sort machine data object
 * @returns {{items: Array}}
 */
export const defaultSortMachineData = () => ({
  boxes: [{ id: "id_0", label: "", value: "A", totalCorrectItems: 0 }],
  items: [],
  [MAX_ITEMS_IN_BOX]: 12
});

/**
 * Generates default sort machine settings object
 */
export const defaultSortMachineSettings = () => ({
  [ORDER]: SHUFFLED,
  [BOXES_COUNT]: 1,
  [OPERATIVE]: true,
  [CHIPS]: DEFAULT,
  [BOX_TYPE]: FREE,
  [TEXT_SIZE]: L
});
