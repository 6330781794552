import { put, call } from "redux-saga/effects";
import {
  userLoginFailure,
  userLoginSuccessful,
  addAuthor,
  doLogin
} from "../api/actions";
import * as api from "../api/requests";
import { deleteCookie } from "../../shared/services/cookies";
import { CMS_SSO_TOKEN } from "../../shared/constants/fetch";
import { authReady } from "../../shared/store/application/actions";
import { getCookie } from "../../shared/services/cookies";

/**
 * Maker saga for initializing authentication
 * @param action
 */
export function* makeAuthInit(action) {
  // If auth required and not authorized, check if there is a client token
  const clientToken = getCookie(CMS_SSO_TOKEN);
  if (clientToken) {
    // Client token found, authorize user using clientToken from SSO
    yield put(doLogin({ authData: { clientToken } }));
  } else {
    // No client token found. Attach user to SSO to get clientToken (redirect to /sso)
    window.location.href = "/sso/attach";
  }
}

/**
 * Maker saga for logging in a user
 * @param action
 */
export function* makeDoLogin(action) {
  try {
    const author = yield call(api.authorizeUser, action.authData.clientToken);

    yield put(addAuthor(author.data));
    yield put(userLoginSuccessful(author.data.id));
    yield put(authReady());
  } catch (error) {
    yield put(userLoginFailure(error.response.status));
  }
}

/**
 * Maker saga for logging out a user
 * @param action
 */
export function makeDoLogout(action) {
  window.location.href = "/sso/logout";
}

/**
 * Maker saga to handle login failures
 * @param action action
 */
export function makeHandleLoginFailure(action) {
  deleteCookie(CMS_SSO_TOKEN);
  window.location.href = "/sso/attach";
}
