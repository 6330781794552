export const px2unitless = (pixels, context = fontSizeDefaultNumber) =>
  `${pixels / context}`;

export const fontSizeDefaultNumber = 14;
export const lineHeightNumber = 20;
export const lineHeight = `${lineHeightNumber}px`;
export const fontFamily =
  'Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;';
export const fontSizeDefault = `${fontSizeDefaultNumber}px`;
export const lineHeightDefault = px2unitless(lineHeightNumber);
export const codeFontFamily =
  '"SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Courier, monospace;';
