import { takeEvery, fork, all, put, select } from "redux-saga/effects";
import { selectEntityId } from "../../../store/selectors";
import {
  WATCH_GET_LOCK_STATUS,
  WATCH_UNLOCK_POST,
  FORCE_UNLOCK_POST,
  actionLockPost,
  actionUnLockPost,
  actionForceUnLockPost
} from "./actions";

/**
 * Watches lock post actions
 */
export function* watchGetLockStatus() {
  yield takeEvery(WATCH_GET_LOCK_STATUS, makeLockPost);
}

/**
 * Watches unlock post actions
 */
export function* watchUnlockPost() {
  yield takeEvery(WATCH_UNLOCK_POST, makeUnlockPost);
}

/**
 * Watches force unlock post actions
 */
export function* watchForceUnlockPost() {
  yield takeEvery(FORCE_UNLOCK_POST, makeForceUnlockPost);
}

/**
 * Make Lock post or return
 * @param postId
 * @param newPostData
 */
export function* makeLockPost({ postId }) {
  const entityId = yield select(selectEntityId);
  yield put(actionLockPost(entityId, postId));
}

/**
 * Make unlock post
 * @param postId
 */
export function* makeUnlockPost({ postId }) {
  const entityId = yield select(selectEntityId);
  yield put(actionUnLockPost(entityId, postId));
}

/**
 * Make unlock post
 * @param postId
 */
export function* makeForceUnlockPost({ postId }) {
  const entityId = yield select(selectEntityId);
  yield put(actionForceUnLockPost(entityId, postId));
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLockStatus),
    fork(watchUnlockPost),
    fork(watchForceUnlockPost)
  ]);
}
