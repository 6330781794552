import React from "react";
import styled from "styled-components";

const Count = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const CountRenderer = ({ cellData, rowData }) => (
  <Count isSelected={rowData.selected}>{cellData}</Count>
);

export default CountRenderer;
