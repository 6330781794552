import styled from "styled-components";
import { PrimaryButton,StandardButton } from "../../../shared/components/Button";

export const StyledFraction = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledFractionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledItemEditText = styled.div`
  flex: 1;
  display: flex;
`;

export const StyledValueEditInputWrapper = styled.div`
  background-color: white;
  border: thin solid #999;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 16px;
  padding: 5px 10px;
  margin: 0px 10px;
  input {
    outline: none;
    width: 100%;
    border: 0;
    ${props => (props.danger ? "color: red" : "")};
  }
  height: 40px;
`;

export const StyledInteractiveWrapper = styled.div`
  display: flex;
  margin: 7px 5px;
  align-items: center;
`;

export const StyledDragHandleWrapper = styled.div`
  margin-top: 12px;
`;

export const StyledValueContentWrapper = styled.div`
  flex: 1;
  min-width: 30%;
`;

export const StyledItemContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 735px;
  width: 100%;
`;

export const StyledItem = styled.li`
  background: ${props => {
    switch(props.type) {
      case 'ghost': return "bisque";
      case 'simplified': return "cyan";
        default: return "white"
    }
  }};
  border: 1px solid #999;
  z-index: 5;
  padding: 10px;
  display: flex;
  margin: 0;
`;

export const StyledResultContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #f3f3f2;
  flex-direction: column;
  margin: 5px 0px 5px 0px;
`;

export const StyledIntegerContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #f3f3f2;
  flex-direction: row;
  margin: 5px 0px 5px 0px;
`;

export const StyledResultTogglesContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #f3f3f2;
  flex-direction: column;
  align-self: center;
`;

export const StyledResultWrapper = styled.div`  
  display: flex;
  box-sizing: border-box;
  border: thin solid #999;
  border-radius: 5px;
  background-color: #f3f3f2;
  flex-direction: row;
  margin: 20px;
`;

export const StyledResultDivisor = styled.div`
  border-bottom: 2px solid black;
  width: 75%;
  align-self: center;

`;

export const StyledResultLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  flex: 1;
  align-self: center;
  margin: 0px 10px 0px 10px;
`;

export const StyledResultAnswer = styled.label`
  font-size: ${props => props.big ? '48px' : '15px'};
  font-weight: ${props => props.big ? '300' : '500'};
  align-self: center;
  flex: 1;
  text-align: center;
  padding: 5px 8px 5px 8px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledResultAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledResultAnswerField = styled.div`
  display: flex;
  border: thin solid #999;
  background: white;
  flex: 1;
  flex-direction: column;
  align-self: center;
  margin: 6px 5px 6px 5px;
`;

export const StyledGridContainer = styled.div`
  align-self: center;
  margin-top: 40px;
`;

export const StyledWarningText = styled.span`
  margin: auto;
  color: red;
  font-size: 14px;
  width: 70%;
  justify-content: center;
  display: flex;
`;

export const StyledAddItemContainer = styled.div`
  align-self: center;
  margin-top: 10px;
`;

export const StyledButtonPrimary = styled(PrimaryButton)`
  min-width: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonStandard = styled(StandardButton)`
  border: 1px black;
  min-width: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledRemoveImageButton = styled.button`
  border-radius: 10em;
  padding: 2px 8px 2px;
  text-decoration: none;
  font: 700 16px/16px sans-serif;
  background: #e54e4e;
  border: 2px solid #fff;
  color: #fff;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`;

export const StyledGhostLabel = styled.div`
  flex: 1;
`;