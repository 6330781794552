import React, { useState, useEffect } from "react";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import createToolbarPlugin, {
  Separator
} from "@draft-js-plugins/static-toolbar";
import Editor from "@draft-js-plugins/editor";
import { EditorState, RichUtils, convertToRaw } from "draft-js";
import addLinkPlugin from "./plugins/addLinkPlugin";
import deepEqual from "deep-equal";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineTwoButton,
  UnorderedListButton,
  OrderedListButton
} from "@draft-js-plugins/buttons";
import { StyledLinkButton, StyledLinkButtonWrapper } from "./StyledText";

const classNames = require("classnames");

const getToolbarAndPlugins = () => {
  const toolbarPlugin = createToolbarPlugin();
  const { Toolbar } = toolbarPlugin;
  const plugins = [toolbarPlugin, addLinkPlugin];
  return {
    plugins,
    Toolbar
  };
};

const AidaTextEditor = ({
  onChange,
  editorState,
  readOnly,
  useToolbar,
  useShortToolbar,
  updatedItem,
  callbackUpdatedItem
}) => {
  const [myEditorState, setMyEditorState] = useState(editorState);
  const [{ plugins, Toolbar }] = useState(getToolbarAndPlugins);

  useEffect(() => {
    const stateHasChanged = () => {
      const prev = convertToRaw(myEditorState.getCurrentContent());
      const now = convertToRaw(editorState.getCurrentContent());
      return !deepEqual(prev, now);
    };

    if (updatedItem) {
      if (stateHasChanged()) {
        setMyEditorState(editorState);
        callbackUpdatedItem();
      }
    }
  }, [updatedItem, editorState, myEditorState, callbackUpdatedItem]);

  const _onChange = state => {
    setMyEditorState(state);
    onChange(state);
  };

  const onAddLink = () => {
    const selection = myEditorState.getSelection();
    const link = window.prompt("Skriv in din länk - ");
    if (!link) {
      _onChange(RichUtils.toggleLink(myEditorState, selection, null));
      return "handled";
    }
    const content = myEditorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      myEditorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const linkEditorState = RichUtils.toggleLink(
      newEditorState,
      selection,
      entityKey
    );
    _onChange(linkEditorState);
    return "handled";
  };

  const renderToolbar = () => {
    if (readOnly || !useToolbar) {
      return null;
    }

    return (
      <Toolbar>
        {externalProps => {
          return (
            <div>
              {useShortToolbar ? (
                <>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <StyledLinkButtonWrapper>
                    <StyledLinkButton onClick={onAddLink} />
                  </StyledLinkButtonWrapper>
                </>
              ) : (
                <>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <Separator {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <Separator {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <StyledLinkButtonWrapper>
                    <StyledLinkButton onClick={onAddLink} />
                  </StyledLinkButtonWrapper>
                  <Separator {...externalProps} />
                </>
              )}
            </div>
          );
        }}
      </Toolbar>
    );
  };

  const className = classNames("TextEditor", {
    "TextEditor--Edit": !readOnly,
    "TextEditor--ReadOnly": readOnly
  });

  const editor = (
    <Editor
      plugins={plugins}
      readOnly={readOnly}
      editorState={myEditorState}
      onChange={_onChange}
    />
  );

  return (
    <div className={className} >
      {editor}
      {renderToolbar()}
    </div>
  );
};

export default AidaTextEditor;
