import { call, put, select, all } from "redux-saga/effects";
import * as api from "./requests";

import { generalErrorHandler } from "../../api";

import {
  fetchEntities,
  fetchEntitiesSuccess,
  fetchEntitiesError,
  fetchEntityDetailsSuccess,
  fetchEntityDetailsError,
  startFetch,
  startFetchFilters,
  endFetch,
  endFetchFilters,
  fetchFilterSuccess,
  fetchFilterError,
  fetchComments,
  fetchCommentsSuccess,
  fetchCommentsError,
  setTmpDataForNewPost,
  clearTmpDataForNewPost,
  fetchEntityDetails,
  fetchKeyboardListSuccess,
} from "./actions";

import { getCurrentPostSelector } from "./selectors";

import { DIFFICULTY, STATUS, NOTAGS } from "./constants";
import { selectPostId } from "../sagas/selectors";
import * as sharedModule from "../../shared";
import {
  ROUTE_DETAILED_EXERCISE,
  ROUTE_DETAILED_RESOURCE,
  ROUTE_DETAILED_HELP_RESOURCE,
  ROUTE_DETAILED_AIDA,
} from "../routes";
import { ROUTE_MEDIARESOURCE_EDIT } from "../../mediaresource/routes";
import { applyHelpObject } from "../sagas/PostDraft/helpers";
import {
  savePostSuccess,
  setSavePostStatus,
  fetchAllChipsForEntitySuccess,
} from "../store/actions";
import {
  EDIT,
  EXERCISES,
  ENTITIES,
  HELP_RESOURCE,
  AIDA,
} from "../../constants";
import { filterComponents, filterAidaComponents } from "../../plugins";
import { selectEntityId } from "../../store/selectors";
import {
  ROUTE_DETAILED_MARKETMESSAGE,
  ROUTE_DETAILED_OP_MESSAGE,
  ROUTE_DETAILED_PRODUCT_NEWS,
} from "../../messages";
import { getChipNodes } from "./helpers";
import { SET_CAROUSEL } from "../store/carousel/actions";

/**
 * Routing to different post types.
 */
const detailedRoute = {
  exercise: ROUTE_DETAILED_EXERCISE,
  resource: ROUTE_DETAILED_RESOURCE,
  helpresource: ROUTE_DETAILED_HELP_RESOURCE,
  mediaresource: ROUTE_MEDIARESOURCE_EDIT,
  aida: ROUTE_DETAILED_AIDA,
  opmessage: ROUTE_DETAILED_OP_MESSAGE,
  productnews: ROUTE_DETAILED_PRODUCT_NEWS,
  marketmessage: ROUTE_DETAILED_MARKETMESSAGE
};

/**
 * Post type labels
 */
const postLabels = {
  exercise: "Övning",
  resource: "Resurs",
  helpresource: "Hjälpresurs",
  mediaresource: "Bildresurs",
  aida: "Aida",
  productnews: "Produktnyhet",
  opmessage: "Driftmeddelande",
  marketmessage: "Marknadsmeddelande"
};

/**
 * Fetches a list of entities from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchEntitiesSaga = (entityName, apiAction) => {
  return function* (action) {
    let batchIndex = action.batchIndex;
    if (batchIndex === undefined) {
      batchIndex = yield select((st) => st[entityName].get("page"));
    }
    const searchParameters = yield select((st) =>
      st[entityName].get("searchParameters").toJS()
    );
    const args = yield select((st) => st[entityName].get("args").toJS());

    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], args, searchParameters);
      yield put(
        fetchEntitiesSuccess(
          action.entityName,
          result.data.data,
          result.data.metadata,
          searchParameters,
          batchIndex
        )
      );

      const carouselItems = yield select((st) => st["Carousel"].items);
      const activeItem = yield select((st) => st["Carousel"].active);

      if (carouselItems.length > 0) {
        const items = carouselItems.map((item) => {
          const post = result.data.data.find((p) => p.id === item.id);

          return { ...item, status: post ? post.status : item.status };
        });
        const activePost = result.data.data.find((p) => p.id === activeItem.id);
        const active = {
          ...activeItem,
          status: activePost ? activePost.status : activeItem.status,
        };

        yield put({
          type: SET_CAROUSEL,
          items,
          active,
        });
      }
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchEntitiesError(action.entityName, error.response, batchIndex)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * Fetches entitity data from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchEntityDetailsSaga = (entityName, apiAction) => {
  return function* (action) {
    if (action.args.postId === "new") {
      yield put(clearTmpDataForNewPost(entityName));
    }
    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], action.args);
      yield put(
        fetchEntityDetailsSuccess(
          entityName,
          applyHelpObject(result.data),
          result.metadata,
          action.args
        )
      );
      yield ifNewPostSavedActions(entityName, result, action);
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchEntityDetailsError(entityName, error.response, action.args)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * If this post was a new post that now has been saved - Check if servicebar data has been temporarily saved
 * for this post and save them. Reload page so url is correct.
 * @param {*} entityName
 * @param {*} result
 * @param {*} action
 */
function* ifNewPostSavedActions(entityName, result, action) {
  if (!action.args.postId && result.data.data.id !== null) {
    // This was a new post with no id. Some data may have been temporarily saved from the servicebar.
    // With the new id the changes can be saved.
    const draft = yield select((st) => st[entityName].get("newDataTmp").toJS());
    if (draft.hasDataSaved) {
      if (draft.difficulty !== null) {
        yield call(api["savePost"], {
          entityId: action.args.entityId,
          postId: result.data.data.id,
          difficulty: draft.difficulty,
        });
      }
      if (draft.category !== null) {
        yield call(api["setCategory"], {
          entityId: action.args.entityId,
          posts: [result.data.data.id],
          categoryId: draft.category,
        });
      }
      if (draft.tags.length !== 0) {
        yield call(api["addTagsToPost"], {
          post_ids: [result.data.data.id],
          tags: draft.tags,
        });
      }
      if (draft.chips.length !== 0) {
        yield call(api["addChipsToPost"], {
          post_ids: [result.data.data.id],
          chip_ids: draft.chips,
          entityId: action.args.entityId,
        });
      }
      if (draft.comments) {
        let apicalls = {};
        draft.comments.map((c, i) => {
          apicalls[i] = call(api["addCommentToPost"], {
            entityId: action.args.entityId,
            comment: c.message,
            postId: result.data.data.id,
          });
          return c;
        });
        yield all(apicalls);
      }
      yield put(clearTmpDataForNewPost(entityName));
    }
    // reload page with the new id
    yield put({
      type: detailedRoute[entityName.toLowerCase()],
      payload: {
        entityId: action.args.entityId,
        id: result.data.data.id,
      },
    });
  }
}

/**
 * Fetches the filterdata from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchFiltersSaga = (entityName, apiAction) => {
  return function* (action) {
    yield put(startFetchFilters(entityName));

    const entityId = yield select(selectEntityId);

    let fdata = (action.filters || []).map((type) =>
      sharedModule.filterdata[type] ? sharedModule.filterdata[type] : null
    );

    try {
      const result = yield call(api["fetchTagFilter"], {
        entityId,
        posttype: [entityName.toLowerCase()],
      });

      const chipResult = yield call(api["fetchAllChipsForEntity"], {
        entityId,
        postType: entityName.toLowerCase(),
      });
      const chipfilters =
        chipResult.data &&
        chipResult.data[0] &&
        chipResult.data[0].sub_sections;

      let labels = [];

      fdata = fdata.map((obj) => {
        if (obj === null) return obj;
        const { title, key, component, open, filterkey } = obj;

        let data = null;

        if (key === "difficulty") {
          data = DIFFICULTY.map((d, index) => ({ id: index, title: d }));
        }

        if (key === "component") {
          data = filterComponents(entityName === ENTITIES[HELP_RESOURCE]);
          if (entityName === ENTITIES[AIDA]) {
            data = filterAidaComponents();
          }
        }

        if (key === "status") {
          const entityStatus = ["Förhandsgranskning publicerad"];
          data = STATUS.map((d, index) => ({
            id: index,
            title: d.title
          })).filter(item => item.title && !entityStatus.includes(item.title));
        }

        if (key === "nosection") {
          const fd = chipfilters ? chipfilters : [];
          data = NOTAGS.map((d) => {
            const t = fd.find((f) => f.title === d.id);
            return {
              id: t?.id || 0,
              title: d.title,
            };
          }).filter((item) => item.title);
        }

        if (key === "labels") {
          data = result ? result.data.data : [];
          data = data.filter((d) => d.type === "label");
          labels =
            data[0] && data[0].tags
              ? data[0].tags.map((tag) => {
                  tag.color = tag.color || "#AAAAAA";
                  return tag;
                })
              : [];
        }

        if (key === "chips") {
          const chipsdata = chipfilters ? chipfilters : [];
          data = chipsdata.map((d) => ({
            id: d.id,
            title: d.title,
            entity_id: entityId,
            type: "chips",
            chips: getChipNodes([d]),
          }));
        }

        if (key === "tags") {
          data = result ? result.data.data : [];
          data = data.filter((d) => d.type !== "label");
        }

        return {
          title,
          key,
          component,
          open,
          data,
          filterkey,
        };
      });

      yield put(fetchFilterSuccess(action.entityName, fdata, labels));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchFilterError(action.entityName, error.response, { entityId })
      );
    } finally {
      yield put(endFetchFilters(entityName));
    }
  };
};

/**
 * Fetches comments for a post from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchCommentsSaga = (entityName, apiAction) => {
  return function* (action) {
    try {
      const result = yield call(api[apiAction], action.args);
      yield put(fetchCommentsSuccess(entityName, result.data));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchCommentsError(entityName, error.response, action.args));
    }
  };
};

/**
 * Fetches virtual keyboard presets
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchKeyboardsSaga = (entityName, apiAction) => {
  return function* (action) {
    try {
      const result = yield call(api[apiAction], action.args);
      yield put(fetchKeyboardListSuccess(entityName, result.data));
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Help function for add/remove tags
 * @param {array} tags ex [2, 7, 13]
 * @param {object} add
 * @param {object} remove
 * @return {array}
 */
const combineTags = (tags, add, remove) => {
  let newTags = tags;
  if (add !== null) {
    add.tags.map((tag) => {
      newTags = newTags.indexOf(tag) < 0 ? newTags.concat([tag]) : newTags;
      return tag;
    });
  }
  if (remove !== null) {
    newTags = newTags.filter((f) => remove.tags.indexOf(f) < 0);
  }
  return newTags;
};

/**
 * Help function for add/remove chips
 * @param {array} chips ex [2, 7, 13]
 * @param {object} add
 * @param {object} remove
 * @return {array}
 */
const combineChips = (chips, add, remove) => {
  let newChips = chips;
  if (add !== null && add.chip_ids.length > 0) {
    add.chip_ids.map((chip) => {
      newChips =
        newChips.indexOf(chip) < 0 ? newChips.concat([chip]) : newChips;
      return chip;
    });
  }
  if (remove !== null && remove.chip_ids.length > 0) {
    newChips = newChips.filter((f) => remove.chips.indexOf(f) < 0);
  }
  return newChips;
};

/**
 * Add or remove tags
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addRemoveTagsToPostSaga = (entityName, apiAction) => {
  return function* (action) {
    const searchParameters = yield select((st) =>
      st[entityName].get("searchParameters").toJS()
    );
    const urlPostId = yield select(selectPostId);
    let apicalls = {};

    if (urlPostId !== "new") {
      if (action.add !== null) {
        apicalls["add"] = call(api["addTagsToPost"], action.add);
      }
      if (action.remove !== null) {
        apicalls["remove"] = call(api["removeTagsFromPost"], action.remove);
      }
    } else {
      const data = yield select((st) => st[entityName].get("newDataTmp"));
      const tags = data.get("tags");
      const newdata = data.set(
        "tags",
        combineTags(tags, action.add, action.remove)
      );

      yield put(setTmpDataForNewPost(entityName, newdata));
    }

    try {
      yield all(apicalls);

      if (!action.detailpage) {
        yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Add category to a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const setCategoryToPostsSaga = (entityName, apiAction) => {
  return function* (action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select((st) =>
      st[entityName].get("searchParameters").toJS()
    );
    const urlPostId = yield select(selectPostId);
    try {
      if (urlPostId === "new") {
        const data = yield select((st) => st[entityName].get("newDataTmp"));
        const newdata = data.set("category", action.catId);
        yield put(setTmpDataForNewPost(entityName, newdata));
      } else {
        if (action.catId > -1) {
          yield call(api["setCategory"], {
            entityId: entityId,
            posts: action.postIds,
            categoryId: action.catId,
          });
        } else {
          yield call(api["unsetCategory"], {
            entityId: entityId,
            posts: action.postIds,
          });
        }

        if (!action.useCurrentPost) {
          yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
        }
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Duplicate a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const duplicatePostSaga = (apiAction) => {
  return function* (action) {
    const entityId = yield select(selectEntityId);

    const { postType, postId, args } = action;
    const label = postLabels[postType.toLowerCase()];

    try {
      const result = yield call(api[apiAction], {
        entityId: entityId,
        postId: postId,
      });
      const {
        data: { id },
      } = result.data;

      // reload page with the new id
      yield put({
        type: detailedRoute[postType.toLowerCase()],
        payload: {
          entityId: entityId,
          id: id,
        },
      });

      const message = `${label}: ${postId} är nu duplicerad.`;
      yield generalFlashMessage(message, "success");
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchEntityDetailsError(postType, error.response, args));
    }
  };
};

/**
 * A general saga for posts, calls the api, handles the result & updates entities.
 *
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} action
 */
export function* generalPostSaga(entityName, apiAction, action) {
  const entityId = yield select(selectEntityId);
  const postId = action.postIds[0];
  const searchParameters = yield select((st) =>
    st[entityName].get("searchParameters").toJS()
  );

  const deletePost = { entityId: entityId, data: { posts: action.postIds } };
  const generalPostData = { entityId: entityId, posts: action.postIds };

  try {
    const result = yield call(
      api[apiAction],
      apiAction === "deletePosts" ? deletePost : generalPostData
    );

    switch (action.mode) {
      case EDIT:
        yield put(
          fetchEntityDetails(entityName, apiAction, { entityId, postId })
        );
        break;
      case EXERCISES:
      default:
        yield put(
          fetchEntities(entityName, apiAction, { entityId }, searchParameters)
        );
    }
    return result;
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchEntitiesError(entityName, error.response));
  } finally {
    yield put(endFetch(entityName));
  }
}

/**
 * Delete a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const deletePostsSaga = (entityName, apiAction) => {
  return function* (action) {
    yield generalPostSaga(entityName, apiAction, action);
  };
};

/**
 * Publish a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const publishPostsSaga = (entityName, apiAction) => {
  return function* (action) {
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result) {
      const totalIDs = action.postIds.length;
      const message =
        totalIDs > 1
          ? `${totalIDs} poster är nu publicerade.`
          : `Posten är nu publicerad.`;

      yield generalFlashMessage(message, "success");
    }
  };
};

/**
 * Unpublish a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const unpublishPostsSaga = (entityName, apiAction) => {
  return function* (action) {
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result) {
      const totalIDs = action.postIds.length;
      const message =
        totalIDs > 1
          ? `${totalIDs} poster är nu avpublicerade.`
          : `Posten är nu avpublicerad.`;

      yield generalFlashMessage(message, "success");
    }
  };
};

/**
 * Request a review for a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const requestReviewSaga = (entityName, apiAction) => {
  return function* (action) {
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result) {
      const {
        data: { invalidPosts, numOfPostsToValidate, numOfPostsValidated },
      } = result.data;
      const postId = action.postIds[0];
      const entityId = yield select(selectEntityId);

      if (numOfPostsToValidate === numOfPostsValidated) {
        let message =
          numOfPostsValidated > 1
            ? `${numOfPostsValidated} poster är nu redo att granskas.`
            : `Posten är nu redo att granskas.`;

        yield generalFlashMessage(message, "success");
      } else {
        for (let post of invalidPosts) {
          let startMessage = `Post ${post.postId}, ${post.exerciseType} har följande fel: `;
          const uniqueErrors = [...new Set(post.errors)].map(
            (err) => " " + err
          );
          const message = startMessage + uniqueErrors;

          yield generalFlashMessage(message, "info", false);
        }
        yield put(
          fetchComments(entityName, "loadComments", { entityId, postId })
        );
      }
    }
  };
};

/**
 * Review a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const reviewPostSaga = (entityName, apiAction) => {
  return function* (action) {
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result) {
      let message =
        action.postIds.length > 1
          ? `${action.postIds.length} poster är nu granskade och är nu redo att bli publicerade.`
          : `Posten är nu granskad och är redo för att bli publicerad.`;

      yield generalFlashMessage(message, "success");
    }
  };
};

/**
 * Reject a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const rejectPostSaga = (entityName, apiAction) => {
  return function* (action) {
    const result = yield generalPostSaga(entityName, apiAction, action);

    if (result) {
      let message =
        action.postIds.length > 1
          ? `${action.postIds.length} poster har blivit avböjda.`
          : `Posten har blivit avböjd.`;

      yield generalFlashMessage(message, "success");
    }
  };
};

/**
 * General flasher
 *
 * @param {*} message
 * @param {*} type
 * @param {*} args
 */
export function* generalFlashMessage(message, type, args) {
  yield put(sharedModule.addFlash(message, type, args));
}

/**
 * Save a post without fetching the updated post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const savePostsSaga = (entityName, apiAction) => {
  return function* (action) {
    // only save post - don't fetch
    const urlPostId = yield select(selectPostId);
    const searchParameters = yield select((st) =>
      st[entityName].get("searchParameters").toJS()
    );

    yield put(setSavePostStatus(entityName, true));

    try {
      if (urlPostId === "new") {
        const data = yield select((st) => st[entityName].get("newDataTmp"));
        const newdata = data.set("difficulty", action.args.difficulty);
        yield put(setTmpDataForNewPost(entityName, newdata));
      } else {
        yield call(api[apiAction], action.args);
      }

      yield put(savePostSuccess(entityName, action.args));

      //Only fetch entities if the requests made are done from the post listview and part of a chain.
      if (action.args.finalRequest && !action.args.detailedPage) {
        yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Add a comment to a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addCommentToPost = (entityName, apiAction) => {
  return function* (action) {
    const entityId = yield select(selectEntityId);
    const currentPost = yield select(getCurrentPostSelector(entityName));
    const postId = currentPost.get("data").id;
    const urlPostId = yield select(selectPostId);

    if (urlPostId === "new") {
      const data = yield select((st) => st[entityName].get("newDataTmp"));
      const comments = data.get("comments");
      const newComment = {
        message: action.comment,
        user_id: 1,
        entity_id: entityId,
        post_id: 0,
        updated_at: "",
      };
      const newdata = data.set("comments", comments.push(newComment));
      yield put(setTmpDataForNewPost(entityName, newdata));
    } else {
      yield call(api[apiAction], {
        entityId,
        comment: action.comment,
        postId,
      });
      yield put(
        fetchComments(entityName, "loadComments", { entityId, postId })
      );
    }
  };
};

export const fetchAllChipsForEntitySaga = (entityName, apiAction) => {
  return function* (action) {
    try {
      const result = yield call(api[apiAction], {
        entityId: action.args.entityId,
        postType: entityName.toLowerCase(),
      });
      yield put(fetchAllChipsForEntitySuccess(entityName, result.data));
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Add or remove tags
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addRemoveChipsToPostSaga = (entityName, apiAction) => {
  return function* (action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select((st) =>
      st[entityName].get("searchParameters").toJS()
    );
    const urlPostId = yield select(selectPostId);
    try {
      if (urlPostId !== "new") {
        if (action.add !== null && action.add.chip_ids.length > 0) {
          yield call(api["addChipsToPost"], {
            ...action.add,
            entityId: entityId,
          });
        }
        if (action.remove !== null && action.remove.chip_ids.length > 0) {
          yield call(api["removeChipsFromPost"], {
            ...action.remove,
            entityId: entityId,
          });
        }
      } else {
        const data = yield select((st) => st[entityName].get("newDataTmp"));
        const chips = data.get("chips");
        const newdata = data.set(
          "chips",
          combineChips(chips, action.add, action.remove)
        );
        yield put(setTmpDataForNewPost(entityName, newdata));
      }

      if (!action.detailpage) {
        yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};
