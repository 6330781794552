import styled from "styled-components";

export const StyledNoWrapBold = styled.b`
  white-space: nowrap;
`;

export const StyledBarSectionContentTitle = styled.h5`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: lighter;
  line-height: 12px;
`;

export const StyledBarSectionUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledBarSectionContentUsername = styled.p`
  font-size: 12px;
  color: #404040;
  font-weight: 500;
  padding: 0;
  margin: 0;
  flex: 1;
  margin-right: 3px;
`;

export const StyledBarSectionContentDate = styled.p`
  font-size: 12px;
  color: #404040;
  font-weight: 200;
  padding: 0;
  margin: 0;
  flex: 4;
`;

export const StyledUsedByLabel = styled.div`
  font-size: 14px;
  background-color: ${props =>
    props.tagtype === "exercise" ? props.theme.themePrimary : "#324d72"};
  color: ${props => props.theme.white};
  padding: 5px 15px;
  margin-right: 5px;
  border-radius: 5px;
  svg {
    fill: ${props => props.theme.white};
`;
