import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { TransitionMotion, spring } from "react-motion";

import { clearFlash } from "../../store/flasher/actions";
import Growler from "../../components/Growler";

const mapStateToProps = state => ({
  growlers: state.flasher.get("showing").reverse()
});

const mapDispatchToProps = dispatch => ({
  close: id => dispatch(clearFlash(id))
});

const willEnter = () => ({
  opacity: 0
});

const willLeave = () => ({
  opacity: spring(0)
});

const GrowlerWrapper = styled.div`
  margin: 10px;
`;

const FlasherContainer = ({ growlers, className, close }) => {
  const styles = growlers
    .map(growler => ({
      key: growler.get("id"),
      data: {
        msg: growler.get("msg"),
        type: growler.get("type"),
        id: growler.get("id")
      },
      style: {
        opacity: spring(1)
      }
    }))
    .toArray();

  return (
    <TransitionMotion
      styles={styles}
      willEnter={willEnter}
      willLeave={willLeave}
    >
      {styles => (
        <div className={className}>
          {styles.map(({ style, key, data }) => (
            <GrowlerWrapper style={style} key={key}>
              <Growler {...data} onClick={close} />
            </GrowlerWrapper>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
};

const StyledFlasherContainer = styled(FlasherContainer)`
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 4;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledFlasherContainer);
