import { AIDA, CLASSROOM, MESSAGES, SERIES, OMNIA } from "../../constants";

export const FETCH_MEDIA_LIMIT = 50;
export const MEDIA_TYPES = [
  { title: "Enskilda bilder", type: "image" },
  { title: "Ljud", type: "audio" },
  { title: "Video", type: "video" },
  { title: "Dokument", type: "document" },
];
export const MEDIA_LIBS = [
  { route: "ROUTE_MEDIA", name: "product" },
  { route: "ROUTE_PRODUCT", name: "product" },
  { route: "ROUTE_CLASSROOM_MEDIA", name: CLASSROOM},
  { route: "ROUTE_MESSAGES_MEDIA", name: MESSAGES},
  { route: "ROUTE_AIDA_MEDIA", name: AIDA },
  { route: "ROUTE_SERIES_MEDIA", name: SERIES },
  { route: "ROUTE_OMNIA_MEDIA", name: OMNIA }
];

export const OMNIA_SYSTEM_REFID = "2f742594-5e70-4c96-93f0-f93c3d0eb857";

