const withInlines = editor => {
  const { isInline } = editor;

  editor.isInline = element =>
    ["link"].includes(element.type) || isInline(element);

  return editor;
};

export default withInlines;
