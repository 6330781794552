import * as React from "react";
import PluginHoc from "../../shared/PluginHoc";
import Text from "../../shared/components/Text/Text";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading,
  StyledSidebarContent
} from "../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import {
  StyledTextBlock,
  StyledTextContainer
} from "../../shared/components/Text/StyledText";

const Texts = ({
  updateData,
  data,
  settings,
  pluginHeader,
  draftTarget,
  storeDraft,
  callbackUpdatedItem,
  updatedItem
}) => {

  const renderSideBar = () => {
    if (settings.readOnly) {
      return null;
    }

    return (
      <StyledBlockSidebar maxWidth={"200px"}>
        <StyledSidebarSection>
          <StyledSidebarHeading backgroundColor={"#35877A"}>
            Texthantering
          </StyledSidebarHeading>

          <StyledSidebarContent />
        </StyledSidebarSection>
      </StyledBlockSidebar>
    );
  };

  return (
    <StyledTextBlock>
      <StyledTextContainer>
        {pluginHeader}

        <Text
          updateData={updateData}
          storeDraft={storeDraft}
          draftTarget={draftTarget}
          data={data}
          settings={settings}
          callbackUpdatedItem={callbackUpdatedItem}
          updatedItem={updatedItem}
        />
      </StyledTextContainer>
      {renderSideBar()}
    </StyledTextBlock>
  );
};

export default PluginHoc({
  Component: Texts
});
