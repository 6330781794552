import styled from "styled-components";

export const InputArea = styled.textarea.attrs({
  rows: 5,
  cols: 50
})`
  width: 100%;
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  outline: none;
`;
