import {
  ADVENTURES,
  ADVENTURES_CARD,
  ADVENTURES_CARD_EDITOR,
  ADVENTURES_GROUP
} from "../../constants";
import { selectAdventureGroupId, selectAdventureId } from "../sagas/selectors";

const getRoute = (state, entityName, row_id) => {
  const entity_id = state.Product.get("selectedItem")
    ? state.Product.get("selectedItem").id
    : null;

  const group_id = selectAdventureGroupId(state);
  const adventure_id = selectAdventureId(state);

  switch (entityName) {
    case ADVENTURES_GROUP:
      return `/products/${entity_id}/adventure-editor/adventure-group/${row_id}/adventures`;
    case ADVENTURES:
      return `/products/${entity_id}/adventure-editor/adventure-group/${group_id}/adventure/${row_id}/adventures-cards`;
    case ADVENTURES_CARD:
      return `/products/${entity_id}/adventure-editor/adventure-group/${group_id}/adventure/${adventure_id}/adventure-card/${row_id}`;
    default:
      break;
  }
};

export const getPreviousRoute = (state, entityName) => {
  const entity_id = state.Product.get("selectedItem")
    ? state.Product.get("selectedItem").id
    : null;

  const group_id = selectAdventureGroupId(state);
  const adventure_id = selectAdventureId(state);

  switch (entityName) {
    case ADVENTURES_GROUP:
      return null;
    case ADVENTURES:
      return `/products/${entity_id}/adventure-editor/adventure-groups`;
    case ADVENTURES_CARD:
      return `/products/${entity_id}/adventure-editor/adventure-group/${group_id}/adventures`;
    case ADVENTURES_CARD_EDITOR:
      return `/products/${entity_id}/adventure-editor/adventure-group/${group_id}/adventure/${adventure_id}/adventures-cards`;
    default:
      break;
  }
};

export const getList = (state, entityName) => {
  const list = state[entityName].get("listDraft");
  return list
    .map(row => ({ ...row, url: getRoute(state, entityName, row.id) }))
    .sort((a, b) => a.weight - b.weight);
};

export const isLoading = (state, entityName) =>
  state[entityName].get("fetching");
