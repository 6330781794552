import { StyledColorPickerContainer } from "./StyledColorPicker";
import { GithubPicker } from "react-color";
import { COLOR } from "../../../../constants";
import React from "react";

const ColorPicker = ({ allCells, callback, show }) => {
  return (
    show && (
      <StyledColorPickerContainer>
        <GithubPicker
          width="213px"
          triangle="hide"
          colors={[
            "rgb(190,218,162)",
            "rgb(250,235,158)",
            "rgb(255, 255, 255)",
            "rgb(243,183,192)",
            "rgb(159,207,242)",
            "rgb(58,196,194)",
            "rgb(251 195 135)",
            "rgb(201 180 215)"
          ]}
          color={allCells.length > 1 && allCells[0].color}
          onChange={callback(COLOR)}
        />
      </StyledColorPickerContainer>
    )
  );
};

export default ColorPicker;
