import React from "react";

const GripHandle = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-griphandle"
    fill={color}
  >
    <path d="M16.3 0H12A2 2 0 0 0 10 2v4.3c0 1.1 1 2 2 2h4.3c1.1 0 2-.9 2-2V2c0-1.2-.9-2.1-2-2.1zM37 0h-4.2a2 2 0 0 0-2 2v4.3c0 1.1.9 2 2 2h4.2c1.1 0 2-.9 2-2V2c0-1.2-.9-2.1-2-2.1zM16.2 20.8h-4.1A2 2 0 0 0 10 23v4.2c0 1.1 1 2 2 2h4.3c1.1 0 2-.9 2-2v-4.2c0-1.1-.9-2-2-2zm20.9 0h-4.2a2 2 0 0 0-2 2.1v4.2c0 1.1.9 2 2 2h4.2c1.1 0 2-.9 2-2v-4.2c0-1.1-.9-2-2-2zM16.2 41.7h-4.1a2 2 0 0 0-2.1 2V48c0 1.2 1 2.1 2 2.1h4.3c1.1 0 2-1 2-2v-4.3c0-1.1-.9-2-2-2zm20.9 0h-4.2a2 2 0 0 0-2 2V48c0 1.2.9 2.1 2 2.1h4.2c1.1 0 2-1 2-2v-4.3c0-1.1-.9-2-2-2z" />
  </svg>
);

export default GripHandle;
