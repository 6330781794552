import styled from "styled-components";
import { PrimaryButton, StandardButton } from "../components/Button/index";

export const StyledMediaButton = styled(PrimaryButton)`
  min-width: 40px;
  min-height: 40px;
  border-radius: 4px;
  margin-right: 4px;
  background-color: #0c224e;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledItemMediaEdit = styled.div`
  height: 100%;
  font-size: 12px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  a,
  p {
    padding: 5px 10px;
  }
`;

export const StyledMediaResursButton = styled(StandardButton)`
  min-width: 40px;
  min-height: 40px;
  border-radius: 4px;
  background-color: #384e70;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMediaButtonsContainer = styled.span`
  display: flex;
  height: auto;
  min-width: 80px;
`;

export const StyledEditMedia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;
  ${({ withMargin }) => (withMargin ? "margin-bottom: 10px" : "")};
`;

export const StyledItemImage = styled.div`
  ${({ size }) => !size && " height: 85px;"}
  ${({ size }) => !size && "margin-bottom: 10px;"}
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledItemMedia = styled.div`
  display: flex;
  img {
    ${({ size }) => size && "height: 85px;"}
    max-height: ${({ size }) => (size ? size : "85px")};
    max-width: 100%;
  }

  svg {
    max-height: ${({ size }) => (size ? size : "85px")};
    max-width: 100%;
  }
`;

export const StyledIdContainer = styled.div`
  border: thin solid #999;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const StyledRemoveImageButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;

  &:hover {
    background: #e54e4e;
    padding: 3px 7px 5px;
    top: -11px;
    right: -11px;
  }

  &:active {
    background: #e54e4e;
    top: -10px;
    right: -11px;
  }
`;
