import React from "react";

const Publish = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 35 34"
    width={`${size}px`}
    className="studlicon-publish"
    fill={color}
  >
    <g stroke="#1C7AD4" fill="none" fillRule="evenodd">
      <path strokeLinecap="square" d="M1 .5h17M34 33.5v-17M1 33.5h32M1 .5v32"/>
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.429">
        <path d="M9.5 24.571 28.071 6M20.929 6h7.142v7.143"/>
      </g>
    </g>
  </svg>
);

export default Publish;
