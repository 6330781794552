import React from "react";

const Gamification = ({ size }) => (
  <svg
    xmlns=" https://www.w3.org/2000/svg "
    viewBox="0 0 41 39"
    width={`${size}px`}
    className="studlicon-gamification"
  >
  <path d="M36.75,8.75 L36.75,33.25 L40.25,33.25 L40.25,8.75 L36.75,8.75 Z M29.75,33.25 L33.25,33.25 L33.25,8.75 L29.75,8.75 L29.75,33.25 Z M24.5,8.75 L3.5,8.75 C2.5375,8.75 1.75,9.5375 1.75,10.5 L1.75,31.5 C1.75,32.4625 2.5375,33.25 3.5,33.25 L24.5,33.25 C25.4625,33.25 26.25,32.4625 26.25,31.5 L26.25,10.5 C26.25,9.5375 25.4625,8.75 24.5,8.75 Z M14,13.5625 C16.17,13.5625 17.9375,15.33 17.9375,17.5 C17.9375,19.67 16.17,21.4375 14,21.4375 C11.83,21.4375 10.0625,19.67 10.0625,17.5 C10.0625,15.33 11.83,13.5625 14,13.5625 Z M21.875,29.75 L6.125,29.75 L6.125,28.4375 C6.125,25.8125 11.375,24.5 14,24.5 C16.625,24.5 21.875,25.8125 21.875,28.4375 L21.875,29.75 Z" />
  </svg>
);

export default Gamification;
