import React, { useState } from "react";
import styled from "styled-components";
import { ChevronDown, ChevronRight, Warning } from "../../../shared/components/StudliIcons"
import IconButton from "../IconButton";
import {
  format as dfnsFormat
} from "date-fns";
import { sv } from "date-fns/locale";

const StyledHistoryItemWrapper = styled.div`
`
const StyledHistoryItem = styled.div`
  color: ${ props => props.hasError ? "#b00020" : "#000"};
  display:flex;
  flex-direction: row;
  cursor: ${ props => props.hasError ? 'pointer' : 'default' };
`
const StyledHistoryChildren = styled.div`
  margin-left: 2.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
const StyledIconButton = styled(IconButton)`
  width: 1rem;
`
const StyledChildWrapper = styled.div`
  padding: 1px 0;
`
const StyledWarningWrapper = styled.span`
  margin-left: 0.4rem;
  color: #000;
`
const StyledDateWrapper = styled.div`
  margin-left: 1.2rem;
`
const StyledColumn = styled.div`
  width: 14rem;
  display: flex;
  align-items: end;
`
const StyledErrorDetailsWrapper = styled.div`
  display: none;
`

export const formatDate = dateString => {
  const f = "yyyy-MM-dd"
  const date = new Date(dateString);
  return dfnsFormat(date, f, { locale: sv });
}
export const formatTime = dateString => {
  const f = "HH:mm"
  const date = new Date(dateString);
  return dfnsFormat(date, f, { locale: sv });
}


const HistoryItem = ({ item }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleIconButton = () => {
    setIsOpen(!isOpen)
  }

  return (
    <StyledHistoryItemWrapper>
      <StyledHistoryItem onClick={toggleIconButton} hasError={item.error != null || item.status === "failed"}>
        <StyledColumn>
          <StyledIconButton aria-label="visa fellista" >
            { item.error && isOpen && <ChevronDown size="8"/> }
            { item.error && !isOpen && <ChevronRight size="8"/> }
          </StyledIconButton>
          <div>{ item.env === "preview" ? "Publicerad till Test" : "Publicerad Live"}</div>
          { item.error && <StyledWarningWrapper><Warning size="16"/></StyledWarningWrapper> }
        </StyledColumn>
        <StyledColumn>
          <StyledDateWrapper>{formatDate(item.date)}</StyledDateWrapper>
          <StyledDateWrapper>{formatTime(item.date)}</StyledDateWrapper>
        </StyledColumn>
        <StyledColumn>
          <div>{item.author}</div>
        </StyledColumn>
        
      </StyledHistoryItem>
      { item.error && isOpen && (
        <StyledHistoryChildren>
          { item.error.map((e, i) => (
            <StyledChildWrapper key={i}>
              {e.message}
              <StyledErrorDetailsWrapper>
                {e.buildStep} - {e.details}
              </StyledErrorDetailsWrapper>
            </StyledChildWrapper>
          ))}
        </StyledHistoryChildren>
      )}
    </StyledHistoryItemWrapper>
    
  )
}

export default HistoryItem