import React from "react"
import { ADVENTURES_GROUP } from "../../../constants";
import List from "../../components/List";

const AdventuresGroup = () => {
  return (
    <List
      type={ADVENTURES_GROUP}
      draggable
      title="Äventyrsgrupp" />
  )
}

export default AdventuresGroup