import React from "react";

const CheckboxChecked = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-checkboxchecked"
  >
    <path
      fill="currentColor"
      d="M44.6428571,0 L5.35714286,0 C2.3984375,0 0,2.3984375 0,5.35714286 L0,44.6428571 C0,47.6015625 2.3984375,50 5.35714286,50 L44.6428571,50 C47.6015625,50 50,47.6015625 50,44.6428571 L50,5.35714286 C50,2.3984375 47.6015625,0 44.6428571,0 Z M44.6428571,3.57142857 C45.627567,3.57142857 46.4285714,4.37254464 46.4285714,5.35714286 L46.4285714,44.6428571 C46.4285714,45.6274554 45.627567,46.4285714 44.6428571,46.4285714 L5.35714286,46.4285714 C4.37254464,46.4285714 3.57142857,45.6274554 3.57142857,44.6428571 L3.57142857,5.35714286 C3.57142857,4.37254464 4.37254464,3.57142857 5.35714286,3.57142857 L44.6428571,3.57142857 Z M40.8146205,14.5416295 L39.8714286,13.5907366 C39.350558,13.065625 38.502567,13.0621652 37.9774554,13.5831473 L19.7362723,31.6780134 L12.038058,23.9176339 C11.5171875,23.3925223 10.6691964,23.3890625 10.1440848,23.9100446 L9.19319196,24.8532366 C8.66808036,25.3741071 8.66462054,26.2220982 9.18560268,26.7472098 L18.7702009,36.4092634 C19.2910714,36.934375 20.1390625,36.9378348 20.6641741,36.4168527 L40.8070312,16.4356027 C41.3320312,15.9147321 41.3354911,15.0667411 40.8146205,14.5416295 Z"
    />
  </svg>
);

export default CheckboxChecked;
