import React from "react";
import styled from "styled-components";

const InputArea = styled.textarea.attrs({
  rows: 5,
  cols: 50
})`
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  outline: none;
`;

const TextArea = ({ value, placeholder, onChange, onBlur, disabled, name }) => {
  const _onChange = e => onChange(e.target.value);
  const _onBlur = e => (onBlur ? onBlur(e.target.value, e.target.name) : null);

  return (
    <InputArea
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={_onChange}
      onBlur={_onBlur}
      disabled={disabled}
    />
  );
};

export default TextArea;
