import React from "react";
import styled from "styled-components";
import { Button, StandardButton } from "../../../shared";
import SortableTree from "react-sortable-tree";
import Branch from "./Branch";

export const CatActionWrapper = styled.div`
  position: relative;
`;

export const CatActionContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
export const Top = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
export const Title = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
export const Tree = styled(SortableTree)`
  .category-node {
    user-select: none;
    &.selected {
      background-color: #0078d7;
      color: white;
      padding-left: 7px;
      border-radius: 4px;
    }
  }
`;

export const TreeWrapper = styled.div`
  height: 100%;
  padding: 10;
  color: #333;
`;

export const Middle = styled.div``;

export const MiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
  height: 40vh;
`;

export const Bottom = styled.div`
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.neutralLight};
`;

export const UpdateButton = styled(props => <StandardButton {...props} />)`
  width: 280px;
  margin: 10px;
  border-radius: 5px;
`;
export const InlineSectionButton = styled(props => <Button {...props} />)`
  background-color: transparent;
  border: 0;
  padding: 0 0 10px 0;
  min-width: 25px;

  &:hover,
  &:active {
    background-color: transparent;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 220px;
`;

export const HeaderTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
`;
export const InlineBranchDisplay = styled(Branch)`
  postition: absolute;
  left: 0;
`;

export const CatBranchWrapper = styled.div`
  position: relative;
`;

export const NodeWrapper = styled.div`
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  &.not_first {
    font-weight: normal;
  }
`;
export const IconWrapper = styled.span`
  margin-right: 5px;
`;
