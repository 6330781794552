import styled from "styled-components";
import { darken } from "polished";
import Button from "../../../Button";
import Link from "../../../Link";
import { themePrimary, white } from "../../../../../styles/shared/colors";

export const StyledActionWrapper = styled.div`
  display: flex;
  padding: ${({ isLocked }) => (isLocked ? "0" : "0 9px 3px 20px")};
  justify-content: space-between;
  align-items: center;
  height: 48px;
  opacity: 0;
  background-color: ${props => (props.isSelected ? "#b0d0ed" : "#e6f0f9")};
  transition: opacity 200ms;

  .DataTable_row:hover & {
    opacity: 1;
    border-left: 1px solid ${props => props.theme.neutralQuaternary};
  }
`;

export const StyledActionLink = styled(Link)`
  width: 40px;
  height: 40px;
  color: ${props => props.theme.red};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  & > span {
    margin-right: 0;
    width: 25px;
    height: 25px;

    &:hover {
      fill: ${props => (props.selected ? "white" : props.theme.themePrimary)};
    }
  }
`;

export const StyledLockedBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${themePrimary};
  color: ${white};
`;

export const StyledActionButton = styled(Button).attrs({
  transparent: true,
  outline: true
})`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 30px;
  margin-right: 10px;

  & > span {
    margin-right: 0;
    & > span {
      width: 25px;
      height: 25px;
    }
  }

  &:hover {
    background: transparent;
    fill: ${props => (props.selected ? "white" : props.theme.themePrimary)};
    & > span {
      width: 25px;
      height: 25px;
    }
  }

  &:disabled {
    & > span {
      fill: ${props => darken(0.2, props.theme.white)};
      & > span {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const StyledActionPubButton = styled(StyledActionButton)`
  .studlicon-circle {
    fill: ${props => props.theme.neutralPrimary};
  }
  .studlicon-arrowcircleup {
    fill: ${props => props.theme.green};
  }
`;
