import { Column } from "react-virtualized";
import CountRenderer from "../Renderer/Count";

const CountColumn = () => null;

CountColumn.propTypes = Object.assign({}, Column.propTypes);
CountColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: CountRenderer,
  draggable: true
});

export default CountColumn;
