import PluginHoc from "../../../shared/PluginHoc";
import { IMAGE } from "../../../constants";

const Image = ({ pluginHeader, useModal }) => {
  useModal(IMAGE);

  return pluginHeader;
};

export default PluginHoc({
  Component: Image
});
