import SortMachine from "./components/SortMachine/SortMachine";
import { SORT_MACHINE } from "../constants";
import {
  defaultSortMachineData,
  defaultSortMachineSettings
} from "./components/SortMachine/SortMachineHelpers";

const getUsedResources = ({ data }) => {
  let items =
    data &&
    data.items &&
    data.items.reduce((acc, item) => {
      if (item.mediaresource && item.mediaresource.data) {
        return acc.concat(item.mediaresource.data.id);
      }
      return acc;
    }, []);

  let boxes =
    data &&
    data.boxes &&
    data.boxes.reduce((acc, box) => {
      if (box.mediaresource && box.mediaresource.data) {
        return acc.concat(box.mediaresource.data.id);
      }
      return acc;
    }, []);

  if (items && boxes) {
    return items.concat(boxes);
  } else if (items || boxes) {
    return items ? items : boxes;
  } else {
    return undefined;
  }
};

const types = [SORT_MACHINE];

export const createSortMachinePlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: SortMachine,
        style: { padding: 0 },
        props: {
          data: { ...defaultSortMachineData(), ...entity.data },
          settings: { ...defaultSortMachineSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types,
  getUsedResources
});

export default createSortMachinePlugin;

export const emptyBlocks = {
  [SORT_MACHINE]: {
    type: SORT_MACHINE,
    data: defaultSortMachineData(),
    settings: defaultSortMachineSettings()
  }
};
