import { RadioButton } from "../../../shared/components/RadioButton";

const customStyles = {
  containerWidth: "auto",
  width: "40px",
  cursor: "pointer",
  backgroundColor: "#FFFF",
  color: "black",
  borderRadius: "5px",
  fontSize: "16px"
};

const buttonStyles = {
  border: "1px solid #999",
  borderRadius: "5px",
  minWidth: "40px",
  minHeight: "40px",
  marginRight: "5px"
};

const ChartButton = ({
  id,
  content,
  callback,
  isActive,
  xButtonStyles,
  xOverrideStyles,
  show,
  title
}) =>
  show && (
    <RadioButton
      key={id}
      isActive={isActive}
      content={content}
      buttonStyle={{ ...buttonStyles, ...xButtonStyles }}
      overrideStyles={{ ...customStyles, ...xOverrideStyles }}
      callback={callback}
      title={title}
    />
  );

export default ChartButton;
