import styled from 'styled-components';
import { Button } from "../../../../shared";
import PrimaryButton from "../../../../shared/components/Button/Primary"

export const StyledFileSectionTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  button {
    margin: 0;
    margin-left: auto;
  }
`;

export const StyledFileSectionBlock = styled.div`
  margin-top: 60px;
`;

export const StyledMediaButton = styled(Button)`
  border-radius: 4px;
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  font-weight: bold;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`

export const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
`