import React from "react";

const ArrowDown = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-arrowdown"
  >
    <path
      transform="translate(25.000000, 25.000000) rotate(-180.000000) translate(-25.000000, -25.000000) "
      d="M20.08738,46.4286631 C18.8022951,46.4286631 17.7604963,45.4006761 17.7604963,44.1327399 L17.7604963,24.9996373 L4.52973079,24.9996373 C3.72386646,24.9996373 3.32008601,24.0385012 3.88956348,23.4759041 L23.3527989,4.24615217 C24.2617007,3.34818415 25.7365739,3.34807254 26.6454757,4.24615217 L46.1088242,23.4759041 C46.6783017,24.0385012 46.2744081,24.9996373 45.4686569,24.9996373 L32.2377783,24.9996373 L32.2377783,44.1327399 C32.2377783,45.4007877 31.1959795,46.4286631 29.9108946,46.4286631 L20.08738,46.4286631 Z M20.08738,50 L29.9110077,50 C33.1896596,50 35.8570989,47.3679247 35.8570989,44.1327399 L35.8570989,28.5709742 L45.4685438,28.5709742 C49.4845192,28.5709742 51.519256,23.7675261 48.6696066,20.9521965 L29.2063712,1.72244463 C26.8820888,-0.573924996 23.1166382,-0.574371413 20.7919034,1.72244463 L1.328668,20.9520849 C-1.5092186,23.7558076 0.497242217,28.5708626 4.52973079,28.5708626 L14.1411757,28.5708626 L14.1411757,44.1326283 C14.1411757,47.3679247 16.808615,50 20.08738,50 Z"
    />
  </svg>
);

export default ArrowDown;
