import styled from "styled-components";
import Button from "../../../shared/components/Button";
import { Field } from "../../../products/components/Input";

export const StyledMessageEditorWrapper = styled.div`
  display: flex;
`;
export const StyledEditorColumn = styled.div`
  width: 43rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 2rem;
`;

export const StyledSection = styled.div`
  margin-bottom: 1.6rem;
  width: 100%;
`;
export const StyledStatusColumn = styled.div`
  margin-left: 2rem;
  width: 19rem;
`;

export const StyledDateWrapper = styled.div`
  margin-right: 1rem;
`;

export const StyledTimeAndDateWrapper = styled.div`
  display: flex;
  padding: 1.625rem 0 0.5rem 0;
`;

export const ErrorMsg = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0 0 1rem 0.25rem;
`;

export const StyledErrorContent = styled.div`
  height: 2rem;
`;

export const ChipPostWrapper = styled.div`
  margin-top: 3rem;
  width: 100%;
  height: 10rem;
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
`;

export const StyledStatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const StyledField = styled(Field)`
  width: 300px;
`;
export const StyledInputWrapper = styled.div`
  display: flex;
`;
export const StyledTimeCover = styled.div`
  background-color: white;
  position: absolute;
  width: 9rem;
  font-size: 16px;
  padding: 0.25rem;
  border-radius: 5px;
  margin-top: 6px;
  margin-left: 10px;
  z-index: 2;
`;
export const StyledIcon = styled.div`
  padding-right: 10px;
  padding-top: 10px;
`;

export const StyledButton = styled(Button)`
  margin: 0;
  fill: ${props => props.theme.themePrimary};
  margin-bottom: 2rem;
`;

export const StyledMediaWrapper = styled.div`
  margin-top: 1.6rem;
  justify-content: center;
`;
export const StyledGroup = styled.div`
  margin-right: 2rem;
  width: 100%;
`;
export const StyledMediaButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const StyledSaveButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
`;
