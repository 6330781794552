import React from "react"
import { ADVENTURES_CARD } from "../../../constants";
import List from "../../components/List";

const AdventuresCard = () => {
  return (
    <List
      type={ADVENTURES_CARD}
      draggable={true}
      title={'Problemkort'} />
  )
}

export default AdventuresCard