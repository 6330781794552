import styled from "styled-components";
import { CancelButton } from "../../../../shared/components/Button";
import PrimaryButton from "../../../../shared/components/Button/Primary";

const opacityTransition = "200ms";

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(50, 77, 114, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: ${({ isActive = false }) => (isActive ? 10 : -999)};
  opacity: ${({ isActive = false }) => (isActive ? 1 : 0)};

  -webkit-transition: opacity ${opacityTransition} ease-in-out;
  -moz-transition: opacity ${opacityTransition} ease-in-out;
  -ms-transition: opacity ${opacityTransition} ease-in-out;
  -o-transition: opacity ${opacityTransition} ease-in-out;
  transition: opacity ${opacityTransition} ease-in-out;
`;

export const StyledModalContainer = styled.div`
  background: #fff;
  height: 200px;
  box-shadow: 0 1px 4px -2px;
  border: 1px solid #cecece;
  width: 300px;
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledModalButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: -5px;
`;

export const StyledModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCancelButton = styled(CancelButton)`
  margin: 5px;
`;

export const StyledRemoveButton = styled(PrimaryButton)`
  margin: 5px;
`;

export const StyledSpanText = styled.span`
  font-weight: bold;
`;
