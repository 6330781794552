import { put, select, take } from "redux-saga/effects";
import { fetchEntities, fetchMediaList } from "../api/actions";
import { FETCH_ENTITIES_SUCCESS } from "../../api";
import {
  selectAdventureGroupId,
  selectAdventureId,
  selectAdventureCardId
} from "./selectors";
import { actionCreator } from "../../shared";
import {
  ADVENTURES,
  ADVENTURES_CARD,
  ADVENTURES_CARD_EDITOR,
  ADVENTURES_GROUP,
  MARKERS
} from "../../constants";
import { selectEntityId } from "../../store/selectors";

export function* onGetMediaList(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const adventureGroupId = yield select(selectAdventureGroupId);
  const adventureId = yield select(selectAdventureId);
  const adventureCardId = yield select(selectAdventureCardId);
  const payload = { entityId, adventureGroupId, adventureId, adventureCardId };
  yield put(fetchMediaList(entityName, apiAction, payload));
}

export function* onRoute(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const adventureGroupId = yield select(selectAdventureGroupId);
  const adventureId = yield select(selectAdventureId);
  const adventureCardId = yield select(selectAdventureCardId);
  const name = entityName.entityName ? entityName.entityName : entityName;

  let payload;

  switch (name) {
    case MARKERS:
    case ADVENTURES_GROUP:
      payload = Object.assign({ entityId }, payload);
      break;
    case ADVENTURES:
      payload = Object.assign({ entityId, adventureGroupId }, payload);
      break;
    case ADVENTURES_CARD:
      payload = Object.assign(
        { entityId, adventureGroupId, adventureId },
        payload
      );
      break;
    case ADVENTURES_CARD_EDITOR:
      payload = Object.assign(
        { entityId, adventureGroupId, adventureId, adventureCardId },
        payload
      );
      break;
    default:
      break;
  }

  yield put(fetchEntities(name, apiAction, payload));

  yield take(actionCreator(name, FETCH_ENTITIES_SUCCESS));
}
