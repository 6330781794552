import React from "react";
import { Editor, Range } from "slate";
import { useSlate } from "slate-react";
import IconButton from "../../../products/components/IconButton";
import { Bold, Italic, Underline } from "react-feather";
import styled from "styled-components";
import { selectionHasTitle } from "./helper";

const StyledIconButton = styled(IconButton)`
  background-color: ${props => (props.active ? "#ccc" : "transparent")};
  font-size: 18px;
  height: 23px;
  padding: 0 4px 4px;
`;

const ICONSIZE = "16px";
const InlineEditorButton = ({ format }) => {
  const editor = useSlate();

  const isMarkActive = (ed, form) => {
    const marks = Editor.marks(ed);
    return marks ? marks[form] === true : false;
  };

  const toggleMark = (ed, form) => {
    if (selectionHasTitle(editor)) return;

    const isActive = isMarkActive(ed, form);
    const { selection } = ed;
    const isCollapsed = selection && Range.isCollapsed(selection);

    if (isCollapsed) return;

    if (isActive) {
      Editor.removeMark(ed, form);
    } else {
      Editor.addMark(ed, form, true);
    }
  };

  return (
    <StyledIconButton
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {format === "bold" && <Bold width={ICONSIZE} height={ICONSIZE} />}
      {format === "italic" && <Italic width={ICONSIZE} height={ICONSIZE} />}
      {format === "underline" && (
        <Underline width={ICONSIZE} height={ICONSIZE} />
      )}
    </StyledIconButton>
  );
};

export default InlineEditorButton;
