import React from "react";
import { InputArea } from "./StyledTextArea";

const TextArea = ({ value, placeholder, onChange, target, disabled }) => {
  const _onChange = e => onChange(e.target.value, target);

  return (
    <InputArea
      value={value}
      placeholder={placeholder}
      onChange={_onChange}
      disabled={disabled}
    />
  );
};

export default TextArea;
