import {
  ADVENTURES,
  ADVENTURES_CARD,
  ADVENTURES_GROUP,
  ADVENTURES_CARD_EDITOR,
  MARKERS
} from "../constants";
import AdventuresTabPanel from "./components/AdventuresTabPanel";
import { AdventureCardDetailed } from "./views";

export const ROUTE_ADVENTURES_ACHIVEMENTS = "ROUTE_ADVENTURES_ACHIVEMENTS";
export const ROUTE_ADVENTURES_GROUP = "ROUTE_ADVENTURES_GROUP";
export const ROUTE_ADVENTURES = "ROUTE_ADVENTURES";
export const ROUTE_ADVENTURES_CARD = "ROUTE_ADVENTURES_CARD";
export const ROUTE_ADVENTURES_CARD_EDITOR = "ROUTE_ADVENTURES_CARD_EDITOR";
export const ROUTE_MARKERS = "ROUTE_MARKERS";

export const routes = {
  [ROUTE_ADVENTURES_GROUP]: {
    path: "/products/:entityId/adventure-editor/adventure-groups",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Spelifiering",
    icon: "Gamification",
    confirmLeave: state =>
      confirmDirtyDraftStateLeave(ADVENTURES_GROUP, state),
    childRoutes: [
      ROUTE_ADVENTURES,
      ROUTE_ADVENTURES_CARD,
      ROUTE_ADVENTURES_CARD_EDITOR,
      ROUTE_MARKERS
    ]
  },
  [ROUTE_ADVENTURES]: {
    path:
      "/products/:entityId/adventure-editor/adventure-group/:adventureGroupId/adventures",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    confirmLeave: state =>
      confirmDirtyDraftStateLeave(ADVENTURES, state)
  },
  [ROUTE_ADVENTURES_CARD]: {
    path:
      "/products/:entityId/adventure-editor/adventure-group/:adventureGroupId/adventure/:adventureId/adventures-cards",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    confirmLeave: state =>
      confirmDirtyDraftStateLeave(ADVENTURES_CARD, state)
  },
  [ROUTE_ADVENTURES_CARD_EDITOR]: {
    path:
      "/products/:entityId/adventure-editor/adventure-group/:adventureGroupId/adventure/:adventureId/adventure-card/:adventureCardId",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    confirmLeave: state =>
      confirmDirtyDraftStateLeave(ADVENTURES_CARD_EDITOR, state)
  },
  [ROUTE_MARKERS]: {
    path: "/products/:entityId/adventure-editor/markers",
    query: {},
    noServiceBar: true,
    hideFilters: true
  }
};

export const views = {
  [ROUTE_ADVENTURES_GROUP]: AdventuresTabPanel(ADVENTURES_GROUP),
  [ROUTE_ADVENTURES]: AdventuresTabPanel(ADVENTURES),
  [ROUTE_ADVENTURES_CARD]: AdventuresTabPanel(ADVENTURES_CARD),
  [ROUTE_ADVENTURES_CARD_EDITOR]: AdventureCardDetailed,
  [ROUTE_MARKERS]: AdventuresTabPanel(MARKERS)
};

export const splitAndBuildRouteName = name => {
  const array = name.match(/[A-Z][a-z]+/g);
  let string = "ROUTE";

  array.forEach(element => {
    string += `_${element.toUpperCase()}`;
  });

  return string;
};

const confirmDirtyDraftStateLeave = (route, state) => {
  const draftState = state[route].get("draftState");
  if (draftState !== "dirty") {
    return;
  }
  return `Du har gjort ändringar som inte kommer sparas om du väljer att lämna sidan.`;
};
