import { Column } from "react-virtualized";
import FileStatusRenderer from "../Renderer/FileStatusRenderer";

const FileStatusColumn = () => null;

FileStatusColumn.propTypes = { ...Column.propTypes };
FileStatusColumn.defaultProps = {
  ...Column.defaultProps,
  ...{
    cellRenderer: FileStatusRenderer,
    draggable: true
  }
};

export default FileStatusColumn;
