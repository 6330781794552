import { all, fork } from "redux-saga/effects";

import watchRemoveInitialLoadingScreen from "./loading";
import {
  watchApplicationMount,
  watchApplicationReady,
  watchAuthReady
} from "./application";
import { watchShowFlash, watchAddFlash } from "./flasher";

export default function* rootSaga() {
  yield all([
    fork(watchRemoveInitialLoadingScreen),
    fork(watchApplicationMount),
    fork(watchAuthReady),
    fork(watchApplicationReady),
    fork(watchShowFlash),
    fork(watchAddFlash)
  ]);
}
