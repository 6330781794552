import { generalErrorHandler } from "../../api";
import {
  fetchClassroomGroups,
  fetchClassroomGroupsStart,
  fetchClassroomGroupsSuccess,
  fetchClassroomGroupsFailed,
  fetchClassroomGroupsEnd,
  resetBeforeFilterSortChange,
  fetchClassroomGroupDetails,
  fetchClassroomGroupDetailsStart,
  fetchClassroomGroupDetailsSuccess,
  fetchClassroomGroupDetailsFailed,
  fetchClassroomGroupDetailsEnd,
  fetchPreparedAssignmentsForProductSuccess,
  clearPreparedAssignmentsSuccess,
  fetchFormativeTestsForProduct,
  fetchFormativeTestsForProductSuccess,
  deleteFormativeTestsSuccess,
  createFormativeQuestionSuccess,
  fetchFormativeQuestionDataSuccess,
  fetchFormativeQuestionData,
  fetchClassroomGroupPermissionSuccess,
  fetchClassroomStickersSuccess,
  fetchClassroomStickers
} from "../api/actions";
import {
  addFlash,
  setSections,
  mainNavigation,
  setTitle,
  gotoRoute
} from "../../shared";
import { call, put, select } from "redux-saga/effects";
import * as api from "../api/requests";
import {
  selectFetchLimit,
  selectSearchParams,
  selectPage,
  selectQuery,
  selectClassroomTitle,
  selectLocationPayload,
  selectPreparedAssignmentPostData
} from "../store/selectors";
import { selectPrePath } from "../../store/selectors";
import {
  ROUTE_CLASSROOM_DETAILS,
  ROUTE_FORMATIVE_QUESTION_EDIT
} from "../routes";

export function* onFetchError(action) {
  yield put(
    addFlash(
      `Error when fetching classroom${
        action.error ? ": " + action.error.toString() : ""
      }`,
      "error"
    )
  );
}

export function* onClassroomListRoute() {
  let page = yield select(selectPage);
  const params = yield select(selectQuery);

  if (params.page) {
    page = params.page;
  }

  const args = yield select(st => st.Classroom.get("args").toJS()) || {};
  const fetchLimit = yield select(selectFetchLimit);

  yield put(
    fetchClassroomGroups(args, {
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

export function* onFetchClassroomGroups(action) {
  let batchIndex = action.batchIndex;
  if (batchIndex === undefined) {
    batchIndex = yield select(st => st.Classroom.get("page"));
  }

  yield put(fetchClassroomGroupsStart());
  try {
    const result = yield call(
      api.listClassroomGroups,
      "classroomgroup",
      action.searchParameters
    );
    yield put(
      fetchClassroomGroupsSuccess(
        result.data.data,
        result.data.metadata,
        action.searchParameters,
        batchIndex
      )
    );
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchClassroomGroupsFailed(error));
  } finally {
    yield put(fetchClassroomGroupsEnd());
  }
}

/**
 * Fetches list of posts for a specific page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} args
 * @param {*} params
 * @param {*} page
 */
function* fetchPage(args, params, page) {
  const fetchLimit = yield select(selectFetchLimit);
  yield put(
    fetchClassroomGroups(args, {
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

/**
 * Sort the list
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} param2
 */
export function* sortClassroomGroupList(sort) {
  yield put(resetBeforeFilterSortChange());

  const searchParams = yield select(selectSearchParams);
  const params = { ...searchParams, sort: sort.sort };

  yield call(fetchPage, {}, params, 1);
}

export function* onCreateClassroomGroup(data) {
  try {
    const result = yield call(api.createClassroomgroup, data);
    yield put({
      type: ROUTE_CLASSROOM_DETAILS,
      payload: { classroomGroupEntityId: result.data.data }
    });
    yield put(addFlash(`En ny klassrumsgrupp skapades`, "success"));
  } catch (error) {
    yield put(
      addFlash(`Något gick fel när klassrumsgruppen skulle skapas.`, "error")
    );
  }
}

export function* onClassroomGroupDetailRoute(action) {
  yield put(fetchClassroomGroupDetails(action.payload));

  const params = yield select(selectQuery);
  const args = yield select(st => st.Classroom.get("args").toJS()) || {};
  const fetchLimit = yield select(selectFetchLimit);
  yield put(clearPreparedAssignmentsSuccess());
  yield put(
    fetchClassroomGroups(args, {
      offset: 0,
      num_result: fetchLimit,
      ...params
    })
  );
}

export function* onFetchClassroomGroupDetails(action) {
  yield put(fetchClassroomGroupDetailsStart());
  try {
    const result = yield call(
      api.classroomGroupDetails,
      action.payload.classroomGroupEntityId
    );
    const details = result.data.data;
    if (details.entity && details.entity.typeId === 2) {
      yield put(fetchClassroomStickers(details.entity.id));
    }
    yield put(
      fetchClassroomGroupDetailsSuccess(result.data.data, result.data.metadata)
    );
    yield put(setTitle(result.data.data.entity.title));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchClassroomGroupDetailsFailed(error));
  } finally {
    yield put(fetchClassroomGroupDetailsEnd());
  }
}

export function* onUpdateClassroomgroupDetails(action) {
  try {
    yield call(
      api.updateClassroomgroupDetails,
      action.classroomGroupEntityId,
      action.data
    );
    yield put(addFlash(`Ändringarna är sparade!`, "success"));
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: action.classroomGroupEntityId }
    });
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onAddProductToClassroomgroup(action) {
  try {
    yield call(
      api.addProductToClassroomGroup,
      action.productEntityId,
      action.classroomGroupEntityId
    );
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: action.classroomGroupEntityId }
    });
  } catch (error) {
    yield put(
      addFlash(`Något gick fel när produkten skulle läggas till.`, "error")
    );
  }
}

export function* onDeleteProductFromClassroomgroup(action) {
  try {
    yield call(
      api.deleteProductFromClassroomGroup,
      action.productEntityId,
      action.classroomGroupEntityId
    );
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: action.classroomGroupEntityId }
    });
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* applicationEnsureClassroom() {
  const prePath = yield select(selectPrePath);
  if (prePath !== "klassrum") {
    return;
  }
  const title = yield select(selectClassroomTitle);
  yield put(setSections(mainNavigation));
  yield put(setTitle(title));
}

export function* onPublishClassroomGroup({ classroomGroupEntityId }) {
  try {
    yield call(api.publishClassroomGroup, classroomGroupEntityId);
    yield put(addFlash(`Klassrumsgruppen är nu publicerad!`, "success"));
  } catch (err) {
    yield put(
      addFlash(
        `Något gick fel när klassrumsgruppen skulle publiceras.`,
        "error"
      )
    );
  }
}

export function* onFetchPreparedAssignments({
  classroomGroupEntityId,
  postId
}) {
  try {
    if (postId > 0) {
      const result = yield call(
        api.fetchPreparedAssignments,
        classroomGroupEntityId,
        postId
      );
      yield put(fetchPreparedAssignmentsForProductSuccess(result.data.data));
    } else {
      yield put(fetchPreparedAssignmentsForProductSuccess({}));
    }
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDeletePreparedAssignments({
  classroomGroupEntityId,
  postId
}) {
  const postdata = yield select(selectPreparedAssignmentPostData);
  try {
    yield call(
      api.deletePreparedAssignments,
      classroomGroupEntityId,
      postId,
      postdata
    );
    yield put(clearPreparedAssignmentsSuccess());
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: classroomGroupEntityId }
    });
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onUploadPreparedAssignments({
  data,
  classroomGroupEntityId,
  postId,
  productId
}) {
  const postdata = yield select(selectPreparedAssignmentPostData);
  const upload = { ...postdata, data: JSON.parse(data) };
  try {
    if (!postId) {
      const result = yield call(
        api.createPreparedAssignmentFile,
        upload,
        classroomGroupEntityId,
        productId
      );
      postId = result?.data?.data?.id;
    } else {
      yield call(
        api.uploadPreparedAssignmentFile,
        upload,
        classroomGroupEntityId,
        postId
      );
    }
    yield call(onFetchPreparedAssignments, { classroomGroupEntityId, postId });
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: classroomGroupEntityId }
    });
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDownloadPreparedAssignments() {
  const postdata = yield select(selectPreparedAssignmentPostData);
  try {
    yield call(api.downloadPreparedAssignmentFile, postdata);
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onPublishPreparedAssignments({
  classroomGroupEntityId,
  postIds
}) {
  try {
    yield call(api.publishPreparedAssignments, classroomGroupEntityId, postIds);
    yield put(addFlash("De förberedda uppdragen är publicerade", "success"));
    yield call(onFetchClassroomGroupDetails, {
      payload: { classroomGroupEntityId: classroomGroupEntityId }
    });
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

// Formative tests
/**
 *
 * @param {*} param0
 */
export function* onFetchFormativeTests({ classroomGroupEntityId, postId }) {
  try {
    const data = yield call(
      api.fetchFormativeTests,
      classroomGroupEntityId,
      postId
    );
    yield put(fetchFormativeTestsForProductSuccess(data));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onPublishFormativeTests({ classroomGroupEntityId, postIds }) {
  try {
    yield call(api.publishFormativeTests, classroomGroupEntityId, postIds);
    yield put(
      addFlash("Formativa tester och frågor har blivit publicerade", "success")
    );
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onUploadFormativeTests({
  classroomGroupEntityId,
  postId,
  data
}) {
  try {
    yield call(
      api.uploadFormativeTestsFile,
      classroomGroupEntityId,
      postId,
      data
    );
    yield call(onFetchFormativeTests, classroomGroupEntityId, postId);
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDeleteFormativeTests({ classroomGroupEntityId, postId }) {
  try {
    yield call(api.deleteFormativeTests, classroomGroupEntityId, postId);
    yield put(deleteFormativeTestsSuccess());
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDownloadFormativeTests({ classroomGroupEntityId, postId }) {
  try {
    yield call(api.downloadFormativeTests, classroomGroupEntityId, postId);
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDeleteFormativeQuestion({
  classroomGroupEntityId,
  questionId,
  postId
}) {
  try {
    yield call(
      api.deleteFormativeQuestion,
      classroomGroupEntityId,
      questionId,
      postId
    );
    yield put(fetchFormativeTestsForProduct(classroomGroupEntityId, postId));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onCreateFormativeQuestions({
  classroomGroupEntityId,
  postId
}) {
  const data = {
    json: "",
    title: "Undefined",
    images: [],
    audio: []
  };
  try {
    const result = yield call(
      api.createFormativeQuestion,
      classroomGroupEntityId,
      postId,
      data
    );
    yield put(createFormativeQuestionSuccess({ ...data, id: result.postId }));
    yield put(
      gotoRoute(ROUTE_FORMATIVE_QUESTION_EDIT, {
        classroomGroupEntityId,
        postId: result.postId
      })
    );
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onFetchFormativeQuestionData({ postId }) {
  try {
    const result = yield call(api.fetchFormativeQuestionData, postId);
    yield put(fetchFormativeQuestionDataSuccess(result.data));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onFormativeQuestionEditRoute() {
  const { postId } = yield select(selectLocationPayload);
  yield put(fetchFormativeQuestionData(postId));
}

export function* onUpdateFormativeQuestionData({ postId, data }) {
  try {
    yield call(api.updateFormativeQuestionData, postId, data);
    yield put(fetchFormativeQuestionData(postId));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDeleteFormativeQuestionMediaItem({ mediaId }) {
  const { postId } = yield select(selectLocationPayload);
  try {
    yield call(api.deleteFormativeQuestionsMediaItem, postId, mediaId);
    yield put(fetchFormativeQuestionData(postId));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onDeleteFormativeQuestionMediaSection({ mediaType }) {
  const { postId } = yield select(selectLocationPayload);
  try {
    yield call(api.deleteFormativeQuestionsMediaSection, postId, mediaType);

    yield put(fetchFormativeQuestionData(postId));
  } catch (err) {
    yield put(addFlash("Något gick fel ", "error"));
  }
}

export function* onFilterClassroomGroups({ filter }) {
  yield put(resetBeforeFilterSortChange());
  const searchParams = yield select(selectSearchParams);
  const params = { ...searchParams, ...filter };
  if (params.search === "") {
    delete params.search;
  }
  yield call(fetchPage, {}, params, 1);
}

export function* onFetchClassroomPermissions() {
  try {
    const result = yield call(api.fetchClassroomGroupPermissions);
    yield put(fetchClassroomGroupPermissionSuccess(result.data.permissions));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onFetchClassroomStickers(action) {
  const classroomGroupEntityId = action.classroomGroupEntityId;
  try {
    const result = yield call(
      api.fetchClassroomStickers,
      classroomGroupEntityId
    );
    yield put(fetchClassroomStickersSuccess(result.data));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onDeleteStickers(action) {
  const payload = yield select(selectLocationPayload);
  const classroomGroupEntityId =
    action.classroomGroupEntityId || payload.classroomGroupEntityId;
  const ids = action.stickerIds || action.postIds;
  try {
    yield call(api.deleteStickers, classroomGroupEntityId, ids);
    yield put(addFlash(`${ids.length} stickers har tagits bort`, "success"));
    yield put(fetchClassroomStickers(classroomGroupEntityId));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onAddStickersToClassroomgroup(action) {
  const classroomGroupEntityId = action.classroomGroupEntityId;
  const stickers = action.stickers;
  // Extract stickerIds
  let stickerIds = [];
  stickers.forEach(sticker => {
    stickerIds.push(sticker.id);
  });
  try {
    yield call(
      api.addStickersToClassroomgroup,
      classroomGroupEntityId,
      stickerIds
    );
    yield put(fetchClassroomStickers(classroomGroupEntityId));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onPublishStickers({ classroomGroupEntityId }) {
  try {
    yield call(api.publishStickers, classroomGroupEntityId);
    yield put(addFlash(`Stickers har publicerats`, "success"));
    yield put(fetchClassroomStickers(classroomGroupEntityId));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}
