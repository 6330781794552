import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Field } from "../../../products/components/Input";
import { getFetchLimit } from "../../store/selectors";
import { setFetchLimit } from "../../api/actions";

const StyledWrapper = styled.div`
  margin-bottom: 8px;
  align-self: flex-end;
  margin-left: 17rem;
`;

const SetFetchLimit = () => {
  const pageLimit = useSelector(getFetchLimit);
  const [fetchLimitValue, setFetchLimitValue] = useState(pageLimit);
  const dispatch = useDispatch();

  const saveFetchLimit = () => {
    if (fetchLimitValue > 0) {
      dispatch(setFetchLimit(fetchLimitValue));
    }
  };

  return (
    <StyledWrapper>
      <Field
        value={fetchLimitValue}
        onChange={val => setFetchLimitValue(val)}
        onEnter={saveFetchLimit}
        disabled={false}
        label="Visa per sida"
        name="fetchLimit"
        style={{ width: "120px" }}
      />
    </StyledWrapper>
  );
};

export default SetFetchLimit;
