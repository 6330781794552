/**
 * Select current page number from state
 * @param {*} state
 * @param {string} entityName
 * @returns {number}
 */
export const selectPage = (state, entityName) => state[entityName].get("page");

/**
 * Select current fetch limit from state
 * @param {*} state
 * @param {string} entityName
 * @returns {number}
 */
export const selectFetchLimit = (state, entityName) =>
  state[entityName].get("fetchLimit");

/**
 * Select query object from state
 * @param {*} state
 * @returns {object}
 */
export const selectQuery = state => state.location.query || {};

/**
 * Selects current post id from state
 * @param {*} state
 * @returns {number}
 */
export const selectPostId = state => state.location.payload.id;

/**
 * Selects current filters from state
 * @param {*} state
 * @returns {object}
 */
export const selectFilters = state =>
  state.location.routesMap[state.location.type].filters;

/**
 * Selects current search params from state
 * @param {*} state
 * @param {string} entityName
 * @returns {object}
 */
export const selectSearchParams = (state, entityName) =>
  state[entityName].get("searchParameters").toJS();

/**
 * Selects Active item in carousel
 * @param {*} state
 * @returns {object}
 */
export const selectCarouselActive = state => state.Carousel.active;

/**
 * Selects a specific buffer from state
 * @param {*} state
 * @param {string} entityName
 * @param {number} batchIndex
 * @returns {object}
 */
export const selectBuffer = (state, entityName, batchIndex) =>
  state[entityName].get("buffer").get(batchIndex);

/**
 * Selects goalsDraft from the state
 * @param state
 */
export const selectGoalsDraft = state => state.Structure.get("goalsDraft");

/**
 * Selects the goals undo array from the state
 * @param state
 * @returns {any | *}
 */
export const selectGoalsUndoArr = state =>
  state.Structure.getIn(["goalsReplication", "undo"]);

/**
 * Selects the goals redo array from the state
 * @param state
 * @returns {any | *}
 */
export const selectGoalsRedoArr = state =>
  state.Structure.getIn(["goalsReplication", "redo"]);

/**
 * Selects the temporary data for a new post. Data like labels, tags, difficulty,
 * @param state
 * @returns {any | *}
 */
export const selectNewPostTmpData = (state, entityName) =>
  state[entityName].get("newDataTmp");
