import * as React from "react";
import Checkbox from "../../../shared/components/Checkbox";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { GripHandle } from "../../../shared/components/StudliIcons";
import Text from "../../../shared/components/Text/Text";
import {
  StyledOption,
  StyledOptionContainer,
  StyledOptionContentWrapper,
  StyledColumnWrapper,
  StyledDragHandleWrapper,
  StyledCheckboxWrapper,
  StyledButton,
  StyledOptionEditText,
  StyledAddInputWrapper,
  StyledEditSoundWrapper,
  StyledMediaButton,
  StyledMediaResourceWrapper,
  StyledTextToolbarButton
} from "./StyledMultipleChoice";
import {
  getMedia,
  renderMedia,
  renderEditMedia
} from "../../../shared/MediaHandler/MediaHandler";

export const OptionItem = SortableElement(({ value: data, ...rest }) => {
  const {
    indx,
    onChangeFn,
    onRemoveFn,
    actions,
    resources,
    editBtnFn,
    onMediaChange,
    onMediaResourceChange,
    onDuplicateOption,
    settings,
    storeDraft,
    draftTarget,
    updatedItem,
    callbackUpdatedItem
  } = rest;

  const [showTextToolbar, setTextToolbar] = React.useState(false);

  /**
   * Check an option
   */
  const _onCheck = () => onChangeFn(indx, { correct: !data.isCorrect });

  /**
   * Remove an option
   */
  const removeItem = () => onRemoveFn(indx);

  /**
   * Duplicate an option
   */
  const duplicateItem = () => onDuplicateOption(indx);

  /**
   * Show/hide text area toolbar
   */
  const toggleTextOptions = () => {
    setTextToolbar(!showTextToolbar);
  };

  /**
   * Edit text
   * @param {*} v
   */
  const onEditChange = (keyType, text, target) =>
    editBtnFn(indx, { text: text });

  /**
   * render the edit text
   * @param {*} data
   * @param {*} actions
   */
  const renderTextEdit = (data, actions) => {
    if (data.text !== undefined) {
      return (
        <StyledOptionEditText>
          <StyledAddInputWrapper>
            <Text
              data={data}
              settings={{
                useShortToolbar: true,
                useToolbar: showTextToolbar,
                ...settings
              }}
              storeDraft={storeDraft}
              draftTarget={draftTarget}
              updateData={onEditChange}
              updatedItem={updatedItem}
              callbackUpdatedItem={callbackUpdatedItem}
            />
          </StyledAddInputWrapper>
          {data.sound ? renderEditSound(data.id, data.sound, actions) : null}
        </StyledOptionEditText>
      );
    }
    return null;
  };

  /**
   * render the edit sound part
   * @param {*} id
   * @param {*} sound
   * @param {*} actions
   */
  const renderEditSound = (id, sound, actions) => {
    /* LJUD EJ FÄRDIGT */
    const changeMedia = () => onMediaChange(indx, "Sound");
    return (
      <StyledEditSoundWrapper>
        <p>{id}</p>
        <span>
          {/* <AudioBtn src={sound} actions={actions}/> */}
          <StyledMediaButton
            title="Spela upp"
            studlicon="Sound"
          ></StyledMediaButton>
          <StyledMediaButton
            title="Media"
            studlicon="Media"
            onClick={changeMedia}
          ></StyledMediaButton>
        </span>
      </StyledEditSoundWrapper>
    );
  };

  /**
   * Render media button row part if media exists.
   * @param media
   */
  const renderMediaButtonsRow = media =>
    media.mediaComp
      ? renderEditMedia(
          media,
          indx,
          true,
          null,
          onMediaChange,
          onMediaResourceChange
        )
      : null;

  /**
   * Render mediaresource file
   * @param {*} media
   */
  const renderMediaResource = media =>
    media.mediaComp ? (
      <StyledMediaResourceWrapper>
        {renderMedia(media)}
      </StyledMediaResourceWrapper>
    ) : null;

  return (
    <StyledOption>
      <StyledDragHandleWrapper>
        <DragHandle />
      </StyledDragHandleWrapper>
      <StyledCheckboxWrapper>
        <Checkbox
          id={"c" + indx}
          isChecked={data.isCorrect}
          onClick={_onCheck}
        />
      </StyledCheckboxWrapper>
      <StyledOptionContentWrapper>
        {renderMediaResource(getMedia(data, resources))}
        {renderMediaButtonsRow(getMedia(data, resources))}
        {renderTextEdit(data, actions)}
      </StyledOptionContentWrapper>
      <StyledColumnWrapper>
        <StyledTextToolbarButton
          activestate={"active"}
          studlicon={"TextTool"}
          onClick={toggleTextOptions}
        ></StyledTextToolbarButton>
      </StyledColumnWrapper>
      <StyledColumnWrapper>
        <StyledButton icon={"Copy"} onClick={duplicateItem}></StyledButton>
      </StyledColumnWrapper>
      <StyledColumnWrapper>
        <StyledButton onClick={removeItem} studlicon={"Trash"}></StyledButton>
      </StyledColumnWrapper>
    </StyledOption>
  );
});

export const OptionContainer = SortableContainer(({ children }) => {
  return <StyledOptionContainer>{children}</StyledOptionContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);
