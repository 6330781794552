import { RouterService, registerSaga, registerReducer } from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import {
  adventures as adventuresReducer,
  adventuresGroup as adventuresGroupReducer,
  adventuresStoryCard as adventuresCardReducer,
  adventuresStoryCardEditor as adventuresCardEditorReducer
} from "./store/reducer";
import { markers as markersReducer } from "./markers/store/reducer";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Adventures", adventuresReducer);
  registerReducer("AdventuresGroup", adventuresGroupReducer);
  registerReducer("AdventuresCard", adventuresCardReducer);
  registerReducer("AdventuresCardEditor", adventuresCardEditorReducer);
  registerReducer("Markers", markersReducer);
};
