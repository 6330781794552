import { takeLatest, put, all } from "redux-saga/effects";
import {
  APPLICATION_MOUNT,
  APPLICATION_READY,
  AUTH_READY,
  applicationReady,
  authInit
} from "../store/application/actions";
import { removeInitialLoadingScreen } from "../store/loading/actions";
import constraints from "../services/constraints";
import { initiateWebSocket } from "../store/socket/actions"

let dispatchInitialRoute;

function* setInitAuthentication(action) {
  dispatchInitialRoute = action.dispatchInitialRoute;
  yield put(authInit());
}

function* setApplicationReady() {
  yield all(constraints().map(c => c()));
  yield put(applicationReady());
  yield put(initiateWebSocket());
  if (dispatchInitialRoute) {
    dispatchInitialRoute();
  }
}

function* removeLoadingScreen() {
  yield put(removeInitialLoadingScreen());
}

export function* watchApplicationMount() {
  yield takeLatest(APPLICATION_MOUNT, setInitAuthentication);
}

export function* watchAuthReady() {
  yield takeLatest(AUTH_READY, setApplicationReady);
}

export function* watchApplicationReady() {
  yield takeLatest(APPLICATION_READY, removeLoadingScreen);
}
