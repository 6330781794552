import React from "react";
import styled from "styled-components";

const Box = styled.path`
  color: ${props => props.theme.neutralSecondary};
`;

const Mark = styled.path`
  color: ${props => props.theme.themePrimary};
`;

const Button = styled.div.attrs({
  role: "button"
})`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;

  &:hover ${Box} {
    color: ${props => props.theme.themePrimary};
  }
`;

const Input = styled.input.attrs({
  type: "checkbox"
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
`;

const InputHandle = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  pointer-events: none;
`;

const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  flex: 0 0 26px;
  box-sizing: content-box;
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
`;

const CheckboxLabel = styled.label`
  color: ${props => props.labelColor || props.theme.neutralPrimary};
  user-select: none;
  pointer-events: none;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  padding: 8px;
`;

const Checkbox = ({
  name,
  isChecked,
  isDisabled,
  onChange,
  onClick,
  label,
  labelColor,
  checkBoxColor
}) => (
  <Button onClick={onClick}>
    <InputWrapper>
      <Input
        name={name}
        disabled={isDisabled}
        checked={isChecked}
        value={name}
        onChange={onChange}
      />
      <InputHandle>
        <svg
          fill={checkBoxColor || "currentColor"}
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />

          <Box d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
          {isChecked ? (
            <Mark d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
          ) : null}
        </svg>
      </InputHandle>
    </InputWrapper>
    {label ? (
      <CheckboxLabel labelColor={labelColor}>{label}</CheckboxLabel>
    ) : null}
  </Button>
);

Checkbox.defaultProps = {
  onClick() {},
  onChange() {}
};

export default Checkbox;
