import React, { createRef } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import {
  StyledSortableItem,
  StyledSortableItemWrapper,
  StyledSortableUl,
  StyledSortItemHead,
  StyledTitle,
  StyledButtonsSpacer,
  StyledButtonsSpacerLine,
  StyledPanelButtonsWrapper,
  StyledExerciseRefContainer,
  StyledExerciseRef,
  StyledExerciseRefNumber,
  StyledExerciseGoalsContainer,
  StyledExerciseGoalsBubble,
  StyledItemGoalStatus,
  StyledStageDropDown,
  StyledStageModal,
  StyledStageBubble,
  StyledStageBubbleTitle,
  StyledStageModalTitle,
  StyledRemoveButtonContainer
} from "./StyledGoalList";
import connect from "react-redux/es/connect/connect";
import { CancelButton, PrimaryButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import {
  redoGoalsChange,
  resetGoalDraft,
  saveGoalsOrderChanges,
  undoGoalsChange,
  updateGoalOrder,
  updateStageChanges
} from "../../api/actions";
import { RotateCcw, RotateCw } from "react-feather";
import {
  StyledReplicationButtons,
  StyledUndoButton
} from "../../components/ReplicationButtons/StyledReplicationButtons";
import GoalIndex from "./GoalIndex/GoalIndex";
import { flattenTree } from "../../Helpers";
import TooltipTree from "./TooltipTree/TooltipTree";
import { ROUTE_EXERCISES } from "../../../posts/routes";
import {
  POST_STATUS,
  RIGHT,
  STRUCTURE,
  EDIT,
  SAVE,
  PUBLISH
} from "../../../constants";
import {
  Ellipsis,
  Star,
  RemoveIcon
} from "../../../shared/components/StudliIcons";
import ReactDOM from "react-dom";
import DropDown, { Item } from "../../../shared/components/DropDown";
import Tooltip from "react-tooltip-lite";
import HasPermission from "../../../shared/Permissions";

class GoalList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.myRef = createRef();

    this.state = {
      editMode: false,
      selectedItemId: -1,
      stageModalId: -1
    };
  }

  /**
   * Toggles edit mode
   */
  toggleEditMode = () =>
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      stageModalId: -1
    }));

  /**
   * Resets the goals draft to it's previously saved state
   */
  resetGoalsDraft = () => {
    this.props.resetGoalsDraft();
    this.toggleEditMode();
  };

  /**
   * Saves the goalDraft
   */
  saveGoalsDraft = () => {
    this.props.saveGoalsOrderChanges();
    this.toggleEditMode();
  };

  /**
   * Callback for when a sorting has completed.
   *
   * When sorting is completed we highlight the the moved element.
   *
   * @param oldIndex
   * @param newIndex
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { category_ref } = this.props.goalsDraft[oldIndex];
    this.setState({ selectedItemId: category_ref });

    this.props.updateGoalOrder(
      arrayMove(this.props.goalsDraft, oldIndex, newIndex)
    );
  };

  /**
   * When the component mounts, we want to know if the user clicked on a goal
   * from the structure tree. The goalId's default is -1, any other value indicates
   * coming from the structure tree.
   */
  componentDidMount() {
    if (this.props.goalId !== -1) {
      this.scrollToMyRef();
    }
  }

  /**
   * Reset the goalId that has been highlighted and scrolled to in goal list.
   * @returns {*}
   */
  resetGoalId = () => this.props.resetId();

  /**
   * Finds the element in the goallist, if it exist the element will
   * be scrolled into the view.
   *
   * The id passed down from parent will also reset after the scroll.
   */
  scrollToMyRef = () => {
    const { category_ref } = this.myRef.current.props.value.goal;
    const element = ReactDOM.findDOMNode(this.myRef.current);

    const offset = 75;
    const position = element.getBoundingClientRect().top - offset;

    window.scrollTo({ top: position, behavior: "smooth" });
    this.toggleHighlightedElement(category_ref)();
    this.resetGoalId();
  };

  /**
   * Renders edit button
   */
  renderEditButton = () => (
    <PrimaryButton onClick={this.toggleEditMode}>Redigera</PrimaryButton>
  );

  /**
   * Renders save button
   */
  renderSaveButton = () => (
    <PrimaryButton onClick={this.saveGoalsDraft}>Spara</PrimaryButton>
  );

  /**
   * Renders edit buttons
   * @returns {*}
   */
  renderEditButtons = () =>
    this.state.editMode ? (
      <ButtonGroup>
        <HasPermission
          component={this.renderSaveButton()}
          section={STRUCTURE}
          permission={SAVE}
        />

        <CancelButton onClick={this.resetGoalsDraft}>Avbryt</CancelButton>
      </ButtonGroup>
    ) : (
      <HasPermission
        component={this.renderEditButton()}
        section={STRUCTURE}
        permission={EDIT}
      />
    );

  /**
   * Renders the treeStructure ellipsed wrapper
   * @param goal
   * @returns {null}
   */
  renderTreeStructureWrapper = ({ category_ref }) =>
    this.props.flattenedTreeDraft ? (
      <TooltipTree
        categoryRef={category_ref}
        flatTreeData={this.props.flattenedTreeDraft}
      />
    ) : null;

  /**
   * Passing the goalId (category_ref) to the structure tree.
   *
   * @param category_ref
   * @returns {function(): void}
   */
  jumpToGoalsStructureParent = ({ category_ref }) => () =>
    this.props.setSearchStringForStructure(category_ref);

  /**
   * Renders the star that indicates if the goal meets the requirement of total published posts.
   * For now the requirement is:
   * At least 15 easy, 15 intermediate, 15 hard
   *
   * @param goalMeetsRequirement
   * @returns {*}
   */
  renderStar = goalMeetsRequirement => (
    <Star
      height={18}
      width={30}
      color={goalMeetsRequirement ? "orange" : "#cecece"}
    />
  );

  /**
   * Renders the remove icon in the stage bubble.
   */
  renderRemoveIcon = ref => (
    <StyledRemoveButtonContainer onClick={this.removeStageBubble(ref)}>
      <RemoveIcon height={20} width={20} color={"white"} />
    </StyledRemoveButtonContainer>
  );

  /**
   * Toggles the selected/highlighted element.
   *
   * @param category_ref
   */
  toggleHighlightedElement = category_ref => () =>
    this.setState({
      selectedItemId:
        category_ref === this.state.selectedItemId ? -1 : category_ref
    });

  /**
   * Renders a sortableItem
   * @param goal
   * @param index
   * @returns {React.ComponentClass<any & SortableElementProps>}
   */

  renderSortableItem = (goal, index) =>
    SortableElement(() => {
      const { category_ref, publishedExerciseCount, isPublished } = goal;

      return (
        <StyledSortableItemWrapper>
          <StyledItemGoalStatus published={isPublished} />

          <StyledSortableItem
            isEditMode={this.state.editMode}
            onClick={
              this.state.editMode
                ? undefined
                : this.toggleHighlightedElement(category_ref)
            }
            isSelected={this.state.selectedItemId === category_ref}
          >
            <StyledSortItemHead>
              <GoalIndex
                index={index}
                isEditMode={this.state.editMode}
                setIndexCallback={this.onSortEnd}
                listLength={this.props.goalsDraft.length - 1}
              />

              <StyledTitle onClick={this.jumpToGoalsStructureParent(goal)}>
                {goal.title}
              </StyledTitle>

              {this.renderStageModal(category_ref)}

              <StyledStageDropDown>
                {this.state.editMode ? null : (
                  <HasPermission
                    component={this.renderDropDown(category_ref)}
                    section={STRUCTURE}
                    permission={EDIT}
                  />
                )}
              </StyledStageDropDown>
            </StyledSortItemHead>

            <StyledExerciseRefContainer>
              {this.renderStar(
                this.calcTotalExerciseCount(publishedExerciseCount) >= 15
              )}
              {this.renderTreeStructureWrapper(goal)}
              {this.renderExerciseGoalsBubbles(goal)}
            </StyledExerciseRefContainer>

            {this.renderBubbleIfStartGoal(goal)}
          </StyledSortableItem>
        </StyledSortableItemWrapper>
      );
    });

  /**
   * Renders stage modal for selected goal item.
   * @param ref
   * @returns {*}
   */
  renderStageModal = ref => {
   const grades =[{id:1,value:"Åk 1"},{id:2,value:"Åk 2"},{id:3,value:"Åk 3"},{id:4,value:"Åk 4"},{id:5,value:"Åk 5"},{id:6,value:"Åk 6"}]
    return(
    <StyledStageModal isVisible={this.state.stageModalId === ref}>
      <StyledStageModalTitle>Startmål för:</StyledStageModalTitle>
      {grades.map(grade =>
       <Item key={grade.id} onClick={this.setStageFor(grade.id, ref)}>{grade.value}</Item>)}
    </StyledStageModal>
    )
};

  /**
   * Checks if goal item is a start goal.
   * @param goal
   * @returns {null|*}
   */
  renderBubbleIfStartGoal = ({ stage, category_ref }) => {
    return stage !==0 && this.renderStageBubble(stage, category_ref);
  };

  /**
   * Renders bubble for start goal. Renders remove icon if editMode = false
   * @param stage
   * @param ref
   */
  renderStageBubble = (stage, ref) => (
    <StyledStageBubble>
      <StyledStageBubbleTitle>
        {" "}
        Startmål för: Åk {stage}{" "}
      </StyledStageBubbleTitle>
      {this.state.editMode ? null : (
        <HasPermission
          component={this.renderRemoveIcon(ref)}
          section={STRUCTURE}
          permission={PUBLISH}
        />
      )}
    </StyledStageBubble>
  );

  /**
   * Removes the bubble for start goal.
   * @param ref
   */
  removeStageBubble = ref => e => {
    e.stopPropagation();
    this.resetSelectedStageForGoals(this.props.goalsDraft, ref);
  };

  /**
   * Sets the goal item ref for selected stage and closes stage modal.
   * @param stageLevel
   * @param ref
   */
  setStageFor = (stageLevel, ref) => () => {
    this.setState({ stageModalId: -1 });
    this.updateSelectedStageForGoal(this.props.goalsDraft, ref, stageLevel);
  };

  /**
   * Resets the goal stage when removed.
   * @param goals
   * @param ref
   */
  resetSelectedStageForGoals = (goals, ref) => {
    let goal = goals.find(({ category_ref }) => category_ref === ref);
    goal.stage = 0;
    this.props.updateStageChanges(goal);
  };

  /**
   * Sets the goal stage when selected in the stage modal.
   * @param goals
   * @param ref
   * @param stageLevel
   */
  updateSelectedStageForGoal = (goals, ref, stageLevel) => {
    let goal = goals.find(({ category_ref }) => category_ref === ref);
    goal.stage = stageLevel;
    this.props.updateStageChanges(goal);
  };

  /**
   * Renders the different bubbles that displays the amount of
   * exercises that are published/unpublished in the different
   * categories.
   *
   * @param goal
   * @returns {*}
   */
  renderExerciseGoalsBubbles = goal => (
    <StyledExerciseGoalsContainer>
      <StyledExerciseGoalsBubble color={"grey"}>
        {this.renderExerciseCountWrap(goal)}
      </StyledExerciseGoalsBubble>

      <StyledExerciseGoalsBubble color={"#32CD32"}>
        {this.renderExerciseCountWrap(goal, 1)}
      </StyledExerciseGoalsBubble>

      <StyledExerciseGoalsBubble color={"orange"}>
        {this.renderExerciseCountWrap(goal, 2)}
      </StyledExerciseGoalsBubble>

      <StyledExerciseGoalsBubble color={"red"}>
        {this.renderExerciseCountWrap(goal, 3)}
      </StyledExerciseGoalsBubble>
    </StyledExerciseGoalsContainer>
  );

  calcTotalExerciseCount = count =>
    Object.values(count || {}).reduce((a, v) => a + v, 0);

  getTitleForDifficulty = difficulty => {
    switch (difficulty) {
      case 1:
        return "Lätt";
      case 2:
        return "Medel";
      case 3:
        return "Svår";
      // no default
    }
    return "Antal övningar";
  };

  /**
   * Renders the total number of published/unpublished post for a goal.
   * @param goal
   * @param difficulty 1-3
   * @returns {*}
   */
  renderExerciseCountWrap = (goal, difficulty) => {
    const { ref, publishedExerciseCount, suppressedExerciseCount } = goal;

    let published = 0;
    let suppressed = 0;
    if (difficulty) {
      published =
        publishedExerciseCount && publishedExerciseCount[difficulty]
          ? publishedExerciseCount[difficulty]
          : 0;
      suppressed =
        suppressedExerciseCount && suppressedExerciseCount[difficulty]
          ? suppressedExerciseCount[difficulty]
          : 0;
    } else {
      published = this.calcTotalExerciseCount(publishedExerciseCount);
      suppressed = this.calcTotalExerciseCount(suppressedExerciseCount);
    }

    const publishedStatus = [
      POST_STATUS.PostStatusPublished,
      POST_STATUS.PostStatusModified,
      POST_STATUS.PostStatusRevisionReadyForReview,
      POST_STATUS.PostStatusRevisionReviewed
    ];
    const suppressedStatus = [
      POST_STATUS.PostStatusDraft,
      POST_STATUS.PostStatusDraftReadyForReview,
      POST_STATUS.PostStatusDraftReviewed,
      POST_STATUS.PostStatusRetracted
    ];

    return (
      <Tooltip
        content={this.getTitleForDifficulty(difficulty)}
        useDefaultStyles={true}
      >
        <StyledExerciseRef>
          <StyledExerciseRefNumber
            withPointer={!this.state.editMode}
            onClick={this.props.goToPosts(ref, publishedStatus, difficulty)}
          >
            {published}
          </StyledExerciseRefNumber>
          <StyledExerciseRefNumber>/</StyledExerciseRefNumber>
          <StyledExerciseRefNumber
            withPointer={!this.state.editMode}
            onClick={this.props.goToPosts(ref, suppressedStatus, difficulty)}
          >
            {suppressed}
          </StyledExerciseRefNumber>
        </StyledExerciseRef>
      </Tooltip>
    );
  };

  /**
   * Renders the sortable items
   * @returns {any[]}
   */
  renderSortableItems = () => {
    return this.props.goalsDraft.map((goal, index) => {
      const { category_ref } = goal;
      const SortableItem = this.renderSortableItem(goal, index);
      return (
        <SortableItem
          key={`item-${index}`}
          ref={this.props.goalId === category_ref ? this.myRef : undefined}
          index={index}
          value={{ goal, index }}
          disabled={!this.state.editMode}
        />
      );
    });
  };

  /**
   * Renders a buttons spacer line for give a bit of air between buttons
   * @returns {*}
   */
  renderButtonsSpacer = () =>
    this.state.editMode ? (
      <StyledButtonsSpacer>
        <StyledButtonsSpacerLine />
      </StyledButtonsSpacer>
    ) : null;

  /**
   * Renders a sortable list of goals
   * @returns {*}
   */
  renderSortableList = () => {
    const SortableList = SortableContainer(() => {
      return (
        <div>
          <StyledPanelButtonsWrapper>
            {this.renderEditButtons()}

            {this.renderButtonsSpacer()}

            {this.renderUndoRedoButtons()}
          </StyledPanelButtonsWrapper>

          <StyledSortableUl>{this.renderSortableItems()}</StyledSortableUl>
        </div>
      );
    });

    return (
      <SortableList
        axis={"xy"}
        items={this.props.goalsDraft}
        onSortEnd={this.onSortEnd}
      />
    );
  };

  /**
   * Opens stage modal for the selected goal item.
   * @param ref
   * @returns {Function}
   */
  onActionDropDown = ref => () => {
    this.setState({ stageModalId: ref });
  };

  /**
   * Renders drop down for selecting goal item as start goal.
   * @param ref
   * @returns {*}
   */
  renderDropDown = ref => (
    <DropDown
      title={<Ellipsis size={16} />}
      hasChevron={false}
      onChange={this.onActionDropDown(ref)}
      dropDownAlign={RIGHT}
      overrideStyles={{
        right: 0,
        display: "inline-block",
        minWidth: "inherit",
        border: "none"
      }}
    >
      <Item>Gör till startmål</Item>
    </DropDown>
  );

  /**
   * Render undo and redo button if app is in edit mode
   * @returns {null}
   */
  renderUndoRedoButtons = () => {
    const {
      replicationStatus: { hasUndo, hasRedo }
    } = this.props;

    return this.state.editMode ? (
      <StyledReplicationButtons>
        <StyledUndoButton
          onClick={this.props.undoGoalsAction}
          disabled={!hasUndo}
        >
          <RotateCcw size={14} />
        </StyledUndoButton>

        <StyledUndoButton
          onClick={this.props.redoGoalsAction}
          disabled={!hasRedo}
        >
          <RotateCw size={14} />
        </StyledUndoButton>
      </StyledReplicationButtons>
    ) : null;
  };

  render() {
    return this.props.fetching ? (
      <div>Fetching...</div>
    ) : (
      this.renderSortableList()
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.Structure.get("fetching"),
  goalsDraft: state.Structure.get("goalsDraft").toJS(),
  flattenedTreeDraft: flattenTree(
    state.Structure.getIn(["treeDraft", "tree"]).toJS()
  ),
  replicationStatus: {
    hasUndo: state.Structure.getIn(["goalsReplication", "undo"]).size > 0,
    hasRedo: state.Structure.getIn(["goalsReplication", "redo"]).size > 0
  }
});

const mapDispatchToProps = dispatch => ({
  updateStageChanges: newChanges => dispatch(updateStageChanges(newChanges)),
  updateGoalOrder: newOrder => dispatch(updateGoalOrder(newOrder)),
  resetGoalsDraft: () => dispatch(resetGoalDraft()),
  saveGoalsOrderChanges: () => dispatch(saveGoalsOrderChanges()),
  undoGoalsAction: () => dispatch(undoGoalsChange()),
  redoGoalsAction: () => dispatch(redoGoalsChange()),
  goToPosts: (goalId, status, difficulty) => () =>
    dispatch({
      type: ROUTE_EXERCISES,
      payload: { entityId: 1 },
      query: { goal: goalId, status, difficulty: difficulty ? difficulty : [] }
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalList);
