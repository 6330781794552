import { format } from "../../date";

export const isRefIdInList = (list, refId) =>
  list.filter(item => item === refId).length > 0;

export const checkIfDateIsZero = date => {
  const year = date.getFullYear();
  return year === 1;
};

export const getStartTime = dateString => {
  const date = new Date(dateString);
  if (checkIfDateIsZero(date)) {
    return "Från publicering";
  }
  return format(date);
};

export const getEndTime = dateString => {
  const date = new Date(dateString);
  if (checkIfDateIsZero(date)) {
    return "Ingen tid satt";
  }
  return format(date);
}
