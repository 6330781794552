import * as querystring from "querystring";
import { client } from "../../api";
import {
  EXERCISE,
  RESOURCE,
  HELP_RESOURCE,
  TEMPLATE,
  AIDA,
  PRODUCTNEWS,
  OPMESSAGE,
  MARKETMESSAGE
} from "../../constants";
import {
  getDefaultDataObj,
  getDefaultMetaDataObj
} from "../sagas/PostDraft/helpers";
import { STATUS } from "./constants";

/**
 *
 * @param {Object} args - Map of Post attribute names to values.
 * @param {Object} searchParameters - Map of search parameters
 */
export const listExercises = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: EXERCISE.toLowerCase() }, searchParameters);
export const listTemplates = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: TEMPLATE.toLowerCase() }, searchParameters);
export const listResources = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: RESOURCE.toLowerCase() }, searchParameters);
export const listHelpResources = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: HELP_RESOURCE.toLowerCase() }, searchParameters);
export const listAidaPosts = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: AIDA.toLowerCase() }, searchParameters);
export const listProductNewsPosts = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: PRODUCTNEWS.toLowerCase() }, searchParameters);
export const listOpMessagePosts = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: OPMESSAGE.toLowerCase() }, searchParameters);
export const listMarketMessagesPosts = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: MARKETMESSAGE.toLowerCase() }, searchParameters);

const listPosts = ({ entityId, type }, searchParameters) => {
  const params = Object.assign(
    {
      type
    },
    searchParameters,
    {
      sort_orders: searchParameters.sort
        ? Object.keys(searchParameters.sort).map(key =>
            `${key}:${searchParameters.sort[key]}`.toLowerCase()
          )
        : []
    },
    searchParameters.status &&
      (Array.isArray(searchParameters.status)
        ? { status: searchParameters.status.map(index => STATUS[index].id) }
        : { status: STATUS[searchParameters.status].id })
  );

  delete params.sort;
  return client.get(
    `entity/${entityId}/posts?${querystring.stringify(params)}`
  );
};

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 */
export const fetchExercise = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: EXERCISE.toLowerCase() });
export const fetchTemplate = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: TEMPLATE.toLowerCase() });
export const fetchResource = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: RESOURCE.toLowerCase() });
export const fetchHelpResource = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: HELP_RESOURCE.toLowerCase() });
export const fetchAidaPost = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: AIDA.toLowerCase() });
export const fetchProductNewsPost = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: PRODUCTNEWS.toLowerCase() });
export const fetchOpMessagePost = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: OPMESSAGE.toLowerCase() });
export const fetchMarketMessagePost = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: MARKETMESSAGE.toLowerCase() });

export const fetchPost = ({ entityId, postId, type }) => {
  const _type = type.toLowerCase();
  const params = Object.assign({
    type: _type
  });

  if (postId === "new") {
    return new Promise(resolve => {
      resolve({
        data: {
          data: {
            id: null,
            entity_id: entityId,
            title: "Untitled",
            type: _type,
            data: getDefaultDataObj(_type),
            help: getDefaultDataObj(_type),
            tags: [],
            chips: [],
            metadata: getDefaultMetaDataObj(_type)
          }
        }
      });
    });
  }
  return client.get(
    `entity/${entityId}/post/${postId}?${querystring.stringify(params)}`
  );
};

export const saveExercise = data =>
  savePost({ ...data, type: EXERCISE.toLowerCase() });
export const saveTemplate = data =>
  savePost({ ...data, type: TEMPLATE.toLowerCase() });
export const saveResource = data =>
  savePost({ ...data, type: RESOURCE.toLowerCase() });
export const saveHelpResource = data =>
  savePost({ ...data, type: HELP_RESOURCE.toLowerCase() });
export const saveAidaPost = data =>
  savePost({ ...data, type: AIDA.toLowerCase() });
export const saveOpMessagePost = data =>
  savePost({ ...data, type: OPMESSAGE.toLowerCase() });
export const saveProductNewsPost = data =>
  savePost({ ...data, type: PRODUCTNEWS.toLowerCase() });
export const saveMarketMessagePost = data =>
  savePost({ ...data, type: MARKETMESSAGE.toLowerCase() });

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const savePost = ({
  entityId,
  postId,
  title,
  data,
  help = null,
  difficulty = 0,
  resource_ids = null,
  media_urls = null,
  components = null,
  metadata = "{}"
}) => {
  return client.put(`entity/${entityId}/post/${postId}`, {
    title,
    data,
    help,
    difficulty,
    resource_ids,
    media_urls,
    components,
    metadata
  });
};

export const createExercise = data =>
  createPost({ ...data, type: EXERCISE.toLowerCase() });
export const createTemplate = data =>
  createPost({ ...data, type: TEMPLATE.toLowerCase() });
export const createResource = data =>
  createPost({ ...data, type: RESOURCE.toLowerCase() });
export const createHelpResource = data =>
  createPost({ ...data, type: HELP_RESOURCE.toLowerCase() });
export const createAidaPost = data =>
  createPost({ ...data, type: AIDA.toLowerCase() });
export const createOpMessagePost = data =>
  createPost({ ...data, type: OPMESSAGE.toLowerCase() });
export const createProductNewsPost = data =>
  createPost({ ...data, type: PRODUCTNEWS.toLowerCase() });
export const createMarketMessagePost = data =>
  createPost({ ...data, type: MARKETMESSAGE.toLowerCase() });

export const createPost = ({
  entityId,
  title,
  data,
  help = null,
  type,
  difficulty = 0,
  category_id = 0,
  resource_ids = null,
  mediaresource_ids = null,
  media_urls = null,
  components = null,
  metadata = "{}"
}) => {
  return client.post(`entity/${entityId}/post`, {
    title,
    data,
    help,
    type,
    difficulty,
    category_id: "" + category_id,
    resource_ids,
    media_urls,
    components,
    metadata
  });
};

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const addTagsToPost = data => client.put(`tagpost`, data);

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const removeTagsFromPost = data => client.delete(`tagpost`, { data });

export const fetchCommentsForAidaPost = ({ entityId, postId }) =>
  fetchCommentsForPost({ entityId: 0, postId });
/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchCommentsForPost = ({ entityId, postId }) => {
  return client.get(`entity/${entityId}/post/${postId}/comments`);
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchKeyboardListForPost = ({ entityId, postId }) => {
  return client.get(`keyboardlist`);
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const addCommentToPost = ({ entityId, postId, comment }) => {
  return client.post(`entity/${entityId}/post/${postId}/comment`, {
    message: comment
  });
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchTagFilter = ({ entityId, posttype }) => {
  const params = { entity_id: entityId, tags: true, type: posttype };
  return client.get(`tagcategories?${querystring.stringify(params)}`);
};

/**
 *
 *
 */
export const fetchLabelFilter = ({ posttype }) => {
  const params = { tags: true, type: posttype };
  return client.get(`tagcategories?${querystring.stringify(params)}`);
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchTemplateFilter = ({ entityId }) => {
  return client.get(`entity/${entityId}/templates`);
};

export const replaceResourceRefsForPost = ({
  entityId,
  postId,
  resourceIds
}) => {
  return client.put(`/entity/${entityId}/post/${postId}/resources`, {
    resource_ids: resourceIds
  });
};

export const setCategory = ({ entityId, posts, categoryId }) =>
  client.post(`entity/${entityId}/post/category/set`, { posts, categoryId });

export const unsetCategory = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/category/unset`, { posts });

export const publishPosts = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/publish`, { posts });

export const requestReview = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/request-review`, { posts });

export const reviewPost = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/set-reviewed`, { posts });

export const rejectPost = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/reject`, { posts });

export const unpublishPosts = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/retract`, { posts });

export const deletePosts = ({ entityId, data }) =>
  client.delete(`entity/${entityId}/posts`, { data });

export const duplicatePost = ({ entityId, postId }) =>
  client.post(`entity/${entityId}/post/${postId}/clone`);

export const fetchAllChipsForEntity = ({ entityId, postType }) => {
  const params = { type: postType };
  return client.get(
    `entity/${entityId}/chips?${querystring.stringify(params)}`
  );
};

export const addChipsToPost = data => client.post(`posts/connect/chips`, data);

export const removeChipsFromPost = data =>
  client.delete(`posts/delete/chips`, { data });
