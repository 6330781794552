import React from "react";
import styled from "styled-components";
import HistoryItem from "./HistoryItem";

const Title = styled.h2`
  font-size: 1.5rem;
`;

const StyledModalContent = styled.div``;
const StyledHistoryWrapper = styled.div`
  height: 26rem;
  overflow-y: scroll;
`;

const PublishHistory = ({ history }) => {
  return (
    <StyledModalContent>
      <Title>Historik</Title>
      <StyledHistoryWrapper>
        {history
          ? history.map(h => {
              return <HistoryItem key={h.buildId} item={h} />;
            })
          : null}
      </StyledHistoryWrapper>
    </StyledModalContent>
  );
};

export default PublishHistory;
