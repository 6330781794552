import styled from "styled-components";
import { StandardButton } from "../../../shared";

export const StyledToolBarBtn = styled(StandardButton)`
  border-radius: 5px;
  font-size: 12px;
  font-weight: bolder;
  min-width: auto;
`;

export const StyledMenuBar = styled.header`
  height: 68px;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4f4;
  display: flex;
  padding: 0 20px 0 10px;
  box-shadow: inset 0 -1px 6px #ccc;
`;

export const StyledMenuBarLeft = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
`;

export const StyledMenuBarRight = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledMenuBarMiddle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledToolbarAction = styled.div`
  margin: 2px;
  label {
    font-size: 12px;
  }
`;

export const StyledCheckboxAction = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButtonGroup = styled.div`
  display: inherit;
  padding: 0 15px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  height: 55px;
`;
