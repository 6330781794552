import React from "react";

const ChevronUp = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-chevronup"
  >
    <path
      transform="translate(25.000000, 24.500000) rotate(-270.000000) translate(-25.000000, -24.500000) "
      d="M37.3195634,49.1059879 L38.1080823,48.3135645 C38.6306392,47.7883457 38.6306392,46.9367427 38.1080823,46.4114119 L16.30778,24.499951 L38.1080823,2.58848999 C38.6306392,2.06327129 38.6306392,1.21166827 38.1080823,0.686337477 L37.3195634,-0.106085969 C36.7970065,-0.631304677 35.9497194,-0.631304677 35.4271625,-0.106085969 L11.8919177,23.5489307 C11.3693608,24.0741495 11.3693608,24.9257525 11.8919177,25.4510833 L35.4271625,49.1061 C35.9497194,49.6313187 36.7970065,49.6313187 37.3195634,49.1059879 Z"
    />
  </svg>
);

export default ChevronUp;
