import * as colors from "../shared/colors";
import * as font from "../shared/font";
import * as baseline from "../shared/baseline";
import * as headings from "../shared/headings";
import "katex/dist/katex.min.css";

export const global = `
  body,
  html {
    height: 100%;
    width: 100%;
  }
  html {
    position: relative;
    box-sizing: border-box;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    text-rendering: optimizeLegibility;
  }
  body {
    background-color: #fff;
    min-height: 100%;
    margin: 0;
    padding: 0;
    color: ${colors.neutralPrimary};
    font-family: ${font.fontFamily};
    font-size: ${font.fontSizeDefault};
    font-style: normal;
    font-weight: 400;
    line-height: ${font.lineHeightDefault};
  }
  input,
  select,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  p,
  ul,
  ol,
  dl,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  form,
  table {
    margin: ${baseline.baselineNumber * 1.5}px 0 0 0;
  }

  a {
    color: ${colors.themePrimary};
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:hover {
    color: ${colors.themeDark};
    text-decoration: underline;
  }

  a:active {
    color: ${colors.themeDarkAlt};
  }
  a:focus {
    outline: 2px solid ${colors.themeLighter};
    outline-offset: 2px;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):hover,
  a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  h1 {
    ${headings.d800()}
  }
  h2 {
    ${headings.d700()}
  }
  h3 {
    ${headings.d600()}
  }
  h4 {
    ${headings.d500()}
  }
  h5 {
    ${headings.d400()}
  }
  h6 {
    ${headings.d300()}
  }

  ul,
  ol,
  dl {
    padding-left: ${baseline.baselineNumber * 5}px;

    &[dir="rtl"] {
      padding-left: 0;
      padding-right: ${baseline.baselineNumber * 5}px;
    }
  }
  dd,
  dd + dt,
  li + li {
    margin-top: ${baseline.baselineNumber / 2}px;
  }
  ul,
  ol {
    ul,
    ol {
      &:not(:first-child) {
        margin-top: ${baseline.baselineNumber / 2}px;
      }
    }
  }

  p:first-child,
  ul:first-child,
  ol:first-child,
  dl:first-child,
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  blockquote:first-child,
  pre:first-child,
  form:first-child,
  table:first-child {
    margin-top: 0;
  }

  small {
    ${headings.d100()}
  }
  code,
  kbd {
    font-family: ${font.codeFontFamily};
  }
`;
