import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { SelectField } from "../../../products/components/Input";
import { getProductTypes } from "../../../products/store/selectors";
import { filterQueryAction } from "../../../shared/containers/Filters/filterdata";

const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  padding-left: 0;
  margin-right: 2rem;
`;
const StyledSelect = styled(SelectField)`
  width: 200px;
  margin-right: 10px;
`;

const FILE_STATUS_OPTIONS = {
  Alla: -1,
  "Ej publicerad": 0,
  "Publicerad till test": 1,
  "Publicerad live": 2,
  "Modifierad i test": 3
};

const ProductListFilters = () => {
  const productTypes = useSelector(getProductTypes);
  let params = useSelector(state => state.location.query);
  const [values, setValues] = useState({
    typeId: -1,
    status: -1,
    ...params
  });

  const types = productTypes
    ? productTypes.toJS().filter(t => !t.disabled)
    : [];

  const dispatch = useDispatch();

  const _onSelectChange = (val, name) => {
    setValues({ ...values, [name]: val });

    if (val < 0) {
      delete params[name];
    } else {
      params = { ...params, [name]: val };
    }
    dispatch(filterQueryAction("ROUTE_PRODUCTS", {}, params));
  };
  
  const disabled = false;

  return (
    <StyledFiltersWrapper>
      <StyledSelect
        value={values.typeId}
        onChange={_onSelectChange}
        disabled={disabled}
        label="Filtrera på produkttyp"
        name="typeId"
        options={[{ type: "Alla", id: -1 }, ...types].map(v => ({
          key: v.type,
          val: v.id
        }))}
      />
      <StyledSelect
        value={values.status}
        onChange={_onSelectChange}
        disabled={disabled}
        label="Filtrera på status"
        name="status"
        options={Object.keys(FILE_STATUS_OPTIONS).map(key => ({
          key: key,
          val: FILE_STATUS_OPTIONS[key]
        }))}
      />
    </StyledFiltersWrapper>
  );
};

export default ProductListFilters;
