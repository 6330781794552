import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductInfo, getProductMediaFiles } from "../../store/selectors";
import { setProductFiles, setProductSettings } from "../../api/actions";
import { PrimaryButton, StandardButton } from "../../../shared";
import { openMediaModal } from "../../../shared/components/Modal/MediaModal";
import { MEDIA } from "../../../constants";
import styled from "styled-components";
import SelectMediaType from "./SelectMediaType";
import Label from "../Label";
import BiafMediaList from "./BiafMediaList";
import { PreviewItem } from "./BiafMediaListItem";

const MEDIA_FILE_TYPE = "media_file_type";
const MEDIA_FILE_PREVIEW = "media_file_preview";

const Button = styled(PrimaryButton)`
	margin-bottom: 1rem;
`;

const PreviewButton = styled(StandardButton)`
	margin-top: 0.5rem;
`;

const BiafMediaHandler = () => {
	const dispatch = useDispatch();
	const [entity] = useSelector(getProductInfo);
	const { productSettings } = entity;
	const mediaFiles = useSelector(getProductMediaFiles);
	const selectedMediaType = productSettings.find(
		item => item.key === MEDIA_FILE_TYPE
	);
	const selectedPreview = productSettings.find(
		item => item.key === MEDIA_FILE_PREVIEW
	);

	const updateSettings = (key, val) => {
		if (key === MEDIA_FILE_TYPE && selectedMediaType) {
			return productSettings.map(s => {
				if (s.key === key) {
					return { ...s, value: val };
				}
				return s;
			});
		}

		if (key === MEDIA_FILE_PREVIEW && selectedPreview) {
			return productSettings.map(s => {
				if (s.key === key) {
					return { ...s, value: val };
				}
				return s;
			});
		}
		return [...productSettings, { key, value: val }];
	};

	const updateFiles = (files) => {
		let mediaFileIds = [];
		if (mediaFiles) {
			mediaFileIds = [...mediaFiles.map(file => file.id)]
		}
		const updatedIds = [...mediaFileIds, ...files.map(file => file.id)]
		updateFileList(updatedIds);
	};

	const updateFileList = (fileIds) => {
		const data = {
			media_file_ids: [
				...new Set([...fileIds]) // Use Set to remove duplicates
			]
		};
		dispatch(setProductFiles(entity.id, data));
	}

	const removeFile = (id) => {
		const data = {
			media_file_ids: mediaFiles.map(file => file.id).filter(item => item !== id)
		}
		dispatch(setProductFiles(entity.id, data));
	}

	const onTypeChange = type => dispatch(
		setProductSettings(entity.id, updateSettings(MEDIA_FILE_TYPE, type.toUpperCase()))
	);

	const onPreviewChange = fileId => {
		dispatch(
			setProductSettings(entity.id, updateSettings(MEDIA_FILE_PREVIEW, fileId.toString()))
		);
		updateFiles([{ id: fileId }]);
	}

	const removePreview = (id) => {
		removeFile(id);
		dispatch(
			setProductSettings(entity.id, productSettings.filter(setting => setting.key !== MEDIA_FILE_PREVIEW))
		);
	}

	const openMedia = (key, isPreview) => () => {
		const selectedType = key.toLowerCase() === "summary" ? "video" : key;
		openMediaModal(dispatch)(
			{
				onOK: files => {
					if (!files.length) return;

					if (isPreview) {
						const [first] = files;
						return onPreviewChange(first.id);
					}

					updateFiles(files);
				},
				onCancel: () => { }
			},
			{
				filters: {
					selected: selectedType,
					hide: ["type"]
				}
			},
			MEDIA,
			"Lägg till media",
			true,
			!isPreview
		);
	};

	return (
		<>
			<SelectMediaType disabled={mediaFiles?.length > 0} selectedMediaType={selectedMediaType} onChange={onTypeChange} />
			<BiafMediaList list={mediaFiles} removeItem={removeFile} previewId={parseInt(selectedPreview?.value)} updateOrder={updateFileList} />
			<Button
				disabled={!selectedMediaType}
				onClick={selectedMediaType && openMedia(selectedMediaType.value, false)}
			>
				Lägg till media
			</Button>
			<Label>Smakprov</Label>
			<PreviewItem fileList={mediaFiles} selected={selectedPreview} onDelete={removePreview} />
			<PreviewButton disabled={!selectedMediaType || selectedPreview} onClick={selectedMediaType && openMedia(selectedMediaType.value, true)}>
				Lägg till smakprov
			</PreviewButton>

		</>
	);
};

export default BiafMediaHandler;
