import { client } from "../../api";

/**
 * Authorize/login a user using the client token
 * @param clientToken
 */
export const authorizeUser = clientToken =>
  client.post(`account/login`, { clientToken });

/**
 * Logout a user
 */
export const logout = () => client.get(`../sso/logout`);
