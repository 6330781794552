import React from "react";
import * as staticData from "../StaticData";
import { Item } from "../../../../shared/components/DropDown";
import DropDown from "../../../../shared/components/DropDown/DropDown";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import Button from "../../../../shared/components/Button/Button";
import {
  AUTO,
  DATA,
  END,
  ERROR_MARGIN,
  FIXED,
  INTERVAL,
  IS_ACTIVE,
  JUMP,
  MARKING,
  NORMAL,
  NUMBER,
  OPERATIVE,
  SETTINGS,
  START,
  STEPS
} from "../../../../constants";
import {
  StyledSidebarGrid,
  StyledSidebarLineGrid,
  StyledLineGridCell,
  StyledCellGridItem,
  StyledInputLabel,
  StyledInput
} from "./StyledSidebar";
import { defaultJump, generateStepObject } from "../NumberLineHelpers";
import SidebarButton from "../SidebarButton/SidebarButton";
import AutoLaTeX from "react-autolatex";

const Sidebar = ({
  data,
  settings,
  callback,
  bulkUpdateCallback,
  selectJumpImg,
  labelGenerator,
  decimalSetting,
  storeDraft,
  draftTarget
}) => {
  const { steps } = data;

  const {
    lineType,
    interval,
    errorMargin,
    marking,
    jump = defaultJump(),
    smooth,
    operative
  } = settings;

  const DropDownStyleOverride = { minWidth: "inherit", width: "100%" };

  /**
   * Returns true if line type is additive
   * @returns {boolean}
   */
  const isAdditive = () => lineType === "additive";

  /**
   * Handle value change
   * @param key
   * @returns {function(*=): *}
   */
  const handleValueChange = key => value => {
    storeDraft(draftTarget);
    callback(key, value, SETTINGS);
  };

  /**
   * Adds new steps
   * @param diff
   */
  const addSteps = diff => {
    const newTicks = [];

    for (let i = 0; i < diff; i++) {
      newTicks.push(generateStepObject((interval * steps.length).toString()));
    }
    storeDraft(draftTarget);
    callback(STEPS, [...steps, ...newTicks], DATA);
  };

  /**
   * Removes steps
   * @param diff
   */
  const removeSteps = diff => {
    const newData = steps.filter((item, index) => index < diff);
    storeDraft(draftTarget);
    callback(STEPS, newData, DATA);
  };

  /**
   * Handles whether steps should be added or removed
   * @param value
   */
  const handleStepChange = value => {
    if (value > steps.length) {
      addSteps(value - steps.length);
    }

    if (value < steps.length) {
      removeSteps(value);
    }
  };

  /**
   * Render steps
   */
  const renderDropDownItems = dropDownData =>
    dropDownData.map(({ value, label }) => (
      <Item key={`step_${value}`} value={value}>
        <AutoLaTeX>{label}</AutoLaTeX>
      </Item>
    ));

  /**
   * Renders steps dropDown field
   * @returns {*}
   */
  const renderStepsDropDown = () => (
    <StyledLineGridCell startColumn={1}>
      <StyledInputLabel>Steg</StyledInputLabel>

      <DropDown
        defaultValue={steps.length}
        onChange={handleStepChange}
        overrideStyles={DropDownStyleOverride}
        inSidebar={true}
      >
        {renderDropDownItems(staticData.steps)}
      </DropDown>
    </StyledLineGridCell>
  );

  /**
   * Callback for interval input enter press
   */
  const handleIntervalEnter = value => {
    storeDraft(draftTarget);
    callback(INTERVAL, Number.parseFloat(value.text), SETTINGS);
  };

  /**
   * Renders interval field
   * @returns {*}
   */
  const renderInterval = () => (
    <StyledLineGridCell startColumn={2}>
      <StyledInputLabel>Intervall</StyledInputLabel>

      <StyledInput
        maxWidth={"80px"}
        type={NUMBER}
        placeholder={!isNaN(interval) ? interval : ""}
        min={decimalSetting}
        step={decimalSetting}
        onEnter={handleIntervalEnter}
      />
    </StyledLineGridCell>
  );

  /**
   * Renders error margin field
   * @returns {*}
   */
  const renderErrorMargin = () => (
    <StyledLineGridCell startColumn={1}>
      <StyledInputLabel>Felmarginal</StyledInputLabel>

      <DropDown
        defaultValue={errorMargin}
        onChange={handleValueChange(ERROR_MARGIN)}
        overrideStyles={DropDownStyleOverride}
        inSidebar={true}
      >
        {renderDropDownItems(staticData.errorMargin)}
      </DropDown>
    </StyledLineGridCell>
  );

  /**
   * Renders marking field
   * @returns {*}
   */
  const renderMarking = () => (
    <StyledLineGridCell startColumn={2}>
      <StyledInputLabel>Markering</StyledInputLabel>

      <DropDown
        defaultValue={marking}
        onChange={handleValueChange(MARKING)}
        overrideStyles={DropDownStyleOverride}
        inSidebar={true}
      >
        {renderDropDownItems(staticData.lineMarking)}
      </DropDown>
    </StyledLineGridCell>
  );

  /**
   * Renders line section
   * @returns {*}
   */
  const renderLineSection = () => (
    <StyledSidebarSection>
      <StyledSidebarHeading backgroundColor={"#35877A"}>
        Linje:
      </StyledSidebarHeading>
      <StyledSidebarLineGrid>
        {renderStepsDropDown()}
        {renderInterval()}
        {renderErrorMargin()}
        {renderMarking()}

        <StyledLineGridCell startColumn={1}>
          <SidebarButton
            callback={toggleAdditive}
            isActive={isAdditive()}
            content={"Additiv"}
          />
        </StyledLineGridCell>
        <StyledLineGridCell startColumn={2}>
          <SidebarButton
            callback={toggleSmoothLine}
            isActive={smooth}
            content={"Steglös"}
          />
        </StyledLineGridCell>

        <StyledLineGridCell startColumn={1}>
          <SidebarButton
            callback={toggleOperative}
            isActive={operative}
            content={"Operativ"}
          />
        </StyledLineGridCell>
      </StyledSidebarLineGrid>
    </StyledSidebarSection>
  );

  /**
   * Toggles smooth line setting
   * @returns {*}
   */
  const toggleSmoothLine = () => {
    storeDraft(draftTarget);
    callback("smooth", !smooth, SETTINGS);
  };

  /**
   * Toggles additive setting
   * @returns {*}
   */
  const toggleAdditive = () => {
    storeDraft(draftTarget);

    callback("lineType", isAdditive() ? NORMAL : "additive", SETTINGS);
  };

  /**
   * Toggles additive setting
   * @returns {*}
   */
  const toggleOperative = () => {
    storeDraft(draftTarget);
    callback(OPERATIVE, !operative, SETTINGS);
  };

  /**
   * Toggles fixed jump setting
   * @returns {*}
   */
  const toggleFixedJump = () => {
    storeDraft(draftTarget);
    callback(
      JUMP,
      {
        ...jump,
        [FIXED]: {
          ...jump.fixed,
          [IS_ACTIVE]: !jump.fixed.isActive
        }
      },
      SETTINGS
    );
  };

  /**
   * Toggles auto jump setting
   * @returns {*}
   */
  const toggleAutoJump = () => {
    storeDraft(draftTarget);
    callback(
      JUMP,
      {
        ...jump,
        [AUTO]: !jump.auto
      },
      SETTINGS
    );
  };

  /**
   * Handles jump start change
   * @param data
   */
  const updateFixedJumpData = key => value => {
    storeDraft(draftTarget);
    callback(
      JUMP,
      {
        ...jump,
        [FIXED]: {
          ...jump.fixed,
          [key]: value
        }
      },
      SETTINGS
    );
  };

  /**
   * Handles jump start change
   * @param value
   */
  const updateJumpLengthData = value => {
    storeDraft(draftTarget);
    const jumpLength = parseInt(value, 10) > 0 ? parseInt(value, 10) : 0;

    callback(
      JUMP,
      {
        ...jump,
        jumpLength
      },
      SETTINGS
    );
  };

  /**
   * Toggles turn jump asset
   * @returns {*}
   */
  const toggleTurnJumpAsset = () => {
    storeDraft(draftTarget);

    callback(
      JUMP,
      {
        ...jump,
        turnAsset: !jump.turnAsset
      },
      SETTINGS
    );
  };

  /**
   * Render fixed jump button
   * @returns {*}
   */
  const renderFixedJumpToggleBtn = () => (
    <StyledCellGridItem startColumn={2}>
      <SidebarButton
        overrideStyles={{ width: "100%" }}
        callback={toggleFixedJump}
        isActive={jump.fixed.isActive}
        content={"Fast"}
      />
    </StyledCellGridItem>
  );

  /**
   * Render jump toggle Btn
   * @returns {*}
   */
  const renderAutoJumpToggleBtn = () => (
    <StyledCellGridItem startColumn={3}>
      <SidebarButton
        overrideStyles={{ width: "100%" }}
        callback={toggleAutoJump}
        isActive={jump.auto}
        content={"Auto"}
      />
    </StyledCellGridItem>
  );

  /**
   * Generate jump drop down items
   */
  const generateJumpDropDownItems = () =>
    steps.map(({ id, text, hasCustomValue }, index) => ({
      label: labelGenerator(text, hasCustomValue, index),
      value: id
    }));

  /**
   * Returns the selected dropdown item label
   *
   * @param {*} dropDownItems
   * @param {*} id
   * @returns {*}
   */
  const getDropDownItemLabel = (dropDownItems, id) => {
    const object = dropDownItems.find(obj => obj.key === "step_" + id);
    const children =
      object && object.props && object.props.children
        ? object.props.children
        : "";

    return <span>{children}</span>;
  };

  /**
   * Renders jump start drop down
   * @returns {*}
   */
  const renderJumpStartDropDown = () => {
    const dropDownItems = renderDropDownItems(generateJumpDropDownItems());

    return (
      <StyledCellGridItem startColumn={2}>
        <DropDown
          title={getDropDownItemLabel(dropDownItems, jump.fixed.start)}
          defaultValue=""
          onChange={updateFixedJumpData(START)}
          disabled={!jump.fixed.isActive}
          overrideStyles={{ minWidth: "inherit", width: "100%" }}
          inSidebar={true}
        >
          {dropDownItems}
        </DropDown>
      </StyledCellGridItem>
    );
  };

  /**
   * Renders jump end drop down
   * @returns {*}
   */
  const renderJumpEndDropDown = () => {
    const dropDownItems = renderDropDownItems(generateJumpDropDownItems());

    return (
      <StyledCellGridItem startColumn={2}>
        <DropDown
          title={getDropDownItemLabel(dropDownItems, jump.fixed.end)}
          defaultValue=""
          onChange={updateFixedJumpData(END)}
          disabled={!jump.fixed.isActive}
          overrideStyles={{ minWidth: "inherit", width: "100%" }}
          inSidebar={true}
        >
          {dropDownItems}
        </DropDown>
      </StyledCellGridItem>
    );
  };

  /**
   * Renders type
   * @returns {*}
   */
  const renderType = () => (
    <React.Fragment>
      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Typ</StyledInputLabel>
      </StyledCellGridItem>
      {renderFixedJumpToggleBtn()}
      {renderAutoJumpToggleBtn()}

      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Start</StyledInputLabel>
      </StyledCellGridItem>

      {renderJumpStartDropDown()}

      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Slut</StyledInputLabel>
      </StyledCellGridItem>

      {renderJumpEndDropDown()}

      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Längd</StyledInputLabel>
      </StyledCellGridItem>

      {renderJumpLengthInput()}
    </React.Fragment>
  );

  /**
   * Renders jump update input
   * @returns {*}
   */
  const renderJumpLengthInput = () => (
    <StyledCellGridItem startColumn={2}>
      <StyledInput
        placeholder={"Längd"}
        disabled={!jump.fixed.isActive}
        type={NUMBER}
        value={jump.jumpLength}
        min={1}
        onChange={updateJumpLengthData}
      />
    </StyledCellGridItem>
  );

  /**
   * Render image field
   * @returns {*}
   */
  const renderImage = () => (
    <React.Fragment>
      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Bild</StyledInputLabel>
      </StyledCellGridItem>

      <StyledCellGridItem startColumn={2} endColumn={4}>
        <Button onClick={selectJumpImg} style={{ width: "100%" }}>
          Välj bild
        </Button>
      </StyledCellGridItem>
    </React.Fragment>
  );

  /**
   * Render image settings
   * @returns {*}
   */
  const renderShowImageSetting = () => (
    <React.Fragment>
      <StyledCellGridItem startColumn={1}>
        <StyledInputLabel>Visa</StyledInputLabel>
      </StyledCellGridItem>
      <StyledCellGridItem startColumn={2} endColumn={4}>
        <SidebarButton
          callback={toggleTurnJumpAsset}
          isActive={jump.turnAsset}
          overrideStyles={{ width: "100%" }}
          content={"Vändbar"}
        />
      </StyledCellGridItem>
    </React.Fragment>
  );

  /**
   * Render jump section
   * @returns {*}
   */
  const renderJumpSection = () => (
    <StyledSidebarSection>
      <StyledSidebarHeading backgroundColor={"#72A5AF;"}>
        Hopp:
      </StyledSidebarHeading>
      <StyledSidebarGrid>
        {renderType()}
        {renderImage()}
        {renderShowImageSetting()}
      </StyledSidebarGrid>
    </StyledSidebarSection>
  );

  return (
    <StyledBlockSidebar maxWidth={"200px"}>
      {renderLineSection()}
      {renderJumpSection()}
    </StyledBlockSidebar>
  );
};

export default Sidebar;
