import React from "react";
import { StandardButton } from "../../../shared";
import Checkbox from "../../../shared/components/Checkbox";
import {
  StyledMenuBar,
  StyledMenuBarLeft,
  StyledMenuBarMiddle,
  StyledMenuBarRight,
  StyledToolbarAction,
  StyledButtonGroup,
  StyledCheckboxAction
} from "./StyledToolbar";
import { ToolBarBtn } from "./ToolBarBtn";
import DropDown, { Item } from "../../../shared/components/DropDown";
import Link from "../../../shared/components/Link";
import {
  ROUTE_DETAILED_EXERCISE,
  ROUTE_DETAILED_RESOURCE
} from "../../../posts/store/actions";
import { connect } from "react-redux";
import { setDraftState } from "../../api/actions";
import { ENTITIES, MEDIA_RESOURCE } from "../../../constants";

/* eslint-disable jsx-a11y/label-has-associated-control */

const EditorToolbar = ({
  openMediaModal,
  formatChange,
  currentFormat,
  alignToWorkspace,
  actionAlignToWorkspace,
  actionResize,
  actionAlign,
  actionDistribute,
  actionRemove,
  actionMoveZ,
  actionDuplicate,
  actionGrid,
  elementsIsSelected,
  selectedSizes,
  saveImage,
  prev,
  mediaresourcesPath,
  duplicateImage,
  post,
  updateDraftState
}) => {
  /**
   * Updates editor setting 'alignworkspace'
   */
  const updateSetting = () => {
    updateDraftState("dirty");
    actionAlignToWorkspace({
      alignWorkspace: !alignToWorkspace
    });
  };

  /**
   * Function for updating the size of selected icons.
   * @param {string} v imagesize
   */
  const imageChange = v => {
    updateDraftState("dirty");
    actionResize(v);
  };

  /**
   * Toolbar actions
   * @param {string} action
   */
  const handleToolbarAction = action => {
    updateDraftState("dirty");
    switch (action) {
      case "action-align-top":
        actionAlign("top");
        break;
      case "action-align-middle":
        actionAlign("middle");
        break;
      case "action-align-bottom":
        actionAlign("bottom");
        break;
      case "action-align-left":
        actionAlign("left");
        break;
      case "action-align-center":
        actionAlign("center");
        break;
      case "action-align-right":
        actionAlign("right");
        break;
      case "action-distribute-horiz":
        actionDistribute("horiz");
        break;
      case "action-distribute-vert":
        actionDistribute("vert");
        break;
      case "action-duplicate":
        actionDuplicate(1);
        break;
      case "action-resize":
        actionResize();
        break;
      case "action-move-front":
        actionMoveZ(1);
        break;
      case "action-move-back":
        actionMoveZ(-1);
        break;
      case "action-trash":
        actionRemove();
        break;
      case "action-grid":
        actionGrid();
        break;
      default:
        break;
    }
  };

  /**
   * Checks if the post can be duplicated or not.
   */
  const shouldRenderDuplicateButton = () => {
    if (post && post.first()) {
      const _post = post.first();
      return _post.id ? true : false;
    }
    return false;
  };

  /**
   *  Render dropdown for formatchange
   */
  const renderFormatChangeAction = () => (
    <StyledToolbarAction>
      <label>Ändra format:</label>
      <DropDown
        defaultValue={currentFormat}
        title={currentFormat}
        onChange={formatChange}
      >
        <Item value="16:9">16:9</Item>
        <Item value="3:1">3:1</Item>
        <Item value="4:1">4:1</Item>
        <Item value="2:1">2:1</Item>
        <Item value="1:1">1:1</Item>
      </DropDown>
    </StyledToolbarAction>
  );

  /**
   *  Render dropdown for image size
   */
  const renderImageSizeAction = () => (
    <StyledToolbarAction>
      <label>Ändra storlek:</label>
      <DropDown onChange={imageChange} title={selectedSizes}>
        <Item value="extrasmall">extrasmall</Item>
        <Item value="small">small</Item>
        <Item value="medium">medium</Item>
        <Item value="large">large</Item>
      </DropDown>
    </StyledToolbarAction>
  );

  /**
   *  Render workspace actions
   */
  const renderWorkspaceActions = () => (
    <StyledToolbarAction>
      <label>Grid:</label>
      <div>
        <ToolBarBtn
          title="Visa/Dölj Grid"
          icon="Grid"
          value="action-grid"
          onClick={handleToolbarAction}
        />
      </div>
    </StyledToolbarAction>
  );

  /**
   *  Render alignment buttons
   */
  const renderAlignmentActions = () => (
    <StyledToolbarAction>
      <label>Justera:</label>
      <div>
        <ToolBarBtn
          title="Överkant"
          studlicon="AlignTop"
          value="action-align-top"
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Lodrät mittenjustering"
          studlicon="AlignMiddle"
          value="action-align-middle"
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="underkant"
          studlicon="AlignBottom"
          value="action-align-bottom"
          onClick={handleToolbarAction}
        />

        <ToolBarBtn
          title="Vänster"
          studlicon="AlignLeft"
          value="action-align-left"
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Vågrät mittenjustering"
          studlicon="AlignCenter"
          value="action-align-center"
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Höger"
          studlicon="AlignRight"
          value="action-align-right"
          onClick={handleToolbarAction}
        />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Render buttons for distribution of objects
   */
  const renderDistActions = () => (
    <StyledToolbarAction>
      <label>Fördela:</label>
      <div>
        <ToolBarBtn
          title="Fördela horisontellt"
          studlicon="DistributeHorizont"
          value="action-distribute-horiz"
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Fördela vertikalt"
          studlicon="DistributeVertical"
          value="action-distribute-vert"
          onClick={handleToolbarAction}
        />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Renders a checkbox for a setting 'alignToWorkspace'
   */
  const renderCheckBox = () => (
    <StyledToolbarAction>
      <label>Mot arbetsytan:</label>
      <StyledCheckboxAction>
        <Checkbox isChecked={alignToWorkspace} onClick={updateSetting} />
      </StyledCheckboxAction>
    </StyledToolbarAction>
  );

  /**
   *  Render other actions
   */
  const renderOtherActions = () => (
    <StyledToolbarAction>
      <label>Övrigt:</label>
      <div>
        <ToolBarBtn
          title="Duplicera"
          icon="Copy"
          value="action-duplicate"
          disabled={elementsIsSelected}
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Flytta fram"
          studlicon="MoveFront"
          value="action-move-front"
          disabled={elementsIsSelected}
          onClick={handleToolbarAction}
        />
        <ToolBarBtn
          title="Flytta bak"
          studlicon="MoveBack"
          value="action-move-back"
          disabled={elementsIsSelected}
          onClick={handleToolbarAction}
        />

        <ToolBarBtn
          title="Ta bort"
          studlicon="Trash"
          value="action-trash"
          disabled={elementsIsSelected}
          onClick={handleToolbarAction}
        />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Render add new file button
   */
  const renderAddFile = () => (
    <StyledToolbarAction>
      <label>Lägg till Enskild bild:</label>
      <div>
        <StandardButton onClick={openMediaModal} icon="Image" />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Render save file button
   */
  const renderSaveFile = () => (
    <StyledToolbarAction>
      <label>Spara:</label>
      <div>
        <StandardButton onClick={saveImage} icon="Save" />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Renders duplicate button
   */
  const renderDuplicateButton = () => (
    <StyledToolbarAction>
      <label>Duplicera:</label>
      <div>
        <StandardButton onClick={duplicateImage} studlicon="Copy" />
      </div>
    </StyledToolbarAction>
  );

  /**
   * Render goback button
   */
  const renderGoBackButton = () => (
    <StyledToolbarAction>
      {/* <label>Avbryt:</label> */}
      <div>
        <Link to={getLink()}>
          <StandardButton icon="X" />
        </Link>
      </div>
    </StyledToolbarAction>
  );

  const getLink = () => {
    switch (prev.type) {
      case ROUTE_DETAILED_EXERCISE:
      case ROUTE_DETAILED_RESOURCE:
        return prev.pathname;
      default:
        return mediaresourcesPath;
    }
  };

  return (
    <StyledMenuBar>
      <StyledMenuBarLeft>{renderAddFile()}</StyledMenuBarLeft>
      <StyledMenuBarMiddle>
        <StyledButtonGroup>
          {renderFormatChangeAction()}
          {renderWorkspaceActions()}
        </StyledButtonGroup>
        <StyledButtonGroup>
          {renderAlignmentActions()}
          {renderDistActions()}
          {renderCheckBox()}
        </StyledButtonGroup>
        <StyledButtonGroup>
          {renderImageSizeAction()}
          {renderOtherActions()}
        </StyledButtonGroup>
      </StyledMenuBarMiddle>
      <StyledMenuBarRight>
        {renderSaveFile()}
        {shouldRenderDuplicateButton() ? renderDuplicateButton() : null}
        {renderGoBackButton()}
      </StyledMenuBarRight>
    </StyledMenuBar>
  );
};

const mapDispatchToProps = dispatch => ({
  updateDraftState: draft =>
    dispatch(setDraftState(ENTITIES[MEDIA_RESOURCE], draft))
});

export default connect(null, mapDispatchToProps)(EditorToolbar);
