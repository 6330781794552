import React from "react";

const Circle = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-circle"
  >
    <path d="M25,0 C11.1895161,0 0,11.1895161 0,25 C0,38.8104839 11.1895161,50 25,50 C38.8104839,50 50,38.8104839 50,25 C50,11.1895161 38.8104839,0 25,0 Z M46.7741935,25 C46.7741935,36.9657258 37.0866935,46.7741935 25,46.7741935 C13.0342742,46.7741935 3.22580645,37.0866935 3.22580645,25 C3.22580645,13.0342742 12.9133065,3.22580645 25,3.22580645 C36.9657258,3.22580645 46.7741935,12.9133065 46.7741935,25 Z" />
  </svg>
);

export default Circle;
