import styled from "styled-components";
import Input from "../../../../shared/components/Input";

export const StyledValueInput = styled(Input)`
  border: 2px solid rgba(0, 0, 0, 0.5);
  box-shadow: none;
  width: 250px;
`;

export const StyledTick = styled.div`
  width: 15px;
  height: 20px;
  margin: 6px;
  background-color: #ffffff;
  border: 3px solid #000000;
`;

export const StyledValueInputWrapper = styled.div`
  display: flex;
`;

export const StyledInputContainer = styled.div`
  display: flex;
`;

export const StyledValueDisplay = styled.span`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 8px 0 0 12px;
`;
