import React from "react";
import FormativeQuestionsForm from "../../containers/FormativeQuestionsForm";

const FormativeQuestionsEdit = () => {
  return (
    <React.Fragment>
      <FormativeQuestionsForm />
    </React.Fragment>
  );
};

export default FormativeQuestionsEdit;
