import React from "react";

const AlignRight = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-alignright"
  >
    <defs>
      <path
        d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,0 L34,0 L34,20 L24,20 L24,0 Z"
        id="path-1"
      ></path>
      <path
        d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,0 L34,0 L34,20 L24,20 L24,0 Z"
        id="path-3"
      ></path>
    </defs>
    <g
      id="Icon/Alignment/right"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Rectangle"
        fill="#000000"
        transform="translate(49.000000, 25.000000) rotate(-270.000000) translate(-49.000000, -25.000000) "
        x="24"
        y="24"
        width="50"
        height="2"
      ></rect>
      <g
        id="Color/Brand"
        transform="translate(30.000000, 20.000000) rotate(-270.000000) translate(-30.000000, -20.000000) translate(13.000000, 0.000000)"
      >
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Mask" fill="#737171" xlinkHref="#path-1"></use>
        <g mask="url(#mask-2)" fill="#377AD3" id="Rectangle">
          <g transform="translate(-3.000000, -10.000000)">
            <rect x="0" y="0" width="100" height="100"></rect>
          </g>
        </g>
      </g>
      <g
        id="Color/Brand-Dark"
        transform="translate(30.000000, 30.000000) rotate(-270.000000) translate(-30.000000, -30.000000) translate(13.000000, 10.000000)"
      >
        <mask id="mask-4" fill="white">
          <use xlinkHref="#path-3"></use>
        </mask>
        <use id="Mask" fill="#B6B4B4" xlinkHref="#path-3"></use>
        <g mask="url(#mask-4)" fill="#384E70" id="Rectangle">
          <g transform="translate(-13.000000, -10.000000)">
            <rect x="0" y="0" width="100" height="100"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AlignRight;
