import { actionCreator } from "../../../shared";

export const WATCH_GET_LOCK_STATUS = "WATCH_GET_LOCK_STATUS";
export const UPDATE_LOCK = "UPDATE_LOCK";
export const WATCH_UNLOCK_POST = "WATCH_UNLOCK_POST";
export const FORCE_UNLOCK_POST = "FORCE_UNLOCK_POST";

export const LOCK_POST_BROADCAST = "LOCK_POST_BROADCAST";
export const UNLOCK_POST_BROADCAST = "UNLOCK_POST_BROADCAST";
export const FORCE_UNLOCK_POST_BROADCAST = "FORCE_UNLOCK_POST_BROADCAST";

/**
 * Action for updating lock data
 * @param lock
 * @param entityType
 * @returns {{type: string, data: *}}
 */
export const actionUpdateLock = (lock, entityType, postId) => ({
  type: actionCreator(entityType, UPDATE_LOCK),
  entityType,
  lock,
  postId
});

/**
 * Action for lock post
 * @param lock
 * @param entityType
 * @returns {{type: string, data: *}}
 */
export const actionLockPost = (entityId, postId) => ({
  type: LOCK_POST_BROADCAST,
  postId,
  entityId
});

/**
 * Action for unlock post
 * @param lock
 * @param entityType
 * @returns {{type: string, data: *}}
 */
export const actionUnLockPost = (entityId, postId) => ({
  type: UNLOCK_POST_BROADCAST,
  postId,
  entityId
});

/**
 * Action for force unlock post
 * @param lock
 * @param entityType
 * @returns {{type: string, data: *}}
 */
export const actionForceUnLockPost = (entityId, postId) => ({
  type: FORCE_UNLOCK_POST_BROADCAST,
  postId,
  entityId
});
