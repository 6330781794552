import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Card from "../../../products/components/Card";
import PublishButton from "../../../products/components/PublishButton";
import PreviewButton from "../../../products/components/PreviewButton";
import {
  selectClassroomGroup,
  selectStickersInClassroomgroup
} from "../../store/selectors";
import { selectClassroomgroupEntityId } from "../../../store/selectors";
import StickersList from "../StickersList/StickersList";
import { publishStickers } from "../../api/actions";

const Container = styled.div`
  display: flex;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -3px;

  button {
    margin: 0 3px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 100%;
`;

const ListWrapper = styled.div`
  height: ${ props => props.stickerLength > 0 ? "400px" : "50px"};
`;

const StickersCard = () => {
  const stickers = useSelector(selectStickersInClassroomgroup);
  const classroomgroup = useSelector(selectClassroomGroup);
  const classroomGroupEntityId = useSelector(selectClassroomgroupEntityId);
  const {updatedAt, publishedAt} = stickers.length > 0 ? stickers[0] : {};
  const dispatch = useDispatch();

  const publishToLive = () => {
    dispatch(publishStickers(classroomGroupEntityId));
  };

  const gotoPreview = () => {
    window.open(classroomgroup.previewURL);
  };

  return (
    <Card title="Stickers">
      <ListWrapper stickerLength={stickers.length}>
        <StickersList stickers={stickers} />
      </ListWrapper>
      <Container>
        <ButtonWrapper>
          <ButtonGroup>
            <PublishButton
              toLive={false}
              disabled
              publishtime={updatedAt}
              isLatest
            />
            <PreviewButton onClickPreview={gotoPreview} />
          </ButtonGroup>
          <ButtonGroup>
            <PublishButton
              toLive={true}
              onClick={publishToLive}
              disabled={false}
              publishtime={publishedAt}
              isLatest={publishedAt >= updatedAt}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Container>
    </Card>
  );
};

export default StickersCard;
