import React from "react";
import TagMedia from "../../components/TagMedia";
import {
  MediaToolbarWrapper,
  MediaButton,
  MediaToolBarSection,
  StyledMediaToolbarButtonGroup,
  StyledModalContent,
  StyledButtonWrapper,
  StyledButton,
  StyledSpan
} from "./StyledMediaToolbar";
import { MEDIA_RESOURCE, MEDIA, DELETE } from "../../../constants";
import Link from "../../../shared/components/Link";
import HasPermission from "../../../shared/Permissions";
import Modal from '../../../shared/components/Modal/Modal';

const MediaToolBar = props => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const renderEditLink = active => {
    const link = active && active.actions ? active.actions.editLink : null;
    return (
      link &&
      props.selectedMediaItems.length === 1 && (
        <Link to={link}>
          <MediaButton outline studlicon="PostAdd">
            {" "}
            Redigera{" "}
          </MediaButton>
        </Link>
      )
    );
  };

  const renderMediaresourceButtons = ({
    hasSelectedItems,
    active,
    newPostLink
  }) => (
    <StyledMediaToolbarButtonGroup>
      {hasSelectedItems && renderEditLink(active)}
      <Link to={newPostLink}>
        <MediaButton outline studlicon="CanvasAdd">
          {" "}
          Skapa ny{" "}
        </MediaButton>
      </Link>
      <TagMedia disabled={!hasSelectedItems} />
    </StyledMediaToolbarButtonGroup>
  );
  
  const renderDelete = (hasSelectedItems) => (
    <MediaButton
      studlicon="Close"
      disabled={!hasSelectedItems}
      outline
      onClick={() => setIsModalOpen(true)}
    >
      {" "}
      Ta bort markerade{" "}
    </MediaButton>
  );

  const renderMediaButtons = ({
    hasSelectedItems,
    onClearMediaList,
    onSelectAll,
    onshowSelectedModal,
    onUploadMedia,
    showDropZone,
    onDeleteFiles
  }) => (
    <StyledMediaToolbarButtonGroup>
      <MediaButton
        icon="RotateCcw"
        outline
        disabled={!hasSelectedItems}
        onClick={onClearMediaList}
      >
        {" "}
        Avmarkera alla{" "}
      </MediaButton>
      <MediaButton icon="Check" outline onClick={onSelectAll}>
        {" "}
        Markera alla{" "}
      </MediaButton>
      <MediaButton
        studlicon="Pencil"
        disabled={!hasSelectedItems}
        outline
        onClick={onshowSelectedModal}
      >
        {" "}
        Redigera markerade{" "}
      </MediaButton>
      <TagMedia disabled={!hasSelectedItems} />
      <HasPermission
        component={renderDelete(hasSelectedItems)}
        section={MEDIA}
        permission={DELETE}
        type={props.mediaType}
      />
      <MediaButton icon="Upload" outline onClick={onUploadMedia.bind()}>
        {" "}
        {showDropZone ? "Avbryt" : "Ladda upp fil"}{" "}
      </MediaButton>
    </StyledMediaToolbarButtonGroup>
  );

  const renderConfirmModal = ({onDeleteFiles}) => (
    <Modal isModalOpen={isModalOpen} modalClose={() => setIsModalOpen(false)}>
      <StyledModalContent>
          {renderDeleteMsg()}
          <StyledButtonWrapper>
          <StyledButton onClick={() => setIsModalOpen(false)}>Avbryt</StyledButton>
          <StyledButton onClick={() => handleDeleteFiles(onDeleteFiles)}>Ta bort</StyledButton>
          </StyledButtonWrapper>
        </StyledModalContent>
      </Modal>
  )

  const renderDeleteMsg = () => (
    <p>Är du säker på att du vill ta bort (<StyledSpan>{props.selectedMediaItems.length}</StyledSpan>) {props.selectedMediaItems.length > 1 ? "filer" : "fil"}?</p>
  )

  const handleDeleteFiles = (onDeleteFiles) => {
    onDeleteFiles();
    setIsModalOpen(false);
  }

  return (
    <MediaToolbarWrapper>
      <MediaToolBarSection start>
        {props.section === MEDIA_RESOURCE
          ? renderMediaresourceButtons(props)
          : renderMediaButtons(props)}
      </MediaToolBarSection>
      {renderConfirmModal(props)}
    </MediaToolbarWrapper>
  );
};

export default MediaToolBar;
