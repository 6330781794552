import { createSelector } from "reselect";
import { selectCurrentPostType } from "../posts/api/selectors";
import { getSection } from "../shared/Permissions";
import { getMediaRoute } from "../shared/helpers";

/**
 * Selects current user from state
 * @param state
 * @returns {*}
 */
export const selectCurrentUserData = state => state.viewer;

/**
 * Returns current entityId
 * @param {*} state
 */
export const selectEntityId = state =>
  Number(state.location.payload.entityId) >= 0
    ? Number(state.location.payload.entityId)
    : undefined;

/**
 * Returns current entityType
 * @param {*} state
 */
export const selectEntityType = state =>
  state.location.routesMap[state.location.type].type;

export const selectIsInSystem = state =>
  state.location.pathname.indexOf("system") >= 0;

export const selectPrePath = state => {
  const parts = state.location.pathname.split("/");
  return parts[1];
};

export const selectUploadBasePath = state => {
  const entityId = selectEntityId(state);
  const entityType = selectEntityType(state);
  const systems = state.Systems.get("systems");
  const mediaRoute = getMediaRoute(entityId, entityType, systems);
  return state.location.routesMap[mediaRoute].uploadBasePath;
};


export const selectCurrentPath = state => state.location.pathname;

export const selectWorkflowMap = state => state.Product.get("workflowMap");

export const selectWorkflowMapForCurrentPostType = createSelector(
  selectCurrentPostType,
  selectWorkflowMap,
  (postType, workMap) => {
    const section = getSection(postType);
    return workMap ? workMap[section] || workMap["exercises"] : {};
  }
);

export const selectClassroomgroupEntityId = state =>
  Number(state.location.payload.classroomGroupEntityId) >= 0
    ? Number(state.location.payload.classroomGroupEntityId)
    : undefined;
