import { PrimaryButton, StandardButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import { createSaveableObject } from "../../sagas/PostDraft/helpers";

export const handlePrompt = (thisProps, goToRoute) => {
  const promptSaveIfModified = callback => {
    if (thisProps.postDraftState !== "dirty") {
      callback();
      return;
    }
    const header = "Vill du spara ändringarna?";
    const children = (
      <div>Ändringarna som du gjort kommer att förloras om du inte sparar.</div>
    );
    const actions = (
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <ButtonGroup>
          <StandardButton
            onClick={() => {
              thisProps.modalClose();
              callback(true);
            }}
          >
            Spara inte
          </StandardButton>
          <StandardButton onClick={() => thisProps.modalClose()}>
            Avbryt
          </StandardButton>
          <PrimaryButton
            onClick={() => {
              thisProps.modalClose();
              callback();
            }}
          >
            Spara
          </PrimaryButton>
        </ButtonGroup>
      </div>
    );
    thisProps.modalOpen({
      children,
      header,
      actions,
      closeModal: thisProps.modalClose
    });
  };

  /*
   * @param goToRoute String: ROUTE_EXERCISES, ROUTE_RESOURCES, ROUTE_HELP_RESOURCES
   */
  const promptSaveOnBackClick = () => {
    if (thisProps.postDraftState === "dirty") {
      promptSaveIfModified((notSaved = false) => {
        if (notSaved) {
          thisProps.goToPosts(goToRoute, thisProps.post.entity_id, {
            uses: thisProps.post.id
          });
        } else if (!notSaved) {
          saveBeforeLeave();
          setTimeout(() => {
            // wait a moment so the post has time to be saved before going to postlist 
            thisProps.goToPosts(goToRoute, thisProps.post.entity_id, {
              uses: thisProps.post.id
            });
          }, 100);
          
        }
      });

      return false;
    }
  };

  const saveBeforeLeave = () => {
    const { id } = createSaveableObjectTest();
    id !== null && thisProps.savePostBeforeLeave(createSaveableObjectTest());
  };

  /**
   * Gathers neccessary data and composes an object based on it
   */
  const createSaveableObjectTest = () => {
    const { postState, postDraft, helpDraft, title, resources } = thisProps;
    const { id, difficulty, entity_id } = postState?.first();
    return createSaveableObject(
      id,
      entity_id,
      title,
      difficulty,
      resources,
      postDraft,
      helpDraft
    );
  };

  return promptSaveOnBackClick();
};
