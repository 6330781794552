import React from "react";
import {
  HelpResource,
  Media,
  Sound,
  Resources,
  Posts,
  Template,
  ImageResourceIcon,
  AidaHelp
} from "../../../shared/components/StudliIcons";
import Tag from "../../../shared/components/Tag";
import { format } from "../../../date";
import {
  MediaListItemContainer,
  MediaListItemContainerList,
  MediaListItemImage,
  MediaListItemContainerChild,
  MediaListItemContainerChildList,
  MediaListItemContainerEndChild,
  MediaListItemInfo,
  MediaListID,
  DummyImage,
  Marker,
  MarkerWrapper,
  MarkerCount,
  Title,
  Desc,
  MediaLabels,
  MediaFileUserInfo,
  MediaFileUsageInfoWrapper,
  MediaFileUsageInfo,
  MediaTag,
  MediaFileInfoWrapper,
  CheckBoxWrapper,
  MediaListItemContainerThumbnailSection,
  StyledVideoThumbnail,
  FileTitle
} from "./StyledMediaListItem";
import Checkbox from "../../../shared/components/Checkbox";
import {
  getMedia,
  renderMedia
} from "../../../shared/MediaHandler/MediaHandler";
import { File } from "react-feather";

/**
 * Format the date
 * @param {*} dateString
 */
const formatDate = dateString => {
  const date = new Date(dateString);
  return format(date);
};

/**
 * basic color of tags
 */
const basicColor = "#00b294";

const MediaListItem = ({
  id,
  src,
  constenttype,
  user,
  date,
  labels,
  entity,
  format,
  description,
  resources,
  exercises,
  mediaresources,
  helpresources,
  onClick,
  isSelected,
  isGridView,
  title,
  data,
  aidaposts,
  series,
  classroomgroup,
  marketmessage,
  medialib,
  fileTitle
}) => {
  /**
   * render the mediaresource file
   */
  const renderMediaResource = () => {
    const mediaresourceObject = {
      mediaresource: {
        data: {
          id: id,
          text: title
        },
        type: "mediaresource"
      }
    };

    const resourcesObject = {
      [id]: {
        data: data.data,
        type: data.type
      }
    };

    return renderMedia(getMedia(mediaresourceObject, resourcesObject));
  };

  /**
   * render the mediafile
   */
  const renderImage = () => <img src={src} alt="title" />;

  /**
   * Render Media thumbnail
   */
  const renderMediaThumbnail = () => (
    <DummyImage>
      <Media size="130" viewBox="10 10 50 50" />
    </DummyImage>
  );

  /**
   * render Sound thumbnail
   */
  const renderSoundThumbnail = () => (
    <DummyImage>
      <Sound size="80" viewBox="10 10 50 50" />
    </DummyImage>
  );

  /**
   * Render video thumbnail
   */
  const renderVideoThumbnail = () => (
    <StyledVideoThumbnail src={src} alt="Video-thumbnail" />
  );

  const renderDocumentThumbnail = () => <File size="70" strokeWidth="1.5" />;

  /**
   * Add br tags in the description
   */
  const showDescription = () => {
    const desc = description ? description.split("\n").join("</br>") : "";
    return { __html: desc };
  };

  /**
   * returns the image
   */
  const hasImage = src ? renderImage() : renderMediaThumbnail();

  /**
   * Checks if it should render image or mediaresource
   */
  const renderCorrectFileType = () =>
    data ? renderMediaResource() : getThumbnail();

  /**
   * return correct thumbnail depending on content type
   */
  const getThumbnail = () => {
    if (!constenttype) return null;

    if (constenttype.match(/^image\//)) {
      return hasImage;
    }

    if (constenttype.match(/^audio\//)) {
      return renderSoundThumbnail();
    }

    if (constenttype.match(/^video\//)) {
      return renderVideoThumbnail();
    }

    if (constenttype.match(/^application\//)) {
      return renderDocumentThumbnail();
    }
  };

  /**
   * Render thumbnail
   */

  const renderThumbnail = bool => (
    <MediaListItemImage isSelected={isSelected} listview={bool}>
      {renderCorrectFileType()}
    </MediaListItemImage>
  );

  const inAidaMediaInfo = () =>
    aidaposts >= 0 && (
      <MarkerWrapper>
        <MarkerCount isSelected={isSelected && !isGridView}>
          {aidaposts}
        </MarkerCount>
        <Marker type="aida">
          <AidaHelp size="15" viewBox="10 10 50 50" />
        </Marker>
      </MarkerWrapper>
    );

  const inSeriesMediaInfo = () =>
    series >= 0 && (
      <MarkerWrapper>
        <MarkerCount isSelected={isSelected && !isGridView}>
          {series}
        </MarkerCount>
        <Marker type="series">
          <AidaHelp size="15" viewBox="10 10 50 50" />
        </Marker>
      </MarkerWrapper>
    );

  const inClassromgroupMediaInfo = () => (
    <>
      {classroomgroup >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {classroomgroup}
          </MarkerCount>
          <Marker type="classroomgroup">
            <AidaHelp size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
    </>
  );

  const inMessageMediaInfo = () => (
    <>
      {marketmessage >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {marketmessage}
          </MarkerCount>
          <Marker type="marketmessage">
            <AidaHelp size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
    </>
  );

  const postsMediaInfo = () => (
    <>
      {helpresources >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {helpresources}
          </MarkerCount>
          <Marker type="helpresource">
            <HelpResource size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
      {mediaresources >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {mediaresources}
          </MarkerCount>
          <Marker type="mediaresource">
            <ImageResourceIcon size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
      {resources >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {resources}
          </MarkerCount>
          <Marker type="resource">
            <Resources size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
      {exercises >= 0 && (
        <MarkerWrapper>
          <MarkerCount isSelected={isSelected && !isGridView}>
            {exercises}
          </MarkerCount>
          <Marker type="post">
            <Posts size="15" viewBox="10 10 50 50" />
          </Marker>
        </MarkerWrapper>
      )}
    </>
  );

  const getMediaInfo = () => {
    switch (medialib) {
      case "classroomgroup":
        return inClassromgroupMediaInfo();
      case "series":
        return inSeriesMediaInfo();
      case "aida":
        return inAidaMediaInfo();
      case "messages":
        return inMessageMediaInfo();
      case "adapt":
        return postsMediaInfo();
      default:
        return postsMediaInfo();
    }
  };

  /**
   * Render MediaInfo for Gridview
   */
  const renderGridViewMediaInfo = () => (
    <MediaListItemInfo>
      <MediaListID isSelected={isSelected && !isGridView}>
        {"ID: " + id}
      </MediaListID>
      {getMediaInfo()}
      {isIcon ? (
        <Marker type="other">
          <Template size="15" viewBox="10 10 50 50" />
        </Marker>
      ) : null}
    </MediaListItemInfo>
  );

  /**
   * Renders checkbox
   */
  const renderCheckBox = () => (
    <CheckBoxWrapper>
      <Checkbox isChecked={isSelected ? isSelected : false} onClick={onClick} />
    </CheckBoxWrapper>
  );

  /**
   * Render media file labels
   */
  const renderMediaListItemLabels = () => (
    <MediaLabels>
      {labels.length > 0 ? (
        labels.map(label => (
          <MediaTag key={label.id}>
            <Tag label={label.title} labelColor={label.color || basicColor} />
          </MediaTag>
        ))
      ) : (
        <span>Ingen label satt</span>
      )}
    </MediaLabels>
  );

  /**
   * Render user and label info for mediafile in listview
   */
  const renderMediaFileUserAndLabelInfo = () => (
    <MediaListItemContainerChildList style={{ flex: 3 }}>
      <MediaFileUserInfo>
        <MediaFileInfoWrapper>
          <Title>Skapad:</Title>
          <Desc>{uploaded}</Desc>
          <Title>av:</Title>
          <Desc>{user}</Desc>
        </MediaFileInfoWrapper>
        <MediaFileInfoWrapper>
          <Title>Titel:</Title>
          <Desc>{title}</Desc>
        </MediaFileInfoWrapper>
        <div style={{ marginTop: "10px", display: "flex" }}>
          <Title>Labels: </Title>
          {renderMediaListItemLabels()}
        </div>
      </MediaFileUserInfo>
    </MediaListItemContainerChildList>
  );

  const renderTitle = () => (
    <>
      <Title>Inmatad titel: </Title>
      <MediaListItemInfo>
        <Desc>{fileTitle}</Desc>
      </MediaListItemInfo>
    </>
  );

  /**
   * Render description in listview
   */
  const renderDescription = () => (
    <MediaListItemContainerEndChild style={{ flex: 1.5 }}>
      {fileTitle && renderTitle()}
      <Title>Beskrivning: </Title>
      <MediaListItemInfo style={{ flex: 2, maxHeight: "90px", height: "100%" }}>
        <Desc>
          <div dangerouslySetInnerHTML={showDescription()}></div>
        </Desc>
      </MediaListItemInfo>
    </MediaListItemContainerEndChild>
  );

  /**
   * Render thumbnail and id for media file in listview
   */
  const renderThumbnailAndID = () => (
    <MediaListItemContainerThumbnailSection isSelected={isSelected}>
      {renderThumbnail(true)}
      {renderCheckBox()}
      {renderGridViewMediaInfo()}
    </MediaListItemContainerThumbnailSection>
  );

  /**
   * Render usage and other info for media file in listview
   */
  const renderMediaListUsageRow = () => (
    <MediaFileUsageInfoWrapper>
      <MediaFileUsageInfo>
        <Title>Filtyp: </Title>
        <Tag variant="info" label={format} labelColor="#377AD3" />
      </MediaFileUsageInfo>
      <MediaFileUsageInfo>
        <Title>Produkt: </Title>
        <Tag variant="info" label={entity} labelColor="#14B302" />
      </MediaFileUsageInfo>
    </MediaFileUsageInfoWrapper>
  );

  /**
   *  Render the item in gridview
   */
  const renderGridViewItem = () => (
    <MediaListItemContainer style={{ height: "170px" }} isSelected={isSelected}>
      <MediaListItemContainerChild>
        {renderThumbnail(false)}
        {renderCheckBox()}
        {fileTitle && <FileTitle>{fileTitle}</FileTitle>}
        {renderGridViewMediaInfo()}
      </MediaListItemContainerChild>
    </MediaListItemContainer>
  );

  /**
   * Render the item in listview
   */
  const renderListViewItem = () => (
    <MediaListItemContainerList>
      <div
        style={{
          display: "flex",
          width: "100%",
          maxHeight: "100%",
          minHeight: 0
        }}
      >
        {renderThumbnailAndID()}
        {renderMediaFileUserAndLabelInfo()}
        {renderDescription()}
      </div>
      {renderMediaListUsageRow()}
    </MediaListItemContainerList>
  );

  const isIcon = false;
  const uploaded = formatDate(date);

  // Render grid or listview
  if (isGridView) {
    return renderGridViewItem();
  } else {
    return renderListViewItem();
  }
};

MediaListItem.defaultProps = {
  onClick() {},
  onChange() {}
};

export default MediaListItem;
