import MultiSvgImage from "./components/MultiSvgImage";
import { MULTIIMAGE } from "../constants";

export const createMultiImagePlugin = () => ({
  entityRendererFn: ({ type, data, settings }) => {
    const types = [MULTIIMAGE];

    if (types.includes(type)) {
      const icons = data.icons;
      const format = data.format;
      return {
        component: MultiSvgImage,
        props: {
          icons,
          format,
          settings
        }
      };
    }
    return null;
  }
});

export default createMultiImagePlugin;

export const emptyBlocks = {
  [MULTIIMAGE]: {
    type: MULTIIMAGE,
    data: {
      icons: []
    }
  }
};
