import React from "react";
import styled from "styled-components";

import Card from "./Card";
import TaxonomyLevels from "./TaxonomyLevel";
import TaxonomySubjects from "./TaxonomySubject";

const Container = styled.div`
  display: flex;
`;

const Part = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 1.75rem;
  }

  &:last-child {
    margin-left: 1.75rem;
  }
`;

const ClassroomCard = () => {
  return (
    <Card title="Klassificering">
      <Container>
        <Part>
          <TaxonomyLevels />
          <TaxonomySubjects />
        </Part>
      </Container>
    </Card>
  );
};

export default ClassroomCard;
