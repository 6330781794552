import Image from "./components/Image/Image";
import { IMAGE } from "../constants";
import { getMediaObject } from "./dataModels";

export const createImagePlugin = () => ({
  entityRendererFn: ({ type, data, settings }) => {
    const types = [IMAGE];

    if (types.includes(type)) {
      return {
        component: Image,
        props: {
          data,
          settings
        }
      };
    }
    return null;
  }
});

export default createImagePlugin;

export const emptyBlocks = {
  image: {
    type: IMAGE,
    data: {
      [IMAGE]: getMediaObject(IMAGE)
    }
  }
};
