import * as React from "react";
import {
  StyledInputTypeInputsContainer,
  StyledInputTypeLabel,
  StyledRowContainer
} from "./StyledSidebar";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import {
  SETTINGS,
  KEYBOARD_CHARACTER_SET,
  DROPDOWN,
  INPUT
} from "../../../constants";
import SidebarButton from "../NumberLine/SidebarButton/SidebarButton";
import DropDown, {Item} from "../../../shared/components/DropDown";
import Input from "../../../shared/components/Input";
import { isPositiveInteger } from "../../../shared/helpers";

const SidebarButtonStyles = {
  width: "40px",
  height: "40px"
};

const SidebarWideButtonStyles = {
  width: "80px",
  height: "40px"
};

const SidebarVeryWideButtonStyles = {
  width: "164px",
  height: "40px"
};

const styles = {
  default: SidebarButtonStyles,
  wide: SidebarWideButtonStyles,
  veryWide: SidebarVeryWideButtonStyles
};

const SideBar = ({
  updateData,
  settings,
  data,
  rowSettingStyles,
  storeDraft,
  draftTarget,
  keyboards
}) => {
  
  /**
   * Handles on change event with predefined keys
   * @param key
   * @returns {function(*=)}
   */
  const changeSetting = (key, value) => () => {
    const active = isActiveSetting(key, value)
    storeDraft(draftTarget);
    updateData(key, (typeof value === 'boolean' && active ) ? !value : value, SETTINGS);
  };

  /**
   * Checks if a setting is active
   * @param key
   * @param value
   * @returns {boolean}
   */
  const isActiveSetting = (key, value) => settings && settings[key] === value;

  /**
   * Handles on change event for selected keyboard
   */
  const handleKeyboardDropDownChange = (key, value) => {
    storeDraft(draftTarget);
    updateData(key, value, SETTINGS);
  }

  /**
   * Handles on change event for input field
   */
  const handleInputFieldChange = (key) => (value) => {
    if(isPositiveInteger(value)) {
      storeDraft(draftTarget);
      updateData(key, value, SETTINGS);
    }
  }

  /**
   * Renders buttons for a setting row
   */
  const renderButtons = (rowData, rowSetting, rowStyle) =>
  
    rowData.map(({ id, label, value }) => (
      <SidebarButton
        key={id}
        callback={changeSetting(rowSetting, value)}
        isActive={isActiveSetting(rowSetting, value)}
        content={label}
        overrideStyles={styles[rowStyle]}
      />
    ));

  /**
   * Renders keyboard dropdown items
   */
  const renderDropDownItems = (dropDownData) => (
    dropDownData.map(({ id, label }) => (
      <Item key={`item_${label}`} value={id}>
        {label}
      </Item>
  )));

  /**
   * Renders keyboard dropdown list
   */
  const renderDropdown = (rowData, rowSetting, defaultValue) => (
    <DropDown
      defaultValue={defaultValue}
      onChange={value => handleKeyboardDropDownChange(rowSetting, value)}
      inSidebar={true}
      overrideStyles={{minHeight: '2rem', height: 'auto'}}
    >
      {renderDropDownItems(rowData)}
    </DropDown>
  )

  /**
   * Renders input field
   */
  const renderInputField = (rowSetting, defaultValue) => (
    <Input
      placeholder={'Skriv in multiple...'}
      value={defaultValue}
      onChange={handleInputFieldChange(rowSetting)} />
  )

  const renderKeyboardSection = (keyboardsData) => {
    const updatedData = keyboardsData.map(({keyboard_id, keyboard_label}) => ({id: keyboard_id,label: keyboard_label}))
    return(
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor={"#35877A"}>
          {'Alternativ inmatning'}
        </StyledSidebarHeading>
        <StyledInputTypeInputsContainer>
          <React.Fragment>
            {renderDropdown(updatedData, KEYBOARD_CHARACTER_SET, settings.keyboardCharacterSet)}
          </React.Fragment>
        </StyledInputTypeInputsContainer>
      </StyledSidebarSection>
    )
  }

  /**
   * Return the selected row component.
   * @param {*} rowComponent
   * @param {*} rowData
   * @param {*} rowSetting
   */
  const getComponent = (rowComponent, rowData, rowSetting) => {
    switch(rowComponent) {
      case DROPDOWN: return renderDropdown(rowData, rowSetting, settings[rowSetting])
      case INPUT: return renderInputField(rowSetting, settings[rowSetting])
        default: return renderButtons(rowData, rowSetting, rowSettingStyles[rowSetting])
    }
  }

  /**
   * Renders a settings row
   * @param rowTitle
   * @param rowData
   * @param rowSetting
   * @returns {*}
   */
  const renderSettingRow = (rowTitle, rowData, rowSetting, rowStyle, rowComponent) => (
    <React.Fragment key={rowSetting}>
      <StyledInputTypeLabel>{rowTitle}</StyledInputTypeLabel>
      <StyledInputTypeInputsContainer setting={rowStyle}>
        <React.Fragment>
          { getComponent(rowComponent, rowData, rowSetting) }
        </React.Fragment>
      </StyledInputTypeInputsContainer>
    </React.Fragment>
    
  );

  const renderSetting = setting => (
    <StyledSidebarSection key={setting.title}>
      <StyledSidebarHeading backgroundColor={"#35877A"}>
        {setting.title}:
      </StyledSidebarHeading>
      <StyledRowContainer>{renderRowSettings(setting.rows)}</StyledRowContainer>
    </StyledSidebarSection>
  );

  const renderRowSettings = rowsArray =>
    rowsArray.map(row => renderSettingRow(row.title, row.data, row.type, row.style, row.component));

  const renderBlockSettings = settingsArray =>
    settingsArray.map(setting => renderSetting(setting));

  /**
   * Initiate component
   */
  return (
    <StyledBlockSidebar maxWidth={"200px"}>
      {renderBlockSettings(data)}
      {keyboards && renderKeyboardSection(keyboards)}
    </StyledBlockSidebar>
  );
};

export default SideBar;
