import { Column } from "react-virtualized";
import ImageRenderer from "../Renderer/ImageRenderer";

const ImageColumn = () => null;

ImageColumn.propTypes = { ...Column.propTypes };
ImageColumn.defaultProps = {
  ...Column.defaultProps,
  ...{
    cellRenderer: ImageRenderer,
    draggable: true
  }
};

export default ImageColumn;
