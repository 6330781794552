import React from "react";
import { upperCaseFirst } from "./helpers";
import {
  DATA,
  DEFAULT,
  DROP_ZONE,
  MODAL,
  OPEN,
  RESOURCE,
  SETTINGS,
  MEDIA,
  MEDIA_RESOURCE,
  RESOURCES
} from "../constants";
import PluginHeader from "../plugins/components/PluginHeader/PluginHeader";
import { ModalHandler } from "./effects/ModalHandler";
import uuidv5 from "uuid";

/**
 * If a new block key is ever to be added for the root-scope of a block, such as "data" or "settings", add that in
 * the array below. No further actions should be necessary inside this component.
 * the "mergeObjects" and "updateData" -functions should need no alteration.
 */
export const keys = [DATA, SETTINGS];
export const modals = [DROP_ZONE, MEDIA, RESOURCE, MEDIA_RESOURCE];

/**
 * Mainly built to prevent duplicate functions and maintain a DRY application.
 * PluginHoc takes an object with three keys
 *
 * @param setup - {
 *   Component          - A react component to be rendered inside PluginHoc
 *   defaultData        - Any data that is to be added to data as default
 *   defaultSettings    - Any settings that is to be added to settings as default
 * }
 * @returns {function(*=)} - React component
 * @constructor
 */
const PluginHoc = setup => props => {
  const id = uuidv5();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hasRegisteredValidators, setHasRegisteredValidators] = React.useState(
    false
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mediaresources, setMediaResources] = React.useState({});

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    const unregister = () =>
      props.unregisterValidators && props.unregisterValidators(id);

    return unregister;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Returns error string if a invalid key is supplied.
   * @param target
   * @returns {string}
   * @constructor
   */
  const ErrorInvalidKey = target => `Could not perform update of block. The target "${target}" could not be found in keys
   array and is therefor considered invalid. Available targets: ${keys}.`;

  /**
   * Returns error string if a invalid modal is requested.
   * @param modal - {string}
   * @returns {function(): string}
   * @constructor
   */
  const ErrorInvalidModal = modal => () =>
    `Could not open modal "${modal}. No such modal exists"`;

  /**
   * Extract default by key from setup object
   * @returns {*}
   */
  const extractDefault = targetKey =>
    setup[`${DEFAULT}${upperCaseFirst(targetKey)}`];

  /**
   * Merges Objects based on the keys array
   *
   * @param key - {}
   * @param value - {*}
   * @param target - {string}
   * @returns {{}[]}
   */
  const mergeObjects = (key, value, target) =>
    keys.map(targetKey =>
      target === targetKey
        ? shouldOverwriteTarget(key, value, targetKey)
        : { ...extractDefault(targetKey), ...props[targetKey] }
    );

  /**
   * Decides whether or not a key should be written to, or if the entire target should be overwritten by the new value.
   * @param key
   * @param value
   * @param targetKey
   * @returns {{}}
   */
  const shouldOverwriteTarget = (key, value, targetKey) =>
    key === null
      ? { ...extractDefault(targetKey), ...value }
      : { ...extractDefault(targetKey), ...props[targetKey], [key]: value };

  /**
   * Map the data back to it's keys, that way creating a single object that is merge-able with for example the editor
   * @param objects
   * @returns {*|{}}
   */
  const mapObjects = objects =>
    keys.reduce(
      (accum, currentVal, index) => ({
        ...accum,
        [currentVal]: objects[index]
      }),
      {}
    );

  /**
   * Updates data
   *
   * @param key - {string|null} if string is supplied, the matching key will be updated. If null is supplied,
   * the entire object, that is the key `target`, will be overwritten.
   *
   * @param value - {*} the new value
   * @param target - {string} A key of what part of the block you want to update. Key must be found in the `keys`-array
   * which can be found at the top of this file
   */
  const updateData = (key, value, target) => {
    if (target === RESOURCES) {
      updateResources(value);
      return;
    }

    if (!keys.includes(target)) throw new Error(ErrorInvalidKey(target));
    props.onChange(
      props.blockKey,
      mapObjects(mergeObjects(key, value, target))
    );
  };

  /**
   * Saves the resource data to state
   * @param {*} value
   */
  const updateResources = value =>
    setMediaResources({ ...mediaresources, ...value });

  /**
   * Callback for opening a modal.
   * @param modal - {string} A string used to identify which modal should be opened
   * @param modalCallback - {func}
   * @returns {function()}
   */
  const openModal = modal => (modalCallback, conditions) => () => {
    if (!modals.includes(modal)) throw new Error(ErrorInvalidModal(modal));

    props.actions[`${OPEN}${upperCaseFirst(modal)}${upperCaseFirst(MODAL)}`](
      modalCallback,
      conditions
    );
  };

  /**
   * Preps a modal with necessary data and allows for more control in individual components
   * @param type
   * @param openOnMount
   * @param key
   * @returns {{openModal}}
   */
  const preppedModal = (type, settings) =>
    ModalHandler({
      type,
      data: props.data,
      updateData,
      openMediaLibrary: openModal(getModalType(type)),
      settings
    });

  /**
   * Used to update the postdraft list.
   */
  const updateDraftList = () => {
    if (!props.readOnly) {
      props.storeDraft(props.draftTarget);
    }
  };

  /**
   * Returns either 'mediaresource' or 'media'
   * Right now these are the only types of media defined.
   *
   * @param {} type
   */
  const getModalType = type =>
    type === MEDIA_RESOURCE ? MEDIA_RESOURCE : MEDIA;

  /**
   * Prepares the register validator function with the block key
   * @param validators
   */
  const preppedRegisterValidator = (...validators) => {
    if (!hasRegisteredValidators) {
      validators.forEach(([validator, errorMsg], index) => {
        props.registerValidator(id, props.blockKey, validator, errorMsg, index);
      });
      setHasRegisteredValidators(true);
    }
  };

  /**
   * Renders a plugin header for the component
   * @returns {*}
   */
  const renderPluginHeader = () => {
    const { resources: propsMediaResources } = props;
    const resources = { ...propsMediaResources, ...mediaresources };

    return (
      <PluginHeader
        data={props.data}
        resources={resources}
        updateData={updateData}
        openMediaLibrary={openModal}
        settings={props.settings}
        updateDraftList={updateDraftList}
      />
    );
  };

  return (
    <setup.Component
      updateData={updateData}
      openMediaLibrary={openModal}
      pluginHeader={renderPluginHeader()}
      useModal={preppedModal}
      {...props}
      registerValidator={preppedRegisterValidator}
    />
  );
};

export default PluginHoc;
