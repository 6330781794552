import { DEFAULT, MAX_ITEMS, OPERATIVE, CHIPS } from "../../../constants";
import { totalItemsInBox } from "./OrderData";

/**
 * Generates default Order data object
 * @returns {{items: Array}}
 */
export const defaultOrderData = () => ({
  items: [],
  [MAX_ITEMS]: totalItemsInBox[DEFAULT]
});

/**
 * Generates default Order settings object
 */
export const defaultOrderSettings = () => ({
  [CHIPS]: DEFAULT,
  [OPERATIVE]: true
});
