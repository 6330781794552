import styled from "styled-components";
import Button from "../../shared/components/Button";
import { format } from "../../date";

const StyledButton = styled(Button)`
  margin: 0;
  width: 145px;
  fill: ${props =>
    props.skin === "primary" ? props.theme.white : props.theme.themePrimary};
`;

const StyledButtonTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
`;
const StyledDateWrapper = styled.div`
  margin-left: 4px;
  font-size: 12px;
  margin-bottom: 4px;
  text-align: center;
  &.published-not-latest {
    color: #b00020;
  }
  color: #333333;
`;

const formatDate = dateString => {
  const date = new Date(dateString);
  return format(date);
};

const CommercialButton = ({ onClick, commercialTime, disabled, ...rest }) => {
  const _onClick = e => onClick(e);

  return (
    <StyledButtonTimeWrapper>
      {commercialTime && (
        <StyledDateWrapper>{formatDate(commercialTime)}</StyledDateWrapper>
      )}
      <StyledButton
        onClick={_onClick}
        type="button"
        disabled={disabled}
        skin="primary"
        {...rest}
      >
        {disabled ? "Är säljbar" : "Gör säljbar"}
      </StyledButton>
    </StyledButtonTimeWrapper>
  );
};

export default CommercialButton;
