import React, { PureComponent } from "react";
import CollapsibleStateless from "./CollapsibleStateless";

export default class Collapsible extends PureComponent {
  state = {
    isOpen: !!this.props.open
  };

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <CollapsibleStateless
        title={this.props.title}
        onToggle={this.onToggle}
        isOpen={this.state.isOpen}
        children={this.props.children}
      />
    );
  }
}
