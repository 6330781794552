import * as React from "react";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import Editor from "@draft-js-plugins/editor";
import createToolbarPlugin, {
  Separator
} from "@draft-js-plugins/static-toolbar";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  CodeButton,
  SupButton,
  SubButton
} from "@draft-js-plugins/buttons";

import { TextParser } from "../../shared/TextTransformerService";

const toolbarPlugin = createToolbarPlugin();

export class BlanksEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    const { editorState } = props;
    this.state = {
      editorState
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this._keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._keyDown);
  }

  _keyDown = event => {
    let undo = event.ctrlKey && event.keyCode === 90 ? true : false;

    if (undo) {
      const textHandler = new TextParser();
      const { editorState } = this.state;
      const { data } = this.props;

      let newText = data.text ? data.text : "";
      let inputs = data.inputs ? data.inputs : undefined;

      if (inputs !== undefined && inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          let text = inputs[i].correctAnswers[0].text;
          newText = newText.replace("{" + i + "}", "<code>" + text + "</code>");
        }
      }

      newText = newText.split("<ins>").join("<u>");
      newText = newText.split("</ins>").join("</u>");

      let newState = textHandler.createNewEditorState(editorState, newText);

      this.setState({
        editorState: newState
      });
    }
  };

  _onChange = editorState => {
    const textHandler = new TextParser();
    const { onChange, data } = this.props;
    onChange(editorState);

    if (data.text && data.text.length === 1) {
      editorState = textHandler.moveFocus(editorState);
    }

    this.setState({
      editorState
    });
  };

  _ref = editor => (this.editor = editor);

  _focus = () => this.editor.focus();

  _handled = () => "handled";

  render() {
    const { editorState } = this.state;
    const styleMap = {
      CODE: {
        backgroundColor: "rgba(206, 98, 98,.35)"
      },
      SUPERSCRIPT: { fontSize: "0.8em", verticalAlign: "super" },
      SUBSCRIPT: { fontSize: "0.8em", verticalAlign: "sub" },
    };

    const editor = (
      <Editor
        plugins={[toolbarPlugin]}
        readOnly={false}
        editorState={editorState}
        onChange={this._onChange}
        customStyleMap={styleMap}
        ref={this._ref}
      />
    );

    const { Toolbar } = toolbarPlugin;

    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <div className="TextEditor--Edit" onClick={this._focus}>
        {editor}
        <Toolbar>
          {externalProps => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              <Separator {...externalProps} />
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
              <Separator {...externalProps} />
              <SubButton {...externalProps} />
              <SupButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </div>
    );
  }
}

export default BlanksEditor;
