import { all, fork, takeEvery } from "redux-saga/effects";
import productNewsSaga from "./productnews";
import opMessagesSaga from "./opmessages";
import marketMessagesSaga from "./marketmessages";
import { ROUTE_MESSAGES } from "../routes";
import { onMessagesRoute } from "./sagas";

function* watchMessagesRoute() {
  yield takeEvery(ROUTE_MESSAGES, onMessagesRoute);
}

export default function* rootSaga() {
  yield all([
    fork(watchMessagesRoute),
    fork(productNewsSaga),
    fork(opMessagesSaga),
    fork(marketMessagesSaga)
  ]);
}
