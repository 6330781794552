import React from "react";
import styled from "styled-components";
import ClassroomList from "../../containers/ClassroomList";
import AddClassroomGroup from "../../components/AddClassroomGroup/AddClassroomGroup";
import SearchField from "../../containers/SearchField";

const StyledSearchWrapper = styled.div`
  padding: 20px 15px;
  height: 100px;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
`;

const Classroom = () => {
  return (
    <React.Fragment>
      <StyledSearchWrapper>
        <SearchField />
        <AddClassroomGroup />
      </StyledSearchWrapper>
      <ClassroomList />
    </React.Fragment>
  );
};

export default Classroom;
