import {
  END_FETCH,
  START_FETCH,
  FETCH_MEDIA_ENTITIES,
  FETCH_MEDIA_ENTITIES_SUCCESS,
  FETCH_MEDIA_ENTITIES_ERROR,
  FETCH_MEDIA_ENTITY_DETAILS,
  FETCH_MEDIA_ENTITY_DETAILS_ERROR,
  FETCH_MEDIA_ENTITY_DETAILS_SUCCESS,
  FETCH_MEDIAFILTER,
  FETCH_MEDIAFILTER_SUCCESS,
  FETCH_MEDIAFILTER_ERROR,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  NEXT_MEDIA_PAGE,
  PREVIOUS_MEDIA_PAGE,
  GOTO_MEDIA_PAGE,
  RESET_MEDIA_BUFFER_INDEX
} from "../../api";
import { actionCreator } from "../../shared";

export const fetchMediaEntities = (
  entityName,
  apiAction,
  args = {},
  searchParameters = {},
  batchIndex
) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITIES),
  entityName,
  apiAction,
  args,
  searchParameters,
  batchIndex
});

export const fetchMediaEntitiesSuccess = (
  entityName,
  entities,
  metadata,
  searchParameters = {},
  batchIndex
) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITIES_SUCCESS),
  entityName,
  entities,
  metadata,
  searchParameters,
  batchIndex
});

export const fetchMediaEntitiesError = (entityName, error, batchIndex) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITIES_ERROR),
  entityName,
  error,
  batchIndex
});

export const fetchMediaEntityDetails = (entityName, apiAction, args = {}) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITY_DETAILS),
  entityName,
  apiAction,
  args
});

export const fetchMediaEntityDetailsSuccess = (entityName, entity, args) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITY_DETAILS_SUCCESS),
  entityName,
  entity,
  args
});

export const fetchMediaEntityDetailsError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_MEDIA_ENTITY_DETAILS_ERROR),
  entityName,
  error
});

export const nextMediaPage = entityName => ({
  type: actionCreator(entityName, NEXT_MEDIA_PAGE)
});

export const previousMediaPage = entityName => ({
  type: actionCreator(entityName, PREVIOUS_MEDIA_PAGE)
});

export const gotoMediaPage = (entityName, page) => ({
  type: actionCreator(entityName, GOTO_MEDIA_PAGE),
  page
});

export const resetMediaBufferPage = (entityName, page) => ({
  type: actionCreator(entityName, RESET_MEDIA_BUFFER_INDEX),
  batchIndex: page
});

export const fetchMediaFilter = (entityName, apiAction, args, filters) => ({
  type: actionCreator(entityName, FETCH_MEDIAFILTER),
  entityName,
  apiAction,
  args,
  filters
});

export const fetchMediaFilterSuccess = (entityName, filter, labels) => ({
  type: actionCreator(entityName, FETCH_MEDIAFILTER_SUCCESS),
  entityName,
  filter,
  labels
});

export const fetchMediaFilterError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_MEDIAFILTER_ERROR),
  entityName,
  error
});

export const startFetch = entityName => ({
  type: actionCreator(entityName, START_FETCH)
});

export const endFetch = entityName => ({
  type: actionCreator(entityName, END_FETCH)
});

export const resetBeforeFilterSortChange = entityName => ({
  type: actionCreator(entityName, RESET_BEFORE_FILTER_SORT_CHANGE)
});
