import React from "react";
import styled from "styled-components";
import Link from "../../../../shared/components/Link";
import { ENTITY_PLURAL, ENTITY_TYPE } from "../../../../constants";

const StyledLink = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  pointer-events: none;

  ${props => (props.isSelected ? "color: white" : "")};
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const LinkRenderer = ({
  cellData,
  rowData: { id, entity_id, type, selected }
}) => {
  const path =
    type && ENTITY_TYPE[type] === "system"
      ? `/system/${entity_id}/${ENTITY_PLURAL[type]}/${id}`
      : `/products/${entity_id}/${ENTITY_PLURAL[type]}/${id}`;
  return type ? (
    <StyledLink isSelected={selected}>
      <Link to={path}>{cellData}</Link>
    </StyledLink>
  ) : null;
};

export default LinkRenderer;
