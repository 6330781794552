import styled from 'styled-components';

export const StyledMediaItemContainer = styled.div`
  max-width: 100%;
  margin-top: 50px;
`;

export const StyledMediaItemImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledMediaItemImageWrapper = styled.div`
  flex: 1;
`;

export const StyledMediaItemImage = styled.img`
  flex: 1;
  max-width: 100%;
  max-height: 100px;
`;

export const StyledMediaItemName = styled.p`
  font-size: 16px;
  color: #277575;
  margin-top: 4px;
  word-break: break-word;
`;

export const StyledMediaDeleteButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: #333333;
    outline: none;

  &:hover,
  &:focus {
    color: #767676;
  }
  
  &:active {
    color: #767676;
  }
`;

export const StyledAudioWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  audio {
    height: 30px;
    margin-top: 10px;
  }
`;

export const StyledAudioContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledAudioPlayerContainer = styled.div`
  audio {
    height: 30px;
  }
`;