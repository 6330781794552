import React from "react";
import { connect } from "react-redux";
import FilterSection from "../../components/FilterSection";
import { filterPosts } from "../../../posts/store/actions";
import { setItems } from "../../../posts/store/carousel/actions";
import { setItems as setMediaItems } from "../../../media/store/carousel/actions";
import { filterMedia } from "../../../media/store/actions";
import { MEDIA } from "./constants";
import {
  selectAndOrderFilterList,
  filterQueryAction,
  hasFilters
} from "./filterdata";
import {
  selectCurrentPostType,
  selectFilterVisibility
} from "../../../posts/api/selectors";
import {
  StyledFilterContainer,
  StyledFilterButton,
  StyledFilterButtonWrapper
} from "./styles";
import { selectEntityId, selectEntityType } from "../../../store/selectors";
import { getRouteTypeFromPostType } from "../../../shared/helpers";
import { getSystemsList } from "../../../systems/store/selector";
/**
 * Clean up query
 * @param {*} query
 */
const cleanUpQuery = query =>
  query &&
  Object.keys(query).reduce((acc, key) => {
    if (Array.isArray(query[key])) {
      acc[key] = query[key].filter((v, i, a) => a.indexOf(v) === i); // remove duplicates
    } else {
      acc[key] = query[key];
    }
    return acc;
  }, {});

class Filters extends React.PureComponent {
  static defaultProps = {
    filterList: []
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: undefined
    };
  }

  /**
   * Callback for Search Input
   * @param {String} value The current search string
   */
  onSearchChange = value => {
    this.setState({
      searchValue: value
    });

    setTimeout(() => {
      if (value === this.state.searchValue) {
        this.doSearch(value);
      }
    }, 500);
  };

  /**
   * Filter search
   * @param {*} value
   */
  doSearch = value => {
    const { filterSearchMedia, filterSearchPost, postType, query } = this.props;
    const searchParams = { ...query, search: value };

    this.clearSelectionInList();

    postType.toUpperCase() === MEDIA
      ? filterSearchMedia(searchParams)
      : filterSearchPost(postType.toUpperCase(), searchParams);
  };

  clearSelectionInList = () => {
    const {
      clearSelectionInMediaList,
      clearSelectionInPostList,
      postType
    } = this.props;

    postType.toUpperCase() === MEDIA
      ? clearSelectionInMediaList([])
      : clearSelectionInPostList([]);
  };

  /**
   * Merge tag change to query
   * @param {*} query
   * @param {*} value
   * @param {*} name
   */
  mergeTagChangeToQuery = (query, value, name) => {
    const { filterList } = this.props;
    const section = filterList.find(({ title }) => title === name);
    const sectionTagIds = (
      (section && section.data && section.data[0] && section.data[0].tags) ||
      []
    ).map(t => +t.id);
    let tags = (query && query.tags) || [];
    if (!Array.isArray(tags)) {
      tags = [tags];
    }
    const queryTags = tags.map(tid => +tid);
    const valueInSection = value.filter(tid => sectionTagIds.indexOf(tid) >= 0);
    const queryNotInSection = queryTags.filter(
      tid => sectionTagIds.indexOf(tid) < 0
    );
    return { ...query, tags: [...queryNotInSection, ...valueInSection] };
  };

  /**
   * Merge new key and value to query
   * @param query
   * @param key
   * @param value
   */
  mergeToQuery = (query, key, value) => {
    let newFilter = {};
    if (value !== undefined && value !== null && value !== "") {
      newFilter[key] = value;
    }
    const newQuery = Object.keys(query).reduce((acc, k) => {
      if (k !== key) {
        acc[k] = query[k];
      }
      return acc;
    }, {});
    return { ...newQuery, ...newFilter };
  };

  /**
   * Function recieving filter choices and dispatching them to the store.
   * @param value Value from filter
   * @param name The name of filtersection/filtergroup
   * @param filterkey What kind of filter is it, tags, tmpls, search etc
   */
  onFilterChange = (value, name, filterkey) => {
    const { filterQuery, filterRoute, entityId, query = {} } = this.props;

    const searchParams =
      filterkey === "tags"
        ? this.mergeTagChangeToQuery(query, value, name)
        : this.mergeToQuery(query, filterkey, value);

    this.clearSelectionInList();
    filterQuery(filterRoute, entityId, searchParams);
    this.setState({
      searchValue: undefined
    });
  };

  /**
   * Clear all filters
   */
  clearFilters = () => {
    const { filterQuery, entityId, filterRoute } = this.props;
    filterQuery(filterRoute, entityId, {});
    this.setState({
      searchValue: undefined
    });
  };

  /**
   * Get searchValue
   */
  getSearchValue = () =>
    this.state.searchValue !== undefined
      ? this.state.searchValue
      : this.props.query && this.props.query.search;

  render() {
    const { hideFilters, query } = this.props;

    if (hideFilters) {
      return null;
    }

    return (
      <StyledFilterContainer>
        <StyledFilterButtonWrapper>
          {hasFilters(query, this.state.searchValue) && (
            <StyledFilterButton onClick={this.clearFilters}>
              Rensa filter
            </StyledFilterButton>
          )}
        </StyledFilterButtonWrapper>
        {this.renderFilterList()}
      </StyledFilterContainer>
    );
  }

  /**
   * Render the content in filter list
   */
  renderFilterList = () => {
    const { filterList, query } = this.props;

    return filterList.map((fs, i) => (
      <FilterSection
        key={fs.title}
        searchValue={this.getSearchValue()}
        content={fs}
        onFilterChange={this.onFilterChange}
        onSearchChange={this.onSearchChange}
        query={query}
      />
    ));
  };
}

const mapStateToProps = state => {
  const postType = selectCurrentPostType(state);
  const entityId = selectEntityId(state);
  const entityType = selectEntityType(state);
  const systems = getSystemsList(state);
  const filterRoute = getRouteTypeFromPostType(
    postType,
    entityId,
    entityType,
    systems
  );
  return {
    hideFilters: selectFilterVisibility(state),
    postType: postType,
    entityId: selectEntityId(state),
    query: cleanUpQuery(state.location.query),
    filterList: selectAndOrderFilterList(state, postType),
    filterRoute: filterRoute
  };
};

const mapDispatchToProps = dispatch => ({
  filterSearchPost: (postType, params) =>
    dispatch(filterPosts(postType, params)),
  filterSearchMedia: params => dispatch(filterMedia(params)),
  filterQuery: (type, entityId, params) =>
    dispatch(filterQueryAction(type, entityId, params)),
  clearSelectionInMediaList: items => setMediaItems(items)(dispatch),
  clearSelectionInPostList: items => setItems(items)(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
