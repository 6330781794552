import React from "react";

const ZeroIcon = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="-8 0 36 26"
    width={`${size}px`}
    fill={color}
    className="studlicon-zeroicon"
  >
    <title>0</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="4,3">
    <g id="Adapt/Uppställning/Addition-Test-1" transform="translate(-546.000000, -293.000000)" fillRule="nonzero" stroke="#287574" strokeWidth="3">
        <path d="M555.961538,318 C558.552695,318 560.527408,317.020604 561.885675,315.061813 C563.243943,313.103022 563.923077,310.238553 563.923077,306.468407 C563.923077,302.719322 563.243943,299.87065 561.885675,297.92239 C560.527408,295.97413 558.552695,295 555.961538,295 C553.370382,295 551.395669,295.979396 550.037402,297.938187 C548.679134,299.896978 548,302.750916 548,306.5 C548,310.249084 548.679134,313.103022 550.037402,315.061813 C551.395669,317.020604 553.370382,318 555.961538,318 Z" id="0"></path>
    </g>
</g>  </svg>
);

export default ZeroIcon;
