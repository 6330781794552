import React from "react";

const Keyhole = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-keyhole"
  >
    <path d="M25.2192479,0 C26.1839388,0 27.1310899,0.0701550815 28.0957808,0.192926474 C41.7943918,1.91172597 51.4939205,14.4168692 49.8100963,28.0971101 C48.0911925,41.7948898 35.5852903,51.4938298 21.9042192,49.8101078 C8.20560818,48.0913083 -1.49392046,35.5861651 0.189903682,21.8883854 C1.82110832,9.19031567 12.4853279,0.0350775407 25.2192479,0 Z M14.8707454,20.9412918 C14.9759844,24.9225927 17.0106053,28.2724978 20.5536519,30.0965299 L18.6242701,39.1640742 L31.5511283,39.1640742 L29.6217465,30.0965299 C33.1472533,28.184804 35.2520334,25.0102865 35.3046529,20.9412918 C35.2344936,15.1885751 30.7793756,10.8564989 25.0789293,10.7161887 C19.4486423,10.8564989 15.0286039,15.0658037 14.8707454,20.9412918 Z" />
  </svg>
);

export default Keyhole;
