import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../products/components/Card";

import {
  selectFormativeTestsProducts,
  selectFormativeQuestionsSelectedProduct,
  selectFormativeQuestionsToc,
  selectFormativeTestsForCurrentProduct
} from "../../store/selectors";
import ProductSelect from "../../components/ProductSelect";
import PublishButton from "../../../products/components/PublishButton";
import PreviewButton from "../../../products/components/PreviewButton";
import { selectClassroomgroupEntityId } from "../../../store/selectors";
import FormativeQuestionsToc from "./FormativeQuestionsToc";
import FormativeTests from "./FormativeTests";
import {
  downloadFormativeTests,
  fetchFormativeTestsForProduct,
  publishFormativeTestsToLive,
  uploadFormativeTestsFile,
  deleteFormativeTestsforProduct,
  deleteFormativeQuestion,
  createFormativeQuestion,
  setFormativeQuestionSelectedProduct
} from "../../api/actions";
import {
  Container,
  ButtonGroup,
  ButtonWrapper,
  StyledFormativeWrapper,
  Divider,
  StyledModalContent,
  StyledPrimaryButton
} from "./StyledFormativeTests";
import Modal from "../../../shared/components/Modal/Modal";

const FormativeTestsCard = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductPostId, setSelectedProductPostId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const { products, updatedAt, publishedAt } = useSelector(
    selectFormativeTestsProducts
  );
  const classroomGroupEntityId = useSelector(selectClassroomgroupEntityId);
  const formativeTests = useSelector(selectFormativeTestsForCurrentProduct);
  const questionsToc = useSelector(selectFormativeQuestionsToc);
  const selProduct = useSelector(selectFormativeQuestionsSelectedProduct);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selProduct !== null) {
      setSelectedProduct(selProduct);
      const data = products.find(p => p.sesam.name === selProduct);
      setSelectedProductPostId(data.postId);

      dispatch(
        fetchFormativeTestsForProduct(classroomGroupEntityId, data.postId)
      );
    }
  }, [selProduct, products, classroomGroupEntityId, dispatch]);

  const onSelectProduct = sesam => {
    setSelectedProduct(sesam);
    dispatch(setFormativeQuestionSelectedProduct(sesam));
  };

  const publishToLive = () => {
    const postIds = products.map(p => p.postId);
    dispatch(publishFormativeTestsToLive(classroomGroupEntityId, postIds));
  };

  const gotoPreview = () => {
    console.log("preview");
  };

  const onDownloadFormativeTests = () => {
    dispatch(
      downloadFormativeTests(classroomGroupEntityId, selectedProductPostId)
    );
  };

  const onUploadFormativeTests = data => {
    // The file uploaded should be the predefined-tests.json file
    dispatch(
      uploadFormativeTestsFile(
        classroomGroupEntityId,
        selectedProductPostId,
        JSON.stringify(data)
      )
    );
  };

  const onDeleteFormativeTests = () => {
    dispatch(
      deleteFormativeTestsforProduct(
        classroomGroupEntityId,
        selectedProductPostId
      )
    );
  };

  const onDeleteFormativeQuestion = qId => {
    setIsModalOpen(true);
    setQuestionId(qId);
  };

  const onAddNewFormativeQuestion = () => {
    dispatch(
      createFormativeQuestion(classroomGroupEntityId, selectedProductPostId)
    );
  };

  const onConfirmDelete = () => {
    dispatch(
      deleteFormativeQuestion(
        classroomGroupEntityId,
        questionId,
        selectedProductPostId
      )
    );
    setQuestionId(null);
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setQuestionId(null);
  };

  return (
    <>
      <Card title="Formativa frågor/test">
        <ProductSelect
          productList={products}
          onChange={onSelectProduct}
          v={selectedProduct}
        />
        <StyledFormativeWrapper>
          <FormativeQuestionsToc
            questions={questionsToc}
            onDeleteQuestion={onDeleteFormativeQuestion}
            addNewQuestion={onAddNewFormativeQuestion}
            disabled={selectedProduct === null || selectedProduct === ""}
          />
          <Divider />
          <FormativeTests
            tests={formativeTests}
            downloadContent={onDownloadFormativeTests}
            uploadContent={onUploadFormativeTests}
            deleteContent={onDeleteFormativeTests}
            disabled={selectedProduct === null || selectedProduct === ""}
          />
        </StyledFormativeWrapper>
        <Container>
          <ButtonWrapper>
            <ButtonGroup>
              <PublishButton
                toLive={false}
                disabled
                publishtime={updatedAt}
                isLatest
              />
              <PreviewButton onClickPreview={gotoPreview} />
            </ButtonGroup>
            <ButtonGroup>
              <PublishButton
                toLive={true}
                onClick={publishToLive}
                disabled={false}
                publishtime={publishedAt}
                isLatest={false}
              />
            </ButtonGroup>
          </ButtonWrapper>
        </Container>
        <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
          <StyledModalContent>
            <p>Vill du ta bort posten och all tillhörande media?</p>
            <StyledPrimaryButton onClick={onConfirmDelete}>
              Ta Bort
            </StyledPrimaryButton>
          </StyledModalContent>
        </Modal>
      </Card>
    </>
  );
};

export default FormativeTestsCard;
