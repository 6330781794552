import React from "react";
import { useSelector } from "react-redux";
import { Field, SelectField } from "../../../products/components/Input";
import styled from "styled-components";
import Checkbox from "../../../products/components/Checkbox";
import {
  selectClassroomGroupTypesList,
  getClassroomgroupTitles
} from "../../store/selectors";
import Label from "../../../products/components/Label";
import AutoComplete from "../../../products/components/AutoComplete";

const StyledSettingsWrapper = styled.div`
  input[type="text"],
  select {
    width: 250px;
  }
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  align-items: start;
  width: 120px;
  input[type="text"] {
    width: 120px;
  }
`;
const StyledColorWrapper = styled.div`
  padding-top: 28px;
`;
const StyledFormFieldWrapper = styled.div`
  margin: 15px 0;
  width: 100px;
`;

const StyledColorSquare = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.color};
  border: 1px solid #cccccc;
  margin-left: 20px;
`;

const GroupSettings = ({
  values,
  errors,
  onChange,
  onBlur,
  onSelectChange
}) => {
  const types = useSelector(selectClassroomGroupTypesList);
  const disabled = false;

  const _onChange = (val, name) => {
    if (name === "edition") {
      val = val.replace(/[^0-9]/g, "");
      val = Number(val);
    }
    onChange(val, name);
  };

  const _onCheckBoxChange = val => {
    onSelectChange(Number(val), "lightMode");
  };

  const _onSelectChange = val => {
    onSelectChange(val, "typeId");
  };

  const _onBlur = (val, name) => {
    if (name === "edition") {
      val = Number(val);
    }
    onBlur(val, name);
  };

  return (
    <StyledSettingsWrapper>
      <Label>
        Namn
        <AutoComplete
          selector={getClassroomgroupTitles}
          onSelect={val => onSelectChange(val, "title")}
          onChange={val => onChange(val, "title")}
          name="title"
          error={errors["title"]}
          preSearchValue={values.title}
          onBlur={_onBlur}
          width="18rem"
        />
      </Label>
      <StyledFieldWrapper>
        <Field
          style={{ marginBottom: "1rem" }}
          error={errors["edition"]}
          value={values.edition}
          onChange={_onChange}
          disabled={disabled}
          onBlur={_onBlur}
          label="Upplaga"
          name="edition"
          autocomplete="off"
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <Field
          error={errors["colour"]}
          value={values.colour}
          onChange={_onChange}
          disabled={disabled}
          onBlur={_onBlur}
          label="Färg"
          name="colour"
          autocomplete="off"
        />
        <StyledColorWrapper>
          <StyledColorSquare color={values.colour} />
        </StyledColorWrapper>
      </StyledFieldWrapper>
      <StyledFormFieldWrapper>
        <Checkbox
          label="Ljus titel"
          onChange={_onCheckBoxChange}
          checked={values.lightMode}
          disabled={disabled}
          name="lightMode"
        />
      </StyledFormFieldWrapper>
      <SelectField
        error={errors["typeId"]}
        value={values.typeId}
        onChange={_onSelectChange}
        disabled={disabled}
        label="Tillval"
        name="typeId"
        options={types.map(v => ({
          key: v.type,
          val: v.id
        }))}
      />
    </StyledSettingsWrapper>
  );
};

export default GroupSettings;
