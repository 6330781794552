import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Card from "../../../products/components/Card";
import SeriesSettings from "../../components/SeriesSettings";
import SeriesProducts from "../../components/SeriesProducts";
import SeriesSubSeries from "../../components/SeriesSubSeries";
import { Grid, Section } from "../../components/Grid";
import { useSelector, useDispatch } from "react-redux";
import { equals } from "ramda";
import {
  getProductsAvailable,
  getSeriesAvailable,
  selectSeries,
  isCreatedSerieEdited,
} from "../../store/selectors";
import { PrimaryButton } from "../../../shared";
import {
  createSeries,
  updateSeriesDetails,
  createdSerieIsEdited
} from "../../api/actions";

const StyledProductAndSeriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 2rem 0.5rem;
  border-radius: 5px;
  width: 5rem;
`;

const SeriesDetails = () => {
  const selectedSerie = useSelector(selectSeries);
  const seriesList = useSelector(getSeriesAvailable);
  const productsList = useSelector(getProductsAvailable);
  const isSerieEdited = useSelector(isCreatedSerieEdited);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [serie, setSerie] = useState({
    title: "",
    color: "",
    lightMode: null,
    image: { id: 0, url: "" },
    logotype: { id: 0, url: "" },
    pageUrl: "",
    subSeries: [],
    products: []
  });

  const isEqual = equals(serie, selectedSerie);
  const saveDisabled =
    Object.keys(errors).length !== 0 ||
    serie.title === "" ||
    serie.color === "";

  const handleIsCreatedSerieEdited = useCallback(() => {
    if (!selectedSerie?.id || !serie?.id) return;
    if ((isSerieEdited && !isEqual) || isSerieEdited === !isEqual) return;
    dispatch(createdSerieIsEdited(!isEqual));
  }, [selectedSerie, serie, dispatch, isSerieEdited, isEqual]);

  useEffect(() => {
    selectedSerie.id && setSerie(selectedSerie);
  }, [selectedSerie]);

  useEffect(() => {
    handleIsCreatedSerieEdited();
  }, [handleIsCreatedSerieEdited]);

  return (
    <React.Fragment>
      <Grid>
        <Section>
          <Card title="Egenskaper">
            <SeriesSettings
              serie={serie}
              seriesList={seriesList}
              errors={errors}
              setErrors={setErrors}
              setSerie={setSerie}
            />
          </Card>
          <StyledPrimaryButton
            disabled={saveDisabled}
            onClick={() =>
              dispatch(
                serie.id
                  ? updateSeriesDetails(serie.id, serie)
                  : createSeries(serie)
              )
            }
          >
            Spara
          </StyledPrimaryButton>
        </Section>
        <StyledProductAndSeriesWrapper>
          <Section>
            <Card noPadding title="Produkter">
              <SeriesProducts
                serie={serie}
                productsList={productsList}
                errors={errors}
                setErrors={setErrors}
                setSerie={setSerie}
              />
            </Card>
          </Section>
          <Section>
            <Card noPadding title="Serier">
              <SeriesSubSeries
                serie={serie}
                errors={errors}
                seriesList={seriesList}
                setErrors={setErrors}
                setSerie={setSerie}
              />
            </Card>
          </Section>
        </StyledProductAndSeriesWrapper>
      </Grid>
    </React.Fragment>
  );
};

export default SeriesDetails;
