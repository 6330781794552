import * as React from "react";
import {
  StyledLayoutSelector,
  StyledLabelContainer,
  StyledLabel
} from "./StyledLayoutSelector";
import LayoutButton from "./LayoutButton";
import { StyledLayoutBlock } from "./LayoutButton/StyledLayoutButton";

const LayoutSelector = ({ numberOfBlocks, selectedFormat, callback }) => {

  const format222 = "2 : 2 : 2";
  const format132 = "1 : 3 : 2";
  const format123 = "1 : 2 : 3";
  const format141 = "1 : 4 : 1";
  const format24 = "2 : 4";
  const format15 = "1 : 5";
  const format33 = "3 : 3";
  const format42 = "4 : 2";

  const twoBlocks = 2;
  const threeBlocks = 3;
  
  const getDefaultFormat = () => {
    if (numberOfBlocks === twoBlocks) {
      return format33;
    }
    
    if (numberOfBlocks === threeBlocks) {
      return format222;
    }
    else return selectedFormat;  
  }
  
  const format = selectedFormat ? selectedFormat : getDefaultFormat();
  
  return (
    <StyledLayoutSelector>
      <StyledLabelContainer>
        <StyledLabel>Layout</StyledLabel>
      </StyledLabelContainer>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format222}
        disabled={numberOfBlocks !== 3}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"2"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"2"} />
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"2"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format132}
        disabled={numberOfBlocks !== 3}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"1"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"3"} />
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"2"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format123}
        disabled={numberOfBlocks !== 3}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"1"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"2"} />
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"3"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format141}
        disabled={numberOfBlocks !== 3}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"1"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"4"} />
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"1"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format24}
        disabled={numberOfBlocks !== 2}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"2"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"4"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format15}
        disabled={numberOfBlocks !== 2}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"1"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"5"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format33}
        disabled={numberOfBlocks !== 2}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"3"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"3"} />
      </LayoutButton>
      <LayoutButton
        callback={callback}
        selectedFormat={format}
        layoutFormat={format42}
        disabled={numberOfBlocks !== 2}
      >
        <StyledLayoutBlock backgroundColor={"#384E70"} size={"4"} />
        <StyledLayoutBlock backgroundColor={"#377AD3"} size={"2"} />
      </LayoutButton>
    </StyledLayoutSelector>
  );
};

export default LayoutSelector;
