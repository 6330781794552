import React from "react";
import { connect } from "react-redux";
import { selectEntityId } from "../../store/selectors";
import { StyledAdventureWrapper, TabWrapper, StyledTabs } from "./StyledList";
import { PageHeader } from "../../shared";
import { Tab, TabList, TabPanel } from "react-tabs";
import Link from "../../shared/components/Link";
import Markers from "../markers/containers/Markers";

const AdventuresEditor = ({
  components: AdventureComponent,
  index,
  entityId
}) => {
  return (
    <StyledAdventureWrapper>
      <PageHeader studlicon="Gamification" title="Spelifiering" />
      <TabWrapper>
        <StyledTabs defaultIndex={index}>
          <TabList>
            <Tab>
              <Link
                to={`/products/${entityId}/adventure-editor/adventure-groups`}
              >
                {"Äventyrseditorn"}
              </Link>
            </Tab>
            <Tab>
              <Link to={`/products/${entityId}/adventure-editor/markers`}>
                {"Märkesgrupper"}
              </Link>
            </Tab>
            <Tab>Prestationer</Tab>
          </TabList>
          <TabPanel>
            <AdventureComponent />
          </TabPanel>
          <TabPanel>
            <Markers />
          </TabPanel>
          <TabPanel>
            <p>Prestationer</p>
          </TabPanel>
        </StyledTabs>
      </TabWrapper>
    </StyledAdventureWrapper>
  );
};

const mapStateToProps = state => {
  return {
    entityId: selectEntityId(state)
  };
};

export default connect(mapStateToProps)(AdventuresEditor);
