import { call, put, select, take } from "redux-saga/effects";
import {
  FETCH_ENTITIES,
  FETCH_ENTITIES_SUCCESS,
  generalErrorHandler
} from "../../api";
import * as api from "../../structure/api/requests";
import * as sharedModule from "../../shared";
import {
  fetchStructureEnd,
  fetchStructureFailed,
  fetchStructureStart,
  fetchStructureSuccess,
  saveStructureStart,
  saveStructureSuccess,
  saveStructureFailed,
  saveStructureEnd,
  createCategoryStart,
  createCategorySuccess,
  createCategoryFailed,
  createCategoryEnd,
  createStructureDraft,
  resetDeletedList,
  updateTreeDraftAction,
  onTreeUpdateAction,
  onTreeDraftUpdateAction,
  popUndoAction,
  popRedoAction,
  pushRedoAction,
  addIdForRemoval,
  fetchGoalSuccess,
  createGoalDraft,
  updateGoalOrderReducer,
  addGoalsUndoRecord,
  popGoalsUndoRecord,
  updateGoalOrder,
  addGoalsRedoRecord,
  popGoalsRedoRecord,
  clearUndoRedoArraysAction,
  publishStructureFailed,
  publishStructureSuccess,
  fetchGoalsFailed,
  fetchGoalsEnd,
  fetchGoalsStart,
  fetchStructure,
  updateStageChangesReducer
} from "../api/actions";
import {
  selectOriginalTree,
  selectReplicationArrays,
  selectTreeDraft
} from "../api/selectors";
import { fromJS } from "immutable";
import {
  selectGoalsDraft,
  selectGoalsRedoArr,
  selectGoalsUndoArr
} from "../../posts/sagas/selectors";
import { saveGoalsOrder, saveStageChanges } from "../api/requests";
import { actionCreator } from "../../shared";
import { addExpandKeyForAll } from "../Helpers";
import { selectEntityId } from "../../store/selectors";

/**
 * On fetch structure
 * @param action
 */
export function* onFetchStructure(action) {
  const entityId = yield select(selectEntityId);
  yield put(fetchStructureStart());

  try {
    const structure = yield call(api.getStructure, entityId);
    const goals = yield call(api.getGoals, entityId);

    yield put(fetchGoalSuccess(goals.data.data));
    yield put(createGoalDraft(goals.data.data));

    yield put(fetchStructureSuccess(structure.data, {}));
    yield put(createStructureDraft(addExpandKeyForAll(structure.data), {}));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchStructureFailed(error));
  } finally {
    yield put(fetchStructureEnd());
  }
}

export function* onFetchGoals() {
  const entityId = yield select(selectEntityId);
  yield put(fetchGoalsStart());

  try {
    const goals = yield call(api.getGoals, entityId);

    yield put(fetchGoalSuccess(goals.data.data));
    yield put(createGoalDraft(goals.data.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchGoalsFailed(error));
  } finally {
    yield put(fetchGoalsEnd());
  }
}

/**
 * On list route
 */
export function* onListRoute() {
  const entityId = yield select(selectEntityId);

  const entityName = "structure";
  const apiAction = "fetchStructure";

  yield put({
    type: actionCreator(entityName, FETCH_ENTITIES),
    apiAction: apiAction,
    entityId: entityId
  });

  yield take(actionCreator(entityName, FETCH_ENTITIES_SUCCESS));
}

/**
 * Recursively extracts all IDS from an array of object then flattens it
 * @param nodes
 * @returns {*[]}
 */
const filterOutNodeIds = nodes => {
  const a = node => {
    if (
      node.hasOwnProperty("children") &&
      node.children !== null &&
      node.children.length > 0
    ) {
      return [].concat(...[node.id, ...node.children.map(a)]);
    }
    return node.id;
  };

  return [].concat(...nodes.map(a));
};

/**
 * Compares two arrays of id's and returns the diff
 * @param oldTreeIds
 * @param newTreeIds
 * @returns {*[]}
 */
const extractErasedId = (oldTreeIds, newTreeIds) => {
  const oldTreeNodeIds = filterOutNodeIds(oldTreeIds);
  const newTreeNodeIds = filterOutNodeIds(newTreeIds);

  return oldTreeNodeIds.filter(
    oldId => !newTreeNodeIds.find(newId => oldId === newId)
  );
};

/**
 * Updates temp id's and replaces them with an actual id
 * @param idsMap
 * @returns {function({tempId: *, node: *})}
 */
const recursiveNodeIdUpdate = idsMap => ({ tempId: nodeTempId, ...node }) => {
  const tempIds = Object.keys(idsMap);
  const matchingTempId = tempIds.find(tempId => tempId === nodeTempId);

  if (matchingTempId) {
    node = {
      ...node,
      id: idsMap[matchingTempId]
    };
  }
  if ("children" in node && node.children !== null) {
    return {
      ...node,
      children: node.children.map(recursiveNodeIdUpdate(idsMap))
    };
  }

  return node;
};

/**
 * Save structure function
 * @param categories
 */
export function* onSaveStructure({ data: { categories } }) {
  yield put(saveStructureStart());

  const entityId = yield select(selectEntityId);
  const originalTree = yield select(selectOriginalTree);
  const removed = extractErasedId(originalTree, categories);

  try {
    const {
      data: { tempIdToRef }
    } = yield call(api.saveStructure, entityId, { categories, removed });
    const tree = categories.map(recursiveNodeIdUpdate(tempIdToRef));

    yield put(saveStructureSuccess(entityId));
    yield put(clearUndoRedoArraysAction());
    yield put(updateTreeDraftAction(tree));

    yield put(fetchStructure());
  } catch (error) {
    const status = error.response && error.response.status;
    if (status >= 400 && status < 500 && status !== 401) {
      let msg =
        error.response.data.error === "category can not be deleted"
          ? "Kategorin kan inte tas bort"
          : error.response.data.error;

      yield put(sharedModule.addFlash(msg, "warning"));
    } else {
      yield generalErrorHandler(error);
    }
    yield put(saveStructureFailed(error));
  } finally {
    yield put(saveStructureEnd(entityId));
  }
}

/**
 * Publish structure function
 */
export function* onPublishStructure() {
  const entityId = yield select(selectEntityId);
  try {
    const result = yield call(api.publishStructure, entityId);
    yield put(publishStructureSuccess(result.data, {}));

    yield put(
      sharedModule.addFlash(
        "En förfrågan om publicering har nu skickats!",
        "success"
      )
    );
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(publishStructureFailed(error));
  }
}

/**
 * On structure save
 */
export function* onStructureSaved() {
  yield put(resetDeletedList());
}

/**
 * On create category
 * @param action
 */
export function* onCreateCategory(action) {
  yield put(createCategoryStart());

  const entityId = yield select(selectEntityId);

  try {
    const result = yield call(api.createCategory, entityId, {
      title: action.data.title
    });
    yield put(createCategorySuccess(result.data, {}));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(createCategoryFailed(error));
  } finally {
    yield put(createCategoryEnd());
  }
}

/**
 * On update category
 * @param action
 */
export function* onUpdateCategory(action) {
  const recursiveNodeUpdate = item => {
    if (item.id === action.id || item.tempId === action.id) {
      return { ...item, ...action.data };
    } else if ("children" in item && item.children !== null) {
      return { ...item, children: item.children.map(recursiveNodeUpdate) };
    }
    return item;
  };

  const tree = fromJS(yield select(selectTreeDraft))
    .toJS()
    .map(recursiveNodeUpdate);
  yield put(updateTreeDraftAction(tree));
}

/**
 * Removes a node from the tree and updates replication register
 * @param id
 * @param tree
 */
export function* makeDeleteNode({ id, tree }) {
  yield call(updateTreeDraft, { tree });
  yield call(addIdForRemoval, id);
}

/**
 * Updates the tree draft, possible to bypass adding of undo record
 * @param tree - tree data
 * @param isUndoException - boolean for bypassing adding a undo record
 */
export function* updateTreeDraft({ tree, bypassReplication = false }) {
  const oldTree = yield select(selectTreeDraft);

  if (!bypassReplication) {
    yield call(addUndoRecord, oldTree);
  }

  yield put(onTreeDraftUpdateAction(tree));
}

/**
 * Adds current tree state to redo record
 * @param prevTree
 */
export function* addRedoRecord(prevTree) {
  yield put(pushRedoAction(prevTree));
}

/**
 * Redo latest user action
 */
export function* redoTreeChange() {
  const currentTree = yield select(selectTreeDraft);
  const replication = yield select(selectReplicationArrays);

  const tree = replication.get("redo").last();

  yield call(addUndoRecord, currentTree);
  yield put(onTreeDraftUpdateAction(tree));
  yield put(popRedoAction());
}

/**
 * Saves previous tree state to undo record
 * @param oldTree
 */
export function* addUndoRecord(oldTree) {
  yield put(onTreeUpdateAction(oldTree));
}

/**
 * Undo the latest user action
 * @param action
 */
export function* undoTreeChange(action) {
  const currentTree = yield select(selectTreeDraft);
  const replication = yield select(selectReplicationArrays);
  const tree = replication.get("undo").last();

  yield call(addRedoRecord, currentTree);
  yield put(onTreeDraftUpdateAction(tree));
  yield put(popUndoAction());
}

/**
 * Updates the order of the goals
 * @param data
 * @param bypassReplication
 */
export function* makeUpdateGoalOrder({ data, bypassReplication = false }) {
  const prevGoalsOrder = yield select(selectGoalsDraft);

  if (!bypassReplication) {
    yield put(addGoalsUndoRecord(prevGoalsOrder));
  }

  yield put(updateGoalOrderReducer(data));
}

/**
 * Saga for calling request to save new goal order
 */
export function* makeSaveGoalsOrderChanges() {
  const data = yield select(selectGoalsDraft);
  const entityId = yield select(selectEntityId);
  const goals = data.map(({ ref }) => ({ ref })).toJS();

  try {
    yield call(saveGoalsOrder, entityId, { goals });
  } catch (error) {
    console.warn("Something went wrong, couldn't save goals.", error);
  }
}

/**
 * Saga for calling request to save goal stage changes.
 * @param data
 */
export function* makeSaveStageChanges({ data }) {
  const entityId = yield select(selectEntityId);

  const { ref, stage } = data;

  try {
    yield call(saveStageChanges, entityId, ref, { stage: stage });
  } catch (error) {
    console.warn("Something went wrong, couldn't save stage for goal.", error);
  }

  yield put(updateStageChangesReducer(data));
}

/**
 * Undo goals latest change and sets it back to its most recent state
 */
export function* undoGoalChange() {
  const currentGoals = yield select(selectGoalsDraft);
  const undoRecords = yield select(selectGoalsUndoArr);

  yield put(addGoalsRedoRecord(currentGoals));
  yield put(updateGoalOrder(undoRecords.last(), true));
  yield put(popGoalsUndoRecord());
}

/**
 * Redo an undo
 */
export function* redoGoalChange() {
  const currentGoals = yield select(selectGoalsDraft);
  const redoRecord = yield select(selectGoalsRedoArr);

  yield put(addGoalsUndoRecord(currentGoals));
  yield put(updateGoalOrder(redoRecord.last(), true));
  yield put(popGoalsRedoRecord());
}
