import { Column } from "react-virtualized";
import StatusRenderer from "../Renderer/StatusRenderer";

const StatusColumn = () => null;

StatusColumn.propTypes = Object.assign({}, Column.propTypes);
StatusColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: StatusRenderer,
  draggable: true
});

export default StatusColumn;
