import { Column } from "react-virtualized";
import LockRenderer from "../Renderer/Lock";

const LockColumn = () => null;

LockColumn.propTypes = Object.assign({}, Column.propTypes);
LockColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: LockRenderer,
  draggable: true
});

export default LockColumn;
