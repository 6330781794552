import React from "react";
import styled from "styled-components";
import { darken, lighten } from "polished";
import { X, Check, Clock, AlertTriangle, Info } from "react-feather";

import { StandardButton } from "../Button";

const getIcon = type => {
  switch (type) {
    case "info":
      return Info;
    case "success":
      return Check;
    case "error":
      return X;
    case "warning":
      return AlertTriangle;
    case "delay":
      return Clock;
    default:
      return null;
  }
};

const getIconColor = type => {
  switch (type) {
    case "info":
      return darken(0.05, "#68A5BF");
    case "success":
      return darken(0.05, "#3CB26C");
    case "error":
      return darken(0.05, "#C74545");
    case "warning":
      return darken(0.05, "#ffd71a");
    case "delay":
      return darken(0.05, "#C8D5DC");
    default:
      return null;
  }
};

const getBGColor = type => {
  switch (type) {
    case "info":
      return "#68A5BF";
    case "success":
      return "#3CB26C";
    case "error":
      return "#C74545";
    case "warning":
      return "#ffd71a";
    case "delay":
      return darken(0.01, "#C8D5DC");
    default:
      return null;
  }
};

const Icon = ({ className, type }) => (
  <section className={className}>
    {React.createElement(getIcon(type), { width: "30px", height: "30px" })}
  </section>
);

export const StyledIcon = styled(Icon)`
  background: ${props => getIconColor(props.type)}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .25rem .5rem;

  & > svg {
    color: ${props => props.theme.white};
    width: 24px;
  }
`;

const Text = styled.span`
  ${props => props.theme.d100()}
  color: ${props => props.theme.white};
  font-weight: 700;
  padding: 20px;
  align-self: center;
  margin: 0;
  flex: 1;
`;

const CloseButton = styled(StandardButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  min-width: 20px;
  height: 20px;
  color: ${props => props.theme.neutralLight};
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    color: ${props => lighten(0.1, props.theme.neutralLight)};
  }
`;

const Growler = ({ id, msg, className, onClick }) => {
  const _onClick = () => onClick(id);
  return (
    <div className={className}>
      <CloseButton icon="X" transparent outline onClick={_onClick} />
      <Text>{msg}</Text>
    </div>
  );
};

export default styled(Growler)`
  background: ${props => getBGColor(props.type)};
  display: flex;
  width: 325px;
  min-height: 75px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.3);
`;
