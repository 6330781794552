import { clone } from "ramda";
import React from "react";
import {
  PLUS,
  MINUS,
  CALCULATE_TYPE,
  translation,
  GOAL,
  DEFAULT,
  MULTIPLICATION,
  DIVISION,
  ANSWER_TYPE,
  REST,
  DECIMAL, APPROXIMATION, EQUAL, EQUAL_SIGN
} from "../../../constants";
import {
  Addition,
  Subtraction,
  Multiplication,
  Division
} from "../../../shared/components/StudliIcons";

export const rowSettingStyles = {
  [CALCULATE_TYPE]: DEFAULT,
  [ANSWER_TYPE]: DEFAULT,
  [EQUAL_SIGN]: DEFAULT
};

export const answerTypeData = [
  { id: 0, label: "Rest", value: REST },
  { id: 1, label: "0,1", value: DECIMAL }
];

export const resultData = [
  { id: 0, label: "=", value: EQUAL },
  { id: 1, label: "≈", value: APPROXIMATION }
];

export const calculateTypeData = [
  { id: 0, label: <Addition size={22} color="white"/>, value: PLUS },
  { id: 1, label: <Subtraction size={22} color="white" />, value: MINUS },
  { id: 2, label: <Multiplication size={22} color="white" />, value: MULTIPLICATION },
  { id: 3, label: <Division size={20} color="white" />, value: DIVISION }
];


const divisionRowData = {
  title: `${translation[ANSWER_TYPE]}:`,
  data: answerTypeData,
  type: ANSWER_TYPE
}

const divisionResultData = {
  title: `${translation[EQUAL_SIGN]}:`,
  data: resultData,
  type: EQUAL_SIGN
}

const lineupRowData = {
  title: `${translation[CALCULATE_TYPE]}:`,
  data: calculateTypeData,
  type: CALCULATE_TYPE
}

export const settingsData = (type, answerType) => ([
  {
    title: translation[GOAL],
    rows:
      type === DIVISION
      ? answerType === DECIMAL
        ? [{...lineupRowData}, {...divisionRowData}, {...divisionResultData}]
        : [{...lineupRowData}, {...divisionRowData}]
      : [{...lineupRowData}]
  }
]);

export const caluculateNumbers = {
  [PLUS]: (x, y) => parseFloat((Number(x) + Number(y)).toFixed(5)),
  [MINUS]: (x, y) => +(x - y).toFixed(5),
  [MULTIPLICATION]: (x, y) => parseFloat((x * y).toFixed(5)),
  [DIVISION]: (x, y) => parseFloat((x / y).toFixed(5))
}

export const warningText = {
  isNumber: "Inmatat värde måste vara ett tal",
  isWithinBorders: "Max antal tecken uppnådd",
  valueIsNegative: "Talet får inte vara mindre än noll",
  resultIsNegative: "Differensen får inte vara mindre än noll",
  correctTermsAlignment: "Första talet måste vara större",
  zeroValue: "Multiplikation med 0 är inte tillåtet",
  hasDecimal: "Inga decimaler är tillåtna",
  resultHasDecimals: "REST division är inte tillåtet med dessa värden",
  memoryDigitGreaterThan: "Minnessiffra > 9"
};

/**
 * Empty cell
 */
const emptyCell = {
  id: "",
  hidden: false,
  inMemory: false,
  value: "",
  interactive: false
}

/**
 * Checks if the values has decimals if the type is division && decimal && has index === 1
 * @param {*} value
 * @param {*} type
 */
export const hasDecimals = (value, type, index) => (  
  index === 1 && type === DIVISION && value.indexOf(".") !== -1
)

/**
 * Returns the operationsymbol for the selected type
 * @param {*} type
 */
const getOperationSymbol = (type) => {
  switch (type) {
    case PLUS:
      return "+";
    case MINUS:
      return "-";
    case MULTIPLICATION:
      return "⋅";
    case EQUAL:
      return "=";
    case APPROXIMATION:
      return "≈"
    default:
      break;
  }
};

/**
 * Generate ids for grid.
 * @param items
 */
const generateIDsForEachPosition = grid =>
  grid.map((column, index1) =>
    column.map((row, index2) => ({ ...row, id: `[${index1}, ${index2}]` }))
  );

/**
 * Creates empty grid
 * @param {*} w
 * @param {*} h
 */
const getEmptyGrid = (w, h) =>
  Array(h)
    .fill("")
    .map(_ =>
      Array(w).fill(emptyCell)
    );

/**
 * Return true if conditions is met.
 * @param {*} items
 */
export const firstTermLengthIsGreaterOrEqual = (items) => {
  const firstLength = getNumberLengthWithDecimals(items[0].value)
  const secondLength = getNumberLengthWithDecimals(items[1].value)
  return firstLength >= secondLength
}

/**
 * Return the length of the number, e.g The number 4.5 returns 2
 * @param {*} number
 */
const getNumberLengthWithDecimals = (number) => number.toString().includes('.') ? number.toString().length-1 : number.toString().length

/**
 * Returns the total decimals in a number
 * @param {*} result
 */
export const getTotalDecimals = (result) => (
  Math.floor(result) === Number(result)
    ? 0
    : (result && result.toString().split(".")[1].length) || 0
)

/**
 * Returns the total decimals in a term
 * @param {*} term
 */
const getTotalDecimalsInTerm = (term) => term.indexOf(".") === -1 ? 0 : term.slice(term.indexOf(".") + 1).length

/**
 * Returns the height and width for the grid depending on the lenght of the numbers.
 * For addition & subtraction a static height and width is returned.
 * @param {*} term1
 * @param {*} term2
 * @param {*} type
 */
const getGridDimensions = (term1, term2, type) => {
  if(type === MULTIPLICATION) {
    const x = term1.join('')
    const y = term2.join('')
    const result = parseFloat((x*y).toFixed(5))
    const xLength = getNumberLengthWithDecimals(x)
    const yLength = getNumberLengthWithDecimals(y)
    const resultLength = getNumberLengthWithDecimals(result)
    const totalDecimalsInTerm = getTotalDecimalsInTerm(term1) + getTotalDecimalsInTerm(term2)
    const totalDecimalsInResult = getTotalDecimals(result)
    const decimalDiff = totalDecimalsInTerm - totalDecimalsInResult

    let length = Math.max(xLength, yLength ,resultLength)
    length = decimalDiff > 0 ? length + decimalDiff : length

    return xLength === 1 || yLength === 1
      ? { height: 3, width: length + 4 }
      : { height: 5, width: length + 4 }
  }
  else if(type === DIVISION) {
    const updatedTerm1 = term1.map(value => value === 'ghost' ? 0 : value)
    const updatedTerm2 = term2.map(value => value === 'ghost' ? 0 : value)
    const x = Number(updatedTerm1.join(''))
    const y = Number(updatedTerm2.join(''))
    const result = x / y
    const length = getNumberLengthWithDecimals(result)
    const t = termsInfo([term1, term2])
    const totalWidth = t.longLength + (length > 6 ? 6 : length) + 4

    return { height: 4, width: totalWidth}
  }
  else if(type === PLUS || type === MINUS) {
    const x = term1.join('')
    const y = term2.join('')
    const xLength = getNumberLengthWithDecimals(x)
    const yLength = getNumberLengthWithDecimals(y)
    const termLength = Math.max(xLength, yLength , 3)

    return { height: 4, width: termLength + 2 }
  }
}

/**
 * Add 'ghost' to termsdigits array if the totalDecimals is more than 0
 * @param {*} termsDigits
 * @param {*} totalDecimals
 */
const addGhostzerosToTerms = (termsDigits, totalDecimals) => {
  const diffToMax = 7 - termsDigits[0].length
  const noDecimalInTerm = termsDigits[0].indexOf(".") === -1
  const totalDecimalsInTerm = getTotalDecimalsInTerm(termsDigits[0])
  const totalGhostZeros = ((totalDecimals - totalDecimalsInTerm) < diffToMax) ? (totalDecimals - totalDecimalsInTerm) : diffToMax

  totalDecimals > 0 && noDecimalInTerm && diffToMax !== 0 && termsDigits[0].push('.')
  for(let i = 0; i < totalGhostZeros; i++) {
    termsDigits[0].push('ghost')
  }

  return termsDigits
}

/**
 * Add missing decimals to term where the other has decimals
 * to match the lengths after the decimal sign
 * @param {*} termsDigits
 */
const addDecimalsToTerms = (termsDigits) => {
  const term1TotalDecimals = getTotalDecimalsInTerm(termsDigits[0])
  const term2TotalDecimals = getTotalDecimalsInTerm(termsDigits[1])

  if(term1TotalDecimals === term2TotalDecimals) {
    return termsDigits
  }

  const diff = Math.abs(term1TotalDecimals - term2TotalDecimals)

  if(term1TotalDecimals > term2TotalDecimals) {
    if(!termsDigits[1].includes('.')) {
      termsDigits[1].push('.')
    }
    for(let i = 0; i < diff; i++) {
      termsDigits[1].push(0)
    }
  } else {
    if(!termsDigits[0].includes('.')) {
      termsDigits[0].push('.')
    }
    for(let i = 0; i < diff; i++) {
      termsDigits[0].push(0)
    }
  }

  return termsDigits
}

/**
 * Merges arrays.
 * @param {*} arr1
 * @param {*} arr2
 * @param {*} pad
 */
const mergeArray = (arr1, arr2, pad, type) => {
  const l = Math.max(arr1.length, arr2.length + pad);
  const r = [];
  for (let i = 0; i < l; i++) {
    if (i >= pad && arr2[i - pad] !== undefined) {
      if(type === DIVISION) {
        r[i] = { ...arr1[i], value: arr2[i - pad] };
      } else {
        if(arr2[i - pad] === ".") {
          r[i-1] = { ...arr1[i-1], value: arr2[i - 1 - pad], comma: true }
          r.splice(i,1)
          r.unshift({...arr1[0]})
        } else {
          r[i] = { ...arr1[i], value: arr2[i - pad] }
        }
      }
    } else {
      r[i] = arr1[i];
    }
  }
  return r;
};

/**
 * Returns false if the value exceed the limits for current type
 * @param {*} first
 * @param {*} second
 */
export const valuesIswithinBorders = (value, type, index) => {

  if(value === "") {
    return true
  }

  switch(type) {
    case PLUS:
    case MINUS:
      return parseInt(value) <= 999999
          ? getNumberLengthWithDecimals(value) < 7
          : false
    case MULTIPLICATION:
      if(index === 0) {
        return parseInt(value) <= 9999
          ? getNumberLengthWithDecimals(value) < 5
          : false
      } else {
        return parseInt(value) <= 99
          ? getNumberLengthWithDecimals(value) < 3
          : false
      }
    case DIVISION:
      if(index === 0) {
        return parseInt(value) <= 999999
          ? getNumberLengthWithDecimals(value) < 7
          : false
      } else {
        return parseInt(value) <= 9999
          ? getNumberLengthWithDecimals(value) < 5
          : false
      }
    default:
      break;
  }
}

/**
 * Split all terms into individual digits
 * @param {*} terms
 */
const getTermsDigits = terms => terms.map(t => ("" + t).split("").map(d => d === "." ? d : +d));

/**
 * Add terms to grid
 * @param {*} termsDigits
 * @param {*} grid
 * @param {*} width
 */
const addTermsToGrid = (termsDigits, grid, width, type) => {
  termsDigits.forEach((digits, row) => {
    switch(type) {
      case PLUS:
      case MINUS:
        grid[row + 1] = mergeArray(grid[row + 1], digits, width - digits.length)
        break;
      case MULTIPLICATION:
        grid[row] = mergeArray(grid[row], digits, (width-3) - digits.length)
        break;
      case DIVISION:
        const t = termsInfo(termsDigits)
        const pad = (t.longIndex === row || t.equalLengths) ? 0 : t.longLength - t.shortLength

        if (termsDigits[0].includes("ghost")) {
          const ghostIndex = termsDigits[0].indexOf("ghost")
          const sliceTo = termsDigits[0][ghostIndex-1] === "." ? ghostIndex-1 : ghostIndex
          const digitsLengthWithoutGhost = termsDigits[0].slice(0, sliceTo).length
          const padGhost = (t.longIndex === row || t.equalLengths) ? 0 : Math.abs(digitsLengthWithoutGhost - t.shortLength)

          grid[row + 1] = mergeArray(grid[row + 1], digits, padGhost, type)
        } else {
          grid[row + 1] = mergeArray(grid[row + 1], digits, pad, type)
        }
        break;
      default:
        break;
    }
  });
  return grid;
};

/**
 * Add operation symbol to grid
 * @param {*} grid
 * @param {*} pos1
 * @param {*} pos2
 * @param {*} symbol
 */
const addOperationSymbol = (grid, pos1, pos2, symbol, styling) => {
  grid[pos1][pos2] = { ...grid[pos1][pos2], value: symbol, ...styling };
  return grid;
};

/**
 * In some rows 0 padding is added depending on how many columns is generated.
 * This function removes the 0 paddings. Unsets the interactive field as well.
 */
const removePadding = grid => {
  for (let i = 0; i < grid[grid.length - 1].length; i++) {
    if (i === grid[grid.length - 1].length - 1) {
      break;
    }

    if(grid[grid.length - 1][i].comma) {
      break;
    }

    if (
      grid[grid.length - 1][i].value === 0 ||
      grid[grid.length - 1][i].value === ""
    ) {
      grid[grid.length - 1][i] = {
        ...grid[grid.length - 1][i],
        value: "",
        interactive: false
      };
    } else {
      break;
    }
  }
  return grid;
};

/**
 * Adds the decimal bool to the answer
 * @param {*} grid
 * @param {*} totalDecimals
 */
const addDecimalSignToAnswer = (grid, totalDecimals, padding = 0) => {
  const gridLength = grid.length-1
  const rowLength = grid[gridLength].length-1

  grid[gridLength][rowLength-totalDecimals-padding] = {...grid[gridLength][rowLength-totalDecimals-padding], comma: true}
}

/**
 * Return information about the terms. e.g Which of the two terms is longenst/shortest
 * and on which index they belong to. Decimal length & if the terms has equal length.
 * @param {*} termsDigits
 */
const termsInfo = (termsDigits) => {
  const equalLengths = termsDigits[0].length === termsDigits[1].length

  const term1 = Number(termsDigits[0].join(''))
  const term2 = Number(termsDigits[1].join(''))
  let long = {}
  let short = {}

  if(equalLengths) {
    long = term1 > term2
      ? {longLength: termsDigits[0].length, longIndex: 0}
      : {longLength: termsDigits[1].length, longIndex: 1}
    short = term1 < term2
      ? {shortLength: termsDigits[0].length, shortIndex: 0}
      : {shortLength: termsDigits[1].length, shortIndex: 1}
  } else {
    long = termsDigits[0].length > termsDigits[1].length
      ? {longLength: termsDigits[0].length, longIndex: 0}
      : {longLength: termsDigits[1].length, longIndex: 1}
    short = termsDigits[0].length < termsDigits[1].length
      ? {shortLength: termsDigits[0].length, shortIndex: 0}
      : {shortLength: termsDigits[1].length, shortIndex: 1}
  }

  const indexDecimal = termsDigits[long && long.longIndex].indexOf(".")
  const decimalLength = indexDecimal === -1
    ? 0
    : termsDigits[long.longIndex].slice(indexDecimal+1).length

  return {...long, ...short, equalLengths, decimalLength }
}

/**
 * Calculate answer and memory digits and add digits to grid.
 * @param {*} grid
 */
const populateAdditionGrid = (grid, termsDigits, gridLength, colNr, multiplicationGrid) => {
  for (let col = gridLength; col >= 1; col--) {
    // add all digits in column from question, and add memory from answer
    const sum = multiplicationGrid
    ? multiplicationGrid.reduce(
        (acc, row, index) =>
          acc + (
            Number.isInteger(row[col].value) && (gridLength - col > 1 || index > 0)
              ? row[col].value
              : 0
          ),
        0
      )
    : grid.reduce(
      (acc, row) =>
        acc + (Number.isInteger(row[col].value) ? row[col].value : 0),
      0
    )
    const ans = sum % 10;

    grid[grid.length - 1][col] = {
      ...grid[grid.length - 1][col],
      value: ans,
      interactive: true
    };
    const memory = Math.floor(sum / 10);
    if (memory > 0) {
      if (col > 1) {
        grid[colNr][col - 1] = {
          ...grid[colNr][col - 1],
          value: memory,
          inMemory: true,
          hidden: true
        };
      } else {
        grid[grid.length - 1][col - 1] = {
          ...grid[grid.length - 1][col - 1],
          value: memory,
          inMemory: true,
          hidden: true
        };
      }
    }
  }

  const totalDecimals = getTotalDecimalsInTerm(termsDigits[0])
  totalDecimals > 0 && !multiplicationGrid && addDecimalSignToAnswer(grid, totalDecimals)

  return grid;
};

/**
 * Calculate answer and "borrow" digits and add digits to grid.
 * @param {*} grid
 */
const populateSubtractionGrid = (grid, termsDigits, width) => {
  for (let col = grid[0].length - 1; col >= 1; col--) {
    // calculate the subtraction for the column
    let diff = grid.reduce((acc, row, index) => {
      let val = Number.isInteger(row[col].value) ? parseInt(row[col].value) : 0;
      return index === 0 ? 0 : index === 1 ? val : acc - val;
    }, 0);

    // while result is negative, "borrow" from left digit
    while (diff < 0) {
      diff = diff + 10;
      const memory = Number.isInteger(grid[0][col].value)
        ? grid[0][col].value + 10
        : 10;
      grid[0][col] = {
        ...grid[0][col],
        value: memory,
        inMemory: true,
        hidden: true
      };

      let b = Number.isInteger(grid[1][col - 1].value)
        ? grid[1][col - 1].value
        : 0;
      grid[1][col - 1] = { ...grid[1][col - 1], value: b - 1 };
    }

    grid[grid.length - 1][col] = {
      ...grid[0][col - 1],
      value: diff,
      interactive: true
    };
  }

  const totalDecimals = getTotalDecimalsInTerm(termsDigits[0])
  totalDecimals > 0 && addDecimalSignToAnswer(grid, totalDecimals)

  // restore original first term in question.
  grid[1] = mergeArray(grid[1], termsDigits[0], width - termsDigits[0].length);

  return grid;
};

/**
 * Calculate answer and memory digits and add digits to grid.
 * @param {*} grid
 */
const populateMultiplicationGrid = (grid, termsDigits) => {
  const term1TotalDecimals = getTotalDecimalsInTerm(termsDigits[0])
  const term2TotalDecimals = getTotalDecimalsInTerm(termsDigits[1])
  const totalDecimals = term1TotalDecimals + term2TotalDecimals

  for (let col1 = grid[0].length-4; col1 >= 1; col1--) {
    // add all digits in column from question, and add memory from answer

    let memory = 0
    let memorySlot = grid[0].length-3

    for (let col2 = grid[0].length-4; col2 >= 1; col2--) {
      const bottom = grid[1][col1].value
      const upper = grid[0][col2].value
      const colNumber = grid.length > 3 ? getColNumber(col1, grid[0].length-4) : 2
      const rowNumber = colNumber === 3 ? col2 - 1 : col2

      if(!Number.isInteger(upper) || !Number.isInteger(bottom)) {
        if(memory) {
          grid[colNumber][rowNumber] = {...grid[grid.length-1][col2], value: memory, interactive: true}
        }
        break;
      }

      let sum = bottom * upper + memory
      const ans = sum % 10;

      grid[colNumber][rowNumber] = {...emptyCell, value: ans, interactive: true}
      memory = Math.floor(sum / 10);

      const termLength = term1TotalDecimals > 0 ? termsDigits[0].length - 2 : termsDigits[0].length - 1
      const lastDigit = grid[0].length-4 - (termLength)
      const width = grid[0].length-5

      if (memory > 0 && lastDigit < col2 ) {
        if (col2 > 1) {
          grid[col1 - width][memorySlot] = {...grid[col1 - width][memorySlot], value: memory, inMemory: true, interactive: true}
          memorySlot++
        }
      }
    }
  }
  totalDecimals > 0 && addDecimalSignToAnswer(grid, totalDecimals, 3)

  return grid.length > 3 ? populateAdditionGrid(grid, termsDigits, grid[0].length - 4, 1, [...grid].splice(1)) : grid
}

/**
 * Calculate answer and memory digits and add digits to grid.
 * @param {*} grid
 */
const populateDivisionGrid = (grid, termsDigits, answerType, equalSign, result) => {
  const t = termsInfo(termsDigits)
  const numerator = Number(termsDigits[1].join(''))

  let memory = 0
  let denominator = 0

  for(let i = 0; i < t.longLength; i++) {

    // If value is infinity, we skip adding NaN values to the grid.
    if(grid[2][t.longLength+1].value === "∞") {
      continue;
    }

    // If value is 0 and the result is 0 we skip adding more 0 values to the grid.
    if(grid[2][t.longLength+1].value === 0 && result === 0) {
      continue;
    }

    // If value is "." we add it to the grid move on to the next value.
    if(grid[1][i].value === ".") {
      grid[2][t.longLength+i+1] = {...grid[2][t.longLength+i+1], value: grid[1][i].value, interactive: true, isVertical: true}
      continue;
    }

    // If the value is "ghost" we add the ghost property to the cell & we hide the whole column by adding hidden property.
    if(grid[1][i].value === "ghost") {
      for(let c = 0; c < grid.length; c++) {
        c === 1
          ? grid[1][i] = {...grid[c][i], value: 0, hidden: true, interactive: false, isVertical: true, ghost: true, borderBottom: true}
          : grid[c][i] = {...grid[c][i], hidden: true}
      }
    }

    // if the memory prop has a value we append it to the current value.
    denominator = memory ? Number('' + memory + grid[1][i].value) : grid[1][i].value
    memory = 0

    const quotient = Math.floor(denominator/numerator) === Infinity ? '∞' : Math.floor(denominator/numerator);
    const remainder = denominator % numerator;

    // Rounds the result if the result contains decimals & is the last one.
    if(t.longLength-1 === i && t.decimalLength > 0 && equalSign === APPROXIMATION) {
      const roundedResult = result.toFixed(t.decimalLength);
      const lastDigit = +roundedResult.toString().split('').pop();

      // Adds last digit to grid
      grid[2][t.longLength+i+1] = {...grid[2][t.longLength+i+1], value: lastDigit, interactive: true, isVertical: true}
    } else {
      // Adds quotient to grid
      grid[2][t.longLength+i+1] = {...grid[2][t.longLength+i+1], value: quotient, interactive: true, isVertical: true}
    }

    if(remainder) {
      memory = remainder

      // final digit
      if(i+1 === t.longLength) {
        // add rest values
        if(answerType === REST) {
          const restLength = getNumberLengthWithDecimals(memory)
          const restNumbersToArray = Array.from(memory.toString()).map(Number);

          grid[2][t.longLength+i+2] = {...grid[2][t.longLength+i+2], value: 'r', interactive: false, isVertical: true}

          for(let j = 3; j < restLength + 3; j++) {
            grid[2][t.longLength+i+j] = {...grid[2][t.longLength+i+j], value: restNumbersToArray[j-3], interactive: true, isVertical: true}
          }
        }
      } else {
        // add memory digit
        if(grid[1][i+1].value === ".") {
          grid[0][i+2] = {...grid[0][i+2], value: memory, inMemory: true, interactive: true}
        } else {
          grid[0][i+1] = {...grid[0][i+1], value: memory, inMemory: true, interactive: true}
        }
      }
    }
  }

  return grid
}

/**
 * Center the terms in a divsion
 * @param {*} termsDigits
 * @param {*} grid
 */
const centerTerms = (termsDigits, grid) => {
  const strippedTermsDigits = [...termsDigits].map(term => term.filter(digit => Number.isInteger(digit)))
  const st = termsInfo(strippedTermsDigits)
  const diff = st.longLength - st.shortLength

  // center terms
  if(!st.equalLengths) {
    for(let i = 0; i < st.shortLength; i++) {
      grid[1 + st.shortIndex][diff + i] = {...grid[1 + st.shortIndex][diff + i], isHorizontal: diff}
    }
  }

  return grid
}

/**
 * Removes whole column where comma sign occurs,
 * and adds a property to the previous cell comma: true
 * @param {*} grid
 */
const replaceCommaSignWithCommaBool = (grid) => {
  const termIndex = grid[1].findIndex(el => el.value === ".")
  const answerIndex = grid[2].findIndex(el => el.value === ".")

  if (answerIndex !== -1) {
    grid[2][answerIndex-1] = {...grid[2][answerIndex-1], comma: true}

    for(let i = 0; i < grid.length; i++) {
      grid[i].splice(answerIndex, 1);
    }
  }

  if (termIndex !== -1) {
    grid[1][termIndex-1] = {...grid[1][termIndex-1], comma: true}

    for(let i = 0; i < grid.length; i++) {
      // if cell to be removed has a value, left shift the value.
      if (grid[i][termIndex].value !== "" && grid[i][termIndex].value !== ".") {
          grid[i][termIndex-1] = {...grid[i][termIndex]}
      }
      grid[i].splice(termIndex, 1);
    }
  }
  return grid
}

const getColNumber = (col, width) => col === width-1 ? 3 : 2

/**
 * Convert all values to string
 * @param {*} grid
 */
const convertValuesToString = grid =>
  grid.map(column =>
    column.map(row => ({ ...row, value: row.value.toString() }))
  );

const checkForGhostinColumn = (grid) => {
  const ghostIndex = grid[1].findIndex(element => element.ghost)
  const lastIndex = grid[1].map(element => element.ghost).lastIndexOf(true);

  if(ghostIndex !== -1 && grid[2][ghostIndex].value !== "") {
    grid.forEach(column => column.splice(lastIndex + 1, 0, {...emptyCell}))

    let firstItem = grid[0].pop()
    grid[0].unshift({...firstItem})

    let secondItem = grid[1].pop()
    grid[1].unshift({...secondItem})
  }

  return grid
}

const addDivisionBorders = (termsDigits, grid) => {
  const termsDigitsWithoutGhost = clone(termsDigits)

  if (termsDigitsWithoutGhost[0].includes("ghost")) {
    const ghostIndex = termsDigitsWithoutGhost[0].indexOf("ghost")
    const sliceFrom = termsDigitsWithoutGhost[0][ghostIndex-1] === "." ? ghostIndex-1 : ghostIndex
    termsDigitsWithoutGhost[0].splice(sliceFrom)
  }

  const infoWithoutGhost = termsInfo(termsDigitsWithoutGhost)

  // add division borders
  for(let i = 0; i < infoWithoutGhost.longLength; i++) {
    grid[1][i] = {...grid[1][i], borderBottom: true}
  }

  return grid
}

/**
 * Manipulating hidden props depending on ghost indicies
 * @param {*} grid
 */
const manipulateHiddenProps = (grid) => {
  let indexes = []

  // Find ghost indexes
  for (let k = 0; k < grid[1].length; k++){
    if (grid[1][k].ghost){
      indexes.push(k)
    }
  }

  grid.forEach(column => {
    column.forEach((cell, index) => {
      cell.hidden = indexes.includes(index) ? true : false;
    });
  });

  return grid
}

/**
 * Generates a multidimensional array ( grid ) with complete data for diffrent calculation types.
 * @param  {...any} terms
 * @param type
 */
export const generateGridWithTerms = (type, answerType, equalSign, totalDecimals, ...terms) => {
  let termsDigits = getTermsDigits(terms);

  if(type === DIVISION && answerType === DECIMAL) {
    termsDigits = addGhostzerosToTerms(termsDigits, totalDecimals)
  }

  if(type === PLUS || type === MINUS) {
    termsDigits = addDecimalsToTerms(termsDigits)
  }

  const { height, width } = getGridDimensions(termsDigits[0], termsDigits[1], type)

  let grid = getEmptyGrid(width, height);
  grid = addTermsToGrid(termsDigits, grid, width, type);

  switch (type) {
    case PLUS:
      grid = populateAdditionGrid(grid, termsDigits, grid[0].length - 1, 0);
      break;
    case MINUS:
      grid = populateSubtractionGrid(grid, termsDigits, width);
      break;
    case MULTIPLICATION:
      grid = populateMultiplicationGrid(grid, termsDigits);
      break;
    case DIVISION:
      grid = populateDivisionGrid(grid, termsDigits, answerType, equalSign, caluculateNumbers[type](...terms));
      break;
    default:
      return grid;
  }

  switch(type) {
    case PLUS:
    case MINUS:
      grid = addOperationSymbol(grid, grid.length - 2, 0, getOperationSymbol(type));
      break;
    case MULTIPLICATION:
      grid = addOperationSymbol(grid, grid.length - (grid.length - 1), 0, getOperationSymbol(MULTIPLICATION));
      grid = height > 3 ? addOperationSymbol(grid, grid.length - 2, 0, getOperationSymbol(PLUS)) : grid
      break;
    case DIVISION:
      grid = addOperationSymbol(grid, grid.length - 2, termsInfo(termsDigits).longLength, getOperationSymbol(equalSign), { isVertical: true });
      grid = replaceCommaSignWithCommaBool(grid)
      grid = checkForGhostinColumn(grid)
      grid = centerTerms(termsDigits, grid)
      grid = addDivisionBorders(termsDigits, grid)
      grid = manipulateHiddenProps(grid)
      break;
    default:
      break;
  }

  grid = removePadding(grid);
  grid = convertValuesToString(grid);
  grid = generateIDsForEachPosition(grid);

  return grid;
};
