import styled from "styled-components";

export const StyledLayoutButtonWrapper = styled.div`
  background-color: ${props => (props.selected ? "#377AD3" : "transparent")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  ${props => (props.disabled ? "filter: grayscale(90%)" : "")};
`;

export const StyledLayoutDisplayBox = styled.div`
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  width: 87px;
  height: 64px;
`;

export const StyledLayoutBlock = styled.div`
  flex: ${props => (props.size ? props.size : "1")};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "#384E70"};
`;

export const StyledLabel = styled.span`
  color: ${props => (props.selected ? "#FFFFFF" : "#333333")};
  text-shadow: ${props =>
    props.selected ? "0 0 2px rgba(0,0,0,0.5)" : "none"};
`;
