import { registerReducer, registerSaga } from "../shared";
import {
  watchAuthInit,
  watchDoLogin,
  watchDoLogout,
  watchLoginFailure
} from "./saga/watchers";
import authorsReducer from "./store/authors/reducer";
import viewerReducer from "./store/viewer/reducer";

export const init = () => {
  registerReducer("authors", authorsReducer);
  registerReducer("viewer", viewerReducer);
  registerSaga(watchAuthInit);
  registerSaga(watchDoLogout);
  registerSaga(watchDoLogin);
  registerSaga(watchLoginFailure);
};
