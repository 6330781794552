import React from "react";
import { Button } from "../../../shared";
import styled from "styled-components";

export const MediaListWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  min-width: 800px;
  overflow: auto;
  box-sizing: border-box;
  color: ${props => props.theme.neutralSecondary};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
`;

export const MediaListActionRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  padding: 0px 20px;
`;

export const ListMedia = styled.div`
  display: flex;
  flex-direction: ${props => (props.isGridView ? "row" : "column")};
  padding: 8px;
  flex-wrap: wrap;
  max-width: ${props => (props.isGridView ? "1200px" : "1200px")};
`;

export const ToggleButtonsSection = styled.section`
  display: flex;
  flex: 3;
`;

export const MediaActionButton = styled(({ isGridView, ...rest }) => (
  <Button {...rest} />
))`
  background: transparent;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: ${props =>
    props.isGridView ? "3px solid #002050" : "3px solid transparent"};
  &:hover {
    border-left: 1px dotted #696969;
    border-right: 1px dotted #696969;
    border-top: 1px dotted #696969;
    border-bottom: 3px solid #002050;
    background: transparent;
    box-sizing: border-box;
  }

  &:active,
  &:focus {
    background: transparent;
    box-sizing: border-box;
  }
  & span {
    height: 30px;
    width: 30px;
    color: #696969;
  }
`;

export const MediaActionButtonList = styled(MediaActionButton)`
  border-bottom: ${props =>
    props.isGridView ? "3px solid transparent" : "3px solid #002050"};
`;
