import React from "react";
import styled from "styled-components";
import Row from "./Row";
import Section from "./Section";
import Title from "./Title";
import PageHeaderIcon from "./PageHeaderIcon";

const PageHeader = ({
  className,
  headerIcon,
  studlicon,
  title,
  titleAction,
  primaryAction,
  secondaryAction
}) => {
  const actionRow =
    primaryAction || secondaryAction ? (
      <Row>
        <section start>{primaryAction}</section>
        <section end>{secondaryAction}</section>
      </Row>
    ) : null;

  return (
    <div className={className}>
      <Row>
        <Section start>
          <PageHeaderIcon icon={headerIcon} studlicon={studlicon} />
          <Title> {title}</Title>
        </Section>
        <Section end>{titleAction}</Section>
      </Row>
      {actionRow}
    </div>
  );
};

export default styled(PageHeader)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.baselineNumber * 1}px;
  width: 100%;
`;
