import React from "react";
import MediaButtons from "../../../shared/components/AddMediaButtons/AddMediaButtons";
import {
  StyledAudioContainer,
  StyledImageContainer,
  StyledInputField,
  StyledMediaTitle,
  StyledImageWrapper,
  StyledVideoContainer
} from "../Help/StyledHelp";
import {
  StyledMediaRow,
  StyledPluginHeaderWrapper
} from "./StyledPluginHeader";
import { DATA, TITLE } from "../../../constants";
import { renderMultiSVG } from "../MultiSvgImageHelpers";
import { StyledMediaResource } from "../MathWord/StyledMathWord";

const PluginHeader = ({
  data,
  updateData,
  openMediaLibrary,
  settings,
  resources,
  updateDraftList
}) => {
  /**
   * Handles user title change
   * @param title
   * @returns {*}
   */
  const onTitleChange = title => {
    updateDraftList();
    updateData(TITLE, title, DATA);
  };

  /**
   * Renders title input
   * @returns {null}
   */
  const renderTitleInput = () =>
    "title" in data ? (
      <StyledInputField
        onChange={onTitleChange}
        placeholder={"Titel"}
        value={data.title || ""}
      />
    ) : null;

  /**
   * Renders media buttons
   * @returns {*}
   */
  const renderMediaButtons = () => (
    <MediaButtons
      openMediaLibrary={openMediaLibrary}
      data={data}
      updateData={updateData}
      settings={settings}
      updateDraftList={updateDraftList}
    />
  );

  /**
   *  Render audio controls
   */
  const renderSelectedAudio = () => {
    var filename =
      data.audio && data.audio.src
        ? data.audio.src.replace(/^.*[\\/]/, "")
        : null;

    return data.audio && data.audio.src ? (
      <StyledAudioContainer>
        <StyledMediaTitle readOnly={settings.readOnly}>Titel: {filename}</StyledMediaTitle>
        <audio src={data.audio.src} {...data.audio.attributes} />
      </StyledAudioContainer>
    ) : null;
  };

  /**
   * Render the video player
   */
  const renderVideoPlayer = () => {
    var filename =
      data.video && data.video.src
        ? data.video.src.replace(/^.*[\\/]/, "")
        : null;

    return data.video && data.video.src ? (
      <StyledVideoContainer>
        <StyledMediaTitle noMarginLeft={true}>
          Titel: {filename}
        </StyledMediaTitle>
        <span>
          <video key={data.video.src} controls={true} controlsList="nodownload">
            <source src={data.video.src} type="video/mp4" />
            {settings.useClosedCaption &&
              data.cc &&
              data.cc.map((track, index) => renderVttTrack(track, index))}
          </video>
        </span>
      </StyledVideoContainer>
    ) : null;
  };

  /**
   * Render the track part of video
   * @param {object}  track
   * @param {string}  track.label
   * @param {string}  track.kind
   * @param {string}  track.lang
   * @param {string}  track.src
   * @param {number}  key
   */
  const renderVttTrack = ({ label, kind, lang, src }, key) => (
    <track key={key} label={label} kind={kind} srcLang={lang} src={src} />
  );

  /**
   *  Render selected image
   */
  const renderSelectedImage = () => {
    const imageSrc = data.image && data.image.src;
    const filename = imageSrc ? data.image.src.replace(/^.*[\\/]/, "") : null;

    return !!imageSrc ? (
      <StyledImageWrapper>
        <StyledMediaTitle>Titel: {filename}</StyledMediaTitle>
        <StyledImageContainer>
          <img src={imageSrc} alt="alttext" />
        </StyledImageContainer>
      </StyledImageWrapper>
    ) : null;
  };

  /**
   * Render Media Resource
   */
  const renderMediaResource = () => {
    const mrData = data.mediaresource && data.mediaresource.data;
    return (
      mrData &&
      mrData.src && (
        <StyledImageWrapper>
          <StyledMediaTitle noMarginLeft={true}>
            Titel: {mrData.text}
          </StyledMediaTitle>
          <StyledMediaResource>
            {renderMultiSVG(
              mrData.id,
              { [mrData.id]: mrData.src, ...resources },
              600
            )}
          </StyledMediaResource>
        </StyledImageWrapper>
      )
    );
  };

  return (
    data && (
      <StyledPluginHeaderWrapper>
        <StyledMediaRow>
          {renderTitleInput()}
          {renderMediaButtons()}
        </StyledMediaRow>

        <StyledMediaRow>
          {renderSelectedAudio()}
          {renderVideoPlayer()}
          {renderSelectedImage()}
          {renderMediaResource()}
        </StyledMediaRow>
      </StyledPluginHeaderWrapper>
    )
  );
};

export default PluginHeader;
