import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PrimaryButton } from "../../../shared/components/Button";
import { Field } from "../../../products/components/Input";
import {
  getClassroomgroupList,
  getClassroomgroupTitles
} from "../../store/selectors";
import { createClassroomGroup } from "../../api/actions";

import {
  createValidation,
  ErrorFormatter
} from "../../../products/validation/product";
import {
  Classroomgroup,
  classroomgroupErrors,
  checkIfGroupExists
} from "./validation";
import Label from "../../../products/components/Label";
import AutoComplete from "../../../products/components/AutoComplete";

const Title = styled.span`
  ${props => props.theme.d400}
  margin-top: 0;
  color: #287574;
`;

const StyledErrorMessage = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
`;

const FormButtonWrapper = styled.div`
  position: absolute;
  bottom: 27px;
  right: 27px;
`;

const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
`;

export const Input = styled(Field)`
  width: 100%;
  height: 45px;
  &:disabled {
    background: white;
    opacity: 0.7;
  }
`;

const classroomgroupValidation = createValidation(
  Classroomgroup,
  ErrorFormatter(classroomgroupErrors)
);

const CreateNewClassroomGroupForm = ({ close }) => {
  const initValues = {
    title: ""
  };
  const classroomgroups = useSelector(getClassroomgroupList);
  const [formValues, setFormValues] = useState(initValues);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const createClassroomgroup = data => dispatch(createClassroomGroup(data));

  const onSubmit = () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (!formValues.title) {
      setErrors({ title: "Namn saknas" });
      return;
    }

    if (!formValues.edition) {
      setErrors({ edition: "Upplaga saknas" });
      return;
    }
    const found = checkIfGroupExists(
      classroomgroups,
      formValues.title,
      formValues.edition
    );
    if (found) {
      setErrors({
        edition: "Klassrumsgrupp med samma namn och upplaga finns redan."
      });
      return;
    }

    createClassroomgroup(formValues);
    close();
  };

  const _onChange = (value, name) => {
    const { err } = classroomgroupValidation({
      ...formValues,
      [name]: value
    });
    setErrors(err || {});

    if (name === "edition") {
      const onlyIntegers = value.replace(/[^0-9]/g, "");
      value = Number(onlyIntegers);
    }

    if (name === "title" && !value) {
      setErrors({ title: "Måste innehålla minst ett tillåtet tecken." });
    }

    setFormValues({
      ...formValues,
      [name]: value
    });
  };
  return (
    <>
      <>
        <Title>Skapa klassrumsgrupp</Title>
        <FormWrapper style={{ marginBottom: "1rem" }}>
          <FormElementWrapper>
            <Label>
              Namn *
              <AutoComplete
                selector={getClassroomgroupTitles}
                onSelect={val => _onChange(val, "title")}
                onChange={val => _onChange(val, "title")}
                onBlur={val => _onChange(val, "title")}
                preSearchValue={formValues.title}
                name="title"
              />
              {errors.title && (
                <StyledErrorMessage>{errors.title}</StyledErrorMessage>
              )}
            </Label>
            <Input
              error={errors["edition"]}
              value={formValues.edition}
              onChange={_onChange}
              label="Upplaga *"
              name="edition"
              autoComplete="off"
            />
          </FormElementWrapper>
        </FormWrapper>
      </>
      <FormButtonWrapper>
        <PrimaryButton onClick={onSubmit}>Skapa</PrimaryButton>
      </FormButtonWrapper>
    </>
  );
};

export default CreateNewClassroomGroupForm;
