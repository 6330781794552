import styled from "styled-components";

export const MediaListItemContainer = styled.article`
  display: flex;
  flex-direction: row;
  margin: 5px 5px;
  border: ${props =>
    props.isSelected ? "4px solid #5990dc" : "4px solid transparent"};
  padding: 0;
  align-content: space-between;
  width: 260px;
  border-radius: 5px;
`;

export const MediaListItemContainerList = styled.article`
  display: flex;
  flex-direction: column;
  margin: 5px;
  align-content: space-between;
  min-width: 220px;
  min-height: 180px;
  position: relative;
  border-bottom: 2px solid lightgrey;
  border-radius: 3px;
  padding: 10px 3px;
  align-items: flex-start;
`;

export const MediaListItemImage = styled.section`
  background-color: white;
  ${props => (props.isSelected ? "" : "border: 1px solid lightgrey;")};
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 3px;
  min-height: 48px;
  & img {
    max-height: 100%;
    ${props =>
      props.listview
        ? "max-width: 100%; min-height: 90px;"
        : "max-width: 180px;"};
  }
  & video {
    max-height: 100%;
  }
`;

export const FileTitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  word-break: break-word;
  text-align: start;
`;

export const MediaListItemContainerChild = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: space-between;
  position: relative;
`;

export const MediaListItemContainerChildList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: space-between;
  margin-right: 10px;
  padding: 6px 6px 8px 6px;
  min-height: 170px;
`;

export const MediaListItemContainerThumbnailSection = styled(
  MediaListItemContainerChildList
)`
  ${props => (props.isSelected ? "background-color: #0078d7" : "")};
  ${props => (props.isSelected ? "border-radius: 8px" : "")};
`;

export const MediaListItemContainerEndChild = styled(
  MediaListItemContainerChildList
)`
  margin-left: auto;
  margin-right: 0;
`;

export const MediaListItemInfo = styled.section`
  margin-top: 5px;
  flex: 1;
  display: flex;
  font-size: 12px;
  max-height: 30px;
`;

export const StyledIdContainer = styled.div`
  margin-top: 3px;
  flex: 1;
  display: flex;
  font-size: 12px;
  max-height: min-content;
`;

export const MediaListID = styled.div`
  flex: 1;
  margin: auto 0;
  width: max-content;
  color: ${props => (props.isSelected ? "#FFFFFF" : "#0078d7")};
`;

export const CheckBoxWrapper = styled.div`
  width: max-content;
  position: absolute;
`;

export const DummyImage = styled.svg`
  fill: #a0a0a0;
  padding: 0px 30px;
  margin: 0 auto;
  max-height: 100%;
  width: 150px;
`;

export const getMediaInfoColor = props => {
  switch (props.type) {
    case "resource":
      return props.theme.blue;
    case "post":
      return props.theme.blueMid;
    case "aida":
      return props.theme.magentaDark;
    case "series":
      return props.theme.red;
    case "classroomgroup":
      return props.theme.tealLight;
    case "marketmessage":
      return props.theme.purple;
    default:
      return props.theme.green;
  }
};

export const Marker = styled.div`
  height: 23px;
  border-radius: 5px;
  color: white;
  background-color: ${props => getMediaInfoColor(props)};
  margin-left: auto;
  margin-right: 1px;
  fill: white;
  align-items: center;
  justify-content: center;
  padding: 5px;
  display: flex;
`;

export const MarkerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
`;
export const MarkerCount = styled.div`
  padding: 3px;
  text-align: right;
  color: ${props => (props.isSelected ? "#FFFFFF" : "#0078d7")};
`;

export const Title = styled.span`
  ${props => props.theme.d100}
  margin-top: 0;
`;
export const Desc = styled.div`
  font-weight: 600;
  overflow: auto;
  flex: 1;
  line-height: 20px;
  margin-left: 5px;
`;

export const MediaFileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Checked = styled.span`
  position: absolute;
  background-color: #5990dc;
  border-radius: 20px;
  color: #fff;
  right: -9px;
  top: -13px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    line-height: 12px;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const MediaLabels = styled.span`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const MediaTag = styled.span`
  margin: 0px 5px 5px 5px;
`;

export const MediaFileUserInfo = styled(MediaListItemInfo)`
  flex-direction: column;
  display: flex;
  max-height: none;
`;
export const MediaFileLabelInfo = styled(MediaListItemInfo)`
  align-items: center;
  margin-top: 13px;
`;

export const MediaFileUsageInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const MediaFileUsageInfo = styled.section`
  padding: 0px 1px;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin: 0px 5px 0px 5px;

  & div {
    margin-right: 7px;
  }
`;

export const StyledVideoThumbnail = styled.video`
  padding: 0px 30px;
  margin: 0 auto;
  max-height: 220px;
  width: 100%;
`;
