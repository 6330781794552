import React from "react";

const ResourceAdd = () => (
  <svg width="60px" height="51px" viewBox="0 0 60 51" version="1.1">
    <title>Icon/Resources Add</title>

    <path
      d="M46.875,29.125 C48.6005859,29.125 50,30.5244141 50,32.25 L50,32.25 L50,47.875 C50,49.6005859 48.6005859,51 46.875,51 L46.875,51 L31.25,51 C29.5244141,51 28.125,49.6005859 28.125,47.875 L28.125,47.875 L28.125,32.25 C28.125,30.5244141 29.5244141,29.125 31.25,29.125 L31.25,29.125 Z M12.5,26 C19.4033203,26 25,31.5966797 25,38.5 C25,45.4033203 19.4033203,51 12.5,51 C5.59667969,51 0,45.4033203 0,38.5 C0,31.5966797 5.59667969,26 12.5,26 Z M46.875,32.25 L31.25,32.25 L31.25,47.875 L46.875,47.875 L46.875,32.25 Z M12.5,29.125 C7.33105469,29.125 3.125,33.3300781 3.125,38.5 C3.125,43.6689453 7.33105469,47.875 12.5,47.875 C17.6699219,47.875 21.875,43.6689453 21.875,38.5 C21.875,33.3300781 17.6699219,29.125 12.5,29.125 Z M35.9375,1 L36,1.002 L36.0013741,4.12799477 C35.9807182,4.12599916 35.9594259,4.125 35.9375,4.125 C35.7739258,4.125 35.6456163,4.18060981 35.5537019,4.29069915 L35.5029297,4.36328125 L25.0625,22.2207031 C25.0263672,22.2822266 24.9423828,22.4267578 25.0605469,22.6337891 C25.1370443,22.7672526 25.2521973,22.8457845 25.402615,22.8682545 L25.4970703,22.875 L36,22.875 L36,26 L25.4970703,26 C22.7734163,26 21.048812,23.1514466 22.2788342,20.7986594 L22.3652344,20.6425781 L32.8056641,2.78613281 C33.5009766,1.59570312 34.7197266,1 35.9375,1 Z"
      id="path-1"
    ></path>
    <path
      d="M59.09375,10.375 L50.5,10.375 L50.5,1.78125 C50.5,1.34960938 50.1503906,1 49.71875,1 L48.15625,1 C47.7246094,1 47.375,1.34960938 47.375,1.78125 L47.375,10.375 L38.78125,10.375 C38.3496094,10.375 38,10.7246094 38,11.15625 L38,12.71875 C38,13.1503906 38.3496094,13.5 38.78125,13.5 L47.375,13.5 L47.375,22.09375 C47.375,22.5253906 47.7246094,22.875 48.15625,22.875 L49.71875,22.875 C50.1503906,22.875 50.5,22.5253906 50.5,22.09375 L50.5,13.5 L59.09375,13.5 C59.5253906,13.5 59.875,13.1503906 59.875,12.71875 L59.875,11.15625 C59.875,10.7246094 59.5253906,10.375 59.09375,10.375 Z"
      id="path-3"
    ></path>
  </svg>
);

export default ResourceAdd;
