import styled from "styled-components";
import React from "react";

const Outer = styled.div`
  width: 100%;
  height: 10px;
  margin: 10px auto;
  background: white;
  border-radius: 5px;
  box-shadow: inset 0px 0px 2px #888888;
`;

const Inner = styled.div`
  height: 100%;
  background: #37a3f1;
  border-radius: 5px;
  transition: width 0.2s ease-in;
  width: ${props => (props ? `${props.width}%` : "0%")};
`;

const Filler = ({ percentage }) => <Inner width={percentage} />;

const ProgressBar = ({ percentage }) => (
  <Outer>
    <Filler percentage={percentage} />
  </Outer>
);

export default ProgressBar;
