import Modal from "../../../../shared/components/Modal/Modal";
import { Warning } from "../../../../shared/components/StudliIcons";
import {
  StyledRemovePrompt,
  StyledHeadingContainer,
  StyledRemoveHeading,
  StyledRemoveText,
  StyledButtonContainer,
  StyledModalButton
} from "./StyledChartModal";

const ChartModal = ({ show, closeModal }) => (
  <Modal isModalOpen={show} modalClose={closeModal}>
    <StyledRemovePrompt>
      <StyledHeadingContainer>
        <Warning size={20} />
        <StyledRemoveHeading>Ändra tabelltyp?</StyledRemoveHeading>
      </StyledHeadingContainer>
      <StyledRemoveText>
        Om du ändrar tabelltyp kommer innehållet i tabellen att försvinna.
      </StyledRemoveText>
      <StyledRemoveText>
        <strong>Vill du fortsätta ändå?</strong>
      </StyledRemoveText>
      <StyledButtonContainer>
        <StyledModalButton $danger onClick={closeModal(false)}>
          Avbryt
        </StyledModalButton>
        <StyledModalButton onClick={closeModal(true)}>
          Fortsätt
        </StyledModalButton>
      </StyledButtonContainer>
    </StyledRemovePrompt>
  </Modal>
);

export default ChartModal;
