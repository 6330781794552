import React from "react";
import { Clock, DotComma } from "../../../shared/components/StudliIcons";
import { ALPHA_NUMERIC, NUMERIC, TIME } from "../../../constants";

export const tags = {
  CODE: { pre: "{", post: "}" }
};

export const SidebarButtonStyles = {
  width: "40px",
  height: "40px"
};

export const caseSensitiveData = [
  { id: 0, label: "Ab", value: true },
  { id: 1, label: "ab", value: false }
];

export const spaceSensitiveData = [
  { id: 0, label: "a_b", value: true },
  { id: 1, label: "ab", value: false }
];

export const dotsSensitiveData = [
  { id: 0, label: ".", value: true },
  { id: 1, label: <DotComma size={12} />, value: false }
];

export const answerTypes = [
  { id: 1, label: "A-9", value: ALPHA_NUMERIC },
  { id: 2, label: "0-9", value: NUMERIC },
  { id: 3, label: <Clock size={15} />, value: TIME }
];
