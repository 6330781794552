import {
  registerReducer,
  registerSaga,
  RouterService,
  registerConstraint
} from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import { classroom as classroomReducer } from "../classroom/store/reducer";
import { applicationEnsureClassroom } from "./sagas/sagas";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Classroom", classroomReducer);
  registerConstraint(applicationEnsureClassroom);
};
