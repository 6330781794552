import React from "react";

const Warning = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 22 19"
    width={`${size}px`}
    className="studlicon-warning"
  >
    <path fill="currentColor" d="M12,12 L10,12 L10,8 L12,8 M12,16 L10,16 L10,14 L12,14 M0,19 L22,19 L11,0 L0,19 Z" />
  </svg>
);

export default Warning;
