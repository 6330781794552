import React from "react";
import { connect } from "react-redux";

import Input from "../../../shared/components/Input";
import { isBefore, startOfToday } from "date-fns";

import {
  StyledCommentSection,
  StyledNoComments,
  StyledCommentList,
  StyledCommentItem,
  StyledCommentUser,
  StyledCommentTime,
  StyledCommentMessage,
  StyledInputWrapper
} from "./StyledComments";

import { addComment } from "../../store/actions";

import {
  selectComments,
  selectCurrentPost,
  selectCurrentPostType
} from "../../api/selectors";

import { selectPostId, selectNewPostTmpData } from "../../sagas/selectors";
import { distanceInWordsToNow, format } from "../../../date";

/**
 * Format the date
 * @param {*} dateString
 */
const formatDate = dateString => {
  const date = new Date(dateString);

  if (isBefore(date, startOfToday())) {
    return format(date);
  }
  return distanceInWordsToNow(date);
};

/**
 * Add a name to user 1. Change this when users is implemented.
 * @param {*} userId
 */
const getUserName = userId => {
  if (userId === 1) return "admin";
  return userId;
};

/**
 * Adds userinfo and formats the date for each comment.
 * TODO: Look over this when user is implemented!!!
 * @param {*} comments
 */
const addUserAndFormattedDateToCommentList = comments => {
  return comments
    ? comments.map(c => {
        c.name = getUserName(c.user_id);
        c.time = formatDate(c.updated_at) || "";
        return c;
      })
    : [];
};

class Comments extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      commentList: []
    };
  }

  onEnter = v => {
    const { addComment, postType } = this.props;

    addComment(postType, v.text);
    this.setState({
      inputValue: ""
    });
  };

  onChange = v => {
    this.setState({
      inputValue: v
    });
  };

  render() {
    const { inputValue } = this.state;

    return (
      <StyledCommentSection>
        <StyledCommentList>{this.renderCommentList()}</StyledCommentList>
        <StyledInputWrapper>
          <Input
            onEnter={this.onEnter}
            onChange={this.onChange}
            value={inputValue}
            placeholder="Skriv kommentar..."
          />
        </StyledInputWrapper>
      </StyledCommentSection>
    );
  }

  /**
   * render the list of comments
   */
  renderCommentList = () =>
    this.props.commentList.length ? (
      this.props.commentList.map((c, i) => (
        <StyledCommentItem key={"c" + i}>
          <StyledCommentUser>{c.name}</StyledCommentUser> -{" "}
          <StyledCommentTime>{c.time}</StyledCommentTime>
          <StyledCommentMessage>{c.message}</StyledCommentMessage>
        </StyledCommentItem>
      ))
    ) : (
      <StyledNoComments>Inga kommentarer...</StyledNoComments>
    );
}

const mapStateToProps = state => {
  const postType = selectCurrentPostType(state);
  const postId = selectPostId(state);
  const data = selectNewPostTmpData(state, postType);
  const comments =
    postId === "new"
      ? { data: data.get("comments").toJS() }
      : selectComments(state, postType).toJS();
  return {
    postType: postType,
    commentList: addUserAndFormattedDateToCommentList(comments.data),
    current: selectCurrentPost(state, postType).toJS()
  };
};

const mapDispatchToProps = dispatch => ({
  addComment: (postType, comment) => dispatch(addComment(postType, comment))
});
export default connect(mapStateToProps, mapDispatchToProps)(Comments);
