import React from "react";

const Auth = ({ size, color }) => (
  <svg
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-auth"
  >
  <path d="M9,17 L12,14.06 C11.61,14.02 11.32,14 11,14 C8.33,14 3,15.34 3,18 L3,20 L12,20 L9,17 Z M11,12 C13.21,12 15,10.21 15,8 C15,5.79 13.21,4 11,4 C8.79,4 7,5.79 7,8 C7,10.21 8.79,12 11,12 L11,12 Z M15.47,20.5 L12,17 L13.4,15.59 L15.47,17.67 L20.6,12.5 L22,13.91 L15.47,20.5 Z" id="path-1"></path>
</svg>
);

export default Auth;
