import React, { useState, useRef, useEffect } from "react"
import { ChevronDown } from "react-feather";
import {
    StyledDropDown,
    StyledIconWrapper,
    StyledDropDownText,
    StyledInternalDropDownWrapper,
    StyledDropDownWrapper,
    StyledDropDownThemeLabel,
    StyledDropDownColorCount,
    StyledDropDownOption
} from "./StyledMarkerTree";



const DropDown = ({ themes, selectedThemeCallback, selectedTheme, isMarkerTypeUrl }) => {

    const dropdownRef = useRef(null);


    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = e => {
        if (!dropdownRef.current.contains(e.target)) {
            setIsOpen(!true);
        }
    };

    const handleClick = () => {
        themes.size !== 0 && setIsOpen(!isOpen)
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    });

    const selectTheme = (id) => {
        isMarkerTypeUrl && selectedThemeCallback(id)
    }    

   const renderColorThemeLabelAndColorCountOrDefault = () =>{
       const colorTheme = selectedTheme.toArray()[0];
       return colorTheme ? (
           <><span style={{marginLeft:'3px'}}>{colorTheme.theme_label} </span> <span style={{marginLeft:'2px'}}>  {colorTheme.color_count} färger</span></>   
       ): "Välj färgtema"
   }

    return (
        <StyledDropDownWrapper ref={dropdownRef}>
            <StyledDropDown onClick={handleClick}>
                <StyledDropDownText>{renderColorThemeLabelAndColorCountOrDefault()}</StyledDropDownText>
                <StyledIconWrapper role="button" >
                    <ChevronDown width={16} height={16} color={"grey"} />
                </StyledIconWrapper>
            
            <StyledInternalDropDownWrapper isOpen={isOpen}>
                {themes.size !== 0 && themes.map(
                    item => <StyledDropDownOption key={item.theme_id} isOpen={isOpen} role="button" onClick={() => selectTheme(item.theme_id)} >
                        <StyledDropDownThemeLabel >{item.theme_label} </StyledDropDownThemeLabel>
                        <StyledDropDownColorCount >{item.color_count} färger</StyledDropDownColorCount>
                    </StyledDropDownOption>)}            </StyledInternalDropDownWrapper>
            </StyledDropDown>
        </StyledDropDownWrapper>
    )
}
export default DropDown;