import React from "react";

const Template = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-template"
  >
    <path d="M45.3125,3 L4.6875,3 C2.09863281,3 0,5.09863281 0,7.6875 L0,42.0625 C0,44.6513672 2.09863281,46.75 4.6875,46.75 L45.3125,46.75 C47.9013672,46.75 50,44.6513672 50,42.0625 L50,7.6875 C50,5.09863281 47.9013672,3 45.3125,3 Z M46.875,18.25 L46.875,42.0625 C46.875,42.9254883 46.1754883,43.625 45.3125,43.625 L18.375,43.625 L18.375,18.25 L46.875,18.25 Z M15.6199999,43.625 L4.68249989,43.625 C3.8195116,43.625 3.11999989,42.9254883 3.11999989,42.0625 L3.11999989,18.25 L15.6199999,18.25 L15.6199999,43.625 Z M3.11999989,15.4949999 L3.11999989,7.68249989 C3.11999989,6.8195116 4.12362715,6.11999989 5.36180308,6.11999989 L46.8799982,6.11999989 L46.8799982,15.4949999 L3.11999989,15.4949999 Z" />
  </svg>
);

export default Template;
