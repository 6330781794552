import styled from "styled-components";
import React from "react";
import { StandardButton } from "../../../shared";
import PrimaryButton from "../../../shared/components/Button/Primary"

export const MediaToolbarWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 0 8px;
  box-sizing: border-box;
  color: #808080;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 70px;
`;

export const StyledMediaToolbarButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  button {
    margin: 0 3px;

    &:last-child {
      margin-left: auto;
    }
  }
`;

export const MediaButton = styled(StandardButton)`
  padding: 15px 20px;
  height: auto;
  color: #000;
  &:active,
  &:focus {
    color: grey;
    &:hover {
      color: lightgrey;
    }
  }
`;

export const MediaToolBarSection = styled(({ start, end, ...rest }) => (
  <section {...rest} />
))`
  display: flex;
  flex: 1;
  align-items: center;
  flex-grow: 2;
  ${props =>
    props.end
      ? `
      justify-self: flex-end;
      flex-grow:5;
      justify-content:flex-end;
    `
      : null};
  ${props =>
    props.start
      ? `
      justify-self: flex-start;
      flex-grow:5;
    `
      : null};
  height: 100%;
  min-width: 90%;
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  font-weight: bold;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`

export const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
`

export const StyledSpan = styled.span`
  color: #0078d7;
`
