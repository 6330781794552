import { Column } from "react-virtualized";
import TextRenderer from "../Renderer/Text";

const TextColumn = () => null;

TextColumn.propTypes = Object.assign({}, Column.propTypes);
TextColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: TextRenderer,
  draggable: true
});

export default TextColumn;
