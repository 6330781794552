import { ENTITIES, SERIES } from "../constants";
import Media from "../media/views/Media";
import Series from "./views/Series/Series";
import SeriesDetails from "./views/SeriesDetails";

export const ROUTE_SERIES = "ROUTE_SERIES";
export const ROUTE_SERIES_DETAILS = "ROUTE_SERIES_DETAILS";
export const ROUTE_SERIES_MEDIA = "ROUTE_SERIES_MEDIA";

export const routes = {
  [ROUTE_SERIES]: {
    path: "/series",
    name: ENTITIES[SERIES],
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Serier",
    icon: "system",
    childRoutes: [ROUTE_SERIES_MEDIA, ROUTE_SERIES_DETAILS]
  },
  [ROUTE_SERIES_DETAILS]: {
    path: "/series/detail/:id",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Ny serie",
    icon: "system",
    type: "series",
    confirmLeave: state =>
      handleChangesMadeConfirmLeave(ENTITIES[SERIES], state),
  },
  [ROUTE_SERIES_MEDIA]: {
    name: "Media",
    path: "/series/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH", "MEDIATAGS"],
    tagsByTypeOnly: ["serie"],
    uploadBasePath: "/series",
    text: "Media",
    icon: "Media",
    type: "series"
  }
};

export const views = {
  [ROUTE_SERIES]: Series,
  [ROUTE_SERIES_DETAILS]: SeriesDetails,
  [ROUTE_SERIES_MEDIA]: Media
};

const handleChangesMadeConfirmLeave = (route, state) => {
  const isEdited = state[route].getIn(["isEdited"]);
  const isNew = state.location.payload.id === "new";

  if (!isEdited || isNew) return;

  return "Du har gjort ändringar som inte kommer sparas om du väljer att lämna sidan.";
}
