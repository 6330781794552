import { createReducer } from "../../../store/utils";
import { USER_LOGIN_SUCCESSFUL } from "../../api/actions";
import { mergeDeepRight } from "ramda";
import { fromJS } from "immutable";

export const requiredFields = {
  author: null,
  fetching: false
};

const viewerReducer = initialState => {
  const state = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(state, {
    [USER_LOGIN_SUCCESSFUL](state, { authorId }) {
      return state.set("author", authorId);
    }
  });
};

export default viewerReducer({ author: null });
