import React from "react";

const Star = ({ width, height, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="2 2 20 20"
    width={`${width}px`}
    height={`${height}px`}
  >
    <path
      fill={`${color}`}
      d="M 12 17.27 L 18.18 21 l -1.64 -7.03 L 22 9.24 l -7.19 -0.61 L 12 2 L 9.19 8.63 L 2 9.24 l 5.46 4.73 L 5.82 21 Z"
    />
  </svg>
);

export default Star;
