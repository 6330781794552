import React from "react";
import { NodeCancelBtn } from "./StyledStructureTree";

export const NodeCancelButton = ({ callback, nodeData, ...rest }) => {
  const onClick = e => {
    e.stopPropagation();
    callback(nodeData);
  };

  return <NodeCancelBtn studlicon={"Trash"} onClick={onClick} {...rest} />;
};
