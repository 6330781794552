import styled from "styled-components";

export const Grid = styled.div`
  height: 100%;
  background-color: #eeeeee;
  padding: 1rem;
  display: flex;
`;

export const Section = styled.section`
  width: ${({ large, medium }) =>
    large ? "740px" : medium ? "550px" : "410px"};

  margin: 0 0.5rem;
  &:first-child {
    margin: 0 0.5rem 0 0;
  }

  &:last-child {
    margin: 0 0 0 0.5rem;
  }

  & > div {
    margin: 0.5rem 0;
  }

  & > div:last-child {
    margin: 0.5rem 0 0 0;
  }

  & > div:first-child {
    margin: 0 0 0.5rem 0;
  }
`;
