import styled from "styled-components";
import { DataTable, selectable } from "../../../shared";
import { PrimaryButton } from "../../../shared/components/Button";

export const StyledSelectableDataTable = styled(selectable(DataTable))`
  height: calc(100% - 56px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
`;

export const StyledFilterContainer = styled.div`
  width: 240px;
`;

export const StyledFilterWrapper = styled.div`
  margin: 0px 20px 0px 0px;
`;

export const StyledListWrapper = styled.div`
  width: 100%;
`;

export const StyledPaginationWrapper = styled.div`
  display: flex;
`;

export const StyledFilterButton = styled(PrimaryButton)`
  width: 100%;
  border-radius: 5px;
`;

export const StyledFilterButtonWrapper = styled.div`
  width: 100%;
  padding: 15px 10px;
`;

export const StyledSimpleModalPostList = styled.div`
  width: calc(100vw - 150px);
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: row;
`;
