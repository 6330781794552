import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setProductSettings } from "../api/actions";
import { getProductInfo } from "../store/selectors";
import Checkbox from "./Checkbox";
import Link from "../../shared/components/Link";

const RefIDWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RefIDRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const TextWrapper = styled.p`
  margin: 0;
  padding-right: 0.4rem;
`;

const DescriptionWrapper = styled.p`
  margin: 0 0 1rem;
`;

const LinkButton = styled(Link).attrs({
  icon: "Link"
})`
  margin-top: 2rem;
`;

const epubKey = "epub";
const pdfKey = "pdf";
const pdfRefId = "pdf_ref_id";
const epubRefId = "epub_ref_id";

const CreateHurixBookHandler = () => {
  const dispatch = useDispatch();
  const [entity] = useSelector(getProductInfo);

  const settings = entity.productSettings;
  const foundPDF = settings.find(i => i.key === pdfKey);
  const Pdf = foundPDF && foundPDF.value === "true" ? true : false;

  const foundEPUB = settings.find(i => i.key === epubKey);
  const Epub = foundEPUB && foundEPUB.value === "true" ? true : false;

  const pdfRefID = settings.find(i => i.key === pdfRefId);
  const epubRefID = settings.find(i => i.key === epubRefId);

  const getSettings = (key, val) => {
    return settings.map(s => {
      if (s.key === key) {
        return { ...s, value: val };
      }
      return s;
    });
  };

  const updateSettings = (k, v) =>
    dispatch(setProductSettings(entity.id, getSettings(k, v)));

  const updatePdfFormatSettings = checked => {
    if (checked) {
      updateSettings(pdfKey, "true");
    } else {
      updateSettings(pdfKey, "false");
    }
  };

  const updateEpubFormatSettings = checked => {
    if (checked) {
      updateSettings(epubKey, "true");
    } else {
      updateSettings(epubKey, "false");
    }
  };

  return (
    <>
      <DescriptionWrapper>
        Kryssrutorna nedan styr vilka varianter boken finns tillgänglig i för studenterna. 
        Ref ID används vid kommunikation med Kitaboo och ska anges när böcker publiceras i deras gränssnitt.
        Om en variant kryssas ur kommer inte längre studenter få tillgång till den vid köp av boken.
      </DescriptionWrapper>
      <Checkbox
        label="Finns som PDF"
        checked={Pdf}
        onChange={updatePdfFormatSettings}
      />
      <Checkbox
        label="Finns som ePub"
        checked={Epub}
        onChange={updateEpubFormatSettings}
      />
      <RefIDWrapper>
        {Pdf && pdfRefID && (
          <RefIDRow>
            <TextWrapper>PDF Ref ID: </TextWrapper>
            <TextWrapper>{pdfRefID.value}</TextWrapper>
          </RefIDRow>
        )}
        {Epub && epubRefID && (
          <RefIDRow>
            <TextWrapper>ePub Ref ID: </TextWrapper>
            <TextWrapper>{epubRefID.value}</TextWrapper>
          </RefIDRow>
        )}
      </RefIDWrapper>
      
      <LinkButton to="https://create.kitaboo.eu/home.xhtml" target="_blank" rel="noopener noreferrer">Öppna Kitaboo</LinkButton>
    </>
  );
};

export default CreateHurixBookHandler;
