import React from "react";
import { PageHeader } from "../../../shared";
import PostList from "../../containers/PostList";
import {
  ENTITIES,
  ENTITY_PLURAL,
  HELP_RESOURCE,
  translation
} from "../../../constants";

const helpresource = HELP_RESOURCE.toLowerCase();
const helpresources = ENTITY_PLURAL[helpresource].toLowerCase();

const Resources = () => (
  <React.Fragment>
    <PageHeader studlicon="HelpResource" title={translation[helpresources]} />
    <PostList type={ENTITIES[helpresource]} />
  </React.Fragment>
);

export default Resources;
