import React from "react";
import {
  StyledOverlay,
  StyledModalContainer,
  StyledModalButtonsContainer,
  StyledModalButtons,
  StyledModalBody,
  StyledCancelButton,
  StyledRemoveButton,
  StyledSpanText
} from "./StyledRemoveModal";

const RemoveModal = ({
  acceptCallback,
  cancelCallback,
  title,
  data,
  isActive
}) => {
  const doAcceptCallback = () => {
    if (data) {
      acceptCallback(data);
    }
  };

  return (
    <StyledOverlay isActive={isActive}>
      <StyledModalContainer>
        <StyledModalBody>
          <span>Är du säker på att du vill ta bort följande kategori: {data && <StyledSpanText>{data.title}</StyledSpanText>}</span>
        </StyledModalBody>

        <StyledModalButtonsContainer>
          <StyledModalButtons>
            <StyledRemoveButton onClick={doAcceptCallback}>
              Ta bort
            </StyledRemoveButton>

            <StyledCancelButton onClick={cancelCallback}>
              Avbryt
            </StyledCancelButton>
          </StyledModalButtons>
        </StyledModalButtonsContainer>
      </StyledModalContainer>
    </StyledOverlay>
  );
};

export default RemoveModal;
