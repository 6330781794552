import {
  S,
  M,
  L,
  XL,
  BORDER_RIGHT,
  BORDER_BOTTOM,
  ROW,
  COLUMN,
  GRID,
  DATA,
  EQUATION
} from "../../../constants";
import { isPositiveInteger } from "../../../shared/helpers";
import { getMedia } from "../../../shared/MediaHandler/MediaHandler";
import {
  emptyCell,
  generateIDsForEachPosition,
  getEmptyGrid,
  getEmptyGridEquation
} from "./ChartData";

export const getHeight = size => {
  switch (size) {
    case S:
      return "35px";
    case M:
      return "50px";
    case L:
      return "70px";
    default:
      return "35px";
  }
};

export const getWidth = size => {
  switch (size) {
    case S:
      return "50px";
    case M:
      return "100px";
    case L:
      return "120px";
    case XL:
      return "200px";
    default:
      return "100px";
  }
};

export const getBorderKey = cell => {
  if (cell.borderBottom) return BORDER_BOTTOM;
  if (cell.borderRight) return BORDER_RIGHT;
  return undefined;
};

export const getBorderThickness = border => {
  if (border === BORDER_BOTTOM) return "thin thin 5px thin";
  if (border === BORDER_RIGHT) return "thin 5px thin thin";
  return "thin";
};

const getGridLength = (grid, type) => {
  if (type === COLUMN) {
    return grid ? grid[0].length : 0;
  }
  return grid ? grid.length : 0;
};

const getGridLengthDiff = (grid, direction, count) => {
  const rowLength = getGridLength(grid, ROW);
  const columnLength = getGridLength(grid, COLUMN);
  return count - (direction === ROW ? rowLength : columnLength);
};

const generateGridWithUpdatedIDs = ({
  grid,
  diff,
  type,
  direction,
  columnCount,
  a,
  b
}) => {
  const newGrid =
    type === EQUATION
      ? getEmptyGridEquation(Number(a), Number(b))
      : getEmptyGrid(Number(a), Number(b));

  return generateIDsForEachPosition(
    grid && diff > 0
      ? addColumnOrRowToGrid(grid, direction, type, columnCount, diff)
      : newGrid
  );
};

export const setGridSize = ({
  grid,
  type,
  currentType,
  direction,
  setColumnCount,
  setRowCount,
  columnCount,
  rowCount,
  updateData,
  storeDraft,
  draftTarget
}) => count => {
  if (!Number(count) && count !== "") {
    return;
  }

  const isColumn = direction === COLUMN;
  const numberCount = Number(count) ? Number(count) : "";
  isColumn ? setColumnCount(numberCount) : setRowCount(numberCount);

  const selectedType = isColumn ? String(rowCount) : String(columnCount);
  const a = isColumn ? count : columnCount;
  const b = !isColumn ? count : rowCount;

  if (isPositiveInteger(count) && isPositiveInteger(selectedType)) {
    storeDraft(draftTarget);

    const diff = getGridLengthDiff(grid, direction, count);
    if (diff === 0 && currentType === type) return;

    updateData(
      GRID,
      generateGridWithUpdatedIDs({
        grid,
        diff,
        type,
        direction,
        columnCount,
        a,
        b
      }),
      DATA
    );
  }
};

const addColumnOrRowToGrid = (grid, direction, type, column, diff) => {
  if (direction === ROW) {
    const arrayData = grid[0].map(cell => ({
      ...emptyCell,
      row: cell.row,
      column: cell.column
    }));
    return [
      ...grid,
      ...(type === EQUATION
        ? getEmptyGridEquation(column, diff)
        : Array(diff)
            .fill("")
            .map(_ => Array(column).fill(...arrayData)))
    ];
  }

  if (direction === COLUMN) {
    return grid.map(row => [
      ...row,
      ...Array(diff).fill({
        ...emptyCell,
        row: row[0].row,
        column: row[0].column
      })
    ]);
  }
};

export const checkEverySelectedCell = (cells, key, value) =>
  cells.length !== 0 &&
  cells.every(cell =>
    key === ROW || key === COLUMN ? cell[key] === value : cell[key]
  );

export const onItemImageChange = (
  media,
  selectedCells,
  grid,
  storeDraft,
  draftTarget,
  updateData
) => {
  let selectedCell = { ...selectedCells[0] };

  if (media.mediaresource) {
    selectedCell = { ...selectedCell, ...media };
    if (selectedCell.image) {
      delete selectedCell.image;
    }
  }
  if (media.url) {
    selectedCell.image = {
      src: media.url
    };
    if (selectedCell.mediaresource) {
      delete selectedCell.mediaresource;
    }
  }

  selectedCell.value = "";

  const updatedGrid = grid.map(row =>
    row.map(cell => (cell.id === selectedCell.id ? selectedCell : cell))
  );

  storeDraft(draftTarget);
  updateData(GRID, updatedGrid, DATA);
};

export const cellHasMedia = selectedCells =>
  selectedCells.length === 1 &&
  (selectedCells[0].mediaresource || selectedCells[0].image);

export const getMediaTitle = (
  selectedCells,
  propsMediaResources,
  mediaresources
) => {
  const cell = selectedCells.length === 1 && selectedCells[0];
  const media = getMedia(cell, { ...propsMediaResources, ...mediaresources });

  return media.mediaTitle;
};

export const removeMedia = (
  grid,
  selectedCells,
  storeDraft,
  draftTarget,
  updateData
) => () => {
  const updatedGrid = grid.map(row =>
    row.map(cell => {
      if (cell.id === selectedCells[0].id) {
        delete cell.image;
        delete cell.mediaresource;
      }
      return cell;
    })
  );

  storeDraft(draftTarget);
  updateData(GRID, updatedGrid, DATA);
};

export const getAlignment = (type, index) => {
  if (type === EQUATION) {
    switch (index) {
      case 0:
        return "end";
      case 1:
        return "center";
      case 2:
        return "start";
      default:
        return "center";
    }
  }
  return "center";
};

export const getSectionSelections = (direction, grid, rowIndex, cellIndex) =>
  direction === COLUMN
    ? grid
        .map(row => row.filter((_, index) => index === Number(cellIndex)))
        .flat()
    : grid.filter((_, index) => index === Number(rowIndex)).flat();
