import createEntityReducer from "../api/reducer";
import {
  ENTITIES,
  EXERCISE,
  RESOURCE,
  HELP_RESOURCE,
  TEMPLATE,
  AIDA
} from "../../constants";

const exerciseReducer = createEntityReducer(ENTITIES[EXERCISE.toLowerCase()]);
const resourceReducer = createEntityReducer(ENTITIES[RESOURCE.toLowerCase()]);
const helpResourceReducer = createEntityReducer(
  ENTITIES[HELP_RESOURCE.toLowerCase()]
);
const templateReducer = createEntityReducer(ENTITIES[TEMPLATE.toLowerCase()]);
const aidaReducer = createEntityReducer(ENTITIES[AIDA.toLowerCase()]);

export const exercise = exerciseReducer({
  args: {}
});

export const template = templateReducer({
  args: {}
});

export const resource = resourceReducer({
  args: {}
});

export const helpResource = helpResourceReducer({
  args: {}
});

export const aida = aidaReducer({
  args: {}
});
