import * as React from "react";
import { Input } from "../Input";
import { RadioButtonRow } from "../../../shared/components/RadioButtonRow/index";
import {
  StyledSettingsRow,
  StyledPrimaryButton,
  StyledMultipleChoice,
  StyledMultipleChoiceContent,
  StyledEditInputWrapper,
  StyledAddNewOptionRow,
  StyledInputTypeInputsContainer,
  StyledInputTypeLabel
} from "./StyledMultipleChoice";

import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import { DATA, FONT_SIZE, OPTIONS, SETTINGS, SIZES } from "../../../constants";

import { OptionContainer, OptionItem } from "./OptionItem";
import PluginHoc from "../../../shared/PluginHoc";
import { guid } from "../../../shared/helpers";
import SidebarButton from "../NumberLine/SidebarButton/SidebarButton";
import { SidebarButtonStyles } from "../Blanks/BlanksData";

export class MultipleChoice extends React.Component {
  static defaultProps = {
    settings: {
      multipleAnswers: false,
      randomizeAlternatives: true
    },
    useCorrectionButton: true,
    buttonLineUp: "horizontal",
    buttonSize: "medium"
  };

  constructor(props) {
    super(props);
    this.state = this.getStateFromData(props);
  }

  /**
   * Sets ID's for options
   * @param options
   */
  setIdsForOptions = options =>
    options.map((opt, indx) => ({ ...opt, id: "id_" + indx }));

  /**
   * Save input to state
   * @param value
   */
  onChange = value => this.setState({ value: value });

  /**
   * Extracts state from data
   * @param data
   * @returns {{value: string}}
   */
  getStateFromData = data => {
    return {
      mediaresources: {},
      value: "",
      updatedItem: false
    };
  };

  /**
   * Removes option
   * @param indx
   */
  removeOption = indx => {
    this.setState({
      updatedItem: true
    });
    const { options } = this.props;
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(
      OPTIONS,
      this.setIdsForOptions(options.filter((_, i) => i !== indx)),
      DATA
    );
  };

  /**
   * Opens media resource list
   */
  openMediaResourceList = () => {
    const { openMediaresourceModal = () => {} } = this.props.actions || {};
    openMediaresourceModal(res => {
      if (res.length > 0) {
        let newOption = {
          mediaresource: {
            data: {
              id: res[0].id,
              text: res[0].title
            },
            type: "mediaresource"
          },
          isCorrect: false
        };
        this.addNewOption({ multiSVG: newOption });
        this.setState({
          mediaresources: {
            ...this.state.mediaresources,
            [res[0].id]: res[0].data
          }
        });
      }
    });
  };

  /**
   * Opens media library
   */
  openMediaLibrary = () => {
    const { openMediaModal = () => {} } = this.props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === "image") {
        this.addNewOption({
          image: {
            src: selected[0].url
          }
        });
      }
    });
  };

  /**
   * open media library and replace image or mediaresource with a new media
   * @param indx indx of option to replace image in
   */
  onChangeMedia = indx => {
    const { openMediaModal = () => {} } = this.props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === "image") {
        this.onOptionImageChange(indx, selected[0]);
      }
    });
  };

  /**
   * Open mediaresource library and replaces image or mediaresource with a new mediaresource
   * @param indx index of option to replace mediaresource in
   */
  onChangeMediaResource = indx => {
    const { openMediaresourceModal = () => {} } = this.props.actions || {};
    openMediaresourceModal(res => {
      let newOption = {
        text: this.state.value,
        mediaresource: {
          data: {
            id: res[0].id,
            text: res[0].title
          },
          type: "mediaresource"
        },
        isCorrect: false
      };
      this.onOptionImageChange(indx, newOption);
      this.setState({
        mediaresources: {
          ...this.state.mediaresources,
          [res[0].id]: res[0].data
        }
      });
    });
  };

  /**
   * File upload dropzone
   */
  openDropzone = () => {
    const { openDropzoneModal = () => {} } = this.props.actions || {};
    openDropzoneModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === "image") {
        this.addNewOption({ image: selected[0].url });
      }
    });
  };

  /**
   * Adds new option
   * @param content
   */
  addNewOption = content => {
    if (
      this.state.value ||
      content.sound ||
      content.image ||
      content.multiSVG
    ) {
      const { options } = this.props;
      let newOption = {
        isCorrect: false
      };
      if (this.state.value) {
        newOption["text"] = this.state.value;
      }
      if (content.sound) {
        newOption["sound"] = content.sound;
      }
      if (content.image) {
        newOption["image"] = content.image;
      }
      if (content.multiSVG) {
        newOption = content.multiSVG;
      }

      this.setState({ value: "" });
      this.props.storeDraft(this.props.draftTarget);
      this.props.updateData(
        OPTIONS,
        options.concat({ ...newOption, id: guid() }),
        DATA
      );
    }
  };

  /**
   * Get number of correct answers
   */
  getNumberOfCorrectAnswers = () => {
    const correctAnswers = this.props.options.filter(
      element => element.isCorrect === true
    );
    return correctAnswers ? correctAnswers.length : 0;
  };

  /**
   * Handles edit option content
   * @param indx
   * @param content
   */
  editOptionContent = (indx, content) => {
    const {
      options,
      settings: { multipleAnswers }
    } = this.props;
    let newOption = { ...options[indx] };
    if (content.text !== undefined) {
      newOption.text = content.text;
    }
    if (content.correct !== undefined) {
      if (
        multipleAnswers ||
        this.getNumberOfCorrectAnswers() < 1 ||
        newOption.isCorrect
      ) {
        newOption.isCorrect = content.correct;
      }
    }

    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(
      OPTIONS,
      this.setIdsForOptions(
        options.map((item, i) => (i === indx ? newOption : item))
      ),
      DATA
    );
  };

  /**
   * Handles image option image change
   * @param indx
   * @param media
   */
  onOptionImageChange = (indx, media) => {
    const { options } = this.props;
    let newOption = { ...options[indx] };
    if (media.mediaresource) {
      newOption.mediaresource = media.mediaresource;
      if (newOption.image) {
        delete newOption.image;
      }
      this.props.storeDraft(this.props.draftTarget);
      this.props.updateData(
        OPTIONS,
        this.setIdsForOptions(
          options.map((item, i) => (i === indx ? newOption : item))
        ),
        DATA
      );
    }
    if (media.url) {
      newOption.image = {
        src: media.url
      };
      if (newOption.mediaresource) {
        delete newOption.mediaresource;
      }

      this.props.storeDraft(this.props.draftTarget);
      this.props.updateData(
        OPTIONS,
        this.setIdsForOptions(
          options.map((item, i) => (i === indx ? newOption : item))
        ),
        DATA
      );
    }
  };

  /**
   * Duplicates option with index
   * @param indx
   */
  onDuplicateOption = indx => {
    const items = [...this.props.options];
    items.splice(indx, 0, { ...this.props.options[indx], id: guid() });
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(OPTIONS, items, DATA);
  };

  /**
   * Changes settings for one or several correct answers
   * @returns {void|*}
   */
  hasMultipleAnswers = indexClicked => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData("multipleAnswers", indexClicked === 0, SETTINGS);
  };

  /**
   * Changes settings for randomizing order of options or not
   * @returns {void|*}
   */
  setRandomizeAlternativesSetting = indexClicked => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(
      "randomizeAlternatives",
      indexClicked === 1,
      SETTINGS
    );
  };

  /**
   * Checks if a setting is active
   * @param key
   * @param value
   * @returns {boolean}
   */
  isActiveSetting = (key, value) =>
    this.props.settings && this.props.settings[key] === value;

  /**
   * Set font size setting
   * @param fontSize
   */
  setFontSize = fontSize => () => {
    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData("fontSize", fontSize, SETTINGS);
  };

  radioButtonCallback = indexClicked => {
    if (
      this.props.settings.multipleAnswers === false ||
      this.getNumberOfCorrectAnswers() <= 1
    ) {
      this.hasMultipleAnswers(indexClicked);
    }
  };

  callbackUpdatedItem = () => this.setState({ updatedItem: false });

  /**
   * Function that runs after options has moved.
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...this.props.options];
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);

    this.setState({
      updatedItem: true
    });

    this.props.storeDraft(this.props.draftTarget);
    this.props.updateData(OPTIONS, items, DATA);
  };

  render() {
    return (
      <StyledMultipleChoice>
        <StyledMultipleChoiceContent>
          {this.renderMultipleChoiceOptions()}
          {this.renderAddOption()}
        </StyledMultipleChoiceContent>
        {this.renderMultipleChoiceSidebar()}
      </StyledMultipleChoice>
    );
  }

  /**
   * Render MultipleChoice sidebar
   * @returns {*}
   */
  renderMultipleChoiceSidebar = () => (
    <StyledBlockSidebar maxWidth="200px">
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor="#35877A">
          Övning:
        </StyledSidebarHeading>
        <StyledSettingsRow>
          {this.renderMultipleAnswersSetting()}
        </StyledSettingsRow>
      </StyledSidebarSection>
      <StyledSidebarSection>
        <StyledSidebarHeading backgroundColor="#35877A">
          Text och bild:
        </StyledSidebarHeading>
        <StyledInputTypeInputsContainer>
          <React.Fragment>
            <StyledInputTypeLabel>Textstorlek</StyledInputTypeLabel>
            {SIZES.map(size => (
              <SidebarButton
                key={size}
                callback={this.setFontSize(size)}
                isActive={this.isActiveSetting(FONT_SIZE, size)}
                content={size}
                overrideStyles={SidebarButtonStyles}
              />
            ))}
          </React.Fragment>
        </StyledInputTypeInputsContainer>
      </StyledSidebarSection>
    </StyledBlockSidebar>
  );

  /**
   * Render component for selecting if exercise should have more than one correct answer
   * @returns {*}
   */
  renderMultipleAnswersSetting = () => {
    const Opt = [<div>Flera</div>, <div>Ett</div>];

    return (
      <RadioButtonRow
        options={Opt}
        activeIndex={this.props.settings.multipleAnswers ? 0 : 1}
        callback={this.radioButtonCallback}
        label="Antal rätta svar"
      />
    );
  };

  /**
   * OUT OF SCOPE (Still here in case we want to use if in the future.)
   * Render component for selecting if options should be in a randomized order.
   * @returns {*}
   */
  renderRandomizeAnswersSetting = () => {
    const Opt = [<div>Av</div>, <div>På</div>];
    const { settings } = this.props;

    return (
      <RadioButtonRow
        options={Opt}
        activeIndex={settings.randomizeAlternatives ? 1 : 0}
        callback={this.setRandomizeAlternativesSetting}
        label="Slumpa alternativ"
      />
    );
  };

  /**
   * Renders add media button
   * @returns {*}
   */
  renderAddMediaButton = () => (
    <StyledPrimaryButton studlicon="Media" onClick={this.openMediaLibrary}>
      Lägg till Enskild bild
    </StyledPrimaryButton>
  );

  /**
   * Renders add media resource button
   * @returns {*}
   */
  renderAddMediaResourceButton = () => (
    <StyledPrimaryButton
      studlicon="ImageResourceIcon"
      outline
      onClick={this.openMediaResourceList}
    >
      Lägg till Sammansatt bild
    </StyledPrimaryButton>
  );

  /**
   * Renders input for new options
   * @returns {*}
   */
  renderNewOptionInput = () => (
    <StyledEditInputWrapper>
      <Input
        placeholder="Lägg till ett alternativ..."
        value={this.state.value}
        onChange={this.onChange}
        onEnter={this.addNewOption}
      />
    </StyledEditInputWrapper>
  );

  /**
   * Renders add option if is not read only state
   * @returns {null}
   */
  renderAddOption = () => {
    if (!!this.props.readOnly) {
      return null;
    }

    return (
      <StyledAddNewOptionRow>
        {this.renderNewOptionInput()}
        {this.renderAddMediaButton()}
        {this.renderAddMediaResourceButton()}
      </StyledAddNewOptionRow>
    );
  };

  /**
   * Render options
   */
  renderOptions = () => {
    const { actions, resources: propsMediaResources, options } = this.props;
    const { mediaresources: stateMediaResources } = this.state;
    const resources = { ...propsMediaResources, ...stateMediaResources };

    return options
      ? options.map((item, i) => (
          <OptionItem
            key={"alt" + i}
            index={i}
            value={item}
            indx={i}
            actions={actions}
            onChangeFn={this.editOptionContent}
            onRemoveFn={this.removeOption}
            resources={resources}
            onImageChange={this.onOptionImageChange}
            editBtnFn={this.editOptionContent}
            onMediaChange={this.onChangeMedia}
            onMediaResourceChange={this.onChangeMediaResource}
            onDuplicateOption={this.onDuplicateOption}
            settings={{ multiLine: false }}
            storeDraft={this.props.storeDraft}
            draftTarget={this.props.draftTarget}
            updateData={this.props.updateData}
            updatedItem={this.state.updatedItem}
            callbackUpdatedItem={this.callbackUpdatedItem}
          />
        ))
      : null;
  };

  /**
   * Renders options for MultipleChoice
   */
  renderMultipleChoiceOptions = () => {
    return (
      <OptionContainer useDragHandle onSortEnd={this.onSortEnd}>
        {this.renderOptions()}
      </OptionContainer>
    );
  };
}

export default PluginHoc({
  Component: MultipleChoice,
  defaultSettings: { operative: true }
});
