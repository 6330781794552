import styled from "styled-components";

const Avatar = styled.div`
  display: flex;
  padding: 0;
  width: 32px;
  height: 32px;
  background: ${props => props.theme.blue};
  user-select: none;
  color: ${props => props.theme.white};
  font-size: 12px;
  font-weight: 900;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export default Avatar;
