import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_MEDIARESOURCES, ROUTE_MEDIARESOURCE_EDIT } from "../routes";

import { ENTITIES, MEDIA_RESOURCE } from "../../constants";

import {
  fetchEntitiesSaga,
  fetchEntityDetailsSaga,
  fetchFiltersSaga,
  addRemoveTagsToPostSaga,
  deletePostsSaga,
  savePostsSaga,
  addCommentToPost,
  addDescriptionToPost,
  fetchCommentsSaga
} from "../api/saga";

import {
  moveIconsFrontOrBack,
  resizeIcons,
  duplicateIcons,
  alignIcons,
  removeIcons,
  updateIcons,
  initIconData,
  distributeIcons,
  updateSettings,
  addNewIcon,
  onMediaEditRoute
} from "./editorSagas";

import { onMediaFilterRoute } from "../../media/sagas/sagas";
import { onFetchStructure } from "../../structure/sagas/sagas";
import {
  onListRoute,
  sortList,
  onFilterRoute,
  onLoadComments,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga
} from "./sagas";

function* watchPostRequests() {
  yield takeEvery(
    "MEDIARESOURCE/FETCH_ENTITIES",
    fetchEntitiesSaga(ENTITIES[MEDIA_RESOURCE], "listMediaResources")
  );
  yield takeLatest(
    "MEDIARESOURCE/FETCH_ENTITY_DETAILS",
    fetchEntityDetailsSaga(ENTITIES[MEDIA_RESOURCE], "fetchMediaResource")
  );
  yield takeEvery(
    "MEDIARESOURCE/SAVE_ENTITY_DETAILS",
    fetchEntityDetailsSaga(ENTITIES[MEDIA_RESOURCE], "saveMediaResource")
  );
  yield takeEvery(
    "MEDIARESOURCE/CREATE_ENTITY_DETAILS",
    fetchEntityDetailsSaga(ENTITIES[MEDIA_RESOURCE], "createMediaResource")
  );

  yield takeEvery(
    "MEDIARESOURCE/SAVE_POST",
    savePostsSaga(ENTITIES[MEDIA_RESOURCE], "savePost")
  );

  yield takeEvery(
    "MEDIARESOURCE/LOAD_LESS_POSTS",
    previousPageSaga,
    ENTITIES[MEDIA_RESOURCE],
    "listMediaResources"
  );
  yield takeEvery(
    "MEDIARESOURCE/LOAD_MORE_POSTS",
    nextPageSaga,
    ENTITIES[MEDIA_RESOURCE],
    "listMediaResources"
  );
  yield takeEvery(
    "MEDIARESOURCE/LOAD_POST_PAGE",
    loadPageSaga,
    ENTITIES[MEDIA_RESOURCE],
    "listMediaResources"
  );

  yield takeEvery(
    "MEDIARESOURCE/RELOAD_POSTS",
    reloadPageSaga,
    ENTITIES[MEDIA_RESOURCE],
    "listMediaResources"
  );

  yield takeEvery(
    "MEDIARESOURCE/SORT_POSTS",
    sortList,
    ENTITIES[MEDIA_RESOURCE],
    "listMediaResources"
  );

  yield takeLatest(
    "MEDIARESOURCE/FILTER_POSTS",
    filterPosts,
    ENTITIES[MEDIA_RESOURCE],
    "filterPosts"
  );

  yield takeEvery(
    "MEDIARESOURCE/POST_ADD_REMOVE_TAGS",
    addRemoveTagsToPostSaga(ENTITIES[MEDIA_RESOURCE], "addRemoveTagsToPost")
  );

  yield takeEvery(
    "MEDIARESOURCE/DELETE_POSTS",
    deletePostsSaga(ENTITIES[MEDIA_RESOURCE], "deletePosts")
  );

  yield takeEvery(
    "MEDIARESOURCE/ADD_COMMENT",
    addCommentToPost(ENTITIES[MEDIA_RESOURCE], "addCommentToPost")
  );
  yield takeEvery(
    "MEDIARESOURCE/ADD_DESCRIPTION",
    addDescriptionToPost(ENTITIES[MEDIA_RESOURCE], "addDescriptionToPost")
  );
  yield takeEvery(
    "MEDIARESOURCE/FETCH_ENTITY_COMMENTS",
    fetchCommentsSaga(ENTITIES[MEDIA_RESOURCE], "fetchCommentsForPost")
  );

  yield takeEvery(
    "MEDIARESOURCE/FETCH_FILTER",
    fetchFiltersSaga(ENTITIES[MEDIA_RESOURCE], "loadFilters")
  );
}

function* watchEditorRequests() {
  yield takeEvery(
    "MULTISVG_MOVE_ICONS_Z",
    moveIconsFrontOrBack,
    ENTITIES[MEDIA_RESOURCE],
    "moveIconsFrontOrBack"
  );
  yield takeEvery(
    "MULTISVG_RESIZE_ICONS",
    resizeIcons,
    ENTITIES[MEDIA_RESOURCE],
    "resizeIcons"
  );
  yield takeEvery(
    "MULTISVG_DUPLICATE_ICONS",
    duplicateIcons,
    ENTITIES[MEDIA_RESOURCE],
    "duplicateIcons"
  );
  yield takeEvery(
    "MULTISVG_ALIGN_ICONS",
    alignIcons,
    ENTITIES[MEDIA_RESOURCE],
    "alignIcons"
  );
  yield takeEvery(
    "MULTISVG_DISTRIBUTE_ICONS",
    distributeIcons,
    ENTITIES[MEDIA_RESOURCE],
    "distributeIcons"
  );
  yield takeEvery(
    "MULTISVG_REMOVE_ICONS",
    removeIcons,
    ENTITIES[MEDIA_RESOURCE],
    "removeIcons"
  );
  yield takeEvery(
    "MULTISVG_UPDATE_ICONS",
    updateIcons,
    ENTITIES[MEDIA_RESOURCE],
    "updateIcons"
  );
  yield takeEvery(
    "MULTISVG_INIT_ICONDATA",
    initIconData,
    ENTITIES[MEDIA_RESOURCE],
    "initIconData"
  );
  yield takeEvery(
    "MULTISVG_UPDATE_SETTINGS",
    updateSettings,
    ENTITIES[MEDIA_RESOURCE],
    "updateSettings"
  );
  yield takeEvery(
    "MULTISVG_ADD_NEW_ICON",
    addNewIcon,
    ENTITIES[MEDIA_RESOURCE],
    "addNewIcon"
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_MEDIARESOURCES,
    onListRoute,
    ENTITIES[MEDIA_RESOURCE],
    "listResources"
  );
  yield takeEvery(
    ROUTE_MEDIARESOURCES,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_MEDIARESOURCES,
    onFetchStructure,
    ENTITIES[MEDIA_RESOURCE],
    "fetchStructure"
  );

  yield takeEvery(
    ROUTE_MEDIARESOURCE_EDIT,
    onMediaEditRoute,
    ENTITIES[MEDIA_RESOURCE],
    "fetchMediaResource"
  );
  yield takeEvery(
    ROUTE_MEDIARESOURCE_EDIT,
    onFilterRoute,
    ENTITIES[MEDIA_RESOURCE],
    "loadFilters"
  );
  yield takeEvery(
    ROUTE_MEDIARESOURCE_EDIT,
    onLoadComments,
    ENTITIES[MEDIA_RESOURCE],
    "loadComments"
  );
  yield takeEvery(
    ROUTE_MEDIARESOURCE_EDIT,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchPostRequests),
    fork(watchPostsRoutes),
    fork(watchEditorRequests)
  ]);
}
