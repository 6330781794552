import { ORDER } from "../constants";
import Order from "./components/Order/Order";
import {
  defaultOrderData,
  defaultOrderSettings
} from "./components/Order/OrderHelpers";

const getUsedResources = ({ data }) => {
  if (!data.items) {
    return undefined;
  }
  return data.items.reduce((acc, item) => {
    if (item.mediaresource && item.mediaresource.data) {
      return acc.concat(item.mediaresource.data.id);
    }
    return acc;
  }, []);
};

const types = [ORDER];

export const createOrderPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: Order,
        style: { padding: 0 },
        props: {
          data: { ...defaultOrderData(), ...entity.data },
          settings: { ...defaultOrderSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types,
  getUsedResources
});

export default createOrderPlugin;

export const emptyBlocks = {
  [ORDER]: {
    type: ORDER,
    data: defaultOrderData(),
    settings: defaultOrderSettings()
  }
};
