import React, { useState } from "react";
import PluginHoc from "../../../shared/PluginHoc";
import {
  StyledImageSlider,
  StyledImageSliderContent,
  StyledPrimaryButton
} from "./StyledImageSlider";
import { Slide, SlidesContainer } from "./Slide";
import { DATA, SLIDES } from "../../../constants";
import { guid } from "../../../shared/helpers";
import { EditorState, convertToRaw } from "draft-js";

const NOOP = () => {}

const ImageSlider = ({
  pluginHeader,
  updateData,
  useModal,
  data,
  settings,
  storeDraft,
  draftTarget,
  actions
}) => {
  const [updatedItem, setUpdatedItem] = useState(false);

  const openMediaLibrary = () => {
    const { openMediaModal = NOOP} = actions || {};
    const callback = selected => {
      const [sel] = selected;
      if (!sel) return;
      if (sel.type === "image") {
        addSlide({
          image: {
            src: sel.url
          },
          text: convertToRaw(EditorState.createEmpty().getCurrentContent())
        });
      }
    }
    openMediaModal(callback, {
      filters: {
        selected: "image",
        hide: ["type"]
      }
    });
  };

  const onChangeImageFn = indx => {
    const { openMediaModal = NOOP } = actions || {};
    openMediaModal(selected => {
      const [sel] = selected;
      if (!sel) return;
      if (sel.type === "image") {
        onChangeSlideImage(indx, sel);
      }
    }, {
      filters: {
        selected: "image",
        hide: ["type"]
      }
    });
  };

  const onChangeSlideImage = (indx, media) => {
    const { slides } = data;
    const newSlide = { ...slides[indx] };

    if (!media.url) {
      return
    }

    newSlide.image = {
      src: media.url
    };

    storeDraft(draftTarget);
    updateData(
      SLIDES,
      setIdsForSlides(
        slides.map((item, i) => (i === indx ? newSlide : item))
      ),
      DATA
    );

  };

  const callbackUpdatedItem = () => setUpdatedItem(false);

  const addSlide = content => {

    if (!content.image) {
      return
    }

    const items = [...data.slides];
    const newSlide = { ...content, id: guid() };

    storeDraft(draftTarget);
    updateData(SLIDES, items.concat({ ...newSlide }), DATA);

  };

  const setIdsForSlides = options =>
    options.map((slide, indx) => ({ ...slide, id: `id_${indx}`}));

  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...data.slides];
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);

    setUpdatedItem(true);

    storeDraft(draftTarget);
    updateData(SLIDES, items, DATA);
  };

  const removeSlide = indx => {
    setUpdatedItem(true);
    storeDraft(draftTarget);
    updateData(
      SLIDES,
      setIdsForSlides(data.slides.filter((_, i) => i !== indx)),
      DATA
    );
  };

  const editSlideContent = (indx, content) => {
    const newSlide = { ...data.slides[indx] };
    if (data.slides[indx].text !== undefined) {
      newSlide.text = content.text;
    }

    storeDraft(draftTarget);
    updateData(
      SLIDES,
      setIdsForSlides(
        data.slides.map((item, i) => (i === indx ? newSlide : item))
      ),
      DATA
    );
  };

  return (
    <StyledImageSlider>
      <StyledImageSliderContent>
        {pluginHeader}
        <SlidesContainer useDragHandle onSortEnd={onSortEnd}>
          { data.slides
              ? data.slides.map((slide, i) => (
                  <Slide
                    key={`slide${i}`}
                    index={i}
                    indx={i}
                    value={slide}
                    storeDraft={storeDraft}
                    draftTarget={draftTarget}
                    actions={actions}
                    editFn={editSlideContent}
                    onRemoveFn={removeSlide}
                    onChangeFn={editSlideContent}
                    onChangeImageFn={onChangeImageFn}
                    updatedItem={updatedItem}
                    callbackUpdatedItem={callbackUpdatedItem}
                  />
                ))
              : null    
          }
        </SlidesContainer>
        <StyledPrimaryButton studlicon="Media" onClick={openMediaLibrary}>
          Lägg till ny slide
        </StyledPrimaryButton>
      </StyledImageSliderContent>
    </StyledImageSlider>
  );
};

export default PluginHoc({
  Component: ImageSlider
});
