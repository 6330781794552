import React from "react";
import { useSelector } from "react-redux";
import Card from "../Card";
import {
	List,
	ListItem,
	ArtNumber,
	ProductLink,
	ListItemTextContainer
} from "../AddBiafLink/LinkedProductsList";
import { getParentEntities } from "../../store/selectors";

const BiafIncludedIn = () => {
	const parentEntities = useSelector(getParentEntities);

	if (!parentEntities.length) return null;

	return (
		<Card noPadding={false} title="Ingår i">
			<List>
				{parentEntities.map(e => (
					<ListItem key={e.id}>
						<ListItemTextContainer>
							<ArtNumber>{e.artnr}</ArtNumber>
							<ProductLink to={`/products/${e.id}`}>{e.title}</ProductLink>
						</ListItemTextContainer>
					</ListItem>
				))}
			</List>
		</Card>
	);
};

export default BiafIncludedIn;
