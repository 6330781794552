import React from "react";

const AlignMiddle = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-alignmiddle"
  >
    <path
      transform="translate(3.000000, 5.000000)"
      fill="#377AD3"
      d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,10 L34,10 L34,30 L24,30 L24,10 Z"
      id="path-1"
    ></path>
    <path
      transform="translate(13.000000, 5.000000)"
      fill="#384E70"
      d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,10 L34,10 L34,30 L24,30 L24,10 Z"
      id="path-3"
    ></path>
    <rect
      id="Rectangle"
      fill="#000000"
      x="0"
      y="24"
      width="50"
      height="2"
    ></rect>
  </svg>
);

export default AlignMiddle;
