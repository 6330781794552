import React from "react";
import styled from "styled-components";

import Label from "./Label";

const StyledLabel = styled(Label)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;

  & > input {
    margin-right: 0.75rem;
    width: 18px;
    height: 18px;
  }

  ${({ disabled }) => disabled && "color: rgba(0,0,0,0.61);"}
`;

const Checkbox = ({ label, onChange, checked, disabled, className }) => {
  return (
    <StyledLabel disabled={disabled} className={className}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
    </StyledLabel>
  );
};

export default Checkbox;
