import styled from "styled-components";
import { Table } from "react-virtualized";
import { darken } from "polished";
import PropTypes from "prop-types";

// you should not do this, but we do this because
// we want to handle the columns in a better looking way
// eslint-disable-next-line react/forbid-foreign-prop-types
Table.propTypes.children = PropTypes.node;

const StyledTable = styled(Table).attrs({
  headerClassName: "DataTable_headerColumn",
  rowClassName: "DataTable_row"
})`
  color: ${props => props.theme.neutralPrimary};

  a {
    color: #106ebe;
    text-decoration: none;
  }

  img {
    vertical-align: middle;
  }

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .DataTable_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.neutralLight};
  }

  .ReactVirtualized__Table__Grid .DataTable_row {
    &:hover {
      background: #e6f0f9;
    }
    &:nth-child(even) {
      background: ${props => props.theme.neutralLighter};
      &:hover {
        background: #e6f0f9;
      }
    }
  }

  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
    outline: none;
  }

  .DataTable_headerColumn {
    ${props => props.theme.d100()};
    margin-top: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    display: flex;

    & > .DataTable_label {
      padding-right: ${props => props.theme.baseline};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
    }

    & > .DragHandleIcon {
      position: absolute;
      right: 0;
      cursor: col-resize;
      padding: 0 ${props => props.theme.baselineNumber / 2}px;
      background: ${props => props.theme.white};
      width: 14px;
      user-select: none;

      &:hover,
      &:focus,
      &:active {
        background: ${props => darken(0.1, props.theme.white)};
      }
    }
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin-right: 10px;
    min-width: 0px;
  }

  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap;
    &.action_column {
      position: fixed;
      right: 0;
      padding-left: 20px;
      margin-right: 0;
    }
  }

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px;
  }
`;

export default StyledTable;
