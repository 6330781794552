import React from "react";
import styled from "styled-components";

const ID = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const IDRenderer = ({ cellData, rowData }) => (
  <ID isSelected={rowData.selected}>{cellData}</ID>
);

export default IDRenderer;
