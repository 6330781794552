import styled from "styled-components";

export const ToolbarWrapper = styled.div`
  padding: 0px;
  background-color: ${props => props.theme.neutralLight};
`;

export const ToolbarRow = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  height: auto;
  min-width: 1px;
  min-height: 1px;
`;

export const ToolBarContent = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  min-width: 1px;
  min-height: 1px;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

export const ToolBarMid = styled.div`
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  font-size: 14px;
  justify-content: left;
`;

export const ToolBarLeft = styled.div`
  order: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  font-size: 14px;
  justify-content: flex-start;
`;

export const ToolBarRight = styled.div`
  order: 3;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  font-size: 14px;
  justify-content: flex-end;
`;

export const ToolBarisSavedMarkup = styled.div`
  display: flex;
  line-height: 24px;
  padding: 6px;
  color: ${props => props.theme.neutralSecondary};
  font-weight: 700;
  font-size: 14px;
`;

export const ToolBarPaginationMarkup = styled.div`
  display: flex;
  line-height: 24px;
  font-size: 16px;
`;
