import React from "react";

const Clock = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    viewBox="0 0 50 50"
  >
    <path
      fill="currentColor"
      d="M24.5 0A24.5 24.5 0 1 0 49 24.5 24.54 24.54 0 0 0 24.5 0zm0 44.3a19.8 19.8 0 1 1 19.8-19.8 19.85 19.85 0 0 1-19.8 19.8zm6.1-10.4l-8.4-6.1a1.23 1.23 0 0 1-.5-1V10.7a1.16 1.16 0 0 1 1.2-1.2h3.2a1.16 1.16 0 0 1 1.2 1.2v14l6.6 4.8a1.36 1.36 0 0 1 .3 1.7l-1.9 2.6a1.35 1.35 0 0 1-1.7.1z"
    />
  </svg>
);

export default Clock;
