import Blanks from "./components/Blanks/Blanks";
import {
  defaultBlanksData,
  defaultBlanksSettings
} from "./components/Blanks/BlanksHelpers";
import { BLANKS } from "../constants";

export const createBlanksPlugin = () => ({
  entityRendererFn: entity => {
    const types = [BLANKS];

    if (types.includes(entity.type)) {
      const { staticInputWidth = null, inputHeight = null } =
        entity.settings || {};
      return {
        style: { padding: 0 },
        component: Blanks,
        props: {
          staticInputWidth,
          inputHeight,
          data: { ...defaultBlanksData(), ...entity.data },
          settings: { ...defaultBlanksSettings(), ...entity.settings }
        }
      };
    }

    return null;
  }
});

export default createBlanksPlugin;

export const emptyBlocks = {
  blanks: {
    type: BLANKS,
    data: defaultBlanksData(),
    settings: defaultBlanksSettings()
  }
};
