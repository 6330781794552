import React, { useState } from "react";
import {
  StyledMarkerTypeImageInItems,
  StyledAddMarkersTypeImagePlaceHolder,
  StyledMarkerItem,
  StyledMarkerItemLabel,
  StyledInput,
  StyledMarkersHolder,
  StyledInputAndButtonWrapper,
  StyledMediaItemImageWrapper,
} from "./StyledMarkerTree";
import { MarkerUpload } from "../../../shared/components/StudliIcons";
import UploadMedia from "../../../shared/components/UploadMedia";

const MarkerItem = ({ label, itemData, onUpdateMarkerItemCallback, colors }) => {
  const [mediaData, setMediaData] = useState({ image_url: { name: "", files: {} } })

  /**
   * Render add audio button
   */
  const renderAddImageButton = key => (
    <div>
      <UploadMedia
        multiple={false}
        type={key}
        skin={"primary"}
        withmargin={'true'}
        buttonstate={"selected"}
        studlicon="Media"
        callback={mediaUploadCallback}
      />
    </div>
  );

  /**
  * Callback for changes in the form
  * @param {*} key
  */
  const mediaUploadCallback = ({ files, type }) => {    
    onUpdateMarkerItemCallback(itemData.id,files)
    setMediaData({ ...mediaData, [type]: { name: files.name, files } });
    
  };

  const renderImage = (image) => (
    /* eslint-disable jsx-a11y/alt-text */
    image ? (
      <StyledMediaItemImageWrapper style={{ backgroundImage: `url(${image})` }}/>       
      
    ) : (
        <StyledMediaItemImageWrapper>
          <StyledAddMarkersTypeImagePlaceHolder style={{ marginTop: '2px', marginLeft:'40px' }}>
            <MarkerUpload size={45} />
          </StyledAddMarkersTypeImagePlaceHolder>
        </StyledMediaItemImageWrapper>
      )
  );

  const renderInput = () => (
    <StyledInput type={"text"}
      placeholder={"Ladda upp bild"}
      value={itemData.item_filename ? itemData.item_filename : ""}
      readOnly />
  )

  const renderMarkersType = () => (
    <StyledMarkersHolder>
      {colors && itemData.marker_type_url ? renderColoredMarker() : renderMarker()}
    </StyledMarkersHolder>
  );  

  const renderColoredMarker = () => {
    let markers = [];   
      colors.map(color => (
        markers.push(
          <StyledAddMarkersTypeImagePlaceHolder key={color.color_id} backgroundColor= {color.color_code}>
          <StyledMarkerTypeImageInItems src={itemData.marker_type_url} alt=""/>
        </StyledAddMarkersTypeImagePlaceHolder>
        )
      ))     
    return markers;
  }

  const renderMarker = () => {    
    let markersHolder = []
    for (let i = 0; i < itemData.marker_count; i++) {
      itemData.marker_type_url ? 
      markersHolder.push(        
        <StyledAddMarkersTypeImagePlaceHolder key={i}>
          <StyledMarkerTypeImageInItems src={itemData.marker_type_url} alt=""/>
        </StyledAddMarkersTypeImagePlaceHolder>
        
       ) :
        markersHolder.push(
          <StyledAddMarkersTypeImagePlaceHolder key={i} backgroundColor= {'#F9F6F6'}>
            <MarkerUpload size={45} />
          </StyledAddMarkersTypeImagePlaceHolder>
        )      
    }
  
    return markersHolder
  }
 
  return (
    <StyledMarkerItem>
      {renderImage(itemData.item_url)}
      <StyledInputAndButtonWrapper>
        {renderInput()}
        {renderAddImageButton("image_url")}
        <StyledMarkerItemLabel> {label} </StyledMarkerItemLabel>
      </StyledInputAndButtonWrapper>
      {renderMarkersType()}
    </StyledMarkerItem>
  )
}



export default MarkerItem;