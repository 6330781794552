import styled from "styled-components";
import { Input } from "../../../products/components/AddProduct/CreateNewProductForm";



export const TitleInput = styled(Input)`
  margin-bottom: 1rem;
`;

export const MediaDetailWrapper = styled.div`
  padding: 0px;
  min-width: 900px;
`;
export const MediaItemContent = styled.div``;
export const MediaWrapper = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 15px;
  & img {
    max-height: 350px;
  }
`;
export const MediaDetailRow = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  height: auto;
  min-width: 1px;
  min-height: 1px;
`;
export const DetailRow = styled.div`
  padding: 3px 0;
  display: flex;
`;
export const MediaDetailContent = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  min-width: 1px;
  min-height: 1px;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

export const MediaDetailLeft = styled.div`
  order: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  font-size: 14px;
  justify-content: flex-start;
`;

export const MediaDetailRight = styled.div`
  order: 3;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  font-size: 14px;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  line-height: 24px;
  padding-right: 5px;
  color: ${props => props.theme.neutralSecondary};
  font-weight: 700;
  font-size: 14px;
`;

export const FileTypeWrapper = styled.span`
  border-radius: 5px;
  background-color: ${props => props.theme.themePrimary};
  color: white;
  padding: 2px 10px;
  margin-left: 5px;
`;
export const VersionWrapper = styled.span`
  border-radius: 5px;
  background-color: ${props => props.theme.themeDarker};
  color: white;
  padding: 4px 10px 0 10px;
  margin-left: 5px;
  margin-right: 10px;
`;

export const MediaDetailPaginationMarkup = styled.div`
  display: flex;
  line-height: 24px;
  font-size: 16px;
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  video, audio {
    width: 100%;
    max-height: 400px;
  }

  &:has(video) {
    width: 900px;
  }
`;

export const IconWrapper = styled.div`
  fill: #a0a0a0;
  padding: 0px 30px;
  margin: 0 auto;
  height: 270px;
  display: flex;
  align-items: center;
`;

export const getMediaInfoContentColor = props => {
  switch (props.tagtype) {
    case "exercise":
      return props.theme.themePrimary;
    case "aida":
      return props.theme.magentaDark;
    case "series":
      return props.theme.red;
    case "marketmessage":
      return props.theme.purple;
    default:
      return "#324d72";
  }
};

export const StyledUsedByLabel = styled.div`
  font-size: 14px;
  background-color: ${props => getMediaInfoContentColor(props)};
  color: ${props => props.theme.white};
  padding: 5px 15px;
  margin-right: 5px;
  border-radius: 5px;
  svg {
    fill: ${props => props.theme.white};
`;
