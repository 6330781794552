import Classroom from "./views/Classroom";
import ClassroomDetails from "./views/ClassroomDetails";
import FormativeQuestionsEdit from "./views/FormativeQuestionsEdit";
import Media from "../media/views/Media";

export const ROUTE_CLASSROOM = "ROUTE_CLASSROOM";
export const ROUTE_CLASSROOM_DETAILS = "ROUTE_CLASSROOM_DETAILS";
export const ROUTE_FORMATIVE_QUESTION_EDIT = "ROUTE_FORMATIVE_QUESTION_EDIT";
export const ROUTE_CLASSROOM_MEDIA = "ROUTE_CLASSROOM_MEDIA";

export const routes = {
  [ROUTE_CLASSROOM]: {
    path: "/klassrum/grupper",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Klassrum",
    icon: "system",
    childRoutes: [
      ROUTE_CLASSROOM_DETAILS,
      ROUTE_FORMATIVE_QUESTION_EDIT,
      ROUTE_CLASSROOM_MEDIA
    ]
  },
  [ROUTE_CLASSROOM_DETAILS]: {
    path: "/klassrum/grupp/:classroomGroupEntityId",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Egenskaper",
    icon: "system",
    type: "classroomgroup"
  },
  [ROUTE_FORMATIVE_QUESTION_EDIT]: {
    path:
      "/klassrum/grupp/:classroomGroupEntityId/fraga/:postId/edit",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Skapa",
    icon: "system"
  },
  [ROUTE_CLASSROOM_MEDIA]: {
    name: "Media",
    path: "/klassrum/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH"],
    uploadBasePath: "/classroom",
    text: "Media",
    icon: "Media",
    type: "classroomgroup"
  }
};

export const views = {
  [ROUTE_CLASSROOM]: Classroom,
  [ROUTE_CLASSROOM_DETAILS]: ClassroomDetails,
  [ROUTE_FORMATIVE_QUESTION_EDIT]: FormativeQuestionsEdit,
  [ROUTE_CLASSROOM_MEDIA]: Media
};
