import React from "react";
import { PrimaryButton, CancelButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import Input from "../../components/Input";
import { SelectField } from "../../../products/components/Input";

import TextArea from "../../components/TextArea";
import { FormNodeWrapper, FormRow, Label } from "./StyledStructureTree";
import { connect } from "react-redux";
import Checkbox from "../../components/Checkbox";

const NodeEdit = ({ draft, onSave, onCancel, disabled, treeDraft }) => {
  const [node, setNode] = React.useState(draft.node);

  const tableCategoryOptions = [
    {
      key: "Välj kategori",
      val: ""
    },
    {
      key: "Addition",
      val: "addition"
    },
    {
      key: "Subtraktion",
      val: "subtraction"
    },
    {
      key: "Addition och subtraktion (blandat)",
      val: "additionsubtraction"
    },
    {
      key: "Multiplikation",
      val: "multiplication"
    },
    {
      key: "Division",
      val: "division"
    },
    {
      key: "Multiplikation och division (blandat)",
      val: "multiplicationdivision"
    }
  ];

  /**
   * Updates the draft when changes are made
   * @param value
   * @param key
   * @returns {*}
   */
  const updateDraft = (value, key) => setNode({ ...node, [key]: value });

  const onToggleIsTable = (checked, target) => {
    if (!checked) {
      setNode({ ...node, [target]: checked, table_category: "" });
      return;
    }
    setNode({ ...node, [target]: checked });
  };

  /**
   * Updates all is_published key in the selected node
   * @param {*} value
   * @param {*} key
   */
  const updateIsPublishedForNode = (value, key) => {
    // updates node + children
    updateObject(node, key, value);

    let parent = null;

    // updates parents if value === true and parent exists.
    if (value && node.parent_id) {
      const flatTreeDraft = flat(treeDraft);
      parent = updateParentOnNodePublished(
        node,
        node.parent_id,
        node.id,
        key,
        flatTreeDraft
      );
    }

    return setNode(parent ? parent : node);
  };

  /**
   * Updates all parent path for node if the node gets published.
   * @param {*} node
   * @param {*} parentId
   * @param {*} nodeId
   * @param {*} key
   * @param {*} flatTreeDraft
   */
  const updateParentOnNodePublished = (
    node,
    parentId,
    nodeId,
    key,
    flatTreeDraft
  ) => {
    if (parentId !== "0") {
      let parent = flatTreeDraft.find(item => item.id === parentId);

      //Check for newly created parents.
      if (parent === undefined) {
        parent = flatTreeDraft.find(item => item.tempId === parentId);
      }
      parent[key] = true;

      const index = parent.children.findIndex(child => child.id === nodeId);
      parent.children[index] = node;

      updateParentOnNodePublished(
        parent,
        parent.parent_id,
        parent.id,
        key,
        flatTreeDraft
      );
    }

    return node;
  };

  /**
   * Flattens the tree draft
   * @param {*} array
   */
  const flat = array => {
    var result = [];
    array.forEach(function(a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };

  /**
   * Recursively updates all values for a key in an object
   * @param {*} object
   * @param {*} value
   */
  const updateObject = (object, key, value) => {
    if (object) {
      object[key] = value;
      if (object.children) {
        for (var i = 0, l = object.children.length; i < l; ++i) {
          var child = object.children[i];
          child[key] = value;
          updateObject(child, key, value);
        }
      }
    }
  };

  /**
   * Calls upon save callback
   * @returns {*}
   */
  const save = () => onSave(node, draft.path);

  /**
   * If a title isn't set, disable save button
   * @returns {boolean}
   */
  const disableSave = () => node.title.length < 1;

  /**
   * Renders button group
   * @returns {*}
   */
  const renderButtonGroup = () =>
    !disabled ? (
      <ButtonGroup>
        <PrimaryButton onClick={save} disabled={disableSave()}>
          OK
        </PrimaryButton>
        <CancelButton onClick={onCancel}>Avbryt</CancelButton>
      </ButtonGroup>
    ) : (
      <ButtonGroup>
        <PrimaryButton onClick={onCancel}>Stäng</PrimaryButton>
      </ButtonGroup>
    );

  return (
    <FormNodeWrapper>
      <FormRow>
        <Label>id: {node.id}</Label>
      </FormRow>

      <FormRow>
        <Label htmlFor="title">Titel: </Label>
        <Input
          id="title"
          name="Titel"
          value={node.title}
          onChange={updateDraft}
          target={"title"}
          disabled={disabled}
        />
      </FormRow>

      <FormRow>
        <Label htmlFor="description">Beskrivning: </Label>
        <TextArea
          id="description"
          name="Beskrivning"
          value={node.subtitle}
          onChange={updateDraft}
          target={"subtitle"}
          disabled={disabled}
        />
      </FormRow>

      <FormRow>
        <Label htmlFor="description">Är mål: </Label>
        <Checkbox
          target="is_goal"
          value={node.is_goal}
          onChange={updateDraft}
        />
        <Label htmlFor="description">Publicerad: </Label>
        <Checkbox
          target="is_published"
          value={node.is_published}
          onChange={updateIsPublishedForNode}
        />
        <Label htmlFor="description">Tabellträning: </Label>
        <Checkbox
          target="is_table"
          value={node.is_table}
          onChange={onToggleIsTable}
        />
        {node.is_table && (
          <SelectField
            value={node.table_category || ""}
            onChange={event => updateDraft(event, "table_category")}
            disabled={!node.is_table}
            label="Räknesätt"
            name="table_category"
            showValue
            options={tableCategoryOptions}
          />
        )}
      </FormRow>
      {renderButtonGroup()}
    </FormNodeWrapper>
  );
};

const mapStateToProps = state => {
  return {
    draft: state.Structure.get("nodeDraft").toJS(),
    treeDraft: state.Structure.getIn(["treeDraft", "tree"]).toJS()
  };
};

export default connect(mapStateToProps)(NodeEdit);
