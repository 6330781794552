import { generalErrorHandler } from "../../api";
import {
  fetchSeries,
  fetchSeriesStart,
  fetchSeriesSuccess,
  fetchSeriesFailed,
  fetchSeriesEnd,
  resetBeforeFilterSortChange,
  fetchSeriesDetails,
  fetchSeriesDetailsStart,
  fetchSeriesDetailsSuccess,
  fetchSeriesDetailsFailed,
  fetchSeriesDetailsEnd,
  fetchSeriesPermissionsSuccess,
  fetchSeriesWorkFlowSuccess
} from "../api/actions";
import { addFlash, mainNavigation, setSections, setTitle } from "../../shared";
import { call, put, select } from "redux-saga/effects";
import * as api from "../api/requests";
import {
  selectFetchLimit,
  selectSearchParams,
  selectPage,
  selectQuery
} from "../store/selectors";
import { ROUTE_SERIES, ROUTE_SERIES_DETAILS } from "../routes";
import { selectPrePath } from "../../store/selectors";
import {
  ENTITIES,
  SERIES,
  seriesTranslationErrorMessage
} from "../../constants";
import { gotoPage, nextPage, previousPage } from "../../posts/api/actions";

export function* onSeriesListRoute() {
  let page = yield select(selectPage);
  const params = yield select(selectQuery);

  if (params.page) {
    page = params.page;
  }

  const args = yield select(st => st.Series.get("args").toJS()) || {};
  const fetchLimit = yield select(selectFetchLimit);

  yield put(
    fetchSeries(args, {
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

export function* onFetchSeries(action) {
  let batchIndex = action.batchIndex;
  if (batchIndex === undefined) {
    batchIndex = yield select(st => st.Series.get("page"));
  }

  yield put(fetchSeriesStart());
  try {
    const result = yield call(
      api.listSeries,
      "series",
      action.searchParameters
    );
    yield put(
      fetchSeriesSuccess(
        result.data.data,
        result.data.metadata,
        action.searchParameters,
        batchIndex
      )
    );
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchSeriesFailed(error));
  } finally {
    yield put(fetchSeriesEnd());
  }
}

/**
 * Fetches list of posts for a specific page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} args
 * @param {*} params
 * @param {*} page
 */
function* fetchPage(args, params) {
  const fetchLimit = yield select(selectFetchLimit);
  const currentPage = yield select(selectPage);

  yield put(
    fetchSeries(args, {
      ...params,
      offset: fetchLimit * (currentPage - 1),
      num_result: fetchLimit
    })
  );
}

/**
 * Sort the list
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} param2
 */
export function* sortSeriesList(sort) {
  yield put(resetBeforeFilterSortChange());

  const searchParams = yield select(selectSearchParams);
  const params = { ...searchParams, sort: sort.sort };

  yield call(fetchPage, {}, params);
}

export function* onCreateSeries(data) {
  const {
    data: { logotype, image, ...rest }
  } = data;

  const serieData = {
    ...rest,
    ...(logotype.id && { logotypeMediaFileId: logotype.id }),
    ...(image.id && { imageMediaFileId: image.id })
  };

  try {
    const result = yield call(api.createSeries, serieData);
    yield put({
      type: ROUTE_SERIES_DETAILS,
      payload: { id: result.data.data }
    });

    yield put(addFlash(`En ny serie skapades`, "success"));
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* onSeriesDetailRoute(action) {
  yield put(fetchSeries());
  yield put(fetchSeriesDetails(action.payload));
}

export function* onFetchSeriesDetails(action) {
  if (action.payload.id !== "new") {
    yield put(fetchSeriesDetailsStart());
    try {
      const result = yield call(api.seriesDetails, action.payload.id);

      yield put(
        fetchSeriesDetailsSuccess(
          result.data.data,
          result.data.metadata,
          ENTITIES[SERIES]
        )
      );
      yield put(setTitle(result.data.data.entity.title));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchSeriesDetailsFailed(error));
    } finally {
      yield put(fetchSeriesDetailsEnd());
    }
  }
}

export function* onUpdateSeriesDetails(action) {
  const {
    data: { logotype, image, ...rest }
  } = action;

  const serieData = {
    ...rest,
    ...(logotype.id && { logotypeMediaFileId: logotype.id }),
    ...(image.id && { imageMediaFileId: image.id })
  };

  try {
    yield call(api.updateSeriesDetails, action.seriesEntityId, serieData);
    yield put(addFlash(`Serien är sparad!`, "success"));
    yield call(onFetchSeriesDetails, {
      payload: { id: action.seriesEntityId }
    });
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* onAddProductToSeries(action) {
  try {
    yield call(
      api.createRelationForSeries,
      action.productEntityId,
      action.seriesEntityId
    );
    yield call(onFetchSeriesDetails, {
      payload: { entityId: action.seriesEntityId }
    });
  } catch (error) {
    yield put(
      addFlash(`Något gick fel när produkten skulle läggas till.`, "error")
    );
  }
}

export function* onDeleteProductFromSeries(action) {
  try {
    yield call(
      api.deleteRelationFromSeries,
      action.productEntityId,
      action.seriesEntityId
    );
    yield call(onFetchSeriesDetails, {
      payload: { entityId: action.seriesEntityId }
    });
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* onDeleteSeries(action) {
  try {
    yield call(api.deleteSeries, { ids: action.postIds });
    yield put(
      addFlash(
        `${action.postIds.length} antal serier blev borttagna`,
        "success"
      )
    );
    yield put({
      type: ROUTE_SERIES,
      payload: {}
    });
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* onFilterSeries({ filter }) {
  yield put(resetBeforeFilterSortChange());
  const searchParams = yield select(selectSearchParams);
  const params = { ...searchParams, filter };
  if (params.search === "") {
    delete params.search;
  }
  if (params.filter === "all") {
    delete params.filter;
  }
  yield call(fetchPage, {}, params);
}

export function* applicationEnsureSeries() {
  const prePath = yield select(selectPrePath);
  if (prePath !== "series") {
    return;
  }
  yield put(setSections(mainNavigation));
  yield put(setTitle("Serier"));
}

export function* previousPageSaga(entityName) {
  const searchParams = yield select(selectSearchParams, entityName);
  yield put(previousPage(entityName));
  yield call(fetchPage, {}, searchParams);
}

export function* nextPageSaga(entityName) {
  const searchParams = yield select(selectSearchParams, entityName);
  yield put(nextPage(entityName));
  yield call(fetchPage, {}, searchParams);
}

export function* loadPageSaga(entityName, _, { page }) {
  const searchParams = yield select(selectSearchParams);
  yield put(gotoPage(entityName, page));
  yield call(fetchPage, {}, searchParams);
}

export function* onFetchSeriesPermissions() {
  try {
    const result = yield call(api.fetchPermissions);
    yield put(fetchSeriesPermissionsSuccess(result.data.permissions));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onFetchSeriesWorkflow() {
  try {
    const result = yield call(api.fetchWorkflow);
    yield put(fetchSeriesWorkFlowSuccess(result.data.series));
  } catch (error) {
    yield generalErrorHandler(error);
  }
}

export function* onPublishSeries(data) {
  try {
    const searchParams = yield select(selectSearchParams);
    yield call(api.publishSerie, data.postIds);
    yield call(fetchPage, {}, searchParams);
    yield put(addFlash(`Serien är nu publicerad`, "success"));
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* onUnpublishSeries(data) {
  try {
    const searchParams = yield select(selectSearchParams);
    yield call(api.unpublishSeries, data.postIds);
    yield call(fetchPage, {}, searchParams);
    yield put(addFlash(`Serien är nu avpublicerad`, "success"));
  } catch (error) {
    yield seriesErrorHandler(error)
  }
}

export function* onPublishPreviewSeries(data) {
  try {
    const searchParams = yield select(selectSearchParams);
    yield call(api.publishPreviewSeries, data.postIds);
    yield call(fetchPage, {}, searchParams);
    yield put(
      addFlash(`Serien är nu publicerad till förhandsgranskning`, "success")
    );
  } catch (error) {
    yield seriesErrorHandler(error);
  }
}

export function* seriesErrorHandler(error) {
  console.warn(error);
  const errorMessage = error.response && error.response.data.message;
  const matches = errorMessage && errorMessage.match(/\[(.*?)\]/);
  const id = Number(matches && matches[1]);
  const replaced = errorMessage.replace(id, "x");

  if (errorMessage) {
    const message = seriesTranslationErrorMessage(replaced);

    yield put(addFlash(message, "error"));
  } else {
    yield put(
      addFlash(
        "Ett oväntat fel uppstod med serier, försök igen senare",
        "error"
      )
    );
  }

  return false;
}
