import { createSelector } from "reselect";
import { format } from "../../date";
import { ROUTE_CLASSROOM } from "../routes";
import { sortSesamName } from "./helpers";

export const selectFetchLimit = state => state.Classroom.get("fetchLimit");

export const selectSearchParams = state =>
  state.Classroom.get("searchParameters").toJS();

export const selectPage = state => state.Classroom.get("page");

export const selectClassroomGroupTypes = state =>
  state.Classroom.get("grouptypes");

export const selectQuery = state => state.location.query || {};

export const getClassroomgroupTitles = createSelector(
  state => state.Classroom.get("list").toJS(),

  list => list.map(l => l.title).filter((v, i, a) => a.indexOf(v) === i)
);

export const getClassroomgroupList = createSelector(
  state => state.Classroom.get("list").toJS(),
  state => state.Classroom.get("grouptypes").toJS(),
  (list, types) =>
    list.map(l => {
      const typeObj = types.find(t => t.id === l.typeId);
      const type = typeObj.id === 1 ? "" : typeObj.type;
      const date = new Date(l.updatedAt);
      return { ...l, type: type, updatedAt: format(date) };
    })
);

export const selectClassroomGroupTypesList = state => {
  const types = state.Classroom.get("grouptypes").toJS();
  return types.map(t =>
    t.type === "no_feature" ? { ...t, type: "Standard" } : t
  );
};

export const selectClassroomGroup = createSelector(
  state => state.Classroom.get("selectedItem"),
  item => {
    if (item.products && item.products.length > 1) {
      const sortedProducts = [...item.products].sort(sortSesamName);
      return { ...item, products: sortedProducts };
    }
    return item;
  }
);

export const getProductsAvailableForClassroomGroup = state =>
  state.Product.get("list").toJS();

export const selectClassroomTitle = state =>
  state.location.routesMap[ROUTE_CLASSROOM].text || "";

export const selectClassroomProducts = createSelector(
  selectClassroomGroup,
  group => group.products && group.products.filter(p => !p.isTeacherGuide)
);

export const selectPreparedAssignmentProducts = createSelector(
  selectClassroomGroup,
  group => {
    return {
      products: group.products && group.products.filter(p => !p.isTeacherGuide),
      assignmentsdata: group.preparedAssignments || {}
    };
  }
);

export const selectPreparedAssignmentsForCurrentProduct = state => {
  const data = state.Classroom.get("preparedAssignmentsdata");
  return !data.data || data.data === "{}" ? [] : JSON.parse(data.data);
};

export const selectPreparedAssignmentPostData = state =>
  state.Classroom.get("preparedAssignmentsdata");

export const selectFormativeTestsProducts = createSelector(
  selectClassroomGroup,
  group => {
    const formativeTestsData = group.formativeTests || {};
    const products = formativeTestsData.products || [];

    return {
      products: products.map(p => ({
        id: p.sesam.name,
        postId: p.postId,
        sesam: p.sesam
      })),
      publishedAt: formativeTestsData.publishedAt || null,
      updatedAt: formativeTestsData.updatedAt || null
    };
  }
);

export const selectFormativeTestsForCurrentProduct = state =>
  state.Classroom.get("formativeTestsdata");

export const selectFormativeQuestionsToc = state =>
  state.Classroom.get("formativeQuestionsToc");

export const selectLocationPayload = state => state.location.payload;

export const selectFormativeQuestionDetails = state =>
  state.Classroom.get("formativeQuestionData");

export const selectFormativeQuestionsSelectedProduct = state =>
  state.Classroom.get("formativeQuestionsSelectedProduct");

export const selectStickersInClassroomgroup = createSelector(
  state => state.Classroom.get("stickers"),
  state => state.Classroom.get("permissions"),
  (stickers, permission) => {
    const list = stickers.stickerlist || [];
    return list.map(s => ({
      ...s,
      actions: {
        delete: {
          postType: "Classroom",
          postIds: [s.id]
        }
      },
      status: stickers.status,
      permissions: permission,
      workflowMap: {
        draft: ["delete"],
        modified: ["delete"]
      },
      type: "classroomgroup",
      askWhenDelete: true,
      updatedAt: stickers.updatedAt,
      publishedAt: stickers.publishedAt
    }));
  }
);

export const selectStickersPostId = state => {
  const item = state.Classroom.get("selectedItem");
  return item.stickersPost;
};

export const selectIsInClassroom = state =>
  state.location.pathname.indexOf("klassrum") >= 0;
