import { MARKERS } from "../../constants";

export const selectAdventureGroupId = state =>
  Number(state.location.payload.adventureGroupId);

export const selectAdventureId = state =>
  Number(state.location.payload.adventureId);

export const selectAdventureCardId = state =>
  Number(state.location.payload.adventureCardId);

/**
 * Selects current entityDraft from state
 * @param {*} state
 * @param {*} type
 * @returns {number}
 */
export const selectEntityDraft = (state, type) =>
  state[type].get("entityDraft");

export const selectJsonDraft = (state, type) => state[type].get("jsonDraft");

export const selectMarkers = (state, type) => state[type].get("markers");

export const selectColorThemesByColorCount = (state, id) => {
  const themes = state[MARKERS].get("colorThemes");
  const items = state[MARKERS].get("itemList");
  const itemsInTheGroup = items.filter(item => item.parent_id === id);
  const markerCount =
    itemsInTheGroup.size !== 0 && itemsInTheGroup.get(0).marker_count;
  return themes.filter(theme => theme.color_count === markerCount);
};

export const selectHeadGroups = state => state[MARKERS].get("headGroupList");

export const selectMarkerGroups = (state, id) => {
  const markerGroups = state[MARKERS].get("groupList");
  return markerGroups.filter(group => group.parent_id === id);
};

export const selectMarkerGroupItems = (state, id) => {
  const items = state[MARKERS].get("itemList");
  return items.filter(item => item.parent_id === id);
};

export const selectColorTheme = (state, markerGroup) => {
  const colorThemes = state[MARKERS].get("colorThemes");
  return colorThemes.filter(
    theme => theme.theme_id === markerGroup.colortheme_id
  );
};
