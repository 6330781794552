import styled from "styled-components";

export const StyledSortableUl = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: rgba(50, 77, 114, 0.1);
  padding: 5px;
`;

export const StyledSortableItemWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const StyledSortItemHead = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  position: relative;
`;

export const StyledExerciseRefContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledStageModal = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: absolute;
  padding: 20px;
  top: 100%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease 0s;
  list-style: none;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

export const StyledStageModalTitle = styled.div`
  font-weight: 600;
  padding-bottom: 10px;
  color: #333;
`;

export const StyledStageBubbleTitle = styled.div`
  font-weight: 600;
  padding: 10px 10px 10px 5px;
  color: white;
`;

export const StyledRemoveButtonContainer = styled.div`
  width: 22px;
  cursor: pointer;
  height: 22px;
`;

export const StyledExerciseRef = styled.div`
  color: #fff;
  font-size: 16px;
  display: flex;
`;

export const StyledItemGoalStatus = styled.div`
  height: auto;
  width: 15px;
  content: "";
  background: ${({ published }) => (published ? "#32CD32" : "#cecece")};
`;

export const StyledExerciseGoalsBubble = styled.div`
  display: flex;
  width: auto;
  height: 10px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 20px;
  margin: 1px;
  padding: 14px;
  align-items: center;

  background: ${({ color = "black" }) => color};
`;

export const StyledExerciseGoalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-left: auto;
  margin-right: 0;
`;

export const StyledStageDropDown = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-left: auto;
  margin-right: 0;
`;

export const StyledStageBubble = styled.div`
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 10px;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: dodgerblue;
  width: fit-content;
`;

export const StyledExerciseRefNumber = styled.span`
  font-weight: 700;
  padding: 1.5px;

  ${({ withPointer }) => (withPointer ? "cursor: pointer" : "")}
`;

export const StyledSortableItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  display: flex;
  border: 1px solid #cecece;
  padding: 10px;
  justify-content: space-between;
  background: ${({ isSelected }) => (isSelected ? "#cdf2e0" : "#FFF")};

  ${({ isEditMode }) => (isEditMode === 1 ? "cursor: pointer" : "")};
`;

export const StyledTitle = styled.div`
  font-weight: 900;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #777;
  }
`;

export const StyledButtonsSpacer = styled.div`
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonsSpacerLine = styled.div`
  height: 100%;
  width: 1px;
  background: #cecece;
`;

export const StyledPanelButtonsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #cecece;
  box-shadow: 0 1px 4px -2px;
  position: relative;
  z-index: 1;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
`;
