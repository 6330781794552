import { Column } from "react-virtualized";
import LabelRenderer from "../Renderer/Labels";

const LabelColumn = () => null;

LabelColumn.propTypes = Object.assign({}, Column.propTypes);
LabelColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: LabelRenderer,
  draggable: true
});

export default LabelColumn;
