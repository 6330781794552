import { createReducer } from "../../store/utils";
import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import {
  ACTION_FETCH_SERIES,
  ACTION_FETCH_SERIES_START,
  ACTION_FETCH_SERIES_SUCCESS,
  ACTION_FETCH_SERIES_END,
  ACTION_FETCH_SERIES_DETAILS_START,
  ACTION_FETCH_SERIES_DETAILS_SUCCESS,
  ACTION_FETCH_SERIES_DETAILS_END,
  ACTION_EDITING_CREATED_SERIES,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  FETCH_SERIES_PERMISSIONS_SUCCESS,
  FETCH_SERIES_WORKFLOW_SUCCESS,
} from "./actions";
import { actionCreator } from "../../shared";
import { GOTO_PAGE, NEXT_PAGE, PREVIOUS_PAGE } from "../../api";
import { ENTITIES, SERIES } from "../../constants";
import { modifyPermissionsArray } from "../../shared/Permissions";

const FETCH_LIMIT = 20;

export const requiredFields = {
  selectedItem: {},
  page: 1,
  buffer: {},
  list: [],
  filters: [],
  labels: [],
  tags: [],
  args: {},
  total: 0,
  fetchLimit: FETCH_LIMIT,
  fetching: false,
  searchParameters: {},
  isEdited: false,
};

const reducer = initialState => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {
    [ACTION_FETCH_SERIES](state, action) {
      return state.withMutations(st => {
        const stateSearchParameters = st.get("searchParameters").toJS();
        let searchParameters = action.searchParameters;
        if (
          stateSearchParameters &&
          stateSearchParameters.sort &&
          !action.searchParameters.sort
        ) {
          searchParameters = {
            ...action.searchParameters,
            sort: stateSearchParameters.sort
          };
        }
        st.set("searchParameters", new Map(searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [ACTION_FETCH_SERIES_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_SERIES_SUCCESS](state, action) {
      state = state.update("total", () => action.data.total);
      state = state.update("filters", () => action.data.filters);
      return state.update("list", () => new List(action.data.entities));
    },
    [ACTION_FETCH_SERIES_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_FETCH_SERIES_DETAILS_START](state) {
      return state.set("fetching", true).set("selectedItem", {});
    },
    [ACTION_FETCH_SERIES_DETAILS_SUCCESS](state, action) {
      return state.set("selectedItem", action.data.entity);
    },
    [ACTION_FETCH_SERIES_DETAILS_END](state) {
      return state.set("fetching", false);
    },
    [RESET_BEFORE_FILTER_SORT_CHANGE](state) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", _ => 1);
        st.update("buffer", () => new Map());
      });
    },
    [actionCreator(ENTITIES[SERIES], GOTO_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", _ => action.page);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _ => new List(bufferedList));
        }
      });
    },
    [actionCreator(ENTITIES[SERIES], NEXT_PAGE)](state) {
      return state.withMutations(st => {
        st.update("page", s => s + 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _ => new List(bufferedList));
        }
      });
    },
    [actionCreator(ENTITIES[SERIES], PREVIOUS_PAGE)](state) {
      return state.withMutations(st => {
        st.update("page", s => s - 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _ => new List(bufferedList));
        }
      });
    },
    [FETCH_SERIES_PERMISSIONS_SUCCESS](state, action) {
      return state.set("permissions", modifyPermissionsArray(action.data));
    },
    [FETCH_SERIES_WORKFLOW_SUCCESS](state, action) {
      return state.set("workflowMap", action.data);
    },
    [ACTION_EDITING_CREATED_SERIES](state, action) {
      return state.set("isEdited", action.data)
    }
  });
};

export default reducer;
