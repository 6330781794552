import styled from "styled-components";
import { SQUARE } from "../../../constants";

export const StyledInputTypeInputsContainer = styled.div`
  padding: 0 10px;
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  margin: 0 auto;
  grid-template-columns: ${({ setting }) =>
    setting === SQUARE ? "repeat(2,2fr)" : "repeat(4, 1fr)"};
`;

export const StyledInputTypeLabel = styled.label`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  grid-column: span 4;
`;

export const StyledRowContainer = styled.div`
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
`;
