import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import { GripHandle, Ellipsis } from "../../../shared/components/StudliIcons";
import { Item } from "../../../shared/components/DropDown";

import DropDown from "../../../shared/components/DropDown/DropDown";

import {
  translation,
  RIGHT,
  BLOCKARRAY,
  RESOURCE,
  HELP_RESOURCE,
  MEDIA_RESOURCE,
  IMAGE,
  MULTIIMAGE,
  TEXT,
  ABOUT,
  MATH_WORD,
  TIPS,
  AUDIO,
  VIDEO,
  HELP_IMAGE,
  HELP_AUDIO,
  HELP_VIDEO,
  AIDATEXT,
  IMAGESLIDER
} from "../../../constants";

const helpBlocks = [
  HELP_RESOURCE,
  ABOUT,
  MATH_WORD,
  TIPS,
  HELP_IMAGE,
  HELP_AUDIO,
  HELP_VIDEO
];

const isHelpBlock = type => helpBlocks.includes(type);

const getTypes = (resources, block) => {
  if (block.type === BLOCKARRAY) {
    return block.data
      .map(getTypes.bind(null, resources))
      .reduce((acc, val) => acc.concat(val), []);
  }
  if (block.type === RESOURCE || block.type === HELP_RESOURCE) {
    let id = block.data.id;
    try {
      return getTypes(resources, JSON.parse(resources[id].data)).reduce(
        (acc, val) => acc.concat(val),
        []
      );
    } catch (_) {
      // something went wrong, either the resource isn't loaded, or the resource is corrupt.
      // let the default return handle the error
    }
  }
  return [block.type];
};

const getRenderer = data => {
  const placeholderRenderer = {
    component: Placeholder,
    props: data
  };

  let type = data.type.toLowerCase();

  if (type === "title") {
    return null;
  }
  if (type === BLOCKARRAY) {
    return data.readOnly
      ? {
          component: ReadOnlyBlockArray,
          props: data
        }
      : {
          component: BlockArray,
          props: data
        };
  }
  if (type === RESOURCE) {
    return {
      component: Resource,
      props: data
    };
  }

  if (type === MEDIA_RESOURCE) {
    return {
      component: MediaResource,
      props: data
    };
  }

  if (type === HELP_RESOURCE) {
    return {
      component: HelpResource,
      props: data
    };
  }

  if (type === IMAGE && data.data && data.data.icons) {
    // handle old data structure where image and multiimage had the same type
    type = MULTIIMAGE;
  }

  let entityRenderer = {};
  data.plugins.forEach(entityRendererFn => {
    const renderer = entityRendererFn({ ...data, type });
    if (renderer) {
      entityRenderer = renderer;
    }
  });

  return entityRenderer.component ? entityRenderer : placeholderRenderer;
};

const _resource = (type, props) => {
  const {
    plugins,
    resources,
    data: { id },
    blockKey,
    actions
  } = props;
  const resource = resources[id];
  if (resource) {
    try {
      const resourcedata =
        typeof resource.data === "string"
          ? JSON.parse(resource.data)
          : resource.data;
      if (resourcedata) {
        if (resourcedata.type.toLowerCase() === BLOCKARRAY) {
          return (
            <ReadOnlyBlockArray
              key={blockKey}
              data={resourcedata.data}
              plugins={plugins}
              resources={resources}
              onChange={() => {}}
              readOnly={true}
              actions={actions}
            />
          );
        }
        return (
          <ReadOnlyBlock
            key={blockKey}
            {...resourcedata}
            plugins={plugins}
            resources={resources}
            onChange={() => {}}
            readOnly={true}
            actions={actions}
          />
        );
      }
    } catch (_) {
      /* Ignore errors, let default return handle any JSON parse errors */
    }
  }
  return (
    <div>
      {type} {id}
    </div>
  );
};

const Resource = _resource.bind(null, "RESOURCE");
const MediaResource = _resource.bind(null, "MEDIARESOURCE");
const HelpResource = _resource.bind(null, "HELPRESOURCE");

const Placeholder = props => {
  return <div className={props.type}>{props.type}</div>;
};

const executeAction = item => {
  if (item.action) {
    item.action();
  }
};

const getHeader = ({
  readOnly,
  type,
  data,
  blockKey,
  actions,
  resources,
  postType
}) => {
  if (readOnly || type.toLowerCase() === BLOCKARRAY) {
    return null;
  }

  let title = translation[type] || type;

  let menuItems = [
    { text: "Ta bort", action: actions.delete.bind(null, blockKey) }
  ];

  switch (type) {
    case AIDATEXT:
      break;
    case IMAGESLIDER:
      break;
    case RESOURCE:
    case HELP_RESOURCE:
      let resourceTypes = getTypes(resources, { data, type }).map(
        type => translation[type] || type
      );
      title = title + " (" + resourceTypes.join(", ") + ")";
      menuItems = [].concat(menuItems, [
        {
          text: "Gör om till komponent",
          action: actions.convertFromResource.bind(null, blockKey)
        }
      ]);
    // falls through
    case MEDIA_RESOURCE:
      menuItems = [].concat(menuItems, [
        { text: "Redigera", action: actions.editEntity.bind(null, blockKey) },
        { text: "Byt ut", action: actions.replaceBlock.bind(null, blockKey) }
      ]);
      break;
    case HELP_IMAGE:
    case HELP_AUDIO:
    case HELP_VIDEO:
    case ABOUT:
    case MATH_WORD:
    case TIPS:
      menuItems = [].concat(menuItems, [
        {
          text: "Gör om till hjälpresurs",
          action: actions.convertToResource.bind(null, blockKey)
        }
      ]);
      break;
    case TEXT:
    case IMAGE:
    case AUDIO:
    case VIDEO:
      if (postType !== "aida") {
        menuItems = [].concat(menuItems, [
          {
            text: "Gör om till resurs",
            action: actions.convertToResource.bind(null, blockKey)
          }
        ]);
      }
      break;
    default: // not required
  }

  /**
   * Render drop down items
   * @returns {any[]}
   */
  const renderDropDownItems = () =>
    menuItems.map((item, i) => (
      <Item key={i} value={item}>
        {item.text}
      </Item>
    ));

  /**
   *  Renders drop down
   */
  const renderDropDown = () => (
    <DropDown
      title={<Ellipsis size={16} />}
      hasChevron={false}
      defaultValue=""
      onChange={executeAction}
      dropDownAlign={RIGHT}
      overrideStyles={{
        right: 0,
        display: "inline-block",
        minWidth: "inherit"
      }}
    >
      {renderDropDownItems()}
    </DropDown>
  );

  const blockClass = isHelpBlock(type)
    ? "block__header block__header--alt-color"
    : "block__header";
  return (
    <div className={blockClass}>
      <DragHandle />
      <h5 className="Heading">{title}</h5>

      {renderDropDown()}
    </div>
  );
};

const DragHandle = SortableHandle(() => <GripHandle size={16} />);

export const Block = SortableElement(props => {
  const renderer = getRenderer(props);
  const {
    blockKey,
    actions,
    onChange,
    resources,
    registerValidator,
    unregisterValidators,
    readOnly,
    storeDraft,
    draftTarget,
    infoFlash,
    keyboards
  } = props;

  if (readOnly) {
    return renderer ? (
      <renderer.component
        blockKey={blockKey}
        {...renderer.props}
        resources={resources}
        draftTarget={draftTarget}
        storeDraft={storeDraft}
        infoFlash={infoFlash}
        registerValidator={registerValidator}
        unregisterValidators={unregisterValidators}
        actions={actions}
        onChange={onChange}
        keyboards={keyboards}
      />
    ) : null;
  }

  const header = getHeader(props);

  return renderer ? (
    <li className="block">
      {header}
      <div className="block__content" style={renderer.style}>
        <renderer.component
          blockKey={blockKey}
          {...renderer.props}
          registerValidator={registerValidator}
          unregisterValidators={unregisterValidators}
          resources={resources}
          actions={actions}
          onChange={onChange}
          draftTarget={draftTarget}
          storeDraft={storeDraft}
          infoFlash={infoFlash}
          keyboards={keyboards}
        />
      </div>
    </li>
  ) : null;
});

export const BlockArray = SortableContainer(props => {
  const {
    data,
    plugins,
    onChange,
    actions,
    resources,
    registerValidator,
    unregisterValidators,
    readOnly,
    storeDraft,
    draftTarget,
    infoFlash,
    keyboards,
    postType
  } = props;

  return (
    <ul style={{ paddingLeft: 0 }}>
      {data.map((block, index) => (
        <Block
          key={block.blockKey}
          index={index}
          {...block}
          plugins={plugins}
          storeDraft={storeDraft}
          draftTarget={draftTarget}
          resources={resources}
          registerValidator={registerValidator}
          unregisterValidators={unregisterValidators}
          onChange={onChange}
          actions={actions}
          readOnly={readOnly}
          infoFlash={infoFlash}
          keyboards={keyboards}
          postType={postType}
        />
      ))}
    </ul>
  );
});

const ReadOnlyBlockArray = props => {
  const { data, plugins, onChange, actions, resources } = props;

  data.map(index => {
    if (index.settings === undefined) {
      index.settings = {};
    }
    return (index.settings.readOnly = props.readOnly);
  });

  return (
    <ul style={{ paddingLeft: 0 }}>
      {data.map((block, index) => (
        <ReadOnlyBlock
          key={block.blockKey || index}
          index={index}
          {...block}
          plugins={plugins}
          resources={resources}
          onChange={onChange}
          actions={actions}
        />
      ))}
    </ul>
  );
};

const ReadOnlyBlock = props => {
  const renderer = getRenderer(props);
  const { blockKey, actions, onChange, resources } = props;
  return renderer ? (
    <renderer.component
      blockKey={blockKey}
      {...renderer.props}
      resources={resources}
      actions={actions}
      onChange={onChange}
      readOnly={true}
    />
  ) : null;
};
