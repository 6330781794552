import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Card from "../../components/Card";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import PublishButton from "../../components/PublishButton";
import PreviewButton from "../../components/PreviewButton";
import PublishItem from "../../components/PublishItem";
import { selectEntityId } from "../../../store/selectors";
import {
  selectProductFilesStatus,
  selectPublishingStatus,
  selectProductFilePublishHistory
} from "../../store/selectors";
import Button from "../../../shared/components/Button";
import Modal from "../../../shared/components/Modal/Modal";
import PublishHistory from "../../components/PublishHistory";
import {
  publishProductFiles,
  fetchProductPublishHistory,
  startProductBuildStatusWatcher,
  stopProductBuildStatusWatcher,
  fetchProductPublishHistorySuccess,
  fetchProductPublishFilesStatusSuccess
} from "../../api/actions";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 100%;
`;

const StyledLine = styled.div`
  width: 390px;
  height: 1px;
  border: 1px solid #a9a9a9;
  margin-top: 20px;
  position: absolute;
  left: 100px;
`;

const StyledPublishViewWrapper = styled.div`
  height: 150px;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 35px;
`;

const StyledButton = styled(Button)`
  margin: 0;
  width: 125px;
`;

const StyledModalContent = styled.div`
  width: 630px;
  height: 520px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 1.7rem;
  border-radius: 7px;
  position: relative;
`;

const PublishCard = () => {
  const [isPublishingToTest, setIsPublishingToTest] = useState(false);
  const [isPublishingToProd, setIsPublishingToProd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const entityId = useSelector(selectEntityId);
  const fileStatus = useSelector(selectProductFilesStatus);
  const publishingStatus = useSelector(selectPublishingStatus);
  const pubHistory = useSelector(selectProductFilePublishHistory);

  useEffect(() => {
    setIsPublishingToProd(publishingStatus.toProd);
    setIsPublishingToTest(publishingStatus.toPreview);
  }, [publishingStatus]);

  // Start polling of product build status, and stop when card is removed
  useEffect(() => {
    dispatch(startProductBuildStatusWatcher());
    return () => {
      dispatch(stopProductBuildStatusWatcher());
    };
  }, [dispatch]);

  // If product changes, close modal and empty history
  useEffect(() => {
    setIsModalOpen(false);
    dispatch(fetchProductPublishHistorySuccess(null));
    dispatch(fetchProductPublishFilesStatusSuccess({}));
  }, [entityId, dispatch]);

  const closeModal = () => setIsModalOpen(false);

  const publishToTest = () => {
    if (!isPublishingToTest) {
      setIsPublishingToTest(true);
      dispatch(publishProductFiles(entityId, "preview"));
    }
  };

  const publishToLive = () => {
    if (!isPublishingToProd) {
      setIsPublishingToProd(true)
      dispatch(publishProductFiles(entityId, "prod"));
    }
    
  };

  const gotoPreview = () => {
    if (fileStatus.preview && fileStatus.preview.url) {
      window.open(fileStatus.preview.url, "product_preview");
    }
  };

  const onHistoryButtonClick = () => {
    dispatch(fetchProductPublishHistory(entityId));
    setIsModalOpen(true);
  };

  const getStatus = type => {
    if (!fileStatus[type]) {
      return { date: null, pubstate: "nostatus" };
    }
    // special case for preview state according to SKL-7453, SKL-6732
    // if preview is modified and prod is not published, show preview as published
    if (type === "preview") {
      const prevState = fileStatus.preview.pubstate
      const prevStatus = fileStatus.preview.status
      if (prevState === "unpublished" && prevStatus !== "failed") {
        const prodState = fileStatus.prod && fileStatus.prod.pubstate
        if (prodState !=="published") {
          return { date: fileStatus.preview.date, pubstate: "published" }
        }
      }
    }
    return fileStatus[type];
  };

  const disabled = false;

  return (
    <Card title="xpub/dill">
      <Container>
        <StyledPublishViewWrapper>
          <StyledLine />
          <PublishItem type="campus" status={getStatus("campus")} />
          <PublishItem type="preview" status={getStatus("preview")} />
          <PublishItem type="prod" status={getStatus("prod")} />
        </StyledPublishViewWrapper>
      </Container>
      <Container>
        <ButtonWrapper>
          <ButtonGroup>
            <PublishButton
              toLive={false}
              onClick={publishToTest}
              disabled={disabled}
              loading={isPublishingToTest}
            />
            <PreviewButton onClickPreview={gotoPreview} />
          </ButtonGroup>
          <ButtonGroup>
            <StyledButton outline skin="DM" onClick={onHistoryButtonClick}>
              Historik
            </StyledButton>
            <PublishButton
              toLive={true}
              onClick={publishToLive}
              disabled={disabled}
              loading={isPublishingToProd}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Container>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          <PublishHistory close={closeModal} history={pubHistory} />
        </StyledModalContent>
      </Modal>
    </Card>
  );
};

export default PublishCard;
