import React, { useState } from "react";
import {
  SideBarContainer,
  SideBarButton,
  SideBarWrapper,
  SideBarMargin,
  StyledSideBarWrapper
} from "./StyledSideBar";
import { ChevronLeft, ChevronRight } from "../../components/StudliIcons";
import { useDispatch, useSelector } from "react-redux";
import { collapse, open } from "../../store/navigation/actions";
import { selectNavMenuState } from "../../store/navigation/selectors";

const SideBar = ({ children }) => {
  const [isMouseOverMenu, setIsMouseOverMenu] = useState(false);

  const handleMouseEnter = e => {
    if (collapsed) {
      setIsMouseOverMenu(true);
      openNav(true);
    }
  };

  const handleMouseLeave = e => {
    if (isMouseOverMenu) {
      closeNav(true);
      setTimeout(() => setIsMouseOverMenu(false), 220);
    }
  };

  const dispatch = useDispatch();
  const { collapsed, byMouseOver } = useSelector(selectNavMenuState);

  const openNav = mouseOver => dispatch(open(mouseOver));
  const closeNav = mouseOver => dispatch(collapse(mouseOver));

  return (
    <StyledSideBarWrapper>
      <SideBarMargin byMouseOver={byMouseOver} collapsed={collapsed} />
      <SideBarWrapper>
        <SideBarContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </SideBarContainer>
        <SideBarButton
          id="BBB"
          collapsed={collapsed}
          onClick={() => (collapsed ? openNav(false) : closeNav(false))}
        >
          {!collapsed ? <ChevronLeft size="10" /> : <ChevronRight size="10" />}
        </SideBarButton>
      </SideBarWrapper>
    </StyledSideBarWrapper>
  );
};

export default SideBar;
