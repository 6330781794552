import Media from "./views/Media";

export const ROUTE_MEDIA = "ROUTE_MEDIA";
export const ROUTE_AIDA_MEDIA = "ROUTE_AIDA_MEDIA";
export const ROUTE_OMNIA_MEDIA = "ROUTE_OMNIA_MEDIA";

export const routes = {
  [ROUTE_MEDIA]: {
    name: "Media",
    path: "/products/:entityId/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH", "MEDIATYPES", "MEDIALABELS", "MEDIATAGS"],
    text: "Media",
    icon: "Media"
  },
  [ROUTE_AIDA_MEDIA]: {
    name: "Media",
    path: "/system/:entityId/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH", "MEDIATYPES", "MEDIATAGS"],
    tagsByTypeOnly: ["aida_system"],
    uploadBasePath: "/aida",
    text: "Media",
    icon: "Media"
  },
  [ROUTE_OMNIA_MEDIA]: {
    name: "Media",
    path: "/system/:entityId/omnia/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH", "MEDIATYPES", "MEDIATAGS"],
    tagsByTypeOnly: ["omnia"],
    uploadBasePath: "/omnia",
    text: "Media",
    icon: "Media"
  }
};

export const views = {
  [ROUTE_MEDIA]: Media,
  [ROUTE_AIDA_MEDIA]: Media,
  [ROUTE_OMNIA_MEDIA]: Media
};
