import React from "react";

const Sliders = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-sliders"
  >
    <path d="M49.21875,37.375 L18.75,37.375 L18.75,33.46875 C18.75,32.1699219 17.7050781,31.125 16.40625,31.125 L11.71875,31.125 C10.4199219,31.125 9.375,32.1699219 9.375,33.46875 L9.375,37.375 L0.78125,37.375 C0.3515625,37.375 0,37.7265625 0,38.15625 L0,39.71875 C0,40.1484375 0.3515625,40.5 0.78125,40.5 L9.375,40.5 L9.375,44.40625 C9.375,45.7050781 10.4199219,46.75 11.71875,46.75 L16.40625,46.75 C17.7050781,46.75 18.75,45.7050781 18.75,44.40625 L18.75,40.5 L49.21875,40.5 C49.6484375,40.5 50,40.1484375 50,39.71875 L50,38.15625 C50,37.7265625 49.6484375,37.375 49.21875,37.375 Z M15.625,43.625 L12.5,43.625 L12.5,34.25 L15.625,34.25 L15.625,43.625 Z M49.21875,9.25 L25,9.25 L25,5.34375 C25,4.04492188 23.9550781,3 22.65625,3 L17.96875,3 C16.6699219,3 15.625,4.04492188 15.625,5.34375 L15.625,9.25 L0.78125,9.25 C0.3515625,9.25 0,9.6015625 0,10.03125 L0,11.59375 C0,12.0234375 0.3515625,12.375 0.78125,12.375 L15.625,12.375 L15.625,16.28125 C15.625,17.5800781 16.6699219,18.625 17.96875,18.625 L22.65625,18.625 C23.9550781,18.625 25,17.5800781 25,16.28125 L25,12.375 L49.21875,12.375 C49.6484375,12.375 50,12.0234375 50,11.59375 L50,10.03125 C50,9.6015625 49.6484375,9.25 49.21875,9.25 Z M21.875,15.5 L18.75,15.5 L18.75,6.125 L21.875,6.125 L21.875,15.5 Z M49.21875,23.3125 L40.625,23.3125 L40.625,19.40625 C40.625,18.1074219 39.5800781,17.0625 38.28125,17.0625 L33.59375,17.0625 C32.2949219,17.0625 31.25,18.1074219 31.25,19.40625 L31.25,23.3125 L0.78125,23.3125 C0.3515625,23.3125 0,23.6640625 0,24.09375 L0,25.65625 C0,26.0859375 0.3515625,26.4375 0.78125,26.4375 L31.25,26.4375 L31.25,30.34375 C31.25,31.6425781 32.2949219,32.6875 33.59375,32.6875 L38.28125,32.6875 C39.5800781,32.6875 40.625,31.6425781 40.625,30.34375 L40.625,26.4375 L49.21875,26.4375 C49.6484375,26.4375 50,26.0859375 50,25.65625 L50,24.09375 C50,23.6640625 49.6484375,23.3125 49.21875,23.3125 Z M37.5,29.5625 L34.375,29.5625 L34.375,20.1875 L37.5,20.1875 L37.5,29.5625 Z" />
  </svg>
);

export default Sliders;
