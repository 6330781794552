import React from "react";
import styled from "styled-components";
import { StyledQuestionsWrapper, StyledColumn } from "./StyledFormativeTests";
import IconButton from "../../../products/components/IconButton";
import { Plus, Trash2 } from "react-feather";
import Link from "../../../shared/components/Link";
import { useSelector } from "react-redux";
import { selectCurrentPath } from "../../../store/selectors";

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 0.5rem;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 0.3rem 0;
`;

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
  button {
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
  color: #0078d7;
  text-decoration: underline;
`;

const StyledPlusIcon = styled(Plus)`
  color: #0078d7;
`;
const iconSize = "20px";

const FormativeQuestionsToc = ({
  onDeleteQuestion,
  addNewQuestion,
  disabled,
  questions
}) => {
  const path = useSelector(selectCurrentPath);
  return (
    <StyledColumn>
      <ToolbarWrapper>
        <StyledTitle>Frågor</StyledTitle>
        <IconButton
          disabled={disabled}
          aria-label="Lägg till"
          onClick={() => addNewQuestion()}
        >
          <StyledPlusIcon height={iconSize} width={iconSize} />
        </IconButton>
      </ToolbarWrapper>

      <StyledQuestionsWrapper>
        {questions &&
          questions.map(q => (
            <QuestionWrapper key={q.postId}>
              <IconButton
                aria-label="Ta bort från lista"
                onClick={() => onDeleteQuestion(q.postId)}
              >
                <Trash2 width={iconSize} height={iconSize} />
              </IconButton>
              <StyledLink to={`${path}/fraga/${q.postId}/edit`}>
                {q.title}
              </StyledLink>
            </QuestionWrapper>
          ))}
      </StyledQuestionsWrapper>
    </StyledColumn>
  );
};

export default FormativeQuestionsToc;
