import { connectRoutes } from "redux-first-router";
import queryString from "querystring";
import {
  CLOSE_CONFIRM_MODAL,
  OPEN_CONFIRM_MODAL,
  RouterService
} from "../shared";
import { store } from "../../src/index";

const options = {
  displayConfirmLeave: (message, callback) => {
    const canLeave = can => {
      store.dispatch({ type: CLOSE_CONFIRM_MODAL });
      return callback(can);
    };

    store.dispatch({
      type: OPEN_CONFIRM_MODAL,
      msg: message,
      action: canLeave
    });
  },
  querySerializer: queryString,
  initialDispatch: false
};

const createRoutes = () => connectRoutes(RouterService.getRouteMap(), options);

export default createRoutes;
