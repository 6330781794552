import { FETCH_ENTITY_DETAILS_SUCCESS } from "../../../api";
import { selectCurrentPost } from "../../api/selectors";
import { fetchEntityDetailsSuccessfullyPrepped } from "../../api/actions";
import { takeEvery, all, fork, put, select } from "redux-saga/effects";
import { ENTITIES, LOCKCODES } from "../../../constants";
import {
  RESET_ENTITY_DRAFT,
  SAVE_ENTITY_DRAFT,
  setEntityDraft
} from "./actions";
import {
  createSaveableObjectFromEntityDraft,
  createSaveableMessageObjectFromDraft,
  isMessageEntity,
  prepareEntityDraft,
  prepareMessageDraft
} from "./helpers";
import {
  actionCreatePost,
  actionSavePost
} from "../../store/actions";
import { actionCreator } from "../../../shared";
import { selectEntityDraft } from "./selectors";
import { actionUpdateLock, UPDATE_LOCK } from "../Locks/actions";

/**
 * Fetches the entity keys which should have an editor
 * @param action
 * @returns {string[]}
 */
const getEditorKeys = action =>
  Object.keys(ENTITIES).map(ENTITY => actionCreator(ENTITY, action));

/**
 * Saga for watching prepping of newly fetched post data for the frontend
 */
function* watchPrepSelectedItem() {
  yield takeEvery(
    getEditorKeys(FETCH_ENTITY_DETAILS_SUCCESS),
    makePrepSelectedItem
  );
}

/**
 * Saga for making preparations of newly fetched post data for the frontend
 * @param type
 * @param args
 * @param entity
 * @param entityName
 */
function* makePrepSelectedItem({ type, args, entity, entityName }) {
  yield put(fetchEntityDetailsSuccessfullyPrepped(entityName, entity, args));
  const preparedEntityDraft = isMessageEntity(entityName)
    ? prepareMessageDraft(entity)
    : prepareEntityDraft(entity);
  yield put(setEntityDraft(entityName, preparedEntityDraft));
}

/**
 * Saga for watching for resetting requests for post to it's previously saved state
 */
function* watchResetEntityDraft() {
  yield takeEvery(getEditorKeys(RESET_ENTITY_DRAFT), makeResetPostDraft);
}

/**
 * Saga for resetting a post to it's previously saved state
 */
function* makeResetPostDraft({ entityType }) {
  const post = yield select(selectCurrentPost, entityType);
  if (post) {
    yield put(setEntityDraft(entityType, prepareEntityDraft(post.toJS())));
    yield put(actionUpdateLock(null, entityType));
  }
}

/**
 * Saga for watching lock changes
 */
function* watchUpdateLock() {
  yield takeEvery(getEditorKeys(UPDATE_LOCK), makeResetOnUpdateLock);
}

/**
 * Saga for resetting a post to it's previously saved state if the lock is owned by another user
 */
function* makeResetOnUpdateLock({ lock, entityType, postId }) {
  if (
    lock &&
    (lock.code === LOCKCODES.LockedByOther || lock.code === LOCKCODES.NotLocked)
  ) {
    const selectedPost = yield select(selectCurrentPost, entityType);
    const post = selectedPost && selectedPost.toJS();

    if (post && post.data) {
      const { id } = post.data;

      if (postId === id) {
        yield put(setEntityDraft(entityType, prepareEntityDraft(post)));
      }
    }
  }
}

/**
 * 'Watches request for saving the post
 */
function* watchSavePost() {
  yield takeEvery(getEditorKeys(SAVE_ENTITY_DRAFT), makeSavePost);
}

/**
 * Saves the post
 */
function* makeSavePost({ entityType }) {
  try {
    const entityDraft = yield select(selectEntityDraft, entityType);
    const data = isMessageEntity(entityType)
      ? createSaveableMessageObjectFromDraft(entityDraft)
      : createSaveableObjectFromEntityDraft(entityDraft);

    entityDraft.get("id")
      ? yield put(actionSavePost(data, entityDraft.get("id"), entityType))
      : yield put(actionCreatePost(data, entityType));

  } catch (e) {
    console.log(e);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchPrepSelectedItem),
    fork(watchResetEntityDraft),
    fork(watchSavePost),
    fork(watchUpdateLock)
  ]);
}
