import React, { useState } from "react";
import SeriesList from "../../containers/SeriesList";
import AddSeries from "../../components/AddSeries/AddSeries";
import FilterSeriesList from "../../components/FilterSeriesList/FilterSeriesList";
import SeriesActions from "../../components/SeriesActions/SeriesActions";
import styled from "styled-components";

const StyledButtonGroup = styled.div`
  display: flex;
`;

const Series = () => {
  const [selected, setSelected] = useState([]);

  return (
    <React.Fragment>
      <StyledButtonGroup>
        <AddSeries />
        {selected.length !== 0 && <SeriesActions selected={selected} />}
      </StyledButtonGroup>
      <FilterSeriesList />
      <SeriesList setSelected={setSelected} />
    </React.Fragment>
  );
};

export default Series;
