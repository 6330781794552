import React from "react";

const Optimus = ({ size, color }) => (
  <svg
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-optimus"
  >
  <path d="M15,11 L14.32,11 L12.32,13 L14.23,13 L16,15 L2,15 L3.78,13 L5.83,13 L3.83,11 L3,11 L0,14 L0,18 C0,19.1 0.89,20 1.99,20 L16,20 C17.1,20 18,19.11 18,18 L18,14 L15,11 Z M14,5.95 L9.05,10.9 L5.51,7.36 L10.46,2.41 L14,5.95 Z M9.76,0.29 L3.39,6.66 C3,7.05 3,7.68 3.39,8.07 L8.34,13.02 C8.73,13.41 9.36,13.41 9.75,13.02 L16.11,6.66 C16.5,6.27 16.5,5.64 16.11,5.25 L11.16,0.3 C10.78,-0.1 10.15,-0.1 9.76,0.29 Z" id="path-1"></path>
</svg>
);

export default Optimus;
