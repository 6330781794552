import React from "react";

const EllipsisV = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-ellipsisv"
  >
    <path d="M25.2631579,19.7368421 C28.1743421,19.7368421 30.5263158,22.0888158 30.5263158,25 C30.5263158,27.9111842 28.1743421,30.2631579 25.2631579,30.2631579 C22.3519737,30.2631579 20,27.9111842 20,25 C20,22.0888158 22.3519737,19.7368421 25.2631579,19.7368421 Z M20,5.26315789 C20,8.17434211 22.3519737,10.5263158 25.2631579,10.5263158 C28.1743421,10.5263158 30.5263158,8.17434211 30.5263158,5.26315789 C30.5263158,2.35197368 28.1743421,0 25.2631579,0 C22.3519737,0 20,2.35197368 20,5.26315789 Z M20,44.7368421 C20,47.6480263 22.3519737,50 25.2631579,50 C28.1743421,50 30.5263158,47.6480263 30.5263158,44.7368421 C30.5263158,41.8256579 28.1743421,39.4736842 25.2631579,39.4736842 C22.3519737,39.4736842 20,41.8256579 20,44.7368421 Z" />
  </svg>
);

export default EllipsisV;
