import React from "react";
import { connect } from "react-redux";
import { selectMarkers, selectHeadGroups } from "../../sagas/selectors";
import MarkerHeadGroup from "./MarkerHeadGroup";
import {
  StyledExpandAllButtonsContainer,
  StyledAccordionWrapper,
  StyledExpandAllButton
} from "./StyledMarkerTree";
import { MARKERS, PUBLISH } from "../../../constants";
import { expandTree, publishMarkers } from "../api/actions";
import { isLoading } from "../../store/selectors";
import { StyledLoaderWrapper } from "../../components/StyledList";
import { Loader } from "../../../shared/components/Loader/Loader";
import HasPermission from "../../../shared/Permissions";

const MarkerTree = (props) => {

  const renderMarkerAccordion = (item) => (
    <StyledAccordionWrapper key={item.id}>
      {<MarkerHeadGroup data={item} type={MARKERS} />}
    </StyledAccordionWrapper>
  )

  /**
   * Renders loader while loading is true
   */
  const renderLoader = () => (
    <StyledLoaderWrapper>
      <Loader dotColor={'black'} />
    </StyledLoaderWrapper>
  )


  const publishMarkers = () => {
    props.onPublish(MARKERS);
  };

  const renderPublishButton = () => (
    <StyledExpandAllButton onClick={publishMarkers}>
      Publicera
    </StyledExpandAllButton>
  );

  return props.loading
    ? renderLoader()
    : (
      <React.Fragment>
        <StyledExpandAllButtonsContainer>
          <StyledExpandAllButton onClick={() => props.expandMarkerTree(MARKERS, true)}>Expandera alla</StyledExpandAllButton>
          <StyledExpandAllButton onClick={() => props.expandMarkerTree(MARKERS, false)}>Kollapsa alla</StyledExpandAllButton>
          <HasPermission
            component={renderPublishButton()}
            section={MARKERS.toLowerCase()}
            permission={PUBLISH}
          />
        </StyledExpandAllButtonsContainer>
        {props.headGroups.map(item => renderMarkerAccordion(item))}
      </React.Fragment>
    )
};
const mapStateToProps = (state, { type }) => {
  return {
    loading: isLoading(state, type),
    markers: selectMarkers(state, type),
    headGroups: selectHeadGroups(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    expandMarkerTree: (type, param) => dispatch(expandTree(type, param)),
    onPublish: (type) => dispatch(publishMarkers(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerTree)



