import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  ACTION_FETCH_PRODUCTLIST_START,
  ACTION_FETCH_PRODUCTLIST_SUCCESS,
  ACTION_FETCH_PRODUCTLIST_END,
  ACTION_PRODUCTLIST_GOTOPAGE,
  ACTION_PRODUCTLIST_NEXTPAGE,
  ACTION_PRODUCTLIST_PREVPAGE,
  ACTION_FETCH_PRODUCTLIST,
  ACTION_RESET_BEFORE_FILTER_SORT_CHANGE,
  ACTION_SET_FETCH_LIMIT_SUCCESS
} from "./actions";

const FETCH_LIMIT = 50;

export const requiredFields = {
  selectedItem: {},
  active: {},
  page: 1,
  filters: [],
  list: [],
  args: {},
  fetching: false,
  saving: false,
  total: 0,
  fetchLimit: FETCH_LIMIT,
  searchParameters: {sort: { sesam_name: 'ASC' }},
  buffer: {}
};

const reducer = initialState => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {
    [ACTION_FETCH_PRODUCTLIST_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_PRODUCTLIST](state, action) {
      return state.withMutations(st => {
        const stateSearchParameters = st.get("searchParameters").toJS();
        let searchParameters = action.searchParameters;
        if (
          stateSearchParameters &&
          stateSearchParameters.sort &&
          !action.searchParameters.sort
        ) {
          searchParameters = {
            ...action.searchParameters,
            sort: stateSearchParameters.sort
          };
        }

        st.set("searchParameters", new Map(searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [ACTION_FETCH_PRODUCTLIST_SUCCESS](state, action) {
      state = state.update("total", () => action.metadata.total);
      if (action.batchIndex === state.get("page")) {
        state = state.update("list", _list => new List(action.data));
      }
      return state.update("buffer", buffer =>
        buffer.set(action.batchIndex, new List(action.entities))
      );
    },
    [ACTION_FETCH_PRODUCTLIST_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_PRODUCTLIST_GOTOPAGE](state, action) {
      return state.withMutations(st => {
        st.update("page", _s => action.page);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _list => new List(bufferedList));
        }
      });
    },
    [ACTION_PRODUCTLIST_NEXTPAGE](state) {
      return state.withMutations(st => {
        st.update("page", s => s + 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _list => new List(bufferedList));
        }
      });
    },
    [ACTION_PRODUCTLIST_PREVPAGE](state) {
      return state.withMutations(st => {
        st.update("page", s => s - 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", _list => new List(bufferedList));
        }
      });
    },
    [ACTION_RESET_BEFORE_FILTER_SORT_CHANGE](state, _action) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", _s => 1);
        st.update("buffer", () => new Map());
      });
    },
    [ACTION_SET_FETCH_LIMIT_SUCCESS](state, action) {
      return state.set("fetchLimit", Number(action.limit));
    }
  });
};

export default reducer;
