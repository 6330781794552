import React from "react";
import { StyledNodeStandardBtn } from "./StyledStructureTree";

export const TreeExpansionOptBtn = ({ callback, expandOpt, icon, ...rest }) => {
  const onClick = e => {
    e.stopPropagation();
    callback(expandOpt);
  };

  return (
    <StyledNodeStandardBtn onClick={onClick} {...rest}>
      {icon}
    </StyledNodeStandardBtn>
  );
};
