import React from "react";
import { CircleSolid } from "../../../../shared/components/StudliIcons";
import {
  FILE_NOT_PUBLISHED
} from "../../../../constants";
import Tooltip from "react-tooltip-lite";
import { FILE_STATUS_COLOR, FILE_STATUS } from "../../../api/constants";
import styled from "styled-components";

export const StyledStatusWrapper = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
`;

export const StyledIcon = styled.svg`
  padding-top: 2px;
  fill: ${({ theme, color }) => theme[color]};
  height: -webkit-fill-available;
`;

const FileStatusRenderer = ({ rowData: { status } }) => {
  const studliSize = 25;

  const getStatusColor = () => FILE_STATUS_COLOR[status] || FILE_STATUS_COLOR[FILE_NOT_PUBLISHED];
  
  /**
   * Renders icons
   * @param themeColor
   * @returns {*}
   */
  const renderIcon = themeColor => (
    <StyledIcon color={themeColor}>
      <CircleSolid size={studliSize} />
    </StyledIcon>
  );

  const statusContent = FILE_STATUS.find(item => item.id === status);
  
  return (
    status && (
      <StyledStatusWrapper>
        <Tooltip
          content={(statusContent && statusContent.title) || "okänd status"}
          useDefaultStyles={true}
        >
          {renderIcon(getStatusColor())}
        </Tooltip>
      </StyledStatusWrapper>
    )
  );
};

export default FileStatusRenderer;
