export const formativeTestsExample = {
  products: [
    {
      sesam: {
        name: "33425-WB02_magic5",
        validated: false
      },
      postId: 1407
    },
    {
      sesam: {
        name: "56789_hittepaProdukt",
        validated: false,
      },
      postId: 1209,
    },
    {
      sesam: {
        name: "56999_TestProdukt",
        validated: false,
      },
      postId: 1704
    }
  ],
  publishedAt: null,
  updatedAt: null
}