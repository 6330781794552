import ImageSlider from "./components/ImageSlider/ImageSlider";
import { IMAGESLIDER } from "../constants";

export const createImageSliderPlugin = () => ({
  entityRendererFn: ({ type, data, settings }) => {
    const types = [IMAGESLIDER];

    if (!types.includes(type)) {
      return null
    }

    return {
      component: ImageSlider,
      props: {
        data,
        settings
      }
    };
  }
});

export default createImageSliderPlugin;

export const emptyBlocks = {
  [IMAGESLIDER]: {
    type: IMAGESLIDER,
    data: {
      slides: []
    }
  }
};
