import React from "react";
import { CircleSolid } from "../../../../shared/components/StudliIcons/index";
import styled from "styled-components";
import {
  PUBLISHED,
  UNPUBLISHED,
  DRAFT,
  DRAFT_PREVIEW_PUBLISHED,
  REVISION_PREVIEW_PUBLISHED,
  MODIFIED
} from "../../../../constants";
import Tooltip from "react-tooltip-lite";

const StyledStatusWrapper = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  padding-left: 10px;
`;

const StyledIcon = styled.svg`
  padding-top: 2px;
  fill: ${({ theme, color }) => theme[color]};
  height: -webkit-fill-available;
`;

const StatusRenderer = ({ rowData: { status } }) => {
  const studliSize = 25;

  /**
   * Renders icons
   * @param themeColor
   * @returns {*}
   */
  const renderIcon = themeColor => (
    <StyledIcon color={themeColor}>
      <CircleSolid size={studliSize} />
    </StyledIcon>
  );

  const getStatusContent = s => {
    switch (s) {
      case PUBLISHED:
        return {
          title: "Publicerad",
          color: "green"
        };
      case UNPUBLISHED:
        return {
          title: "Avpublicerad",
          color: "black"
        };
      case DRAFT:
      case DRAFT_PREVIEW_PUBLISHED:
        return {
          title: "Ej publicerad",
          color: "lightGray"
        };
      case REVISION_PREVIEW_PUBLISHED:
      case MODIFIED:
        return {
          title: "Opublicerade ändringar",
          color: "orange"
        };
      default:
        return {};
    }
  };

  const statusContent = getStatusContent(status);

  return (
    status && (
      <StyledStatusWrapper>
        <Tooltip content={statusContent.title} useDefaultStyles={true}>
          {renderIcon(statusContent.color)}
        </Tooltip>
      </StyledStatusWrapper>
    )
  );
};

export default StatusRenderer;
