import { createSelector } from "reselect";

export const getProductDataForList = createSelector(
  state => state.Productlist.get("list").toArray(),
  state => state.Product.get("types"),
  (products, types) =>
    products.map(p => {
      const s = types && types.find(t => t.get("id") === p.typeId);
      return {
        sesam_name: p.sesam.name,
        status: p.status,
        entity_id: p.id,
        type: p.entityType,
        title: p.title,
        data: p,
        typeId: s.get("type")
      };
    })
);

export const selectFetchLimit = state => state.Productlist.get("fetchLimit");

export const selectSearchParams = state =>
  state.Productlist.get("searchParameters").toJS();

export const selectPage = state => state.Productlist.get("page");

export const getFetchLimit = state => state.Productlist.get("fetchLimit");
