import React from "react";
import { connect } from "react-redux";
import { DateColumn, LabelColumn, IDColumn } from "../../../shared";
import {
  StyledSelectableDataTable,
  StyledFilterContainer,
  StyledFilterWrapper,
  StyledListWrapper,
  StyledPaginationWrapper,
  StyledFilterButton,
  StyledFilterButtonWrapper,
  StyledSimpleModalPostList
} from "./styles";
import { Pagination } from "../../../shared";
import {
  getCurrentPostPage,
  isCurrentPostPageLoading
} from "../../store/selectors";
import {
  loadPosts,
  loadLessPosts,
  loadPage,
  reloadPosts,
  fetchFilters,
  sortPosts,
  filterPosts
} from "../../store/actions";
import FilterSection from "../../../shared/components/FilterSection";
import {
  filterTransformer,
  hasFilters
} from "../../../shared/containers/Filters/filterdata";
import LinkColumn from "../../../shared/components/DataTable/Columns/Link";
import { TextColumn } from "../../../shared/components/DataTable";

import { ENTITIES, RESOURCE } from "../../../constants";
import { selectEntityId } from "../../../store/selectors";

const filters = {
  exercise: ["SEARCH", "LABELS", "TAGS", "DIFFICULTY", "STATUS"],
  resource: ["SEARCH", "LABELS", "TAGS"],
  template: ["SEARCH", "LABELS", "TAGS"],
  mediaresource: ["SEARCH", "LABELS"],
  helpresource: ["SEARCH", "LABELS", "TAGS"]
};

const tagFilterWhitelist = {
  Exercise: ["Komponenter"],
  Resource: ["Komponenter"],
  Template: ["Komponenter"],
  MediaResource: ["Komponenter"],
  Helpresource: ["Komponenter"]
};

/**
 * Transform filters from redux state to a transformed array with tags as a seperate item
 *
 * @param {string} postType
 * @param {List} filtersToTransform
 */
const transformFilters = (postType, filtersToTransform) =>
  filtersToTransform
    .map(filterTransformer) // collect tags as one item
    .reduce((res, i) => res.concat(i), []) // convert from List to array
    .filter(f =>
      f.key === "tags"
        ? (tagFilterWhitelist[postType] || []).indexOf(f.title) >= 0
        : true
    ); // filter tags according to whitelist

const mapStateToProps = (state, props) => {
  const postType = props.type || ENTITIES[RESOURCE];
  const postState = state[postType];

  return {
    posts: getCurrentPostPage(state, postType),
    postType: postType,
    totalPosts: postState.get("total"),
    maxRows: postState.get("fetchLimit"),
    page: postState.get("page"),
    entityId: selectEntityId(state),
    loading: isCurrentPostPageLoading(state, postType),
    filterList: transformFilters(postType, postState.get("filters"))
  };
};

const mapDispatchToProps = dispatch => ({
  initialLoad: postType => dispatch(reloadPosts(postType)),
  fetchFilters: postType =>
    dispatch(fetchFilters(postType, filters[postType.toLowerCase()] || [])),
  loadPosts: postType => dispatch(loadPosts(postType)),
  loadLessPosts: postType => dispatch(loadLessPosts(postType)),
  loadPage: (postType, page) => dispatch(loadPage(postType, page)),
  sort: (postType, sort) => dispatch(sortPosts(postType, sort)),
  filterPost: (postType, params) => dispatch(filterPosts(postType, params))
});

class SimplePostList extends React.PureComponent {
  static defaultProps = {
    posts: [],
    isModal: false
  };

  constructor(props) {
    super(props);
    this.initialLoadDone = false;
    this.state = {
      query: {},
      searchValue: undefined,
      paginationInputValue: undefined,
      selected: []
    };
    this.changeTimer = null;
  }

  /**
   * Helper function to generate a query object when a tag-filter has changed
   * @param {array} value  the new value
   * @param {string} name  tha name of the tag-section
   */
  getSearchQueryForTags = (value, name) => {
    const { query = {} } = this.state;
    const { filterList } = this.props;
    const section = filterList.filter(section => section.title === name)[0];
    const sectionTagIds = (
      (section && section.data && section.data[0] && section.data[0].tags) ||
      []
    ).map(t => +t.id);
    let tags = (query && query.tags) || [];
    if (!Array.isArray(tags)) {
      tags = [tags];
    }
    const queryTags = tags.map(tid => +tid);
    const valueInSection = value.filter(tid => sectionTagIds.indexOf(tid) >= 0);
    const queryNotInSection = queryTags.filter(
      tid => sectionTagIds.indexOf(tid) < 0
    );
    return { ...query, tags: [...queryNotInSection, ...valueInSection] };
  };

  /**
   * Helper function to generate a query object when a filter that isn't tags has changed
   * @param {string} key  the filter-key
   * @param {*} value  the new value
   */
  getSearchQuery = (key, value) => {
    const { query = {} } = this.state;
    let newFilter = {};
    if (value !== undefined && value !== null && value !== "") {
      newFilter[key] = value;
    }
    const newQuery = Object.keys(query).reduce((acc, k) => {
      if (k !== key) {
        acc[k] = query[k];
      }
      return acc;
    }, {});
    return { ...newQuery, ...newFilter };
  };

  /**
   * Called when search-text-field changes
   * @param {string} value
   */
  onSearchChange = value => {
    if (value === this.state.searchValue) {
      return;
    }
    const { query } = this.state;
    const { postType, filterPost } = this.props;
    const searchParams = { ...query, search: value };
    filterPost(postType, searchParams);
    this.setState({
      searchValue: value
    });
  };

  /**
   * Called when a filter changes
   * @param {*} value
   * @param {string} name
   * @param {string} filterkey
   */
  onFilterChange = (value, name, filterkey) => {
    const { postType, filterPost } = this.props;
    const searchParams =
      filterkey === "tags"
        ? this.getSearchQueryForTags(value, name)
        : this.getSearchQuery(filterkey, value);
    filterPost(postType, searchParams);
    this.setState({
      query: searchParams,
      searchValue: undefined
    });
  };

  /**
   * Load next page in post list (called from pagionation component)
   */
  loadNextPage = () => {
    const { postType, loadPosts } = this.props;
    loadPosts(postType);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load previous page in post list (called from pagionation component)
   */
  loadPrevPage = () => {
    const { postType, loadLessPosts } = this.props;
    loadLessPosts(postType);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load first page in post list (called from pagionation component)
   */
  loadFirstPage = () => {
    const { postType, loadPage } = this.props;
    loadPage(postType, 1);
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Load last page in post list (called from pagionation component)
   */
  loadLastPage = () => {
    const { postType, loadPage, totalPosts, maxRows } = this.props;
    loadPage(postType, Math.ceil(totalPosts / maxRows));
    this.setState({ paginationInputValue: undefined });
  };

  /**
   * Called when input field in pagination changes
   * @param {number} value the page number
   */
  onPagingationInputChange = value => {
    this.setState({
      paginationInputValue: value === "" ? undefined : value
    });
  };

  /**
   * Called when the selection is changed
   * @param {array} selectedPosts  an array of the currently selected posts
   */
  onSelect = selectedPosts => {
    const oldSelected = this.state.selected;
    const oldIds = oldSelected.map(p => p.id);
    const { singleSelect } = this.props;

    // If single select is set, use ugly hack to deselect previously selected posts.
    const newSelected = singleSelect
      ? (oldSelected.length < selectedPosts.length
          ? selectedPosts.filter(p => oldIds.indexOf(p.id) < 0)
          : []
        ).filter((_, i) => i < 1)
      : selectedPosts;

    this.setState({
      selected: newSelected
    });
    this.props.onSelect(newSelected);
  };

  /**
   * Called when input field in pagination shoul update the current page
   * @param {object} value for some reason, an object containing the page number as property "text"
   */
  onPagingationInputEnter = value => {
    const nextPage = Number(value.text);
    const {
      totalPosts,
      maxRows,
      postType,
      loadPage,
      page: currentPage
    } = this.props;
    const totalPages = Math.ceil(totalPosts / maxRows);

    if (
      value.text === "" ||
      isNaN(nextPage) ||
      nextPage <= 0 ||
      nextPage > totalPages
    ) {
      this.setState({ paginationInputValue: currentPage });
    } else {
      loadPage(postType, nextPage);
      this.setState({ paginationInputValue: undefined });
    }
  };

  /**
   * Clear all filters
   */
  clearFilters = () => {
    this.props.filterPost(this.props.postType, {});
    this.setState({
      searchValue: undefined,
      query: {}
    });
  };

  /**
   * Render the filter sections menu
   */
  renderFilters = () => {
    const { filterList } = this.props;
    const { searchValue, query } = this.state;
    return (
      <StyledFilterWrapper>
        <StyledFilterContainer>
          <StyledFilterButtonWrapper>
            {hasFilters(query, searchValue) && (
              <StyledFilterButton onClick={this.clearFilters}>
                Rensa filter
              </StyledFilterButton>
            )}
          </StyledFilterButtonWrapper>
          {filterList.map((fs, i) => (
            <FilterSection
              key={fs.title}
              content={fs}
              searchValue={
                searchValue !== undefined ? searchValue : query && query.search
              }
              onSearchChange={this.onSearchChange}
              onFilterChange={this.onFilterChange}
              query={query}
            />
          ))}
        </StyledFilterContainer>
      </StyledFilterWrapper>
    );
  };

  /**
   * Render the pagination section
   */
  renderPagination = () => {
    const { page, maxRows, totalPosts, loading } = this.props;
    const { paginationInputValue } = this.state;
    return (
      <StyledPaginationWrapper>
        <Pagination
          page={page}
          inputvalue={
            paginationInputValue === undefined ? page : paginationInputValue
          }
          maxRows={maxRows}
          total={totalPosts}
          disabled={loading}
          more={this.loadNextPage}
          less={this.loadPrevPage}
          tofirst={this.loadFirstPage}
          tolast={this.loadLastPage}
          onChange={this.onPagingationInputChange}
          onEnter={this.onPagingationInputEnter}
        />
      </StyledPaginationWrapper>
    );
  };

  /**
   * Get appropriate title column
   * @returns {*}
   */
  getTitleColumn = () =>
    this.props.isModal ? (
      <TextColumn dataKey="title" label="Title" width={0.2} />
    ) : (
      <LinkColumn dataKey="title" label="title" width={0.2} />
    );

  /**
   * Render the post list
   */
  renderList = () => {
    const { postType, posts, loading, sort } = this.props;
    const { selected } = this.state;
    return (
      <StyledSelectableDataTable
        list={posts}
        selectedPosts={selected}
        disableCheckbox={this.props.isModal}
        onSelect={this.onSelect}
        loading={loading}
        onSort={sort.bind(this, postType)}
      >
        <IDColumn dataKey="id" label="ID" width={0.1} />
        {this.getTitleColumn()}
        <LabelColumn dataKey="tags" label="Labels" width={0.7} disableSort />
        <DateColumn
          dataKey="updated_at"
          label="Senast uppdaterad"
          width={0.3}
        />
      </StyledSelectableDataTable>
    );
  };

  /**
   * Returns appropriate wrapper
   * @param children
   * @returns {*}
   */
  getPostListWrapper = children =>
    this.props.isModal ? (
      <StyledSimpleModalPostList>{children}</StyledSimpleModalPostList>
    ) : (
      { children }
    );

  render() {
    const { postType, initialLoad, fetchFilters } = this.props;
    if (!this.initialLoadDone) {
      setTimeout(() => {
        fetchFilters(postType);
        initialLoad(postType);
      }, 10);
      this.initialLoadDone = true;
      return null;
    }

    const child = (
      <React.Fragment>
        {this.renderFilters()}
        <StyledListWrapper>
          {this.renderPagination()}
          {this.renderList()}
        </StyledListWrapper>
      </React.Fragment>
    );

    return this.getPostListWrapper(child);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimplePostList);
