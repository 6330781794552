import React, { PureComponent } from "react";
import { CaretDown, CaretRight } from "../StudliIcons";
import styled from "styled-components";

const CollapsibleCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CollapsibleCardHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 5px;
`;

const CollapsibleCardTitle = styled.div`
  ${props => props.theme.d100()}
  color: ${props => props.theme.neutralPrimary};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  z-index: 1;
  order: -1;
`;

const CollapsibleCardIcon = styled.div`
  color: inherit;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  z-index: 1;
  justify-content: flex-end;
  order: 1;
  padding-top: 5px;
`;

const CollapsibleCardContent = styled.div`
  padding: 0 ${props => props.theme.baselineNumber * 2}
    ${props => props.theme.baselineNumber * 2};
`;

const Collapsible = styled.div`
  display: none;
  margin: 0;
  padding: 0;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;

const CollapsibleCardToggle = styled.div`
  color: ${props => props.theme.neutralPrimary};
  display: flex;
  width: 100%;
  padding: 0 ${props => props.theme.baselineNumber * 2};
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
`;

export default class CollapsibleStateless extends PureComponent {
  static defaultProps = {
    onToggle: () => {},
    isOpen: false
  };

  render() {
    const { title, onToggle, children, isOpen } = this.props;

    const classNames = isOpen ? "isOpen" : "";

    const icon = !isOpen ? <CaretRight size={24} /> : <CaretDown size={24} />;

    return (
      <CollapsibleCard>
        <CollapsibleCardHeader>
          <CollapsibleCardToggle onClick={onToggle}>
            <CollapsibleCardTitle>{title}</CollapsibleCardTitle>
            <CollapsibleCardIcon>{icon}</CollapsibleCardIcon>
          </CollapsibleCardToggle>
        </CollapsibleCardHeader>
        <Collapsible className={classNames}>
          <CollapsibleCardContent>{children}</CollapsibleCardContent>
        </Collapsible>
      </CollapsibleCard>
    );
  }
}
