import React from "react";
import { connect } from "react-redux";
import { Pagination } from "../../../shared";
import { loadPosts, loadLessPosts, loadPage } from "../../store/actions";
import { ENTITIES, EXERCISE } from "../../../constants";
import { selectEntityId } from "../../../store/selectors";

const mapStateToProps = (state, props) => {
  let postType = props.type
    ? ENTITIES[props.type]
    : state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[EXERCISE];
  }
  const postState = state[postType];
  return {
    postType: postType,
    totalPosts: postState.get("total"),
    maxRows: postState.get("fetchLimit"),
    page: postState.get("page"),
    entityId: selectEntityId(state),
    loading: postState.get("fetching")
  };
};

const mapDispatchToProps = dispatch => ({
  loadPosts: postType => dispatch(loadPosts(postType)),
  loadLessPosts: postType => dispatch(loadLessPosts(postType)),
  loadPage: (postType, page) => dispatch(loadPage(postType, page))
});

class PaginationAction extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: undefined
    };
  }

  /**
   * Load next page in post list
   */
  loadNextPage = () => {
    const { postType, loadPosts } = this.props;
    loadPosts(postType);
    this.setState({ currentPage: undefined });
  };

  /**
   * Load previous page in post list
   */
  loadPrevPage = () => {
    const { postType, loadLessPosts } = this.props;
    loadLessPosts(postType);
    this.setState({ currentPage: undefined });
  };

  /**
   * Load first page in post list
   */
  loadFirstPage = () => {
    const { postType, loadPage } = this.props;
    loadPage(postType, 1);
    this.setState({ currentPage: undefined });
  };

  /**
   * Load last page in post list
   */
  loadLastPage = () => {
    const { postType, loadPage, totalPosts, maxRows } = this.props;
    loadPage(postType, Math.ceil(totalPosts / maxRows));
    this.setState({ currentPage: undefined });
  };

  /**
   * Called when input field in pagination changes
   * @param {number} value the page number
   */
  onPagingationInputChange = value => {
    this.setState({
      currentPage: value === "" ? undefined : value
    });
  };

  /**
   * Called when input field in pagination shoul update the current page
   * @param {object} value for some reason, an object containing the page number as property "text"
   */
  onPagingationInputEnter = value => {
    const nextPage = Number(value.text);
    const {
      totalPosts,
      maxRows,
      postType,
      loadPage,
      page: currentPage
    } = this.props;
    const totalPages = Math.ceil(totalPosts / maxRows);

    if (
      value.text === "" ||
      isNaN(nextPage) ||
      nextPage <= 0 ||
      nextPage > totalPages
    ) {
      this.setState({ currentPage });
    } else {
      loadPage(postType, nextPage);
      this.setState({ currentPage: undefined });
    }
  };

  render() {
    const { maxRows, totalPosts, loading, page } = this.props;
    const { currentPage } = this.state;
    return (
      <Pagination
        page={page}
        inputvalue={currentPage === undefined ? page : currentPage}
        maxRows={maxRows}
        total={totalPosts}
        disabled={loading}
        onChange={this.onPagingationInputChange}
        onEnter={this.onPagingationInputEnter}
        more={this.loadNextPage}
        less={this.loadPrevPage}
        tofirst={this.loadFirstPage}
        tolast={this.loadLastPage}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationAction);
