import React from "react";

const Multiplication = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="-8 -8 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-multiplication"
  >
  <path d="M2.12121212,0 C3.29272523,0 4.24242424,1.01096992 4.24242424,2.25806452 C4.24242424,3.50515911 3.29272523,4.51612903 2.12121212,4.51612903 C0.949699016,4.51612903 0,3.50515911 0,2.25806452 C0,1.01096992 0.949699016,0 2.12121212,0 Z" id="path-3"></path>
 
</svg>
);

export default Multiplication;
