import * as React from "react";
import deepEqual from "deep-equal";
import AidaTextEditor from "./AidaTextEditor";
import { DATA, TEXT } from "../../../constants";
import { StyledAidaTextEditorContainer } from "./StyledText";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

const getStateFromData = aidadata => {
  if (aidadata.text === "") {
    return EditorState.createEmpty();
  }
  const contentState = convertFromRaw(aidadata.text);
  return EditorState.createWithContent(contentState);
};

const AidaText = ({
  updateData,
  data,
  settings,
  draftTarget,
  storeDraft,
  updatedItem,
  keyType = TEXT,
  target = DATA,
  callbackUpdatedItem
}) => {
  const textEditorState = getStateFromData(data);
  const { readOnly, useShortToolbar } = settings;

  

  /**
   *  Updates text
   * @param editorState
   * @returns {*}
   */
  const onTextChange = editorState => {
    const prev = convertToRaw(textEditorState.getCurrentContent());
    const now = convertToRaw(editorState.getCurrentContent());
    const change = !deepEqual(prev, now);

    if (change) {
      storeDraft(draftTarget);
      updateData(keyType, now, target);
    }
  };

  return (
    <StyledAidaTextEditorContainer>
      <AidaTextEditor
        onChange={onTextChange}
        editorState={textEditorState}
        readOnly={readOnly}
        useToolbar
        useShortToolbar={useShortToolbar}
        updatedItem={updatedItem}
        callbackUpdatedItem={callbackUpdatedItem}
      />
    </StyledAidaTextEditorContainer>
  );
};

export default AidaText;
