import React from "react";

import { StyledSearchBoxWrapper, StyledLabel, StyledInput } from "./styles";

const SearchBox = ({ label, id, onChange, value, onBlur, onKeyUp }) => (
  <StyledSearchBoxWrapper>
    <StyledInput
      id={id}
      placeholder={"Sök..."}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
    />
    <StyledLabel htmlFor={id}>{label}</StyledLabel>
  </StyledSearchBoxWrapper>
);

SearchBox.defaultProps = {
  onClick() {},
  onChange() {},
  onBlur() {}
};

export default SearchBox;
