import React from "react";
import { PageHeader } from "../../../shared";
import PostList from "../../containers/PostList";

import { ENTITIES, RESOURCE } from "../../../constants";

const Resources = () => (
  <React.Fragment>
    <PageHeader studlicon="Resources" title="Resurser" />
    <PostList type={ENTITIES[RESOURCE]} />
  </React.Fragment>
);

export default Resources;
