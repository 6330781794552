import React, { useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

import { Button } from "../../shared";
import NoImage from "./NoImage";
import { ErrorMsg } from "./Input";
import { Plus } from "../../shared/components/StudliIcons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    margin-top: 1rem;
    width: 100px;
  }
  outline: 0;
`;

const Image = styled.img`
  max-width: 150px;
  border: 1px solid #979797;
  border-radius: 4px;
  ${({ error }) => error && "border-color: #B00020;"}
`;

const StyledButtonLabel = styled.div`
  margin-right: 5px;
`;

const ImageUpload = ({ img, name, onChange, error }) => {
  const [uploadedImg, setImage] = React.useState();

  React.useEffect(() => {
    setImage(img);
  }, [setImage, img]);

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result;
          setImage(binaryStr);
        };

        reader.readAsDataURL(file);

        onChange(file);
      });
    },
    [setImage, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const rootPros = { ...getRootProps(), onClick: undefined };

  return (
    <Wrapper {...rootPros}>
      {!uploadedImg && <NoImage error={error} />}
      {uploadedImg && <Image src={uploadedImg} error={error} />}
      <input
        id={name}
        type="file"
        name={name}
        hidden
        {...getInputProps()}
        accept="image/jpeg"
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
      <Button skin="DM" htmlFor={name} as="label" outline>
        <StyledButtonLabel>Omslag</StyledButtonLabel>
        <Plus size={"16"} />
      </Button>
    </Wrapper>
  );
};

export default ImageUpload;
