import React from "react";
import {
  SquarePlusSolid,
  SquareMinusSolid,
  FormatListBullet,
  Shuffle
} from "../../../shared/components/StudliIcons";
import {
  PLUS,
  MINUS,
  SHUFFLED,
  ORDER,
  SORTED,
  SUMMED,
  WIDE,
  TALL,
  DEFAULT,
  SQUARE,
  CHIPS,
  FREE,
  PARKING,
  BOX_TYPE,
  TEXT_SIZE,
  ALTERNATIVE,
  translation,
  BOXES,
  S,
  L
} from "../../../constants";
import {
  StyledDefaultChips,
  StyledWideChips,
  StyledTallChips,
  StyledSquareChips
} from "./StyledSortMachine";

export const rowSettingStyles = {
  [CHIPS]: WIDE,
  [ORDER]: DEFAULT,
  [BOX_TYPE]: WIDE,
  [TEXT_SIZE]: WIDE
};

export const totalItemsInBox = {
  [DEFAULT]: { 1: 12, 2: 12, 3: 10, 4: 8 },
  [WIDE]: { 1: 12, 2: 8, 3: 4, 4: 3 },
  [TALL]: { 1: 12, 2: 12, 3: 8, 4: 6 },
  [SQUARE]: { 1: 12, 2: 8, 3: 6, 4: 4 }
};

export const orderData = [
  { id: 0, label: <Shuffle size={25} />, value: SHUFFLED },
  { id: 1, label: <FormatListBullet size={25} />, value: ORDER }
];

export const calculateTypeData = [
  { id: 0, label: <SquarePlusSolid size={22} color="white" />, value: PLUS },
  { id: 1, label: <SquareMinusSolid size={22} color="white" />, value: MINUS }
];

export const boxesData = [
  { id: "id_0", label: "", value: "A", totalCorrectItems: 0 },
  { id: "id_1", label: "", value: "B", totalCorrectItems: 0 },
  { id: "id_2", label: "", value: "C", totalCorrectItems: 0 },
  { id: "id_3", label: "", value: "D", totalCorrectItems: 0 }
];

export const answerTypeData = [
  { id: 1, label: "Sortera", value: SORTED },
  { id: 2, label: "Summera", value: SUMMED }
];

export const chipsData = [
  { id: 1, label: <StyledDefaultChips />, value: DEFAULT },
  { id: 2, label: <StyledWideChips />, value: WIDE },
  { id: 3, label: <StyledTallChips />, value: TALL },
  { id: 4, label: <StyledSquareChips />, value: SQUARE }
];

export const boxTypeData = [
  { id: 1, label: "Fri", value: FREE },
  { id: 2, label: "Parkering", value: PARKING }
];

export const textSizeData = [
  { id: 1, label: "S", value: S },
  { id: 2, label: "L", value: L }
];

export const settingsData = [
  {
    title: translation[ALTERNATIVE],
    rows: [
      { title: "Ordning", data: orderData, type: ORDER },
      { title: "Format", data: chipsData, type: CHIPS, style: SQUARE }
    ]
  },
  {
    title: translation[BOXES],
    rows: [
      { title: "Lådtyp", data: boxTypeData, type: BOX_TYPE },
      { title: "Text storlek", data: textSizeData, type: TEXT_SIZE }
    ]
  }
];
