import React from "react";
import { CircleSolid } from "../../StudliIcons/index";
import { StyledStatusWrapper, StyledIcon } from "./StyledStatus";
import {
  DRAFT,
  MODIFIED,
  PUBLISHED,
  DRAFT_READY_FOR_REVIEW,
  REVISION_READY_FOR_REVIEW,
  DRAFT_REVIEWED,
  REVISION_REVIEWED,
  UNPUBLISHED,
  DRAFT_PREVIEW_PUBLISHED,
  REVISION_PREVIEW_PUBLISHED,
} from "../../../../constants";
import Tooltip from "react-tooltip-lite";
import { STATUS } from "../../../../posts/api/constants";

const StatusRenderer = ({ rowData: { status, lock } }) => {
  const studliSize = 25;

  /**
   * Returns proper status of icon
   */
  const getStatus = () => {
    switch (status) {
      case PUBLISHED:
        return PUBLISHED;
      case MODIFIED:
        return MODIFIED;
      case DRAFT_READY_FOR_REVIEW:
        return DRAFT_READY_FOR_REVIEW;
      case REVISION_READY_FOR_REVIEW:
        return REVISION_READY_FOR_REVIEW;
      case DRAFT_REVIEWED:
        return DRAFT_REVIEWED;
      case REVISION_REVIEWED:
        return REVISION_REVIEWED;
      case UNPUBLISHED:
        return UNPUBLISHED;
      case DRAFT_PREVIEW_PUBLISHED:
        return DRAFT_PREVIEW_PUBLISHED
      case REVISION_PREVIEW_PUBLISHED:
        return REVISION_PREVIEW_PUBLISHED
      default:
        return DRAFT;
    }
  };

  /**
   * Renders icons
   * @param themeColor
   * @returns {*}
   */
  const renderIcon = themeColor => (
    <StyledIcon color={themeColor}>
      <CircleSolid size={studliSize} />
    </StyledIcon>
  );

  const statusIcons = {
    [PUBLISHED]: renderIcon("green"),
    [MODIFIED]: renderIcon("darkGray"),
    [DRAFT]: renderIcon("lightGray"),
    [DRAFT_READY_FOR_REVIEW]: renderIcon("orange"),
    [REVISION_READY_FOR_REVIEW]: renderIcon("darkOrange"),
    [DRAFT_PREVIEW_PUBLISHED]: renderIcon("darkOrange"),
    [REVISION_PREVIEW_PUBLISHED]: renderIcon("darkOrange"),
    [DRAFT_REVIEWED]: renderIcon("blue"),
    [REVISION_REVIEWED]: renderIcon("darkBlue"),
    [UNPUBLISHED]: renderIcon("black")
  };

  return (
    status && (
      <StyledStatusWrapper>
        <Tooltip
          content={STATUS.find(item => item.id === status).title}
          useDefaultStyles={true}
        >
          {statusIcons[getStatus()]}
        </Tooltip>
      </StyledStatusWrapper>
    )
  );
};

export default StatusRenderer;
