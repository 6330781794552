import HelpAudio from "./components/Help/HelpAudio/HelpAudio";
import { getMediaObject } from "./dataModels";
import { AUDIO, HELP_AUDIO } from "../constants";

export const createHelpAudioPlugin = () => ({
  entityRendererFn: entity => {
    const types = [HELP_AUDIO];

    if (types.includes(entity.type)) {
      return {
        component: HelpAudio,
        style: { padding: "0px" },
        props: {
          data: entity.data,
          settings: entity.settings
        }
      };
    }
    return null;
  }
});

export default createHelpAudioPlugin;

export const emptyBlocks = {
  [HELP_AUDIO]: {
    type: HELP_AUDIO,
    data: {
      title: null,
      source: null,
      [AUDIO]: getMediaObject(AUDIO)
    },
    settings: {}
  }
};
