import styled from "styled-components";
import { CancelButton, StandardButton } from "../../../shared";
import SortableTree from "react-sortable-tree";

export const FormNodeWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const Label = styled.label`
  font-size: 12px;
  display: block;
`;

export const FormRow = styled.div`
  padding: 5px 0;
`;

export const StyledNodeStandardBtn = styled(StandardButton)`
  min-width: 25px;
  padding: 0;
`;

export const NodeCancelBtn = styled(CancelButton)`
  min-width: 25px;
  padding: 0;
`;

export const StyledPanelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledPanelButtonsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #cecece;
  box-shadow: 0 1px 4px -2px;
  position: relative;
  z-index: 1;
  padding: 5px;
  display: flex;
  justify-content: space-between;
`;

export const PanelWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PanelSection = styled.div`
  flex: 1;
  display: ${({ display = 1 }) => (display ? "flex" : "none")};
  padding: ${({ side }) => (side === "left" ? "0" : "20px")};
  background: ${({ side }) =>
    side === "left" ? "rgba(50, 77, 114, 0.1);" : "transparent"};
  ${({ side }) =>
    side === "right"
      ? `
      border-left: 1px solid #cecece;
      z-index: 1;
      max-width: 30%;
      min-width: 250px;
    `
      : null}
`;

export const Tree = styled(SortableTree)`
  .category-node {
    user-select: none;
  }

  .rst__rowSearchMatch {
    outline: 0;

    .rst__rowContents {
      background: rgba(50, 77, 114, 0.25);
    }
  }

  .rst__rowSearchFocus {
    outline: 0;

    .rst__rowContents {
      background: rgba(56, 114, 50, 0.25);
    }
  }
`;

export const StyledTreeContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const StyledOverlay = styled.div`
  background: rgba(50, 77, 114, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  z-index: ${({ isActive }) => (isActive ? 10 : -999)};
  cursor: pointer;

  transition: opacity 200ms;
`;

export const StyledTreeSearchBox = styled.div`
  display: flex;
`;

export const StyledTreeModBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
`;

export const StyledTreeModBarCol = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5px;

  &:first-child {
    border-right: 1px solid #cecece;
  }
`;

export const StyledButtonsSpacer = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonsSpacerLine = styled.div`
  height: 100%;
  width: 1px;
  background: #cecece;
`;

export const StyledIsGoalIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIsPublishedIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
`;

export const StyledSpacer = styled.div`
  height: 25px;
  width: 1px;
  background: #cecece;
  margin: 0 10px;
`;
