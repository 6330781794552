import styled from "styled-components";
import IconButton from "../IconButton";

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  margin: 0;
  z-index: 1;
  background-color: white;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.neutralTertiary};
  border-radius: 0.313rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
`;

export const Title = styled.span`
  font-size: 1rem;
  white-space: nowrap;
`;

export const PreviewContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledDeleteButton = styled(IconButton)`
  height: auto;
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5rem;
  color: ${props => props.theme.neutralSecondaryAlt};
`;

export const Grip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  margin-right: 0.5rem;
  color: ${props => props.theme.neutralTertiary};
`;
