import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../products/components/Checkbox";
import TreeChips from "./TreeChips";
import { ChevronRight, ChevronDown } from "../StudliIcons";

const StyledItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 35px;
`;
const StyledListWrapper = styled.ul`
  list-style: none;
  padding-left: 5px;
`;

const StyledNodeWrapper = styled.ul`
  list-style: none;
  padding-left: 5px;
`;

const StyledListItemWrapper = styled.li`
  padding-left: 5px;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: ${props => props.theme.neutralDark};
`;
const StyledIcon = styled.div`
  padding-right: 3px;
  stroke: 2px;
  cursor: pointer;
`;

const TreeSection = ({ item, onChipChange, chipsSel, chipsBelowNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const mySubNodes = chipsBelowNode && chipsBelowNode[item.id];

  const onHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (mySubNodes && chipsSel) {
      const nodeIsSelected = mySubNodes.some(s => chipsSel.includes(s));
      setIsChecked(nodeIsSelected)
    }
  }, [chipsSel, mySubNodes]);

  const _onChange = val => {
    if (val) {
      onChipChange({ add: mySubNodes });
    } else {
      onChipChange({ remove: mySubNodes });
    }
  };

  return (
    <StyledListItemWrapper>
      <StyledItemHeader>
        <StyledIcon onClick={onHeaderClick}>
          {isOpen ? <ChevronDown size="10" /> : <ChevronRight size="10" />}
        </StyledIcon>
        <StyledCheckbox onChange={_onChange} checked={isChecked} />
        <StyledTitle onClick={onHeaderClick}>{item.title}</StyledTitle>
      </StyledItemHeader>
      {isOpen && item.sub_sections && item.sub_sections.length > 0 && (
        <StyledListWrapper>
          {item.sub_sections.map(s => (
            <TreeSection
              key={s.id}
              item={s}
              onChipChange={onChipChange}
              chipsSel={chipsSel}
              chipsBelowNode={chipsBelowNode}
            />
          ))}
        </StyledListWrapper>
      )}
      {isOpen && item.chips && (
        <StyledNodeWrapper>
          {item.chips.map(c => (
            <TreeChips
              key={c.id}
              node={c}
              onChange={onChipChange}
              chipsSel={chipsSel}
            />
          ))}
        </StyledNodeWrapper>
      )}
    </StyledListItemWrapper>
  );
};

export default TreeSection;
