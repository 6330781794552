import React, { useEffect } from "react";
import ButtonGroup from "../ButtonGroup";
import {
  PageView,
  Text,
  InputText,
  PaginationInput,
  PaginationButton,
  PaginationButtonLoad
} from "./PaginationStyles";

const Pagination = ({
  page,
  total,
  maxRows,
  disabled,
  more,
  less,
  tofirst,
  tolast,
  onChange,
  onEnter,
  inputvalue
}) => {
  let from = 1;
  let to = maxRows * page;
  let totalPages = Math.ceil(total / maxRows);
  let lastPage = to >= total;
  let firstPage = page <= 1;

  if (!firstPage) {
    from = to - maxRows;
  }

  if (lastPage) {
    to = total;
  }

  useEffect(() => {
    if (page > totalPages) {
      tofirst();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  return (
    <React.Fragment>
      <Text>
        {from}-{to} av {total}
      </Text>
      <ButtonGroup>
        <PaginationButton onClick={tofirst} disabled={firstPage || disabled}>
          {" "}
          Första{" "}
        </PaginationButton>
        <PaginationButtonLoad
          icon="ArrowLeftCircle"
          onClick={less}
          disabled={firstPage || disabled}
        />
      </ButtonGroup>
      <PageView>
        <InputText>
          <PaginationInput
            value={inputvalue}
            onChange={onChange}
            onEnter={onEnter}
            size={5}
          ></PaginationInput>{" "}
          / {totalPages}
        </InputText>
      </PageView>
      <ButtonGroup>
        <PaginationButtonLoad
          icon="ArrowRightCircle"
          onClick={more}
          disabled={lastPage || disabled}
        />
        <PaginationButton onClick={tolast} disabled={lastPage || disabled}>
          {" "}
          Sista{" "}
        </PaginationButton>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default Pagination;
