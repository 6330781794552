import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, rootId }) => {
  const [created, setCreated] = useState(false);

  useEffect(() => {
    const rootEl = document.body.querySelector(`#${rootId}`);

    if (!rootEl) {
      const el = document.createElement("div");
      el.setAttribute("id", rootId);
      document.body.appendChild(el);
    }

    setCreated(true);
  }, [rootId, setCreated]);

  return (
    created && createPortal(children, document.body.querySelector(`#${rootId}`))
  );
};

export default Portal;
