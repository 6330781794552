import React from "react";
import { RadioButton } from "../../../shared/components/RadioButton";
import { StyledButtonsToggleRow } from "./StyledButtonsToggle";

const customStyles = {
  minWidth: "40px",
  padding: "6px 10px",
  cursor: "pointer",
  backgroundColor: "#313131",
  color: "#FFFFFF",
  width: "100%",
  containerWidth: "100%"
};

const buttonStyles = {
  borderRadius: "0",
  minWidth: "40px",
  minHeight: "40px"
};

const ButtonsToggle = ({ buttonData, onChange, activeIndex }) => {
  /**
   * Handles change of value on user interaction
   * @param value
   * @param id
   * @returns {function(): *}
   * @private
   */
  const _onChange = (value, id) => () => onChange(value, id);

  /**
   * Renders buttons based on data passed to it
   */
  const renderButtons = () =>
    buttonData.map(({ id, label, value }, index) => (
      <RadioButton
        key={id}
        isActive={activeIndex === index}
        callback={_onChange(value, id)}
        content={label}
        buttonStyle={buttonStyles}
        overrideStyles={customStyles}
      />
    ));

  return <StyledButtonsToggleRow>{renderButtons()}</StyledButtonsToggleRow>;
};

export default ButtonsToggle;
