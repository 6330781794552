import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_TEMPLATES, ROUTE_DETAILED_TEMPLATE } from "../routes";
import { NEXT_IN_CAROUSEL, PREV_IN_CAROUSEL } from "../store/carousel/actions";
import { fetchEntitiesSaga, fetchEntityDetailsSaga } from "../api/saga";
import { FETCH_ENTITIES, FETCH_ENTITY_DETAILS } from "../../api";
import { onListRoute, onDetailedRoute, carousel } from "./sagas";
import { ENTITIES, TEMPLATE } from "../../constants";
import postsSaga from "./posts";
import aidaSaga from "./aida";
import locksSaga from "./Locks/sagas";
import postDraftSaga from "./PostDraft/sagas";
import resourcesSaga from "./resources";
import helpResourcesSaga from "./helpresources";
import { actionCreator } from "../../shared";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(TEMPLATE, FETCH_ENTITIES),
    fetchEntitiesSaga("Template", "listTemplates")
  );
  yield takeLatest(
    actionCreator(TEMPLATE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga("Template", "fetchTemplate")
  );
  yield takeEvery(NEXT_IN_CAROUSEL, carousel, "nextPost");
  yield takeEvery(PREV_IN_CAROUSEL, carousel, "prevPost");
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_TEMPLATES,
    onListRoute,
    ENTITIES[TEMPLATE],
    "listTemplates"
  );
  yield takeEvery(
    ROUTE_DETAILED_TEMPLATE,
    onDetailedRoute,
    ENTITIES[TEMPLATE],
    "fetchTemplate"
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchPostRequests),
    fork(watchPostsRoutes),
    fork(postsSaga),
    fork(resourcesSaga),
    fork(helpResourcesSaga),
    fork(locksSaga),
    fork(postDraftSaga),
    fork(aidaSaga)
  ]);
}
