import {
  CALCULATE_TYPE,
  PLUS,
  OPERATIVE,
  ANSWER_TYPE,
  FRACTION,
  MULTIPLE_ANSWERS,
  SINGLE,
  METHOD_TYPE,
  FRACTION_METHOD,
  DECIMAL_COUNT,
  SIMPLIFY,
  EXTEND,
  SIMPLIFY_MULTIPLE,
  SIMPLIFY_HIDDEN
} from "../../../constants";
import { getAllIndexes } from "../../../shared/helpers";
import { generateIDsForEachPosition } from "./FractionData";

/**
 * Generates default Fraction data object
 * @returns {{items: Array}}
 */
export const defaultFractionData = () => ({
  items: [
    {
    integer: {value: "" , interactive: false},
    numerator: {value: "" , interactive: false},
    denominator: {value: "" , interactive: false}
    }, 
    {
    integer: {value: "" , interactive: false},
    numerator: {value: "" , interactive: false},
    denominator: {value: "" , interactive: false}
    }
  ],
  result: {
    integer: {value: "" , interactive: true},
    numerator: {value: "" , interactive: true},
    denominator: {value: "" , interactive: true}
  },
  grid: null,
  mirrored: false
});

/**
 * Generates default Fraction settings object
 */
export const defaultFractionSettings = () => ({
  [CALCULATE_TYPE]: PLUS,
  [ANSWER_TYPE]: FRACTION,
  [MULTIPLE_ANSWERS]: SINGLE,
  [METHOD_TYPE]: FRACTION_METHOD,
  [DECIMAL_COUNT]: 0,
  [SIMPLIFY]: EXTEND,
  [SIMPLIFY_MULTIPLE]: 1,
  [SIMPLIFY_HIDDEN]: false,
  [OPERATIVE]: true
});

export const mirrorGrid = (grid, mirrored) => {
  if(!mirrored || grid == null) {
    return grid
  }

  const equalIndices = getAllIndexes(grid[2], "=")
    const arrayPartsUp = [];
    const arrayPartsDown = [];
    let count = 0;

    for (let i = 0; i <= equalIndices.length; i++) {
      const part1 = grid[1].slice(count, equalIndices[i])
      const part2 = grid[2].slice(count, equalIndices[i])
      const equalIndex = part2.findIndex(item => item.value === "=")

      if(equalIndex !== -1) {
        const equalElem1 = part1.shift()
        const equalElem2 = part2.shift()

        part1.push(equalElem1)
        part2.push(equalElem2)
      }

      arrayPartsUp.push(part1)
      arrayPartsDown.push(part2)

     count = (count === equalIndices[i]) ? grid[1].length : equalIndices[i]
    }

    const assembledArrayUp = [].concat(...[...arrayPartsUp].reverse())
    const assembledArrayDown = [].concat(...[...arrayPartsDown].reverse())

    return generateIDsForEachPosition([[...grid[0]], [...assembledArrayUp], [...assembledArrayDown], [...grid[3]]])
}