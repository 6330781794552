import styled from "styled-components";
import Input from "../../../../shared/components/Input/Input";
import {
  white,
  neutralPrimary,
  neutralSecondaryAlt
} from "../../../../styles/shared/colors";

export const StyledSidebarGrid = styled.div`
  padding: 0 10px;
  display: grid;
  width: 100%;
  max-width: 100%;
  grid-template-columns: 1fr 2fr 2fr;
  grid-auto-rows: min-content;
  grid-column-gap: 4px;
  grid-row-gap: 8px;
`;

export const StyledCellGridItem = styled.div`
  display: flex;
  align-items: center;

  grid-area: ${({ area }) => area};
  grid-column-start: ${({ startColumn }) => startColumn};
  grid-column-end: ${({ endColumn }) => endColumn};
`;

export const StyledInputLabel = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: ${white};
`;

export const StyledSidebarLineGrid = styled.div`
  padding: 0 10px;
  display: grid;
  width: 100%;
  max-width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-column-gap: 8px;
  grid-row-gap: 10px;
`;

export const StyledLineGridCell = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: ${({ area }) => area};
  grid-column-start: ${({ startColumn }) => startColumn};
  grid-column-end: ${({ endColumn }) => endColumn};
`;

export const StyledInput = styled(Input)`
  color: ${white};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  background-color: ${neutralPrimary};
  border: 1px solid ${neutralSecondaryAlt};
  box-shadow: none;
  height: 30px;
  width: 100%;
`;
