import React from "react";

const Close = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-close"
  >
    <path d="M29.2432576,25.000625 L42.0636582,12.1802244 L44.7074909,9.53639176 C45.097503,9.14637957 45.097503,8.51260977 44.7074909,8.12259758 L41.8786525,5.29375918 C41.4886403,4.90374699 40.8548705,4.90374699 40.4648583,5.29375918 L25.000625,20.7579924 L9.53639176,5.29250914 C9.14637957,4.90249695 8.51260977,4.90249695 8.12259758,5.29250914 L5.29250914,8.12134754 C4.90249695,8.51135973 4.90249695,9.14512954 5.29250914,9.53514172 L20.7579924,25.000625 L5.29250914,40.4648583 C4.90249695,40.8548705 4.90249695,41.4886403 5.29250914,41.8786525 L8.12134754,44.7074909 C8.51135973,45.097503 9.14512954,45.097503 9.53514172,44.7074909 L25.000625,29.2432576 L37.8210257,42.0636582 L40.4648583,44.7074909 C40.8548705,45.097503 41.4886403,45.097503 41.8786525,44.7074909 L44.7074909,41.8786525 C45.097503,41.4886403 45.097503,40.8548705 44.7074909,40.4648583 L29.2432576,25.000625 Z" />
  </svg>
);

export default Close;
