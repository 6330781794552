import { iconSizes, scale, viewBoxes } from "../../shared/constants/formats";

/**
 * Moves an icon front (positive direction) or back (negative direction) in z by swapping place in icon list
 * one step to the right or left in the list.
 * @param {*} draft
 * @param {*} direction
 */
export const moveFrontOrBack = (draft, direction) => {
  if (direction > 0) {
    draft = draft.reverse();
  }

  for (let i = 0; i < draft.length; i++) {
    if (draft[i].selected) {
      if (i - 1 >= 0) {
        if (draft[i - 1].selected !== true) {
          let temp = draft[i - 1];
          draft[i - 1] = draft[i];
          draft[i] = temp;
        }
      }
    }
  }
  if (direction > 0) {
    draft = draft.reverse();
  }
  return draft;
};

/**
 * Updates selected Items with the newSize
 * @param {*} draft
 * @param {*} newSize
 */
export const updateSizeForSelectedIcons = (draft, newSize) =>
  draft.map(item => (item.get("selected") ? item.set("size", newSize) : item));

/**
 * Adds multiple (numberOfCopies) copies of selectedIcon/s
 * @param {*} draft
 * @param {*} numberOfCopies
 */
export const duplicateSelectedIcons = (draft, numberOfCopies) => {
  let newIcons = [];
  let d = new Date();

  for (let icon of draft) {
    if (icon.selected) {
      for (let i = 0; i < numberOfCopies; i++) {
        newIcons = [
          ...newIcons,
          {
            image: { src: icon.image.src },
            size: icon.size,
            x: parseFloat(icon.x) + 10,
            y: parseFloat(icon.y) + 10,
            id: icon.id + d.getTime(),
            selected: true
          }
        ];
      }
      icon.selected = false;
    }
  }

  return [...draft, ...newIcons];
};

/**
 * Get max and min for x and y of the rect that all selected items is included in.
 * @param {*} draft
 * @param {*} alignToWorkspace
 * @param {*} format
 */
export const getSelectedObjectsRectCoords = (draft, alignToWorkspace, format) =>
  alignToWorkspace
    ? getWorkspaceCoord(format)
    : alignCoord(
        draft.filter(val => val.get("selected")),
        format
      );

/**
 * Returns a new draft with the items aligned according to alignDirection.
 * @param {*} draft
 * @param {*} alignDirection
 * @param {*} alignToWorkspace
 * @param {*} coords
 */
export const align = (draft, alignDirection, alignToWorkspace, coords) => {
  let newDraft = [];
  switch (alignDirection) {
    case "top":
      newDraft = alignTop(draft, coords);
      break;
    case "middle":
      newDraft = alignMiddle(draft, coords, alignToWorkspace);
      break;
    case "bottom":
      newDraft = alignBottom(draft, coords, alignToWorkspace);
      break;
    case "left":
      newDraft = alignLeft(draft, coords);
      break;
    case "right":
      newDraft = alignRight(draft, coords, alignToWorkspace);
      break;
    case "center":
      newDraft = alignCenter(draft, coords, alignToWorkspace);
      break;
    default:
  }

  return newDraft;
};

/**
 * Aligns selected icons to the top of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 */
export const alignTop = (draft, coords) =>
  draft.map(item => (item.get("selected") ? item.set("y", coords.yMin) : item));

/**
 * Aligns selected icons to the bottom of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 * @param {bool} alignToWorkspace
 */
export const alignBottom = (draft, coords, alignToWorkspace) =>
  draft.map(item =>
    item.get("selected")
      ? item.set(
          "y",
          coords.yMax - getItemHeight(item, alignToWorkspace, coords.format)
        )
      : item
  );

/**
 * Aligns selected icons to the left of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 */
export const alignLeft = (draft, coords) =>
  draft.map(item => (item.get("selected") ? item.set("x", coords.xMin) : item));

/**
 * Aligns selected icons to the right of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 * @param {bool} alignToWorkspace
 */
export const alignRight = (draft, coords, alignToWorkspace) =>
  draft.map(item =>
    item.get("selected")
      ? item.set(
          "x",
          coords.xMax - getItemWidth(item, alignToWorkspace, coords.format)
        )
      : item
  );

/**
 * Aligns selected icons to the middle (y) of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 * @param {bool} alignToWorkspace
 */
export const alignMiddle = (draft, coords, alignToWorkspace) =>
  draft.map(item =>
    item.get("selected")
      ? item.set(
          "y",
          coords.yMin +
            (coords.yMax - coords.yMin) / 2 -
            getItemHeight(item, alignToWorkspace, coords.format) / 2
        )
      : item
  );

/**
 * Aligns selected icons to the center (x) of the rect
 * @param {*} draft
 * @param {*} coords - xMin, yMin, xMax, yMax for the rect that objects is aligning to
 * @param {bool} alignToWorkspace
 */
export const alignCenter = (draft, coords, alignToWorkspace) =>
  draft.map(item =>
    item.get("selected")
      ? item.set(
          "x",
          coords.xMin +
            (coords.xMax - coords.xMin) / 2 -
            getItemWidth(item, alignToWorkspace, coords.format) / 2
        )
      : item
  );

/**
 * Sort function for X
 * @param {*} a
 * @param {*} b
 */
const sortByX = (a, b) => a.get("x") - b.get("x");

/**
 * Sort function for Y
 * @param {*} a
 * @param {*} b
 */
const sortByY = (a, b) => a.get("y") - b.get("y");

/**
 * Add properties Height and Width to objects with selected === true
 * @param {*} objects
 * @param {*} format
 */
const addHeightWidthToObjectData = (objects, format) =>
  objects.map(item =>
    item.get("selected")
      ? item
          .set("Width", scale[item.get("size")] * iconSizes[format])
          .set("Height", scale[item.get("size")] * iconSizes[format])
      : item
  );

/**
 * Calculates the distance required between the objects so they will be spread equally.
 * @param {*} sorted List with the selected items
 * @param {*} alignToWorkspace align between objects or to workspace
 * @param {*} diff distance between max and min edges of the rect items will be distributed in.
 * @param {*} dir Height or Width (Vertical or Horizontal)
 */
const calculateAverageDistanceBetweenObjects = (
  sorted,
  alignToWorkspace,
  diff,
  dir
) => {
  let objsize = sorted.reduce((o, item) => o + parseFloat(item.get(dir)), 0);
  const last = parseFloat(sorted.getIn([sorted.size - 1, dir]));
  objsize = objsize - last;
  const tot = alignToWorkspace ? diff - last - objsize : diff - objsize;

  return Math.round(tot / (sorted.size - 1));
};

/**
 * Updates the coords (X or Y) and returns a new draft
 * @param {*} draft the original draft with objects
 * @param {*} sorted the sorted List with the selected items
 * @param {*} spaceBetween the calculated distance between the items
 * @param {*} alignToWorkspace
 * @param {*} key X or Y depending if this is horisontal or vertical
 */
const updateDraft = (draft, sorted, spaceBetween, alignToWorkspace, key) => {
  let next = alignToWorkspace ? 0 : parseFloat(sorted.getIn([0, key]));
  const dir = key === "x" ? "Width" : "Height";
  const newSorted = sorted.map(item => {
    let prev = next;
    next = next + parseFloat(item.get(dir)) + spaceBetween;
    return item.set(key, prev);
  });

  let newDraft = draft;
  for (let newdata of newSorted) {
    const indx = draft.findIndex(idr => idr.get("id") === newdata.get("id"));
    newDraft = newDraft.update(indx, item => item.set(key, newdata.get(key)));
  }

  return newDraft;
};

/**
 * Calculates the space between objects horizontally and sets the new position on selected objects
 * If alignToWorkspace is true it distribute objects against the workspace
 * @param {*} iconDraft
 * @param {*} format
 * @param {*} coords
 * @param {bool} alignToWorkspace
 */
export const distributeHorizontal = (
  iconDraft,
  format,
  coords,
  alignToWorkspace
) => {
  const extendedDraft = addHeightWidthToObjectData(iconDraft, format);
  const selectedSortedByX = extendedDraft
    .filter(item => item.get("selected"))
    .sort(sortByX);
  const diff = coords.xMax - coords.xMin;
  const spaceBetween = calculateAverageDistanceBetweenObjects(
    selectedSortedByX,
    alignToWorkspace,
    diff,
    "Width"
  );

  return updateDraft(
    iconDraft,
    selectedSortedByX,
    spaceBetween,
    alignToWorkspace,
    "x"
  );
};

/**
 * Calculates the space between objects vertically and sets the new position on selected objects
 * If alignToWorkspace is true it distribute objects against the workspace
 * @param {*} iconDraft
 * @param {*} format
 * @param {*} coords
 * @param {bool} alignToWorkspace
 */
export const distributeVertical = (
  iconDraft,
  format,
  coords,
  alignToWorkspace
) => {
  const extendedDraft = addHeightWidthToObjectData(iconDraft, format);
  const selectedSortedByY = extendedDraft
    .filter(item => item.get("selected"))
    .sort(sortByY);
  const diff = coords.yMax - coords.yMin;
  const spaceBetween = calculateAverageDistanceBetweenObjects(
    selectedSortedByY,
    alignToWorkspace,
    diff,
    "Height"
  );

  return updateDraft(
    iconDraft,
    selectedSortedByY,
    spaceBetween,
    alignToWorkspace,
    "y"
  );
};

/**
 * Get the min value of values
 * @param {*} values
 */
const getMin = values =>
  Math.min(...values) < 1000 ? Math.min(...values) : 1000;

/**
 * get the max value of values
 * @param {*} values
 */
const getMax = values => (Math.max(...values) > 0 ? Math.max(...values) : 0);

/**
 * get a list of key from draft
 * @param {*} draft
 * @param {*} key the property i want a list of
 */
const getValuesByKey = (draft, key) =>
  draft.map(val => parseFloat(val.get(key)));

/**
 * Get min and max for a property
 * @param {*} values
 * @param {*} prefix
 */
const getMinMax = (values, prefix) => ({
  [`${prefix}Min`]: getMin(values),
  [`${prefix}Max`]: getMax(values)
});

/**
 * Calculates the rect where the selected icons is inside.
 * @param {*} draft
 * @param {bool} format
 * @return {object} - returns an object with xMin, yMin, xMax, yMax
 */
const alignCoord = (draft, format) => ({
  ...getMinMax(getValuesByKey(draft, "x"), "x"),
  ...getMinMax(getValuesByKey(draft, "y"), "y"),
  format: format
});

/**
 * Returns the workspace outer coords
 * @param {*} format
 * @return {object}
 */
const getWorkspaceCoord = format => {
  let viewBox = viewBoxes[format];
  let c = viewBox.split(" ");
  return {
    xMin: parseFloat(c[0]),
    yMin: parseFloat(c[1]),
    xMax: parseFloat(c[2]),
    yMax: parseFloat(c[3]),
    format: format
  };
};

/**
 * returns the width of the item. If alignToWorkspace is false I don't need the width and returns 0
 * @param {*} item
 * @param {*} alignToWorkspace
 * @param {*} format
 */
const getItemWidth = (item, alignToWorkspace, format) =>
  alignToWorkspace ? scale[item.get("size")] * iconSizes[format] : 0;

/**
 * returns the height of the item. If alignToWorkspace is false I don't need the height and returns 0
 * @param {*} item
 * @param {*} alignToWorkspace
 * @param {*} format
 */
const getItemHeight = (item, alignToWorkspace, format) =>
  alignToWorkspace ? scale[item.get("size")] * iconSizes[format] : 0;
