import { Column } from "react-virtualized";
import MenuActionRenderer from "../Renderer/MenuAction/MenuActionRenderer";

const MenuActionColumn = () => null;

MenuActionColumn.propTypes = Object.assign({}, Column.propTypes);
MenuActionColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: MenuActionRenderer,
  draggable: true
});

export default MenuActionColumn;
