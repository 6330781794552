import React from "react";
import styled from "styled-components";

import Section from "./Section";
import { StandardButton } from "../Button";

const StyledContentHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  box-sizing: border-box;
  padding: 22px;
`;

const ContentHeaderTitle = styled.h3`
  ${props => props.theme.d300()};
`;

const CloseButton = styled(StandardButton)`
  justify-self: flex-end;
`;

const ContentHeader = ({ header, modalClose }) => (
  <StyledContentHeader>
    <Section start>
      <ContentHeaderTitle>{header}</ContentHeaderTitle>
    </Section>
    <Section end>
      <CloseButton icon="X" transparent outline onClick={modalClose} />
    </Section>
  </StyledContentHeader>
);

export default ContentHeader;
