import React from "react";
import { useSelector } from "react-redux";
import ProductForm from "../../containers/ProductForm";
import ProductView from "../../containers/ProductView";
import ClassroomCard from "../../components/ClassroomCard";
import PublishCard from "../../containers/PublishCard";
import { getCurrentProductType } from "../../store/selectors";
import { Grid, Section } from "../../components/Grid";
import RelatedProductsCard from "../../components/RelatedProductsCard";
import CreateHurixBookCard from "../../components/CreateHurixBookCard";
import TaxonomyCard from "../../components/TaxonomyCard";
import BiafContentCard from "../../components/BiafContent/BiafContentCard";
import AddBiafLinkCard from "../../components/AddBiafLink/AddBiafLinkCard";
import BiafIncludedIn from "../../components/BiafIncludedIn/BiafIncludedIn";

const Product = () => {
  const productType = useSelector(getCurrentProductType);

  const dillContent = (
    <Grid>
      <Section>
        <ProductForm />
      </Section>
      <Section large>
        <PublishCard />
        <ClassroomCard />
        <TaxonomyCard />
      </Section>
    </Grid>
  );

  const xPubContent = (
    <Grid>
      <Section>
        <ProductForm />
      </Section>
      <Section large>
        <PublishCard />
        <ClassroomCard />
        <TaxonomyCard />
      </Section>
    </Grid>
  );

  const adaptContent = (
    <Grid>
      <Section>
        <ProductForm />
      </Section>
      <Section large>
        <ProductView />
      </Section>
    </Grid>
  );

  const omniaContent = (
    <Grid>
      <Section>
        <ProductForm />
      </Section>
      {productType === "omnia_book" && (
        <Section large>
          <CreateHurixBookCard />
          <AddBiafLinkCard />
        </Section>
      )}
      {productType === "omnia_course" && (
        <Section large>
          <RelatedProductsCard />
        </Section>
      )}
      {productType === "omnia_biaf" && (
        <Section large>
          <BiafContentCard />
          <BiafIncludedIn />
        </Section>
      )}
    </Grid>
  );

  const isDill = productType === "dill" || productType === "dill_cloud";
  const isXPub = productType === "xPub" || productType === "xPub_cloud";
  const isOmnia =
    productType === "omnia_course" ||
    productType === "omnia_book" ||
    productType === "omnia_biaf";

  return (
    <React.Fragment>
      {productType === "adapt" && adaptContent}
      {isDill && dillContent}
      {isXPub && xPubContent}
      {isOmnia && omniaContent}
    </React.Fragment>
  );
};

export default Product;
