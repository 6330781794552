import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  DataTable,
  TextColumn,
  withActions,
  selectable
} from "../../../shared";
import ImageColumn from "./Columns/ImageColumn";
import MenuActionColumn from "../../../shared/components/DataTable/Columns/MenuActionColumn";
import StickerActions from "../../components/StickerActions";

const StyledDataTable = withActions(DataTable);
const SelectableTable = styled(selectable(StyledDataTable))`
  height: calc(100% - 22px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const sortOnTitle = (list, order) => {
  const t = list.sort((a, b) => {
    if (order === "DESC") {
      return b.original_filename.localeCompare(a.original_filename, "se", {
        sensitivity: "base"
      });
    }
    return a.original_filename.localeCompare(b.original_filename, "se", {
      sensitivity: "base"
    });
  });
  return [...t];
};

const addCallbackToList = (list, deleteCallback) => {
  return list.map(l => ({...l, deleteCallback}))
}

const StickersList = ({ stickers }) => {
  const [selectedStickers, setSelectedStickers] = useState([]);
  const [sortedStickers, setSortedStickers] = useState([]);

  useEffect(() => {
    setSortedStickers(sortOnTitle(stickers));
  }, [stickers]);

  const _onSort = s => {
    const sortorder = Object.values(s);
    const a = sortOnTitle(sortedStickers, sortorder[0]);
    setSortedStickers(a);
  };

  const deleteCallback = ([id]) => {
    const list = selectedStickers.filter(s => s.id !== id)
    setSelectedStickers(list)
  }

  const onSelect = a => setSelectedStickers(a);

  return (
    <>
      <StickerActions
        selected={selectedStickers}
        setSelected={setSelectedStickers}
      />
      {stickers.length > 0 && (
        <SelectableTable
          list={addCallbackToList(sortedStickers, deleteCallback)}
          onSort={_onSort}
          selectedPosts={selectedStickers}
          onSelect={onSelect}
        >
          <ImageColumn dataKey="url" label="" width={0.2} />
          <TextColumn dataKey="original_filename" label="Titel" width={0.8} />
          <MenuActionColumn
            dataKey="actions"
            label=""
            fixedWidth
            width={40}
            disableSort
          />
        </SelectableTable>
      )}
    </>
  );
};

export default StickersList;
