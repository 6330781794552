import * as querystring from "querystring";
import { client } from "../../api";
import { MEDIA_RESOURCE } from "../../constants";

/**
 *
 * @param {Object} args - Map of Post attribute names to values.
 * @param {Object} searchParameters - Map of search parameters
 */
export const listMediaResources = ({ entityId }, searchParameters) =>
  listPosts({ entityId, type: MEDIA_RESOURCE.toLowerCase() }, searchParameters);

const listPosts = ({ entityId, type }, searchParameters) => {
  const params = Object.assign(
    {
      type
    },
    searchParameters,
    {
      sort_orders: searchParameters.sort
        ? Object.keys(searchParameters.sort).map(key =>
            `${key}:${searchParameters.sort[key]}`.toLowerCase()
          )
        : []
    }
  );

  delete params.sort;
  return client.get(
    `entity/${entityId}/posts?${querystring.stringify(params)}`
  );
};

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 */
export const fetchMediaResource = ({ entityId, postId }) =>
  fetchPost({ entityId, postId, type: MEDIA_RESOURCE.toLowerCase() });

const fetchPost = ({ entityId, postId, type }) => {
  const params = Object.assign({
    type
  });

  if (postId === "new") {
    return new Promise(resolve => {
      resolve({
        data: {
          data: {
            id: null,
            entity_id: entityId,
            title: "Untitled",
            description: "Undescribed",
            type: type,
            data: JSON.stringify({
              data: [],
              type: "blockArray"
            }),
            tags: []
          }
        }
      });
    });
  }
  return client.get(
    `entity/${entityId}/post/${postId}?${querystring.stringify(params)}`
  );
};

export const saveMediaResource = data =>
  savePost({ ...data, type: MEDIA_RESOURCE.toLowerCase() });

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const savePost = ({
  entityId,
  postId,
  title,
  description,
  data,
  difficulty = 0,
  resource_ids = null,
  media_urls = null,
  components = null
}) => {
  return client.put(`entity/${entityId}/post/${postId}`, {
    title,
    description,
    data,
    difficulty,
    resource_ids,
    media_urls,
    components
  });
};

export const createMediaResource = data =>
  createPost({ ...data, type: MEDIA_RESOURCE.toLowerCase() });

export const createPost = ({
  entityId,
  title,
  description,
  data,
  type,
  difficulty = 0,
  category_id = 0,
  resource_ids = null,
  media_urls = null,
  components = null,
  metadata = '{}'
}) => {
  return client.post(`entity/${entityId}/post`, {
    title,
    description,
    data,
    type,
    difficulty,
    category_id: "" + category_id,
    resource_ids,
    media_urls,
    components,
    metadata
  });
};

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const addTagsToPost = data => client.put(`tagpost`, data);

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const removeTagsFromPost = data => client.delete(`tagpost`, { data });

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchCommentsForPost = ({ entityId, postId }) => {
  return client.get(`entity/${entityId}/post/${postId}/comments`);
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const addCommentToPost = ({ entityId, postId, comment }) => {
  return client.post(`entity/${entityId}/post/${postId}/comment`, {
    message: comment
  });
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchTagFilter = ({ entityId, posttype }) => {
  const params = { entity_id: entityId, tags: true, type: posttype };
  return client.get(`tagcategories?${querystring.stringify(params)}`);
};

/**
 *
 *
 */
export const fetchLabelFilter = ({ posttype }) => {
  const params = { tags: true, type: posttype };
  return client.get(`tagcategories?${querystring.stringify(params)}`);
};

/**
 *
 * @param {Object} entityId - Map of entityId
 */
export const fetchTemplateFilter = ({ entityId }) => {
  return client.get(`entity/${entityId}/templates`);
};

export const replaceResourceRefsForPost = ({
  entityId,
  postId,
  resourceIds
}) => {
  return client.put(`/entity/${entityId}/post/${postId}/resources`, {
    resource_ids: resourceIds
  });
};

export const setCategory = ({ entityId, posts, categoryId }) =>
  client.post(`entity/${entityId}/post/category/set`, { posts, categoryId });

export const unsetCategory = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/category/unset`, { posts });

export const publishPosts = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/publish`, { posts });

export const unpublishPosts = ({ entityId, posts }) =>
  client.post(`entity/${entityId}/post/retract`, { posts });

export const deletePosts = ({ entityId, data }) =>
  client.delete(`entity/${entityId}/posts`, { data });
