import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_PRODUCT_NEWS, ROUTE_DETAILED_PRODUCT_NEWS } from "../routes";
import {
  fetchEntitiesSaga,
  unpublishPostsSaga,
  deletePostsSaga,
  savePostsSaga,
  fetchEntityDetailsSaga,
  fetchAllChipsForEntitySaga,
  addRemoveChipsToPostSaga
} from "../../posts/api/saga";

import { publishMessagePostsSaga } from "./sagas";

import {
  PUBLISH_POSTS,
  UNPUBLISH_POSTS,
  DELETE_POSTS,
  SAVE_ENTITY_DETAILS,
  CREATE_ENTITY_DETAILS,
  SAVE_POST,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  RELOAD_POST,
  RELOAD_POSTS,
  SORT_POSTS,
  FILTER_POSTS,
  FETCH_CHIPS,
  POST_ADD_REMOVE_CHIPS
} from "../../posts/store/actions";
import {
  onListRoute,
  sortList,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onDetailedRoute,
  onFetchChips
} from "../../posts/sagas/sagas";

import { FETCH_ENTITIES, FETCH_ENTITY_DETAILS } from "../../api";
import { actionCreator } from "../../shared";
import { ENTITIES, PRODUCTNEWS } from "../../constants";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(PRODUCTNEWS, FETCH_ENTITIES),
    fetchEntitiesSaga(ENTITIES[PRODUCTNEWS], "listProductNewsPosts")
  );
  yield takeLatest(
    actionCreator(PRODUCTNEWS, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[PRODUCTNEWS], "fetchProductNewsPost")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[PRODUCTNEWS], "saveProductNewsPost")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[PRODUCTNEWS], "createProductNewsPost")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, SAVE_POST),
    savePostsSaga(ENTITIES[PRODUCTNEWS], "savePost")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, RELOAD_POST),
    onDetailedRoute,
    ENTITIES[PRODUCTNEWS],
    "fetchProductNewsPost"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, SORT_POSTS),
    sortList,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeLatest(
    actionCreator(PRODUCTNEWS, FILTER_POSTS),
    filterPosts,
    ENTITIES[PRODUCTNEWS],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, POST_ADD_REMOVE_CHIPS),
    addRemoveChipsToPostSaga(ENTITIES[PRODUCTNEWS], "addRemoveChipsToPost")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, PUBLISH_POSTS),
    publishMessagePostsSaga(ENTITIES[PRODUCTNEWS], "publishPosts")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, UNPUBLISH_POSTS),
    unpublishPostsSaga(ENTITIES[PRODUCTNEWS], "unpublishPosts")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, DELETE_POSTS),
    deletePostsSaga(ENTITIES[PRODUCTNEWS], "deletePosts")
  );
  yield takeEvery(
    actionCreator(PRODUCTNEWS, FETCH_CHIPS),
    fetchAllChipsForEntitySaga(ENTITIES[PRODUCTNEWS], "fetchAllChipsForEntity")
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_PRODUCT_NEWS,
    onListRoute,
    ENTITIES[PRODUCTNEWS],
    "listProductNewsPosts"
  );
  yield takeEvery(
    ROUTE_PRODUCT_NEWS,
    onFetchChips,
    ENTITIES[PRODUCTNEWS],
    "fetchAllChips"
  );
  yield takeEvery(
    ROUTE_DETAILED_PRODUCT_NEWS,
    onDetailedRoute,
    ENTITIES[PRODUCTNEWS],
    "fetchProductNewsPost"
  );
  yield takeEvery(
    ROUTE_DETAILED_PRODUCT_NEWS,
    onFetchChips,
    ENTITIES[PRODUCTNEWS],
    "fetchAllChips"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
