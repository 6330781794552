import React from "react";
import SetFetchLimit from "../../containers/SetFetchLimit";
import { Pagination } from "../../../posts/containers/ToolBar/PostActions";

const PaginationWithLimitChange = () => {
  return (
    <>
      <SetFetchLimit />
      <Pagination />
    </>
  );
};

export default PaginationWithLimitChange;
