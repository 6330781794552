import React from "react";

const HelpResourceAdd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-helpresourceadd"
  >
    <g
      id="Icon/HelpResource-Outline-Add"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M25,0 L25.208,3.124 L14.0625,3.125 C13.2490234,3.125 12.5784023,3.750428 12.5063996,4.54552101 L12.5,4.6875 L12.5,9.375 L25.625,9.375 L25.867,12.999 L3,13 L3,47 L37,47 L37,25 L40.625,25 L40.625,37.5 L45.3125,37.5 C46.1738281,37.5 46.875,36.7988281 46.875,35.9375 L46.875,35.9375 L46.875,25 L50,25 L50,35.9375 C50,38.5263672 47.9013672,40.625 45.3125,40.625 L45.3125,40.625 L40.625,40.625 L40.625,45.3125 C40.625,47.9013672 38.5263672,50 35.9375,50 L35.9375,50 L4.6875,50 C2.09863281,50 -7.10542736e-15,47.9013672 -7.10542736e-15,45.3125 L-7.10542736e-15,45.3125 L0,14.0625 C0,11.4736328 2.09863281,9.375 4.6875,9.375 L4.6875,9.375 L9.375,9.375 L9.375,4.6875 C9.375,2.09863281 11.4736328,-7.10542736e-15 14.0625,-7.10542736e-15 L14.0625,-7.10542736e-15 L25,0 Z"
        id="Combined-Shape"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <g
        id="Help"
        transform="translate(5.000000, 15.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <path
          d="M15,0 C23.28,0 30,6.72 30,15 C30,23.28 23.28,30 15,30 C6.72,30 0,23.28 0,15 C0,6.72 6.72,0 15,0 Z M15,3 C8.385,3 3,8.385 3,15 C3,21.615 8.385,27 15,27 C21.615,27 27,21.615 27,15 C27,8.385 21.615,3 15,3 Z M16.5,21 L16.5,24 L13.5,24 L13.5,21 L16.5,21 Z M15,6 C11.685,6 9,8.685 9,12 L12,12 C12,10.35 13.35,9 15,9 C16.65,9 18,10.35 18,12 C18,15 13.5,14.625 13.5,19.5 L16.5,19.5 C16.5,16.125 21,15.75 21,12 C21,8.685 18.315,6 15,6 Z"
          id="Shape"
        ></path>
      </g>
      <path
        d="M49.09375,9.375 L40.5,9.375 L40.5,0.78125 C40.5,0.349609375 40.1503906,0 39.71875,0 L38.15625,0 C37.7246094,0 37.375,0.349609375 37.375,0.78125 L37.375,9.375 L28.78125,9.375 C28.3496094,9.375 28,9.72460938 28,10.15625 L28,11.71875 C28,12.1503906 28.3496094,12.5 28.78125,12.5 L37.375,12.5 L37.375,21.09375 C37.375,21.5253906 37.7246094,21.875 38.15625,21.875 L39.71875,21.875 C40.1503906,21.875 40.5,21.5253906 40.5,21.09375 L40.5,12.5 L49.09375,12.5 C49.5253906,12.5 49.875,12.1503906 49.875,11.71875 L49.875,10.15625 C49.875,9.72460938 49.5253906,9.375 49.09375,9.375 Z"
        id="Path"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default HelpResourceAdd;
