import * as React from "react";
import { Input } from "../Input";
import { renderMultiSVG } from "../MultiSvgImageHelpers";
import {
  StyledItem,
  StyledEditItemColumn,
  StyledEditInputWrapper,
  StyledEditButtonsColumn,
  StyledButtonRow,
  StyledImage,
  StyledMediaResource,
  StyledTextArea,
  StyledAudioWrapper
} from "./StyledMathWord";
import Text from "../../../shared/components/Text/Text";
import {
  Media,
  Sound,
  Plus,
  Minus,
  ImageResourceIcon
} from "../../../shared/components/StudliIcons";
import { AddMediaButton } from "../../../shared/components/AddMediaButton";

export const MathWordItem = ({
  data,
  index,
  onChangeHandler,
  openMediaLibrary,
  openMediaResourceList,
  removeMedia,
  resources,
  blockKey,
  onChange,
  settings,
  storeDraft,
  draftTarget,
  readOnly
}) => {
  /**
   * new title input to onChangeHandler
   * @param {*} v
   */
  const _onTitleChange = v => {
    if (!readOnly) {
      storeDraft(draftTarget);
    }

    onChangeHandler(index, { title: v });
  };

  /**
   * new text input to onChangeHandler
   * @param {*} v
   */
  const _onTextChange = (key, value, target) => {
    onChangeHandler(index, { text: value });
  };

  /**
   * Function for inputfield to catch enter keystroke
   */
  const _onEnter = () => {};

  /**
   * If a media is already chosen it will remove media
   * otherwise the mediamodal is opened.
   */
  const _openSound = () =>
    data.soundsrc ? removeMedia(index, "soundsrc") : openMediaLibrary(index);

  /**
   * If a media is already chosen it will remove media
   * otherwise the mediamodal is opened.
   */
  const _openMedia = () =>
    data.mediasrc ? removeMedia(index, "mediasrc") : openMediaLibrary(index);

  /**
   * If a mediaresource is already chosen it will remove mediaresource
   * otherwise the mediaresource modal is opened.
   */
  const _openMediaResource = () =>
    data.mediaresource
      ? removeMedia(index, "mediaresource")
      : openMediaResourceList(index);

  /**
   * Renders input for new options
   * @returns {*}
   */
  const renderTitleInput = () => (
    <StyledEditInputWrapper>
      <Input
        name="matteord"
        placeholder="Lägg till ett matteord..."
        value={data.title}
        onChange={_onTitleChange}
        onEnter={_onEnter}
      />
    </StyledEditInputWrapper>
  );

  /**
   * Render media button row, sound, image and mediaresource buttons
   */
  const renderButtonRow = () => (
    <StyledButtonRow>
      {renderAddButton(_openSound, data.soundsrc, soundIcon)}
      {renderAddButton(_openMedia, data.mediasrc, mediaIcon, "#377AD3")}
      {renderAddButton(
        _openMediaResource,
        data.mediaresource,
        mediaResourceIcon,
        "#384E70"
      )}
    </StyledButtonRow>
  );

  /**
   * Sound icon
   */
  const soundIcon = <Sound size={"25"} />;

  /**
   * Media icon
   */
  const mediaIcon = <Media size={"25"} />;

  /**
   * Mediaresource icon
   */
  const mediaResourceIcon = <ImageResourceIcon color={"white"} size={"25"} />;

  /**
   * Render media buttons
   * @param {*} callback
   * @param {*} condition
   * @param {*} icon
   * @param {*} bgColor
   */
  const renderAddButton = (callback, condition, icon, bgColor) =>
    settings &&
    !settings.readOnly && (
      <AddMediaButton callback={callback} bgColor={bgColor}>
        {condition ? <Minus size={"16"} /> : <Plus size={"16"} />}
        {icon}
      </AddMediaButton>
    );

  /**
   * Render image
   */
  const renderEditImage = () =>
    data.mediasrc !== null ? (
      <StyledImage>
        <img alt="" src={data.mediasrc} />
      </StyledImage>
    ) : null;

  /**
   * Render Media Resource
   */
  const renderMediaResource = () => {
    const mrData = data.mediaresource && data.mediaresource.data;
    return !!mrData ? (
      <StyledMediaResource>
        {renderMultiSVG(
          mrData.id,
          { [mrData.id]: mrData.src, ...resources },
          600
        )}
      </StyledMediaResource>
    ) : null;
  };

  /**
   * Render sound player
   */
  const renderEditSound = () =>
    data.soundsrc !== null ? (
      <StyledAudioWrapper>
        <audio controls={true} src={data.soundsrc} />
      </StyledAudioWrapper>
    ) : null;

  /**
   * Render Text editor
   */
  const renderEditText = () => (
    <StyledTextArea>
      <Text
        blockKey={blockKey}
        updateData={_onTextChange}
        data={data}
        settings={settings}
        onChange={onChange}
        draftTarget={draftTarget}
        storeDraft={storeDraft}
      />
    </StyledTextArea>
  );

  return (
    <StyledItem>
      <StyledEditItemColumn>
        {renderTitleInput()}
        {renderEditImage()}
        {renderMediaResource()}
        {renderEditText()}
        {renderEditSound()}
      </StyledEditItemColumn>
      <StyledEditButtonsColumn>{renderButtonRow()}</StyledEditButtonsColumn>
    </StyledItem>
  );
};

export default MathWordItem;
