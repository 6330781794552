import { JSONEditor } from "./components/JSONEditor";

export const createJSONEditorPlugin = () => ({
  entityRendererFn: entity => {
    if (!!entity.type) {
      return {
        component: JSONEditor,
        props: {
          json: JSON.stringify(entity.data)
        }
      };
    }

    return null;
  }
});

export default createJSONEditorPlugin;
