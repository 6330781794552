import { MARKERS } from "../../../constants";
import { actionCreator } from "../../../shared";

export const SAVE_ENTITY = "SAVE_ENTITY";
export const UPLOAD_MEDIA_FILE = "UPLOAD_MEDIA_FILE";
export const UPDATE_MARKER = "UPDATE_MARKER";
export const UPDATE_MARKER_SUCCESS = "UPDATE_MARKER_SUCCESS";
export const UPDATE_MARKER_FAILED = "UPDATE_MARKER_FAILED";
export const UPDATE_MARKER_ITEM = "UPDATE_MARKER_ITEM";
export const UPDATE_MARKER_ITEM_SUCCESS = "UPDATE_MARKER_ITEM_SUCCESS";
export const UPDATE_MARKER_ITEM_FAILED = "UPDATE_MARKER_ITEM_FAILED";
export const UPDATE_MARKER_COLOR_THEME = "UPDATE_MARKER_COLOR_THEME";
export const UPDATE_MARKER_COLOR_THEME_SUCCESS = "UPDATE_MARKER_COLOR_THEME_SUCCESS";
export const UPDATE_MARKER_COLOR_THEME_FAILED = "UPDATE_MARKER_COLOR_THEME_FAILED";
export const PUBLISH_MARKERS = "PUBLISH_MARKERS";
export const PUBLISH_MARKERS_ERROR = "PUBLISH_MARKERS_ERROR";
export const PUBLISH_MARKERS_SUCCESS = "PUBLISH_MARKERS_SUCCESS";

export const EXPAND = "EXPAND";
export const EXPAND_CLOSE_ELEMENT = "EXPAND_CLOSE_ELEMENT";

export const uploadMediaFile = (id,file) =>({
  type: actionCreator(MARKERS, UPLOAD_MEDIA_FILE),
  id,
  file
});

export const updateMarkerSuccess = (entityName, id,url,name) => ({
  type: actionCreator(entityName, UPDATE_MARKER_SUCCESS),
  id,
  url,
  name
});

export const updateMarkersFailed = (entityName, error) => ({
  type: actionCreator(entityName, UPDATE_MARKER_FAILED),
  error
});

export const updateMarkerItem = (id,file) => ({
  type: actionCreator(MARKERS, UPDATE_MARKER_ITEM),
  id,
  file
});

export const updateMarkerItemSuccess = (entityName, id,itemUrl,fileName) => ({
  type: actionCreator(entityName, UPDATE_MARKER_ITEM_SUCCESS),
  id,
  itemUrl,
  fileName
});

export const updateMarkerItemFailed = (entityName, error) => ({
  type: actionCreator(entityName, UPDATE_MARKER_ITEM_FAILED),
  error
});

export const updateMarkerColorTheme = (id,data) => ({
  type: actionCreator(MARKERS, UPDATE_MARKER_COLOR_THEME),
  id,
  data
});

export const updateMarkerColorThemeSuccess = (entityName,groupId,themeId) => ({
  type:actionCreator(entityName, UPDATE_MARKER_COLOR_THEME_SUCCESS),
  groupId,
  themeId
});

export const updateMarkerColorThemeFailed = (entityName, error) => ({
  type: actionCreator(entityName, UPDATE_MARKER_COLOR_THEME_FAILED),
  error
});

export const expandTree = (entityName, param) => ({
  type: actionCreator(entityName, EXPAND),
  param    
});

export const expandTreeElement = (entityName, id, groupType, param) => ({
  type: actionCreator(entityName, EXPAND_CLOSE_ELEMENT),
  id,
  groupType,
  param   
});

export const publishMarkers = (entityName) => ({
  type: actionCreator(entityName, PUBLISH_MARKERS)
});

export const publishMarkersError = (entityName, error) => ({
  type: actionCreator(entityName, PUBLISH_MARKERS_ERROR),
  data: error
});

export const publishMarkersSuccess = (entityName) => ({
  type: actionCreator(entityName, PUBLISH_MARKERS_SUCCESS)
});
