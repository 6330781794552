import HelpImage from "./components/Help/HelpImage/HelpImage";
import { getMediaObject } from "./dataModels";
import { IMAGE, TIPS, AUDIO, MEDIA_RESOURCE } from "../constants";

const types = [TIPS];

const getUsedResources = ({ data }) =>
  data.mediaresource
    ? data.mediaresource.data.id ? [data.mediaresource.data.id] : []
    : [];

export const createTipsPlugin = () => ({
  entityRendererFn: entity => {

    const settings = {
      readOnly: false,
      multiLine: true
    };

    if (types.includes(entity.type)) {
      return {
        component: HelpImage,
        style: { padding: "0px" },
        props: {
          data: entity.data,
          settings: { ...settings, ...entity.settings }
        }
      };
    }
    return null;
  },
  types,
  getUsedResources
});

export default createTipsPlugin;

export const emptyBlocks = {
  [TIPS]: {
    type: TIPS,
    data: {
      title: null,
      text: null,
      [IMAGE]: getMediaObject(IMAGE),
      [AUDIO]: getMediaObject(AUDIO),
      [MEDIA_RESOURCE]: getMediaObject(MEDIA_RESOURCE)
    },
    settings: {}
  }
};
