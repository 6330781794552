import MediaResources from "./views/MediaResources";
import MediaEditor from "./views/MediaEditor";

import { ENTITIES, MEDIA_RESOURCE } from "../constants";

export const ROUTE_MEDIARESOURCES = "ROUTE_MEDIARESOURCES";
export const ROUTE_MEDIARESOURCE_EDIT = "ROUTE_MEDIARESOURCE_EDIT";

export const routes = {
  [ROUTE_MEDIARESOURCES]: {
    name: ENTITIES[MEDIA_RESOURCE],
    path: "/products/:entityId/mediaresources",
    query: {},
    filters: ["SEARCH", "LABELS", "TAGS"],
    hideFilters: false,
    noServiceBar: true,
    text: "Sammansatta bilder",
    icon: "ImageResourceIcon",
    childRoutes: [ROUTE_MEDIARESOURCE_EDIT]
  },
  [ROUTE_MEDIARESOURCE_EDIT]: {
    name: ENTITIES[MEDIA_RESOURCE],
    path: "/products/:entityId/mediaresource/edit/:id",
    noServiceBar: false,
    hideFilters: true,
    filters: ["SEARCH", "LABELS", "TAGS"],
    confirmLeave: state =>
      confirmDirtyDraftStateLeave(state)
  }
};

export const views = {
  [ROUTE_MEDIARESOURCES]: MediaResources,
  [ROUTE_MEDIARESOURCE_EDIT]: MediaEditor
};

const confirmDirtyDraftStateLeave = (state) => {
  const draftState = state["Mediaresource"].get("draftState");
  if (draftState !== "dirty") {
    return;
  }
  return "Du har gjort ändringar som inte kommer sparas om du väljer att lämna sidan.";
};
