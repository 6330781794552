import styled from "styled-components";

export const StyledModalContainer = styled.div`
  ${props =>
    props.isOpen
      ? `
        width: 100%;
        height: 100%;
        display: flex;
        alignItems: center;
        justifyContent: center;
      `
      : ""}
`;

export const StyledModalActions = styled.div`
  display: flex;
  flex: 1;
  justifycontent: flex-end;
`;
