import ButtonGroup from "../../../shared/components/ButtonGroup";
import {
  StandardButton,
  PrimaryButton
} from "../../../shared/components/Button";
import { modalOpen, modalClose } from "../../../shared";

export const deleteStickerModal = dispatch => ({ onClickOK, selected }) => {
  const onOK = () => {
    modalClose(dispatch);
    onClickOK();
  };

  const onCloseModal = () => {
    modalClose(dispatch);
  };

  const header = "Ta bort";
  const children = (
    <div>
      {selected.length > 1
        ? `Vill du ta bort dessa ${selected.length} stickers?`
        : `Vill du ta bort denna stickerbild?`}
    </div>
  );
  const actions = (
    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
      <ButtonGroup>
        <StandardButton onClick={onCloseModal}>Avbryt</StandardButton>
        <PrimaryButton onClick={onOK}>Ta bort</PrimaryButton>
      </ButtonGroup>
    </div>
  );
  modalOpen(dispatch)({
    children,
    header,
    actions,
    closeModal: onCloseModal
  });
};
