import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { SelectField } from "../../../products/components/Input";
import { selectSeriesFilters } from "../../store/selectors";
import { translation } from "../../../constants";
import { filterSeries } from "../../api/actions";

const StyledSelectField = styled.div`
  margin-top: 1rem;
  max-width: 5rem;
  padding: 0px 15px;

  select {
    width: 250px;
  }
`;

const FilterSeriesList = () => {
  const dispatch = useDispatch();
  const filters = ["all", ...useSelector(selectSeriesFilters)];
  const [selected, setSelected] = useState("all");
  const onChange = value => {
    dispatch(filterSeries(value));
    setSelected(value);
  };

  return (
    <StyledSelectField>
      <SelectField
        value={selected}
        onChange={onChange}
        label="Visa"
        name="filter"
        showValue
        options={filters.map(v => ({ val: v, key: translation[v] }))}
      />
    </StyledSelectField>
  );
};

export default FilterSeriesList;
