import React from "react";

const AlignCenter = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-aligncenter"
  >
    <path
      transform="translate(25.000000, 20.000000) rotate(-270.000000) translate(-25.000000, -20.000000) translate(8.000000, 0.000000)"
      fill="#377AD3"
      d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,10 L34,10 L34,30 L24,30 L24,10 Z"
      id="path-3"
    ></path>
    <path
      fill="#384E70"
      transform="translate(25.000000, 30.000000) rotate(-270.000000) translate(-25.000000, -30.000000) translate(8.000000, 10.000000)"
      d="M0,0 L10,0 L10,40 L0,40 L0,0 Z M24,10 L34,10 L34,30 L24,30 L24,10 Z"
      id="path-1"
    ></path>

    <rect
      id="Rectangle"
      fill="#000000"
      transform="translate(25.000000, 25.000000) rotate(-90.000000) translate(-25.000000, -25.000000) "
      x="0"
      y="24"
      width="50"
      height="2"
    ></rect>
  </svg>
);

export default AlignCenter;
