import ProductList from "./views/products";
import Product from "./views/product";

export const ROUTE_PRODUCT = "ROUTE_PRODUCT";
export const ROUTE_PRODUCTS = "ROUTE_PRODUCTS";
export const ROUTE_PRODUCT_SETTINGS = "ROUTE_PRODUCT_SETTINGS";

export const routes = {
  [ROUTE_PRODUCTS]: {
    path: "/",
    query: {},
    noServiceBar: true,
    navLogoLink: true,
    hideNavigation: false,
    hideFilters: true,
    type: "default",
    name: "Productlist"
  },
  [ROUTE_PRODUCT]: {
    path: "/products/:entityId",
    query: {},
    noServiceBar: true,
    text: "Produkt",
    icon: "Home",
    hideFilters: true,
    type: "product",
    filters: ["MEDIASEARCH"],
  }
};

export const views = {
  [ROUTE_PRODUCTS]: ProductList,
  [ROUTE_PRODUCT]: Product
};
