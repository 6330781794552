import React from "react";
import FilterSection from "../FilterSection";
import { connect } from "react-redux";
import {
  StyledFilterContainer,
  StyledFilterButton,
  StyledFilterButtonWrapper
} from "./styles";
import {
  selectAndOrderFilterList,
  hasFilters
} from "../../containers/Filters/filterdata";
import { selectSearchParams } from "../../../mediaresource/sagas/selectors";

class MediaModalFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
      searchValue: undefined
    };
  }

  componentDidMount() {
    if (this.props.conditions) {
      this.handleCondition(this.props.conditions);
    }
  }

  /**
   * Helper function to generate a query object when a tag-filter has changed
   * @param {array} value  the new value
   * @param {string} name  tha name of the tag-section
   */
  getSearchQueryForTags = (value, name) => {
    const { query = {} } = this.state;
    const { filterList } = this.props;
    const section = filterList.filter(section => section.title === name)[0];
    const sectionTagIds = (
      (section && section.data && section.data[0] && section.data[0].tags) ||
      []
    ).map(t => +t.id);
    let tags = (query && query.tags) || [];
    if (!Array.isArray(tags)) {
      tags = [tags];
    }
    const queryTags = tags.map(tid => +tid);
    const valueInSection = value.filter(tid => sectionTagIds.indexOf(tid) >= 0);
    const queryNotInSection = queryTags.filter(
      tid => sectionTagIds.indexOf(tid) < 0
    );
    return { ...query, tags: [...queryNotInSection, ...valueInSection] };
  };

  /**
   * Helper function to generate a query object when a filter that isn't tags has changed
   * @param {string} key  the filter-key
   * @param {*} value  the new value
   */
  getSearchQuery = (key, value) => {
    const { query = {} } = this.state;
    let newFilter = {};
    if (value !== undefined && value !== null && value !== "") {
      newFilter[key] = value;
    }
    const newQuery = Object.keys(query).reduce((acc, k) => {
      if (k !== key) {
        acc[k] = query[k];
      }
      return acc;
    }, {});
    return { ...newQuery, ...newFilter };
  };

  /**
   * Called when search-text-field changes
   * @param {string} value
   */
  onSearchChange = value => {
    if (value === this.state.searchValue) {
      return;
    }
    const { query } = this.state;
    const searchParams = { ...query, search: value };
    this.props.filterMedia(searchParams);
    this.setState({
      searchValue: value
    });
  };

  /**
   * Clear all filters
   */
  clearFilters = () => {
    const hasFilterTypeInQuery =
      this.props.searchParameters.type &&
      !Array.isArray(this.props.searchParameters.type);
    const queryAfterClear = hasFilterTypeInQuery
      ? { type: this.props.searchParameters.type }
      : {};
    this.props.filterMedia(queryAfterClear);
    this.setState({
      searchValue: undefined,
      query: queryAfterClear
    });
  };

  /**
   * Called when a filter changes
   * @param {*} value
   * @param {string} name
   * @param {string} filterkey
   */
  onFilterChange = (value, name, filterkey) => {
    const { searchParameters } = this.props;
    const searchParams =
      filterkey === "tags"
        ? this.getSearchQueryForTags(value, name)
        : this.getSearchQuery(filterkey, value);

    const updatedParams = { ...searchParameters, ...searchParams };
    this.props.filterMedia(updatedParams);
    this.setState({
      query: updatedParams,
      searchValue: undefined
    });
  };

  /**
   * Handle conditions
   */
  handleCondition = () => {
    this.onFilterChange(
      this.props.conditions.filters.selected,
      "Mediatyp",
      "type"
    );
  };

  /**
   * Get the current search value based on the component state
   * @returns {string}
   */
  getSearchValue = () => {
    const { searchValue, query } = this.state;
    return searchValue !== undefined ? searchValue : query && query.search;
  };

  /**
   * Checks if the searchParameters from store has values for search or tags.
   */
  hasSearchParams = () => {
    const { searchParameters } = this.props;
    const search =
      searchParameters &&
      searchParameters.search !== undefined &&
      searchParameters.search !== "";
    const tags =
      searchParameters &&
      searchParameters.tags &&
      searchParameters.tags.length > 0;
    return search || tags;
  };

  /**
   * Filters the filter so that only desired filters sections are displayed
   * @returns {T[]}
   */
  filterByConditions = () =>
    this.props.conditions
      ? this.props.filterList.filter(
          filter => !this.props.conditions.filters["hide"].includes(filter.key)
        )
      : this.props.filterList;

  /**
   * Renders filter list
   * @returns {any[]}
   */
  renderFilterList = () => {
    return this.filterByConditions().map(fs => (
      <FilterSection
        key={fs.title}
        content={fs}
        searchValue={this.getSearchValue()}
        onSearchChange={this.onSearchChange}
        onFilterChange={this.onFilterChange}
        query={this.state.query}
      />
    ));
  };

  render() {
    return (
      <StyledFilterContainer>
        <StyledFilterButtonWrapper>
          {(hasFilters(this.state.query, this.state.searchValue) ||
            this.hasSearchParams()) && (
            <StyledFilterButton onClick={this.clearFilters}>
              Rensa filter
            </StyledFilterButton>
          )}
        </StyledFilterButtonWrapper>
        {this.renderFilterList()}
      </StyledFilterContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    filterList: selectAndOrderFilterList(state, props.type),
    searchParameters: selectSearchParams(state, props.type)
  };
};

export default connect(mapStateToProps)(MediaModalFilters);
