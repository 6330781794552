import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Trash2 as Trash } from "react-feather";

import Label from "./Label";
import ProductSearch from "./ProductSearch";
import {
  getProductInfo,
  getProductsOfCurrentProductType,
  getTwinProduct
} from "../store/selectors";
import { connectTwin, disconnectTwin } from "../api/actions";
import IconButton from "./IconButton";

export const Search = styled(ProductSearch)`
  width: 100%;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const TwinWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TwinText = styled.p`
  margin: 0;
`;

const TwinHandler = () => {
  const dispatch = useDispatch();
  const twinProduct = useSelector(getTwinProduct);
  const [entity] = useSelector(getProductInfo);

  const removeTwin = () => dispatch(disconnectTwin(entity.id, twinProduct.id));
  const addTwin = twinId => dispatch(connectTwin(entity.id, twinId));

  return (
    <>
      {!twinProduct && (
        <Label>
          Tvilling
          <Search
            placeholder="Sök tvillingprodukt"
            onSelect={p => addTwin(p.id)}
            selector={getProductsOfCurrentProductType}
          />
        </Label>
      )}
      {!!twinProduct && (
        <>
          <Label as="h4">Tvilling</Label>
          <TwinWrapper>
            <IconButton
              aria-label="Ta bort tvilling koppling"
              onClick={removeTwin}
            >
              <Trash width="16px" height="16px" />
            </IconButton>
            <TwinText>{twinProduct.sesam.name}</TwinText>
          </TwinWrapper>
        </>
      )}
    </>
  );
};

export default TwinHandler;
