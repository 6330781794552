import { createReducer } from "../../../store/utils";

import {
  SET_MEDIA_CAROUSEL,
  NEXT_IN_MEDIA_CAROUSEL,
  PREV_IN_MEDIA_CAROUSEL
} from "./actions";

const initialState = {
  items: [],
  active: null
};

const mediaCarouselReducer = createReducer(initialState, {
  [SET_MEDIA_CAROUSEL](state, { items, active }) {
    return Object.assign({}, state, {
      items,
      active
    });
  },
  [NEXT_IN_MEDIA_CAROUSEL](state) {
    const i = state.items.findIndex(item => item === state.active) + 1;
    return Object.assign({}, state, {
      active: state.items[i > state.items.length - 1 ? 0 : i]
    });
  },
  [PREV_IN_MEDIA_CAROUSEL](state) {
    const i = state.items.findIndex(item => item === state.active) - 1;
    return Object.assign({}, state, {
      active: state.items[i < 0 ? state.items.length - 1 : i]
    });
  }
});

export { mediaCarouselReducer };
