import React from "react";
import { PageHeader } from "../../../shared";
import PostEditor from "../../containers/PostEditor";
import styled from "styled-components";

import { translation, ENTITIES, HELP_RESOURCE } from "../../../constants";

const HelpResourceDetailView = styled.div`
  background-color: ${props => props.theme.neutralLight};
  padding: 7px 10px;
`;

const helpresource = HELP_RESOURCE.toLowerCase();

const HelpResourceDetails = () => (
  <React.Fragment>
    <HelpResourceDetailView>
      <PageHeader studlicon="HelpResource" title={translation[helpresource]} />
    </HelpResourceDetailView>
    <PostEditor type={ENTITIES[helpresource]} />
  </React.Fragment>
);

export default HelpResourceDetails;
