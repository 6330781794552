import { fromJS } from "immutable";
import { createReducer } from "../../../store/utils";

import {
  COLLAPSE_NAVIGATION,
  OPEN_NAVIGATION,
  SET_SECTIONS,
  SET_TITLE,
  SET_SELECTED_PRODUCTID,
  SET_IF_PRODUCT_SELECTED_MESSAGE
} from "./actions";

const initialState = fromJS({
  collapsed: false,
  title: "Okänd produkt",
  sections: {},
  byMouseOver: false,
  sub: {},
  selectedProductEntityId: null,
  isProductSelectedMessageVisible: false
});

export default createReducer(initialState, {
  [COLLAPSE_NAVIGATION](state, action) {
    return state.set("collapsed", true).set("byMouseOver", action.status);
  },
  [OPEN_NAVIGATION](state, action) {
    return state.set("collapsed", false).set("byMouseOver", action.status);
  },
  [SET_TITLE](state, action) {
    return state.set("title", action.title);
  },
  [SET_SECTIONS](state, action) {
    return state.set(
      "sections",
      fromJS(action.sections, (_, v) =>
        !Array.isArray(v) ? v.toOrderedMap() : v.toList()
      )
    );
  },
  [SET_SELECTED_PRODUCTID](state, action) {
    return state.set("selectedProductEntityId", action.id);
  },
  [SET_IF_PRODUCT_SELECTED_MESSAGE](state, action) {
    return state.set("isProductSelectedMessageVisible", action.bool);
  }
});
