import React from "react";

const Subtraction = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-subtraction"
  >
  <path id="path-3" d="M15.8333333 10.8333333 4.16666667 10.8333333 4.16666667 9.16666667 15.8333333 9.16666667z"></path>  
</svg>
);

export default Subtraction;
