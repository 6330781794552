import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../shared/components/Button";
import Confirm from "../../shared/components/Confirm";
import { format } from "../../date";

const StyledButton = styled(Button)`
  margin: 0;
  width: 145px;
`;

const StyledButtonTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
`;
const StyledDateWrapper = styled.div`
  margin-left: 4px;
  font-size: 12px;
  margin-bottom: 4px;
  text-align: center;
  &.published-not-latest {
    color: #b00020;
  }
  color: #333333;
`;

const formatDate = dateString => {
  const date = new Date(dateString);
  return format(date);
};

const DiscontinueButton = ({ onClick, discontinueDate, disabled, ...rest }) => {
  const _onClick = e => onClick(e);
  const [showConfirmDiscontinueProduct, setShowConfirmDiscontinueProduct] = useState(false);
  return (
    <>
    <StyledButtonTimeWrapper>
      {discontinueDate && (
        <StyledDateWrapper>{formatDate(discontinueDate)}</StyledDateWrapper>
      )}
      <StyledButton
        onClick={() => setShowConfirmDiscontinueProduct(true)}
        type="button"
        disabled={disabled}
        skin="cancel"
        {...rest}
      >
        Lägg ner produkt
      </StyledButton>
    </StyledButtonTimeWrapper>
    {showConfirmDiscontinueProduct && (
        <Confirm
          confirmBtnText="Ja"
          onClose={go => {
            if (go) {
              _onClick();
            }
            setShowConfirmDiscontinueProduct(false);
          }}
        >
          <p>
            Är du säker på att du vill lägga ner produkten?
          </p>
        </Confirm>
      )}
    </>
  );
};

export default DiscontinueButton;
