import * as React from "react";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import PluginHoc from "../../../../shared/PluginHoc";
import {
  StyledHelpWrapper,
  StyledHelpContainer,
} from "../StyledHelp";

const HelpVideo = ({ data, settings, updateData, pluginHeader }) => {
  /**
   * Render sidebar
   */
  const renderSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      <StyledSidebarHeading backgroundColor={"#72A5AF"}>
        Inställningar
      </StyledSidebarHeading>
      <StyledSidebarSection>{/* SIDEBAR CONTENT\ */}</StyledSidebarSection>
    </StyledBlockSidebar>
  );

  return (
    <StyledHelpWrapper>
      <StyledHelpContainer>{pluginHeader}</StyledHelpContainer>
      {renderSidebar()}
    </StyledHelpWrapper>
  );
};

export default PluginHoc({
  Component: HelpVideo
});
