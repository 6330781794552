import * as React from "react";
import {
  COLUMN,
  DATA,
  ROW,
  GRID,
  IMAGE,
  COLOR,
  BORDER_RIGHT,
  BORDER_BOTTOM,
  EQUATION,
  SHOW_BORDERS,
  SETTINGS,
  STANDARD,
  S,
  XL,
  CHART_TYPE
} from "../../../constants";

import Input from "../../../shared/components/Input";
import { mediaresourceStructure } from "../../../shared/MediaHandler/MediaHandler";
import PluginHoc from "../../../shared/PluginHoc";
import SideBar from "../Sidebar/Sidebar";
import {
  emptyCell,
  generateIDsForEachPosition,
  rowSettingStyles,
  settingsData
} from "./ChartData";
import ChartGrid from "./ChartGrid";
import {
  StyledSettingsRow,
  StyledChart,
  StyledChartContainer,
  StyledChartContent,
  StyledChartSettings,
  StyledGridContainer,
  StyledText,
  StyledInputContainer,
  StyledIdContainer,
  StyledIdLabel,
  StyledRemoveButton,
  maxDimensions
} from "./StyledChart";
import ChartButtonsRow from "./ChartButtonsRow";
import {
  cellHasMedia,
  checkEverySelectedCell,
  getMediaTitle,
  getSectionSelections,
  onItemImageChange,
  removeMedia,
  setGridSize
} from "./ChartHelpers";
import ColorPicker from "./ColorPicker/ColorPicker";
import ChartModal from "./ChartModal/ChartModal";

const Chart = ({
  updateData,
  data: { grid },
  storeDraft,
  keyboards,
  draftTarget,
  settings,
  actions,
  registerValidator,
  resources: propsMediaResources
}) => {
  const [mediaresources, setMediaResources] = React.useState({});
  const [columnCount, setColumnCount] = React.useState(
    grid ? grid[0].length : ""
  );
  const [rowCount, setRowCount] = React.useState(grid ? grid.length : "");
  const [gridDimensions, setGridDimensions] = React.useState({
    width: 0,
    height: 0
  });

  const [selection, setSelection] = React.useState({
    direction: null,
    index: 0,
    cells: []
  });

  const [showModal, setShowModal] = React.useState(false);

  const chartTypeRef = React.useRef(settings.chartType);
  const showBordersRef = React.useRef(settings.showBorders);
  const chartContainerRef = React.useRef();

  React.useEffect(() => {
    if (chartContainerRef) {
      const handleClickOutside = e => {
        if (!chartContainerRef.current.contains(e.target)) {
          resetSelection();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  });

  React.useEffect(() => {
    if (settings.showBorders !== showBordersRef.current) {
      if (settings.chartType === EQUATION) {
        showBordersRef.current = settings.showBorders;
        updateData(
          SHOW_BORDERS,
          settings.chartType === EQUATION ? false : true,
          SETTINGS
        );
      }
    }
  }, [settings.chartType, settings.showBorders, updateData]);

  React.useEffect(() => {
    if (settings.chartType !== chartTypeRef.current) {
      setShowModal(true);
    }
  }, [settings.chartType]);

  React.useEffect(() => {
    registerValidator([validateInUse, "Skapa en tabell för att kunna spara."]);
  }, [grid, registerValidator]);

  const validateInUse = componentData => componentData.grid !== null;

  const updateCellCallback = (rowIndex, cellIndex) => value => {
    grid[rowIndex][cellIndex] = { ...grid[rowIndex][cellIndex], value };
    updateData(GRID, grid, DATA);
  };

  const resetSelection = () =>
    setSelection({
      cells: [],
      direction: null,
      index: 0
    });

  const removeCellsCallback = () => {
    if (selection.direction === ROW) {
      grid.splice(selection.index, 1);
      updateData(GRID, generateIDsForEachPosition(grid), DATA);
      setRowCount(count => count - 1);
    }

    if (selection.direction === COLUMN) {
      const updatedGrid = grid.map((r, i) =>
        r.filter(cell => cell.id !== `[${i}, ${selection.index}]`)
      );

      updateData(GRID, generateIDsForEachPosition(updatedGrid), DATA);
      setColumnCount(count => count - 1);
    }

    resetSelection();
  };

  const updateSingleCell = (selections, rowIndex, cellIndex) => {
    setSelection({
      direction: selections ? selections.direction : null,
      index: selections ? selections.index : 0,
      cells: [grid[rowIndex][cellIndex]]
    });
  };

  const updateMultipleCells = selections => {
    setSelection({
      direction: selections ? selections.direction : null,
      index: selections ? selections.index : 0,
      cells: getSectionSelections(
        selections.direction,
        grid,
        selections.index,
        selections.index
      )
    });
  };

  const multipleSelections = (rowIndex, cellIndex, direction) => {
    if (direction) {
      const cells = getSectionSelections(direction, grid, rowIndex, cellIndex);
      const intersection = selection.cells.filter(x => cells.includes(x));
      const difference = selection.cells.filter(x => !cells.includes(x));

      setSelection(prevState => ({
        direction: null,
        index: 0,
        cells:
          intersection.length === cells.length
            ? [...difference]
            : [
                ...new Map(
                  [...prevState.cells, ...cells].map(cell => [cell.id, cell])
                ).values()
              ]
      }));
    } else {
      const isSelected = selection.cells.findIndex(
        cell => cell.id === `[${rowIndex}, ${cellIndex}]`
      );

      setSelection(prevState => ({
        direction: null,
        index: 0,
        cells:
          isSelected !== -1
            ? prevState.cells.filter((_, index) => index !== isSelected)
            : [...prevState.cells, grid[rowIndex][cellIndex]]
      }));
    }
  };

  const onAddSection = (direction, index) => () => {
    if (direction === ROW) {
      const arrayData = grid[0].map(cell => ({
        ...emptyCell,
        row: cell.row,
        column: cell.column
      }));
      if (settings.chartType === EQUATION) {
        grid.splice(
          index,
          0,
          arrayData.map((cell, i) => ({ ...cell, row: i === 1 ? S : XL }))
        );
      } else {
        grid.splice(index, 0, arrayData);
      }
      setRowCount(count => count + 1);
    }

    if (direction === COLUMN) {
      grid.map(r =>
        r.splice(index, 0, { ...emptyCell, row: r[0].row, column: r[0].column })
      );
      setColumnCount(count => count + 1);
    }

    updateData(GRID, generateIDsForEachPosition(grid), DATA);

    resetSelection();
  };

  const onSelectedCell = (rowIndex, cellIndex, selections) => () => {
    if (selections) {
      updateMultipleCells(selections);
    } else {
      updateSingleCell(selections, rowIndex, cellIndex);
    }
  };

  const updateCell = (cells, key, value, color) =>
    key === ROW || key === COLUMN || key === COLOR
      ? key === COLOR
        ? color.hex
        : value
      : !checkEverySelectedCell(cells, key);

  const radioButtonCallback = (key, value) => color => {
    const selectedCellsID = selection.cells.map(cell => cell.id);
    const isBorderKey = key === BORDER_RIGHT || key === BORDER_BOTTOM;
    const updatedGrid = grid.map(row =>
      row.map(cell => ({
        ...cell,
        borderBottom: isBorderKey ? false : cell.borderBottom,
        borderRight: isBorderKey ? false : cell.borderRight,
        ...(selectedCellsID.includes(cell.id) && {
          [key]: updateCell(selection.cells, key, value, color)
        })
      }))
    );

    const updatedSelectedCells = selection.cells.map(cell => ({
      ...cell,
      [key]: updateCell(selection.cells, key, value, color)
    }));

    setSelection(prevState => ({
      ...prevState,
      cells: updatedSelectedCells
    }));

    updateData(GRID, updatedGrid, DATA);
  };

  const onMediaChange = () => {
    const { openMediaModal } = actions;
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === IMAGE) {
        onItemImageChange(
          selected[0],
          selection.cells,
          grid,
          storeDraft,
          draftTarget,
          updateData
        );
      }
    });
  };

  const onMediaResourceChange = () => {
    const { openMediaresourceModal } = actions;
    openMediaresourceModal(res => {
      if (res.length > 0) {
        onItemImageChange(
          mediaresourceStructure(res[0]),
          selection.cells,
          grid,
          storeDraft,
          draftTarget,
          updateData
        );
        setMediaResources({ ...mediaresources, [res[0].id]: res[0].data });
      }
    });
  };

  const gridDimensionsCallback = (width, height) =>
    setGridDimensions({ width, height });

  const changeChartType = shouldChange => () => {
    if (shouldChange) {
      setGridSize({
        grid,
        type: settings.chartType === EQUATION ? EQUATION : STANDARD,
        currentType: chartTypeRef.current,
        direction: COLUMN,
        setColumnCount,
        setRowCount,
        columnCount,
        rowCount,
        updateData,
        storeDraft,
        draftTarget
      })(String(3));

      resetSelection();

      chartTypeRef.current = settings.chartType;
      showBordersRef.current = settings.chartType === EQUATION ? false : true;
    } else {
      updateData(
        CHART_TYPE,
        settings.chartType === EQUATION ? STANDARD : EQUATION,
        SETTINGS
      );
    }

    setShowModal(false);
  };

  return (
    <StyledChart>
      <StyledChartContent>
        <StyledChartContainer ref={chartContainerRef}>
          <ChartModal show={showModal} closeModal={changeChartType} />
          {grid && (
            <StyledGridContainer>
              <ChartGrid
                settings={settings}
                grid={grid}
                storeDraft={storeDraft}
                draftTarget={draftTarget}
                gridDimensions={gridDimensions}
                selectedCells={selection.cells}
                multipleSelections={multipleSelections}
                gridDimensionsCallback={gridDimensionsCallback}
                updateCellCallback={updateCellCallback}
                onSelectedCell={onSelectedCell}
                onAddSection={onAddSection}
                resources={{ ...propsMediaResources, ...mediaresources }}
              />
            </StyledGridContainer>
          )}
          <StyledChartSettings>
            <StyledSettingsRow>
              <StyledText>Kolumner:</StyledText>
              <StyledInputContainer
                hightlight={gridDimensions.width > maxDimensions.width}
              >
                <Input
                  disabled={settings.chartType === EQUATION}
                  value={columnCount}
                  onChange={setGridSize({
                    grid,
                    type: settings.chartType === EQUATION ? EQUATION : STANDARD,
                    currentType: chartTypeRef.current,
                    direction: COLUMN,
                    setColumnCount,
                    setRowCount,
                    columnCount,
                    rowCount,
                    updateData,
                    storeDraft,
                    draftTarget
                  })}
                />
              </StyledInputContainer>
              <StyledText>Rader:</StyledText>
              <StyledInputContainer
                hightlight={gridDimensions.height > maxDimensions.height}
              >
                <Input
                  value={rowCount}
                  onChange={setGridSize({
                    grid,
                    type: settings.chartType === EQUATION ? EQUATION : STANDARD,
                    currentType: chartTypeRef.current,
                    direction: ROW,
                    setColumnCount,
                    setRowCount,
                    columnCount,
                    rowCount,
                    updateData,
                    storeDraft,
                    draftTarget
                  })}
                />
              </StyledInputContainer>
            </StyledSettingsRow>
            <StyledSettingsRow>
              <ChartButtonsRow
                isOperative={settings.operative}
                type={settings.chartType}
                row={rowCount}
                column={columnCount}
                selection={selection}
                onMediaChange={onMediaChange}
                onMediaResourceChange={onMediaResourceChange}
                selectedCells={selection.cells}
                checkEverySelectedCell={checkEverySelectedCell}
                radioButtonCallback={radioButtonCallback}
                removeCellsCallback={removeCellsCallback}
              />
            </StyledSettingsRow>
          </StyledChartSettings>
          <ColorPicker
            allCells={selection.cells}
            callback={radioButtonCallback}
            show={selection.cells.length !== 0}
          />
          {cellHasMedia(selection.cells) && (
            <StyledIdContainer>
              <StyledIdLabel>{getMediaTitle(selection.cells)}</StyledIdLabel>
              <StyledRemoveButton
                onClick={removeMedia(
                  grid,
                  selection.cells,
                  storeDraft,
                  draftTarget,
                  updateData
                )}
              >
                Ta bort
              </StyledRemoveButton>
            </StyledIdContainer>
          )}
        </StyledChartContainer>
      </StyledChartContent>
      <SideBar
        updateData={updateData}
        storeDraft={storeDraft}
        draftTarget={draftTarget}
        settings={settings}
        data={settingsData(settings)}
        rowSettingStyles={rowSettingStyles}
        keyboards={keyboards}
      />
    </StyledChart>
  );
};

export default PluginHoc({
  Component: Chart
});
