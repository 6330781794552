export const editQuestionData = {
  id: 1,
  title: "Testdata",
  json: [
    {
      id: "Lektion1",
      title: "Lektion 1",
      parts: [
        {
          id: "1.1",
          module: "multiple",
          partCategory: "1",
          category: "Lektion 1",
          settings: {
            randomizeQuestions: false,
            randomizeAlternatives: true,
            multipleAnswers: false
          },
          commonerrors: [
            {
              text:
                "Eleven behöver förstå betydelsen av positionen av talen i det decimala talsystemet."
            }
          ],
          questions: [
            {
              task: [
                {
                  text: "Vilken talsort är siffran 4 i talet 5 469?"
                }
              ],
              alternatives: [
                {
                  main: [
                    {
                      text: "hundratal"
                    }
                  ],
                  correct: true,
                  points: 1
                },
                {
                  main: [
                    {
                      text: "tiotal"
                    }
                  ],
                  correct: false,
                  points: 0
                },
                {
                  main: [
                    {
                      text: "tusental"
                    }
                  ],
                  correct: false,
                  points: 0
                },
                {
                  main: [
                    {
                      text: "ental"
                    }
                  ],
                  correct: false,
                  points: 0
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  images: [
    {
      id: 123,
      filename: "kattB.png",
      src:
        "https://media.dev.studentlitteratur.se/image/mr/files/11e8d761-8c41-4af2-b069-f79e6276fa54_KattB.png",
      alt: "Bild på katt"
    },
    {
      id: 124,
      filename: "kattB.png",
      src:
        "https://media.dev.studentlitteratur.se/image/mr/files/11e8d761-8c41-4af2-b069-f79e6276fa54_KattB.png",
      alt: "Bild på katt igen"
    }
  ],
  audio: [
    {
      id: 123456,
      filename: "ljud.mp3",
      src:
        "https://media.dev.studentlitteratur.se/audio/mr/files/610653a7-4067-467c-bdd6-df88f649b0f3_006-01.mp3"
    },
    {
      id: 123457,
      filename: "ljud.mp3",
      src:
        "https://media.dev.studentlitteratur.se/audio/mr/files/610653a7-4067-467c-bdd6-df88f649b0f3_006-01.mp3"
    }
  ]
};
