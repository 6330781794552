import * as font from "./font";
import * as colors from "./colors";
import * as baseline from "./baseline";

export const d800Size = 48;
export const d700Size = 40;
export const d600Size = 32;
export const d500Size = 24;
export const d400Size = 20;
export const d300Size = 16;
export const d200Size = 14;
export const d100Size = 12;

export const px2rem = (pixels, context = font.fontSizeDefaultNumber) =>
  `${pixels / context}rem`;

export const d800 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d800Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d800Size + 8}px;
  margin-top: ${baseline.baselineNumber * 5}px;
`;
export const d700 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d700Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d700Size + 8}px;
  margin-top: ${baseline.baselineNumber * 3.5}px;
`;
export const d600 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d600Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d600Size + 8}px;
  margin-top: ${baseline.baselineNumber * 3.5}px;
`;
export const d500 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d500Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d500Size + 8}px;
  margin-top: ${baseline.baselineNumber * 3}px;
`;
export const d400 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d400Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d400Size + 8}px;
  margin-top: ${baseline.baselineNumber * 2}px;
`;
export const d300 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d300Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d300Size + 8}px;
  margin-top: ${baseline.baselineNumber * 2.5}px;
`;
export const d200 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d200Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d200Size + 8}px;
  margin-top: ${baseline.baselineNumber * 2}px;
`;
export const d100 = () => `
  color: ${colors.neutralDark};
  font-size: ${px2rem(d100Size)};
  font-style: inherit;
  font-weight: 500;
  line-height: ${d100Size + 8}px;
  margin-top: ${baseline.baselineNumber * 2}px;
`;
