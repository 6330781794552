import React, { useRef } from "react";
import styled from "styled-components";
import { Button } from "../..";

const types = {
  image_url: 'image/*',
  text_audio_url: 'audio/*',
  description_audio_url: 'audio/*',
  collection_image_url: 'image/*',
  all: 'audio/*,image/*'
}

const UploadMedia = ({multiple, type, callback, children, ...rest}) => {
  const inputFile = useRef(null);

  const onInputClick = e => {
    e.target.value = ''
  }

  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const array = Array.from(files)
      callback({files: multiple ? [...array] : array[0], type})
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div>
      <input
        style={{ display: "none" }}
        accept={types[type]}
        ref={inputFile}
        onChange={handleFileUpload}
        onClick={onInputClick}
        type="file"
        multiple={multiple}
      />
      <StyledButton {...rest} onClick={onButtonClick}>{children}</StyledButton>
    </div>
  );
};

const StyledButton = styled(Button)`
  min-width: ${({ size }) => (size === 'wide' ? "64px" : "40px")};
  border-radius: 4px;
  margin-right: 4px;
  ${({ withmargin }) => (withmargin === 'true' ? "margin-top: 10px" : "")};
`;

export default UploadMedia;