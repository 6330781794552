import React, { useState, useEffect } from "react";
import { Field } from "../Input";
import { connect } from "react-redux";
import useVisible from "../../../shared/effects/useVisible";
import useKeyNavigation from "../../../shared/effects/useKeyNavigation";
import styled from "styled-components";

const Input = styled(Field)`
  width: 100%;
  height: 45px;
  &:disabled {
    background: white;
    opacity: 0.7;
  }
`;

const StyledInputWrapper = styled.div`
  width: ${props => props.w};
  input {
    font-size: 14px;
    width: 100%;
  }
`;

const StyledSearchResult = styled.ul`
  border: 1px solid;
  border-radius: 5px;
  position: absolute;
  width: ${props => props.w};
  z-index: 5;
  background-color: white;
  border-style: outset;
  max-height: 25rem;
  overflow-y: scroll;
  padding-left: 0;
  margin-top: 3px;
`;

const StyledSearchResultItem = styled.li`
  cursor: pointer;
  padding: 0.3rem 0.7rem;
  list-style-type: none;
  font-weight: 500;
  margin: 0;
  &:hover,
  &.active {
    background-color: ${props => props.theme.neutralQuaternary};
  }
`;

const AutoComplete = ({
  options,
  className,
  placeholder = "",
  onSelect,
  name,
  error,
  preSearchValue = "",
  onBlur = null,
  onChange = null,
  width = "21.7rem"
}) => {
  const [searchBase, setSearchBase] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useEffect(() => {
    if (preSearchValue) {
      setSearchValue(preSearchValue);
    }
  }, [preSearchValue]);

  const onEnter = () => {
    setIsVisible(false);
    onSelect(searchResult[currentNodeIndex]);
  };

  const { itemsRef, currentNodeIndex } = useKeyNavigation(
    ref,
    searchResult,
    onEnter,
    setIsVisible
  );

  useEffect(() => {
    setSearchBase(options);
  }, [options, setSearchBase]);

  const filterBase = value => {
    return searchBase.filter(b =>
      b.toLowerCase().includes(value.toLowerCase())
    );
  };

  const onSearchChange = newValue => {
    if (onChange) {
      onChange(newValue);
    }
    setSearchValue(newValue);
    const s = newValue.length > 0 ? filterBase(newValue) : [];
    setSearchResult(s);
    setIsVisible(true);
    if (newValue === "") {
      onSelect("");
    }
  };

  const onFocus = e => {
    if (e.target.value !== "") {
      onSearchChange(e.target.value);
    }
  };

  const onOptionSelect = entity => {
    onSelect(entity);
    setSearchValue(entity);
    setIsVisible(false);
    setSearchResult([]);
  };

  return (
    <StyledInputWrapper w={width} ref={ref} className={className}>
      <Input
        onChange={onSearchChange}
        onBlur={onBlur ? () => onBlur(searchValue, name) : () => {}}
        onFocus={onFocus}
        value={searchValue}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        type="search"
        error={error}
      />
      {isVisible && searchResult.length > 0 && (
        <StyledSearchResult w={width}>
          {searchResult.map((s, index) => (
            <StyledSearchResultItem
              key={s}
              ref={el => (itemsRef.current[index] = el)}
              onClick={e => {
                onOptionSelect(s);
                e.preventDefault();
              }}
              className={currentNodeIndex === index ? "active" : ""}
            >
              {s}
            </StyledSearchResultItem>
          ))}
        </StyledSearchResult>
      )}
    </StyledInputWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  options: props.selector(state)
});

export default connect(mapStateToProps)(AutoComplete);
