import styled from "styled-components";

export const StyledMathWord = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledMathWordContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledAddedWords = styled.div`
  width: 100%;
  display: flex;
  min-height: 150px;
  padding: 10px;
  flex-direction: column;
`;

export const StyledEditButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

export const StyledEditItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-right: 10px;
`;

export const StyledEditInputWrapper = styled.div`
  border: thin solid #999
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 10px;
  input {
    border: 0
    outline: none;
    width: 100%
  }
  height: 40px;
`;

export const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledImage = styled.div`
  border: thin solid #999;
  margin: 10px 0;
  img {
    max-height: 200px;
    max-width: 600px;
  }
`;

export const StyledMediaResource = styled.div`
  border: thin solid #999;
  margin: 10px 0;
  svg {
    max-height: 200px;
    max-width: 600px;
  }
`;

export const StyledAudioWrapper = styled.div`
  margin-top: 10px;
`;

export const StyledTextArea = styled.div`
  max-width: 1010px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  cursor: text;
  background: #fefefe;

  .TextEditor {
    margin: 0;
    min-height: 140px;
  }

  .public-DraftEditor-content {
    min-height: 140px;
  }
`;
