import React from "react";
import Chip from "../../Chip";
import { CHIP_COLOR } from "../../../../posts/api/constants";

const ChipRenderer = ({ cellData }) => {
  return (
    cellData &&
    cellData.map((chip, index) => chip && (
      <Chip
        key={index}
        label={chip.title}
        variant="chips"
        fill
        labelColor={CHIP_COLOR}
      />
    ))
  );
};

export default ChipRenderer;
