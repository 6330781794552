export const MULTISVG_MOVE_ICONS_Z = "MULTISVG_MOVE_ICONS_Z";
export const MULTISVG_RESIZE_ICONS = "MULTISVG_RESIZE_ICONS";
export const MULTISVG_DUPLICATE_ICONS = "MULTISVG_DUPLICATE_ICONS";
export const MULTISVG_ALIGN_ICONS = "MULTISVG_ALIGN_ICONS";
export const MULTISVG_REMOVE_ICONS = "MULTISVG_REMOVE_ICONS";
export const MULTISVG_UPDATE_ICONS = "MULTISVG_UPDATE_ICONS";
export const MULTISVG_INIT_ICONDATA = "MULTISVG_INIT_ICONDATA";
export const MULTISVG_DISTRIBUTE_ICONS = "MULTISVG_DISTRIBUTE_ICONS";
export const MULTISVG_UPDATE_SETTINGS = "MULTISVG_UPDATE_SETTINGS";
export const MULTISVG_ADD_NEW_ICON = "MULTISVG_ADD_NEW_ICON";

export const moveIconsZ = direction => ({
  type: MULTISVG_MOVE_ICONS_Z,
  direction
});

export const resizeIcons = newSize => ({
  type: MULTISVG_RESIZE_ICONS,
  newSize
});

export const duplicateIcons = numberOfCopies => ({
  type: MULTISVG_DUPLICATE_ICONS,
  numberOfCopies
});

export const alignIcons = alignDirection => ({
  type: MULTISVG_ALIGN_ICONS,
  alignDirection
});

export const removeIcons = () => ({
  type: MULTISVG_REMOVE_ICONS
});

export const updateIcons = data => ({
  type: MULTISVG_UPDATE_ICONS,
  data
});

export const initIconData = data => ({
  type: MULTISVG_INIT_ICONDATA,
  data
});

export const distributeIcons = direction => ({
  type: MULTISVG_DISTRIBUTE_ICONS,
  direction
});

export const updateSettings = settings => ({
  type: MULTISVG_UPDATE_SETTINGS,
  settings
});

export const addNewIcon = newIcon => ({
  type: MULTISVG_ADD_NEW_ICON,
  newIcon
});

export const savePost = ({
  entityId,
  postId,
  title,
  description,
  data,
  difficulty,
  media_urls = null
}) => ({
  type: "MEDIARESOURCE/SAVE_ENTITY_DETAILS",
  args: {
    entityId,
    postId,
    title,
    description,
    data,
    difficulty,
    media_urls
  }
});

export const createPost = ({
  entityId,
  title,
  description,
  data,
  difficulty,
  media_urls = null
}) => ({
  type: "MEDIARESOURCE/CREATE_ENTITY_DETAILS",
  args: {
    entityId,
    title,
    description,
    data,
    difficulty,
    media_urls
  }
});
