import React from "react";
import Card from "../Card";
import { getBiafProducts, getProductInfo, getRelatedProducts } from "../../store/selectors";
import Label from "../Label";
import { Search } from "../TwinHandler";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createProductsRelation, deleteProductsRelation } from "../../api/actions";
import LinkedProductsList from "./LinkedProductsList";

const Autocomplete = styled(Search)`
  max-width: 18.75rem;
`;

const FieldLabel = styled(Label)`
  margin-top: 1.5rem;
`;


const AddBiafLinkCard = () => {
  const dispatch = useDispatch();
  const [entity] = useSelector(getProductInfo);
  const relatedProducts = useSelector(getRelatedProducts);

  const addRelatedProduct = relatedProductId =>
    dispatch(createProductsRelation(entity.id, relatedProductId));

  const removeRelatedProduct = productId =>
    dispatch(deleteProductsRelation(entity.id, productId));

  const _onSearchEnter = ({ id }) => {
    addRelatedProduct(id);
  };

  return (
    <Card title="Kompletterande material">
      <LinkedProductsList products={relatedProducts} onDelete={removeRelatedProduct} />
      <FieldLabel>
        Lägg till material
        <Autocomplete
          placeholder="Sök"
          onSelect={_onSearchEnter}
          selector={getBiafProducts}
        />
      </FieldLabel>
    </Card>
  );
};

export default AddBiafLinkCard;
