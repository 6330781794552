import React from "react";
import { connect } from "react-redux";
import {
  StyledEditorContainer,
  StyledEditorArea,
  StyledGrid,
  StyledIcon,
  StyledBorder,
  StyledInputWrapper,
  StyledInfoTextContainer,
  StyledInfoText,
  StyledInfoButtonContainer
} from "./StyledImageEditor";
import EditorToolbar from "../../components/EditorToolbar";
import Input from "../../../shared/components/Input";
import { viewBoxes, iconSizes, scale } from "../../../shared/constants/formats";

import { setDraftState, setMultiSVGFormat, updateTitle } from "../../api/actions";

import {
  ENTITIES,
  MEDIA_RESOURCE,
  MULTIIMAGE,
  MEDIA
} from "../../../constants";

import {
  moveIconsZ,
  resizeIcons,
  duplicateIcons,
  alignIcons,
  removeIcons,
  updateIcons,
  initIconData,
  distributeIcons,
  updateSettings,
  addNewIcon,
  savePost,
  createPost,
  duplicatePost
} from "../../store/actions";

import {
  selectIconsData,
  selectCurrentWorkspaceFormat,
  selectWorkspaceSettingsJS,
  selectSelectedIcons,
  selectSizesOfSelectedIcons
} from "../../api/selectors";

import { openMediaModal } from "../../../shared/components/Modal/MediaModal";

import { getUsedMediaFilesFromDataString } from "../../../shared/services/media";
import { Info } from "react-feather";
import { selectEntityId } from "../../../store/selectors";

const shortCommands = [
  { key: "a", action: "markera alla bilder" },
  { key: "b", action: "lägga till fler bilder" },
  { key: "SHIFT + d", action: "duplicera bildresurs" },
  { key: "d", action: "duplicera markerad bild" },
  { key: "BACKSPACE", action: "radera markerade bilder" },
  { key: "1", action: "ändra bildstorleken till extra liten" },
  { key: "2", action: "ändra bildstorleken till liten" },
  { key: "3", action: "ändra bildstorleken till medium" },
  { key: "4", action: "ändra bildstorleken till stor" },
  { key: "5", action: "flytta fram bild" },
  { key: "6", action: "flytta bak bild" },
  { key: "SHIFT + →", action: "flytta markerade bilder åt höger" },
  { key: "SHIFT + ←", action: "flytta markerade bilder åt vänster" },
  { key: "SHIFT + ↑", action: "flytta upp markerade bilder" },
  { key: "SHIFT + ↓", action: "flytta ner markerade bilder" }
];

/**
 * ImageEditor to edit multiSVGs
 */
class ImageEditorContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dragTarget: null,
      showGrid: true,
      selectTool: null,
      showInfo: false
    };

    /**
     * clickTime - time in ms between mousedown and mouseup to define a click
     * startClick - timeout to define a click or drag
     * isADrag - bool or null if event is a drag event
     * selectDrag - bool or null if this is a drag on workspace and not a target.
     */
    this.clickTime = 200;
    this.startClick = null;
    this.isADrag = null;
    this.selectDrag = null;

    this.clickRef = React.createRef();
  }

  componentDidMount = () => {
    this.props.updateDraftState("clean");
    document.addEventListener("keydown", this.onKeyDown);
    const svg = this.clickRef.current.firstElementChild;
    this.setState({
      svg,
      selectTool: svg.getElementById("select")
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.onKeyDown);
  };

  /**
   * Returns the size in pixels of the element
   * @param {string} size
   * @return {float}
   */
  getSize = size => scale[size] * this.getIconSize();

  /**
   * Returns the element size with no scale for the current workspace size
   * @return {number}
   */
  getIconSize = () => iconSizes[this.props.format];

  /**
   * Changes the viewBox in workspace svg to the format in param
   * @param {string} format
   */
  changeViewBox = format => {
    this.props.setFormat(format)
    this.props.updateDraftState("dirty")
  };

  /**
   * Is target the workspace or not
   * @param {element} target
   * @return {bool}
   */
  isTargetTheWorkspace = target =>
    target.getAttributeNS(null, "id") === "workspace" ||
    target.getAttributeNS(null, "id") === "svgWorkspace";

  /**
   * Is target a draggable
   * @param {element} target
   * @return {bool}
   */
  isTargetADraggable = target => {
    let classNames = target.getAttributeNS(null, "class");
    return classNames && classNames.indexOf("draggable") >= 0;
  };

  /**
   * Get data for the object with the id
   * @param {string} id
   * @return {object}
   */
  getIconDataForId = id => {
    const { icons } = this.props;
    return icons.filter(icon => icon.id === id);
  };

  /**
   * Get data for target
   * @param {element} target
   * @return {object}
   */
  getIconDataForTarget = target => {
    let id = target.getAttributeNS(null, "id");
    return this.getIconDataForId(id);
  };

  /**
   * update coords for target and/or selected icons to redux
   * @param {element} target the object that has moved
   */
  saveCoordForSelectedIcons = target => {
    const { selectedIcons } = this.props;
    const { svg } = this.state;
    let selList = selectedIcons.map(icon => {
      const iconEl = svg.getElementById(icon.id);
      return {
        id: icon.id,
        x: parseFloat(iconEl.getAttributeNS(null, "x")),
        y: parseFloat(iconEl.getAttributeNS(null, "y"))
      };
    });
    if (target) {
      const targetId = target.getAttributeNS(null, "id");
      if (!this.isIdSelected(targetId)) {
        selList = [
          ...selList,
          {
            id: targetId,
            x: parseFloat(target.getAttributeNS(null, "x")),
            y: parseFloat(target.getAttributeNS(null, "y"))
          }
        ];
      }
    }
    this.props.updateIcons(selList);
  };

  /**
   * Sets element to selected true/false depending on previous state.
   * if multiselect is true - more than one element can be selected
   *
   * @param { object } e event object from mouseclick
   * @param { bool } multiselect enables to select more than one element
   */
  onSelect = (e, multiselect) => {
    if (this.isTargetADraggable(e.target)) {
      if (!multiselect) {
        this.deSelectAll();
      }
      let icondata = this.getIconDataForTarget(e.target);

      this.props.updateIcons([
        {
          id: icondata[0].id,
          selected: !icondata[0].selected
        }
      ]);
    }
  };

  /**
   * Deselects all elements on workspace.
   *
   */
  deSelectAll = () => {
    const { selectedIcons } = this.props;
    const selList = selectedIcons.map(icon => {
      return {
        id: icon.id,
        selected: false
      };
    });
    this.props.updateIcons(selList);
  };

  /**
   * selects all elements on workspace.
   *
   */
  selectAll = () => {
    const iconData = this.props.icons;
    const selList = iconData.map(icon => {
      return {
        id: icon.id,
        selected: true
      };
    });
    this.props.updateIcons(selList);
  };

  /**
   * Moves selected elements on workspace.
   * @param direction
   */
  moveSelectedIcons = direction => {
    const { selectedIcons } = this.props;
    const updatedIconList = selectedIcons.map(icon => {
      const { x, y } = icon;
      let updatedX = x;
      let updatedY = y;

      switch (direction) {
        case "down":
          updatedY = updatedY + 1;
          break;
        case "up":
          updatedY = updatedY - 1;
          break;
        case "right":
          updatedX = updatedX + 1;
          break;
        case "left":
          updatedX = updatedX - 1;
          break;
        default:
          break;
      }

      return {
        ...icon,
        x: updatedX,
        y: updatedY
      };
    });
    this.props.updateIcons(updatedIconList);
  };

  /**
   * Handles key events.
   * @param e
   */
  onKeyDown = e => {
    const iconsSelected = this.props.selectedIcons.length > 0;

    if (e.ctrlKey) {
      // Represents ctrl + a ( HIGLIGHT ALL ELEMENTS )
      if (e.keyCode === 65) {
        this.selectAll();
        e.preventDefault();
      }
      // Represents ctrl + b ( OPENS MODAL )
      if (e.keyCode === 66) {
        this.openMediaModal();
        e.preventDefault();
      }
      // Represents ctrl + shift + d ( DUPLICATE MEDIA RESOURCE )
      if (e.keyCode === 68 && e.shiftKey) {
        this.duplicateImage();
        e.preventDefault();
      }
      // Represents ctrl + d ( DUPLICATE IMAGE )
      if (e.keyCode === 68) {
        iconsSelected && this.props.duplicate(1);
        e.preventDefault();
      }
      // Represents ctrl + BACKSPACE ( REMOVE ELEMENTS )
      if (e.keyCode === 8) {
        iconsSelected && this.props.remove();
        e.preventDefault();
      }
      // Represents ctrl + 1 ( SIZE EXTRASMALL )
      if (e.key === "1") {
        iconsSelected && this.props.resize("extrasmall");
        e.preventDefault();
      }
      // Represents ctrl + 2 ( SIZE SMALL )
      if (e.key === "2") {
        iconsSelected && this.props.resize("small");
        e.preventDefault();
      }
      // Represents ctrl + 3 ( SIZE MEDIUM )
      if (e.key === "3") {
        iconsSelected && this.props.resize("medium");
        e.preventDefault();
      }
      // Represents ctrl + 4 ( SIZE LARGE )
      if (e.key === "4") {
        iconsSelected && this.props.resize("large");
        e.preventDefault();
      }
      // Represents ctrl + 5 ( BRING FORTH )
      if (e.key === "5") {
        iconsSelected && this.props.moveZ(1);
        e.preventDefault();
      }
      // Represents ctrl + 6 ( BRING BACK )
      if (e.key === "6") {
        iconsSelected && this.props.moveZ(-1);
        e.preventDefault();
      }
      // Represents ctrl + shift + ← ( MOVE LEFT )
      if (e.keyCode === 37 && e.shiftKey) {
        iconsSelected && this.moveSelectedIcons("left");
        e.preventDefault();
      }
      // Represents ctrl + shift + ↑ ( MOVE UP )
      if (e.keyCode === 38 && e.shiftKey) {
        iconsSelected && this.moveSelectedIcons("up");
        e.preventDefault();
      }
      // Represents ctrl + shift + → ( MOVE RIGHT )
      if (e.keyCode === 39 && e.shiftKey) {
        iconsSelected && this.moveSelectedIcons("right");
        e.preventDefault();
      }
      // Represents ctrl + shift + ↓ ( MOVE DOWN )
      if (e.keyCode === 40 && e.shiftKey) {
        iconsSelected && this.moveSelectedIcons("down");
        e.preventDefault();
      }
    }
  };

  /**
   * sets (x,y) to (0,0) and height and width to 0
   */
  clearSelectTool = () => {
    const { selectTool } = this.state;
    this.setCoordOnElement(selectTool, 0, 0, 0, 0);
    selectTool.setAttributeNS(null, "hotSpotX", 0);
    selectTool.setAttributeNS(null, "hotSpotY", 0);
  };

  /**
   * Init the select tool on position x, y and with size 0
   * @param {number} x
   * @param {number} y
   */
  initSelectTool = (x, y) => {
    const { selectTool } = this.state;
    this.setCoordOnElement(selectTool, x, y, 0, 0);
    selectTool.setAttributeNS(null, "hotSpotX", x);
    selectTool.setAttributeNS(null, "hotSpotY", y);
  };

  /**
   * Update the select tool to the rectangle with one corner in previous initiated position
   * and one corner in position x, y
   * @param {number} x
   * @param {number} y
   */
  updateSelectTool = (x, y) => {
    const { selectTool } = this.state;
    const hotSpot = {
      x: parseFloat(selectTool.getAttributeNS(null, "hotSpotX")),
      y: parseFloat(selectTool.getAttributeNS(null, "hotSpotY"))
    };
    const width = Math.abs(hotSpot.x - x);
    const height = Math.abs(hotSpot.y - y);
    const left = x < hotSpot.x ? x : hotSpot.x;
    const top = y < hotSpot.y ? y : hotSpot.y;

    this.setCoordOnElement(selectTool, left, top, height, width);
  };

  /**
   * Updates x, y, height, width on a draggable element and its border rect
   * @param {element} element
   * @param {float} x
   * @param {float} y
   * @param {number} height
   * @param {number} width
   */
  setCoordOnElement = (element, x, y, height = null, width = null) => {
    let border = element.parentNode.firstElementChild;
    element.setAttributeNS(null, "x", x);
    element.setAttributeNS(null, "y", y);
    if (border) {
      border.setAttributeNS(null, "x", x);
      border.setAttributeNS(null, "y", y);
    }
    if (height !== null && height >= 0) {
      element.setAttributeNS(null, "height", height);
      border.setAttributeNS(null, "height", height);
    }
    if (width !== null && width >= 0) {
      element.setAttributeNS(null, "width", width);
      border.setAttributeNS(null, "width", width);
    }
  };

  /**
   * Returns an objects x, y, height and width of the specified element
   * @param {element} element
   * @returns {object}
   */
  getCoordFromElement = element => ({
    x: parseFloat(element.getAttributeNS(null, "x")),
    y: parseFloat(element.getAttributeNS(null, "y")),
    height: parseFloat(element.getAttributeNS(null, "height")),
    width: parseFloat(element.getAttributeNS(null, "width"))
  });

  /**
   * updates x and y coordinates on selected elements.
   * @param {object} coord {x, y} the new svg coordinates
   * @param {bool} useSnap if true set coords to closest grid coordinates from x, y
   */
  updateCoordsOnSelectedElements = (coord, useSnap) => {
    const { dragTarget, offset, svg } = this.state;
    const { selectedIcons } = this.props;
    const { gridspace } = this.props.settings;
    let diff = {},
      newCoordsForTarget = {};

    const targetCoord = this.getCoordFromElement(dragTarget);

    newCoordsForTarget.x = coord.x - offset.x;
    newCoordsForTarget.y = coord.y - offset.y;

    if (useSnap) {
      newCoordsForTarget.x =
        Math.round(newCoordsForTarget.x / gridspace) * gridspace;
      newCoordsForTarget.y =
        Math.round(newCoordsForTarget.y / gridspace) * gridspace;
    }

    diff.x = targetCoord.x - newCoordsForTarget.x;
    diff.y = targetCoord.y - newCoordsForTarget.y;

    selectedIcons.map(icon => {
      let iconEl = svg.getElementById(icon.id);
      if (iconEl !== dragTarget) {
        let iconCoord = this.getCoordFromElement(iconEl);
        this.setCoordOnElement(
          iconEl,
          iconCoord.x - diff.x,
          iconCoord.y - diff.y
        );
      }
      return icon;
    });
    this.setCoordOnElement(
      dragTarget,
      newCoordsForTarget.x,
      newCoordsForTarget.y
    );
    this.props.updateDraftState("dirty");
  };

  /**
   * Check if coord is in rect
   * @param {object} coord x, y, height, width
   * @param {object} rect x, y, height, width
   * @return {bool}
   */
  isObjectInRect = (coord, rect) => {
    const leftright =
      coord.x >= rect.x && coord.x + coord.width <= rect.x + rect.width;
    const topbottom =
      coord.y >= rect.y && coord.y + coord.height <= rect.y + rect.height;
    return leftright && topbottom;
  };

  /**
   * Is id in the list of selected icons
   * @param {string} id
   * @return {bool}
   */
  isIdSelected = id => {
    const { selectedIcons } = this.props;
    const els = selectedIcons.filter(icon => icon.id === id);
    return els.length > 0;
  };

  /**
   * save the image
   */
  saveImage = () => {
    const { post, icons, format, background, title, description } = this.props;
    const _post = post.first();
    const d = new Date();
    const assetId = "icon" + d.getTime();

    const iconData = icons.map(icon => {
      delete icon.id;
      delete icon.selected;
      if (icon.AssetId === undefined) {
        icon.AssetId = assetId;
      }
      return icon;
    });

    const data = JSON.stringify({
      data: {
        icons: iconData,
        format: format,
        background: background
      },
      type: MULTIIMAGE
    });

    const media_urls = getUsedMediaFilesFromDataString(data);

    if (_post.id === null) {
      this.props.createPost({
        entityId: _post.entity_id,
        title,
        description,
        data,
        difficulty: _post.difficulty,
        media_urls
      });
      this.props.updateDraftState("clean");
    } else {
      this.props.savePost({
        entityId: _post.entity_id,
        postId: _post.id,
        title,
        description,
        data,
        difficulty: _post.difficulty,
        media_urls
      });
      this.props.updateDraftState("clean");
    }
  };

  /**
   * Duplicate image
   */
  duplicateImage = () => {
    const { post } = this.props;
    const _post = post.first();

    this.props.duplicatePost(_post.id);
  };

  /**
   * Open the media modal.
   */
  openMediaModal = () => {
    const { selectedIcons } = this.props;
    this.props.openMediaModal(
      {
        onOK: this.addImageToWorkspace,
        onCancel: () => {
          this.addImageToWorkspace([]);
        }
      },
      null,
      MEDIA,
      null,
      null,
      selectedIcons.length === 0
    );
  };

  /**
   * Callback for openMediaModal. Save new images or replace an image on the workspace with this new Image.
   * @param {array} newImages - the images selected from the mediamodal
   */
  addImageToWorkspace = newImages => {
    const { selectedIcons } = this.props;
    if (newImages.length > 0) {
      if (selectedIcons.length > 0) {
        this.replaceImage(newImages[0], selectedIcons[0]);
      } else {
        newImages.forEach((image, index) => this.addNewImage(image, index));
        this.props.updateDraftState("dirty");
      }
    }
  };

  /**
   * replaces the selected icon on the workspace with the new image
   * @param {object} newImage
   * @param {object} selectedIcon
   */
  replaceImage = (newImage, selectedIcon) => {
    const { updateIcons } = this.props;
    selectedIcon.image.src = newImage.url
      ? newImage.url
      : selectedIcon.image.src;
    updateIcons([selectedIcon]);
  };

  /**
   * add a new image to workspace
   * @param {object} newImage
   */
  addNewImage = (newImage, index) => {
    const { addIcon } = this.props;
    addIcon({
      x: index * 20,
      y: index * 20,
      size: "small",
      image: { src: newImage.url },
      selected: false,
      id: "icon_" + newImage.id
    });
  };

  /**
   * Returns the draggable objects found within the coordinates of specified rect
   * @param {object} rect x, y, height, width
   * @return {array} elements contained in rect
   */
  findElementsInRect = rect => {
    const { svg } = this.state;
    let draggableIconsOnWorkspace = svg.getElementsByClassName("draggable");
    let draggableObjectsInRect = Object.keys(draggableIconsOnWorkspace).reduce(
      (acc, key) => {
        let coord = this.getCoordFromElement(draggableIconsOnWorkspace[key]);
        if (this.isObjectInRect(coord, rect)) {
          acc = [
            ...acc,
            {
              id: draggableIconsOnWorkspace[key].getAttributeNS(null, "id"),
              selected: true
            }
          ];
        }
        return acc;
      },
      []
    );

    return draggableObjectsInRect;
  };

  /**
   * Returns mouse position in svg coordinates
   * @param {object} e mouse event
   * @returns {x, y} svg coordinates
   */
  getMouseSVGPosition = e => {
    const { svg } = this.state;

    let CTM = svg.getScreenCTM();
    if (e.touches) {
      e = e.touches[0];
    }

    return {
      x: (e.clientX - CTM.e) / CTM.a,
      y: (e.clientY - CTM.f) / CTM.d
    };
  };

  /**
   * Function that is run if it is a drag event. Inits the drag.
   * @param e - mousedown event
   */
  startDragTarget = e => {
    let coord = this.getMouseSVGPosition(e);
    this.startClick = null;
    this.isADrag = true;

    if (this.isTargetTheWorkspace(e.target)) {
      this.initSelectTool(coord.x, coord.y);
      this.selectDrag = true;
      if (this.props.selectedIcons.length > 0) {
        this.deSelectAll();
      }
    } else {
      let targetId = e.target.getAttributeNS(null, "id");
      if (!this.isIdSelected(targetId)) {
        this.deSelectAll();
      }
      coord.x -= parseFloat(e.target.getAttributeNS(null, "x"));
      coord.y -= parseFloat(e.target.getAttributeNS(null, "y"));
      this.setState({
        offset: coord,
        dragTarget: e.target
      });
    }
  };

  /**
   * Start drag.
   * @param {object} e event from mousedown
   * @returns {*}
   */
  startDrag = e => {
    e.persist();
    this.startClick = setTimeout(this.startDragTarget, this.clickTime, e);
  };

  /**
   * moves selected elements on workspace. If drag is on workspace there is a select rectangle showing
   * changing its size after mousecoordinates
   * @param {object} e Event from mousedown
   */
  onDrag = e => {
    if (this.isADrag) {
      if (this.selectDrag) {
        const coord = this.getMouseSVGPosition(e);
        this.updateSelectTool(coord.x, coord.y);
      } else {
        const coord = this.getMouseSVGPosition(e);
        this.updateCoordsOnSelectedElements(coord, false);
      }
    }
  };

  /**
   * Ends drag. It could be a click, drag or selectdrag.
   * @param {object} e Event from mouseUp
   */
  endDrag = e => {
    if (this.startClick) {
      clearTimeout(this.startClick);
      if (this.isTargetTheWorkspace(e.target)) {
        this.deSelectAll();
      } else {
        this.onSelect(e, e.shiftKey);
      }
    } else if (this.isADrag) {
      this.isADrag = false;
      if (this.selectDrag) {
        const { selectTool } = this.state;
        const selectCoord = this.getCoordFromElement(selectTool);

        this.props.updateIcons(this.findElementsInRect(selectCoord));
        this.clearSelectTool();
        this.selectDrag = false;
      } else {
        const { dragTarget } = this.state;
        const coord = this.getMouseSVGPosition(e);
        this.updateCoordsOnSelectedElements(coord, this.state.showGrid);
        this.saveCoordForSelectedIcons(dragTarget);
      }
    }
  };

  /**
   * Handler for titleinput
   */
  _onChange = v => {
    this.props.updateTitle(v);
    this.props.updateDraftState("dirty");
  };

  /**
   * Toogle grid on/off
   */
  toggleGrid = () =>
    this.setState({
      showGrid: !this.state.showGrid
    });

  /**
   * Toogle info
   */
  toggleInfo = () =>
    this.setState({
      showInfo: !this.state.showInfo
    });

  /**
   * Shows information about fast commands
   */
  showInfo = () => (
    <StyledInfoButtonContainer>
      <Info onClick={this.toggleInfo} />
      {this.state.showInfo && (
        <StyledInfoTextContainer>
          {shortCommands.map(command =>
            this.renderInfoRow(command.key, command.action)
          )}
        </StyledInfoTextContainer>
      )}
    </StyledInfoButtonContainer>
  );

  /**
   * Renders fast command information
   * @param {*} key
   * @param {*} action
   */
  renderInfoRow = (key, action) => (
    <StyledInfoText
      key={key}
    >{`Snabbkommando CTRL + ${key} för att kunna ${action}`}</StyledInfoText>
  );

  /**
   * Render function for the Class
   */
  render() {
    const { icons } = this.props;
    const { gridspace } = this.props.settings;
    const { showGrid } = this.state;
    const thickline = gridspace * 10;

    const classNames = {
      "16:9": "sexton-nio",
      "3:1": "tre-ett",
      "4:1": "fyra-ett",
      "2:1": "tva-ett",
      "1:1": "ett-ett"
    };

    return (
      <StyledEditorContainer>
        {this.renderTitle()}
        {this.renderToolbar()}
        <StyledEditorArea>
          <StyledGrid className={classNames[this.props.format]}>
            <div ref={this.clickRef}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBoxes[this.props.format]}
                onMouseMove={this.onDrag}
                onMouseDown={this.startDrag}
                onMouseUp={this.endDrag}
                id="svgWorkspace"
              >
                {this.renderGrid(gridspace, thickline, showGrid)}
                {this.renderIcons(icons)}
                {this.renderSelectRect()}
              </svg>
            </div>
          </StyledGrid>
        </StyledEditorArea>
        {this.showInfo()}
      </StyledEditorContainer>
    );
  }

  /**
   * Returns className depending on param
   * @param {bool} selected
   * @return {string} classname
   */
  getBorderClassNames = selected => (selected ? "selected" : "");

  /**
   * Render the toolbar for the Editor
   */
  renderToolbar = () => (
    <EditorToolbar
      openMediaModal={this.openMediaModal}
      formatChange={this.changeViewBox}
      openExamples={this.props.setMultiSVGData}
      currentFormat={this.props.format}
      alignToWorkspace={this.props.settings.alignWorkspace}
      actionAlignToWorkspace={this.props.updateSettings}
      actionResize={this.props.resize}
      actionAlign={this.props.align}
      actionDistribute={this.props.distribute}
      actionRemove={this.props.remove}
      actionMoveZ={this.props.moveZ}
      actionDuplicate={this.props.duplicate}
      actionGrid={this.toggleGrid}
      elementsIsSelected={this.props.selectedIcons.length <= 0}
      selectedSizes={this.props.selectedSizes}
      saveImage={this.saveImage}
      duplicateImage={this.duplicateImage}
      post={this.props.post}
      prev={this.props.prev}
      mediaresourcesPath={this.props.mediaresourcesPath}
    />
  );

  /* eslint-disable jsx-a11y/label-has-associated-control */
  renderTitle = () => (
    <StyledInputWrapper>
      <label>
        Titel:
        <Input onChange={this._onChange} value={this.props.title} />
      </label>
    </StyledInputWrapper>
  );

  /**
   * Render the dottet rect you use when selecting objects
   */
  renderSelectRect = () => (
    <rect
      id="select"
      width="0"
      height="0"
      x="0"
      y="0"
      fill="none"
      stroke="darkgrey"
      strokeWidth="0.5"
      strokeDasharray="3,3"
    />
  );

  /**
   * Render of the grid in the background
   * @param gridspace - space between the lines in the grid
   * @param thickline - when to draw a thickline
   * @param showGrid - show or hide the grid
   */
  renderGrid = (gridspace, thickline, showGrid) => (
    <g>
      <defs>
        <pattern
          id="smallGrid"
          width={gridspace}
          height={gridspace}
          patternUnits="userSpaceOnUse"
        >
          <path
            d={"M " + gridspace + " 0 L 0 0 0 " + gridspace}
            fill="none"
            stroke="lightgray"
            strokeWidth="0.5"
          />
        </pattern>
        <pattern
          id="grid"
          width={thickline}
          height={thickline}
          patternUnits="userSpaceOnUse"
        >
          <rect width={thickline} height={thickline} fill="url(#smallGrid)" />
          <path
            d={"M " + thickline + " 0 L 0 0 0 " + thickline}
            fill="none"
            stroke="lightgray"
            strokeWidth="1"
          />
        </pattern>
      </defs>

      <rect
        id="workspace"
        width="100%"
        height="100%"
        fill={showGrid ? "url(#grid)" : "none"}
      />
    </g>
  );

  /**
   * render the icons in the multisvg
   * @param icons - the icondata
   */
  renderIcons = icons =>
    icons.map((img, index) =>
      this.renderItem(
        img.x,
        img.y,
        this.getSize(img.size, this.props.format),
        img.id,
        img.image.src,
        img.selected,
        index
      )
    );

  /**
   * Render of imageElement with a border rect
   * @param {number} x
   * @param {number} y
   * @param {number} size
   * @param {string} id
   * @param {string} path
   * @param {bool} selected
   * @returns {*}
   */
  renderItem = (x, y, size, id, path, selected, index) => {
    let c = this.getBorderClassNames(selected);
    return (
      <g key={"g" + id + "_" + index}>
        <StyledBorder
          className={c}
          width={size + "px"}
          height={size + "px"}
          x={x}
          y={y}
        />
        <StyledIcon
          x={x}
          y={y}
          className={"draggable"}
          id={id}
          width={size + "px"}
          height={size + "px"}
          xlinkHref={path}
          key={id}
        />
      </g>
    );
  };
}

const mapStateToProps = (state, props) => {
  const entity = ENTITIES[MEDIA_RESOURCE];
  const post = state[entity].get("selectedItem");
  const description = state[entity].get("description");
  const title = state[entity].getIn(["multiSVG", "title"]);
  const prodID = selectEntityId(state);
  return {
    post: post,
    title: title,
    icons: selectIconsData(state),
    format: selectCurrentWorkspaceFormat(state),
    settings: selectWorkspaceSettingsJS(state),
    selectedIcons: selectSelectedIcons(state),
    selectedSizes: selectSizesOfSelectedIcons(state),
    mediaresourcesPath: state.location.routesMap[
      "ROUTE_MEDIARESOURCES"
    ].path.replace(":entityId", prodID),
    prev: state.location.prev,
    description: description
  };
};

const mapDispatchToProps = dispatch => ({
  setMultiSVGData: data => dispatch(initIconData(data)),
  updateIcons: data => dispatch(updateIcons(data)),
  setFormat: format =>
    dispatch(setMultiSVGFormat(ENTITIES[MEDIA_RESOURCE], format)),
  resize: size => dispatch(resizeIcons(size)),
  moveZ: direction => dispatch(moveIconsZ(direction)),
  duplicate: numberOfCopies => dispatch(duplicateIcons(numberOfCopies)),
  align: actionType => dispatch(alignIcons(actionType)),
  distribute: direction => dispatch(distributeIcons(direction)),
  remove: () => dispatch(removeIcons()),
  updateSettings: settings => dispatch(updateSettings(settings)),
  addIcon: newIcon => dispatch(addNewIcon(newIcon)),
  openMediaModal: openMediaModal(dispatch),
  savePost: post => dispatch(savePost(post)),
  duplicatePost: id => dispatch(duplicatePost(id, MEDIA_RESOURCE)),
  createPost: post => dispatch(createPost(post)),
  updateTitle: title => dispatch(updateTitle(ENTITIES[MEDIA_RESOURCE], title)),
  updateDraftState: draft => dispatch(setDraftState(ENTITIES[MEDIA_RESOURCE], draft))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageEditorContainer);
