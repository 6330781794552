import styled from "styled-components";
import { Tabs } from "react-tabs";

export const StyledStructureWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex: 1;

  .react-tabs__tab-panel {
    height: 100%;
  }

  .react-tabs__tab-list {
    margin: 0;
    z-index: 2;
  }
`;

export const StyledTabs = styled(Tabs)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.div`
  ${props => props.theme.d200()};
  margin: 0;
  padding: 10px 15px;
`;
