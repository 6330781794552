import React from "react";

const AidaHelp = ({ size, color }) => (
  <svg
    viewBox="0 0 17 17"
    width={`${size}px`}
    fill={color}
    className="studlicon-aidahelp"
  >
    <defs>
      <path d="M15.426 0H4.787c-.88 0-1.595.714-1.595 1.596v1.596H1.596C.714 3.192 0 3.906 0 4.787v10.64c0 .88.714 1.595 1.596 1.595h10.639c.881 0 1.595-.714 1.595-1.596V13.83h1.596c.882 0 1.596-.714 1.596-1.595V1.595c0-.88-.714-1.595-1.596-1.595zM3.192 4.256v7.979c0 .881.714 1.595 1.595 1.595h7.98v1.596a.533.533 0 0 1-.532.532H1.595a.533.533 0 0 1-.531-.532V4.787c0-.293.239-.531.532-.531h1.596zM16 1.02v11.575H4.426V1.021H16z" id="a"/>
      <path d="M8.681 0H.965a.95.95 0 0 0-.96.936L0 9.362 1.93 7.49H8.68c.53 0 .965-.422.965-.936V.936C9.646.421 9.212 0 8.68 0zm-.964 5.617H1.929v-.936h5.788v.936zm0-1.404H1.929v-.936h5.788v.936zm0-1.404H1.929v-.937h5.788v.937z" id="c"/>
    </defs>
    <g transform="translate(-.021 .112)" fill="none" fillRule="evenodd">
      <use fill="#FFF" fillRule="nonzero" transform="rotate(-180 8.511 8.511)" xlinkHref="#a"/>
      <g transform="translate(1.986 5.559)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c"/>
        </mask>
        <g mask="url(#d)">
          <path fill="#FFF" d="M-.965-.936H10.61v11.235H-.965z"/>
        </g>
      </g>
    </g>
</svg>
);

export default AidaHelp;
