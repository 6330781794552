import React from "react";
import styled from "styled-components";
import * as Icons from "react-feather";
import * as StudliIcons from "../StudliIcons";

const getSkin = props => {
  switch (props.skin) {
    case "standard":
      return props.theme.buttons.skins.standard();
    case "primary":
      return props.theme.buttons.skins.primary();
    case "tomoyo":
      return props.theme.buttons.skins.tomoyo();
    case "DM":
      return props.theme.buttons.skins.DM();
    case "cancel":
      return props.theme.buttons.skins.cancel();
    default:
      return props.theme.buttons.skins.defaults();
  }
};

const getOutline = props => {
  switch (props.skin) {
    case "standard":
      return props.theme.buttons.outline(
        props.theme.neutralTertiary,
        props.theme.neutralTertiary
      );
    case "primary":
      return props.theme.buttons.outline(
        props.theme.themeSecondary,
        props.theme.themePrimary
      );
    case "tomoyo":
      return props.theme.buttons.outline(
        props.theme.tomoyoPrimary,
        props.theme.tomoyoPrimary
      );
    case "DM":
      return props.theme.buttons.outline(
        props.theme.themePrimary,
        props.theme.themePrimary
      );
    default:
      return null;
  }
};

const ButtonContent = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1px;
  min-height: 1px;
  ${props => props.$html && "display: inline-block"};
`;

const ButtonIcon = styled(({ margin, ...rest }) => <span {...rest} />)`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: ${props => props.margin};
  vertical-align: top;
  & > svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

const Button = ({
  skin,
  outline,
  transparent,
  children,
  icon,
  studlicon,
  withInnerHTML,
  ...rest
}) => {
  let iconMarkup = null;

  if (icon) {
    let Icon = null;
    if (typeof icon !== "string") {
      Icon = icon;
    } else {
      Icon = Icons[icon];
      if (!Icon) {
        throw new Error(`Could not find icon '${icon}'`);
      }
      Icon = <Icon />;
    }

    iconMarkup = (
      <ButtonIcon margin={children ? "0 8px 0 0" : "0"}>{Icon}</ButtonIcon>
    );
  }

  if (studlicon) {
    let Icon = null;
    const studliSize = 20;
    if (typeof studlicon !== "string") {
      Icon = studlicon;
    } else {
      Icon = StudliIcons[studlicon];
      if (!Icon) {
        throw new Error(`Could not find icon '${icon}'`);
      }
      Icon = <Icon size={studliSize} />;
    }

    iconMarkup = (
      <ButtonIcon margin={children ? "0 8px 0 0" : "0"}>{Icon}</ButtonIcon>
    );
  }

  return (
    <button {...rest}>
      {withInnerHTML ? (
        <ButtonContent $html dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        <ButtonContent>
          {iconMarkup}
          {children}
        </ButtonContent>
      )}
    </button>
  );
};

export default styled(Button)`
  position: relative;
  display: ${({ visible = true }) => (visible ? "inline-flex" : "none")};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: ${props => props.theme.buttons.height};
  padding: 0 ${props => props.theme.buttons.padding};
  margin: 0;
  border: none;
  border-radius: 2px;
  outline: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;

  &::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	&:active {
		outline: none;
	}

	&:hover {
		cursor: pointer;
	}

	&:disabled {
		pointer-events: none;
    opacity: 0.6;
	}

	${props => getSkin(props)}
	${props => props.theme.buttons.typography(...props.theme.buttons.defaultType)}

	${props => (props.outline ? getOutline(props) : null)}

	${props => (props.transparent ? props.theme.buttons.transparent() : null)}
`;
