/**
 * Generates default multiple choice data object
 * @returns {{options: Array}}
 */
export const defaultMultipleChoiceData = () => ({
  options: []
});

/**
 * Generates default multiple choice settings object
 * @returns {{multipleAnswers: boolean, randomizeAlternatives: boolean, operative: boolean}}
 */
export const defaultMultipleChoiceSettings = () => ({
  multipleAnswers: false,
  randomizeAlternatives: true,
  operative: true
});
