import * as React from "react";
import PluginHoc from "../../../shared/PluginHoc";
import {
  StyledFraction,
  StyledFractionContent,
  StyledResultAnswer,
  StyledResultAnswerField,
  StyledResultLabel,
  StyledResultContainer,
  StyledGridContainer,
  StyledResultWrapper,
  StyledResultDivisor,
  StyledInteractiveWrapper,
  StyledResultAnswerWrapper,
  StyledResultTogglesContainer,
  StyledAddItemContainer,
  StyledButtonPrimary,
  StyledButtonStandard,
  StyledIntegerContainer,
  StyledWarningText
} from "./StyledFraction";
import SideBar from "../Sidebar/Sidebar";
import {
  settingsData,
  rowSettingStyles,
  generateGridWithTerms,
  calculateResult,
  isErrorFree,
  fractionObject,
  keyValueFractionObject
} from "./FractionData";
import {
  DATA,
  DECIMAL,
  FRACTION,
  FRACTION_METHOD,
  IMAGE_METHOD,
  INTERACTIVE,
  ITEMS,
  MIXED,
  PERCENT,
  RESULT
} from "../../../constants";
import { FractionItem, ItemContainer } from "./FractionItem";
import FractionGrid from "./FractionGrid/FractionGrid";
import ToggleButton from "./ToggleButton";
import { clone } from "ramda";
import { mirrorGrid } from "./FractionHelpers";

const Fraction = props => {
  /**
   * Ref for calculate type.
   */
  const calculateTypeRef = React.useRef(props.settings.calculateType);

  /**
   * Ref for answer type.
   */
  const answerTypeRef = React.useRef(props.settings.answerType);

  /**
   * Ref for method type.
   */
  const methodTypeRef = React.useRef(props.settings.methodType);

  /**
   * Ref for decimal count type.
   */
  const decimalCountRef = React.useRef(props.settings.decimalCount);

  /**
   * Ref for simplify type.
   */
  const simplifyRef = React.useRef(props.settings.simplify);

  /**
   * Ref for simplify count type.
   */
  const simplifyHiddenRef = React.useRef(props.settings.simplifyHidden);

  /**
   * Ref for simplify count type.
   */
  const simplifyCountRef = React.useRef(props.settings.simplifyMultiple);

  /**
   * This Effect listens to changes done to the calculate type setting or answer type setting.
   */
  React.useEffect(() => {
    if (
      props.settings.simplify !== simplifyRef.current ||
      props.settings.simplifyMultiple !== simplifyCountRef.current ||
      props.settings.simplifyHidden !== simplifyHiddenRef.current
    ) {
      simplifyRef.current = props.settings.simplify;
      simplifyHiddenRef.current = props.settings.simplifyHidden;
      simplifyCountRef.current = props.settings.simplifyMultiple;

      const {
        data: { items, result, mirrored }
      } = props;
      generateAndUpdateData(items, result, mirrored);
    }
    // eslint-disable-next-line
  }, [
    props.settings.simplify,
    props.settings.simplifyMultiple,
    props.settings.simplifyHidden
  ]);

  /**
   * This Effect listens to changes done to the calculate type setting or answer type setting.
   */
  React.useEffect(() => {
    if (
      props.settings.calculateType !== calculateTypeRef.current ||
      props.settings.answerType !== answerTypeRef.current
    ) {
      calculateTypeRef.current = props.settings.calculateType;
      answerTypeRef.current = props.settings.answerType;

      const {
        data: { items, result, mirrored }
      } = props;
      generateAndUpdateData(items, result, mirrored);
    }
    // eslint-disable-next-line
  }, [
    props.settings.calculateType,
    props.settings.answerType,
    props.settings.equalSign
  ]);

  /**
   * This Effect listens to changes done to the method type.
   */
  React.useEffect(() => {
    if (props.settings.methodType !== methodTypeRef.current) {
      methodTypeRef.current = props.settings.methodType;

      const {
        data: { items, result, mirrored },
        settings: { methodType }
      } = props;
      let updatedItems = [items[0]];

      if (methodType === FRACTION_METHOD) {
        updatedItems.push(fractionObject({}));
      }

      generateAndUpdateData(updatedItems, result, mirrored);
    }
    // eslint-disable-next-line
  }, [props.settings.methodType]);

  /**
   * This Effect listens to changes done to the decimal count.
   */
  React.useEffect(() => {
    if (props.settings.decimalCount !== decimalCountRef.current) {
      decimalCountRef.current = props.settings.decimalCount;

      const {
        data: { items, result, mirrored }
      } = props;
      generateAndUpdateData(items, result, mirrored);
    }
    // eslint-disable-next-line
  }, [props.settings.decimalCount]);

  /**
   * Registers the validator after render
   */
  React.useEffect(() => {
    props.registerValidator([
      validateInUse,
      "Det måste finnas något att räkna på eller så finns det inte någon Grid för tillfället."
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.result]);

  /**
   * Validator checking if grid is available.
   * @returns {*}
   */
  const validateInUse = componentData => componentData.grid !== null;

  /**
   * Function that runs after items has moved.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...props.data.items];
    const result = { ...props.data.result };
    const { mirrored } = props.data;
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);

    generateAndUpdateData(items, result, mirrored);
  };

  /**
   * Callback for content changes.
   * @param {*} name
   * @param {*} content
   * @param {*} index
   */
  const updateContent = (name, content, index) => {
    const { items, result, mirrored } = props.data;
    const originalItems =
      name === RESULT || name === INTERACTIVE
        ? items
        : items.filter(elem => !elem.ghost && !elem.simplified);

    const key = Object.keys(content)[0];
    const value = Object.values(content)[0];

    let item = { ...originalItems[index] };

    switch (name) {
      case RESULT:
        result[key] = { ...result[key], interactive: value };
        break;
      case INTERACTIVE:
        item[key] = { ...item[key], interactive: value };
        break;
      case ITEMS:
        item[key] = { ...item[key], value };
        break;
      default:
        break;
    }

    const updatedItems = originalItems.map((element, i) =>
      i === index ? item : element
    );
    generateAndUpdateData(updatedItems, result, mirrored);
  };

  /**
   * Callback for result interactive changes
   * @param {*} name
   * @param {*} content
   */
  const updateResultCallback = (name, content) => () =>
    updateContent(name, content);

  /**
   * Callback for adding a new item
   */
  const addNewItem = () => {
    const { items, result } = props.data;
    const originalItems = items.filter(item => !item.ghost && !item.simplified);

    if (originalItems.length < 3) {
      const newItem = {
        integer: { value: "", interactive: false },
        numerator: { value: "", interactive: false },
        denominator: { value: "", interactive: false }
      };

      items.splice(originalItems.length, 0, newItem);
      generateAndUpdateData(items, result, false);
    } else {
      alert("Det får max finnas 3 tal");
    }
  };

  /**
   * Callback for removing an item.
   * @param {*} index
   */
  const removeItem = index => {
    const { items, result, mirrored } = props.data;
    const originalItems = items.filter(item => !item.ghost && !item.simplified);

    if (originalItems.length > 2) {
      const updatedItems = items.filter((_, i) => i !== index);
      generateAndUpdateData(updatedItems, result, mirrored);
    } else {
      alert("Det måste finnas minst 2 tal kvar");
    }
  };

  /**
   * Generates & returns the result label & grid
   * @param {*} items
   * @param {*} calculateType
   */
  const generateFractionData = (items, resultObj) => {
    const {
      calculateType,
      answerType,
      methodType,
      simplifyHidden
    } = props.settings;

    for (let i = 0; i < items.length; i++) {
      const { condition } = isErrorFree(
        answerType,
        ITEMS,
        ...keyValueFractionObject(
          items[i].integer.value,
          items[i].numerator.value,
          items[i].denominator.value
        )
      );
      if (!condition) {
        return {
          resultLabel: {
            ...fractionObject({
              i1: resultObj.integer.interactive,
              i2: resultObj.numerator.interactive,
              i3: resultObj.denominator.interactive
            })
          },
          grid: null,
          newItems: items
        };
      }
    }

    const originalItems = items.filter(item => !item.ghost && !item.simplified);
    const calculation = calculateResult(
      originalItems,
      resultObj,
      props.settings
    );
    const calculationCopyResult = clone(calculation);
    const result = calculationCopyResult.pop();

    for (let i = 0; i < calculationCopyResult.length; i++) {
      if (calculation[i] && items[i]) {
        calculation[i].integer.interactive = items[i].integer.interactive;
        calculation[i].numerator.interactive = items[i].numerator.interactive;
        calculation[i].denominator.interactive =
          items[i].denominator.interactive;
      }
    }

    const calculationCopy = clone(calculation);
    calculationCopy.pop();

    const resultLabel = {
      integer: {
        value: result.integer.value,
        interactive: result.integer.interactive
      },
      numerator: {
        value: result.numerator.value,
        interactive: result.numerator.interactive
      },
      denominator: {
        value: result.denominator.value,
        interactive: result.denominator.interactive
      }
    };

    const conditionsIsMet = calculation.every(
      (item, index) =>
        isErrorFree(
          answerType,
          index === calculation.length - 1 ? RESULT : ITEMS,
          ...keyValueFractionObject(
            item.integer.value,
            item.numerator.value,
            item.denominator.value
          )
        ).condition
    );

    const terms = methodType === IMAGE_METHOD ? [{ ...result }] : calculation;

    const grid = conditionsIsMet
      ? generateGridWithTerms(
          calculateType,
          terms,
          originalItems,
          answerType,
          methodType,
          simplifyHidden
        )
      : null;

    return {
      resultLabel,
      newItems: calculationCopy,
      grid,
      error: isErrorFree(
        answerType,
        RESULT,
        ...keyValueFractionObject(
          resultLabel.integer.value,
          resultLabel.numerator.value,
          resultLabel.denominator.value
        )
      ).error
    };
  };

  /**
   * Generates fraction data and updates state
   * @param {*} items
   * @param {*} result
   */
  const generateAndUpdateData = (items, result, mirrored) => {
    const { resultLabel, grid, newItems, error } = generateFractionData(
      items,
      result
    );
    const updatedGrid = mirrorGrid(grid, mirrored);

    props.storeDraft(props.draftTarget);
    props.updateData(
      null,
      {
        items: newItems,
        result: resultLabel,
        grid: updatedGrid,
        mirrored,
        error
      },
      DATA
    );
  };

  /**
   * Render Lineup sidebar
   * @returns {*}
   */
  const renderLineupSidebar = () => (
    <SideBar
      updateData={props.updateData}
      storeDraft={props.storeDraft}
      draftTarget={props.draftTarget}
      settings={props.settings}
      data={settingsData(props.settings)}
      rowSettingStyles={rowSettingStyles}
      keyboards={props.keyboards}
    />
  );

  /**
   * Renders lineup grid
   */
  const renderGrid = () => (
    <StyledGridContainer>
      <FractionGrid
        grid={props.data.grid}
        type={props.settings.calculateType}
      />
    </StyledGridContainer>
  );

  /**
   * Render the result
   * @param {*} numerator
   * @param {*} denominator
   */
  const renderResult = (numerator, denominator) => (
    <StyledResultContainer>
      <StyledResultAnswerWrapper>
        <StyledResultAnswerField>
          <StyledResultAnswer>{numerator ? numerator : 0}</StyledResultAnswer>
        </StyledResultAnswerField>
      </StyledResultAnswerWrapper>
      <StyledResultDivisor />
      <StyledResultAnswerWrapper>
        <StyledResultAnswerField>
          <StyledResultAnswer>
            {denominator ? denominator : 0}
          </StyledResultAnswer>
        </StyledResultAnswerField>
      </StyledResultAnswerWrapper>
    </StyledResultContainer>
  );

  /**
   * Render the result toggle buttons
   * @param {*} numerator
   * @param {*} denominator
   */
  const renderResultToggles = (numerator, denominator) => (
    <StyledResultTogglesContainer>
      <StyledInteractiveWrapper>
        <ToggleButton
          title="Inputfält"
          isActive={numerator}
          callback={updateResultCallback(RESULT, { numerator: !numerator })}
        />
      </StyledInteractiveWrapper>
      <StyledInteractiveWrapper>
        <ToggleButton
          title="Inputfält"
          isActive={denominator}
          callback={updateResultCallback(RESULT, { denominator: !denominator })}
        />
      </StyledInteractiveWrapper>
    </StyledResultTogglesContainer>
  );

  const renderInteger = (value, interactive, answerType) => (
    <StyledIntegerContainer>
      <StyledInteractiveWrapper>
        <ToggleButton
          isActive={interactive}
          callback={updateResultCallback(RESULT, { integer: !interactive })}
        />
      </StyledInteractiveWrapper>
      <StyledResultAnswerWrapper>
        <StyledResultAnswerField>
          <StyledResultAnswer big={true}>
            {`${value ? value : 0} ${answerType === PERCENT ? "%" : ""}`}
          </StyledResultAnswer>
        </StyledResultAnswerField>
      </StyledResultAnswerWrapper>
    </StyledIntegerContainer>
  );

  /**
   * Renders the result
   */
  const renderResultBox = () => {
    const { result } = props.data;
    const { answerType } = props.settings;
    const integerValue = result && result.integer && result.integer.value;
    const numeratorValue = result && result.numerator && result.numerator.value;
    const denominatorValue =
      result && result.denominator && result.denominator.value;
    const integerInteractive =
      result && result.integer && result.integer.interactive;
    const numeratorInteractive =
      result && result.numerator && result.numerator.interactive;
    const denominatorInteractive =
      result && result.denominator && result.denominator.interactive;

    const { integer, fraction } = renderResultPartsCheck(
      answerType,
      numeratorValue
    );

    return (
      <StyledResultWrapper>
        <StyledResultLabel>Svar:</StyledResultLabel>
        {integer && renderInteger(integerValue, integerInteractive, answerType)}
        {fraction && renderResult(numeratorValue, denominatorValue)}
        {fraction &&
          renderResultToggles(numeratorInteractive, denominatorInteractive)}
      </StyledResultWrapper>
    );
  };

  const renderResultPartsCheck = (answerType, numeratorValue) => {
    switch (answerType) {
      case FRACTION:
        return { integer: false, fraction: true };
      case MIXED:
        return { integer: true, fraction: numeratorValue > 0 };
      case DECIMAL:
        return { integer: true, fraction: false };
      case PERCENT:
        return { integer: true, fraction: false };
      default:
        break;
    }
  };

  /**
   * Renders add item if is not read only state
   * @returns {null}
   */
  const renderAddItem = () => {
    if (!!props.readOnly) {
      return null;
    }
    return (
      <StyledAddItemContainer>
        <StyledButtonPrimary onClick={addNewItem}>
          Lägg till rad
        </StyledButtonPrimary>
      </StyledAddItemContainer>
    );
  };

  /**
   * Updates mirror property
   */
  const updateMirror = () => {
    const { items, result, mirrored } = props.data;
    generateAndUpdateData(items, result, !mirrored);
  };

  /**
   * Renders mirror button
   * @param {*} mirrored
   */
  const renderStyledButton = mirrored =>
    mirrored ? (
      <StyledButtonPrimary onClick={updateMirror}>Spegla</StyledButtonPrimary>
    ) : (
      <StyledButtonStandard onClick={updateMirror}>Spegla</StyledButtonStandard>
    );

  /**
   * Renders add item if is not read only state
   * @returns {null}
   */
  const renderMirrorButton = () => {
    if (!!props.readOnly) {
      return null;
    }
    return (
      <StyledAddItemContainer>
        {renderStyledButton(props.data.mirrored)}
      </StyledAddItemContainer>
    );
  };

  /**
   * Renders warning text
   * @param {*} text
   */
  const renderWarningText = () => (
    <StyledWarningText>{props.data.error}</StyledWarningText>
  );

  /**
   * Renders items for lineup
   */
  const renderFractionItems = () => (
    <ItemContainer useDragHandle onSortEnd={onSortEnd}>
      {renderItems()}
    </ItemContainer>
  );

  /**
   * Render items
   */
  const renderItems = () => {
    const {
      actions,
      data: { items },
      settings: { calculateType, methodType }
    } = props;
    return (
      items &&
      items.map((item, i) => (
        <FractionItem
          key={"alt" + i}
          indx={i}
          index={i}
          data={item}
          actions={actions}
          methodType={methodType}
          updateContent={updateContent}
          removeItemFn={removeItem}
          type={calculateType}
        />
      ))
    );
  };

  return (
    <StyledFraction>
      <StyledFractionContent>
        {renderFractionItems()}
        {props.settings.methodType === FRACTION_METHOD && renderAddItem()}
        {props.settings.methodType !== IMAGE_METHOD && renderMirrorButton()}
        {props.data.error && renderWarningText()}
        {props.data.grid && renderGrid()}
        {renderResultBox()}
      </StyledFractionContent>
      {renderLineupSidebar()}
    </StyledFraction>
  );
};

export default PluginHoc({
  Component: Fraction
});
