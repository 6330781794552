export const SHOW_FLASH = "SHOW_FLASH";
export const PREPARE_SHOW_FLASH = "PREPARE_SHOW_FLASH";
export const CLEAR_FLASH = "CLEAR_FLASH";
export const ADD_FLASH = "ADD_FLASH";

export const clearFlash = id => ({
  type: CLEAR_FLASH,
  id
});

export const addFlash = (msg, type = "info", delay = true) => ({
  type: ADD_FLASH,
  msg,
  flashType: type,
  delay
});
