/**
 * Flattens the tree array
 * @param nodes
 * @returns {*[]}
 */
export const flattenTree = nodes => {
  const flattener = node => {
    if (
      node.hasOwnProperty("children") &&
      node.children !== null &&
      node.children.length > 0
    ) {
      return [].concat(...[node, ...node.children.map(flattener)]);
    }
    return node;
  };

  return [].concat(...nodes.map(flattener));
};

/**
 * Adds "expanded:true" to all nodes in the tree structure.
 * @param data
 * @returns {*}
 */
export const addExpandKeyForAll = data => {
  const addExpandKey = obj => {
    if (
      obj.hasOwnProperty("children") &&
      obj.children !== null &&
      obj.children.length > 0
    ) {
      return {
        ...obj,
        children: obj.children.map(addExpandKey),
        expanded: true
      };
    }
    return { ...obj, expanded: true };
  };

  return data.map(addExpandKey);
};
