import { put, select, take, call } from "redux-saga/effects";
import {
  fetchEntities,
  fetchEntityDetails,
  nextPage,
  previousPage,
  gotoPage,
  fetchFilter,
  resetBeforeFilterSortChange,
  fetchComments,
  fetchKeyboardList
} from "../api/actions";

import { FETCH_ENTITIES_SUCCESS } from "../../api";

import {
  selectPage,
  selectFetchLimit,
  selectQuery,
  selectPostId,
  selectFilters,
  selectSearchParams,
  selectCarouselActive
} from "./selectors";
import { actionCreator } from "../../shared";
import { selectEntityId } from "../../store/selectors";
import { fetchAllChips } from "../store/actions";

/**
 * Get list of posts
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* onListRoute(entityName, apiAction) {
  let page = yield select(selectPage, entityName);
  const params = yield select(selectQuery);

  if (params.page) {
    page = params.page;
  }

  const entityId = yield select(selectEntityId);
  const fetchLimit = yield select(selectFetchLimit, entityName);

  yield put(
    fetchEntities(
      entityName,
      apiAction,
      { entityId },
      { offset: fetchLimit * (page - 1), num_result: fetchLimit, ...params }
    )
  );

  yield take(actionCreator(entityName, FETCH_ENTITIES_SUCCESS));
}

/**
 * Fetches list of posts for a specific page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} args
 * @param {*} params
 * @param {*} page
 */
function* fetchPage(entityName, apiAction, args, params, page) {
  const fetchLimit = yield select(selectFetchLimit, entityName);
  yield put(
    fetchEntities(entityName, apiAction, args, {
      // we need to subtract by one to get desired page. Page 1 = 50 * 0 on
      offset: fetchLimit * (page - 1),
      num_result: fetchLimit,
      ...params
    })
  );
}

/**
 * Fetch filter content
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* onFilterRoute(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const filters = yield select(selectFilters);

  const labels = yield select(st => st[entityName].get("labels").toJS());
  if (labels.length > 0 && !filters.includes("COMPONENT")) {
    return;
  }
  yield put(fetchFilter(entityName, apiAction, { entityId }, filters));
}

/**
 * Load comments in post. Don't load comments if id is 'new'.
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* onLoadComments(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const id = yield select(selectPostId);
  if (id !== "new") {
    yield put(fetchComments(entityName, apiAction, { entityId, postId: id }));
  }
}

export function* onLoadKeyboards(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const id = yield select(selectPostId);
  yield put(fetchKeyboardList(entityName, apiAction, { entityId, postId: id }));
}

/**
 * Filter posts according to filterParams
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} filterParams
 */
export function* filterPosts(entityName, apiAction, filterParams) {
  const entityId = yield select(selectEntityId);
  const params = filterParams.filter;

  yield put(resetBeforeFilterSortChange(entityName));

  const page = yield select(selectPage, entityName);

  yield call(fetchPage, entityName, apiAction, { entityId }, params, page);
  yield take(actionCreator(entityName, FETCH_ENTITIES_SUCCESS));
}

/**
 * Sort the list
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} param2
 */
export function* sortList(entityName, apiAction, { sort }) {
  const entityId = yield select(selectEntityId);

  yield put(resetBeforeFilterSortChange(entityName));

  const searchParams = yield select(selectSearchParams, entityName);
  const params = { ...searchParams, sort };

  yield call(fetchPage, entityName, apiAction, { entityId }, params, 1);

  yield take(actionCreator(entityName, FETCH_ENTITIES_SUCCESS));
}

/**
 * Fetch new entity detail when going back and forth in carousel. Also updates comments
 * @param {*} apiAction
 */
export function* carousel(apiAction) {
  const active = yield select(selectCarouselActive);
  const entityId = yield select(selectEntityId);
  const entityName = active.actions.editLink.postType;

  yield put(
    fetchEntityDetails(entityName, apiAction, {
      entityId: entityId,
      postId: active.id
    })
  );
  yield put(
    fetchComments(entityName, apiAction, { entityId, postId: active.id })
  );
}

/**
 * Fetches list of entities for the current page
 * @param {*} entityName
 * @param {*} apiAction
 */
function* fetchCurrentPage(entityName, apiAction) {
  const currentPage = yield select(selectPage, entityName);
  const entityId = yield select(selectEntityId);
  const searchParams = yield select(selectSearchParams, entityName);
  const fetchLimit = yield select(selectFetchLimit, entityName);
  yield put(
    fetchEntities(
      entityName,
      apiAction,
      { entityId },
      {
        ...searchParams,
        offset: (currentPage - 1) * fetchLimit,
        num_result: fetchLimit
      }
    )
  );
}
/**
 * Fetches previous page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* previousPageSaga(entityName, apiAction) {
  yield put(previousPage(entityName));
  yield fetchCurrentPage(entityName, apiAction);
}

/**
 * Fetches the next page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* nextPageSaga(entityName, apiAction) {
  yield put(nextPage(entityName));
  yield fetchCurrentPage(entityName, apiAction);
}

/**
 * Fetches a specific page in pagination
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} param2
 */
export function* loadPageSaga(entityName, apiAction, { page }) {
  yield put(gotoPage(entityName, page));
  yield fetchCurrentPage(entityName, apiAction);
}

/**
 * Reload the current page
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* reloadPageSaga(entityName, apiAction) {
  yield fetchCurrentPage(entityName, apiAction);
}

/**
 * Fetches an entity
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* onDetailedRoute(entityName, apiAction) {
  const postId = yield select(selectPostId);
  const entityId = yield select(selectEntityId);

  yield put(
    fetchEntityDetails(entityName, apiAction, {
      entityId: entityId,
      postId: postId
    })
  );
}

export function* onFetchChips(entityName) {
  const entityId = yield select(selectEntityId);
  yield put(
    fetchAllChips(entityName, {
      entityId: entityId
    })
  );
}
