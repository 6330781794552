import { actionCreator } from "../../shared";

export const ACTION_UPLOADING_DONE = "UPLOADING_DONE";
export const ACTION_CREATE_ENTITY = `CREATE_ENTITY`;
export const ACTION_CREATE_ENTITY_START = `CREATE_ENTITY_START`;
export const ACTION_CREATE_ENTITY_SUCCESS = `CREATE_ENTITY_SUCCESS`;
export const ACTION_CREATE_ENTITY_FAILED = `CREATE_ENTITY_FAILED`;
export const ACTION_CREATE_ENTITY_END = `CREATE_ENTITY_END`;

export const ACTION_SAVE_ENTITY = `SAVE_ENTITY`;
export const ACTION_SAVE_ENTITY_FAILED = `SAVE_ENTITY_FAILED`;
export const ACTION_SAVE_ENTITY_SUCCESS = `SAVE_ENTITY_SUCCESS`;
export const ACTION_SAVE_JSON_SUCCESS = `SAVE_JSON_SUCCESS`;

export const ACTION_DELETE_ENTITY = `DELETE_ENTITY`;
export const ACTION_DELETE_ENTITY_FAILED = `DELETE_ENTITY_FAILED`;
export const ACTION_DELETE_ENTITY_SUCCESS = `DELETE_ENTITY_SUCCESS`;

export const ACTION_GET_PUBLISH_LIST = `ACTION_GET_PUBLISH_LIST`;
export const ACTION_GET_PUBLISH_LIST_SUCCESS = `ACTION_GET_PUBLISH_LIST_SUCCESS`;
export const ACTION_RESET_PUBLISH_LIST = `ACTION_RESET_PUBLISH_LIST`;

export const ACTION_PUBLISH_ENTITY = `PUBLISH_ENTITY`;
export const ACTION_PUBLISH_ENTITY_FAILED = `PUBLISH_ENTITY_FAILED`;
export const ACTION_PUBLISH_ENTITY_SUCCESS = `PUBLISH_ENTITY_SUCCESS`;

export const ACTION_UNPUBLISH_ENTITY = `UNPUBLISH_ENTITY`;
export const ACTION_UNPUBLISH_ENTITY_FAILED = `UNPUBLISH_ENTITY_FAILED`;
export const ACTION_UNPUBLISH_ENTITY_SUCCESS = `UNPUBLISH_ENTITY_SUCCESS`;

export const ACTION_REJECT_ENTITY = `REJECT_ENTITY`;
export const ACTION_REJECT_ENTITY_FAILED = `REJECT_ENTITY_FAILED`;
export const ACTION_REJECT_ENTITY_SUCCESS = `REJECT_ENTITY_SUCCESS`;

export const ACTION_REVIEW_ENTITY = `REVIEW_ENTITY`;
export const ACTION_REVIEW_ENTITY_FAILED = `REVIEW_ENTITY_FAILED`;
export const ACTION_REVIEW_ENTITY_SUCCESS = `REVIEW_ENTITY_SUCCESS`;

export const ACTION_REQUEST_REVIEW_ENTITY = `REQUEST_REVIEW_ENTITY`;
export const ACTION_REQUEST_REVIEW_ENTITY_FAILED = `REQUEST_REVIEW_ENTITY_FAILED`;
export const ACTION_REQUEST_REVIEW_ENTITY_SUCCESS = `REQUEST_REVIEW_ENTITY_SUCCESS`;

export const ACTION_UPDATE_ORDER = `UPDATE_ORDER`;
export const ACTION_UPDATE_ORDER_FAILED = `UPDATE_ORDER_FAILED`;
export const ACTION_UPDATE_ORDER_SUCCESS = `UPDATE_ORDER_SUCCESS`;

export const ACTION_DELETE_MEDIA_ITEM = `DELETE_MEDIA_ITEM`;
export const ACTION_DELETE_MEDIA_ITEM_SUCCESS = `ACTION_DELETE_MEDIA_ITEM_SUCCESS`;
export const ACTION_DELETE_MEDIA_ITEM_FAILED = `ACTION_DELETE_MEDIA_ITEM_FAILED`;
export const ACTION_DELETE_MEDIA_SECTION = `DELETE_MEDIA_SECTION`;
export const ACTION_DELETE_MEDIA_SECTION_SUCCESS = `ACTION_DELETE_MEDIA_SECTION_SUCCESS`;
export const ACTION_DELETE_MEDIA_SECTION_FAILED = `ACTION_DELETE_MEDIA_SECTION_FAILED`;

export const ACTION_RELOAD_ADVENTURES = 'ACTION_RELOAD_ADVENTURES';
export const ACTION_UPDATE_COLLECTION_MARKER = 'ACTION_UPDATE_COLLECTION_MARKER';
export const ACTION_UPDATE_COLLECTION_MARKER_SUCCESS = 'ACTION_UPDATE_COLLECTION_MARKER_SUCCESS'; 
export const ACTION_UPDATE_COLLECTION_MARKER_FAILURE = 'ACTION_UPDATE_COLLECTION_MARKER_FAILURE'; 

export const deleteMediaItem = (entityName, mediaId) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_ITEM),
  mediaId
});


export const deleteMediaItemSuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_ITEM_SUCCESS),
  data
});

export const deleteMediaItemFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_ITEM_FAILED),
  error
});


export const deleteMediaSection = (entityName, mediaType) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_SECTION),
  mediaType
});

export const deleteMediaSectionSuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_SECTION_SUCCESS),
  data
});

export const deleteMediaSectionFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_DELETE_MEDIA_ITEM_FAILED),
  error
});

export const uploadingDone = (entityName) => ({
  type: actionCreator(entityName, ACTION_UPLOADING_DONE)
});

export const createEntity = (entityName) => ({
  type: actionCreator(entityName, ACTION_CREATE_ENTITY)
});

export const createEntityStart = (entityName) => ({
  type: actionCreator(entityName, ACTION_CREATE_ENTITY_START)
});

export const createEntitySuccess = (entityName) => ({
  type: actionCreator(entityName, ACTION_CREATE_ENTITY_SUCCESS)
});

export const createEntityFailed = (entityName) => ({
  type: actionCreator(entityName, ACTION_CREATE_ENTITY_FAILED)
});

export const createEntityEnd = (entityName) => ({
  type: actionCreator(entityName, ACTION_CREATE_ENTITY_END)
});

export const saveEntity = (entityName, media) => ({
  type: actionCreator(entityName, ACTION_SAVE_ENTITY),
  media
});

export const saveEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_SAVE_ENTITY_SUCCESS),
  data
});

export const saveJsonSuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_SAVE_JSON_SUCCESS),
  data
});

export const saveEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_SAVE_ENTITY_FAILED),
  error
});

export const deleteEntity = (entityName, id) => ({
  type: actionCreator(entityName, ACTION_DELETE_ENTITY),
  id
});

export const deleteEntitySuccess = (entityName, id) => ({
  type: actionCreator(entityName, ACTION_DELETE_ENTITY_SUCCESS),
  id
});

export const deleteEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_DELETE_ENTITY_FAILED),
  error
});

export const getPublishList = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_GET_PUBLISH_LIST),
  data
});

export const getPublishListSuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_GET_PUBLISH_LIST_SUCCESS),
  data
});

export const resetPublishList = (entityName) => ({
  type: actionCreator(entityName, ACTION_RESET_PUBLISH_LIST)
});

export const publishEntity = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_PUBLISH_ENTITY),
  data
});

export const publishEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_PUBLISH_ENTITY_FAILED),
  error
});

export const publishEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_PUBLISH_ENTITY_SUCCESS),
  data
});

export const unpublishEntity = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_UNPUBLISH_ENTITY),
  data
});

export const unpublishEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_UNPUBLISH_ENTITY_FAILED),
  error
});

export const unpublishEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_UNPUBLISH_ENTITY_SUCCESS),
  data
});

export const rejectEntity = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REJECT_ENTITY),
  data
});

export const rejectEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_REJECT_ENTITY_FAILED),
  error
});

export const rejectEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REJECT_ENTITY_SUCCESS),
  data
});

export const requestReviewEntity = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REQUEST_REVIEW_ENTITY),
  data
});

export const requestReviewEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_REQUEST_REVIEW_ENTITY_FAILED),
  error
});

export const requestReviewEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REQUEST_REVIEW_ENTITY_SUCCESS),
  data
});

export const reviewEntity = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REVIEW_ENTITY),
  data
});

export const reviewEntityFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_REVIEW_ENTITY_FAILED),
  error
});

export const reviewEntitySuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_REVIEW_ENTITY_SUCCESS),
  data
});

export const updateOrder = (entityName, list) => ({
  type: actionCreator(entityName, ACTION_UPDATE_ORDER),
  list
});

export const updateOrderSuccess = (entityName, list) => ({
  type: actionCreator(entityName, ACTION_UPDATE_ORDER_SUCCESS),
  list
});

export const updateOrderFailed = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_UPDATE_ORDER_FAILED),
  error
});

export const reloadAdventures = (entityName) => ({
  type: ACTION_RELOAD_ADVENTURES,
  entityName
});

export const updateCollectionMarker = (entityName, marker) => ({
  type: actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER),
  marker
});

export const updateCollectionMarkerSuccess = (entityName, data) => ({
  type: actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER_SUCCESS),
  data
});

export const updateCollectionMarkerFailure = (entityName, error) => ({
  type: actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER_FAILURE),
  error
})