import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_DETAILS_ERROR,
  FETCH_ENTITY_DETAILS_SUCCESS,
  START_FETCH,
  FETCH_ENTITY_DETAILS,
  SAVE_ENTITY,
  SAVE_ENTITY_ERROR,
  SAVE_ENTITY_SUCCESS,
  START_SAVE_ENTITY,
  END_SAVE_ENTITY
} from "../../api";

export const ACTION_FETCH_PRODUCTS = `PRODUCT/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCTS_START = `PRODUCT/${START_FETCH}`;
export const ACTION_FETCH_PRODUCTS_SUCCESS = `PRODUCT/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_PRODUCTS_FAILED = `PRODUCT/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_PRODUCTS_END = `PRODUCT/${END_FETCH}`;

export const ACTION_FETCH_PRODUCT_DETAILS = `PRODUCT/${FETCH_ENTITY_DETAILS}`;
export const ACTION_FETCH_PRODUCT_DETAILS_START = `PRODUCT/${START_FETCH}`;
export const ACTION_FETCH_PRODUCT_DETAILS_SUCCESS = `PRODUCT/${FETCH_ENTITY_DETAILS_SUCCESS}`;
export const ACTION_FETCH_PRODUCT_DETAILS_FAILED = `PRODUCT/${FETCH_ENTITY_DETAILS_ERROR}`;
export const ACTION_FETCH_PRODUCT_DETAILS_END = `PRODUCT/${END_FETCH}`;

export const ACTION_FETCH_PRODUCT_STATUS_SUMMARY = `PRODUCT_STATUS_SUMMARY/${FETCH_ENTITY_DETAILS}`;
export const ACTION_FETCH_PRODUCT_STATUS_SUMMARY_START = `PRODUCT_STATUS_SUMMARY/${START_FETCH}`;
export const ACTION_FETCH_PRODUCT_STATUS_SUMMARY_SUCCESS = `PRODUCT_STATUS_SUMMARY/${FETCH_ENTITY_DETAILS_SUCCESS}`;
export const ACTION_FETCH_PRODUCT_STATUS_SUMMARY_FAILED = `PRODUCT_STATUS_SUMMARY/${FETCH_ENTITY_DETAILS_ERROR}`;
export const ACTION_FETCH_PRODUCT_STATUS_SUMMARY_END = `PRODUCT_STATUS_SUMMARY/${END_FETCH}`;

export const ACTION_ACTIVATE_PRODUCT = "PRODUCT/ACTIVATE";

export const ACTION_FETCH_PRODUCT_SETTINGS = `PRODUCT_SETTINGS/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCT_SETTINGS_START = `PRODUCT_SETTINGS/${START_FETCH}`;
export const ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS = `PRODUCT_SETTINGS/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_PRODUCT_SETTINGS_FAILED = `PRODUCT_SETTINGS/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_PRODUCT_SETTINGS_END = `PRODUCT_SETTINGS/${END_FETCH}`;

export const ACTION_FETCH_PRODUCT_FILES = `PRODUCT_FILES/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCT_FILES_START = `PRODUCT_FILES/${START_FETCH}`;
export const ACTION_FETCH_PRODUCT_FILES_SUCCESS = `PRODUCT_FILES/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_PRODUCT_FILES_END = `PRODUCT_FILES/${END_FETCH}`;

export const ACTION_UPDATE_PRODUCT = `PRODUCT/${SAVE_ENTITY}`;
export const ACTION_UPDATE_PRODUCT_START = `PRODUCT/${START_SAVE_ENTITY}`;
export const ACTION_UPDATE_PRODUCT_SUCCESS = `PRODUCT/${SAVE_ENTITY_SUCCESS}`;
export const ACTION_UPDATE_PRODUCT_FAILED = `PRODUCT/${SAVE_ENTITY_ERROR}`;
export const ACTION_UPDATE_PRODUCT_END = `PRODUCT/${END_SAVE_ENTITY}`;

export const ACTION_CREATE_PRODUCT = `PRODUCT/CREATE_PRODUCT`;
export const ACTION_IMPORT_PRODUCT = `PRODUCT/IMPORT_PRODUCT`;

export const ACTION_UPLOAD_COVER_IMAGE = `PRODUCT/UPLOAD_COVER`;

export const ACTION_CONNECT_TWIN = `PRODUCT/CONNECT_TWIN`;

export const ACTION_DISCONNECT_TWIN = `PRODUCT/DISCONNECT_TWIN`;

export const ACTION_SET_PRODUCT_SETTINGS = `PRODUCT/SET_PRODUCT_SETTINGS`;

export const ACTION_SET_PRODUCT_FILES = `PRODUCT/SET_PRODUCT_FILES`;

export const ACTION_CREATE_PRODUCTS_RELATION = `PRODUCT/CREATE_PRODUCTS_RELATION`;

export const ACTION_DELETE_PRODUCTS_RELATION = `PRODUCT/DELETE_PRODUCTS_RELATION`;

export const ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE = `PRODUCT/ADD_TO_TEACHERS_GUIDE`;
export const ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE_SUCCESS = `PRODUCT/ADD_TO_TEACHERS_GUIDE_SUCCESS`;

export const ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE = `PRODUCT/REMOVE_FROM_TEACHERS_GUIDE`;
export const ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE_SUCCESS = `PRODUCT/REMOVE_FROM_TEACHERS_GUIDE_SUCCESS`;

export const ACTION_SET_PRODUCT_COMMERCIAL = `PRODUCT/COMMERCIAL`;
export const ACTION_SET_PRODUCT_DISCONTINUED = `PRODUCT/DISCONTINUED`;

export const ACTION_PUBLISH_PRODUCT = `PRODUCT/PUBLISH`;
export const ACTION_PUBLISH_PRODUCT_FILES = `PRODUCT_FILES/PUBLISH`;

export const ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS = `PRODUCT_PUBLISH_FILES_STATUS/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS_SUCCESS = `PRODUCT_PUBLISH_FILES_STATUS/${FETCH_ENTITIES_SUCCESS}`;
export const SET_PUBLISHING_PREVIEW_STATUS = `SET_PUBLISHING_PREVIEW_STATUS`;
export const SET_PUBLISHING_PROD_STATUS = `SET_PUBLISHING_PROD_STATUS`;

export const ACTION_FETCH_PRODUCT_PUBLISH_HISTORY = `PRODUCT_PUBLISH_HISTORY/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCT_PUBLISH_HISTORY_SUCCESS = `PRODUCT_PUBLISH_HISTORY/${FETCH_ENTITIES_SUCCESS}`;

export const START_BUILD_STATUS_WATCHER_TASK = `START_BUILD_STATUS_WATCHER_TASK`;
export const STOP_BUILD_STATUS_WATCHER_TASK = `STOP_BUILD_STATUS_WATCHER_TASK`;

export const ACTION_FETCH_AVAILABLE_SESAMGROUPS = `AVAILABLE_SESAMGROUPS/${FETCH_ENTITIES}`;
export const ACTION_FETCH_AVAILABLE_SESAMGROUPS_SUCCESS = `AVAILABLE_SESAMGROUPS/${FETCH_ENTITIES_SUCCESS}`;

export const ACTION_PRODUCT_SET_TAXONOMY_LEVELS = `PRODUCT/SET_TAXONOMY_LEVELS`;

export const ACTION_PRODUCT_SET_TAXONOMY_SUBJECTS = `PRODUCT/SET_TAXONOMY_SUBJECTS`;

export const fetchProducts = () => ({
  type: ACTION_FETCH_PRODUCTS,
});

export const fetchProductsStart = () => ({
  type: ACTION_FETCH_PRODUCTS_START,
});

export const fetchProductsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_PRODUCTS_SUCCESS,
  data,
  metadata,
});

export const fetchProductsFailed = (error) => ({
  type: ACTION_FETCH_PRODUCTS_FAILED,
  error,
});

export const fetchProductsEnd = () => ({
  type: ACTION_FETCH_PRODUCTS_END,
});

export const fetchProductDetails = (entityId) => ({
  type: ACTION_FETCH_PRODUCT_DETAILS,
  entityId,
});

export const fetchProductDetailsStart = () => ({
  type: ACTION_FETCH_PRODUCT_DETAILS_START,
});

export const fetchProductDetailsSuccess = (data, metadata) => ({
  type: ACTION_FETCH_PRODUCT_DETAILS_SUCCESS,
  data,
  metadata,
});

export const fetchProductDetailsFailed = (error) => ({
  type: ACTION_FETCH_PRODUCT_DETAILS_FAILED,
  error,
});

export const fetchProductDetailsEnd = () => ({
  type: ACTION_FETCH_PRODUCT_DETAILS_END,
});

export const fetchProductStatusSummary = (entityId) => ({
  type: ACTION_FETCH_PRODUCT_STATUS_SUMMARY,
  entityId,
});

export const fetchProductStatusSummaryStart = () => ({
  type: ACTION_FETCH_PRODUCT_STATUS_SUMMARY_START,
});

export const fetchProductStatusSummarySuccess = (data, metadata) => ({
  type: ACTION_FETCH_PRODUCT_STATUS_SUMMARY_SUCCESS,
  data,
  metadata,
});

export const fetchProductStatusSummaryFailed = (error) => ({
  type: ACTION_FETCH_PRODUCT_STATUS_SUMMARY_FAILED,
  error,
});

export const fetchProductStatusSummaryEnd = () => ({
  type: ACTION_FETCH_PRODUCT_STATUS_SUMMARY_END,
});

export const activateProduct = (entity) => ({
  type: ACTION_ACTIVATE_PRODUCT,
  entity,
});

export const fetchProductSettings = () => ({
  type: ACTION_FETCH_PRODUCT_SETTINGS,
});

export const fetchProductSettingsStart = () => ({
  type: ACTION_FETCH_PRODUCT_SETTINGS_START,
});

export const fetchProductSettingsSuccess = (data) => ({
  type: ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS,
  data,
});

export const fetchProductSettingsFailed = (error) => ({
  type: ACTION_FETCH_PRODUCT_SETTINGS_FAILED,
  error,
});

export const fetchProductSettingsEnd = () => ({
  type: ACTION_FETCH_PRODUCT_SETTINGS_END,
});

export const saveProduct = (data) => ({
  type: ACTION_UPDATE_PRODUCT,
  data,
});

export const saveProductStart = () => ({
  type: ACTION_UPDATE_PRODUCT_START,
});

export const saveProductsSuccess = (data) => ({
  type: ACTION_UPDATE_PRODUCT_SUCCESS,
  data,
});

export const saveProductFailed = (error) => ({
  type: ACTION_UPDATE_PRODUCT_FAILED,
  error,
});

export const saveProductEnd = (entity) => ({
  type: ACTION_UPDATE_PRODUCT_END,
  entity,
});

export const createProduct = (data) => ({
  type: ACTION_CREATE_PRODUCT,
  data,
});

export const uploadCoverImage = (file) => ({
  type: ACTION_UPLOAD_COVER_IMAGE,
  file,
});

export const connectTwin = (entityId, twinId) => ({
  type: ACTION_CONNECT_TWIN,
  payload: {
    entityId,
    twinId,
  },
});

export const disconnectTwin = (entityId, twinId) => ({
  type: ACTION_DISCONNECT_TWIN,
  payload: {
    entityId,
    twinId,
  },
});

export const createProductsRelation = (entityId, relatedProductId) => ({
  type: ACTION_CREATE_PRODUCTS_RELATION,
  payload: {
    entityId,
    relatedProductId,
  },
});

export const setProductSettings = (entityId, settings) => ({
  type: ACTION_SET_PRODUCT_SETTINGS,
  payload: {
    entityId,
    settings,
  },
});

export const deleteProductsRelation = (entityId, relatedProductId) => ({
  type: ACTION_DELETE_PRODUCTS_RELATION,
  payload: {
    entityId,
    relatedProductId,
  },
});

export const addToTeachersGuide = (teachersGuideId, entityId) => ({
  type: ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE,
  payload: {
    teachersGuideId,
    entityId,
  },
});

export const addToTeachersGuideSuccess = (teachersGuideId, entityId) => ({
  type: ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE_SUCCESS,
  payload: {
    teachersGuideId,
    entityId,
  },
});

export const removeFromTeachersGuide = (teachersGuideId, entityId) => ({
  type: ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE,
  payload: {
    teachersGuideId,
    entityId,
  },
});

export const removeFromTeachersGuideSuccess = (teachersGuideId, entityId) => ({
  type: ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE_SUCCESS,
  payload: {
    teachersGuideId,
    entityId,
  },
});

export const setProductCommercial = (entityId) => ({
  type: ACTION_SET_PRODUCT_COMMERCIAL,
  payload: {
    entityId,
  },
});

export const setProductDiscontinued = (entityId) => ({
  type: ACTION_SET_PRODUCT_DISCONTINUED,
  payload: {
    entityId,
  },
});

export const publishProduct = (entityId) => ({
  type: ACTION_PUBLISH_PRODUCT,
  payload: {
    entityId,
  },
});

export const publishProductFiles = (entityId, env) => ({
  type: ACTION_PUBLISH_PRODUCT_FILES,
  payload: {
    entityId,
    env,
  },
});

export const importProduct = (sesamName) => ({
  type: ACTION_IMPORT_PRODUCT,
  payload: {
    sesamName,
  },
});

export const fetchProductPublishFilesStatus = (entityId) => ({
  type: ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS,
  payload: {
    entityId,
  },
});

export const fetchProductPublishFilesStatusSuccess = (data) => ({
  type: ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS_SUCCESS,
  data,
});

export const setPublishingPreviewStatus = (status) => ({
  type: SET_PUBLISHING_PREVIEW_STATUS,
  status,
});

export const setPublishingProdStatus = (status) => ({
  type: SET_PUBLISHING_PROD_STATUS,
  status,
});

export const fetchProductPublishHistory = (entityId) => ({
  type: ACTION_FETCH_PRODUCT_PUBLISH_HISTORY,
  payload: {
    entityId,
  },
});

export const fetchProductPublishHistorySuccess = (data) => ({
  type: ACTION_FETCH_PRODUCT_PUBLISH_HISTORY_SUCCESS,
  data,
});

export const startProductBuildStatusWatcher = () => ({
  type: START_BUILD_STATUS_WATCHER_TASK,
});

export const stopProductBuildStatusWatcher = (error) => ({
  type: STOP_BUILD_STATUS_WATCHER_TASK,
  error,
});

export const fetchAvailableSesamgroups = () => ({
  type: ACTION_FETCH_AVAILABLE_SESAMGROUPS,
});

export const fetchAvailableSesamgroupsSuccess = (data) => ({
  type: ACTION_FETCH_AVAILABLE_SESAMGROUPS_SUCCESS,
  data,
});

export const setProductTaxonomyLevels = (entityId, levelIds) => ({
  type: ACTION_PRODUCT_SET_TAXONOMY_LEVELS,
  payload: {
    entityId,
    levelIds,
  },
});

export const setProductTaxonomySubjects = (entityId, subjectIds) => ({
  type: ACTION_PRODUCT_SET_TAXONOMY_SUBJECTS,
  payload: {
    entityId,
    subjectIds,
  },
});

export const fetchProductFiles = entityId => ({
  type: ACTION_FETCH_PRODUCT_FILES,
  entityId
});

export const fetchProductFilesStart = () => ({
  type: ACTION_FETCH_PRODUCT_FILES_START
});


export const fetchProductFilesSuccess = data => ({
  type: ACTION_FETCH_PRODUCT_FILES_SUCCESS,
  data
});

export const fetchProductFilesEnd = () => ({
  type: ACTION_FETCH_PRODUCT_FILES_END
});

export const setProductFiles = (entityId, files) => ({
  type: ACTION_SET_PRODUCT_FILES,
  payload: {
    entityId,
    files
  }
});
