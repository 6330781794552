import styled from "styled-components";

export const StyledLineup = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledLineupContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledItemContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 735px;
  width: 100%;
`;

export const StyledItem = styled.li`
  border: 1px solid #999;
  z-index: 5;
  background: white;
  padding: 10px;
  display: flex;
  margin: 0;
`;
export const StyledDragHandleWrapper = styled.div`
  margin-top: 12px;
`;

export const StyledValueContentWrapper = styled.div`
  border: thin solid #999;
  flex: 1;
  margin: 0 15px;
  min-width: 30%;
`;

export const StyledButtonRowWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

export const StyledItemEditText = styled.div`
  flex: 1;
  display: flex;
`;

export const StyledValueEditInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 16px;
  padding: 5px 10px;
  input {
    outline: none;
    width: 100%;
    border: 0;
    ${props => (props.danger ? "color: red" : "")};
  }
  height: 42px;
`;

export const StyledWarningText = styled.span`
  margin: auto;
  color: red;
  font-size: 12px;
  width: 70%;
  justify-content: flex-end;
  display: flex;
`;

export const StyledRowOptionsWrapper = styled.label`
  display: flex;
  flex: 1;
`;

export const StyledResultContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  border: thin solid #999;
  border-radius: 5px;
  background-color: #f3f3f2;
  flex-direction: row;
  margin: 20px;
  height: 50px;
`;

export const StyledResultLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  flex: 1;
  align-self: center;
  margin: 0px 10px 0px 10px;
`;

export const StyledResultAnswer = styled.label`
  font-size: 15px;
  align-self: center;
  flex: 1;
  text-align: center;
  padding: 5px 15px 5px 15px;
`;

export const StyledResultAnswerField = styled.div`
  display: flex;
  border: thin solid #999;
  background: white;
  flex: 1;
  align-self: center;
  margin: 6px 10px 6px 10px;
`;

export const StyledGridContainer = styled.div`
  align-self: center;
  margin-top: 40px;
`;
