import React from "react";
import styled from "styled-components";

import DropDownBase from "../DropDownBase";
import CheckBox from "../../Checkbox";
// import Tag from '../../Tag';

const ComboBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
`;

const ComboBoxList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ComboBoxListItem = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &:hover {
    background: #ebebeb;
  }
`;

const ComboBoxSearchBar = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ebebeb;

  input {
    border: none;
    outline: none;
    font-size: 0.9em;
    width: 100%;
  }
`;

const ComboBoxItemCheckBox = styled.div`
  padding: 0 10px;
`;

const ComboBoxItemContent = styled.div`
  flex: 1;
  max-width: 165px;
`;

const NoResultsFound = styled.div`
  padding: 10px;
`;

class DropDownComboBox extends React.PureComponent {
  static defaultProps = {
    checkboxCallback: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      searchTerm: "",
      filteredItems: [],
      fullWidth: false
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isActive: false
    });
  };

  /* Changes the toggle state of the component */
  onToggle = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  /* Runs callback for checkboxEvents and returns id of instance clicked*/
  checkboxEvent = item => {
    this.props.checkboxCallback(item);
  };

  /* Creates array of list items based on this.props.items */
  listItemRenderer = item => {
    return item ? (
      <ComboBoxListItem key={item.id}>
        <ComboBoxItemCheckBox>
          <CheckBox
            id={item.id}
            name={item.id}
            onChange={() => {
              this.checkboxEvent(item);
            }}
            isChecked={item.isChecked}
          />
        </ComboBoxItemCheckBox>
        <ComboBoxItemContent>{item.element}</ComboBoxItemContent>
      </ComboBoxListItem>
    ) : (
      <NoResultsFound key={0}>
        <span>Inga funna resultat</span>
      </NoResultsFound>
    );
  };

  /**
   *   Checks if there is any data to begin with, and if there is, pass the render decision further.
   * @returns {*}
   */
  resultRenderer = () => {
    return this.props.items.length > 0 ? (
      this.hasSearchTerm()
    ) : (
      <div>There is no data to be displayed</div>
    );
  };

  /**
   * Return appropriate result for expandable area depending on whether there were matching
   * result for searchTermn or not
   *
   * @param {string} searchTerm
   * @returns {*[]}
   */
  hasSearchRes = searchTerm => {
    const filtered = this.props.items.filter(this.filterItems(searchTerm));
    return filtered.length ? filtered : [false];
  };

  /**
   * Handles the event when user types in input
   * @param event
   */
  handleDataSearch = event => {
    const filteredRes = event.target.value
      ? this.hasSearchRes(event.target.value)
      : this.props.items.slice(0);

    this.setState({
      searchTerm: event.target.value,
      filteredItems: filteredRes
    });
  };

  /**
   * Checks if there is a search being made or if it should be ready to display all as a scrollable list
   * @returns {any[]}
   */
  hasSearchTerm = () => {
    if (this.state.searchTerm.length) {
      const filteredItems = this.hasSearchRes(this.state.searchTerm);
      return filteredItems.map((item, index) =>
        this.listItemRenderer(item, index)
      );
    } else {
      return this.props.items.map((item, index) =>
        this.listItemRenderer(item, index)
      );
    }
  };

  /**
   * Creates a wrapper to be placed in the content area of the DropDown
   * @returns {*}
   */
  listHolderRenederer = () => {
    return (
      <ComboBox>
        <ComboBoxSearchBar>
          <input
            type="search"
            placeholder="Skriv för att söka"
            onChange={this.handleDataSearch}
          />
        </ComboBoxSearchBar>

        <ComboBoxList>{this.resultRenderer()}</ComboBoxList>
      </ComboBox>
    );
  };

  /**
   * filters the items
   * @param {string} filter
   * @returns {function(*): boolean}
   */
  filterItems = filter => item =>
    item[this.props.searchKey].toLowerCase().indexOf(filter.toLowerCase()) !==
    -1;

  render() {
    const { title, fullWidth, icon } = this.props;

    /* Label element */
    const label = <span>{title}</span>;

    return (
      <div ref={this.clickRef}>
        <DropDownBase
          fullWidth={fullWidth}
          icon={icon}
          titleComponent={label}
          onToggle={this.onToggle}
          items={this.listHolderRenederer()}
          isActive={this.state.isActive}
        />
      </div>
    );
  }
}

export default DropDownComboBox;
