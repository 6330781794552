import { ENTITIES, MEDIA_RESOURCE } from "../../constants";

const mediaresource = ENTITIES[MEDIA_RESOURCE];

/**
 * Fetches editable icons data
 * @param state
 * @returns {Array}
 */
export const selectIconsData = state =>
  state[mediaresource].getIn(["multiSVG", "iconDraft"]).toJS();

/**
 * Fetches current workspace format
 * @param state
 * @returns { String }
 */
export const selectCurrentWorkspaceFormat = state =>
  state[mediaresource].getIn(["multiSVG", "currentFormat"]);

/**
 * Fetches current editor settings as JS
 * @param state
 * @returns { Object }
 */
export const selectWorkspaceSettingsJS = state =>
  state[mediaresource].get("multiSVGEditor").toJS();

/**
 * Fetches current editor settings
 * @param state
 * @returns { Object }
 */
export const selectWorkspaceSettings = state =>
  state[mediaresource].get("multiSVGEditor");

/**
 * Get setting alignToWorkspace for editor
 * @param state
 * @returns { Bool }
 */
export const selectAlignToWorkspaceSetting = state =>
  state[mediaresource].getIn(["multiSVGEditor", "alignWorkspace"]);

/**
 * Fetches the icondraft and change to JS.
 * @param state
 * @returns { Array }
 */
export const selectIconDraftJS = state =>
  state[mediaresource].getIn(["multiSVG", "iconDraft"]).toJS();

/**
 * Fetches the icondraft and change to JS.
 * @param state
 * @returns { Array }
 */
export const selectIconDraft = state =>
  state[mediaresource].getIn(["multiSVG", "iconDraft"]);

/**
 * Fetches the svg elements that is selected.
 * @param state
 * @returns { Array }
 */
export const selectSelectedIcons = state =>
  state[mediaresource]
    .getIn(["multiSVG", "iconDraft"])
    .toJS()
    .filter(icon => icon.selected === true);

/**
 * Fetches the size of current selected icons. If two icons has different size the result is ' '.
 * @param {*} state
 * @returns { String }
 */
export const selectSizesOfSelectedIcons = state =>
  state[mediaresource]
    .getIn(["multiSVG", "iconDraft"])
    .reduce((result, icon) => {
      if (icon.get("selected")) {
        const p = icon.get("size");
        if (result === "") {
          return p;
        } else {
          return p !== result ? " " : result;
        }
      } else {
        return result;
      }
    }, "");
