export const FETCH_LIMIT = 50;
export const PREVIEW_URL =
  process.env.REACT_APP_PREVIEW_URL ||
  "http://mr.test.studentlitteratur.se/?preview=";
export const DIFFICULTY = ["Ingen", "Lätt", "Medel", "Svår"];
export const STATUS = [
  "Utkast",
  "Publicerad",
  null,
  null,
  "Avpublicerad",
  "Ändrad"
];
