import {
  RouterService,
  registerReducer,
  registerSaga,
  registerConstraint
} from "../shared";
import { routes, views } from "./routes";
import { series as seriesReducer } from "../series/store/reducer";
import rootSaga from "./sagas";
import { applicationEnsureSeries } from "./sagas/sagas";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Series", seriesReducer);
  registerConstraint(applicationEnsureSeries);
};
