import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Field } from "../../../products/components/Input";
import ColorContrastChecker from "color-contrast-checker";
import Radio from "../../../products/components/Radio";
import { DARK, LIGHT, MEDIA } from "../../../constants";
import { PrimaryButton } from "../../../shared";
import {
  getMedia,
  renderMedia
} from "../../../shared/MediaHandler/MediaHandler";
import { openMediaModal } from "../../../shared/components/Modal/MediaModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormFieldWrapper = styled.div`
  margin: 15px 0;
`;

const StyledColorSquare = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background-color: ${props => props.color};
  border: 1px solid #cccccc;
  margin-left: 20px;
`;

const StyledColorWrapper = styled.div`
  padding-top: 21px;
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 250px;
  input[type="text"] {
    width: 250px;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 1rem 0.5rem 1rem 0;
  border-radius: 5px;
  width: 8rem;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  ${({ centered }) => centered && "justify-content: center;"}
`;

const SeriesSettings = ({ serie, seriesList, errors, setErrors, setSerie }) => {
  const dispatch = useDispatch();
  const isColorValid = value =>
    value && value.length === 7 && CSS.supports("background-color", value);

  const isRatioContrastValid = (textColor, backgroundColor) =>
    isColorValid(backgroundColor) &&
    new ColorContrastChecker().isLevelCustom(backgroundColor, textColor, 4.5);

  const isWhiteTextColorValid = backgroundColor =>
    isRatioContrastValid("#FFFFFF", backgroundColor);
  const isBlackTextColorValid = backgroundColor =>
    isRatioContrastValid("#000000", backgroundColor);

  const radioOnChange = type => _ =>
    setSerie({ ...serie, lightMode: type === LIGHT });

  const removeImage = key => () => setSerie({ ...serie, [key]: "" });

  const openMedia = key => () => {
    openMediaModal(dispatch)(
      {
        onOK: image =>
          setSerie({
            ...serie,
            [key]: { id: image[0].id, url: image[0].url }
          }),
        onCancel: () => {
          // This is intentional
        }
      },
      {
        filters: {
          selected: key,
          hide: ["type"]
        }
      },
      MEDIA
    );
  };

  const onChange = (value, key) => {
    setErrors({});

    if (key === "title") {
      const found = !!seriesList.find(s => s.title === value);
      if (found && value !== serie[key]) {
        setErrors({
          title: "Titeln måste vara unik"
        });
      }

      if (value.length > 30) {
        setErrors({
          title: "Max antal tecken för titel är 30 tecken."
        });
        return;
      }
    }

    if (key === "color") {
      if (value === "" || !isColorValid(value)) {
        setErrors({
          color: "Felaktig färgkod. Ange färg i HEX-format"
        });

        setSerie({ ...serie, [key]: value, lightMode: null });
        return;
      } else {
        setSerie({
          ...serie,
          [key]: value,
          lightMode: isWhiteTextColorValid(value)
        });
        return;
      }
    }

    setSerie({ ...serie, [key]: value });
  };

  return (
    <>
      <Container>
        <Field
          variant="outlined"
          error={errors["title"]}
          value={serie.title}
          onChange={onChange}
          label="Titel"
          name="title"
        />
        <StyledFieldWrapper>
          <Field
            error={errors["color"]}
            value={serie.color}
            onChange={onChange}
            label="Färg"
            name="color"
            autocomplete="off"
          />
          <StyledColorWrapper>
            <StyledColorSquare color={serie.color} />
          </StyledColorWrapper>
        </StyledFieldWrapper>
        {serie.color && (
          <StyledFormFieldWrapper>
            <Radio
              label="Ljus textfärg"
              onChange={radioOnChange(LIGHT)}
              selected={serie.lightMode !== null && serie.lightMode}
              disabled={!isWhiteTextColorValid(serie.color)}
            />
            <Radio
              label="Mörk textfärg"
              onChange={radioOnChange(DARK)}
              selected={serie.lightMode !== null && !serie.lightMode}
              disabled={!isBlackTextColorValid(serie.color)}
            />
          </StyledFormFieldWrapper>
        )}
        <Field
          variant="outlined"
          error={errors["pageUrl"]}
          value={serie.pageUrl}
          onChange={onChange}
          label="URL “Mer om serien”"
          name="pageUrl"
        />
        {serie.image.url &&
          renderMedia(
            getMedia({
              image: {
                src: serie.image.url
              }
            })
          )}
        <StyledButtonGroup centered={serie.image.url}>
          {serie.image.url && (
            <StyledPrimaryButton onClick={removeImage("image")}>
              Ta bort
            </StyledPrimaryButton>
          )}
          <StyledPrimaryButton onClick={openMedia("image")}>
            Välj seriebild
          </StyledPrimaryButton>
        </StyledButtonGroup>
        <hr />
        {serie.logotype.url &&
          renderMedia(
            getMedia({
              image: {
                src: serie.logotype.url
              }
            })
          )}
        <StyledButtonGroup centered={serie.logotype.url}>
          {serie.logotype.url && (
            <StyledPrimaryButton onClick={removeImage("logotype")}>
              Ta bort
            </StyledPrimaryButton>
          )}
          <StyledPrimaryButton onClick={openMedia("logotype")}>
            Välj logotyp
          </StyledPrimaryButton>
        </StyledButtonGroup>
      </Container>
    </>
  );
};

export default SeriesSettings;
