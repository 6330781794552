import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ProductSearch from "../../components/ProductSearch";
import AddProduct from "../../components/AddProduct";
import { isProductSelectedMessageVisible } from "../../../shared/store/navigation/selectors";
import SimpleProductList from "../../../productlist/containers/SimpleProductList";

const Wrapper = styled.div`
  margin-left: 1rem;
`;
const SearchAndAddWrapper = styled.div`
  margin: 1rem 2rem 3rem 0;
`;

const StyledMessageWrapper = styled.div`
  color: #b00020;
  padding: 3px;
  height: 25px;
`;

const Products = () => {
  const showMessage = useSelector(isProductSelectedMessageVisible);
  const msg = showMessage ? "Välj en produkt för att gå vidare." : "";
  return (
    <React.Fragment>
      <Wrapper>
        <SearchAndAddWrapper>
          <StyledMessageWrapper>{msg}</StyledMessageWrapper>
          <ProductSearch />
          <AddProduct />
        </SearchAndAddWrapper>
      </Wrapper>
      <SimpleProductList />
    </React.Fragment>
  );
};

export default Products;
