import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "../../../products/components/Input";
import SlateEditor from "../../components/SlateEditor";
import { PrimaryButton, StandardButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import ProductSearchAndList from "../../../shared/components/ProductSearchAndList";
import {
  getAllProductsList,
  selectPermissions
} from "../../../products/store/selectors";
import {
  updateEntityDraft,
  saveEntityDraft
} from "../../../posts/sagas/PostDraft/actions";
import { selectEntityDraft } from "../../../posts/sagas/PostDraft/selectors";
import { EDIT, ENTITIES, MARKETMESSAGE, MEDIA, OPMESSAGE } from "../../../constants";
import { STATUS } from "../../../posts/api/constants";
import Tag from "../../../shared/components/Tag";
import { statusColor } from "../../../shared/constants/status";
import EllipsisDropDownMenu from "../../../posts/components/EllipsisDropDownMenu";
import { selectWorkflowMapForCurrentPostType } from "../../../store/selectors";
import ChipPost from "../../../posts/components/ChipPost";
import { formatISOZeroTime } from "../../../date";
import { openMediaModal } from "../../../shared/components/Modal/MediaModal";
import {
  StyledMessageEditorWrapper,
  StyledEditorColumn,
  StyledSection,
  StyledStatusColumn,
  ChipPostWrapper,
  SectionTitle,
  StyledStatusHeader,
  StyledSaveButtonWrapper,
  StyledButtonWrapper
} from "./StyledMessageEditor";
import { duplicatePost } from "../../../posts/store/actions";
import MarketMessageSettings from "./MarketMessageSettings";
import OpMessageSettings from "./OpMessageSettings";
import MessageTimeDateSettings from "./MessageTimeDateSettings";
import { getAllSeries } from "../../../series/store/selectors";
import SearchSeriesAndList from "../../components/SearchSeriesAndList/SearchSeriesAndList";

const MessageEditor = ({ type }) => {
  const draft = useSelector(state => selectEntityDraft(state, type));
  const postData = draft.toJS();
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [meta, setmeta] = useState(postData.metadata);
  const [postContent, setPostContent] = useState([]);

  const workflowMap = useSelector(selectWorkflowMapForCurrentPostType);
  const permissions = useSelector(selectPermissions);
  const allSeries = useSelector(getAllSeries);

  const zeroTime = formatISOZeroTime();

  const messageActions = {
    publish: { postType: type, postIds: [postData.id], mode: EDIT },
    unpublish: { postType: type, postIds: [postData.id], mode: EDIT }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const { metadata, post } = postData;
    setIsChecked(metadata.alert);
    setmeta(metadata);
    setPostContent(post);
  }, [postData]);

  const _onChange = (val, name) => {
    dispatch(updateEntityDraft(type, name, val));
  };

  const updateMetaData = m => {
    setmeta(m);
    setErrors({});
    dispatch(updateEntityDraft(type, "metadata", m));
  };

  const _onMetaDataChange = (val, name) => {
    let value = val;
    if (name === "startTime" || name === "endTime") {
      value = val === "" ? zeroTime : new Date(val).toISOString();
    }
    const m = {
      ...meta,
      [name]: value
    };
    updateMetaData(m);
  };

  const _onEditorChange = val => {
    dispatch(updateEntityDraft(type, "post", val));
  };

  const _onCheckBoxChange = checked => {
    setIsChecked(checked);
    let m = { ...meta, alert: checked };
    updateMetaData(m);
  };

  const _addProduct = refId => {
    const { products } = meta;
    if (products) {
      products.push(refId);
    }
    const m = { ...meta, products: products || [refId] };
    updateMetaData(m);
  };

  const addSerieToMessage = refId => {
    const { series } = meta;
    if (series) {
      series.push(refId);
    }
    const m = { ...meta, series: series || [refId] };
    updateMetaData(m);
  };

  const _removeProduct = refId => {
    const { products } = meta;
    const productsRemoved = products.filter(p => p !== refId);
    const m = { ...meta, products: productsRemoved };
    updateMetaData(m);
  };

  const removeSerieFromMessage = refId => {
    const { series } = meta;
    const seriesRemoved = series.filter(p => p !== refId);
    const m = { ...meta, series: seriesRemoved };
    updateMetaData(m);
  };

  const onSavePost = () => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(saveEntityDraft(type));
  };

  const removeMedia = () => {
    const m = { ...meta, image: {} };
    updateMetaData(m);
  };

  const openMedia = key => () => {
    openMediaModal(dispatch)(
      {
        onOK: image => {
          updateMetaData({
            ...meta,
            image: { id: image[0].id, url: image[0].url }
          });
        },
        onCancel: () => {
          // do nothing
        }
      },
      {
        filters: {
          selected: key,
          hide: ["type"]
        }
      },
      MEDIA,
      "Lägg till en eller flera bilder",
      true,
      false
    );
  };

  const handleDuplicatePostClick = id => {
    dispatch(duplicatePost(id, type));
  };

  const disabled = false;
  const statusObj = STATUS.find(item => item.id === postData.status);
  const statusLabel = statusObj ? statusObj.title : null;

  return (
    <>
      <StyledMessageEditorWrapper>
        <StyledEditorColumn>
          <StyledSection>
            <StyledButtonWrapper>
              {postData.postDraftState !== "dirty" && (
                <StandardButton
                  onClick={() => handleDuplicatePostClick(postData.id)}
                  studlicon="Copy"
                >
                  Duplicera
                </StandardButton>
              )}
            </StyledButtonWrapper>
            <Field
              value={postData.title}
              onChange={_onChange}
              disabled={disabled}
              label="Titel"
              name="title"
            />
          </StyledSection>
          <StyledSection>
            <SectionTitle>Meddelande: </SectionTitle>
            {postContent.length > 0 && (
              <SlateEditor
                data={postContent}
                onChange={_onEditorChange}
                type={type}
              />
            )}
          </StyledSection>
          {type === ENTITIES[MARKETMESSAGE] && (
            <StyledSection>
              <MarketMessageSettings
                meta={meta}
                onMetaDataChange={_onMetaDataChange}
                openMedia={openMedia}
                removeMedia={removeMedia}
              />
            </StyledSection>
          )}
          {type === ENTITIES[OPMESSAGE] && (
            <StyledSection>
              <OpMessageSettings
                meta={meta}
                onMetaDataChange={_onMetaDataChange}
                openMedia={openMedia}
                removeMedia={removeMedia}
              />
            </StyledSection>
          )}
          <StyledSection>
            <MessageTimeDateSettings
              type={type}
              meta={meta}
              isChecked={isChecked}
              onCheckBoxChange={_onCheckBoxChange}
              onMetaDataChange={_onMetaDataChange}
              errors={errors}
            />
          </StyledSection>
        </StyledEditorColumn>
        <StyledStatusColumn>
          <StyledSection>
            <StyledStatusHeader>
              <SectionTitle>Status: </SectionTitle>
              <EllipsisDropDownMenu
                data={{
                  actions: messageActions,
                  status: postData.status,
                  workflowMap: workflowMap,
                  type,
                  permissions: {
                    [type.toLowerCase()]: permissions
                      ? permissions[type.toLowerCase()]
                      : []
                  }
                }}
              />
            </StyledStatusHeader>
            {postData.status ? (
              <Tag
                variant={"warning"}
                label={statusLabel}
                labelColor={statusColor[postData.status]}
              />
            ) : null}
          </StyledSection>
          <StyledSection>
            <ProductSearchAndList
              label="Koppla meddelandet till produkt/er"
              productRefIdList={meta.products}
              addProductToList={_addProduct}
              removeProductFromList={_removeProduct}
              productSelector={getAllProductsList}
            />
          </StyledSection>
          {type === ENTITIES[MARKETMESSAGE] && (
            <StyledSection>
              <SearchSeriesAndList
                addSerie={addSerieToMessage}
                errors={errors}
                series={meta.series}
                removeSerie={removeSerieFromMessage}
                allSeries={allSeries}
              />
            </StyledSection>
          )}
          <StyledSection>
            <ChipPostWrapper>
              {postData.post.length > 0 ? (
                <ChipPost
                  isDetailView
                  useCurrentPost
                  postType={type}
                  title="Visas för: "
                />
              ) : null}
            </ChipPostWrapper>
          </StyledSection>
        </StyledStatusColumn>
      </StyledMessageEditorWrapper>
      {postData.postDraftState === "dirty" && (
        <StyledSaveButtonWrapper>
          <ButtonGroup>
            <PrimaryButton onClick={onSavePost}>Spara</PrimaryButton>
          </ButtonGroup>
        </StyledSaveButtonWrapper>
      )}
    </>
  );
};

export default MessageEditor;
