import React from "react";
import styled from "styled-components";
import { Trash2 as Trash } from "react-feather";
import Link from "../../../shared/components/Link/Link";
import IconButton from "../IconButton";

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArtNumber = styled.span`
  font-size: 0.75rem;
`;

export const ProductLink = styled(Link)`
  color: ${props => props.theme.themeDarkAlt};
  font-size: 1rem;
`;


const DeleteButton = styled(IconButton)`
  height: auto;
  display: flex;
  align-self: center;
`;

const DeleteIcon = styled(Trash)`
  color: ${props => props.theme.neutralSecondaryAlt};
`;

const LinkedProductsList = ({ products, onDelete }) => {

  if (!products) return null;

  return (
    <List>
      {products.map(p => (
        <ListItem key={p.id}>
          <DeleteButton
            aria-label="Ta bort media"
            onClick={() => onDelete(p.id)}
          >
            <DeleteIcon width="24px" height="24px" />
          </DeleteButton>
          <ListItemTextContainer>
            <ArtNumber>{p.artnr}</ArtNumber>
            <ProductLink to={`/products/${p.id}`}>{p.title}</ProductLink>
          </ListItemTextContainer>
        </ListItem>))}
    </List>
  );
};

export default LinkedProductsList;
