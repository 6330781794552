import React, { useState, useCallback } from "react";
import { Slate, Editable, withReact } from "slate-react";
import styled from "styled-components";
import InlineEditorButton from "./InlineEditorButton";
import BlockEditorButton from "./BlockEditorButton";
import LinkEditorButton from "./LinkEditorButton";
import { createEditor } from "slate";
import deepEqual from "deep-equal";
import withMessageLayout from "./withMessageLayout";
import withInlines from "./withInlines";
import Element from "./Element";
import Leaf from "./Leaf";
import { ENTITIES, MARKETMESSAGE, OPMESSAGE } from "../../../constants";

const StyledSlateEditorWrapper = styled.div`
  border: 1px solid;
  height: 27rem;
  padding-bottom: 0.3rem;
  a {
    color: blue;
    text-decoration: underline;
  }
  margin-bottom: 1rem;
`;
const StyledEditable = styled(Editable)`
  padding: 1rem;
  height: 23rem;
  overflow-y: scroll;
`;
const StyledToolbar = styled.div`
  border-bottom: 1px solid;
  padding: 0.5rem;
`;

const SlateEditor = ({ data, onChange, type }) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const [editor] = useState(
    () => withMessageLayout(withInlines(withReact(createEditor())), type),
    []
  );

  return (
    <StyledSlateEditorWrapper>
      <Slate
        editor={editor}
        value={data}
        onChange={newValue => {
          if (!deepEqual(data, newValue)) {
            onChange(newValue);
          }
        }}
      >
        <StyledToolbar>
          <InlineEditorButton format="bold" />
          <InlineEditorButton format="italic" />
          <InlineEditorButton format="underline" />
          {type !== ENTITIES[OPMESSAGE] && <BlockEditorButton format="subtitle" /> }
          {type !== ENTITIES[MARKETMESSAGE] && <LinkEditorButton />}
        </StyledToolbar>
        <StyledEditable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Rubrik"
          renderPlaceholder={({ children, attributes }) => {
            // render this placeholder only if empty node is a title.
            // otherwise it will generate an error for older messages where empty node is a paragraph.
            return data[0].type === "title" ? (
              <div {...attributes}>
                <p>{children}</p>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    marginTop: "1rem"
                  }}
                >
                  Innehåll
                </div>
              </div>
            ) : null;
          }}
        />
      </Slate>
    </StyledSlateEditorWrapper>
  );
};

export default SlateEditor;
