import React from "react";

const CheckboxEmpty = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-checkboxempty"
  >
    <path d="M44.6428571,0 L5.35714286,0 C2.3984375,0 0,2.3984375 0,5.35714286 L0,44.6428571 C0,47.6015625 2.3984375,50 5.35714286,50 L44.6428571,50 C47.6015625,50 50,47.6015625 50,44.6428571 L50,5.35714286 C50,2.3984375 47.6015625,0 44.6428571,0 Z M44.6428571,3.57142857 C45.627567,3.57142857 46.4285714,4.37254464 46.4285714,5.35714286 L46.4285714,44.6428571 C46.4285714,45.6274554 45.627567,46.4285714 44.6428571,46.4285714 L5.35714286,46.4285714 C4.37254464,46.4285714 3.57142857,45.6274554 3.57142857,44.6428571 L3.57142857,5.35714286 C3.57142857,4.37254464 4.37254464,3.57142857 5.35714286,3.57142857 L44.6428571,3.57142857 Z" />
  </svg>
);

export default CheckboxEmpty;
