import { takeLatest, delay } from "redux-saga/effects";

import { REMOVE_INITIAL_LOADING_SCREEN } from "../store/loading/actions";

function* removeInitialLoadingScreen() {
  const loading = document.querySelector("#splash");
  loading.style.opacity = 0;

  yield delay(200);
  document.body.removeChild(loading);
  document.head.removeChild(document.querySelector("#splash-style"));
}

export default function* watchRemoveInitialLoadingScreen() {
  yield takeLatest(REMOVE_INITIAL_LOADING_SCREEN, removeInitialLoadingScreen);
}
