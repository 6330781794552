import React from "react";
import { PageHeader } from "../../../shared";
import PostList from "../../containers/PostList";
import { ENTITIES, EXERCISE } from "../../../constants";

const Exercsies = () => (
  <React.Fragment>
    <PageHeader studlicon="Posts" title="Övningar" />
    <PostList type={ENTITIES[EXERCISE]} />
  </React.Fragment>
);

export default Exercsies;
