import React from "react";
import styled from "styled-components";

import Button from "../Button";
import Link from "../Link";

export const itemHeight = 36;

const BaseStyles = `
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  flex: 1;
  color: inherit;
  padding: 0 16px;
  font-weight: normal;
`;

const MenuButton = styled(Button).attrs({ type: "button" })`
  ${BaseStyles} & > span > span {
    margin: 0 8px 0 0;
    color: ${props => props.theme.neutralSecondary};
  }
`;

const MenuLink = styled(Link)`
  ${props => props.theme.buttons.skins.defaults()}
  ${props => props.theme.buttons.transparent()}

  ${BaseStyles}

  & > span {
    color: ${props => props.theme.neutralSecondary};
  }
`;

const Item = ({ className, to, ...rest }) => (
  <li className={className}>
    {to ? <MenuLink to={to} {...rest} /> : <MenuButton transparent {...rest} />}
  </li>
);

export default styled(Item)`
  margin-top: 0;
  height: ${itemHeight}px;
  overflow: hidden;
  color: ${props => props.theme.neutralSecondary};
`;
