import { camelCaseToPascalCase } from "./shared/helpers";

export const ID = "ID";
export const LEFT = "left";
export const RIGHT = "right";
export const STEPS = "steps";
export const START = "start";
export const END = "end";
export const TOP = "top";
export const MIDDLE = "middle";
export const BOTTOM = "bottom";
export const NUMBER = "number";
export const NORMAL = "normal";
export const ERROR_MARGIN = "errorMargin";
export const IS_ACTIVE = "isActive";
export const INTERVAL = "interval";
export const MARKING = "marking";
export const LINES = "lines";
export const NO_LINES = "noLines";
export const NUMBER_LINE = "numberline";
export const JUMP = "jump";
export const FIXED = "fixed";
export const AUTO = "auto";
export const DATA = "data";
export const HELP = "help";
export const SETTINGS = "settings";
export const START_VALUE = "startValue";
export const TEXT = "text";
export const AIDATEXT = "aidatext";
export const AIDASYSTEM = "aidasystem";
export const FILTER_AIDATAG = "Nyckelord";
export const FILTER_AIDASYSTEM = "System";
export const TITLE = "title";
export const BORDER_BOTTOM = "borderBottom";
export const BORDER_RIGHT = "borderRight";
export const FONT_SIZE = "fontSize";
export const ALT_TEXT = "altText";
export const TYPE = "type";
export const DEFAULT = "default";
export const ABOUT = "about";
export const MULTIPLE_CHOICE = "multiplechoice";
export const SORT_MACHINE = "sortmachine";
export const BLANKS = "blanks";
export const INSTRUCTION = "instruction";
export const TEMPLATE = "template";
export const HELP_VIDEO = "helpvideo";
export const HELP_AUDIO = "helpaudio";
export const HELP_IMAGE = "helpimage";
export const TIPS = "tips";
export const MODAL = "modal";
export const MATH_WORD = "mathword";
export const ALPHA_NUMERIC = "alphaNumeric";
export const NUMERIC = "number";
export const NUMERATOR = "numerator";
export const DENOMINATOR = "denominator";
export const INTEGER = "integer";
export const MULTIPLE = "multiple";
export const SINGLE = "single";
export const MULTIPLE_ANSWERS = "multipleAnswers";

export const CALCULATION = "calculation";
export const PLUS = "plus";
export const MINUS = "minus";
export const MIXED = "mixed";
export const PERCENT = "percent";
export const REST = "rest";
export const EQUAL = "equal";
export const APPROXIMATION = "approximation";
export const DECIMAL = "decimal";
export const MULTIPLICATION = "multiplication";
export const DIVISION = "division";
export const SHUFFLED = "shuffled";
export const ORDER = "order";
export const SORTED = "sorted";
export const SUMMED = "summed";
export const ANSWER_TYPE = "answerType";
export const EQUAL_SIGN = "equalSign";
export const BOXES_COUNT = "boxesCount";
export const GOAL = "goal";
export const FIELD = "field";
export const BOXES = "boxes";
export const ITEMS = "items";
export const RESULT = "result";
export const CHIPS = "chips";
export const LABEL = "label";
export const SQUARE = "square";
export const WIDE = "wide";
export const VERY_WIDE = "veryWide";
export const TALL = "tall";
export const EXTRALARGE = "extralarge";
export const FREE = "free";
export const PARKING = "parking";
export const BOX_TYPE = "boxType";
export const UNSORTED_BOX = "unsortedbox";
export const ALTERNATIVE = "alternative";
export const TEXT_SIZE = "textSize";
export const MAX_ITEMS_IN_BOX = "maxItemsInBox";
export const MAX_ITEMS = "maxItems";
export const CALCULATE_TYPE = "calculateType";
export const METHOD_TYPE = "methodType";
export const DECIMAL_COUNT = "decimalCount";
export const SHORT = "short";
export const EXTEND = "extend";
export const LINE_UP = "lineup";
export const FRACTION = "fraction";
export const CHART = "chart";
export const FRACTION_METHOD = "fractionMethod";
export const CONVERT_METHOD = "convertMethod";
export const SIMPLIFY = "simplify";
export const SIMPLIFY_HIDDEN = "simplifyHidden";
export const SIMPLIFY_MULTIPLE = "simplifyMultiple";
export const IMAGE_METHOD = "imageMethod";
export const INTERACTIVE = "interactive";
export const KEYBOARD_CHARACTER_SET = "keyboardCharacterSet";
export const DROPDOWN = "dropdown";
export const INPUT = "input";
export const CHART_TYPE = "chartType";
export const HEADING = "heading";
export const ROW = "row";
export const COLUMN = "column";
export const GRID = "grid";
export const BOTH = "both";
export const WITHOUT = "without";
export const STANDARD = "standard";
export const EQUATION = "equation";
export const LAYOUT = "layout";
export const SHOW_BORDERS = "showBorders";

export const BLOCKARRAY = "blockarray";

export const WORDS = "words";
export const COLOR = "color";

export const SIZE = "size";
export const S = "S";
export const M = "M";
export const L = "L";
export const XL = "XL";

export const LIGHT = "light";
export const DARK = "dark";

export const SIZES = [S, M, L, XL];
export const OPERATIVE = "operative";
export const STRING = "string";
export const OPTIONS = "options";
export const TIME = "time";
export const POST = "post";

export const INPUT_TYPE = "inputType";
export const CASE_SENSITIVE = "caseSensitive";
export const SPACE_SENSITIVE = "spaceSensitive";
export const PUNCTUATION_MARKS_SENSITIVE = "punctuationMarksSensitive";

export const OPEN = "open";

export const MODIFIED = "modified";
export const PUBLISHED = "published";
export const DRAFT = "draft";
export const LOCKED = "locked";
export const INVALID = "invalid";
export const EXERCISE = "exercise";
export const RESOURCE = "resource";
export const HELP_RESOURCE = "helpresource";

export const MEDIA_SRC = "mediasrc";
export const MEDIA_RESOURCE = "mediaresource";
export const DROP_ZONE = "dropzone";

export const MULTIIMAGE = "multiimage";

export const AUDIO = "audio";
export const IMAGE = "image";
export const VIDEO = "video";
export const SOURCE = "source";

export const SLIDES = "slides";

export const TEXT_WITH_SOUND = "textWithSound";
export const IMAGESLIDER = "imageslider";

/// Permissions
export const PUBLISH = "publish";
export const DELETE = "delete";
export const EDIT = "edit";
export const SAVE = "save";
export const CREATE = "create";

// Actions
export const REQUEST_REVIEW = "request_review";
export const SET_REVIEWED = "set_reviewed";
export const UNPUBLISH = "unpublish";
export const REJECT = "reject";

// Status
export const REVISION_READY_FOR_REVIEW = "revision_ready_for_review";
export const DRAFT_READY_FOR_REVIEW = "draft_ready_for_review";
export const REVISION_REVIEWED = "revision_reviewed";
export const DRAFT_REVIEWED = "draft_reviewed";
export const UNPUBLISHED = "unpublished";
export const READY_FOR_REVIEW = "ready_for_review";
export const REVIEWED = "reviewed";
export const DRAFT_PREVIEW_PUBLISHED = "draft_preview_published";
export const REVISION_PREVIEW_PUBLISHED = "revision_preview_published";

// File status
export const FILE_NOT_PUBLISHED = "draft";
export const FILE_PUBLISHED_TO_TEST = "draft_preview_published";
export const FILE_PUBLISHED_LIVE = "published";
export const FILE_MODIFIED_IN_TEST = "revision_preview_published";

/// Sections
export const HOME = "home";
export const EXERCISES = "exercises";
export const RESOURCES = "resources";
export const IMAGE_RESOURCES = "imageresources";
export const HELP_RESOURCES = "helpresources";
export const STRUCTURE = "structure";
export const MEDIA = "media";
export const AIDA = "aida";
export const OMNIA = "omnia";

export const SERIES = "series";
export const PRODUCTLIST = "productlist";
export const CLASSROOMGROUP = "classroomgroup";
export const CLASSROOM = "classroom";

// Messages
export const MESSAGES = "messages";
export const PRODUCTNEWS = "productnews";
export const OPMESSAGE = "opmessage";
export const MARKETMESSAGE = "marketmessage";

// Adventures
export const ADVENTURES = "Adventures";
export const ADVENTURES_GROUP = "AdventuresGroup";
export const ADVENTURES_CARD = "AdventuresCard";
export const ADVENTURES_CARD_EDITOR = "AdventuresCardEditor";

//Markers
export const MARKERS = "Markers";

// Reload data
export const RELOAD_DETAILED_EXERCISE = "RELOAD_DETAILED_EXERCISE";
export const RELOAD_DETAILED_RESOURCE = "RELOAD_DETAILED_RESOURCE";
export const RELOAD_DETAILED_AIDA = "RELOAD_DETAILED_AIDA";

// Web Socket
export const WS_INITIATE = "WS_INITIATE";
export const WS_SOCKET_OPENED = "WS_SOCKET_OPENED";
export const WS_SOCKET_ERROR = "WS_SOCKET_ERROR";
export const WS_SOCKET_CLOSED = "WS_SOCKET_CLOSED";

// Network
export const NETWORK_CONNECTED = "NETWORK_CONNECTED";
export const NETWORK_DISCONNECTED = "NETWORK_DISCONNECTED";
export const NETWORK_DISCONNECTED_DELAYED = "NETWORK_DISCONNECTED_DELAYED";
export const NETWORK_CHECK_CONNECTION = "NETWORK_CHECK_CONNECTION";

export const sectionCoverter = {
  exercise: "exercises",
  resource: "resources",
  helpresource: "helpresources",
  mediaresource: "imageresources",
  aida: "aida",
  opmessage: "messages",
  productnews: "messages",
  marketmessage: "messages"
};

/// Entity types
export const ENTITIES = {
  [EXERCISE.toLowerCase()]: camelCaseToPascalCase(EXERCISE),
  [RESOURCE.toLowerCase()]: camelCaseToPascalCase(RESOURCE),
  [HELP_RESOURCE.toLowerCase()]: camelCaseToPascalCase(HELP_RESOURCE),
  [MEDIA_RESOURCE.toLowerCase()]: camelCaseToPascalCase(MEDIA_RESOURCE),
  [TEMPLATE.toLowerCase()]: camelCaseToPascalCase(TEMPLATE),
  [MEDIA.toLowerCase()]: camelCaseToPascalCase(MEDIA),
  [AIDA.toLowerCase()]: camelCaseToPascalCase(AIDA),
  [PRODUCTNEWS.toLowerCase()]: camelCaseToPascalCase(PRODUCTNEWS),
  [OPMESSAGE.toLowerCase()]: camelCaseToPascalCase(OPMESSAGE),
  [MARKETMESSAGE.toLowerCase()]: camelCaseToPascalCase(MARKETMESSAGE),
  [SERIES.toLowerCase()]: camelCaseToPascalCase(SERIES),
  [PRODUCTLIST.toLowerCase()]: camelCaseToPascalCase(PRODUCTLIST),
  [OMNIA.toLowerCase()]: camelCaseToPascalCase(OMNIA),
};

/// Entity plurals
export const ENTITY_PLURAL = {
  [EXERCISE.toLowerCase()]: `${EXERCISE}s`,
  [RESOURCE.toLowerCase()]: `${RESOURCE}s`,
  [HELP_RESOURCE.toLowerCase()]: `${HELP_RESOURCE}s`,
  [MEDIA_RESOURCE.toLowerCase()]: `${MEDIA_RESOURCE}s`,
  [TEMPLATE.toLowerCase()]: `${TEMPLATE}s`,
  [AIDA.toLowerCase()]: `posts`,
  [PRODUCTNEWS.toLowerCase()]: `produktnyheter`,
  [OPMESSAGE.toLowerCase()]: `drift`,
  [MARKETMESSAGE.toLowerCase()]: "marknadsmeddelande"
};

export const ENTITY_TYPE = {
  [EXERCISE.toLowerCase()]: "product",
  [RESOURCE.toLowerCase()]: "product",
  [HELP_RESOURCE.toLowerCase()]: "product",
  [MEDIA_RESOURCE.toLowerCase()]: "product",
  [TEMPLATE.toLowerCase()]: "product",
  [AIDA.toLowerCase()]: "system",
  [PRODUCTNEWS.toLowerCase()]: "system",
  [OPMESSAGE.toLowerCase()]: "system",
  [MARKETMESSAGE.toLowerCase()]: "system"
};

export const LOCKCODES = {
  NotLocked: 1,
  LockedByMe: 2,
  LockedByOther: 3
};

// Error message translation
export const translationErrorMessage = {
  "media file still has connected posts":
    "Media filer kan ej tas bort, har kopplingar till övningar/resurser",
  "duplicate tagID in the upload file":
    "Label id förekommer flera gånger för denna fil.",
  "no media label found": "Kunde inte hitta label id.",
  "media file extension not acceptable":
    "Filtypen för denna fil är inte tillåten.",
  "invalid parameter: labels": "Något är fel med labels fältet i JSON.",
  "node has no, or not enough, previously published parents":
    "Noden saknar publicerade föräldrar.",
  "no markergroups to publish": "Inget nytt att publicera."
};

export const seriesTranslationErrorMessage = message => {
  switch (message) {
    case "Parent series [x] has not been published previously":
      return "Serien är kopplad till en opublicerad serie. Du måste publicera huvudserien";
    case "series with id [x] is not deletable, has status [draft_preview_published]":
      return "För att radera en serie så måste serien ha statusen utkast";
    case "entity with the same title already exists":
      return "Serie med samma titel finns redan";
    case "missing series image":
      return "Serien saknar seriebild.";
    case "cannot unpublish series that are not in a published state":
      return "Serien går inte avpublicera då den inte har korrekt status";
    default:
      return "Ett fel har uppstått med serier.";
  }
};

export const translation = {
  text: "Text",
  aidatext: "AidaText",
  imageslider: "Bildsnurra",
  instruction: "Instruktion",
  multiplechoice: "Flerval",
  sortmachine: "Sortera",
  order: "Ordna",
  goal: "Mål",
  field: "Fält",
  chips: "Chips",
  boxes: "Lådor",
  blanks: "Skriv i lucka",
  help: "Hjälp",
  image: "Enskild bild",
  audio: "Ljud",
  resource: "Resurs",
  resources: "Resurser",
  mediaresource: "Sammansatt bild",
  mediaresources: "Sammansatta bilder",
  numberline: "Tallinje",
  textwithsound: "Text med ljud",
  video: "Video",
  about: "Om",
  helpvideo: "Video (Hjälp)",
  mathword: "Matteord",
  helpaudio: "Ljud (Hjälp)",
  helpimage: "Bild (Hjälp)",
  tips: "Tips",
  helpresource: "Hjälpresurs",
  helpresources: "Hjälpresurser",
  alternative: "Alternativ",
  exercises: "Övningar",
  settings: "Inställningar",
  home: "Hem",
  media: "Media",
  structure: "Struktur",
  markers: "Markers",
  imageresources: "Bildresurser",
  calculateType: "Räknesätt",
  lineup: "Uppställning",
  chart: "Tabell",
  fraction: "Bråk",
  exercise: "Övning",
  answerType: "Svarstyp",
  equalSign: "Likhetstecken",
  adventures: "Spelifiering",
  multipleAnswers: "Antal svar",
  methodType: "Metod",
  decimalCount: "Antal decimaler",
  simplify: "Förenkla",
  aida: "Hjälpverktyg",
  adventure: "Äventyr",
  "adventure-group": "Äventyrsgrupp",
  "adventure-card": "Problemkort",
  assignment_specific: "Uppdragsmärke",
  problem_independent: "Problemoberoende",
  problem_specific: "Problemspecifik",
  product: "Produkt",
  classroom: "Klassrum",
  sesam: "Sesam",
  productnews: "Produktnyheter",
  opmessage: "Driftmeddelanden",
  demoshelves: "Demobokhyllor",
  series: "Serier",
  tools: "JSON-lint",
  productlist: "Produktlista",
  accessAdmin: "Roller & behörigheter",
  books: "Böcker",
  modules: "Moduler",
  publish: "Publicera",
  heading: "Rubrik",
  chartType: "Tabelltyp",
  operative: "Operativ",
  type: "Typ",
  layout: "Layout",
  messageservice: "Meddelanden",
  no_image: "Saknar seriebild",
  no_logotype: "Saknar logotyp",
  no_page_url: "Saknar länk till seriesida",
  all: "Alla",
  omnia: "Omnia"
};

export const POST_STATUS = {
  PostStatusDraft: 0,
  PostStatusDraftReadyForReview: 1,
  PostStatusDraftReviewed: 2,
  PostStatusModified: 3,
  PostStatusRevisionReadyForReview: 4,
  PostStatusDraftPreviewPublished: 5,
  PostStatusRevisionPreviewPublished: 6,
  PostStatusRevisionReviewed: 7,
  PostStatusPublished: 8,
  PostStatusRetracted: 9
};

export const ACTION_MENU_LABELS = {
  delete: "Ta bort",
  publish: "Publicera",
  unpublish: "Avpublicera",
  edit: "Redigera"
};
