import React from "react";

const LockSolid = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 22 19"
    width={`${size}px`}
  >
    <path
      fill="currentColor"
      d="M17.25,7.66666667 L16.2916667,7.66666667 L16.2916667,5.75 C16.2916667,3.105 14.145,0.958333333 11.5,0.958333333 C8.855,0.958333333 6.70833333,3.105 6.70833333,5.75 L6.70833333,7.66666667 L5.75,7.66666667 C4.69583333,7.66666667 3.83333333,8.52916667 3.83333333,9.58333333 L3.83333333,19.1666667 C3.83333333,20.2208333 4.69583333,21.0833333 5.75,21.0833333 L17.25,21.0833333 C18.3041667,21.0833333 19.1666667,20.2208333 19.1666667,19.1666667 L19.1666667,9.58333333 C19.1666667,8.52916667 18.3041667,7.66666667 17.25,7.66666667 Z M11.5,16.2916667 C10.4458333,16.2916667 9.58333333,15.4291667 9.58333333,14.375 C9.58333333,13.3208333 10.4458333,12.4583333 11.5,12.4583333 C12.5541667,12.4583333 13.4166667,13.3208333 13.4166667,14.375 C13.4166667,15.4291667 12.5541667,16.2916667 11.5,16.2916667 Z M8.625,7.66666667 L8.625,5.75 C8.625,4.15916667 9.90916667,2.875 11.5,2.875 C13.0908333,2.875 14.375,4.15916667 14.375,5.75 L14.375,7.66666667 L8.625,7.66666667 Z"
    ></path>
  </svg>
);

export default LockSolid;
