import React from "react";
import { connect } from "react-redux";

import TextArea from "../../../shared/components/TextArea";
import { StandardButton } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";

import {
  StyledDescriptionSection,
  StyledInputWrapper
} from "./StyledDescription";

import {
  selectCurrentPostType,
  selectDescription
} from "../../../mediaresource/api/selectors";

import { addDescription } from "../../store/actions";

class Description extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      desc: props.description,
      initialDesc: props.description
    };
  }

  /**
   * Saves the description, updating the state.
   */
  saveDescription = () => {
    const { addDescription: add, postType } = this.props;
    const desc = this.state.desc;

    add(postType, desc);
    this.setState({
      showDescButtons: false,
      initialDesc: desc
    });
  };

  /**
   * Cancel previous changes
   */
  cancelDescription = () => {
    this.setState({
      showDescButtons: false,
      desc: this.state.initialDesc
    });
  };

  /**
   * Saves the description to the current state and allows for rendering of buttons.
   */
  onChange = value => {
    this.setState({
      desc: value,
      showDescButtons: true
    });
  };

  /**
   * Renders the save and cancel buttons
   */
  renderCancelAndSaveButtons = () => (
    <ButtonGroup>
      <StandardButton onClick={this.saveDescription} icon="Check" />
      <StandardButton onClick={this.cancelDescription} icon="X" />
    </ButtonGroup>
  );

  render() {
    const { desc } = this.state;

    return (
      <StyledDescriptionSection>
        <StyledInputWrapper>
          <TextArea
            value={desc}
            onChange={this.onChange}
            placeholder="Skriv en beskrivning.."
          />
        </StyledInputWrapper>
        {this.state.showDescButtons ? this.renderCancelAndSaveButtons() : null}
      </StyledDescriptionSection>
    );
  }
}

const mapStateToProps = state => {
  const postType = selectCurrentPostType(state);
  const description = selectDescription(state);

  return {
    postType: postType,
    description: description
  };
};

const mapDispatchToProps = dispatch => ({
  addDescription: (postType, description) =>
    dispatch(addDescription(postType, description))
});

export default connect(mapStateToProps, mapDispatchToProps)(Description);
