import { Column } from "react-virtualized";
import ChipRenderer from "../Renderer/ChipRenderer";

const ChipColumn = () => null;

ChipColumn.propTypes = Object.assign({}, Column.propTypes);
ChipColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: ChipRenderer,
  draggable: true
});

export default ChipColumn;
