import React from "react";

const MoveFront = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-movefront"
  >
    <rect
      transform="translate(25.000000, 30.000000) rotate(-270.000000) translate(-25.000000, -30.000000) translate(20.000000, 10.000000)"
      fill="#384E70"
      id="path-1"
      x="0"
      y="0"
      width="10"
      height="40"
    ></rect>
    <path
      transform="translate(25.000000, 15.000000) rotate(-270.000000) translate(-25.000000, -15.000000) translate(15.000000, -5.000000)"
      fill="#377AD3"
      d="M10,29.8994949 L9.89949494,30 L7.99360578e-14,20.1005051 L9.89949494,10.2010101 L10,10.3015152 L10,0 L20,0 L20,40 L10,40 L10,29.8994949 Z"
      id="path-3"
    ></path>
  </svg>
);

export default MoveFront;
