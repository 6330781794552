import ProductNews from "./views/ProductNews";
import OpMessages from "./views/OpMessages";
import MarketMessages from "./views/MarketMessages";
import { ENTITIES, MARKETMESSAGE, OPMESSAGE, PRODUCTNEWS } from "../constants";
import ProductNewsDetails from "./views/ProductNewsDetails";
import OpMessageDetails from "./views/OpMessageDetails";
import Media from "../media/views/Media";
import MarketMessageDetails from "./views/MarketMessageDetails";

export const ROUTE_PRODUCT_NEWS = "ROUTE_PRODUCT_NEWS";
export const ROUTE_MESSAGES = "ROUTE_MESSAGES";
export const ROUTE_OP_MESSAGES = "ROUTE_OP_MESSAGES";
export const ROUTE_DETAILED_OP_MESSAGE = "ROUTE_DETAILED_OP_MESSAGE";
export const ROUTE_DETAILED_PRODUCT_NEWS = "ROUTE_DETAILED_PRODUCT_NEWS";
export const ROUTE_MESSAGES_MEDIA = "ROUTE_MESSAGES_MEDIA";
export const ROUTE_MARKETMESSAGES = "ROUTE_MARKETMESSAGES";
export const ROUTE_DETAILED_MARKETMESSAGE = "ROUTE_DETAILED_MARKETMESSAGE";

export const routes = {
  [ROUTE_MESSAGES]: {
    path: "/system/:entityId/meddelanden",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Meddelanden",
    childRoutes: [ROUTE_PRODUCT_NEWS, ROUTE_OP_MESSAGES],
    type: "system"
  },
  [ROUTE_PRODUCT_NEWS]: {
    name: ENTITIES[PRODUCTNEWS],
    path: "/system/:entityId/produktnyheter",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Produktnyheter",
    childRoutes: [ROUTE_DETAILED_PRODUCT_NEWS],
    type: "system"
  },
  [ROUTE_DETAILED_PRODUCT_NEWS]: {
    name: ENTITIES[PRODUCTNEWS],
    path: "/system/:entityId/produktnyheter/:id",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Produktnyheter",
    type: "system",
    confirmLeave: state =>
      confirmDirtyDraftPostStateLeave(ENTITIES[PRODUCTNEWS], state)
  },
  [ROUTE_OP_MESSAGES]: {
    name: ENTITIES[OPMESSAGE],
    path: "/system/:entityId/drift",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Driftmeddelanden",
    childRoutes: [ROUTE_DETAILED_OP_MESSAGE],
    type: "system"
  },
  [ROUTE_DETAILED_OP_MESSAGE]: {
    name: ENTITIES[OPMESSAGE],
    path: "/system/:entityId/drift/:id",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Driftmeddelande",
    type: "system",
    confirmLeave: state =>
      confirmDirtyDraftPostStateLeave(ENTITIES[OPMESSAGE], state)
  },
  [ROUTE_MARKETMESSAGES]: {
    name: ENTITIES[MARKETMESSAGE],
    path: "/system/:entityId/marknadsmeddelande",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Marknadsmeddelanden",
    childRoutes: [ROUTE_DETAILED_MARKETMESSAGE],
    type: "system"
  },
  [ROUTE_DETAILED_MARKETMESSAGE]: {
    name: ENTITIES[MARKETMESSAGE],
    path: "/system/:entityId/marknadsmeddelande/:id",
    query: {},
    noServiceBar: true,
    hideFilters: true,
    text: "Marknadsmeddelande",
    type: "system",
    confirmLeave: state =>
      confirmDirtyDraftPostStateLeave(ENTITIES[MARKETMESSAGE], state)
  },
  [ROUTE_MESSAGES_MEDIA]: {
    name: "Media",
    path: "/system/:entityId/meddelanden/media",
    query: {},
    noServiceBar: true,
    hideFilters: false,
    filters: ["MEDIASEARCH", "MEDIATAGS"],
    tagsByTypeOnly: ["messages"],
    uploadBasePath: "/messages",
    text: "Media",
    icon: "Media"
  }
};

export const views = {
  [ROUTE_PRODUCT_NEWS]: ProductNews,
  [ROUTE_OP_MESSAGES]: OpMessages,
  [ROUTE_DETAILED_PRODUCT_NEWS]: ProductNewsDetails,
  [ROUTE_DETAILED_OP_MESSAGE]: OpMessageDetails,
  [ROUTE_MARKETMESSAGES]: MarketMessages,
  [ROUTE_DETAILED_MARKETMESSAGE]: MarketMessageDetails,
  [ROUTE_MESSAGES_MEDIA]: Media
};

const confirmDirtyDraftPostStateLeave = (route, state) => {
  const isNew = state.location.payload.id === "new";
  if (isNew) {
    return;
  }
  const draftState = state[route].getIn(["postDraft", "postDraftState"]);
  if (draftState !== "dirty") {
    return;
  }
  return `Du har gjort ändringar som inte kommer sparas om du väljer att lämna sidan.`;
};
