import { RouterService, registerSaga, registerReducer } from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import { mediaresource as mediaResourceReducer } from "./store/reducer";

import { ENTITIES, MEDIA_RESOURCE } from "../constants";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer(ENTITIES[MEDIA_RESOURCE], mediaResourceReducer);
};
