import { routes, views } from "./routes";
import {
  RouterService,
  registerReducer,
  registerSaga,
  registerConstraint
} from "../shared";
import rootSaga from "./sagas";
import { entity as productReducer } from "../products/store/reducer";
import { applicationEnsureProduct } from "./sagas/sagas";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Product", productReducer);
  registerConstraint(applicationEnsureProduct);
};
