import MathWord from "./components/MathWord/MathWord";
import { MATH_WORD } from "../constants";

const getUsedResources = ({ data }) => {
  if (!data.words) {
    return undefined;
  }
  return data.words.reduce((acc, word) => {
    if (word.mediaresource && word.mediaresource.data) {
      return acc.concat(word.mediaresource.data.id);
    }
    return acc;
  }, []);
};

const types = [MATH_WORD];

export const createMathWordPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      const { words } = entity.data;

      return {
        component: MathWord,
        style: { padding: 0 },
        props: {
          words: words,
          settings: { ...entity.settings },
          data: entity.data
        }
      };
    }

    return null;
  },
  types,
  getUsedResources
});

export default createMathWordPlugin;

export const emptyBlocks = {
  [MATH_WORD]: {
    type: MATH_WORD,
    data: {
      words: [
        {
          title: "",
          mediasrc: null,
          mediaresource: null,
          soundsrc: null,
          text: ""
        }
      ]
    },
    settings: {
      multiLine: true,
      operative: false
    }
  }
};
