import React from "react";
import { connect } from "react-redux";

import ButtonGroup from "../../../shared/components/ButtonGroup";
import { StandardButton } from "../../../shared";
import { PrimaryButton } from "../../../shared";
import Link from "../../../shared/components/Link";
import TagPost from "../../components/TagPost";
import CategoryAction from "../../components/CategoryAction";
import PublishPost from "./PublishPost";
import DeletePosts from "./DeletePosts";
import PaginationAction from "../ToolBar/ListPagination";
import styled from "styled-components";

import { PREVIEW_URL } from "../../api/constants";
import { ENTITIES, EXERCISE, MEDIA_RESOURCE } from "../../../constants";
import { selectEntityId } from "../../../store/selectors";

const mapStateToProps = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[EXERCISE];
  }
  const prodID = selectEntityId(state);
  return {
    pathname: state.location.pathname,
    postType: postType,
    selectedPosts: state.Carousel.items,
    activePost: state.Carousel.active,
    entityId: prodID,
    newPostLink: state.location.routesMap["ROUTE_MEDIARESOURCE_EDIT"].path
      .replace(":entityId", prodID)
      .replace(":id", "new")
  };
};

const ActionGroup = styled.div`
  display: flex;
  width: 100%;
`;

const SelectedPosts = styled.div`
  margin: 0px 3px;
  padding: 13px;
`;

export const ActionTop = connect(mapStateToProps)(
  ({ selectedPosts, postType, newPostLink }) => {
    const posts = selectedPosts.length;

    let Preview = null;
    let Category = null;
    let Publish = null;

    if (postType === ENTITIES[EXERCISE]) {
      const postIds = selectedPosts.map(p => p.id);
      Preview = (
        <a
          target="_blank"
          href={PREVIEW_URL + postIds.join(",")}
          rel="noopener noreferrer"
        >
          <StandardButton studlicon="Preview"></StandardButton>
        </a>
      );
      Publish = <PublishPost />;
      Category = <CategoryAction />;
    }

    const CreateNew = (
      <Link to={newPostLink}>
        <PrimaryButton outline studlicon="PostAdd">
          Skapa ny
        </PrimaryButton>
      </Link>
    );

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <ActionGroup style={{ flex: 1, width: "100%" }}>
          <ButtonGroup>{CreateNew}</ButtonGroup>
        </ActionGroup>
        {selectedPosts.length > 0 ? (
          <ActionGroup
            style={{ flex: 5, width: "100%", justifyContent: "flex-end" }}
          >
            <SelectedPosts>
              {" "}
              <b>{posts} </b> markerade{" "}
            </SelectedPosts>
            <ButtonGroup>
              {Preview}
              <DeletePosts />
              {Publish}
              <TagPost content={["Labels"]} />
              {Category}
            </ButtonGroup>
          </ActionGroup>
        ) : null}
      </div>
    );
  }
);

export const Pagination = connect(mapStateToProps)(() => (
  <PaginationAction type={MEDIA_RESOURCE} />
));
