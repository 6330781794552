import styled from "styled-components";

export default styled.div`
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: ${props => props.theme.baselineNumber * 9}px;
`;
