import React from 'react'
import { connect } from 'react-redux';
import { closeConfirmModal } from '../../..';
import Modal from '../Modal';
import { 
    StyledModalContent, 
    StyledTitle, 
    StyledButtonWrapper, 
    StyledText,
    StyledButton } 
from "./StyledConfirmModal"

const ConfirmModal = ({isConfirmModalOpen, closeModal, handleCloseModal, modalMsg}) => (
    <Modal isModalOpen={isConfirmModalOpen} modalClose={closeModal}>
        <StyledModalContent>
            <StyledTitle>Vill du verkligen lämna sidan?</StyledTitle>
            <StyledText>{modalMsg}</StyledText>
            <StyledButtonWrapper>
            <StyledButton transparent onClick={() => handleCloseModal(false)}>STANNA KVAR PÅ SIDAN</StyledButton>
            <StyledButton transparent onClick={() => handleCloseModal(true)}>LÄMNA SIDAN</StyledButton>
            </StyledButtonWrapper>
        </StyledModalContent>
    </Modal>
)

const mapDispatchToProps = dispatch => ({
  closeModal: () => closeConfirmModal(dispatch)
});

const mapStateToProps = state => ({ 
  isConfirmModalOpen: state.modal.get("isConfirmModalOpen"),
  handleCloseModal: state.modal.get("canLeave"),
  modalMsg: state.modal.get("modalMsg")
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);