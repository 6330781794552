import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StandardButton } from "../../../shared";
import { deletePosts } from "../../store/actions";
import { DELETE, IMAGE_RESOURCES } from "../../../constants";
import HasPermission from "../../../shared/Permissions";

const mapStateToProps = state => ({
  selectedPosts: state.Carousel.items
});

const mapDispatchToProps = dispatch => ({
  deletePosts: postIds => dispatch(deletePosts(postIds))
});

const DeletePostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

const DeletePostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
const Top = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
const Title = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
const MiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const UpdateButton = styled(StandardButton)`
  width: 280px;
  border-radius: 5px;
  display: block;
  margin: 5px 0 0 0 !important;
`;

class DeletePost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  onDelete = () => {
    const { selectedPosts, deletePosts } = this.props;

    const postIds = selectedPosts.map(post => post.id);
    deletePosts(postIds);

    this.setState({
      isOpen: false
    });
  };

  renderDelete = () => {
    const { selectedPosts } = this.props;

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <DeletePostWrapper>
        <StandardButton
          studlicon="Trash"
          onClick={this.onToggle}
        ></StandardButton>
        <DeletePostContent className={classNames}>
          <Top>
            <Title>Ta bort:</Title>
          </Top>

          <div>
            <MiddleContent>
              <UpdateButton studlicon="Trash" onClick={this.onDelete}>
                {" "}
                Ta bort {selectedPosts.length} poster{" "}
              </UpdateButton>
            </MiddleContent>
          </div>
        </DeletePostContent>
      </DeletePostWrapper>
    );
  };

  render() {
    return (
      <div ref={this.clickRef}>
        <HasPermission
          component={this.renderDelete()}
          section={IMAGE_RESOURCES}
          permission={DELETE}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePost);
