import {
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@material-ui/core";
import React from "react";
import { Settings } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../shared/components/Button";
import Checkbox from "../../shared/components/Checkbox";
import { getTaxonomySubjects } from "../../taxonomy/store/selectors";
import { setProductTaxonomySubjects } from "../api/actions";
import { getProductInfo, getProductTaxonomySubjects } from "../store/selectors";
import IconButton from "./IconButton";
import Label from "./Label";

const StackHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  ${({ noWrap }) => (noWrap ? `flex-wrap: nowrap;` : `flex-wrap: wrap;`)}
`;

const StackVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Subject = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 0.25rem;
  background-color: #5099fc33;
`;

const DialogContent = styled(MuiDialogContent)`
  min-width: 360px;
`;

const TaxonomySubjectsEdit = ({
  initialSelectedSubjects,
  subjects,
  onSave,
  onCancel,
}) => {
  const [selectedSubjects, setSelectedSubjects] = React.useState(
    initialSelectedSubjects.map((l) => l.id)
  );

  const isSelected = (subject) => selectedSubjects.includes(subject.id);

  const toggleSubject = (subject) => {
    if (isSelected(subject)) {
      setSelectedSubjects(selectedSubjects.filter((id) => id !== subject.id));
      return;
    }
    setSelectedSubjects([...selectedSubjects, subject.id]);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Redigera områden</DialogTitle>
      <DialogContent>
        <StackVertical>
          <List dense>
            {subjects.map((l) => (
              <ListItem key={l.id}>
                <Checkbox
                  isChecked={isSelected(l)}
                  onClick={() => toggleSubject(l)}
                />
                <Label>{l.description}</Label>
              </ListItem>
            ))}
          </List>
        </StackVertical>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} type="button" skin="primary">
          Avbryt
        </Button>
        <Button
          onClick={() => onSave(selectedSubjects)}
          type="button"
          skin="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TaxonomySubjects = () => {
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState(false);
  const [product] = useSelector(getProductInfo);
  const subjects = useSelector(getTaxonomySubjects);
  const selectedSubjects = useSelector(getProductTaxonomySubjects);

  const startEdit = () => {
    setEditing(true);
  };

  const saveEdit = (subjectIds) => {
    dispatch(setProductTaxonomySubjects(product.id, subjectIds));
    setEditing(false);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  return (
    <StackVertical>
      <StackHorizontal noWrap>
        <Label as="h4">Område</Label>
        <IconButton aria-label="Hantera områden" onClick={startEdit}>
          <Settings width="16px" height="16px" />
        </IconButton>
        {editing && (
          <TaxonomySubjectsEdit
            subjects={subjects}
            initialSelectedSubjects={selectedSubjects}
            onCancel={cancelEdit}
            onSave={saveEdit}
          />
        )}
      </StackHorizontal>
      <StackHorizontal>
        {selectedSubjects.map((l) => (
          <Subject key={l.id}>{l.description}</Subject>
        ))}
      </StackHorizontal>
    </StackVertical>
  );
};

export default TaxonomySubjects;
