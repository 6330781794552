import * as React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { SliderTick } from "./SliderTick";
import { RadioButton } from "../../../shared/components/RadioButton";
import {
  StyledJumpImg,
  StyledTickLabel,
  StyledJumpLabel,
  StyledBottomBar,
  StyledNumberLine,
  StyledBottomBarCol,
  StyledBottomBarRow,
  StyledJumpImgWrapper,
  StyledNumberLineEdge,
  StyledNumberLineTrack,
  StyledJumpImgContainer,
  StyledNumberLineContent,
  StyledNumberLineWrapper,
  StyledNumberLineContainer,
  StyledNumberLineEdgeContainer,
  StyledNumberLineEdgeArrowContainer,
  StyledNumberLineEndInput,
  StyledRemoveImgButton
} from "./StyledNumberLine";
import {
  LEFT,
  STEPS,
  RIGHT,
  START,
  END,
  DATA,
  SETTINGS,
  START_VALUE
} from "../../../constants";
import { ValueInput } from "./ValueInput";
import Checkbox from "../../../shared/components/Checkbox/Checkbox";
import {
  Media,
  JumpEnd,
  Preview,
  Bullseye,
  JumpStart,
  ArrowCircleUp,
  ArrowCircleLeft,
  CheckboxChecked,
  ArrowCircleRight,
  Close
} from "../../../shared/components/StudliIcons";
import {
  defaultJump,
  defaultShowArrow,
  defaultNumberlineSettings,
  defaultStepsGenerator
} from "./NumberLineHelpers";
import { white } from "../../../styles/shared/colors";
import { Button } from "../../../shared";
import PluginHoc from "../../../shared/PluginHoc";
import Tooltip from "react-tooltip-lite";

const iconSize = 18;
const decimalSetting = 0.001;

const NumberLine = ({
  blockKey,
  onChange,
  actions,
  updateData,
  storeDraft,
  draftTarget,
  data = defaultStepsGenerator(),
  settings,
  registerValidator
}) => {
  const [currentTickId, selectTick] = React.useState(null);
  const [showHideStatus, setShowHideStatus] = React.useState(false);

  const {
    interval,
    lineType,
    startValue = 0,
    jump = defaultJump(),
    showArrow = defaultShowArrow()
  } = { ...defaultNumberlineSettings(), ...settings };

  /**
   * Registers the validator after render
   */
  React.useEffect(() => {
    registerValidator([
      fixedJumpValidator,
      "Ett fast hopp måste ha en start- och slutposition"
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Returns true if line type is additive
   * @returns {boolean}
   */
  const isAdditive = () => lineType === "additive";

  /**
   * Validator for fixed jump so that a numberline containing fixed jump setting cannot be saved unless settings are
   * properly filled in
   * @returns {*}
   */
  const fixedJumpValidator = (componentData, componentSettings) => {
    if (componentSettings.jump == null) {
      componentSettings.jump = defaultJump();
    }

    const {
      fixed: { isActive, start, end }
    } = componentSettings.jump;

    return isActive
      ? checkStringValidity(start) && checkStringValidity(end) && start !== end
      : true;
  };

  /**
   * Makes sure a variable is a string and that the string is not empty
   * @param str
   * @returns {boolean|number}
   */
  const checkStringValidity = str => typeof str === "string" && str.length;

  /**
   * Return object with icons
   * @returns {{image: *, isCorrect: *, isMarked: *, isStartingPoint: *}}
   */
  const getIcons = () => ({
    image: <Media size={iconSize} />,
    show: <Preview size={iconSize} />,
    isCorrect: <CheckboxChecked size={iconSize} />,
    isMarked: <ArrowCircleUp size={iconSize} />,
    isStartingPoint: <Bullseye size={iconSize} />,
    isJumpStart: <JumpStart size={iconSize} />,
    isJumpEnd: <JumpEnd size={iconSize} />,
    arrowLeft: <ArrowCircleLeft size={iconSize} />,
    arrowRight: <ArrowCircleRight size={iconSize} />
  });

  /**
   * Updates data with a bulk of new data
   * @param data
   * @param settings
   * @returns {*}
   */
  const bulkUpdateData = (data, settings) => {
    storeDraft(draftTarget);
    onChange(blockKey, data, settings);
  };

  /**
   * Fetches icon by key
   * @param key
   * @returns {*}
   */
  const getIcon = key => getIcons()[key];

  /**
   * Renders icon
   * @param id
   * @param key
   * @returns {*}
   */
  const renderIcon = (id, key) => (
    <StyledTickLabel key={id ? `icon_${key}_${id}` : false}>
      {getIcon(key)}
    </StyledTickLabel>
  );

  /**
   * Provides a tick with it's correct icons
   * @param tickData
   * @returns {any[]}
   */
  const getTicIcons = tickData =>
    Object.keys(tickData)
      .filter(key => getIcons().hasOwnProperty(key))
      .map(key => (tickData[key] ? renderIcon(tickData.id, key) : undefined))
      .filter(item => item);

  /**
   * Returns tick data based on an id
   * @param providedId
   */
  const getTickDataById = providedId =>
    data.steps.find(({ id }) => id === providedId);

  /**
   * Fetches tick index by id
   * @param providedId
   */
  const getTickIndexById = providedId =>
    data.steps.findIndex(({ id }) => id === providedId);

  /**
   * Returns array of ticks with updated data
   * @param updatedTick
   * @param modValue
   */
  const updateTicksData = (updatedTick, modValue = {}) => {
    storeDraft(draftTarget);

    return updateData(
      STEPS,
      data.steps.map(tickData =>
        updatedTick.id === tickData.id
          ? updatedTick
          : { ...tickData, ...modValue }
      ),
      DATA
    );
  };

  /**
   * tick click callback
   * @param tickID
   */
  const tickCallback = tickID => selectTick(tickID);

  /**
   * If
   * @param index
   * @returns {boolean}
   */
  const tickIsActive = index =>
    isAdditive() &&
    index < data.steps.findIndex(step => step.id === currentTickId);

  /**
   * Updates interval values
   */
  const updateIntervalValues = () => {
    storeDraft(draftTarget);
    const updatedSteps = data.steps.map((step, index) => ({
      ...step,
      text: getLabelValue(step.text, step.hasCustomValue, index),
      showLabel: true
    }));

    updateData(STEPS, updatedSteps, DATA);
  };

  /**
   * Clears all interval values
   */
  const hideAllLabels = () => {
    storeDraft(draftTarget);
    updateData(
      STEPS,
      data.steps.map(step => ({ ...step, showLabel: false })),
      DATA
    );
  };

  /**
   * Extends tick data for icons
   * @param tickData
   * @returns {{isJumpStart: boolean, isJumpEnd: boolean}}
   */
  const prepTickIconData = tickData => ({
    ...tickData,
    isJumpStart: jump.fixed.start === tickData.id,
    isJumpEnd: jump.fixed.end === tickData.id
  });

  /**
   * Fetches label value
   * @param text
   * @param hasCustomValue
   * @param index
   * @returns {number}
   */
  const getLabelValue = (text, hasCustomValue, index) => {
    if (hasCustomValue && text !== "") {
      return text;
    }

    const interv = !isNaN(interval) ? parseFloat(interval) : 1;
    const startv =
      !isNaN(startValue) && startValue !== "" ? parseFloat(startValue) : 0;
    return (Math.round((interv * index + startv) * 1000) / 1000).toString();
  };

  /**
   * Render slider ticks
   * @returns {any[]}
   */
  const renderSliderTicks = () =>
    data.steps.map(
      ({ id, text, showLabel, stepImage, hasCustomValue }, index) => (
        <SliderTick
          isFirst={index === 0}
          isLast={index === data.steps.length - 1}
          key={id}
          id={id}
          callback={tickCallback}
          isSelected={id === currentTickId}
          isActive={tickIsActive(index)}
          icons={getTicIcons(prepTickIconData(getTickDataById(id)))}
          showLabel={showLabel}
          label={getLabelValue(text, hasCustomValue, index)}
          imgUrl={stepImage}
          removeImgCallback={removeTickImg}
        />
      )
    );

  /**
   * Update Start value
   * @param value
   */
  const updateStartValue = e => {
    if (e.key === "Enter") {
      storeDraft(draftTarget);
      if (e.target.value === "" || e.target.value === "-") {
        return updateData(START_VALUE, e.target.value, SETTINGS);
      }
      if (!isNaN(parseFloat(e.target.value))) {
        return updateData(START_VALUE, parseFloat(e.target.value), SETTINGS);
      }
    }
  };

  /**
   * Renders arrow button based on direction variable
   * @param direction
   * @returns {*}
   */
  const renderArrowDirectionButton = direction =>
    direction === LEFT
      ? iconButton(
          toggleArrow(direction),
          <ArrowCircleLeft color={white} size={iconSize} />,
          "",
          showArrow.left,
          { width: "25px" }
        )
      : iconButton(
          toggleArrow(direction),
          <ArrowCircleRight color={white} size={iconSize} />,
          "",
          showArrow.right,
          { width: "25px" }
        );

  /**
   * Renders an input or simply a label, based on horizontal value
   * @param horizontal
   * @param value
   * @returns {*}
   */
  const renderNumberLineInputOrLabel = (horizontal, value) =>
    horizontal === LEFT ? (
      <StyledNumberLineEndInput
        type="number"
        step={decimalSetting}
        placeholder={startValue}
        onKeyDown={updateStartValue}
      />
    ) : (
      value
    );

  /**
   * Renders edges of number line
   * @param value
   * @param horizontal
   * @returns {*}
   */
  const renderNumberLineEdge = (value, horizontal) => (
    <StyledNumberLineEdgeContainer>
      <StyledNumberLineEdgeArrowContainer horizontal={horizontal}>
        {renderArrowDirectionButton(horizontal)}
      </StyledNumberLineEdgeArrowContainer>

      <StyledNumberLineEdge>
        {renderNumberLineInputOrLabel(horizontal, value)}
      </StyledNumberLineEdge>
    </StyledNumberLineEdgeContainer>
  );

  /**
   * Renders number line
   * @returns {any[]}
   */
  const renderNumberLine = () => (
    <StyledNumberLineWrapper>
      <StyledNumberLineContainer>
        {renderNumberLineEdge(0, LEFT)}

        <StyledNumberLineTrack>{renderSliderTicks()}</StyledNumberLineTrack>

        {renderNumberLineEdge(
          getLabelValue("", false, data.steps.length - 1),
          RIGHT
        )}
      </StyledNumberLineContainer>
    </StyledNumberLineWrapper>
  );

  /**
   * Sets start position
   */
  const toggleStartCallback = () =>
    currentTickId ? toggleTickData("isStartingPoint", true) : null;

  /**
   * Toggles whether an arrow should be shown at right or left end of number line
   * @param direction
   * @returns {function(): void}
   */
  const toggleArrow = direction => () => {
    storeDraft(draftTarget);

    updateData(
      "showArrow",
      {
        ...{ left: false, right: false },
        [direction]: !showArrow[direction]
      },
      SETTINGS
    );
  };

  /**
   * Toggles label
   */
  const toggleShow = () => (currentTickId ? toggleTickData("showLabel") : null);

  /**
   * Toggles whether a tick is correct answer or not
   */
  const toggleIsAnswer = () =>
    currentTickId ? toggleTickData("isCorrect", true) : null;

  /**
   * Toggles whether a tick is correct answer or not
   */
  const toggleMark = () =>
    currentTickId ? toggleTickData("isMarked", false) : null;

  /**
   * Toggles is jump end
   */
  const toggleFixedJump = key => () => {
    storeDraft(draftTarget);

    return currentTickId
      ? updateData(
          "jump",
          {
            ...jump,
            fixed: {
              ...jump.fixed,
              isActive: true,
              [key]: jump.fixed[key] !== currentTickId ? currentTickId : null
            }
          },
          SETTINGS
        )
      : null;
  };

  /**
   * Toggles has custom value
   */
  const toggleCustomValue = () => {
    const tickData = getTickDataById(currentTickId);

    tickData.hasCustomValue
      ? updateTicksData({
          ...tickData,
          text: getLabelValue(
            tickData.text,
            false,
            getTickIndexById(currentTickId)
          ),
          hasCustomValue: false
        })
      : updateTicksData({ ...tickData, hasCustomValue: true });
  };

  /**
   * Updates tick text
   * @param text
   */
  const setTickText = text => {
    const tickData = getTickDataById(currentTickId);
    updateTicksData({
      ...tickData,
      text
    });
  };

  /**
   * Shows/hides all interval values
   */
  const showHideAll = () => {
    showHideStatus ? updateIntervalValues() : hideAllLabels();

    setShowHideStatus(!showHideStatus);
  };

  /**
   * Toggles tickData by key
   * @param key
   * @param neutralize
   */
  const toggleTickData = (key, neutralize) => {
    const tickData = getTickDataById(currentTickId);
    const neutralizeObject = neutralize ? { [key]: false } : {};

    updateTicksData(
      {
        ...tickData,
        [key]: !tickData[key]
      },
      neutralizeObject
    );
  };

  /**
   * Callback when selecting tick image
   * @param selected
   * @returns {*}
   */
  const selectTickImgCallback = selected => {
    const tickData = getTickDataById(currentTickId);
    updateTicksData({
      ...tickData,
      stepImage: selected[0] ? selected[0].url : null
    });
  };

  /**
   * Callback when selecting jump image
   * @param selected
   * @returns {*}
   */
  const selectJumpImgCallback = selected => {
    storeDraft(draftTarget);
    updateData(
      "jump",
      {
        ...jump,
        image: selected[0] ? selected[0].url : null
      },
      SETTINGS
    );
  };

  /**
   * Removes jump image
   */
  const removeJumpImg = () => {
    storeDraft(draftTarget);
    updateData(
      "jump",
      {
        ...jump,
        image: null
      },
      SETTINGS
    );
  };

  /**
   * Removes tick image
   */
  const removeTickImg = id => {
    const tickData = getTickDataById(id);
    updateTicksData({
      ...tickData,
      stepImage: null
    });
  };

  /**
   * Runs openMediaLibrary function
   */
  const selectTickImg = () => openMediaLibrary(selectTickImgCallback);

  /**
   * Runs openMediaLibrary function
   */
  const selectJumpImg = () => openMediaLibrary(selectJumpImgCallback);

  /**
   * Opens media library modal
   * @param callback
   * @returns {*}
   */
  const openMediaLibrary = callback => {
    const { openMediaModal = () => {} } = actions || {};
    openMediaModal(callback);
  };

  /**
   * Sets a jump start or end
   * @param key
   * @returns {boolean}
   */
  const setJumpStarOrEnd = key =>
    currentTickId && currentTickId === jump.fixed[key];

  /**
   * Render is start toggle
   * @param isStartingPoint
   * @returns {*}
   */
  const renderIsStartToggle = isStartingPoint =>
    iconButton(
      toggleStartCallback,
      <Bullseye size={iconSize} />,
      "Start",
      isStartingPoint,
      { margin: "0 10px" }
    );

  /**
   * Render should show toggle
   * @param shouldShow
   * @returns {*}
   */
  const renderShowToggle = shouldShow =>
    iconButton(toggleShow, <Preview size={iconSize} />, "Visa", shouldShow, {
      margin: "0 10px"
    });

  /**
   * Render is answer toggle
   * @param isCorrect
   * @returns {*}
   */
  const renderIsAnswerToggle = isCorrect =>
    iconButton(
      toggleIsAnswer,
      <CheckboxChecked size={iconSize} />,
      "Svar",
      isCorrect,
      { margin: "0 10px" }
    );

  /**
   * Render is marked toggle
   * @param isMarked
   * @returns {*}
   */
  const renderMarkingToggle = isMarked =>
    iconButton(
      toggleMark,
      <ArrowCircleUp size={iconSize} />,
      "Märke",
      isMarked,
      { margin: "0 10px" }
    );

  /**
   * Render is jump start toggle
   * @returns {*}
   */
  const renderJumpStartToggle = () =>
    iconButton(
      toggleFixedJump(START),
      <JumpStart size={iconSize} />,
      "Start",
      setJumpStarOrEnd(START),
      { margin: "0 10px" }
    );

  /**
   * Render is jump end toggle
   * @returns {*}
   */

  const renderJumpEndToggle = () =>
    iconButton(
      toggleFixedJump(END),
      <JumpEnd size={iconSize} />,
      "Slut",
      setJumpStarOrEnd(END),
      { margin: "0 10px" }
    );
  /**
   * Renders show/hide all toggle
   * @returns {*}
   */
  const renderShowHideAll = () =>
    iconButton(
      showHideAll,
      <Preview size={iconSize} />,
      `${showHideStatus ? "Alla" : "Inga"}`,
      showHideStatus,
      { margin: "0 10px" }
    );

  /**
   * Renders jump image
   * @returns {*}
   */
  const renderJumpImage = src => {
    var filename = src
      ? src.replace(/^.*[\\/]/, "")
      : "Kunde inte hämta filnamnet";

    return src ? (
      <StyledJumpImgWrapper>
        <Tooltip content={filename} tipContentHover useDefaultStyles={true}>
          <StyledJumpImgContainer>
            <StyledJumpImg src={src} />
            <StyledRemoveImgButton onClick={removeJumpImg}>
              <Close size={"16"} />
            </StyledRemoveImgButton>
          </StyledJumpImgContainer>
          <StyledJumpLabel>Hopp</StyledJumpLabel>
        </Tooltip>
      </StyledJumpImgWrapper>
    ) : null;
  };

  /**
   * Runs when enter is pressed in custom value input field
   */
  const onEnterPress = e => {
    setTickText(e.text);
    e.target.value = "";
  };

  /**
   * Renders custom value input
   * @param hasCustomValue
   * @param showLabel
   * @param text
   * @returns {null}
   */
  const renderCustomValueInput = (hasCustomValue, showLabel, text) =>
    currentTickId && showLabel ? (
      <StyledBottomBarCol>
        <ValueInput onEnterPress={onEnterPress} disabled={!hasCustomValue} />
        <Checkbox
          isChecked={hasCustomValue}
          label={"Ersätt värde"}
          onClick={toggleCustomValue}
        />
        <Button onClick={selectTickImg} disabled={!hasCustomValue}>
          Välj enskild bild
        </Button>
      </StyledBottomBarCol>
    ) : null;

  /**
   * Renders bottom bar
   * @returns {*}
   */
  const renderBottomBar = ({
    isStartingPoint,
    text,
    isCorrect,
    isMarked,
    hasCustomValue,
    showLabel
  } = {}) => (
    <StyledBottomBar>
      <StyledBottomBarRow>
        <StyledBottomBarCol>
          {renderIsStartToggle(isStartingPoint)}
          {renderShowToggle(showLabel)}
          {renderIsAnswerToggle(isCorrect)}
          {renderMarkingToggle(isMarked)}
        </StyledBottomBarCol>

        <StyledBottomBarCol margin={"0 auto"}>
          {renderJumpStartToggle()}
          {renderJumpEndToggle()}
        </StyledBottomBarCol>

        <StyledBottomBarCol>
          {renderShowHideAll()}
          {renderJumpImage(jump.image)}
        </StyledBottomBarCol>
      </StyledBottomBarRow>

      <StyledBottomBarRow>
        {renderCustomValueInput(hasCustomValue, showLabel, text)}
      </StyledBottomBarRow>
    </StyledBottomBar>
  );

  /**
   * Render icon button
   * @param callback
   * @param icon
   * @param label
   * @param isActive
   * @param styles
   * @returns {*}
   */
  const iconButton = (
    callback,
    icon,
    label = "",
    isActive = false,
    styles = {}
  ) => (
    <RadioButton
      overrideStyles={styles}
      callback={callback}
      isActive={isActive}
      content={icon}
      label={label}
    />
  );

  return (
    <StyledNumberLine>
      <StyledNumberLineContent>
        {renderNumberLine()}
        {renderBottomBar(getTickDataById(currentTickId))}
      </StyledNumberLineContent>

      <Sidebar
        data={data}
        settings={settings}
        selectJumpImg={selectJumpImg}
        callback={updateData}
        bulkUpdateCallback={bulkUpdateData}
        labelGenerator={getLabelValue}
        decimalSetting={decimalSetting}
        storeDraft={storeDraft}
        draftTarget={draftTarget}
      />
    </StyledNumberLine>
  );
};

export default PluginHoc({
  Component: NumberLine
});
