import React from "react";
import { connect } from "react-redux";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import ToolBar from "../ToolBar";
import { hasOneOperative } from "./Validators/operative";
import {
  WATCH_GET_LOCK_STATUS,
  WATCH_UNLOCK_POST,
  FORCE_UNLOCK_POST
} from "../../sagas/Locks/actions";
import {
  ROUTE_EXERCISES,
  ROUTE_RESOURCES,
  ROUTE_HELP_RESOURCES,
  ROUTE_AIDA_LIST
} from "../../routes";
import { createSaveableObject } from "../../sagas/PostDraft/helpers";
import { PrimaryButton, StandardButton } from "../../../shared";
import Input from "../../../shared/components/Input";
import {
  createJSONEditorPlugin,
  createMultipleChoicePlugin,
  createSortMachinePlugin,
  createOrderPlugin,
  createLineupPlugin,
  createChartPlugin,
  createFractionPlugin,
  createBlanksPlugin,
  createImagePlugin,
  createMultiImagePlugin,
  createAudioPlugin,
  createTextPlugin,
  createNumberLinePlugin,
  createVideoPlugin,
  createAboutPlugin,
  createHelpVideoPlugin,
  createMathWordPlugin,
  createHelpAudioPlugin,
  createHelpImagePlugin,
  createTipsPlugin,
  createAidaTextPlugin,
  createImageSliderPlugin
} from "../../../plugins";
import "../../components/Editor/editor.css";
import { Editor, findBlock } from "../../components/Editor";
import { nextItem, prevItem, setItems } from "../../store/carousel/actions";
import {
  modalUpdate,
  modalOpen,
  modalClose
} from "../../../shared/store/modal/actions";
import { PREVIEW_URL } from "../../api/constants";
import SimplePostList from "../../components/SimplePostList";
import { openMediaModal } from "../../../shared/components/Modal/MediaModal";
import { openDropzoneModal } from "../../../shared/components/Modal/DropZoneModal/DropzoneModal";
import {
  EditorWrapper,
  EditorBlock,
  BlockContent,
  PostInfoMarkup,
  LockBar,
  InvalidDataBar,
  StyledLayoutWrapper
} from "./StyledPostEditor";
import {
  ENTITIES,
  STRING,
  TITLE,
  DATA,
  EXERCISE,
  ENTITY_PLURAL,
  POST,
  MEDIA_RESOURCE,
  NUMBER,
  MEDIA,
  EXERCISES,
  CREATE,
  translation,
  RELOAD_DETAILED_EXERCISE,
  RELOAD_DETAILED_RESOURCE,
  RELOAD_DETAILED_AIDA,
  HELP_RESOURCE,
  RESOURCE,
  AIDA
} from "../../../constants";
import {
  StyledSaveResetButtonsContainer,
  StyledValidationError,
  StyledValidationErrorsContainer
} from "../../components/Editor/StyledEditor";
import {
  actionEditEntity,
  actionSavePostBeforeLeave,
  REGISTER_VALIDATOR,
  UNREGISTER_VALIDATOR,
  duplicatePost,
  reloadPost
} from "../../store/actions";
import {
  resetEntityDraft,
  saveEntityDraft,
  updateEntityDraft,
  storeEntityDraft,
  revertEntityDraft,
  setReplaceBlockOngoing
} from "../../sagas/PostDraft/actions";
import LayoutSelector from "../LayoutSelector/LayoutSelector";
import uuidv5 from "uuid";

import { addFlash } from "../../../shared";
import HasPermission, {
  hasPermissionBool,
  getSection
} from "../../../shared/Permissions";
import Checkbox from "../../../shared/components/Checkbox";
import AidaPreview from "../AidaPreview";

const plugins = [
  createJSONEditorPlugin(),
  createTextPlugin(),
  createAidaTextPlugin(),
  createImagePlugin(),
  createMultiImagePlugin(),
  createAudioPlugin(),
  createMultipleChoicePlugin(),
  createSortMachinePlugin(),
  createOrderPlugin(),
  createLineupPlugin(),
  createChartPlugin(),
  createFractionPlugin(),
  createBlanksPlugin(),
  createNumberLinePlugin(),
  createVideoPlugin(),
  createAboutPlugin(),
  createHelpVideoPlugin(),
  createMathWordPlugin(),
  createHelpAudioPlugin(),
  createHelpImagePlugin(),
  createTipsPlugin(),
  createImageSliderPlugin()
];

/**
 * Returns a data settings object
 * @param data
 * @param target
 * @param title
 * @returns {{data: *, target: *, title: *}}
 */
export const getEditorSettingsObj = (data, target, title) => ({
  data,
  target,
  title
});

class PostEditor extends React.PureComponent {
  static defaultProps = {
    post: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      saveIsDirty: false,
      validatorId: 2,
      validationErrors: [],
      prevPath: this.props.location.prev.search,
      backClicked: false
    };
  }

  componentDidMount() {
    this.registerValidator(
      "123abc",
      -1, // blockKey is not needed for this validator
      hasOneOperative.bind(this),
      "Ett block måste vara operativt"
    );
    document.addEventListener("keydown", this.onKeyDown);
    window.addEventListener(
      "beforeunload",
      () => this.props.postDraftState === "dirty" && this.revert()
    );

    // fetches servicebar data if needed
    let type;
    switch (this.props.type) {
      case ENTITIES[AIDA]:
        type = RELOAD_DETAILED_AIDA;
        break;
      case ENTITIES[RESOURCE]:
        type = RELOAD_DETAILED_RESOURCE;
        break;
      default:
        type = RELOAD_DETAILED_EXERCISE;
    }

    this.props.categories.toJS().length === 0 &&
      this.props.reloadServiceBarData(type);
  }

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.onKeyDown);
    this.props.postDraftState === "dirty" && this.revert();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.postDraft &&
      this.props.postDraft.data &&
      prevProps.postDraft &&
      prevProps.postDraft.data &&
      this.props.postDraft.blockKey === prevProps.postDraft.blockKey &&
      !this.props.replacingBlock
    ) {
      this.shouldLayoutUpdate(prevProps.postDraft.data.length);
    }

    if (!this.props.saving && this.state.backClicked) {
      this.goBackToPostList();
    }

    // If post was locked by another user reload page when lock is released to get eventual changes.
    if (
      this.props.lock &&
      prevProps.lock &&
      prevProps.lock !== this.props.lock
    ) {
      const prevUser = prevProps.lock.user_id
        ? prevProps.lock.user_id
        : undefined;
      const prevLockStatus = prevProps.lock.locked
        ? prevProps.lock.locked
        : undefined;

      const currentUser = this.props.userId;
      const currentLockStatus = this.props.lock.locked;

      if (prevUser !== currentUser && prevLockStatus && !currentLockStatus) {
        this.promptPostReload();
      }
    }
  }

  /**
   * Updates layout when number of blocks changes
   * @param prevNumberOfBlocks
   */
  shouldLayoutUpdate = prevNumberOfBlocks => {
    // check if number of blocks has changed
    if (this.props.postDraft.data.length !== prevNumberOfBlocks) {
      if (this.props.postDraft.data.length === 2) {
        this.updateLayout("3 : 3");
      } else if (this.props.postDraft.data.length === 3) {
        this.updateLayout("2 : 2 : 2");
      } else this.updateLayout("1 : 1 : 1");
    }
  };

  /**
   * Handles key events.
   * @param e
   */
  onKeyDown = e => {
    if (e.ctrlKey && e.altKey) {
      //Represents ctrl + d ( DUPLICATE )
      if (e.keyCode === 68) {
        const post = this.props.post && this.props.post.first();
        hasPermissionBool(getSection(post.type), CREATE, this.props.permissions)
          ? this.props.duplicatePost(post.id)
          : this.props.infoFlash(
              `Du har inga rättigheter för att duplicera en ${translation[
                post.type.toLowerCase()
              ].toLowerCase()}.`
            );
        e.preventDefault();
      }
      //Represents ctrl + s ( SAVE CHANGES )
      if (e.keyCode === 83) {
        this.props.postDraftState === "dirty" && this.props.title !== ""
          ? this.save()
          : this.props.infoFlash("Inga nya ändringar, går ej att spara.");
        e.preventDefault();
      }
      //Represents ctrl + z ( RESTORE CHANGES )
      if (e.keyCode === 90) {
        if (this.props.postDraftState !== "dirty") {
          this.props.infoFlash(
            "Inga nya ändringar finns, går ej att återställa."
          );
        }

        this.props.postDraftState === "dirty" &&
        this.props.postDraftList.length >= 1
          ? this.props.revertDraft()
          : this.revert();
        e.preventDefault();
      }
    }
  };

  /**
   * Register a new validator that runs on save.
   * @param id
   * @param blockKey
   * @param validator
   * @param errorMsg
   */
  registerValidator = (id, blockKey, validator, errorMsg) => {
    const error = "Failed to register validator";

    if (!id) throw new Error(`${error}. No ID was supplied`);
    if (typeof id !== STRING) throw new Error(`${error}. ID must be a string.`);

    if (!blockKey) throw new Error(`${error}. No block key was supplied`);
    if (typeof blockKey !== NUMBER)
      throw new Error(`${error}. Block key must be a number.`);

    if (!validator) throw new Error(`${error}. No validator was supplied.`);
    if (typeof validator !== "function")
      throw new Error(
        `${error}. Validator must be a function and return a boolean.`
      );

    if (!errorMsg) throw new Error(`${error}. No error message was suplied`);
    if (typeof errorMsg !== STRING)
      throw new Error(`${error}. Error msg must be a string.`);

    this.props.registerNewValidator({ id, blockKey, validator, errorMsg });
  };

  /**
   * Runs validation
   */
  getValidationData = () =>
    this.props.validators.map(({ blockKey, validator, errorMsg }) => {
      const { data, settings } =
        findBlock(blockKey, this.props.postDraft).block || {};

      return {
        valid: validator(data, settings),
        errorMsg
      };
    });
  /**
   * If a component is removed, it's validators will also be removed from the validators stack, so that we don't have
   * validators running from a component that no longer exists
   *
   * @param blockKey
   * @returns {*[]}
   */
  unregisterValidators = id => {
    this.props.unregisterValidator(id);
  };

  /**
   * Filters out validations that didn't pass
   * @param validationdata
   */
  getValidationErrors = validationdata =>
    validationdata.filter(({ valid }) => !valid);

  /**
   * Save if modified callback
   * @param callback
   * @param rest
   * @returns {function()}
   */
  saveIfModifiedCallback = (callback, ...rest) => () => {
    const entity_id = this.props.post.first().entity_id;
    this.props.clearCarousel();

    callback(entity_id, ...rest);
  };

  /**
   * Edit resource
   * @param id
   * @param entityType
   */
  editEntity = (id, entityType) =>
    this.promptSaveIfModified(
      this.saveIfModifiedCallback(this.props.editEntity, id, entityType)
    );

  /**
   * Revert
   */
  revert = () => {
    const { id } = this.props.post.first();
    this.props.resetDraft();

    if (id != null) {
      this.props.unlockPost(id);
    }
  };

  /**
   * Validates and save post
   */
  save = () => {
    const validationErrors = this.getValidationErrors(
      this.getValidationData().toJS()
    );
    if (validationErrors.length) {
      this.setState({ validationErrors });
      return;
    }

    const { id } = this.props.post.first();
    this.props.savePost();

    if (id != null) {
      this.props.unlockPost(id);
    }

    this.setState({
      validationErrors
    });
  };

  forceUnlock = lock => {
    const {
      author: { firstName, lastName }
    } = lock;
    const name = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;
    const { id } = this.props.post.first();

    const onOK = () => {
      this.props.forceUnlockPost(id);
    };

    const header = "Vill du verkligen låsa upp?";
    const children = (
      <div>
        Ändringarna som <span>{name}</span> gjort kommer att förloras.
      </div>
    );
    const actions = (
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <ButtonGroup>
          <StandardButton onClick={() => this.props.modalClose()}>
            Avbryt
          </StandardButton>
          <PrimaryButton
            onClick={() => {
              this.props.modalClose();
              onOK();
            }}
          >
            Lås upp
          </PrimaryButton>
        </ButtonGroup>
      </div>
    );
    this.props.modalOpen({
      children,
      header,
      actions,
      closeModal: this.props.modalClose
    });
  };

  promptPostReload = () => {
    const onOK = () => {
      const { post } = this.props;
      const { id } = post.first();
      this.props.reloadPost(id);
    };

    const header = "Denna post har varit låst av en annan användare.";
    const children = (
      <div>
        För att ta del av de eventuella ändringar som gjorts behöver du ladda om
        posten.
      </div>
    );
    const actions = (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <ButtonGroup>
          <PrimaryButton
            onClick={() => {
              this.props.modalClose();
              onOK();
            }}
          >
            Ladda om
          </PrimaryButton>
        </ButtonGroup>
      </div>
    );
    this.props.modalOpen({
      children,
      header,
      actions,
      closeModal: this.props.modalClose
    });
  };

  /**
   * Save before leave
   */
  saveBeforeLeave = backClicked => {
    const saveableObj = this.createSaveableObject();
    const { id } = saveableObj;

    if (id !== null) {
      this.props.savePostBeforeLeave(saveableObj);
      if (!backClicked) {
        this.props.unlockPost(id);
      }
    }
  };

  /**
   * Gathers neccessary data and composes an object based on it
   */
  createSaveableObject = () => {
    const { post, postDraft, helpDraft, title, resources } = this.props;
    const { id, difficulty, entity_id } = post.first();
    return createSaveableObject(
      id,
      entity_id,
      title,
      difficulty,
      resources,
      postDraft,
      helpDraft
    );
  };

  /**
   * Prompt save if modified
   * @param callback
   */
  promptSaveIfModified = (callback, backClicked = false) => {
    if (this.props.postDraftState === "dirty") {
      const header = "Vill du spara ändringarna?";
      const children = (
        <div>
          Ändringarna som du gjort kommer att förloras om du inte sparar.
        </div>
      );
      const actions = (
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <ButtonGroup>
            <StandardButton
              onClick={() => {
                this.props.modalClose();
                callback(true);
              }}
            >
              Spara inte
            </StandardButton>
            <StandardButton onClick={() => this.props.modalClose()}>
              Avbryt
            </StandardButton>
            <PrimaryButton
              onClick={() => {
                this.props.modalClose();
                this.saveBeforeLeave(backClicked);
                callback();
              }}
            >
              Spara
            </PrimaryButton>
          </ButtonGroup>
        </div>
      );
      this.props.modalOpen({
        children,
        header,
        actions,
        closeModal: this.props.modalClose
      });
    } else {
      callback();
    }
  };

  /**
   * Extracts post id
   */
  getPostID = () => this.props.post.get("data").id;

  /**
   * On change
   * @param pluginCategory
   * @param postData
   */
  onChange = pluginCategory => postData => {
    if (this.isLockedByOtherUser()) {
      return;
    }

    const { lock } = this.props;
    const lockAttemptMade = lock && lock.locked;

    if (!lockAttemptMade && this.getPostID()) {
      this.props.getPostLockStatus(this.props.post.first().id);
    }

    this.props.onChange(pluginCategory, postData);
  };

  /**
   * On resource media
   * @param resource
   */
  onResourceAdded = resource =>
    this.props.onChange("resources", {
      ...this.props.resources,
      [resource.id]: resource
    });

  openPostModal = (type, header, callback) => {
    if (this.isLockedByOtherUser()) {
      return;
    }
    let selectedIds = [];

    const onSelect = selected => {
      selectedIds = selected.map(r => r.id);

      this.props.modalUpdate({
        actions: actions(selectedIds.length > 0 ? false : true)
      });
    };

    const children = (
      <SimplePostList
        isModal={true}
        type={type}
        onSelect={onSelect}
        singleSelect={true}
      />
    );

    const actions = isDisabled => {
      return (
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <ButtonGroup>
            <StandardButton onClick={this.props.modalClose}>
              Avbryt
            </StandardButton>

            <PrimaryButton
              disabled={isDisabled}
              onClick={() => {
                this.props.modalClose();
                callback(selectedIds);
              }}
            >
              Infoga
            </PrimaryButton>
          </ButtonGroup>
        </div>
      );
    };

    this.props.modalOpen({
      header,
      children,
      actions: actions(selectedIds.length > 0 ? false : true),
      closeModal: this.props.modalClose
    });
  };

  /**
   * Open media resource modal
   * @param callback
   */
  openMediaresourceModal = (callback, conditions, title = null) => {
    if (this.isLockedByOtherUser()) {
      return;
    }

    const isActive = this.props.isActive;
    const callbacks = {
      onOK: callback,
      onCancel: () => {
        callback([]);
      }
    };
    return this.props.openMediaModal(
      callbacks,
      conditions,
      MEDIA_RESOURCE,
      title,
      isActive
    );
  };

  /**
   * Open media modal
   * @param callback
   * @param conditions
   */
  openMediaModal = (callback, conditions) => {
    if (this.isLockedByOtherUser()) {
      return;
    }

    const callbacks = {
      onOK: callback,
      onCancel: () => {
        callback([]);
      }
    };
    return this.props.openMediaModal(callbacks, conditions, MEDIA);
  };

  /**
   * Open drop-zone modal
   * @param callback
   */
  openDropzoneModal = callback => {
    if (this.isLockedByOtherUser()) {
      return;
    }
    const entityId = this.props.location.payload.entityId;
    return this.props.openDropzoneModal({
      onOK: callback,
      onCancel: () => {
        callback([]);
      },
      entityId
    });
  };

  /**
   * On title change
   * @param title
   */
  onTitleChange = title => {
    this.props.storeDraft(TITLE);
    return this.onChange(TITLE)(title);
  };

  /**
   * On pagination click
   * @param direction
   */
  onPaginationClick = direction => {
    this.promptSaveIfModified(() => {
      const { post, location } = this.props;
      const selectedPostIDs = location.query ? location.query.c || [] : [];
      const { entityId, query, id: activePostID } = location.payload;
      const activePostIndex = selectedPostIDs.findIndex(
        id => +id === +activePostID
      );
      const nextPostID =
        selectedPostIDs[activePostIndex + (direction === "next" ? 1 : -1)];
      if (nextPostID) {
        // all selected posts will be of same type, so just use the type from the current post.
        const entityType = ((post && post.first()) || {}).type || "exercise";
        this.props.editEntity(entityId, nextPostID, entityType, {
          ...query,
          c: selectedPostIDs
        });
        this.props.unlockPost(activePostID);
      }
    });
  };

  /**
   * Returns previous URL if there is one, otherwise to origin list of currently edited Post
   * @param post
   * @param path
   * @returns {string}
   */
  getPrevUrl = (post, path) => {
    const q = this.state.prevPath;
    const query = q && q !== "" ? `?${q}` : "";

    const pathToPrev =
      post.type === AIDA
        ? `/system/${post.entity_id}/${path + query}`
        : `/products/${post.entity_id}/${path + query}`;

    return pathToPrev;
  };

  updateLayout = selectedLayout => {
    const newPost = {
      ...this.props.postDraft,
      settings: {
        ...this.props.postDraft.settings,
        layout: selectedLayout.replace(/ /g, "").split(":") // Remove spaces and split at ':'
      }
    };
    this.props.storeDraft(POST);
    this.onChange("post")(newPost);
  };

  updateTextSetting = e => {
    const { checked } = e.target;
    const { settings } = this.props.postDraft;

    const newPost = {
      ...this.props.postDraft,
      settings: {
        ...settings,
        minimizeSpace: checked
      }
    };
    this.props.storeDraft(POST);
    this.onChange("post")(newPost);
  };

  renderPreviewButton = (id, hasOperative) =>
    hasOperative ? (
      <a target="_blank" href={PREVIEW_URL + id} rel="noopener noreferrer">
        <StandardButton studlicon="Preview">Förhandsgranska</StandardButton>
      </a>
    ) : (
      <StandardButton studlicon="Preview" onClick={this.promptNotOperative}>
        Förhandsgranska
      </StandardButton>
    );

  renderAidaPreviewButton = id => <AidaPreview id={id} btnType="detail" />;

  promptNotOperative = () => {
    this.props.infoFlash(
      "Förhandsgranskning kräver att övningen innehåller en operativ komponent.\nVänligen lägg till en operativ komponent för att fortsätta."
    );
  };

  promptSaveOnBackClick = () => {
    if (this.props.postDraftState === "dirty") {
      this.promptSaveIfModified((notSaved = false) => {
        if (notSaved) {
          this.goBackToPostList();
        }

        this.setState({
          backClicked: true
        });
      }, true);

      return false;
    }
  };

  goBackToPostList = () => {
    const location = this.props.location;
    const query = location.prev.query;
    const { post } = this.props;
    const { entity_id, type } = post.first();

    let queryObj = {};
    if (query) {
      Object.keys(query).forEach(key => {
        if (query[key] !== undefined) {
          queryObj[key] = query[key];
        }
      });
    }

    let route = "";
    switch (type.toLowerCase()) {
      case ENTITIES[EXERCISE].toLowerCase():
        route = ROUTE_EXERCISES;
        break;
      case ENTITIES[RESOURCE].toLowerCase():
        route = ROUTE_RESOURCES;
        break;
      case ENTITIES[HELP_RESOURCE].toLowerCase():
        route = ROUTE_HELP_RESOURCES;
        break;
      case ENTITIES[AIDA].toLowerCase():
        route = ROUTE_AIDA_LIST;
        break;
      default:
        break;
    }

    this.props.goToPosts(route, entity_id, queryObj);
  };

  duplicatePostCallback = id => () => this.props.duplicatePost(id);

  renderDuplicateButton = id =>
    this.props.postDraftState !== "dirty" && !this.isLockedByOtherUser() ? (
      <StandardButton onClick={this.duplicatePostCallback(id)} studlicon="Copy">
        Duplicera
      </StandardButton>
    ) : null;

  /**
   * Renders preview button
   * @param post
   * @returns {null}
   */
  renderButtonGroup = (post, operative) =>
    post && post.id && (post.type === "exercise" || post.type === "aida") ? (
      <ButtonGroup>
        <HasPermission
          component={this.renderDuplicateButton(post.id)}
          section={EXERCISES}
          permission={CREATE}
        />
        {post.type === "aida"
          ? this.renderAidaPreviewButton(post.id)
          : this.renderPreviewButton(post.id, operative)}
      </ButtonGroup>
    ) : null;

  /**
   * Renders validation errors
   * @returns {null}
   */
  renderValidationErrors = () =>
    this.state.validationErrors.length
      ? this.state.validationErrors.map(({ errorMsg, blockKey, id }, index) => {
          return (
            <StyledValidationError key={uuidv5()}>
              <span>{`${index + 1}. ${errorMsg}`}</span>
            </StyledValidationError>
          );
        })
      : null;

  /**
   * Renders save button
   * @returns {*}
   */
  renderSaveButton = () =>
    this.props.title !== "" ? (
      <StyledValidationErrorsContainer>
        <PrimaryButton onClick={this.save}>Spara</PrimaryButton>

        <div>{this.renderValidationErrors()}</div>
      </StyledValidationErrorsContainer>
    ) : null;

  /**
   * Renders save reset buttons
   * @returns {null}
   */
  renderSaveResetButtons = () =>
    this.props.postDraftState === "dirty" ? (
      <StyledSaveResetButtonsContainer>
        <ButtonGroup>
          <StandardButton onClick={this.revert}>Återställ</StandardButton>

          {this.renderSaveButton()}
        </ButtonGroup>
      </StyledSaveResetButtonsContainer>
    ) : null;

  /**
   * Renders the editor
   * @param postData
   * @param post
   * @param dropDownData
   * @returns {null}
   */
  renderEditor = (postData, post, { data, title, target }) =>
    this.props.postDraft ? (
      <EditorBlock key={target}>
        {post.type === "exercise" && target !== "help" ? (
          <StyledLayoutWrapper>
            <LayoutSelector
              numberOfBlocks={this.props.postDraft.data.length}
              selectedFormat={
                this.props.postDraft.settings &&
                this.props.postDraft.settings.layout &&
                this.props.postDraft.settings.layout
                  .toString()
                  .replace(/,/g, ":")
              }
              callback={this.updateLayout}
            />
            <Checkbox
              label={"Minska textyta"}
              isChecked={
                (this.props.postDraft.settings &&
                  this.props.postDraft.settings.minimizeSpace) ||
                false
              }
              onChange={this.updateTextSetting}
              checkBoxColor={"#333333"}
              labelColor={"#333333"}
            />
          </StyledLayoutWrapper>
        ) : null}
        <BlockContent>
          <Editor
            post={post}
            registerValidator={this.registerValidator}
            savePost={this.save}
            editEntity={this.editEntity}
            postData={postData}
            target={target}
            storeDraft={this.props.storeDraft}
            resources={this.props.resources}
            onChange={this.onChange(target)}
            replacingBlock={this.props.setReplaceBlockOngoing}
            onResourceAdded={this.onResourceAdded}
            openMediaModal={this.openMediaModal}
            openDropzoneModal={this.openDropzoneModal}
            openPostModal={this.openPostModal}
            openMediaresourceModal={this.openMediaresourceModal}
            plugins={plugins}
            dropDownData={{ data, title }}
            readOnly={this.isLockedByOtherUser()}
            disabledAddPlugin={this.disableAddPlugin()}
            unregisterValidators={this.unregisterValidators}
            infoFlash={this.props.infoFlash}
            errorFlash={this.props.errorFlash}
            keyboards={this.props.keyboards}
          />
        </BlockContent>
      </EditorBlock>
    ) : null;

  /**
   * Disables the plugin if it has a limit and that limit is exceeded
   * @returns {boolean}
   */
  disableAddPlugin = () =>
    this.isLockedByOtherUser() ||
    (this.pluginLimit() &&
      this.props.postDraft.data.length >= this.pluginLimit());

  /**
   * Checks if the post is locked by another user
   * @returns {boolean}
   */
  isLockedByOtherUser = () => {
    const { lock, userId } = this.props;
    return lock && lock.author && lock.author.id !== userId;
  };

  /**
   * Returns false or a limit based on whether what type of post is being edited
   * @returns {*}
   */
  pluginLimit = () =>
    this.props.post.get(DATA).type === EXERCISE ? false : 10;

  /**
   * Gets the path
   * @param type
   * @returns {*}
   */
  getPath = type => (type ? ENTITY_PLURAL[type] : ENTITY_PLURAL[EXERCISE]);

  /**
   * Renders post markup
   * @returns {*}
   */
  renderPostMarkup = post => (
    <PostInfoMarkup>
      <span>#{(post && post.id) || 0}</span>
      <Input
        id="title"
        name="title"
        value={this.props.title}
        onChange={this.onTitleChange}
      />
    </PostInfoMarkup>
  );

  /**
   * Render editors based on what is supplied from view
   * @param editors
   * @param post
   */
  renderEditors = (editors, post) =>
    editors.map(({ target, ...rest }) =>
      this.renderEditor(this.props[`${target}Draft`], post, { target, ...rest })
    );

  /**
   * Renders lock bar
   * @param lock
   * @returns {*}
   */
  renderLockBar = lock => {
    if (this.isLockedByOtherUser()) {
      const {
        author: { firstName, lastName }
      } = lock;
      return (
        <LockBar>
          <div>{`POST LÅST: REDIGERAS AV ${firstName.toUpperCase()} ${lastName.toUpperCase()}`}</div>
          <StandardButton onClick={this.forceUnlock.bind(this, lock)}>
            Lås upp
          </StandardButton>
        </LockBar>
      );
    }
  };

  renderInvalidDataMessage = invalid => {
    return invalid ? (
      <InvalidDataBar>
        <div>{`FELAKTIG DATA, Försök att spara om posten.`}</div>
      </InvalidDataBar>
    ) : null;
  };

  render() {
    const {
      showCarousel,
      postDraft,
      lock,
      userId,
      editorSettings: { editors }
    } = this.props;
    const post = this.props.post && this.props.post.first();

    if (!post || !postDraft) return null;

    const path = this.getPath(post.type);

    const selectedPostIDs = this.props.location.query
      ? this.props.location.query.c || []
      : [];

    const operative = postDraft.data.some(
      elem => elem.settings && elem.settings.operative
    );

    return (
      <div>
        <ToolBar
          breadcrumb={{
            href: this.getPrevUrl(post, path),
            content: "Tillbaka"
          }}
          onBackClick={this.promptSaveOnBackClick}
          paginationClick={this.onPaginationClick}
          postIDsInCarousel={selectedPostIDs}
          activePostID={post.id}
          showCarousel={showCarousel}
          postTitle={this.renderPostMarkup(post)}
          isSaved={true}
          titleChange={this.onTitleChange}
          lock={lock}
          userId={userId}
        >
          {this.renderButtonGroup(post, operative)}
        </ToolBar>
        {this.renderInvalidDataMessage(post.invalid_data)}
        {this.renderLockBar(lock)}
        <EditorWrapper>
          {this.renderEditors(editors, post, postDraft)}
        </EditorWrapper>

        {this.renderSaveResetButtons()}
      </div>
    );
  }
}

const mapStateToProps = (state, { type = "Exercise" }) => {
  const post = state[type].get("selectedItem");
  const lock = post && post.get("data") ? post.get("data").lock : null;

  return {
    post,
    resources: state[type].getIn(["postDraft", "resources"]),
    title: state[type].getIn(["postDraft", "title"]),
    postDraft: state[type].getIn(["postDraft", "post"]),
    helpDraft: state[type].getIn(["postDraft", "help"]),
    postDraftState: state[type].getIn(["postDraft", "postDraftState"]),
    postDraftList: state[type].get("postDraftList"),
    revertTarget: state[type].get("revertTarget"),
    lock: lock,
    userId: state.viewer.get("author"),
    location: state.location,
    modal: state.modal,
    isActive: state.MediaCarousel["active"],
    showCarousel: state.Carousel.showCarousel,
    editorSettings:
      state.location.routesMap[state.location.type].editorSettings,
    validators: state[type].get("validators"),
    permissions: state.Product.get("permissions"),
    categories: state.Structure.get("list"),
    keyboards: state[type].get("keyboards"),
    replacingBlock: state[type].get("replacingBlock"),
    saving: state[type].get("saving")
  };
};

const mapDispatchToProps = (dispatch, { type = ENTITIES[EXERCISE] }) => {
  return {
    savePostBeforeLeave: ({ id: postId, ...rest }) =>
      dispatch(actionSavePostBeforeLeave(rest, postId, type)),
    savePost: () => dispatch(saveEntityDraft(type)),
    duplicatePost: id => dispatch(duplicatePost(id, type)),
    createPost: () => dispatch(saveEntityDraft(type)),
    editEntity: (entityId, id, entityType, query) =>
      dispatch(actionEditEntity(entityId, id, entityType, query)),
    openMediaModal: openMediaModal(dispatch),
    openDropzoneModal: openDropzoneModal.bind(null, dispatch),
    modalUpdate: modalUpdate(dispatch),
    modalOpen: modalOpen(dispatch),
    modalClose: () => modalClose(dispatch),
    nextPost: () => nextItem()(dispatch),
    prevPost: () => prevItem()(dispatch),
    clearCarousel: () => setItems([])(dispatch),
    onChange: (key, value) => dispatch(updateEntityDraft(type, key, value)),
    storeDraft: key => dispatch(storeEntityDraft(type, key)),
    revertDraft: () => dispatch(revertEntityDraft(type)),
    resetDraft: () => dispatch(resetEntityDraft(type)),
    getPostLockStatus: postId =>
      dispatch({ type: WATCH_GET_LOCK_STATUS, postId }),
    unlockPost: postId => dispatch({ type: WATCH_UNLOCK_POST, postId }),
    forceUnlockPost: postId => dispatch({ type: FORCE_UNLOCK_POST, postId }),
    infoFlash: msg => dispatch(addFlash(msg)),
    errorFlash: msg => dispatch(addFlash(msg, "error")),
    registerNewValidator: validator =>
      dispatch({ type: REGISTER_VALIDATOR, entityType: type, validator }),
    unregisterValidator: id =>
      dispatch({ type: UNREGISTER_VALIDATOR, entityType: type, id }),
    reloadServiceBarData: action => dispatch({ type: action }),
    reloadPost: postId => dispatch(reloadPost(type, postId)),
    setReplaceBlockOngoing: value =>
      dispatch(setReplaceBlockOngoing(type, value)),
    goToPosts: (route, entityId, queryObj) =>
      dispatch({
        type: route,
        payload: { entityId: entityId },
        query: queryObj
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostEditor);
