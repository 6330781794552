import React, { useState, useEffect } from "react";
import {
  StyledInputWrapper,
  StyledSearchResult,
  StyledSearchResultItem
} from "./StyledSeriesSearch";
import Input from "../../../shared/components/Input";
import connect from "react-redux/es/connect/connect";
import useVisible from "../../../shared/effects/useVisible";
import useKeyNavigation from "../../../shared/effects/useKeyNavigation";

const SeriesSearch = ({
  series,
  selectedItem,
  className,
  placeholder = "Sök serier...",
  onSelect
}) => {
  const [searchBase, setSearchBase] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const onEnter = () => {
    setIsVisible(false);
    setSearchValue("");
    if (onSelect) {
      onSelect(searchResult[currentNodeIndex]);
    }
  };
  const { itemsRef, currentNodeIndex } = useKeyNavigation(
    ref,
    searchResult,
    onEnter
  );

  useEffect(() => {
    const filteredSeries = series.filter(s => s.id !== selectedItem.id);
    const base = filteredSeries.map(p => {
      p.search = `${p.title}`.toLowerCase();
      return p;
    });
    setSearchBase(base);
  }, [series, selectedItem, setSearchBase]);

  const filterBase = value =>
    searchBase.filter(b => b.search.includes(value.toLowerCase()));

  const onChange = newValue => {
    setSearchValue(newValue);
    const s = newValue.length > 0 ? filterBase(newValue) : [];
    setSearchResult(s);
    setIsVisible(s.length > 0);
  };

  const onFocus = e => {
    setIsVisible(e.target.value.length > 0);
  };

  const onSeriesSelect = entity => {
    if (onSelect) {
      onSelect(entity);
    }

    setIsVisible(false);
    setSearchValue("");
  };

  return (
    <StyledInputWrapper ref={ref} className={className}>
      <Input
        onChange={onChange}
        onFocus={onFocus}
        value={searchValue}
        placeholder={placeholder}
        autoComplete="off"
        type="search"
      />
      {isVisible && (
        <StyledSearchResult>
          {searchResult.map((value, index) => (
            <StyledSearchResultItem
              key={value.id}
              ref={el => (itemsRef.current[index] = el)}
              onClick={() => onSeriesSelect(value)}
              className={currentNodeIndex === index ? "active" : ""}
            >
              {value.title}
            </StyledSearchResultItem>
          ))}
        </StyledSearchResult>
      )}
    </StyledInputWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  series: props.selector
    ? props.selector(state)
    : state.Series.get("list").toArray(),
  selectedItem: state.Series.get("selectedItem")
});

export default connect(mapStateToProps)(SeriesSearch);
