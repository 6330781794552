import styled from "styled-components";

export const StyledCommentSection = styled.div``;

export const StyledNoComments = styled.div`
  font-size: 11px;
  color: ${props => props.theme.neutralTertiary};
`;

export const StyledCommentList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const StyledCommentItem = styled.li`
  padding: 5px 0;
`;

export const StyledCommentUser = styled.span`
  font-size: 12px;
  color: #404040;
  font-weight: 500;
`;

export const StyledCommentTime = styled.span`
  font-size: 11px;
`;

export const StyledCommentMessage = styled.div`
  font-size: 12px;
`;

export const StyledInputWrapper = styled.div`
  margin-top: 15px;
  input {
    font-size: 12px;
    width: 100%;
  }
`;
