import React from "react";

const MarkerUpload = ({ size }) => (
  <svg
    xmlns=" https://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-markerupload"
  >
     <path d="M26.0351562,13.2329932 C25.1202257,8.68579932 21.0434028,5.27210884 16.1458333,5.27210884 C12.2573785,5.27210884 8.88020833,7.43367347 7.19835069,10.5969388 C3.1484375,11.0187075 0,14.3796769 0,18.452381 C0,22.815051 3.61935764,26.3605442 8.07291667,26.3605442 L25.5642361,26.3605442 C29.2777778,26.3605442 32.2916667,23.4081633 32.2916667,19.7704082 C32.2916667,16.2908163 29.5334201,13.4702381 26.0351562,13.2329932 Z M18.8368056,17.1343537 L18.8368056,22.4064626 L13.4548611,22.4064626 L13.4548611,17.1343537 L9.41840278,17.1343537 L16.1458333,10.5442177 L22.8732639,17.1343537 L18.8368056,17.1343537 Z" id="path-1"></path>
  </svg>
);

export default MarkerUpload;