import React from "react";
import styled from "styled-components";

import HeaderSection from "./Section";
import LogoHome from "./LogoHome";
import Link from "../Link";
import { useDispatch } from "react-redux";
import {
  setIfProductSelectedMessageVisible,
  setSelectedProductId
} from "../../store/navigation/actions";

const Text = styled.span`
  ${props => props.theme.d300()} color: #fff;
  font-weight: 700;
  align-self: center;
  margin-top: 0;
  padding: 0 ${props => props.theme.baseline} 0 0;
  z-index: 1;
`;

const TitleText = styled.span`
  color: #fff;
  font-size: 28px;
  letter-spacing: 0.04em;
  font-weight: 700;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
`;

const Title = ({ children, to }) => {
  const dispatch = useDispatch();
  const resetSelectProductMessage = () => {
    dispatch(setIfProductSelectedMessageVisible(false));
    dispatch(setSelectedProductId(null));
  };
  return (
    <HeaderSection start={1} shrink={1}>
      <Text>
        <Link to={to ? to : "#"} onClick={resetSelectProductMessage}>
          <LogoHome size={38} />
          <TitleText>{children}</TitleText>
        </Link>
      </Text>
    </HeaderSection>
  );
};

export default Title;
