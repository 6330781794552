import * as React from "react";
import { Input } from "../Input";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { Ellipsis, GripHandle } from "../../../shared/components/StudliIcons";
import {
  StyledItem,
  StyledItemContainer,
  StyledValueContentWrapper,
  StyledDragHandleWrapper,
  StyledItemEditText,
  StyledValueEditInputWrapper,
  StyledInteractiveWrapper,
  StyledRemoveImageButton,
  StyledGhostLabel
} from "./StyledFraction";
import { NUMERATOR, DENOMINATOR, RIGHT, ITEMS, INTERACTIVE, INTEGER, FRACTION_METHOD } from "../../../constants";
import { isErrorFree } from "./FractionData";
import ToggleButton from "./ToggleButton";
import DropDown, {Item} from "../../../shared/components/DropDown";
import Tooltip from "react-tooltip-lite";

export const FractionItem = SortableElement(
  ({ data, indx: index, updateContent, removeItemFn, methodType }) => {

    /**
     * Edit question value text
     * @param {*} v
     */
    const onValueChange = (type) => (value) => updateContent(ITEMS, { [type]: value.trim()}, index);

    /**
     * Update interactive item
     * @param {*} v
     */
    const onInteractiveToggle = (type) => () => updateContent(INTERACTIVE, { [type]: !data[type].interactive }, index);

    /**
     * Remove item
     */
    const onRemoveItem = () => removeItemFn(index);

    /**
     * Renders warning text
     * @param {*} text
     */
    const renderWarningText = (key, value) => {
      const { error } = isErrorFree(null, ITEMS, {key, value})

      return (
        error &&
        <Tooltip content={error} useDefaultStyles={true}>
          <StyledRemoveImageButton>
            {'!'}
          </StyledRemoveImageButton>
        </Tooltip>
      )
    }

    /**
     * render the edit text
     */
    const renderValueBox = () => (
      data !== undefined && (
        <StyledItemEditText>
          {renderInput('Heltal', data.integer.value, INTEGER)}
          {renderInteractiveToggle(data.integer.interactive, INTEGER)}
          {renderInput('Täljare', data.numerator.value, NUMERATOR)}
          {renderInteractiveToggle(data.numerator.interactive, NUMERATOR)}
          {renderInput('Nämnare', data.denominator.value, DENOMINATOR)}
          {renderInteractiveToggle(data.denominator.interactive, DENOMINATOR)}
        </StyledItemEditText>
      )
    )

    /**
     * Render interactive toggle button
     * @param {*} active
     * @param {*} key
     */
    const renderInteractiveToggle = (active, key) => (
      <StyledInteractiveWrapper>
        <ToggleButton title="Inputfält" isActive={active} callback={onInteractiveToggle(key)} />
      </StyledInteractiveWrapper>
    ) 

    /**
     * Render input
     * @param {*} placeholder
     * @param {*} value
     * @param {*} key
     */
    const renderInput = (placeholder, value, key) => (
      <StyledValueEditInputWrapper>
        {(data.ghost || data.simplified)
        ? <StyledGhostLabel>{String(value)}</StyledGhostLabel>
        : <Input
            placeholder={placeholder}
            value={value}
            onChange={onValueChange(key)}
        />}
        {renderWarningText(key, value)}
      </StyledValueEditInputWrapper>
    )

    /**
     * Render dropdown
     */
    const renderDropDown = (disabled) => (
      <DropDown
        disabled={disabled}
        title={<Ellipsis size={16} />}
        hasChevron={false}
        onChange={onRemoveItem}
        dropDownAlign={RIGHT}
        overrideStyles={{
          right: 0,
          display: "inline-block",
          minWidth: "inherit",
          border: "none",
          alignSelf: "center"
        }}
      >
        <Item>Ta bort</Item>
      </DropDown>
    );

    /**
     * Returns the itemtype for styling purposes
     */
    const getItemType = ({ghost, simplified}) => {
      if(ghost) return 'ghost'
      if(simplified) return 'simplified'
      return 'default'
    }

    return (
      <StyledItem type={getItemType({ghost: data.ghost, simplified: data.simplified})}>
        <StyledDragHandleWrapper>
          {(data.ghost || data.simplified) ? <GripHandle size={16} /> : <DragHandle />}
        </StyledDragHandleWrapper>
        <StyledValueContentWrapper>
          {renderValueBox()}
        </StyledValueContentWrapper>
        {methodType === FRACTION_METHOD && renderDropDown((data.ghost || data.simplified))}
      </StyledItem>
    );
  }
);

export const ItemContainer = SortableContainer(({ children }) => {
  return <StyledItemContainer>{children}</StyledItemContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);
