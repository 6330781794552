export * from "./imageEditorActions";

export const LOAD_MORE_POSTS = "LOAD_MORE_POSTS";
export const LOAD_LESS_POSTS = "LOAD_LESS_POSTS";
export const LOAD_POST_PAGE = "LOAD_POST_PAGE";
export const RELOAD_POSTS = "RELOAD_POSTS";
export const RELOAD_POST = "RELOAD_POST";
export const SORT_POSTS = "SORT_POSTS";
export const FETCH_FILTER = "FETCH_FILTER";
export const FILTER_POSTS = "FILTER_POSTS";
export const POST_ADD_REMOVE_TAGS = "POST_ADD_REMOVE_TAGS";
export const POST_SET_CATEGORY = "POST_SET_CATEGORY";
export const PUBLISH_POSTS = "PUBLISH_POSTS";
export const UNPUBLISH_POSTS = "UNPUBLISH_POSTS";
export const DELETE_POSTS = "DELETE_POSTS";
export const DUPLICATE_POST = "DUPLICATE_POST";
export const SAVE_POST = "SAVE_POST";
export const ADD_COMMENT = "ADD_COMMENT";

export const loadPosts = postType => ({
  type: postType.toUpperCase() + "/" + LOAD_MORE_POSTS
});

export const loadLessPosts = postType => ({
  type: postType.toUpperCase() + "/" + LOAD_LESS_POSTS
});

export const loadPage = (postType, page) => ({
  type: postType.toUpperCase() + "/" + LOAD_POST_PAGE,
  page
});

export const reloadPosts = postType => ({
  type: postType.toUpperCase() + "/" + RELOAD_POSTS
});

export const reloadPost = (postType, postId) => ({
  type: postType.toUpperCase() + "/" + RELOAD_POST,
  postId
});

export const sortPosts = (postType, sort) => ({
  type: postType.toUpperCase() + "/" + SORT_POSTS,
  sort
});

export const filterPosts = (postType, filter) => ({
  type: postType.toUpperCase() + "/" + FILTER_POSTS,
  filter
});

export const fetchFilters = (postType, filters) => ({
  type: postType.toUpperCase() + "/" + FETCH_FILTER,
  filters,
  entityName: postType
});

export const addRemoveTagsToPost = (postType, add, remove, detailpage) => ({
  type: postType.toUpperCase() + "/" + POST_ADD_REMOVE_TAGS,
  add,
  remove,
  detailpage
});

export const setCategoryToPosts = (postIds, catId, useCurrentPost) => ({
  type: POST_SET_CATEGORY,
  postIds,
  catId,
  useCurrentPost
});

export const publishPosts = postIds => ({
  type: PUBLISH_POSTS,
  postIds
});

export const unpublishPosts = postIds => ({
  type: UNPUBLISH_POSTS,
  postIds
});

export const deletePosts = postIds => ({
  type: DELETE_POSTS,
  postIds
});

export const duplicatePost = (postId, postType) => ({
  type: DUPLICATE_POST,
  postType: postType,
  postId
});

export const addComment = (postType, comment) => ({
  type: postType.toUpperCase() + "/" + ADD_COMMENT,
  comment
});
