import React from "react";
import styled from "styled-components";

import ContentHeader from "./Header";

const ContentBody = styled.section`
  padding: 0 22px;
  height: auto;
`;

const ContentFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;
  padding: 22px;
`;

const Card = ({ className, header, children, actions, modalClose }) => (
  <div className={className}>
    <ContentHeader header={header} modalClose={modalClose} />
    <ContentBody>{children}</ContentBody>
    <ContentFooter>{actions}</ContentFooter>
  </div>
);

export default styled(Card)`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  min-width: 640px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.25s ease 0s;
  ${props =>
    props.visible
      ? `
    opacity: 1;
  `
      : null};
`;
