import * as React from "react";
import {
  StyledTickMarkerContainer,
  StyledTickMarker,
  StyledTickLabelContainer,
  StyledTickMarkBg,
  StyledTickImageContainer,
  StyledTickImage,
  StyledRemoveImgButton,
  StyledSliderLabel,
  StyledSliderLabelText
} from "./StyledSliderTick";
import Tooltip from "react-tooltip-lite";
import { Close } from "../../../../shared/components/StudliIcons";
import AutoLaTeX from "react-autolatex";

export const SliderTick = ({
  id,
  label = "",
  icons = [],
  isSelected,
  isActive,
  callback,
  showLabel,
  isFirst,
  isLast,
  imgUrl,
  removeImgCallback
}) => {
  /**
   * Handles on tick click
   * @returns {*}
   */
  const onTickClick = () => callback(id);

  /**
   * Handles click on remove image button
   * @returns {*}
   */
  const onImgRemoveClick = () => removeImgCallback(id);

  /**
   * Renders slider tickk label
   * @returns {*}
   */
  const renderTickTextLabel = () => (
    <StyledSliderLabel>
      <StyledSliderLabelText>
        <AutoLaTeX>{label}</AutoLaTeX>
      </StyledSliderLabelText>
    </StyledSliderLabel>
  );

  /**
   * Renders tick mark
   * @returns {*}
   */
  const renderTickMark = () => (
    <StyledTickMarkBg isFirst={isFirst} isLast={isLast}>
      <Tooltip content={<AutoLaTeX>{label}</AutoLaTeX>} useDefaultStyles={true}>
        <StyledTickMarker
          onClick={onTickClick}
          selected={isSelected}
          active={isActive}
        />
      </Tooltip>
    </StyledTickMarkBg>
  );

  /**
   * Renders tick label
   * @returns {null}
   */
  const renderTickLabel = () => {
    var filename = imgUrl
      ? imgUrl.replace(/^.*[\\/]/, "")
      : "Kunde inte hämta filnamnet";

    return imgUrl ? (
      <Tooltip content={filename} tipContentHover useDefaultStyles={true}>
        <StyledTickImageContainer>
          <StyledTickImage src={imgUrl} alt="Tick image" />
          <StyledRemoveImgButton onClick={onImgRemoveClick}>
            <Close size={"16"} />
          </StyledRemoveImgButton>
        </StyledTickImageContainer>
      </Tooltip>
    ) : (
      renderTickTextLabel()
    );
  };

  return (
    <StyledTickMarkerContainer>
      {showLabel && renderTickLabel()}

      {renderTickMark()}

      <StyledTickLabelContainer>{icons}</StyledTickLabelContainer>
    </StyledTickMarkerContainer>
  );
};
