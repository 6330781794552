import React from "react";
import { PageHeader } from "../../../shared";
import { ENTITY_PLURAL, MEDIA_RESOURCE, translation } from "../../../constants";
import MediaShell from "../../../media/views/MediaShell/MediaShell";

const mediaresource = MEDIA_RESOURCE.toLowerCase();
const mediaresources = ENTITY_PLURAL[mediaresource].toLowerCase();

const Resources = () => (
  <React.Fragment>
    <PageHeader
      studlicon="ImageResourceIcon"
      title={translation[mediaresources]}
    />
    <MediaShell section={mediaresource} />
  </React.Fragment>
);

export default Resources;
