import React from "react";
import { StyledToolBarBtn } from "./StyledToolbar";

export const ToolBarBtn = ({ value, onClick, children, disabled, ...rest }) => {
  const _onClick = () => onClick(value);
  const label = rest.studlicon || rest.icon ? "" : value;

  return (
    <StyledToolBarBtn onClick={_onClick} {...rest} disabled={disabled}>
      {label}
    </StyledToolBarBtn>
  );
};
