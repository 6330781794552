export const SET_CAROUSEL = "SET_CAROUSEL";
export const NEXT_IN_CAROUSEL = "NEXT_IN_CAROUSEL";
export const PREV_IN_CAROUSEL = "PREV_IN_CAROUSEL";
export const FLAG_SHOW_CAROUSEL = "FLAG_SHOW_CAROUSEL";

export const nextItem = () => dispatch =>
  dispatch({
    type: NEXT_IN_CAROUSEL
  });

export const prevItem = () => dispatch =>
  dispatch({
    type: PREV_IN_CAROUSEL
  });

export const setItems = items => dispatch =>
  dispatch({
    type: SET_CAROUSEL,
    items,
    active: items[0] || null
  });

export const showCarousel = bool => dispatch =>
  dispatch({
    type: FLAG_SHOW_CAROUSEL,
    bool
  });
