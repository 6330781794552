import React from "react";
import PostEditor from "../../containers/PostEditor";

const PostDetails = () => (
  <React.Fragment>
    <PostEditor />
  </React.Fragment>
);

export default PostDetails;
