import React from "react";

const Pencil = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-pencil"
  >
    <path d="M48.1694336,5.48109162 L44.5083008,1.82709552 C42.0675781,-0.608869396 38.1103516,-0.608966862 35.6694336,1.82709552 L1.25371094,36.1756335 L0.0147460937,47.3046784 C-0.151074219,48.7945419 1.11044922,50.0535088 2.60302734,49.8879142 L13.7538086,48.6513645 L48.1695312,14.302729 C50.6101562,11.8666667 50.6101562,7.91705653 48.1694336,5.48109162 Z M38.8729492,19.1701754 L16.9921875,41.0082846 L16.9921875,37.6218324 L12.3046875,37.6218324 L12.3046875,32.9434698 L8.91162109,32.9434698 L30.7923828,11.1053606 L38.8729492,19.1701754 Z M12.319043,45.6723197 L6.5578125,46.3112086 L3.59833984,43.3575049 L4.23847656,37.6075049 L6.17724609,35.6725146 L9.5703125,35.6725146 L9.5703125,40.3508772 L14.2578125,40.3508772 L14.2578125,43.7373294 L12.319043,45.6723197 Z M45.959668,12.097271 L41.0826172,16.9648148 L33.0020508,8.9 L37.8791016,4.03245614 C39.100293,2.81364522 41.0771484,2.81345029 42.2985352,4.03245614 L45.959668,7.68645224 C47.180957,8.90536062 47.1810547,10.8781676 45.959668,12.097271 Z" />
  </svg>
);

export default Pencil;
