import {
  translation,
  DEFAULT,
  WIDE,
  EQUATION,
  STANDARD,
  CHART_TYPE,
  OPERATIVE,
  M,
  TYPE,
  LAYOUT,
  VERY_WIDE,
  SHOW_BORDERS,
  S,
  XL
} from "../../../constants";

export const rowSettingStyles = {
  [CHART_TYPE]: WIDE,
  [OPERATIVE]: DEFAULT,
  [SHOW_BORDERS]: VERY_WIDE
};

export const chartTypeData = [
  { id: 0, label: "Standard", value: STANDARD },
  { id: 1, label: "Ekvation", value: EQUATION }
];

export const operativeTypeData = [
  { id: 0, label: "Ja", value: true },
  { id: 1, label: "Nej", value: false }
];

export const layoutTypeData = [
  { id: 0, label: "Visa kantlinjer", value: true }
];

const chartTypeRowData = {
  title: `${translation[CHART_TYPE]}:`,
  data: chartTypeData,
  type: CHART_TYPE
};

const operativeRowData = {
  title: `${translation[OPERATIVE]}:`,
  data: operativeTypeData,
  type: OPERATIVE
};

const layoutRowData = {
  title: `${translation[LAYOUT]}:`,
  data: layoutTypeData,
  type: SHOW_BORDERS
};

export const settingsData = settings => {
  const { chartType } = settings;

  const rows =
    chartType === STANDARD
      ? [{ ...chartTypeRowData }, { ...operativeRowData }, { ...layoutRowData }]
      : [{ ...chartTypeRowData }, { ...operativeRowData }];

  return [{ title: translation[TYPE], rows }];
};

export const emptyCell = {
  id: "",
  value: "",
  interactive: false,
  title: false,
  color: "#fff",
  column: M,
  row: M,
  borderRight: false,
  borderBottom: false
};

export const getEmptyGrid = (w, h) =>
  Array(h)
    .fill("")
    .map(_ => Array(w).fill(emptyCell));

export const getEmptyGridEquation = (w, h) =>
  getEmptyGrid(w, h).map(row =>
    row.map((cell, i) => ({ ...cell, row: i === 1 ? S : XL }))
  );

export const generateIDsForEachPosition = grid =>
  grid.map((column, index1) =>
    column.map((row, index2) => ({ ...row, id: `[${index1}, ${index2}]` }))
  );

/**
 * Generates default Chart data object
 * @returns {{items: Array}}
 */
export const defaultChartData = () => ({
  grid: null
});

/**
 * Generates default Chart settings object
 */
export const defaultChartSettings = () => ({
  [CHART_TYPE]: STANDARD,
  [OPERATIVE]: true,
  [SHOW_BORDERS]: true
});
