import React from "react";
import styled from "styled-components";

const Wrapper = styled.h5`
  padding: 20px;
`

const Aida = () => {

  return (
    <React.Fragment>
      <Wrapper>Aida</Wrapper>
    </React.Fragment>
  );
};

export default Aida;
