import React from "react";
import * as feather from "react-feather";
import * as studliIcons from "../StudliIcons";

import styled from "styled-components";

const HeaderIcon = styled.span`
  width: 24px;
  height: 28px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  color: inherit;
  margin-right: 8px;
`;

const PageHeaderIcon = ({ icon, studlicon, children, size, ...rest }) => {
  let iconContent = null;

  if (icon) {
    const Icon = feather[icon];
    if (!Icon) {
      throw new Error(`No icon with name '${icon}'`);
    }

    iconContent = (
      <HeaderIcon>
        <Icon size={size || 24} />
      </HeaderIcon>
    );
  }

  if (studlicon) {
    const Icon = studliIcons[studlicon];
    if (!Icon) {
      throw new Error(`No icon with name '${studlicon}'`);
    }

    iconContent = (
      <HeaderIcon>
        <Icon size={size || 24} />
      </HeaderIcon>
    );
  }

  return (
    <div {...rest}>
      {iconContent}
      {children}
    </div>
  );
};

export default PageHeaderIcon;
