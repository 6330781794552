import React, { Children, forwardRef, cloneElement } from "react";
import { StyledDropDownButton, StyledDropDownContent } from "./StyledDropDown";

export const DropDownStateless = ({
  children,
  onToggle,
  onChange,
  disabled,
  className,
  selected,
  open,
  overrideStyles,
  overrideContentStyles,
  innerRef,
  itemsToShow,
  dropDownPos,
  hasChevron,
  dropDownAlign,
  inSidebar
}) => {
  /**
   * Handles click
   * @returns {null}
   */
  const handleClick = e => {
    e.stopPropagation();

    return disabled ? null : onToggle();
  };

  /**
   * Clones and prepares children
   * @returns {Object}
   */
  const prepChildren = () =>
    Children.map(children, child => cloneElement(child, { onClick: onChange }));

  /**
   * Render drop down b utton
   * @returns {*}
   */
  const renderDropDownButton = () => (
    <StyledDropDownButton
      open={open}
      disabled={disabled}
      onClick={handleClick}
      hasChevron={hasChevron}
      style={overrideStyles}
      inSidebar={inSidebar}
      type="button"
    >
      {selected}
    </StyledDropDownButton>
  );

  return (
    <div className={className} ref={innerRef} style={overrideStyles}>
      {renderDropDownButton()}

      <StyledDropDownContent
        active={open}
        dropDownPos={dropDownPos}
        dropDownAlign={dropDownAlign}
        itemsToShow={itemsToShow}
        style={overrideContentStyles}
      >
        {prepChildren()}
      </StyledDropDownContent>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <DropDownStateless {...props} innerRef={ref} />
));
