import styled from "styled-components";
import Menu from "../Menu/index";
import { DropDownButton } from "./DropDownButton";
import { itemHeight } from "../Menu/Item";
import { TOP, RIGHT } from "../../../constants";

const presetItemsToShow = 6;

export const StyledDropDownContent = styled(Menu)`
  margin-top: 5px;
  max-height: ${({ itemsToShow }) => setHeight(itemsToShow)}px;
  overflow: auto;

  ${({ dropDownPos }) => (dropDownPos === TOP ? `bottom: 40px;` : "")}
  ${({ dropDownAlign }) => (dropDownAlign === RIGHT ? `right: 0;` : "")}
`;

export const StyledDropDownButton = styled(DropDownButton)`
  border: solid 1px ${({ theme: { neutralQuaternary } }) => neutralQuaternary};
  box-sizing: border-box;
  color: ${({ inSidebar, theme }) =>
    inSidebar ? theme.white : theme.neutralSecondary};
  min-width: 140px;
  font-weight: 700;
  padding: 0 12px 0 12px;

  ${({ disabled }) => isDisabled(disabled)}

  & > span {
    flex: 1;
    justify-content: space-between;
  }

  & > span > svg {
    transition: transform 200ms;

    ${({ open }) => (open ? `transform: rotate(180deg);` : null)};
  }
`;

/**
 * Styled for if is disabled
 * @param disabled
 * @returns {string}
 */
const isDisabled = disabled =>
  !disabled
    ? `
    &:hover,
    &:focus {
      border-color: ${({ neutralTertiary }) => neutralTertiary};
      color: inherit;
    }
  `
    : "";

/**
 * Sets height of drop down
 * @param itemsToShow
 * @returns {number}
 */
const setHeight = itemsToShow =>
  itemsToShow
    ? itemHeight * itemsToShow + 8
    : itemHeight * presetItemsToShow + 8;
