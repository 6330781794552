import { all, takeLatest, takeEvery } from "redux-saga/effects";
import * as action from "../api/actions";
import * as saga from "./sagas";

function* watchFetchErrors() {
  yield takeEvery(
    action.ACTION_FETCH_TAXONOMY_LEVELS_FAILED,
    saga.onFetchErrors
  );
}

function* watchFetchTaxonomyLevels() {
  yield takeLatest(
    action.ACTION_FETCH_TAXONOMY_LEVELS,
    saga.onFetchTaxonomyLeves
  );
}

function* watchFetchTaxonomySubjects() {
  yield takeLatest(
    action.ACTION_FETCH_TAXONOMY_SUBJECTS,
    saga.onFetchTaxonomySubjects
  );
}

export default function* rootSaga() {
  yield all([
    watchFetchErrors(),
    watchFetchTaxonomyLevels(),
    watchFetchTaxonomySubjects(),
  ]);
}
