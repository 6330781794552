import styled from "styled-components";

export const StyledDescriptionSection = styled.div``;

export const StyledInputWrapper = styled.div`
  margin-top: 15px;
  textarea {
    font-size: 12px;
    width: 100%;
  }
`;
