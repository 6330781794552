import React from "react";
import styled from "styled-components";

import DropDownComboBox from "../DropDown/DropDownComboBox";
import Tag from "../Tag";

const TagSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedTags = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 10px 0;
  float: left;
`;

const NoTagsSelected = styled.div`
  font-size: 0.8em;
  color: #999;
  padding: 5px;
`;

const TagWrapper = styled.div`
  border-radius: 5px;
  padding: 3px 0;
  float: left;
  position: relative;
`;

class TagSelector extends React.PureComponent {
  static defaultProps = {
    fullWidth: false,
    icon: "settings",
    selected: [],
    eventCallback: () => {}
  };

  /**
   * Runs correct function depending on whether item is checked or unchecked
   * @param item
   */
  checkBoxEvent = item => {
    const { selected, eventCallback } = this.props;
    const selectedIds = selected || [];
    eventCallback(
      item,
      selectedIds.indexOf(item.id) >= 0
        ? selected.filter(id => id !== item.id) // deselect
        : [...selected, item.id] // select
    );
  };

  /**
   * Renders tags
   * @param tag
   * @param fill
   * @returns {*}
   */
  tagRenderer = (tag, fill) => (
    <Tag
      label={tag.title}
      variant={"info"}
      fill={fill}
      labelColor={tag.color}
    />
  );

  /** Creates the tag object */
  createTags = (tags, selected) =>
    tags.map(tag => ({
      id: tag.id,
      content: tag.title,
      color: tag.color,
      variant: "info",
      isChecked: selected.indexOf(tag.id) >= 0,
      element: this.tagRenderer(tag, true)
    }));

  /**
   * Render selected tags OR no tags selected.
   * @param {Array} tags a list of selected tags
   */
  renderSelectedTags = tags =>
    tags.length ? this.renderTagWrapper(tags) : this.renderNoTagsSelected();

  /**
   * Render the selected tags.
   * @param {Array} tags a list of selected tags
   */
  renderTagWrapper = tags =>
    tags.map(tag => (
      <TagWrapper key={tag.id + "selectedTagsRenderer"}>
        {this.tagRenderer(tag, true)}
      </TagWrapper>
    ));

  /**
   * Render Text when no tags are selected
   */
  renderNoTagsSelected = () => (
    <NoTagsSelected>
      <p>Inga valda labels</p>
    </NoTagsSelected>
  );

  render() {
    const { title, fullWidth, icon, tags, selected } = this.props;

    const selectedIds = selected ? selected : [];
    const selectedTags = tags.filter(tag => selectedIds.indexOf(tag.id) >= 0);

    return (
      <TagSelectorWrapper>
        <DropDownComboBox
          fullWidth={fullWidth}
          icon={icon}
          title={title}
          items={this.createTags(tags, selectedIds)}
          checkboxCallback={this.checkBoxEvent}
          searchKey={"content"}
        />

        {fullWidth && tags.length > 0 ? (
          <SelectedTags>{this.renderSelectedTags(selectedTags)}</SelectedTags>
        ) : null}
      </TagSelectorWrapper>
    );
  }
}

export default TagSelector;
