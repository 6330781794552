import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StandardButton, addFlash } from "../../../shared";
import { deletePosts } from "../../store/actions";
import { DELETE, sectionCoverter, EXERCISES, AIDA } from "../../../constants";
import HasPermission from "../../../shared/Permissions";
import Tooltip from "react-tooltip-lite";

const mapStateToProps = state => ({
  selectedPosts: state.Carousel.items,
  workflowMap: state.Product.get("workflowMap")
});

const mapDispatchToProps = dispatch => ({
  infoFlash: msg => dispatch(addFlash(msg)),
  deletePosts: (postType, postIds) => dispatch(deletePosts(postType, postIds))
});

const DeletePostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

const DeletePostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
const Top = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
const Title = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
const MiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const UpdateButton = styled(StandardButton)`
  width: 280px;
  border-radius: 5px;
  display: block;
  margin: 5px 0 0 0 !important;
`;

class DeletePost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  /**
   * Returns section name for row
   */
  getSection = () =>
    this.props.postType && sectionCoverter[this.props.postType.toLowerCase()];

  onDelete = () => {
    const { postType, selectedPosts, deletePosts } = this.props;

    let isConnected = false;

    selectedPosts.forEach(element => {
      if (element.usage_count > 0) {
        isConnected = true;
      }
    });

    if (isConnected) {
      this.props.infoFlash(
        "En eller flera av de valda resurserna är kopplade till en övning.\nVar god ta bort dessa kopplingar innan resursen kan tas bort."
      );
      return;
    }

    const postIds = selectedPosts.map(post => post.id);
    deletePosts(postType, postIds);

    this.setState({
      isOpen: false
    });
  };

  renderDelete = () => {
    const { selectedPosts } = this.props;

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <DeletePostWrapper>
        <Tooltip content="Ta bort" useDefaultStyles={true}>
          <StandardButton studlicon="Trash" onClick={this.onToggle} />
        </Tooltip>
        <DeletePostContent className={classNames}>
          <Top>
            <Title>Ta bort:</Title>
          </Top>

          <div>
            <MiddleContent>
              <UpdateButton studlicon="Trash" onClick={this.onDelete}>
                {" "}
                Ta bort {selectedPosts.length} poster{" "}
              </UpdateButton>
            </MiddleContent>
          </div>
        </DeletePostContent>
      </DeletePostWrapper>
    );
  };

  render() {
    const {
      selectedPosts,
      workflowMap: { exercises }
    } = this.props;

    /* flatMap does not work on IE */
    const actionList = selectedPosts
      .map(element => Object.values({ ...exercises[element.status] }))
      .reduce((acc, val) => acc.concat(val), [])
      .filter(element => element === DELETE);

    const section = this.getSection();
    const actionsAreEqual = actionList.length === selectedPosts.length;
    const hasDelete =
      section === EXERCISES || section === AIDA ? actionsAreEqual : true;

    return (
      <div ref={this.clickRef}>
        {hasDelete ? (
          <HasPermission
            component={this.renderDelete()}
            section={section}
            permission={DELETE}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePost);
