import React from "react";
import {
  renderMediaButtons
} from "../../../shared/MediaHandler/MediaHandler";
import {
  BOXES
} from "../../../constants";
import {
  StyledTextButtonWrapper,
  StyledValueEditInputWrapper,
  StyledBoxEditInputWrapper
} from "./StyledSortMachine";
import { StyledTextToolbarButton } from "../MultipleChoice/StyledMultipleChoice";
import Text from "../../../shared/components/Text/Text";

const SortMachineBoxLabel = ({
  index,
  label,
  onChangeMedia,
  onChangeMediaResource,
  storeDraft,
  draftTarget,
  editBtnFn,
  callbackUpdatedItem,
  updatedItem
}) => {
  const [showTextToolbar, setTextToolbar] = React.useState(false);

  const toggleTextOptions = () => setTextToolbar(!showTextToolbar);

  /**
   * Edit question label text
   */
   const onEditChange = (_keyType, text, _target) => editBtnFn(index, { label: text });


  return (
    <div>
        <StyledTextButtonWrapper>
          {renderMediaButtons(index, BOXES, onChangeMedia, onChangeMediaResource)}
          {label !== undefined && 
            <StyledTextToolbarButton isActive={showTextToolbar} studlicon={"TextTool"} onClick={toggleTextOptions}></StyledTextToolbarButton>
          }
        </StyledTextButtonWrapper>
        <StyledValueEditInputWrapper>
          <StyledBoxEditInputWrapper>
            <Text
              data={{text: label}}
              settings={{ useShortToolbar: true, useToolbar: showTextToolbar, multiLine: false }}
              storeDraft={storeDraft}
              draftTarget={draftTarget}
              updateData={onEditChange}
              callbackUpdatedItem={callbackUpdatedItem}
              updatedItem={updatedItem}
              />
          </StyledBoxEditInputWrapper>
        </StyledValueEditInputWrapper>
      </div>
  )
}

export default SortMachineBoxLabel