import routerService from "./services/router";
import { registerReducer as RegisterReducer } from "./services/reducer";
import { registerSaga as RegisterSaga } from "./services/sagas";
import NotFound from "./views/NotFound";

import navigationReducer from "./store/navigation/reducer";
import flasherReducer from "./store/flasher/reducer";
import modalReducer from "./store/modal/reducer";
import sagas from "./sagas";
import websocket from "./store/socket/sagas";

export { default as AppShell } from "./containers/AppShell";
export { default as Router } from "./containers/Router";
export {
  default as DataTable,
  TextColumn,
  DateColumn,
  LabelColumn,
  KeyWordsColumn,
  ChipColumn,
  IDColumn,
  CountColumn,
  StatusColumn,
  LockColumn,
  ErrorColumn,
  selectable,
  withActions,
  Pagination
} from "./components/DataTable";
export {
  default as Button,
  StandardButton,
  PrimaryButton,
  CancelButton
} from "./components/Button";
export { default as Collapsible } from "./components/Collapsible";
export { default as RouterService } from "./services/router";
export { default as sagas, registerSaga } from "./services/sagas";
export { default as reducers, registerReducer } from "./services/reducer";
export {
  default as middlewares,
  registerMiddleware
} from "./services/middleware";
export { registerConstraint } from "./services/constraints";
export * from "./store/application/actions";
export * from "./store/flasher/actions";
export * from "./store/navigation/actions";
export * from "./store/modal/actions";

export * from "./constants/navigation";

export { default as PageHeader } from "./components/PageHeader";

export const init = () => {
  routerService.setNotFoundView(NotFound);
  RegisterReducer("navigation", navigationReducer);
  RegisterReducer("flasher", flasherReducer);
  RegisterReducer("modal", modalReducer);
  RegisterSaga(websocket);
  RegisterSaga(sagas);
};

export { filterdata, mediaFilter } from "./containers/Filters/filterdata";
