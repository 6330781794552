import styled from "styled-components";

export const StyledSearchBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 8px 0;
  padding: 0 8px;
  box-sizing: border-box;
  color: ${props => props.theme.neutralSecondary};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 32px;
  border: 1px solid ${props => props.theme.neutralSecondary};
  border-radius: 5px;
`;

export const StyledLabel = styled.div`
  color: ${props => props.theme.neutralPrimary};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`;

export const StyledInput = styled.input.attrs({
  type: "search"
})`
  vertical-align: baseline;
  box-shadow: none;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  color: inherit;
  background-color: transparent;
  text-overflow: ellipsis;
  border-width: 0;
  border-style: none;
  outline: none;
  flex: 1 1 0px;
  overflow: hidden;
  border-radius: 0;
  appearance: none;
`;
