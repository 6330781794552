import { routes, views } from "./routes";
import {
  RouterService,
  registerReducer,
  registerSaga,
} from "../shared";
import rootSaga from "./sagas";
import { systems as supportSystemReducer } from "./store/reducer";


export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer("Systems", supportSystemReducer);
};
