import styled from "styled-components";

export const StyledInputTypeInputsContainer = styled.div`
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
`;

export const StyledInputTypeLabel = styled.label`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  grid-column: span 4;
`;

export const StyledRowContainer = styled.div`
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
`;

export const StyledGridColumn = styled.div`
  display: flex;
  align-items: center;
  grid-column-start: ${({ colStart }) => colStart};
`;
