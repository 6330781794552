import { Column } from "react-virtualized";
import DateRenderer from "../Renderer/Date";

const DateColumn = () => null;

DateColumn.propTypes = Object.assign({}, Column.propTypes);
DateColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: DateRenderer,
  draggable: true
});

export default DateColumn;
