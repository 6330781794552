import {
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@material-ui/core";
import React from "react";
import { Settings } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../shared/components/Button";
import Checkbox from "../../shared/components/Checkbox";
import { getTaxonomyLevels } from "../../taxonomy/store/selectors";
import { setProductTaxonomyLevels } from "../api/actions";
import { getProductInfo, getProductTaxonomyLevels } from "../store/selectors";
import IconButton from "./IconButton";
import Label from "./Label";

const StackHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  ${({ noWrap }) => (noWrap ? `flex-wrap: nowrap;` : `flex-wrap: wrap;`)}
`;

const StackVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Level = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 0.25rem;
  background-color: #5099fc33;
`;

const DialogContent = styled(MuiDialogContent)`
  min-width: 360px;
`;

const TaxonomyLevelsEdit = ({
  initialSelectedLevels,
  levels,
  onSave,
  onCancel,
}) => {
  const [selectedLevels, setSelectedLevels] = React.useState(
    initialSelectedLevels.map((l) => l.id)
  );

  const isSelected = (level) => selectedLevels.includes(level.id);

  const toggleLevel = (level) => {
    if (isSelected(level)) {
      setSelectedLevels(selectedLevels.filter((id) => id !== level.id));
      return;
    }
    setSelectedLevels([...selectedLevels, level.id]);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Redigera nivåer</DialogTitle>
      <DialogContent>
        <StackVertical>
          <List dense>
            {levels.map((l) => (
              <ListItem key={l.id}>
                <Checkbox
                  isChecked={isSelected(l)}
                  onClick={() => toggleLevel(l)}
                />
                <Label>{l.description}</Label>
              </ListItem>
            ))}
          </List>
        </StackVertical>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} type="button" skin="primary">
          Avbryt
        </Button>
        <Button
          onClick={() => onSave(selectedLevels)}
          type="button"
          skin="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TaxonomyLevels = () => {
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState(false);
  const [product] = useSelector(getProductInfo);
  const levels = useSelector(getTaxonomyLevels);
  const selectedLevels = useSelector(getProductTaxonomyLevels);

  const startEdit = () => {
    setEditing(true);
  };

  const saveEdit = (levelIds) => {
    dispatch(setProductTaxonomyLevels(product.id, levelIds));
    setEditing(false);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  return (
    <StackVertical>
      <StackHorizontal noWrap>
        <Label as="h4">Nivå</Label>
        <IconButton aria-label="Hantera niveåer" onClick={startEdit}>
          <Settings width="16px" height="16px" />
        </IconButton>
        {editing && (
          <TaxonomyLevelsEdit
            levels={levels}
            initialSelectedLevels={selectedLevels}
            onCancel={cancelEdit}
            onSave={saveEdit}
          />
        )}
      </StackHorizontal>
      <StackHorizontal>
        {selectedLevels.map((l) => (
          <Level key={l.id}>{l.description}</Level>
        ))}
      </StackHorizontal>
    </StackVertical>
  );
};

export default TaxonomyLevels;
