import { generalErrorHandler } from "../../api";

import {
  fetchSupportSystemDetails,
  fetchSupportSystemsStart,
  fetchSupportSystemsSuccess,
  fetchSupportSystemsFailed,
  fetchSupportSystemsEnd
} from "../api/actions";
import { mainNavigation, setSections, addFlash } from "../../shared";
import { fetchProductDetailsSuccess } from "../../products/api/actions";
import { call, put } from "redux-saga/effects";
import * as sharedModule from "../../shared";
import { ROUTE_AIDA_LIST } from "../../posts/routes";
import * as api from "../api/requests";
import { translation } from "../../constants";

export function* onFetchError(action) {
  yield put(
    addFlash(
      `Error when fetching products${
        action.error ? ": " + action.error.toString() : ""
      }`,
      "error"
    )
  );
}

export function* onAidaHelpActivated(action) {
  yield put(sharedModule.setTitle(action.aida.title));
  yield put({ type: ROUTE_AIDA_LIST, payload: { entityId: action.aida.id } });
}

export function* onFetchSupportSystemDetails(action) {
  const result = yield call(
    api.getSupportSystemDetails,
    action.payload.entityId
  );
  let workflowMap = result.data.data.workflowMap;
  workflowMap.aida = workflowMap.exercises;
  yield put(
    fetchProductDetailsSuccess(
      { ...result.data.data, workflowMap: workflowMap },
      result.data.metadata
    )
  );
  const systemTitle = result.data.data.entity.title.toLowerCase();
  const translatedTitle = translation[systemTitle];
  yield put(sharedModule.setTitle(translatedTitle));
  yield put(setSections(mainNavigation));
}

/**
 * Always fetches status summary & fetches system details only when routing.
 * @param {*} action
 */

export function* onAidaRoute(action) {
  if (action.meta && action.meta.location.kind !== "load") {
    yield put(fetchSupportSystemDetails(action.payload));
  }
}

export function* onFetchSupportSystems(action) {
  yield put(fetchSupportSystemsStart());
  try {
    const result = yield call(api.listSystems);
    yield put(
      fetchSupportSystemsSuccess(result.data.data, result.data.metadata)
    );
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(fetchSupportSystemsFailed(error));
  } finally {
    yield put(fetchSupportSystemsEnd());
  }
}
