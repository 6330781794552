import { darken } from "polished";

import * as colors from "./colors";

export const height = "32px";
export const padding = "12px";
export const paddingNumber = 12;

export const fill = {
  defautls: colors.neutralLight,
  disable: colors.neutralLight,
  standard: colors.neutralLighter,
  primary: colors.themePrimary,
  cancel: colors.red,
  tomoyo: colors.tomoyoPrimary,
  DM: colors.white
};

export const ink = {
  defaults: colors.neutralDark,
  disable: colors.neutralTertiary,
  standard: colors.neutralPrimary,
  primary: colors.white,
  cancel: colors.white,
  tomoyo: colors.white,
  DM: colors.themePrimary
};

export const skins = {
  defaults: () => skin(fill.defautls, ink.defaults),
  standard: () => skin(fill.standard, ink.standard),
  primary: () => skin(fill.primary, ink.primary),
  tomoyo: () => skin(fill.tomoyo, ink.tomoyo),
  DM: () => skin(fill.DM, ink.DM),
  cancel: () => skin(fill.cancel, ink.cancel)
};

export const defaultType = ["14px", 1, 500, "0.02em", "none"];

export const skin = (fill, ink) => `
  background-color: ${fill};
  color: ${ink};
  
  &:hover,
  &:focus {
    background-color: ${darken(0.05, fill)};
    color: ${ink};
  }
  
  &:active {
    background-color: ${darken(0.08, fill)};
    color: ${ink};
  }
`;

export const outline = (color, ink) => `
  background-color: transparent;
  color: ${ink};
  border 1px solid ${color};
  padding: 0 ${paddingNumber - 1}px;
  
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: ${darken(0.08, color)};
    color: ${darken(0.08, ink)};
  }
`;

export const typography = (size, height, weight, spacing, transform) => `
  font-size: ${size};
  line-height: ${height};
  font-weight: ${weight};
  letter-spacing: ${spacing};
  margin: inherit;
  text-decoration: none;
  text-transform: ${transform};
`;

export const transparent = () => `
  background: transparent;
  outline: none;
  border: 0;
`;
