import styled from 'styled-components';

export const StyledTextBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  background-color: #F9F6F6;
  min-height: 40%;
  max-height: 75%;
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.5);
  
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;
`;

export const StyledTextBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 30px 10px;
  
  
`;

export const StyledTextBoxContentText = styled.p`
  font-size: 18px;
  letter-spacing: 0.58px;
  line-height: normal;
  overflow: auto;  

  ul {
    margin: 10px 0 10px 30px;
  }
`;

export const StyledTextBoxContentOptional = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 20px;
`;