import React from "react";

const XIcon = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 10 19"
    width={`${size}px`}
    className="studlicon-warning"
  >
    <text fillRule="evenodd" fontFamily="Helvetica" fontSize="14">
        <tspan x="0.5" y="15">X</tspan>
    </text>
  </svg>
);

export default XIcon;
