import React from "react";
import { setItems } from "../../../media/store/carousel/actions";
import { filterMedia } from "../../../media/store/actions";
import ButtonGroup from "../ButtonGroup";
import MediaListContainer from "../../../media/containers/MediaList";
import MediaModalFilters from "./MediaModalFilters";
import { PrimaryButton, StandardButton } from "../../../shared";
import { StyledModalContainer, StyledModalActions } from "./styles";
import { modalOpen, modalUpdate, modalClose } from "../../";
import { MEDIA, MEDIA_RESOURCE, ENTITIES } from "../../../constants";
import { filterPosts } from "../../../mediaresource/store/actions";

const _filterMedia = (dispatch, params) => dispatch(filterMedia(params));
const _clearCarousel = dispatch => setItems([])(dispatch);
const _filterPost = (dispatch, params) =>
  dispatch(filterPosts(MEDIA_RESOURCE, params));

/**
 * Open a media modal
 * @param {*} dispatch The dispatch object used by the store
 * @param {*} callbacks {onOk, onCancel} callbacks with the selected media (if any).
 */
export const openMediaModal = dispatch => (
  { onOK = () => {}, onCancel = () => {} },
  conditions,
  type,
  title,
  isActive,
  multipleSelection
) => {
  let selectedMedia = [];

  /**
   * Using the correct title depending on type
   */
  const getTitleForType = () =>
    type === MEDIA ? "Lägg till media." : "Lägg till sammansatt bild.";

  /**
   * Use custom title from props or use title for type.
   */
  const header = title ? title : getTitleForType();

  /**
   * Using the right filter depending on type
   */
  const filter =
    type === MEDIA_RESOURCE
      ? _filterPost.bind(null, dispatch)
      : _filterMedia.bind(null, dispatch);

  /**
   * Clears carousel
   */
  const clearCarousel = _clearCarousel.bind(null, dispatch);

  /**
   * Close the modal and call the cancel callback
   */
  const cancelClose = () => {
    modalClose(dispatch);
    clearCarousel();
    onCancel([]);
  };

  /**
   * Close the modal and call the ok callback with the selected media
   */
  const okClose = () => {
    modalClose(dispatch);
    clearCarousel();
    onOK(selectedMedia);
  };

  /**
   * Save the selected media to be used in an eventual ok callback
   * @param {*} selected
   */
  const onSelectMedia = selected => {
    selectedMedia = selected;

    modalUpdate(dispatch)({
      actions: renderActions(selectedMedia.length > 0 ? false : true)
    });
  };

  /**
   * Render children
   * @returns {*}
   */
  const renderChildren = () => (
    <StyledModalContainer>
      <MediaModalFilters
        filterMedia={filter}
        conditions={conditions}
        type={ENTITIES[type]}
      />
      <MediaListContainer
        section={type}
        useCarousel={multipleSelection}
        onSelectMedia={onSelectMedia}
      />
    </StyledModalContainer>
  );

  /**
   * Render actions
   * @returns {*}
   */
  const renderActions = isDisabled => (
    <StyledModalActions>
      <ButtonGroup>
        <StandardButton onClick={cancelClose}>Avbryt</StandardButton>
        <PrimaryButton onClick={okClose} disabled={isDisabled}>
          Infoga
        </PrimaryButton>
      </ButtonGroup>
    </StyledModalActions>
  );

  modalOpen(dispatch)({
    header,
    children: renderChildren(),
    actions: renderActions(isActive ? false : true),
    closeModal: cancelClose
  });
};
