import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_MARKETMESSAGES, ROUTE_DETAILED_MARKETMESSAGE } from "../routes";
import {
  fetchEntitiesSaga,
  unpublishPostsSaga,
  deletePostsSaga,
  savePostsSaga,
  fetchEntityDetailsSaga,
  fetchAllChipsForEntitySaga,
  addRemoveChipsToPostSaga
} from "../../posts/api/saga";

import { publishMessagePostsSaga } from "./sagas";

import {
  PUBLISH_POSTS,
  UNPUBLISH_POSTS,
  DELETE_POSTS,
  SAVE_ENTITY_DETAILS,
  CREATE_ENTITY_DETAILS,
  SAVE_POST,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  RELOAD_POST,
  RELOAD_POSTS,
  SORT_POSTS,
  FILTER_POSTS,
  FETCH_CHIPS,
  POST_ADD_REMOVE_CHIPS
} from "../../posts/store/actions";
import {
  onListRoute,
  sortList,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onDetailedRoute,
  onFetchChips
} from "../../posts/sagas/sagas";

import { FETCH_ENTITIES, FETCH_ENTITY_DETAILS } from "../../api";
import { actionCreator } from "../../shared";
import { ENTITIES, MARKETMESSAGE } from "../../constants";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(MARKETMESSAGE, FETCH_ENTITIES),
    fetchEntitiesSaga(ENTITIES[MARKETMESSAGE], "listMarketMessagesPosts")
  );
  yield takeLatest(
    actionCreator(MARKETMESSAGE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[MARKETMESSAGE], "fetchMarketMessagePost")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[MARKETMESSAGE], "saveMarketMessagePost")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[MARKETMESSAGE], "createMarketMessagePost")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, SAVE_POST),
    savePostsSaga(ENTITIES[MARKETMESSAGE], "savePost")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, RELOAD_POST),
    onDetailedRoute,
    ENTITIES[MARKETMESSAGE],
    "fetchMarketMessagePost"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, SORT_POSTS),
    sortList,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeLatest(
    actionCreator(MARKETMESSAGE, FILTER_POSTS),
    filterPosts,
    ENTITIES[MARKETMESSAGE],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, POST_ADD_REMOVE_CHIPS),
    addRemoveChipsToPostSaga(ENTITIES[MARKETMESSAGE], "addRemoveChipsToPost")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, PUBLISH_POSTS),
    publishMessagePostsSaga(ENTITIES[MARKETMESSAGE], "publishPosts")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, UNPUBLISH_POSTS),
    unpublishPostsSaga(ENTITIES[MARKETMESSAGE], "unpublishPosts")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, DELETE_POSTS),
    deletePostsSaga(ENTITIES[MARKETMESSAGE], "deletePosts")
  );
  yield takeEvery(
    actionCreator(MARKETMESSAGE, FETCH_CHIPS),
    fetchAllChipsForEntitySaga(
      ENTITIES[MARKETMESSAGE],
      "fetchAllChipsForEntity"
    )
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_MARKETMESSAGES,
    onListRoute,
    ENTITIES[MARKETMESSAGE],
    "listMarketMessagesPosts"
  );
  yield takeEvery(
    ROUTE_MARKETMESSAGES,
    onFetchChips,
    ENTITIES[MARKETMESSAGE],
    "fetchAllChips"
  );
  yield takeEvery(
    ROUTE_DETAILED_MARKETMESSAGE,
    onDetailedRoute,
    ENTITIES[MARKETMESSAGE],
    "fetchMarketMessages"
  );
  yield takeEvery(
    ROUTE_DETAILED_MARKETMESSAGE,
    onFetchChips,
    ENTITIES[MARKETMESSAGE],
    "fetchAllChips"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
