import styled from "styled-components";

export const StyledIconWrapper = styled.div`
  display: flex;
  alignitems: center;
`;

export const StyledMarginedSpan = styled.span`
  white-space: nowrap;
  margin-left: 8px;
`;

export const EddaEditorContainer = styled.div`
  box-sizing: border-box;
  min-height: 300px;
  padding-bottom: 30px;
  position: relative;
`;

export const StyledAddComponentButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledSaveResetButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 10;
  backgroundcolor: #eaeaea;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 2;
`;

export const StyledValidationErrorsContainer = styled.div`
  display: flex;
`;

export const StyledValidationError = styled.div`
  margin-left: 15px;
`;
