import React from "react";
import styled from "styled-components";

const CounterWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledHoverArea = styled.div`
  display: inline-block;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s;
    min-width: 120px;
    background: white;
    padding: 10px 15px;
    border-radius: 5px;
    position: fixed;
    z-index: 20;
    margin-left: 2rem;
    margin-top: -2rem;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05), 0 1px 15px 0 rgba(0, 0, 0, 0.15);
    &::after {
      content: " ";
      position: absolute;
      top: 1rem;
      right: 100%;
      border-width: 5px 10px;
      border-style: solid;
      border-color: transparent white transparent transparent;
    }
  }
`;

const StyledItem = styled.div`
  font-size: 0.8rem;
  color: black;
  padding: 3px 0;
`;

const StyledCount = styled.div`
  width: 25px;
  cursor: default;
`;

const CountWithInfoRenderer = ({ cellData, rowData }) => {
  const { count, content } = cellData 
  return (
    <CounterWrapper>
      <StyledHoverArea>
        <StyledCount>{count }</StyledCount>
        {count > 0 && (
          <div className="tooltip">
            {content.map((item, index) => (
              <StyledItem key={index}>{item}</StyledItem>
            ))}
          </div>
        )}
      </StyledHoverArea>
    </CounterWrapper>
  );
};

export default CountWithInfoRenderer;
