import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StandardButton } from "../../../shared";
import { publishPosts, unpublishPosts } from "../../store/actions";
import { PUBLISH, IMAGE_RESOURCES } from "../../../constants";
import HasPermission from "../../../shared/Permissions";

const mapStateToProps = state => ({
  selectedPosts: state.Carousel.items
});

const mapDispatchToProps = dispatch => ({
  publishPosts: postIds => dispatch(publishPosts(postIds)),
  unpublishPosts: postIds => dispatch(unpublishPosts(postIds))
});

const PublishPostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

const PublishPostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
const Top = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;
const Title = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
const MiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const UpdateButton = styled(StandardButton)`
  width: 280px;
  border-radius: 5px;
  display: block;
  margin: 5px 0 0 0 !important;
`;

class PublishPost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!this.props.open
    };

    this.clickRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.clickRef.current.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isOpen: false
    });
  };

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  onPublish = () => {
    const { selectedPosts, publishPosts } = this.props;

    const postIds = selectedPosts.map(post => post.id);

    publishPosts(postIds);

    this.setState({
      isOpen: false
    });
  };

  onUnpublish = () => {
    const { selectedPosts, unpublishPosts } = this.props;

    const postIds = selectedPosts.map(post => post.id);

    unpublishPosts(postIds);

    this.setState({
      isOpen: false
    });
  };

  renderPublish = () => {
    const { selectedPosts } = this.props;

    const classNames = this.state.isOpen ? "isOpen" : "isClosed";

    return (
      <PublishPostWrapper>
        <StandardButton
          studlicon="Cloud"
          onClick={this.onToggle}
        ></StandardButton>
        <PublishPostContent className={classNames}>
          <Top>
            <Title>Ändra publiceringsstatus:</Title>
          </Top>

          <div>
            <MiddleContent>
              <UpdateButton onClick={this.onPublish}>
                {" "}
                Publicera {selectedPosts.length} poster{" "}
              </UpdateButton>
              <UpdateButton onClick={this.onUnpublish}>
                {" "}
                Avpublicera {selectedPosts.length} poster{" "}
              </UpdateButton>
            </MiddleContent>
          </div>
        </PublishPostContent>
      </PublishPostWrapper>
    );
  };

  render() {
    return (
      <div ref={this.clickRef}>
        <HasPermission
          component={this.renderPublish()}
          section={IMAGE_RESOURCES}
          permission={PUBLISH}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishPost);
