import React from "react";

const CircleSolid = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-circlesolid"
  >
    <path d="M25,0 C11.1895161,0 0,11.1895161 0,25 C0,38.8104839 11.1895161,50 25,50 C38.8104839,50 50,38.8104839 50,25 C50,11.1895161 38.8104839,0 25,0 Z" />
  </svg>
);

export default CircleSolid;
