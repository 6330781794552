import * as React from "react";
import PlainImage from "../../plugins/components/Image/PlainImage";
import { StyledImageWrapper } from "../../plugins/components/Help/StyledHelp";
import {
  renderMultiSVG,
  renderSvgImage
} from "../../plugins/components/MultiSvgImageHelpers";
import { MEDIA_RESOURCE } from "../../constants";
import {
  StyledItemMedia,
  StyledRemoveImageButton,
  StyledIdContainer,
  StyledItemImage,
  StyledEditMedia,
  StyledMediaButtonsContainer,
  StyledMediaResursButton,
  StyledItemMediaEdit,
  StyledMediaButton
} from "./StyledMediaHandler";

/**
 * Returns a media resource structure
 * @param {*} res
 */
export const mediaresourceStructure = res => ({
  mediaresource: {
    data: {
      id: res.id,
      text: res.title
    },
    type: MEDIA_RESOURCE
  }
});

/**
 * Render the Image part of the edit item component
 * @param {*} mediaComp
 * @param {*} id
 */
export const renderMedia = ({ mediaComp }, callback, size) =>
  mediaComp !== null ? (
    <StyledImageWrapper size={size}>
      <StyledItemMedia size={size}>
        <StyledItemImage size={size}>
          {callback ? (
            <StyledRemoveImageButton onClick={callback}>
              ×
            </StyledRemoveImageButton>
          ) : null}
          {mediaComp}
        </StyledItemImage>
      </StyledItemMedia>
    </StyledImageWrapper>
  ) : null;

/**
 * Get Media params from data content
 * @param {*} data
 */
export const getMedia = (
  { id, assetId, image, assets, mediaresource = {} },
  resources
) => {
  const width = 85;
  const height = 85;

  let mediaComp = null;
  let mediaId = 0;
  let mediaTitle = "";

  if (image) {
    mediaId = assetId;
    mediaComp = renderImage(image);
    mediaTitle =
      mediaComp && mediaComp.props.src
        ? mediaComp.props.src.replace(/^.*[\\/]/, "")
        : "";
  }
  if (assets && assets.icons) {
    mediaId = id;
    mediaComp = renderSvgImage(assets.icons, assets.format, height, width);
    mediaTitle =
      mediaComp && mediaComp.props.src
        ? mediaComp.props.src.replace(/^.*[\\/]/, "")
        : "";
  }

  if (mediaresource.data && mediaresource.data.id) {
    mediaId = mediaresource.data.id;
    mediaTitle = mediaresource.data.text;
    mediaComp = renderMultiSVG(mediaresource.data.id, resources, height, width);
  }

  return { mediaComp: mediaComp, mediaId: mediaId, mediaTitle: mediaTitle };
};

/**
 * render image
 * @param {*} url
 */
const renderImage = image => (
  <PlainImage src={image.src} {...image.attributes} />
);

/**
 * Render the edit image part
 * @param id
 * @param title
 * @param index
 * @param type
 * @param onChangeMedia
 * @param onMediaResourceChange
 */
export const renderEditImage = (
  id,
  title,
  index,
  type,
  onMediaChange,
  onMediaResourceChange
) => (
  <StyledItemMediaEdit>
    <StyledIdContainer>
      <p style={{ fontSize: 10 }}>
        {title ? (id ? title + " - " + id : title) : id}
      </p>
    </StyledIdContainer>
    {renderMediaButtons(index, type, onMediaChange, onMediaResourceChange)}
  </StyledItemMediaEdit>
);

/**
 * Render the media buttons
 * @param index
 * @param type
 * @param onChangeMedia
 * @param onMediaResourceChange
 */
export const renderMediaButtons = (
  index,
  type,
  onChangeMedia,
  onChangeMediaResource
) => {
  const changeMedia = () => onChangeMedia(index, type);
  const changeMediaResurs = () => onChangeMediaResource(index, type);

  return (
    <StyledMediaButtonsContainer>
      <StyledMediaButton
        title="Enskild bild"
        studlicon={"Media"}
        onClick={changeMedia}
      />
      <StyledMediaResursButton
        title="Sammansatt bild"
        studlicon={"MediaResourceIcon"}
        onClick={changeMediaResurs}
      />
    </StyledMediaButtonsContainer>
  );
};

/**
 * Render media box with media buttons.
 * @param {*} media
 */
export const renderEditMedia = (
  { mediaComp, mediaId, mediaTitle },
  index,
  withMargin,
  type,
  onChangeMedia,
  onChangeMediaResource
) => (
  <StyledEditMedia withMargin={withMargin}>
    {mediaComp !== null
      ? renderEditImage(
          mediaId,
          mediaTitle,
          index,
          type,
          onChangeMedia,
          onChangeMediaResource
        )
      : null}
  </StyledEditMedia>
);
