import React from "react";
import styled from "styled-components";
import { translationErrorMessage } from "../../../constants";

const StyledInvalidFilesContainer = styled.span`
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0px;
  padding: 10px;
`;

const StyledInvalidRowWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const StyledTitlesWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const StyledInvalidLabel = styled.span`
  font-size: 11px;
  flex: 1;
`;

const StyledTitle = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  flex: 1;
`;

const FileUploadProgressInvalidFiles = ({ failed }) => {
  return (
    <StyledInvalidFilesContainer>
      <StyledTitlesWrapper>
        <StyledTitle>Undantagna filer:</StyledTitle>
        <StyledTitle>Orsak:</StyledTitle>
      </StyledTitlesWrapper>
      {failed.map(file => (
        <StyledInvalidRowWrapper key={file.key}>
          <StyledInvalidLabel>{file.filename}</StyledInvalidLabel>
          <StyledInvalidLabel>
            {translationErrorMessage[file.error]
              ? translationErrorMessage[file.error]
              : file.error}
          </StyledInvalidLabel>
        </StyledInvalidRowWrapper>
      ))}
    </StyledInvalidFilesContainer>
  );
};

export default FileUploadProgressInvalidFiles;
