export const ACTION_FETCH_TAXONOMY_LEVELS = "TAXONOMY/FETCH_LEVELS";
export const ACTION_FETCH_TAXONOMY_LEVELS_START = `${ACTION_FETCH_TAXONOMY_LEVELS}/START`;
export const ACTION_FETCH_TAXONOMY_LEVELS_SUCCESS = `${ACTION_FETCH_TAXONOMY_LEVELS}/SUCCESS`;
export const ACTION_FETCH_TAXONOMY_LEVELS_FAILED = `${ACTION_FETCH_TAXONOMY_LEVELS}/FAILED`;
export const ACTION_FETCH_TAXONOMY_LEVELS_END = `${ACTION_FETCH_TAXONOMY_LEVELS}/END`;

export const ACTION_FETCH_TAXONOMY_SUBJECTS = "TAXONOMY/FETCH_SUBJECTS";
export const ACTION_FETCH_TAXONOMY_SUBJECTS_START = `${ACTION_FETCH_TAXONOMY_SUBJECTS}/START`;
export const ACTION_FETCH_TAXONOMY_SUBJECTS_SUCCESS = `${ACTION_FETCH_TAXONOMY_SUBJECTS}/SUCCESS`;
export const ACTION_FETCH_TAXONOMY_SUBJECTS_FAILED = `${ACTION_FETCH_TAXONOMY_SUBJECTS}/FAILED`;
export const ACTION_FETCH_TAXONOMY_SUBJECTS_END = `${ACTION_FETCH_TAXONOMY_SUBJECTS}/END`;

export const fetchTaxonomyLevels = () => ({
  type: ACTION_FETCH_TAXONOMY_LEVELS,
});

export const fetchTaxonomyLevelsStart = () => ({
  type: ACTION_FETCH_TAXONOMY_LEVELS_START,
});

export const fetchTaxonomyLevelsSuccess = (payload) => ({
  type: ACTION_FETCH_TAXONOMY_LEVELS_SUCCESS,
  payload,
});

export const fetchTaxonomyLevelsFailed = (payload) => ({
  type: ACTION_FETCH_TAXONOMY_LEVELS_FAILED,
  payload,
});

export const fetchTaxonomyLevelsEnd = () => ({
  type: ACTION_FETCH_TAXONOMY_LEVELS_END,
});

export const fetchTaxonomySubjects = (payload) => ({
  type: ACTION_FETCH_TAXONOMY_SUBJECTS,
  payload,
});

export const fetchTaxonomySubjectsStart = () => ({
  type: ACTION_FETCH_TAXONOMY_SUBJECTS_START,
});

export const fetchTaxonomySubjectsSuccess = (payload) => ({
  type: ACTION_FETCH_TAXONOMY_SUBJECTS_SUCCESS,
  payload,
});

export const fetchTaxonomySubjectsFailed = (payload) => ({
  type: ACTION_FETCH_TAXONOMY_SUBJECTS_FAILED,
  payload,
});

export const fetchTaxonomySubjectsEnd = () => ({
  type: ACTION_FETCH_TAXONOMY_SUBJECTS_END,
});
