export const getPostUsageFromMediaData = (mediaData, usageType = "postUsage") =>
  mediaData[usageType]
    ? mediaData[usageType].reduce((acc, v) => {
        if (acc[v.type] !== undefined) {
          acc[v.type]++;
          return acc;
        }
        return { ...acc, [v.type]: 1 };
      }, {})
    : {};
