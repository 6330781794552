import styled from "styled-components";
import { getHeight, getWidth } from "../../ChartHelpers";

export const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  height: ${props => getHeight(props.column)};
  width: ${props => getWidth(props.row)};

  input {
    height: 100%;
    background-color: ${props => props.color};
    border-radius: 0px;
    outline: none;
    flex: 1;
    box-shadow: none;
    width: -webkit-fill-available;
    padding: 3px 10px;
  }
`;

export const StyledTextEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 3px solid black;
  display: flex;
  justify-content: end;

  .TextEditor--OneLine {
    height: 100%;
    margin-bottom: 0;
  }

  .TextEditor--Edit {
    padding: 12px;
    background-color: ${props => props.color};
  }
`;
