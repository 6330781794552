import React from "react";
import RLink, { NavLink } from "redux-first-router-link";
import * as feather from "react-feather";
import styled from "styled-components";
import * as studliIcons from "../StudliIcons";

export const LinkStyle = `
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  background: none;
  border: none;
  margin:1px;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
  vertical-align: top;
  color: inherit;
  letter-spacing: 0.02em;
  pointer-events: all;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${props => props.theme.themeDark};
    color: inherit;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    color: ${props => props.theme.neutralSecondary};
    pointer-events: none;
  }
`;

const LinkIcon = styled.span`
  width: 18px;
  height: 18px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  color: inherit;
  margin-right: 8px;
`;

const RNLink = ({ activeClassName, nav, ...rest }) =>
  nav ? (
    <NavLink activeClassName={activeClassName} {...rest} />
  ) : (
    <RLink {...rest} />
  );

const StyledLink = styled(RNLink).attrs({
  activeClassName: "nav-item-active"
})`
  ${LinkStyle};
`;

const Link = ({ disabled, icon, studlicon, children, ...rest }) => {
  let iconContent = null;

  if (icon) {
    const Icon = feather[icon];
    if (!Icon) {
      throw new Error(`No icon with name '${icon}'`);
    }

    iconContent = (
      <LinkIcon>
        <Icon />
      </LinkIcon>
    );
  }

  if (studlicon) {
    const Icon = studliIcons[studlicon];
    const studliSize = 22;
    if (!Icon) {
      throw new Error(`No icon with name '${studlicon}'`);
    }

    iconContent = (
      <LinkIcon>
        <Icon size={studliSize} />
      </LinkIcon>
    );
  }

  return (
    <StyledLink aria-disabled={disabled} {...rest}>
      {iconContent}
      {children}
    </StyledLink>
  );
};

export default Link;
