import {
  OPERATIVE,
  SETTINGS,
  TYPE,
  MULTIPLE_CHOICE,
  BLANKS
} from "../../../../constants";
import { flattenBlockArrayData } from "../../../components/Editor";

/**
 * Returns whether or not current view requires operative
 * @param self
 */
function requireOperative(self) {
  const {
    type,
    routesMap: {
      [type]: {
        editorSettings: { requireOperative }
      }
    }
  } = self.props.location;
  return requireOperative;
}

const exceptionCases = [requireOperative];

/**
 * Runs through exception cases to see if operative validator should be ignored
 * @returns {any[]}
 */
function isExceptionCase(self) {
  return (
    exceptionCases
      .map(exception => exception(self))
      .filter(exceptionResult => exceptionResult).length === 0
  );
}

/**
 * Checks if a block is operative
 * @param block
 * @returns {*|boolean}
 */
const blockIsOperative = block =>
  (block &&
    SETTINGS in block &&
    OPERATIVE in block[SETTINGS] &&
    block[SETTINGS][OPERATIVE]) ||
  oldSupport(block);

/**
 * Old support
 * @param block
 * @returns {*|boolean}
 */
const oldSupport = block =>
  block &&
  TYPE in block &&
  (block[TYPE] === MULTIPLE_CHOICE || block[TYPE] === BLANKS);

/**
 * Checks resources for operative blocks
 * @param resources
 * @returns {boolean}
 */
const checkResourcesForOperative = resources =>
  resources &&
  resources
    .map(({ data }) => JSON.parse(data))
    .map(({ data }) => flattenBlockArrayData(data))
    .flat()
    .filter(blockIsOperative).length === 1;

/**
 * Checks so that no less or more than one operative block has been added
 */
export function hasOneOperative() {
  if (isExceptionCase(this)) {
    return true;
  }

  const resources = Object.keys(this.props.resources).map(
    resourceKey => this.props.resources[resourceKey]
  );

  const blockArrayRes =
    flattenBlockArrayData(this.props.postDraft.data).filter(blockIsOperative)
      .length === 1;

  const resourceRes = checkResourcesForOperative(resources);

  return resourceRes + blockArrayRes === 1;
}
