import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_MEDIA, ROUTE_AIDA_MEDIA, ROUTE_OMNIA_MEDIA } from "../routes";
import {
  fetchMediaEntitiesSaga,
  fetchMediaEntityDetailsSaga,
  fetchMediaFiltersSaga,
  updateMediaFile,
  addRemoveTagsToMediaSaga,
  deleteMediaSaga
} from "../api/saga";

import {
  onMediaListRoute,
  onMediaFilterRoute,
  previousMediaPageSaga,
  nextMediaPageSaga,
  reloadMediaPageSaga,
  filterMedia,
  loadMediaPageSaga,
  mediacarousel
} from "./sagas";
import { ROUTE_SERIES_MEDIA } from "../../series/routes";
import { ROUTE_CLASSROOM_MEDIA } from "../../classroom/routes";
import { ROUTE_MESSAGES_MEDIA } from "../../messages/routes";

function* watchMediaRequests() {
  yield takeEvery(
    "MEDIA/FETCH_MEDIA_ENTITIES",
    fetchMediaEntitiesSaga("Media", "listMedia")
  );
  yield takeEvery(
    "MEDIA/FETCH_MEDIA_ENTITY_DETAILS",
    fetchMediaEntityDetailsSaga("Media", "fetchMedia")
  );
  yield takeEvery(
    "LOAD_LESS_MEDIA",
    previousMediaPageSaga,
    "Media",
    "listMedia"
  );
  yield takeEvery("LOAD_MORE_MEDIA", nextMediaPageSaga, "Media", "listMedia");
  yield takeEvery("RELOAD_MEDIA", reloadMediaPageSaga, "Media", "listMedia");
  yield takeEvery(
    "MEDIA/FETCH_MEDIAFILTER",
    fetchMediaFiltersSaga("Post", "loadMediaFilters")
  );
  yield takeLatest("FILTER_MEDIA", filterMedia, "Media", "filterMedia");
  yield takeEvery(
    "UPDATE_MEDIAFILE",
    updateMediaFile("Media", "updateMediaFile")
  );
  yield takeEvery(
    "DELETE_MEDIAFILES",
    deleteMediaSaga("Media", "deleteMediaFiles")
  );
  yield takeEvery("LOAD_MEDIA_PAGE", loadMediaPageSaga, "Media", "listMedia");
  yield takeEvery("LOAD_MEDIA_DETAILS", mediacarousel, "Media", "fetchMedia");
  yield takeEvery(
    "NEXT_IN_MEDIA_CAROUSEL",
    mediacarousel,
    "Media",
    "fetchMedia"
  );
  yield takeEvery(
    "PREV_IN_MEDIA_CAROUSEL",
    mediacarousel,
    "Media",
    "fetchMedia"
  );
  yield takeEvery(
    "MEDIA_ADD_REMOVE_TAGS",
    addRemoveTagsToMediaSaga("Media", "addRemoveTagsToMedia")
  );
}

function* watchMediaRoutes() {
  yield takeEvery(ROUTE_MEDIA, onMediaListRoute, "Media", "listMedia");
  yield takeEvery(ROUTE_MEDIA, onMediaFilterRoute, "Media", "loadMediaFilters");
  yield takeEvery(ROUTE_AIDA_MEDIA, onMediaListRoute, "Media", "listMedia");
  yield takeEvery(
    ROUTE_AIDA_MEDIA,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(ROUTE_SERIES_MEDIA, onMediaListRoute, "Media", "listMedia");
  yield takeEvery(
    ROUTE_SERIES_MEDIA,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(
    ROUTE_CLASSROOM_MEDIA,
    onMediaListRoute,
    "Media",
    "listMedia"
  );
  yield takeEvery(
    ROUTE_CLASSROOM_MEDIA,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(
    ROUTE_MESSAGES_MEDIA,
    onMediaListRoute,
    "Media",
    "listMedia"
  );
  yield takeEvery(
    ROUTE_MESSAGES_MEDIA,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
  yield takeEvery(
    ROUTE_OMNIA_MEDIA,
    onMediaListRoute,
    "Media",
    "listMedia"
  );
  yield takeEvery(
    ROUTE_OMNIA_MEDIA,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
}

export default function* rootSaga() {
  yield all([fork(watchMediaRequests), fork(watchMediaRoutes)]);
}
