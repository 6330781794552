import { fromJS, List } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer, toListWithMaps } from "../../store/utils";
import {
  ACTION_FETCH_PRODUCTS_START,
  ACTION_FETCH_PRODUCTS_SUCCESS,
  ACTION_FETCH_PRODUCTS_END,
  ACTION_FETCH_PRODUCT_DETAILS_START,
  ACTION_FETCH_PRODUCT_DETAILS_SUCCESS,
  ACTION_FETCH_PRODUCT_DETAILS_END,
  ACTION_ACTIVATE_PRODUCT,
  ACTION_FETCH_PRODUCT_STATUS_SUMMARY_SUCCESS,
  ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS,
  ACTION_UPDATE_PRODUCT_START,
  ACTION_UPDATE_PRODUCT_END,
  ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE_SUCCESS,
  ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE_SUCCESS,
  ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS_SUCCESS,
  SET_PUBLISHING_PREVIEW_STATUS,
  SET_PUBLISHING_PROD_STATUS,
  ACTION_FETCH_PRODUCT_PUBLISH_HISTORY_SUCCESS,
  ACTION_FETCH_AVAILABLE_SESAMGROUPS_SUCCESS,
  ACTION_FETCH_PRODUCT_FILES_SUCCESS
} from "./actions";
import { modifyPermissionsArray } from "../../shared/Permissions";

export const requiredFields = {
  active: {},
  list: [],
  fetching: false,
  saving: false,
  isPublishingToPreview: false,
  isPublishingToProd: false,
  productFilesPublishStatus: {},
  productFilesPublishHistory: [],
  availableSesamgroups: []
};

const updateProduct = (state, diff) => {
  const list = state.get("list");
  const currentItem = state.get("selectedItem");

  return state
    .set("selectedItem", diff)
    .set(
      "list",
      list.setIn([list.findIndex(i => i.id === currentItem.id)], diff)
    );
};

const reducer = initialState => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {
    [ACTION_FETCH_PRODUCTS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_PRODUCTS_SUCCESS](state, action) {
      return state.update("list", () => new List(action.data.entities));
    },
    [ACTION_FETCH_PRODUCT_PUBLISH_FILES_STATUS_SUCCESS](state, action) {
      return state
        .set("productFilesPublishStatus", action.data)
        .set("isPublishingToPreview", action.data.preview && action.data.preview.status === "active")
        .set("isPublishingToProd", action.data.prod && action.data.prod.status === "active")
    },
    [SET_PUBLISHING_PREVIEW_STATUS](state, action) {
      return state.set("isPublishingToPreview", action.status)
    },
    [SET_PUBLISHING_PROD_STATUS](state, action) {
      return state.set("isPublishingToProd", action.status)
    },
    [ACTION_FETCH_PRODUCT_PUBLISH_HISTORY_SUCCESS](state, action) {
      return state.set("productFilesPublishHistory", action.data)
    },
    [ACTION_FETCH_PRODUCTS_END](state) {
      return state.set("fetching", false);
    },

    // TODO denna krockar med ACTION_FETCH_PRODUCTS_START
    [ACTION_FETCH_PRODUCT_DETAILS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_PRODUCT_DETAILS_SUCCESS](state, action) {
      return state
        .set("workflowMap", action.data.workflowMap)
        .set("permissions", modifyPermissionsArray(action.data.permissions))
        .set("selectedItem", action.data.entity);
    },
    [ACTION_FETCH_PRODUCT_STATUS_SUMMARY_SUCCESS](state, action) {
      return state.set("statusSummary", action.data.summary);
    },
    // TODO denna krockar med ACTION_FETCH_PRODUCTS_END
    [ACTION_FETCH_PRODUCT_DETAILS_END](state) {
      return state.set("fetching", false);
    },

    [ACTION_ACTIVATE_PRODUCT](state, action) {
      return state.set("selectedItem", action.entity);
    },
    [ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS](
      state,
      { data: { productTypes, stages, subjects, topics } }
    ) {
      return state
        .set("types", toListWithMaps(productTypes))
        .set("stages", toListWithMaps(stages))
        .set("subjects", toListWithMaps(subjects))
        .set("topics", toListWithMaps(topics));
    },
    [ACTION_FETCH_PRODUCT_FILES_SUCCESS](state, { data }) {
      return state.set("mediaFiles", data);
    },
    [ACTION_UPDATE_PRODUCT_START](state) {
      return state.set("saving", true);
    },
    [ACTION_UPDATE_PRODUCT_END](state, { entity }) {
      return updateProduct(state, entity).set("saving", false);
    },
    [ACTION_ADD_PRODUCT_TO_TEACHERS_GUIDE_SUCCESS](
      state,
      { payload: { teachersGuideId, entityId } }
    ) {
      const list = state.get("list");
      const productToUpdate = list.find(p => p.id === entityId);

      return state.set(
        "list",
        list.set(
          list.findIndex(p => p.id === entityId),
          {
            ...productToUpdate,
            teacherGuides: [
              ...(productToUpdate.productToUpdate || []),
              teachersGuideId
            ]
          }
        )
      );
    },
    [ACTION_REMOVE_PRODUCT_FROM_TEACHERS_GUIDE_SUCCESS](
      state,
      { payload: { teachersGuideId, entityId } }
    ) {
      const list = state.get("list");
      const productToUpdate = list.find(p => p.id === entityId);

      return state.set(
        "list",
        list.set(
          list.findIndex(p => p.id === entityId),
          {
            ...productToUpdate,
            teacherGuides: productToUpdate.teacherGuides.filter(
              id => id !== teachersGuideId
            )
          }
        )
      );
    },
    [ACTION_FETCH_AVAILABLE_SESAMGROUPS_SUCCESS]( state, action ) {
      return state.set("availableSesamgroups", action.data.sesamgroups)
    }
  });
};

export default reducer;
