import React from "react";
import {
  StyledTopWrapper,
  StyledWrapper,
  StyledContainer,
  StyledSaveResetButtonsContainer,
  StyledJsonTitle,
  StyledUploadButtonWrapper,
  StyledHeadingContainer,
  StyledProgressBarContainer,
  StyledTitle,
  StyledUploadContainer,
  StyledHeaderTitle,
  StyledUploadDescriptions,
  StyledLabels,
  StyledInvalidFilesContainer,
  StyledInvalidRowWrapper,
  StyledTitlesWrapper,
  StyledInvalidLabel,
  StyledCloseButtonContainer
} from "./StyledAdventuresCardEditor";
import JSONPreviewContainer from "./JSONPreviewContainer";
import { connect } from "react-redux";
import TextArea from "../../../shared/components/TextArea";
import { updateJsonDraft, resetJsonDraft } from "../../api/actions";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import { PrimaryButton, StandardButton, addFlash } from "../../../shared";
import {
  deleteMediaItem,
  deleteMediaSection,
  saveEntity,
  uploadingDone
} from "../../sagas/actions";
import {
  StyledBackLink,
  StyledBackLinkContainer
} from "../../components/StyledList";
import Link from "../../../shared/components/Link";
import { getPreviousRoute } from "../../store/selectors";
import AdventureFileList from "./AdventureFileList/AdventureFileList";
import UploadMedia from "../../../shared/components/UploadMedia";
import { uploadMediaFiles } from "../../api/requests";
import {
  selectAdventureCardId,
  selectAdventureGroupId,
  selectAdventureId
} from "../../sagas/selectors";
import { selectEntityId } from "../../../store/selectors";
import { translationErrorMessage } from "../../../constants";
import ProgressBar from "../../../shared/components/ProgressBar/ProgressBar";

const AdventuresCardEditor = props => {
  const [state, setState] = React.useState({
    isUploading: false,
    progress: 0,
    uploaded: [],
    failed: [],
    fileCount: 0
  });

  const onChange = data => {
    let draftValid = true;
    try {
      JSON.parse(data);
    } catch (err) {
      console.log(err.message);
      draftValid = false;
    }
    props.onUpdateJsonDraft({ data, validData: draftValid });
  };

  const revert = () => props.onResetJsonDraft();

  const save = () => props.onSave();

  const mediaUploadCallback = file => {
    let count = 0;
    let failed = [];
    let uploaded = [];

    setState(prevState => ({
      ...prevState,
      isUploading: true,
      fileCount: file.files.length
    }));

    file.files.forEach(item => {
      uploadMediaFiles(
        item,
        props.entityId,
        props.adventureGroupId,
        props.adventureId,
        props.adventureCardId
      ).then(res => {
        count = count + 1;
        const updatedProgress = (count / file.files.length) * 100;

        if (res && res.data) {
          uploaded.push(res.data);
        } else {
          const { response } = res;
          failed.push({
            filename: item.name,
            error: response.data.message,
            key: count
          });
        }

        setState(prevState => ({
          ...prevState,
          uploaded,
          failed,
          progress: updatedProgress
        }));
        if (updatedProgress === 100) props.onUploadingDone();
      });
    });
  };

  const endUploading = () => {
    setState({
      isUploading: false,
      progress: 0,
      uploaded: [],
      failed: [],
      fileCount: 0
    });
  };

  const deleteItemCallback = id => {
    props.onDeleteItem(id);
  };

  const deleteMediaSectionCallback = mediaType => {
    props.onDeleteMediaSecton(mediaType);
  };

  const renderProgressBar = percentage => (
    <StyledProgressBarContainer>
      <StyledTitle>
        {percentage === 100 ? "Klart" : "Laddar upp filer..."}
      </StyledTitle>
      <ProgressBar percentage={percentage} />
    </StyledProgressBarContainer>
  );

  /**
   * If some files are invalid, a compilation of all invalid files with error message, is rendered.
   */
  const renderInvalidFiles = failed => (
    <StyledInvalidFilesContainer>
      <StyledTitlesWrapper>
        <StyledTitle>Undantagna filer:</StyledTitle>
        <StyledTitle>Orsak:</StyledTitle>
      </StyledTitlesWrapper>
      {failed.map(file =>
        renderInvalidFileRow(file.filename, file.error, file.key)
      )}
    </StyledInvalidFilesContainer>
  );

  /**
   * Renders a single file with error message.
   */
  const renderInvalidFileRow = (filename, error, key) => (
    <StyledInvalidRowWrapper key={key}>
      <StyledInvalidLabel>{filename}</StyledInvalidLabel>
      <StyledInvalidLabel>
        {translationErrorMessage[error]
          ? translationErrorMessage[error]
          : error}
      </StyledInvalidLabel>
    </StyledInvalidRowWrapper>
  );

  const renderUploading = () => (
    <StyledUploadContainer>
      <StyledHeaderTitle>{"Uppladdning av filer"}</StyledHeaderTitle>
      <StyledUploadDescriptions>
        <StyledLabels>{`Antal filer att ladda upp: ${state.fileCount}`}</StyledLabels>
        {renderProgressBar(state.progress)}
        {state.progress === 100 &&
          state.failed.length !== 0 &&
          renderInvalidFiles(state.failed)}
      </StyledUploadDescriptions>
      <StyledCloseButtonContainer>
        <PrimaryButton onClick={endUploading}>STÄNG</PrimaryButton>
      </StyledCloseButtonContainer>
    </StyledUploadContainer>
  );

  /**
   * Renders save reset buttons
   * @returns {null}
   */
  const renderSaveResetButtons = (draftState, validData) => {
    return draftState === "dirty" ? (
      <StyledSaveResetButtonsContainer>
        <ButtonGroup>
          <StandardButton onClick={revert}>Återställ</StandardButton>
          <PrimaryButton onClick={save} disabled={!validData}>
            Spara
          </PrimaryButton>
        </ButtonGroup>
      </StyledSaveResetButtonsContainer>
    ) : null;
  };

  /**
   * Render button that navigates back to previous view
   */
  const renderBackLink = () =>
    props.prevUrl ? (
      <StyledBackLinkContainer>
        <Link to={props.prevUrl}>
          <StyledBackLink>TILLBAKA</StyledBackLink>
        </Link>
      </StyledBackLinkContainer>
    ) : null;

  const renderDraftEditor = () => (
    <>
      <StyledTopWrapper>
        <StyledJsonTitle>{props.json.title}</StyledJsonTitle>
        {renderBackLink()}
      </StyledTopWrapper>
      <StyledWrapper>
        {state.isUploading && renderUploading()}
        <StyledContainer>
          <StyledHeadingContainer>
            <h1>Json</h1>
          </StyledHeadingContainer>
          {
            <TextArea
              value={props.jsonDraft.data}
              onChange={onChange}
              disabled={false}
            />
          }
        </StyledContainer>
        <StyledContainer>
          <StyledHeadingContainer>
            <h1>Förhandsgranskning</h1>
          </StyledHeadingContainer>
          <JSONPreviewContainer
            mediaList={props.mediaList}
            json={props.jsonDraft && props.jsonDraft.data}
          />
        </StyledContainer>
        <StyledContainer maxWidth={"300"}>
          <StyledHeadingContainer>
            <h1>Fillista</h1>
          </StyledHeadingContainer>
          <StyledUploadButtonWrapper>
            <UploadMedia
              skin={"tomoyo"}
              outline
              multiple={true}
              type={"all"}
              callback={mediaUploadCallback}
            >
              FILUPPLADDNING
            </UploadMedia>
          </StyledUploadButtonWrapper>
          <AdventureFileList
            deleteMediaSectionCallback={deleteMediaSectionCallback}
            deleteItemCallback={deleteItemCallback}
            fileList={props.mediaList}
          />
        </StyledContainer>
        {renderSaveResetButtons(props.draftState, props.jsonDraft.validData)}
      </StyledWrapper>
    </>
  );

  return props.jsonDraft ? renderDraftEditor() : null;
};

const mapStateToProps = (state, { type }) => {
  return {
    json: state[type].get("json"),
    jsonDraft: state[type].get("jsonDraft"),
    draftState: state[type].get("draftState"),
    prevUrl: getPreviousRoute(state, type),
    mediaList: state[type].get("mediaList"),
    entityId: selectEntityId(state),
    adventureGroupId: selectAdventureGroupId(state),
    adventureId: selectAdventureId(state),
    adventureCardId: selectAdventureCardId(state)
  };
};

const mapDispatchToProps = (dispatch, { type }) => ({
  onUpdateJsonDraft: value => dispatch(updateJsonDraft(type, value)),
  onResetJsonDraft: item => dispatch(resetJsonDraft(type, item)),
  onSave: () => dispatch(saveEntity(type)),
  showFlash: (message, flashType) => dispatch(addFlash(message, flashType)),
  onUploadingDone: () => dispatch(uploadingDone(type)),
  onDeleteItem: id => dispatch(deleteMediaItem(type, id)),
  onDeleteMediaSecton: mediaType =>
    dispatch(deleteMediaSection(type, mediaType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdventuresCardEditor);
