import React from "react";

const Division = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="-2 -2 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-division"
  >
  <path d="M16.3636364,9.03225806 L0,9.03225806 L0,7.09677419 L16.3636364,7.09677419 L16.3636364,9.03225806 M8.48484848,0 C9.48900258,0 10.3030303,0.866545645 10.3030303,1.93548387 C10.3030303,3.0044221 9.48900258,3.87096774 8.48484848,3.87096774 C7.48069439,3.87096774 6.66666667,3.0044221 6.66666667,1.93548387 C6.66666667,0.866545645 7.48069439,0 8.48484848,0 M8.48484848,12.2580645 C9.48900258,12.2580645 10.3030303,13.1246102 10.3030303,14.1935484 C10.3030303,15.2624866 9.48900258,16.1290323 8.48484848,16.1290323 C7.48069439,16.1290323 6.66666667,15.2624866 6.66666667,14.1935484 C6.66666667,13.1246102 7.48069439,12.2580645 8.48484848,12.2580645 Z" id="path-3"></path>
 
</svg>
);

export default Division;
