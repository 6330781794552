import React from "react";
import styled from "styled-components";
import { StandardButton, Button } from "../../../shared";
import TagsInMedia from "./TagsInMedia";

export const StyledTagPostWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

export const StyledTagMedia = styled.div`
  display: flex;
`;

export const MediaButton = styled(StandardButton)`
  padding: 15px 20px;
  height: auto;
  color: #000;
  &:active,
  &:focus {
    color: grey;
    &:hover {
      color: lightgrey;
    }
  }
`;

export const StyledTagPostContent = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: ${props => (props.detailview ? "-255px" : "10px")};
  right:0px;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  will-change: transform, opacity;
  z-index: 4;
  height: 50vh;
  min-height: 350px;
  padding-bottom: 10px;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledHeaderTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
`;

export const StyledTop = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
  position: fixed;
  width: 100%;
  top: 0;
`;
export const StyledTitle = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;

export const StyledMiddle = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;
  min-height: 300px;
  overflow-y: scroll;
  height: calc(50vh - 52px);
  padding-bottom: 35px;
`;

export const StyledMiddleContent = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

export const StyledBottom = styled.div`
  width: 100%;
  bottom: 0;
  position: fixed;
  border-top: 1px solid ${props => props.theme.neutralLight};
  padding: ${props => (props.detailview ? 0 : "10px 5px")};
  height: 52px;
  background-color: white;
  z-index: 5;
`;

export const StyledUpdateButton = styled(props => (
  <StandardButton {...props} />
))`
  width: 280px;
  margin: 10px;
  border-radius: 5px;
`;

export const StyledInlineSectionButton = styled(props => <Button {...props} />)`
  background-color: transparent;
  border: 0;
  min-width: 25px;

  &:hover,
  &:active {
    background-color: transparent;
  }
`;
export const StyledInlineTagDisplay = styled(TagsInMedia)`
  postition: absolute;
  left: 0;
`;
