import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "../../../products/components/IconButton";
import {
  ChevronRight,
  ChevronDown
} from "../../../shared/components/StudliIcons";

const StyledNodeWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 0.3rem;
`;

const StyledNodeChildrenWrapper = styled.div`
  margin-left: 2.5rem;
  padding: 0.6rem 0;
`;

const StyledNode = styled.div`
  padding: 0.2rem 0;
`;

const PreparedAssignmentsNode = ({ node }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleButton = () => setIsOpen(!isOpen);

  return (
    <>
      {node && (
        <>
          <StyledNodeWrapper>
            <StyledIconButton onClick={toggleButton}>
              {isOpen ? <ChevronDown size="10" /> : <ChevronRight size="10" />}
            </StyledIconButton>
            <span>{node.title}</span>
          </StyledNodeWrapper>
          {node.contents && node.contents.length > 0 && isOpen && (
            <StyledNodeChildrenWrapper>
              {node.contents.map((n, index) => {
                const key = index+n.title;
                return n.contents ?
                <PreparedAssignmentsNode key={key} node={n} />
              :
                <StyledNode key={key}>{n.title}</StyledNode>

              })}
            </StyledNodeChildrenWrapper>
          )}
        </>
      )}
    </>
  );
};

export default PreparedAssignmentsNode;
