/**
 * Helper function to extract all media urls used in a post or media resource
 * @param {string} str JSON-encoded string of data
 * @returns {array} an array of urls
 */
export const getUsedMediaFilesFromDataString = str => {
  let regex = /("path"|"image"|"src"|"audio"|"assetpath"|"mediasrc"|"soundsrc")\s*:\s*"([^"]+)"/gi;
  let matches,
    output = [];
  // eslint-disable-next-line
  while ((matches = regex.exec(str))) {
    output.push(matches[2]);
  }
  return output.filter((url, i, a) => a.indexOf(url) === i);
};
