import { Column } from "react-virtualized";
import CountWithInfoRenderer from "../Renderer/CountWithInfo";

const CountWithInfoColumn = () => null;

CountWithInfoColumn.propTypes = Object.assign({}, Column.propTypes);
CountWithInfoColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: CountWithInfoRenderer,
  draggable: true
});

export default CountWithInfoColumn;