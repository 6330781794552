import { getContrast } from "polished";

/**
 * capitalize first letter of a string
 * @param text
 * @returns {string}
 */
export const upperCaseFirst = text =>
  text
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const camelCaseToPascalCase = s =>
  s.charAt(0).toUpperCase() + s.substring(1);

export const getTextColorByContrast = background => {
  const contrastRatio = background ? getContrast(background, "#333333") : 20;

  return contrastRatio <= 4.5 ? "#FFFFFF" : "#333333";
};

/**
 * Function that returns an id
 * @returns {string}
 */
export const guid = () => {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

/**
 * @returns {string}
 */
const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

/**
 * Returns true if value is numeric
 * @param {*} value
 */
export const isNumeric = value => !isNaN(parseFloat(value)) && isFinite(value);

/**
 * Returns true if str is a postiver integer, where 0 is not allowed
 * @param {*} str
 */
export const isPositiveInteger = str => {
  const number = Math.floor(Number(str));
  return number !== Infinity && String(number) === str && number > 0;
};

/**
 * Used to see if the string is a JSON string or not.
 * @param  str
 */
export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Returns an array with indices of all occurances of a value
 * @param {*} arr
 * @param {*} val
 */
export const getAllIndexes = (arr, val) => {
  let indexes = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].value === val) {
      indexes.push(i);
    }
  }
  return indexes;
};

/**
 * Shortens the text and applies the 3 dots at the end.
 * Used where text-overflow: ellipsis doesnt apply
 * @param {*} text
 * @param {*} maxTextLength
 */
export const shorten = (text, maxTextLength) => {
  let ret = text;
  if (ret.length > maxTextLength) {
    ret = ret.substr(0, maxTextLength - 3) + "...";
  }
  return ret;
};

export const getRouteTypeFromPostType = (postType, entityId, entityType, systems) => ({
    EXERCISE: "ROUTE_EXERCISES",
    RESOURCE: "ROUTE_RESOURCES",
    MEDIA: getMediaRoute(entityId, entityType, systems),
    MEDIARESOURCE: "ROUTE_MEDIARESOURCES",
    STORYTEXT: "ROUTE_STORY_TEXTS",
    HELPRESOURCE: "ROUTE_HELP_RESOURCES",
    AIDA: "ROUTE_AIDA_LIST",
    PRODUCTNEWS: "ROUTE_PRODUCT_NEWS",
    OPMESSAGE: "ROUTE_OP_MESSAGES"
  }[postType.toUpperCase()]);

export const isHTML = str => {
  const a = document.createElement("div");
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
};

export const getMediaRoute = (entityId, entityType, systems) => {
  if (entityId) {
    const system = systems.find(sys => sys.id === entityId);
    if (system) {
      switch (system.title) {
        case "Aida":
          return "ROUTE_AIDA_MEDIA";
        case "messageservice":
          return "ROUTE_MESSAGES_MEDIA";
        case "omnia":
          return "ROUTE_OMNIA_MEDIA";
        default:
          return "ROUTE_MEDIA";
      }
    }
  }

  if (entityType) {
    switch (entityType) {
      case "series":
        return "ROUTE_SERIES_MEDIA";
      case "classroomgroup":
        return "ROUTE_CLASSROOM_MEDIA";
      case "product":
        return "ROUTE_PRODUCT";
      default:
       return "ROUTE_MEDIA";
    }
  }

  return "ROUTE_MEDIA"
};

