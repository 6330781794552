import React from "react";
import { connect } from "react-redux";
import MediaListItem from "../../components/MediaListItem";
import MediaListInfoService from "../../components/MediaListInfoService";
import MediaDropZone from "../../components/MediaDropZone/MediaDropZone";
import ButtonGroup from "../../../shared/components/ButtonGroup";
import { selectCurrentMediaLib, selectFlattenTagsAndLabels } from "../../api/selectors";
import { reloadMedia } from "../../store/actions";
import { setItems } from "../../store/carousel/actions";
import {
  ListMedia,
  MediaListWrapper,
  MediaListActionRow,
  ToggleButtonsSection,
  MediaActionButtonList,
  MediaActionButton
} from "./StyledMediaList";

import {
  MEDIA,
  MEDIA_RESOURCE
} from "../../../constants";
import { reloadPosts } from "../../../posts/store/actions";
import { addFlash } from "../../../shared";
import {
  selectEntityId,
  selectEntityType,
  selectUploadBasePath
} from "../../../store/selectors";
import { getPostUsageFromMediaData } from "./helpers";

const MediaList = props => {
  const extractData = (section, itemData) => {
    const isMedia = section === MEDIA;

    const format = isMedia
      ? itemData.original_filename
          .split(".")
          .pop()
          .toUpperCase()
      : itemData.type;

    const postUsage = getPostUsageFromMediaData(
      itemData,
      itemData["entityUsage"] && "entityUsage"
    );

    const constenttype = isMedia ? itemData.content_type : null;
    const title = isMedia ? itemData.original_filename : itemData.title;
    const description = itemData.description || "no description";

    let resources = postUsage.resource || 0;
    let exercises = postUsage.exercise || 0;
    let mediaresources = postUsage.mediaresource || 0;
    let helpresources = postUsage.helpresource || 0;
    const aidaposts = postUsage.aida || 0;
    const series = postUsage.series || 0;
    const classroomgroup = postUsage.classroomgroup || 0;
    const marketmessage = postUsage.marketmessage || 0;

    if (section === MEDIA_RESOURCE) {
      resources = itemData.resource_usage_count || 0;
      exercises = itemData.exercise_usage_count || 0;
      mediaresources = itemData.mediaresource_usage_count || 0;
      helpresources = itemData.helpresource_usage_count || 0;
    }

    return {
      title,
      description,
      format,
      exercises,
      resources,
      mediaresources,
      helpresources,
      constenttype,
      aidaposts,
      series,
      classroomgroup,
      marketmessage
    };
  };

  const mediaItems = props.mediaItems;

  const ListItems = mediaItems.map((itemData, index) => {
    const {
      format,
      title,
      description,
      resources,
      exercises,
      mediaresources,
      helpresources,
      constenttype,
      aidaposts,
      series,
      classroomgroup,
      marketmessage
    } = extractData(props.section, itemData);
    const tagData = props.tagList.filter(
      tag => itemData.tags && itemData.tags.indexOf(tag.id) >= 0
    );
    return (
      <MediaListItem
        fileTitle={itemData.title}
        key={"item-" + itemData.id}
        title={title}
        description={description}
        id={itemData.id}
        labels={tagData}
        format={format}
        exercises={exercises}
        resources={resources}
        mediaresources={mediaresources}
        helpresources={helpresources}
        aidaposts={aidaposts}
        series={series}
        classroomgroup={classroomgroup}
        marketmessage={marketmessage}
        entity="MR"
        date={itemData.updated_at}
        src={itemData.url ? itemData.url : null}
        constenttype={constenttype}
        user="no info"
        data={itemData.data ? itemData.data : null}
        isSelected={itemData.selected ? itemData.selected : false}
        onClick={props.onSelectMedia.bind(null, index)}
        isGridView={props.isGridView}
        medialib={props.medialib}
      />
    );
  });
  return <ListMedia isGridView={props.isGridView}> {ListItems}</ListMedia>;
};

class MediaListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.initialLoadDone = false;

    this.state = {
      isSelected: false,
      isGridView: true,
      listLength: 50
    };
  }

  componentDidMount() {
    this.props.onSelect([]);
  }

  onSelectMedia = itemIndex => {
    const {
      onSelect,
      useCarousel,
      mediaItems = [],
      onSelectMedia,
      selectedMediaItems
    } = this.props;

    const selectedItem = mediaItems[itemIndex] || null;

    if (selectedItem) {
      let newSelectedMediaItems;
      if (useCarousel) {
        let isPrevSelected =
          selectedMediaItems.filter(item => item.id === selectedItem.id)
            .length > 0;
        newSelectedMediaItems = isPrevSelected
          ? selectedMediaItems.filter(item => item.id !== selectedItem.id)
          : [].concat(selectedMediaItems, selectedItem);
      } else {
        newSelectedMediaItems =
          selectedItem === selectedMediaItems[0] ? [] : [selectedItem];
      }

      if (onSelectMedia) {
        // used in modal
        onSelectMedia(newSelectedMediaItems);
      }
      // used by carousel
      onSelect(newSelectedMediaItems);
    }
  };

  onhandleList = () => {
    this.setState(state => ({ isGridView: false }));
  };

  onhandleGrid = () => {
    this.setState(state => ({ isGridView: true }));
  };

  render() {
    const {
      mediaItems,
      initialMediaresourceLoad,
      initialMediaLoad,
      section
    } = this.props;

    if (!mediaItems.length && !this.initialLoadDone) {
      setTimeout(() => {
        section === MEDIA_RESOURCE
          ? initialMediaresourceLoad()
          : initialMediaLoad();
      }, 10);
      this.initialLoadDone = true;
      return null;
    }

    return (
      <MediaListWrapper>
        {this.renderDropZone()}
        <MediaListActionRow>
          {this.renderListViewButtons()}
          {this.renderMediaListInfo()}
        </MediaListActionRow>
        {this.renderMediaList()}
      </MediaListWrapper>
    );
  }

  /**
   * Add selected property to those items in medialist that corresponds to the id's in selectedMediaItems
   */
  addSelectedToMediaItems = () => {
    const { mediaItems } = this.props;
    const selectedIds = this.props.selectedMediaItems.map(i => i.id);
    return mediaItems.map(item =>
      selectedIds.indexOf(item.id) >= 0 ? { ...item, selected: true } : item
    );
  };

  /**
   * Render media list
   */
  renderMediaList = () => {
    const { isGridView, listLength } = this.state;
    const mediaItems = this.addSelectedToMediaItems();
    return (
      <MediaList
        section={this.props.section}
        onLoadMore={this.onLoadMore}
        onSelectMedia={this.onSelectMedia}
        listLength={listLength}
        isGridView={isGridView}
        mediaItems={mediaItems}
        singleSelect={!this.props.useCarousel}
        tagList={this.props.tagsAndLabels}
        medialib={this.props.medialib}
      />
    );
  };

  /**
   * Render medialist info (how many audio, image and video etc )
   */
  renderMediaListInfo = () => {
    const { meta, useCarousel, selectedMediaItems } = this.props;

    return (
      <MediaListInfoService
        section={this.props.section}
        hasItems={!!(useCarousel && selectedMediaItems.length)}
        meta={meta}
        selectedMediaItems={selectedMediaItems.length}
      />
    );
  };

  /**
   * Render List- and GridView Buttons
   */
  renderListViewButtons = () => (
    <ToggleButtonsSection>
      <ButtonGroup>
        <MediaActionButton
          icon="Grid"
          outline
          onClick={this.onhandleGrid}
          isGridView={this.state.isGridView}
        />
        <MediaActionButtonList
          icon="List"
          outline
          onClick={this.onhandleList}
          isGridView={this.state.isGridView}
        />
      </ButtonGroup>
    </ToggleButtonsSection>
  );

  /**
   * Render dropzone for file upload
   */
  renderDropZone = () => {
    const { showDropZone, onUploadMedia } = this.props;
    return !!showDropZone ? (
      <MediaDropZone
        isGridView={this.state.isGridView}
        onUploadMedia={onUploadMedia}
        onShowUploadMedia={this.props.onShowUploadMedia}
        showFlash={this.props.showFlash}
        entityId={this.props.entityId}
        entityType={this.props.entityType}
        basePath={this.props.basePath}
      />
    ) : null;
  };
}

const mapStateToProps = (state, props) => {
  let isActive = null;
  let mediaList = null;
  let tagsAndLabels = null;
  let meta = null;

  if (props.section === MEDIA_RESOURCE) {
    isActive = state.MediaCarousel["active"];
    mediaList = state.Mediaresource.get("list").toJS();
    tagsAndLabels = selectFlattenTagsAndLabels(state, "Mediaresource");
  } else {
    isActive = state.MediaCarousel["active"];
    mediaList = state.Media.get("list").toJS();
    tagsAndLabels = selectFlattenTagsAndLabels(state, "Media");
    meta = state.Media.get("meta");
  }

  return {
    active: isActive,
    mediaItems: mediaList,
    tagsAndLabels: tagsAndLabels,
    selectedMediaItems: state.MediaCarousel.items,
    meta: meta,
    entityId: selectEntityId(state),
    entityType: selectEntityType(state),
    medialib: selectCurrentMediaLib(state),
    basePath: selectUploadBasePath(state),
  };
};

const mapDispatchToProps = dispatch => ({
  initialMediaLoad: () => dispatch(reloadMedia()),
  initialMediaresourceLoad: () => dispatch(reloadPosts(MEDIA_RESOURCE)),
  onSelect: items => setItems(items)(dispatch),
  showFlash: (message, type) => dispatch(addFlash(message, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaListContainer);
