import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const BarSectionHeader = ({ className, title, primaryAction }) => {
  const actionRow = primaryAction ? primaryAction : null;

  return (
    <HeaderWrapper className={className}>
      <section style={{ alignSelf: "flex-start" }}>{title}</section>
      <section style={{ alignSelf: "flex-end", color: "#B0B0B0" }}>
        {actionRow}
      </section>
    </HeaderWrapper>
  );
};

export default styled(BarSectionHeader)`
  ${props => props.theme.d400()};
  margin-top: 10px;
  color: #000;
  letter-spacing: 0.02em;
  font-size: 14px;
  padding: 0 ${props => props.theme.baselineNumber * 1}px;
  padding-bottom: 8px;
`;
