import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../products/components/Checkbox";

const StyledListItemWrapper = styled.li`
  padding-left: 20px;
  color: ${props => props.theme.neutralDark};
`;

const TreeChips = ({ node, onChange, chipsSel }) => {
  const [isNodeChecked, setIsNodeChecked] = useState(false);

  useEffect(() => {
    if (chipsSel) {
      setIsNodeChecked(chipsSel.includes(node.id));
    }
  }, [chipsSel, node.id]);

  const _onNodeChange = val => {
    setIsNodeChecked(val);
    if (!val) {
      onChange({ remove: [node.id] });
    } else {
      onChange({ add: [node.id] });
    }
  };

  return (
    <StyledListItemWrapper>
      <Checkbox
        label={node.title}
        checked={isNodeChecked}
        onChange={_onNodeChange}
      />
    </StyledListItemWrapper>
  );
};

export default TreeChips;
