import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 40px;
`;

const ImageRenderer = ({ cellData }) => <StyledImage src={cellData} />;

export default ImageRenderer;
