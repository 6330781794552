import styled from "styled-components";

export const StyledIndex = styled.div`
  background: #cecece;
  display: flex;
  width: 30px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 4px;
  margin-right: 10px;
  padding: 8px;
  font-weight: 600;
`;

export const StyledIndexInput = styled.input`
  display: flex;
  width: 30px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 10px;

  ${({ isValidIndex }) =>
    isValidIndex === true ? "background: #cecece" : "background: #ff0000"}
`;
