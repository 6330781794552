import { Column } from "react-virtualized";
import ErrorRenderer from "../Renderer/Error";

const ErrorColumn = () => null;

ErrorColumn.propTypes = Object.assign({}, Column.propTypes);
ErrorColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: ErrorRenderer,
  draggable: true
});

export default ErrorColumn;
