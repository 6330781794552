import * as React from "react";
import ContentEditable from "react-contenteditable";

export class JSONEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      json: JSON.stringify(JSON.parse(props.json), null, 2)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      json: JSON.stringify(JSON.parse(nextProps.json), null, 2)
    });
  }

  onChange = e => {
    const { blockKey } = this.props;

    const newState = {
      json: e.target.value.replace("<pre>", "").replace("</pre>", "")
    };

    this.props.onChange(blockKey, JSON.parse(newState.json));
    this.setState(newState);
  };

  render() {
    const { resources } = this.props;
    const isEmpty = Object.keys(resources).length === 0 ? true : false;

    return (
      <div className="JSONEditor">
        <ContentEditable
          html={`<pre>${this.state.json}</pre>`}
          onChange={this.onChange}
          disabled={!isEmpty}
        />
      </div>
    );
  }
}

export default JSONEditor;
