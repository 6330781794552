import {
  S,
  M,
  L,
  INTERACTIVE,
  TITLE,
  BORDER_RIGHT,
  BORDER_BOTTOM,
  ROW,
  COLUMN,
  STANDARD,
  EQUATION
} from "../../../constants";
import {
  StyledInteractiveWrapper,
  toggle,
  borderBottom,
  borderRight,
  trash
} from "./StyledChartButtonRow";
import { renderMediaButtons } from "../../../shared/MediaHandler/MediaHandler";

import ChartButton from "./ChartButton";

const ChartButtonsRow = ({
  isOperative,
  type,
  row,
  column,
  selection,
  onMediaChange,
  onMediaResourceChange,
  selectedCells,
  checkEverySelectedCell,
  radioButtonCallback,
  removeCellsCallback
}) => {
  const { direction, index } = selection;
  const key = direction === ROW ? COLUMN : ROW;
  const firstColumnSelected = direction === COLUMN && index === 0;
  const firstRowSelected = direction === ROW && index === 0;
  const oneSelectedCell = selectedCells.length === 1;
  const hasSelectedCells = selectedCells.length !== 0;
  const selectedCell = hasSelectedCells && selectedCells[0];
  const minumumCellsSelected = direction && row > 1 && column > 1;
  const columnEquationDelete = direction === COLUMN && type === EQUATION;

  return (
    <StyledInteractiveWrapper>
      {isOperative && <ChartButton
        show={hasSelectedCells}
        id="button_row_interactive"
        isActive={checkEverySelectedCell(selectedCells, INTERACTIVE)}
        content={toggle(checkEverySelectedCell(selectedCells, INTERACTIVE))}
        callback={radioButtonCallback(INTERACTIVE)}
        title="Inputfält"
      />}
      {oneSelectedCell &&
        renderMediaButtons(0, "", onMediaChange, onMediaResourceChange)}
      <ChartButton
        show={direction}
        id="button_row_title"
        isActive={checkEverySelectedCell(selectedCells, TITLE)}
        content="Rubrik"
        xButtonStyles={{
          marginLeft: "5px",
          width: "70px"
        }}
        callback={radioButtonCallback(TITLE)}
      />
      <ChartButton
        show={direction && type === STANDARD}
        id="button_row_size_s"
        isActive={checkEverySelectedCell(selectedCells, key, S)}
        content="S"
        callback={radioButtonCallback(key, S)}
      />
      <ChartButton
        show={direction && type === STANDARD}
        id="button_row_size_m"
        isActive={checkEverySelectedCell(selectedCells, key, M)}
        content="M"
        callback={radioButtonCallback(key, M)}
      />
      <ChartButton
        show={direction && type === STANDARD}
        id="button_row_size_l"
        isActive={checkEverySelectedCell(selectedCells, key, L)}
        content="L"
        callback={radioButtonCallback(key, L)}
      />
      <ChartButton
        show={hasSelectedCells}
        id="button_row_color"
        isActive={false}
        content="Färg"
        xButtonStyles={{ marginLeft: "5px" }}
        xOverrideStyles={{ backgroundColor: selectedCell.color }}
      />
      <ChartButton
        show={minumumCellsSelected && !columnEquationDelete}
        id="button_row_trash"
        isActive={false}
        content={trash}
        callback={removeCellsCallback}
      />
      <ChartButton
        show={firstRowSelected && type === STANDARD}
        id="button_row_border_bottom"
        isActive={checkEverySelectedCell(selectedCells, BORDER_BOTTOM)}
        content={borderBottom(
          checkEverySelectedCell(selectedCells, BORDER_BOTTOM)
        )}
        callback={radioButtonCallback(BORDER_BOTTOM)}
      />
      <ChartButton
        show={firstColumnSelected && type === STANDARD}
        id="button_row_border_right"
        isActive={checkEverySelectedCell(selectedCells, BORDER_RIGHT)}
        content={borderRight(
          checkEverySelectedCell(selectedCells, BORDER_RIGHT)
        )}
        callback={radioButtonCallback(BORDER_RIGHT)}
      />
    </StyledInteractiveWrapper>
  );
};

export default ChartButtonsRow;
