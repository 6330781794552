import { takeEvery, all, fork } from "redux-saga/effects";
import { ROUTE_AIDA, ROUTE_OMNIA } from "../routes";
import { ROUTE_AIDA_LIST } from "../../posts/routes";
import {
  onAidaRoute,
  onAidaHelpActivated,
  onFetchSupportSystemDetails,
  onFetchSupportSystems
} from "./sagas";

import {
  ACTION_ACTIVATE_AIDAHELP,
  ACTION_FETCH_SYSTEMS_DETAILS,
  ACTION_FETCH_SYSTEMS,
} from "../api/actions";

function* watchAidaHelpActivated() {
  yield takeEvery(ACTION_ACTIVATE_AIDAHELP, onAidaHelpActivated);
}

function* watchFetchSupportSystemDetails() {
  yield takeEvery(ACTION_FETCH_SYSTEMS_DETAILS, onFetchSupportSystemDetails);
}

function* watchOmniaRoute() {
  yield takeEvery(ROUTE_OMNIA, onFetchSupportSystemDetails);
}

function* watchAidaRoute() {
  yield takeEvery(ROUTE_AIDA, onAidaRoute);
}

function* watchAidaListRoute() {
  yield takeEvery(ROUTE_AIDA_LIST, onAidaRoute);
}

function* watchFetchSupportSystems() {
  yield takeEvery(ACTION_FETCH_SYSTEMS, onFetchSupportSystems);
}

export default function* rootSaga() {
  yield all([
    fork(watchAidaHelpActivated),
    fork(watchFetchSupportSystemDetails),
    fork(watchAidaRoute),
    fork(watchAidaListRoute),
    fork(watchFetchSupportSystems),
    fork(watchOmniaRoute)
  ]);
}
