import {
  ContentState,
  convertToRaw,
  EditorState,
  Modifier,
  convertFromHTML
} from "draft-js";

import htmlToDraft from "html-to-draftjs";

export class TextParser {
  tags;

  constructor(customtags = {}) {
    this.tags = {
      BOLD: { pre: "<strong>", post: "</strong>" },
      ITALIC: { pre: "<em>", post: "</em>" },
      UNDERLINE: { pre: "<ins>", post: "</ins>" },
      CODE: { pre: "<code>", post: "</code>" },
      "header-one": { pre: "<h1>", post: "</h1>" },
      "header-two": { pre: "<h2>", post: "</h2>" },
      "header-three": { pre: "<h3>", post: "</h3>" },
      SUBSCRIPT: { pre: "<sub>", post: "</sub>" },
      SUPERSCRIPT: { pre: "<sup>", post: "</sup>" },
      unstyled: { pre: "", post: "" },
      ...customtags
    };
  }

    /**
   * Get editor state from data
   * @param text
   * @returns {string}
   */
     transformTextToHTML(text) {
      let txt = text ? text : "";
      txt = txt.indexOf("<br>") >= 0
        ? `<p>${txt.split("<br>").join("</p><p>")}</p>`
        : txt;
      txt = txt.split("<p><h1>").join("<h1>").split("</h1></p>").join("</h1>");
      txt = txt.split("<p><h2>").join("<h2>").split("</h2></p>").join("</h2>");
      txt = txt.split("<p><h3>").join("<h3>").split("</h3></p>").join("</h3>");
      return txt
    }  

  /**
   * Parse to raw text
   * @param raw
   * @returns {string}
   */
  parseRawToData(raw) {
    let blocks = raw.blocks;
    return blocks.map((block, i) => {
      if (block.text === "") {
        return "";
      }

      let inlineStyles = block.inlineStyleRanges;
      let textArr = block.text.split("");

      return this.formatText(
        textArr,
        inlineStyles,
        block.type,
        block.text.length
      );
    });
  }

  /**
   * Formats the text by wrapping html tags between all the words instead
   * of wrapping tags between sentences.
   *
   * @param textArr
   * @param inlineStyles
   * @returns {string}
   */
  formatText = (textArr, inlineStyles, type, length) => {
    for (let i = 0; i < inlineStyles.length; i++) {
      const { style, offset, length } = inlineStyles[i];

      if (
        style === "CODE" ||
        style === "SUBSCRIPT" ||
        style === "SUPERSCRIPT"
      ) {
        textArr[offset] = this.tags[style].pre + textArr[offset];
        textArr[offset + length - 1] =
          textArr[offset + length - 1] + this.tags[style].post;
      } else if (this.tags[style]) {
        for (let i = 0; i < length; i++) {
          if (
            (i === 0 ||
            textArr[offset + i - 1] === " " ||
            offset + i - 1 === -1 ||
            textArr[offset + i - 1] === "[") &&
            textArr[offset + i] !== " "
          ) {
            textArr[offset + i] = this.tags[style].pre + textArr[offset + i];
          }
          if (
            (i === length - 1 ||
            offset + length === i ||
            textArr[offset + i + 1] === " " ||
            offset + i >= textArr.length - 1 ||
            textArr[offset + i + 1] === "]") &&
            textArr[offset + i] !== " "
          ) {
            textArr[offset + i] = textArr[offset + i] + this.tags[style].post;
          }
        }
      }
    }

    for (let i = 0; i < textArr.length; i++) {
      if (textArr[i].charAt(textArr[i].length - 1) === "[") {
        textArr[i] = "[" + textArr[i].slice(0, -1);
      } else if (textArr[i].charAt(0) === "]") {
        textArr[i] = textArr[i].substring(1) + "]";
      }
    }

    textArr[0] = this.tags[type].pre + textArr[0];
    textArr[length - 1] = textArr[length - 1] + this.tags[type].post;

    return textArr.join("");
  };

  /**
   * Get editor state from content state
   * @param contentState
   * @returns {EditorState}
   */
  getEditorStateFromContentState(contentState) {
    if (contentState.getBlockMap().size > 0) {
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  }

  /**
   * Get editor state from data
   * @param text
   * @returns {EditorState}
   */
  getStateFromData(text = null) {
    const textHtmlData = this.transformTextToHTML(text);
    const blocksFromHtml = htmlToDraft(textHtmlData);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return this.getEditorStateFromContentState(contentState);
  }

  /**
   * Moves the cursor to the end of the editor if no text is selected.
   * @param {} state
   */
  moveFocus(state) {
    const selection = state.getSelection();
    if (selection.anchorOffset !== selection.focusOffset) {
      return state;
    }
    return EditorState.moveFocusToEnd(state);
  }

  /**
   * Clears the content in the state.
   * @param {*} state
   */
  clearContent(state) {
    let contentState = ContentState.createFromText("");
    return EditorState.push(state, contentState, "");
  }

  /**
   * Replaces content of the state with the specified text, content has to be cleared first.
   * @param {*} state
   * @param {*} text
   */
  replaceContent(state, text) {
    let content = state.getCurrentContent();
    let selection = state.getSelection();
    let newContentState;

    text.length === 0
      ? (newContentState = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: 0
          }),
          text
        ))
      : (newContentState = this.convertFromHtmlToText(text));

    return EditorState.push(state, newContentState, "");
  }

  /**
   * Creates new editorstate, clears the provided state and returns
   * a state containing the specified text.
   * @param {*} state
   * @param {*} text
   */
  createNewEditorState(state, text) {
    let clearedState = this.clearContent(state);
    let newState = this.replaceContent(clearedState, text);

    if (!newState.getCurrentContent().getPlainText()) {
      clearedState = this.clearContent(state);
      newState = this.replaceContent(clearedState, "");
    }

    return newState;
  }

  /**
   * Get the contents of the state in raw text format
   * @param {*} state
   */
  getRaw(state) {
    let raw = convertToRaw(state.getCurrentContent());
    return this.parseRawToData(raw).join("<br>");
  }

  /**
   * Convert from raw html text to text that can be used in the editor
   * @param {*} text
   */
  convertFromHtmlToText(text) {
    const blocksFromHTML = convertFromHTML(text);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return contentState;
  }

  /**
   *  Updates text
   * @param editorState
   * @param textEditorState
   * @returns {*}
   */
  onTextChange(editorState, textEditorState) {
    const rawData = convertToRaw(editorState.getCurrentContent());
    const tempText = this.parseRawToData(rawData).join("<br>");
    const prevRawData = convertToRaw(textEditorState.getCurrentContent());
    const prevText = this.parseRawToData(prevRawData).join("<br>");

    const text = tempText === "" ? null : tempText;

    return { didChange: prevText !== tempText, text };
  }
}
