import React, {useState} from "react";
import { StyledSectionName } from "../StyledJSONPreview";
import AdventureMediaItem from "./AdventureMediaItem/AdventureMediaItem";
import {
  StyledFileSectionTop,
  StyledFileSectionBlock,
  StyledMediaButton,
  StyledModalContent,
  StyledButton,
  StyledButtonWrapper
} from './StyledAdventureFileList';
import Modal from '../../../../shared/components/Modal/Modal';

const AdventureFileList = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(null);
  const [itemToRemoveId, setItemToRemoveId] = useState(null);
  const [isAudio, setIsAudio] = useState(null);

  const callbackToHandleDeleteItem = (itemId) => {
    setItemToRemoveId(itemId);
    setIsModalOpen(true);
    setIsDeletingAll(false);
  };

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleDelete = () => {
    if(!isDeletingAll && itemToRemoveId){
      deleteItem(itemToRemoveId);
    }
    if(isDeletingAll && isAudio !== null){
      isAudio === false ? deleteSection('image') : deleteSection('audio');
    }
    setIsDeletingAll(null);
    setItemToRemoveId(null);
    setIsAudio(null);
    setIsModalOpen(false);
  }

  const filterMediaItems = (type) => {
    let list = [];
    if (props.fileList) {
      Object.keys(props.fileList).forEach((item) => {
        if (props.fileList[item].type === type) {
          list.push(props.fileList[item]);
        }
        return undefined
      });
    }
    return list;
  }

  const deleteItem = (id) => {
    props.deleteItemCallback(id);
  }
  
  const deleteSection = (type) => {
    props.deleteMediaSectionCallback(type);
  }

  const deleteAllImages = () => {
    setIsModalOpen(true);
    setIsDeletingAll(true);
    setIsAudio(false);
  };
  
  const deleteAllAudio = () => {
    setIsModalOpen(true);
    setIsDeletingAll(true);
    setIsAudio(true);
  };

  const renderMediaItems = (mediaList) => (mediaList.map((item) => (
      <AdventureMediaItem 
        key={item.id} 
        id={item.id} 
        name={item.original_filename} 
        type={item.type} 
        url={item.url}
        callbackToHandleDeleteItem={callbackToHandleDeleteItem}
      />
  )))

  const imageList = filterMediaItems('image');
  const audioList = filterMediaItems('audio');

  const renderConfirmModal = () => (
    <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
      <StyledModalContent>
          Är du säker på att du vill ta bort?
          <StyledButtonWrapper>
          <StyledButton onClick={() => setIsModalOpen(false)}>Avbryt</StyledButton>
          <StyledButton onClick={handleDelete}>Ta bort</StyledButton>
          </StyledButtonWrapper>
        </StyledModalContent>
      </Modal>
  )

  return (
    <>
      <StyledFileSectionBlock>
        <StyledFileSectionTop>
          <StyledSectionName>Bilder</StyledSectionName>
          <StyledMediaButton outline skin={"tomoyo"} disabled={imageList.length < 1} onClick={deleteAllImages}>TA BORT ALLA BILDER</StyledMediaButton>
        </StyledFileSectionTop>
        {renderMediaItems(imageList)}
      </StyledFileSectionBlock>
      <StyledFileSectionBlock>
        <StyledFileSectionTop>
          <StyledSectionName>Ljud</StyledSectionName>
          <StyledMediaButton outline skin={"tomoyo"} disabled={audioList.length < 1} onClick={deleteAllAudio}>TA BORT ALLA LJUD</StyledMediaButton>
        </StyledFileSectionTop>
        {renderMediaItems(audioList)}
        {renderConfirmModal()}
      </StyledFileSectionBlock>
    </>
  )
}

export default AdventureFileList