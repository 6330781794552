import { RouterService, registerSaga, registerReducer } from "../shared";
import { routes, views } from "./routes";
import rootSaga from "./sagas";
import { carouselReducer } from "./store/carousel/reducer";
import {
  exercise as exerciseReducer,
  template as templateReducer,
  resource as resourceReducer,
  helpResource as helpResourceReducer,
  aida as aidaReducer
} from "./store/reducer";
import { ENTITIES, EXERCISE, RESOURCE, HELP_RESOURCE, AIDA } from "../constants";

export * from "./routes";

export const init = () => {
  Object.keys(routes).forEach(key => {
    RouterService.registerRoute(key, routes[key]);
    RouterService.setView(key, views[key]);
  });
  registerSaga(rootSaga);
  registerReducer(ENTITIES[EXERCISE], exerciseReducer);
  registerReducer("Template", templateReducer);
  registerReducer(ENTITIES[RESOURCE], resourceReducer);
  registerReducer(ENTITIES[HELP_RESOURCE], helpResourceReducer);
  registerReducer("Carousel", carouselReducer);
  registerReducer(ENTITIES[AIDA], aidaReducer);
};
