import { takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { ROUTE_SERIES, ROUTE_SERIES_DETAILS } from "../routes";
import {
  ACTION_ADD_PRODUCT_TO_SERIES,
  ACTION_CREATE_SERIES,
  ACTION_DELETE_PRODUCT_FROM_SERIES,
  ACTION_FETCH_SERIES,
  ACTION_FETCH_SERIES_DETAILS,
  ACTION_FILTER_SERIES,
  ACTION_UPDATE_SERIES,
  ACTION_DELETE_SERIES,
  SORT_SERIES,
  FETCH_SERIES_PERMISSIONS,
  FETCH_SERIES_WORKFLOW,
  PUBLISH_SERIES,
  PUBLISH_PREVIEW_SERIES,
  UNPUBLISH_SERIES
} from "../api/actions";
import {
  onSeriesDetailRoute,
  onCreateSeries,
  sortSeriesList,
  onFetchSeries,
  onSeriesListRoute,
  onFetchSeriesDetails,
  onUpdateSeriesDetails,
  onAddProductToSeries,
  onDeleteProductFromSeries,
  onFilterSeries,
  onDeleteSeries,
  previousPageSaga,
  nextPageSaga,
  loadPageSaga,
  onFetchSeriesPermissions,
  onFetchSeriesWorkflow,
  onPublishSeries,
  onPublishPreviewSeries,
  onUnpublishSeries
} from "./sagas";
import { ENTITIES, SERIES } from "../../constants";
import {
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE
} from "../../posts/store/actions";
import { actionCreator } from "../../shared";
import { onMediaFilterRoute } from "../../media/sagas/sagas";

function* watchSeriesRoute() {
  yield takeEvery(ROUTE_SERIES, onSeriesListRoute);
}

function* watchfetchSeries() {
  yield takeEvery(ACTION_FETCH_SERIES, onFetchSeries);
}

function* watchSortSeries() {
  yield takeEvery(SORT_SERIES, sortSeriesList);
}

function* watchCreateSeries() {
  yield takeLatest(ACTION_CREATE_SERIES, onCreateSeries);
}

function* watchSeriesDetailsRoute() {
  yield takeEvery(ROUTE_SERIES_DETAILS, onSeriesDetailRoute);
  yield takeEvery(
    ROUTE_SERIES_DETAILS,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
}

function* watchFetchSeriesDetails() {
  yield takeEvery(ACTION_FETCH_SERIES_DETAILS, onFetchSeriesDetails);
}

function* watchUpdateSeriesDetails() {
  yield takeEvery(ACTION_UPDATE_SERIES, onUpdateSeriesDetails);
}

function* watchAddProductToSeries() {
  yield takeEvery(ACTION_ADD_PRODUCT_TO_SERIES, onAddProductToSeries);
}

function* watchDeleteProductFromSeries() {
  yield takeEvery(ACTION_DELETE_PRODUCT_FROM_SERIES, onDeleteProductFromSeries);
}

function* watchDeleteSeries() {
  yield takeEvery(
    [ACTION_DELETE_SERIES, "SERIES/DELETE_POSTS"],
    onDeleteSeries
  );
}

function* watchPublishSeries() {
  yield takeEvery([PUBLISH_SERIES, "SERIES/PUBLISH_POSTS"], onPublishSeries);
}

function* watchUnpublishSeries() {
  yield takeEvery([UNPUBLISH_SERIES, "SERIES/UNPUBLISH_POSTS"], onUnpublishSeries);
}

function* watchPublishPreviewSeries() {
  yield takeEvery(
    [PUBLISH_PREVIEW_SERIES, "SERIES/PUBLISH_PREVIEW_POSTS"],
    onPublishPreviewSeries
  );
}

function* watchFilterSeries() {
  yield takeEvery(ACTION_FILTER_SERIES, onFilterSeries);
}

function* watchLoadLessSeries() {
  yield takeEvery(
    actionCreator(SERIES, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[SERIES],
    "listSeries"
  );
}

function* watchLoadMoreSeries() {
  yield takeEvery(
    actionCreator(SERIES, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[SERIES],
    "listSeries"
  );
}
function* watchLoadPageSeries() {
  yield takeEvery(
    actionCreator(SERIES, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[SERIES],
    "listSeries"
  );
}

function* watchfetchSeriesPermission() {
  yield takeEvery(FETCH_SERIES_PERMISSIONS, onFetchSeriesPermissions);
}

function* watchfetchSeriesWorkflow() {
  yield takeEvery(FETCH_SERIES_WORKFLOW, onFetchSeriesWorkflow);
}

export default function* rootSaga() {
  yield all([
    fork(watchSeriesRoute),
    fork(watchSeriesDetailsRoute),
    fork(watchfetchSeries),
    fork(watchSortSeries),
    fork(watchCreateSeries),
    fork(watchFetchSeriesDetails),
    fork(watchUpdateSeriesDetails),
    fork(watchDeleteSeries),
    fork(watchPublishSeries),
    fork(watchUnpublishSeries),
    fork(watchPublishPreviewSeries),
    fork(watchAddProductToSeries),
    fork(watchDeleteProductFromSeries),
    fork(watchFilterSeries),
    fork(watchLoadLessSeries),
    fork(watchLoadMoreSeries),
    fork(watchLoadPageSeries),
    fork(watchfetchSeriesPermission),
    fork(watchfetchSeriesWorkflow)
  ]);
}
