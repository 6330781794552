import React from "react";

const CheckboxSolidEmpty = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-checkboxsolidempty"
  >
    <path
      fill="currentColor"
      d="M44.6428571,0 L5.35714286,0 C2.39955357,0 0,2.39955357 0,5.35714286 L0,44.6428571 C0,47.6004464 2.39955357,50 5.35714286,50 L44.6428571,50 C47.6004464,50 50,47.6004464 50,44.6428571 L50,5.35714286 C50,2.39955357 47.6004464,0 44.6428571,0 Z"
    />
  </svg>
);

export default CheckboxSolidEmpty;
