import {
  ADVENTURES_GROUP,
  ADVENTURES,
  ADVENTURES_CARD,
  PUBLISH,
  DELETE
} from "../../constants"

export const emptyListItem = {
  description_audio_url: "",
  text_audio_url: "",
  text: "",
  description: "",
  grade_ids: [],
  image_url: "",
  node_type: "",
  parent_id: 0,
  title: "",
  random: false,
  marker_group_connections: [],
  collection_marker_url: ""
}

const titelRow = {
  key: "title", title: "Titel:", disabled: false, placeholder: "Lägg till titel...", type: 'title', input: true
}
const textRow = {
  key: "text", title: "Text:", disabled: false, type: 'text', input: true
}
const textAudioRow = {
  key: "text_audio_url",title: "Ljud:", disabled: true, placeholder: "Ladda upp ljud", type: 'audio', input: true
}
const descriptionRow = {
  key: "description", title: "Faktatext:", disabled: false, type: 'text', input: true
}
const descriptionAudioRow = {
  key: "description_audio_url",title: "Ljud:", disabled: true, placeholder: "Ladda upp ljud", type: 'audio', input: true
}
const imageRow = {
  key: "image_url",title: "Bild:", disabled: true, placeholder: "Ladda upp bild", type: 'image', input: true
}
const gradeRow = {
  key: "grade_ids",title: "Årskurs:", disabled: true, placeholder: "", type: 'grades', input: false 
}

const orderRow = {
  key: "random",title: "Slumpa äventyr:", disabled: true, placeholder: "", type: 'order', input: false
}

const markerRow = {
  key: "marker_group_connections", title: "Märkesgrupp:", disabled: true, placeholder: "Välj", type: "dropdown", input: false
}

export const rowData = {
  [ADVENTURES_GROUP]: [
    titelRow,
    textRow,
    textAudioRow,
    descriptionRow,
    descriptionAudioRow,
    imageRow,
    gradeRow,
    orderRow
  ],
  [ADVENTURES]: [
    titelRow,
    textRow,
    textAudioRow,
    imageRow
  ],
  [ADVENTURES_CARD]: [
    titelRow,
    markerRow
  ]
}

export const nodeData = (type, adventureGroupID, adventureID) => {
  switch(type) {
    case ADVENTURES_GROUP: return { parent_id: 0, node_type: 'adventure-group' }
    case ADVENTURES: return { parent_id: adventureGroupID, node_type: 'adventure' }
    case ADVENTURES_CARD: return { parent_id: adventureID, node_type: 'adventure-card' }
    default:
      break;
  }
}

export const validateRow = (data, type) => (
  type ? validateDropdownRow(data) : (data && data.length > 0)
)

const validateDropdownRow = connection => {
  return Number(connection.marker_group_id) ? true : false;
};

export const validateItem = (item,lastItem) => {
  let isValidated = [];
  const itemToValidateValues = Object.values(getItemToValidate(item, lastItem));
  let isValid = false;
  itemToValidateValues.map(
    value => validateRow(value) && isValidated.push(validateRow(value))
  );
  isValidated.length === itemToValidateValues.length && (isValid = true);
  return isValid;
};

const getItemToValidate = (item,lastItem) => {
  switch (item.node_type) {
    case "adventure-group":
      return {
        description_audio_url: item.description_audio_url,
        text_audio_url: item.text_audio_url,
        text: item.text,
        description: item.description,
        grade_ids: item.grade_ids,
        image_url: item.image_url,
        title: item.title
      };
    case "adventure":
      return {
        text_audio_url: item.text_audio_url,
        text: item.text,
        image_url: item.image_url,
        title: item.title
      };
    case "adventure-card":
      return lastItem ? {
        title: item.title
      } : {
        title: item.title,
        marker_group_connections:item.marker_group_connections
      };
    default:
      return {};
  }
};

export const modalText = {
  [DELETE]: {
    header: 'Observera',
      text: `Du har valt “Ta bort”. Om du går vidare kommer allt innehåll som
             ligger på lägre nivåer i strukturen inklusive uppladdade mediafiler
             att tas bort.`,
      bold: 'Är du säker på att du vill ta bort allt innehåll?',
       yes: 'TA BORT',
        no: 'Avbryt'
  },
  [PUBLISH]: {
    header: 'Observera',
      text: `Du har valt att publicera. Om du går vidare kommer alla följande noder att också publiceras.`,
      bold: 'Är du säker på att du vill publicera dessa noder?',
       yes: 'Publicera',
        no: 'Avbryt'
  }
}