import React from "react";
import { Warning } from "../../StudliIcons/index";

import { StyledStatusWrapper, StyledIcon } from "./StyledStatus";

const ErrorRenderer = ({ rowData: { invalid_data } }) => {
  const studliSize = 25;

  /**
   * Renders icons
   * @param themeColor
   * @returns {*}
   */
  const renderIcon = invalid =>
    invalid ? (
      <StyledIcon color="errorColor">
        <Warning size={studliSize} />
      </StyledIcon>
    ) : null;

  return <StyledStatusWrapper>{renderIcon(invalid_data)}</StyledStatusWrapper>;
};

export default ErrorRenderer;
