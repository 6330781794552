import { mergeDeepRight } from "ramda";
import { fromJS, List, Map } from "immutable";
import { createReducer } from "../../store/utils";
import { actionCreator } from "../../shared";
import { formatISOZeroTime } from "../../date";

import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_SUCCESS,
  START_FETCH,
  FETCH_ENTITY_DETAILS_SUCCESSFULLY_PREPPED,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  GOTO_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  SET_POST_TMPDATA,
  CLEAR_POST_TMPDATA
} from "../../api";

import {
  SET_ENTITY_DRAFT,
  UPDATE_ENTITY_DRAFT,
  STORE_ENTITY_DRAFT,
  REVERT_ENTITY_DRAFT,
  RESET_ENTITY_DRAFT
} from "../../posts/sagas/PostDraft/actions";

import {
  SAVE_POST_SUCCESS,
  REGISTER_VALIDATOR,
  UNREGISTER_VALIDATOR,
  SET_SAVE_POST_STATUS,
  FETCH_CHIPS_SUCCESS
} from "../../posts/store/actions";

const FETCH_LIMIT = 50;
const ISO_ZERO_TIME = formatISOZeroTime()

export const requiredFields = {
  selectedItem: {},
  fetching: false,
  page: 1,
  buffer: {},
  list: [],
  filters: [],
  tags: [],
  chips: [],
  labels: [],
  args: {},
  total: 0,
  fetchLimit: FETCH_LIMIT,
  searchParameters: {},
  postDraft: new Map({
    post: [],
    tags: [],
    chips: [],
    status: "",
    title: "",
    metadata: {
      startTime: ISO_ZERO_TIME,
      endTime: ISO_ZERO_TIME,
      alert: false
    },
    postDraftState: "clean"
  }),
  postDraftList: [],
  previousDraft: {},
  initialDraft: {},
  revertTargetList: [],
  validators: [],
  newDataTmp: {
    hasDataSaved: false,
    tags: [],
    chips: [],
    difficulty: null,
    category: null,
  }
};

const initPostDraft = {
  post: [],
  tags: [],
  chips: [],
  status: "",
  title: "",
  metadata: {
    startTime: ISO_ZERO_TIME,
    endTime: ISO_ZERO_TIME,
    alert: false
  },
  postDraftState: "clean"
};

const reducer = entityName => initialState => {
  const mergedState = fromJS(mergeDeepRight(requiredFields, initialState));
  return createReducer(mergedState, {
    [actionCreator(entityName, START_FETCH)](state) {
      return state.set("fetching", true).set("selectedItem", null);
    },
    [actionCreator(entityName, END_FETCH)](state) {
      return state.set("fetching", false);
    },
    [actionCreator(entityName, FETCH_ENTITIES)](state, action) {
      return state.withMutations(st => {
        const stateSearchParameters = st.get("searchParameters").toJS();
        let searchParameters = action.searchParameters;
        if (
          stateSearchParameters &&
          stateSearchParameters.sort &&
          !action.searchParameters.sort
        ) {
          searchParameters = {
            ...action.searchParameters,
            sort: stateSearchParameters.sort
          };
        }

        st.set("searchParameters", new Map(searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [actionCreator(entityName, FETCH_ENTITIES_SUCCESS)](state, action) {
      state = state.update("total", () => action.metadata.total);
      if (action.batchIndex === state.get("page")) {
        state = state.update("list", list => new List(action.entities));
      }
      return state
        .update("buffer", buffer =>
          buffer.set(action.batchIndex, new List(action.entities))
        )
        .set("postDraft", new Map(initPostDraft));
    },
    [actionCreator(entityName, FETCH_ENTITY_DETAILS_SUCCESSFULLY_PREPPED)](
      state,
      action
    ) {
      return state
        .set("selectedItem", new Map(action.entity))
        .update("args", args => args.merge(action.args));
    },
    [actionCreator(entityName, SET_ENTITY_DRAFT)](state, { post }) {
      const replacer = (key, value) => {
        return typeof value === "undefined" ? null : value;
      };

      let postCopy = JSON.parse(JSON.stringify(post, replacer));

      return state
        .set("postDraft", post)
        .set("initialDraft", postCopy)
        .set("postDraftList", [])
        .set("previousDraft", {})
        .set("revertTargetList", []);
    },
    [actionCreator(entityName, STORE_ENTITY_DRAFT)](state, { key }) {
      const currentPostDraft = state.get("postDraft");
      const oldRevertTargetList = state.get("revertTargetList");
      const oldChanges = state.get("postDraftList");

      let revertTargetList = [];
      oldRevertTargetList.map(elem => revertTargetList.push(elem));
      revertTargetList.push(key);

      const replacer = (replacerKey, value) => {
        return typeof value === "undefined" ? null : value;
      };

      const currentPostDraftCopy = JSON.parse(
        JSON.stringify(currentPostDraft, replacer)
      );

      let changes = [];
      oldChanges.map(elem => changes.push(elem));
      changes.push(currentPostDraftCopy);

      return state
        .set("postDraftList", changes)
        .set("revertTargetList", revertTargetList);
    },
    [actionCreator(entityName, REVERT_ENTITY_DRAFT)](state, action) {
      const changes = state.get("postDraftList");
      const closestChange = changes[changes.length - 1];
      const revertTargetList = state.get("revertTargetList");

      let revertTarget = revertTargetList[revertTargetList.length - 1];

      if (changes.length === 1) {
        const initialDraft = state.get("initialDraft");
        let initialDraftMap = new Map(initialDraft);
        let newInitialDraft = JSON.parse(JSON.stringify(initialDraftMap));

        return state
          .set("postDraft", initialDraftMap)
          .set("postDraftList", [])
          .set("previousDraft", {})
          .set("initialDraft", newInitialDraft)
          .setIn(["postDraft", "postDraftState"], "clean");
      }

      changes.pop();
      revertTargetList.pop();

      return state
        .setIn(["postDraft", revertTarget], closestChange[revertTarget])
        .set("postDraftList", changes)
        .set("previousDraft", closestChange)
        .set("revertTargetList", revertTargetList);
    },
    [actionCreator(entityName, UPDATE_ENTITY_DRAFT)](state, { key, value }) {
      return state
        .setIn(["postDraft", key], value)
        .setIn(["postDraft", "postDraftState"], "dirty");
    },
    [actionCreator(entityName, RESET_ENTITY_DRAFT)](state, action) {
      return state
        .set()
        .set("postDraftList", [])
        .set("previousDraft", {})
        .set("revertTargetList", []);
    },
    [actionCreator(entityName, RESET_BEFORE_FILTER_SORT_CHANGE)](
      state,
      action
    ) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", s => 1);
        st.update("buffer", () => new Map());
      });
    },
    [actionCreator(entityName, SET_SAVE_POST_STATUS)](state, value) {
      return state.set("saving", value);
    },
    [actionCreator(entityName, SAVE_POST_SUCCESS)](state, action) {
      const { difficulty } = action.args;
      return state
        .setIn(["postDraft", "difficulty"], difficulty)
        .set("saving", false);
    },
    [actionCreator(entityName, SET_POST_TMPDATA)](state, action) {
      const newdata = action.data.set("hasDataSaved", true);
      return state
        .set("newDataTmp", newdata)
        .setIn(["postDraft", "postDraftState"], "dirty");
    },
    [actionCreator(entityName, CLEAR_POST_TMPDATA)](state) {
      const newdata = {
        hasDataSaved: false,
        tags: new List(),
        chips: new List(),
        difficulty: null,
        category: null
      };
      return state.set("newDataTmp", new Map(newdata));
    },
    [REGISTER_VALIDATOR](state, action) {
      const registeredValidators = state.get("validators");
      return state.set(
        "validators",
        registeredValidators.push(action.validator)
      );
    },
    [UNREGISTER_VALIDATOR](state, action) {
      const registeredValidators = state.get("validators");
      return state.set(
        "validators",
        registeredValidators.filter(item => item.id !== action.id)
      );
    },
    [actionCreator(entityName, GOTO_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => action.page);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => new List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, NEXT_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s + 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => new List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, PREVIOUS_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s - 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => new List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, FETCH_CHIPS_SUCCESS)](state, action) {
      return state.set("chips", action.data);
    }
  });
};

export default reducer;
