import React from "react";

const Structure = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-structure"
  >
    <path d="M46.25,5 L38.75,5 C36.6789062,5 35,6.67890625 35,8.75 L35,11.25 L15,11.25 L15,8.75 C15,6.67890625 13.3210937,5 11.25,5 L3.75,5 C1.67890625,5 0,6.67890625 0,8.75 L0,16.25 C0,18.3210937 1.67890625,20 3.75,20 L11.1609375,20 L18.0953125,31.7351563 C17.7226562,32.3179688 17.4992188,33.00625 17.4992188,33.75 L17.4992188,41.25 C17.4992188,43.3210938 19.178125,45 21.2492188,45 L28.7492188,45 C30.8203125,45 32.4992188,43.3210938 32.4992188,41.25 L32.4992188,33.75 C32.4992188,31.6789063 30.8203125,30 28.7492188,30 L21.2492188,30 C20.8429688,30 20.459375,30.08125 20.0929688,30.2007812 L13.575,19.1710937 C14.4375,18.4835938 15,17.4375 15,16.25 L15,13.75 L35,13.75 L35,16.25 C35,18.3210937 36.6789062,20 38.75,20 L46.25,20 C48.3210938,20 50,18.3210937 50,16.25 L50,8.75 C50,6.67890625 48.3210938,5 46.25,5 Z M2.5,16.25 L2.5,8.75 C2.5,8.0609375 3.0609375,7.5 3.75,7.5 L11.25,7.5 C11.9390625,7.5 12.5,8.0609375 12.5,8.75 L12.5,16.25 C12.5,16.9390625 11.9390625,17.5 11.25,17.5 L3.75,17.5 C3.0609375,17.5 2.5,16.9390625 2.5,16.25 Z M28.75,32.5 C29.4390625,32.5 30,33.0609375 30,33.75 L30,41.25 C30,41.9390625 29.4390625,42.5 28.75,42.5 L21.25,42.5 C20.5609375,42.5 20,41.9390625 20,41.25 L20,33.75 C20,33.0609375 20.5609375,32.5 21.25,32.5 L28.75,32.5 Z M47.5,16.25 C47.5,16.9390625 46.9390625,17.5 46.25,17.5 L38.75,17.5 C38.0609375,17.5 37.5,16.9390625 37.5,16.25 L37.5,8.75 C37.5,8.0609375 38.0609375,7.5 38.75,7.5 L46.25,7.5 C46.9390625,7.5 47.5,8.0609375 47.5,8.75 L47.5,16.25 Z" />
  </svg>
);

export default Structure;
