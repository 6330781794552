import styled from "styled-components";

export const StyledFractionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.rowLength}, 33px);
  grid-auto-rows: 40px;
  background-color: #979797;

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledCell = styled.div`
  height: auto;
  width: 100%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isInteger && "margin-left: 5px;"}
  ${props => props.borderBottom && "border-bottom: 2px solid #979797"};
`;

export const StyledCellValue = styled.span`
  color: #277575;
  letter-spacing: 0;
  line-height: 1;
  ${props => props.isInteger && "margin-right: 5px;"}
  ${props => props.isInteger && "margin-bottom: 40px;"}
  ${props => props.isVertical && "margin-top: -48px;"}
  margin-left: ${props => props.isHorizontal * -32}px;
  font-size: ${props => props.isInteger ? 50 : 32}px;
  font-weight: ${props => props.isInteger ? 400 : 600};
`;
