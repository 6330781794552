import React, { useState, useEffect } from "react";
import {
  StyledInputWrapper,
  StyledSearchResult,
  StyledSearchResultItem
} from "./StyledProductSearch";
import Input from "../../../shared/components/Input";
import connect from "react-redux/es/connect/connect";
import { activateProduct } from "../../api/actions";
import useVisible from "../../../shared/effects/useVisible";
import useKeyNavigation from "../../../shared/effects/useKeyNavigation";

const ProductSearch = ({
  products,
  activateProduct,
  className,
  placeholder = "Sök produkt...",
  onSelect
}) => {
  const [searchBase, setSearchBase] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const onEnter = () => {
    setIsVisible(false);
    if (onSelect) {
      onSelect(searchResult[currentNodeIndex]);
    } else {
      activateProduct(searchResult[currentNodeIndex]);
    }
  };

  const { itemsRef, currentNodeIndex } = useKeyNavigation(
    ref,
    searchResult,
    onEnter,
    setIsVisible
  );

  useEffect(() => {
    const base = products.map(p => {
      p.search = `${p.sesam.name}|${p.title}|${p.artnr}`.toLowerCase();
      return p;
    });
    setSearchBase(base);
  }, [products, setSearchBase]);

  const filterBase = value =>
    searchBase.filter(b => b.search.includes(value.toLowerCase()));

  const onChange = newValue => {
    setSearchValue(newValue);
    const s = newValue.length > 0 ? filterBase(newValue) : [];
    setSearchResult(s);
    setIsVisible(true);
  };

  const onFocus = e => {
    if(e.target.value !== "") {
      onChange(e.target.value);
    }
  };

  const onProductSelect = entity => {
    if (onSelect) {
      onSelect(entity);
    } else {
      activateProduct(entity);
    }
    setIsVisible(false);
    setSearchResult([])
  };

  return (
    <StyledInputWrapper ref={ref} className={className}>
      <Input
        onChange={onChange}
        onFocus={onFocus}
        value={searchValue}
        placeholder={placeholder}
        autoComplete="off"
        type="search"
      />
      {isVisible && searchResult.length > 0 && (
        <StyledSearchResult>
          {searchResult.map((value, index) => (
            <StyledSearchResultItem
              key={value.sesam.name}
              ref={el => (itemsRef.current[index] = el)}
              onClick={(e) => {
                onProductSelect(value);
                e.preventDefault();
              }}
              className={currentNodeIndex === index ? "active" : ""}
            >
              {value.sesam.name}
            </StyledSearchResultItem>
          ))}
        </StyledSearchResult>
      )}
    </StyledInputWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  products: props.selector
    ? props.selector(state)
    : state.Product.get("list").toArray()
});

const mapDispatchToProps = dispatch => ({
  activateProduct: entity => dispatch(activateProduct(entity))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
