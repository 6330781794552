import React from "react";
import styled from "styled-components";

import InputDefault from "../../shared/components/Input";
import Label from "./Label";

export const Input = styled(InputDefault)`
  height: 45px;
  width: 100%;
  max-width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  box-shadow: none;

  &:active,
  &:focus {
    border-width: 2px;
    border-color: #006cc8;
  }

  &:hover:not(&:active, &:focus) {
    border-color: rgba(0, 0, 0, 0.52);
  }

  ${({ error }) =>
    error &&
    `
    border-color: #B00020;

    &:active,
    &:focus,
    &:hover:not(&:active, &:focus) {
      border-color: #B00020;
    }
  `}
`;

export const Select = styled(Input).attrs({ as: "select" })`
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
`;

export const ErrorMsg = styled.span`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

export const Field = ({
  error,
  label,
  name,
  value,
  onChange,
  onEnter,
  onBlur,
  disabled,
  ...rest
}) => (
  <Label error={!!error} disabled={disabled}>
    {label}
    <Input
      name={name}
      value={value || ""}
      onChange={onChange}
      onEnter={onEnter}
      onBlur={onBlur}
      disabled={disabled}
      error={!!error}
      {...rest}
    />
    {!!error && <ErrorMsg>{error}</ErrorMsg>}
  </Label>
);

export const SelectField = ({
  error,
  label,
  name,
  value,
  onChange,
  disabled,
  options,
  showValue,
  ...rest
}) => (
  <Label error={!!error} disabled={disabled}>
    {label}
    <Select
      name={name}
      onChange={e =>
        showValue
          ? onChange(e.target.value)
          : onChange(Number(e.target.value), e.target.name)
      }
      value={value}
      disabled={disabled}
      error={!!error}
      {...rest}
    >
      {options.map(({ key, val }) => (
        <option key={val} value={val}>
          {key}
        </option>
      ))}
    </Select>
    {!!error && <ErrorMsg>{error}</ErrorMsg>}
  </Label>
);
