import * as React from "react";
import {
  StyledLayoutButtonWrapper,
  StyledLayoutDisplayBox,
  StyledLabel
} from "./StyledLayoutButton";

const LayoutButton = ({
  callback,
  children,
  disabled,
  layoutFormat,
  selectedFormat
}) => {
  const handleClick = () => (disabled ? null : callback(layoutFormat));

  const isSelected = () => selectedFormat === layoutFormat.replace(/ /g, ""); // Remove spaces from string
  return (
    <StyledLayoutButtonWrapper
      onClick={handleClick}
      selected={isSelected()}
      disabled={disabled}
    >
      <StyledLayoutDisplayBox>{children}</StyledLayoutDisplayBox>
      <StyledLabel selected={isSelected()}>{layoutFormat}</StyledLabel>
    </StyledLayoutButtonWrapper>
  );
};

export default LayoutButton;
