import React from "react";
import { ThemeProvider } from "styled-components";

import { createGlobalStyle } from "styled-components";
import { BaseStyle } from "../styles/base";

import { AppShell, Router } from "../shared";
import theme from "../styles/theme";
import ConfirmModal from '../shared/components/Modal/ConfirmModal/ConfirmModal';

// import "react-sortable-tree";

const GlobalStyle = createGlobalStyle`
  ${BaseStyle}
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppShell>
        <Router />
      </AppShell>
      <ConfirmModal/>
    </ThemeProvider>
  );
};

export default App;
