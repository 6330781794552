import React from "react";
import Tag from "../../Tag";
import { AIDA_KEYWORD_COLOR } from "../../../../posts/api/constants";

const KeyWordsRenderer = ({ cellData }) => {
  return (
    cellData &&
    cellData
      .filter(c => c.tag === undefined)
      .map((tag, index) => (
        <Tag
          key={index}
          label={tag.title}
          variant="info"
          fill
          labelColor={AIDA_KEYWORD_COLOR}
        />
      ))
  );
};

export default KeyWordsRenderer;
