import React from "react";
import styled from "styled-components";
import Card from "./Card";
import RelatedProductsHandler from "./RelatedProductsHandler";

const Part = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  flex-direction: column;
`;

const RelatedProductsCard = () => {
  return (
    <Card title="Kursinnehåll">
      <Part>
        <RelatedProductsHandler />
      </Part>
    </Card>
  );
};

export default RelatedProductsCard;
