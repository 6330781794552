import React, { useState } from "react";
import styled from "styled-components";
import Label from "../../../products/components/Label";
import ProductSearch from "../../../products/components/ProductSearch";
import { useSelector } from "react-redux";
import IconButton from "../../../products/components/IconButton";
import PrimaryButton from "../../../shared/components/Button/Primary";
import { Trash2 as Trash } from "react-feather";
import Modal from "../../../shared/components/Modal/Modal";

const Search = styled(ProductSearch)`
  width: 100%;
  margin-top: 0;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const Text = styled.p`
  margin: 0;
`;

const StyledErrorMessage = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

const Wrapper = styled.div``;

const StyledProductsWrapper = styled.div`
  height: 200px;
  padding: 4px 0;
  ${props => (props.showScroll ? "overflow-y: scroll" : null)};
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const isIdInList = (list, refId) => list.filter(item => item === refId).length > 0;

const ProductSearchAndList = ({
  label,
  productRefIdList = [],
  addProductToList,
  removeProductFromList,
  productSelector
}) => {
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const products = useSelector(productSelector);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const TrashButtonClick = refId => {
    setIsModalOpen(true);
    setSelectedProduct(refId);
  };

  const removeProductButtonClick = () => {
    removeProductFromList(selectedProduct);
    setIsModalOpen(false);
  };
  const addNewProductToList = refId => {
    if (isIdInList(productRefIdList, refId)) {
      setError(true);
      return;
    }

    addProductToList(refId);
    setError(false);
  };

  const getSesamNameForProductRefId = refId => {
    if (products.length === 0) {
      return ""
    }
    const product = products.find(p => p.refId === refId)
    return product ? product.sesam.name : "Hittar inte refId";
  }

  return (
    <Wrapper>
      <Label>
        {label}
        <Search
          selector={productSelector}
          placeholder="Sök produkt"
          onSelect={p => addNewProductToList(p.refId)}
        />
        {error && (
          <StyledErrorMessage>
            Produkten finns redan med i listan.
          </StyledErrorMessage>
        )}
      </Label>
      <StyledProductsWrapper showScroll={productRefIdList && productRefIdList.length > 6}>
        {productRefIdList &&
          productRefIdList.map(pRefId => (
            <ProductWrapper key={pRefId}>
              <IconButton
                aria-label="Ta bort produkt"
                onClick={() => TrashButtonClick(pRefId)}
              >
                <Trash width="16px" height="16px" />
              </IconButton>
              <Text>{getSesamNameForProductRefId(pRefId)}</Text>
            </ProductWrapper>
          ))}
      </StyledProductsWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          Vill du ta bort produkten?
          <StyledButton onClick={removeProductButtonClick}>
            Ta bort
          </StyledButton>
        </StyledModalContent>
      </Modal>
    </Wrapper>
  );
};

export default ProductSearchAndList;
