import React, { useState } from "react";
import { connect } from "react-redux";
import {
  selectMarkerGroupItems,
  selectColorThemesByColorCount,
  selectColorTheme
} from "../../sagas/selectors"
import MarkerItem from "./MarkerItem";
import UploadMedia from "../../../shared/components/UploadMedia";
import {
  StyledAccordionWrapper,
  StyledInternalWrapper,
  StyledAccordionPanelHeader,
  StyledAccordionPanelLabel,
  StyledAccordionPanelCount,
  StyledMarkerGroupWrapper,
  StyledAddMarkersTypeImage,
  StyledCollapseButton,
  StyledInput,
  StyledMarkerTypeLabel,
  StyledMarkerGroupHeader,
  StyledMarkerTypeImg,
} from "./StyledMarkerTree";
import { MarkerUpload } from "../../../shared/components/StudliIcons";
import DropDown from "./DropDown";
import { uploadMediaFile, updateMarkerItem,updateMarkerColorTheme, expandTreeElement } from "../api/actions";

const MarkerGroup = ({ 
  markerGroup,
  colorThemes,
  itemsList,
  onMediaUpload,
  onMarkerItemUpdate,
  onMarkerColorThemeUpdate,
  selectedTheme,
  typeOfGroup,
  openCollapseGroup
}) => {
  const [mediaData, setMediaData] = useState({ image_url: { name: "", files: {} } })  


  const renderAddMarkersTypeImage = (key) => (
    <>
      <StyledMarkerGroupHeader>
        <StyledMarkerTypeLabel>Typmärke</StyledMarkerTypeLabel>
        {renderTypeMarkerImage(markerGroup.marker_type_url)}
        {renderInput()}
        <UploadMedia
          multiple={false}
          disabled={itemsList.size === 0 ? true : false}
          type={key}
          withmargin={'true'}
          skin={"primary"}
          buttonstate={"selected"}
          studlicon="Media"
          callback={mediaUploadCallback} />
      </StyledMarkerGroupHeader>
      <DropDown isMarkerTypeUrl={markerGroup.marker_type_url && true} themes={colorThemes} selectedThemeCallback={selectedThemeCallback} selectedTheme={selectedTheme}/>
    </>
  )

  const renderTypeMarkerImage = (image) => (
    /* eslint-disable jsx-a11y/alt-text */
    image ? (
      <StyledMarkerTypeImg src={image} />
    ) : (
        <StyledAddMarkersTypeImage style={{ marginTop: '2px' }}>
          <MarkerUpload size={45} />
        </StyledAddMarkersTypeImage>
      )
  )
  
  const renderInput = () => (
    <StyledInput type={"text"}
      placeholder={"Ladda upp bild"}
      value={markerGroup.markertype_filename ? markerGroup.markertype_filename : ""}
      readOnly />
  )


  const renderPanelCollapseButton = () => {
    if (markerGroup.isOpen) {
      return <StyledCollapseButton studlicon="Minus" onClick={() => openCollapseGroup(typeOfGroup,markerGroup.id,"group",false)} />;
    }
    return <StyledCollapseButton studlicon="Plus" onClick={() => openCollapseGroup(typeOfGroup,markerGroup.id,"group",true)} />;
  }

  const mediaUploadCallback = ({ files, type }) => {
    onMediaUpload(markerGroup.id, files)
    setMediaData({ ...mediaData, [type]: { name: files.name, files } });
  };

  const markersCount = () => {
    const item = itemsList.get(0);
    return (item ? item.marker_count * itemsList.size : 0)
  }

  const onUpdateMarkerItemCallback = (id, file) => onMarkerItemUpdate(id, file);

  const selectedThemeCallback = (id) => {     
    let data={}
    id && onMarkerColorThemeUpdate(markerGroup.id,{...data,id:markerGroup.id,colortheme_id:id})
  }

 
  return (
    <StyledAccordionWrapper>
      <StyledAccordionPanelHeader>
        <StyledAccordionPanelLabel>Märkesgrupp: {markerGroup.label} </StyledAccordionPanelLabel>
        <StyledAccordionPanelCount>Antal märken:{markersCount()}</StyledAccordionPanelCount>
        {renderPanelCollapseButton()}
      </StyledAccordionPanelHeader>
      <StyledInternalWrapper open={markerGroup.isOpen}>
        <StyledMarkerGroupWrapper>
          {renderAddMarkersTypeImage("image_url")}
        </StyledMarkerGroupWrapper>
        {itemsList ? itemsList.map((item) => (

          <MarkerItem 
            key={item.id}
            onUpdateMarkerItemCallback={onUpdateMarkerItemCallback}
            itemData={item}
            label={item.label}
            colors={selectedTheme.size !==0 && selectedTheme.get(0).colors}
             />
        )) : null}

      </StyledInternalWrapper>
    </StyledAccordionWrapper>

  )
}
const mapStateToProps = (state, { markerGroup }) => {
  const { id} = markerGroup
  return {
    colorThemes: selectColorThemesByColorCount(state, id),
    itemsList: selectMarkerGroupItems(state, id),
    selectedTheme: selectColorTheme(state,markerGroup)
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onMediaUpload: (id, file) => dispatch(uploadMediaFile(id, file)),
    onMarkerItemUpdate: (id, file) => dispatch(updateMarkerItem(id, file)),
    onMarkerColorThemeUpdate: (id,data) => dispatch(updateMarkerColorTheme(id, data)),
    openCollapseGroup:(type, id, group,param) => dispatch(expandTreeElement(type,id,group, param))
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(MarkerGroup)