import React from "react";

const LogoHome = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50 "
    width={`${size}px`}
  >
    <path
      fill="#fff"
      d="M49.7837478,22.0919712 L43.7493081,17.2390319 L43.7493081,9.81803806 C43.7493081,9.49581069 43.4856676,9.23217013 43.1634402,9.23217013 L41.2105471,9.23217013 C40.8883197,9.23217013 40.6246792,9.49581069 40.6246792,9.81803806 L40.6246792,14.7100353 L26.9641919,3.69571817 C25.8217494,2.76809394 24.1813192,2.76809394 23.0388767,3.69571817 L0.21932079,22.0919712 C-0.0345553142,22.297025 -0.0736131764,22.6680747 0.1314406,22.9121863 L1.36176326,24.435443 C1.56681703,24.6893191 1.93786672,24.7283769 2.19174283,24.5233231 L6.25376049,21.2522272 L6.25376049,43.6128533 C6.25376049,45.3411637 7.65007906,46.7374822 9.37838946,46.7374822 L20.7051695,46.7374822 C21.3496242,46.7374822 21.8769053,46.2102011 21.8769053,45.5657464 L21.8769053,32.6668874 L28.1261633,32.6961808 L28.1261633,45.6048042 C28.1261633,46.249259 28.6534444,46.7765401 29.2978991,46.7765401 L40.6246792,46.7472467 C42.3529896,46.7472467 43.7493081,45.3509281 43.7493081,43.6226177 L43.7493081,21.2619917 L47.8113258,24.5330876 C48.0652019,24.7381414 48.4362516,24.6990835 48.6413054,24.4452074 L49.871628,22.9219508 C50.0766818,22.6680747 50.0278595,22.297025 49.7837478,22.0919712 Z M40.6246792,43.6226177 L31.2507922,43.6519111 L31.2507922,30.7335232 C31.2507922,30.0890685 30.7235111,29.5617874 30.0790564,29.5617874 L19.9240122,29.532494 C19.2795575,29.532494 18.7522764,30.0597751 18.7522764,30.7042298 L18.7522764,43.6226177 L9.37838946,43.6226177 L9.37838946,18.7329951 L24.6304846,6.42976852 C24.8453029,6.25400814 25.1480013,6.25400814 25.3628195,6.42976852 L40.6149147,18.7329951 L40.6149147,43.6226177 L40.6246792,43.6226177 Z"
    />
  </svg>
);

export default LogoHome;
