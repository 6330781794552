import { createReducer, toListWithMaps } from "../../store/utils";
import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import {
  ACTION_FETCH_CLASSROOMGROUPS_START,
  ACTION_FETCH_CLASSROOMGROUPS_SUCCESS,
  ACTION_FETCH_CLASSROOMGROUPS_END,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  ACTION_FETCH_CLASSROOMGROUP_DETAILS_START,
  ACTION_FETCH_CLASSROOMGROUP_DETAILS_END,
  ACTION_FETCH_CLASSROOMGROUP_DETAILS_SUCCESS,
  ACTION_FETCH_PREPARED_ASSIGNMENTS_SUCCESS,
  ACTION_CLEAR_PREPARED_ASSIGNMENTS_SUCCESS,
  ACTION_FETCH_FORMATIVE_TESTS_SUCCESS,
  ACTION_DELETE_FORMATIVE_TESTS_SUCCESS,
  ACTION_DELETE_FORMATIVE_QUESTION_SUCCESS,
  ACTION_CREATE_FORMATIVE_QUESTION_SUCCESS,
  ACTION_FETCH_FORMATIVE_QUESTION_DATA_SUCCESS,
  ACTION_SET_FORMATIVE_QUESTION_SELECTED_PRODUCT,
  ACTION_FETCH_CLASSROOMGROUPS,
  ACTION_FETCH_CLASSROOMGROUP_PERMISSION_SUCCESS,
  ACTION_FETCH_CLASSROOMGROUP_STICKERS_SUCCESS
} from "./actions";

import { ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS } from "../../products/api/actions";
import { modifyPermissionsArray } from "../../shared/Permissions";
import {
  formativeTestsExample
} from "../containers/FormativeTestsCard/tempdata/formTestsProducts_ex";

const FETCH_LIMIT = 500;

export const requiredFields = {
  selectedItem: {},
  page: 1,
  buffer: {},
  list: [],
  filters: [],
  labels: [],
  tags: [],
  args: {},
  total: 0,
  fetchLimit: FETCH_LIMIT,
  fetching: false,
  searchParameters: {},
  grouptypes: [],
  preparedAssignmentsdata: {},
  formativeTestsdata: [],
  formativeQuestionsToc: [],
  formativeQuestionData: {},
  formativeQuestionsSelectedProduct: null,
  permissions: {},
  stickers: []
};

const reducer = initialState => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {
    [ACTION_FETCH_CLASSROOMGROUPS](state, action) {
      return state.withMutations(st => {
        const stateSearchParameters = st.get("searchParameters").toJS();
        let searchParameters = action.searchParameters;
        if (
          stateSearchParameters &&
          stateSearchParameters.sort &&
          !action.searchParameters.sort
        ) {
          searchParameters = {
            ...action.searchParameters,
            sort: stateSearchParameters.sort
          };
        }
        st.set("searchParameters", new Map(searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [ACTION_FETCH_CLASSROOMGROUPS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_CLASSROOMGROUPS_SUCCESS](state, action) {
      return state.update("list", () => new List(action.data.entities));
    },
    [ACTION_FETCH_CLASSROOMGROUPS_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_FETCH_CLASSROOMGROUP_DETAILS_START](state) {
      return state.set("fetching", true).set("selectedItem", {});
    },
    [ACTION_FETCH_CLASSROOMGROUP_DETAILS_SUCCESS](state, action) {
      // remove classroomgroup variable below and just add
      // .set("selectedItem", action.data.entity) when BE is in place.
      const classroomgroup = {
        ...action.data.entity,
        formativeTests: formativeTestsExample
      };
      return state.set("selectedItem", classroomgroup);
    },
    [ACTION_FETCH_CLASSROOMGROUP_DETAILS_END](state) {
      return state.set("fetching", false);
    },
    [ACTION_FETCH_PRODUCT_SETTINGS_SUCCESS](state, action) {
      const types = action.data.classroomGroupTypes;
      return state.set("grouptypes", toListWithMaps(types));
    },
    [RESET_BEFORE_FILTER_SORT_CHANGE](state, action) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", s => 1);
        st.update("buffer", () => new Map());
      });
    },
    [ACTION_FETCH_PREPARED_ASSIGNMENTS_SUCCESS](state, action) {
      return state.set("preparedAssignmentsdata", action.data);
    },
    [ACTION_CLEAR_PREPARED_ASSIGNMENTS_SUCCESS](state, action) {
      return state.set("preparedAssignmentsdata", {});
    },
    [ACTION_FETCH_FORMATIVE_TESTS_SUCCESS](state, action) {
      return state
        .set("formativeTestsdata", action.data.tests)
        .set("formativeQuestionsToc", action.data.questions)
        .set("formativeQuestionData", {});
    },
    [ACTION_DELETE_FORMATIVE_TESTS_SUCCESS](state, action) {
      return state.set("formativeTestsdata", []);
    },
    [ACTION_DELETE_FORMATIVE_QUESTION_SUCCESS](state, action) {
      return state.set("formativeQuestionsToc", action.toc);
    },
    [ACTION_CREATE_FORMATIVE_QUESTION_SUCCESS](state, action) {
      return state.set("formativeQuestionData", action.data);
    },
    [ACTION_FETCH_FORMATIVE_QUESTION_DATA_SUCCESS](state, action) {
      return state.set("formativeQuestionData", action.data);
    },
    [ACTION_SET_FORMATIVE_QUESTION_SELECTED_PRODUCT](state, action) {
      return state.set("formativeQuestionsSelectedProduct", action.id);
    },
    [ACTION_FETCH_CLASSROOMGROUP_PERMISSION_SUCCESS](state, action) {
      return state.set("permissions", modifyPermissionsArray(action.data));
    },
    [ACTION_FETCH_CLASSROOMGROUP_STICKERS_SUCCESS](state, action) {
      return state.set("stickers", action.data);
    }
  });
};

export default reducer;
