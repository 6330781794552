import React from "react";

const Copy = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-copy"
  >
    <path d="M45.3770508,6.43955078 L40.3104492,1.37294922 C39.4313775,0.493870953 38.2390982,6.50074621e-06 36.9958984,0 L20.1875,0 C17.5986328,0 15.5,2.09863281 15.5,4.6875 L15.5,9.375 L7.6875,9.375 C5.09863281,9.375 3,11.4736328 3,14.0625 L3,45.3125 C3,47.9013672 5.09863281,50 7.6875,50 L29.5625,50 C32.1513672,50 34.25,47.9013672 34.25,45.3125 L34.25,40.625 L42.0625,40.625 C44.6513672,40.625 46.75,38.5263672 46.75,35.9375 L46.75,9.75410156 C46.7499935,8.51090175 46.256129,7.31862255 45.3770508,6.43955078 Z M37.375,3.17294922 C37.6497412,3.24073757 37.900697,3.38240891 38.1006836,3.58261719 L43.1672852,8.64931641 C43.3674735,8.84931815 43.5091415,9.10026806 43.5769531,9.375 L37.375,9.375 L37.375,3.17294922 Z M31.125,45.3125 C31.125,46.1740234 30.4240234,46.875 29.5625,46.875 L7.6875,46.875 C6.82597656,46.875 6.125,46.1740234 6.125,45.3125 L6.125,14.0625 C6.125,13.2009766 6.82597656,12.5 7.6875,12.5 L15.5,12.5 L15.5,35.9375 C15.5,38.5263672 17.5986328,40.625 20.1875,40.625 L31.125,40.625 L31.125,45.3125 Z M43.625,35.9375 C43.625,36.7990234 42.9240234,37.5 42.0625,37.5 L20.1875,37.5 C19.3259766,37.5 18.625,36.7990234 18.625,35.9375 L18.625,4.6875 C18.625,3.82597656 19.3259766,3.125 20.1875,3.125 L34.25,3.125 L34.25,10.15625 C34.25,11.4453125 35.3046875,12.5 36.59375,12.5 L43.625,12.5 L43.625,35.9375 Z" />
  </svg>
);

export default Copy;
