import React from "react";
import { InputField } from "./StyledInput";

const Input = ({ value, placeholder, onChange, onEnter, target, disabled }) => {
  /**
   * Callback returns calue
   * @param e
   * @returns {*}
   * @private
   */
  const _onChange = e => onChange(e.target.value, target);

  /**
   * Enter key even. If specific functions is defined, return object with text.
   * otherwise, use onChange and return value.
   * @param e
   * @private
   */
  const _onKeyDown = e => {
    if (e.keyCode === 13) {
      onEnter !== undefined ? onEnter({ text: e.target.value }) : _onChange(e);
    }
  };

  return (
    <InputField
      value={value}
      placeholder={placeholder}
      onChange={_onChange}
      onKeyDown={_onKeyDown}
      disabled={disabled}
    />
  );
};

export default Input;
