import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MediaPaginationAction from "./MediaListPagination";

import ButtonGroup from "../../shared/components/ButtonGroup";
import { MEDIA_RESOURCE } from "../../constants";
import { Pagination } from "../../mediaresource/containers/ToolBar/PostActions";

const MediaListInfobar = styled.div`
  display: flex;
  flex-direction: row;
  height: 70px;
  width: 100%;
  padding: 10px 0px;
  flex: 10;
  position: relative;
`;

const MediaListInfobarSection = styled.section`
  height: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: flex-end;
  & p {
    margin: auto 0px;
    width: 100%;
    text-align: center;
  }
`;

const MediaListInfobarPagination = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
  flex-grow: 10;
`;

const Checked = styled.span`
  display: inline-flex;
  & p {
    font-weight: 700;
  }
`;

const mediaListInfobar = props => {
  const {
    total: totalMediaItems,
    audio: totalMediaAudio,
    image: totalMediaImages,
    video: totalMediaVideo
  } = props.meta;

  return (
    <MediaListInfobar>
      <MediaListInfobarSection style={{ position: "absolute", left: "-50px" }}>
        {props.hasItems ? (
          <div style={{ width: "125px" }}>
            {" "}
            <span style={{ display: "inline-flex" }}>
              {" "}
              <p> Antal valda: </p>
            </span>{" "}
            <Checked>
              {" "}
              <p>{props.selectedMediaItems} </p>{" "}
            </Checked>
          </div>
        ) : null}
      </MediaListInfobarSection>
      <MediaListInfobarSection style={{ marginLeft: "80px" }}>
        <p>
          <b>{totalMediaItems}</b> objekt
        </p>
      </MediaListInfobarSection>
      <MediaListInfobarSection>
        <p>
          <b>{totalMediaImages} </b> bilder
        </p>
      </MediaListInfobarSection>
      <MediaListInfobarSection>
        <p>
          <b>{totalMediaAudio}</b> ljud{" "}
        </p>
      </MediaListInfobarSection>
      <MediaListInfobarSection>
        <p>
          <b>{totalMediaVideo}</b> video{" "}
        </p>
      </MediaListInfobarSection>
    </MediaListInfobar>
  );
};

const pagination = section => (
  <MediaListInfobarPagination>
    {section === MEDIA_RESOURCE ? <Pagination /> : <MediaPaginationAction />}
  </MediaListInfobarPagination>
);

const MediaListInfoService = props => (
  <div>
    {props.meta && mediaListInfobar(props)}
    {pagination(props.section)}
  </div>
);

MediaListInfoService.propTypes = {
  selectedMediaItems: PropTypes.number,
  totalMediaItems: PropTypes.number,
  totalMediaImages: PropTypes.number,
  totalMediaAudio: PropTypes.number,
  MediaPageUp: PropTypes.func,
  MediaPageDown: PropTypes.func,
  hasItems: PropTypes.bool
};

export default MediaListInfoService;
