import React from "react";
import Link from "../Link";
import * as Icon from "react-feather";

import { OptionTag, OptionTagIcon, PlainTag, TagContent } from "./StyledTag";

const Tag = ({
  label,
  url,
  variant = "info",
  isDeletable = false,
  isEditable = false,
  fill = false,
  labelColor,
  deleteCallback,
  editCallback
}) => {
  /**
   * Render editable
   * @returns {null}
   */
  const renderChangeAble = () =>
    isEditable ? (
      <OptionTag onClick={_editCallback}>
        <OptionTagIcon>
          <Icon.Edit2 size={9} />
        </OptionTagIcon>
      </OptionTag>
    ) : null;

  /**
   * Edit callback
   * @returns {null}
   * @private
   */
  const _editCallback = () => (editCallback ? editCallback() : null);

  /**
   * Render deletable
   * @returns {null}
   */
  const renderDeletable = () =>
    isDeletable ? (
      <OptionTag onClick={_deleteCallback}>
        <OptionTagIcon>
          <Icon.X size={9} />
        </OptionTagIcon>
      </OptionTag>
    ) : null;

  /**
   * Delete callback
   * @returns {null}
   * @private
   */
  const _deleteCallback = () => (deleteCallback ? deleteCallback(label) : null);

  /**
   * Render label
   * @returns {*}
   */
  const renderLabel = () => (
    <PlainTag labelColor={labelColor}>
      <TagContent>{label}</TagContent>
      {renderChangeAble()}
      {renderDeletable()}
    </PlainTag>
  );

  /**
   * Render link
   * @returns {*}
   */
  const renderLink = () => <Link href={url}>{renderLabel()}</Link>;

  return url ? renderLink() : renderLabel();
};

export default Tag;
