import React from "react"
import { ADVENTURES_CARD_EDITOR } from "../../../constants";
import AdventuresCardEditor from "./AdventuresCardEditor";

const AdventureCardDetailed = () => {
  return (
    <AdventuresCardEditor type={ADVENTURES_CARD_EDITOR} />
  )
}

export default AdventureCardDetailed