export const sortSesamName = (a, b) => {
  var nameA = a.sesam.name.toLowerCase();
  var nameB = b.sesam.name.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const removeItemWithIdFromList = (id, list) =>
  list.filter(l => l.id !== id);
