import styled from "styled-components";

export const StyledLineupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.rowLength}, 33px);
  grid-auto-rows: 40px;
  grid-gap: 1px;
  background-color: #979797;

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledCell = styled.div`
  height: auto;
  width: 100%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.borderBottom && "border-bottom: 2px solid #979797"};
  ${props => props.borderTop && "border-top: 2px solid #979797"};
  ${props => props.borderLeft && "border-left: 2px solid #979797"};
  ${props => props.removeBorder && "background-color: #ffffff;"};
`;

export const StyledCellValue = styled.span`
  color: #277575;
  font-size: ${props => (props.isInMemory ? "18px" : "32px")};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  ${props => props.isDivision && props.isInMemory && "margin-right: 15px;"}
  ${props => props.isVertical && "margin-top: -48px;"}
  margin-left: ${props => props.isHorizontal * -32}px;
  ${props => props.isInMemory && "align-self: flex-end"};
`;

export const StyledGhostZero = styled.span`
  border-radius: 20px;
  background-color: #fff;
  border: 3px dotted #277575;
  height: 24px;
  width: 22px;
`