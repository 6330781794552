/**
 *
 * @param {*} tree an array of objects with subarrays. All nodes has an id
 * @returns an object of all parentnode id:s as key and an array of all combined node id:s of nodes below.
 */

function useGetChipsBelowNodes(treeArr) {

  const traverseForIdsBelowNode = tree => {
    return tree.reduce( (acc, node) => {
      const subs = traverseForIdsBelowNode(node.sub_sections);
      const subnodes = Object.values(subs).reduce((a, n) => [...a, ...n], [])
      const uniqueNodes = [...new Set([...subnodes, ...node.chips.map(c => c.id) ])]

      return ({
        ...acc,
        ...{ [node.id]: uniqueNodes},
        ...subs
      })}, {});
  }

  return {
    nodeObj:  traverseForIdsBelowNode(treeArr)
  };
}

export default useGetChipsBelowNodes;
