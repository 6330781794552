import React from "react";

const Plus = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
  >
    <path
      fill={"currentColor"}
      d="M50,21.92V28a2.39,2.39,0,0,1-2.35,2.35H30.48V47.57a2.39,2.39,0,0,1-2.35,2.35H21.87a2.39,2.39,0,0,1-2.35-2.35v-17H2.34A2.38,2.38,0,0,1,0,28.12v-6.2a2.35,2.35,0,0,1,2.35-2.35H19.52V2.39A2.35,2.35,0,0,1,21.87,0H28a2.39,2.39,0,0,1,2.35,2.35V19.57H47.52A2.38,2.38,0,0,1,50,21.84Z"
    />
  </svg>
);

export default Plus;
