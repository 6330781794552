import React from "react";

const Bullseye = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-bullseye"
  >
    <path
      fill="currentColor"
      d="M25,0 C11.1925403,0 0,11.1925403 0,25 C0,38.8074597 11.1925403,50 25,50 C38.8074597,50 50,38.8074597 50,25 C50,11.1925403 38.8074597,0 25,0 Z M25,43.5483871 C14.7489919,43.5483871 6.4516129,35.2530242 6.4516129,25 C6.4516129,14.7489919 14.7469758,6.4516129 25,6.4516129 C35.2510081,6.4516129 43.5483871,14.7469758 43.5483871,25 C43.5483871,35.2510081 35.2530242,43.5483871 25,43.5483871 Z M25,12.0967742 C17.8739919,12.0967742 12.0967742,17.8739919 12.0967742,25 C12.0967742,32.1260081 17.8739919,37.9032258 25,37.9032258 C32.1260081,37.9032258 37.9032258,32.1260081 37.9032258,25 C37.9032258,17.8739919 32.1260081,12.0967742 25,12.0967742 Z M25,31.4516129 C21.4425403,31.4516129 18.5483871,28.5574597 18.5483871,25 C18.5483871,21.4425403 21.4425403,18.5483871 25,18.5483871 C28.5574597,18.5483871 31.4516129,21.4425403 31.4516129,25 C31.4516129,28.5574597 28.5574597,31.4516129 25,31.4516129 Z"
    />
  </svg>
);

export default Bullseye;
