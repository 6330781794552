import styled from "styled-components";

export const StyledVideo = styled.div`
  width: 100%;
  display: flex;
  video {
    max-width: 700px;
  }
`;

export const StyledVideoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledEditInputWrapper = styled.div`
  border: thin solid #999
  font-size: 14px;
  padding: 6px 10px;
  input {
    border: 0
    outline: none;
    width: 100%
  }
  height: 40px;
  margin-top: 2px;
`;

export const StyledAltText = styled.div`
  min-width: 60%;
  margin-top: 15px;
  label {
    font-size: 12px;
  }
`;

export const StyledSettingsRow = styled.div`
  width: 100%;
  margin: 15px 10px;
  color: white;
`;

export const StyledHeaderText = styled.div`
  padding: 0 10px 10px 0
  font-size: 12px;
`;
