import { fromJS, List } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../../store/utils";
import {
  END_FETCH,
  START_FETCH,
  FETCH_ENTITIES_SUCCESS,
} from "../../../api";
import { actionCreator } from "../../../shared";
import {
  UPDATE_MARKER_SUCCESS,
  UPDATE_MARKER_ITEM_SUCCESS,
  UPDATE_MARKER_COLOR_THEME_SUCCESS
} from "./actions";
import {
  EXPAND,
  EXPAND_CLOSE_ELEMENT
} from "../api/actions"

export const requiredFields = {
  headGroupList: [],
  groupList: [],
  itemList: [],
  colorThemes: []
};

const reducer = entityName => initialState => {
  const reducerstate = fromJS(mergeDeepRight(requiredFields, initialState));
  return createReducer(reducerstate, {
    [actionCreator(entityName, START_FETCH)](state) {
      return state.set("fetching", true);
    },
    [actionCreator(entityName, END_FETCH)](state) {
      return state.set("fetching", false);
    },
    [actionCreator(entityName, FETCH_ENTITIES_SUCCESS)](state, action) {

      return state
        .update("groupList", () => new List(action.entities.groupList))
        .update("headGroupList", () => new List(action.entities.headGroupList))
        .update("itemList", () => new List(action.entities.itemList))
        .update("colorThemes", () => new List(action.extras));
    },

    [actionCreator(entityName, UPDATE_MARKER_SUCCESS)](
      state,
      action
    ) {
      const list = state.get("groupList");
      const itemslist = state.get("itemList");
      const groupToUpdate = list.find(p => p.id === action.id);

      return state.set(
        "groupList",
        list.set(
          list.findIndex(p => p.id === action.id),
          {
            ...groupToUpdate,
            marker_type_url: action.url,
            markertype_filename: action.name
          }
        )
      )
        .set("itemList",
          itemslist.map(item => {
            if (item.parent_id === action.id) {
              return {
                ...item,
                marker_type_url: action.url,
                markertype_filename: action.name
              }
            }
            return item
          }))
    },
    [actionCreator(entityName, UPDATE_MARKER_ITEM_SUCCESS)](
      state,
      action
    ) {
      const items = state.get("itemList")
      const selectedItem = items.find(p => p.id === action.id);

      return state.set(
        "itemList",
        items.set(
          items.findIndex(p => p.id === action.id),
          {
            ...selectedItem,
            item_url: action.itemUrl,
            item_filename: action.fileName
          }
        )
      )
    },
    [actionCreator(entityName, UPDATE_MARKER_COLOR_THEME_SUCCESS)](
      state,
      action
    ) {
      const groups = state.get("groupList");
      const items = state.get("itemList");
      const selectedGroup = groups.find(g => g.id === action.groupId);

      return state.set(
        "groupList",
        groups.set(
          groups.findIndex(g => g.id === action.groupId),
          {
            ...selectedGroup,
            colortheme_id: action.themeId
          }
        )
      ).set("itemList",
          items.map(item => {
            if (item.parent_id === action.groupId) {
              return {
                ...item,
                colortheme_id: action.themeId
              }
            }
            return item
          }))
    },
    [actionCreator(entityName, EXPAND)](
      state,
      action
    ){
      const headGroups = state.get("headGroupList")
      const markerGroups = state.get("groupList")      
      return state.set(
        "headGroupList",
        headGroups.map(g => {
          return {...g,isOpen:action.param}
        })
      )
      .set("groupList",
        markerGroups.map(m => {
          return {...m,isOpen:action.param}
        })
      ) 
    },
    [actionCreator(entityName,EXPAND_CLOSE_ELEMENT)](
      state,
      action
    ){      
      const markersList = state.get(`${action.groupType}List`)     
      const elementToUpdate = markersList.find(g => g.id === action.id)
      return state.set(
        `${action.groupType}List`,
        markersList.set(
          markersList.findIndex(g => g.id === action.id),
          {
            ...elementToUpdate,
            isOpen:action.param
          }
        )
      )
    }
  })
};

export default reducer;
