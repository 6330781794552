import styled from "styled-components";
import Input from "../../../shared/components/Input";

export const StyledHelpWrapper = styled.article`
  display: flex;
  width: 100%;
`;

export const StyledHelpContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const StyledTopSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledInputField = styled(Input)`
  width: 50%;
  min-width: 100px;
  max-width: 500px;
  height: 26px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  box-shadow: none;
  font-weight: 300;
  line-height: 19px;
  margin-right: 6px;
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  display: inline;
  ${({ size }) => !size && "margin-top: 10px;"}
  ${({ size }) => size && "align-self: center;"}
`;

export const StyledImageContainer = styled.div`
  width: 50%;
  min-width: 100px;
  max-width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 10px;

  img,
  svg {
    width: 100%;
    height: auto;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const StyledTextArea = styled.div`
  max-width: 1010px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  cursor: text;
  background: #fefefe;

  .TextEditor {
    margin: 0;
  }

  .public-DraftEditor-content {
    min-height: 140px;
  }
`;

export const StyledMediaTitle = styled.p`
  color: ${props => (props.readOnly ? "#277575" : "inherit")};
  font-weight: 600;
  margin: ${({ noMarginLeft }) =>
    noMarginLeft ? "10px 10px 10px 0px" : "10px"};
`;

export const StyledAudioContainer = styled.div`
  display: inline;
  width: 100%;
  min-width: 100px;
  max-width: 500px;
  margin-top: 10px;

  audio {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px rgba(55, 122, 211, 0.5);
      border-radius: 30px;
    }
  }
`;

export const StyledVideoContainer = styled.div`
  margin-top: 10px;

  video {
    max-width: 700px;
  }
`;
