import React from "react";
import { useDispatch } from "react-redux";
import DropDown from "../../../shared/components/DropDown/DropDown";
import { Ellipsis } from "../../../shared/components/StudliIcons";
import { Item } from "../../../shared/components/DropDown";
import {
  DELETE,
  PUBLISH,
  RIGHT,
  UNPUBLISH,
  ACTION_MENU_LABELS,
  LEFT,
  ENTITIES,
  SERIES,
  DRAFT_PREVIEW_PUBLISHED,
  REVISION_PREVIEW_PUBLISHED
} from "../../../constants";
import styled from "styled-components";
import {
  publishPosts,
  deletePosts,
  unpublishPosts,
  publishPreviewPosts
} from "../../store/actions";
import HasPermission, { hasPermissionBool } from "../../../shared/Permissions";
import { deletePostQuestionModal } from "./deletePostQuestionModal";

const StyledDropDown = styled(DropDown)`
  < div {
    position: fixed;
  }
`;

const EllipsisDropDownMenu = ({ data, useInTable }) => {
  const dispatch = useDispatch();
  const {
    actions,
    status,
    workflowMap,
    type,
    permissions,
    askWhenDelete = false,
    deleteCallback
  } = data;

  if (!status || status === "") {
    return null;
  }
  const workflowMapStatuses = workflowMap ? Object.keys(workflowMap) : [];
  let statusArr = workflowMapStatuses.length > 0 ? workflowMap[status] : [];

  const workflowWithPreview =
    workflowMapStatuses.includes(DRAFT_PREVIEW_PUBLISHED) ||
    workflowMapStatuses.includes(REVISION_PREVIEW_PUBLISHED);

  if (!useInTable) {
    statusArr = statusArr.filter(s => s !== DELETE);
  }

  if (actions.edit) {
    statusArr = ["edit", ...statusArr];
  }

  const executeAction = action => {
    const actionObj = actions[action];
    const { postType, postIds, mode } = actionObj;

    const onDeleteOK = () => {
      dispatch(deletePosts(postType, postIds));
      if (deleteCallback) {
        deleteCallback(postIds)
      }
    };

    switch (action) {
      case DELETE:
        return askWhenDelete
          ? deletePostQuestionModal(dispatch)({
              onClickOK: onDeleteOK
            })
          : onDeleteOK();
      case PUBLISH:
        if (workflowWithPreview) {
          return status === DRAFT_PREVIEW_PUBLISHED ||
            status === REVISION_PREVIEW_PUBLISHED
            ? dispatch(publishPosts(postType, postIds, mode))
            : dispatch(publishPreviewPosts(postType, postIds, mode));
        }
        return dispatch(publishPosts(postType, postIds, mode));
      case UNPUBLISH:
        return dispatch(unpublishPosts(postType, postIds, mode));
      default:
        dispatch(actionObj);
    }
  };

  const contentStyle = useInTable ? { position: "fixed" } : { top: "55px" };
  const menuStyles = useInTable
    ? {
        right: 0,
        display: "inline-block",
        minWidth: "inherit",
        border: 0
      }
    : {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        minWidth: "inherit",
        border: 0
      };

  return (
    <HasPermission
      component={
        <StyledDropDown
          title={<Ellipsis size={16} />}
          hasChevron={false}
          defaultValue=""
          onChange={executeAction}
          dropDownAlign={useInTable ? RIGHT : LEFT}
          overrideContentStyles={contentStyle}
          overrideStyles={menuStyles}
        >
          {statusArr
            .filter(a => hasPermissionBool(type.toLowerCase(), a, permissions))
            .map(item => (
              <Item key={item} value={item}>
                {ACTION_MENU_LABELS[item]}
              </Item>
            ))}
        </StyledDropDown>
      }
      multiplePermissions={statusArr}
      section={type.toLowerCase()}
      {...(type === SERIES && { type: ENTITIES[SERIES] })}
      {...(type === "classroomgroup" && { type: "Classroom" })}
    />
  );
};

export default EllipsisDropDownMenu;
