import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import InputDefault from "../../../shared/components/Input";
import { filterClassroomGroups } from "../../api/actions";

const Input = styled(InputDefault)`
  height: 40px;
  width: 348px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  box-shadow: none;
  display: block;

  &:active,
  &:focus {
    border-width: 2px;
    border-color: #006cc8;
  }

  &:hover:not(&:active, &:focus) {
    border-color: rgba(0, 0, 0, 0.52);
  }
`;

const SearchField = () => {
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const onChange = val => {
    setValue(val);
    dispatch(filterClassroomGroups({ search: val }));
  };

  return (
    <>
      <Input
        name="search_classroomgroup"
        value={value}
        onChange={onChange}
        placeholder="Sök klassrumsgrupp"
      />
    </>
  );
};

export default SearchField;
