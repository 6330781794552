import styled from "styled-components";
import { PrimaryButton, StandardButton } from "../../../shared";
import {
  deleteSeries,
  publishSeries,
  publishPreviewSeries,
  unpublishSeries,
} from "../../api/actions";
import { useDispatch } from "react-redux";
import HasPermission from "../../../shared/Permissions";
import {
  DELETE,
  ENTITIES,
  DRAFT_PREVIEW_PUBLISHED,
  PUBLISH,
  REVISION_PREVIEW_PUBLISHED,
  SERIES,
  UNPUBLISH
} from "../../../constants";
import { useState } from "react";

const ActionGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const SelectedSeries = styled.div`
  padding: 10px;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0px 5px;
`;

const DeleteSeriesWrapper = styled.div`
  position: relative;
  margin: 2px;
`;

const DeleteModal = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.neutralLight};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  white-space: nowrap;
  margin-top: 3px;
  padding: 0;
  display: none;
  transform: scale(0);
  transform-origin: top;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;

  &.isOpen {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;

const Title = styled.div`
  ${props => props.theme.d100()};
  margin: 0;
  padding: 10px 15px;
`;
const MiddleContent = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const Top = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const UpdateButton = styled(StandardButton)`
  width: 280px;
  border-radius: 5px;
  display: block;
  margin: 5px 0 0 0 !important;
`;

const SeriesActions = ({ selected }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  // selected > 0
  const workflowMap = selected[0].workflowMap;
  const actionsAllowed = selected
    .map(item => item.status)
    .every((action, _, arr) => action === arr[0])
    ? workflowMap[selected[0].status]
    : [];

  const publishAllowed =
    selected.length === 1 && actionsAllowed.includes(PUBLISH);

  const deleteAllowed = actionsAllowed.includes(DELETE);

  const unpublishedAllowed =
    selected.length === 1 && actionsAllowed.includes(UNPUBLISH);

  const removeSeries = () => dispatch(deleteSeries(selected.map(s => s.id)));

  // single publish for now.
  const publish = () => {
    selected[0].status === DRAFT_PREVIEW_PUBLISHED ||
    selected[0].status === REVISION_PREVIEW_PUBLISHED
      ? dispatch(publishSeries(selected[0].id))
      : dispatch(publishPreviewSeries(selected[0].id));
  };

  const unpublish = () => {
    dispatch(unpublishSeries(selected[0].id));
  }

  return (
    <ActionGroup>
      <SelectedSeries>
        {selected.length === 1
          ? `${selected.length} markerad`
          : `${selected.length} markerade`}
      </SelectedSeries>
      {publishAllowed && (
        <HasPermission
          component={
            <StyledButton onClick={publish} outline>
              Publicera
            </StyledButton>
          }
          section={SERIES}
          permission={PUBLISH}
          type={ENTITIES[SERIES]}
        />
      )}
      {unpublishedAllowed && (
        <HasPermission
          component={
            <StyledButton onClick={unpublish} outline>
              Avpublicera
            </StyledButton>
          }
          section={SERIES}
          permission={UNPUBLISH}
          type={ENTITIES[SERIES]}
        />
      )}
      {deleteAllowed && (
        <HasPermission
          component={
            <DeleteSeriesWrapper>
              <StyledButton outline onClick={() => setModalOpen(!modalOpen)}>
                Ta bort
              </StyledButton>
              <DeleteModal className={modalOpen ? "isOpen" : "isClosed"}>
                <Top>
                  <Title>Ta bort:</Title>
                </Top>
                <MiddleContent>
                  <UpdateButton studlicon="Trash" onClick={removeSeries}>
                    {`Ta bort ${selected.length}  ${
                      selected.length === 1 ? "serie" : "serier"
                    }`}
                  </UpdateButton>
                </MiddleContent>
              </DeleteModal>
            </DeleteSeriesWrapper>
          }
          section={SERIES}
          permission={DELETE}
          type={ENTITIES[SERIES]}
        />
      )}
    </ActionGroup>
  );
};

export default SeriesActions;
