import React from "react";
import { LockSolid } from "../../StudliIcons/index";
import { LOCKED, LOCKCODES } from "../../../../constants";

import { StyledStatusWrapper, StyledIcon } from "./StyledStatus";

const LockRenderer = ({ rowData: { lock } }) => {
  const studliSize = 20;

  /**
   * Returns proper status of icon
   */
  const getLockStatus = () => {
    if (lock && lock.code === LOCKCODES.LockedByOther) {
      return LOCKED;
    }
    return null;
  };

  /**
   * Renders icons
   * @param themeColor
   * @returns {*}
   */
  const renderLockIcon = lockStatus =>
    lockStatus === LOCKED ? (
      <StyledIcon color="#4A4A4A">
        <LockSolid size={studliSize} />
      </StyledIcon>
    ) : null;

  return (
    <StyledStatusWrapper>{renderLockIcon(getLockStatus())}</StyledStatusWrapper>
  );
};

export default LockRenderer;
