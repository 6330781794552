import HelpVideo from "./components/Help/HelpVideo/HelpVideo";
import { getMediaObject } from "./dataModels";
import { HELP_VIDEO, VIDEO } from "../constants";

export const createHelpVideoPlugin = () => ({
  entityRendererFn: entity => {
    const types = [HELP_VIDEO];

    if (types.includes(entity.type)) {
      return {
        component: HelpVideo,
        style: { padding: "0px" },
        props: {
          data: entity.data,
          settings: entity.settings
        }
      };
    }
    return null;
  }
});

export default createHelpVideoPlugin;

export const emptyBlocks = {
  [HELP_VIDEO]: {
    type: HELP_VIDEO,
    data: {
      title: null,
      source: null,
      [VIDEO]: getMediaObject(VIDEO)
    },
    settings: {}
  }
};
