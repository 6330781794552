import React from "react";
import styled from "styled-components";
import Shadow from "./Shadow";
import { connect } from "react-redux";

const Modal = ({ isModalOpen, className, modalClose, children, disableShadowClose }) => (
  <aside className={className}>
    {isModalOpen
      ? React.cloneElement(children, { visible: isModalOpen, modalClose })
      : null}
    <Shadow backdrop={isModalOpen} onClick={disableShadowClose ? null : modalClose} />
  </aside>
);

const mapStateToProps = state => {
  return {
    disableShadowClose: state.modal.get("disableShadowClose")
  };
};

export default connect(mapStateToProps)(styled(Modal)`
  pointer-events: none;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  visibility: visible;
  z-index: 50;
  transition: visibility linear 0.35s;
  ${props =>
    props.isModalOpen
      ? `
    pointer-events: auto;
    visibility: visible;
  `
      : null};
`);
