import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import { ROUTE_OP_MESSAGES, ROUTE_DETAILED_OP_MESSAGE } from "../routes";
import {
  fetchEntitiesSaga,
  unpublishPostsSaga,
  deletePostsSaga,
  savePostsSaga,
  fetchEntityDetailsSaga,
  fetchAllChipsForEntitySaga,
  addRemoveChipsToPostSaga
} from "../../posts/api/saga";

import {
  PUBLISH_POSTS,
  UNPUBLISH_POSTS,
  DELETE_POSTS,
  SAVE_ENTITY_DETAILS,
  CREATE_ENTITY_DETAILS,
  SAVE_POST,
  LOAD_LESS_POSTS,
  LOAD_MORE_POSTS,
  LOAD_POST_PAGE,
  RELOAD_POST,
  RELOAD_POSTS,
  SORT_POSTS,
  FILTER_POSTS,
  FETCH_CHIPS,
  POST_ADD_REMOVE_CHIPS
} from "../../posts/store/actions";

import { publishMessagePostsSaga } from "./sagas";

import {
  onListRoute,
  sortList,
  filterPosts,
  previousPageSaga,
  nextPageSaga,
  reloadPageSaga,
  loadPageSaga,
  onDetailedRoute,
  onFetchChips
} from "../../posts/sagas/sagas";
import { FETCH_ENTITIES, FETCH_ENTITY_DETAILS } from "../../api";
import { actionCreator } from "../../shared";
import { ENTITIES, OPMESSAGE } from "../../constants";

function* watchPostRequests() {
  yield takeEvery(
    actionCreator(OPMESSAGE, FETCH_ENTITIES),
    fetchEntitiesSaga(ENTITIES[OPMESSAGE], "listOpMessagePosts")
  );
  yield takeLatest(
    actionCreator(OPMESSAGE, FETCH_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[OPMESSAGE], "fetchOpMessagePost")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, SAVE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[OPMESSAGE], "saveOpMessagePost")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, CREATE_ENTITY_DETAILS),
    fetchEntityDetailsSaga(ENTITIES[OPMESSAGE], "createOpMessagePost")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, SAVE_POST),
    savePostsSaga(ENTITIES[OPMESSAGE], "savePost")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, LOAD_LESS_POSTS),
    previousPageSaga,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, LOAD_MORE_POSTS),
    nextPageSaga,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, LOAD_POST_PAGE),
    loadPageSaga,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, RELOAD_POSTS),
    reloadPageSaga,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, RELOAD_POST),
    onDetailedRoute,
    ENTITIES[OPMESSAGE],
    "fetchOpMessagePost"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, SORT_POSTS),
    sortList,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeLatest(
    actionCreator(OPMESSAGE, FILTER_POSTS),
    filterPosts,
    ENTITIES[OPMESSAGE],
    "filterPosts"
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, POST_ADD_REMOVE_CHIPS),
    addRemoveChipsToPostSaga(ENTITIES[OPMESSAGE], "addRemoveChipsToPost")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, PUBLISH_POSTS),
    publishMessagePostsSaga(ENTITIES[OPMESSAGE], "publishPosts")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, UNPUBLISH_POSTS),
    unpublishPostsSaga(ENTITIES[OPMESSAGE], "unpublishPosts")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, DELETE_POSTS),
    deletePostsSaga(ENTITIES[OPMESSAGE], "deletePosts")
  );
  yield takeEvery(
    actionCreator(OPMESSAGE, FETCH_CHIPS),
    fetchAllChipsForEntitySaga(ENTITIES[OPMESSAGE], "fetchAllChipsForEntity")
  );
}

function* watchPostsRoutes() {
  yield takeEvery(
    ROUTE_OP_MESSAGES,
    onListRoute,
    ENTITIES[OPMESSAGE],
    "listOpMessagePosts"
  );
  yield takeEvery(
    ROUTE_OP_MESSAGES,
    onFetchChips,
    ENTITIES[OPMESSAGE],
    "fetchAllChips"
  );
  yield takeEvery(
    ROUTE_DETAILED_OP_MESSAGE,
    onDetailedRoute,
    ENTITIES[OPMESSAGE],
    "fetchOpMessagePost"
  );
  yield takeEvery(
    ROUTE_DETAILED_OP_MESSAGE,
    onFetchChips,
    ENTITIES[OPMESSAGE],
    "fetchAllChips"
  );
}

export default function* rootSaga() {
  yield all([fork(watchPostRequests), fork(watchPostsRoutes)]);
}
