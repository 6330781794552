import * as querystring from "querystring";
import { client, longTimeoutClient } from "../../api";

/**
 *
 * @param {int} entityId - Map of entityId
 */
export const fetchMediaTagFilter = ({ entityId }) => {
  const params = { entity_id: entityId, tags: true, type: "media" };
  return client.get(`tagcategories?${querystring.stringify(params)}`);
};

/**
 * Fetch a list of mediafiles
 * @param {{ entityId: number }} arg object with data
 * @param {object} searchParameters
 */
export const listMedia = ({ entityId, entityType, overrideEntityId }, searchParameters) => {
  const params = Object.assign({ entityId: overrideEntityId || entityId }, { entityType }, searchParameters, {
    sort_orders: searchParameters.sort
      ? Object.keys(searchParameters.sort).map(key =>
        `${key}:${searchParameters.sort[key]}`.toLowerCase()
      )
      : []
  });

  entityId && delete params.entityType;
  delete params.sort;
  return client.get(`media/files?${querystring.stringify(params)}`);
};

/**
 * Fetch media file with id fileID
 * @param {*} entityId
 * @param {*} fileID
 */
export const fetchMedia = ({ fileID }) => client.get(`media/file/${fileID}`);

/**
 * Update mediafile
 * @param {object} args object with data to update mediafile
 * @param {number} args.mediaId the id of the mediafile to be updated
 * @param {object} args.data object containing data like description.
 *
 */
export const updateMediaFile = ({ mediaId, data }) => {
  let formData = new FormData();

  if (data.description) {
    formData.append("description", data.description);
  }

  if (data.title) {
    formData.append("title", data.title);
  }
  return client.put(`media/file/${mediaId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

/**
 * Upload mediafile
 * @param {{files: * }} arg object with mediafiles to upload
 */
export const uploadMedia = async ({ files, json, entityId, entityType, basePath }) => {
  let formData = new FormData();
  files.forEach(file => {
    json &&
      json.title &&
      formData.append("title", json.title);
    json &&
      json.description &&
      formData.append("description", json.description);
    json && json.labels && formData.append("labels", json.labels);

    formData.append("file", file, file.name);

    entityType && formData.append("entity_type", entityType);
    entityId && formData.append("entity_id", entityId);
    basePath && formData.append("base_path", basePath);
  });

  try {
    return await longTimeoutClient.post(`media/file`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (error) {
    return error;
  }
};

/**
 * Delete mediafile/s
 * @param {*} ids (array)
 */
export const deleteMediaFiles = ids => client.delete(`/media/files`, ids);

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const addTagsToMedia = data => client.put(`media/tag`, data);

/**
 *
 * @param {Object} args - Map of Post attribute names to values
 * @param {Object} entityMap - New entityMap for the Post
 */
export const removeTagsFromMedia = data => client.delete(`media/tag`, { data });
