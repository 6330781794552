import React from "react";
import styled from "styled-components";

const NoRowsWrapper = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.neutralLight};
`;

const NoRows = () => <NoRowsWrapper>Inga träffar</NoRowsWrapper>;

export default NoRows;
