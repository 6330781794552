import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "../../../products/components/IconButton";
import { Trash2 as Trash } from "react-feather";
import Label from "../../../products/components/Label";
import SeriesSearch from "../../../series/components/SeriesSearch";
import { isRefIdInList } from "../../api/helpers";
import Modal from "../../../shared/components/Modal/Modal";
import PrimaryButton from "../../../shared/components/Button/Primary";

const Search = styled(SeriesSearch)`
  width: 100%;
  margin-top: 0;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const StyledProductsWrapper = styled.div`
  height: 200px;
  padding: 4px 0;
  ${props => (props.showScroll ? "overflow-y: scroll" : null)};
`;

const SeriesWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const Text = styled.p`
  margin: 0;
`;

const ErrorMsg = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0 0 1rem 0.25rem;
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const SearchSeriesAndList = ({ addSerie, series, removeSerie, allSeries }) => {
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSerie, setSelectedSerie] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const trashButtonClick = refId => {
    setIsModalOpen(true);
    setSelectedSerie(refId);
  };

  const removeSerieButtonClick = () => {
    removeSerie(selectedSerie);
    setIsModalOpen(false);
  };

  const getSeriesNameFromRefId = refId => {
    if (allSeries.length === 0) {
      return "";
    }
    const serie = allSeries.find(s => s.refId === refId);
    return serie.title;
  };

  const addNewSerieToList = refId => {
    if (isRefIdInList(series, refId)) {
      setError(true);
      return;
    }

    addSerie(refId);
    setError(false);
  };

  return (
    <>
      <Label>
        Koppla meddelandet till serie/r
        <Search
          placeholder="Sök serie"
          onSelect={p => addNewSerieToList(p.refId)}
        />
        {error && <ErrorMsg>Serien finns redan med i listan.</ErrorMsg>}
      </Label>
      <StyledProductsWrapper showScroll={series && series.length > 6}>
        {series &&
          series.map(sRefId => (
            <SeriesWrapper key={sRefId}>
              <IconButton
                aria-label="Ta bort serie"
                onClick={() => trashButtonClick(sRefId)}
              >
                <Trash width="16px" height="16px" />
              </IconButton>
              <Text>{getSeriesNameFromRefId(sRefId)}</Text>
            </SeriesWrapper>
          ))}
      </StyledProductsWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          Vill du ta bort serien?
          <StyledButton onClick={removeSerieButtonClick}>Ta bort</StyledButton>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default SearchSeriesAndList;
