import * as React from "react";
import { Input } from "../Input";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { GripHandle } from "../../../shared/components/StudliIcons";
import {
  StyledItem,
  StyledItemContainer,
  StyledValueContentWrapper,
  StyledDragHandleWrapper,
  StyledItemEditText,
  StyledValueEditInputWrapper,
  StyledWarningText
} from "./StyledLineup";
import { isNumeric } from "../../../shared/helpers";
import { warningText, firstTermLengthIsGreaterOrEqual, valuesIswithinBorders, hasDecimals } from "./LineupData";
import { DIVISION, MULTIPLICATION, REST } from "../../../constants";

export const LineupItem = SortableElement(
  ({ data: { value: itemValue }, indx: index, result, editBtnFn, type, items, answerType, warnings }) => {
    /**
     * Edit question value text
     * @param {*} v
     */
    const onValueChange = value => editBtnFn(index, { value: value.trim() });

    /**
     * Renders warning text
     * @param {*} text
     */
    const renderWarningText = text => (
      <StyledWarningText>{warningText[Object.keys(text)[0]]}</StyledWarningText>
    );

    /**
     * render the edit text
     */
    const renderValueBox = value => {
      const isEmpty = value === "";
      const isNumber = isNumeric(value) || isEmpty;
      const hasDecimal = hasDecimals(value, type, index)
      const isWithinBorders = valuesIswithinBorders(value, type, index)
      const zeroValue = parseFloat(value) === 0 && type === MULTIPLICATION
      const valueIsNegative = parseInt(value) < 0;
      const resultIsNegative = result < 0;
      const resultHasDecimals = result < 1 && result > 0 && answerType === REST && type === DIVISION
      const correctTermsAlignment = type === MULTIPLICATION
        ? firstTermLengthIsGreaterOrEqual(items)
        : true

      return (
        value !== undefined && (
          <StyledItemEditText>
            <StyledValueEditInputWrapper danger={!isNumber}>
              <Input
                placeholder={"Tal " + (index + 1)}
                value={value}
                onChange={onValueChange}
              />
              {
                (!isNumber && renderWarningText({ isNumber })) ||
                (hasDecimal && renderWarningText({ hasDecimal })) ||
                (!isWithinBorders && renderWarningText({ isWithinBorders })) ||
                (valueIsNegative && renderWarningText({ valueIsNegative })) ||
                (resultIsNegative && renderWarningText({ resultIsNegative })) ||
                (!correctTermsAlignment && index === 0 && renderWarningText({ correctTermsAlignment })) ||
                (zeroValue && renderWarningText({ zeroValue })) ||
                (resultHasDecimals && renderWarningText({ resultHasDecimals })) ||
                (warnings && renderWarningText(warnings))
              }
            </StyledValueEditInputWrapper>
          </StyledItemEditText>
        )
      );
    };

    return (
      <StyledItem>
        <StyledDragHandleWrapper>
          <DragHandle />
        </StyledDragHandleWrapper>
        <StyledValueContentWrapper>
          {renderValueBox(itemValue)}
        </StyledValueContentWrapper>
      </StyledItem>
    );
  }
);

export const ItemContainer = SortableContainer(({ children }) => {
  return <StyledItemContainer>{children}</StyledItemContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);
