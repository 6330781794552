import styled from "styled-components";
import { getWidth, getHeight, getBorderThickness } from "../../ChartHelpers";

export const StyledMediaContainer = styled.div`
  display: flex;
  align-self: center;
  padding: 2px;
  border: thin solid #999;
  border-width: ${({ border }) => getBorderThickness(border)};
  background-color: white;
  border-radius: 0px;
  ${props => props.selected && "border: 3px solid black;"}
  height: ${props => getHeight(props.column)};
  width: ${props => getWidth(props.row)};
`;
