import React from "react";

const MediaResourceIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-media"
  >
    <path
      fill="currentColor"
      d="M46.9,12.6V9.5h2C49.5,9.6,50,9,50,8.4c0,0,0,0,0,0V4.5c0-0.6-0.5-1.2-1.2-1.2c0,0,0,0,0,0h-3.9
      c-0.6,0-1.2,0.5-1.2,1.1c0,0,0,0,0,0v1.9H6.3V4.5c0-0.6-0.5-1.2-1.2-1.2c0,0,0,0,0,0H1.2C0.5,3.4,0,3.9,0,4.5c0,0,0,0,0,0v3.8
      C0,9,0.5,9.5,1.1,9.5c0,0,0,0,0,0h2v30.9h-2C0.5,40.4,0,41,0,41.6c0,0,0,0,0,0v3.9c0,0.6,0.5,1.2,1.2,1.2c0,0,0,0,0,0h3.9
      c0.6,0,1.2-0.5,1.2-1.1c0,0,0,0,0,0v-1.9h37.5v1.9c0,0.6,0.5,1.2,1.2,1.2c0,0,0,0,0,0h3.9c0.6,0,1.2-0.5,1.2-1.1c0,0,0,0,0,0v-3.9
      c0-0.6-0.5-1.2-1.2-1.2c0,0,0,0,0,0h-2V12.6z M9.6,27.2V14.1c0-0.6,0.5-1.2,1.2-1.2c0,0,0,0,0,0h16.8c0.7,0,1.2,0.5,1.2,1.1
      c0,0,0,0,0,0v13.1c0,0.7-0.6,1.2-1.2,1.2c0,0,0,0,0,0H10.8C10.2,28.4,9.6,27.9,9.6,27.2C9.6,27.2,9.6,27.2,9.6,27.2z M40.4,35.9
      c0,0.6-0.5,1.1-1.1,1.1c0,0,0,0,0,0h-16c-0.6,0-1.1-0.5-1.1-1.1c0,0,0,0,0,0v-5H29c1.3,0,2.3-1,2.3-2.3c0,0,0,0,0,0v-6.9h8
      c0.6,0,1.1,0.5,1.1,1.1L40.4,35.9z"
    />
  </svg>
);

export default MediaResourceIcon;
