import { call, put, select, all } from "redux-saga/effects";
import * as api from "./requests";

import { generalErrorHandler } from "../../api";

import {
  fetchEntities,
  fetchEntitiesSuccess,
  fetchEntitiesError,
  fetchEntityDetailsSuccess,
  fetchEntityDetailsError,
  startFetch,
  endFetch,
  fetchFilterSuccess,
  fetchFilterError,
  fetchComments,
  fetchCommentsSuccess,
  fetchCommentsError,
  setTmpDataForNewPost,
  clearTmpDataForNewPost,
  setDescription
} from "./actions";

import { getCurrentPostSelector } from "./selectors";

import { selectPostId } from "../sagas/selectors";
import * as sharedModule from "../../shared";
import { ROUTE_MEDIARESOURCE_EDIT } from "../routes";
import { selectEntityId } from "../../store/selectors";

export const fetchEntitiesSaga = (entityName, apiAction) => {
  return function*(action) {
    let batchIndex = action.batchIndex;
    if (batchIndex === undefined) {
      batchIndex = yield select(st => st[entityName].get("page"));
    }
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const args = yield select(st => st[entityName].get("args").toJS());

    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], args, searchParameters);
      yield put(
        fetchEntitiesSuccess(
          action.entityName,
          result.data.data,
          result.data.metadata,
          searchParameters,
          batchIndex
        )
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchEntitiesError(action.entityName, error.response, batchIndex)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

export const fetchEntityDetailsSaga = (entityName, apiAction) => {
  return function*(action) {
    if (action.args.postId === "new") {
      yield put(clearTmpDataForNewPost(entityName));
    }
    yield put(startFetch(entityName));

    try {
      const result = yield call(api[apiAction], action.args);
      yield put(
        fetchEntityDetailsSuccess(
          entityName,
          result.data,
          result.metadata,
          action.args
        )
      );
      yield ifNewPostSavedActions(entityName, result, action);
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(
        fetchEntityDetailsError(entityName, error.response, action.args)
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * If this post was a new post that now has been saved - Check if servicebar data has been temporarily saved
 * for this post and save them. Reload page so url is correct.
 * @param {*} entityName
 * @param {*} result
 * @param {*} action
 */
function* ifNewPostSavedActions(entityName, result, action) {
  if (!action.args.postId && result.data.data.id !== null) {
    // This was a new post with no id. Some data may have been temporarily saved from the servicebar.
    // With the new id the changes can be saved.
    const draft = yield select(st => st[entityName].get("newDataTmp").toJS());
    if (draft.hasDataSaved) {
      if (draft.difficulty !== null) {
        yield call(api["savePost"], {
          entityId: action.args.entityId,
          postId: result.data.data.id,
          difficulty: draft.difficulty
        });
      }
      if (draft.category !== null) {
        yield call(api["setCategory"], {
          entityId: action.args.entityId,
          posts: [result.data.data.id],
          categoryId: draft.category
        });
      }
      if (draft.tags.length !== 0) {
        yield call(api["addTagsToPost"], {
          post_ids: [result.data.data.id],
          tags: draft.tags
        });
      }
      if (draft.comments) {
        let apicalls = {};
        draft.comments.map((c, i) => {
          apicalls[i] = call(api["addCommentToPost"], {
            entityId: action.args.entityId,
            comment: c.message,
            postId: result.data.data.id
          });
          return c;
        });
        yield all(apicalls);
      }
      yield put(clearTmpDataForNewPost(entityName));
    }

    // reload page with the new id
    yield put({
      type: ROUTE_MEDIARESOURCE_EDIT,
      payload: {
        entityId: action.args.entityId,
        id: result.data.data.id
      }
    });
  }
}

/**
 * Fetches the filterdata from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchFiltersSaga = (entityName, apiAction) => {
  return function*(action) {
    yield put(startFetch(entityName));

    const entityId = yield select(selectEntityId);

    let fdata = (action.filters || []).map(type =>
      sharedModule.filterdata[type] ? sharedModule.filterdata[type] : null
    );

    try {
      const result = yield call(api["fetchTagFilter"], {
        entityId,
        posttype: ["mediaresource"]
      });
      let labels = [];

      fdata = fdata.map(obj => {
        if (obj === null) return obj;
        const { title, key, component, open, filterkey } = obj;
        let data = null;

        if (key === "labels") {
          data = result ? result.data.data : [];
          data = data.filter(d => d.type === "label");
          labels =
            data[0] && data[0].tags
              ? data[0].tags.map(tag => {
                  tag.color = tag.color || "#AAAAAA";
                  return tag;
                })
              : [];
        }

        if (key === "tags") {
          data = result ? result.data.data : [];
          data = data.filter(d => d.type !== "label");
        }

        return {
          title,
          key,
          component,
          open,
          data,
          filterkey
        };
      });

      yield put(fetchFilterSuccess(action.entityName, fdata, labels));
    } catch (error) {
      yield generalErrorHandler(error);

      yield put(
        fetchFilterError(action.entityName, error.response, { entityId })
      );
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * Fetches comments for a post from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchCommentsSaga = (entityName, apiAction) => {
  return function*(action) {
    try {
      const result = yield call(api[apiAction], action.args);
      yield put(fetchCommentsSuccess(entityName, result.data));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchCommentsError(entityName, error.response, action.args));
    }
  };
};

/**
 * Help function for add/remove tags
 * @param {array} tags ex [2, 7, 13]
 * @param {object} add
 * @param {object} remove
 * @return {array}
 */
const combineTags = (tags, add, remove) => {
  let newTags = tags;
  if (add !== null) {
    add.tags.map(tag => {
      newTags = newTags.indexOf(tag) < 0 ? newTags.concat([tag]) : newTags;
      return tag;
    });
  }
  if (remove !== null) {
    newTags = newTags.filter(f => remove.tags.indexOf(f) < 0);
  }
  return newTags;
};

/**
 * Add or remove tags
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addRemoveTagsToPostSaga = (entityName, apiAction) => {
  return function*(action) {
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const urlPostId = yield select(selectPostId);
    let apicalls = {};

    if (urlPostId !== "new") {
      if (action.add !== null) {
        apicalls["add"] = call(api["addTagsToPost"], action.add);
      }
      if (action.remove !== null) {
        apicalls["remove"] = call(api["removeTagsFromPost"], action.remove);
      }
    } else {
      const data = yield select(st => st[entityName].get("newDataTmp"));
      const tags = data.get("tags");
      const newdata = data.set(
        "tags",
        combineTags(tags, action.add, action.remove)
      );

      yield put(setTmpDataForNewPost(entityName, newdata));
    }

    try {
      yield all(apicalls);

      if (!action.detailpage) {
        yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

export const setCategoryToPostsSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const urlPostId = yield select(selectPostId);
    try {
      if (urlPostId === "new") {
        const data = yield select(st => st[entityName].get("newDataTmp"));
        const newdata = data.set("category", action.catId);
        yield put(setTmpDataForNewPost(entityName, newdata));
      } else {
        if (action.catId > -1) {
          yield call(api["setCategory"], {
            entityId: entityId,
            posts: action.postIds,
            categoryId: action.catId
          });
        } else {
          yield call(api["unsetCategory"], {
            entityId: entityId,
            posts: action.postIds
          });
        }
      }

      if (!action.useCurrentPost) {
        yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

export const deletePostsSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    const data = { posts: action.postIds };
    yield call(api[apiAction], { entityId: entityId, data: data });
    yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
  };
};

export const publishPostsSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    yield call(api[apiAction], { entityId: entityId, posts: action.postIds });
    yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
  };
};

export const unpublishPostsSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const searchParameters = yield select(st =>
      st[entityName].get("searchParameters").toJS()
    );
    yield call(api[apiAction], { entityId: entityId, posts: action.postIds });
    yield put(fetchEntities(entityName, apiAction, {}, searchParameters));
  };
};

/**
 * Save a post without fetching the updated post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const savePostsSaga = (entityName, apiAction) => {
  return function*(action) {
    // only save post - don't fetch
    const urlPostId = yield select(selectPostId);
    try {
      if (urlPostId === "new") {
        const data = yield select(st => st[entityName].get("newDataTmp"));
        yield put(setTmpDataForNewPost(entityName, data));
      } else {
        yield call(api[apiAction], action.args);
      }
    } catch (error) {
      yield generalErrorHandler(error);
    }
  };
};

/**
 * Add a comment to a post
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addCommentToPost = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const currentPost = yield select(getCurrentPostSelector(entityName));
    const postId = currentPost.get("data").id;
    const urlPostId = yield select(selectPostId);

    if (urlPostId === "new") {
      const data = yield select(st => st[entityName].get("newDataTmp"));
      const comments = data.get("comments");
      const newComment = {
        message: action.comment,
        user_id: 1,
        entity_id: entityId,
        post_id: 0,
        updated_at: ""
      };
      const newdata = data.set("comments", comments.push(newComment));
      yield put(setTmpDataForNewPost(entityName, newdata));
    } else {
      yield call(api[apiAction], {
        entityId,
        comment: action.comment,
        postId
      });
      yield put(
        fetchComments(entityName, "loadComments", { entityId, postId })
      );
    }
  };
};

/**
 * Add description to the post and the current state.
 * @param {*} entityName
 * @param {*} apiAction
 */
export const addDescriptionToPost = (entityName, apiAction) => {
  return function*(action) {
    yield put(setDescription(entityName, action.description));
  };
};
