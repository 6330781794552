import { client } from "../../api";
import * as querystring from "querystring";

export const listSeries = (type, searchParameters) => {
  const params = Object.assign(
    {
      type
    },
    searchParameters,
    {
      sort_orders: searchParameters.sort
        ? Object.keys(searchParameters.sort).map(
            key => `${key}:${searchParameters.sort[key]}`
          )
        : []
    }
  );

  delete params.sort;
  return client.get(`entities?${querystring.stringify(params)}`);
};

export const createSeries = data => client.post(`entity?type=series`, data);

export const seriesDetails = entityId => {
  return client.get(`entity/${entityId}?type=series`);
};

export const updateSeriesDetails = (entityId, data) =>
  client.put(`entity/${entityId}`, data);

export const deleteSeries = data =>
  client.delete(`entities?type=series`, { data });

export const createRelationForSeries = (entityParentId, entityChildId) =>
  client.put(`entity/relations`, { entityParentId, entityChildId });

export const deleteRelationFromSeries = (parentId, childId) =>
  client.delete(`entity/${parentId}/relation/${childId}`);

export const fetchPermissions = () =>
  client.get(`entity-type/series/permissions`);

export const fetchWorkflow = () => client.get(`entity-type/series/workflow`);

export const publishSerie = postIds => client.get(`entity/${postIds}/publish`);

export const publishPreviewSeries = postIds =>
  client.get(`entity/${postIds}/publish?env=preview`);

export const unpublishSeries = postIds =>
  client.post(`entity/${postIds}/unpublish`);
