import { Column } from "react-virtualized";
import TypeRenderer from "../Renderer/TypeRenderer";

const TypeColumn = () => null;

TypeColumn.propTypes = Object.assign({}, Column.propTypes);
TypeColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: TypeRenderer,
  draggable: true
});

export default TypeColumn;
