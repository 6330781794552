import React, { useState } from "react";
import styled from "styled-components";
import Label from "../../../products/components/Label";
import ProductSearch from "../../../products/components/ProductSearch";

import PrimaryButton from "../../../shared/components/Button/Primary";
import { getProductsAvailableForClassroomGroup } from "../../store/selectors";
import Modal from "../../../shared/components/Modal/Modal";
import ProductItem from "./ProductItem";

const Search = styled(ProductSearch)`
  width: 100%;
  margin-top: 0;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 365px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const StyledErrorMessage = styled.div`
  color: #b00020;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0.25rem;
`;

const Wrapper = styled.div``;

const StyledProductsWrapper = styled.div`
  height: 200px;
  padding: 4px 0;
  ${props => (props.showScroll ? "overflow-y: scroll" : null)};
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const isIdInList = (list, id) => list.filter(l => l.id === id).length > 0;

const GroupProducts = ({ classroomGroup, addProduct, removeProduct }) => {
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const TrashButtonClick = id => {
    setIsModalOpen(true);
    setSelectedProduct(id);
  };

  const removeProductButtonClick = () => {
    removeProduct(selectedProduct);
    setIsModalOpen(false);
  };
  const addNewProductToGroup = id => {
    if (classroomGroup.products && isIdInList(classroomGroup.products, id)) {
      setError(true);
      return;
    }

    addProduct(id);
    setError(false);
  };

  const addProductToGroup = p => {
    addNewProductToGroup(p.id);
  };

  return (
    <Wrapper>
      <Label>
        Produkter
        <Search
          selector={getProductsAvailableForClassroomGroup}
          placeholder="Sök produkt"
          onSelect={addProductToGroup}
        />
        {error && (
          <StyledErrorMessage>
            Produkten finns redan med i gruppen.
          </StyledErrorMessage>
        )}
      </Label>
      <StyledProductsWrapper
        showScroll={
          classroomGroup.products && classroomGroup.products.length > 6
        }
      >
        {classroomGroup.products &&
          classroomGroup.products.map(p => (
            <ProductItem key={p.id} product={p} handleClick={TrashButtonClick} />
          ))}
      </StyledProductsWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          Vill du ta bort produkten från klassrumsgruppen?
          <StyledButton onClick={removeProductButtonClick}>
            Ta bort
          </StyledButton>
        </StyledModalContent>
      </Modal>
    </Wrapper>
  );
};

export default GroupProducts;
