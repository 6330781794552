import { fromJS, List } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  END_FETCH,
  START_FETCH,
  FETCH_ENTITIES_SUCCESS,
  FETCH_MEDIA_LIST_SUCCESS
} from "../../api";
import { actionCreator } from "../../shared";
import {
  SET_ENTITY_DRAFT,
  UPDATE_ENTITY_DRAFT,
  RESET_ENTITY_DRAFT,
  CREATE_ENTITY_DRAFT,
  UPDATE_JSON_DRAFT,
  RESET_JSON_DRAFT
} from "./actions";
import {
  ACTION_SAVE_ENTITY,
  ACTION_SAVE_ENTITY_SUCCESS,
  ACTION_DELETE_ENTITY_SUCCESS,
  ACTION_UPDATE_ORDER,
  ACTION_UPDATE_ORDER_SUCCESS,
  ACTION_SAVE_JSON_SUCCESS,
  ACTION_DELETE_MEDIA_ITEM_SUCCESS,
  ACTION_DELETE_MEDIA_SECTION_SUCCESS,
  ACTION_GET_PUBLISH_LIST_SUCCESS,
  ACTION_RESET_PUBLISH_LIST,
  ACTION_UPDATE_COLLECTION_MARKER,
  ACTION_UPDATE_COLLECTION_MARKER_SUCCESS,
  ACTION_UPDATE_COLLECTION_MARKER_FAILURE
} from "../sagas/actions";
import { splitAndBuildRouteName } from "../routes";
import { ADVENTURES_CARD_EDITOR, ADVENTURES_GROUP } from "../../constants";
import { flattenTree } from "../../structure/Helpers";

export const requiredFields = {
  list: [],
  listDraft: [],
  markerGroups: [],
  labels: [],
  args: {},
  entityDraft: {},
  draftState: "clean",
  draftValid: true,
  fetching: false,
  saving: false,
  publishing: false,
  grades: [],
  json: "",
  readyForPublish: [],
  uploading: false,
  collectionMarkerUrl: ""
};

const reducer = entityName => initialState => {
  const reducerstate = fromJS(mergeDeepRight(requiredFields, initialState));
  return createReducer(reducerstate, {
    [actionCreator(entityName, START_FETCH)](state) {
      return state.set("fetching", true);
    },
    [actionCreator(entityName, END_FETCH)](state) {
      return state.set("fetching", false);
    },
    [actionCreator(entityName, FETCH_ENTITIES_SUCCESS)](state, action) {
      if (entityName === ADVENTURES_CARD_EDITOR) {
        let newData = "";
        try {
          // Add whitespace to json to make it readable
          newData = JSON.stringify(
            JSON.parse(action.entities.data.data),
            null,
            2
          );
        } catch (e) {}

        return state
          .update("json", () => ({ ...action.entities.data, data: newData }))
          .update("jsonDraft", () => ({
            ...action.entities.data,
            data: newData
          }));
      } else {
        let collectionMarkerUrl = entityName === ADVENTURES_GROUP && action.entities.length > 0 ? action.entities[0].collection_marker_url : "";
        return state
          .update("list", () => new List(action.entities))
          .update("grades", () => new List(action.extras))
          .update("listDraft", () => new List(action.entities))
          .update("markerGroups", () => new List(flattenTree(action.markers)))
          .update("collectionMarkerUrl", () => collectionMarkerUrl)
      }
    },
    [actionCreator(entityName, FETCH_MEDIA_LIST_SUCCESS)](state, action) {
      return state.update("mediaList", () => action.entities);
    },
    [actionCreator(entityName, CREATE_ENTITY_DRAFT)](state, action) {
      return state
        .update("listDraft", list => list.push(action.entity))
        .update("entityDraft", () => action.entity)
        .update("draftState", () => "dirty");
    },
    [actionCreator(entityName, SET_ENTITY_DRAFT)](state, { entity }) {
      return state
        .update("entityDraft", () => entity)
        .update("draftState", () => "clean");
    },
    [actionCreator(entityName, UPDATE_JSON_DRAFT)](
      state,
      { entity, validation }
    ) {
      const prev = state.get("jsonDraft");
      return state
        .update("jsonDraft", () => ({ ...prev, ...entity }))
        .update("draftState", () => "dirty");
    },
    [actionCreator(entityName, UPDATE_ENTITY_DRAFT)](
      state,
      { entity, validation }
    ) {
      const prev = state.get("entityDraft");
      return state
        .update("entityDraft", () => ({ ...prev, ...entity }))
        .update("draftState", () => "dirty")
        .update("draftValid", () => validation)
        .update(
          "listDraft",
          list =>
            new List(list.map(item => (entity.id === item.id ? entity : item)))
        );
    },
    [actionCreator(entityName, RESET_JSON_DRAFT)](state) {
      return state
        .update("jsonDraft", () => state.get("json"))
        .update("draftState", () => "clean")
        .update("draftValid", () => true);
    },
    [actionCreator(entityName, RESET_ENTITY_DRAFT)](state) {
      return state
        .update("listDraft", () => state.get("list"))
        .update("entityDraft", () => {})
        .update("draftState", () => "clean")
        .update("draftValid", () => true);
    },
    [actionCreator(entityName, ACTION_DELETE_ENTITY_SUCCESS)](state, { id }) {
      return state
        .update("listDraft", list => list.filter(item => item.id !== id))
        .update("list", list => list.filter(item => item.id !== id))
        .update("entityDraft", entityDraft =>
          entityDraft ? (entityDraft.id === id ? {} : entityDraft) : {}
        );
    },
    [actionCreator(entityName, ACTION_SAVE_ENTITY)](state) {
      return state.update("saving", () => true);
    },
    [actionCreator(entityName, ACTION_SAVE_ENTITY_SUCCESS)](state, { data }) {
      return state.withMutations(st => {
        st.update("entityDraft", () => ({ ...data }));
        st.update("draftState", () => "clean");
        st.update("draftValid", () => true);
        st.update("saving", () => false);

        const list = state.get("listDraft");
        const found = list.some(item => item.id === data.id);
        const updatedList = found
          ? list.map(obj => (obj.id === data.id ? data : obj))
          : list.pop().push({ ...data });

        st.update("listDraft", () => new List(updatedList));
        st.update("list", () => new List(updatedList));
      });
    },
    [actionCreator(entityName, ACTION_DELETE_MEDIA_ITEM_SUCCESS)](
      state,
      action
    ) {
      return state.update("mediaList", () => action.data);
    },
    [actionCreator(entityName, ACTION_DELETE_MEDIA_SECTION_SUCCESS)](
      state,
      action
    ) {
      return state.update("mediaList", () => action.data);
    },
    [actionCreator(entityName, ACTION_SAVE_JSON_SUCCESS)](state, { data }) {
      const prev = state.get("json");
      const newData = JSON.stringify(JSON.parse(data.data.data), null, 2);

      return state.withMutations(st => {
        st.update("json", () => ({ ...prev, data: newData }));
        st.update("jsonDraft", () => ({ ...prev, data: newData }));
        st.update("draftState", () => "clean");
        st.update("draftValid", () => true);
      });
    },
    [actionCreator(entityName, ACTION_GET_PUBLISH_LIST_SUCCESS)](state, action) {
      return state.update("readyForPublish", () => new List(action.data));
    },
    [actionCreator(entityName, ACTION_RESET_PUBLISH_LIST)](state, action) {
      return state.update("readyForPublish", () => []);
    },
    [actionCreator(entityName, ACTION_UPDATE_ORDER)](state, { list }) {
      return state.update("listDraft", () => new List(list));
    },
    [actionCreator(entityName, ACTION_UPDATE_ORDER_SUCCESS)](state, { list }) {
      return state.withMutations(st => {
        st.update("list", () => new List(list));
        st.update("listDraft", () => new List(list));
      });
    },
    [actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER)] (state) {
      return state.withMutations(st => {
        st.update("uploading", () => true);
      });
    },
    [actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER_SUCCESS)] (state, { data }) {
      return state.withMutations(st => {
        st.update("collectionMarkerUrl",() => data.collection_marker_url);
        st.update("uploading", () => false);
        st.update("list", () => new List(data.updated_nodes));
        st.update("listDraft", () => new List(data.updated_nodes));
      });
    },
    [actionCreator(entityName, ACTION_UPDATE_COLLECTION_MARKER_FAILURE)] (state) {
      return state.withMutations(st => {
        st.update("uploading", () => false);
      });
    },
    [splitAndBuildRouteName(entityName)](state) {
      return state
        .update("entityDraft", () => {})
        .update("draftState", () => "clean")
        .update("draftValid", () => true);
    }
  });
};

export default reducer;
