import ButtonGroup from "../../../shared/components/ButtonGroup";
import { StandardButton, PrimaryButton } from "../../../shared/components/Button";
import { modalOpen, modalClose } from "../../../shared";

export const deletePostQuestionModal = dispatch => ({ onClickOK })=> {

  const onOK = () => {
    modalClose(dispatch)
    onClickOK()
  };

  const onCloseModal = () => {
    modalClose(dispatch)
  }
  
  const header = "Ta bort post";
  const children = (
    <div>
      Vill du verkligen ta bort posten?
    </div>
  );
  const actions = (
    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
      <ButtonGroup>
        <StandardButton onClick={onCloseModal}>
          Avbryt
        </StandardButton>
        <PrimaryButton
          onClick={onOK}
        >
          Ta bort
        </PrimaryButton>
      </ButtonGroup>
    </div>
  );
  modalOpen(dispatch)({
    children,
    header,
    actions,
    closeModal: onCloseModal
  });
}
