import { Item as MenuItem } from "../Menu";
import React from "react";

export const Item = ({ value, onClick, ...rest }) => {
  /**
   * Handles on click event
   * @returns {*}
   * @private
   */
  const _onClick = () => onClick(value);

  return <MenuItem {...rest} onClick={_onClick} />;
};
