import React from "react";

import ChoiceGroupStateless from "./ChoiceGroupStateless";

/**
 * Toggles the clicked item in the current selection
 * Allows multiple items to be selected
 * @param {array} prevSelection
 * @param {*} itemId, the id of the clicked item
 * @return {array} the new selection
 */
const toggleMultiSelection = (prevSelection, itemId) =>
  prevSelection.indexOf(itemId) > -1
    ? prevSelection.filter(item => item !== itemId)
    : [...prevSelection, itemId];

/**
 * Toggles the clicked item in the current selection
 * Only one item can be selected
 * @param {array} prevSelection
 * @param {*} itemId, the id of the clicked item
 * @return {array} the new selection
 */
const toggleSingleSelection = (prevSelection, itemId) =>
  prevSelection.indexOf(itemId) > -1 ? [] : [itemId];

class ChoiceGroupContainer extends React.PureComponent {
  static defaultProps = {
    onChange: () => {},
    selected: []
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  /**
   * Called when an item is selected
   * @param {*} value the id of the selected item
   */
  select = value => {
    const { multi, selected, onChange } = this.props;
    const toggle = multi ? toggleMultiSelection : toggleSingleSelection;
    onChange(toggle(selected, value));
  };

  /**
   * Toggle the expanded/collapsed state
   */
  toggle = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { multi, title, collapsible, choices, selected } = this.props;
    const { expanded } = this.state;
    return (
      <ChoiceGroupStateless
        multi={multi}
        collapsible={collapsible}
        title={title}
        choices={choices}
        selected={selected}
        expanded={expanded}
        onSelect={this.select}
        onToggle={this.toggle}
      />
    );
  }
}

export default ChoiceGroupContainer;
