import styled from "styled-components";
import { neutralSecondaryAlt } from "../../../../styles/shared/colors";

export const StyledDragActive = styled.div`
  display: flex;
`;

export const StyledDragActiveH5 = styled.h5`
  margin: 0 10px;
  color: ${neutralSecondaryAlt};
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  justifycontent: flex-end;
`;

export const StyledMediaList = styled.ul`
  display: flex;
  flex: 1;
  padding: 0;
  justifycontent: flex-end;
`;

export const StyledMediaListItem = styled.li`
  display: block;
`;

export const StyledMediaListItemImage = styled.img`
  max-width: 200px;
`;
