export const COLLAPSE_NAVIGATION = "COLLAPSE_NAVIGATION";
export const OPEN_NAVIGATION = "OPEN_NAVIGATION";
export const SET_TITLE = "SET_TITLE";
export const SET_SECTIONS = "SET_SECTIONS";
export const SET_SELECTED_PRODUCTID = "SET_SELECTED_PRODUCTID";
export const SET_IF_PRODUCT_SELECTED_MESSAGE =
  "SET_IF_PRODUCT_SELECTED_MESSAGE";

export const collapse = mouseOver => ({
  type: COLLAPSE_NAVIGATION,
  status: mouseOver
});
export const open = mouseOver => ({ type: OPEN_NAVIGATION, status: mouseOver });
export const setTitle = title => ({ type: SET_TITLE, title });
export const setSections = sections => ({ type: SET_SECTIONS, sections });
export const setSelectedProductId = id => ({
  type: SET_SELECTED_PRODUCTID,
  id
});
export const setIfProductSelectedMessageVisible = bool => ({
  type: SET_IF_PRODUCT_SELECTED_MESSAGE,
  bool
});
export const gotoRoute = (type, payload) => {
  return { type, payload };
};
