import React from "react";
import Tag from "../Tag";

import { StyledSearchBoxWrapper, StyledDataList, StyledInput } from "./styles";

const SearchDataList = ({
  selectedTags,
  id,
  dataList,
  onChange,
  onBlur,
  onKeyUp,
  deleteCallback
}) => {
  const renderSelectedTags = () => {
    const selected = dataList.filter(d => selectedTags.indexOf(d.id) >= 0);
    return selected.map((t, i) => (
      <Tag
        variant="info"
        label={t.title}
        isDeletable={true}
        labelColor={t.color || "#83bef9"}
        key={`tag_${i}`}
        deleteCallback={deleteCallback}
      />
    ));
  };

  const renderDataList = data => (
    <StyledDataList id={`${id}_tag_list`}>
      {data.map((tag, i) => (
        <option key={i} value={tag.title} />
      ))}
    </StyledDataList>
  );

  const renderInput = () => (
    <StyledInput
      id={id}
      placeholder={"Sök..."}
      list={`${id}_tag_list`}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
    />
  );

  return (
    <StyledSearchBoxWrapper>
      <div>{renderSelectedTags()}</div>
      {renderInput()}
      {renderDataList(dataList)}
    </StyledSearchBoxWrapper>
  );
};

SearchDataList.defaultProps = {
  onClick() {},
  onChange() {},
  onBlur() {}
};

export default SearchDataList;
