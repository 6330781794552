import styled from "styled-components";

export const StyledLayoutSelector = styled.article`
  background-color: #eaeaea;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const StyledLabel = styled.div`
  font-size: 16px;
`;
