import { Column } from "react-virtualized";
import LinkRenderer from "../Renderer/Link";

const LinkColumn = () => null;

LinkColumn.propTypes = { ...Column.propTypes };
LinkColumn.defaultProps = {
  ...Column.defaultProps,
  ...{
    cellRenderer: LinkRenderer,
    draggable: true
  }
};

export default LinkColumn;
