import React from "react";
import {
  LogoutBtn,
  StyledAvatar,
  StyledProfilePic,
  StyledUserName,
  StyledUserNavHandlerContainer
} from "./StyledUserNavHandler";
import { LogOut } from "react-feather";
import { connect } from "react-redux";
import { doLogout } from "../../../users/api/actions";

const UserNavHandler = ({ user, doLogout }) => {
  /**
   * Renders profile picture with initials as fallback
   * @param user
   * @returns {string}
   */
  const renderPortrait = user =>
    user.profile_pic ? (
      <StyledProfilePic src={user.profile_pic} alt="" />
    ) : (
      getInitials(user)
    );

  /**
   * Extract initials from a name
   * @returns {string}
   */
  const getInitials = ({ firstName, lastName }) =>
    firstName.charAt(0) + lastName.charAt(0);

  /**
   * Returns full name as a string
   * @param firstName
   * @param lastName
   * @returns {string}
   */
  const getName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

  return user ? (
    <StyledUserNavHandlerContainer>
      <LogoutBtn>
        <LogOut size={20} onClick={doLogout} />
      </LogoutBtn>

      <StyledAvatar>{renderPortrait(user)}</StyledAvatar>

      <StyledUserName>{getName(user)}</StyledUserName>
    </StyledUserNavHandlerContainer>
  ) : null;
};

/**
 * Fetches author from state
 * @param state
 * @param id
 * @returns {*}
 */
const getAuthor = (state, id) => state.authors.get(id);

const mapStateToProps = state => ({
  user: getAuthor(state, state.viewer.get("author"))
});

const mapDispatchToProps = dispatch => ({
  doLogout: () => dispatch(doLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNavHandler);
