import * as React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { GripHandle } from "../../../shared/components/StudliIcons";
import {
  StyledItem,
  StyledItemContainer,
  StyledValueContentWrapper,
  StyledQuestionContentWrapper,
  StyledColumnWrapper,
  StyledDragHandleWrapper,
  StyledButton,
  StyledItemEditText,
  StyledQuestionEditInputWrapper,
  StyledItemBoxLabel,
  StyledButtonRowWrapper,
  StyledUtilityButtonsContainer,
  StyledCheckBoxWrapper,
  StyledRowOption,
  StyledRowOptionsWrapper,
  StyledOrderTextButtonWrapper
} from "./StyledOrder";
import { ITEMS } from "../../../constants";
import {
  renderMedia,
  getMedia,
  renderEditMedia,
  renderMediaButtons
} from "../../../shared/MediaHandler/MediaHandler";
import Checkbox from "../../../shared/components/Checkbox";
import Text from "../../../shared/components/Text/Text";
import { StyledTextToolbarButton } from "../MultipleChoice/StyledMultipleChoice";

export const OrderItem = SortableElement(({ data, ...rest }) => {
  const {
    indx: index,
    onRemoveFn,
    resources,
    editBtnFn,
    onMediaChange,
    onMediaResourceChange,
    onDuplicateItem,
    onRemoveImage,
    storeDraft,
    draftTarget,
    callbackUpdatedItem,
    updatedItem
  } = rest;

  const { label, inOrderBox, inUse } = data;
  const [showTextToolbar, setTextToolbar] = React.useState(false);

  /**
   * Remove an item
   */
  const removeItem = () => onRemoveFn(index);

  /**
   * Duplicate an item
   */
  const duplicateItem = () => onDuplicateItem(index);

  const setInUse = () => editBtnFn(index, { inUse: !inUse });

  const setInOrderBox = () => editBtnFn(index, { inOrderBox: !inOrderBox });

  /**
   * Edit question label text
   */
  const onEditChange = (keyType, text, target) => editBtnFn(index, { label: text });

  /**
   * Show/hide text area toolbar
   */
  const toggleTextOptions = () => setTextToolbar(!showTextToolbar);

  /**
   * render the value box & media part if media exists.
   * @param media
   */
  const renderValueBox = media => (
    <StyledItemEditText hasMedia={media.mediaComp}>
      {media.mediaComp
        ? renderEditMedia(
            media,
            index,
            true,
            ITEMS,
            onMediaChange,
            onMediaResourceChange
          )
        : null}
      {renderButtonRow()}
    </StyledItemEditText>
  );

  /**
   * Renders checkbox
   */
  const renderCheckBox = (checked, callback) => (
    <StyledCheckBoxWrapper>
      <Checkbox isChecked={checked} onClick={callback} />
    </StyledCheckBoxWrapper>
  );

  const renderRowOption = (option, checked, callback) => (
    <StyledRowOption>
      <StyledItemBoxLabel>{option}</StyledItemBoxLabel>
      {renderCheckBox(checked, callback)}
    </StyledRowOption>
  );

  /**
   * Renders the row which contains the item options.
   * @param {*} dropDownItems
   */
  const renderButtonRow = () => (
    <StyledButtonRowWrapper>

      {label !== undefined && <StyledOrderTextButtonWrapper>
        <StyledTextToolbarButton isActive={showTextToolbar} studlicon={"TextTool"} onClick={toggleTextOptions}></StyledTextToolbarButton>
      </StyledOrderTextButtonWrapper>}

      <StyledRowOptionsWrapper>
        {renderRowOption("Förifylld", inOrderBox, setInOrderBox)}
        {renderRowOption("Utan plats", !inUse, setInUse)}
      </StyledRowOptionsWrapper>
      {label !== undefined
        ? renderMediaButtons(index, ITEMS, onMediaChange, onMediaResourceChange)
        : null}
    </StyledButtonRowWrapper>
  );

  /**
   * Renders duplicate & remove item
   */
  const renderUtilityButtons = () => (
    <StyledUtilityButtonsContainer>
      <StyledColumnWrapper>
        <StyledButton icon={"Copy"} onClick={duplicateItem}></StyledButton>
      </StyledColumnWrapper>
      <StyledColumnWrapper>
        <StyledButton onClick={removeItem} studlicon={"Trash"}></StyledButton>
      </StyledColumnWrapper>
    </StyledUtilityButtonsContainer>
  );

  /**
   * render the edit text
   */
  const renderQuestionBox = () =>
    label !== undefined ? (
      <StyledItemEditText>
        <StyledQuestionEditInputWrapper>
          <Text
            data={{ text: data.label }}
            settings={{ useShortToolbar: true, useToolbar: showTextToolbar, multiLine: false }}
            storeDraft={storeDraft}
            draftTarget={draftTarget}
            updateData={onEditChange}
            updatedItem={updatedItem}
            callbackUpdatedItem={callbackUpdatedItem}
          />
        </StyledQuestionEditInputWrapper>
      </StyledItemEditText>
    ) : null;

  return (
    <StyledItem>
      <StyledDragHandleWrapper>
        <DragHandle />
      </StyledDragHandleWrapper>
      <StyledQuestionContentWrapper>
        {renderMedia(getMedia(data, resources), onRemoveImage(index))}
        {renderQuestionBox()}
      </StyledQuestionContentWrapper>
      <StyledValueContentWrapper>
        {renderValueBox(getMedia(data, resources))}
      </StyledValueContentWrapper>
      {renderUtilityButtons()}
    </StyledItem>
  );
});

export const ItemContainer = SortableContainer(({ children }) => {
  return <StyledItemContainer>{children}</StyledItemContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);
