import styled from "styled-components";
import { AddMediaButton } from "../AddMediaButton/AddMediaButton";

const margin = 5;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 -${margin}px;
`;

export const StyledAddMediaButton = styled(AddMediaButton)`
  margin: 0 ${margin}px;
`;
