import * as React from "react";

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { GripHandle } from "../../../shared/components/StudliIcons";
import {
  StyledSlide,
  StyledDragHandleWrapper,
  StyledSlideContentWrapper,
  StyledColumnWrapper,
  StyledButton,
  StyledSlidesContainer,
  StyledPrimaryButton,
  StyledImageWrapper,
  StyledImageContainer,
  StyledMediaTitle,
  StyledAddInputWrapper,
  StyledImageAndButtonWrapper
} from "./StyledImageSlider";

import AidaText from "../../../shared/components/Text/AidaText";

export const Slide = SortableElement(({ value: data, ...rest }) => {
  const {
    indx,
    storeDraft,
    draftTarget,
    updatedItem,
    editFn,
    onRemoveFn,
    onChangeImageFn,
    callbackUpdatedItem
  } = rest;

  const removeItem = () => onRemoveFn(indx);

  const changeFn = () => onChangeImageFn(indx);

  const onEditChange = (keyType, text, target) => editFn(indx, { text: text });

  return (
    <StyledSlide>
      <StyledDragHandleWrapper>
        <DragHandle />
      </StyledDragHandleWrapper>
      <StyledSlideContentWrapper>
        <SelectedImage data={data} changeFn={changeFn} />
      </StyledSlideContentWrapper>
      <StyledSlideContentWrapper>
        {data.text !== undefined && (
          <div>
            <StyledAddInputWrapper>
              <AidaText
                data={data}
                settings={{
                  useShortToolbar: true,
                  useToolbar: true,
                  multiLine: true
                }}
                storeDraft={storeDraft}
                draftTarget={draftTarget}
                updateData={onEditChange}
                updatedItem={updatedItem}
                callbackUpdatedItem={callbackUpdatedItem}
              />
            </StyledAddInputWrapper>
          </div>
        )}
      </StyledSlideContentWrapper>
      <StyledColumnWrapper>
        <StyledButton onClick={removeItem} studlicon="Trash"></StyledButton>
      </StyledColumnWrapper>
    </StyledSlide>
  );
});

export const SlidesContainer = SortableContainer(({ children }) => {
  return <StyledSlidesContainer>{children}</StyledSlidesContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);

const SelectedImage = ({ data, changeFn }) => {
  const imageSrc = data.image && data.image.src;
  const filename = imageSrc ? data.image.src.replace(/^.*[\\/]/, "") : null;

  return !!imageSrc ? (
    <StyledImageWrapper>
      <StyledImageAndButtonWrapper>
        <StyledImageContainer>
          <img src={imageSrc} alt="alttext" />
        </StyledImageContainer>
        <StyledPrimaryButton
          studlicon="Media"
          onClick={changeFn}
        ></StyledPrimaryButton>
      </StyledImageAndButtonWrapper>
      <StyledMediaTitle>Titel: {filename}</StyledMediaTitle>
    </StyledImageWrapper>
  ) : null;
};
