import * as React from "react";
import { StyledAddMediaButton } from "./StyledAddMediaButton";

export const AddMediaButton = ({ className, bgColor, callback, children }) => {
  /**
   * Handles click callback
   * @returns {null}
   */
  const handleClick = () => (callback ? callback() : null);

  return (
    <StyledAddMediaButton
      className={className}
      bgColor={bgColor}
      onClick={handleClick}
    >
      {children}
    </StyledAddMediaButton>
  );
};
