import { ROUTE_MEDIA, ROUTE_AIDA_MEDIA, ROUTE_OMNIA_MEDIA } from "../../media";
import { ROUTE_MEDIARESOURCES } from "../../mediaresource";
import {
  ROUTE_EXERCISES,
  ROUTE_RESOURCES,
  ROUTE_HELP_RESOURCES,
  ROUTE_AIDA_LIST
} from "../../posts";
import { ROUTE_PRODUCT } from "../../products";
import { ROUTE_ADVENTURES_GROUP } from "../../story-adventures";
import { ROUTE_STRUCTURE } from "../../structure";
import { ROUTE_OMNIA } from "../../systems";
import {
  ROUTE_PRODUCT_NEWS,
  ROUTE_OP_MESSAGES,
  ROUTE_MESSAGES,
  ROUTE_MESSAGES_MEDIA,
  ROUTE_MARKETMESSAGES
} from "../../messages";
import { ROUTE_CLASSROOM, ROUTE_CLASSROOM_MEDIA } from "../../classroom";
import { ROUTE_SERIES, ROUTE_SERIES_MEDIA } from "../../series/routes";

const mainRoutes = [
  ROUTE_PRODUCT,
  ROUTE_CLASSROOM,
  ROUTE_OMNIA,
  ROUTE_MESSAGES,
  ROUTE_AIDA_LIST,
  ROUTE_SERIES
];

const mainSubs = {
  ROUTE_MESSAGES: [
    ROUTE_PRODUCT_NEWS,
    ROUTE_OP_MESSAGES,
    ROUTE_MARKETMESSAGES,
    ROUTE_MESSAGES_MEDIA
  ],
  ROUTE_AIDA_LIST: [ROUTE_AIDA_MEDIA],
  ROUTE_SERIES: [ROUTE_SERIES_MEDIA],
  ROUTE_CLASSROOM: [ROUTE_CLASSROOM_MEDIA],
  ROUTE_OMNIA: [ROUTE_OMNIA_MEDIA]
};

export const mainNavigation = {
  main: {
    routes: mainRoutes,
    subs: mainSubs
  }
};

export const adaptNavigation = {
  main: {
    routes: mainRoutes,
    subs: {
      ...mainSubs,
      ...{
        ROUTE_PRODUCT: [
          ROUTE_EXERCISES,
          ROUTE_RESOURCES,
          ROUTE_HELP_RESOURCES,
          ROUTE_MEDIARESOURCES,
          ROUTE_STRUCTURE,
          ROUTE_MEDIA,
          ROUTE_ADVENTURES_GROUP
        ]
      }
    }
  }
};
