export const APPLICATION_MOUNT = "APPLICATION_MOUNT";
export const APPLICATION_READY = "APPLICATION_READY";
export const AUTH_READY = "AUTH_READY";
export const AUTH_INIT = "AUTH_INIT";

/**
 * Creates an action for an entity
 * @param entityName
 * @param action
 * @returns {string}
 */
export const actionCreator = (entityName, action) =>
  `${entityName.toUpperCase()}/${action}`;

export const applicationMount = dispatchInitialRoute => ({
  type: APPLICATION_MOUNT,
  dispatchInitialRoute
});

export const applicationReady = () => ({
  type: APPLICATION_READY
});

export const authInit = () => ({
  type: AUTH_INIT
});

export const authReady = () => ({
  type: AUTH_READY
});
