import React from "react";
import styled, { css } from "styled-components";
import { Checkmark, XIcon, Exclamationmark } from "../../../shared/components/StudliIcons";
import { format } from "../../../date";

const StyledTitle = styled.div`
  font-size: 18px;
  padding-top: 7px;
  font-weight: 500;
`

const StyledTime = styled.div`
  font-size: 14px;
  padding-top: 5px;
  width: 80px;
  text-align: center;
`

const Published = css`
  background-color: #0078d7;
  fill: #ffffff;
  color: #ffffff;
  border: 1px solid #0078d7;
  box-shadow: 0 0 1px 1px rgba(0, 120, 215, .8);
`

const Unpublished = css`
  background-color: #fff;
  fill: #0078d7;
  color: #0078d7;
  border: 1px solid #0078d7;
  box-shadow: 0 0 1px 1px rgba(0, 120, 215, .8);
`
const Empty = css`
  background-color: #d9d9d9;
  fill: #6a6a6a;
  color: #6a6a6a;
  border: 1px solid #6a6a6a;
  box-shadow: 0 0 1px 1px rgba(160, 160, 160, .8);
`
const StyledPublishItem = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  ${props => getSkin(props)}
`
const StyledCircleItem = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  width: 70px;
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  z-index: 2;
`

const getSkin = props => {
  switch (props.status) {
    case "published": 
      return `${Published}`
    case "unpublished":
      return `${Unpublished}`;
    default: 
      return `${Empty}`;
  }
}

const getIcon = status => {
  switch (status) {
    case "nostatus":
      return null
    case "published": 
      return <Checkmark size="14"/>
    case "unpublished":
      return <Exclamationmark size="14"/>
    default: 
      return <XIcon size="14"/>
  }
}

const getTitle = type => {
  switch (type) {
    case "campus": 
      return "Nya filer"
    case "preview":
      return "Publicerad till Test"
    case "prod": 
      return "Publicerad live"
    default: 
      return null
  }
}

const formatTime = dateString => {
  const date = new Date(dateString)
  return format(date);
}

const PublishItem = ({ type, status }) => {
  return (
    <StyledItem>
      <StyledCircleItem>
        <StyledPublishItem status={status.pubstate}>
          { getIcon(status.pubstate) }
        </StyledPublishItem>
      </StyledCircleItem>
      <StyledTitle>{getTitle(type)}</StyledTitle>
      <StyledTime>{status.date != null && formatTime(status.date)}</StyledTime>
    </StyledItem>
  )
}

export default PublishItem;