let reducers = {};

export const registerReducer = (key, reducer) => {
  reducers = Object.assign({}, reducers, {
    [key]: reducer
  });
};

const lazyReducer = () => reducers;

export default lazyReducer;
