import React from "react";
import styled from "styled-components";

import MenuList from "./List";
export { default as Item } from "./Item";

const Menu = ({ children, className, style }) => (
  <div className={className} style={style}>
    <MenuList>{children}</MenuList>
  </div>
);

export default styled(Menu)`
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  min-width: 220px;
  max-width: 368px;
  margin: 0;
  margin-top: 4px;
  padding: 0;
  border: 0;
  transform-origin: top left;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;
  border: solid 1px ${props => props.theme.neutralLight};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  transition: visibility 0ms linear 200ms, opacity 200ms linear;

  &:focus {
    outline: none;
  }

  ${props =>
    props.active
      ? `
      opacity: 1;
      visibility: visible;
      transition-delay: 0ms;
    `
      : null};
`;
