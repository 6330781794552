import React from "react";
import { RadioButton } from "../../../../shared/components/RadioButton/RadioButton";

const customStyles = {
  minWidth: "40px",
  padding: "6px 10px",
  cursor: "pointer",
  backgroundColor: "#313131",
  color: "#FFFFFF",
  width: "100%",
  containerWidth: "100%"
};

const SidebarButton = ({
  isActive,
  callback,
  content,
  overrideStyles = {}
}) => (
  <RadioButton
    isActive={isActive}
    callback={callback}
    content={content}
    overrideStyles={{ ...customStyles, ...overrideStyles }}
  />
);

export default SidebarButton;
