import { connect } from "react-redux";
import React from "react";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Modal/Card";
import ButtonGroup from "../../components/ButtonGroup";
import { PrimaryButton, StandardButton } from "../../components/Button";
import {
  StyledModalActions,
  StyledModalContainer
} from "./StyledModalContainer";

const ModalContainer = ({
  children,
  isModalOpen,
  header,
  actions,
  closeModal
}) => {
  const renderModalActions = () => (
    <StyledModalActions>
      <ButtonGroup>
        <PrimaryButton>Ok</PrimaryButton>
        <StandardButton>Cancel</StandardButton>
      </ButtonGroup>
    </StyledModalActions>
  );

  return (
    <StyledModalContainer isOpen={false}>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <Card
          header={header || "Test modal"}
          actions={actions || renderModalActions()}
          modalClose={closeModal}
        >
          {children}
        </Card>
      </Modal>
    </StyledModalContainer>
  );
};

const mapStateToProps = state => {
  return {
    isModalOpen: state.modal.get("isModalOpen"),
    header: state.modal.get("header"),
    children: state.modal.get("children"),
    actions: state.modal.get("actions"),
    closeModal: state.modal.get("closeModal")
  };
};

export default connect(mapStateToProps, null)(ModalContainer);
