import styled from "styled-components";

export const StyledAddMediaButton = styled.button`
  width: 60px;
  height: 30px;
  padding: 0;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#0C224E")};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px rgba(55, 122, 211, 0.5);
  }

  &:hover {
    filter: brightness(110%);
  }

  svg {
    max-height: 100%;
    max-width: 100%;
  }
`;
