import React from "react";
import styled from "styled-components";
import Link from "../../../../shared/components/Link";

const StyledLink = styled.span`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  pointer-events: none;
`;

const LinkRenderer = ({ cellData, rowData: { id } }) => {
  const path = `/series/detail/${id}`;
  return (
    <StyledLink>
      <Link to={path}>{cellData}</Link>
    </StyledLink>
  );
};

export default LinkRenderer;
