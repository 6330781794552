import React from "react";
import { useSelector } from "react-redux";
import { Grid, Section } from "../../../products/components/Grid";
import ClassroomGroupCard from "../../containers/ClassroomGroupCard/ClassroomGroupCard";
import FormativeTestsCard from "../../containers/FormativeTestsCard/FormativeTestsCard";
import PreparedAssignmentsCard from "../../containers/PreparedAssignmentsCard/PreparedAssignmentsCard";
import { selectClassroomGroup } from "../../store/selectors";
import StickersCard from "../../containers/StickersCard";

const ClassroomDetails = () => {
  const classroomgroup = useSelector(selectClassroomGroup);
  return (
    <React.Fragment>
      <Grid>
        <Section large>
          <ClassroomGroupCard />
          {classroomgroup && classroomgroup.typeId === 3 && (
            <FormativeTestsCard />
          )}
          {classroomgroup && classroomgroup.typeId === 2 && <StickersCard />}
        </Section>
        <Section medium>
          <PreparedAssignmentsCard />
        </Section>
      </Grid>
    </React.Fragment>
  );
};

export default ClassroomDetails;
