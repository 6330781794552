import React from "react";
import {
  StyledInputWrapper,
  StyledGroup,
  SectionTitle,
  StyledField
} from "./StyledMessageEditor";


const OpMessageSettings = ({
  meta,
  onMetaDataChange,
}) => {

  return (
    <StyledInputWrapper>
      <StyledGroup>
        <SectionTitle>Knapp: </SectionTitle>
        <StyledField
          value={meta.actionCaption}
          onChange={onMetaDataChange}
          disabled={false}
          label="Text"
          name="actionCaption"
        />
        <StyledField
          value={meta.actionURL}
          onChange={onMetaDataChange}
          disabled={false}
          label="URL"
          name="actionURL"
        />
      </StyledGroup>
    </StyledInputWrapper>
  );
};

export default OpMessageSettings;
