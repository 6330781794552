import React from "react";

const JumpEnd = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-jumpend"
  >
    <g>
      <path
        fill="currentColor"
        d="M7.55,15A1.48,1.48,0,0,0,9,13.5v-5A1.48,1.48,0,0,0,7.55,7H1.45A1.48,1.48,0,0,0,0,8.5v5A1.48,1.48,0,0,0,1.45,15Zm10,4A1.48,1.48,0,0,0,19,17.5v-5A1.48,1.48,0,0,0,17.55,11h-6.1A1.48,1.48,0,0,0,10,12.5v5A1.48,1.48,0,0,0,11.45,19ZM30,18.5A1.48,1.48,0,0,0,28.55,17h-6.1A1.48,1.48,0,0,0,21,18.5v5A1.48,1.48,0,0,0,22.45,25h6.1A1.48,1.48,0,0,0,30,23.5Zm11,8A1.48,1.48,0,0,0,39.55,25h-6.1A1.48,1.48,0,0,0,32,26.5v5A1.48,1.48,0,0,0,33.45,33h6.1A1.48,1.48,0,0,0,41,31.5Zm9,9A1.48,1.48,0,0,0,48.55,34h-6.1A1.48,1.48,0,0,0,41,35.5v5A1.48,1.48,0,0,0,42.45,42h6.1A1.48,1.48,0,0,0,50,40.5Z"
        transform="translate(0 -7)"
      />
    </g>
  </svg>
);

export default JumpEnd;
