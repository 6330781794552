import React from "react"
import { ADVENTURES } from "../../../constants";
import List from "../../components/List";

const Adventures = () => {
  return (
    <List
      type={ADVENTURES}
      draggable={true}
      title={'Äventyr'} />
  )
}

export default Adventures