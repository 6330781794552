import React from "react";
import ImageEditorContainer from "../containers/ImageEditor";

const Media = () => (
  <React.Fragment>
    <ImageEditorContainer />
  </React.Fragment>
);

export default Media;
