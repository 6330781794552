import Exercsies from "./views/Exercsies";
import ExercsieDetails from "./views/ExercsieDetails";
import Resources from "./views/Resources";
import ResourceDetails from "./views/ResourceDetails";
import HelpResources from "./views/HelpResources";
import HelpResourceDetails from "./views/HelpResources/HelpResourceDetails";
import AidaList from "./views/AidaList";
import AidaDetails from "./views/AidaDetails";
import { getEditorSettingsObj } from "./containers/PostEditor/PostEditor";
import {
  ENTITIES,
  HELP,
  POST,
  EXERCISE,
  RESOURCE,
  HELP_RESOURCE,
  AIDA
} from "../constants";
import {
  standardComponents,
  mediaResourceComponent,
  resourceComponent,
  helpComponents,
  helpResourceComponent,
  nonOperativeComponents,
  aidaComponents
} from "../plugins";

export const ROUTE_EXERCISES = "ROUTE_EXERCISES";
export const ROUTE_DETAILED_EXERCISE = "ROUTE_DETAILED_EXERCISE";
export const ROUTE_TEMPLATES = "ROUTE_TEMPLATES";
export const ROUTE_DETAILED_TEMPLATE = "ROUTE_DETAILED_TEMPLATE";
export const ROUTE_RESOURCES = "ROUTE_RESOURCES";
export const ROUTE_DETAILED_RESOURCE = "ROUTE_DETAILED_RESOURCE";
export const ROUTE_HELP_RESOURCES = "ROUTE_HELP_RESOURCES";
export const ROUTE_DETAILED_HELP_RESOURCE = "ROUTE_DETAILED_HELP_RESOURCE";
export const ROUTE_AIDA_LIST = "ROUTE_AIDA_LIST";
export const ROUTE_DETAILED_AIDA = "ROUTE_DETAILED_AIDA";

export const routes = {
  [ROUTE_EXERCISES]: {
    name: ENTITIES[EXERCISE],
    path: "/products/:entityId/exercises",
    query: {},
    filters: ["SEARCH", "LABELS", "TAGS", "CHIPS", "DIFFICULTY", "COMPONENT", "STATUS"],
    text: "Övningar",
    icon: "Posts",
    noServiceBar: true,
    childRoutes: [ROUTE_DETAILED_EXERCISE],
    type: "product"
  },
  [ROUTE_DETAILED_EXERCISE]: {
    name: ENTITIES[EXERCISE],
    path: "/products/:entityId/exercises/:id",
    query: {},
    hideFilters: true,
    filters: ["SEARCH", "LABELS", "TAGS"],
    editorSettings: {
      requireOperative: false, // Set to false to temporary fix opreative bug (SKL-2911)
      editors: [
        getEditorSettingsObj(
          {
            ...standardComponents,
            ...resourceComponent,
            ...mediaResourceComponent
          },
          POST,
          "Lägg till komponent"
        ),
        getEditorSettingsObj(
          {
            ...helpComponents,
            ...helpResourceComponent
          },
          HELP,
          "Lägg till hjälp"
        )
      ]
    },
    type: "product"
  },
  [ROUTE_TEMPLATES]: {
    name: "Template",
    path: "/products/:entityId/templates",
    query: {},
    filters: ["SEARCH", "LABELS", "TAGS"],
    hideFilters: true,
    noServiceBar: true,
    text: "Mallar",
    icon: "Template",
    childRoutes: [ROUTE_DETAILED_TEMPLATE],
    type: "product"
  },
  [ROUTE_DETAILED_TEMPLATE]: {
    name: "Template",
    path: "/products/:entityId/templates/:id",
    query: {},
    hideFilters: true,
    type: "product"
  },
  [ROUTE_RESOURCES]: {
    name: ENTITIES[RESOURCE],
    path: "/products/:entityId/resources",
    query: {},
    filters: ["SEARCH", "COMPONENT", "LABELS", "TAGS"],
    hideFilters: false,
    noServiceBar: true,
    text: "Resurser",
    icon: "Resources",
    childRoutes: [ROUTE_DETAILED_RESOURCE],
    type: "product"
  },
  [ROUTE_DETAILED_RESOURCE]: {
    name: ENTITIES[RESOURCE],
    path: "/products/:entityId/resources/:id",
    query: {},
    noServiceBar: false,
    hideFilters: true,
    filters: ["SEARCH", "LABELS", "TAGS"],
    editorSettings: {
      requireOperative: false,
      editors: [
        getEditorSettingsObj(
          {
            ...nonOperativeComponents,
            ...mediaResourceComponent
          },
          POST,
          "Lägg till komponent"
        )
      ]
    },
    type: "product"
  },
  [ROUTE_HELP_RESOURCES]: {
    name: ENTITIES[HELP_RESOURCE],
    path: "/products/:entityId/helpResources",
    query: {},
    filters: ["SEARCH", "LABELS", "COMPONENT", "TAGS"],
    hideFilters: false,
    noServiceBar: true,
    text: "Hjälpresurser",
    icon: "HelpResource",
    childRoutes: [ROUTE_DETAILED_HELP_RESOURCE],
    type: "product"
  },
  [ROUTE_DETAILED_HELP_RESOURCE]: {
    name: ENTITIES[HELP_RESOURCE],
    path: "/products/:entityId/helpResources/:id",
    query: {},
    noServiceBar: false,
    hideFilters: true,
    filters: ["SEARCH", "LABELS", "TAGS"],
    editorSettings: {
      requireOperative: false,
      editors: [
        getEditorSettingsObj(
          {
            ...helpComponents
          },
          POST,
          "Lägg till hjälp"
        )
      ]
    },
    type: "product"
  },
  [ROUTE_AIDA_LIST]: {
    name: ENTITIES[AIDA],
    path: "/system/:entityId/posts",
    query: {},
    filters: ["SEARCH","CHIPS","COMPONENT","STATUS", "NOSECTION"],
    hideFilters: false,
    noServiceBar: true,
    text: "Hjälpverktyg",
    icon: "Aida",
    childRoutes: [ROUTE_DETAILED_AIDA],
    type: "system"
  },
  [ROUTE_DETAILED_AIDA]: {
    name: ENTITIES[AIDA],
    path: "/system/:entityId/posts/:id",
    query: {},
    noServiceBar: false,
    hideFilters: true,
    filters: ["TAGS"],
    editorSettings: {
      requireOperative: false,
      editors: [
        getEditorSettingsObj(
          {
            ...aidaComponents,
          },
          POST,
          "Lägg till komponent"
        )
      ]
    },
    type: "system"
  }
};

export const views = {
  [ROUTE_EXERCISES]: Exercsies,
  [ROUTE_DETAILED_EXERCISE]: ExercsieDetails,
  [ROUTE_RESOURCES]: Resources,
  [ROUTE_DETAILED_RESOURCE]: ResourceDetails,
  [ROUTE_HELP_RESOURCES]: HelpResources,
  [ROUTE_DETAILED_HELP_RESOURCE]: HelpResourceDetails,
  [ROUTE_AIDA_LIST]: AidaList,
  [ROUTE_DETAILED_AIDA]: AidaDetails
};
