import React from "react";
import classNames from "classnames";
import { modalClose } from "../../../store/modal/actions";
import Dropzone from "react-dropzone";
import ButtonGroup from "../../ButtonGroup/index";
import { uploadMedia } from "../../../../media/api/requests";
import { modalOpen } from "../../../";
import { PrimaryButton, StandardButton } from "../../../index";
import {
  StyledActionsWrapper,
  StyledDragActive,
  StyledDragActiveH5,
  StyledMediaList,
  StyledMediaListItem,
  StyledMediaListItemImage
} from "./StyledDropZoneModal";

export const openDropzoneModal = (
  dispatch,
  { onOK = () => {}, onCancel = () => {}},
  entityId
) => {
  let selectedMedia = [];
  const header = "Ladda upp media";

  const _onDrop = files => {
    uploadMedia({
      files,
      entityId
    }).then(res => {
      if (res && res.data) {
        selectedMedia = res.data.data;
      }
      render();
    });
  };

  /**
   * Returns correct classes for drop zone
   * @param isDragActive
   */
  const getDropZoneClasses = isDragActive =>
    classNames("dropzone", { "dropzone--isActive": isDragActive });

  /**
   * Render drag active
   * @param isDragActive
   * @returns {null}
   */
  const renderDragActive = isDragActive =>
    !isDragActive ? (
      <StyledDragActive>
        <StyledDragActiveH5>Släpp filerna här eller</StyledDragActiveH5>
        <PrimaryButton>Bläddra</PrimaryButton>
      </StyledDragActive>
    ) : null;

  /**
   * Render drop zone content
   * @param getRootProps
   * @param getInputProps
   * @param isDragActive
   * @returns {*}
   */
  const renderDropsZoneContent = ({
    getRootProps,
    getInputProps,
    isDragActive
  }) => (
    <div {...getRootProps()} className={getDropZoneClasses(isDragActive)}>
      <input {...getInputProps()} />
      {renderDragActive(isDragActive)}
    </div>
  );

  /**
   * Render children
   * @returns {*}
   */
  const renderChildren = () => (
    <div>
      <Dropzone onDrop={_onDrop}>{renderDropsZoneContent}</Dropzone>
      <StyledMediaList>
        {selectedMedia.map((m, i) => (
          <StyledMediaListItem key={i}>
            <StyledMediaListItemImage src={m.url} />
          </StyledMediaListItem>
        ))}
      </StyledMediaList>
    </div>
  );

  /**
   * On cancel
   * @private
   */
  const _onCancel = () => {
    modalClose(dispatch);
    onCancel([]);
  };

  /**
   * On ok
   * @private
   */
  const _onOk = () => {
    modalClose(dispatch);
    onOK(selectedMedia);
  };

  /**
   * Render actions
   * @returns {*}
   */
  const renderActions = () => (
    <StyledActionsWrapper>
      <ButtonGroup>
        <StandardButton onClick={_onCancel}>Avbryt</StandardButton>

        <PrimaryButton onClick={_onOk}>Ok</PrimaryButton>
      </ButtonGroup>
    </StyledActionsWrapper>
  );

  const render = () => {
    modalOpen(dispatch)({
      header,
      children: renderChildren(),
      actions: renderActions()
    });
  };

  render();
};
