/*
  Formats for MultiSvgImages
*/
export const viewBoxes = {
  "16:9": "0 0 700 390",
  "3:1": "0 0 900 300",
  "4:1": "0 0 1024 256",
  "2:1": "0 0 312 136",
  "1:1": "0 0 256 256"
};

export const iconSizes = {
  "16:9": 280,
  "3:1": 280,
  "4:1": 256,
  "2:1": 136,
  "1:1": 256
};

export const scale = {
  extrasmall: 0.125,
  small: 0.25,
  medium: 0.5,
  large: 1
};
