import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./styles/PluginCssOverrides.css";
import * as sharedModule from "./shared";
import * as productsModule from "./products";
import * as postsModule from "./posts";
import * as structureModule from "./structure";
import * as mediaModule from "./media";
import * as mediaResourceModule from "./mediaresource";
import * as usersModule from "./users";
import * as adventuresModule from "./story-adventures";
import * as systemsModule from "./systems";
import * as classroomModule from "./classroom";
import * as seriesModule from "./series";
import * as messagesModule from "./messages";
import * as productlistModule from "./productlist";
import * as taxonomyModule from "./taxonomy";

import App from "./App";

import createStore from "./store";

sharedModule.init();
productsModule.init();
structureModule.init();
postsModule.init();
mediaModule.init();
mediaResourceModule.init();
usersModule.init();
adventuresModule.init();
systemsModule.init();
classroomModule.init();
messagesModule.init();
seriesModule.init();
productlistModule.init();
taxonomyModule.init();

export const store = createStore({});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
