import * as React from "react";
import {
  StyledLineupGrid,
  StyledCell,
  StyledCellValue
} from "./StyledLineupGrid";
import { MULTIPLICATION, DIVISION, PLUS, MINUS } from "../../../../constants";
import { ZeroIcon } from "../../../../shared/components/StudliIcons";

const LineupGrid = props => {
  /**
   * Renders each row in the grid.
   * @param {*} row
   * @param {*} index
   */
  const renderRow = (row, index) => {
    const multiplication = props.type === MULTIPLICATION
    const addition = props.type === PLUS
    const subtraction = props.type === MINUS
    const division = props.type === DIVISION
    const upperLine = index === props.grid.length - 4 && multiplication
    const lowerLine = index === props.grid.length - 2 && (multiplication || addition || subtraction)

    return(
      row.map((cell, cellIndex) => (
        <StyledCell
          borderBottom={upperLine || lowerLine || row[cellIndex].borderBottom}
          key={"key_" + cellIndex}
          borderLeft={multiplication && cellIndex === props.grid[0].length - 3}
          removeBorder={multiplication && cellIndex >= props.grid[0].length - 3}
        >
          { cell.ghost
          ? <ZeroIcon size={31} color="none" />
          : <StyledCellValue isInMemory={cell.inMemory} isHorizontal={row[cellIndex].isHorizontal} isVertical={row[cellIndex].isVertical} isDivision={division}>
              {cell.comma ? cell.value + "," : cell.value}
            </StyledCellValue>
          }
        </StyledCell>
        )
      )
    )
  }

  /**
   * Renders grid data
   */
  const renderData = () =>
    props.grid.map((row, index) => renderRow(row, index));

  return (
    <StyledLineupGrid rowLength={props.grid[0].length}>
      {renderData()}
    </StyledLineupGrid>
  );
};

export default LineupGrid;
