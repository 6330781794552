import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  
  display: flex;
  cursor: pointer;
  flex-direction: row;
  margin-top: 0.5rem;
  font-weight: 500;
  color: #287574;

  & > input {
    display: none;

    &:checked ~ .toogle-switch {
      background: #287574;
    }

    &:checked ~ .toogle-switch::after {
      transform: translateX(20px);
    }
  }
`;

const StyledSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 15px;
  border-radius: 10px;
  background: #dddddd;
  transition: background 0.2s;
  margin-left: 1rem;
  margin-top: 3px;

  &::after {
    content: "";
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: transform 0.2s;
  }
`;

const Switch = ({ label, onChange, checked, disabled, className }) => {

  return (
    <StyledLabel disabled={disabled} className={className}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
      <StyledSwitch className="toogle-switch"></StyledSwitch>
    </StyledLabel>
  );
};

export default Switch;
