import styled from "styled-components";

export default styled.span`
  ${props => props.theme.d400()};
  font-weight: 500;
  margin: 0;
  line-height: 1;
  align-self: center;
  z-index: 1;
`;
