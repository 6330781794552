import React from "react";

const Move = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-move"
  >
    <path d="M31,21.6281523 L31,26.214871 L6,26.214871 L6,29.5351835 L31,29.5351835 L31,34.1213164 C31,36.8971953 34.3678711,38.2977812 36.334668,36.3309843 L42.584668,30.0846953 C43.8049805,28.8643828 43.8051758,26.8857695 42.584668,25.6652617 L36.334668,19.4183867 C34.371875,17.4555937 31,18.846707 31,21.6281523 Z M34.4016602,21.352371 L40.6511719,27.5987578 C40.7244508,27.6718497 40.7656829,27.7710643 40.7657928,27.8745641 C40.7659027,27.9780639 40.7248814,28.0773659 40.6517578,28.1506132 L34.4011719,34.3974882 C34.1539062,34.6447539 33.7342773,34.468289 33.7342773,34.1213164 L33.7342773,21.6281523 C33.734375,21.2816679 34.1539062,21.1046171 34.4016602,21.352371 Z M19.0175781,9.125 L24.3525391,14.4599609 C24.9384766,15.0458984 25.7333984,15.375 26.5625,15.375 L45.3125,15.375 C46.1757813,15.375 46.875,16.0742188 46.875,16.9375 L46.875,38.8125 C46.875,39.6757812 46.1757813,40.375 45.3125,40.375 L4.6875,40.375 C3.82421875,40.375 3.125,39.6757812 3.125,38.8125 L3.125,10.6875 C3.125,9.82421875 3.82421875,9.125 4.6875,9.125 L19.0175781,9.125 Z M4.6875,6 C2.09863281,6 3.55271368e-14,8.09863281 3.55271368e-14,10.6875 L3.55271368e-14,38.8125 C3.55271368e-14,41.4013672 2.09863281,43.5 4.6875,43.5 L45.3125,43.5 C47.9013672,43.5 50,41.4013672 50,38.8125 L50,16.9375 C50,14.3486328 47.9013672,12.25 45.3125,12.25 L26.5625,12.25 L21.2275391,6.91503906 C20.6416016,6.32910156 19.8466797,6 19.0175781,6 L4.6875,6 Z" />
  </svg>
);

export default Move;
