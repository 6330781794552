import React from "react";
import styled from "styled-components";

const Date = styled.abbr`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  ${props => (props.isSelected ? "font-weight: bold" : "font-weight: normal")};
`;

const DateRenderer = ({ cellData, rowData }) => (
  <Date isSelected={rowData.selected}>{cellData}</Date>
);

export default DateRenderer;
