import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { PrimaryButton } from "../../../shared";
import { exportProductList } from "../../api/actions";

const StyledButton = styled(PrimaryButton)`
  width: 150px;
`;
const StyledButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ProductListExportButton = () => {
  const dispatch = useDispatch();

  const exportListHandler = () => {
    dispatch(exportProductList());
  };

  return (
    <StyledButtonWrapper>
      <StyledButton onClick={exportListHandler}>Exportera lista</StyledButton>
    </StyledButtonWrapper>
  );
};

export default ProductListExportButton;
