import { fromJS, Map } from "immutable";
import { createReducer } from "../../../store/utils";

import { SHOW_FLASH, ADD_FLASH, CLEAR_FLASH } from "./actions";
import { guid } from "../../helpers";

const initialState = fromJS({
  showing: [],
  queue: [],
  showTime: 5000
});

export default createReducer(initialState, {
  [SHOW_FLASH](state, { id }) {
    const flash = state.get("queue").find(flash => flash.get("id") === id);
    return state
      .set("queue", state.get("queue").shift())
      .update("showing", showing => showing.push(flash));
  },
  [CLEAR_FLASH](state, { id }) {
    const flashIndex = state
      .get("showing")
      .findIndex(flash => flash.get("id") === id);
    if (flashIndex === -1) {
      return state;
    }
    return state.set("showing", state.get("showing").delete(flashIndex));
  },
  [ADD_FLASH](state, { msg, flashType, delay }) {
    return state.update("queue", queue =>
      queue.push(
        Map({
          msg,
          type: flashType,
          id: guid(),
          delay: delay
        })
      )
    );
  }
});
