import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Download, Upload, Trash2 } from "react-feather";

import IconButton from "../../../products/components/IconButton";
import Modal from "../../../shared/components/Modal/Modal";
import PrimaryButton from "../../../shared/components/Button/Primary";

const StyledToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 0.3rem 0;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 0;
`;

const StyledIconButtonWithRightMargin = styled(IconButton)`
  margin-right: 1rem;
`;

const StyledDownloadIcon = styled(Download)`
  color: #0078d7;
`;

const StyledUploadIcon = styled(Upload)`
  color: #0078d7;
`;

const iconSize = "20px";

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 465px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const StyledModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;

  :last-child {
    margin-left: 10px;
  }
`;

const FileToolbar = ({
  onDownloadContent,
  onUploadContent,
  onDeleteContent,
  disabled,
  isFilesUploaded = false,
  uploadWarningText = "",
  title = ""
}) => {
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [uploadData, setUploadData] = useState(null);

  const fileInputRef = useRef();

  const _fileUpload = e => {
    const { files } = e.target;
    const fileReader = new FileReader();

    if (files) {
      fileReader.readAsText(files[0], "UTF-8");
      fileReader.onload = ev => {
        if (isFilesUploaded) {
          setVerifyModalOpen(true);
          setUploadData(ev.target.result);
          return;
        }
        onUploadContent(ev.target.result);
      };
    }
    e.target.value = "";
  };

  const handleOverrideFiles = () => {
    onUploadContent(uploadData);
    setVerifyModalOpen(false);
    setUploadData(null);
  };

  return (
    <>
      <StyledToolbarWrapper>
        <StyledInput
          type="file"
          multiple={false}
          onChange={_fileUpload}
          ref={fileInputRef}
          accept="application/json"
        />
        {title !== "" && <StyledTitle>{title}</StyledTitle>}
        <StyledIconButton
          disabled={disabled}
          aria-label="Ladda ner"
          onClick={e => onDownloadContent(e)}
        >
          <StyledDownloadIcon height={iconSize} width={iconSize} />
        </StyledIconButton>
        <StyledIconButtonWithRightMargin
          aria-label="Ladda upp"
          disabled={disabled}
          onClick={() => fileInputRef.current.click()}
        >
          <StyledUploadIcon height={iconSize} width={iconSize} />
        </StyledIconButtonWithRightMargin>
        <IconButton
          disabled={disabled}
          aria-label="Ta bort"
          onClick={() => onDeleteContent()}
        >
          <Trash2 height={iconSize} width={iconSize} />
        </IconButton>
      </StyledToolbarWrapper>
      <Modal isModalOpen={verifyModalOpen} modalClose={() => setVerifyModalOpen(false)}>
        <StyledModalContent>
          {uploadWarningText}
          <StyledModalActions>
            <StyledButton onClick={() => setVerifyModalOpen(false)}>
              Avbryt
            </StyledButton>
            <StyledButton onClick={handleOverrideFiles}>
              OK
            </StyledButton>
          </StyledModalActions>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default FileToolbar;
