import styled from "styled-components";

export const StyledProductViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledProductViewColumn = styled.div`
  flex: 1;
  min-height: 25vh;
  padding: 20px;
`;

export const StyledSecondaryProductViewColumn = styled(StyledProductViewColumn)`
  padding-top: 40px;
`;

export const StyledSectionContainer = styled.div`
  display: flex;
`;

export const StyledSection = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid lightgrey;
  flex-direction: row;
  display: flex;
  width: 100%;
`;

export const StyledDescriptionContainer = styled.div`
  width: 100%;
`;

export const StyledLink = styled.span`
  font-size: 13px;
  text-decoration: underline;
  color: #0978d6;
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 5px;
`;

export const StyledSectionsTitle = styled.div`
  display: flex;
`;

export const StyledSectionsWrapper = styled.div`
  margin-top: 25px;
  padding: 3px;
`;

export const StyledDescription = styled.div`
  font-size: 13px;
`;

export const StyledSectionTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
`;
