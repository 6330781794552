import { put, select } from "redux-saga/effects";
import { fromJS } from "immutable";
import {
  selectIconDraftJS,
  selectIconDraft,
  selectCurrentWorkspaceFormat,
  selectAlignToWorkspaceSetting,
  selectWorkspaceSettings
} from "../api/selectors";
import {
  moveFrontOrBack,
  updateSizeForSelectedIcons,
  duplicateSelectedIcons,
  align,
  getSelectedObjectsRectCoords,
  distributeHorizontal,
  distributeVertical
} from "./editorfunctions";
import {
  updateDraftMultiSVG,
  setMultiSVGData,
  setMultiSVGFormat,
  updateEditorSettings,
  fetchMediaResource
} from "../api/actions";
import { selectEntityId } from "../../store/selectors";
import { selectPostId } from "./selectors";

/**
 * Init when opening a new multi svg.
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} data - multi svg data
 */
export function* initIconData(entityName, apiAction, { data }) {
  const icons = data && data.Icons ? data.Icons : [];
  const draft = icons.map((icon, index) => {
    icon.selected = false;
    icon.id = "icon_" + index;
    return icon;
  });
  const format = data && data.Format ? data.Format : "16:9";

  yield put(setMultiSVGData(entityName, fromJS(icons)));
  yield put(updateDraftMultiSVG(entityName, fromJS(draft)));
  yield put(setMultiSVGFormat(entityName, fromJS(format)));
}

/**
 * Moves an icon front (positive direction) or back (negative direction) in z by swapping place in icon list
 * one step to the right or left in the list.
 * @param {*} entityName
 * @param {*} apiAction
 * @param {number} direction
 */
export function* moveIconsFrontOrBack(entityName, apiAction, { direction }) {
  const iconDraft = yield select(selectIconDraftJS);
  const newdraft = fromJS(moveFrontOrBack(iconDraft, direction));
  yield put(updateDraftMultiSVG(entityName, newdraft));
}

/**
 * Resize selected icons
 * @param {*} entityName
 * @param {*} apiAction
 * @param {string} newSize
 */
export function* resizeIcons(entityName, apiAction, { newSize }) {
  const iconDraft = yield select(selectIconDraft);
  const newDraft = updateSizeForSelectedIcons(iconDraft, newSize);
  yield put(updateDraftMultiSVG(entityName, newDraft));
}

/**
 * Multiply selected icons
 * @param {*} entityName
 * @param {*} apiAction
 * @param {number} numberOfCopies
 */
export function* duplicateIcons(entityName, apiAction, { numberOfCopies }) {
  const iconDraft = yield select(selectIconDraftJS);
  const newDraft = fromJS(duplicateSelectedIcons(iconDraft, numberOfCopies));
  yield put(updateDraftMultiSVG(entityName, newDraft));
}

/**
 * Align Icons according to aligndirection
 * @param {*} entityName
 * @param {*} apiAction
 * @param {string} aligndirection
 */
export function* alignIcons(entityName, apiAction, { alignDirection }) {
  const iconDraft = yield select(selectIconDraft);
  const alignToWorkspace = yield select(selectAlignToWorkspaceSetting);
  const format = yield select(selectCurrentWorkspaceFormat);
  const coords = getSelectedObjectsRectCoords(
    iconDraft,
    alignToWorkspace,
    format
  );

  const newDraft = align(iconDraft, alignDirection, alignToWorkspace, coords);
  yield put(updateDraftMultiSVG(entityName, newDraft));
}

/**
 * Distribute icons evenly between eachother
 * @param {*} entityName
 * @param {*} apiAction
 * @param {string} direction - horizontal or vertical
 */
export function* distributeIcons(entityName, apiAction, { direction }) {
  const iconDraft = yield select(selectIconDraft);
  const alignToWorkspace = yield select(selectAlignToWorkspaceSetting);
  const format = yield select(selectCurrentWorkspaceFormat);
  const coords = getSelectedObjectsRectCoords(
    iconDraft,
    alignToWorkspace,
    format
  );

  const newDraft =
    direction === "horiz"
      ? distributeHorizontal(iconDraft, format, coords, alignToWorkspace)
      : distributeVertical(iconDraft, format, coords, alignToWorkspace);

  yield put(updateDraftMultiSVG(entityName, newDraft));
}

/**
 * Remove selected icons
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* removeIcons(entityName, apiAction) {
  const iconDraft = yield select(selectIconDraftJS);
  const newDraft = iconDraft.reduce(
    (result, icon) => (icon.selected ? result : [...result, icon]),
    []
  );
  yield put(updateDraftMultiSVG(entityName, fromJS(newDraft)));
}

/**
 * Updates settings for the Editor
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} settings
 */
export function* updateSettings(entityName, apiAction, { settings }) {
  let editorSettings = yield select(selectWorkspaceSettings);
  editorSettings = editorSettings.merge(settings);
  yield put(updateEditorSettings(entityName, editorSettings));
}

/**
 * Update icons in draft
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} data - new data for icons
 */
export function* updateIcons(entityName, apiAction, { data }) {
  let iconDraft = yield select(selectIconDraft);
  for (let newdata of data) {
    const indx = iconDraft.findIndex(idr => idr.get("id") === newdata.id);
    iconDraft = iconDraft.update(indx, item => item.merge(fromJS(newdata)));
  }
  yield put(updateDraftMultiSVG(entityName, iconDraft));
}

/**
 * Add a new icon to current draft
 * @param {*} entityName
 * @param {*} apiAction
 * @param {*} newIcon - the new icon added to draft
 */
export function* addNewIcon(entityName, apiAction, { newIcon }) {
  const iconDraft = yield select(selectIconDraft);
  const newDraft = iconDraft.push(fromJS(newIcon));
  yield put(updateDraftMultiSVG(entityName, newDraft));
}

/**
 * Called when route changes to mediaresource edit
 * Loads the media resource based on the parameters from the payload
 * @param {*} entityName
 * @param {*} apiAction
 */
export function* onMediaEditRoute(entityName, apiAction) {
  const entityId = yield select(selectEntityId);
  const postId = yield select(selectPostId);
  yield put(
    fetchMediaResource(entityName, apiAction, {
      entityId: entityId,
      postId: postId
    })
  );
}
