import { createSelector } from "reselect";
import { SERIES } from "../../constants";
import { format } from "../../date";
import { ROUTE_SERIES_DETAILS } from "../routes";

export const getProductsAvailable = state => state.Product.get("list").toJS();

export const getSeriesAvailable = createSelector(
  state => state.Series.get("list").toJS(),
  state => state.Series.get("workflowMap"),
  state => state.Series.get("permissions"),
  (_, type) => type,
  (list, workflowMap, permissions, type) =>
    list.map(l => {
      const date = new Date(l.updatedAt);

      const actions = {
        edit: {
          type: ROUTE_SERIES_DETAILS,
          postType: SERIES,
          payload: {
            id: l.id
          }
        },
        delete: {
          postType: SERIES,
          postIds: [l.id]
        },
        publish: {
          postType: SERIES,
          postIds: l.id
        },
        unpublish: {
          postType: SERIES,
          postIds: l.id
        }
      };

      return {
        ...l,
        type,
        updatedAt: format(date),
        actions,
        workflowMap,
        permissions,
        askWhenDelete: true
      };
    })
);

export const selectSeriesFilters = state => state.Series.get("filters");

export const selectFetchLimit = state => state.Series.get("fetchLimit");

export const selectSearchParams = state =>
  state.Series.get("searchParameters").toJS();

export const selectPage = state => state.Series.get("page");

export const selectQuery = state => state.location.query || {};

export const selectSeries = createSelector(
  state => state.Series.get("selectedItem"),
  item => item
);

export const selectPathName = state => state.location.pathname;

export const selectIsInSeries = state =>
  state.location.pathname.indexOf("series") >= 0;

export const getAllSeries = state => state.Series.get("list").toJS();

export const isCreatedSerieEdited = state => state.Series.get("isEdited");
