import {
  take,
  takeEvery,
  put,
  select,
  all,
  fork,
  cancel,
  race,
  delay
} from "redux-saga/effects";
import {
  ADD_FLASH,
  PREPARE_SHOW_FLASH,
  SHOW_FLASH,
  CLEAR_FLASH
} from "../store/flasher/actions";

function* showFlashes() {
  const showTime = yield select(st => st.flasher.get("showTime"));

  const queue = yield select(st => st.flasher.get("queue"));

  const execs = queue.map(flash =>
    fork(function*() {
      yield put({ type: SHOW_FLASH, id: flash.get("id") });
      const hasDelay = flash.get("delay");
      if (hasDelay) {
        const clearTask = yield fork(clearFlash, flash.get("id"), showTime);

        while (true) {
          const clearAction = yield take(CLEAR_FLASH);

          if (clearAction.id === flash.get("id")) {
            cancel(clearTask);
            break;
          }
        }
      }
    })
  );

  yield all(execs.toArray());
}

function* clearFlash(id, showTime) {
  yield delay(showTime);
  yield put({ type: CLEAR_FLASH, id });
}

export function* watchShowFlash() {
  while (true) {
    yield take(PREPARE_SHOW_FLASH);
    yield race({
      task: showFlashes(),
      cancel: take(CLEAR_FLASH)
    });
  }
}

export function* watchAddFlash() {
  yield takeEvery(ADD_FLASH, showFlashes);
}
