import styled from "styled-components";

export const TagsPostWrapper = styled.div``;

export const TagWrapper = styled.div`
  border-radius: 5px;
  padding: 3px;
  float: left;
  position: relative;
`;

export const NoTagsSelected = styled.div`
  font-size: 12px;
  color: #999;
  padding: 5px;
`;

export const ColorLessTag = styled.span`
  font-size: 13px;
  color: ${props => props.theme.neutralDark};
`;
