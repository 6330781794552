import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ENTITIES, AIDA } from "../../../constants";
import { StandardButton } from "../../../shared";
import { Preview } from "@studli/aidahelp"
import "@studli/aidahelp/dist/index.css";
import { StyledActionPubButton } from "../../../shared/components/DataTable/Renderer/Action/StyledAction";
import { fetchEntityDetails } from "../../api/actions"
import useGetPreviewData from "./useGetPreviewData";
import { selectEntityId } from "../../../store/selectors";

const AidaPreview = ({id, btnType = "detail", selected}) => {

  const [helpPosition, setHelpPosition] = useState("topRight");
  const mid = window.innerHeight/2;
  const { data, chips } = useGetPreviewData();
  const entityId = useSelector(selectEntityId);
  const dispatch = useDispatch(); 
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [setAnchorEl])

  const hasAnyOtherHelpWindowOpen = () => {
    const container = document.getElementById("help-container-aida");
    return container && container.hasChildNodes();
  }

  const handleClick = (event) => {
    const oneOpen = hasAnyOtherHelpWindowOpen();
    const rect = event.currentTarget.getBoundingClientRect();
    setHelpPosition(rect.y > mid ? "bottomRight" : "topRight");
    if (btnType !== "detail" && !oneOpen) {
      dispatch(fetchEntityDetails(ENTITIES[AIDA], "fetchAidaPost", { entityId: entityId, postId: id }))
    }
    !oneOpen && setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl)
  
  return (
    <>
      { btnType === "postAction" && 
        <StandardButton id={id} studlicon="Preview" onClick={handleClick} />}
      { btnType === "action" && 
        <StyledActionPubButton id={id} selected={selected} studlicon="Preview" onClick={handleClick}/>}
      { btnType === "detail" &&
        <StandardButton id={id} studlicon="Preview" onClick={handleClick}>
          Förhandsgranska
        </StandardButton> }
      { data.content !== "" && open && (
        <Preview 
          position={{ windowPosition: helpPosition }}
          anchorEl={anchorEl}
          open={open}
          tags={chips}
          data={[data]}
          closeHelp={handleClose}
          supportURL={process.env.REACT_APP_BOOKSHELF_URL}
        />
      )}
    </>
  )
}

export default AidaPreview
