import React from "react";

const DistributeHorizont = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-distributehorizont"
  >
    <rect
      transform="translate(30.000000, 25.000000) rotate(-180.000000) translate(-30.000000, -25.000000) translate(25.000000, 5.000000)"
      fill="#377AD3"
      id="path-1"
      x="0"
      y="0"
      width="10"
      height="40"
    ></rect>
    <rect
      transform="translate(20.000000, 25.000000) rotate(-180.000000) translate(-20.000000, -25.000000) translate(15.000000, 5.000000)"
      fill="#384E70"
      id="path-3"
      x="0"
      y="0"
      width="10"
      height="40"
    ></rect>

    <rect
      id="Rectangle"
      fill="#000000"
      transform="translate(1.000000, 25.000000) rotate(-270.000000) translate(-1.000000, -25.000000) "
      x="-24"
      y="24"
      width="50"
      height="2"
    ></rect>
    <rect
      id="Rectangle"
      fill="#000000"
      transform="translate(49.000000, 25.000000) rotate(-270.000000) translate(-49.000000, -25.000000) "
      x="24"
      y="24"
      width="50"
      height="2"
    ></rect>
  </svg>
);

export default DistributeHorizont;
