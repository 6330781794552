import * as React from "react";
import PluginHoc from "../../../shared/PluginHoc";
import {
  StyledLineup,
  StyledLineupContent,
  StyledResultContainer,
  StyledResultLabel,
  StyledResultAnswer,
  StyledResultAnswerField,
  StyledGridContainer
} from "./StyledLineup";
import SideBar from "../Sidebar/Sidebar";
import {
  rowSettingStyles,
  settingsData,
  caluculateNumbers,
  generateGridWithTerms,
  firstTermLengthIsGreaterOrEqual,
  valuesIswithinBorders,
  hasDecimals,
  getTotalDecimals
} from "./LineupData";
import { ItemContainer, LineupItem } from "./LineupItem";
import { DATA, MULTIPLICATION, REST, DIVISION, EQUAL, SETTINGS, EQUAL_SIGN, APPROXIMATION } from "../../../constants";
import { isNumeric } from "../../../shared/helpers";
import LineupGrid from "./LineupGrid/LineupGrid";

const Lineup = props => {  
  /**
   * Ref for calculate type.
   */
  const calculateTypeRef = React.useRef(props.settings.calculateType);
  /**
   * Ref for answer type. ( ONLY FOR DIVISION )
   */
  const answerTypeRef = React.useRef(props.settings.answerType);
  /**
   * Ref for equal sign type. ( ONLY FOR DIVISION )
   */
  const equalSignRef = React.useRef(props.settings.equalSign);

  /**
   * If warnings are generated we store them in state.
   */
  const [warnings, setWarnings] = React.useState({});

  /**
   * This Effect listens to changes done to answer type.
   * It resets the equal sign on change.
   */
  React.useEffect(() => {
    if (props.settings.answerType !== answerTypeRef.current) {
      const { settings: { answerType, equalSign }} = props;
      if (answerType === REST && equalSign === APPROXIMATION) {
        answerTypeRef.current = props.settings.answerType;
        props.updateData(EQUAL_SIGN, EQUAL, SETTINGS)
      }
    }
    // eslint-disable-next-line
  }, [props.settings.answerType, props.settings.equalSign])

  /**
   * This Effect listens to changes done to the calculate type setting.
   */
  React.useEffect(() => {
    if ((props.settings.calculateType !== calculateTypeRef.current)
    || (props.settings.answerType !== answerTypeRef.current)
    || (props.settings.equalSign !== equalSignRef.current)) {

      calculateTypeRef.current = props.settings.calculateType;
      answerTypeRef.current = props.settings.answerType;
      equalSignRef.current = props.settings.equalSign;

      const { data: { items } } = props;

      updateGridContent(items)
    }
    // eslint-disable-next-line
  }, [props.settings.calculateType, props.settings.answerType, props.settings.equalSign]);

  /**
   * Registers the validator after render
   */
  React.useEffect(() => {
    props.registerValidator([
      validateInUse,
      "Det måste finnas något att räkna på eller så finns det inte någon Grid för tillfället."
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Validator checking if grid is available.
   * @returns {*}
   */
  const validateInUse = componentData => componentData.grid !== null;

  /**
   * Checks if any of the values are zero or not for multiplication.
   * @param {*} first
   * @param {*} second
   * @param {*} type
   */
  const noZeroValues = (first, second, type) => (
    type === MULTIPLICATION
      ? parseFloat(first) === 0 || parseFloat(second) === 0
        ? false
        : true
      : true
  )

  /**
   * Sets initial index for items
   * @param {*} items
   */
  const setInitalIndexForItems = items =>
    items.map((item, index) => ({ ...item, index: index }));

  /**
   * Returns the calculated value
   * @param {*} num1
   * @param {*} num2
   * @param {*} type
   */
  const caluculateResult = (num1, num2, type) =>
    isNumeric(num1) && isNumeric(num2)
      ? caluculateNumbers[type](num1, num2)
      : null;

  /**
   * Function that runs after items has moved.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...props.data.items];
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);
    props.storeDraft(props.draftTarget);
    updateGridContent(items);
  };

  /**
   * Render Lineup sidebar
   * @returns {*}
   */
  const renderLineupSidebar = () => (
    <SideBar
      updateData={props.updateData}
      storeDraft={props.storeDraft}
      draftTarget={props.draftTarget}
      settings={props.settings}
      data={settingsData(props.settings.calculateType, props.settings.answerType)}
      rowSettingStyles={rowSettingStyles}
      keyboards={props.keyboards}
    />
  );

  /**
   * Handles edit item content
   * @param index
   * @param content
   */
  const editItemContent = (index, content) => {
    const { items } = props.data;
    let item = { ...items[index] };

    if (content.value !== undefined) {
      item.value = content.value;
    }

    const newItems = items.map((element, i) => (i === index ? item : element));
    updateGridContent(newItems);
    props.storeDraft(props.draftTarget);
  };

  /**
   * Update gridcontent
   * @param {*} items
   */
  const updateGridContent = (items) => {
    const { calculateType, answerType, equalSign } = props.settings;

    const firstItem = items[0].value;
      const secondItem = items[1].value;
      const result = caluculateResult(
        firstItem,
        secondItem,
        calculateType
      );

      const resultHasDecimals = result < 1 && result > 0 && answerType === REST && calculateType === DIVISION
      const totalDecimals = getTotalDecimals(result)
      const correctTermsAlignment = calculateType === MULTIPLICATION
        ? firstTermLengthIsGreaterOrEqual(items)
        : true

      const grid =
        result !== null &&
        result >= 0 &&
        correctTermsAlignment &&
        !resultHasDecimals &&
        noZeroValues(firstItem, secondItem, calculateType) &&
        valuesIswithinBorders(firstItem, calculateType, 0) &&
        valuesIswithinBorders(secondItem, calculateType, 1) &&
        noNegativeNumbers(firstItem, secondItem) &&
        !hasDecimals(secondItem, calculateType, 1)
          ? generateGridWithTerms(
              calculateType,
              answerType,
              equalSign,
              totalDecimals,
              items[0].value,
              items[1].value
            )
          : null;

      const warning = checkForWarnings(grid, calculateType)
      setWarnings(warning)

      props.updateData(
        null,
        { items: setInitalIndexForItems(items), result: result, grid: Object.keys(warning)[0] ? null : grid},
        DATA
      );
  }

  /**
   * Checks for specific warnings depending on usecase
   */
  const checkForWarnings = (grid, type) => {
    if(type === DIVISION) {
     // Checks if memory digit is greater than 9
     return grid && grid[0].some(element => element.value > 9) ? { memoryDigitGreaterThan: true } : {}
    }

    return {}
  }

  /**
   * Values can not be negative, returns false if negative
   * @param {*} first
   * @param {*} second
   */
  const noNegativeNumbers = (first, second) =>
    parseInt(first) >= 0 && parseInt(second) >= 0;

  /**
   * Renders the result
   */
  const renderResultBox = () => {
    const { result } = props.data;
    return (
      <StyledResultContainer>
        <StyledResultLabel>{"Svar:"}</StyledResultLabel>
        <StyledResultAnswerField>
          <StyledResultAnswer>
            {(result !== null && !isNaN(result)) ? result : 0}
          </StyledResultAnswer>
        </StyledResultAnswerField>
      </StyledResultContainer>
    );
  };

  /**
   * Renders lineup grid
   */
  const renderGrid = () => (
    <StyledGridContainer>
      <LineupGrid grid={props.data.grid} type={props.settings.calculateType} />
    </StyledGridContainer>
  );

  /**
   * Renders items for lineup
   */
  const renderLineupItems = () => (
    <ItemContainer useDragHandle onSortEnd={onSortEnd}>
      {renderItems()}
    </ItemContainer>
  );

  /**
   * Render items
   */
  const renderItems = () => {
    const {
      actions,
      data: { items, result },
      settings: { calculateType, answerType }
    } = props;
    return (
      items &&
      items.map((item, i) => (
        <LineupItem
          key={"alt" + i}
          indx={i}
          index={i}
          data={item}
          result={result}
          actions={actions}
          editBtnFn={editItemContent}
          type={calculateType}
          items={items}
          answerType={answerType}
          warnings={warnings}
        />
      ))
    );
  };

  return (
    <StyledLineup>
      <StyledLineupContent>
        {renderLineupItems()}
        {props.data.grid && renderGrid()}
        {renderResultBox()}
      </StyledLineupContent>
      {renderLineupSidebar()}
    </StyledLineup>
  );
};

export default PluginHoc({
  Component: Lineup
});
