import { createSelector } from "reselect";
import { PRODUCTNEWS, OPMESSAGE, MARKETMESSAGE } from "../../constants";
import { format } from "../../date";
import {
  ROUTE_DETAILED_MARKETMESSAGE,
  ROUTE_DETAILED_OP_MESSAGE,
  ROUTE_DETAILED_PRODUCT_NEWS
} from "../routes";
import { selectWorkflowMapForCurrentPostType } from "../../store/selectors";
import { selectFlatChipList } from "../../posts/store/selectors";
import { getAllProductsList } from "../../products/store/selectors";
import { getEndTime, getStartTime } from "./helpers";

export const selectMessageListData = createSelector(
  (state, type) => state[type].get("list"),
  (_, type) => type,
  selectWorkflowMapForCurrentPostType,
  selectFlatChipList,
  getAllProductsList,
  (state, _) => state.Product.get("permissions"),
  (list, type, workflowMap, chipsList, productList, permissions) => {
    const mList = list.toJS();
    const detailedRoute = {
      [OPMESSAGE.toLowerCase()]: ROUTE_DETAILED_OP_MESSAGE,
      [PRODUCTNEWS.toLowerCase()]: ROUTE_DETAILED_PRODUCT_NEWS,
      [MARKETMESSAGE.toLowerCase()]: ROUTE_DETAILED_MARKETMESSAGE
    }[type.toLowerCase()];
    return mList.map(item => {
      const { startTime, endTime, products, series } = JSON.parse(item.metadata);

      const start =
        startTime && startTime !== "" ? getStartTime(startTime) : "";
      const end = endTime && endTime !== "" ? getEndTime(endTime) : "";

      const sesam_names =
        products &&
        products.map(refId => {
          const pData = findSesamFromRefId(productList, refId);
          return pData.sesam_name || "";
        });

      const chips =
        chipsList &&
        item.chips &&
        item.chips.reduce((acc, c) => {
          const chip = chipsList.find(i => c === i.id);
          if (chip) {
            acc.push(chip.title);
          }
          return acc;
        }, []);

      return {
        id: item.id,
        title: item.title,
        status: item.status,
        startTime: start,
        endTime: end,
        entity_id: item.entity_id,
        series: {
          count: series?.length || 0,
          content: []
        },
        products: {
          count: sesam_names?.length || 0,
          content: sesam_names || []
        },
        type: item.type,
        updated_at: format(new Date(item.updated_at)),
        chips: { count: chips?.length || 0, content: chips || [] },
        workflowMap: workflowMap,
        askWhenDelete: true,
        permissions: {
          [type.toLowerCase()]: permissions[type.toLowerCase()]
        },
        actions: {
          edit: {
            type: detailedRoute,
            postType: type,
            payload: {
              entityId: item.entity_id,
              id: item.id
            }
          },
          delete: {
            postType: type,
            postIds: [item.id]
          },
          publish: {
            postType: type,
            postIds: [item.id]
          },
          unpublish: {
            postType: type,
            postIds: [item.id]
          }
        }
      };
    });
  }
);

const findSesamFromRefId = (list, refId) => list.find(p => p.refId === refId) || {};
