import styled from "styled-components";

export const StyledStatusWrapper = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
`;

export const StyledIcon = styled.svg`
  padding-top: 2px;
  fill: ${({ theme, color }) => theme[color]};
  height: -webkit-fill-available;
`;
