import React from "react";
import Card from "./Card";
import CreateHurixBookHandler from "./CreateHurixBookHandler";

const CreateHurixBookCard = () => {
  return (
    <Card title="Kitaboo">
      <CreateHurixBookHandler />
    </Card>
  );
};

export default CreateHurixBookCard;
