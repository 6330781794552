import axios from "axios";
import { CMS_SSO_TOKEN } from "./shared/constants/fetch";
import { getCookie } from "./shared/services/cookies";

import { put } from "redux-saga/effects";
import { addFlash } from "./shared";
import { translationErrorMessage } from "./constants";

export const client = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_BACKEND_URL || "/api/",
  headers: { Authorization: `Token ${getCookie(CMS_SSO_TOKEN)}` }
});

export const longTimeoutClient = axios.create({
  timeout: 600000,
  baseURL: process.env.REACT_APP_BACKEND_URL || "/api/",
  headers: { Authorization: `Token ${getCookie(CMS_SSO_TOKEN)}` }
});

export function* generalErrorHandler(error) {
  console.warn(error);
  if (error.response) {
    if (error.response.status === 401) {
      yield put(
        addFlash(
          "Du har blivit utloggad. Sidan kommer automatiskt att laddas om.",
          "error"
        )
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return true;
    }
    if (error.response.status >= 500) {
      yield put(
        addFlash("Ett oväntat fel uppstod, försök igen senare", "error")
      );
      return true;
    }
  } else {
    if(error.message === "Network Error"){
      yield put(
        addFlash(
          "Kunde inte ansluta. Kontrollera din anslutning och försök igen.", "error"
        )
      );
      return;
    }
    yield put(
      addFlash(
        "Ett oväntat fel har uppstått: " +
          (translationErrorMessage[error.message]
            ? translationErrorMessage[error.message]
            : error.message),
        "error"
      )
    );
    return true;
  }
  return false;
}

export const FETCH_MEDIA_LIST = "FETCH_MEDIA_LIST";
export const FETCH_MEDIA_LIST_SUCCESS = "FETCH_MEDIA_LIST_SUCCESS";
export const FETCH_MEDIAL_LIST_ERROR = "FETCH_MEDIAL_LIST_ERROR";

export const FETCH_ENTITIES = "FETCH_ENTITIES";
export const FETCH_ENTITIES_SUCCESS = "FETCH_ENTITIES_SUCCESS";
export const FETCH_ENTITIES_ERROR = "FETCH_ENTITIES_ERROR";

export const FETCH_ENTITY_DETAILS = "FETCH_ENTITY_DETAILS";
export const FETCH_ENTITY_DETAILS_SUCCESS = "FETCH_ENTITY_DETAILS_SUCCESS";
export const FETCH_ENTITY_DETAILS_ERROR = "FETCH_ENTITY_DETAILS_ERROR";

export const FETCH_ENTITY_COMMENTS = "FETCH_ENTITY_COMMENTS";
export const FETCH_ENTITY_COMMENTS_SUCCESS = "FETCH_ENTITY_COMMENTS_SUCCESS";
export const FETCH_ENTITY_COMMENTS_ERROR = "FETCH_ENTITY_COMMENTS_ERROR";

export const FETCH_KEYBOARD_LIST = "FETCH_KEYBOARD_LIST";
export const FETCH_KEYBOARD_LIST_SUCCESS = "FETCH_KEYBOARD_LIST_SUCCESS";

export const START_FETCH = "START_FETCH";
export const START_FETCH_FILTERS = "START_FETCH_FILTERS";
export const END_FETCH = "END_FETCH";
export const END_FETCH_FILTERS = "END_FETCH_FILTERS";

export const GOTO_PAGE = "GOTO_PAGE";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const RESET_ENTITIES = "RESET_ENTITIES";

export const FETCH_FILTER = "FETCH_FILTER";
export const FETCH_FILTER_SUCCESS = "FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "FETCH_FILTER_ERROR";
export const RESET_BEFORE_FILTER_SORT_CHANGE =
  "RESET_BEFORE_FILTER_SORT_CHANGE";
export const RESET_POST_BUFFER = "RESET_POST_BUFFER";
export const RESET_POST_BUFFER_INDEX = "RESET_POST_BUFFER_INDEX";
export const FILTER_POSTS = "FILTER_POSTS";

export const FETCH_MEDIAFILTER = "FETCH_MEDIAFILTER";
export const FETCH_MEDIAFILTER_SUCCESS = "FETCH_MEDIAFILTER_SUCCESS";
export const FETCH_MEDIAFILTER_ERROR = "FETCH_MEDIAFILTER_ERROR";

export const FETCH_MEDIA_ENTITIES = "FETCH_MEDIA_ENTITIES";
export const FETCH_MEDIA_ENTITIES_SUCCESS = "FETCH_MEDIA_ENTITIES_SUCCESS";
export const FETCH_MEDIA_ENTITIES_ERROR = "FETCH_MEDIA_ENTITIES_ERROR";

export const FETCH_MEDIA_ENTITY_DETAILS = "FETCH_MEDIA_ENTITY_DETAILS";
export const FETCH_MEDIA_ENTITY_DETAILS_ERROR =
  "FETCH_MEDIA_ENTITY_DETAILS_ERROR";
export const FETCH_MEDIA_ENTITY_DETAILS_SUCCESS =
  "FETCH_MEDIA_ENTITY_DETAILS_SUCCESS";

export const NEXT_MEDIA_PAGE = "NEXT_MEDIA_PAGE";
export const PREVIOUS_MEDIA_PAGE = "PREVIOUS_MEDIA_PAGE";
export const RESET_MEDIA_BUFFER_INDEX = "RESET_MEDIA_BUFFER_INDEX";
export const GOTO_MEDIA_PAGE = "GOTO_MEDIA_PAGE";

export const MULTISVG_SET_DATA = "MULTISVG_SET_DATA";
export const MULTISVG_SET_FORMAT = "MULTISVG_SET_FORMAT";
export const MULTISVG_UPDATE_DRAFT = "MULTISVG_UPDATE_DRAFT";
export const MULTISVG_EDITOR_UPDATE_SETTINGS =
  "MULTISVG_EDITOR_UPDATE_SETTINGS";
export const MULTISVG_UPDATE_TITLE = "MULTISVG_UPDATE_TITLE";

export const SET_POST_DESCRIPTION = "SET_POST_DESCRIPTION";
export const SET_POST_TMPDATA = "SET_POST_TMPDATA";
export const CLEAR_POST_TMPDATA = "CLEAR_POST_TMPDATA";
export const FETCH_ENTITY_DETAILS_SUCCESSFULLY_PREPPED =
  "FETCH_ENTITY_DETAILS_SUCCESSFULLY_PREPPED";

export const PUBLISH_ALL_SUCCESS = "PUBLISH_ALL_SUCCESS";

export const SAVE_ENTITY = "SAVE_ENTITY";
export const START_SAVE_ENTITY = "SAVE_ENTITY_START";
export const SAVE_ENTITY_SUCCESS = "SAVE_ENTITY_SUCCESS";
export const SAVE_ENTITY_ERROR = "SAVE_ENTITY_ERROR";
export const END_SAVE_ENTITY = "SAVE_ENTITY_END";
export const SET_MEDIA_DRAFT = "SET_MEDIA_DRAFT";
