import React from "react";

const Label = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-label"
  >
    <path d="M48.6270508,22.0645508 L27.9354492,1.37294922 C27.0563775,0.493870953 25.8640982,6.50074621e-06 24.6208984,0 L4.6875,0 C2.09863281,5.20417043e-16 0,2.09863281 0,4.6875 L0,24.6208984 C6.50074621e-06,25.8640982 0.493870953,27.0563775 1.37294922,27.9354492 L22.0645508,48.6270508 C23.8951172,50.4576172 26.8630859,50.4577148 28.6936523,48.6270508 L48.6270508,28.6936523 C50.4576172,26.8630859 50.4576172,23.8951172 48.6270508,22.0645508 Z M46.4173828,26.4839844 L26.4839844,46.4173828 C25.8743164,47.0270508 24.8848633,47.0280273 24.2743164,46.4173828 L3.58261719,25.7256836 C3.28863324,25.4332899 3.1238497,25.0354323 3.125,24.6208008 L3.125,4.6875 C3.125,3.82597656 3.82597656,3.125 4.6875,3.125 L24.6208984,3.125 C25.0382812,3.125 25.4306641,3.2875 25.7257813,3.58261719 L46.4173828,24.2742188 C47.0265625,24.8834961 47.0265625,25.8748047 46.4173828,26.4839844 Z M14.0625,12.109375 C15.1394531,12.109375 16.015625,12.9855469 16.015625,14.0625 C16.015625,15.1394531 15.1394531,16.015625 14.0625,16.015625 C12.9855469,16.015625 12.109375,15.1394531 12.109375,14.0625 C12.109375,12.9855469 12.9855469,12.109375 14.0625,12.109375 Z M14.0625,9.375 C11.4736328,9.375 9.375,11.4736328 9.375,14.0625 C9.375,16.6513672 11.4736328,18.75 14.0625,18.75 C16.6513672,18.75 18.75,16.6513672 18.75,14.0625 C18.75,11.4736328 16.6513672,9.375 14.0625,9.375 Z" />
  </svg>
);

export default Label;
