import { client, longTimeoutClient } from "../../api";

export const listAdventuresGroups = ({ entityId }) => {
  return client.get(`entity/${entityId}/adventuregroups`);
};

export const listAdventures = ({ entityId, adventureGroupId }) => {
  return client.get(`entity/${entityId}/adventuregroup/${adventureGroupId}`);
};

export const listAdventuresCards = ({
  entityId,
  adventureGroupId,
  adventureId
}) => {
  return client.get(
    `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}`
  );
};

export const listAdventuresCardEditor = ({
  entityId,
  adventureGroupId,
  adventureId,
  adventureCardId
}) => {
  return client.get(
    `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${adventureCardId}`
  );
};

export const getAdventureMediaList = ({
  entityId,
  adventureGroupId,
  adventureId,
  adventureCardId
}) => {
  return client.get(
    `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${adventureCardId}/mediafiles`
  );
};

export const getGrades = ({ entityId }) => {
  return client.get(`entity/${entityId}/adventures/grades`);
};

export const createAdventure = ({ entityId, entityDraft, media }) => {
  const audioText = media && media.text_audio_url.files;
  const audioDescription = media && media.description_audio_url.files;
  const image = media && media.image_url.files;

  let formData = new FormData();
  formData.append("imagefile", image);
  formData.append("descriptionaudiofile", audioDescription);
  formData.append("textaudiofile", audioText);
  formData.append("text", JSON.stringify(entityDraft));

  console.log(entityDraft)

  return longTimeoutClient.post(`entity/${entityId}/adventures`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const updateAdventure = ({ entityId, entityDraft, id, media }) => {
  const audioText = media && media.text_audio_url.files;
  const audioDescription = media && media.description_audio_url.files;
  const image = media && media.image_url.files;

  let formData = new FormData();
  formData.append("imagefile", image);
  formData.append("descriptionaudiofile", audioDescription);
  formData.append("textaudiofile", audioText);
  formData.append("text", JSON.stringify(entityDraft));

  return longTimeoutClient.post(
    `entity/${entityId}/adventures/${id}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
};

export const updateAdventureJson = ({
  entityId,
  adventureGroupId,
  adventureId,
  id,
  entityDraft
}) => {
  return client.post(
    `/entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${id}`,
    entityDraft
  );
};

export const deleteAdventure = ({ entityId, id }) => {
  return client.delete(`entity/${entityId}/adventures/${id}`);
};

export const updateAdventureOrder = ({ entityId, list }) => {
  return client.post(`entity/${entityId}/adventures/update-order`, { list });
};

export const deleteAdventureCardMedia = ({
  entityId,
  adventureGroupId,
  adventureId,
  adventureCardId,
  mediaId
}) => {
  return client.delete(
    `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${adventureCardId}/mediafile/${mediaId}`
  );
};

export const deleteAdventureCardMediaSection = ({
  entityId,
  adventureGroupId,
  adventureId,
  adventureCardId,
  mediaType
}) => {
  return client.delete(
    `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${adventureCardId}/mediafiles/${mediaType}`
  );
};

export const getPublishedList = ({ entityId, list }) =>
  client.post(`/entity/${entityId}/adventures/publish-list`, { list });

export const publishNodes = ({ entityId, list }) =>
  client.post(`/entity/${entityId}/adventures/publish`, { list });

export const requestReview = ({ entityId, list }) =>
  client.post(`entity/${entityId}/adventures/request-review`, { list });

export const reviewNodes = ({ entityId, list }) =>
  client.post(`entity/${entityId}/adventures/set-reviewed`, { list });

export const rejectNodes = ({ entityId, list }) =>
  client.post(`entity/${entityId}/adventures/reject`, { list });

export const unpublishNodes = ({ entityId, list }) =>
  client.post(`entity/${entityId}/adventures/retract`, { node_id: list });
export const publishAdventure = ({ entityId, nodeId }) => {
  return client.post(`/entity/${entityId}/adventures/publish/${nodeId}`);
};

export const uploadMediaFiles = (
  file,
  entityId,
  adventureGroupId,
  adventureId,
  adventureCardId
) => {
  let formData = new FormData();
  formData.append("file", file);

  return longTimeoutClient
    .post(
      `entity/${entityId}/adventuregroup/${adventureGroupId}/adventure/${adventureId}/adventurecard/${adventureCardId}/mediafile`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    )
    .then(response => response)
    .catch(error => error);
};

export const getMarkers = ({ entityId }) => {
  return client.get(`entity/${entityId}/markers`);
};

export const getMarkersReady = ({ entityId }) => {
  return client.get(`entity/${entityId}/markers/ready`);
};

export const getColorThemes = ({ entityId }) => {
  return client.get(`/entity/${entityId}/colorthemes`);
};

export const updateMediaFile = ({ entityId, id, file }) => {
  let formData = new FormData();
  formData.append("file", file);

  return longTimeoutClient
    .post(`entity/${entityId}/markergroup/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(response => response)
    .catch(error => error);
};

export const updateMarkerItem = ({ entityId, id, file }) => {
  let formData = new FormData();
  formData.append("file", file);

  return longTimeoutClient
    .post(`entity/${entityId}/item/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(response => response)
    .catch(error => error);
};

export const updateMarkerColorTheme = ({ entityId, id, data }) => {
  return client.put(`/entity/${entityId}/markergroup/${id}`, data);
};

export const publishMarkers = entityId => {
  return client.post(`/entity/${entityId}/markers/publish`);
};

export const uploadCollectionMarker = ({entityId, marker}) => {
  let formData = new FormData();
  formData.append("collectionmarkerfile", marker);

  return longTimeoutClient.post(
    `entity/${entityId}/adventuregroup/collection-marker`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
}