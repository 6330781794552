import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getClassroomgroupsWhereProductExist } from "../store/selectors";
import Link from "../../shared/components/Link";

const ClassroomGroupInfoWrapper = styled.div`
  margin-bottom: 2rem;
`;
const StyledListWrapper = styled.div`
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

const StyledLink = styled.div`
  ${props => props.theme.d100()};
  margin-top: 0;
  font-weight: normal;
  pointer-events: none;
  color: ${props => props.theme.themePrimary};
  margin-bottom: 0.2rem;
`;

const ClassroomGroups = () => {
  const groups = useSelector(getClassroomgroupsWhereProductExist);

  return (
    <ClassroomGroupInfoWrapper>
      <div>Produkten finns i dessa klassrumsgrupper:</div>
      {groups.length <= 0 && (
        <StyledListWrapper>
          <i>-</i>
        </StyledListWrapper>
      )}
      <StyledListWrapper>
        {groups.map(g => (
          <StyledLink key={g.id}>
            <Link to={`/klassrum/grupp/${g.id}`}>{g.title}</Link>
          </StyledLink>
        ))}
      </StyledListWrapper>
    </ClassroomGroupInfoWrapper>
  );
};

export default ClassroomGroups;
