import React from "react";

const ArrowCircleLeft = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-arrowcircleleft"
  >
    <path
      fill={color || "currentColor"}
      d="M25,50C11.2,50,0,38.8,0,25S11.2,0,25,0s25,11.2,25,25S38.8,50,25,50z M36.7,20.6H25v-7.1c0-1.1-1.3-1.6-2.1-0.9L11.4,24.1
	c-0.5,0.5-0.5,1.2,0,1.7l11.5,11.6c0.8,0.8,2.1,0.2,2.1-0.9v-7.1h11.7c0.7,0,1.2-0.5,1.2-1.2v-6.5C37.9,21.1,37.4,20.6,36.7,20.6z"
    />
  </svg>
);

export default ArrowCircleLeft;
