import {
  convertFromRaw,
  convertToRaw,
  getUsedComponents,
  preparePostData
} from "../../components/Editor";
import { Map } from "immutable";
import { getUsedResources } from "../../components/Editor/EditorData";
import { MARKETMESSAGE, OPMESSAGE, PRODUCTNEWS } from "../../../constants";
import {
  createAudioPlugin,
  createBlanksPlugin,
  createImagePlugin,
  createJSONEditorPlugin,
  createMultipleChoicePlugin,
  createSortMachinePlugin,
  createNumberLinePlugin,
  createTextPlugin,
  createMathWordPlugin,
  createAboutPlugin,
  createHelpVideoPlugin,
  createHelpAudioPlugin,
  createHelpImagePlugin,
  createTipsPlugin,
  createOrderPlugin,
  createLineupPlugin,
  createChartPlugin,
  createFractionPlugin
} from "../../../plugins";
import { getUsedMediaFilesFromDataString } from "../../../shared/services/media";
import { formatISOZeroTime } from "../../../date";

export const getPluginsArray = () => [
  createJSONEditorPlugin(),
  createTextPlugin(),
  createImagePlugin(),
  createAudioPlugin(),
  createMultipleChoicePlugin(),
  createSortMachinePlugin(),
  createOrderPlugin(),
  createLineupPlugin(),
  createChartPlugin(),
  createFractionPlugin(),
  createBlanksPlugin(),
  createNumberLinePlugin()
];

export const getHelpPluginsArray = () => [
  createMathWordPlugin(),
  createAboutPlugin(),
  createHelpVideoPlugin(),
  createHelpAudioPlugin(),
  createHelpImagePlugin(),
  createTipsPlugin()
];

export const isMessageEntity = entityName => {
  return (
    entityName.toLowerCase() === OPMESSAGE ||
    entityName.toLowerCase() === PRODUCTNEWS ||
    entityName.toLowerCase() === MARKETMESSAGE
  );
};

export const getDefaultMessageObj = () =>
  JSON.stringify([
    {
      type: "title",
      children: [
        {
          text: ""
        }
      ]
    }
  ]);

export const getDefaultOpMessageObj = () => 
  JSON.stringify([
    {
      "type": "paragraph",
      "children": [
        {
          "text": ""
        }
      ]
    }
  ])

export const getMessageMetadataObj = () =>
  JSON.stringify({
    startTime: formatISOZeroTime(),
    endTime: formatISOZeroTime(),
    alert: false,
    products: [],
    series: [],
    image: {},
    actionCaption: "",
    actionURL: ""
  });

export const getDefaultMetaDataObj = type => {
  switch (type) {
    case OPMESSAGE:
    case PRODUCTNEWS:
    case MARKETMESSAGE:
      return getMessageMetadataObj();
    default:
      return "{}";
  }
};

export const getDefaultDataObj = type => {
  switch (type) {
    case OPMESSAGE:
      return getDefaultOpMessageObj();
    case PRODUCTNEWS:
    case MARKETMESSAGE:
      return getDefaultMessageObj();
    default:
      return getDefaultBlockArrObj();
  }
};

/**
 * Returns string of default block array
 * @returns {string}
 */
export const getDefaultBlockArrObj = () =>
  JSON.stringify({
    data: [],
    type: "blockArray"
  });

/**
 * Applies a empty help object
 * @param obj
 * @returns {{data: {help: *}}}
 */
export const applyHelpObject = obj => ({
  data: {
    ...obj.data,
    help:
      obj.data.help && JSON.parse(obj.data.help) !== null
        ? obj.data.help
        : getDefaultBlockArrObj()
  }
});

/**
 * Gets post data
 * @param post
 * @returns {*}
 */
export const getPostData = post => {
  let data = null;
  try {
    data = post && JSON.parse(post);
    let templateData = {};

    if (data && data.entityMap) {
      // Merge template and post for old data-structure
      const template = post.template;
      if (template) {
        templateData = JSON.parse(template.data);
      }
      const entityMap = Object.assign(
        {},
        templateData.entityMap,
        data.entityMap
      );
      data = {
        entityMap,
        blocks: Object.assign([], templateData.blocks, data.blocks)
      };
    }
  } catch (e) {
    console.log(e);
    return null;
  }

  return convertFromRaw(data);
};

/**
 * Gets resources
 * @param res
 * @returns {T | {}}
 */
export const getResources = res =>
  (res || []).reduce(
    (acc, resource) => ({
      ...acc,
      [resource.id]: resource
    }),
    {}
  );

/**
 * Prepares post draft
 * @param data
 * @param id
 * @param entity_id
 * @param difficulty
 * @param tags
 * @param status
 * @param resources
 * @param title
 * @returns {Immutable.Map}
 */
export const prepareEntityDraft = ({
  data: {
    id,
    entity_id,
    data,
    help,
    difficulty,
    tags,
    chips,
    status,
    resources,
    title
  }
}) => {
  return new Map({
    id,
    entity_id,
    post: preparePostData(getPostData(data)),
    help: preparePostData(getPostData(help)),
    resources: getResources(resources),
    difficulty,
    tags,
    chips,
    status,
    title
  });
};

export const prepareMessageDraft = ({
  data: { id, entity_id, data, chips, tags, status, title, metadata }
}) => {
  return new Map({
    id,
    entity_id,
    post: JSON.parse(data),
    help: null,
    resources: null,
    difficulty: null,
    tags,
    chips,
    status,
    title,
    metadata: JSON.parse(metadata)
  });
};

/**
 * Creates a savable object from an entity draft object
 * @param postDraft
 */
export const createSaveableMessageObjectFromDraft = postDraft => {
  return createSaveableMessageObject(
    postDraft.get("id"),
    postDraft.get("entity_id"),
    postDraft.get("title"),
    postDraft.get("post"),
    postDraft.get("help"),
    postDraft.get("metadata"),
    postDraft.get("chips")
  );
};

export const createSaveableObjectFromEntityDraft = postDraft => {
  return createSaveableObject(
    postDraft.get("id"),
    postDraft.get("entity_id"),
    postDraft.get("title"),
    postDraft.get("difficulty"),
    postDraft.get("resources"),
    postDraft.get("post"),
    postDraft.get("help")
  );
};

/**
 * Creates a savable object
 * @returns {{
 *  id: number,
 *  entityId: number,
 *  title: string,
 *  data: string,
 *  help: string,
 *  difficulty: number,
 *  resource_ids: Array<number>,
 *  media_urls: Array<string>,
 *  components: Array<string>
 * }}
 */
export const createSaveableObject = (
  id,
  entityId,
  title,
  difficulty,
  resources,
  postData,
  postHelp,
) => {
  const distinct = (value, index, self) => self.indexOf(value) === index;

  const plugins = [...getPluginsArray(), ...getHelpPluginsArray()];

  const post_resources = getUsedResources(plugins, postData);
  const help_resources = getUsedResources(plugins, postHelp);
  const resource_ids = [...post_resources, ...help_resources].filter(distinct);

  const post_components = getUsedComponents(postData, resources);
  const help_components = getUsedComponents(postHelp, resources);
  const components = [...post_components, ...help_components].filter(distinct);

  const data = JSON.stringify(convertToRaw(postData));
  const help = JSON.stringify(convertToRaw(postHelp));

  const postMediaURLs = getUsedMediaFilesFromDataString(data);
  const helpMediaURLs = getUsedMediaFilesFromDataString(help);
  const media_urls = [...postMediaURLs, ...helpMediaURLs].filter(distinct);

  return {
    id,
    entityId,
    title,
    data,
    help,
    difficulty,
    resource_ids,
    media_urls,
    components
  };
};

export const createSaveableMessageObject = (
  id,
  entityId,
  title,
  postData,
  postHelp,
  meta,
  chips
) => {
  const data = JSON.stringify(postData);
  const help = postHelp;
  const metadata = JSON.stringify(meta);
  const media_urls = meta.image && meta.image.url ? [meta.image.url]: []
  return {
    id,
    entityId,
    title,
    data,
    chips,
    help,
    metadata,
    media_urls
  };
};

export const makeAddRemoveObject = (newArr, oldArr, post_ids) => ({
    add: {
      chip_ids: newArr.filter(n => !oldArr.some(oldItem => oldItem === n)),
      post_ids: post_ids
    },
    remove: {
      chip_ids: oldArr.filter(o => !newArr.some(newItem => newItem === o)),
      post_ids: post_ids
    }
});
