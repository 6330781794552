import React from "react";
import { PageHeader } from "../../../shared";
import PostList from "../../containers/PostList";
import { ENTITIES, AIDA } from "../../../constants";

const AidaList = () => (
  <React.Fragment>
    <PageHeader studlicon="Aida" title="Aida" />
    <PostList type={ENTITIES[AIDA]} />
  </React.Fragment>
);

export default AidaList;
