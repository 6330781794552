import styled from "styled-components";

const MenuList = styled.ul`
  color: ${props => props.theme.neutralPrimary};
  box-sizing: border-box;
  list-style: none;
  margin-top: 0;
  padding: 8px 0;
  list-style-type: none;
  line-height: 24px;
  transform: scale(1);
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform;
`;

export default MenuList;
