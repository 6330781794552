import React from "react";
import styled from "styled-components";
import MessageEditor from "../../containers/MessageEditor";
import { ENTITIES, PRODUCTNEWS } from "../../../constants";

const StyledMessageWrapper = styled.div`
  padding: 1.5rem;
`;

const ProductNewsDetails = () => {
  return (
    <StyledMessageWrapper>
      <MessageEditor type={ENTITIES[PRODUCTNEWS]} />
    </StyledMessageWrapper>
  );
};

export default ProductNewsDetails;

