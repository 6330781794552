import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../shared";
import ProgressBar from "../../../shared/components/ProgressBar";
import FileUploadProgressInvalidFiles from "./FileUploadProgressInvalidFiles";

export const StyledUploadContainer = styled.div`
  background: rgba(240, 240, 240);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  align-self: center;
  border: 1px solid;
  padding: 20px 0px 40px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 30;
`;

export const StyledHeaderTitle = styled.div`
  margin: 20px auto;
  font-weight: bold;
`;

export const StyledUploadDescriptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  margin: auto;
  width: 80%;
`;

export const StyledLabels = styled.span`
  margin: 0px 10px 10px 0px;
`;

export const StyledProgressBarContainer = styled.span`
  margin: 10px 10px 10px 0px;
`;

export const StyledCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledTitle = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  flex: 1;
`;

const FileUploadProgress = ({ progress, fileCount, failed, endUploading }) => {
  return (
    <StyledUploadContainer>
      <StyledHeaderTitle>{"Uppladdning av filer"}</StyledHeaderTitle>
      <StyledUploadDescriptions>
        <StyledLabels>{`Antal filer att ladda upp: ${fileCount}`}</StyledLabels>
        <StyledProgressBarContainer>
          <StyledTitle>
            {progress === 100 ? "Klart" : "Laddar upp filer..."}
          </StyledTitle>
          <ProgressBar percentage={progress} />
        </StyledProgressBarContainer>
        {progress === 100 && failed.length !== 0 && (
          <FileUploadProgressInvalidFiles failed={failed} />
        )}
      </StyledUploadDescriptions>
      <StyledCloseButtonContainer>
        <PrimaryButton onClick={endUploading}>STÄNG</PrimaryButton>
      </StyledCloseButtonContainer>
    </StyledUploadContainer>
  );
};

export default FileUploadProgress;
