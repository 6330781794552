import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import { FETCH_LIMIT, DIFFICULTY } from "./constants";
import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_DETAILS_SUCCESS,
  FETCH_ENTITY_COMMENTS,
  FETCH_ENTITY_COMMENTS_SUCCESS,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  GOTO_PAGE,
  START_FETCH,
  FETCH_FILTER_SUCCESS,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  RESET_POST_BUFFER,
  RESET_POST_BUFFER_INDEX,
  MULTISVG_SET_DATA,
  MULTISVG_SET_FORMAT,
  MULTISVG_UPDATE_DRAFT,
  MULTISVG_EDITOR_UPDATE_SETTINGS,
  MULTISVG_UPDATE_TITLE,
  SET_POST_TMPDATA,
  SET_POST_DESCRIPTION,
  CLEAR_POST_TMPDATA,
  SET_MEDIA_DRAFT
} from "../../api";
import { actionCreator } from "../../shared";
import { MEDIA_RESOURCE } from "../../constants";
import { ROUTE_MEDIARESOURCE_EDIT } from "../routes";

export const requiredFields = {
  selectedItem: {},
  comments: [],
  page: 1,
  buffer: {},
  list: [],
  filters: [],
  labels: [],
  tags: [],
  args: {},
  total: 0,
  fetchLimit: FETCH_LIMIT,
  difficulty: DIFFICULTY,
  fetching: false,
  searchParameters: {},
  multiSVG: {
    iconMap: [],
    iconDraft: [],
    title: "",
    currentFormat: "16:9"
  },
  multiSVGEditor: {
    gridspace: 10,
    alignWorkspace: false
  },
  newDataTmp: {
    hasDataSaved: false,
    tags: [],
    comments: []
  },
  description: "",
  draftState: "clean"
};

const reducer = entityName => initialState => {
  const mergedState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(mergedState, {
    [actionCreator(entityName, START_FETCH)](state) {
      return state.set("fetching", true).set("selectedItem", null);
    },
    [actionCreator(entityName, END_FETCH)](state) {
      return state.set("fetching", false);
    },
    [actionCreator(entityName, FETCH_ENTITIES)](state, action) {
      return state.withMutations(st => {
        st.set("searchParameters", Map(action.searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [actionCreator(entityName, FETCH_ENTITIES_SUCCESS)](state, action) {
      const parsedData = action.entities.map(entity => {
        const data = JSON.parse(entity && entity.data);
        let icons = [];
        let format = "16:9";

        if (data && data.data) {
          const d = data.data;
          icons = d.icons || d.Icons || icons;
          format = d.format || d.Format || format;
        }

        const iconsObject = icons.map(icon => {
          const x = icon.X || icon.x;
          const y = icon.Y || icon.y;
          const size = icon.Size || icon.size;
          const src = icon.AssetPath || (icon.image && icon.image.src);

          return {
            x: x,
            y: y,
            size: size,
            image: {
              src: src
            },
            AssetId: icon.AssetId
          };
        });

        const dataObject = {
          data: {
            icons: iconsObject,
            format: format
          },
          type: "multiimage"
        };

        const actions = {
          editLink: {
            type: ROUTE_MEDIARESOURCE_EDIT,
            postType: MEDIA_RESOURCE,
            payload: {
              entityId: entity.entity_id,
              id: entity.id
            }
          }
        };

        return { ...entity, data: dataObject, actions: actions };
      });

      const newData = { ...action, entities: parsedData };

      state = state.update("total", () => newData.metadata.total);
      if (newData.batchIndex === state.get("page")) {
        state = state.update("list", list => List(newData.entities));
      }
      return state.update("buffer", buffer =>
        buffer.set(newData.batchIndex, List(newData.entities))
      );
    },
    [actionCreator(entityName, FETCH_ENTITY_DETAILS_SUCCESS)](state, action) {
      const data = JSON.parse(
        action.entity && action.entity.data && action.entity.data.data
      );
      let icons = [];
      let format = "16:9";
      if (data && data.data) {
        const d = data.data;
        icons = d.icons || d.Icons || icons;
        format = d.format || d.Format || format;
      }
      const draft = icons.map((icon, index) => {
        icon.selected = false;
        icon.id = "icon_" + index;
        return icon;
      });

      const entityData = action.entity && action.entity.data;
      const title = entityData.title;
      const description = entityData.description;

      return state
        .setIn(["multiSVG", "iconMap"], fromJS(icons))
        .setIn(["multiSVG", "iconDraft"], fromJS(draft))
        .setIn(["multiSVG", "currentFormat"], fromJS(format))
        .set("selectedItem", Map(action.entity))
        .set("description", description)
        .setIn(["multiSVG", "title"], title)
        .update("args", args => args.merge(action.args));
    },
    [actionCreator(entityName, FETCH_ENTITY_COMMENTS)](state, action) {
      return state.set("comments", Map());
    },
    [actionCreator(entityName, FETCH_ENTITY_COMMENTS_SUCCESS)](state, action) {
      return state.set("comments", Map(action.entity));
    },
    [actionCreator(entityName, SET_MEDIA_DRAFT)](state, action) {
      return state.set("draftState", action.draftSate);
    },
    [actionCreator(entityName, GOTO_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => action.page);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, NEXT_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s + 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, PREVIOUS_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s - 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, FETCH_FILTER_SUCCESS)](state, action) {
      const tags = action.filter
        .filter(item => {
          return item.key === "tags";
        })
        .map(tag => {
          const sub = tag.data.map(item => ({
            title: item.title,
            key: "tags",
            component: tag.component,
            open: tag.open,
            data: [item],
            tags: item.tags,
            filterkey: tag.filterkey
          }));
          return sub;
        });

      return state
        .set("filters", List(action.filter))
        .set("labels", List(action.labels))
        .set("tags", tags[0]);
    },
    [actionCreator(entityName, RESET_BEFORE_FILTER_SORT_CHANGE)](
      state,
      action
    ) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", s => 1);
        st.update("buffer", () => new Map());
      });
    },
    [actionCreator(entityName, RESET_POST_BUFFER)](state, action) {
      return state.withMutations(st => {
        st.update("buffer", () => new Map());
      });
    },
    [actionCreator(entityName, RESET_POST_BUFFER_INDEX)](state, action) {
      return state.withMutations(st => {
        let batchIndex = action.batchIndex;
        if (batchIndex === undefined) {
          batchIndex = st.get("page");
        }
        st.update("buffer", buffer => buffer.set(batchIndex, null));
      });
    },
    [actionCreator(entityName, MULTISVG_SET_DATA)](state, action) {
      return state.setIn(["multiSVG", "iconMap"], fromJS(action.icons));
    },
    [actionCreator(entityName, MULTISVG_SET_FORMAT)](state, action) {
      return state.setIn(["multiSVG", "currentFormat"], action.format);
    },
    [actionCreator(entityName, MULTISVG_UPDATE_DRAFT)](state, action) {
      return state.setIn(["multiSVG", "iconDraft"], action.newdraft);
    },
    [actionCreator(entityName, MULTISVG_EDITOR_UPDATE_SETTINGS)](
      state,
      action
    ) {
      return state.set("multiSVGEditor", action.settings);
    },
    [actionCreator(entityName, MULTISVG_UPDATE_TITLE)](state, action) {
      return state.setIn(["multiSVG", "title"], action.title);
    },
    [actionCreator(entityName, SET_POST_TMPDATA)](state, action) {
      const newdata = action.data.set("hasDataSaved", true);
      return state.set("newDataTmp", newdata);
    },
    [actionCreator(entityName, SET_POST_DESCRIPTION)](state, action) {
      return state.set("description", action.description);
    },
    [actionCreator(entityName, CLEAR_POST_TMPDATA)](state, action) {
      const newdata = {
        hasDataSaved: false,
        difficulty: null,
        tags: List(),
        category: null,
        comments: List()
      };
      return state.set("newDataTmp", Map(newdata));
    }
  });
};

export default reducer;
