import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentRouteAndTitle } from "../../../shared/store/navigation/selectors";
import styled from "styled-components";

const StyledTitle = styled.div`
  padding: 20px;
  font-size: 18px;
`

const Empty = () => {
  const { title } = useSelector(selectCurrentRouteAndTitle)
  // Temporary view for menupages that don't exists yet.
  return (
    <React.Fragment>
      <StyledTitle>{title}</StyledTitle>
    </React.Fragment>
  );
};

export default Empty;
