import React from "react";
import MessagePostList from "../../containers/MessagePostList";
import { ENTITIES, PRODUCTNEWS } from "../../../constants";
import styled from "styled-components";
import AddMessage from "../../components/AddMessage";

const StyledMessageHeader = styled.div`
  padding: 20px 15px;
  margin-bottom: 3rem;
`;

const ProductNews = () => {
  return (
    <React.Fragment>
      <StyledMessageHeader>
        <AddMessage type={PRODUCTNEWS} />
      </StyledMessageHeader>
      <MessagePostList postType={ENTITIES[PRODUCTNEWS]} />
    </React.Fragment>
  );
};

export default ProductNews;
