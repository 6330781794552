import React from "react";

const Bib = ({ size, color }) => (
  <svg
    viewBox="0 0 20 20"
    width={`${size}px`}
    fill={color}
    className="studlicon-bib"
  >
  <path d="M12,11.55 C9.64,9.35 6.48,8 3,8 L3,19 C6.48,19 9.64,20.35 12,22.55 C14.36,20.36 17.52,19 21,19 L21,8 C17.52,8 14.36,9.35 12,11.55 Z M12,8 C13.66,8 15,6.66 15,5 C15,3.34 13.66,2 12,2 C10.34,2 9,3.34 9,5 C9,6.66 10.34,8 12,8 Z" id="path-1"></path>
  
</svg>
);

export default Bib;
