/**
 * Fetches unsaved removed nodes ID's
 * @param state
 * @returns {any | * | Object}
 */
export const selectRemovedTreeIds = state =>
  state.Structure.getIn(["treeDraft", "removedIds"]).toJS();

/**
 * Fetches the tree draft from the state
 * @param state
 * @returns {any | * | Object}
 */
export const selectTreeDraft = state =>
  state.Structure.getIn(["treeDraft", "tree"]).toJS();

/**
 * Fetches the original, unedited tree from the state
 * @param state
 */
export const selectOriginalTree = state => state.Structure.get("list").toJS();

/**
 * Fetches replication arrays from the state
 * @param state
 */
export const selectReplicationArrays = state =>
  state.Structure.get("replication");
