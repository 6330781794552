import * as React from "react";
import {
  StyledBlockSidebar,
  StyledSidebarSection,
  StyledSidebarHeading
} from "../../../../posts/components/Editor/BlockSidebar/StyledBlockSidebar";
import PluginHoc from "../../../../shared/PluginHoc";
import {
  StyledHelpWrapper,
  StyledHelpContainer,
  StyledImageWrapper,
  StyledTextArea
} from "../StyledHelp";
import Text from "../../../../shared/components/Text/Text";

/**
 * This component is also used by the Tips plugin
 */
const HelpImage = ({
  updateData,
  data,
  settings,
  pluginHeader,
  storeDraft,
  draftTarget
}) => {
  /**
   *  Render text area
   */
  const renderTextEditor = () => (
    <StyledTextArea>
      <Text
        updateData={updateData}
        data={data}
        storeDraft={storeDraft}
        draftTarget={draftTarget}
        settings={settings}
      />
    </StyledTextArea>
  );

  /**
   * Render sidebar
   */
  const renderSidebar = () => (
    <StyledBlockSidebar maxWidth={"200px"}>
      <StyledSidebarHeading backgroundColor={"#72A5AF"}>
        Inställningar
      </StyledSidebarHeading>
      <StyledSidebarSection>{/* SIDEBAR CONTENT\ */}</StyledSidebarSection>
    </StyledBlockSidebar>
  );

  return (
    <StyledHelpWrapper>
      <StyledHelpContainer>
        {pluginHeader}

        <StyledImageWrapper></StyledImageWrapper>
        {renderTextEditor()}
      </StyledHelpContainer>
      {renderSidebar()}
    </StyledHelpWrapper>
  );
};

export default PluginHoc({
  Component: HelpImage
});
