import styled from 'styled-components';

export const StyledTopWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledHeadingContainer = styled.div`
  margin-bottom: 10px;

  h1 {
    font-size: 32px;
    color: #277575;
    line-height: 1;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;
  padding: 12px;
`
export const StyledContainer = styled.div`
  flex: 1;
  height: auto;
  padding: 0 12px 12px 12px;
  max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : 'none'};

  textarea {
    width: 100%;
    min-height: 600px;
    height: 100%;
  }
`

export const StyledText = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #277575;
  letter-spacing: 0.24px;
  line-height: 30px;
  margin: 20px 0;
`;

export const StyledSaveResetButtonsContainer = styled.div`
  position: fixed;
  bottom: 10px;
  padding: 10;
  background-color: #eaeaea;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 2;
`;

export const StyledJsonTitle = styled.span`
  font-weight: 600;
  font-size: 32px;
  color: #277575;
  line-height: normal;
  margin-bottom: 10px;
`;

export const StyledUploadButtonWrapper = styled.div`
  display: flex;
`;

export const StyledUploadContainer = styled.div`
  background: rgba(240, 240, 240);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  align-self: center;
  border: 1px solid;
  padding: 20px 0px 40px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 30;
`;

export const StyledHeaderTitle = styled.div`
  margin: 20px auto;
  font-weight: bold;
`;

export const StyledUploadDescriptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  margin: auto;
  width: 80%;
`;

export const StyledLabels = styled.span`
  margin: 0px 10px 10px 0px;
`;

export const StyledProgressBarContainer = styled.span`
  margin: 10px 10px 10px 0px;
`;

export const StyledTitle = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  flex: 1;
`;

export const StyledInvalidFilesContainer = styled.span`
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0px;
  padding: 10px;
`;

export const StyledInvalidRowWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

export const StyledTitlesWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

export const StyledInvalidLabel = styled.span`
  font-size: 11px;
  flex: 1;
`;

export const StyledCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

