import React from "react";

const Checkmark = ({ size, color }) => (
  <svg
    viewBox="0 0 10 13"
    width={`${size}px`}
    fill={color}
    className="studlicon-checkmark"
  >
  <path d="M.604 6.07C4.908 15.086 2.24 13.052 8.72.122" stroke="#FFFBFB" fill="none" fillRule="evenodd"/>
</svg>
);

export default Checkmark;
