import React from "react";

const Minus = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
  >
    <path
      fill={"currentColor"}
      d="M2.34,30.47A2.35,2.35,0,0,1,0,28.13V21.87a2.35,2.35,0,0,1,2.34-2.34H47.66A2.35,2.35,0,0,1,50,21.87v6.26a2.35,2.35,0,0,1-2.34,2.34Z"
    />
  </svg>
);

export default Minus;
