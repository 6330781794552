import React from "react";
import { connect } from "react-redux";

import ButtonGroup from "../../../shared/components/ButtonGroup";
import { StandardButton } from "../../../shared";
import { PrimaryButton } from "../../../shared";
import Link from "../../../shared/components/Link";
import TagPost from "../../components/TagPost";
import ChipPost from "../../components/ChipPost";
import DifficultyPost from "../../components/TagPost/DifficultyPost";
import CategoryAction from "../../components/CategoryAction";
import PublishPost from "./PublishPost";
import DeletePosts from "./DeletePosts";
import PaginationAction from "../ToolBar/ListPagination";
import styled from "styled-components";
import AidaPreview from "../AidaPreview";
import { PREVIEW_URL } from "../../api/constants";

import { ENTITIES, EXERCISE, CREATE } from "../../../constants";

import HasPermission, { getSection } from "../../../shared/Permissions";
import Tooltip from "react-tooltip-lite";
import { selectEntityId } from "../../../store/selectors";

const mapStateToProps = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[EXERCISE];
  }

  return {
    pathname: state.location.pathname,
    postType: postType,
    selectedPosts: state.Carousel.items,
    activePost: state.Carousel.active,
    entityId: selectEntityId(state)
  };
};

const ActionGroup = styled.div`
  display: flex;
  width: 100%;
`;

const SelectedPosts = styled.div`
  margin: 0px 3px;
  padding: 13px;
`;

export const ActionTop = connect(mapStateToProps)(
  ({ selectedPosts, activePost, postType, pathname }) => {
    const posts = selectedPosts.length;

    let Preview = null;
    let Category = null;
    let Publish = null;
    let Chips = null;
    let Label = null;
    let postTypeIcon = null;

    if (postType === "Exercise") {
      const postIds = selectedPosts.map(p => p.id);
      Preview = (
        <Tooltip content="Preview" useDefaultStyles>
          <a
            target="_blank"
            href={PREVIEW_URL + postIds.join(",")}
            rel="noopener noreferrer"
          >
            <StandardButton studlicon="Preview"></StandardButton>
          </a>
        </Tooltip>
      );
      Publish = <PublishPost />;
      Category = <CategoryAction />;
      Label = <TagPost content={["Labels"]} />;
      Chips = <ChipPost postType={postType} title="Exempelövning"/>
      postTypeIcon = "PostAdd";
    }

    if (postType === "Aida") {
      const postIds = selectedPosts.map(p => p.id);
      if (selectedPosts.length === 1) {
        Preview = (
          <Tooltip content="Preview" useDefaultStyles>
            <AidaPreview id={postIds[0]} btnType="postAction" />
          </Tooltip>
        );
      }
      Publish = <PublishPost />;
      Chips = <ChipPost postType={postType} title="Nyckelord"/>
      postTypeIcon = "PostAdd";
    }

    if (postType === "Resource") {
      postTypeIcon = "ResourceAdd";
    }

    if (postType === "Helpresource") {
      postTypeIcon = "HelpResourceAdd";
    }

    const CreateNew = (
      <Link to={pathname + "/new"}>
        <PrimaryButton outline studlicon={postTypeIcon}>
          Skapa ny
        </PrimaryButton>
      </Link>
    );

    const RenderDifficultyOption = () =>
      postType === ENTITIES[EXERCISE] ? (
        <DifficultyPost content={["Difficulty"]} />
      ) : null;

    let EditLink = null;
    if (selectedPosts.length > 0) {
      const postIds = selectedPosts.map(p => p.id);
      const editLink = activePost.actions.editLink;
      const link = {
        ...editLink,
        payload: { ...editLink.payload, query: { c: postIds } }
      };
      EditLink = (
        <Link to={link}>
          <StandardButton>Redigera markerade</StandardButton>
        </Link>
      );
    }

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <ActionGroup style={{ flex: 1, width: "100%" }}>
          <ButtonGroup>
            <HasPermission
              component={CreateNew}
              section={getSection(postType)}
              permission={CREATE}
            />
          </ButtonGroup>
        </ActionGroup>
        {selectedPosts.length > 0 ? (
          <ActionGroup
            style={{ flex: 5, width: "100%", justifyContent: "flex-end" }}
          >
            <SelectedPosts>
              {" "}
              <b>{posts} </b> markerade{" "}
            </SelectedPosts>
            <ButtonGroup>
              {EditLink}
              {Preview}
              <DeletePosts postType={postType} />
              {Publish}
              {Label}
              {Chips}
              {RenderDifficultyOption()}
              {Category}
            </ButtonGroup>
          </ActionGroup>
        ) : null}
      </div>
    );
  }
);

export const Pagination = connect(mapStateToProps)(() => <PaginationAction />);
