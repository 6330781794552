import React from "react"
import { ListContainer, ListItem } from "./ListItem";
import { connect } from "react-redux";
import {
  getList,
  isLoading,
  getPreviousRoute
} from "../store/selectors";
import {
  StyledAdventureWrapper,
  StyledList,
  StyledPanelButtonsWrapper,
  StyledSaveResetButtonsContainer,
  StyledFormLabel,
  StyledBackLinkContainer,
  StyledLoaderWrapper,
  StyledBackLink,
  StyledMetadataImageContainer,
  StyledMetadataContainer,
  StyledMetadataLabelContainer,
  StyledInputContainer,
  StyledEditInputWrapper
} from "./StyledList";
import { PrimaryButton, StandardButton } from "../../shared";
import ButtonGroup from "../../shared/components/ButtonGroup";
import {
  setEntityDraft,
  updateEntityDraft,
  resetEntityDraft,
  createEntityDraft,
} from "../api/actions";
import {
  deleteEntity,
  publishEntity,
  rejectEntity,
  requestReviewEntity,
  reviewEntity,
  saveEntity,
  unpublishEntity,
  updateOrder,
  getPublishList,
  resetPublishList,
  updateCollectionMarker
} from "../sagas/actions";
import {
  selectAdventureGroupId,
  selectAdventureId
} from "../sagas/selectors";
import { nodeData, emptyListItem } from "./ListHelpers";
import Link from "../../shared/components/Link";
import { Loader } from "../../shared/components/Loader/Loader";
import HasPermission from "../../shared/Permissions";
import { ADVENTURES_GROUP, ADVENTURES, CREATE } from "../../constants";
import { selectEntityId } from "../../store/selectors";
import UploadMedia from "../../shared/components/UploadMedia";
import Input from "../../shared/components/Input";

const List = (props) => {

  const [mediaData, setMediaData] = React.useState({
    text_audio_url: {
      name: "", files: {}
    },
    description_audio_url: {
      name: "", files: {}
    },
    image_url: {
      name: "", files: {}
    },
    collection_image_url: {
      name: "", files: {}
    }
  })

  /**
   * Callback for removing an item
   * @param {*} item 
   */
  const onRemoveItem = (id) => props.onDeleteEntityDraft(id)

  /**
   * Function that runs after items has been moved.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const list = [...props.list];
    const oldWeight = list.map(obj => ({ ...obj }));
    list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
    const updatedWeight = list.map((item, index) => ({ ...item, weight: (index + 1) * 100 }))

    let sendUpdateOrderRequest = false;
    oldWeight.forEach((e, index) => {
      if (e.id !== updatedWeight[index].id) {
        sendUpdateOrderRequest = true;
      }
    });
    if (sendUpdateOrderRequest) {
      props.onUpdateOrder(updatedWeight)
    }
  };

  /**
   * Updates the entityDraft
   * @param {*} data 
   * @param {*} validation 
   */
  const updateEntityDraftCallback = (data, validation) => props.onUpdateEntityDraft(data, validation)

  /**
   * Callback for reverting an item
   */
  const revert = () => props.onResetEntityDraft()

  /**
   * Callback for saving an item
   */
  const save = () => {
    props.onSave(mediaData)
    setMediaData({
      text_audio_url: {
        name: "", files: {}
      },
      description_audio_url: {
        name: "", files: {}
      },
      image_url: {
        name: "", files: {}
      }
    })
  }

  /**
   * Render create item button
   */
  const renderCreateButton = () => {
    const createNew = (
      <ButtonGroup>
        <PrimaryButton
          disabled={props.draftState === 'dirty'}
          onClick={onCreateItem}>
          {`Skapa ${props.title}`}
        </PrimaryButton>
      </ButtonGroup>
    )

    return (
      <HasPermission
        component={createNew}
        section={ADVENTURES.toLowerCase()}
        permission={CREATE} />
    )
  }

  /**
   * Render button that navigates back to previous view
   */
  const renderBackLink = () => (props.prevUrl &&
    <StyledBackLinkContainer>
      <Link to={props.prevUrl}>
        <StyledBackLink margin="0 0 0 12px" >TILLBAKA</StyledBackLink>
      </Link>
    </StyledBackLinkContainer>
  )

  /**
   * Alert for creating an item
   */
  const onCreateItem = () => {
    const alert = window.prompt("Titel:");
    if (alert) {
      const node = nodeData(props.type, props.adventureGroupID, props.adventureID)
      const object = { ...emptyListItem, parent_id: node.parent_id, node_type: node.node_type, title: alert, collection_marker_url: props.collectionMarkerUrl }
      props.adventureGroupID && delete object.grade_ids
      props.onCreateEntityDraft(object)
    }
  }

  /**
   * Renders label if list is empty
   */
  const renderEmptyListLabel = () => (
    <StyledFormLabel>{`Inga ${props.title.toLowerCase()} hittades. Börja med att skapa nya ${props.title.toLowerCase()}.`}</StyledFormLabel>
  )

  /**
   * Renders loader while fetching is true
   */
  const renderLoader = () => (
    <StyledLoaderWrapper>
      <Loader dotColor="black" />
    </StyledLoaderWrapper>
  )

  /**
   * Renders Buttongroup for "revert" & "save"
   * @param {*} draftState 
   */
  const renderSaveResetButtons = (draftState, saving) => (
    (draftState === "dirty" && !saving) && (
      <StyledSaveResetButtonsContainer>
        <ButtonGroup>
          <StandardButton onClick={revert}>Återställ</StandardButton>
          <PrimaryButton onClick={save} >Spara</PrimaryButton>
        </ButtonGroup>
      </StyledSaveResetButtonsContainer>
    )
  )

  /**
   * Renders items for lineup
   */
  const renderListItems = () => (
    <ListContainer useDragHandle onSortEnd={onSortEnd}>
      {(props.list && props.list.size > 0) ? props.list.map((item, i) => (
        <ListItem
          key={`item-${i}`}
          index={i}
          itemData={item}
          itemTitle={props.title}
          grades={props.grades}
          itemIndex={i}
          disabled={!props.draggable}
          useDragHandle={props.draggable}
          draftState={props.draftState}
          setEntityDraft={props.onSetEntityDraft}
          updateEntityDraft={updateEntityDraftCallback}
          entityDraft={props.entityDraft}
          onGetPublishList={props.onGetPublishList}
          onResetPublishList={props.onResetPublishList}
          onPublish={props.onPublish}
          onUnPublish={props.onUnPublish}
          onReview={props.onReview}
          onRequestReview={props.onRequestReview}
          onReject={props.onReject}
          onRemoveItem={onRemoveItem}
          mediaData={mediaData}
          setMediaData={setMediaData}
          type={props.type}
          workflow={props.workflowMap.adventures}
          readyForPublish={props.readyForPublish}
          markerGroups={props.markerGroups}
          entityId={props.entityId}
          draftValid={props.draftValid}
          lastItem={props.list.size - 1 === i}
        />
      ))
        : renderEmptyListLabel()}
    </ListContainer>
  );

  const collectionMarkerUpload = ({ files, type }) => {
    props.onCollectionMarkerUpload(files)
    setMediaData({ ...mediaData, [type]: { name: files.name, files } });
  };

  /**
 * Create an url from file
 * @param {*} event
 * @param {*} file
 */
  const addLocalUrl = (event, file) => {
    if (file instanceof File) {
      event.target.src = URL.createObjectURL(file);
    }
  };

  return (
    <StyledAdventureWrapper>
      <StyledPanelButtonsWrapper>
        {renderCreateButton()}
        {renderBackLink()}
      </StyledPanelButtonsWrapper>
      {props.type === ADVENTURES_GROUP && (
        <StyledMetadataContainer>
          <StyledMetadataLabelContainer>
            <StyledFormLabel>Samlingsmärke</StyledFormLabel>
          </StyledMetadataLabelContainer>
          <StyledInputContainer>
            <StyledEditInputWrapper>
              <Input
                disabled
                placeholder="Ladda upp bild"
                value={props.collectionMarkerUrl}
              />
            </StyledEditInputWrapper>
          </StyledInputContainer>
          <UploadMedia
            multiple={false}
            type="collection_marker_url"
            skin="primary"
            buttonstate="selected"
            studlicon="Media"
            callback={collectionMarkerUpload}
          />
          <StyledMetadataImageContainer>
            <img
              src={props.collectionMarkerUrl}
              alt=""
              onError={event => addLocalUrl(event, mediaData.collection_image_url.files)}
            />
          </StyledMetadataImageContainer>
        </StyledMetadataContainer>
      )}
      {props.loading && renderLoader()}
      <StyledList>
        {!props.loading && renderListItems()}
        {renderSaveResetButtons(props.draftState, props.saving)}
      </StyledList>
    </StyledAdventureWrapper>
  )
}

const mapStateToProps = (state, { type }) => {
  return {
    prevUrl: getPreviousRoute(state, type),
    list: getList(state, type),
    loading: isLoading(state, type),
    grades: state[type].get('grades'),
    draftState: state[type].get("draftState"),
    saving: state[type].get("saving"),
    draftValid: state[type].get("draftValid"),
    entityDraft: state[type].get("entityDraft"),
    adventureGroupID: selectAdventureGroupId(state),
    adventureID: selectAdventureId(state),
    workflowMap: state.Product.get("workflowMap"),
    readyForPublish: state[type].get("readyForPublish"),
    markerGroups: state[type].get("markerGroups"),
    entityId: selectEntityId(state),
    collectionMarkerUrl: state[type].get("collectionMarkerUrl")
  };
};

const mapDispatchToProps = (dispatch, { type }) => ({
  onCreateEntityDraft: item => dispatch(createEntityDraft(type, item)),
  onSetEntityDraft: item => dispatch(setEntityDraft(type, item)),
  onUpdateEntityDraft: (item, validation) => dispatch(updateEntityDraft(type, item, validation)),
  onResetEntityDraft: item => dispatch(resetEntityDraft(type, item)),
  onDeleteEntityDraft: id => dispatch(deleteEntity(type, id)),
  onUpdateOrder: list => dispatch(updateOrder(type, list)),
  onSave: (media) => dispatch(saveEntity(type, media)),
  onPublish: id => dispatch(publishEntity(type, id)),
  onUnPublish: id => dispatch(unpublishEntity(type, id)),
  onReview: id => dispatch(reviewEntity(type, id)),
  onRequestReview: id => dispatch(requestReviewEntity(type, id)),
  onReject: id => dispatch(rejectEntity(type, id)),
  onGetPublishList: id => dispatch(getPublishList(type, id)),
  onResetPublishList: () => dispatch(resetPublishList(type)),
  onCollectionMarkerUpload: (marker) => dispatch(updateCollectionMarker(type, marker))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);