import React from "react";

const Cloud = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-cloud"
  >
    <path
      d="M44.6640625,24.15625 C44.8828125,23.3828125 45,22.578125 45,21.75 C45,16.9140625 41.0859375,13 36.25,13 C34.9453125,13 33.6796875,13.28125 32.5,13.84375 C30.03125,10.328125 25.9140625,8 21.25,8 C13.875,8 7.8359375,13.8203125 7.515625,21.140625 C3.0625,22.703125 0,26.9296875 0,31.75 C0,37.96875 5.03125,43 11.25,43 L40,43 C45.5234375,43 50,38.53125 50,33 C50,29.328125 47.984375,25.90625 44.6640625,24.15625 Z M40,40.5 L11.25,40.5 C6.4140625,40.5 2.5,36.5859375 2.5,31.75 C2.5,27.3125 5.796875,23.6484375 10.078125,23.078125 C10.0234375,22.640625 10,22.1953125 10,21.75 C10,15.5390625 15.0390625,10.5 21.25,10.5 C25.9609375,10.5 29.9921875,13.390625 31.671875,17.5 C32.8125,16.2734375 34.4375,15.5 36.25,15.5 C39.703125,15.5 42.5,18.296875 42.5,21.75 C42.5,23.1953125 42.0078125,24.53125 41.1796875,25.59375 C44.765625,26.15625 47.5,29.2578125 47.5,33 C47.5,37.140625 44.140625,40.5 40,40.5 Z"
      id="path-1"
    />
  </svg>
);

export default Cloud;
