import React from "react";
import Tooltip from "react-tooltip-lite";
import { CornerDownRight } from "react-feather";

import {
  StyledPathOfStructureTreeContainer,
  StyledSortItemBody,
  StyledTooltipBranch,
  StyledTooltipTree
} from "./StyledTooltipTree";

const TooltipTree = ({ flatTreeData, categoryRef }) => {
  /**
   * Constructs an array of the path the goal has in the StructureTree
   * @param id
   * @param arr
   * @returns {*[]}
   */
  const getPath = (id, arr) => {
    const node = arr.find(n => n.id === id);

    if (node.parent_id === "0") {
      return [node];
    }

    return [node, ...getPath(node.parent_id, arr)];
  };

  const path = getPath(categoryRef, flatTreeData);

  /**
   * Concantinates an array to a long string
   * @param id
   * @returns {string}
   */
  const renderStringPath = id => path.map(({ title }) => title).join("/");

  /**
   * Renders tool tip tree
   * @returns {*}
   */
  const renderToolTipTree = () => {
    return <StyledTooltipTree>{renderToolTipBranch()}</StyledTooltipTree>;
  };

  /**
   * Renders corner down right arrow
   * @param index
   * @returns {null}
   */
  const renderArrow = index =>
    index ? <CornerDownRight color="#fff" size={14} /> : null;

  /**
   * Renders tooltip branch
   * @returns {any[]}
   */
  const renderToolTipBranch = () => {
    return path.reverse().map((pathPart, index) => {
      return (
        <StyledTooltipBranch index={index} key={path[index].id}>
          {renderArrow(index)}
          {pathPart.title}
        </StyledTooltipBranch>
      );
    });
  };

  /**
   * New requirements about not having the tooltip.
   * Instead of removing it we simply pass a condition
   * indicating if we want to use it or not.
   *
   * @param condition
   * @returns {*}
   */
  const returnWithToolTip = condition =>
    condition ? (
      <StyledSortItemBody>
        <Tooltip
          content={renderToolTipTree()}
          useDefaultStyles={true}
          tipContentHover={false}
        >
          <StyledPathOfStructureTreeContainer>
            {renderStringPath(categoryRef)}
          </StyledPathOfStructureTreeContainer>
        </Tooltip>
      </StyledSortItemBody>
    ) : (
      <StyledSortItemBody>
        <StyledPathOfStructureTreeContainer>
          {renderStringPath(categoryRef)}
        </StyledPathOfStructureTreeContainer>
      </StyledSortItemBody>
    );

  return returnWithToolTip(false);
};

export default TooltipTree;
