import {
  FILE_NOT_PUBLISHED,
  FILE_PUBLISHED_TO_TEST,
  FILE_PUBLISHED_LIVE,
  FILE_MODIFIED_IN_TEST
} from "../../constants";

export const FILE_STATUS = [
  { id: FILE_NOT_PUBLISHED, title: "Opublicerad" },
  { id: FILE_PUBLISHED_TO_TEST, title: "Publicerad till test" },
  { id: FILE_PUBLISHED_LIVE, title: "Publicerad live" },
  { id: FILE_MODIFIED_IN_TEST, title: "Ändrad i test" },
];

export const FILE_STATUS_COLOR = {
  [FILE_NOT_PUBLISHED] : "lightGray",
  [FILE_PUBLISHED_TO_TEST] : "yellow",
  [FILE_PUBLISHED_LIVE] : "green",
  [FILE_MODIFIED_IN_TEST] : "orange"
}
