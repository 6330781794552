/**
 *
 * @param {*} trees an array of chipstrees. All nodes has an id
 * @returns an object of all parentnode id:s as key and an array of all node id:s of nodes below.
 */

function useFindPathsForChips(trees, chips = []) {
  const traverseTreeForPaths = cTrees => {
    const foundChips = [];
    cTrees.forEach(t => findPathForNodes(t, [t.title], foundChips));
    return foundChips;
  };

  const findPathForNodes = (node, path, foundChips) => {
    if (node.sub_sections.length > 0) {
      node.sub_sections.forEach(s => {
        findPathForNodes(s, [...path, s.title], foundChips);
      });
    }
    if (node.chips.length > 0 && chips) {
      const cIds = node.chips.filter(c => chips.indexOf(c.id) >= 0);
      if (cIds.length > 0) {
        foundChips.push({
          path: path,
          tags: cIds
        });
      }
    }
  };

  const arr = trees && trees.length > 0 ? traverseTreeForPaths(trees) : [];

  return {
    groups: arr
  };
}

export default useFindPathsForChips;
