import { client, longTimeoutClient } from "../../api";

/**
 * Fetches the goals
 * @param entityId
 */
export const getGoals = entityId => client.get(`entity/${entityId}/goals`);

/**
 * Fetches the structure
 * @param entityId
 */
export const getStructure = entityId =>
  client.get(`entity/${entityId}/category`);

/**
 * Saves the structure go
 * @param entityId
 * @param data
 */
export const saveStructure = (entityId, data) =>
  longTimeoutClient.post(`entity/${entityId}/category/all`, data);

/**
 * Publish structure
 * @param entityId
 */
export const publishStructure = entityId =>
  client.post(`entity/${entityId}/graph/export`);

/**
 * Creates a new category
 * @param entityId
 * @param data
 */
export const createCategory = (entityId, data) =>
  client.post(`entity/${entityId}/category`, data);

/**
 * Updates a category
 * @param entityId
 * @param catId
 * @param data
 */
export const updateCategory = (entityId, catId, data) =>
  client.put(`entity/${entityId}/category/${catId}`, data);

/**
 * Saves the order of goals
 * @param entityId
 * @param data
 */
export const saveGoalsOrder = (entityId, data) =>
  client.post(`entity/${entityId}/goals`, data);

/**
 * Save changes for stage in a goal
 * @param entityId
 * @param ref
 * @param data
 */
export const saveStageChanges = (entityId, ref, data) =>
  client.post(`entity/${entityId}/goal/${ref}`, data);
