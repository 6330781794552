import { Transforms, Node, Element as SlateElement } from "slate";
import { ENTITIES, OPMESSAGE } from "../../../constants";

const withMessageLayout = (editor, type) => {
  const { normalizeNode } = editor;

  const enforceType = (elType, nodeChild, path) => {
    if (SlateElement.isElement(nodeChild) && nodeChild.type !== elType) {
      Transforms.setNodes(editor, { type: elType }, { at: path });
    }
  };

  const enforceNoTitleType = ( nodeChild, path) => {
    if (SlateElement.isElement(nodeChild) && nodeChild.type === "title") {
      Transforms.setNodes(
        editor,
        { type: "paragraph" },
        { at: path }
      );
    }
  };

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const title = {
          type: "title",
          children: [{ text: "" }]
        };
        Transforms.insertNodes(editor, title, { at: path.concat(0) });
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        const slateIndex = childPath[0];

        switch (slateIndex) {
          case 0:
            type === ENTITIES[OPMESSAGE] ? enforceNoTitleType(child, childPath) : enforceType("title", child, childPath);
            break;
          case 1:
            enforceNoTitleType(child, childPath);
            break;
          default:
            break;
        }
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};

export default withMessageLayout;
