import Audio from "./components/Audio";
import { AUDIO } from "../constants";
import { getMediaObject } from "./dataModels";

export const createAudioPlugin = () => ({
  entityRendererFn: ({ type, data, settings }) => {
    const types = [AUDIO];

    if (types.includes(type)) {
      return {
        component: Audio,
        props: {
          data,
          settings
        }
      };
    }
    return null;
  }
});

export default createAudioPlugin;

export const emptyBlocks = {
  audio: {
    type: AUDIO,
    data: {
      [AUDIO]: getMediaObject(AUDIO)
    }
  }
};
