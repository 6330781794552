import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "./Button";
import Portal from "./Portal";

const Container = styled.div`
  position: absolute;
  z-index: 10;
  background: #fff;
  padding: 1.25rem 1rem 1rem 1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 491px;
  border-radius: 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.75rem;
  margin-left: auto;

  & > button {
    margin: 0;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
  }

  & > button:last-child {
    margin-left: 1rem;
  }
`;

const Confirm = ({ onClose, children, confirmBtnText }) => (
  <Portal rootId="modals">
    <Container>
      <div>{children}</div>
      <Buttons>
        <PrimaryButton type="button" onClick={() => onClose(false)}>
          Avbryt
        </PrimaryButton>
        <PrimaryButton type="button" onClick={() => onClose(true)}>
          {confirmBtnText}
        </PrimaryButton>
      </Buttons>
    </Container>
  </Portal>
);

export default Confirm;
