import styled from "styled-components";
import PrimaryButton from "../../../shared/components/Button/Primary"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const StyledNavigationAndContent = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const StyledContent = styled.main`
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
`;

export const StyledTopBarContainer = styled.header`
  position: relative;
  width: 100%;
  min-height: 48px;
  z-index: 3;
`;

export const StyledSideBarContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  font-weight: bold;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`

export const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
`
