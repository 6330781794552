import React from "react";

const ArrowCircleRight = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-arrowcircleright"
  >
    <path
      fill={color || "currentColor"}
      d="M25,0c13.8,0,25,11.2,25,25S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z M13.3,29.4H25v7.1c0,1.1,1.3,1.6,2.1,0.9l11.5-11.6
	    c0.5-0.5,0.5-1.2,0-1.7L27.1,12.6c-0.8-0.8-2.1-0.2-2.1,0.9v7.1H13.3c-0.7,0-1.2,0.5-1.2,1.2v6.5C12.1,28.9,12.6,29.4,13.3,29.4z"
    />
  </svg>
);

export default ArrowCircleRight;
