import * as React from "react";
import { TextEditor } from "./TextEditor";
import { TextParser } from "../../../shared/TextTransformerService";
import { DATA, TEXT } from "../../../constants";
import { StyledTextEditorContainer } from "./StyledText";

const Text = ({
  updateData,
  data,
  settings,
  draftTarget,
  storeDraft,
  callbackUpdatedItem,
  updatedItem,
  keyType = TEXT,
  target = DATA
}) => {
  const textHandler = new TextParser();
  const textEditorState = textHandler.getStateFromData(data.text);
  const {
    multiLine,
    readOnly,
    useToolbar,
    useShortToolbar,
    useInlineToolbar
  } = settings;

  /**
   *  Updates text
   * @param editorState
   * @returns {*}
   */
  const onTextChange = editorState => {
    const { didChange, text } = textHandler.onTextChange(
      editorState,
      textEditorState
    );

    let currentText = textHandler.getRaw(editorState);
    let change = true;

    if (text) {
      change = text === currentText;
    }

    if (didChange && change) {
      storeDraft(draftTarget);
      updateData(keyType, text, target);
    }
  };

  return (
    <StyledTextEditorContainer>
      <TextEditor
        onChange={onTextChange}
        callbackUpdatedItem={callbackUpdatedItem}
        updatedItem={updatedItem}
        editorState={textEditorState}
        readOnly={readOnly}
        oneLine={!multiLine}
        data={data}
        useToolbar={useToolbar}
        useShortToolbar={useShortToolbar}
        useInlineToolbar={useInlineToolbar}
      />
    </StyledTextEditorContainer>
  );
};

export default Text;
