import { fromJS } from "immutable";
import { createReducer } from "../../../store/utils";

import { UPDATE_MODAL, OPEN_MODAL, CLOSE_MODAL, OPEN_CONFIRM_MODAL, CLOSE_CONFIRM_MODAL, UPDATE_SHADOW_CLOSE } from "./actions";

const defaultProps = {
  isModalOpen: false,
  children: null,
  header: null,
  actions: null,
  hideFilters: [],
  disableShadowClose: false,
  isConfirmModalOpen: false,
  canLeave: null,
  modalMsg: ""
};

const initialState = fromJS(defaultProps);

export default createReducer(initialState, {
  [OPEN_MODAL](state, { ...props }) {
    props = { ...defaultProps, ...props, isModalOpen: true };
    Object.keys(props).forEach(key => (state = state.set(key, props[key])));
    return state;
  },
  [CLOSE_MODAL](state) {
    return state.set("isModalOpen", false).set("disableShadowClose", false);
  },
  [UPDATE_MODAL](state, { ...props }) {
    props = { ...props, isModalOpen: true };
    Object.keys(props).forEach(key => (state = state.set(key, props[key])));
    return state;
  },
  [UPDATE_SHADOW_CLOSE](state, payload) {
    return state.set("disableShadowClose", payload.payload)
  },  
  [OPEN_CONFIRM_MODAL](state, payload) {
    return state.set("isConfirmModalOpen", true)
      .set("modalMsg", payload.msg)
      .set("canLeave", payload.action);
  },
  [CLOSE_CONFIRM_MODAL](state) {
  return state.set("isConfirmModalOpen", false);
  }
});
