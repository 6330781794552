import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { DataTable, withActions, TextColumn } from "../../../shared";
import ProductLinkColumn from "./Columns/ProductLinkColumn";
import FileStatusColumn from "./Columns/FileStatusColumn";
import { getProductDataForList } from "../../store/selectors";
import { sortProducts } from "../../api/actions";
import { activateProduct } from "../../../products/api/actions";
import ProductListFilters from "../ProductListFilters";
import ProductListExportButton from "../ProductListExportButton";
import PaginationWithLimitChange from "../../components/PaginationWithLimitChange/PaginationWithLimitChange";

const ActionDataTable = withActions(DataTable);

const StyledDataTable = styled(ActionDataTable)`
  height: calc(100% - 112px);
  ${props => (props.loading ? "filter: blur(5px);" : null)};
  ${props => (props.loading ? "pointer-events: none;" : null)};
`;

const SimpleProductList = () => {
  const products = useSelector(getProductDataForList);
  const dispatch = useDispatch();

  const activateProductSelection = product => {
    dispatch(activateProduct(product));
  };

  const list = products.map(p => ({
    ...p,
    onClickProduct: activateProductSelection
  }));

  const _onSort = sort => {
    dispatch(sortProducts(sort));
  };

  return (
    <StyledDataTable
      list={list}
      actionsRight={PaginationWithLimitChange}
      actionsLeft={ProductListFilters}
      actionsMid={ProductListExportButton}
      onSort={_onSort}
    >
      <ProductLinkColumn dataKey="sesam_name" label="Sesamnamn" width={0.3} />
      <TextColumn dataKey="typeId" label="Produkttyp" width={0.3} />
      <FileStatusColumn dataKey="status" label="Status" width={0.3} />
    </StyledDataTable>
  );
};

export default SimpleProductList;
