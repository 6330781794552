import React from "react";
import PostEditor from "../../containers/PostEditor";
import { ENTITIES, AIDA } from "../../../constants";

const AidaDetails = () => (
  <React.Fragment>
    <PostEditor type={ENTITIES[AIDA]}/>
  </React.Fragment>
);

export default AidaDetails;
