import { fromJS, List, Map } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  END_FETCH,
  START_FETCH,
  FETCH_MEDIAFILTER_SUCCESS,
  RESET_BEFORE_FILTER_SORT_CHANGE,
  FETCH_MEDIA_ENTITIES_SUCCESS,
  FETCH_MEDIA_ENTITY_DETAILS_SUCCESS,
  FETCH_MEDIA_ENTITIES,
  NEXT_MEDIA_PAGE,
  GOTO_MEDIA_PAGE,
  PREVIOUS_MEDIA_PAGE
} from "../../api";

import { FETCH_MEDIA_LIMIT } from "./constants";
import { actionCreator } from "../../shared";

export const requiredFields = {
  selectedItem: {},
  page: 1,
  buffer: {},
  list: [],
  filters: [],
  labels: [],
  tags: [],
  args: {},
  total: 0,
  meta: {},
  fetching: false,
  fetchLimit: FETCH_MEDIA_LIMIT,
  searchParameters: {}
};

const reducer = entityName => initialState => {
  const mergedState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(mergedState, {
    [actionCreator(entityName, START_FETCH)](state) {
      return state.set("fetching", true).set("selectedItem", null);
    },
    [actionCreator(entityName, END_FETCH)](state) {
      return state.set("fetching", false);
    },
    [actionCreator(entityName, FETCH_MEDIA_ENTITIES)](state, action) {
      return state.withMutations(st => {
        st.set("searchParameters", Map(action.searchParameters)).update(
          "args",
          args => args.merge(action.args)
        );
      });
    },
    [actionCreator(entityName, FETCH_MEDIA_ENTITIES_SUCCESS)](state, action) {
      state = state.update("total", () => action.metadata.total);
      state = state.update("meta", () => action.metadata);
      if (action.batchIndex === state.get("page")) {
        state = state.update("list", list => new List(action.entities));
      }
      return state.update("buffer", buffer =>
        buffer.set(action.batchIndex, List(action.entities))
      );
    },
    [actionCreator(entityName, FETCH_MEDIA_ENTITY_DETAILS_SUCCESS)](
      state,
      action
    ) {
      return state
        .set("selectedItem", Map(action.entity.data))
        .update("args", args => args.merge(action.args));
    },
    [actionCreator(entityName, NEXT_MEDIA_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s + 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, PREVIOUS_MEDIA_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => s - 1);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => new List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, GOTO_MEDIA_PAGE)](state, action) {
      return state.withMutations(st => {
        st.update("page", s => action.page);
        const page = st.get("page");
        const bufferedList = st.getIn(["buffer", page]);
        if (bufferedList) {
          st.update("list", list => List(bufferedList));
        }
      });
    },
    [actionCreator(entityName, FETCH_MEDIAFILTER_SUCCESS)](state, action) {
      const tags = action.filter
        .filter(item => {
          return item.key === "tags";
        })
        .map(tag => {
          const sub = tag.data.map(item => ({
            title: item.title,
            key: "tags",
            component: tag.component,
            open: tag.open,
            data: [item],
            tags: item.tags,
            filterkey: tag.filterkey,
            entity_id: item.entity_id
          }));
          return sub;
        });

      return state
        .set("filters", List(action.filter))
        .set("labels", List(action.labels))
        .set("tags", tags[0]);
    },
    [actionCreator(entityName, RESET_BEFORE_FILTER_SORT_CHANGE)](
      state,
      action
    ) {
      return state.withMutations(st => {
        st.setIn(["searchParameters", "offset"], 0);
        st.update("page", s => 1);
        st.update("buffer", () => new Map());
      });
    }
  });
};

export default reducer;
