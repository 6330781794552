import React from "react";
import { useSelector } from "react-redux";
import Link from "../../../shared/components/Link";
import { PrimaryButton } from "../../../shared/components/Button";
import styled from "styled-components";
import { selectPathName } from "../../store/selectors";
import HasPermission from "../../../shared/Permissions";
import { CREATE, ENTITIES, SERIES } from "../../../constants";

const StyledAddSeriesWrapper = styled.div`
  padding: 20px 15px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0;
`;

const AddSeries = () => {
  const pathname = useSelector(selectPathName);

  return (
    <StyledAddSeriesWrapper>
      <React.Fragment>
        <HasPermission
          component={
            <Link to={pathname + "/detail/new"}>
              <StyledPrimaryButton>Skapa ny serie</StyledPrimaryButton>
            </Link>
          }
          section={SERIES}
          permission={CREATE}
          type={ENTITIES[SERIES]}
        />
      </React.Fragment>
    </StyledAddSeriesWrapper>
  );
};

export default AddSeries;
