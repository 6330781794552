import React from "react";
import { connect } from "react-redux";

import ServiceBar, { BarSection } from "../../../shared/components/ServiceBar";
import Tag from "../../../shared/components/Tag";
import Avatar from "../../../shared/components/Avatar";
import TagPost from "../../components/TagPost";
import ChipPost from "../../components/ChipPost";
import Comments from "../../components/Comments";
import DifficultyPost from "../../components/TagPost/DifficultyPost";
import CategoryAction from "../../components/CategoryAction";
import Link from "../../../shared/components/Link";
import { isBefore, startOfYesterday } from "date-fns";

import {
  StyledNoWrapBold,
  StyledBarSectionContentTitle,
  StyledBarSectionUserWrapper,
  StyledBarSectionContentUsername,
  StyledBarSectionContentDate,
  StyledUsedByLabel
} from "./StyledServiceBar";

import {
  ENTITIES,
  EXERCISE,
  RESOURCE,
  AIDA,
  MEDIA_RESOURCE,
  HELP_RESOURCE,
  PUBLISHED,
  MODIFIED,
  DRAFT,
  DRAFT_READY_FOR_REVIEW,
  REVISION_READY_FOR_REVIEW,
  DRAFT_REVIEWED,
  REVISION_REVIEWED,
  UNPUBLISHED
} from "../../../constants";
import StatusPost from "../../components/TagPost/StatusPost";
import {
  green,
  lightGray,
  darkBlue,
  darkGray,
  darkOrange,
  black,
  orange,
  blue
} from "../../../styles/shared/colors";
import { STATUS } from "../../api/constants";
import Description from "../../components/Description";
import { distanceInWordsToNow, format } from "../../../date";
import { modalClose, modalOpen, modalUpdate } from "../../../shared";
import { WATCH_UNLOCK_POST } from "../../sagas/Locks/actions";
import { actionSavePostBeforeLeave } from "../../store/actions";
import { handlePrompt } from "./utils";

/**
 * Formats the datestring to YYYY-MM-DD:hh:mm
 * @param {*} dateString
 */
const formatDate = dateString => {
  const date = dateString ? new Date(dateString) : new Date();

  if (isBefore(date, startOfYesterday())) {
    return format(date);
  }

  const distance = distanceInWordsToNow(date);
  const savedNow = distance === "" && dateString !== undefined;

  if (savedNow) return "för en sekund sedan";
  return distance !== "" ? `för ${distance} sedan` : "";
};

const statusColor = {
  [PUBLISHED]: green,
  [MODIFIED]: darkGray,
  [DRAFT]: lightGray,
  [DRAFT_READY_FOR_REVIEW]: orange,
  [REVISION_READY_FOR_REVIEW]: darkOrange,
  [DRAFT_REVIEWED]: blue,
  [REVISION_REVIEWED]: darkBlue,
  [UNPUBLISHED]: black
};

class ServiceBarContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      tags: [],
      selectedTag: []
    };
  }

  render() {
    const { postType, noServiceBar, post } = this.props;
    const showComments = true;

    if (!post) {
      return null;
    }

    if (noServiceBar) {
      return null;
    }

    return (
      <ServiceBar>
        {this.renderMRSection()}

        {this.renderStatusSection()}

        {this.renderUsageSection()}

        {this.renderHistorySection()}

        {this.renderAssignToSection()}

        {this.renderLabelSection()}

        {this.renderChipsSection()}

        {this.renderDescriptionSection(postType)}

        {this.renderDifficultySection()}

        {this.renderCategoryAction()}

        {this.renderCommentSection(showComments)}
      </ServiceBar>
    );
  }

  /**
   * Render of MR content
   */
  renderMRSection = () => {
    const { post } = this.props;
    return (
      <BarSection title="MR temp stuff">
        <StyledBarSectionContentTitle>
          CampmatID:
          <StyledNoWrapBold>{post.campmat_id}</StyledNoWrapBold>
        </StyledBarSectionContentTitle>
        <StyledBarSectionContentTitle>
          Annat ID:
          <StyledNoWrapBold>{post.assignment_id}</StyledNoWrapBold>
        </StyledBarSectionContentTitle>
      </BarSection>
    );
  };

  renderUsageSection = () => {
    const { post, postType } = this.props;

    if (
      [RESOURCE, MEDIA_RESOURCE, HELP_RESOURCE].indexOf(
        postType.toLowerCase()
      ) < 0
    )
      return null;

    const resources = post.used_by_resources || [];
    const exercises = post.used_by_exercises || [];
    const helpresources = post.used_by_helpresources || [];

    const sections = {
      exercise: [
        ENTITIES[RESOURCE],
        ENTITIES[MEDIA_RESOURCE],
        ENTITIES[HELP_RESOURCE]
      ],
      resource: [ENTITIES[RESOURCE], ENTITIES[MEDIA_RESOURCE]],
      helpresource: [ENTITIES[HELP_RESOURCE], ENTITIES[MEDIA_RESOURCE]]
    };

    return (
      <BarSection title="Poster med denna resurs">
        <StyledBarSectionUserWrapper>
          {sections.exercise.indexOf(postType) >= 0 ? (
            <StyledUsedByLabel tagtype="exercise">
              <Link
                to={{
                  type: "ROUTE_EXERCISES",
                  payload: { entityId: post.entity_id },
                  query: { uses: post.id }
                }}
                onClick={() => handlePrompt(this.props, "ROUTE_EXERCISES")}
                studlicon="Posts"
              >
                {exercises.length}
              </Link>
            </StyledUsedByLabel>
          ) : null}
          {sections.resource.indexOf(postType) >= 0 ? (
            <StyledUsedByLabel tagtype="resource">
              <Link
                to={{
                  type: "ROUTE_RESOURCES",
                  payload: { entityId: post.entity_id },
                  query: { uses: post.id }
                }}
                onClick={() => handlePrompt(this.props, "ROUTE_RESOURCES")}
                studlicon="Resources"
              >
                {resources.length}
              </Link>
            </StyledUsedByLabel>
          ) : null}
          {sections.helpresource.indexOf(postType) >= 0 ? (
            <StyledUsedByLabel tagtype="helpresource">
              <Link
                to={{
                  type: "ROUTE_HELP_RESOURCES",
                  payload: { entityId: post.entity_id },
                  query: { uses: post.id }
                }}
                onClick={() => handlePrompt(this.props, "ROUTE_HELP_RESOURCES")}
                studlicon="HelpResource"
              >
                {helpresources.length}
              </Link>
            </StyledUsedByLabel>
          ) : null}
        </StyledBarSectionUserWrapper>
      </BarSection>
    );
  };

  /**
   * Render of the status section
   */
  renderStatusSection = () => {
    const {
      post: { status },
      postType
    } = this.props;

    if (
      [RESOURCE, MEDIA_RESOURCE, HELP_RESOURCE].indexOf(
        postType.toLowerCase()
      ) >= 0
    ) {
      return null;
    }

    const statusAction = (
      <StatusPost useCurrentPost={true} content={["Status"]} status={status} />
    );
    const statusObj = STATUS.find(item => item.id === status);
    const statusLabel = statusObj ? statusObj.title : null;

    return statusAction ? (
      <BarSection
        title={status ? "" : "Status"}
        primaryAction={status ? statusAction : null}
      >
        {status ? (
          <Tag
            variant={"warning"}
            label={statusLabel}
            labelColor={statusColor[status]}
          />
        ) : null}
      </BarSection>
    ) : null;
  };

  /**
   * Render of the history section
   */
  renderHistorySection = () => (
    <BarSection title="Historik">
      <StyledBarSectionUserWrapper>
        <StyledBarSectionContentUsername>
          {" "}
          Användare
        </StyledBarSectionContentUsername>
        <StyledBarSectionContentDate>
          ändrade {formatDate(this.props.post.updated_at)}
        </StyledBarSectionContentDate>
      </StyledBarSectionUserWrapper>
      <StyledBarSectionUserWrapper>
        <StyledBarSectionContentUsername>
          {" "}
          Användare
        </StyledBarSectionContentUsername>
        <StyledBarSectionContentDate>
          skapade {formatDate(this.props.post.created_at)}
        </StyledBarSectionContentDate>
      </StyledBarSectionUserWrapper>
    </BarSection>
  );

  /**
   * Render the assign to section
   */
  renderAssignToSection = () => (
    <BarSection title="Tilldelad">
      <Avatar>MD</Avatar>
    </BarSection>
  );

  /**
   * Render the action section for labels in posts
   */
  renderLabelSection = () => {
    const labelAction =
      this.props.postType !== ENTITIES[AIDA] && (
        <TagPost isDetailView useCurrentPost content={["Labels"]} />
      );
    return labelAction ? (
      <BarSection title="" primaryAction={labelAction}></BarSection>
    ) : null;
  };

  /**
   * Render the action section for chips in posts
   */
   renderChipsSection = () => {
    const { postType } = this.props;
    if ([AIDA, EXERCISE].indexOf(postType.toLowerCase()) < 0) {
      return null;
    }
    const chipTitle = postType.toLowerCase() === AIDA ? "Visas för" : "Används som";
    const chipsAction = (
      <ChipPost
        isDetailView
        useCurrentPost
        postType={postType}
        title={chipTitle}
      />
    );
    
    return chipsAction ? (
      <BarSection title="" primaryAction={chipsAction}></BarSection>
    ) : null;
  };

  /**
   * Render the description section
   */
  renderDescriptionSection = postType =>
    postType === "Mediaresource" ? (
      <BarSection title="Beskrivning">
        <Description />
      </BarSection>
    ) : null;

  /**
   * Render the action section for difficulty property of exercise posts
   */
  renderDifficultySection = () => {
    const difficultyAction =
      this.props.postType === ENTITIES[EXERCISE] ? (
        <DifficultyPost useCurrentPost={true} content={["Difficulty"]} />
      ) : null;

    return this.props.postType === ENTITIES[EXERCISE] ? (
      <BarSection title="" primaryAction={difficultyAction}></BarSection>
    ) : null;
  };

  /**
   * Render the action for category property on exercise posts
   */
  renderCategoryAction = () => {
    const categoryAction =
      this.props.postType === ENTITIES[EXERCISE] ? (
        <CategoryAction useCurrentPost={true} />
      ) : null;
    return categoryAction ? (
      <BarSection title="" primaryAction={categoryAction}></BarSection>
    ) : null;
  };

  /**
   * Render of comments section
   */
  renderCommentSection = showComments =>
    showComments ? (
      <BarSection title="Kommentarer">
        <Comments />
      </BarSection>
    ) : null;
}

const mapStateToProps = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType] || postType === "Series") {
    postType = ENTITIES[EXERCISE];
  }
  const postState = state[postType].get("selectedItem");
  const _post = postState && postState.get("data");

  return {
    postState,
    resources: state[postType].getIn(["postDraft", "resources"]),
    postDraft: state[postType].getIn(["postDraft", "post"]),
    postDraftState: state[postType].getIn(["postDraft", "postDraftState"]),
    title: state[postType].getIn(["postDraft", "title"]),
    helpDraft: state[postType].getIn(["postDraft", "help"]),
    modal: state.modal,
    post: _post,
    location: state.location,
    postType: postType,
    noServiceBar: state.location.routesMap[state.location.type].noServiceBar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    modalUpdate: modalUpdate(dispatch),
    modalOpen: modalOpen(dispatch),
    unlockPost: postId => dispatch({ type: WATCH_UNLOCK_POST, postId }),
    modalClose: () => modalClose(dispatch),
    savePostBeforeLeave: ({ id: postId, ...rest }) =>
      dispatch(actionSavePostBeforeLeave(rest, postId, ENTITIES[EXERCISE])),
    goToPosts: (route, entityId, queryObj) =>
      dispatch({
        type: route,
        payload: { entityId: entityId },
        query: queryObj
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceBarContainer);
