import styled from "styled-components";

export const InputField = styled.input.attrs({
  type: "text"
})`
  border: 2px solid #ddd;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  padding: 5px 10px;
  outline: none;
`;
