import styled from "styled-components";
import { PrimaryButton } from "../../components/Button";

export const StyledFilterContainer = styled.div`
  width: 240px;
  height: 100%;
  overflow: auto;
`;

export const StyledFilterButton = styled(PrimaryButton)`
  width: 100%;
  border-radius: 5px;
`;

export const StyledFilterButtonWrapper = styled.div`
  width: 100%;
  padding: 15px 10px;
`;
