import * as React from "react";
import {
  StyledValueInput,
  StyledValueInputWrapper,
  StyledInputContainer
} from "./StyledValueInput";

export const ValueInput = ({
  value,
  onChangeCallback,
  disabled,
  onEnterPress
}) => {
  return (
    <StyledValueInputWrapper>
      <StyledInputContainer>
        <StyledValueInput
          placeholder={"Ersätt värde"}
          disabled={disabled}
          onEnter={onEnterPress}
        />
      </StyledInputContainer>
    </StyledValueInputWrapper>
  );
};
