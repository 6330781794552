import React from "react";

const Exclamationmark = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 3 13"
    width={`${size}px`}
    className="studlicon-exclamationmark"
  >
    <text transform="translate(-995 -213)" fillRule="evenodd" fontFamily="Helvetica" fontSize="9" letterSpacing=".169">
        <tspan x="995" y="223">!</tspan>
    </text>
  </svg>
);

export default Exclamationmark;
