import styled from "styled-components";
import { COLUMN, ROW } from "../../../constants";
import { StandardButton } from "../../../shared";
import { getHeight, getWidth } from "./ChartHelpers";

export const StyledChartGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.rowLength}, auto);
  grid-template-rows: repeat(${props => props.rowLength}, auto);
  background-color: ${props => (props.showBorders ? "#979797" : "#fff")};

  grid-gap: ${props => (props.showBorders ? "0px" : "3px")};

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledMarkerButton = styled(props => (
  <StandardButton {...props} />
))`
  border: thin solid grey;
  background-color: lightgrey;
  border-radius: 0px;
  padding: 0;
  min-width: 0;
  height: auto;
  &:hover {
    background-color: darkgrey;
  }
  position: absolute;
  width: ${props => (props.direction === COLUMN ? "100%" : "20px")};
  height: ${props => (props.direction === COLUMN ? "20px" : "100%")};
  z-index: 2;
  ${props => (props.direction === COLUMN ? "top: -20px" : "left: -20px")};
`;

export const StyledAddButton = styled(props => <StandardButton {...props} />)`
  &:hover {
    svg {
      stroke: #21c82f;
    }
  }
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
  position: absolute;
  padding: 0;
  height: auto;
  min-width: auto;
  background-color: white;
  display: flex;
  ${props => props.direction === COLUMN && "width: 30px"};
  ${props => props.direction === ROW && "height: 30px"};
  ${props => props.direction === ROW && "align-items: center"};
  ${props => (props.direction === COLUMN ? "top: -35px" : "left: -35px")};
  ${props => (props.direction === COLUMN ? "right: -16px" : "bottom: -16px")};

  ${props =>
    props.position === "zero" && props.direction === ROW && "bottom: 36px;"};

  ${props =>
    props.position === "zero" && props.direction === COLUMN && "left: -16px;"};
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMarkerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${props => props.direction};
  height: ${props => getHeight(props.column)};
  width: ${props => getWidth(props.row)};
`;

export const StyledCellContainer = styled.div`
  position: relative;
  display: flex;
`;

export const StyledInteractiveSign = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
`;
