import { FRACTION } from "../constants";
import Fraction from "./components/Fraction/Fraction";
import {
  defaultFractionData,
  defaultFractionSettings
} from "./components/Fraction/FractionHelpers";

const types = [FRACTION];

export const createFractionPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: Fraction,
        style: { padding: 0 },
        props: {
          data: { ...defaultFractionData(), ...entity.data },
          settings: { ...defaultFractionSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types
});

export default createFractionPlugin;

export const emptyBlocks = {
  [FRACTION]: {
    type: FRACTION,
    data: defaultFractionData(),
    settings: defaultFractionSettings()
  }
};
