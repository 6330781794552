import { ENTITIES, EXERCISE } from "../../constants";
import { createSelector } from "reselect";
import { MEDIA_LIBS } from "./constants";
import { selectEntityId, selectEntityType } from "../../store/selectors";
import { getMediaRoute } from "../../shared/helpers";
import { getSystemsList } from "../../systems/store/selector";

/**
 * select list of posts of type 'type'
 * @param {*} state
 * @param {*} type
 */
export const selectList = (state, type = ENTITIES[EXERCISE]) => {
  if (!state[type]) {
    return [];
  }
  return state[type].get("list");
};

/**
 * select current selectedItem (detailpage). If in listview this is empty.
 * @param {*} state
 * @param {*} type
 */
export const selectCurrentMedia = (state, type = "Media") =>
  state[type].get("selectedItem");

/**
 * get List of labels
 * @param {*} state
 * @param {*} type
 */
export const selectLabels = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("labels");

/**
 * get List of tags
 * @param {*} state
 * @param {*} type
 */
export const selectTags = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("tags") || [];

/**
 * get List of tags for currentEntityId without global tags
 * @param {*} state
 * @param {*} type
 */
 export const selectTagsForCurrentEntity = (state, type = ENTITIES[EXERCISE]) => {
  const tags = state[type].get("tags") || [];
  const entityId = Number(state.location.payload.entityId);
  return tags.filter(f => 
    f.key === "tags" && f.entity_id === entityId
  );
 }
 

/**
 * get flatten List of tags
 * @param {*} state
 * @param {*} type
 */
export const selectFlattenTagsAndLabels = (state, type = "Media") => {
  const tags = selectTags(state, type);
  const labels = selectLabels(state, type).toJS();
  const tagList = tags.reduce((acc, g) => [...acc, ...g.tags], []);
  return [...labels, ...tagList];
};

/**
 * Select what type of post we are currently showing
 * @param {*} state
 */
export const selectCurrentPostType = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[EXERCISE];
  }
  return postType;
};

/**
 * Returns a list of selected posts.
 * @param {*} state
 * @param {*} type
 * @param {*} useCurrentPost true if on a detailview and want to use currentPost.
 */
export const selectSelectedMediaList = (
  state,
  useCurrentMedia,
  type = "Media"
) => {
  let selectedMediaList = [];

  if (!!useCurrentMedia) {
    const currentMedia = selectCurrentMedia(state, type).toJS();
    selectedMediaList = currentMedia ? [currentMedia] : [];
  } else {
    const medialist = selectList(state, type);
    const selectedMediaIds = state.MediaCarousel.items.map(p => p.id);
    selectedMediaList = medialist
      .filter(media => selectedMediaIds.indexOf(media.id) > -1)
      .toJS();
  }
  return selectedMediaList;
};

/**
 * Returns an object with postIds as key and an array of tagids as values
 * postIds is taken from selectedPostList
 * @param {*} selectedMediaList list of selected mediafiles
 * @return {object} ex {8: [1,2,3,4]}
 */
export const getTagsPerMedia = selectedMediaList =>
  selectedMediaList.reduce((a, media) => {
    const id = media.id !== null ? media.id : 0;
    a[id] = media.tags ? media.tags : [];
    return a;
  }, {});

/**
 * Returns an object with tagIds as key and an array of postIds who has that tag as value.
 * postIds is taken from selectedPostList
 * @param {*} selectedPostList
 * @return {object} ex {1: [8,6], 2: [8]}
 */
export const getMediaPerTag = selectedMediaList =>
  selectedMediaList.reduce((a, media) => {
    const mediaTags = media.tags ? media.tags : [];
    const mediaId = media.id !== null ? media.id : 0;
    mediaTags.map(
      tag =>
        (a[tag] =
          a[tag] !== undefined
            ? a[tag].indexOf(mediaId) < 0
              ? [...a[tag], mediaId]
              : a[tag]
            : [mediaId])
    );
    return a;
  }, {});

/**
 * Returns the type of medialib for the current route
 */
  export const selectCurrentMediaLib = createSelector(
    selectEntityType,
    selectEntityId,
    getSystemsList,
    ( entityType, entityId, systems) => {
      const mediaroute = getMediaRoute(entityId, entityType, systems);
      const r = MEDIA_LIBS.find(lib => lib.route === mediaroute);
      return r.name
    });
