import styled from "styled-components";
import { StandardButton } from "../Button";
import Input from "../Input";

export const PageView = styled.span`
  background-color: #00244f;
  border-radius: 5px;
  padding: 6px 20px;
  margin: 0 5px;
`;

export const PaginationInput = styled(Input)`
  font-size: 12px;
  color: #000;
  height: 20px;
  padding: 2px;
  border-radius: 0px;
  text-align: center;
`;

export const Text = styled.span`
  font-weight: normal;
  margin-left: auto;
  margin-right: 1em;
  font-size: 12px;
`;

export const InputText = styled.span`
  font-weight: normal;
  margin-left: auto;
  color: #fff;
  font-size: 12px;
`;

export const PaginationButton = styled(StandardButton)`
  background-color: #282828;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bolder;
  min-width: auto;
  &:hover {
    background-color: darkgrey;
  }
  &:active,
  &:focus {
    background-color: darkgrey;
    &:hover {
      background-color: darkgrey;
    }
  }
`;

export const PaginationButtonLoad = styled(PaginationButton)`
  background-color: #2881c9;
  &:hover {
    background-color: #00244f;
    color: #fff;
  }
  &:active,
  &:focus {
    background-color: #00244f;
    color: #fff;
    &:hover {
      background-color: #00244f;
    }
  }
`;
