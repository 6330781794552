import React from "react";

import TopBar from "../TopBar";
import SideBar from "../SideBar";
import Nav from "../Nav";
import Filters from "../Filters";
import Flasher from "../Flasher";
import UserNavHandler from "../../components/UserNavHandler/UserNavHandler";
import ModalContainer from "../ModalContainer";
import ServiceBarContainer from "../../../posts/containers/ServiceBar";
import {
  Content,
  NavigationAndContent,
  PrimaryAsideContainer,
  SecondaryAsideContainer,
  StyledContainer,
  TopBarContainer
} from "./StyledAppShell";

const AppShell = ({ children }) => (
  <StyledContainer>
    <ModalContainer className="global-modal" />
    <Flasher />
    <TopBarContainer>
      <TopBar secondarySection={<UserNavHandler />} />
    </TopBarContainer>
    <NavigationAndContent>
      <SideBar>
        <Nav />
      </SideBar>
      <PrimaryAsideContainer>
        <Filters />
      </PrimaryAsideContainer>
      <Content>{children}</Content>
      <SecondaryAsideContainer>
        <ServiceBarContainer />
      </SecondaryAsideContainer>
    </NavigationAndContent>
  </StyledContainer>
);

export default AppShell;
