import React from "react";
import Checkbox from "../../Checkbox";

const CheckboxRenderer = ({ cellData, id, onChange }) => {
  const _onChange = () => onChange(id);
  return (
    <Checkbox
      isChecked={!!cellData}
      name={id}
      onChange={_onChange}
      checkBoxColor={cellData ? "black" : ""}
    />
  );
};

export const CheckboxHeaderRenderer = ({ selected, onChange, disabled }) => (
  <Checkbox
    isChecked={selected}
    name="table-all"
    onChange={onChange}
    isDisabled={disabled}
  />
);

export default CheckboxRenderer;
