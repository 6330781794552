import React from "react";
import DropDownStateless from "./DropDownStateless";
import { BOTTOM, LEFT } from "../../../constants";
export { Item } from "./Item";

class DropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    let inSidebar = props.inSidebar ? props.inSidebar : false;

    let selected = this.updateDropdownLabels();

    this.state = {
      selected: selected,
      open: false,
      inSidebar: inSidebar
    };

    this.el = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    let selected = this.updateDropdownLabels();
    this.setState({
      selected: selected
    });
  }

  /**
   * Updates selected labels
   */
  updateDropdownLabels = () => {
    const children = React.Children.toArray(this.props.children);

    const selectedChild = children.find(
      child => child.props.value === this.props.defaultValue
    );

    let selected;

    if (selectedChild) {
      selected = selectedChild.props.children;
    }

    return selected;
  };

  /**
   * Toggles the dropdown
   */
  toggle = () => this.setState({ open: !this.state.open });

  /**
   * Handles changes in the dropdown
   * @param value
   */
  onChange = value => {
    let { children, onChange } = this.props;
    children = React.Children.toArray(children);
    const selectedChild = children.find(child => child.props.value === value);

    this.setState({
      selected: selectedChild.props.children,
      open: false
    });
    onChange(selectedChild.props.value);
  };

  /**
   * Handles clicks outside of the drop down
   * @param e
   */
  handleClickOutside = e =>
    this.el && !this.el.contains(e.target)
      ? this.setState({ open: false })
      : null;

  /**
   * Sets parent ref
   * @param el
   * @returns {*}
   */
  setRef = el => (this.el = el);

  render() {
    const { selected, open, inSidebar } = this.state;
    const {
      children,
      title,
      overrideStyles,
      overrideContentStyles,
      disabled,
      dropDownPos = BOTTOM,
      dropDownAlign = LEFT,
      hasChevron = true,
      itemsToShow
    } = this.props;

    return (
      <DropDownStateless
        hasChevron={hasChevron}
        onToggle={this.toggle}
        selected={title || selected}
        open={open}
        onChange={this.onChange}
        ref={this.setRef}
        overrideStyles={overrideStyles}
        overrideContentStyles={overrideContentStyles}
        disabled={disabled}
        dropDownPos={dropDownPos}
        itemsToShow={itemsToShow}
        dropDownAlign={dropDownAlign}
        inSidebar={inSidebar}
      >
        {children}
      </DropDownStateless>
    );
  }
}

export default DropDown;
