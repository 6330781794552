import styled from "styled-components";
import { StandardButton } from "../../../shared";

export const StyledEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const StyledEditorArea = styled.main`
  display: flex;
  justify-content: center;
`;

export const StyledBottomButtonContainer = styled.div`
  position: relative;
  z-index: 2;
  bottom: 0;
  width: 1187px;

  min-height: 48px;
  border: 1px solid #ccc;
  margin: 3px;
  background-color: #fff;
`;

export const StyledGrid = styled.div`
  svg {
    border: 1px solid #ccc;
  }
  &.sexton-nio {
    width: 90%;
  }
  &.tre-ett {
    width: 90%;
  }
  &.fyra-ett {
    width: 90%;
  }
  &.tva-ett {
    width: 70%;
  }
  &.ett-ett {
    width: 60%;
  }
  padding: 20px;
  height: 100%;
  width: 100%;
`;

export const ImageItem = styled.div`
  position: absolute;
  min-height: 120px;
  min-width: 140px;
  border: 1px solid #ccc;
  background-color: white;
  top: 0;
  left: 0;
  img {
    user-drag: none;
  }
`;

export const StyledToolBarBtn = styled(StandardButton)`
  background-color: #282828;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bolder;
  min-width: auto;
  &:hover {
    background-color: darkgrey;
  }
  &:active,
  &:focus {
    background-color: darkgrey;
    &:hover {
      background-color: darkgrey;
    }
  }
`;

export const StyledIcon = styled.image`
  &:hover {
    cursor: move;
  }
  border: 1px solid grey;
  fill: white;
`;

export const StyledBorder = styled.rect`
  &.selected {
    opacity: 1;
  }
  opacity: 0;
  fill: transparent;
  stroke: red;
  stroke-dasharray: 2, 2;
  stroke-width: 0.5;
`;

export const StyledInputWrapper = styled.div`
  width: 350px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 15px;
  input {
    font-size: 12px;
    width: 100%;
  }
`;

export const StyledInfoButtonContainer = styled.div`
  margin-left: 7%;

  svg {
    &:hover,
    &:focus,
    &:active {
      color: blue;
    }
  }
`;
export const StyledInfoText = styled.div`
  font-weight: 600;
  padding: 10px 10px 10px 5px;
  color: black;

  &:nth-child(odd) {
    background: #ececec;
  }
`;
export const StyledInfoTextContainer = styled.div`
  font-weight: 600;
  padding: 10px 10px 10px 5px;
  color: black;
`;
