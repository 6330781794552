import React from "react";
import styled from "styled-components";

import NavSection from "./Section";

const Wrapper = styled.nav`
  margin-top: 8px;
  left: 0;
  right: initial;
  height: 100%;
  will-change: transform;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 200px;
  overflow: hidden;
  touch-action: none;
  background: #fff;
  transition: width 200ms;
  ${props =>
    props.collapsed
      ? `
    width: 36px;
    opacity: 0
  `
      : null};
`;

const NavStateless = ({
  className,
  sections,
  collapsed,
  clickHandler,
  permissions
}) => (
  <Wrapper className={className} collapsed={collapsed}>
    {sections.map(section => (
      <NavSection
        {...section}
        collapsed={collapsed}
        clickHandler={clickHandler}
        key={section.header}
        permissions={permissions}
      />
    ))}
  </Wrapper>
);

export default NavStateless;
