import styled from "styled-components";
import { neutralQuaternaryAlt } from "../../../styles/shared/colors";

export const InputField = styled.input`
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 10px;
  outline: none;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  width: auto;
  &:disabled {
    background: ${neutralQuaternaryAlt};
  }
`;
