import React from "react";

const JumpStart = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-jumpstart"
  >
    <g>
      <path
        fill="currentColor"
        d="M42.4,15c-0.8,0-1.4-0.7-1.4-1.5v-5C41,7.7,41.6,7,42.4,7h6.1C49.4,7,50,7.7,50,8.5v5
        c0,0.8-0.6,1.5-1.4,1.5H42.4z M32.4,19c-0.8,0-1.4-0.7-1.4-1.5v-5c0-0.8,0.6-1.5,1.4-1.5h6.1c0.8,0,1.4,0.7,1.4,1.5v5
         c0,0.8-0.6,1.5-1.4,1.5H32.4z M20,18.5c0-0.8,0.6-1.5,1.4-1.5h6.1c0.8,0,1.4,0.7,1.4,1.5v5c0,0.8-0.6,1.5-1.4,1.5h-6.1
         c-0.8,0-1.4-0.7-1.4-1.5V18.5z M9,26.5c0-0.8,0.6-1.5,1.4-1.5h6.1c0.8,0,1.4,0.7,1.4,1.5v5c0,0.8-0.6,1.5-1.4,1.5h-6.1
         C9.6,33,9,32.3,9,31.5V26.5z M0,35.5C0,34.7,0.6,34,1.4,34h6.1C8.4,34,9,34.7,9,35.5v5C9,41.3,8.4,42,7.6,42H1.4
         C0.6,42,0,41.3,0,40.5V35.5z"
      />
    </g>
  </svg>
);

export default JumpStart;
