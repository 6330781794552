import * as React from "react";
import { StyledLoader, LoadingDot } from "./StyledLoader";

export const Loader = (props) => (
  <StyledLoader>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
    <LoadingDot dotColor={props.dotColor}/>
  </StyledLoader>
);
