import React from "react";
import EllipsisDropDownMenu from "../../../../../posts/components/EllipsisDropDownMenu";

const MenuActionRenderer = ({ cellData = {}, rowData }) => {
  return (
    <>
      <EllipsisDropDownMenu data={rowData} useInTable />
    </>
  );
};

export default MenuActionRenderer;
