import { createReducer } from "../../../store/utils";

import {
  SET_CAROUSEL,
  NEXT_IN_CAROUSEL,
  PREV_IN_CAROUSEL,
  FLAG_SHOW_CAROUSEL
} from "./actions";

const initialState = {
  items: [],
  active: null,
  showCarousel: true
};

const carouselReducer = createReducer(initialState, {
  [SET_CAROUSEL](state, { items, active }) {
    return Object.assign({}, state, {
      items,
      active
    });
  },
  [NEXT_IN_CAROUSEL](state) {
    const i = state.items.findIndex(item => item === state.active) + 1;
    return Object.assign({}, state, {
      active: state.items[i > state.items.length - 1 ? 0 : i]
    });
  },
  [PREV_IN_CAROUSEL](state) {
    const i = state.items.findIndex(item => item === state.active) - 1;
    return Object.assign({}, state, {
      active: state.items[i < 0 ? state.items.length - 1 : i]
    });
  },
  [FLAG_SHOW_CAROUSEL](state, { bool }) {
    return Object.assign({}, state, {
      showCarousel: bool
    });
  }
});

export { carouselReducer };
