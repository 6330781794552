import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../../products/components/Card";
import ProductSelect from "../../components/ProductSelect";
import {
  selectPreparedAssignmentProducts,
  selectPreparedAssignmentsForCurrentProduct,
  selectClassroomGroup
} from "../../store/selectors";
import {
  fetchPreparedAssignmentsForProduct,
  deletePreparedAssignmentsforProduct,
  uploadPreparedAssignmentsFile,
  publishPreparedAssignmentsToLive,
  downloadPreparedAssignments
} from "../../api/actions";
import FileToolbar from "../../components/FileToolbar";
import PreparedAssignmentsList from "../../components/PreparedAssignmentsList";
import PublishButton from "../../../products/components/PublishButton";
import PreviewButton from "../../../products/components/PreviewButton";
import PrimaryButton from "../../../shared/components/Button/Primary";
import { addFlash } from "../../../shared/store/flasher/actions";
import Modal from "../../../shared/components/Modal/Modal";


const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledAssignmentsWrapper = styled.div`
  margin-top: 2rem;
`;

const Container = styled.div`
  display: flex;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -3px;

  button {
    margin: 0 3px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(PrimaryButton)`
  width: 75px;
  margin-top: 30px;
  align-self: flex-end;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 465px;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
`;

const PreparedAssignmentsCard = () => {
  const [selectedProductPostId, setSelectedProductPostId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { products, assignmentsdata } = useSelector(
    selectPreparedAssignmentProducts
  );

  const prepAssignments = useSelector(
    selectPreparedAssignmentsForCurrentProduct
  );

  const publishedAt = assignmentsdata ? assignmentsdata.publishedAt : null;
  const updatedAt = assignmentsdata ? assignmentsdata.updatedAt : null;

  const classroomgroup = useSelector(selectClassroomGroup);
  const dispatch = useDispatch();

  const updateSelectedProductPostId = productId => {
    const prodId = +productId;
    const sesam_name = products.find(p => p.id === prodId)?.sesam?.name;
    const assignments = sesam_name
      ? assignmentsdata.products.find(p => p.sesam_name === sesam_name)
      : null;
    setSelectedProductPostId(assignments?.postId);
    return assignments?.postId;
  };

  const onSelectProduct = id => {
    const assignmentPostId = updateSelectedProductPostId(id);
    setSelectedProductId(id);
    dispatch(
      fetchPreparedAssignmentsForProduct(classroomgroup.id, assignmentPostId)
    );
  };

  const downloadContent = () => {
    let assignmentPostId = selectedProductPostId;
    if (selectedProductId && !assignmentPostId) {
      assignmentPostId = updateSelectedProductPostId(selectedProductId);
    }
    if (!assignmentPostId) {
      return;
    }
    dispatch(downloadPreparedAssignments(classroomgroup.id, assignmentPostId));
  };

  const checkFileContent = data => {
    let error = false;
    let content = null;
    try {
      content = JSON.parse(data);
    } catch (err) {
      error = true;
    }
    if (error) {
      return false;
    }
    if (Array.isArray(content)) {
      return content.reduce((acc, c) => {
        if (!(c.title && c.contents)) {
          acc = false;
        }
        return acc;
      }, true);
    }
    return false;
  };

  const uploadContent = data => {
    if (checkFileContent(data)) {
      dispatch(
        uploadPreparedAssignmentsFile(
          JSON.stringify(data),
          classroomgroup.id,
          selectedProductPostId,
          +selectedProductId
        )
      );
    } else {
      dispatch(
        addFlash(
          "Filen innehåller fel.",
          "error"
        )
      );
    }
  };

  const deleteContent = () => {
    let assignmentPostId = selectedProductPostId;
    if (selectedProductId && !assignmentPostId) {
      assignmentPostId = updateSelectedProductPostId(selectedProductId);
    }
    if (!assignmentPostId) {
      return;
    }
    dispatch(
      deletePreparedAssignmentsforProduct(classroomgroup.id, assignmentPostId)
    );
  };

  const publishToLive = () => {
    const postIds = assignmentsdata.products.map(p => p.postId);
    if (postIds.length > 0) {
      dispatch(publishPreparedAssignmentsToLive(classroomgroup.id, postIds));
    } else {
      dispatch(addFlash("Inget att publicera.", "info"));
    }
  };

  const gotoPreview = () => {
    window.open(classroomgroup.previewURL);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onTrashButtonClick = () => {
    setIsModalOpen(true);
  };

  const removePreparedAssignmentButtonClick = () => {
    deleteContent();
    setIsModalOpen(false);
  };

  return (
    <Card title="Förberedda uppdrag">
      <SelectWrapper>
        <ProductSelect
          productList={products}
          onChange={onSelectProduct}
          v={selectedProductId}
        />
      </SelectWrapper>
      <StyledAssignmentsWrapper>
        <FileToolbar
          onDownloadContent={downloadContent}
          onUploadContent={uploadContent}
          onDeleteContent={onTrashButtonClick}
          isFilesUploaded={!!prepAssignments.length}
          uploadWarningText="Vill du ersätta befintliga uppdrag?"
          disabled={
            selectedProductPostId === null || selectedProductPostId === 0
          }
        />
        <PreparedAssignmentsList list={prepAssignments} />
      </StyledAssignmentsWrapper>
      <Container>
        <ButtonWrapper>
          <ButtonGroup>
            <PublishButton
              toLive={false}
              disabled
              publishtime={updatedAt}
              isLatest
            />
            <PreviewButton onClickPreview={gotoPreview} />
          </ButtonGroup>
          <ButtonGroup>
            <PublishButton
              toLive
              onClick={publishToLive}
              disabled={false}
              publishtime={publishedAt}
              isLatest={publishedAt >= updatedAt}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Container>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          Vill du ta bort förberedda uppdrag från klassrumsgruppen?
          <StyledButton onClick={removePreparedAssignmentButtonClick}>
            Ta bort
          </StyledButton>
        </StyledModalContent>
      </Modal>
    </Card>
  );
};

export default PreparedAssignmentsCard;
