import styled from "styled-components";
import { PrimaryButton } from "../../../shared";

export const StyledReplicationButtons = styled.div`
  margin: 0 -3px;
`;

export const StyledUndoButton = styled(PrimaryButton)`
  padding: 0;
  width: 32px;
  height: 32px;
  min-width: initial;
  margin: 0 3px;
`;
