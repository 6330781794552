import React from "react";

const ToggleOff = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-toggleoff"
  >
    <path d="M33.3333333,10.7777778 C37.0432292,10.7777778 40.5309896,12.2224826 43.1542535,14.8457465 C45.7775174,17.4690104 47.2222222,20.9567708 47.2222222,24.6666667 C47.2222222,28.3765625 45.7775174,31.8643229 43.1542535,34.4875868 C40.5309896,37.1108507 37.0432292,38.5555556 33.3333333,38.5555556 L16.6666667,38.5555556 C12.9567708,38.5555556 9.46901042,37.1108507 6.84574653,34.4875868 C4.22248264,31.8643229 2.77777778,28.3765625 2.77777778,24.6666667 C2.77777778,20.9567708 4.22248264,17.4690104 6.84574653,14.8457465 C9.46901042,12.2224826 12.9567708,10.7777778 16.6666667,10.7777778 L33.3333333,10.7777778 Z M33.3333333,8 L16.6666667,8 C7.46189236,8 0,15.4618924 0,24.6666667 C0,33.871441 7.46189236,41.3333333 16.6666667,41.3333333 L33.3333333,41.3333333 C42.5381076,41.3333333 50,33.871441 50,24.6666667 C50,15.4618924 42.5381076,8 33.3333333,8 Z" />
  </svg>
);

export default ToggleOff;
