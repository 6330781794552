import { Column } from "react-virtualized";
import IDRenderer from "../Renderer/ID";

const IDColumn = () => null;

IDColumn.propTypes = Object.assign({}, Column.propTypes);
IDColumn.defaultProps = Object.assign({}, Column.defaultProps, {
  cellRenderer: IDRenderer,
  draggable: true
});

export default IDColumn;
