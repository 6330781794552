import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../../shared/components/Input";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFormativeQuestionDetails,
  selectLocationPayload
} from "../../store/selectors";
import Button from "../../../shared/components/Button";
import TextArea from "../../../shared/components/TextArea";
import UploadMedia from "../../../shared/components/UploadMedia";
import MediaListItem from "../../components/MediaListItem";
import { IMAGE, AUDIO, VIDEO } from "../../../constants";
import {
  deleteFormativeQuestionsMediaItem,
  deleteFormativeQuestionsMediaSection,
  updateFormativeQuestionData,
  fetchFormativeQuestionData
} from "../../api/actions";
import Modal from "../../../shared/components/Modal/Modal";
import {
  StyledModalContent,
  StyledPrimaryButton
} from "../FormativeTestsCard/StyledFormativeTests";
import { gotoRoute } from "../../../shared";
import { ROUTE_CLASSROOM_DETAILS } from "../../routes";
import { uploadFormativeQuestionMediaFile } from "../../api/requests";
import FileUploadProgress from "../../components/FileUploadProgress";

const StyledFormativeQuestionWrapper = styled.div`
  padding: 1rem;
  width: 200px;
`;
const StyledFormWrapper = styled.div`
  width: 100%;
`;
const StyledContentWrapper = styled.div`
  display: flex;
  padding: 1rem;
`;
const StyledJSONWrapper = styled.div`
  margin-right: 2rem;

  textarea {
    width: 600px;
    min-height: 600px;
    height: 100%;
    overflow-y: scroll;
  }
`;
const StyledMediaWrapper = styled.div`
  width: 600px;
  flex: 1;
`;
const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;
const StyledSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
`;
const StyledInput = styled(Input)`
  min-width: 300px;
`;
const StyledButton = styled(Button).attrs({
  skin: "DM",
  outline: true
})`
  margin-top: 1rem;
`;
const StyledMediaContent = styled.div`
  display: flex;
  margin-top: 1rem;
`;
const StyledMediaList = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 350px;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const StyledMediaItemsWrapper = styled.div`
  max-height: 800px;
  overflow-y: scroll;
`;

const FormativeQuestionsForm = () => {
  const [qTitle, setTitle] = useState("");
  const [qJson, setJson] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteMediaType, setDeleteMediaType] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFailes, setUploadedFailes] = useState([]);
  const [fileCount, setFileCount] = useState(0);

  const qData = useSelector(selectFormativeQuestionDetails);
  const { postId, classroomGroupEntityId } = useSelector(selectLocationPayload);

  useEffect(() => {
    if (qData.title) {
      setTitle(qData.title);
      setJson(JSON.stringify(qData.json, null, 2));
    }
  }, [qData]);

  const dispatch = useDispatch();

  const onChange = value => setTitle(value);
  const onBlur = (value, name) => {
    dispatch(updateFormativeQuestionData(postId, { [name]: value }));
  };
  const onJSONChange = v => {
    setJson(v);
  };
  const endUploading = () => {
    setIsUploading(false);
    setUploadProgress(0);
    setUploadedFailes([]);
    setFileCount(0);
  };

  const mediaUploadCallback = file => {
    let count = 0;
    let uploaded = [];
    let failed = [];

    setIsUploading(true);
    setFileCount(file.files.length);

    file.files.forEach(item => {
      uploadFormativeQuestionMediaFile(
        classroomGroupEntityId,
        postId,
        item
      ).then(res => {
        count = count + 1;
        const updatedProgress = (count / file.files.length) * 100;

        if (res && res.data) {
          uploaded = [...uploaded, res.data];
        } else {
          const { response } = res;
          failed = [
            ...failed,
            {
              filename: item.name,
              error: response.data.message,
              key: count
            }
          ];
        }

        setUploadedFailes(failed);
        setUploadProgress(updatedProgress);
        if (updatedProgress === 100) {
          dispatch(fetchFormativeQuestionData(postId));
        }
      });
    });
  };
  const deleteMediaItem = id => {
    setModalText("Vill du ta bort filen?");
    setDeleteItemId(id);
    setIsModalOpen(true);
  };

  const getMediaDeleteText = typ => {
    switch (typ) {
      case IMAGE:
        return "Vill du ta bort alla bildfiler?";
      case AUDIO:
        return "Vill du ta bort alla ljudfiler?";
      case VIDEO:
        return "Vill du ta bort alla videofiler?";
      default:
        return "Vill du ta bort filerna?";
    }
  };

  const deleteMediaSection = mediaType => {
    const txt = getMediaDeleteText(mediaType);
    setModalText(txt);
    setDeleteMediaType(mediaType);
    setIsModalOpen(true);
  };

  const goBack = () => {
    dispatch(
      gotoRoute(ROUTE_CLASSROOM_DETAILS, {
        classroomGroupEntityId: classroomGroupEntityId
      })
    );
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setDeleteItemId(null);
    setDeleteMediaType(null);
    setModalText("");
  };

  const onConfirmDelete = () => {
    if (deleteItemId !== null) {
      dispatch(deleteFormativeQuestionsMediaItem(deleteItemId));
    }
    if (deleteMediaType !== null) {
      dispatch(deleteFormativeQuestionsMediaSection(deleteMediaType));
    }
    closeModal();
  };

  return (
    <StyledFormWrapper>
      <StyledFormativeQuestionWrapper>
        <StyledInput
          name="title"
          onChange={onChange}
          onBlur={onBlur}
          value={qTitle}
          placeholder="Ange titel ..."
        />
        <StyledButton onClick={goBack}>TILLBAKA</StyledButton>
      </StyledFormativeQuestionWrapper>
      <StyledContentWrapper>
        {isUploading && (
          <FileUploadProgress
            progress={uploadProgress}
            failed={uploadedFailes}
            fileCount={fileCount}
            endUploading={endUploading}
          />
        )}
        <StyledJSONWrapper>
          <StyledTitle>JSON</StyledTitle>
          <TextArea
            name="json"
            value={qJson}
            onChange={onJSONChange}
            onBlur={onBlur}
          ></TextArea>
        </StyledJSONWrapper>
        <StyledMediaWrapper>
          <StyledTitle>Fillista</StyledTitle>
          <UploadMedia
            skin="DM"
            outline
            multiple={true}
            type="all"
            callback={mediaUploadCallback}
          >
            FILUPPLADDNING
          </UploadMedia>
          <StyledMediaContent>
            <StyledMediaList>
              <StyledWrapper>
                <StyledSubTitle>Bilder</StyledSubTitle>
                <StyledButton onClick={() => deleteMediaSection(IMAGE)}>
                  TA BORT ALLA BILDER
                </StyledButton>
              </StyledWrapper>
              <StyledMediaItemsWrapper>
                {qData.images &&
                  qData.images.map(item => (
                    <MediaListItem
                      key={item.id}
                      type={IMAGE}
                      item={item}
                      removeItem={deleteMediaItem}
                    />
                  ))}
              </StyledMediaItemsWrapper>
            </StyledMediaList>
            <StyledMediaList>
              <StyledWrapper>
                <StyledSubTitle>Ljud</StyledSubTitle>
                <StyledButton onClick={() => deleteMediaSection(AUDIO)}>
                  TA BORT ALLT LJUD
                </StyledButton>
              </StyledWrapper>
              <StyledMediaItemsWrapper>
                {qData.audio &&
                  qData.audio.map(a => (
                    <MediaListItem
                      key={a.id}
                      type={AUDIO}
                      item={a}
                      removeItem={deleteMediaItem}
                    />
                  ))}
              </StyledMediaItemsWrapper>
            </StyledMediaList>
            {qData.video && (
              <StyledMediaList>
                <StyledWrapper>
                  <StyledSubTitle>Video</StyledSubTitle>
                  <StyledButton onClick={() => deleteMediaSection(VIDEO)}>
                    TA BORT ALLA VIDEO
                  </StyledButton>
                </StyledWrapper>
                <StyledMediaItemsWrapper>
                  {qData.video &&
                    qData.video.map(v => (
                      <MediaListItem
                        key={v.id}
                        type={VIDEO}
                        item={v}
                        removeItem={deleteMediaItem}
                      />
                    ))}
                </StyledMediaItemsWrapper>
              </StyledMediaList>
            )}
          </StyledMediaContent>
        </StyledMediaWrapper>
      </StyledContentWrapper>
      <Modal isModalOpen={isModalOpen} modalClose={closeModal}>
        <StyledModalContent>
          {modalText}
          <StyledPrimaryButton onClick={onConfirmDelete}>
            Ta Bort
          </StyledPrimaryButton>
        </StyledModalContent>
      </Modal>
    </StyledFormWrapper>
  );
};

export default FormativeQuestionsForm;
