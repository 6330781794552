import React, { useState } from "react";
import { StyledIndex, StyledIndexInput } from "./StyledGoalIndex";

const GoalIndex = ({ index: i, setIndexCallback, isEditMode, listLength }) => {
  const [isInput, toggleInput] = useState(false);
  const [index, setIndex] = useState(i);
  const [isValidIndex, setIndexState] = useState(true);

  /**
   * Toggles input and label state
   */
  const inputToggle = () => toggleInput(!isInput);

  /**
   * Updates and stores new index value to then pass to redux
   * @param value
   */
  const setNewIndex = ({ target: { value } }) => setIndex(value);

  /**
   * If enter is pressed, update index
   * @param key
   */
  const handleKeyPress = ({ key }) => {
    if (key.toLowerCase() === "enter") {
      inputToggle();
      checkIndexState();
    }
  };

  /**
   * Checks if entered index is between bounds.
   * If it is out of bounds it will not return the new index,
   * rather it will display an error.
   */
  const checkIndexState = () =>
    index && index <= listLength
      ? setIndexCallback({ oldIndex: i, newIndex: index })
      : setIndexState(false);

  /**
   * Renders label
   * @returns {*}
   */
  const renderLabel = () => (
    <StyledIndex onClick={inputToggle}>{i}</StyledIndex>
  );

  /**
   * Renders Input
   * @returns {*}
   */
  const renderInput = () => (
    <StyledIndexInput
      value={index}
      onChange={setNewIndex}
      onBlur={inputToggle}
      onKeyPress={handleKeyPress}
      isValidIndex={isValidIndex}
    />
  );

  return isEditMode ? renderInput() : renderLabel();
};

export default GoalIndex;
