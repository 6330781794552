import { takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import {
  ROUTE_CLASSROOM,
  ROUTE_CLASSROOM_DETAILS,
  ROUTE_FORMATIVE_QUESTION_EDIT
} from "../routes";
import {
  ACTION_CREATE_CLASSROOMGROUP,
  ACTION_FETCH_CLASSROOMGROUPS,
  SORT_CLASSROOMGROUPS,
  ACTION_FETCH_CLASSROOMGROUP_DETAILS,
  ACTION_UPDATE_CLASSROOMGROUP_DETAILS,
  ACTION_ADD_PRODUCT_TO_CLASSROOMGROUP,
  ACTION_DELETE_PRODUCT_FROM_CLASSROOMGROUP,
  ACTION_PUBLISH_CLASSROOMGROUP,
  ACTION_FETCH_PREPARED_ASSIGNMENTS,
  ACTION_DELETE_PREPARED_ASSIGNMENTS_FROM_PRODUCT,
  ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE,
  ACTION_DOWNLOAD_PREPARED_ASSIGNMENTS,
  ACTION_PUBLISH_PREPARED_ASSIGNMENTS,
  ACTION_FETCH_FORMATIVE_TESTS,
  ACTION_PUBLISH_FORMATIVE_TESTS,
  ACTION_DOWNLOAD_FORMATIVE_TESTS,
  ACTION_UPLOAD_FORMATIVE_TESTS,
  ACTION_DELETE_FORMATIVE_TESTS_FROM_PRODUCT,
  ACTION_DELETE_FORMATIVE_QUESTION,
  ACTION_CREATE_FORMATIVE_QUESTION,
  ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM,
  ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION,
  ACTION_UPDATE_FORMATIVE_QUESTION_DATA,
  ACTION_FETCH_FORMATIVE_QUESTION_DATA,
  ACTION_FILTER_CLASSROOMGROUPS,
  ACTION_FETCH_CLASSROOMGROUP_STICKERS,
  ACTION_DELETE_STICKERS,
  ACTION_FETCH_CLASSROOMGROUP_PERMISSIONS,
  ACTION_ADD_STICKERS_TO_CLASSROOMGROUP,
  ACTION_PUBLISH_STICKERS
} from "../api/actions";
import {
  onClassroomListRoute,
  onFetchClassroomGroups,
  sortClassroomGroupList,
  onCreateClassroomGroup,
  onFetchClassroomGroupDetails,
  onClassroomGroupDetailRoute,
  onUpdateClassroomgroupDetails,
  onAddProductToClassroomgroup,
  onDeleteProductFromClassroomgroup,
  onPublishClassroomGroup,
  onFetchPreparedAssignments,
  onDeletePreparedAssignments,
  onUploadPreparedAssignments,
  onDownloadPreparedAssignments,
  onPublishPreparedAssignments,
  onFetchFormativeTests,
  onPublishFormativeTests,
  onUploadFormativeTests,
  onDeleteFormativeTests,
  onDownloadFormativeTests,
  onDeleteFormativeQuestion,
  onCreateFormativeQuestions,
  onFormativeQuestionEditRoute,
  onDeleteFormativeQuestionMediaItem,
  onDeleteFormativeQuestionMediaSection,
  onUpdateFormativeQuestionData,
  onFetchFormativeQuestionData,
  onFilterClassroomGroups,
  onFetchClassroomStickers,
  onDeleteStickers,
  onFetchClassroomPermissions,
  onAddStickersToClassroomgroup,
  onPublishStickers
} from "./sagas";

import { onMediaFilterRoute } from "../../media/sagas/sagas";

function* watchClassroomRoute() {
  yield takeEvery(ROUTE_CLASSROOM, onClassroomListRoute);
}

function* watchfetchClassroomGroups() {
  yield takeEvery(ACTION_FETCH_CLASSROOMGROUPS, onFetchClassroomGroups);
}

function* watchSortClassroomGroups() {
  yield takeEvery(SORT_CLASSROOMGROUPS, sortClassroomGroupList);
}

function* watchCreateClassroomGroup() {
  yield takeLatest(ACTION_CREATE_CLASSROOMGROUP, onCreateClassroomGroup);
}

function* watchClassroomDetailsRoute() {
  yield takeEvery(ROUTE_CLASSROOM_DETAILS, onClassroomGroupDetailRoute);
  yield takeEvery(
    ROUTE_CLASSROOM_DETAILS,
    onMediaFilterRoute,
    "Media",
    "loadMediaFilters"
  );
}

function* watchFetchClassroomGroupDetails() {
  yield takeEvery(
    ACTION_FETCH_CLASSROOMGROUP_DETAILS,
    onFetchClassroomGroupDetails
  );
}

function* watchUpdateClassroomGroupDetails() {
  yield takeEvery(
    ACTION_UPDATE_CLASSROOMGROUP_DETAILS,
    onUpdateClassroomgroupDetails
  );
}

function* watchAddProductToClassroomGroup() {
  yield takeEvery(
    ACTION_ADD_PRODUCT_TO_CLASSROOMGROUP,
    onAddProductToClassroomgroup
  );
}

function* watchDeleteProductFromClassroomGroup() {
  yield takeEvery(
    ACTION_DELETE_PRODUCT_FROM_CLASSROOMGROUP,
    onDeleteProductFromClassroomgroup
  );
}

function* watchPublishClassroomGroup() {
  yield takeEvery(ACTION_PUBLISH_CLASSROOMGROUP, onPublishClassroomGroup);
}

function* watchFetchPreparedAssignmentsForProduct() {
  yield takeEvery(
    ACTION_FETCH_PREPARED_ASSIGNMENTS,
    onFetchPreparedAssignments
  );
}

function* watchDeletePreparedAssignments() {
  yield takeEvery(
    ACTION_DELETE_PREPARED_ASSIGNMENTS_FROM_PRODUCT,
    onDeletePreparedAssignments
  );
}

function* watchDownloadPreparedAssignments() {
  yield takeEvery(
    ACTION_DOWNLOAD_PREPARED_ASSIGNMENTS,
    onDownloadPreparedAssignments
  );
}

function* watchUploadPreparedAssignmentsFile() {
  yield takeEvery(
    ACTION_UPLOAD_PREPARED_ASSIGNMENTS_FILE,
    onUploadPreparedAssignments
  );
}

function* watchPublishPreparedAssignments() {
  yield takeEvery(
    ACTION_PUBLISH_PREPARED_ASSIGNMENTS,
    onPublishPreparedAssignments
  );
}

function* watchFetchFormativeTestsForProduct() {
  yield takeEvery(ACTION_FETCH_FORMATIVE_TESTS, onFetchFormativeTests);
}

function* watchPublishFormativeTests() {
  yield takeEvery(ACTION_PUBLISH_FORMATIVE_TESTS, onPublishFormativeTests);
}

function* watchDownloadFormativeTests() {
  yield takeEvery(ACTION_DOWNLOAD_FORMATIVE_TESTS, onDownloadFormativeTests);
}

function* watchUploadFormativeTests() {
  yield takeEvery(ACTION_UPLOAD_FORMATIVE_TESTS, onUploadFormativeTests);
}

function* watchDeleteFormativeTests() {
  yield takeEvery(
    ACTION_DELETE_FORMATIVE_TESTS_FROM_PRODUCT,
    onDeleteFormativeTests
  );
}

function* watchDeleteFormativeQuestion() {
  yield takeEvery(ACTION_DELETE_FORMATIVE_QUESTION, onDeleteFormativeQuestion);
}

function* watchCreateFormativeQuestion() {
  yield takeEvery(ACTION_CREATE_FORMATIVE_QUESTION, onCreateFormativeQuestions);
}

function* watchFormativeQuestionEditRoute() {
  yield takeEvery(ROUTE_FORMATIVE_QUESTION_EDIT, onFormativeQuestionEditRoute);
}

function* watchFetchFormativeQuestionData() {
  yield takeEvery(
    ACTION_FETCH_FORMATIVE_QUESTION_DATA,
    onFetchFormativeQuestionData
  );
}

function* watchUpdateFormativeQuestionData() {
  yield takeEvery(
    ACTION_UPDATE_FORMATIVE_QUESTION_DATA,
    onUpdateFormativeQuestionData
  );
}

function* watchDeleteFormativeQuestionMediaItem() {
  yield takeEvery(
    ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_ITEM,
    onDeleteFormativeQuestionMediaItem
  );
}

function* watchDeleteFormativeQuestionMediaSection() {
  yield takeEvery(
    ACTION_DELETE_FORMATIVE_QUESTIONS_MEDIA_SECTION,
    onDeleteFormativeQuestionMediaSection
  );
}

function* watchFilterClassroomsGroup() {
  yield takeEvery(ACTION_FILTER_CLASSROOMGROUPS, onFilterClassroomGroups);
}

function* watchFetchClassroomStickers() {
  yield takeEvery(
    ACTION_FETCH_CLASSROOMGROUP_STICKERS,
    onFetchClassroomStickers
  );
}

function* watchDeleteStickerData() {
  yield takeEvery(
    [ACTION_DELETE_STICKERS, "CLASSROOM/DELETE_POSTS"],
    onDeleteStickers
  );
}

function* watchfetchClassroomGroupPermission() {
  yield takeEvery(
    ACTION_FETCH_CLASSROOMGROUP_PERMISSIONS,
    onFetchClassroomPermissions
  );
}

function* watchAddStickersToClassroomgroup() {
  yield takeEvery(
    ACTION_ADD_STICKERS_TO_CLASSROOMGROUP,
    onAddStickersToClassroomgroup
  );
}

function* watchPublishStickers() {
  yield takeEvery(ACTION_PUBLISH_STICKERS, onPublishStickers);
}

export default function* rootSaga() {
  yield all([
    fork(watchClassroomRoute),
    fork(watchClassroomDetailsRoute),
    fork(watchfetchClassroomGroups),
    fork(watchSortClassroomGroups),
    fork(watchCreateClassroomGroup),
    fork(watchFetchClassroomGroupDetails),
    fork(watchUpdateClassroomGroupDetails),
    fork(watchAddProductToClassroomGroup),
    fork(watchDeleteProductFromClassroomGroup),
    fork(watchPublishClassroomGroup),
    fork(watchFetchPreparedAssignmentsForProduct),
    fork(watchDeletePreparedAssignments),
    fork(watchUploadPreparedAssignmentsFile),
    fork(watchDownloadPreparedAssignments),
    fork(watchPublishPreparedAssignments),
    fork(watchFetchFormativeTestsForProduct),
    fork(watchPublishFormativeTests),
    fork(watchDownloadFormativeTests),
    fork(watchUploadFormativeTests),
    fork(watchDeleteFormativeTests),
    fork(watchFetchFormativeQuestionData),
    fork(watchDeleteFormativeQuestion),
    fork(watchCreateFormativeQuestion),
    fork(watchFormativeQuestionEditRoute),
    fork(watchUpdateFormativeQuestionData),
    fork(watchDeleteFormativeQuestionMediaItem),
    fork(watchDeleteFormativeQuestionMediaSection),
    fork(watchFilterClassroomsGroup),
    fork(watchFetchClassroomStickers),
    fork(watchDeleteStickerData),
    fork(watchfetchClassroomGroupPermission),
    fork(watchAddStickersToClassroomgroup),
    fork(watchPublishStickers)
  ]);
}
