import React from "react";
import { Column } from "react-virtualized";

import CheckboxRenderer, { CheckboxHeaderRenderer } from "./Renderer/Checkbox";

function selectable(DataTable) {
  return class SelectableDataTable extends React.PureComponent {
    static defaultProps = {
      onSelect() {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      const postsSelected = nextProps.selectedPosts.map(row => row.id);

      const list = nextProps.list.map(row => {
        if (postsSelected.indexOf(row.id) >= 0) {
          return Object.assign({}, row, { selected: true });
        } else {
          return Object.assign({}, row, { selected: false });
        }
      });

      return {
        list
      };
    }

    constructor(props) {
      super(props);
      
      if (props.list.length > 0 && props.list[0].id === undefined) {
        throw new Error("All items in the selectable table must contain a id");
      }

      this.state = {
        list: props.list,
        allIsSelected: false
      };
    }

    onSelect = id => {
      const { list } = this.state;
      const { onSelect } = this.props;
      const itemIndex = list.findIndex(row => row.id === id);
      let selectedPosts = this.props.selectedPosts;
      if (itemIndex > -1) {
        const item = list[itemIndex];
        list.splice(
          itemIndex,
          1,
          Object.assign({}, item, {
            selected: !item.selected
          })
        );
        this.setState({
          list: [...list]
        });
        selectedPosts = selectedPosts.filter(i => i.id !== item.id);
        const selectedPostsInPage = list.filter(row => !!row.selected); 

        const allSelectedPosts = selectedPosts ? 
          [...selectedPostsInPage, ...selectedPosts] : selectedPostsInPage;
          
        const filteredSelectedPosts = allSelectedPosts.filter((value, index, self) =>
          index === self.findIndex((t) => (
          t.place === value.place && t.id === value.id))
          )
        onSelect(filteredSelectedPosts);
      }
    };

    toggleSelectAll = () => {
      let { list } = this.state;
      const { onSelect } = this.props;

      list = list.map(row =>
        Object.assign({}, row, { selected: !this.state.allIsSelected })
      );

      this.setState({
        allIsSelected: !this.state.allIsSelected,
        list
      });

      const sel = this.state.allIsSelected ? [] : list;
      onSelect(sel);
    };

    createCheckboxColumn = () => {
      const { allIsSelected } = this.state;

      const cellRenderer = ({ cellData, rowData }) => (
        <CheckboxRenderer
          onChange={this.onSelect}
          cellData={cellData}
          id={rowData.id}
        />
      );

      const label = (
        <CheckboxHeaderRenderer
          selected={allIsSelected}
          onChange={this.toggleSelectAll}
          disabled={this.props.disableCheckbox}
        />
      );

      return (
        <Column
          key="selected"
          dataKey="selected"
          label={label}
          width={45}
          cellRenderer={cellRenderer}
          fixedWidth
          disableSort
        />
      );
    };

    render() {
      const { children, ...rest } = this.props;
      const { list } = this.state;

      const arrChildren = React.Children.toArray(children);
      arrChildren.unshift(this.createCheckboxColumn());

      return (
        <DataTable {...rest} list={list}>
          {arrChildren}
        </DataTable>
      );
    }
  };
}

export default selectable;
