import React from "react";

const RemoveIcon = ({ width, height, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="2 2 20 20"
    width={`${width}px`}
    height={`${height}px`}
  >
    <path
      fill={`${color}`}
      d="M 12 2 C 6.47 2 2 6.47 2 12 s 4.47 10 10 10 s 10 -4.47 10 -10 S 17.53 2 12 2 Z m 5 13.59 L 15.59 17 L 12 13.41 L 8.41 17 L 7 15.59 L 10.59 12 L 7 8.41 L 8.41 7 L 12 10.59 L 15.59 7 L 17 8.41 L 13.41 12 L 17 15.59 Z"
    />
  </svg>
);

export default RemoveIcon;
