import React from "react";
import DropDown from "../../../shared/components/DropDown/DropDown";
import { Item } from "../../../shared/components/DropDown";
import { SquarePlusSolid } from "../../../shared/components/StudliIcons";
import { BlockArray } from "./blocks";
import {
  updateBlock,
  findBlock,
  replaceBlock,
  flattenBlockArrayData,
  removeBlock,
  insertBlockBefore,
  insertBlockLast,
  convertToRaw,
  convertFromRaw,
  getUsedComponents,
  generateBlockKeys
} from "./EditorData";
import { createPost, fetchPost } from "../../api/requests";
import {
  EddaEditorContainer,
  StyledAddComponentButton,
  StyledIconWrapper,
  StyledMarginedSpan
} from "./StyledEditor";
import {
  translation,
  TOP,
  MEDIA_RESOURCE,
  RESOURCE,
  HELP_RESOURCE,
  ENTITIES
} from "../../../constants";

export const preparePostData = postData => {
  const { type, data, blockKey, settings } = postData || {};
  return generateBlockKeys(
    type === "blockarray"
      ? {
          type,
          blockKey,
          settings,
          data: flattenBlockArrayData(data)
        }
      : convertFromRaw({
          type: "blockarray",
          settings,
          data: flattenBlockArrayData([
            {
              type,
              data
            }
          ])
        })
  );
};

export class Editor extends React.Component {
  static defaultProps = {
    plugins: [],
    readOnly: false,
    dropDownData: { title: "", data: [] },
    onChange: () => null,
    disabledAddPlugin: false
  };
  entityRendererFns = [];

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onInsertNewBlock = this.onInsertNewBlock.bind(this);

    this.actions = {
      convertFromResource: this.onConvertFromResource.bind(this),
      convertToResource: this.onConvertToResource.bind(this),
      delete: this.onDelete,
      save: this.onSave,
      editEntity: this.onEditEntity.bind(this),
      editVideo: this.onEditVideo.bind(this),
      replaceMedia: this.onReplaceMedia,
      uploadReplaceMedia: this.onUploadReplaceMedia,
      editMediaResource: this.onEditMediaResource.bind(this),
      replaceBlock: this.onReplaceBlock.bind(this),
      openMediaModal: this.onOpenMediaModal,
      openMediaresourceModal: this.onOpenMediaResourceModal,
      openDropzoneModal: this.onOpenDropzoneModal
    };

    this.resolvePlugins(this.props.plugins);
  }

  /**
   * On post save
   * @returns {*}
   */
  onSave = () => this.props.savePost();

  /**
   * On post change
   * @param blockKey
   * @param objects - object containing the available editable, data-holding objects.
   */
  onChangeHandler = (blockKey, objects) => {
    this.props.onChange(updateBlock(blockKey, objects, this.props.postData));
  };

  /**
   * On block delete
   * @param blockKey
   */
  onDelete = blockKey => {
    this.props.storeDraft(this.props.target);
    this.props.onChange(removeBlock(blockKey, this.props.postData));
    this.props.replacingBlock(false);
  };

  /**
   * Open media modal
   * @param handler
   * @param conditions
   * @returns {void|*}
   */
  onOpenMediaModal = (handler, conditions) =>
    this.props.openMediaModal(handler || (() => {}), conditions);

  /**
   * Open mediaresource modal
   * @param handler
   * @param conditions
   * @returns {void|*}
   */
  onOpenMediaResourceModal = (handler, conditions) =>
    this.props.openMediaresourceModal(handler || (() => {}), conditions);

  /**
   * Open drop zone modal
   * @param handler
   * @returns {*|void}
   */
  onOpenDropzoneModal = handler =>
    this.props.openDropzoneModal(handler || (() => {}));

  /**
   * Insert a block at start
   * @param blockKey
   * @param block
   */
  onInsertBlockBefore = (blockKey, block) => {
    this.props.storeDraft(this.props.target);
    this.props.onChange(
      insertBlockBefore(blockKey, block, this.props.postData)
    );
  };

  /**
   * Insert a block at end
   * @param block
   */
  onInsertBlockLast = block => {
    this.props.storeDraft(this.props.target);
    this.props.onChange(insertBlockLast(block, this.props.postData));
  };

  /**
   * Plugins resolve
   * @param plugins
   */
  resolvePlugins = plugins => {
    plugins.forEach(plugin => {
      const pluginFns = this.resolvePlugin(plugin);
      if (typeof pluginFns.entityRendererFn === "function") {
        this.entityRendererFns.push(pluginFns.entityRendererFn);
      }
    });
  };

  /**
   * Resolve plugin
   * @param plugin
   * @returns {{}}
   */
  resolvePlugin(plugin) {
    const pluginFns = {};

    Object.keys(plugin).forEach(attrName => {
      if (attrName === "entityRendererFn") {
        pluginFns.entityRendererFn = plugin[attrName];
      }
    });

    return pluginFns;
  }

  /**
   * Edit resource
   * @param blockKey
   */
  onEditEntity(blockKey) {
    const { block } = findBlock(blockKey, this.props.postData);
    const entityType = ENTITIES[block.type];

    const id = block && block.data && block.data.id;
    this.props.editEntity(id, entityType);
  }

  /**
   * Edit media resource
   * @param blockKey
   */
  onEditMediaResource(blockKey) {
    const { block } = findBlock(blockKey, this.props.postData);
    const mediaresourceId = block && block.data && block.data.id;
    this.props.editMediaResource(mediaresourceId);
  }

  /**
   * Replace video
   * @param blockKey
   */
  onEditVideo(blockKey, data, settings) {
    this.props.openMediaModal(media => {
      media.forEach(obj => {
        if (obj.content_type === "video/mp4") {
          const newData = {
            data: { ...data, Path: obj.url, altText: "", id: obj.id }
          };
          this.onChangeHandler(blockKey, newData, settings);
        }
      });
    });
  }

  /**
   * Replace media
   * @param blockKey
   */
  onReplaceMedia = blockKey => {
    this.props.openMediaModal(media => {
      if (media.length > 0) {
        const { block } = findBlock(blockKey, this.props.postData);
        this.onChangeHandler(blockKey, {
          ...block,
          data: { ...block.data, Path: media[0].url, id: media[0].id }
        });
      }
    });
  };

  /**
   * Upload media
   * @param blockKey
   */
  onUploadReplaceMedia = blockKey => {
    this.props.openDropzoneModal(media => {
      const extractFileExtension = filename => {
        return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
      };
      if (media.length > 0) {
        const mediaType = media[0].type;
        const { block } = findBlock(blockKey, this.props.postData);
        if (!block) {
          this.props.errorFlash(
            `Ett oväntat fel har uppstått, block:${blockKey} kunde inte hittas.`
          );
          return;
        }
        if (mediaType !== block.type) {
          this.props.infoFlash(
            `Filen som valts fungerar inte i den aktuella komponenten.`
          );
          return;
        }
        const newData = {
          [mediaType]: {
            src: media[0].url,
            contentType: media[0].content_type,
            fileExtension: extractFileExtension(media[0].url)
          }
        };
        this.onChangeHandler(blockKey, { ...block, data: { ...newData } });
      }
    });
  };

  /**
   * Replace block
   * @param blockKey
   */
  onReplaceBlock(blockKey) {
    this.props.replacingBlock(true);
    const { block } = findBlock(blockKey, this.props.postData);
    if (block.type === MEDIA_RESOURCE) {
      this.onInsertMediaResource(
        res => this.insertFetchHandler(block.type, res, blockKey),
        null,
        `Byt ut ${translation[block.type.toLowerCase()]}.`
      );
    } else {
      this.props.openPostModal(
        ENTITIES[block.type],
        `Byt ut ${translation[block.type.toLowerCase()]}.`,
        ids => {
          const entityId = this.props.post.entity_id;
          ids.forEach(id => {
            fetchPost({
              entityId,
              postId: id,
              type: ENTITIES[block.type]
            }).then(res => this.insertFetchHandler(block.type, res, blockKey));
          });
        }
      );
    }
  }

  /**
   * Convert to resource
   * @param blockKey
   */
  onConvertToResource(blockKey) {
    const { block } = findBlock(blockKey, this.props.postData);

    const resourceType =
      {
        post: "resource",
        help: "helpresource"
      }[this.props.target] || "resource";

    if (block) {
      const data = {
        type: "blockarray",
        data: [block]
      };

      createPost({
        type: resourceType,
        entityId: this.props.post.entity_id,
        title: resourceType + " (" + block.type + ")",
        data: JSON.stringify(convertToRaw(data)),
        components: getUsedComponents(data)
      }).then(res => {
        if (res && res.data) {
          const { data: resource } = res.data;
          this.props.onResourceAdded(resource);
          const newBlock = convertFromRaw({
            type: resourceType,
            data: {
              id: resource.id
            }
          });
          const newPostData = replaceBlock(
            blockKey,
            newBlock,
            this.props.postData
          );
          newPostData.data = flattenBlockArrayData(newPostData.data);

          this.props.storeDraft(this.props.target);
          this.props.onChange(newPostData);
        }
      });
    }
  }

  getBlockFromResource(resources, id) {
    return resources[id] ? JSON.parse(resources[id].data) : null;
  }

  /**
   * Convert from resource
   * @param blockKey
   */
  onConvertFromResource(blockKey) {
    const { block } = findBlock(blockKey, this.props.postData);
    const newBlock = block
      ? this.getBlockFromResource(this.props.resources, block.data.id)
      : null;
    if (newBlock) {
      const newPostData = replaceBlock(blockKey, newBlock, this.props.postData);
      newPostData.data = flattenBlockArrayData(newPostData.data);

      this.props.storeDraft(this.props.target);
      this.props.onChange(newPostData);
    }
  }

  /**
   * On sort end
   * @param oldIndex
   * @param newIndex
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { type, data: blocks, settings } = this.props.postData;
    const newBlocks = [].concat(blocks);
    const block = newBlocks.splice(oldIndex, 1);
    newBlocks.splice(newIndex, 0, block[0]);
    this.props.onChange({ type, data: newBlocks, settings });
  };

  /**
   * On insert post (mediaresource)
   * @returns {void|*}
   */
  onInsertMediaResource = (callback, conditions, title) =>
    this.props.openMediaresourceModal(
      res => callback(res[0]),
      conditions,
      title
    );

  /**
   * On insert post (resource / helpresource)
   * @param type
   * @param entityId
   * @param callback
   * @returns {void|*}
   */
  onInsertPost = (type, entityId, callback) =>
    this.props.openPostModal(
      type,
      `Lägg till ${translation[type.toLowerCase()]}.`,
      ids => {
        ids.forEach(postId =>
          fetchPost({ entityId, postId, type }).then(res => callback(res))
        );
      }
    );

  /**
   * On insert new block
   * @param block
   */
  onInsertNewBlock = block => {
    const { entity_id } = this.props.post;
    if (block.type === RESOURCE || block.type === HELP_RESOURCE) {
      this.onInsertPost(
        ENTITIES[block.type.toLowerCase()],
        entity_id,
        this.insertFetchHandler.bind(this, block.type)
      );
    } else if (block.type === MEDIA_RESOURCE) {
      this.onInsertMediaResource(
        this.insertFetchHandler.bind(this, block.type)
      );
    } else {
      this.onInsertBlockLast(JSON.parse(JSON.stringify(block)));
    }
  };

  /**
   * Callback that runs when a resource have been successfully fetched
   * @param res
   */
  insertFetchHandler = (type, res, blockKey = null) => {
    if (res && res.data) {
      const { data: resource } = res.data;
      this.props.onResourceAdded(type === MEDIA_RESOURCE ? res : resource);
      const newBlock = convertFromRaw({
        type: type.toLowerCase(),
        data: {
          id: type === MEDIA_RESOURCE ? res.id : resource.id
        }
      });
      if (blockKey !== null) {
        new Promise(resolve => {
          resolve(this.onInsertBlockBefore(blockKey, newBlock));
        }).then(() => this.onDelete(blockKey));
      } else {
        this.onInsertBlockLast(newBlock);
      }
    }
  };

  render() {
    return this.props.postData ? (
      <EddaEditorContainer>
        {this.renderBlockArray()}
        {this.renderDropDown()}
      </EddaEditorContainer>
    ) : null;
  }

  /**
   * Renders blockarray
   * @returns {*}
   */
  renderBlockArray = () => (
    <BlockArray
      data={this.props.postData.data}
      resources={this.props.resources}
      storeDraft={this.props.storeDraft}
      draftTarget={this.props.target}
      plugins={this.entityRendererFns}
      registerValidator={this.props.registerValidator}
      unregisterValidators={this.props.unregisterValidators}
      onChange={this.onChangeHandler}
      actions={this.actions}
      useDragHandle={true}
      onSortEnd={this.onSortEnd}
      helperClass="sortable-helper"
      readOnly={this.props.readOnly}
      infoFlash={this.props.infoFlash}
      keyboards={this.props.keyboards}
      postType={this.props.post.type}
    />
  );

  /**
   * Renders add component button
   * @returns {*}
   */
  renderAddComponentBtn = title => (
    <StyledAddComponentButton>
      <StyledIconWrapper style={{ height: "15px" }}>
        <SquarePlusSolid size={18} />
      </StyledIconWrapper>
      <StyledMarginedSpan>{title}</StyledMarginedSpan>
    </StyledAddComponentButton>
  );

  /**
   * Renders drop down items
   * @param data
   * @returns {any[]}
   */
  renderDropDownItems = data =>
    Object.keys(data).map((key, i) => (
      <Item key={i} value={data[key]}>
        {translation[key] || key}
      </Item>
    ));

  /**
   * Renders custom dropdown
   * @returns {*}
   */
  renderDropDown = () => (
    <DropDown
      title={this.renderAddComponentBtn(this.props.dropDownData.title)}
      defaultValue=""
      onChange={this.onInsertNewBlock}
      overrideStyles={{ position: "absolute", bottom: 0 }}
      hasChevron={false}
      dropDownPos={TOP}
      disabled={this.props.disabledAddPlugin}
    >
      {this.renderDropDownItems(this.props.dropDownData.data)}
    </DropDown>
  );
}
