import styled from "styled-components";
import {
  StandardButton,
  PrimaryButton
} from "../../shared/components/Button/index";
import { Tabs } from "react-tabs";

export const dropDownCustomStyles = {
  minWidth: "300px",
};

export const StyledList = styled.div`
  width: 100%;
  display: flex;
  width: 700px;
  padding: 10px;
`;

export const StyledListItem = styled.li`
  z-index: 5;
  background: white;
  padding-top: 10px;
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
`;

export const StyledCheckBoxWrapper = styled.div`
  width: max-content;
  padding: 5px 10px;
`;

export const StyledListItemContent = styled.div`
  flex: 1;
  display: flex;
  border: 2px solid #999;
  padding: 5px 10px;
  ${({ direction }) => (direction === 'row' ? "background: #385378" : '')};
  ${({ direction }) => (direction === 'row' ? '' : 'border-top: none')};
  flex-direction: ${({ direction }) => (direction === 'row' ? 'row' : 'column')};
`;

export const StyledListContainer = styled.ul`
  list-style-type: none;
  margin: 0px 0px 50px 0px;
  padding: 0;
  min-width: 530px;
  width: 100%;
`;

export const StyledForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const StyledEditText = styled.div`
  flex: 1;
  display: flex;
  align-items: baseline;
  padding: 10px;
`;

export const StyledEditInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  font-size: 16px;
  padding: 5px 10px;
  input {
    box-shadow: none;
    border: 2px solid #ddd;
    font-weight: 600;
    font-size: 12px;
    color: #277575;
    letter-spacing: 0.24px;
    line-height: 30px;
    flex: 1;

    &:disabled {
      background: none;
    }
  }
  height: 42px;
`;

export const StyledDropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  font-size: 16px;
  padding: 5px 10px;
  height: 42px;
`;

export const StyledDropDownButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  padding: 5px 13px;
  height: 42px;
  input {
    max-width: 40px;
    margin: 0px 10px 0px 10px;
    box-shadow: none;
    border: 2px solid #ddd;
    font-weight: 600;
    font-size: 12px;
    color: #277575;
    letter-spacing: 0.24px;
    line-height: 30px;
    flex: 1;
    text-align: center;
    padding: 5px 5px;

    &:disabled {
      background: none;
    }
  }
`;

export const StyledDragHandleWrapper = styled.div`
  margin: 7px 12px 0px 0px;
`;

export const StyledColumnWrapper = styled.div`
  flex: 0 0 5%;
`;

export const StyledListItemContentWrapper = styled.div`
  flex: 1;
  min-width: 30%;
`;

export const StyledButton = styled(StandardButton)`
  background: none;
  min-width: 40px;

  svg {
    fill:white;
  }

  &:hover {
    background-color: transparent;
    svg {
      fill:black;
    }
  }

  &:active, &:focus {
    background-color: transparent;
  }
`;

export const StyledFeedback = styled(StandardButton)`
  background: white;
  flex: 1;
  display: contents;
  color: ${({ color }) => color};
  pointer-events: none;
  width: 20px;
  height: 20px;
  line-height: 40px;
`;

export const StyledUtilityButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledListItemHeaderLink = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: white;
  letter-spacing: 0.24px;
  line-height: 30px;

  &:hover {
    color: none;
    cursor: pointer;
  }
`;

export const StyledListItemHeaderLabel = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: white;
  letter-spacing: 0.24px;
  line-height: 30px;
`;

export const StyledListItemStatusLabel = styled(StyledListItemHeaderLabel)`
  text-align: center;
`;

export const StyledFormLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #277575;
  letter-spacing: 0.24px;
  line-height: 30px;
`;

export const StyledTextEditorWrapper = styled.div `
  flex: 5;
  padding: 10px;
`

export const StyledPrimaryButton = styled(PrimaryButton)`
  border-radius: 4px;
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledActionButton = styled(PrimaryButton)`
  background-color: ${props => props.color};
  margin-right: 8px;
  font-weight: bold;
  
  &:hover {
    cursor: pointer;
    background-color: ${props => props.color};
    opacity: .8;
  }
`;

export const StyledSquareButton = styled(PrimaryButton)`
  min-width: 40px;
  border-radius: 4px;
  margin-right: 4px;
  background-color: ${({ disabled }) => (disabled ? "#d2d2d2" : "black")};
  padding: 0;

  &:hover, &:focus, &:active {
    background-color: ${({ disabled }) => (disabled ? "#d2d2d2" : "black")};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;  
  min-width: 80px;
  align-items: center;
  padding: 5px 10px;
`;

export const StyledLabelContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex: 1;
`
export const StyledInputContainer = styled.div `
  display: flex;
  flex: 5;
  flex-direction: row;
`

export const StyledDropDownContainer = styled.div `
  display: flex;
  flex: 5;
  flex-direction: row;
`

export const StyledDropDownRowContainer = styled.div `
  display: flex;
  flex-direction: column;
`
export const StyledMarkerCountContainer = styled.div `
  display: flex;
  margin-right: 67px;
  align-self: flex-end;
`

export const TabWrapper = styled.div`
  display: flex;
  flex: 1;

  .react-tabs__tab-panel {
    height: 100%;
  }

  .react-tabs__tab-list {
    margin: 0;
    z-index: 2;
  }

  .react-tabs__tab--selected {
    font-weight: 600;
    font-size: 14px;
    color: #277575;
    letter-spacing: 0.24px;
    line-height: 30px;
  }
`;

export const StyledAdventureWrapper = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
`;

export const StyledTabs = styled(Tabs)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledPanelButtonsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #cecece;
  box-shadow: 0 1px 4px -2px;
  position: relative;
  z-index: 1;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
`;

export const StyledSaveResetButtonsContainer = styled.div`
  position: fixed;
  bottom: 10px;
  padding: 10;
  background-color: #eaeaea;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 2;
`;

export const customStyles = {
  containerWidth: "auto",
  minWidth: "40px",
  cursor: "pointer",
  backgroundColor: "#d2d2d2",
  color: "black",
  borderRadius: "5px",
  fontSize: "16px"
};

export const buttonStyles = {
  borderRadius: "5px",
  minWidth: "40px",
  minHeight: "35px",
  marginRight: "5px"
};

export const StyledRemovePrompt = styled.div`
  width: 400px;
  min-height: 250px;
  background-color: #FFFFFF;
  padding: 18px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  svg {
    color: ${props => props.theme.red};
    margin-right: 10px;
  }
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRemoveHeading = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
`;

export const StyledButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;

export const StyledAddMarkerButtonContainer = styled.div`
  padding: 5px 10px;
  display: flex;
`;

export const StyledRemoveText = styled.p`
  font-size: 16px;
`;

export const StyledRemoveMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const StyledBackLinkContainer = styled.div`
  svg {
    color: ${props => props.theme.neutralSecondaryAlt};
    margin-right: 14px;
  }
`;

export const StyledBackLink = styled.div`
  width: 142px;
  height: 32px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.themePrimary};
  border-radius: 5px;
  color: #277575;
  margin: ${props => props.margin || '0'};

  &:hover { border-color: #277575; }
`;

export const StyledLoaderWrapper = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`

export const StyledAudioContainer = styled.div`
  align-self: center;
  margin: 5px 15px 5px 0px;

  audio {
    height: 30px;
    width: 370px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px rgba(55, 122, 211, 0.5);
      border-radius: 30px;
    }
  }
`;

export const StyledImageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 25px 10px 10px;
  max-width: 500px;

  img,
  svg {
    width: auto;
    height: auto;
    max-height: 100px;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const StyledButtonsSpacer = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonsSpacerLine = styled.div`
  height: 100%;
  width: 1px;
  background: white;
`;

export const StyledWarningText = styled.span`
  margin: auto;
  color: red;
  font-size: 14px;
  width: 50%;
  justify-content: center;
  display: flex;
`;

export const StyledMetadataContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #cecece;
  box-shadow: 0 1px 4px -2px;
  position: relative;
  z-index: 1;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
`;

export const StyledMetadataImageContainer = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  height: 100px;
  padding: 10px 25px 10px 10px;
  max-width: 500px;

  img,
  svg {
    width: auto;
    height: auto;
    max-height: 100px;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const StyledMetadataLabelContainer = styled.div `
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
`