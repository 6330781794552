import {
  START_FETCH,
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  FETCH_MEDIA_LIST,
  FETCH_MEDIA_LIST_SUCCESS,
  FETCH_MEDIAL_LIST_ERROR
} from "../../api";
import { actionCreator } from "../../shared";

export const SAVE_ENTITY = "SAVE_ENTITY";
export const SET_ENTITY_DRAFT = "SET_ENTITY_DRAFT";
export const UPDATE_ENTITY_DRAFT = "UPDATE_ENTITY_DRAFT";
export const CREATE_ENTITY_DRAFT = "CREATE_ENTITY_DRAFT";
export const RESET_ENTITY_DRAFT = "RESET_ENTITY_DRAFT";
export const UPDATE_JSON_DRAFT = "UPDATE_JSON_DRAFT";
export const RESET_JSON_DRAFT = "RESET_JSON_DRAFT";

export const startFetch = entityName => ({
  type: actionCreator(entityName, START_FETCH)
});

export const endFetch = entityName => ({
  type: actionCreator(entityName, END_FETCH)
});

export const fetchMediaList = (entityName, apiAction, args = {}) => ({
  type: actionCreator(entityName, FETCH_MEDIA_LIST),
    apiAction,
    args
});

export const fetchMediaListSuccess = (entityName, entities) => ({
  type: actionCreator(entityName, FETCH_MEDIA_LIST_SUCCESS),
  entityName,
  entities
});

export const fetchMediaListError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_MEDIAL_LIST_ERROR),
  entityName,
  error
});

export const fetchEntities = (entityName, apiAction, args = {}) => ({
  type: actionCreator(entityName, FETCH_ENTITIES),
    apiAction,
    args
});

export const fetchEntitiesSuccess = (entityName, entities, extras, markers) => ({
  type: actionCreator(entityName, FETCH_ENTITIES_SUCCESS),
  entityName,
  entities,
  extras,
  markers
});

export const fetchEntitiesError = (entityName, error) => ({
  type: actionCreator(entityName, FETCH_ENTITIES_ERROR),
  entityName,
  error
});

export const createEntityDraft = (entityName, entity) => ({
  type: actionCreator(entityName, CREATE_ENTITY_DRAFT),
  entity
});

export const setEntityDraft = (entityName, entity) => ({
  type: actionCreator(entityName, SET_ENTITY_DRAFT),
  entity
});

export const updateEntityDraft = (entityName, entity, validation, media) => ({
  type: actionCreator(entityName, UPDATE_ENTITY_DRAFT),
  entity,
  validation,
  media
});

export const updateJsonDraft = (entityName, entity, validation) => ({
  type: actionCreator(entityName, UPDATE_JSON_DRAFT),
  entity,
  validation
});

export const resetEntityDraft = entityName => ({
  type: actionCreator(entityName, RESET_ENTITY_DRAFT)
});

export const resetJsonDraft = entityName => ({
  type: actionCreator(entityName, RESET_JSON_DRAFT)
});