import HelpImage from "./components/Help/HelpImage/HelpImage";
import { getMediaObject } from "./dataModels";
import { AUDIO, HELP_IMAGE, IMAGE, MEDIA_RESOURCE } from "../constants";

const types = [HELP_IMAGE];

const getUsedResources = ({ data }) =>
  data.mediaresource.data.id ? [data.mediaresource.data.id] : [];

export const createHelpImagePlugin = () => ({
  entityRendererFn: entity => {

    const settings = {
      readOnly: false,
      multiLine: true
    };

    if (types.includes(entity.type)) {
      return {
        component: HelpImage,
        style: { padding: "0px" },
        props: {
          data: entity.data,
          settings: { ...settings, ...entity.settings }
        }
      };
    }
    return null;
  },
  types,
  getUsedResources
});

export default createHelpImagePlugin;

export const emptyBlocks = {
  [HELP_IMAGE]: {
    type: HELP_IMAGE,
    data: {
      title: null,
      text: null,
      [AUDIO]: getMediaObject(AUDIO),
      [IMAGE]: getMediaObject(IMAGE),
      [MEDIA_RESOURCE]: getMediaObject(MEDIA_RESOURCE)
    },
    settings: {}
  }
};
