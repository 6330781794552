import styled from "styled-components";
import { StandardButton, Button } from "../../../shared";
import ButtonGroup from "../../../shared/components/ButtonGroup";


export const StyledButton = styled(StandardButton)`
  background: white;
  min-width: 40px;
  color:#277575;
  border: 1px solid #0078d7;
  border-radius:5px;
`;
export const StyledCollapseButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin:10px;
`;

export const StyledAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin:10px;
  border:1px solid grey;
  height: auto;
  transition: all 0.6ps ease-in-out;
  border-radius:5px;
`;

export const StyledAccordionPanelHeader = styled.div`
  background:#324d72;
  color:white;
  padding:15px;
  font-size:14px;
  display:flex;
  flex-direction:row;
`;

export const StyledAccordionPanelLabel = styled.span`
color:white;
font-size:14px;
flex:1;
margin: 5px;
text-transform: uppercase;
font-weight:500;
`;

export const StyledAccordionPanelCount = styled.span`
color:white;
font-size:14px;
flex:1;
margin:5px;
text-transform: uppercase;
font-weight:500;
`;

export const StyledAccordionPanelCollapse = styled.span`
  flex:1;
  width:30px;
  background:transparent;
  border:2px solid #fff;
  min-width:30px;
  max-width:30px;
  height:30px;
  content: "-";
  color:#fff;
  &:hover {
    background:transparent;
    cursor:pointer;
  }
  &:active {
    background:transparent;
  }
`;

export const StyledInternalWrapper = styled.div`
  width: 100%;
  max-height: ${(props) => (props.open ? 'auto' : '0')};
  transition: all 0.6ps ease-in-out;
  overflow: hidden;
`;


export const StyledHeadGroupInternalWrapper = styled.div`
  width: 100%;
  max-height: ${(props) => (props.open ? 'auto' : '0')};
  transition: all 0.6ps ease-in-out;
  overflow: hidden;
`;

export const StyledMarkerGroupWrapper = styled.div`
display:flex;
justify-content: center;
flex-direction:row;
height: 80px;
margin-top:10px;
`;

export const StyledAddMarkersTypeImage = styled.div`
flex:1;
justify-content: center;
border-radius: 50%;
height:50px;
width:50px;
position:relative;
border: 1px solid #9D9C9C;
margin: 8px 20px 0px 20px;
padding:8px 7px 7px 9px;
background-size:contain;
background-repeat:no-repeat;
background-position: center;
`;

export const StyledAddMarkersTypeImagePlaceHolder = styled.div`
flex:1;
justify-content: center;
border-radius: 50%;
background-color: ${props => props.backgroundColor};
height:50px;
width:50px;
position:relative;
border: 1px solid #9D9C9C;
margin: 8px 20px 0px 20px;
padding:8px 7px 7px 9px;
`;


export const StyledMarkerItem = styled.div`
background:#F0F0F0;
border-radius:10px;
height:80px;
margin:0px 40px 20px 40px;
padding:10px;
display:flex;
padding-right:80px;
`;

export const StyledMarkerItemLabel = styled.div`
flex: 1;
font-size:16px;
color:black;
text-align:left;
margin-top: 15px;  
margin-left:15px;   
`;

export const StyledMediaItemContainer = styled.div` 
  margin-top: 50px;
`;

export const StyledMediaItemImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledMediaItemImageWrapper = styled.div`
  flex: 1;
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center;
  min-width:80px;
`;

export const StyledMediaItemImage = styled.img`
  flex: 1;
  max-width: 100%;
  max-height: 50px;
`;


export const StyledCollapseButton = styled(Button)`
        flex:1;
        background:transparent;
        border:2px solid #fff;
        height:20px;
        max-width: 20px;
        padding:2px;
        min-width:auto;
        color:#fff;
        &:hover {
        background:transparent;
        cursor:pointer;
        color:#fff;
        }
        &:active {
        background:transparent;
        color:#fff;
        }
        &:focus {
        background:transparent;
        color:#fff;
        }
`;

export const StyledInput = styled.input`
flex:1;
height:32px;
max-width:150px;
margin-top:10px;
`

export const StyledMarkerTypeLabel = styled.div`
font-size:18px;
color:black;
width:100%;
text-align:right;
margin-top:12px;
margin-right:10px;
`
export const StyledMarkerTypeImg = styled.img`
height:55px;
margin-right:15px;
margin-left:15px;
`
export const StyledMarkersHolder = styled.div`
display:flex;
flex-direction:row;

`
export const StyledInputAndButtonWrapper = styled.div`
  display:flex;
  flex-direction:row;
  flex:4;
  margin-top:7px;
`
export const StyledMarkerGroupHeader = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
  margin:10px 40px;
  margin-right:120px;
  justify-content:center;
  flex:3;
`

export const StyledMarkerInputWrapper = styled.div`
  margin-top:5px;
`
export const StyledInternalDropDownWrapper = styled.div`
  width:250px;
  max-height: ${(props) => (props.isOpen ? 'auto' : '0')};
  transition: all 0.6ps ease-in-out;  
  position:absolute;
  top:30px;
  z-index:3;
  border:${(props) => (props.isOpen ? "2px solid #CBCACB":"0")};  
  background-color: white;
  box-sizing: border-box;
`
export const StyledDropDown = styled.div`
  display:flex;
  justify-content:center;
  height:35px;
  background-color:white;
  position:relative;
  border:2px solid #CBCACB;
  border-radius:6px;
  width:250px;
  margin-right:50px;
  margin-left:40px;
  margin-top:20px;
  cursor:pointer;7
  flex:1;
  font-weight:bold;  
`
export const StyledIconWrapper = styled.div`
  margin-top:4px;
  margin-right:10px; 
  align-self:flex-end
`
export const StyledDropDownText = styled.p`
  font-size:13px;
  text-align:left;
  color:#277575;
  width:100%;
  align-self:flex-start;
  margin-left:10px;
  padding-top:7px;
`
export const StyledDropDownWrapper = styled.div`
  display:flex;
  flex-direction:column;
`
export const StyledDropDownThemeLabel = styled.span` 
  margin-left: 10px;
  color: black;
`
export const StyledDropDownColorCount = styled.span`
  float: right;
  margin-right: 10px;
  color: black;

`
export const StyledDropDownOption = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  cursor:pointer;
  font-weight:bold;
  padding:5px;
  :first-child {
    margin-top:15px;
  }
  &:hover {
    background-color:#3979D3;
    span {
      color:#fff;
    }
  }
`
export const StyledMarkerTypeImageInItems = styled.img`  
  max-width:100%;
  position:relative;
`

export const StyledExpandAllButton = styled(StandardButton)`
  background: white;
  color:#277575;
  border: 1px solid #0078d7;
  border-radius:5px;
  text-transform:uppercase;
  margin-left:10px;
`;

export const StyledExpandAllButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-bottom:10px;
  margin-top:10px;
`;
