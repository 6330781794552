import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

import * as sharedModule from "../shared";

import rootSaga from "./root-saga";

import createRoutes from "./routes";

const sagaMiddleware = createSagaMiddleware();

function getComposeEnhancers() {
  if (
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // enables redux trace
      trace: true,
      traceLimit: 25
    }); // eslint-disable-line
  }
  return compose;
}

const createReducer = (initialState = {}) => {
  const composeEnhancers = getComposeEnhancers();
  const routes = createRoutes();

  const rootReducer = combineReducers({
    location: routes.reducer,
    ...sharedModule.reducers()
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      routes.enhancer,
      applyMiddleware(
        routes.middleware,
        sagaMiddleware,
        ...sharedModule.middlewares()
      )
    )
  );

  sagaMiddleware.run(rootSaga());
  store.dispatch(sharedModule.applicationMount(routes.initialDispatch));

  if (process.env.NODE_ENV === "development") {
    window.store = store;
  }

  return store;
};

export default createReducer;
