import React from "react";

const SquarePlusSolid = ({ size, color }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    fill={color}
    className="studlicon-squareplussolid"
  >
    <path d="M44.6428571,0 L5.35714286,0 C2.39955357,0 0,2.39955357 0,5.35714286 L0,44.6428571 C0,47.6004464 2.39955357,50 5.35714286,50 L44.6428571,50 C47.6004464,50 50,47.6004464 50,44.6428571 L50,5.35714286 C50,2.39955357 47.6004464,0 44.6428571,0 Z M41.0714286,28.125 C41.0714286,28.8616071 40.46875,29.4642857 39.7321429,29.4642857 L29.4642857,29.4642857 L29.4642857,39.7321429 C29.4642857,40.46875 28.8616071,41.0714286 28.125,41.0714286 L21.875,41.0714286 C21.1383929,41.0714286 20.5357143,40.46875 20.5357143,39.7321429 L20.5357143,29.4642857 L10.2678571,29.4642857 C9.53125,29.4642857 8.92857143,28.8616071 8.92857143,28.125 L8.92857143,21.875 C8.92857143,21.1383929 9.53125,20.5357143 10.2678571,20.5357143 L20.5357143,20.5357143 L20.5357143,10.2678571 C20.5357143,9.53125 21.1383929,8.92857143 21.875,8.92857143 L28.125,8.92857143 C28.8616071,8.92857143 29.4642857,9.53125 29.4642857,10.2678571 L29.4642857,20.5357143 L39.7321429,20.5357143 C40.46875,20.5357143 41.0714286,21.1383929 41.0714286,21.875 L41.0714286,28.125 Z" />
  </svg>
);

export default SquarePlusSolid;
