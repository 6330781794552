import styled from "styled-components";

export const StyledSortItemBody = styled.div`
  color: #999;
  font-size: 0.9em;
  display: flex;
  position: relative;
  margin-left: 10px;
`;

export const StyledPathOfStructureTreeContainer = styled.div`
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTooltipTree = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledTooltipBranch = styled.div`
  display: flex;
  height: 25px;
  align-items: center;

  ${({ index }) => `margin-left: ${index * 15}px;`}
`;
