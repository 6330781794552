import * as React from "react";
import {
  StyledFractionGrid,
  StyledCell,
  StyledCellValue
} from "./StyledFractionGrid";

const FractionGrid = props => {
  /**
   * Renders each row in the grid.
   * @param {*} row
   * @param {*} index
   */
  const renderRow = (row) => (
    row.map((cell, cellIndex) => (
      <StyledCell key={"key_" + cellIndex} borderBottom={row[cellIndex].borderBottom}>
        <StyledCellValue isVertical={row[cellIndex].isVertical} isHorizontal={row[cellIndex].isHorizontal} isInteger={row[cellIndex].isInteger}>
          {cell.value}
        </StyledCellValue>
      </StyledCell>
      )
    )
  )

  /**
   * Renders grid data
   */
  const renderData = () => props.grid.map(row => renderRow(row));

  return (
    <StyledFractionGrid rowLength={props.grid[0].length}>
      {renderData()}
    </StyledFractionGrid>
  );
};

export default FractionGrid;
