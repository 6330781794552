import React from "react";
import styled from "styled-components";

import Row from "./Row";
import Title from "./Title";
import Section from "./Section";
import ProductSearch from "../../../products/components/ProductSearch";

const StyledNavBar = styled.header`
  background-color: #344e71;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.baselineNumber * 2}px;
  width: 100%;
  height: 100%;
  ${props =>
    props.fixed
      ? `
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;
    `
      : null};
`;

const StyledProductSearch = styled(ProductSearch)`
  margin-left: 80px;
`;

const TitleText = styled.span`
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.04em;
  font-weight: 600;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
`;

const TopBar = ({ fixed, secondarySection, logoNav, isOnStartPage, title }) => (
  <StyledNavBar fixed={fixed}>
    <Row>
      <Title to={logoNav()}>Edda</Title>
      <Section start={1}>
        {!isOnStartPage ? <StyledProductSearch /> : null}
      </Section>
      <Section>
        {!isOnStartPage ? <TitleText>{title || ""}</TitleText> : null}
      </Section>
      <Section end={1}>{secondarySection}</Section>
    </Row>
  </StyledNavBar>
);

export default TopBar;
