import * as React from "react";
import PluginHoc from "../../../shared/PluginHoc";
import { StyledOrder, StyledOrderContent } from "./StyledOrder";
import {
  DATA,
  ITEMS,
  IMAGE,
  LABEL,
  MAX_ITEMS,
  MAX_ITEMS_IN_BOX
} from "../../../constants";
import { ItemContainer, OrderItem } from "./OrderItem";
import { mediaresourceStructure } from "../../../shared/MediaHandler/MediaHandler";
import {
  totalItemsInBox,
  maxTotalItems,
  rowSettingStyles,
  settingsData
} from "./OrderData";
import { Input } from "../Input";
import {
  StyledPrimaryButton,
  StyledEditInputWrapper,
  StyledAddNewItemRow,
  StyledAddNewItemWrapper
} from "./StyledOrder";
import { guid } from "../../../shared/helpers";
import SideBar from "../Sidebar/Sidebar";

const Order = props => {
  const [label, setQuestionLabel] = React.useState("");
  const [updatedItem, setUpdatedItem] = React.useState(false);
  const [mediaresources, setMediaResources] = React.useState({});
  const chipsRef = React.useRef(props.settings.chips);

  /**
   * This Effect listens to changes done to the chips setting.
   */
  React.useEffect(() => {
    if (props.settings.chips !== chipsRef.current) {
      chipsRef.current = props.settings.chips;
      resetItems(false);
    }
    // eslint-disable-next-line
  }, [props.settings.chips]);

  /**
   * Registers the validator after render
   */
  React.useEffect(() => {
    props.registerValidator([
      validateInUse,
      "Det måste finnas minst ett alternativ att ordna i övningen."
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Validator for total items in use, exercise cannot be saved unless there are
   * atleast one item in use.
   * @returns {*}
   */
  const validateInUse = componentData => {
    const totalInUse = componentData.items.filter(item => item.inUse);
    return totalInUse.length > 0;
  };

  /**
   * Save question label to state
   * @param label
   */
  const onQuestionLabelChange = text => setQuestionLabel(text);

  /**
   * Sets ID's for items
   * @param items
   */
  const setIdsForItems = items =>
    items.map((item, index) => ({ ...item, id: "id_" + index }));

  /**
   * Sets initial index for items
   * @param {*} items
   */
  const setInitalIndexForItems = items =>
    items.map((item, index) => ({ ...item, index: index }));

  /**
   * Displays alert when box is full
   */
  const showAlert = type =>
    type === MAX_ITEMS
      ? props.infoFlash(
          `Det finns för många svarsalternativ, välj 'utan plats' eller ta bort någon av svarsalternativen.`
        )
      : props.infoFlash(`Det kan maximalt finnas ${maxTotalItems} items.`);

  /**
   * When the chips settings change,
   * All items usage is restored to default.
   * @param {*} items
   */
  const resetItems = doStoreDraft => {
    const items = props.data.items.map(item => ({
      ...item,
      inUse: false,
      inOrderBox: false
    }));
    if (doStoreDraft) {
      props.storeDraft(props.draftTarget);
    }

    props.updateData(ITEMS, items, DATA);
  };

  const callbackUpdatedItem = () => {
    setUpdatedItem(false);
  }

  /**
   * Function that runs after items has moved.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = [...props.data.items];
    items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);
    setUpdatedItem(true);

    props.storeDraft(props.draftTarget);
    props.updateData(ITEMS, setInitalIndexForItems(items), DATA);
  };

  /**
   * open media library and replace image or mediaresource with a new media
   * @param index index of item to replace image in
   */
  const onChangeMedia = index => {
    const { openMediaModal = () => {} } = props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === IMAGE) {
        onItemImageChange(index, selected[0]);
      }
    });
  };

  /**
   * Open mediaresource library and replaces image or mediaresource with a new mediaresource
   * @param index index of item to replace mediaresource in
   */
  const onChangeMediaResource = index => {
    const { openMediaresourceModal = () => {} } = props.actions || {};
    openMediaresourceModal(res => {
      if (res.length > 0) {
        onItemImageChange(index, mediaresourceStructure(res[0]));
        setMediaResources({ ...mediaresources, [res[0].id]: res[0].data });
      }
    });
  };

  /**
   * Opens media resource list
   */
  const openMediaResourceList = () => {
    const { openMediaresourceModal = () => {} } = props.actions || {};
    openMediaresourceModal(res => {
      addNewItem({ multiSVG: mediaresourceStructure(res[0]) });
      setMediaResources({ ...mediaresources, [res[0].id]: res[0].data });
    });
  };

  /**
   * Opens media library
   */
  const openMediaLibrary = () => {
    const { openMediaModal = () => {} } = props.actions || {};
    openMediaModal(selected => {
      if (!selected[0]) return;
      if (selected[0].type === IMAGE) {
        addNewItem({ image: { src: selected[0].url } });
      }
    });
  };

  /**
   * Removes image / mediaresource from item
   */
  const removeItemImage = index => () => {
    const { items } = props.data;
    let newItem = { ...items[index], label: "" };

    delete newItem.image;
    delete newItem.mediaresource;

    props.storeDraft(props.draftTarget);
    props.updateData(
      ITEMS,
      setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
      DATA
    );
  };

  /**
   * Removes item
   * @param index
   */
  const removeItem = index => {
    const { items } = props.data;
    const newItems = setIdsForItems(items.filter((_, i) => i !== index));
    const newUsedItems = newItems.filter(elem => elem.inUse);

    setUpdatedItem(true);
    props.storeDraft(props.draftTarget);
    props.updateData(
      null,
      { items: setInitalIndexForItems(newItems), maxItems: newUsedItems.length },
      DATA
    );
  };

  /**
   * Handles image item change
   * @param index
   * @param media
   */
  const onItemImageChange = (index, media) => {
    const { items } = props.data;
    let newItem = { ...items[index] };

    delete newItem.label;

    if (media.mediaresource) {
      newItem = { ...newItem, ...media };
      if (newItem.image) {
        delete newItem.image;
      }
      props.storeDraft(props.draftTarget);
      props.updateData(
        ITEMS,
        setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
        DATA
      );
    }
    if (media.url) {
      newItem.image = {
        src: media.url
      };
      if (newItem.mediaresource) {
        delete newItem.mediaresource;
      }

      props.storeDraft(props.draftTarget);
      props.updateData(
        ITEMS,
        setIdsForItems(items.map((item, i) => (i === index ? newItem : item))),
        DATA
      );
    }
  };

  /**
   * Duplicates item with index
   * @param index
   */
  const onDuplicateItem = index => {
    const items = [...props.data.items];

    if (items.length < maxTotalItems) {
      items.splice(index, 0, { ...props.data.items[index], id: guid() });

      const newItems = setInitalIndexForItems(items);
      const newUsedItems = newItems.filter(elem => elem.inUse);

      props.storeDraft(props.draftTarget);
      props.updateData(
        null,
        { items: newItems, maxItems: newUsedItems.length },
        DATA
      );
    } else {
      showAlert(MAX_ITEMS_IN_BOX);
    }
  };

  /**
   * Returns true or false depending on if there is room
   * in the "Ordered" items box.
   */
  const hasSpaceInBox = () => {
    const { items } = props.data;
    const itemsInUse = items.filter(item => item.inUse === true);

    return itemsInUse.length < totalItemsInBox[props.settings.chips];
  };

  /**
   * Adds new item
   * @param content
   */
  const addNewItem = content => {
    if (label || content.image || content.multiSVG) {
      const { items } = props.data;

      if (items.length < maxTotalItems) {
        let newItem = {
          inUse: hasSpaceInBox(),
          inOrderBox: false
        };

        if (label && !(content.image || content.multiSVG)) {
          newItem[LABEL] = label;
        }
        if (content.image) {
          newItem[IMAGE] = content.image;
        }
        if (content.multiSVG) {
          newItem = { ...newItem, ...content.multiSVG };
        }

        setQuestionLabel("");
        const newItems = setInitalIndexForItems(
          items.concat({ ...newItem, id: guid() })
        );
        const newUsedItems = newItems.filter(elem => elem.inUse);

        props.storeDraft(props.draftTarget);
        props.updateData(
          null,
          { items: newItems, maxItems: newUsedItems.length },
          DATA
        );
      } else {
        showAlert(MAX_ITEMS_IN_BOX);
      }
    }
  };

  /**
   * Handles edit item content
   * @param index
   * @param content
   */
  const editItemContent = (index, content) => {
    const { items } = props.data;
    let item = { ...items[index] };
    const usedItems = items.filter(elem => elem.inUse);
    const maxItems = totalItemsInBox[props.settings.chips];
    const key = Object.keys(content)[0];

    switch (key) {
      case "label":
        item.label = content.label;
        break;
      case "inUse":
        if (content.inUse === true) {
          if (usedItems.length >= maxItems) {
            showAlert(MAX_ITEMS);
            return;
          }
        }
        item.inOrderBox = false;
        item.inUse = content.inUse;
        break;
      case "inOrderBox":
        if (item.inUse === false) {
          if (usedItems.length >= maxItems) {
            showAlert(MAX_ITEMS);
            return;
          }
        }
        item.inUse = true;
        item.inOrderBox = content.inOrderBox;
        break;
      default:
      // not needed
    }

    const newItems = items.map((element, i) => (i === index ? item : element));
    const newUsedItems = newItems.filter(elem => elem.inUse);

    props.storeDraft(props.draftTarget);
    props.updateData(
      null,
      { items: newItems, maxItems: newUsedItems.length },
      DATA
    );
  };

  /**
   * Renders input for new items
   * @returns {*}
   */
  const renderNewItemInput = () => (
    <StyledEditInputWrapper>
      <Input
        placeholder="Skriv in värde eller välj bild alt. resurs"
        value={label}
        onChange={onQuestionLabelChange}
        onEnter={addNewItem}
      />
    </StyledEditInputWrapper>
  );

  /**
   * Renders add media button
   * @returns {*}
   */
  const renderAddMediaButton = () => (
    <StyledPrimaryButton studlicon="Media" onClick={openMediaLibrary}>
      Lägg till Enskild bild
    </StyledPrimaryButton>
  );

  /**
   * Renders add media resource button
   * @returns {*}
   */
  const renderAddMediaResourceButton = () => (
    <StyledPrimaryButton
      studlicon="ImageResourceIcon"
      outline
      onClick={openMediaResourceList}
    >
      Lägg till Sammansatt bild
    </StyledPrimaryButton>
  );

  /**
   * Renders add item if is not read only state
   * @returns {null}
   */
  const renderAddItem = () => {
    if (!!props.readOnly) {
      return null;
    }
    return (
      <StyledAddNewItemWrapper>
        <StyledAddNewItemRow>
          {renderNewItemInput()}
          {renderAddMediaButton()}
          {renderAddMediaResourceButton()}
        </StyledAddNewItemRow>
      </StyledAddNewItemWrapper>
    );
  };

  /**
   * Render items
   */
  const renderItems = () => {
    const {
      actions,
      resources: propsMediaResources,
      data: { items }
    } = props;
    const resources = { ...propsMediaResources, ...mediaresources };

    return items
      ? items.map((item, i) => (
          <OrderItem
            key={"alt" + i}
            indx={i}
            index={i}
            data={item}
            actions={actions}
            onRemoveFn={removeItem}
            resources={resources}
            onImageChange={onItemImageChange}
            editBtnFn={editItemContent}
            onMediaChange={onChangeMedia}
            onMediaResourceChange={onChangeMediaResource}
            onDuplicateItem={onDuplicateItem}
            onRemoveImage={removeItemImage}
            draftTarget={props.draftTarget}
            storeDraft={props.storeDraft}
            updatedItem={updatedItem}
            callbackUpdatedItem={callbackUpdatedItem}

          />
        ))
      : null;
  };

  /**
   * Render Order sidebar
   * @returns {*}
   */
  const renderOrderSidebar = () => (
    <SideBar
      updateData={props.updateData}
      storeDraft={props.storeDraft}
      draftTarget={props.draftTarget}
      settings={props.settings}
      data={settingsData}
      rowSettingStyles={rowSettingStyles}
    />
  );

  /**
   * Renders items for Sort machine
   */
  const renderOrderItems = () => (
    <ItemContainer useDragHandle onSortEnd={onSortEnd}>
      {renderItems()}
    </ItemContainer>
  );

  return (
    <StyledOrder>
      <StyledOrderContent>
        {renderOrderItems()}
        {renderAddItem()}
      </StyledOrderContent>
      {renderOrderSidebar()}
    </StyledOrder>
  );
};

export default PluginHoc({
  Component: Order
});
