import { createSelector } from "reselect";

export const getCurrentProductType = createSelector(
  (state) => state.Product.get("types"),
  (state) => state.Product.get("selectedItem"),
  (types, entity) => {
    if (!entity) {
      return "adapt";
    }

    const productType = types.find((t) => t.get("id") === entity.typeId);

    if (!productType) {
      return "adapt";
    }

    return productType.get("type");
  }
);

export const getProductInfo = createSelector(
  (state) => state.Product.get("selectedItem"),
  (state) => state.Product.get("subjects"),
  (state) => state.Product.get("stages"),
  (item, subjects, stages) => [
    item,
    subjects && subjects.toJS(),
    stages && stages.toJS(),
  ]
);

export const getAllProductsList = (state) =>
  state.Product.get("list").toArray();

export const getAllProductsListLength = (state) =>
  state.Product.get("list").toArray().length;

export const getTwinProduct = createSelector(
  (state) => state.Product.get("selectedItem").twinProductId,
  (state) => state.Product.get("list"),
  (twinId, products) => products.find((p) => p.id === twinId)
);

export const getProductsOfCurrentProductType = createSelector(
  (state) => state.Product.get("list"),
  (state) => state.Product.get("selectedItem"),
  (products, item) =>
    products
      .filter((p) => p.id !== item.id)
      .filter((p) => p.typeId === item.typeId)
      .toArray()
);

export const getProductAvailableForTeachersGuide = createSelector(
  (state) => state.Product.get("selectedItem"),
  getProductsOfCurrentProductType,
  (item, products) =>
    products.filter((p) => (p.teacherGuides || []).indexOf(item.id) === -1)
);

export const getProductsForTeachersGuide = createSelector(
  (state) => state.Product.get("selectedItem").id,
  (state) => state.Product.get("list"),
  (entityId, list) =>
    list.filter((p) => (p.teacherGuides || []).indexOf(entityId) > -1).toArray()
);

export const getProductTypes = (state) => state.Product.get("types");

export const selectPermissions = (state) => state.Product.get("permissions");

export const selectProductFilesStatus = (state) => {
  return state.Product.get("productFilesPublishStatus");
};

export const selectPublishingStatus = (state) => ({
  toPreview: state.Product.get("isPublishingToPreview"),
  toProd: state.Product.get("isPublishingToProd"),
});

export const selectProductFilePublishHistory = (state) => {
  return state.Product.get("productFilesPublishHistory");
};

export const getOmniaBookType = (state) =>
  state.Product.get("types").find((t) => t.get("type") === "omnia_book");

export const getOmniaCourseType = (state) =>
  state.Product.get("types").find((t) => t.get("type") === "omnia_course");

export const getOmniaBiafType = (state) =>
  state.Product.get("types").find((t) => t.get("type") === "omnia_biaf");

export const getDillType = (state) =>
  state.Product.get("types").find((t) => t.get("type") === "dill");

export const selectProductsForRelatedProductsSelector = createSelector(
  (state) => state.Product.get("list"),
  (state) => state.Product.get("selectedItem").id,
  getOmniaBookType,
  getOmniaCourseType,
  getDillType,
  getProductInfo,
  (products, entityId, omniaBookType, omiaCourseType, dillType) => {
    return products
      .filter(
        (p) =>
          p.id !== entityId &&
          (p.typeId === omniaBookType.get("id") ||
            p.typeId === omiaCourseType.get("id") ||
            p.typeId === dillType.get("id"))
      )
      .toArray();
  }
);

export const getRelatedProducts = createSelector(
  (state) => state.Product.get("list"),
  (state) => state.Product.get("selectedItem").relatedEntities,
  (products, relatedProductIds) => {
    return products.size === 0
      ? []
      : relatedProductIds.map((id) => products.find((p) => p.id === id));
  }
);

export const getClassroomgroupsWhereProductExist = createSelector(
  (state) => state.Product.get("selectedItem"),
  (product) => product.classroomgroups || []
);

export const getSesamAvailable = (state) =>
  state.Product.get("availableSesamgroups");

export const getProductTaxonomyLevels = createSelector(
  (state) => state.Product.get("selectedItem"),
  (product) => product.levels || []
);

export const getProductTaxonomySubjects = createSelector(
  (state) => state.Product.get("selectedItem"),
  (product) => product.subjects || []
);

export const getProductMediaFiles = state => state.Product.get("mediaFiles");

export const getBiafProducts = createSelector(
  state => state.Product.get("list"),
  getOmniaBiafType,
  (products, type) => {
    const biafTypeId = type.get("id");

    return products.filter(p => p.typeId === biafTypeId).toArray();
  }
);

export const getParentEntities = createSelector(
  state => state.Product.get("list"),
  state => state.Product.get("selectedItem").parentRelatedEntities,
  (products, parentIds) => {
    return products.size === 0
      ? []
      : parentIds.map(id => products.find(p => p.id === id));
  }
);
