import { emptyBlocks as textBlocks } from "./createTextPlugin";
import { emptyBlocks as blanksBlocks } from "./createBlanksPlugin";
import { emptyBlocks as imageBlocks } from "./createImagePlugin";
import { emptyBlocks as audioBlocks } from "./createAudioPlugin";
import { emptyBlocks as multipleChoiceBlocks } from "./createMultipleChoicePlugin";
import { emptyBlocks as sortMachineBlocks } from "./createSortMachinePlugin";
import { emptyBlocks as orderBlocks } from "./createOrderPlugin";
import { emptyBlocks as lineupBlocks } from "./createLineupPlugin";
import { emptyBlocks as chartBlocks } from "./createChartPlugin";
import { emptyBlocks as fractionBlocks } from "./createFractionPlugin";
import { emptyBlocks as numberLineBlocks } from "./createNumberLinePlugin";
import { emptyBlocks as videoBlocks } from "./createVideoPlugin";
import { emptyBlocks as aboutBlocks } from "./createAboutPlugin";
import { emptyBlocks as helpVideoBlocks } from "./createHelpVideoPlugin";
import { emptyBlocks as mathWordBlocks } from "./createMathWordPlugin";
import { emptyBlocks as helpAudioBlocks } from "./createHelpAudioPlugin";
import { emptyBlocks as helpImageBlocks } from "./createHelpImagePlugin";
import { emptyBlocks as tipsBlocks } from "./createTipsPlugin";
import { emptyBlocks as aidaTextBlocks } from "./createAidaTextPlugin";
import { emptyBlocks as imageSliderBlocks } from "./createImageSliderPlugin";

import {
  MEDIA_RESOURCE,
  RESOURCE,
  HELP_RESOURCE,
  translation
} from "../constants";

export * from "./createTextPlugin";
export * from "./createImagePlugin";
export * from "./createAudioPlugin";
export * from "./createBlanksPlugin";
export * from "./createMultipleChoicePlugin";
export * from "./createSortMachinePlugin";
export * from "./createOrderPlugin";
export * from "./createLineupPlugin";
export * from "./createChartPlugin";
export * from "./createFractionPlugin";
export * from "./createJSONPlugin";
export * from "./createNumberLinePlugin";
export * from "./createVideoPlugin";
export * from "./createAboutPlugin";
export * from "./createHelpVideoPlugin";
export * from "./createMathWordPlugin";
export * from "./createHelpAudioPlugin";
export * from "./createHelpImagePlugin";
export * from "./createTipsPlugin";
export * from "./createMultiImagePlugin";
export * from "./createAidaTextPlugin";
export * from "./createImageSliderPlugin";

const emptyResourceBlock = {
  type: RESOURCE,
  data: {
    id: null
  }
};

const emptyMediaResourceBlock = {
  type: MEDIA_RESOURCE,
  data: {
    id: null
  }
};

const emptyHelpResourceBlock = {
  type: HELP_RESOURCE,
  data: {
    id: null
  }
};

export const standardComponents = {
  ...textBlocks,
  ...blanksBlocks,
  ...imageBlocks,
  ...audioBlocks,
  ...multipleChoiceBlocks,
  ...sortMachineBlocks,
  ...orderBlocks,
  ...lineupBlocks,
  ...chartBlocks,
  ...fractionBlocks,
  ...numberLineBlocks,
  ...videoBlocks
};

export const nonOperativeComponents = {
  ...textBlocks,
  ...imageBlocks,
  ...audioBlocks,
  ...videoBlocks
};

export const aidaComponents = {
  ...aidaTextBlocks,
  ...imageBlocks,
  ...audioBlocks,
  ...videoBlocks,
  ...imageSliderBlocks
};

export const mediaResourceComponent = {
  [MEDIA_RESOURCE]: emptyMediaResourceBlock
};

export const resourceComponent = {
  [RESOURCE]: emptyResourceBlock
};

export const emptyBlocks = {
  ...standardComponents,
  ...mediaResourceComponent,
  ...resourceComponent
};

export const helpComponents = {
  ...aboutBlocks,
  ...helpVideoBlocks,
  ...mathWordBlocks,
  ...helpAudioBlocks,
  ...helpImageBlocks,
  ...tipsBlocks
};

export const helpResourceComponent = {
  [HELP_RESOURCE]: emptyHelpResourceBlock
};

export const emptyHelpBlocks = {
  ...helpComponents,
  ...helpResourceComponent
};

export const filterComponents = onlyHelpComponents => {
  const { helpresource, about, ...modifiedHelpBlocks } = { ...emptyHelpBlocks };

  const components = onlyHelpComponents
    ? { ...modifiedHelpBlocks }
    : { ...emptyBlocks, ...emptyHelpBlocks };
  return Object.entries({
    ...components
  }).map(([key]) => ({ id: key, title: translation[key] }));
};

export const filterAidaComponents = () => {
  return Object.entries({
    ...aidaComponents
  }).map(([key]) => ({ id: key, title: translation[key] }));
};
