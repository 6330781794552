import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Trash2 as Trash } from "react-feather";

import {
  saveProduct,
  removeFromTeachersGuide,
  addToTeachersGuide
} from "../api/actions";
import {
  getProductInfo,
  getProductsForTeachersGuide,
  getProductAvailableForTeachersGuide
} from "../store/selectors";
import IconButton from "./IconButton";
import ProductSearch from "./ProductSearch";
import Checkbox from "./Checkbox";
import Confirm from "../../shared/components/Confirm";

const Search = styled(ProductSearch)`
  width: 100%;
  margin-top: 0.5rem;
  & > input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.32);
    box-shadow: none;
    margin-top: 0.25rem;

    &:active,
    &:focus {
      border: 2px solid #006cc8;
    }

    &:hover:not(&:active, &:focus) {
      border: 1px solid rgba(0, 0, 0, 0.52);
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  &:last-child {
    margin: 0.5rem 0 0 0;
  }
`;

const Text = styled.p`
  margin: 0;
`;

const TeachersGuide = () => {
  const dispatch = useDispatch();
  const [entity] = useSelector(getProductInfo);
  const included = useSelector(getProductsForTeachersGuide);
  const [showConfirmRemoveChild, setRemoveChild] = useState(undefined);
  const [showConfirmRemove, setRemove] = useState(false);

  const setProductAsTeacherGuide = checked => {
    if (checked) {
      dispatch(
        saveProduct({
          ...entity,
          isTeacherGuide: checked
        })
      );
      return;
    }

    setRemove(true);
  };

  const addProduct = entityId =>
    dispatch(addToTeachersGuide(entity.id, entityId));

  const removeProduct = entityId =>
    dispatch(removeFromTeachersGuide(entity.id, entityId));

  return (
    <Wrapper>
      <Checkbox
        label="Lärarhandledning"
        checked={entity.isTeacherGuide}
        onChange={setProductAsTeacherGuide}
        disabled={entity.isTeacherGuide && included.length > 0}
      />
      {entity.isTeacherGuide && (
        <Search
          selector={getProductAvailableForTeachersGuide}
          placeholder="Sök produkt"
          onSelect={p => addProduct(p.id)}
        />
      )}
      {entity.isTeacherGuide &&
        included.map(p => (
          <ProductWrapper key={p.id}>
            <IconButton
              aria-label="Ta bort produkt från lärarhandledning"
              onClick={() => setRemoveChild(p.id)}
            >
              <Trash width="16px" height="16px" />
            </IconButton>
            <Text>{p.sesam.name}</Text>
          </ProductWrapper>
        ))}
      {showConfirmRemoveChild && (
        <Confirm
          confirmBtnText="Koppla från"
          onClose={go => {
            if (go) {
              removeProduct(showConfirmRemoveChild);
            }

            setRemoveChild(false);
          }}
        >
          <p>
            Produkten kan finnas i klassrum som kunder har skapat och kommer då
            att påverka dessa kunders klassrum.
          </p>
          <p>
            Är du säker på att du vill koppla bort denna elevprodukt från den
            här lärarhandledningen?
          </p>
        </Confirm>
      )}
      {showConfirmRemove && (
        <Confirm
          confirmBtnText="Fortsätt"
          onClose={go => {
            if (go) {
              dispatch(
                saveProduct({
                  ...entity,
                  isTeacherGuide: false
                })
              );
            }

            setRemove(false);
          }}
        >
          <p>
            Om du fortsätter, kommer produkten inte längre att kategoriseras som
            en lärarhandledning, utan visas som en elevprodukt i befintliga
            klassrum.
          </p>
        </Confirm>
      )}
    </Wrapper>
  );
};

export default TeachersGuide;
