import * as React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import Tooltip from "react-tooltip-lite";
import {
  ADVENTURES,
  DELETE,
  DROPDOWN,
  EDIT,
  INPUT,
  PUBLISH,
  REJECT,
  REQUEST_REVIEW,
  SET_REVIEWED,
  translation,
  UNPUBLISH
} from "../../constants";
import { STATUS } from "../../posts/api/constants";
import { PrimaryButton, StandardButton } from "../../shared";
import Checkbox from "../../shared/components/Checkbox";
import DropDown, { Item } from "../../shared/components/DropDown";
import Input from "../../shared/components/Input";
import Link from "../../shared/components/Link";
import Modal from "../../shared/components/Modal/Modal";
import { RadioButton } from "../../shared/components/RadioButton";
import { GripHandle } from "../../shared/components/StudliIcons";
import { Warning } from "../../shared/components/StudliIcons";
import { StyledTextEditorContainer } from "../../shared/components/Text/StyledText";
import TextEditor from "../../shared/components/Text/TextEditor";
import { isPositiveInteger, shorten } from "../../shared/helpers";
import HasPermission from "../../shared/Permissions";
import { TextParser } from "../../shared/TextTransformerService";
import { validateRow, validateItem, rowData, modalText } from "./ListHelpers";
import {
  StyledListItem,
  StyledListContainer,
  StyledListItemContentWrapper,
  StyledColumnWrapper,
  StyledDragHandleWrapper,
  StyledButton,
  StyledUtilityButtonsContainer,
  StyledListItemHeaderLink,
  StyledListItemHeaderLabel,
  StyledListItemStatusLabel,
  StyledEditInputWrapper,
  StyledListItemContent,
  StyledFormLabel,
  StyledForm,
  StyledFormWrapper,
  StyledTextEditorWrapper,
  StyledLabelContainer,
  StyledInputContainer,
  StyledButtonsContainer,
  StyledSquareButton,
  StyledFeedback,
  customStyles,
  buttonStyles,
  StyledRemovePrompt,
  StyledButtonContainer,
  StyledRemoveHeading,
  StyledActionButton,
  StyledRemoveText,
  StyledHeadingContainer,
  StyledAudioContainer,
  StyledImageContainer,
  StyledButtonsSpacer,
  StyledButtonsSpacerLine,
  StyledDropDownContainer,
  StyledDropDownWrapper,
  dropDownCustomStyles,
  StyledDropDownButtonsWrapper,
  StyledAdventureWrapper,
  StyledAddMarkerButtonContainer,
  StyledWarningText,
  StyledCheckBoxWrapper,
  StyledDropDownRowContainer,
  StyledMarkerCountContainer
} from "./StyledList";
import UploadMedia from "../../shared/components/UploadMedia";

export const ListItem = SortableElement(props => {
  const { itemData, grades, entityDraft, mediaData, setMediaData, workflow, readyForPublish } = props;
  const textHandler = new TextParser();
  const [editable, setEditable] = React.useState(false);
  const [showModal, setShowModal] = React.useState({ type: '', open: false });

  /**
   * Effect for opening the publish modal.
   */
  React.useEffect(() => {
    if (readyForPublish.some(item => item.id === itemData.id)) {
      setShowModal({ type: PUBLISH, open: true });
    }
  }, [readyForPublish, itemData])

  /**
   * Effect for opening a selected item
   */
  React.useEffect(() => {
    entityDraft && entityDraft.id === itemData.id
      ? setEditable(true)
      : setEditable(false);
  }, [entityDraft, itemData]);

  /**
   * Callback for removing an item
   */
  const removeItem = () => {
    if (!showModal.open) {
      setShowModal({ type: DELETE, open: true });
    } else {
      props.onRemoveItem(itemData.id);
      setShowModal({ type: DELETE, open: false });
    }
  };

  /**
   * Callback for resetting the publish list
   */
  const resetPublishList = () => props.onResetPublishList([itemData.id]);

  /**
   * Callback for publishing a node
   */
  const aboutToPublishItem = () => props.onGetPublishList([itemData.id]);

  /**
   * Callback for un publishing a node
   */
  const unPublishNode = () => props.onUnPublish(itemData.id);

  /**
   * Callback for reviewing a node
   */
  const reviewNode = () => props.onReview([itemData.id]);

  /**
  * Callback for request review for a node
  */
  const requestReviewNode = () => props.onRequestReview([itemData.id]);

  /**
   * Callback for rejecting a node
   */
  const rejectNode = () => props.onReject([itemData.id]);

  /**
   * Closes modal and resets publish list if any is found.
   */
  const closeModal = () => {
    setShowModal('', false)
    readyForPublish.some(node => node.id === itemData.id) && resetPublishList()
  }

  /**
   * Callback for publishing a node
   */
  const publishItem = () => {
    props.onPublish([itemData.id])
    resetPublishList()
  }

  /**
   * Callback for changes in the form
   * @param {*} key
   */
  const onChange = key => data => {
    const updatedData = { ...itemData, [key]: data };
    const validation = validateItem(updatedData,props.lastItem, mediaData);
    props.updateEntityDraft(
      updatedData,
      validation,
      mediaData
    );
  };

  /**
   * Callback for editing an item
   */
  const editItem = () => {
    if (props.draftState === "clean") {
      if (!editable) {
        props.setEntityDraft(itemData);
        setEditable(true);
      } else {
        props.setEntityDraft({});
        setEditable(false);
      }
    }
  };

  /**
   * Callback for texteditor
   * @param {*} key
   * @param {*} textEditorState
   */
  const onTextChange = (key, textEditorState) => editorState => {
    const { didChange, text } = textHandler.onTextChange(
      editorState,
      textEditorState
    );
    let currentText = textHandler.getRaw(editorState);
    let change = true;

    if (text) {
      change = text === currentText ? true : false;
    }

    if (didChange && change) {
      onChange(key)(text ? text : "");
    }
  };

  /**
   * Callback for updating order
   * @param {*} key
   * @param {*} value
   */
  const updateOrder = (key, value) => () => onChange(key)(value);

  /**
   * Callback for updating grades
   * @param {*} key
   * @param {*} id
   */
  const updateGrades = (key, id) => () => {
    // Temporary solution
    if (id === 1 || id === 2 || id === 3) {
      if (itemData.grade_ids && itemData.grade_ids.includes(id)) {
        onChange(key)([]);
      } else {
        onChange(key)([1, 2, 3]);
      }
    } else {
      if (itemData.grade_ids && itemData.grade_ids.includes(id)) {
        onChange(key)([]);
      } else {
        onChange(key)([4, 5, 6]);
      }
    }

    /* Should use this solution in the future
    if(itemData.grade_ids && itemData.grade_ids.includes(id)) {
      const arr = itemData.grade_ids.filter(gradeId => gradeId !== id)
      onChange(key)([...arr])
    } else {
      onChange(key)(
        itemData.grade_ids ? [...itemData.grade_ids, id].sort() : [id]
      );
    }
    */
  };

  const mediaUploadCallback = ({ files, type }) => {
    setMediaData({ ...mediaData, [type]: { name: files.name, files } });
    onChange(type)(files.name);
  };

  /**
   * Render add audio button
   */
  const renderAddImageButton = key => (
    <UploadMedia
      multiple={false}
      type={key}
      skin="primary"
      buttonstate="selected"
      studlicon="Media"
      callback={mediaUploadCallback}
    />
  );

  /**
   * Render add audio button
   */
  const renderAddAudioButton = key => (
    <UploadMedia
      multiple={false}
      type={key}
      skin="primary"
      buttonstate="selected"
      studlicon="Sound"
      callback={mediaUploadCallback}
    />
  );

  const removeMedia = key => () => {
    setMediaData({ ...mediaData, [key]: { name: "", files: {} } });
    onChange(key)("");
  };

  /**
   *  Render audio controls
   */
  const renderUploadedAudio = key => audio => {
    return audio ? (
      <StyledAudioContainer>
        <audio
          src={audio}
          controls
          onError={event => addLocalUrl(event, mediaData[key].files)}
        />
      </StyledAudioContainer>
    ) : null;
  };

  /**
   *  Render selected image
   */
  const renderSelectedImage = image => {
    /* eslint-disable jsx-a11y/alt-text */
    return image ? (
      <StyledImageContainer>
        <img
          src={image}
          alt=""
          onError={event => addLocalUrl(event, mediaData.image_url.files)}
        />
      </StyledImageContainer>
    ) : null;
  };

  /**
   * Create an url from file
   * @param {*} event
   * @param {*} file
   */
  const addLocalUrl = (event, file) => {
    if (file instanceof File) {
      event.target.src = URL.createObjectURL(file);
    }
  };

  /**
   * Render revert audio button
   */
  const renderRemoveButton = (disabled, callback) => (
    <StyledSquareButton disabled={disabled} studlicon="Trash" onClick={callback} />
  );

  /**
   * Render toggle button
   * @param {*} button
   * @param {*} state
   * @param {*} callback
   */
  const renderToggleButton = (button, state, callback) => (
    <RadioButton
      key={button.id}
      isActive={state}
      content={button.label}
      buttonStyle={buttonStyles}
      overrideStyles={customStyles}
      callback={callback}
    ></RadioButton>
  )

  /**
   * Render Image button group
   */
  const renderImageButtons = key => () => (
    <StyledButtonsContainer>
      {renderAddImageButton(key)}
      {renderRemoveButton(!itemData[key], removeMedia(key))}
    </StyledButtonsContainer>
  );

  /**
   * Render Audio button group
   */
  const renderAudioButtons = key => () => (
    <StyledButtonsContainer>
      {renderAddAudioButton(key)}
      {renderRemoveButton(!itemData[key], removeMedia(key))}
    </StyledButtonsContainer>
  );

  /**
   * Render Grades button group
   * @param {*} key
   */
  const renderGradeButtons = key => () => (
    <StyledButtonsContainer>
      {grades.map(grade =>
        renderToggleButton(
          grade,
          itemData.grade_ids && itemData.grade_ids.includes(grade.id),
          updateGrades(key, grade.id)
        )
      )}
    </StyledButtonsContainer>
  );

  /**
   * Renders checkbox
   */
  const renderCheckBox = (key) => () => (
    <StyledCheckBoxWrapper>
      <Checkbox isChecked={itemData[key] ? itemData[key] : false} onClick={updateOrder(key, !itemData[key])} />
    </StyledCheckBoxWrapper>
  );

  /**
   * Render utility button
   * @param {*} callback
   * @param {*} icon
   */
  const renderUtilityButton = (callback, icon, content) => (
    <StyledColumnWrapper>
      <Tooltip content={content} useDefaultStyles={true}>
        <StyledButton
          disabled={
            props.draftState === "dirty" ||
            (!itemData.is_valid && content === "Skicka till granskning")
          }
          onClick={callback}
          studlicon={icon}
        />
      </Tooltip>
    </StyledColumnWrapper>
  );

  /**
   * Render feedback mark for validation of row
   * @param {*} data
   */
  const renderFeedBackRow = (data, dropdown = false) => (
    <StyledFeedback
      icon={validateRow(data, dropdown) ? "Check" : "AlertTriangle"}
      color={validateRow(data, dropdown) ? "green" : "red"}
    />
  );

  /**
   * Render feedback mark for validation of item
   * @param {*} data
   */
  const renderFeedBackItem = () => (
    <Tooltip content={itemData.is_valid ? "Validering OK" : "Validering ej OK"} useDefaultStyles={true}>
      <StyledFeedback
        icon={itemData.is_valid ? "Check" : "Slash"}
        color="white"
      />
    </Tooltip>
  );

  /**
   * Render action component depending on which action should be rendered.
   * @param action
   * @returns { JSX.component }
   */
  const renderAction = action => {
    switch (action) {
      case REQUEST_REVIEW:
        return renderUtilityButton(
          requestReviewNode,
          "ReadyCheckMark",
          'Skicka till granskning'
        );
      case SET_REVIEWED:
        return renderUtilityButton(
          reviewNode,
          "CloudCheckMark",
          "Godkänn granskning"
        );
      case REJECT:
        return renderUtilityButton(
          rejectNode,
          "Close",
          "Avböj"
        );
      case PUBLISH:
        return renderUtilityButton(
          aboutToPublishItem,
          "CloudFilled",
          "Publicera"
        );
      case UNPUBLISH:
        return renderUtilityButton(
          unPublishNode,
          "CloudOffline",
          "Avpublicera"
        );
      case EDIT:
        return renderUtilityButton(
          editItem,
          "PencilFilled",
          "Ändra"
        );
      case DELETE:
        return renderUtilityButton(
          removeItem,
          "TrashFilled",
          "Ta bort"
        );
      default:
        return null;
    }
  };

  /**
   * Render first letter of a nodes status
   */
  const renderNodeStatus = () => (
    <StyledColumnWrapper>
      <Tooltip
        content={itemData.status ? STATUS.find(item => item.id === itemData.status).title.toUpperCase() : 'UTKAST'}
        useDefaultStyles={true}>
        <StyledListItemStatusLabel>
          {itemData.status ? itemData.status.charAt(0).toUpperCase() : 'D'}
        </StyledListItemStatusLabel>
      </Tooltip>
    </StyledColumnWrapper>
  );

  /**
   * Renders utility buttons group dependent on the actions allowed for the item
   */
  const renderUtilityButtons = (actions) => {
    const actionButtons = actions.map(action => (
      <HasPermission
        key={action}
        component={renderAction(action)}
        section={ADVENTURES.toLowerCase()}
        permission={action}
      />
    ));

    const firstButtonSpacer = (
      <HasPermission
        component={renderButtonsSpacer()}
        section={ADVENTURES.toLowerCase()}
        multiplePermissions={actions}
      />
    );

    return (
      <StyledUtilityButtonsContainer>
        {firstButtonSpacer}
        {actionButtons}
        {renderButtonsSpacer()}
        {renderFeedBackItem()}
        {renderButtonsSpacer()}
      </StyledUtilityButtonsContainer>
    );
  }

  /**
   * Renders a buttons spacer line for give a bit of air between buttons
   * @returns {*}
   */
  const renderButtonsSpacer = () => (
    <StyledButtonsSpacer>
      <StyledButtonsSpacerLine />
    </StyledButtonsSpacer>
  );

  /**
   * Render texteditor
   * @param {*} key
   * @param {*} title
   * @param {*} data
   * @param {*} callback
   */
  const renderTextEditor = ({ key, title, data, callback }) => {
    const textEditorState = textHandler.getStateFromData(data);
    return (
      <StyledForm key={key}>
        <StyledLabelContainer>
          <StyledFormLabel>{title}</StyledFormLabel>
        </StyledLabelContainer>
        <StyledInputContainer>
          <StyledTextEditorWrapper>
            <StyledTextEditorContainer>
              <TextEditor
                onChange={callback(key, textEditorState)}
                editorState={textEditorState}
                readOnly={false}
                oneLine={false}
                data={data}
              />
            </StyledTextEditorContainer>
          </StyledTextEditorWrapper>
          {renderFeedBackRow(data)}
        </StyledInputContainer>
      </StyledForm>
    );
  };

  /**
   * Render Editform row
   * @param {*} key
   * @param {*} disabled
   * @param {*} title
   * @param {*} data
   * @param {*} placeholder
   * @param {*} callback
   * @param {*} buttons
   */
  const renderEditFormRow = ({
    key,
    disabled,
    title,
    data,
    placeholder,
    callback,
    buttons,
    preview,
    input,
    feedback
  }) => (
    <StyledFormWrapper key={key}>
      <StyledForm>
        <StyledLabelContainer>
          <StyledFormLabel>{title}</StyledFormLabel>
        </StyledLabelContainer>
        <StyledInputContainer>
          {input && (
            <StyledEditInputWrapper>
              <Input
                disabled={disabled}
                placeholder={placeholder}
                value={data}
                onChange={callback(key)}
              />
            </StyledEditInputWrapper>
          )}
          {buttons && buttons()}
          {feedback && renderFeedBackRow(data)}
        </StyledInputContainer>
      </StyledForm>
      {preview && preview(data)}
    </StyledFormWrapper>
  );

  /**
   * Render Dropdown form
   * @param {*} key
   * @param {*} disabled
   * @param {*} title
   * @param {*} data
   * @param {*} placeholder
   * @param {*} callback
   * @param {*} buttons
   */
  const renderDropDownForm = ({
    key,
    title,
    data,
    addMarkerCallback,
    removeMarkerCallback,
    updateMarkerCallback,
    markers
  }) => (
    <StyledFormWrapper key={key}>
      {markers.size > 0 && <StyledForm>
        <StyledLabelContainer>
          <StyledFormLabel>{title}</StyledFormLabel>
        </StyledLabelContainer>
        <StyledAdventureWrapper>
          {data && data.map((connection, index) =>
            renderDropDownRow(key, removeMarkerCallback, updateMarkerCallback, connection, index))}
          {props.lastItem
            ? <StyledWarningText>
              Ingen koppling på sista problemet
            </StyledWarningText>
            : renderAddMarkersGroupButton(key, addMarkerCallback)}
        </StyledAdventureWrapper>
      </StyledForm>}
    </StyledFormWrapper>
  );

  /**
   * Render Dropdown row
   * @param {*} key
   * @param {*} removeMarkerCallback
   * @param {*} updateMarkerCallback
   * @param {*} connection
   * @param {*} index
   * @returns
   */
  const renderDropDownRow = (key, removeMarkerCallback, updateMarkerCallback, connection, index) => {
    const markerCount = getMarkerCount(connection.marker_group_id)

    return (
      <StyledDropDownRowContainer key={connection.marker_group_id}>
        <StyledDropDownContainer>
          <StyledDropDownWrapper>
            <DropDown
              title={connection.marker_group_id ? "" : 'Välj märkesgrupp: '}
              defaultValue={connection.marker_group_id}
              onChange={value => updateMarkerCallback(key, DROPDOWN, connection.marker_group_id, index)(value)}
              overrideStyles={dropDownCustomStyles}
            >
              {renderDropDownItems(getAvailableMarkers(connection.marker_group_id))}
            </DropDown>
          </StyledDropDownWrapper>
          <StyledDropDownButtonsWrapper>
            <StyledFormLabel>Antal:</StyledFormLabel>
            <Input
              disabled={!connection.marker_group_id}
              placeholder={connection.marker_group_id ? "" : "-"}
              value={connection.marker_group_id && connection.complete_threshold}
              onChange={updateMarkerCallback(key, INPUT, connection.marker_group_id, index, markerCount)} />
            {renderRemoveButton(index === -1, removeMarkerCallback(key, index))}
          </StyledDropDownButtonsWrapper>
          {renderFeedBackRow(connection, true)}
        </StyledDropDownContainer>
        <StyledMarkerCountContainer>
          <StyledFormLabel>
            {markerCount > 0 && `Tillgängliga märken: ${markerCount}`}
          </StyledFormLabel>
        </StyledMarkerCountContainer>
      </StyledDropDownRowContainer>
    )
  }

  /**
   * Renders keyboard dropdown items
   */
  const renderDropDownItems = (dropDownData) => (
    dropDownData.map(({ id, label }) => (
      <Item key={id} value={id}>
        {label}
      </Item>
    )));

  /**
   * Render Add Markers group button
   */
  const renderAddMarkersGroupButton = (key, callback) => (
    <StyledAddMarkerButtonContainer>
      <PrimaryButton onClick={callback(key)} disabled={!props.draftValid || getAvailableMarkers().size < 1}>
        Lägg till märkesgrupp
      </PrimaryButton>
      {getAvailableMarkers().size < 1 &&
        <StyledWarningText>
          Inga tillgängliga kopplingar kvar
        </StyledWarningText>}
    </StyledAddMarkerButtonContainer>
  )

  /**
   * Appends an marker group object to dropdown
   * @param {*} key
   * @returns
   */
  const addMarkerOnClick = (key) => () => {
    onChange(key)([
      ...itemData[key],
      {
        marker_group_id: '',
        complete_threshold: 1,
        entity_id: props.entityId
      }
    ])
  }

  /**
   * Finds all available markers, if a marker is already connected we dont show it.
   * @param {*} selectedId
   * @returns
   */
  const getAvailableMarkers = (selectedId = -1) => {
    const { markerGroups } = props
    const { marker_group_connections } = itemData

    return markerGroups.filter(item =>
      item.id === selectedId
        ? item
        : marker_group_connections.every(connection => item.id !== connection.marker_group_id)
    )
  }


  /**
   * Return marker count value or 0 if not found
   * @param {*} id
   * @returns
   */
  const getMarkerCount = (id) => {
    const items = props.markerGroups.find(item => item.id === id)
    return items
      ? items.marker_count
      : 0
  }

  /**
   * Remove marker connection ( removes the dropdown row )
   * @param {*} key
   * @param {*} index
   * @returns
   */
  const removeMarkerOnClick = (key, index) => () => {
    const filteredData = itemData[key].filter((item, indx) => index !== indx)
    onChange(key)(filteredData);
  };

  /**
   * Update the dropdown row data
   * @param {*} key
   * @param {*} type
   * @param {*} id
   * @param {*} index
   * @returns
   */
  const updateMarkerData = (key, type, id, index, markerCount) => (data) => {
    let updatedData = itemData[key];

    if (type === INPUT && isPositiveInteger(data) && data <= markerCount) {
      updatedData = itemData[key].map(item =>
        item.marker_group_id === id
          ? { ...item, complete_threshold: parseInt(data) }
          : item
      )
      onChange(key)(updatedData);
    }
    if (type === DROPDOWN) {
      updatedData = itemData[key].map((item, indx) =>
        index === indx
          ? { ...item, marker_group_id: data }
          : item
      )
      onChange(key)(updatedData);
    }
  };

  /**
   * Renders title part
   * @param {*} row
   * @param {*} data
   */
  const renderTitle = (row, data) =>
    renderEditFormRow({
      ...row,
      data: data,
      feedback: true,
      callback: onChange,
      buttons: null,
    });

  /**
   * Renders text part
   * @param {*} row
   * @param {*} data
   */
  const renderText = (row, data) =>
    renderTextEditor({
      ...row,
      data: data,
      callback: onTextChange,
      buttons: null
    });

  /**
   * Renders audio part
   * @param {*} row
   * @param {*} data
   */
  const renderAudio = (row, data) =>
    renderEditFormRow({
      ...row,
      data: data,
      feedback: true,
      callback: onChange,
      buttons: renderAudioButtons(row.key),
      preview: renderUploadedAudio(row.key),
    });

  /**
   * Renders image part
   * @param {*} row
   * @param {*} data
   */
  const renderImage = (row, data) =>
    renderEditFormRow({
      ...row,
      data: data,
      feedback: true,
      callback: onChange,
      buttons: renderImageButtons(row.key),
      preview: renderSelectedImage
    });

  /**
   * Renders grades part
   * @param {*} row
   * @param {*} data
   */
  const renderGrades = (row, data) =>
    renderEditFormRow({
      ...row,
      feedback: true,
      data: data ? data : [],
      callback: onChange,
      buttons: renderGradeButtons(row.key),
    });

  /**
   * Renders order part
   * @param {*} row
   * @param {*} data
   */
  const renderOrderSelector = (row, data) =>
    renderEditFormRow({
      ...row,
      feedback: false,
      data: data ? data : [],
      callback: onChange,
      buttons: renderCheckBox(row.key)
    });

  /**
   * Renders marker groups part
   * @param {*} row
   * @param {*} data
   */
  const renderMarkers = (row, data) =>
    renderDropDownForm({
      ...row,
      markers: props.markerGroups,
      data: data ? data : [],
      addMarkerCallback: addMarkerOnClick,
      removeMarkerCallback: removeMarkerOnClick,
      updateMarkerCallback: updateMarkerData
    });

  /**
   * Render Editform
   */
  const renderEditForm = () => (
    <StyledListItemContent direction="column">
      {rowData[props.type].map(element => {
        switch (element.type) {
          case "title":
            return renderTitle(element, itemData[element.key]);
          case "text":
            return renderText(element, itemData[element.key]);
          case "audio":
            return renderAudio(element, itemData[element.key]);
          case "image":
            return renderImage(element, itemData[element.key]);
          case "grades":
            return renderGrades(element, itemData[element.key]);
          case "dropdown":
            return renderMarkers(element, itemData[element.key]);
          case "order":
            return renderOrderSelector(element, itemData[element.key]);
          default:
            return null;
        }
      })}
    </StyledListItemContent>
  );

  /**
   * Render remove item modal
   */
  const renderModal = () => (
    <Modal isModalOpen={showModal.open} modalClose={closeModal}>
      <StyledRemovePrompt>
        <StyledHeadingContainer>
          <Warning size={20} />
          <StyledRemoveHeading>{modalText[showModal.type].header}</StyledRemoveHeading>
        </StyledHeadingContainer>
        <StyledRemoveText>
          {modalText[showModal.type].text}
        </StyledRemoveText>
        <ul>{
          readyForPublish.map(item =>
            <li key={item.id}>
              {`Titel: ${item.title.toUpperCase()}, Typ: ${translation[item.node_type]}`}
            </li>
          )}
        </ul>
        <StyledRemoveText>
          <strong>{modalText[showModal.type].bold}</strong>
        </StyledRemoveText>
        <StyledButtonContainer>
          {renderActionButton(showModal.type)}
          <StandardButton onClick={closeModal}>{modalText[showModal.type].no}</StandardButton>
        </StyledButtonContainer>
      </StyledRemovePrompt>
    </Modal>
  );

  const renderActionButton = (type) => {
    const color = type === PUBLISH ? 'green' : 'red'
    const callback = type === PUBLISH ? publishItem : removeItem
    const text = modalText[showModal.type].yes

    return <StyledActionButton color={color} onClick={callback}>{text}</StyledActionButton>
  }

  /**
   * Render Link to child
   */
  const renderHeaderLink = () => (
    <StyledListItemHeaderLink>
      <Link to={itemData.url}>{`${props.itemTitle.toUpperCase()}: ${shorten(
        itemData.title.toUpperCase(),
        20
      )}`}</Link>
    </StyledListItemHeaderLink>
  );

  /**
   * Render Label when link is not available
   */
  const renderHeaderLabel = () => (
    <StyledListItemHeaderLabel>
      {`${props.itemTitle.toUpperCase()}: ${shorten(
        itemData.title.toUpperCase(),
        20
      )}`}
    </StyledListItemHeaderLabel>
  );

  return (
    <>
      {showModal.open ? renderModal() : null}
      <StyledListItem>
        <StyledListItemContent direction={"row"}>
          <StyledDragHandleWrapper>
            {props.useDragHandle && props.draftState === "clean" && (
              <HasPermission
                component={<DragHandle />}
                section={ADVENTURES.toLowerCase()}
                permission={EDIT} />
            )}
          </StyledDragHandleWrapper>
          <StyledListItemContentWrapper>
            {itemData.url && props.draftState === "clean"
              ? renderHeaderLink()
              : renderHeaderLabel()}
          </StyledListItemContentWrapper>
          {itemData.status && renderUtilityButtons(workflow[itemData.status])}
          {renderNodeStatus()}
        </StyledListItemContent>
        {editable && renderEditForm()}
      </StyledListItem>
    </>
  );
});

export const ListContainer = SortableContainer(({ children }) => {
  return <StyledListContainer>{children}</StyledListContainer>;
});

const DragHandle = SortableHandle(() => (
  <GripHandle color="white" size={16} />
));
