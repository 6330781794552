import { CHART } from "../constants";
import Chart from "./components/Chart/Chart";
import {
  defaultChartData,
  defaultChartSettings
} from "./components/Chart/ChartData";

const getUsedResources = ({ data }) => {
  if (!data.grid) {
    return undefined;
  }
  return data.grid.flat().reduce((acc, item) => {
    if (item.mediaresource && item.mediaresource.data) {
      return acc.concat(item.mediaresource.data.id);
    }
    return acc;
  }, []);
};

const types = [CHART];

export const createChartPlugin = () => ({
  entityRendererFn: entity => {
    if (types.includes(entity.type)) {
      return {
        component: Chart,
        style: { padding: 0 },
        props: {
          data: { ...defaultChartData, ...entity.data },
          settings: { ...defaultChartSettings(), ...entity.settings }
        }
      };
    }

    return null;
  },
  types,
  getUsedResources
});

export default createChartPlugin;

export const emptyBlocks = {
  [CHART]: {
    type: CHART,
    data: defaultChartData(),
    settings: defaultChartSettings()
  }
};
