import React from "react";

const ToggleOn = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-toggleon"
  >
    <path d="M50,24.6666667 C50,33.871441 42.5381076,41.3333333 33.3333333,41.3333333 L16.6666667,41.3333333 C7.46189236,41.3333333 0,33.871441 0,24.6666667 C0,15.4618924 7.46189236,8 16.6666667,8 L33.3333333,8 C42.5381076,8 50,15.4618924 50,24.6666667 Z M33.3333333,12.1666667 C26.4251736,12.1666667 20.8333333,17.7572917 20.8333333,24.6666667 C20.8333333,31.5748264 26.4239583,37.1666667 33.3333333,37.1666667 C40.2414931,37.1666667 45.8333333,31.5760417 45.8333333,24.6666667 C45.8333333,17.7585069 40.2427083,12.1666667 33.3333333,12.1666667 Z" />
  </svg>
);

export default ToggleOn;
