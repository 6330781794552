import { ENTITIES, EXERCISE } from "../../constants";
import { createSelector } from "reselect";
/**
 * select list of posts of type 'type'
 * @param {*} state
 * @param {*} type
 */
export const selectList = (state, type = ENTITIES[EXERCISE]) => {
  if (!state[type]) {
    return [];
  }
  return state[type].get("list");
};

/**
 * get a selector for current selectedItem (detailpage). If in listview this is empty.
 * @param {*} type
 */
export const getCurrentPostSelector = (type = ENTITIES[EXERCISE]) => state =>
  state[type].get("selectedItem");

/**
 * select current selectedItem (detailpage). If in listview this is empty.
 * @param {*} state
 * @param {*} type
 */
export const selectCurrentPost = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("selectedItem");

/**
 * get List of difficulty values
 * @param {*} state
 * @param {*} type
 */
export const selectDifficulty = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("difficulty");

/**
 * get List of comments
 * @param {*} state
 * @param {*} type
 */
export const selectComments = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("comments");

/**
 * get List of labels
 * @param {*} state
 * @param {*} type
 */
export const selectLabels = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("labels");

/**
 * get List of tags
 * @param {*} state
 * @param {*} type
 */
export const selectTags = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("tags") || [];

/**
 * Select what type of post we are currently showing
 * @param {*} state
 */
export const selectCurrentPostType = state => {
  let postType = state.location.routesMap[state.location.type].name;
  if (!state[postType]) {
    postType = ENTITIES[EXERCISE];
  }
  return postType;
};

/**
 * Returns a list of selected posts.
 * @param {*} state
 * @param {*} type
 * @param {*} useCurrentPost true if on a detailview and want to use currentPost.
 */
export const selectSelectedPostList = (
  state,
  useCurrentPost,
  type = ENTITIES[EXERCISE]
) => {
  let selectedPostsList = [];

  if (!!useCurrentPost) {
    const currentPost = selectCurrentPost(state, type);
    if (currentPost && currentPost.first().id === null) {
      const draftTags = selectNewPostTags(state, type).toJS();
      let cPost = currentPost.first();
      cPost.tags = draftTags;
      selectedPostsList = [cPost];
    } else {
      selectedPostsList = currentPost ? [currentPost.first()] : [];
    }
  } else {
    const postlist = selectList(state, type);
    const selectedPostsIds = state.Carousel.items.map(p => p.id);
    selectedPostsList = postlist
      .filter(post => selectedPostsIds.indexOf(post.id) > -1)
      .toJS();
  }
  return selectedPostsList;
};

export const selectPostListChipAndIds = createSelector(
  selectSelectedPostList,
  (state, _useCurrentPost, type) => state[type].get("newDataTmp").toJS(),
  (postList, newPostTmpData) => {
    const combinedChips = postList.reduce((acc, post) => {
        if (post.chips) {
          acc = [...new Set([...acc, ...post.chips])];
        }
        return acc;
      }, []);

    const postIds = postList.map(p => p.id);

    return {
      postChips: [...combinedChips, ...newPostTmpData.chips],
      post_ids: postIds
    }
  }
)

/**
 * Returns an object with postIds as key and an array of tagids as values
 * postIds is taken from selectedPostList
 * @param {*} selectedPostList list of selected posts
 * @return {object} ex {8: [1,2,3,4]}
 */
export const getTagsPerPost = selectedPostList =>
  selectedPostList.reduce((a, post) => {
    const id = post.id !== null ? post.id : 0;
    a[id] = post.tags ? post.tags : [];
    return a;
  }, {});

/**
 * Returns an object with tagIds as key and an array of postIds who has that tag as value.
 * postIds is taken from selectedPostList
 * @param {*} selectedPostList
 * @return {object} ex {1: [8,6], 2: [8]}
 */
export const getPostsPerTag = selectedPostList =>
  selectedPostList.reduce((a, post) => {
    const postTags = post.tags ? post.tags : [];
    const postId = post.id !== null ? post.id : 0;
    postTags.map(
      tag =>
        (a[tag] =
          a[tag] !== undefined
            ? a[tag].indexOf(postId) < 0
              ? [...a[tag], postId]
              : a[tag]
            : [postId])
    );
    return a;
  }, {});

/**
 * Returns if filter should be visible for current location
 * @param {*} state
 */
export const selectFilterVisibility = state =>
  state.location.routesMap[state.location.type].hideFilters;

/**
 * Selects the tags that is temporarily saved from servicebar and the post is new
 * @param {*} state
 * @param {*} type
 */
export const selectNewPostTags = (state, type) =>
  state[type].getIn(["newDataTmp", "tags"]);

export const selectAidaPreviewData = createSelector(
  state => state.Aida.get("selectedItem"),
  state => state.Aida.get("chips"),
  (selectedItem, aidachips) => {
    return {
      post: selectedItem ? selectedItem.toJS() : null,
      chiplist: aidachips.reduce((a, t) => [...a, ...t.chips], [])
    };
  }
);

/**
 * get Chips tree
 * @param {*} state
 * @param {*} type
 */
export const selectChips = (state, type = ENTITIES[EXERCISE]) =>
  state[type].get("chips") || [];
