import React from "react";
import { BullseyeArrow } from "../../../shared/components/StudliIcons";

import { CatBranchWrapper, NodeWrapper, IconWrapper } from "./CategoryStyles";

/**
 * Recursive helper function to locate a node with a specific id in a tree
 * @param {any} id  the id to look for
 * @param {object} node  the root node of the (sub)tree to search in
 * @returns  {array | boolean} a branch incuding the node, or false
 */
const findNode = (id, node) => {
  if (node.id === id) {
    return [node];
  }
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const result = findNode(id, node.children[i]);
      if (result) {
        return [node, ...result];
      }
    }
  }
  return false;
};

/**
 * Find the branch in the tree where the node with an id is located.
 * @param {any} id  the node id to look for
 * @param {object} tree
 * @returns {array | boolean} the found branch or false
 */
const getSelectedItemBranch = (id, tree) => {
  const branch = findNode(id, tree);
  if (branch) {
    branch.shift();
    branch.reverse();
  }
  return branch;
};

/**
 * Renders Icon if index is 0
 * @param index
 * @returns {null}
 */
const renderIcon = index =>
  index === 0 ? (
    <IconWrapper>
      <BullseyeArrow size={15} />
    </IconWrapper>
  ) : null;

/**
 * Sub render function for the branch
 * @param {array} branch the branch to be rendered
 */
const renderBranch = branch =>
  branch.reduceRight((acc, node, index) => {
    const classes = index === 0 ? "first" : "not_first";

    return (
      <NodeWrapper className={classes}>
        {renderIcon(index)}
        <span>{node.title}</span>
        {acc}
      </NodeWrapper>
    );
  }, null);

/**
 * Render the branch if it exists
 * @param reversedBranch
 * @returns {string}
 */
const renderTest = branch =>
  branch ? renderBranch(branch) : "Ingen kategori vald";

const Branch = ({ treeData, selectedItemId }) => {
  const maintree = {
    id: 0,
    children: treeData
  };
  const branch = getSelectedItemBranch(selectedItemId, maintree);

  return <CatBranchWrapper>{renderTest(branch)}</CatBranchWrapper>;
};

export default Branch;
