import React from "react";
import styled from "styled-components";

export { default as BarSection } from "./Section";

const ServiceBar = ({ className, children }) => {
  return <nav className={className}>{children}</nav>;
};

export default styled(ServiceBar)`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 250px;
  touch-action: none;
  border-left: 1px solid #ccc;
`;
