import * as React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { GripHandle } from "../../../shared/components/StudliIcons";
import {
  StyledItem,
  StyledItemContainer,
  StyledValueContentWrapper,
  StyledQuestionContentWrapper,
  StyledColumnWrapper,
  StyledDragHandleWrapper,
  StyledButton,
  StyledItemEditText,
  StyledQuestionEditInputWrapper,
  StyledDefaultRadioButton,
  StyledItemBoxLabel,
  StyledButtonRowWrapper,
  StyledUtilityButtonsContainer,
  StyledTextButtonWrapper
} from "./StyledSortMachine";
import { ITEMS, UNSORTED_BOX } from "../../../constants";
import {
  renderMedia,
  getMedia,
  renderEditMedia,
  renderMediaButtons
} from "../../../shared/MediaHandler/MediaHandler";
import DropDown, { Item } from "../../../shared/components/DropDown";
import { RadioButton } from "../../../shared/components/RadioButton/RadioButton";
import Text from "../../../shared/components/Text/Text";
import { StyledTextToolbarButton } from "../MultipleChoice/StyledMultipleChoice";

export const SortMachineItem = SortableElement(({ data, ...rest }) => {
  const {
    indx: index,
    onChangeFn,
    onRemoveFn,
    resources,
    editBtnFn,
    onMediaChange,
    onMediaResourceChange,
    onDuplicateItem,
    boxes,
    onRemoveImage,
    storeDraft,
    draftTarget,
    callbackUpdatedItem,
    updatedItem
  } = rest;

  const { initialBox, value, label } = data;
  const [showTextToolbar, setTextToolbar] = React.useState(false);

  const DropDownStyleOverride = {
    minWidth: "inherit",
    height: "100%",
    border: "none",
    alignSelf: "center"
  };

  const customStyles = {
    containerWidth: "auto",
    minWidth: "40px",
    cursor: "pointer",
    backgroundColor: "#FFFF",
    color: "black",
    borderRadius: "5px",
    fontSize: "16px"
  };

  const buttonStyles = {
    border: "1px solid #999",
    borderRadius: "5px",
    minWidth: "40px",
    minHeight: "40px",
    marginRight: "5px"
  };

  /**
   * Set item in the box
   */
  const onIsInBoxChange = React.useCallback(
    inBoxValue => {
      onChangeFn(index, { initialBox: inBoxValue });
    },
    [index, onChangeFn]
  );

  /**
   * Callback for clicking on association box
   * @param {*} value
   */
  const onBoxChange = React.useCallback(
    boxValue => () => {
      onChangeFn(index, { value: boxValue });
    },
    [index, onChangeFn]
  );

  /**
   * Show/hide text area toolbar
   */
  const toggleTextOptions = () => setTextToolbar(!showTextToolbar);

  /**
   * Remove an item
   */
  const removeItem = () => onRemoveFn(index);

  /**
   * Duplicate an item
   */
  const duplicateItem = () => onDuplicateItem(index);

  /**
   * Edit question label text
   */
  const onEditChange = (keyType, text, target) => editBtnFn(index, { label: text });

  /**
   * Condition 1: If user removes a box that is has an item in it, this effekt will reset initialBox.
   * Condition 2: If user removes a box that is associated with an item, this effekt will default association to unsortedbox
   * Because that box does not exist anymore.
   */
  React.useEffect(() => {
    if (
      initialBox &&
      initialBox !== UNSORTED_BOX &&
      !boxes.find(obj => obj.value === initialBox)
    ) {
      onIsInBoxChange(UNSORTED_BOX);
    }
    if (
      value &&
      value !== UNSORTED_BOX &&
      !boxes.find(obj => obj.value === value)
    ) {
      onBoxChange(UNSORTED_BOX)();
    }
  }, [boxes, initialBox, onIsInBoxChange, onBoxChange, value]);

  /**
   * Returns the selected dropdown item label if found, otherwise default item will be returned.
   *
   * @param {*} dropDownItems
   * @param {*} id
   * @returns {*}
   */
  const getDropDownItemLabel = (value, dropDownItems) => {
    const default_item = defaultDropDownItem();
    const object = dropDownItems.find(obj => obj.key === value);
    return object && object.props && object.props.children
      ? object.props.children
      : default_item.props.children;
  };

  /**
   * Renders a default item.
   */
  const defaultDropDownItem = () => (
    <Item key={`${"default_item"}`} value={UNSORTED_BOX}>
      Utanför
    </Item>
  );

  /**
   * Render drop down items for available boxes & a default option.
   */
  const renderDropDownItems = () => {
    const items = boxes.map(({ value }) => (
      <Item key={`${value}`} value={value}>
        {"Låda " + value}
      </Item>
    ));
    items.unshift(defaultDropDownItem());

    return items;
  };

  /**
   * Renders dropDown with items
   * @returns {*}
   */
  const renderDropDown = dropDownItems => (
    <DropDown
      title={getDropDownItemLabel(initialBox, dropDownItems)}
      defaultValue={UNSORTED_BOX}
      onChange={onIsInBoxChange}
      overrideStyles={DropDownStyleOverride}
    >
      {dropDownItems}
    </DropDown>
  );

  /**
   * render the value box & media part if media exists.
   * @param media
   */
  const renderValueBox = media => {
    const dropDownItems = renderDropDownItems();

    if (value !== undefined) {
      return (
        <StyledItemEditText hasMedia={media.mediaComp}>
          {media.mediaComp
            ? renderEditMedia(
                media,
                index,
                true,
                ITEMS,
                onMediaChange,
                onMediaResourceChange
              )
            : null}
          {renderButtonRow(dropDownItems)}
        </StyledItemEditText>
      );
    }
    return null;
  };

  /**
   * Renders the row which contains the item options.
   * @param {*} dropDownItems
   */
  const renderButtonRow = dropDownItems => (
    <StyledButtonRowWrapper>
      <StyledItemBoxLabel>{"Låda"}</StyledItemBoxLabel>
      {renderBoxButtons()}
      {renderDefaultRadioButton()}
      {renderDropDown(dropDownItems)}
    </StyledButtonRowWrapper>
  );

  /**
   * Render default radio button, which indicates that the item should not
   * be associated with any of the boxes. e.g item has no box value
   */
  const renderDefaultRadioButton = () => (
    <StyledDefaultRadioButton
      onClick={onBoxChange(UNSORTED_BOX)}
      isActive={value === UNSORTED_BOX}
    >
      <span />
    </StyledDefaultRadioButton>
  );

  /**
   * Renders box buttons, which on select indicates that the item belongs to that
   * specific box. e.g items box value gets updated.
   */
  const renderBoxButtons = () =>
    boxes.map(({ id, value: boxValue }) => (
      <RadioButton
        key={id}
        isActive={value === boxValue}
        content={boxValue}
        buttonStyle={buttonStyles}
        overrideStyles={customStyles}
        callback={onBoxChange(boxValue)}
      />
    ));

  /**
   * Renders duplicate & remove item
   */
  const renderUtilityButtons = () => (
    <StyledUtilityButtonsContainer>
      <StyledColumnWrapper>
        <StyledButton icon={"Copy"} onClick={duplicateItem}></StyledButton>
      </StyledColumnWrapper>
      <StyledColumnWrapper>
        <StyledButton onClick={removeItem} studlicon={"Trash"}></StyledButton>
      </StyledColumnWrapper>
    </StyledUtilityButtonsContainer>
  );

  /**
   * render the edit text
   */
  const renderQuestionBox = () => {
    if (label !== undefined) {
      return (
        <StyledItemEditText>
          <StyledQuestionEditInputWrapper>
            <Text
              data={{text: data.label}}
              settings={{ useShortToolbar: true, useToolbar: showTextToolbar, multiLine: false }}
              storeDraft={storeDraft}
              draftTarget={draftTarget}
              updateData={onEditChange}
              callbackUpdatedItem={callbackUpdatedItem}
              updatedItem={updatedItem}
            />
          </StyledQuestionEditInputWrapper>
        </StyledItemEditText>
      );
    }
    return null;
  };

  return (
    <StyledItem>
      <StyledDragHandleWrapper>
        <DragHandle />
      </StyledDragHandleWrapper>
      <StyledQuestionContentWrapper>
        {renderMedia(getMedia(data, resources), onRemoveImage(index))}
        {renderQuestionBox()}
      </StyledQuestionContentWrapper>
      {label !== undefined && <StyledTextButtonWrapper>
        <StyledTextToolbarButton isActive={showTextToolbar} studlicon={"TextTool"} onClick={toggleTextOptions}></StyledTextToolbarButton>
      </StyledTextButtonWrapper>}

      <StyledValueContentWrapper>
        {renderValueBox(getMedia(data, resources))}
      </StyledValueContentWrapper>
      {label !== undefined
        ? renderMediaButtons(index, ITEMS, onMediaChange, onMediaResourceChange)
        : null}
      {renderUtilityButtons()}
    </StyledItem>
  );
});

export const ItemContainer = SortableContainer(({ children }) => {
  return <StyledItemContainer>{children}</StyledItemContainer>;
});

const DragHandle = SortableHandle(() => <GripHandle size={16} />);
