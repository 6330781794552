import React from "react";
import { Transforms, Editor, Element as SlateElement, Range } from "slate";
import { useSlate } from "slate-react";
import IconButton from "../../../products/components/IconButton";
import { Link as LinkIcon } from "react-feather";
import { selectionHasTitle } from "./helper";

const ICONSIZE = "16px";

const LinkEditorButton = () => {
  const editor = useSlate();

  const insertLinkToText = (ed, url) => {
    if (isLinkActive(ed)) {
      undoLink(ed);
    }

    const { selection } = ed;
    // check if anchor and focus of selection is the same
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : []
    };
    if (isCollapsed) {
      // insert node where you are
      Transforms.insertNodes(ed, link);
    } else {
      // Wrap link around selection and split node if part of a textnode.
      Transforms.wrapNodes(ed, link, { split: true });
      // collapse the selection to a single point at the end
      Transforms.collapse(ed, { edge: "end" });
    }
  };

  const undoLink = ed => {
    Transforms.unwrapNodes(ed, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    });
  };

  const isLinkActive = ed => {
    const [link] = Editor.nodes(ed, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    });
    return !!link;
  };

  return (
    <IconButton
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault();
        if (selectionHasTitle(editor)) return;
        const url = window.prompt("Ange url");
        if (!url) return;
        insertLinkToText(editor, url);
      }}
    >
      <LinkIcon height={ICONSIZE} width={ICONSIZE} />
    </IconButton>
  );
};

export default LinkEditorButton;
