import React from "react";

const Search = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-search"
  >
    <path d="M38.6912165,33.3887176 L48.9016999,43.599201 C50.3661,45.0636011 50.3661,47.4378628 48.9016999,48.9022629 C47.4372998,50.3666631 45.0630381,50.3666631 43.598638,48.9022629 L33.3880541,38.6916791 C29.9468391,41.0910388 25.7622575,42.4980853 21.2490426,42.4980853 C9.51352044,42.4980853 -1.43718697e-15,32.9845648 0,21.2490426 C1.43718697e-15,9.51352044 9.51352044,-7.18593485e-16 21.2490426,0 C26.8846391,3.45080761e-16 32.2894163,2.23873199 36.2743848,6.2237005 C40.2593533,10.208669 42.4980853,15.6134461 42.4980853,21.2490426 C42.4980853,25.7625476 41.090858,29.9473769 38.6912165,33.3887176 Z M21.2490426,34.9984232 C24.8956051,34.9984232 28.3928138,33.5498319 30.9713229,30.9713229 C33.5498319,28.3928138 34.9984232,24.8956051 34.9984232,21.2490426 C34.9984232,13.6554695 28.8426158,7.49966211 21.2490426,7.49966211 C13.6554695,7.49966211 7.49966211,13.6554695 7.49966211,21.2490426 C7.49966211,28.8426158 13.6554695,34.9984232 21.2490426,34.9984232 Z" />
  </svg>
);

export default Search;
