import {
  END_FETCH,
  FETCH_ENTITIES,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_SUCCESS,
  START_FETCH,
  GOTO_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  RESET_BEFORE_FILTER_SORT_CHANGE
} from "../../api";

export const ACTION_FETCH_PRODUCTLIST = `PRODUCTLIST/${FETCH_ENTITIES}`;
export const ACTION_FETCH_PRODUCTLIST_START = `PRODUCTLIST/${START_FETCH}`;
export const ACTION_FETCH_PRODUCTLIST_SUCCESS = `PRODUCTLIST/${FETCH_ENTITIES_SUCCESS}`;
export const ACTION_FETCH_PRODUCTLIST_FAILED = `PRODUCTLIST/${FETCH_ENTITIES_ERROR}`;
export const ACTION_FETCH_PRODUCTLIST_END = `PRODUCTLIST/${END_FETCH}`;

export const ACTION_PRODUCTLIST_GOTOPAGE = `PRODUCTLIST/${GOTO_PAGE}`;
export const ACTION_PRODUCTLIST_NEXTPAGE = `PRODUCTLIST/${NEXT_PAGE}`;
export const ACTION_PRODUCTLIST_PREVPAGE = `PRODUCTLIST/${PREVIOUS_PAGE}`;

export const ACTION_RESET_BEFORE_FILTER_SORT_CHANGE = `PRODUCTLIST/${RESET_BEFORE_FILTER_SORT_CHANGE}`;

export const SORT_PRODUCTLIST = `SORT_PRODUCTLIST`;
export const EXPORT_PRODUCTLIST = `EXPORT_PRODUCTLIST`;

export const ACTION_SET_FETCH_LIMIT = `ACTION_SET_FETCH_LIMIT`;
export const ACTION_SET_FETCH_LIMIT_SUCCESS = `ACTION_SET_FETCH_LIMIT_SUCCESS`;

export const fetchProductList = (args, searchParameters = {}) => ({
  type: ACTION_FETCH_PRODUCTLIST,
  args,
  searchParameters
});

export const fetchProductListStart = () => ({
  type: ACTION_FETCH_PRODUCTLIST_START
});

export const fetchProductListSuccess = (
  data,
  metadata,
  searchParameters,
  batchIndex
) => ({
  type: ACTION_FETCH_PRODUCTLIST_SUCCESS,
  data,
  metadata,
  searchParameters,
  batchIndex
});

export const fetchProductListFailed = error => ({
  type: ACTION_FETCH_PRODUCTLIST_FAILED,
  error
});

export const fetchProductListEnd = () => ({
  type: ACTION_FETCH_PRODUCTLIST_END
});

export const sortProducts = sort => ({
  type: SORT_PRODUCTLIST,
  sort
});

export const exportProductList = () => ({
  type: EXPORT_PRODUCTLIST
});

export const resetBeforeFilterSortChange = () => ({
  type: ACTION_RESET_BEFORE_FILTER_SORT_CHANGE
});

export const setFetchLimit = limit => ({
  type: ACTION_SET_FETCH_LIMIT,
  limit
});

export const setFetchLimitSuccess = limit => ({
  type: ACTION_SET_FETCH_LIMIT_SUCCESS,
  limit
});
