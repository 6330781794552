import styled from "styled-components";
import {
  blue,
  white,
  neutralPrimary,
  neutralQuaternaryAlt
} from "../../../styles/shared/colors";
import Input from "../../../shared/components/Input/Input";

export const StyledNumberLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledNumberLineContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 440px;
`;

export const StyledNumberLineEndInput = styled(Input)`
  width: 100%;
  background: #0078d7;
  border: none;
  color: #fff;
  outline: none;
  text-align: center;

  &::placeholder {
    color: #a5c7e2;
  }
`;

export const StyledTickLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
  margin: 5px 0;

  ${({ isActive }) => isActiveLabel(isActive)};

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
`;

export const StyledNumberLineWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
`;

export const StyledNumberLineContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledNumberLineTrack = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const StyledBottomBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledBottomBarCol = styled.div`
  display: flex;
  margin: ${({ margin }) => margin || "0"};
`;

export const StyledBottomBarRow = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  margin: ${({ margin }) => margin || "0"};
`;

export const StyledNumberLineEdgeArrowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: ${({ horizontal }) =>
    `flex-${horizontal === "left" ? "end" : "start"}`};
`;

export const StyledNumberLineEdgeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 44px 35px 25px;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
  justify-items: center;
  align-items: center;
  z-index: 2;
  width: 80px;

  &:first-child {
    margin-right: 3px;
  }

  &:last-child {
    margin-left: 3px;
  }
`;

export const StyledNumberLineEdge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  grid-area: middle;
  color: ${white};
  background: ${blue};
`;

export const StyledJumpImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`;

export const StyledJumpImgContainer = styled.div`
  min-width: 100px;
  min-height: 70px;
  max-height: 300px;
  max-width: 300px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid #333333;
  padding: 6px;
`;

export const StyledJumpImg = styled.img`
  max-width: 100%;
`;

export const StyledJumpLabel = styled.span`
  margin-top: 4px;
  color: ${neutralPrimary};
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
`;

export const StyledRemoveImgButton = styled.button`
  position: absolute;
  top: -14px;
  right: -14px;
  background: none;
  padding: 0;
  display: flex;
  border: none;
  cursor: pointer;
`;

/**
 * Sets color for active label
 * @param isActive
 * @returns {string}
 */
const isActiveLabel = isActive =>
  isActive
    ? `
    background: ${blue};
    color: ${white};
  `
    : `
    background: ${neutralQuaternaryAlt};
    color: ${neutralPrimary};
  `;
