import { fromJS, List } from "immutable";
import { mergeDeepRight } from "ramda";
import { createReducer } from "../../store/utils";
import {
  ACTION_ACTIVATE_AIDAHELP,
  ACTION_FETCH_SYSTEMS_START,
  ACTION_FETCH_SYSTEMS_SUCCESS,
  ACTION_FETCH_SYSTEMS_END
} from "./actions";

export const requiredFields = {
  active: {},
  list: [],
  fetching: false,
  saving: false,
  systems: []
};

const reducer = initialState => {
  const initState = fromJS(mergeDeepRight(requiredFields, initialState));

  return createReducer(initState, {

    [ACTION_ACTIVATE_AIDAHELP](state, action) {
      return state
        .set("selectedItem", action.aida);
    },
    [ACTION_FETCH_SYSTEMS_START](state) {
      return state.set("fetching", true);
    },
    [ACTION_FETCH_SYSTEMS_SUCCESS](state, action) {
      return state.update("systems", () => new List(action.data.entities))
    },
    [ACTION_FETCH_SYSTEMS_END](state) {
      return state.set("fetching", false);
    },
  });
};

export default reducer;