import React from "react";

const Comment = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-comment"
  >
    <path d="M43.75,0 L6.25,0 C2.80273438,0 0,2.80273438 0,6.25 L0,34.375 C0,37.8222656 2.80273438,40.625 6.25,40.625 L15.625,40.625 L15.625,48.828125 C15.625,49.5214844 16.1914062,50 16.796875,50 C17.03125,50 17.2753906,49.9316406 17.4902344,49.765625 L29.6875,40.625 L43.75,40.625 C47.1972656,40.625 50,37.8222656 50,34.375 L50,6.25 C50,2.80273438 47.1972656,0 43.75,0 Z M46.875,34.375 C46.875,36.09375 45.46875,37.5 43.75,37.5 L28.6425781,37.5 L27.8125,38.125 L18.75,44.921875 L18.75,37.5 L6.25,37.5 C4.53125,37.5 3.125,36.09375 3.125,34.375 L3.125,6.25 C3.125,4.53125 4.53125,3.125 6.25,3.125 L43.75,3.125 C45.46875,3.125 46.875,4.53125 46.875,6.25 L46.875,34.375 Z" />
  </svg>
);

export default Comment;
