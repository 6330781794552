import { NOT_FOUND } from "redux-first-router";

class RouterService {
  constructor() {
    this._routeMap = {};
    this._viewMap = {
      [NOT_FOUND]: null
    };
  }

  setNotFoundView(view) {
    this._viewMap = Object.assign({}, this._viewMap, {
      [NOT_FOUND]: view
    });
  }

  registerRoute(key, route) {
    this._routeMap = Object.assign({}, this._routeMap, {
      [key]: { requireAuth: true, ...route }
    });
  }

  setView(route, view) {
    this._viewMap = Object.assign({}, this._viewMap, {
      [route]: view
    });
  }

  getView(route) {
    return this._viewMap[route] || this._viewMap[NOT_FOUND];
  }

  getRouteMap() {
    return this._routeMap;
  }

  getRoute(route) {
    return this._routeMap[route] ? { ...this._routeMap[route] } : false;
  }
}

export default new RouterService();
