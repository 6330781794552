import { call, put, select } from "redux-saga/effects";
import * as api from "./requests";
import { generalErrorHandler } from "../../api";
import * as sharedModule from "../../shared";
import {
  startFetch,
  endFetch,
  fetchEntitiesSuccess,
  fetchEntitiesError,
  resetEntityDraft,
  fetchMediaListSuccess,
  fetchMediaListError
} from "./actions";
import {
  selectEntityDraft,
  selectJsonDraft,
  selectAdventureGroupId,
  selectAdventureId,
  selectAdventureCardId
} from "../sagas/selectors";
import {
  deleteEntityFailed,
  deleteEntitySuccess,
  deleteMediaItemFailed,
  deleteMediaItemSuccess,
  deleteMediaSectionFailed,
  deleteMediaSectionSuccess,
  saveEntityFailed,
  saveEntitySuccess,
  saveJsonSuccess,
  updateOrderFailed,
  updateOrderSuccess,
  publishEntitySuccess,
  publishEntityFailed,
  reviewEntitySuccess,
  reviewEntityFailed,
  unpublishEntitySuccess,
  unpublishEntityFailed,
  requestReviewEntitySuccess,
  requestReviewEntityFailed,
  rejectEntitySuccess,
  rejectEntityFailed,
  reloadAdventures,
  getPublishListSuccess,
  updateCollectionMarkerSuccess,
  updateCollectionMarkerFailure
} from "../sagas/actions";
import { selectEntityId } from "../../store/selectors";
import {
  publishMarkersError,
  publishMarkersSuccess,
  updateMarkerColorThemeFailed,
  updateMarkerColorThemeSuccess,
  updateMarkerItemFailed,
  updateMarkerItemSuccess,
  updateMarkersFailed,
  updateMarkerSuccess
} from "../markers/api/actions";
import { MARKERS, translationErrorMessage } from "../../constants";
import { addFlash } from "../../shared";

export const fetchMediaListSaga = (entityName, apiAction) => {
  return function*(action) {
    const { args } = action;

    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], args);
      yield put(fetchMediaListSuccess(entityName, result.data));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchMediaListError(entityName, error.response));
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * Fetches a list of entities from backend
 * @param {*} entityName
 * @param {*} apiAction
 */
export const fetchEntitiesSaga = (entityName, apiAction) => {
  return function*(action) {
    const { args } = action;

    yield put(startFetch(entityName));
    try {
      const result = yield call(api[apiAction], args);
      let extras;
      let markers;

      if (entityName === MARKERS) {
        extras = yield call(api.getColorThemes, args);
      } else {
        extras = yield call(api.getGrades, args);
        markers = yield call(api.getMarkersReady, args);
      }

      yield put(
        fetchEntitiesSuccess(
          entityName,
          result.data,
          extras.data,
          markers && markers.data
        )
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(fetchEntitiesError(entityName, error.response));
    } finally {
      yield put(endFetch(entityName));
    }
  };
};

/**
 * On saving an entity
 */
export function* onSaveEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const entityDraft = yield select(selectEntityDraft, entityName);
  const args = {
    entityId,
    entityDraft,
    id: entityDraft.id,
    media: action.media
  };

  try {
    const result = args.id
      ? yield call(api.updateAdventure, args)
      : yield call(api.createAdventure, args);

    yield put(saveEntitySuccess(entityName, result.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(saveEntityFailed(entityName, error));
  }
}
/**
 * On saving json
 */
export function* onSaveJson(entityName) {
  const entityId = yield select(selectEntityId);
  const adventureGroupId = yield select(selectAdventureGroupId);
  const adventureId = yield select(selectAdventureId);
  const entityDraft = yield select(selectJsonDraft, entityName);
  const args = {
    entityId,
    entityDraft,
    id: entityDraft.id,
    adventureGroupId,
    adventureId
  };

  try {
    const result = yield call(api.updateAdventureJson, args);

    yield put(saveJsonSuccess(entityName, result.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(saveEntityFailed(entityName, error));
  }
}

/**
 * On deleting an entity
 */
export function* onDeleteEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, id: action.id };

  try {
    yield call(api.deleteAdventure, args);
    yield put(deleteEntitySuccess(entityName, args.id));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(deleteEntityFailed(entityName, error));
  }
}

/**
 * On updating list order
 */
export function* onUpdatingOrder(entityName, action) {
  const entityId = yield select(selectEntityId);
  const ids = action.list.map(item => item.id);
  const args = { entityId, list: ids };

  try {
    const result = yield call(api.updateAdventureOrder, args);
    yield put(updateOrderSuccess(entityName, result.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(updateOrderFailed(entityName, error));
    yield put(resetEntityDraft(entityName));
  }
}

/**
 * On deleting a media item
 */
export function* onDeleteMediaItem(entityName, action) {
  const entityId = yield select(selectEntityId);
  const adventureGroupId = yield select(selectAdventureGroupId);
  const adventureId = yield select(selectAdventureId);
  const adventureCardId = yield select(selectAdventureCardId);
  const args = {
    entityId,
    adventureGroupId,
    adventureId,
    adventureCardId,
    mediaId: action.mediaId
  };

  try {
    const result = yield call(api.deleteAdventureCardMedia, args);
    yield put(deleteMediaItemSuccess(entityName, result.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(deleteMediaItemFailed(entityName, error));
  }
}

/**
 * On deleting a media section
 */
export function* onDeleteMediaSection(entityName, action) {
  const entityId = yield select(selectEntityId);
  const adventureGroupId = yield select(selectAdventureGroupId);
  const adventureId = yield select(selectAdventureId);
  const adventureCardId = yield select(selectAdventureCardId);
  const args = {
    entityId,
    adventureGroupId,
    adventureId,
    adventureCardId,
    mediaType: action.mediaType
  };

  try {
    const result = yield call(api.deleteAdventureCardMediaSection, args);
    yield put(deleteMediaSectionSuccess(entityName, result.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(deleteMediaSectionFailed(entityName, error));
  }
}

/**
 * On publising an entity
 */
export function* getReadyForPublishList(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    const result = yield call(api.getPublishedList, args);
    yield put(getPublishListSuccess(entityName, result.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    if(error && error.response){
      if(error.response.data?.message === "node has no, or not enough, previously published parents"){
        yield put(
          addFlash(
            "Äventyrsgruppen är inte publicerad till det här äventyret."
          )
        );
        return;
      }else{
        yield generalErrorHandler(error.response.data);
      }
    }else{
      yield generalErrorHandler(error);
    }
  }
}

/**
 * On publising an entity
 */
export function* onPublishEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    yield call(api.publishNodes, args);
    yield put(publishEntitySuccess(entityName, action.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error && error.response && error.response.data);
    yield put(publishEntityFailed(entityName, error));
  }
}

/**
 * On unpublising an entity
 */
export function* onUnPublishEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    yield call(api.unpublishNodes, args);
    yield put(unpublishEntitySuccess(entityName, action.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error && error.response && error.response.data);
    yield put(unpublishEntityFailed(entityName, error));
  }
}

/**
 * On reviewing an entity
 */
export function* onReviewEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    yield call(api.reviewNodes, args);
    yield put(reviewEntitySuccess(entityName, action.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error && error.response && error.response.data);
    yield put(reviewEntityFailed(entityName, error));
  }
}

/**
 * On requesting review for an entity
 */
export function* onRequestReviewEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    yield call(api.requestReview, args);
    yield put(requestReviewEntitySuccess(entityName, action.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error && error.response && error.response.data);
    yield put(requestReviewEntityFailed(entityName, error));
  }
}

/**
 * On unpublising an entity
 */
export function* onRejectEntity(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = { entityId, list: action.data };

  try {
    yield call(api.rejectNodes, args);
    yield put(rejectEntitySuccess(entityName, action.data));
    yield put(reloadAdventures(entityName));
  } catch (error) {
    yield generalErrorHandler(error && error.response && error.response.data);
    yield put(rejectEntityFailed(entityName, error));
  }
}

export const updateMediaFileSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);

    const args = { entityId, id: action.id, file: action.file };

    try {
      const result = yield call(api.updateMediaFile, args);

      const groupData = result.data.groupList[0];
      const id = groupData.id;
      const url = groupData.marker_type_url;
      const name = groupData.markertype_filename;

      yield put(updateMarkerSuccess(entityName, id, url, name));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(updateMarkersFailed(entityName, error));
    }
  };
};

export const updateMarkerItemSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const args = { entityId, id: action.id, file: action.file };
    try {
      const result = yield call(api[apiAction], args);

      yield put(
        updateMarkerItemSuccess(
          entityName,
          result.data.id,
          result.data.item_url,
          result.data.item_filename
        )
      );
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(updateMarkerItemFailed(entityName, error));
    }
  };
};

export const updateMarkerColorThemeSaga = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);
    const args = { entityId, id: action.id, data: action.data };

    try {
      const result = yield call(api[apiAction], args);
      const groupId = result.data.groupList[0].id;
      const themeId = result.data.groupList[0].colortheme_id;
      yield put(updateMarkerColorThemeSuccess(entityName, groupId, themeId));
    } catch (error) {
      yield generalErrorHandler(error);
      yield put(updateMarkerColorThemeFailed(entityName, error));
    }
  };
};

export const onPublishMarkers = (entityName, apiAction) => {
  return function*(action) {
    const entityId = yield select(selectEntityId);

    try {
      yield call(api[apiAction], entityId);
      yield put(publishMarkersSuccess(entityName));

      yield put(
        sharedModule.addFlash("Märken har blivit publicerade", "success")
      );
    } catch (error) {
      yield put(
        sharedModule.addFlash(
          translationErrorMessage[error.response.data.message],
          "info"
        )
      );
      yield put(publishMarkersError(entityName, error));
    }
  };
};

export function* onUploadCollectionMarker(entityName, action) {
  const entityId = yield select(selectEntityId);
  const args = {
    entityId,
    marker: action.marker
  };

  try {
    const result = yield call(api.uploadCollectionMarker, args);
    yield put(updateCollectionMarkerSuccess(entityName, result.data));
  } catch (error) {
    yield generalErrorHandler(error);
    yield put(updateCollectionMarkerFailure(entityName, error));
  }
}
